import { Container, Grid, Typography } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import { useAuth } from "../../../contexts/crm/Auth";
import { useChatContext } from "../contexts/ChatContext";
import useWebSocketClient from "../controllers/WebSocketClient";
import { getAllCustomersInQueue } from "../services/queue";
import { AnimationSpin } from "../styles/Animations";

export default function CustomerLobbyWaiting() {
  const theme = useTheme();
  const webSocketClient = useWebSocketClient();
  const context = useChatContext();
  const { company } = useAuth();

  const [queueListState, setQueueListState] = useState({
    position: 0,
    message: "",
  });

  useEffect(() => {
    const findMyPositionInQueue = async () => {
      const currentId = context.chat?.currentConversation;
      const roomId = context.chat?.roomId;
      const companyId = company.id;
      let positionInQueue = 0;
      if (context.chat?.conversations?.length) {
        const conversation = (context.chat?.conversations || []).find(
          (elem) => elem.sessionId === currentId
        );
        const queueId = `${companyId}-${roomId}-${conversation?.departmentId}`;
        const res = await getAllCustomersInQueue({
          webSocketClient,
        }).fromQueueId({ queueId });
        if (res?.queueItem?.length) {
          positionInQueue =
            res.queueItem.findIndex((queue) => queue.session_id === currentId) +
            1;
        }
        const messagePositionInQueue =
          positionInQueue > 0
            ? `Você está na posição ${positionInQueue} da fila`
            : "O atendente logo irá atende-lo";
        setQueueListState({
          position: positionInQueue,
          message: messagePositionInQueue,
        });
      }
    };
    setTimeout(() => {
      findMyPositionInQueue();
    }, 2000);
  }, []);

  return (
    <Container
      style={{
        padding: theme.spacing(0),
        display: "inline-grid",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
      }}
    >
      <Grid
        container
        direction="row"
        alignItems="center"
        justifyContent="center"
        style={{ width: "100%" }}
      >
        <Grid item xs={12} style={{ display: "contents" }}>
          <AnimationSpin style={{ margin: theme.spacing(4) }} />
        </Grid>
        <Grid item xs={12}>
          <Typography>Por favor aguarde</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography>{queueListState.message}</Typography>
        </Grid>
      </Grid>
    </Container>
  );
}
