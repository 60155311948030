import { Grid, Typography } from "@material-ui/core";
import Backdrop from "@material-ui/core/Backdrop";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: "99999",
    background: "rgba(255, 255, 255, 0.38)",
    boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
    backdropFilter: "blur(11.2px)",
    WebkitBackdropFilter: "blur(11.2px)",
    color: "#fff",
  },
}));

export default function Loading({ open, message }) {
  const classes = useStyles();

  return (
    <Backdrop className={classes.backdrop} open={open}>
      <Grid container justifyContent="center">
        <Grid container item xs={6} spacing={2}>
          {message && (
            <Grid item xs={12}>
              <Typography align="center" variant="h5">
                {message}
              </Typography>
            </Grid>
          )}
          <Grid
            item
            xs={12}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <img
              alt="Carregando"
              src="https://s3.sa-east-1.amazonaws.com/qyon.panel.documents/loading.gif"
              width={60}
            />
          </Grid>
        </Grid>
      </Grid>
    </Backdrop>
  );
}
