import { useContext } from "react";
import { ManagementContext } from "../contexts/ManagementContext";
import { useManagementController } from "../controllers/ManagementController";

import { Grid, IconButton } from "@material-ui/core";
import {
  AddCircleOutline,
  DeleteOutlineOutlined,
  EditOutlined,
} from "@material-ui/icons";

import { notify } from "../../../utils/notify";
import SelectErp from "../../../components/basics/SelectErp";
import Btn from "../../../components/buttons/Btn";

import { GridStyled, LightTooltip } from "../../../styles/GlobalStyle";
import { FavoriteOutlinedStyled } from "../styles/management";

export const TabMainGroups = () => {
  const {
    handleChangeGroup,
    handleDeleteGroup,
    handleSaveDeafaultGroup,
    handleSaveGroupPermissions,
    handleDiscartGroupChanges,
  } = useManagementController();

  const { edited, setOpenModalCreateGroup, groupOptions, groupSelected } =
    useContext(ManagementContext);

  return (
    <GridStyled
      container
      justifyContent="space-between"
      alignItems="center"
      bg-color="white"
      padding="8"
    >
      <GridStyled item xs>
        <GridStyled container spacing={2}>
          <Grid item xs>
            <SelectErp
              label="Grupo"
              value={groupSelected}
              optionsList={groupOptions}
              descriptionField="name"
              onChange={handleChangeGroup}
            />
          </Grid>

          {groupSelected && (
            <>
              <GridStyled item alignSelf="center">
                <LightTooltip
                  title={
                    groupSelected.default
                      ? "Grupo padrão"
                      : "Marcar como padrão"
                  }
                >
                  <IconButton
                    size="small"
                    onClick={() => {
                      if (groupSelected.master)
                        return notify(
                          "Grupo de acesso master não pode ser editado"
                        );

                      !groupSelected.default
                        ? handleSaveDeafaultGroup()
                        : notify(
                            "É obrigatório ter um grupo de acesso padrão! Caso deseje altera-lo, selecione outro grupo como padrão"
                          );
                    }}
                  >
                    <FavoriteOutlinedStyled
                      $color={groupSelected.default ? "blue" : "grayPrimary"}
                    />
                  </IconButton>
                </LightTooltip>
              </GridStyled>
              <GridStyled item alignSelf="center">
                <LightTooltip title="Editar Grupo Selecionado">
                  <IconButton
                    size="small"
                    onClick={() => {
                      if (groupSelected.master)
                        return notify(
                          "Grupo de acesso master não pode ser editado"
                        );

                      setOpenModalCreateGroup(true);
                    }}
                  >
                    <EditOutlined />
                  </IconButton>
                </LightTooltip>
              </GridStyled>
              <GridStyled item alignSelf="center">
                <LightTooltip title="Excluir grupo de acesso">
                  <IconButton
                    size="small"
                    onClick={() => {
                      if (groupSelected.master)
                        return notify(
                          "Grupo de acesso master não pode ser excluido"
                        );

                      if (groupSelected.default)
                        return notify(
                          "Grupo de acesso padrão não pode ser excluido"
                        );

                      handleDeleteGroup();
                    }}
                  >
                    <DeleteOutlineOutlined />
                  </IconButton>
                </LightTooltip>
              </GridStyled>
            </>
          )}

          <Grid item>
            <Btn
              label="Novo Grupo"
              full
              onClick={() => {
                handleChangeGroup({ name: "" });
                setOpenModalCreateGroup(true);
              }}
              startIcon={<AddCircleOutline />}
            >
              Novo Grupo
            </Btn>
          </Grid>
          {edited && (
            <>
              <Grid item>
                <Btn
                  label="Cancelar"
                  bg-color="tertiary"
                  full
                  onClick={handleDiscartGroupChanges}
                />
              </Grid>
              <Grid item>
                <Btn label="Salvar" full onClick={handleSaveGroupPermissions} />
              </Grid>
            </>
          )}
        </GridStyled>
      </GridStyled>
    </GridStyled>
  );
};
