import { useEffect, useState } from "react";
import { Margin } from "../../styles/GlobalStyle";
import {
  Grid,
  InputAdornment,
  LinearProgress,
  TextField,
  Tooltip,
} from "@material-ui/core";
import { Search, VerifiedUser, Visibility } from "@material-ui/icons";
import moment from "moment/moment";
import {
  CertificateDataGrid,
  CertificateNormal,
  CertificateWillExpire,
  CompanyColumn,
  DateColumn,
  DateColumnExpired,
  DocumentColumn,
  EnterpriseColumn,
  ExpiredCertificate,
  ProcurationActiveColumn,
  ProcurationCanceledColumn,
  ProcurationExpiredColumn,
  ProcurationRejectedColumn,
} from "./styles/Certificate";
import { getProcurations } from "../../repositories/panel/v1/ProcurationRepository";
import { GridOverlay } from "@material-ui/data-grid";
import ProcurationPermissionModal from "./ProcurationPermissionModal";
import { formatCNPJorCPF } from "./util/Formatter";
import { getExpirationInDays } from "./util/DateUtil";
import {
  ArrowRightIcon,
  VerificationInProgressIcon,
  VerifiedIcon,
} from "./CertificateIcon";

export default function CertificateDetailView({ certificateDetail }) {
  const [loading, setLoading] = useState(true);
  const [rows, setRows] = useState([]);
  const [tmpRows, setTmpRows] = useState([]);
  const [textFilter, setTextFilter] = useState("");
  const [selectedProcuration, setSelectedProcuration] = useState(null);
  const [showProcurationModal, setShowProcurationModal] = useState(false);
  let columns = [
    {
      field: "grantor_name",
      headerName: "Outorgante CPF/CNPJ",
      flex: 1,
      sortable: false,
      renderCell: ({ row }) => {
        if (!row.grantor_name) {
          return <CompanyColumn>CADASTRADO MANUALMENTE</CompanyColumn>;
        }

        return (
          <EnterpriseColumn>
            <CompanyColumn>{row.grantor_name}</CompanyColumn>
            <DocumentColumn>
              {formatCNPJorCPF(row.grantor_document)}
            </DocumentColumn>
          </EnterpriseColumn>
        );
      },
    },
    {
      field: "status",
      headerName: "Status",
      width: 100,
      sortable: false,

      renderCell: ({ row }) => {
        if (!row.status) {
          return "";
        }

        if (row.status === "ACTIVE") {
          return <ProcurationActiveColumn>Ativa</ProcurationActiveColumn>;
        }

        if (row.status === "EXPIRED") {
          return <ProcurationExpiredColumn>Expirada</ProcurationExpiredColumn>;
        }

        if (row.status === "CANCELED") {
          return (
            <ProcurationCanceledColumn>Cancelada</ProcurationCanceledColumn>
          );
        }

        if (row.status === "REJECTED") {
          return (
            <ProcurationRejectedColumn>Rejeitada</ProcurationRejectedColumn>
          );
        }

        return <div>DESCONHECIDA</div>;
      },
    },
    {
      field: "start_in",
      headerName: "Início",
      width: 100,
      sortable: false,

      renderCell: ({ row }) => {
        if (!row.start_date) {
          return "";
        }

        return <DateColumn>{formatDate(row.start_date)}</DateColumn>;
      },
    },
    {
      field: "expire_in",
      headerName: "Expiração",
      width: 150,
      sortable: false,

      renderCell: ({ row }) => {
        if (!row.end_date) {
          return "";
        }

        let daysUntilExpire = getExpirationInDays(row.end_date);
        if (daysUntilExpire <= 0) {
          return (
            <Tooltip title={moment(row.end_date).format("DD/MM/YYYY")}>
              <ExpiredCertificate>Expirado</ExpiredCertificate>
            </Tooltip>
          );
        }

        if (daysUntilExpire <= 7) {
          return (
            <Tooltip title={moment(row.end_date).format("DD/MM/YYYY")}>
              <CertificateWillExpire>
                {daysUntilExpire} Dias
              </CertificateWillExpire>
            </Tooltip>
          );
        }

        return (
          <Tooltip title={moment(row.end_date).format("DD/MM/YYYY")}>
            <CertificateNormal>
              {getExpirationInDays(row.end_date)} Dias
            </CertificateNormal>
          </Tooltip>
        );
      },
    },
    {
      field: "verified",
      headerName: "Verificado",
      width: 150,
      sortable: false,

      renderCell: ({ row }) => {
        if (row.verified === false) {
          return (
            <Tooltip title={"Verificação em progresso"}>
              <div style={{ marginLeft: 20 }}>
                <VerificationInProgressIcon />
              </div>
            </Tooltip>
          );
        }
        return (
          <Tooltip title={"Verificação em progresso"}>
            <div style={{ marginLeft: 20 }}>
              <VerifiedIcon />
            </div>
          </Tooltip>
        );
      },
    },

    {
      field: "actions",
      headerName: "Ações",
      width: 100,
      sortable: false,

      renderCell: ({ row }) => {
        return (
          <Tooltip title={"Visualizar detalhes"}>
            <div
              style={{ cursor: "pointer" }}
              onClick={() => {
                setSelectedProcuration(row);
                setShowProcurationModal(true);
              }}
            >
              <ArrowRightIcon />
            </div>
          </Tooltip>
        );
      },
    },
  ];

  useEffect(() => {
    setLoading(true);
    getProcurations({
      grantedDocument: certificateDetail.document,
    }).then((procurations) => {
      setRows(procurations);
      setTmpRows(procurations);
      setLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (loading === true) {
      return;
    }
    const delayDebounceFn = setTimeout(() => {
      if (textFilter === "") {
        setTmpRows(rows);
        return;
      }
      const rowsFiltered = rows.filter((e) => {
        const search = textFilter
          .toLowerCase()
          .replace("-", "")
          .replace("-", "")
          .replace("/", "")
          .replace(".", "");
        const name = e.grantor_name.toLowerCase();
        const document = e.grantor_document
          .replace("-", "")
          .replace("-", "")
          .replace("/", "")
          .replace(".", "");

        if (name.includes(search) || document.includes(search)) {
          return true;
        }

        return false;
      });

      setTmpRows(rowsFiltered);
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [textFilter]);

  const formatDate = (date) => {
    if (!date) {
      return "";
    }
    return moment(date).format("DD/MM/YYYY");
  };

  return (
    <>
      <ProcurationPermissionModal
        openModal={showProcurationModal}
        selectedProcuration={selectedProcuration}
        closeModal={() => {
          setSelectedProcuration(null);
          setShowProcurationModal(false);
        }}
      />
      <div
        container
        style={{
          background: "#fff",
          border: "1px solid #E0E0E0",
          borderRadius: 15,
          padding: 15,
          height: "100%",
          flex: 1,
        }}
      >
        <Margin top="10" />

        <Grid container spacing={2}>
          <Grid item xs={12}>
            <div style={{ fontWeight: "bold" }}>Pesquisar</div>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              placeholder="Pesquisar por nome e CPF/CNPJ do outorgante"
              value={textFilter}
              onChange={(e) => setTextFilter(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>

        <CertificateDataGrid
          disableColumnFilter
          disableColumnMenu
          style={{ marginTop: 20, height: "calc(100% - 65px)" }}
          rows={loading ? [] : tmpRows}
          columns={columns}
          slots={{
            loadingOverlay: LinearProgress,
          }}
          loading={loading}
          disableSelectionOnClick
          components={{
            NoRowsOverlay: () =>
              !loading && (
                <GridOverlay>
                  <h4>
                    Nenhuma procuração encontrada no momento!<br></br>
                    Rastreamos constantemente procurações outorgadas para sua
                    empresa! Assim que uma procuração for detectada, ela
                    aparecerá aqui.
                  </h4>
                </GridOverlay>
              ),
          }}
        />
      </div>
    </>
  );
}
