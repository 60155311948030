import React from "react";
import { Button, Grid, Typography } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import { AnimationSpin } from "../styles/Animations";
import { useChatContext } from "../contexts/ChatContext";
import Btn from "../../../components/buttons/Btn";


export function LoadingScreen() {
  const theme = useTheme();
  const { chat, setChat, SCREEN_ENUM } = useChatContext()

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      justifyContent="center"
    >
      {chat.isLoading && <AnimationSpin style={{ margin: theme.spacing(4) }} />}
      <Typography
        style={{
          paddingLeft: '10px', 
          paddingRight: '10px', 
          textAlign: 'center'
        }}>
          {chat.loadingMessage}
        </Typography>
      {!chat.isLoading && chat.loadingMessage &&
        <Btn
          style={{ marginTop: 24 }}
          label="Voltar"
          onClick={() => {
            setChat({ currentScreen: SCREEN_ENUM.customerLobby, currentConversation: "" })
          }}
        />}
    </Grid>
  );
}
