import moment from "moment";
import { getDocumentData } from "../../../repositories/Document";
import { getXMLData } from "../../../repositories/DocumentXML";
import { scanZIPData } from "../../../repositories/DocumentZIP";
import { getIbgeCityCode } from "../../../repositories/IbgeProvider";
import { getUserBilling } from "../../../repositories/integrations/erp/ContractQyonRepository";
import {
  getCustomersEntityNew,
  getEntityByDocument,
} from "../../../repositories/panel/v1/EntityRepository";
import { getCustomerPlug } from "../../../repositories/panel/v1/integrations/PlugRepository";
import { notify } from "../../../utils/notify";
import { validateCPF } from "../../../utils/validations";
import { getRPSData } from "../../sidebar/controllers/SendXML";

async function readFile(file) {
  let result = await new Promise((resolve) => {
    let fileReader = new FileReader();
    fileReader.onload = (e) => resolve(fileReader.result);
    fileReader.readAsText(file);
  });
  return result;
}

const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export async function handleFile(
  files,
  botRef,
  companyUser,
  token,
  isInClientView,
  clientViewed,
  closeZipModal,
  closeBotChatModal,
  BotContext
) {
  let listRPS = [];
  let listPfx = [];
  let listTxt = [];
  let listTxtMigration = [];
  let listXml = [];
  let listDocument = [];
  let listZip = [];
  let image = null;

  closeBotChatModal();

  for (let i = 0; i < files.length; i++) {
    let content, base64;

    if (files[i].name.endsWith(".lnk")) {
      return notify(
        "Parece que você tentou enviar um atalho, por favor, envie o arquivo original!"
      );
    }

    switch (files[i].type) {
      case "application/pdf":
        base64 = await toBase64(files[i]);
        base64 = base64.replace("data:application/pdf;base64,", "");

        listDocument.push({
          base64: base64,
          name: files[i].name,
          tab: "ONE",
        });

        break;
      case "image/png":
        base64 = await toBase64(files[i]);
        base64 = base64.replace("data:image/png;base64,", "");

        image = base64;

        break;
      case "application/x-pkcs12":
        base64 = await toBase64(files[i]);
        base64 = base64.replace("data:application/x-pkcs12;base64,", "");

        listPfx.push({
          base64: base64,
          name: files[i].name,
          action: "company", // "company" para cadastro; "procuration" para migração
          accessEmail: "",
          procurationDocument: "",
          procurationDocumentList: [],
        });

        break;
      case "text/plain":
        content = await readFile(files[i]);
        let cnpjs = content.toString().split("\r");

        let base64NFSe = await toBase64(files[i]);
        base64NFSe = base64NFSe.replace("data:text/plain;base64,", "");

        let response = await getRPSData(base64NFSe);

        if (response.nodeName === "RPS") {
          listRPS.push({ ...response, base64: base64NFSe });
          BotContext.setClientsNFSE(listRPS);
          BotContext.setLoadingXml(true);
        } else if (cnpjs[0].toUpperCase() === "INICIAR MIGRAÇÃO ESOCIAL") {
          for (i = 1; i < cnpjs.length; i++) {
            listTxtMigration.push(cnpjs[i]);
          }
          break;
        } else {
          for (let cn of cnpjs) {
            try {
              let cnpjCompanyList = cn.split(";");

              cnpjCompanyList[0] = cnpjCompanyList[0]
                .replace("\n", "")
                .replace(/\D/g, "");
              cnpjCompanyList[1] = cnpjCompanyList[1].replace("\n", "");

              listTxt.push({
                cnpj: cnpjCompanyList[0],
                email: cnpjCompanyList[1],
              });
            } catch (err) {
              console.log(err);
            }
          }
        }

        break;
      case "text/xml":
        content = await readFile(files[i]);
        base64 = await toBase64(files[i]);
        base64 = base64.replace("data:text/xml;base64,", "");

        listXml.push({ xml: content, base64: base64, name: files[i].name });
        break;
      default:
        return notify("Tipo de arquivo não reconhecido!");
    }
  }

  if (listRPS.length !== 0) {
    BotContext.setLoadingXml(true);

    let clients = await getCustomersEntityNew({
      id: companyUser.companyId,
    });

    let listaCnpjs = [];
    let listIm = [];

    if (clients.data) {
      listaCnpjs = clients.data.map((e) => e.document);
      listIm = clients.data.map((e) => e.im);
    }
    let xmlListTmp = [];

    for (let xml of listRPS) {
      for (let cnpj of listaCnpjs) {
        if (xml.prest.document === cnpj) {
          const xmlTmp = Object.assign({}, xml);
          xmlTmp["subtype"] = "output";
          xmlTmp["cnpj"] = cnpj;
          xmlListTmp.push(xmlTmp);
        }
        if (xml.toma.document === cnpj) {
          const xmlTmp = Object.assign({}, xml);
          xmlTmp["subtype"] = "input";
          xmlTmp["cnpj"] = cnpj;
          xmlListTmp.push(xmlTmp);
        }
      }
      for (let im of listIm) {
        if (xml.prest.im === im) {
          const xmlTmp = Object.assign({}, xml);
          xmlTmp["subtype"] = "output";
          xmlTmp["im"] = im;
          xmlListTmp.push(xmlTmp);
        }
        if (xml.toma.im === im) {
          const xmlTmp = Object.assign({}, xml);
          xmlTmp["subtype"] = "input";
          xmlTmp["im"] = im;
          xmlListTmp.push(xmlTmp);
        }
      }
    }
    BotContext.setLoadingXml(false);
    BotContext.setDataRPS(xmlListTmp);
  }

  if (listPfx.length !== 0) {
    BotContext.setCertificateList(listPfx);

    BotContext.setOpenCertificateModal(true);

    botRef.current.a1_upload();
  }

  if (listTxt.length !== 0) {
    let fullCompanyList = [];
    let companyErrorList = [];

    for (let company of listTxt) {
      let entityExists = await getEntityByDocument(company.cnpj);
      let entityHasAccountant = false;
      let obj = {};

      if (entityExists !== null && entityExists?.success) {
        if (
          entityExists.data.accountant_id !== null &&
          entityExists.data.accountant_id !== undefined &&
          entityExists.data.accountant_id !== ""
        ) {
          if (
            entityExists.data.accountant_id.toString() !== companyUser.companyId
          ) {
            obj.companyName = entityExists.data.company_name;
            obj.error = "Esta empresa já está vinculada a outro escritório.";
            obj.clientCnpj = company.cnpj;
            obj.email = company.email;

            entityHasAccountant = true;

            companyErrorList.push(obj);
          }
        }
      }

      if (!entityHasAccountant) {
        let requestOptions = {
          method: "GET",
          redirect: "follow",
          headers: {
            authorization: "Bearer " + token,
            govid: companyUser.document,
          },
        };

        let response = await new Promise((resolve, reject) => {
          fetch(
            localStorage.getItem("environment") === "prod"
              ? "https://qyon-bot.qyon.info/v1/bot/data/company/" + company.cnpj
              : "https://qyon-bot-stg.qyon.info/v1/bot/data/company/" +
                  company.cnpj,
            requestOptions
          )
            .then((response) => resolve(response.json()))
            .catch((err) => resolve(null));
        });

        if (entityExists?.success) {
          obj.companyName = entityExists?.data?.company_name;
          obj.tradingName =
            entityExists.data.trading_name || entityExists.data.company_name;
          obj.clientCnpj = company.cnpj;
          obj.phone = entityExists.data?.phone || "";
          obj.email = company.email;
          obj.addresses = response.result.addresses || {
            cep: "00000000",
            street: "Não Informado",
            number: "1",
            complement: "",
            neighborhood: "Não Informado",
            city: "Não Informado",
            state: "SP",
            ibgeCode: "",
          };
          obj.addresses[0].ibgeCode = obj.addresses[0].cep
            ? await getIbgeCityCode(
                obj.addresses[0].cep.replace(/[^\d]/g, ""),
                token,
                companyUser.document
              )
            : "";

          fullCompanyList.push(obj);
        } else if (validateCPF(company.cnpj)) {
          obj.companyName = company.cnpj;
          obj.tradingName = company.cnpj;
          obj.clientCnpj = company.cnpj;
          obj.phone = "";
          obj.email = company.email;
          obj.addresses = [
            {
              cep: "00000000",
              street: "Não Informado",
              number: "1",
              complement: "",
              neighborhood: "Não Informado",
              city: "Não Informado",
              state: "SP",
              ibgeCode: "",
            },
          ];

          fullCompanyList.push(obj);
        } else if (response) {
          if (response.success) {
            obj.companyName = response.result.companyName;
            obj.tradingName =
              response.result.tradingName || response.result.companyName;
            obj.clientCnpj = company.cnpj;
            obj.phone = response.result.phone;
            obj.email = company.email;

            obj.addresses = response.result.addresses;

            obj.addresses[0].ibgeCode = obj.addresses[0].cep
              ? await getIbgeCityCode(
                  obj.addresses[0].cep.replace(/[^\d]/g, ""),
                  token,
                  companyUser.document
                )
              : "";

            fullCompanyList.push(obj);
          } else {
            obj.companyName = "Não Encontrado";
            obj.error = "Não foi possível obter os dados desta empresa";
            obj.clientCnpj = company.cnpj;
            obj.email = company.email;

            companyErrorList.push(obj);
          }
        }
      }

      BotContext.setCompanyErrorList(companyErrorList);
      BotContext.setCompanyList(fullCompanyList);
    }

    let hasAccountant = false;
    if (companyErrorList.length > 0 && fullCompanyList.length === 0) {
      companyErrorList.forEach((element) => {
        if (
          element.error === "Esta empresa já está vinculada a outro escritório."
        ) {
          hasAccountant = true;
        }
      });
    }
    BotContext.setKeyCompany(Math.random());

    if (hasAccountant && fullCompanyList.length === 0) {
      BotContext.setOpenCompanyHasAccountantModal(true);
    } else if (fullCompanyList.length === 0) {
      BotContext.setOpenNotFoundCompanyModal(true);
    } else {
      BotContext.setOpenCompanyModal(true);
    }
  }

  if (listTxtMigration.length !== 0) {
    BotContext.setOpenMigrationModal(true);
    BotContext.setLoadingMigration(true);

    let fullCompanyList = [];
    for (let cnpj of listTxtMigration) {
      let response = await getCustomerPlug({ document: cnpj });
      response.cnpj = cnpj;
      fullCompanyList.push(response);
    }

    BotContext.setCompanyList(fullCompanyList);
    BotContext.setKeyMigration(Math.random());
    BotContext.setLoadingMigration(false);
  }

  if (listXml.length) {
    BotContext.setOpenXmlModal(true);
    BotContext.setLoadingXml(true);
    BotContext.setKeyXml(Math.random());
    BotContext.setXmlList(listXml);
    for (let xml of listXml) {
      xml.data = await getXMLData(xml.base64);
    }

    BotContext.setClients(
      await getCustomersEntityNew({
        id: isInClientView ? clientViewed?.company : companyUser.companyId,
      })
    );

    BotContext.setLoadingXml(false);
    BotContext.setKeyXml(Math.random());
  }

  if (image != null) {
    BotContext.setLogoImage(image);
    BotContext.setOpenImageModal(true);
    botRef.current.logo_upload();
  }

  if (listDocument.length !== 0) {
    if (listDocument.length > 20) {
      notify("É permitido o upload de no máximo 20 documentos!");
      return;
    }

    BotContext.setDocumentModalLoading(true);

    BotContext.setDocumentList(listDocument);
    BotContext.setOpenDocumentModal(true);

    BotContext.setOpenBotChatModal(false);

    botRef.current.pdf_upload();

    let newDocumentList = [];

    for (let document of listDocument) {
      try {
        let documentDataList = await getDocumentData(document.base64);

        for (let data of documentDataList) {
          let newDocument = Object.assign({}, document);
          newDocument.data = data;
          newDocument.sendToPay = true;

          newDocumentList.push(newDocument);
        }
      } catch (err) {
        console.log(err);
        BotContext.setOpenDocumentModal(false);
        notify("Ocorreu um problema, tente novamente mais tarde!");
      }
    }

    BotContext.setDocumentList(newDocumentList);
    BotContext.setDocumentModalLoading(false);
    BotContext.setKeyDocument(Math.random());
  }

  if (listZip.length !== 0) {
    BotContext.setLoadingZip(true);
    BotContext.setOpenZipModal(true);

    let newZipList = [];
    for (let zip of listZip) {
      let result = await scanZIPData(zip.base64, "eSocial");
      if (result.success) newZipList.push(zip);
    }

    if (newZipList.length > 0) {
      BotContext.setZipList(newZipList);
      BotContext.setLoadingZip(false);
    } else {
      closeZipModal();
    }
  }

  if (
    !listPfx.length &&
    !listTxt.length &&
    !listTxtMigration.length &&
    !listXml.length &&
    image == null &&
    !listDocument.length &&
    !listZip.length &&
    !listRPS.length
  ) {
    BotContext.setOpenFileNotRecognizedModal(true);
  }
}

const getBatteryStatus = (dueDate) => {
  const now = moment().format("YYYY-MM-DD");
  let diff = moment(now).diff(dueDate, "days");

  let status = "";
  let image = "";

  if (diff > 0 && diff <= 14) {
    status = "DUE";
    image = "alert";
  } else if (diff > 14 && diff <= 20) {
    status = "DUE";
    image = "danger";
  } else if (diff > 20) {
    status = "SUSPEND";
    image = "interrupt";
  } else if (diff === 0) {
    status = "DUE_TODAY";
    image = "alert";
  } else {
    status = "NORMAL";
    image = "full";
  }

  return {
    image,
    status,
    diff,
  };
};

export const getBillingData = async (companyUser, botContext) => {
  const response = await getUserBilling(companyUser.document);

  try {
    if (response?.success) {
      const billingList = response.data.find((item) => {
        return item.modalidade === "R";
      });

      if (billingList?.contas_receber) {
        const { contas_receber } = billingList;

        if (contas_receber.length > 0) {
          contas_receber.sort((a, b) => {
            if (a.datavencto < b.datavencto) return -1;
            if (a.datavencto > b.datavencto) return 1;
            return 0;
          });

          const {
            datavencto,
            id,
            valordocto,
            dataemissao,
            keyboleto,
            keyboletopix,
            keyboletoqyon,
            linha_digitavel,
          } = contas_receber[0];
          const { image, status, diff } = getBatteryStatus(datavencto);

          botContext.setBatteryStatus({
            image,
            status,
            diff,
            onError: false,
          });

          botContext.setClientBillingData((prev) => ({
            ...prev,
            id,
            dueDate: datavencto,
            total: valordocto,
            emittedDate: dataemissao,
            enableBoleto: !!keyboleto || !!keyboletopix || !!keyboletoqyon,
            typeableLine: linha_digitavel,
          }));

          return;
        }
      }

      //doesnt have contract
      botContext.setBatteryStatus({
        image: "full",
        status: "WITHOUT_CONTRACT",
        diff: 0,
        onError: false,
      });
    } else {
      if (response?.internalCode === 3031) {
        //doesnt have contract
        botContext.setBatteryStatus({
          image: "full",
          status: "WITHOUT_CONTRACT",
          diff: 0,
          onError: false,
        });

        return;
      }

      throw new Error("");
    }
  } catch (error) {
    botContext.setBatteryStatus({ onError: true, status: "danger" });
  }
};
