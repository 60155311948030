import { Box, Button, Card, Chip, Grid } from "@material-ui/core";

import styled from "styled-components";
import Typography from "@material-ui/core/Typography";
import BusinessIcon from "@material-ui/icons/Business";
import CloseIcon from "@material-ui/icons/Close";

export const CardNfse = styled(Card)`
  margin-bottom: 10px;
`;
export const ChipStyled = styled(Chip)`
  color: white;
  position: relative;
  bottom: 10px;
  float: right;
  margin-top: 10px;
  font-size: 12px;
  margin-right: 20px;
  background-color: rgb(19, 142, 247);
`;

export const TypographyStyled = styled(Typography)`
  margin-top: 10px;
  padding-left: 25px;
  margin-bottom: 10px;
  margin-left: 5px;
  font-size: 14px;
`;

export const GridStyled = styled(Grid)`
  margin-top: 5px;
  padding-left: 15px;
  margin-bottom: 10px;
  margin-left: 10px;
`;

export const BusinessIconStyled = styled(BusinessIcon)`
  font-size: 30px;
`;

export const TypographyEmitente = styled(Typography)`
  margin-top: 5px;
  font-size: 14px;
`;

export const TypographyDestinatario = styled(Typography)`
  margin-top: 5px;
  font-size: 14px;
  margin-bottom: 10px;
`;

export const Div = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
`;

export const DivTitle = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CloseIconStyled = styled(CloseIcon)`
  position: absolute;
  right: 0;
  cursor: pointer;
`;

export const DivCnpjNotIdentified = styled.div`
  font-weight: bold;
  margin-left: 25px;
  margin-bottom: 5px;
  color: red;
  font-size: 14px;
  padding-top: 25px;
`;

export const ButtonStyled = styled(Button)`
  margin-right: "15px";
`;

export const ModalNFSeStyle = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 600px;
  background-color: #fff;
  box-shadow: 24px;
  border-radius: 10px;
`;

export const DivNew = styled.div`
  position: relative;
  right: 10px;
  bottom: 10px;
  float: right;
  margin-top: 20px;

  .Button {
    background-color: red;
    font-weight: bold;
    width: 150px;
    right: 20px;
    color: white;
  }

  .ButtonSecondary {
    font-weight: bold;
    background-color: rgb(19, 142, 247);
    color: white;
    width: 150px;
    right: 10px;
    float: right;
  }
`;

