import { search } from "../BaseRepositoryComponents";

const baseUrl = process.env.REACT_APP_NEW_PAINEL_API_URL;

export function getSystemsAccountantCanEnable() {
  return search({
    path: `${baseUrl}/v1/systems/accountant?system_id=1`,
    method: "GET",
    showMessage: false,
  });
}

export function enableSystemVersionForClient(body) {
  //? REQUIRED FIELDS "payer", "entity_id", "version_id", "service_id"
  return search({
    path: `${baseUrl}/v1/systems/accountant/enable`,
    method: "POST",
    myBody: body,
  });
}
