import axios from "axios";

const path = `https://api-panel.qyon.info/v1/letter`;

export const ListSystemVersion = async () => {
  return await axios
    .get(`${path}`, {
      headers: {
        Authorization: localStorage.getItem("tokenPanel"),
      },
    })
    .then((response) => {
      if (response.status !== 200) {
        return null;
      } else {
        return response;
      }
    })
    .catch((err) => {
      if (err.response) {
        return err.response;
      }
      return null;
    });
};

export const IDSystem = async (id) => {
  return await axios
    .get(`${path}/${id}`, {
      headers: {
        Authorization: JSON.parse(localStorage.getItem("cognitoPanel"))[
          "AccessToken"
        ],
      },
    })
    .then((response) => {
      if (response.status !== 200) {
        return null;
      } else {
        return response;
      }
    })
    .catch((err) => {
      if (err.response) {
        return err.response;
      }
      return null;
    });
};
