import { search } from "../../BaseRepositoryComponents";

const token = process.env.REACT_APP_ERP_TOKEN;

const headers = new Headers();
headers.append("Authorization", token);
headers.append("content-type", "application/json");
headers.append("Content-Language", "pt-BR");

export async function sendEmail(body) {
  return search({
    path: `${process.env.REACT_APP_ERP_API}/api/aws/v1/ses/send`,
    method: "POST",
    myHeaders: headers,
    myBody: body,
  });
}
