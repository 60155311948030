export const isAccountant = () => {
  return localStorage.getItem("isAccountantPanel") === "true";
};


export const isAccountantClient = () => {
  return (
    localStorage.getItem("isAccountantPanel") !== "true" &&
    localStorage.getItem("accountantIdPanel") !== ""
  );
};

export const isCompany = () => {
  return (
    localStorage.getItem("isAccountantPanel") !== "true" &&
    localStorage.getItem("accountantIdPanel") === ""
  );
};
