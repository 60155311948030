import React, { useState } from "react";
import { Grid } from "@material-ui/core";
import InputMask from "react-input-mask";
import IntlCurrencyInput from "react-intl-currency-input";
import { TabTextField, valueFormStyle } from "../styles/DocumentModal";
import useDocumentModalController from "../controllers/DocumentModalController";

export const DocumentFgtsTab = (props) => {
  const [companyName, setCompanyName] = useState(
    props.document.data.company_name
  );
  const [govid, setGovid] = useState(props.document.data.govid);
  const [govidMask, setGovidMask] = useState(
    props.document.data.govid.replace(/[^\d]/g).length <= 11 &&
      props.document.data.govid.replace(/[^\d]/g).length > 0
      ? "999.999.999-999"
      : "99.999.999/9999-99"
  );

  const [periodExibition, setPeriodExibition] = useState(
    `${props.document.data.period.substring(
      5,
      7
    )}/${props.document.data.period.substring(0, 4)}`
  );

  const [expireDate, setExpireDate] = useState(props.document.data.expire_date);

  const [collectTotal, setCollectTotal] = useState(
    props.document.data.total_amount
  );
  const [documentName, setDocumentName] = useState(
    props.document.data.use_suggestion
      ? props.document.data.name_suggestion
      : props.document.name
  );

  const { verifyAndChangeGovidMask } = useDocumentModalController();

  return (
    <Grid container spacing={2}>
      <Grid item xs={9}>
        <TabTextField
          label="Nome da empresa"
          variant="outlined"
          key={"NAME_COMPANY_" + props.index}
          value={companyName}
          onChange={(ev) => {
            props.document.data.company_name = ev.target.value;
            setCompanyName(ev.target.value);
          }}
        />
      </Grid>

      <Grid item xs={3}>
        <InputMask
          onBlur={(ev) => {
            props.setCpfCnpjFocus(false);
          }}
          mask={govidMask}
          disabled={false}
          key={"CPF_CNPJ" + props.index + props.govIdKey}
          maskChar=""
          value={govid}
          onChange={(ev) => {
            setGovid(ev.target.value.replace(/[^\d]/g, ""));
            props.document.data.govid = ev.target.value.replace(/[^\d]/g, "");
            verifyAndChangeGovidMask(
              ev.target.value.replace(/[^\d]/g, ""),
              setGovidMask
            );
            props.setCpfCnpjFocus(true);
          }}
        >
          {() => (
            <TabTextField
              autoFocus={props.cpfCnpjFocus}
              label="CPF/CNPJ"
              variant="outlined"
              key={"CPF_CNPJ" + props.index + props.documentKey}
            />
          )}
        </InputMask>
      </Grid>

      <Grid item xs={6}>
        <InputMask
          mask="99/9999"
          disabled={false}
          maskChar=""
          value={periodExibition}
          onChange={(ev) => {
            setPeriodExibition(ev.target.value);
            props.document.data.competence = `${ev.target.value.substring(
              3,
              7
            )}-${ev.target.value.substring(0, 2)}`;

            props.document.data.period = `${ev.target.value.substring(
              3,
              7
            )}-${ev.target.value.substring(0, 2)}`;
          }}
        >
          {() => (
            <TabTextField
              label="Competência"
              variant="outlined"
              key={"COMPETENCE" + props.index}
            />
          )}
        </InputMask>
      </Grid>

      {/* //FIXME */}
      <Grid item xs={6}>
        <div
          class="MuiFormControl-root MuiTextField-root "
          style={valueFormStyle}
        >
          <label
            style={{ background: "#f2f2f2" }}
            class="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiInputLabel-marginDense MuiInputLabel-outlined MuiFormLabel-filled"
            data-shrink="true"
          >
            Data de Vencimento
          </label>
          <div class="MuiInputBase-root MuiInputBase-formControl">
            <input
              type="date"
              variant="outlined"
              class="MuiInputBase-input MuiInputBase-inputMarginDense "
              key={"EXPIRATION_DATE" + props.index}
              style={{ paddingRight: "5px", paddingLeft: "5px" }}
              value={expireDate}
              onChange={(ev) => {
                setExpireDate(ev.target.value);
                props.document.data.expire_date = ev.target.value;
              }}
            />
          </div>
        </div>
      </Grid>

      <Grid item xs={12}>
        <div
          class="MuiFormControl-root MuiTextField-root "
          style={valueFormStyle}
        >
          <label
            style={{ background: "#f2f2f2" }}
            class="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiInputLabel-marginDense MuiInputLabel-outlined MuiFormLabel-filled"
            data-shrink="true"
          >
            Total a Recolher
          </label>

          <div class="MuiInputBase-root MuiOutlinedInput-root MuiInputBase-formControl MuiInputBase-marginDense MuiOutlinedInput-marginDense">
            <IntlCurrencyInput
              currency="BRL"
              value={collectTotal}
              class="MuiInputBase-input MuiOutlinedInput-input MuiInputBase-inputMarginDense MuiOutlinedInput-inputMarginDense"
              config={{
                locale: "pt-BR",
                formats: {
                  number: {
                    BRL: {
                      style: "currency",
                      currency: "BRL",
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    },
                  },
                },
              }}
              onChange={(event, value, maskedValue) => {
                event.preventDefault();
                setCollectTotal(maskedValue);
                props.document.data.total_amount = maskedValue;
              }}
            />
          </div>
        </div>
      </Grid>

      {props.documentModalLoading === false && (
        <>
          <Grid item xs={12}>
            <TabTextField
              final
              label={
                props.document.use_suggestion
                  ? "Nome do documento (sugestão)"
                  : "Nome do documento (original)"
              }
              variant="outlined"
              key={"DOCUMENT_NAME" + props.index + " - " + props.documentKey}
              value={documentName}
              onChange={(ev) => {
                props.document.name_suggestion = ev.target.value;
                setDocumentName(ev.target.value);
              }}
            />
          </Grid>
        </>
      )}
    </Grid>
  );
};
