import { notify } from "../../../utils/notify";
import { RpaStatus } from "../../../utils/RpaStatus";
import * as entityRepository from "../../../repositories/panel/v1/integrations/qyonMsRpa/entity";

export function useCndGeneralController() {
  const getFile = async (
    parentDocument,
    cndId,
    operation,
    setLoading,
    clientDocument
  ) => {
    try {
      setLoading((prev) => ({ ...prev, getFile: true }));

      const allowedOperations = {
        Download: forceDownloadFile,
        View: forceViewFile,
      };

      if (!allowedOperations[operation]) return notify("Operação Inválida!");

      const responseFile = await entityRepository.getPdfLastAgenda({
        parentDocument,
        clientDocument,
        cndId,
      });

      allowedOperations[operation](responseFile);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading((prev) => {
        let prevLoading = { ...prev };
        delete prevLoading.getFile;
        return prevLoading;
      });
    }
  };

  const forceViewFile = (file) => {
    const blobUrl = URL.createObjectURL(file);

    window.open(blobUrl, "_blank");
  };

  const forceDownloadFile = (file) => {
    const blobUrl = URL.createObjectURL(file);
    let a = document.createElement("a");
    a.href = blobUrl;
    a.download = "CND.pdf";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(blobUrl);
  };

  const disableActionFile = (row) => {
    const validStatus = [
      RpaStatus.POSITIVA.description,
      RpaStatus.NEGATIVA.description,
      RpaStatus.NEGATIVA_POSITIVA.description,
      RpaStatus.INCONSISTENTE.description,
    ];

    return !validStatus.includes(row.status);
  };

  const handleSendEmail = async ({
    e,
    emails,
    handleClose,
    setLoading,
    row,
    parentDocument,
  }) => {
    try {
      e.preventDefault();
      setLoading((prev) => ({ ...prev, handleSendEmail: true }));

      const body = {
        document: row.document,
        cnd_id: row.id,
        emails: emails,
      };

      const result = await entityRepository.sendEmail({
        body,
        parentDocument,
      });

      if (result && result.success) {
        handleClose();
        return notify("E-mail enviado com sucesso.", true, "success");
      }

      notify("Falha ao enviar e-mail.");
    } catch (error) {
      console.error(error);

      notify("Falha ao enviar e-mail.");
    } finally {
      setLoading((prev) => {
        let prevLoading = { ...prev };
        delete prevLoading.handleSendEmail;
        return prevLoading;
      });
    }
  };

  return {
    getFile,
    disableActionFile,
    handleSendEmail,
  };
}
