import React from "react";
import { useHistory } from "react-router-dom";
import { notify } from "../../utils/notify";
import TicketData from "./childrens/TicketData";
import { UseTicketProvider } from "./contexts/EditSupportServiceContext";

const EditTicket = () => {
  const { push } = useHistory();

  const idTicket = window.location.pathname.replace("/ticket/edit/", "");

  if (idTicket) {
    return (
      <>
        <TicketData typeScreen={"edit"} ticketId={idTicket} />
      </>
    );
  } else {
    push("../../attendance/search");
    notify("Atendimento não encontrado", true, "error");
    return null;
  }
};

const EditTicketWrapper = () => {
  return (
    <UseTicketProvider>
      <EditTicket />
    </UseTicketProvider>
  );
};

export default EditTicketWrapper;
