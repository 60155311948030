import { Box, Grid, Icon } from "@material-ui/core";
import { Margin } from "../../styles/GlobalStyle";
import { ButtonCard, Label, PropsBox } from "./styles/CardButton";

export default function CardButton({ label, onClick, icon }) {
  return (
    <ButtonCard variant="contained" onClick={onClick}>
      <Grid justifyContent="space-between" container spacing={2}>
        <Grid item flex={1}>
          <PropsBox />
          <Box />
        </Grid>
        <Grid item xs container>
          <Margin top="15">
            <Label>{label}</Label>
          </Margin>
        </Grid>
        <Grid item>
          <Margin margin="15">
            <Icon>{icon}</Icon>
          </Margin>
        </Grid>
      </Grid>
    </ButtonCard>
  );
}
