import { useEffect, useState } from "react";

import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  makeStyles,
  Tooltip,
  Zoom,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { HelpOutline as HelpIcon } from "@material-ui/icons";

import Btn from "../../../components/buttons/Btn";
import { ContainerIcon, Margin, Title } from "../../../styles/GlobalStyle";

const useStyles = makeStyles({
  dialog: {
    position: "absolute",
    left: "60%",
    bottom: 50,
  },
});

const ModalSave = ({
  setOpen,
  finish,
  setCheckboxEmail,
  checkboxEmail,
  modalAction,
  isCustomer,
  validationSendEmail,
  ...props
}) => {
  const classes = useStyles();
  const [sendEmail, setSendEmail] = useState(true);

  const DarkTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: theme.palette.common.black,
      color: "#fff",
      boxshadow: theme.shadows[2],
      placement: "right",
      fontSize: 13,
    },
  }))(Tooltip);

  const cancelButton = () => {
    // setOpen(!props?.open)
    setOpen(!props?.open);
    modalAction("cancel");
    setCheckboxEmail(null);
  };

  const saveButton = () => {
    // setOpen(!props?.open)
    setCheckboxEmail({
      typeEmail: `${finish ? "ticket" : "newMessage"}`,
      active: sendEmail,
    });

    setOpen(!props?.open);
    modalAction("save");
  };

  useEffect(() => {
    if (!validationSendEmail) {
      setSendEmail(false);
    }
  }, [validationSendEmail]);

  return (
    <Dialog
      open={props?.open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="lg"
      classes={{
        paper: classes.dialog,
      }}
    >
      <DialogTitle id="alert-dialog-title">
        <Grid container xs={12}>
          <Title subtitle style={{ display: "flex", width: "100%" }}>
            <Grid item xs={11}>
              {finish ? "Finalizar Atendimento" : "Salvar Atendimento"}
            </Grid>
            <Grid item xs={1} style={{ display: "flex", alignItems: "center" }}>
              <DarkTooltip
                title="Marque a caixa de seleção para enviar a notificação para o cliente ou desmarque para não enviar"
                placement="right"
                TransitionComponent={Zoom}
                TransitionProps={{ timeout: 100 }}
              >
                <ContainerIcon
                  iconColor="#0a73db"
                  style={{
                    display: "flex",
                    alignSelf: "center",
                  }}
                >
                  <HelpIcon />
                </ContainerIcon>
              </DarkTooltip>
            </Grid>
          </Title>
        </Grid>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Margin left="10">
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      defaultChecked={!validationSendEmail ? false : true}
                    />
                  }
                  value={!validationSendEmail ? false : sendEmail}
                  disabled={!validationSendEmail}
                  label="Enviar notificação ao cliente"
                  onChange={(e) => {
                    setSendEmail(e.target.checked);
                  }}
                />
              </FormGroup>
            </Margin>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Btn label="Cancelar" bg-color="tertiary" onClick={cancelButton} />
        <Btn label="Salvar" onClick={saveButton} />
      </DialogActions>
    </Dialog>
  );
};

export default ModalSave;
