import React, { useState } from 'react';

import { Box } from "@material-ui/core";
import Rating from '@material-ui/lab/Rating';

export function RatingRow({ title, value, setValue }) {
  const [hover, setHover] = useState(0)

  return (
    <>
      <Box style={{ textAlign: 'left', marginBottom: 8 }}>{title}</Box>

      <Rating
        style={{ justifyContent: 'center', alignItems: 'center' }}
        size="large"
        max={10}
        value={value}
        defaultValue={0}
        onChange={(event, newValue) => setValue(newValue)}
        onChangeActive={(event, newHover) => setHover(newHover)}
      />
      <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        <Box style={{ flex: 1, textAlign: 'left', fontSize: 11, color: '#828282' }} >Ruim</Box>
        <Box style={{ flex: 1 }}>
          {value || 0} estrelas
        </Box>
        <Box style={{ flex: 1, textAlign: 'right', fontSize: 11, color: '#828282' }}>Excelente</Box>
      </Box>
    </>
  )
}
