import axios from "axios";

const newPath = `${process.env.REACT_APP_NEW_PAINEL_API_URL}`;

export async function postInvite(body) {
  try {
    const response = await axios.post(
      `${newPath}/v1/email/invite-client`,
      body,
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("tokenPanel"),
          "Entity-Id": localStorage.getItem("companyIdPanel"),
          "Content-Type": "application/json",
          "Content-Language": "pt-BR",
        },
      }
    );

    return response?.data || null;
  } catch (error) {
    console.log("ERROR => postCodeRecover", error.response);
    return error?.response?.data || null;
  }
}
