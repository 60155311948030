import { Divider } from "@material-ui/core";
import React from "react";

export const InactiveERPTab = ({ setLoading, ...props }) => {
  return (
    <div style={{ marginTop: 30 }}>
      <div>
        <h3 style={{ padding: 0, margin: "0px 0px 5px 0px" }}>
          Gestão Fácil Inativo
        </h3>
        <Divider />
      </div>
      <div
        style={{
          background: "#fff",
          border: "1px solid #E0E0E0",
          borderRadius: 15,
          padding: 15,
          marginTop: 20,
        }}
      >
        Quando o status do Gestão Fácil estiver inativo, seu cliente não terá
        acesso a essa facilidade de gerenciamento até a renovação do período de
        pagamento vigente.
      </div>
    </div>
  );
};
