import * as React from "react";

function PersonIcon(props) {
  return (
    <svg
      width={24}
      height={25}
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <mask
        id="a"
        style={{
          maskType: "alpha",
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={24}
        height={25}
      >
        <path d="M0 0.0429688H24V24.0429688H0z" />
      </mask>
      <g mask="url(#a)">
        <path d="M12 12.043c-1.1 0-2.042-.392-2.825-1.175C8.392 10.085 8 9.143 8 8.043s.392-2.042 1.175-2.825C9.958 4.435 10.9 4.043 12 4.043s2.042.392 2.825 1.175C15.608 6.001 16 6.943 16 8.043s-.392 2.042-1.175 2.825c-.783.783-1.725 1.175-2.825 1.175zm-8 8v-2.8c0-.567.146-1.087.438-1.562.291-.475.679-.838 1.162-1.088a14.843 14.843 0 013.15-1.162 13.755 13.755 0 013.25-.388c1.1 0 2.183.13 3.25.388 1.067.258 2.117.645 3.15 1.162.483.25.87.613 1.163 1.088.291.475.437.995.437 1.562v2.8H4zm2-2h12v-.8a.973.973 0 00-.5-.85c-.9-.45-1.808-.787-2.725-1.012a11.6 11.6 0 00-5.55 0c-.917.224-1.825.562-2.725 1.012a.973.973 0 00-.5.85v.8zm6-8c.55 0 1.02-.196 1.412-.588.392-.391.588-.862.588-1.412 0-.55-.196-1.02-.588-1.413A1.926 1.926 0 0012 6.043c-.55 0-1.02.196-1.412.587A1.926 1.926 0 0010 8.043c0 .55.196 1.02.588 1.412.391.392.862.588 1.412.588z" />
      </g>
    </svg>
  );
}

export default PersonIcon;
