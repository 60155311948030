import axios from "axios";
import { search } from "../../BaseRepositoryComponents";

const baseUrl = `${process.env.REACT_APP_NEW_PAINEL_API_URL}/v1`;

export async function getDFes({
  pageSize,
  startDate,
  endDate,
  paginationKey,
  document,
  search = null,
}) {
  let headers = {
    Authorization: "Bearer " + localStorage.getItem("tokenPanel"),
    "Entity-Id": localStorage.getItem("companyIdPanel"),
    "Content-Type": "application/json",
    "Content-Language": "pt-BR",
  };

  if (paginationKey !== "") {
    headers.pagination_key = paginationKey;
  }

  return await axios
    .get(
      `${baseUrl}/dfe?page_size=${pageSize}&start_date=${startDate}&end_date=${endDate}&document=${document}${
        search ? `&search=${search}` : ""
      }`,
      {
        headers: headers,
      }
    )
    .then((response) => {
      return response.data.data;
    })
    .catch((err) => {
      return null;
    });
}

export const downloadXML = (id) => {
  return search({
    path: `${baseUrl}/dfe/${id}/xml`,
    method: "GET",
  });
};

export const downloadPDF = async (id) => {
  const myHeaders = new Headers();
  myHeaders.append("entity-id", localStorage.getItem("companyIdPanel"));
  myHeaders.append(
    "Authorization",
    "Bearer " + localStorage.getItem("tokenPanel")
  );

  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  return fetch(`${baseUrl}/dfe/${id}/da`, requestOptions)
    .then((response) => {
      return response.blob();
    })
    .catch((error) => {
      return console.error(error);
    });
};

export const getDFeData = (id) => {
  return search({
    path: `${baseUrl}/dfe/${id}/json`,
    method: "GET",
  });
};

export const checkXML = (id, body) => {
  return search({
    path: `${baseUrl}/dfe/${id}/send/register`,
    method: "POST",
    myBody: body,
  });
};

export const sendBillToPay = (id, accountantDocument, duplicatas) => {
  let newDups = duplicatas.map((item) => {
    return {
      dupnumber: item.nDup,
      dupdate: item.dVenc,
      dupvalue: item.vDup.replace(",", "."),
    };
  });

  const body = {
    accountant_document: accountantDocument,
    dups: newDups,
  };

  return search({
    path: `${baseUrl}/dfe/${id}/send/bill_to_pay`,
    method: "POST",
    myBody: body,
  });
};
