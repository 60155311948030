import styled, { css } from "styled-components";

export const BatteryDiv = styled.div`
  background: #fff;
  text-align: right;
  padding: 0;
  margin: 0;
  width: 100%;
  border-top-right-radius: 5px;
  z-index: 1000;
  ${(props) =>
    !props.showMessage &&
    css`
      position: fixed;
      width: 60px;
      clip-path: polygon(0 0, 100% 0, 100% 100%, 10% 100%);
    `}
`;

export const BatteryText = styled.div`
  font-size: 12px;
  padding-left: 5px;
  display: flex;
  align-items: center;
`;

export const BotContainer = styled.div`
  width: 230px;
  margin-top: 10px;
  border-radius: 5px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.16);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  cursor: pointer;
`;

export const BotActions = styled.div`
  background: #fff;
  position: fixed;
  width: 170px;
  right: 35px;
  box-shadow: inset 0px 0px 2px 1px rgba(0, 0, 0, 0.16);
  clip-path: polygon(20% 0%, 80% 0%, 100% 100%, 0% 100%);
  ${(props) => {
    let dimensionTop = 476;
    if (props.hasBatteryMessage) {
      dimensionTop += 24;
    }
    if (props.hasBoletoAlert) {
      dimensionTop += 50;
    }

    return css`
      top: ${dimensionTop}px;
    `;
  }}
`;

export const BatteryMessageContainer = styled.p`
  flex-direction: row;
  background-color: #f2f2f2;
  font-size: 12px;
  color: #333;
`;

export const BatteryMessageItalic = styled.p`
  font-style: italic;
`;
