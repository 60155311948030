import * as React from "react";

function DownloadIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      height={24}
      width={24}
      viewBox="0 0 24 24"
      xmlSpace="preserve"
      {...props}
    >
      <path d="M13 3c0-.6-.4-1-1-1s-1 .4-1 1v10.6l-2.8-2.8c-.4-.4-1-.4-1.4 0-.4.4-.4 1 0 1.4l4.4 4.4c.2.2.5.3.8.3.3 0 .5-.1.7-.3l4.5-4.5c.4-.4.4-1 0-1.4-.4-.4-1-.4-1.4 0L13 13.6V3z" />
      <path d="M21 11c.6 0 1 .4 1 1v9c0 .6-.4 1-1 1H3c-.6 0-1-.4-1-1v-9c0-.6.4-1 1-1s1 .4 1 1v8h16v-8c0-.5.4-1 1-1z" />
    </svg>
  );
}

export default DownloadIcon;
