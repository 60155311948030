import { createContext, useState } from "react";

const HomeContext = createContext();

const HomeContextProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);

  const [websiteContent, setWebsiteContent] = useState({});
  const [editEnable, setEditEnable] = useState(false);
  const [saving, setSaving] = useState(false);

  const [websiteType, setWebsiteType] = useState("qyon");
  const [ownWebsitUrl, setOwnWebsitUrl] = useState(null);

  const [landTheme, setLandTheme] = useState({
    id: 5,
    color_pallete: {
      primary: "#2CCEFD",
      secondary: "#F4F4F4",
      tertiary: "#012C67",
      white: "#FFFFFF",
      gray: "#D4D3D3",
    },
    images: {
      header:
        "https://qpanel-public.s3.sa-east-1.amazonaws.com/websiteHeaders/%232CCEFD.jpg",
    },
  });

  const [title, setTitle] = useState("");
  const [subtitle, setSubtitle] = useState(
    "Serviços com qualidade e agilidade, que garantem a rotina contábil da sua empresa"
  );
  const [aboutUsImage, setAboutUsImage] = useState("");
  const [aboutUsText, setAboutUsText] = useState(
    "Nosso escritório fornece serviços de contabilidade e gerencia de forma segura suas finanças. Somos especialistas em registrar contas, rastrear ativos e despesas, gerenciar a folha de pagamento e manter o controle de documentos fiscais. Com uma equipe de contadores experientes, revisamos os registros para determinar a posição financeira de sua empresa e quaisquer alterações necessárias para administrar a organização de maneira econômica. Conte com a gente para garantir a saúde contínua da sua empresa com uma gestão financeira de qualidade."
  );
  const [services, setServices] = useState([
    { description: "Abertura de Empresa" },
    { description: "Escrituração Contábil/Tributária" },
    { description: "Entrega das Obrigações Acessórias" },
    { description: "Emissão de Certidões Negativas de Débitos" },
    { description: "Assessoria Trabalhista" },
    { description: "Assessoria para Planejamento Tributário" },
    { description: "Assessoria para Gestão Financeira" },
    { description: "Encerramento de Empresa" },
  ]);
  const [videoUrl, setVideoUrl] = useState(
    "https://www.youtube.com/watch?v=sjjF1l6tg4Y"
  );
  const [videoText, setVideoText] = useState(
    "Seja bem-vindo ao Escritório Contábil do Futuro!"
  );

  const [street, setStreet] = useState("");
  const [number, setNumber] = useState("");
  const [neighborhood, setNeighborhood] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [cep, setCep] = useState("");

  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");

  const [facebook, setFacebook] = useState("");
  const [instagram, setInstagram] = useState("");
  const [linkedin, setLinkedin] = useState("");
  const [whatsapp, setWhatsapp] = useState("");
  const [googleMaps, setGoogleMaps] = useState("");

  return (
    <HomeContext.Provider
      value={{
        loading,
        setLoading,
        websiteContent,
        setWebsiteContent,
        editEnable,
        setEditEnable,
        saving,
        setSaving,
        websiteType,
        setWebsiteType,
        ownWebsitUrl,
        setOwnWebsitUrl,
        landTheme,
        setLandTheme,
        title,
        setTitle,
        subtitle,
        setSubtitle,
        aboutUsImage,
        setAboutUsImage,
        aboutUsText,
        setAboutUsText,
        services,
        setServices,
        videoUrl,
        setVideoUrl,
        videoText,
        setVideoText,
        street,
        setStreet,
        number,
        setNumber,
        neighborhood,
        setNeighborhood,
        city,
        setCity,
        state,
        setState,
        cep,
        setCep,
        phone,
        setPhone,
        email,
        setEmail,
        facebook,
        setFacebook,
        instagram,
        setInstagram,
        linkedin,
        setLinkedin,
        whatsapp,
        setWhatsapp,
        googleMaps,
        setGoogleMaps,
      }}
    >
      {children}
    </HomeContext.Provider>
  );
};

export { HomeContext, HomeContextProvider };
