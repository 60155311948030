import moment from "moment";
import { search } from "../BaseRepositoryComponents";

const baseUrl = process.env.REACT_APP_NEW_PAINEL_API_URL;

export async function getEventsByTimestamp(callback) {
  let date = moment().format("YYYY-MM-DD");
  let value =
    localStorage.getItem("isAccountantPanel") === "true"
      ? localStorage.getItem("documentPanel")
      : localStorage.getItem("accountantCnpjPanel")
      ? localStorage.getItem("accountantCnpjPanel")
      : localStorage.getItem("documentPanel");
  let field =
    localStorage.getItem("isAccountantPanel") === "true" ? "parent" : "cnpj";

  return search({
    path: `${baseUrl}/v1/events?${field}=${value}&start_date=${date}T00:00:00&end_date=${date}T23:59:59`,
    method: "GET",
    showMessage: false,
  })
    .then((response) => {
      if (response.success) {
        const urlParams = new URLSearchParams(window.location.search);

        if (urlParams.get("mode")) {
          for (let item of response.data.list) {
            if (item.id === "DAS_UPLOAD") {
              item.total += 6;
            } else if (item.id === "BILL_TO_PAY") {
              item.total += 23;
            } else if (item.id === "DARF_UPLOAD") {
              item.total += 8;
            } else if (item.id === "BOLETO_UPLOAD") {
              item.total += 7;
            } else if (item.id === "A1_UPLOAD") {
              item.total += 10;
            } else if (item.id === "CREATE_ACCOUNTANT_CLIENT") {
              item.total += 8;
            } else if (item.id === "NOT_IDENTIFIED_UPLOAD") {
              item.total += 15;
            } else if (item.id === "CREATE_USER") {
              item.total += 8;
            } else if (item.id === "NFE_OUTPUT_DISCOVERY") {
              item.total += 3032;
            } else if (item.id === "NFE_INPUT_DISCOVERY") {
              item.total += 350;
            } else if (item.id === "CTE_OUTPUT_DISCOVERY") {
              item.total += 450;
            } else if (item.id === "CTE_INPUT_DISCOVERY") {
              item.total += 2758;
            } else {
              item.total += 1000;
            }
          }
        }

        callback(response.data.list);
      } else {
        callback([]);
      }
    })
    .catch((err) => {
      console.log(err);
      callback([]);
    });
}
