import {
  DialogActions,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Zoom,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import {
  CheckCircleOutlineRounded as CheckIcon,
  FileCopyOutlined as CopyIcon,
  ErrorOutlineRounded as ErrorIcon,
  WarningOutlined as WarningIcon,
} from "@material-ui/icons";
import { MD5 } from "crypto-js";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import Swal from "sweetalert2";
import AccordionCard from "../../components/accordions/AccordionCard";
import { useAuth } from "../../contexts/crm/Auth";
import {
  ContainerIcon,
  GridStyled,
  Margin,
  Title,
} from "../../styles/GlobalStyle";
import Btn from "./../../components/buttons/Btn";
import { useChatContext } from "./../../components/chat/contexts/ChatContext";
import ChatController from "./../../components/chat/controllers/ChatController";
import TabsCustom from "./components/TabsCustom";
import TextFieldCustom from "./components/TextFieldCustom";
import ChatSettingsController from "./controllers/ChatSettingsController";
// import { notify } from "../../utils/notify";
import "./styles/ChatSettings.css";

export default function ChatSettings() {
  const { push } = useHistory();
  const { chat, settings, setSettings } = useChatContext();
  const { company } = useAuth();
  const { getRoomStatus } = ChatController();
  const { handleSave } = ChatSettingsController();

  const [initialMessageState, setInitialMessageState] = useState("");
  const [tokenToIntegrationMeta, setTokenToIntegrationMeta] = useState("");
  const [initialMessageIntegrationState, setInitialMessageIntegrationState] =
    useState("");
  const [roomOffMessageState, setRoomOffMessageState] = useState("");
  const [messageRoomInactivityState, setMessageRoomInactivityState] =
    useState("");
  const [messagemRoomDowntimeState, setMessagemRoomDowntimeState] =
    useState("");
  // eslint-disable-next-line no-unused-vars
  const [valueParams, setValueParams] = useState(0);
  // eslint-disable-next-line no-unused-vars
  const [valueIntegrations, setValueIntegrations] = useState(0);

  const DarkTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: theme.palette.common.black,
      color: "#fff",
      boxshadow: theme.shadows[2],
      placement: "right",
      fontSize: 13,
    },
  }))(Tooltip);

  const TooltipContainer = ({ title, children }) => (
    <DarkTooltip
      title={title}
      placement="right"
      TransitionComponent={Zoom}
      TransitionProps={{ timeout: 100 }}
    >
      {children}
    </DarkTooltip>
  );
  const validateIntegrationData = ({
    integrationFlag,
    tokenValue,
    whatsappAccountIdValue,
    whatsappNumberIdValue,
  }) => {
    if (
      !integrationFlag &&
      (!tokenValue || !whatsappAccountIdValue || !whatsappNumberIdValue)
    )
      return "whitout-api-and-data";
    if (
      !integrationFlag &&
      (tokenValue || whatsappAccountIdValue || whatsappNumberIdValue)
    )
      return "whitout-api";
    if (
      integrationFlag &&
      (!tokenValue || !whatsappAccountIdValue || !whatsappNumberIdValue)
    )
      return "missing-values";

    if (
      integrationFlag &&
      tokenValue &&
      whatsappAccountIdValue &&
      whatsappNumberIdValue
    )
      return "successfully";
  };

  const statusIntegration = validateIntegrationData({
    integrationFlag: chat?.whatsappFlagIntegration,
    tokenValue: chat?.tokenWhats,
    whatsappAccountIdValue: chat?.whatsappAccountId,
    whatsappNumberIdValue: chat?.integrationWppNumberId,
  });

  const enumStatusIntegration = Object.freeze({
    "whitout-api-and-data": {
      colorIcon: "red",
      colorFont: "#FA8072",
      showIcon: <ErrorIcon />,
      label: "Sem integração com o WhatsApp",
      info: "Sem confirmação da API e sem dados essenciais para a integração",
    },
    "whitout-api": {
      colorIcon: "orange",
      colorFont: "orange",
      showIcon: <WarningIcon />,
      label: "Integração pendente",
      info: "Sem confirmação da API",
    },
    "missing-values": {
      colorIcon: "orange",
      colorFont: "orange",
      showIcon: <WarningIcon />,
      label: "Integração pendente",
      info: "Sem dados essenciais para integração",
    },
    successfully: {
      colorIcon: "green",
      colorFont: "#7FC8A9",
      showIcon: <CheckIcon />,
      label: "Integração concluída",
      info: "Confirmação da API e dados essenciais",
    },
  });

  const GridTextField = ({ label, xs, css, value, id, fullsize = false }) => {
    const [valueState, setValueState] = useState(value || "");
    const handleChange = (e) => {
      setValueState(e.target.value);
    };
    return (
      <Grid xs={xs} style={css}>
        <TextField
          label={label}
          id={id}
          onChange={handleChange}
          style={{ width: fullsize ? "100%" : "90%", marginBottom: 15 }}
          value={valueState}
        />
      </Grid>
    );
  };

  const getValueElement = (elementId) => {
    let getElement = document.getElementById(elementId);
    const elementValue =
      (getElement?.value || "").trim() || getElement?.checked !== undefined
        ? (getElement?.value || "").trim() || getElement?.checked
        : "";
    return elementValue;
  };

  const validationFields = async () => {
    await handleSave({
      value: {
        initialMessage: initialMessageState || "",
        initialMessageIntegration: initialMessageIntegrationState || "",
        roomOffMessage: roomOffMessageState || "",
        messageRoomInactivity: messageRoomInactivityState || "",
        messagemRoomDowntime: messagemRoomDowntimeState || "",
        roomName: getValueElement("roomName"),
        roomDescription: getValueElement("roomDescription"),
        statusRoom: getValueElement("statusRoom"),
        roomAttendanceAmount: getValueElement("roomAttendanceAmount"),
        productParam: getValueElement("productParam"),
        alertTimeInactivity: getValueElement("alertTimeInactivity"),
        alertNewCustomer: getValueElement("alertNewCustomer"),
        downtime: getValueElement("downtime"),
        tokenWhats: getValueElement("tokenWhats"),
        integrationWppNumberId: getValueElement("integrationWppNumberId"),
        whatsappAccountId: getValueElement("whatsappAccountId"),
      },
    });
  };

  const GridLegend = ({ children, legend }) => {
    return (
      <Grid>
        <fieldset>
          <legend>{legend}</legend>
          {children}
        </fieldset>
      </Grid>
    );
  };

  const GridLabelSelect = ({ label, xs, id, array, css, value = 1 }) => {
    const [selectedValue, setSelectedValue] = useState(value);
    const handleChangeSelect = (e) => {
      setSelectedValue(e.target.value);
    };
    return (
      <Grid xs={xs} style={css}>
        <FormControl style={{ width: "90%" }}>
          <InputLabel id="switchLabel">{label}</InputLabel>
          <Select
            fullWidth
            inputProps={{
              id: id,
            }}
            labelId="switchLabel"
            id="switchInput"
            value={selectedValue}
            onChange={handleChangeSelect}
            label={label}
            disabled={array ? false : true}
          >
            {array ? (
              array.map((value) => (
                <MenuItem value={value.value}>{value.description}</MenuItem>
              ))
            ) : (
              <MenuItem value={1}>Dados não encontrados!</MenuItem>
            )}
          </Select>
        </FormControl>
      </Grid>
    );
  };

  const styles = {
    GridStyle: {
      marginBottom: "2%",
    },
  };

  const statusRoom = [
    {
      description: "Online",
      value: "0",
    },
    {
      description: "Offline",
      value: "1",
    },
  ];

  const messagesTabs = [
    {
      label: "Mensagem Inicial",
      textInfo:
        "Aqui irá ficar as informações adicionais sobre a Tab especifica",
      content: (
        <Grid xs={12}>
          <Grid xs={12} style={{ textAlign: "left", marginBottom: "2%" }}>
            <span style={{ color: "#8995a6", fontSize: "17px" }}>
              Configure a mensagem inicial do Chat e do WhatsApp que será
              enviada quando o cliente entrar na conversa!
            </span>
          </Grid>
          <Grid xs={12} style={{ marginBottom: "2%" }}>
            <GridLegend legend="Variáveis">
              <Grid xs={12} style={{ fontSize: "14px" }}>
                <span style={{ color: "#0a73db" }}>@SAUDACAO</span>: Enviar
                mensagem de Bom dia para o Cliente. Exemplo: Bom dia Ricardo!
              </Grid>
            </GridLegend>
          </Grid>
          <TextFieldCustom
            xs={12}
            label="Chat:"
            value={initialMessageState}
            setValue={setInitialMessageState}
          />
          <TextFieldCustom
            xs={12}
            label="WhatsApp:"
            value={initialMessageIntegrationState}
            setValue={setInitialMessageIntegrationState}
          />
        </Grid>
      ),
      value: 0,
    },
    {
      label: "Sala indisponível",
      textInfo:
        "Aqui irá ficar as informações adicionais sobre a Tab especifica",
      content: (
        <Grid xs={12}>
          <Grid xs={12} style={{ textAlign: "left", marginBottom: "2%" }}>
            <span style={{ color: "#8995a6", fontSize: "17px" }}>
              Configure a mensagem que será exibida ao Cliente quando a sala
              estiver indisponível!
            </span>
          </Grid>
          <Grid xs={12} style={{ marginBottom: "2%" }}>
            <GridLegend legend="Variáveis">
              <Grid xs={12} style={{ fontSize: "14px" }}>
                <span style={{ color: "#0a73db" }}>@HORARIOEXPEDIENTE</span>:
                Enviar mensagem de expediente configurado. Exemplo: de Segunda á
                Sexta das 8:00hrs até as 18:00hrs
              </Grid>
            </GridLegend>
          </Grid>
          <TextFieldCustom
            xs={12}
            value={roomOffMessageState}
            setValue={setRoomOffMessageState}
          />
        </Grid>
      ),
      value: 1,
    },
    {
      label: "Inatividade",
      textInfo:
        "Aqui irá ficar as informações adicionais sobre a Tab especifica",
      content: (
        <Grid xs={12}>
          <Grid xs={12} style={{ textAlign: "left", marginBottom: "2%" }}>
            <span style={{ color: "#8995a6", fontSize: "17px" }}>
              Configure a mensagem que será exibida quando o Cliente não enviar
              nenhuma mensagem no intervalo informado!
            </span>
          </Grid>
          <Grid xs={12} style={{ marginBottom: "2%" }}>
            <GridLegend legend="Variáveis">
              <Grid xs={12} style={{ fontSize: "14px" }}>
                <span style={{ color: "#0a73db" }}>
                  @TEMPO_ENCERRAMENTO_INATIVIDADE
                </span>
                : Exibir tempo de encerramento por inatividade
              </Grid>
              <Grid xs={12} style={{ fontSize: "14px" }}>
                <span style={{ color: "#0a73db" }}>
                  @TEMPO_AVISO_INATIVIDADE
                </span>
                : Exibir tempo de aviso por inatividade
              </Grid>
              <Grid xs={12} style={{ fontSize: "14px" }}>
                <span style={{ color: "#0a73db" }}>
                  @DIFF_AVISO_ENCERRAMENTO
                </span>
                : Exibir tempo da diferença entre o encerramento e aviso por
                inatividade
              </Grid>
            </GridLegend>
          </Grid>
          <TextFieldCustom
            xs={12}
            label="Aviso:"
            value={messageRoomInactivityState}
            setValue={setMessageRoomInactivityState}
          />
          <TextFieldCustom
            xs={12}
            label="Encerramento:"
            value={messagemRoomDowntimeState}
            setValue={setMessagemRoomDowntimeState}
          />
        </Grid>
      ),
      value: 2,
    },
  ];

  const screensIntegrations = [
    {
      label: "WhatsApp (Meta)",
      textInfo: "WhatsApp (Meta)",
      content: (
        <GridStyled container xs={12}>
          <GridStyled
            container
            xs={12}
            spacing={1}
            style={{ textAlign: "left", marginBottom: "2%" }}
          >
            <GridStyled
              item
              xs={6}
              style={{ textAlign: "left", marginBottom: "2%" }}
            >
              <span style={{ color: "#8995a6", fontSize: "15px" }}>
                Informe os dados previamente configurados na conta da Meta
                (Facebook).
              </span>
            </GridStyled>
            <GridStyled
              container
              item
              xs={6}
              style={{
                textAlign: "rigth",
                alignItems: "rigth",
              }}
            >
              <GridStyled item xs={1} style={{ textAlign: "rigth" }}>
                <ContainerIcon
                  iconColor={
                    enumStatusIntegration[statusIntegration]?.colorIcon
                  }
                >
                  {enumStatusIntegration[statusIntegration]?.showIcon}
                </ContainerIcon>
              </GridStyled>
              <GridStyled
                item
                xs={6}
                style={{
                  textAlign: "rigth",
                  width: "100%",
                }}
              >
                <TooltipContainer
                  title={enumStatusIntegration[statusIntegration]?.info}
                >
                  <span
                    style={{
                      color:
                        enumStatusIntegration[statusIntegration]?.colorFont,
                      fontSize: "15px",
                    }}
                  >
                    {enumStatusIntegration[statusIntegration]?.label}
                  </span>
                </TooltipContainer>
              </GridStyled>
            </GridStyled>
            {company?.cnpj && (
              <GridStyled item xs={12}>
                <GridLegend legend="Verificação do token">
                  <Grid container xs={12}>
                    <Grid item xs={11}>
                      {tokenToIntegrationMeta}
                    </Grid>
                    <DarkTooltip title="Copiar o token para a área de transferência">
                      <GridStyled
                        item
                        xs={1}
                        style={{ justifyContent: "end", display: "flex" }}
                      >
                        <ContainerIcon
                          onClick={() => {
                            navigator.clipboard.writeText(
                              tokenToIntegrationMeta
                            );
                            Swal.fire({
                              icon: "success",
                              text: "Token copiado com sucesso!",
                              showConfirmButton: false,
                              timer: 2500,
                            });
                          }}
                          onMouseOver={(e) =>
                            (e.target.style.color = "#006FDE")
                          }
                          onMouseOut={(e) => (e.target.style.color = "#aaa")}
                        >
                          <CopyIcon style={{ color: "#aaa" }} />
                        </ContainerIcon>
                      </GridStyled>
                    </DarkTooltip>
                  </Grid>
                </GridLegend>
              </GridStyled>
            )}
          </GridStyled>
          <GridTextField
            label="Informe o token permanente do WhatsApp"
            xs={12}
            fullsize={true}
            value={chat.tokenWhats}
            id="tokenWhats"
          />
          <GridTextField
            label="Informe o Id do seu número WhatsApp"
            xs={6}
            value={chat.integrationWppNumberId}
            id="integrationWppNumberId"
          />
          <GridTextField
            label="Informe o Id da sua conta WhatsApp"
            xs={6}
            fullsize={true}
            value={chat.whatsappAccountId}
            id="whatsappAccountId"
          />
        </GridStyled>
      ),
      value: 0,
    },
  ];

  const amoutAttendance = [
    {
      value: "0",
      description: "Cair direto pra fila",
    },
    {
      value: "1",
      description: "1 Cliente simultâneo",
    },
    {
      value: "2",
      description: "2 Clientes simultâneos",
    },
    {
      value: "3",
      description: "3 Clientes simultâneos",
    },
    {
      value: "4",
      description: "4 Clientes simultâneos",
    },
    {
      value: "5",
      description: "5 Clientes simultâneos",
    },
  ];

  useEffect(() => {
    (async () => {
      const data = await getRoomStatus();
      if (data) {
        setTokenToIntegrationMeta(MD5(company?.cnpj).toString());
        setSettings({ ...settings });
        setInitialMessageState(chat.initialMessage);
        setInitialMessageIntegrationState(chat.initialMessageIntegration);
        setRoomOffMessageState(chat.roomOffMessage);
        setMessageRoomInactivityState(chat.messageRoomInactivity);
        setMessagemRoomDowntimeState(chat.messagemRoomDowntime);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    company,
    chat.initialMessage,
    chat.initialMessageIntegration,
    chat.roomOffMessage,
    chat.messageRoomInactivity,
    chat.messagemRoomDowntime,
  ]);

  return (
    <>
      <Grid container justifyContent="space-between">
        <Grid item xs={12} container alignItems="center">
          <Grid item flex={1}>
            <Title color="blue">Configurações do Chat</Title>
          </Grid>
          <Margin top="20" />
          <Grid xs={12}>
            <AccordionCard title="Geral" defaultExpanded={true}>
              <Grid container xs={12} style={{ padding: "1%" }}>
                <GridTextField
                  xs={6}
                  label="Nome da Sala"
                  css={styles.GridStyle}
                  value={chat.roomName}
                  id="roomName"
                />
                <GridTextField
                  xs={6}
                  label="Descrição"
                  css={styles.GridStyle}
                  value={chat.roomDescription}
                  id="roomDescription"
                />
                <GridLabelSelect
                  xs={6}
                  label="Status da Sala"
                  array={statusRoom}
                  value={settings.roomState}
                  id="statusRoom"
                />
                <GridLabelSelect
                  xs={6}
                  label="Quantidade de Clientes simultâneos por Atendente"
                  array={amoutAttendance}
                  value={chat.roomAttendanceAmount}
                  id="roomAttendanceAmount"
                />
              </Grid>
            </AccordionCard>
          </Grid>
          <Grid xs={12}>
            <AccordionCard title="Parâmetros">
              <Grid container xs={12} style={{ padding: "1%" }}>
                <GridTextField
                  xs={6}
                  label="Tempo de aviso por inatividade do Cliente(minutos)"
                  value={chat.alertTimeInactivity}
                  id="alertTimeInactivity"
                />
                <GridTextField
                  xs={6}
                  label="Quantidade de dias para considerar como cliente novo"
                  value={chat.alertNewCustomer}
                  id="alertNewCustomer"
                />
                <GridTextField
                  xs={6}
                  label="Tempo de encerramento por inatividade do Cliente(minutos)"
                  value={chat.downtime}
                  id="downtime"
                />
              </Grid>
            </AccordionCard>
          </Grid>
          <Grid xs={12}>
            <AccordionCard title="Mensagens">
              <Grid container xs={12} style={{ padding: "1%" }}>
                <TabsCustom items={messagesTabs} setPage={setValueParams} />
              </Grid>
            </AccordionCard>
          </Grid>

          {company?.sgbd?.includes("postgres") && (
            <Grid xs={12}>
              <AccordionCard title="Integrações">
                <Grid container xs={12} style={{ padding: "1%" }}>
                  <TabsCustom
                    items={screensIntegrations}
                    setPage={setValueIntegrations}
                  />
                </Grid>
              </AccordionCard>
            </Grid>
          )}
        </Grid>
      </Grid>
      <DialogActions>
        <Btn
          label="Cancelar"
          bg-color="tertiary"
          onClick={() => push("/setting")}
        />
        <Btn label="Salvar" bg-color="blue" onClick={validationFields} />
      </DialogActions>
    </>
  );
}
