import { Chip, FormControlLabel, Grid, Radio } from "@material-ui/core";

import React, { useContext } from "react";
import { ConfigureERPContext } from "../contexts/ConfigureERPContext";

export const ERPPlanRadioButton = ({
  planAmount,
  planName,
  selectValue,
  value,
  mostPopular = false,
  tagDescription,
  disabled,
  ...props
}) => {
  const { selectedPlan } = useContext(ConfigureERPContext);
  const formattedAmount = new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
  }).format(planAmount);

  return (
    <Grid
      xs={4}
      item
      onClick={(e) => {
        if (disabled) return;
        selectValue(value);
      }}
      {...props}
      container
    >
      <Chip
        size="small"
        style={{
          fontSize: 10,
          fontWeight: "bold",
          marginLeft: 8,
          marginBottom: 8,
        }}
        label={mostPopular ? "Mais Popular" : tagDescription}
        color={mostPopular ? "primary" : "default"}
      />

      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        style={{
          padding: mostPopular && selectedPlan === value ? 6 : 8,
          borderRadius: 10,
          border:
            mostPopular && selectedPlan === value
              ? "2px solid #3F51B5"
              : "1px solid #E0E0E0",
          background: "#FBFBFD",
          cursor: disabled ? "not-allowed" : "pointer",
          height: "100%",
        }}
      >
        <Grid item xs={8}>
          <FormControlLabel
            disabled={disabled}
            control={<Radio color="primary" value={value} />}
            label={
              <>
                <b>{planName}</b>
              </>
            }
          />
        </Grid>

        <Grid
          item
          xs={4}
          container
          justifyContent="center"
          alignItems="flex-end"
          direction="column"
          style={{ color: disabled ? "gray" : "black" }}
        >
          <b>{formattedAmount ?? "Grátis"}</b>
          <div>por / mês</div>
        </Grid>
      </Grid>
    </Grid>
  );
};
