export const getProducts = () => {
  const productsEnum = [
    {
      product: "QYON_DASH",
      name: "Painel",
    },
    {
      product: "QYON_XML",
      name: "XML",
    },
    {
      product: "QYON_ERP",
      name: "ERP",
    },
    {
      product: "QYON_ERP_ADM",
      name: "ERP Administrator",
    },
    {
      product: "QYON_ACCOUNTANT",
      name: "Contábil",
    },
    {
      product: "QYON_CRM",
      name: "CRM",
    },
    {
      product: "QYON_BANK",
      name: "QYON Bank",
    },
  ];

  return productsEnum;
};

export const getProductDescription = (product) => {
  let products = getProducts();

  return products.filter((e) => e.product === product)[0].name;
};

export const getActionDescription = (actionEvent) => {
  try {
    let { action } = actionEvent;
    let message = "Ação a ser realizada";
    switch (action) {
      case "ESOCIAL_UPLOAD_SET_COMPLETE_MESSAGE":
        message = "Migração do e-social finalizada";
        break;
      case "A1_UPLOAD":
        message = "Upload certificado A1";
        break;
      case "BILL_TO_PAY":
        if (actionEvent.extra_info) {
          if (actionEvent.extra_info === "input") {
            message = "Posso enviar para o contas a pagar?";
            break;
          }

          if (actionEvent.extra_info === "output") {
            message = "O que deseja fazer com esse XML?";
            break;
          }
        }

        message = "Posso enviar para o contas a pagar?";
        break;
      case "NFE_INPUT_DISCOVERY":
        message = "Nota fiscal fora do padrão";
        break;
      default:
        break;
    }

    return message;
  } catch (err) {
    return "Ação a ser realizada";
  }
};
