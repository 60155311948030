import { Divider, Grid, TextField, Typography } from "@material-ui/core";
import {
  BodyStyle,
  ButtonCancel,
  ButtonSave,
  CircleIcon,
  CloseIconStyle,
  DialogHeader,
  DialogStyle,
} from "../../../components/modal/Modal";
import MaskService from "../../../utils/mask/maskService";
import SubdirectoryArrowLeftIcon from "@material-ui/icons/SubdirectoryArrowLeft";
import { CompanyCard } from "./styles/CompanyModal";

export const ModalUpdateCertificate = (props) => {
  return (
    <DialogStyle>
      <DialogHeader>
        <div className="row">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginBottom: 20,
              marginRight: 20,
            }}
          >
            {props.getSecondTitle()}
          </div>

          <CloseIconStyle
            onClick={() => {
              props.closeCertificateModal();
            }}
          />
        </div>
      </DialogHeader>

      <div
        style={{
          maxHeight: 400,
          overflowY: "auto",
          overflowX: "hidden",
          paddingRight: 24,
          paddingLeft: 24,
        }}
      >
        {props.certificateListState.map((certificate, index) => {
          return (
            <Grid key={"CERTIFICATE" + index}>
              {index >= 1 && <br />}
              <CompanyCard>
                <Grid container>
                  <Grid
                    item
                    xs={2}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <CircleIcon>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="30"
                        fill="none"
                        viewBox="0 0 24 30"
                      >
                        <path
                          fill="#0036B3"
                          fillRule="evenodd"
                          d="M1 2.5a1 1 0 0 1 1-1h4a1 1 0 0 1 1 1v6.382l6.553-3.276A1 1 0 0 1 15 6.5v2.382l6.553-3.276A1 1 0 0 1 23 6.5v16a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1v-20Zm2 1v18h18V8.118l-6.553 3.276A1 1 0 0 1 13 10.5V8.118l-6.553 3.276A1 1 0 0 1 5 10.5v-7H3Zm2 11a1 1 0 0 1 1-1h4a1 1 0 0 1 1 1v4a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1v-4Zm2 1v2h2v-2H7Zm6-1a1 1 0 0 1 1-1h4a1 1 0 0 1 1 1v4a1 1 0 0 1-1 1h-4a1 1 0 0 1-1-1v-4Zm2 1v2h2v-2h-2Z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </CircleIcon>
                  </Grid>

                  <Grid item xs={10}>
                    <Typography
                      id="modal-modal-title"
                      style={{ fontWeight: 700, fontSize: 18 }}
                    >
                      {certificate.company.companyName}
                    </Typography>

                    <Typography id="modal-modal-title" variant="body2">
                      {certificate.action === "procuration" ? (
                        <b>
                          Certificado de Procuração
                          <br />
                        </b>
                      ) : (
                        ""
                      )}

                      {certificate.company.clientCnpj.length === 14 && (
                        <>
                          CNPJ:{" "}
                          {MaskService.toMask(
                            "cnpj",
                            certificate.company.clientCnpj
                          )}
                        </>
                      )}

                      {certificate.company.clientCnpj.length === 11 && (
                        <>
                          CPF:{" "}
                          {MaskService.toMask(
                            "cpf",
                            certificate.company.clientCnpj
                          )}
                        </>
                      )}
                    </Typography>

                    <Typography id="modal-modal-title" variant="body2">
                      {certificate.name}
                      <br></br>
                    </Typography>

                    {certificate.plug.success === false &&
                      certificate.action !== "procuration" && (
                        <TextField
                          label="Informe o e-mail para acesso à Plataforma do seu cliente"
                          variant="outlined"
                          key={index}
                          style={{
                            marginBottom: 20,
                            marginTop: 10,
                            width: "90%",
                          }}
                          defaultValue={""}
                          onChange={(ev) => {
                            props.certificateListState[index].company.email =
                              ev.target.value;
                          }}
                        />
                      )}
                  </Grid>
                </Grid>
              </CompanyCard>
            </Grid>
          );
        })}
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "end",
          marginTop: 20,
          marginRight: 20,
          marginBottom: 20,
        }}
      >
        {props.disabled === false && (
          <>
            <ButtonCancel
              style={{ marginRight: 16 }}
              onClick={() => {
                props.closeCertificateModal();
              }}
              startIcon={<SubdirectoryArrowLeftIcon />}
            >
              Cancelar
            </ButtonCancel>
            &nbsp;
          </>
        )}
        <ButtonSave
          onClick={async () => {
            props.saveUpdateCertificate();
          }}
          disabled={props.disabled}
        >
          {props.disabled ? "Carregando..." : "Atualizar o Certificado"}
        </ButtonSave>
      </div>
    </DialogStyle>
  );
};
