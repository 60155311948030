import { useContext, useEffect, useState } from "react";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  FormControlLabel,
  Typography,
  Checkbox,
} from "@material-ui/core";

import SelectErp from "../../../components/basics/SelectErp";
import TextFieldErp from "../../../components/basics/TextFieldErp";
import Btn from "../../../components/buttons/Btn";
import { GlobalVariables } from "../../../GlobalVariables";
import { getMaps } from "../../../repositories/panel/v1/integrations/PlugRepository";
import { getAccountantSystemVersions } from "../../../repositories/panel/v1/SystemsRepository";
import { Title } from "../../../styles/GlobalStyle";
import { CustumerManagementContext } from "../contexts/CustomerManagementContext";
import { ModalConfigAccessContext } from "../contexts/ModalConfigAccessContext";
import useModalConfigAccessController from "../controllers/ModalConfigAccessController";
import { GlobalContext } from "../../../contexts/GlobalContext";

export default function ModalConfigAccess({ data }) {
  const {
    versionConfig,
    setVersionConfig,
    emailConfig,
    setEmailConfig,
    setAccessNameConfig,

    payer,
    payerOptions,

    listVersions,
    setListVersions,
    errors,
  } = useContext(ModalConfigAccessContext);

  const {
    handleSubmitConfig,
    handleChangeAccessName,
    handleSelectPayerOption,
    openCompanyProposal,
  } = useModalConfigAccessController();

  const {
    setConfigAccessModalOpen,
    configAccessModalOpen,
    loadingInputs,
    setLoadingInputs,
  } = useContext(CustumerManagementContext);

  const { companyUser } = useContext(GlobalContext);

  const [confirmReading, setConfirmReading] = useState(false);

  useEffect(() => {
    setVersionConfig(null);

    const systemErp =
      (data.entity_systems || []).find((system) =>
        GlobalVariables.ID_PRODUCT_VERSIONS_ERP_PANEL.includes(
          system.system_version_id
        )
      ) || null;

    if (!systemErp) return;

    setVersionConfig(
      listVersions.find(
        (version) => version.id === systemErp?.system_version_id
      ) || null
    );
  }, [data, listVersions, setVersionConfig]);

  useEffect(() => {
    if (!data?.configured) {
      handleChangeAccessName({ target: { value: data?.trading_name || "" } });
      setEmailConfig(data?.master_email || "");
    }
  }, [data, handleChangeAccessName, setEmailConfig]);

  useEffect(() => {
    (async () => {
      try {
        setLoadingInputs((current) => ({ ...current, listVersions: true }));
        const respVersions = await getAccountantSystemVersions({
          id: GlobalVariables.ID_PRODUCT_ERP_PANEL,
        });

        if (respVersions?.success && Array.isArray(respVersions?.data)) {
          setListVersions(respVersions.data);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoadingInputs((current) => ({ ...current, listVersions: false }));
      }
    })();
  }, [configAccessModalOpen, setListVersions, setLoadingInputs]);

  useEffect(() => {
    if (!data?.configured || !versionConfig?.id) return;

    setAccessNameConfig("");
    (async () => {
      try {
        setLoadingInputs((current) => ({
          ...current,
          accessNameConfig: true,
        }));

        const respMap = await getMaps({
          params: `?IdVersao=${versionConfig?.id}&ClienteCNPJ=${data?.document}&columns=Rota`,
        });

        if (respMap?.success) {
          setAccessNameConfig(respMap?.data?.[0]?.Rota || "");
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoadingInputs((current) => ({
          ...current,
          accessNameConfig: false,
        }));
      }
    })();
  }, [
    data,
    configAccessModalOpen,
    setAccessNameConfig,
    setLoadingInputs,
    versionConfig?.id,
  ]);

  return (
    <Dialog
      open={configAccessModalOpen}
      maxWidth="sm"
      fullWidth
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      onClose={() => setConfigAccessModalOpen(false)}
    >
      <DialogTitle id="alert-dialog-title">
        <Title subtitle>Configuração de Acesso</Title>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <SelectErp
              disabled={data?.configured}
              error={errors.versionConfig ? true : false}
              helperText={errors.versionConfig || ""}
              loading={loadingInputs.listVersions || false}
              label="Versão Gestão Fácil (ERP)*"
              value={versionConfig}
              setValue={setVersionConfig}
              getOptionLabel={(option) =>
                option.IdVersao === 27
                  ? `${option.DescricaoVersao} (Trial) `
                  : `${option.DescricaoVersao} - R$ ${option.Preco}`
              }
              optionsList={listVersions}
              descriptionField="version"
            />
          </Grid>

          {!data?.configured && (
            <Grid item xs={12}>
              <TextFieldErp
                error={errors.emailConfig ? true : false}
                helperText={errors.emailConfig || ""}
                value={emailConfig}
                setValue={setEmailConfig}
                label="Email do cliente para acesso*"
                maxLength={50}
              />
            </Grid>
          )}
          {versionConfig?.id !== 27 && (
            <Grid item xs={12}>
              <SelectErp
                label="Responsável pelo contrato Qyon"
                //Campo desabilitado com o valor padrão "Em meu nome" conforme definido na task QEA-997
                disabled={true} //Regra original {data?.configured}
                value={payer}
                setValue={handleSelectPayerOption}
                getOptionLabel={(option) => option.description}
                optionsList={payerOptions}
                descriptionField="payer"
                error={errors.payer}
                helperText={payer?.hint || errors.payer}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <FormControlLabel
              label={
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <Typography variant="body2">
                    Concordo que estou de acordo com a inclusão do aditivo
                    contratual referente a liberação do produto selecionado em
                    meu{" "}
                    <button
                      style={{
                        color: "blue",
                        textDecoration: "underline",
                        cursor: "pointer",
                        padding: 0,
                        border: 0,
                        background: "transparent",
                      }}
                      onClick={() => {
                        openCompanyProposal(companyUser.document);
                      }}
                    >
                      <Typography variant="body2">contrato</Typography>
                    </button>{" "}
                    atual, o que pode implicar em alterações de preços e/ou
                    serviços.
                  </Typography>
                </div>
              }
              control={
                <Checkbox
                  color="primary"
                  checked={confirmReading}
                  onChange={(ev) => {
                    setConfirmReading(ev.target.checked);
                  }}
                />
              }
            />
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <Btn
          label={data?.configured ? "Fechar" : "Cancelar"}
          bg-color="tertiary"
          onClick={() => setConfigAccessModalOpen(false)}
        />
        {!data?.configured && (
          <Btn
            disabled={!confirmReading}
            bg-color={confirmReading ? "blue" : "gray"}
            label="Salvar"
            onClick={() =>
              handleSubmitConfig(
                data?.document,
                data?.id,
                versionConfig?.IdVersao,
                versionConfig?.IdServico,
                payer?.value
              )
            }
          />
        )}
      </DialogActions>
    </Dialog>
  );
}
