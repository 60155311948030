import { convertToTimeLocale } from "../utils";
import { api } from "./api";

export const fetchConversationMessages = async (
  { company, webSocketClient, sessionId },
  callback
) => {
  if (!sessionId) return null;
  return await api
    .get(
      `${
        webSocketClient.urlWebSocketClient || localStorage.getItem("URL_WS")
      }/messages/${sessionId}`,
      {
        headers: {
          company_code: company.id,
          customerSocketClientId: webSocketClient.socket.id,
        },
      }
    )
    .then((response) => {
      if (response.status !== 200) return null;
      const { messages } = response.data;
      const messagesArray = messages.map((message) => {
        if (message.session_id) {
          let timeLocale = convertToTimeLocale(message.time);
          return {
            id: message.message_id,
            sessionId: message.session_id,
            text: message.message
              ? message.message
              : message.messageFile
              ? message.messageFile
              : "",
            time: timeLocale,
            typeUser: message.attendant_code ? "attendant" : "customer",
            username: message.username,
          };
        } else return [];
      });

      if (callback) callback({ messages: messagesArray });
    })
    .catch((error) => {
      console.error(error);
      return null;
    });
};
