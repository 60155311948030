import { Grid } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import React from "react";
import { Margin } from "../../../styles/GlobalStyle";

const SkeletonCards = () => {
  return (
    <Margin top="12" bottom="18">
      <Grid>
        <Skeleton variant="rect" animation="wave" width="100%">
          <div style={{ paddingTop: "350px" }} />
        </Skeleton>
      </Grid>
    </Margin>
  );
};

export default SkeletonCards;
