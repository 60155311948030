import { search } from "../../../../BaseRepositoryComponents";

const path = `${process.env.REACT_APP_PAINEL_API_URL}/api/v1/integrations/rpa/agenda`;
const newPath = `${process.env.REACT_APP_CND_API_URL}/v1/entity/integrations/rpa`;

export async function get({ id }) {
  return search({
    path: `${path}${id ? `/${id}` : ""}`,
  });
}

export async function post({ body }) {
  return search({
    method: "POST",
    path: `${path}`,
    myBody: body,
  });
}

export async function postNew({ body }) {
  return search({
    method: "POST",
    path: `${newPath}/agenda`,
    myBody: body,
  });
}

export async function put({ body, id }) {
  return search({
    method: "PUT",
    path: `${newPath}/agenda/${id}`,
    myBody: body,
  });
}

export async function destroy({ id }) {
  return search({
    method: "DELETE",
    path: `${path}/${id}`,
  });
}

export async function destroyNew({ id }) {
  return search({
    method: "DELETE",
    path: `${newPath}/agenda/${id}`,
  });
}

export async function getEntities({ id }) {
  return search({
    path: `${path}/${id}/entities`,
  });
}

export async function getEntitiesAgenda({ id }) {
  return search({
    path: `${newPath}/schedule/${id}/entities/${localStorage.getItem(
      "documentPanel"
    )}`,
  });
}

export async function linkedCND({ docEscritorio, docClient, IdAgenda, body }) {
  return search({
    method: "POST",
    path: `${newPath}/schedule/linked/${docEscritorio}/${docClient}/${IdAgenda}`,
    myBody: body,
  });
}

export async function unlinkedCND({ IDClient, IDAgenda }) {
  return search({
    method: "DELETE",
    path: `${newPath}/schedule/unlinked/${IDClient}/${IDAgenda}`,
  });
}

export async function syncEntities({ body, id }) {
  return search({
    method: "PUT",
    path: `${path}/${id}/sync-entities`,
    myBody: body,
  });
}

export async function putEmailConfig({ body, document }) {
  return search({
    method: "PUT",
    path: `${newPath}/${document}`,
    myBody: body,
  });
}
