import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import React, { useState } from "react";
import useDocumentModalController from "../controllers/DocumentModalController";

export const ModalCheckboxes = (props) => {
  const { cleanCheckBoxesArea, hasAreaChecked } = useDocumentModalController();
  const [docType, setDocType] = useState(props.document.doc_type);
  const [contabil, setContabil] = useState(props.document.contabil);
  const [fiscal, setFiscal] = useState(props.document.fiscal);
  const [folha, setFolha] = useState(props.document.folha);
  const [outros, setOutros] = useState(props.document.outros);
  const [contratoSocial, setContratoSocial] = useState(
    props.document.contratoSocial
  );
  const [contratoTrabalho, setContratoTrabalho] = useState(
    props.document.contratoTrabalho
  );
  const [sendToPay, setSendToPay] = useState(props.document.sendToPay);
  const [openTicketInCRM, setOpenTicketInCrm] = useState(
    props.document.openTicketInCRM
  );
  const [generateTicketEmail, setGenerateTicketEmail] = useState("");

  const verifyCheckBoxes = () => {
    if (!outros && !fiscal && !contabil && !folha) {
      props.document.openTicketInCRM = false;
      setOpenTicketInCrm(false);
    }
  };

  return (
    <>
      {props.document.data.document_type === "NOT_IDENTIFIED" && (
        <div style={{ textAlign: "left" }}>
          <div id="demo-simple-select-standard-label">
            Selecione para onde o documento deve ser enviado
          </div>

          <FormControl component="fieldset">
            <RadioGroup
              color="primary"
              value={docType}
              name="radio-buttons-group"
              row
            >
              <FormControlLabel
                value="guide"
                control={<Radio color="primary" />}
                onClick={(e) => {
                  setDocType(e.target.value);
                  props.document.doc_type = e.target.value;
                  props.setTypeFileState(e.target.value);
                  cleanCheckBoxesArea(props.document);
                  setContabil(false);
                  setFiscal(false);
                  setFolha(false);
                  setOutros(false);
                  setContratoSocial(false);
                  setContratoTrabalho(false);
                }}
                label="Guias"
              />
              <FormControlLabel
                value="report"
                control={<Radio color="primary" />}
                onClick={(e) => {
                  setDocType(e.target.value);
                  props.document.doc_type = e.target.value;
                  props.setTypeFileState(e.target.value);
                  cleanCheckBoxesArea(props.document);
                  setContabil(false);
                  setFiscal(false);
                  setFolha(false);
                  setOutros(false);
                  setContratoSocial(false);
                  setContratoTrabalho(false);
                }}
                label="Relatórios"
              />
              <FormControlLabel
                value="doc"
                control={<Radio color="primary" />}
                onClick={(e) => {
                  setDocType(e.target.value);
                  props.document.doc_type = e.target.value;
                  props.setTypeFileState(e.target.value);
                  cleanCheckBoxesArea(props.document);
                  setContabil(false);
                  setFiscal(false);
                  setFolha(false);
                  setOutros(false);
                  setContratoSocial(false);
                  setContratoTrabalho(false);
                }}
                label="Documentos"
              />
            </RadioGroup>
          </FormControl>
        </div>
      )}
      <div style={{ textAlign: "left" }}>
        <div>
          <div>
            Selecione a pasta para envio{" "}
            {localStorage.getItem("isAccountantPanel") === "true"
              ? "no painel do cliente"
              : ""}
          </div>
          <Grid style={{ width: "100%" }}>
            <FormControl component="fieldset" row>
              <FormGroup row>
                {props.typeFileState !== "doc" && (
                  <FormControlLabel
                    label={<Typography variant="body2">Contábil</Typography>}
                    control={
                      <Checkbox
                        color="primary"
                        checked={contabil}
                        onChange={(ev) => {
                          props.document.contabil = ev.target.checked;
                          setContabil(ev.target.checked);

                          verifyCheckBoxes();
                        }}
                      />
                    }
                  />
                )}
                {props.typeFileState !== "doc" && (
                  <FormControlLabel
                    label={<Typography variant="body2">Fiscal</Typography>}
                    control={
                      <Checkbox
                        color="primary"
                        checked={fiscal}
                        onChange={(ev) => {
                          props.document.fiscal = ev.target.checked;
                          setFiscal(ev.target.checked);

                          verifyCheckBoxes();
                        }}
                      />
                    }
                  />
                )}
                {props.typeFileState !== "doc" && (
                  <FormControlLabel
                    label={<Typography variant="body2">Folha</Typography>}
                    control={
                      <Checkbox
                        color="primary"
                        checked={folha}
                        onChange={(ev) => {
                          props.document.folha = ev.target.checked;
                          setFolha(ev.target.checked);

                          verifyCheckBoxes();
                        }}
                      />
                    }
                  />
                )}
                {props.typeFileState !== "doc" && (
                  <FormControlLabel
                    label={<Typography variant="body2">Outros</Typography>}
                    control={
                      <Checkbox
                        color="primary"
                        checked={outros}
                        onChange={(ev) => {
                          props.document.outros = ev.target.checked;
                          setOutros(ev.target.checked);

                          verifyCheckBoxes();
                        }}
                      />
                    }
                  />
                )}
                {props.typeFileState === "doc" && (
                  <FormControlLabel
                    label={
                      <Typography variant="body2">
                        Contrato de Trabalho
                      </Typography>
                    }
                    control={
                      <Checkbox
                        color="primary"
                        checked={contratoTrabalho}
                        onChange={(ev) => {
                          props.document.contratoTrabalho = ev.target.checked;
                          setContratoTrabalho(ev.target.checked);
                        }}
                      />
                    }
                  />
                )}
                {props.typeFileState === "doc" && (
                  <FormControlLabel
                    label={
                      <Typography variant="body2">Contrato Social</Typography>
                    }
                    control={
                      <Checkbox
                        color="primary"
                        checked={contratoSocial}
                        onChange={(ev) => {
                          props.document.contratoSocial = ev.target.checked;
                          setContratoSocial(ev.target.checked);
                        }}
                      />
                    }
                  />
                )}
              </FormGroup>
            </FormControl>
          </Grid>
        </div>

        {localStorage.getItem("isAccountantPanel") === "true" &&
          props.document.data.document_type !== "NOT_IDENTIFIED" && (
            <Tooltip
              title={
                "Permite o cliente enviar posteriormente para o cadastro no contas a pagar"
              }
            >
              <>
                <FormControl>
                  <FormControlLabel
                    label="Enviar documento para o bot do cliente"
                    control={
                      <Checkbox
                        color="primary"
                        checked={sendToPay}
                        onChange={(ev) => {
                          props.document.sendToPay = ev.target.checked;
                          setSendToPay(ev.target.checked);
                        }}
                      />
                    }
                  />
                </FormControl>
              </>
            </Tooltip>
          )}
      </div>

      {localStorage.getItem("isAccountantPanel") === "true" && (
        <div style={{ textAlign: "left" }}>
          <Grid container>
            <Grid item xs={6}>
              {hasAreaChecked(props.document) && (
                <div>
                  <Checkbox
                    style={{ marginTop: 0 }}
                    color="primary"
                    key={"OPEN_TICKET_CRM_" + props.index + " - " + props.key}
                    disabled={
                      outros === false &&
                      fiscal === false &&
                      contabil === false &&
                      folha === false &&
                      contratoSocial === false &&
                      contratoTrabalho === false
                    }
                    checked={openTicketInCRM}
                    onChange={(ev) => {
                      props.document.openTicketInCRM = ev.target.checked;
                      setOpenTicketInCrm(ev.target.checked);
                    }}
                  />

                  <span
                    style={{
                      position: "relative",
                      top: 0,
                    }}
                  >
                    Abrir atendimento no Gestão Relacionamento
                  </span>
                </div>
              )}
            </Grid>
            {openTicketInCRM && hasAreaChecked(props.document) && (
              <Grid item xs={6} key={props.key}>
                <Autocomplete
                  fullWidth
                  disabled={
                    outros === false &&
                    fiscal === false &&
                    contabil === false &&
                    folha === false &&
                    contratoSocial === false &&
                    contratoTrabalho === false
                  }
                  options={props.users.map((client) => {
                    return {
                      label: client.email,
                      email: client.email,
                    };
                  })}
                  getOptionLabel={(option) => {
                    return option.label;
                  }}
                  value={generateTicketEmail}
                  onChange={(event, newValue) => {
                    setGenerateTicketEmail(newValue);
                    props.document.generateTicketEmail = newValue;
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      InputLabelProps={{ style: { marginTop: "-8px" } }}
                      label="Selecione o email para atendimento"
                    />
                  )}
                ></Autocomplete>
              </Grid>
            )}
          </Grid>
        </div>
      )}
    </>
  );
};
