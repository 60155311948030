import * as React from "react";

function InfoIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        d="M13.25 6.75a1.25 1.25 0 10-2.5 0 1.25 1.25 0 002.5 0zM10.25 10a1 1 0 011-1h1a1 1 0 011 1v6H14a1 1 0 110 2h-3.5a1 1 0 110-2h.75v-5a1 1 0 01-1-1z"
        fill="#0036B3"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 1a10.969 10.969 0 00-7.778 3.222A10.969 10.969 0 001 12c0 3.037 1.232 5.789 3.222 7.778A10.969 10.969 0 0012 23c3.037 0 5.789-1.232 7.778-3.222A10.969 10.969 0 0023 12c0-3.037-1.232-5.789-3.222-7.778A10.969 10.969 0 0012 1zM5.636 5.636A8.969 8.969 0 0112 3a8.969 8.969 0 016.364 2.636A8.969 8.969 0 0121 12a8.968 8.968 0 01-2.636 6.364A8.968 8.968 0 0112 21a8.969 8.969 0 01-6.364-2.636A8.969 8.969 0 013 12a8.969 8.969 0 012.636-6.364z"
        fill="#0036B3"
      />
    </svg>
  );
}

export default InfoIcon;
