import { useContext } from "react";
import { postRequestVisit } from "../../../repositories/panel/v1/EntityRepository";

import MaskService from "../../../utils/mask/maskService";
import { notify } from "../../../utils/notify";
import { validNotNull } from "../../../utils/validations";
import { CustumerManagementContext } from "../contexts/CustomerManagementContext";
import { ModalRequestVisitContext } from "../contexts/ModalRequestVisitContext";

export default function useModalRequestVisitController() {
  const {
    name,
    documentCustomer,
    email,
    setEmail,
    contactName,
    contactPhone,
    acceptTerms,
    setErrors,
    setLoading,
    setModalVisitOpen,
  } = useContext(ModalRequestVisitContext);
  const { customersBpo, setCustomersBpo } = useContext(
    CustumerManagementContext
  );

  const handleChangeEmail = (e) => {
    setEmail(e.target.value.toLowerCase().replace(/ /g, ""));
  };

  const handleSubmit = async (e, id, sendContactMail, enterInContact) => {
    const fieldsToValidate = [
      { value: name, key: "name" },
      { value: documentCustomer, key: "documentCustomer" },
      { value: email, key: "email" },
      { value: contactName, key: "contactName" },
      { value: contactPhone, key: "contactPhone" },
    ];

    const body = {
      email,
      date: new Date(),
      contact_name: contactName,
      contact_phone: contactPhone.replace(/[^0-9]+/g, ""),
      sendContactMail,
      enterInContact,
    };

    try {
      e.preventDefault();

      if (!id) return notify("Cliente não encontrado.");
      setLoading(true);

      const valid = validNotNull(fieldsToValidate);
      if (!acceptTerms && enterInContact) {
        valid.errorsValidation.acceptTerms = "Campo obrigatório";
        valid.error = true;
        notify("Para continuar é necessário concordar com os termos.");
      }

      if (!MaskService.isValid("cel-phone", contactPhone)) {
        valid.errorsValidation.contactPhone = "Campo inválido";
        valid.error = true;
      }

      setErrors(valid.errorsValidation);
      if (valid.error) return;

      const respMap = await postRequestVisit({
        body,
        id,
      });

      if (respMap?.success) {
        notify(
          "Sua solicitação de contato foi realizada com sucesso e o setor responsável logo entrará em contato com o seu cliente.",
          true,
          "success"
        );
        setModalVisitOpen(false);

        const copy = [...customersBpo];
        const index = copy.findIndex((item) => item.id === id);
        copy[index] = {
          ...copy[index],
          visitRequest: true,
        };

        setCustomersBpo(copy);
        return;
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return {
    handleChangeEmail,
    handleSubmit,
  };
}
