import { useContext, useEffect, useState } from "react";

/**
 * Icons
 */
import { ExpandMore, Add, Close } from "@material-ui/icons";

/**
 * Components
 */
import {
  Grid,
  Tooltip,
  Chip,
  AccordionSummary,
  AccordionDetails,
  AccordionActions,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Switch,
  Button,
} from "@material-ui/core";

import SelectErp from "../../../components/basics/SelectErp";
import Btn from "../../../components/buttons/Btn";
import TextFieldErp from "../../../components/basics/TextFieldErp";
import { validNotNull } from "../../../utils/validations";

/**
 * Styled Components
 */
import {
  AccordionStyled,
  GridStyled,
  IconButtonStyled,
  Title,
} from "../../../styles/GlobalStyle";

import {
  GroupsSettingsContainer,
  StyledAccordionDetails,
} from "../styles/CndAccountant";

/**
 * Context
 */
import { CndAccountantContext } from "../contexts/CndAccountantContext";

/**
 * Controller
 */
import { useCndAccountantController } from "../controllers/CndAccountantController";
import {
  AddCndsInGroup,
  DeleteGroup,
  NewGroup,
  RemoveCndsInGroup,
  UpdateDefaultGroups,
  UpdateGroup,
} from "../../../repositories/panel/v1/integrations/qyonMsRpa/group";
import EditIcon from "@material-ui/icons/Edit";
import { notify } from "../../../utils/notify";
import CloseIcon from "@material-ui/icons/Close";

export const AccordionAccountantGroupSettings = () => {
  const { getEntityGroups } = useCndAccountantController();
  const {
    errors,
    setErrors,
    editedGroupOptions,
    setEditedGroupOptions,
    agendas,
    expandedGroup,
    setExpandedGroup,
    cndToGroup,
    setCndToGroup,
    openModalAddGroup,
    setOpenModalAddGroup,
    newGroupName,
    setNewGroupName,
  } = useContext(CndAccountantContext);

  useEffect(() => {
    getEntityGroups();
  }, [getEntityGroups]);

  const [openEdit, setOpenEdit] = useState(false);
  const [indexEdit, setIndexEdit] = useState(null);
  const [openModalDelete, setOpenModalDelete] = useState(false);

  const [groupIndex, setGroupIndex] = useState(null);
  const [cndId, setCndId] = useState(null);

  const handleClose = () => {
    setOpenModalDelete(false);
  };

  const handleOpenGroupAccordion = (panel) => (event, newExpanded) => {
    setExpandedGroup(newExpanded ? panel : false);
  };

  const handleOpenModalAddGroup = (event) => {
    event.stopPropagation();
    setOpenModalAddGroup(true);
    setOpenEdit(false);
  };

  const handleCloseModalAddGroup = () => {
    setNewGroupName("");
    setOpenModalAddGroup(false);
  };

  const handleAddGroup = async () => {
    if (openEdit === false) {
      let body = { description: newGroupName };
      let document = localStorage.getItem("documentPanel");

      let response = await NewGroup({ document, body });

      if (response.success === true) {
        const valid = validNotNull([
          { value: newGroupName, key: "newGroupName" },
        ]);
        setErrors(valid.errorsValidation);
        if (valid.error) {
          return { success: false };
        }

        const copy = [...editedGroupOptions];
        copy.push({
          description: newGroupName,
          agendas: [],
        });
        getEntityGroups();
        setEditedGroupOptions(copy);
      }
    }
    if (openEdit === true) {
      getEntityGroups();

      const copy = [...editedGroupOptions];
      const groupId = copy[indexEdit].id;
      let body = { description: newGroupName };

      await UpdateGroup(groupId, body);
      setEditedGroupOptions(copy);
    }
    handleCloseModalAddGroup();
  };

  const handleDeleteGroup = async (index) => {
    const copy = [...editedGroupOptions];
    let IdGroupRemove = copy[index].id;

    let response = await DeleteGroup(IdGroupRemove);
    if (response.code === 200) {
      if (copy[index].id) {
        copy[index].remove = true;
      } else {
        copy.splice(index, 1);
      }
      setEditedGroupOptions(copy);
      notify("Grupo removido com sucesso!", true, "success");
    }
    if (response.code === 400) {
      notify(
        "Não é possível excluir um grupo que possui cadastro de empresas."
      );
    }
    if (response.code === 500) {
      notify("Ocorreu um erro ao excluir o grupo.");
    }
  };

  const handleAddCndToGroup = async (groupIndex, identifi) => {
    const copy = [...editedGroupOptions];

    let response = await AddCndsInGroup(cndToGroup?.id, copy[groupIndex].id);
    if (response.code === 201) {
      const cndId = cndToGroup?.type_id || null;
      if (cndId) {
        copy[groupIndex].agendas = [...copy[groupIndex].agendas, cndId];
        setEditedGroupOptions(copy);
        setCndToGroup(null);
        notify("CND adicionada com sucesso!", true, "success");
      }
    } else {
      notify("Ocorreu um erro ao adicionar a CND", true, "warning");
    }
  };

  const handleDeleteCndFromGroup = async (groupIndex, cndId) => {
    const copy = [...editedGroupOptions];

    let response = await RemoveCndsInGroup(cndId.id, copy[groupIndex].id);

    if (response.success === true) {
      const cnds = copy[groupIndex].agendas.filter(
        (cnd) => cnd !== cndId.type_id
      );
      copy[groupIndex].agendas = cnds;
      setEditedGroupOptions(copy);
      notify("CND removida com sucesso!", true, "success");
      setOpenModalDelete(false);
    }
  };

  const openModal = (groupIndex, currentCnd) => {
    setOpenModalDelete(true);
    setGroupIndex(groupIndex);
    setCndId(currentCnd);
  };

  const handleChangeGroupDefault = async (value, groupIndex) => {
    const copy = [...editedGroupOptions];
    copy[groupIndex].default = value;

    let groupId = copy[groupIndex].id;
    let body = {};
    body =
      value === false
        ? (body = { default: false })
        : (body = { default: true });

    let response = await UpdateDefaultGroups(groupId, body);

    if (response.code === 200) {
      notify("Grupo ativado com sucesso!", true, "success");
    }
    setEditedGroupOptions(copy);
  };

  const EditNameGroup = async (index) => {
    const value = [...editedGroupOptions][index].description;
    setNewGroupName(value);
    setIndexEdit(index);
    setOpenEdit(true);
    setOpenModalAddGroup(true);
  };

  return (
    <>
      <Dialog
        open={openModalAddGroup}
        onClose={handleCloseModalAddGroup}
        aria-labelledby="new-cnd-group-modal"
        fullWidth
      >
        <DialogTitle id="new-cnd-group-modal">
          {openEdit === true ? "Editar Grupo de CNDs" : "Grupo de CNDs"}
        </DialogTitle>
        <DialogContent>
          <TextFieldErp
            error={errors.newGroupName ? true : false}
            helperText={errors.newGroupName || ""}
            label="Nome do Grupo"
            value={newGroupName}
            setValue={setNewGroupName}
            autoFocus
          />
        </DialogContent>
        <DialogActions>
          <Btn
            label="Cancelar"
            bg-color="tertiary"
            onClick={handleCloseModalAddGroup}
          >
            Cancelar
          </Btn>
          <Btn label="Salvar" onClick={handleAddGroup}>
            Criar
          </Btn>
        </DialogActions>
      </Dialog>

      <AccordionStyled defaultExpanded={true}>
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Grid container spacing={1} alignItems="center">
            <GridStyled item xs height="100%" container alignItems="center">
              <Title subtitle cursor="pointer">
                GRUPOS
              </Title>
            </GridStyled>
            <Grid item flex={1}>
              <Tooltip title="Adicionar novo grupo">
                <IconButtonStyled
                  bg-color="blue"
                  padding="4px"
                  onClick={handleOpenModalAddGroup}
                >
                  <Add />
                </IconButtonStyled>
              </Tooltip>
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <GroupsSettingsContainer>
            {editedGroupOptions &&
              editedGroupOptions.map((group, groupIndex) => {
                if (group.remove) {
                  return <></>;
                }

                const optionsListCnd = agendas.filter(
                  (option) => !group.agendas?.includes(option.id)
                );

                return (
                  <AccordionStyled
                    key={`accordion-groups-${groupIndex}`}
                    square
                    $dense
                    expanded={expandedGroup === groupIndex}
                    onChange={handleOpenGroupAccordion(groupIndex)}
                  >
                    <AccordionSummary expandIcon={<ExpandMore />}>
                      <Grid container spacing={1} alignItems="center">
                        <GridStyled
                          item
                          xs
                          height="100%"
                          container
                          alignItems="center"
                        >
                          <Title subtitle cursor="pointer">
                            {group.description}
                          </Title>
                        </GridStyled>
                        <Grid item flex={1}>
                          <Tooltip title="Adicionar automaticamente novos clientes a esse grupo">
                            <Switch
                              checked={!!group.default}
                              onClick={(event) => event.stopPropagation()}
                              onChange={(event) =>
                                handleChangeGroupDefault(
                                  event.target.checked,
                                  groupIndex
                                )
                              }
                              color="primary"
                            />
                          </Tooltip>
                        </Grid>

                        <Grid item flex={1}>
                          <Tooltip title="Editar nome do Grupo">
                            <EditIcon
                              fontSize="medium"
                              padding="4px"
                              color="action"
                              onClick={(event) => {
                                EditNameGroup(groupIndex);
                              }}
                            />
                          </Tooltip>
                        </Grid>

                        <Grid item flex={1}>
                          <Tooltip title="Excluir grupo">
                            <IconButtonStyled
                              bg-color="red"
                              padding="3px"
                              onClick={(event) => {
                                event.stopPropagation();
                                handleDeleteGroup(groupIndex);
                              }}
                            >
                              <Close
                                fontSize="small"
                                onClick={(event) => {
                                  event.stopPropagation();
                                  handleDeleteGroup(groupIndex);
                                }}
                              />
                            </IconButtonStyled>
                          </Tooltip>
                        </Grid>
                      </Grid>
                    </AccordionSummary>

                    <StyledAccordionDetails>
                      <Grid container spacing={2} justifyContent="center">
                        {optionsListCnd.length > 0 && (
                          <>
                            <GridStyled
                              item
                              xs={11}
                              container
                              alignItems="center"
                            >
                              <SelectErp
                                label="Opções CND"
                                value={cndToGroup}
                                optionsList={optionsListCnd}
                                getOptionLabel={(option) =>
                                  option?.type?.description
                                }
                                setValue={setCndToGroup}
                              />
                            </GridStyled>
                            <Grid
                              item
                              xs={1}
                              flex={1}
                              container
                              justifyContent="flex-end"
                            >
                              <Tooltip title="Adicionar CND ao grupo">
                                <IconButtonStyled
                                  bg-color="gray"
                                  onClick={(event) => {
                                    event.stopPropagation();
                                    handleAddCndToGroup(groupIndex, "normal");
                                  }}
                                >
                                  <Add />
                                </IconButtonStyled>
                              </Tooltip>
                            </Grid>
                          </>
                        )}
                        {group.agendas &&
                          group.agendas.map((cnd) => {
                            const currentCnd = agendas.find(
                              (option) => option.type_id === cnd
                            );

                            return currentCnd ? (
                              <Grid item flex={1}>
                                <Chip
                                  label={
                                    currentCnd?.type?.configs.scope ===
                                    "Municipal"
                                      ? currentCnd?.type?.description +
                                        " - " +
                                        currentCnd?.type?.configs.state
                                      : currentCnd?.type?.description
                                  }
                                  onDelete={() =>
                                    openModal(groupIndex, currentCnd)
                                  }
                                  color="primary"
                                />
                              </Grid>
                            ) : (
                              <></>
                            );
                          })}
                      </Grid>
                    </StyledAccordionDetails>
                  </AccordionStyled>
                );
              })}

            {openModalDelete && (
              <>
                <Dialog
                  open={openModalDelete}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <div>
                    <CloseIcon
                      style={{
                        position: "absolute",
                        right: 4,
                        cursor: "pointer",
                        marginTop: "10px",
                      }}
                      onClick={handleClose}
                    />
                    <DialogTitle id="alert-dialog-title">
                      Remover CNDs{" "}
                    </DialogTitle>
                  </div>

                  <DialogContent style={{ fontSize: "16px" }}>
                    Deseja remover a CND de todas as empresas vinculadas ao
                    Grupo?
                  </DialogContent>

                  <DialogActions>
                    <Button
                      variant="contained"
                      color="primary"
                      autoFocus
                      onClick={() =>
                        handleDeleteCndFromGroup(groupIndex, cndId)
                      }
                    >
                      Confirmar
                    </Button>
                  </DialogActions>
                </Dialog>
              </>
            )}

            {(!editedGroupOptions || editedGroupOptions.length === 0) && (
              <Grid container justifyContent="center" alignContent="center">
                <Typography>Nenhum grupo cadastrado</Typography>
              </Grid>
            )}
          </GroupsSettingsContainer>
        </AccordionDetails>
      </AccordionStyled>
    </>
  );
};
