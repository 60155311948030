import React from "react";

import { StyledCard } from "./styles/Card";

const Card = ({
  children,
  line = "",
  lineColor = "secondary",
  chipcolor = "secondary",
  chiptextcolor = "black",
  padding = "12",
  full = false,
  fullWidth = false,
  fullHeight = false,
  ...props
}) => {
  return (
    <StyledCard
      full={full}
      line={line}
      lineColor={lineColor}
      padding={padding}
      fullWidth={fullWidth}
      fullHeight={fullHeight}
      {...props}
    >
      {children}
    </StyledCard>
  );
};

export default Card;
