export const getIbgeCityCode = async (cep, token, userDocument) => {
  let requestOptions = {
    method: "GET",
    redirect: "follow",
    headers: {
      authorization: "Bearer " + token,
      govid: userDocument,
    },
  };

  let response = await new Promise((resolve, reject) => {
    fetch(
      localStorage.getItem("environment") === "prod"
        ? `https://qyon-bot.qyon.info/v1/bot/data/address/${cep}`
        : `https://qyon-bot-stg.qyon.info/v1/bot/data/address/${cep}`,
      requestOptions
    )
      .then((response) => resolve(response.json()))
      .catch((err) => resolve(null));
  });

  try {
    if (response.success === true) {
      return response.result.ibgeCode;
    } else {
      return "";
    }
  } catch (err) {
    console.error(err);
    return "";
  }
};
