import styled from "styled-components";

const roboto = (props) => props.theme.fonts.roboto;
const blue = (props) => props.theme.palette.blue;

export const ItemGridDragDropTitleStyle = styled.div`
  font-family: ${roboto} !important;
  font-size: 16px;
  width: 100%;
  font-weight: 700;
  min-height: 20px;
  color: #204fbd;
  line-height: 40px;
  padding: 3px 0px 12px 0px;
  display: flex;
  ${(props) => props.customStyle};
`;
