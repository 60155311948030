import * as React from "react";

function SendIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.202 1.798a1 1 0 01.237 1.037l-6.65 19a1 1 0 01-1.858.076l-3.643-8.198-8.199-3.644a1 1 0 01.076-1.858l19-6.65a1 1 0 011.037.237zm-9.964 11.379l2.497 5.617L19.104 6.31l-6.866 6.866zm5.451-8.28L5.206 9.265l5.618 2.496 6.865-6.866z"
      />
    </svg>
  );
}

export default SendIcon;
