import * as React from "react";

function IconRedirect(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill={props?.fill || "#616161"}
      {...props}
    >
      <path
        fillRule="evenodd"
        d="M8.5 5.5a1 1 0 011-1h9a1 1 0 011 1v9a1 1 0 11-2 0V7.914L6.48 18.935a1 1 0 01-1.415-1.414L16.085 6.5H9.5a1 1 0 01-1-1z"
        clipRule="evenodd"
      />
    </svg>
  );
}

export default IconRedirect;
