export const SendIcon = () => {
  return (
    <svg
      id="uuid-b35f74f0-81fa-4e77-8eb5-d4745f433600"
      xmlns="http://www.w3.org/2000/svg"
      width={25}
      height={25}
      style={{ marginLeft: 4, marginTop: 5 }}
      fill={"#fff"}
    >
      <defs>
        <style>.uuid-ec10f664-0a24-4f9e-a7f1-6f0fa22c889a</style>
      </defs>
      <g id="uuid-6aaa990a-cf93-4348-ad5f-6a4f97eab529">
        <g id="uuid-68a03e43-8255-4fa7-b1cd-40bb97c37a85">
          <g id="uuid-f31225ef-a00f-411f-8a3f-a596a70ba1c6">
            <g id="uuid-d1a2e581-3879-46cc-95f5-60cce8ac85bb">
              <path
                id="uuid-eeb3dfb4-bba1-4161-9420-2024154d5049"
                class="uuid-ec10f664-0a24-4f9e-a7f1-6f0fa22c889a"
                d="m16.11506.48374c-.4144-.42551-1.03042-.58645-1.6-.418L1.15706,3.94974C.56226,4.11255.115,4.60423.00906,5.21174c-.06816.69102.2522,1.36292.832,1.745l4.176,2.566c.42838.26372.98201.19742,1.336-.16l4.781-4.812c.23254-.24301.61804-.25149.86105-.01895.00646.00618.01277.0125.01895.01895.24048.24599.24048.63901,0,.885l-4.79,4.813c-.35476.35812-.42069.91155-.16,1.343l2.552,4.218c.2851.48762.80815.78667,1.373.785.06943.00153.13889-.00115.208-.008.64064-.07998,1.17243-.53315,1.353-1.153l3.963-13.338c.17146-.5697.01891-1.18757-.398-1.612Z"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export const PlayIcon = () => {
  return (
    <svg
      id="uuid-86dd6201-1f5d-43f7-9a50-40ad214ae25a"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24.39941 24.39844"
      width={25}
      height={25}
    >
      <defs>
        <style></style>
      </defs>
      <g id="uuid-9afc985c-4ce4-4296-a5ce-752845341657">
        <g>
          <path
            class="uuid-25fb3b7e-129d-4f17-b0d7-711be245ccef"
            d="m12.2002,0C5.47363,0,0,5.47266,0,12.19922s5.47363,12.19922,12.2002,12.19922,12.19922-5.47266,12.19922-12.19922S18.92676,0,12.2002,0Zm0,23.09766c-6.00977,0-10.89941-4.88867-10.89941-10.89844S6.19043,1.30078,12.2002,1.30078s10.89844,4.88867,10.89844,10.89844-4.88867,10.89844-10.89844,10.89844Z"
          />
          <path
            class="uuid-25fb3b7e-129d-4f17-b0d7-711be245ccef"
            d="m18.02344,11.0459l-7.30469-4.93359c-.42578-.28711-.97656-.31738-1.43164-.0752-.45508.24121-.7373.71191-.7373,1.22754v9.86816c0,.51562.28223.9873.7373,1.22852.20605.10938.42969.16309.65332.16309.27246,0,.54395-.08008.77832-.23828l7.30469-4.93359c.38281-.25977.6123-.69043.6123-1.15332s-.22949-.89355-.6123-1.15332Zm-.67285,1.31055l-7.30371,4.93359c-.0791.05469-.15625.03027-.19531.01074-.03711-.02051-.10059-.06836-.10059-.16797V7.26465c0-.09863.06348-.14648.10059-.16699.02051-.01074.05176-.02344.08984-.02344.03223,0,.06738.00879.10547.03418l7.30371,4.93359c.07227.04883.08301.11914.08301.15723s-.01074.1084-.08301.15723Z"
          />
        </g>
      </g>
    </svg>
  );
};

export const StopIcon = () => {
  return (
    <svg
      id="uuid-4a9d4198-0fd0-43f4-8b63-d56e379a5f1e"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24.39941 24.39941"
      width={25}
      height={25}
    >
      <defs>
        <style>.uuid-b0c4f979-9664-4a92-b645-93b26af447b8</style>
      </defs>
      <g id="uuid-96533263-6b91-4f78-b9f3-4ec7db628986">
        <g>
          <path
            class="uuid-b0c4f979-9664-4a92-b645-93b26af447b8"
            d="m12.19922,0C5.47266,0,0,5.47266,0,12.19922s5.47266,12.2002,12.19922,12.2002,12.2002-5.47363,12.2002-12.2002S18.92578,0,12.19922,0Zm0,23.09863c-6.00977,0-10.89844-4.88965-10.89844-10.89941S6.18945,1.30078,12.19922,1.30078s10.89941,4.88867,10.89941,10.89844-4.88965,10.89941-10.89941,10.89941Z"
          />
          <path
            class="uuid-b0c4f979-9664-4a92-b645-93b26af447b8"
            d="m15.43164,6.86035h-6.46387c-1.16211,0-2.10645.94531-2.10645,2.10742v6.46387c0,1.16211.94434,2.10645,2.10645,2.10645h6.46387c1.16211,0,2.10645-.94434,2.10645-2.10645v-6.46387c0-1.16211-.94434-2.10742-2.10645-2.10742Zm.90527,8.57129c0,.49902-.40625.90527-.90527.90527h-6.46387c-.49902,0-.90527-.40625-.90527-.90527v-6.46387c0-.5.40625-.90625.90527-.90625h6.46387c.49902,0,.90527.40625.90527.90625v6.46387Z"
          />
        </g>
      </g>
    </svg>
  );
};

export const BotLogoType = () => {
  return (
    <svg
      width="131"
      height="27"
      viewBox="0 0 131 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.78169 0.786451C6.45903 1.0684 4.45765 2.02823 2.81375 3.64819C0.498336 5.93028 -0.341853 8.53457 0.123429 11.9874C0.620623 15.6788 2.02827 17.2578 9.52158 22.5301C12.4433 24.5857 14.8998 26.2676 14.9808 26.2676V19.6941C15.9609 19.6788 16.8436 19.6675 17.6435 19.6572C23.0363 19.5877 24.6607 19.5668 26.9274 18.7415C31.1485 16.8746 33.4843 12.8135 32.772 8.58056C32.4306 6.55117 31.7127 5.21466 30.1235 3.64819C28.4238 1.97324 26.5109 1.0879 23.9118 0.773204C21.5926 0.492504 11.1269 0.501502 8.78169 0.786451ZM8.50247 7.31661C8.50247 6.76442 8.95011 6.31679 9.50229 6.31679H11.7934C12.3456 6.31679 12.7933 6.76442 12.7933 7.31661V12.3157C12.7933 12.8679 12.3456 13.3155 11.7934 13.3155H9.50229C8.95011 13.3155 8.50247 12.8679 8.50247 12.3157V7.31661ZM21.3018 6.31679C20.7496 6.31679 20.302 6.76442 20.302 7.31661V12.3157C20.302 12.8679 20.7496 13.3155 21.3018 13.3155H23.5929C24.1451 13.3155 24.5928 12.8679 24.5928 12.3157V7.31661C24.5928 6.76442 24.1451 6.31679 23.5929 6.31679H21.3018Z"
        fill="url(#paint0_linear_3744_36130)"
      />
      <g clip-path="url(#clip0_3744_36130)">
        <path
          d="M88.4686 4.94247V13.2227L85.6243 9.41011L82.3744 4.94775H78.9973V5.79958V6.03944V18.7146H82.3744V10.6135L83.5388 12.2735L88.4686 18.7143V18.7146H88.4692L88.4701 18.7157L90.2667 18.7146H91.8457V18.7137V17.749V4.94775V4.94247H88.4686Z"
          fill="#424242"
        />
        <path
          d="M69.9286 4.44926C65.8363 4.44926 62.5187 7.76686 62.5187 11.8592C62.5187 15.9515 65.8363 19.2691 69.9286 19.2691C74.0209 19.2691 77.3385 15.9515 77.3385 11.8592C77.3385 7.76686 74.0209 4.44926 69.9286 4.44926ZM69.9286 15.8978C67.6983 15.8978 65.8902 14.0895 65.8902 11.8592C65.8902 9.62886 67.6983 7.82052 69.9286 7.82052C72.1589 7.82052 73.9672 9.62886 73.9672 11.8592C73.9672 14.0895 72.1592 15.8978 69.9286 15.8978Z"
          fill="#424242"
        />
        <path
          d="M50.6535 16.6584L50.3157 16.1948C51.1971 14.9756 51.7182 13.4787 51.7182 11.8592C51.7182 7.76715 48.4012 4.44956 44.3092 4.44926H44.308C40.216 4.44956 36.8987 7.76715 36.8987 11.8592C36.8987 15.9512 40.2163 19.2691 44.3086 19.2691C45.556 19.2691 46.7306 18.9594 47.7622 18.4149L48.5683 19.5218L50.6535 22.3852L52.7386 19.5218L50.6535 16.6584ZM44.3086 15.8978C42.0783 15.8978 40.2699 14.0895 40.2699 11.8592C40.2699 9.62886 42.0783 7.82052 44.3086 7.82052C46.5389 7.82052 48.3472 9.62857 48.3472 11.8592C48.3472 12.3248 48.2675 12.7714 48.1226 13.1878H48.1191C47.7262 14.3147 46.8497 15.2119 45.7363 15.6333L45.7387 15.6365C45.2941 15.8048 44.8123 15.8981 44.3086 15.8981V15.8978Z"
          fill="#424242"
        />
        <path
          d="M58.8029 11.841L63.8966 4.94247H59.6521L57.1156 8.36358L54.5795 4.94247H50.3347L55.4571 11.8855L55.4563 11.8873V18.7146H58.8029V11.841Z"
          fill="#424242"
        />
      </g>
      <path
        d="M95.7909 17.9172V5.25602H101.608C103.479 5.25602 104.415 6.19185 104.415 8.06351V9.32963C104.415 9.74555 104.293 10.1003 104.048 10.3939L103.406 11.1646L104.177 11.8068C104.58 12.1371 104.782 12.6264 104.782 13.2748V15.1097C104.782 16.9814 103.846 17.9172 101.975 17.9172H95.7909ZM97.3322 16.5227H101.993C102.825 16.5227 103.241 16.1067 103.241 15.2749V13.2197C103.241 12.3879 102.825 11.972 101.993 11.972H97.3322V16.5227ZM97.3322 10.5774H101.626C102.458 10.5774 102.874 10.1615 102.874 9.32963V7.89836C102.874 7.06651 102.458 6.65059 101.626 6.65059H97.3322V10.5774ZM107.416 15.1097V8.06351C107.416 6.19185 108.352 5.25602 110.224 5.25602H114.518C116.389 5.25602 117.325 6.19185 117.325 8.06351V15.1097C117.325 16.9814 116.389 17.9172 114.518 17.9172H110.224C108.352 17.9172 107.416 16.9814 107.416 15.1097ZM108.958 15.2749C108.958 16.1067 109.374 16.5227 110.205 16.5227H114.536C115.368 16.5227 115.784 16.1067 115.784 15.2749V7.89836C115.784 7.06651 115.368 6.65059 114.536 6.65059H110.205C109.374 6.65059 108.958 7.06651 108.958 7.89836V15.2749ZM119.237 6.65059V5.25602H129.219V6.65059H124.999V17.9172H123.457V6.65059H119.237Z"
        fill="url(#paint1_linear_3744_36130)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_3744_36130"
          x1="0"
          y1="0.567627"
          x2="24.9359"
          y2="32.4881"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#007FFF" />
          <stop offset="1" stop-color="#0059B2" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_3744_36130"
          x1="94.1394"
          y1="3.91724"
          x2="109.826"
          y2="33.6384"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#007FFF" />
          <stop offset="1" stop-color="#0059B2" />
        </linearGradient>
        <clipPath id="clip0_3744_36130">
          <rect
            width="54.947"
            height="17.9359"
            fill="white"
            transform="translate(36.8987 4.44926)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export const ChatAction = () => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 33 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.625"
        y="0.666016"
        width="32"
        height="32"
        rx="8"
        fill="url(#paint0_linear_5146_67457)"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M23.4297 9.8613C23.6108 10.0424 23.6722 10.3112 23.5876 10.5529L19.1542 23.2196C19.0639 23.4776 18.8253 23.6543 18.5522 23.6655C18.2791 23.6766 18.0268 23.5199 17.9158 23.2701L15.4866 17.8044L10.0209 15.3752C9.77112 15.2642 9.61441 15.012 9.62556 14.7389C9.6367 14.4657 9.81344 14.2271 10.0714 14.1368L22.7381 9.70346C22.9798 9.61886 23.2487 9.6802 23.4297 9.8613ZM16.7868 17.447L18.4513 21.192L21.364 12.8698L16.7868 17.447ZM20.4212 11.927L12.0991 14.8398L15.844 16.5042L20.4212 11.927Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_5146_67457"
          x1="33"
          y1="38.166"
          x2="10"
          y2="12.666"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#05246B" />
          <stop offset="1" stop-color="#093FBB" />
        </linearGradient>
      </defs>
    </svg>
  );
};
export const FastAction = () => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_4931_14562)">
        <rect
          x="4"
          y="2"
          width="40"
          height="40"
          rx="8"
          fill="url(#paint0_linear_4931_14562)"
          shape-rendering="crispEdges"
        />
        <rect
          x="4"
          y="2"
          width="40"
          height="40"
          rx="8"
          fill="url(#paint1_linear_4931_14562)"
          shape-rendering="crispEdges"
        />
        <path
          d="M28.0004 12C28.0004 11.4477 27.5527 11 27.0004 11C26.4481 11 26.0004 11.4477 26.0004 12V15C26.0004 15.5523 26.4481 16 27.0004 16C27.5527 16 28.0004 15.5523 28.0004 15V12Z"
          fill="white"
        />
        <path
          d="M22.7577 13.3431C22.3672 12.9526 21.734 12.9526 21.3435 13.3431C20.953 13.7337 20.953 14.3668 21.3435 14.7574L23.4648 16.8787C23.8554 17.2692 24.4885 17.2692 24.879 16.8787C25.2696 16.4882 25.2696 15.855 24.879 15.4645L22.7577 13.3431Z"
          fill="white"
        />
        <path
          d="M32.6572 14.7574C33.0477 14.3668 33.0477 13.7337 32.6572 13.3431C32.2667 12.9526 31.6335 12.9526 31.243 13.3431L29.1217 15.4645C28.7312 15.855 28.7312 16.4882 29.1217 16.8787C29.5122 17.2692 30.1454 17.2692 30.5359 16.8787L32.6572 14.7574Z"
          fill="white"
        />
        <path
          d="M20.0004 18C19.4481 18 19.0004 18.4477 19.0004 19C19.0004 19.5523 19.4481 20 20.0004 20H23.0004C23.5527 20 24.0004 19.5523 24.0004 19C24.0004 18.4477 23.5527 18 23.0004 18H20.0004Z"
          fill="white"
        />
        <path
          d="M31.0004 18C30.4481 18 30.0004 18.4477 30.0004 19C30.0004 19.5523 30.4481 20 31.0004 20H34.0004C34.5527 20 35.0004 19.5523 35.0004 19C35.0004 18.4477 34.5527 18 34.0004 18H31.0004Z"
          fill="white"
        />
        <path
          d="M22.7513 24.6632L15.4796 31.935C15.089 32.3255 14.4559 32.3255 14.0654 31.935C13.6748 31.5444 13.6748 30.9113 14.0654 30.5207L26.0862 18.4999C26.4767 18.1094 27.1099 18.1094 27.5004 18.4999C27.8909 18.8904 27.8909 19.5236 27.5004 19.9141L24.8855 22.529C24.8834 22.5312 24.8812 22.5334 24.879 22.5355L22.7577 24.6569C22.7556 24.659 22.7534 24.6611 22.7513 24.6632Z"
          fill="white"
        />
        <path
          d="M30.5359 21.1213C30.1454 20.7308 29.5122 20.7308 29.1217 21.1213C28.7312 21.5118 28.7312 22.145 29.1217 22.5355L31.243 24.6569C31.6335 25.0474 32.2667 25.0474 32.6572 24.6569C33.0477 24.2663 33.0477 23.6332 32.6572 23.2426L30.5359 21.1213Z"
          fill="white"
        />
        <path
          d="M28.0004 23C28.0004 22.4477 27.5527 22 27.0004 22C26.4481 22 26.0004 22.4477 26.0004 23V26C26.0004 26.5523 26.4481 27 27.0004 27C27.5527 27 28.0004 26.5523 28.0004 26V23Z"
          fill="white"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_4931_14562"
          x="0"
          y="0"
          width="48"
          height="48"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_4931_14562"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_4931_14562"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_4931_14562"
          x1="4"
          y1="2"
          x2="44"
          y2="42"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#007FFF" />
          <stop offset="1" stop-color="#0059B2" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_4931_14562"
          x1="44.4688"
          y1="48.875"
          x2="15.7188"
          y2="17"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#05246B" />
          <stop offset="1" stop-color="#093FBB" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export const KnowledgeBaseIcon = () => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_5352_19118)">
        <rect
          x="4"
          y="2"
          width="40"
          height="40"
          rx="8"
          fill="url(#paint0_linear_5352_19118)"
          shape-rendering="crispEdges"
        />
        <rect
          x="4"
          y="2"
          width="40"
          height="40"
          rx="8"
          fill="url(#paint1_linear_5352_19118)"
          shape-rendering="crispEdges"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M13 13.5C13 12.9477 13.4477 12.5 14 12.5H20C21.6356 12.5 23.0878 13.2853 24 14.4995C24.9122 13.2853 26.3644 12.5 28 12.5H34C34.5523 12.5 35 12.9477 35 13.5V28.5C35 29.0523 34.5523 29.5 34 29.5H27C25.8954 29.5 25 30.3954 25 31.5C25 32.0523 24.5523 32.5 24 32.5C23.4477 32.5 23 32.0523 23 31.5C23 30.3954 22.1046 29.5 21 29.5H14C13.4477 29.5 13 29.0523 13 28.5V13.5ZM23 28.0351C22.4117 27.6948 21.7286 27.5 21 27.5H15V14.5H20C21.6569 14.5 23 15.8431 23 17.5V28.0351ZM25 17.5V28.0351C25.5883 27.6948 26.2714 27.5 27 27.5H33V14.5H28C26.3431 14.5 25 15.8431 25 17.5Z"
          fill="white"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_5352_19118"
          x="0"
          y="0"
          width="48"
          height="48"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.16 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_5352_19118"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_5352_19118"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_5352_19118"
          x1="4"
          y1="2"
          x2="44"
          y2="42"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#007FFF" />
          <stop offset="1" stop-color="#0059B2" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_5352_19118"
          x1="44.4688"
          y1="48.875"
          x2="15.7188"
          y2="17"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#05246B" />
          <stop offset="1" stop-color="#093FBB" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export const MagicWand = () => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.5004 1C14.5004 0.447715 14.0527 0 13.5004 0C12.9481 0 12.5004 0.447715 12.5004 1V4C12.5004 4.55228 12.9481 5 13.5004 5C14.0527 5 14.5004 4.55228 14.5004 4V1Z"
        fill="white"
      />
      <path
        d="M9.25773 2.34315C8.8672 1.95262 8.23404 1.95262 7.84351 2.34315C7.45299 2.73367 7.45299 3.36683 7.84351 3.75736L9.96483 5.87868C10.3554 6.2692 10.9885 6.2692 11.379 5.87868C11.7696 5.48816 11.7696 4.85499 11.379 4.46447L9.25773 2.34315Z"
        fill="white"
      />
      <path
        d="M19.1572 3.75736C19.5477 3.36683 19.5477 2.73367 19.1572 2.34315C18.7667 1.95262 18.1335 1.95262 17.743 2.34315L15.6217 4.46447C15.2312 4.85499 15.2312 5.48816 15.6217 5.87868C16.0122 6.2692 16.6454 6.2692 17.0359 5.87868L19.1572 3.75736Z"
        fill="white"
      />
      <path
        d="M6.50037 7C5.94808 7 5.50037 7.44772 5.50037 8C5.50037 8.55228 5.94808 9 6.50037 9H9.50037C10.0527 9 10.5004 8.55228 10.5004 8C10.5004 7.44772 10.0527 7 9.50037 7H6.50037Z"
        fill="white"
      />
      <path
        d="M17.5004 7C16.9481 7 16.5004 7.44772 16.5004 8C16.5004 8.55228 16.9481 9 17.5004 9H20.5004C21.0527 9 21.5004 8.55228 21.5004 8C21.5004 7.44772 21.0527 7 20.5004 7H17.5004Z"
        fill="white"
      />
      <path
        d="M9.25127 13.6632L1.97957 20.935C1.58904 21.3255 0.955878 21.3255 0.565354 20.935C0.17483 20.5444 0.17483 19.9113 0.565354 19.5207L12.5862 7.49992C12.9767 7.1094 13.6099 7.1094 14.0004 7.49992C14.3909 7.89045 14.3909 8.52361 14.0004 8.91414L11.3855 11.529C11.3834 11.5312 11.3812 11.5334 11.379 11.5355L9.25772 13.6569C9.25558 13.659 9.25343 13.6611 9.25127 13.6632Z"
        fill="white"
      />
      <path
        d="M17.0359 10.1213C16.6454 9.7308 16.0122 9.7308 15.6217 10.1213C15.2312 10.5118 15.2312 11.145 15.6217 11.5355L17.743 13.6569C18.1335 14.0474 18.7667 14.0474 19.1572 13.6569C19.5477 13.2663 19.5477 12.6332 19.1572 12.2426L17.0359 10.1213Z"
        fill="white"
      />
      <path
        d="M14.5004 12C14.5004 11.4477 14.0527 11 13.5004 11C12.9481 11 12.5004 11.4477 12.5004 12V15C12.5004 15.5523 12.9481 16 13.5004 16C14.0527 16 14.5004 15.5523 14.5004 15V12Z"
        fill="white"
      />
    </svg>
  );
};

export const Folha = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width="32"
        height="32"
        rx="8"
        fill="url(#paint0_linear_5441_107065)"
      />
      <path
        d="M14.7272 21.1228H12.8411C12.6554 21.1228 12.5625 21.0299 12.5625 20.8441V11.1566C12.5625 10.9708 12.6554 10.8779 12.8411 10.8779H19.1566C19.3424 10.8779 19.4353 10.9708 19.4353 11.1566V12.9855C19.4353 13.1617 19.3424 13.2498 19.1566 13.2498H14.9915V15.1859H18.5779C18.6732 15.1859 18.7423 15.2073 18.7851 15.2502C18.828 15.2931 18.8494 15.3621 18.8494 15.4574V17.2149C18.8494 17.4006 18.7589 17.4935 18.5779 17.4935H14.9915V20.8441C14.9915 21.0299 14.9034 21.1228 14.7272 21.1228Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_5441_107065"
          x1="32.375"
          y1="37.5"
          x2="9.375"
          y2="12"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#05246B" />
          <stop offset="1" stop-color="#093FBB" />
        </linearGradient>
      </defs>
    </svg>
  );
};
