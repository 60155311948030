import { callApi } from "./main";

const pathApi =
  `${process.env.REACT_APP_CRM_API_URL}/v1` || "http://localhost:4000/v1";

const objErrorResponse = {
  succes: false,
  msg: "path is required",
};
export async function postChatStatus({ body, userId, path }) {
  if (!path) return objErrorResponse;
  return callApi({
    path: `${path}/attendant/status/${userId}`,
    method: "POST",
    params: body,
  });
}
export async function postActiveChat({ body, path }) {
  if (!path) return objErrorResponse;
  return callApi({
    path: `${path}/attendant/force-attendance`,
    method: "POST",
    params: body,
  });
}
export async function getAttendantsChatConected({ companyId, roomId, path }) {
  if (!path) return objErrorResponse;
  return callApi({
    path: `${path}/chat/monitor/getDataMonitor?companyId=${
      companyId || ""
    }&roomId=${roomId || "1"}`,
    method: "GET",
  });
}

export async function postUpdateDepartment({
  body,
  companyId,
  userId,
  roomId,
  path,
}) {
  if (!path) return objErrorResponse;
  return callApi({
    path: `${path}/chat/monitor/updateDepatments?attendantId=${
      userId || ""
    }&companyId=${companyId || ""}&roomId=${roomId || "1"}`,
    method: "POST",
    params: body,
  });
}

export async function getWhatsAppMessagesTemplates({
  templateName,
  limit,
  whatsappAccountId,
  whatsappToken,
  status,
}) {
  let path = `${pathApi}/whatsapp-templates/${whatsappAccountId}/${templateName}?`;
  if (limit) path = `${path}limit=${limit}&`;
  if (whatsappToken) path = `${path}whatsappToken=${whatsappToken}&`;
  if (status) path = `${path}status=${status}&`;
  return callApi({
    path,
    method: "GET",
  });
}
