import { useContext, useEffect, useState } from "react";

// Components
import { Grid, Card, CardContent, CardActions } from "@material-ui/core";

import SelectErp from "../../../components/basics/SelectErp";
import { TransferList } from "../../../components/list/TransferList";

// Styled Components
import { Margin, GridStyled } from "../../../styles/GlobalStyle";

// Contexts
import { CndAccountantContext } from "../contexts/CndAccountantContext";

// controllers
import { useCndAccountantController } from "../controllers/CndAccountantController";

export const TabCndAccountantRegister = () => {
  const {
    getAllClients,
    getEntityAgendas,
    getEntityGroups,
    getGroupEntities,
    getAgendaEntities,
    clearRegisteredClients,
    splitRegisteredClients,
  } = useCndAccountantController();

  const {
    allClients,
    agendas,
    editedGroupOptions,
    group,
    setGroup,
    cnd,
    setCnd,
    registeredClientes,
    setRegisteredClientes,
    notRegisteredClientes,
    setNotRegisteredClientes,
    setRegisteredClientesEdited,
  } = useContext(CndAccountantContext);

  const [firstLoad, setFirstLoad] = useState(true);

  useEffect(() => {
    setGroup(null);
    setCnd(null);
    getAllClients();
    getEntityAgendas();
    getEntityGroups();
  }, [getAllClients, getEntityAgendas, getEntityGroups, setCnd, setGroup]);

  const [originalRegisteredDocuments, setOriginalRegisteredDocuments] =
    useState([]);

  useEffect(() => {
    (async () => {
      if (firstLoad) {
        clearRegisteredClients();
        return;
      }

      let registeredDocuments = null;
      if (allClients.length && group) {
        const groupCallback = await getGroupEntities(group.id);
        registeredDocuments = groupCallback.map((client) => client.document);
      } else if (allClients.length && cnd && !Array.isArray(cnd)) {
        const agendaCallback = await getAgendaEntities(cnd.id);
        registeredDocuments = agendaCallback.map((client) => client.document);
      }

      registeredDocuments
        ? splitRegisteredClients(registeredDocuments)
        : clearRegisteredClients();

      setOriginalRegisteredDocuments(registeredDocuments);
      setRegisteredClientesEdited(false);
    })();
  }, [
    cnd,
    group,
    allClients,
    firstLoad,
    getGroupEntities,
    getAgendaEntities,
    splitRegisteredClients,
    clearRegisteredClients,
    setRegisteredClientesEdited,
  ]);

  const handleSelectGroup = async (value) => {
    setFirstLoad(false);
    setCnd(
      value
        ? agendas.filter((agenda) => value.agendas.includes(agenda.id))
        : [null]
    );
    setGroup(value);
  };

  const handleSelectCnd = async (value) => {
    setFirstLoad(false);
    setCnd(value);
  };

  const handleChangeNotRegisteredClientes = (value) => {
    setNotRegisteredClientes(
      value.sort((a, b) =>
        a.company_name > b.company_name
          ? 1
          : b.company_name > a.company_name
          ? -1
          : 0
      )
    );
  };

  const handleChangeRegisteredClientes = (value) => {
    setRegisteredClientes(
      value.sort((a, b) =>
        a.company_name > b.company_name
          ? 1
          : b.company_name > a.company_name
          ? -1
          : 0
      )
    );
  };

  return (
    <>
      <Card>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item md={4} xs={12}>
              <SelectErp
                label="Grupo"
                value={group}
                optionsList={editedGroupOptions}
                descriptionField="description"
                setValue={handleSelectGroup}
                variant="filled"
              />
            </Grid>

            <Grid item md={8} xs={12}>
              <SelectErp
                multiple={!!group && Array.isArray(cnd)}
                disabled={!!group && Array.isArray(cnd)}
                label="CND"
                value={cnd}
                setValue={handleSelectCnd}
                optionsList={agendas}
                getOptionLabel={(option) =>
                  (option?.description || "") +
                  (option?.configs?.scope === "Municipal"
                    ? ` - ${option?.configs?.state}`
                    : "")
                }
              />
            </Grid>
          </Grid>

          <Margin bottom="30" />

          <TransferList
            leftOptions={notRegisteredClientes}
            setLeftOptions={handleChangeNotRegisteredClientes}
            leftListLabel="Clientes não cadastrados"
            rightOptions={registeredClientes}
            setRightOptions={handleChangeRegisteredClientes}
            rightListLabel="Clientes cadastrados"
            descriptionField="company_name"
            filterFields={["company_name", "document"]}
            toRightLabel="Cadastrar"
            toLeftLabel="Remover"
          />
        </CardContent>
      </Card>
    </>
  );
};
