import { callApi } from "./main";
const path =
  `${process.env.REACT_APP_CRM_API_URL}/v1` || "http://localhost:4000/v1";

export async function getRoomsOn({ companyId, attendantId, customerId }) {
  return callApi({
    path: `${path}/chat/room-to-call?companyId=${companyId}&attendantId=${attendantId}&clientCode=${customerId}`,
    method: "GET",
  });
}

export async function getRoom({ roomId }) {
  return callApi({
    path: `${path}/room/${roomId}`,
    method: "GET",
  });
}

export async function getcheckEmailToCreatAtendance({ url, companyId }) {
  return callApi({
    path: `${url}/cronjob/checar_conta_email_todos_v2.asp?cdE=${companyId}`,
    method: "GET",
  });
}

export async function getClassification() {
  return callApi({
    path: `${path}/classification`,
    method: "GET",
  });
}

export async function getDepartaments({ idUser, typeUser }) {
  return callApi({
    path: `${path}/product?userId=${idUser}&typeUser=${typeUser}`,
    method: "GET",
  });
}

export async function getSituation() {
  return callApi({
    path: `${path}/situation`,
    method: "GET",
  });
}

export async function getAttendance({
  userRegisterId,
  customerId,
  situationId,
  teamId,
  attendanceId,
  departamentId,
  subjectAttendance,
  rowsPerPage,
  page,
}) {
  let route = `${path}/attendance?page=${page ? page : 1}&rowsPerPage=${
    rowsPerPage ? rowsPerPage : 10
  }`;

  if (userRegisterId) route = `${route}&userRegisterId=${userRegisterId}`;
  if (situationId) route = `${route}&situationId=${situationId}`;
  if (teamId) route = `${route}&teamId=${teamId}`;
  if (customerId) route = `${route}&customerId=${customerId}`;
  if (attendanceId) route = `${route}&attendanceId=${attendanceId}`;
  if (departamentId) route = `${route}&departamentId=${departamentId}`;
  if (subjectAttendance)
    route = `${route}&subjectAttendance=${subjectAttendance}`;

  return callApi({
    path: route,
    method: "GET",
  });
}

export async function getMessages({ attendanceId }) {
  return callApi({
    path: `${path}/message?attendanceId=${+attendanceId}`,
    method: "GET",
  });
}

export async function getFilesS3({ attendanceId }) {
  return callApi({
    path: `${path}/file?attendanceId=${+attendanceId}`,
    method: "GET",
  });
}

export async function saveAttendance({ body, typeScreen }) {
  return callApi({
    path: `${path}/attendance`,
    method: typeScreen === "insert" ? "POST" : "PUT",
    params: body,
  });
}

export async function getRate() {
  return callApi({
    path: `${path}/rate/?inactive=false`,
    method: "GET",
  });
}

export async function getAnswerRate({ attendanceId }) {
  return callApi({
    path: `${path}/answer-rate/${+attendanceId}`,
    method: "GET",
  });
}

export async function postAnswerRate({ body }) {
  return callApi({
    path: `${path}/answer-rate`,
    method: "POST",
    params: body,
  });
}
