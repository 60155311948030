import { useEffect, useContext } from "react";

import {
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@material-ui/core";

import TextFieldErp from "../../../components/basics/TextFieldErp";
import Btn from "../../../components/buttons/Btn";
import { LabelStyled, Title } from "../../../styles/GlobalStyle";
import { ModalConfigSidebarContext } from "../contexts/ModalConfigSidebarContext";
import useModalConfigAccessController from "../controllers/ModalConfigAccessController";
import { GlobalContext } from "../../../contexts/GlobalContext";
import Loading from "../../basics/Loading";

export default function ModalConfigAccess() {
  const {
    accessNameConfig,
    emailConfig,
    setEmailConfig,
    typeLimit,

    setOpenConfig,
    openConfig,
    errors,
    loading,
  } = useContext(ModalConfigSidebarContext);
  const { userLogged, companyUser } = useContext(GlobalContext);
  const { handleSubmitConfig, handleChangeAccessName, handleChangeTypeLimit } =
    useModalConfigAccessController();

  useEffect(() => {
    setEmailConfig(userLogged?.email || "");
    handleChangeAccessName({
      target: { value: companyUser?.tradingName || "" },
    });
  }, [companyUser, userLogged]);

  return (
    <>
      <Loading open={loading} />
      <Dialog
        open={openConfig}
        maxWidth="sm"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <Title subtitle>Configuração de Acesso</Title>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <TextFieldErp
                error={errors.accessNameConfig ? true : false}
                helperText={errors.accessNameConfig || ""}
                value={accessNameConfig}
                onChange={handleChangeAccessName}
                placeholder="Exemplo: sorveteriabrasil"
                label="Nome de Acesso*"
                maxLength={20}
              />
            </Grid>

            <Grid item lg={6} md={6} sm={6} xs={12}>
              <TextFieldErp
                error={errors.emailConfig ? true : false}
                helperText={errors.emailConfig || ""}
                value={emailConfig}
                setValue={setEmailConfig}
                label="Email Master*"
                maxLength={50}
              />
            </Grid>

            {/* <Grid item container justifyContent="center">
              <RadioGroup
                row
                aria-label="position"
                name="position"
                defaultValue="trial"
                onChange={handleChangeTypeLimit}
              >
                <FormControlLabel
                  value="trial"
                  control={
                    <Radio color="primary" checked={typeLimit === "trial"} />
                  }
                  label="Trial"
                  labelPlacement="top"
                />

                <FormControlLabel
                  value="freemium"
                  control={
                    <Radio color="primary" checked={typeLimit === "freemium"} />
                  }
                  label="Freemium"
                  labelPlacement="top"
                />
              </RadioGroup>
            </Grid> */}

            {/* <Grid item container justifyContent="center">
              <LabelStyled color="blue" text-align="center">
                {typeLimit === "trial"
                  ? "Libera o uso da versão Gestão Fácil durante 15 dias."
                  : "Libera o uso da versão Gestão Fácil durante um 1 ano com um número reduzido de lançamentos/valores nos processos."}
              </LabelStyled>
            </Grid> */}
          </Grid>
        </DialogContent>

        <DialogActions>
          <Btn
            label="Cancelar"
            bg-color="tertiary"
            onClick={() => setOpenConfig(false)}
          />

          <Btn label="Gerar Base" onClick={() => handleSubmitConfig()} />
        </DialogActions>
      </Dialog>
    </>
  );
}
