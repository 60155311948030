import React, { useEffect, useState } from "react";

import {
  Grid,
  Modal,
  TextField,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Divider,
  Chip,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

import { createCompany } from "./events/CreateCompany";
import {
  uploadCertificate,
  uploadCertificateProcuration,
} from "./events/UploadCertificate";

import { getMP3Audio } from "../../../repositories/AudioHandler";
import { readCertificate } from "../../../repositories/Bot";
import { getCompanyData } from "../../../repositories/CompanyService";

import { notify } from "../../../utils/notify";
import SubdirectoryArrowLeftIcon from "@material-ui/icons/SubdirectoryArrowLeft";
import Certificate from "../../../assets/images/Illlustration.png";

import {
  BodyBox,
  DivBodyDescription,
  GridStyled,
} from "./styles/CertificateModal";

import { useContext } from "react";
import { GlobalContext } from "../../../contexts/GlobalContext";
import MaskService from "../../../utils/mask/maskService";
import { getEntityByDocument } from "../../../repositories/panel/v1/EntityRepository";
import { Autocomplete } from "@material-ui/lab";
import {
  ButtonCancel,
  ButtonSave,
  CloseIconStyle,
  DialogHeader,
  DialogStyle,
} from "../../../components/modal/Modal";

import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { ModalUpdateCertificate } from "./UpdateCertificateModal";

export const CertificateModal = (props) => {
  const [disabled, setDisabled] = useState(false);
  const [screen, setScreen] = useState("CERTIFICATE_DATA");
  const [companiesNotRegistered, setCompaniesNotRegistered] = useState(null);

  const [actionForAll, setActionForAll] = useState("company");

  const [certificateListState, setCertificateListState] = useState(
    props.certificateList
  );
  const { userLogged, companyUser } = useContext(GlobalContext);
  const { document } = companyUser;
  const { token } = userLogged;

  const getTitle = () => {
    return certificateListState.length > 1
      ? `Detectei que estes arquivos são ${certificateListState.length} certificados A1`
      : "Detectei que este arquivo é um certificado A1";
  };

  const getSecondTitle = () => {
    if (
      certificateListState.length === 1 &&
      certificateListState.filter((e) => e.action === "procuration").length ===
        1
    ) {
      return "Detectei um certificado de procuração. Posso armazenar em meu sistema?";
    }

    let message =
      companiesNotRegistered === 0 || companiesNotRegistered === null
        ? certificateListState.length > 1
          ? `Empresas já cadastradas. Deseja atualizar o certificado A1 dessas ${certificateListState.length} empresas?`
          : "Empresa já cadastrada. Deseja atualizar seu Certificado A1?"
        : companiesNotRegistered > 1
        ? `Encontrei ${companiesNotRegistered} empresas não cadastradas. Posso cadastrar essa empresa como cliente do escritório?`
        : "Encontrei 1 empresa não cadastrada. Posso cadastrar essa empresa como cliente no escritório?";

    if (
      certificateListState.filter((e) => e.action === "procuration").length > 1
    ) {
      message +=
        " No caso do(s) certificado(s) de procuração, irei inseri-lo(s) no sistema.";
    }

    return message;
  };

  const saveUpdateCertificate = async () => {
    setDisabled(true);

    let success = true;
    let certificateError = null;

    for (let certificate of certificateListState) {
      if (certificate.action !== "procuration") {
        let companyBody = certificate.company;
        if (
          companyBody.email === null ||
          companyBody.email === undefined ||
          companyBody.email === ""
        ) {
          notify(
            "Email de acesso não informado. Por favor, informe o email para acesso à Plataforma do seu cliente."
          );
          success = false;
          certificateError = certificate;
          break;
        }

        let responseCreateCompany = await createCompany(
          companyBody,
          token,
          document
        );

        let responseUploadCertificate = await uploadCertificate(
          certificate.name,
          certificate.base64,
          certificate.password,
          companyBody.clientCnpj
        );

        if (
          responseCreateCompany === null ||
          responseUploadCertificate === null
        ) {
          certificateError = certificate;
          success = false;
          break;
        }
      } else {
        let responseUploadCertificate = await uploadCertificateProcuration(
          certificate.name,
          certificate.base64,
          certificate.password,
          certificate.procurationDocumentList
        );
        if (responseUploadCertificate === null) {
          certificateError = certificate;
          success = false;
          break;
        }
      }
    }

    if (success === true) {
      notify("Operação realizada com sucesso!", true, "success");
      setDisabled(false);

      props.closeCertificateModal();
    } else {
      setDisabled(false);

      notify(
        `Ocorreu um problema no certificado ${certificateError.name}! Tente novamente!`
      );
    }
  };

  const nextPageSaveCertificate = async () => {
    setDisabled(true);

    let success = true;
    let certificateWithError = null;
    let responseWithError = null;
    let i = 0;

    for (let certificate of certificateListState) {
      if (certificate.password === undefined || certificate.password === "") {
        notify(`Informe a senha do seu certificado!`);
        success = false;
        certificateWithError = certificate;
        let certList = [...certificateListState];
        certList[i].passwordError = true;
        setCertificateListState(certList);
        setDisabled(false);

        break;
      }
      if (
        certificate.action === "procuration" &&
        certificate.procurationDocumentList.length === 0
      ) {
        notify(`Informe no minímo um CNPJ para a procuração!`);
        success = false;
        certificateWithError = certificate;
        let certList = [...certificateListState];
        certList[i].procurationError = true;
        setCertificateListState(certList);
        setDisabled(false);
        break;
      }

      let responseCompany = await readCertificate(certificate);

      if (responseCompany !== null && responseCompany.success === true) {
        let obj = {};
        obj.companyName = responseCompany?.companyName || "";
        obj.tradingName =
          responseCompany?.tradingName != null
            ? responseCompany?.tradingName || ""
            : responseCompany?.companyName || "";

        obj.clientCnpj = responseCompany?.clientCnpj;
        obj.phone = responseCompany?.phone || "";
        obj.email = certificate?.email || "";

        obj.addresses = [];

        let addr = {};

        if (
          responseCompany?.cep !== null &&
          responseCompany?.cep !== undefined &&
          responseCompany?.cep !== ""
        ) {
          addr.cep = responseCompany?.cep;
          addr.number = responseCompany?.number;
          addr.street = responseCompany?.street;
          addr.city = responseCompany?.city;
          addr.neighborhood = responseCompany?.district;
          addr.state = responseCompany?.uf;
          addr.complement = "";
          addr.ibgeCode = null;

          obj.addresses.push(addr);
        }

        certificate.company = obj;

        let entityExists = await getEntityByDocument(
          certificate.company.clientCnpj
        );

        if (entityExists !== null && entityExists.success === true) {
          certificate.company.companyName = entityExists.data.company_name;
          certificate.company.tradingName =
            entityExists.data?.trading_name || entityExists.data.company_name;
          certificate.company.phone =
            entityExists.data?.phone || responseCompany?.phone;

          if (
            entityExists.data.accountant_id !== null &&
            entityExists.data.accountant_id !== undefined &&
            entityExists.data.accountant_id !== ""
          ) {
            if (
              entityExists.data.accountant_id.toString() !==
              localStorage.getItem("companyIdPanel")
            ) {
              success = false;
              notify(
                "A empresa " +
                  certificate?.company?.companyName +
                  " já está vinculada a outro escritório."
              );
              certificateWithError = certificate;
              setDisabled(false);
              break;
            }
          }
        }

        let responsePlug = await getCompanyData(responseCompany.clientCnpj);

        if (responsePlug !== null) {
          if (responsePlug.success === true) {
            certificate.company.email = responsePlug.data.Email;
          }
        }

        if (certificate.company?.addresses.length >= 0 && !addr.cep) {
          addr.cep = responsePlug?.data?.CEP || "00000000";
          addr.number = responsePlug?.data?.Numero || "1";
          addr.street = responsePlug?.data?.Endereco || "Não Informado";
          addr.city = responsePlug?.data?.Municipio || "Não Informado";
          addr.neighborhood = responsePlug?.data?.Bairro || "Não Informado";
          addr.state = responsePlug?.data?.UF || "SP";
          addr.complement = responsePlug?.data?.Complemento || "";
          addr.ibgeCode = responsePlug?.data?.CodigoCidadeIBGE || "";

          certificate.company.addresses.push(addr);
        }

        if (responsePlug === null) {
          success = false;
          certificateWithError = certificate;
          setDisabled(false);
          break;
        }

        certificate.plug = responsePlug;
      } else {
        success = false;
        certificateWithError = certificate;

        responseWithError = responseCompany;
        setDisabled(false);

        if (responseCompany.message === "Senha do certificado incorreta") {
          let list = [...certificateListState];
          list[i].passwordError = true;
          setCertificateListState(list);
        }

        break;
      }

      i++;
    }

    if (success) {
      setDisabled(false);

      let compNotRegistered = certificateListState.filter(
        (e) => e.plug.success === false && e.action !== "procuration"
      ).length;

      setCompaniesNotRegistered(compNotRegistered);

      setScreen("ANALYSYS");

      let title =
        compNotRegistered === 0 || compNotRegistered === null
          ? certificateListState.length > 1
            ? `Detectei que essas empresas já estão cadastradas. Posso atualizar o certificado A1 dessas ${certificateListState.length} empresas?`
            : "Detectei que essa empresa já está cadastrada. Posso atualizar o certificado A1 dela?"
          : compNotRegistered > 1
          ? `Encontrei ${compNotRegistered} empresas não cadastradas. Posso cadastrar essas empresas como cliente do escritório?`
          : "Encontrei 1 empresa não cadastrada. Posso cadastrar essa empresa como cliente no escritório?";

      if (
        certificateListState.filter((e) => e.action === "procuration").length >
        1
      ) {
        title +=
          " No caso do(s) certificado(s) de procuração, irei inseri-lo(s) no sistema.";
      }

      if (
        certificateListState.length === 1 &&
        certificateListState.filter((e) => e.action === "procuration")
          .length === 1
      ) {
        title = "Posso inserir o certificado de procuração no sistema?";
      }

      getMP3Audio(title).then((result) => {
        var snd = new Audio("data:audio/wav;base64," + result);
        snd.play();
      });
    } else {
      setDisabled(false);

      try {
        if (responseWithError) {
          notify(responseWithError.message);
        }
      } catch (err) {
        notify(
          `Ocorreu um problema ao tentar checar o certificado ${certificateWithError.name}! Tente novamente!`
        );
      }
    }
    setDisabled(false);
  };

  useEffect(() => {
    let title = getTitle();
    getMP3Audio(title).then((result) => {
      var snd = new Audio("data:audio/wav;base64," + result);
      snd.play();
    });
  }, []);

  return (
    <Modal
      open={props.openModal}
      onClose={() => {
        props.closeCertificateModal();
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <>
        {/* TODO REFACTOR ANALYSYS PAGE */}
        {screen === "ANALYSYS" && (
          <ModalUpdateCertificate
            getSecondTitle={getSecondTitle}
            closeCertificateModal={props.closeCertificateModal}
            certificateListState={certificateListState}
            disabled={disabled}
            setDisabled={setDisabled}
            saveUpdateCertificate={saveUpdateCertificate}
          />
        )}
        {/* TODO END REFACTOR */}
        {(!screen || screen === "CERTIFICATE_DATA") && (
          <DialogStyle>
            <DialogHeader>
              <div className="row">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    marginBottom: 20,
                  }}
                >
                  Certificado A1 detectado
                </div>
                <CloseIconStyle
                  onClick={() => {
                    props.closeCertificateModal();
                  }}
                />
              </div>
            </DialogHeader>

            <DivBodyDescription>
              <Typography>
                Por favor, insira a senha do seu Certificado A1 para acessar os
                dados. Com o certificado digital, você pode atualizar os dados
                da empresa ou, com procuração, migrar para o e-Social.
              </Typography>
            </DivBodyDescription>

            <Divider style={{ marginTop: 10, marginBottom: 10 }} />

            <BodyBox>
              {certificateListState.length > 1 && (
                <Grid
                  container
                  style={{
                    marginTop: 15,
                    marginLeft: 30,
                  }}
                  spacing={1}
                >
                  <Grid item xs={4}>
                    <FormControl size="small" variant="outlined">
                      <InputLabel>Tipo de ação</InputLabel>

                      <Select
                        label="Escolha uma ação"
                        value={actionForAll}
                        onChange={(e) => {
                          setActionForAll(e.target.value);
                        }}
                      >
                        <MenuItem value="company">
                          Cadastro/Atualização
                        </MenuItem>
                        <MenuItem value="procuration">
                          Procuração (E-social)
                        </MenuItem>
                        <MenuItem value="custom" disabled>
                          Personalizado
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={4} style={{ marginLeft: 20 }}>
                    <ButtonSave
                      disabled={actionForAll === "custom"}
                      variant="text"
                      color="primary"
                      onClick={() => {
                        if (actionForAll !== "custom") {
                          let certList = [...certificateListState];

                          for (let i = 0; i < certList.length; i++) {
                            certList[i].action = actionForAll;

                            if (actionForAll === "procuration") {
                              certList[i].accessEmail = "";
                            } else {
                              certList[i].procurationDocument = "";
                              certList[i].procurationDocumentList = [];
                            }
                          }
                          setCertificateListState(certList);
                        }
                      }}
                    >
                      <Typography style={{ color: "white", fontSize: 16 }}>
                        Aplicar a todos
                      </Typography>
                    </ButtonSave>
                  </Grid>
                </Grid>
              )}

              {certificateListState.map((certificate, index) => {
                return (
                  <Grid key={"CERTIFICATE" + index} style={{ paddingLeft: 24 }}>
                    {certificateListState.length > 1 && (
                      <Divider style={{ marginTop: 20, marginBottom: 20 }} />
                    )}
                    <Grid
                      container
                      style={{
                        padding: certificateListState.length > 1 ? 10 : 24,
                      }}
                    >
                      <Grid item xs={12}>
                        <Typography
                          id="modal-modal-title"
                          style={{
                            marginBottom: 20,
                            fontSize: 18,
                            fontWeight: 500,
                          }}
                        >
                          {certificate.name}
                        </Typography>
                      </Grid>

                      <Grid item container spacing={1} xs={2}>
                        <Grid item xs={2} style={{ textAlign: "center" }}>
                          <img
                            alt="Certificado"
                            src={Certificate}
                            width="50"
                            style={{
                              marginTop:
                                certificate.action === "procuration" ? 15 : 0,
                              width: 80,
                              height: 80,
                            }}
                          />
                        </Grid>
                      </Grid>

                      <Grid item container spacing={1} xs={10}>
                        <Grid item xs={6} style={{ paddingLeft: 24 }}>
                          <FormControl size="small" variant="outlined">
                            <InputLabel>Tipo de ação</InputLabel>

                            <Select
                              label="Escolha uma ação"
                              value={certificate.action}
                              onChange={(e) => {
                                setActionForAll("custom");

                                let list = [...certificateListState];
                                list[index].action = e.target.value;

                                if (e.target.value === "procuration") {
                                  list[index].accessEmail = "";
                                } else {
                                  list[index].procurationDocument = "";
                                  list[index].procurationDocumentList = [];
                                }
                                setCertificateListState(list);
                              }}
                              style={{ width: "100%" }}
                            >
                              <MenuItem value="company">
                                Cadastro/Atualização
                              </MenuItem>
                              <MenuItem value="procuration">
                                Procuração (E-social)
                              </MenuItem>
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item xs={6} style={{ paddingLeft: 30 }}>
                          <TextField
                            size="small"
                            label="Senha do certificado"
                            type={"password"}
                            error={certificate.passwordError}
                            fullWidth
                            variant="outlined"
                            key={index}
                            onChange={(ev) => {
                              let certList = [...certificateListState];
                              certList[index].passwordError = false;
                              certList[index].password = ev.target.value;

                              setCertificateListState(certList);
                            }}
                          />
                        </Grid>

                        {certificate.action === "procuration" && (
                          <Grid
                            item
                            xs={12}
                            style={{ paddingLeft: 24, paddingTop: 10 }}
                          >
                            <Autocomplete
                              fullWidth
                              multiple
                              id="tags-filled"
                              options={[]}
                              freeSolo
                              value={certificate.procurationDocumentList}
                              renderTags={(value, getTagProps) =>
                                value.map((option, index) => (
                                  <Chip
                                    label={option}
                                    style={{ backgroundColor: "#f5f5f5" }}
                                    deleteIcon={
                                      <CloseIcon
                                        style={{
                                          height: 15,
                                          color: "#202020",
                                        }}
                                      />
                                    }
                                    {...getTagProps({ index })}
                                  />
                                ))
                              }
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  size="small"
                                  label="CNPJ da procuração"
                                  variant="outlined"
                                  key={index}
                                  error={certificate.procurationError}
                                  value={certificate.procurationDocument}
                                />
                              )}
                              onChange={(event, newValue) => {
                                if (newValue.length === 0) {
                                  let certList = [...certificateListState];
                                  certList[index].procurationDocumentList = [];
                                  certList[index].procurationDocument = "";
                                  setCertificateListState(certList);
                                } else {
                                  const arrayLength = newValue.length - 1;

                                  if (
                                    newValue[arrayLength].replace(/[^\d]/g, "")
                                      .length !== 11 &&
                                    newValue[arrayLength].replace(/[^\d]/g, "")
                                      .length !== 14
                                  ) {
                                    notify("Informe um CNPJ válido!");
                                    return;
                                  }

                                  let mask =
                                    newValue[arrayLength].replace(/[^\d]/g, "")
                                      .length <= 11
                                      ? "cpf"
                                      : "cnpj";

                                  newValue[arrayLength] = MaskService.toMask(
                                    mask,
                                    newValue[arrayLength]
                                  );

                                  let certList = [...certificateListState];
                                  certList[index].procurationDocumentList =
                                    newValue;
                                  certList[index].procurationDocument =
                                    newValue[arrayLength];
                                  certList[index].procurationError = false;

                                  setCertificateListState(certList);
                                }
                              }}
                            />
                            <Typography
                              variant="subtitle2"
                              color="textSecondary"
                              style={{ marginBottom: 20 }}
                            >
                              * Aperte a tecla "Enter" para inserir os CNPJs
                            </Typography>
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                );
              })}
            </BodyBox>

            <GridStyled>
              <ButtonCancel
                style={{ marginRight: 16 }}
                onClick={() => {
                  props.closeCertificateModal();
                }}
                startIcon={<SubdirectoryArrowLeftIcon />}
              >
                Cancelar
              </ButtonCancel>

              <ButtonSave
                background={disabled}
                endIcon={<ArrowForwardIcon />}
                onClick={async () => {
                  nextPageSaveCertificate();
                }}
              >
                {disabled ? "Carregando..." : "Continuar"}
              </ButtonSave>
            </GridStyled>
          </DialogStyle>
        )}
      </>
    </Modal>
  );
};
