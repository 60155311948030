import React, { useCallback, useContext, useEffect } from "react";
import { GlobalContext } from "../../contexts/GlobalContext";
import useChatAssistantController from "../sidebar/controllers/ChatAssistantController";
import { GlobalVariables } from "../../GlobalVariables";
import {
  CircularProgress,
  Grid,
  IconButton,
  Tooltip,
  makeStyles,
} from "@material-ui/core";
import NotificationBadge from "react-notification-badge/lib/components/NotificationBadge";
import { Pulse, PulseIcon } from "../../styles/GlobalStyle";
import { Effect } from "react-notification-badge";
import IconsMenu from "../sidebar/IconsMenu";
import {
  StyledBotActionBar,
  StyledBotActionButton,
} from "./styles/BotActionBar";
import chatController from "../chat/controllers/ChatController";
import { useChatContext } from "../chat/contexts/ChatContext";
import { useState } from "react";
import { notify } from "../../utils/notify";
import { useAuth } from "../../contexts/crm/Auth";
import InterrogationBot from "../sidebar/children/InterrogationBot";
import MenuChangeStatusUser from "../chat/components/MenuChangeStatusUser";
import AttendanceController from "../../pages/attendance/controllers/AttendanceController";
import { BotNotificationModal } from "../sidebar/children/BotNotificationModal";
import { getAllActions } from "../../repositories/Bot";
import { NewBotContext } from "../newBot/context/NewBotContext";
import Chat from "../chat/Chat";
import {
  ArrowBackIosOutlined,
  ArrowForwardIosOutlined,
} from "@material-ui/icons";

export const BotActionBar = ({
  customKey,
  setCustomKey,
  actions,
  setActions,
  actionTotal,
  setActionTotal,
  botRef,
  handleCloseDrawer,
  handleOpenDrawer,
  ...props
}) => {
  const { companyUser, animation, userLogged, sidebaraction } =
    useContext(GlobalContext);
  const { AumentarChat } = useChatAssistantController();
  const { chat, settings, setSettings } = useChatContext();

  const [isChatDisabled, setIsChatDisabled] = useState(true);
  const [countChatBadge, setCountChatBadge] = useState(0);
  const [pulseButton, setPulseButton] = useState(false);
  const [mouseHover, setMouseHover] = useState(false);
  const [stateBadge, setStateBadge] = useState();
  const [anchorElementState, setAnchorElementState] = useState(null);
  const [openOptionModal, setOpenOptionModal] = useState("");
  const [fnSaveStatus, setFnSaveStatus] = useState(null);
  const open = Boolean(anchorElementState);
  const [reasonChat, setReasonChat] = useState([]);
  const [openActionModal, setOpenActionModal] = useState(false);
  const [actionModalProduct, setActionModalProduct] = useState(null);

  const botContext = useContext(NewBotContext);

  const { user, company } = useAuth();

  let emailCheckCrm;

  const useAttendanceController = AttendanceController();

  const {
    changeOpen,
    subscribeOnCallCustumerToConversation,
    getReasonsChat,
    saveStatus,
    getStateAttendant,
    getRoomStatus,
  } = chatController();

  const buttonSyles = makeStyles({
    squareButtonStyle: {
      width: "10px",
      height: "10px",
      marginTop: "14px",
      marginBottom: "20px",
      marginLeft: "5px",
      border: "2px solid #ebecee",
      backgroundColor: "#ffffff",
      borderRadius: "4px",
      "&:hover": {
        backgroundColor: "#0338b4",
        color: "white",
      },
    },
    IconStyle: {
      fontSize: 12,
      "&:hover": {
        backgroundColor: "#0338b4",
        color: "white",
      },
    },
  });

  const classButton = buttonSyles();

  const standaloneUser =
    companyUser.isAccountant !== "true" && !companyUser.accountantId
      ? {
          filter: "grayscale(1)",
          cursor: "not-allowed",
        }
      : null;

  const hasFolhaAccess = !(
    standaloneUser &&
    !companyUser.systemsPermissions.find(
      (s) => s.id === GlobalVariables.SYSTEMS.Folha
    )?.configured
  );

  const hasContabilAccess = !(
    standaloneUser &&
    !companyUser.systemsPermissions.find(
      (s) => s.id === GlobalVariables.SYSTEMS.Contabil
    )?.configured
  );

  const hasFiscalAccess = !(
    standaloneUser &&
    !companyUser.systemsPermissions.find(
      (s) => s.id === GlobalVariables.SYSTEMS.Fiscal
    )?.configured
  );

  const isInClientView = sessionStorage.getItem("isInClientView");

  const openModalChangeStatusUser = (e) => {
    setAnchorElementState(anchorElementState ? null : e.currentTarget);
  };

  const pulseIconOnMouseHover = () => {
    setMouseHover(true);
  };

  const unPulseOnMouseOut = () => {
    setMouseHover(false);
  };

  const handleClose = async (statusCode) => {
    const useStatusCode =
      typeof statusCode === "string" ? statusCode : settings.myState;
    setFnSaveStatus(useStatusCode);
    setAnchorElementState(null);
  };

  const refresh = async () => {
    async function fetchAPI() {
      let response = await getAllActions();

      setActionTotal(response.headers.count);

      if (
        response != null &&
        response.status === 200 &&
        response.data != null
      ) {
        let QYON_DASH = response.data.filter(
          (e) => e.product === "QYON_DASH" && e.seen === 0
        );
        let QYON_XML = response.data.filter(
          (e) => e.product === "QYON_XML" && e.seen === 0
        );
        let QYON_ERP = response.data.filter(
          (e) => e.product === "QYON_ERP" && e.seen === 0
        );
        let QYON_ERP_ADM = response.data.filter(
          (e) => e.product === "QYON_ERP_ADM" && e.seen === 0
        );
        let QYON_ACCOUNTANT = response.data.filter(
          (e) => e.product === "QYON_ACCOUNTANT" && e.seen === 0
        );
        let QYON_CRM = response.data.filter(
          (e) => e.product === "QYON_CRM" && e.seen === 0
        );
        let QYON_BANK = response.data.filter(
          (e) => e.product === "QYON_BANK" && e.seen === 0
        );

        let statistics = [
          {
            product: "QYON_DASH",
            total: QYON_DASH.length,
          },
          {
            product: "QYON_XML",
            total: QYON_XML.length,
          },
          {
            product: "QYON_ERP",
            total: QYON_ERP.length,
          },
          {
            product: "QYON_ERP_ADM",
            total: QYON_ERP_ADM.length,
          },
          {
            product: "QYON_ACCOUNTANT",
            total: QYON_ACCOUNTANT.length,
          },
          {
            product: "QYON_CRM",
            total: QYON_CRM.length,
          },
          {
            product: "QYON_BANK",
            total: QYON_BANK.length,
          },
        ];

        setActions(statistics);
      }
    }

    fetchAPI();
  };

  const onDecreaseBadge = async () => {
    await refresh();

    setCustomKey(Math.random());
  };

  const onCloseActionModal = () => {
    setOpenActionModal(false);
  };

  const handleOpenOptionModal = useCallback(
    (option) => {
      setOpenOptionModal((current) => {
        const value = current === option ? "" : option;
        if (!user.id || user.id === "") return "";
        return value;
      });
    },
    [user.id]
  );

  const pulseButtonAdd = useCallback(async () => {
    const afterFifteenSeconds = 1000 * 60 * 15;
    if (
      (settings.myState !== "0" && settings.roomState === "1") ||
      settings.myState !== "0"
    ) {
      const pulse = setTimeout(() => {
        setPulseButton(true);
        if (!document.getElementsByClassName("toastUserOfflineNotify").length)
          notify(
            "Você está offline no chat",
            false,
            "warning",
            "",
            1000 * 60 * 5,
            "toastUserOfflineNotify"
          );
      }, afterFifteenSeconds);

      return () => clearTimeout(pulse);
    } else {
      setPulseButton(false);
      const element = document.getElementsByClassName("toastUserOfflineNotify");
      if (element.length) {
        element[0].parentNode.removeChild(element[0]);
      }
    }
  }, [settings.myState, settings.roomState]);

  useEffect(() => {
    setTimeout(() => {
      setIsChatDisabled(false);
    }, 3000);
  }, []);

  useEffect(() => {
    (async () => {
      if (companyUser.isAccountant === "true") {
        const jsonState = await getStateAttendant();
        const roomStatus = await getRoomStatus();
        setSettings({
          ...settings,
          myState: jsonState.myState,
          roomState: roomStatus,
        });
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    user?.id,
    chat?.roomId,
    company?.id,
    userLogged.cognito.RefreshToken,
    companyUser.isAccountant,
  ]);

  useEffect(() => {
    clearInterval(emailCheckCrm);
    if (companyUser.isAccountant === "true") {
      if (company.id) {
        if (company?.hasSMTPConfiguration) {
          // eslint-disable-next-line react-hooks/exhaustive-deps
          emailCheckCrm = setInterval(async () => {
            await useAttendanceController.checkEmailToCreatAtendance();
          }, 1000 * 60 * 5);
        }
      } else {
        clearInterval(emailCheckCrm);
      }
    }
  }, [company.id, company?.hasSMTPConfiguration]);

  useEffect(() => {
    if (companyUser?.isAccountant !== "true" && user?.id)
      subscribeOnCallCustumerToConversation(
        {
          customerId: user?.id,
          companyId: company?.id,
          roomId: chat.roomId,
          contactId: user?.contactId,
        },
        (data) => {
          setCountChatBadge(1);
          changeOpen({ isChatOpen: true });
        }
      );
  }, [
    user?.id,
    user?.contactId,
    chat.roomId,
    company?.id,
    companyUser?.isAccountant,
    handleOpenOptionModal,
    subscribeOnCallCustumerToConversation,
    changeOpen,
  ]);

  useEffect(() => {
    (async () => {
      if (companyUser.isAccountant === "true") {
        if (fnSaveStatus) {
          const statusUser = await saveStatus({
            state: fnSaveStatus,
          });
          setSettings({
            myState: statusUser?.currentStatus,
          });
        }
      }
    })();
  }, [fnSaveStatus, setSettings, saveStatus, companyUser.isAccountant]);

  useEffect(() => {
    if (!chat.open) {
      if (chat.conversations.length) {
        let count = 0;

        chat.conversations.forEach((conversation) => {
          const { countMessages } = conversation;

          if (countMessages) {
            count += countMessages;
          }
        });

        if (count > 0) {
          document.getElementById("chatIcon").style.animation = "shake 0.5s";
          document.getElementById("chatIcon").style.animationIterationCount =
            "infinite";
          setTimeout(() => {
            document.getElementById("chatIcon").style.animationIterationCount =
              "";
          }, 1500);
        }

        setCountChatBadge(count);
      }
    } else {
      setCountChatBadge(0);
    }
  }, [chat.conversations, chat.currentConversation, chat.open]);

  useEffect(() => {
    (async () => {
      if (companyUser.isAccountant === "true") {
        if (settings.myState === "0" && settings.roomState === "0") {
          setStateBadge("#92c353");
        } else {
          setStateBadge("rgba(212, 19, 13, 1)");
        }
      }
    })();
  }, [settings.myState, settings.roomState, companyUser.isAccountant]);

  useEffect(() => {
    (async () => {
      if (companyUser.isAccountant === "true") {
        const response = await getReasonsChat();
        setReasonChat(response);
      }
    })();
  }, [companyUser.isAccountant, getReasonsChat]);

  useEffect(() => {
    (async () => {
      if (companyUser.isAccountant === "true") {
        await pulseButtonAdd();
      }
    })();
  }, [companyUser.isAccountant, pulseButtonAdd]);

  return (
    <StyledBotActionBar>
      <Grid
        container
        style={{
          display: "flex",
          justifyContent: "flex-start",
        }}
      >
        <IconButton
          className={classButton.squareButtonStyle}
          onClick={sidebaraction.open ? handleCloseDrawer : handleOpenDrawer}
        >
          {sidebaraction.open ? (
            <ArrowForwardIosOutlined className={classButton.IconStyle} />
          ) : (
            <ArrowBackIosOutlined className={classButton.IconStyle} />
          )}
        </IconButton>
      </Grid>

      <Tooltip title="Qyon Bot">
        <StyledBotActionButton
          fullWidth
          color="primary"
          aria-label="add"
          hasAccess={true}
          onClick={() => {
            handleOpenDrawer();

            botContext.setBotMode("CHAT");

            botContext.setBotPresentationRunning(null);
            if (botContext.isBotPresentationRunning === true) {
              botRef.current.stop_bot_presentation_to_open_chat();
            }
            botContext.setOpenBotChatModal(true);
          }}
        >
          <Pulse active={animation === 6}>
            <Grid alignItems="center" container justifyContent="center">
              <NotificationBadge
                count={0}
                effect={Effect.SCALE}
                key={customKey}
              />
              <IconsMenu icon="BotIcon" color="#0a73db" />
            </Grid>
          </Pulse>
        </StyledBotActionButton>
      </Tooltip>

      <Tooltip
        title={
          standaloneUser
            ? "Para que essa função seja ativada, você necessita estar conectado à um escritório que seja cliente Qyon."
            : isInClientView
            ? "Para que essa função seja ativada, você necessita sair da visão de cliente e retornar à visão de contador."
            : companyUser?.isAccountant === "true"
            ? "Chat com o Cliente"
            : "Chat com o Contador"
        }
      >
        <div
          style={{
            color: "primary",
            width: "48px",
          }}
        >
          <StyledBotActionButton
            id="chatIcon"
            color="primary"
            aria-label="add"
            size="medium"
            zIndex="1"
            hasAccess={!standaloneUser && !isInClientView}
            onClick={() => {
              if (standaloneUser || isInClientView || isChatDisabled) {
                return;
              }

              changeOpen({ isChatOpen: !chat.open });
            }}
          >
            {isChatDisabled ? (
              <CircularProgress color="white" size={20} />
            ) : (
              IconsMenu({ icon: "ChatIcon", color: "#0a73db" })
            )}
            <div
              style={{
                position: "absolute",
                right: 10,
                top: 0,
              }}
            >
              <NotificationBadge
                count={countChatBadge}
                effect={Effect.SCALE}
                key={customKey}
              />
            </div>
            <MenuChangeStatusUser
              anchorElementState={anchorElementState}
              open={open}
              handleClose={handleClose}
              statusListTemp={reasonChat}
              myState={settings.myState}
            />
          </StyledBotActionButton>
          {companyUser.isAccountant === "true" && (
            <div
              style={{
                position: "relative",
                width: "48px",
                top: "-10px",
                zIndex: "9999",
              }}
            >
              <PulseIcon
                onClick={openModalChangeStatusUser}
                pulseIcon={
                  pulseButton
                    ? "rgb(212, 19, 13, 1)"
                    : mouseHover
                    ? stateBadge
                    : false
                }
                id="pulseIcon"
                pulseOffLine={pulseButton}
              >
                <div
                  onMouseEnter={pulseIconOnMouseHover}
                  onMouseLeave={unPulseOnMouseOut}
                  style={{
                    position: "absolute",
                    cursor: "pointer",
                    bottom: "0",
                    right: "0",
                    backgroundColor: stateBadge,
                    borderRadius: "13px",
                    padding: "6px",
                    border: "2px solid #fff",
                  }}
                ></div>
              </PulseIcon>
            </div>
          )}
        </div>
      </Tooltip>

      <Tooltip
        title={
          !hasFiscalAccess
            ? "Para que essa função seja ativada, você necessita estar conectado à um escritório que seja cliente Qyon."
            : "Notificações da escrita fiscal"
        }
      >
        <StyledBotActionButton
          fullWidth
          color="primary"
          aria-label="add"
          hasAccess={hasFiscalAccess}
          onClick={
            hasFiscalAccess
              ? () => {
                  setOpenActionModal(true);
                  setActionModalProduct("QYON_WRITING_TAX");
                }
              : () => {}
          }
        >
          <Pulse active={animation === 6}>
            <Grid alignItems="center" container justifyContent="center">
              <NotificationBadge
                count={0}
                effect={Effect.SCALE}
                key={customKey}
              />
              <IconsMenu icon="NewEscritaIcon" color="#66bb6a" />
            </Grid>
          </Pulse>
        </StyledBotActionButton>
      </Tooltip>
      <Tooltip
        title={
          !hasFolhaAccess
            ? "Para que essa função seja ativada, você necessita estar conectado à um escritório que seja cliente Qyon."
            : "Notificações da folha de pagamento"
        }
      >
        <StyledBotActionButton
          color="primary"
          aria-label="add"
          hasAccess={hasFolhaAccess}
          onClick={
            hasFolhaAccess
              ? () => {
                  setOpenActionModal(true);
                  setActionModalProduct("QYON_ACCOUNTANT");
                }
              : () => {}
          }
        >
          <Pulse active={animation === 6}>
            <Grid container alignItems="center" justifyContent="center">
              <div
                style={{
                  position: "absolute",
                  top: "0px",
                  right: "0px",
                }}
              >
                <NotificationBadge
                  count={
                    actions.filter((e) => e.product === "QYON_ACCOUNTANT")[0]
                      .total
                  }
                  effect={Effect.SCALE}
                  key={customKey}
                />
              </div>
              <IconsMenu icon="NewFolhaIcon" color="#1ed2fb" />
            </Grid>
          </Pulse>
        </StyledBotActionButton>
      </Tooltip>
      <Tooltip
        title={
          !hasContabilAccess
            ? "Para que essa função seja ativada, você necessita estar conectado à um escritório que seja cliente Qyon."
            : "Notificações do contábil"
        }
      >
        <StyledBotActionButton
          color="primary"
          aria-label="add"
          size="medium"
          top={"48"}
          right="210"
          hasAccess={hasContabilAccess}
          onClick={
            hasContabilAccess
              ? () => {
                  setOpenActionModal(true);
                  setActionModalProduct("QYON_CONTABIL");
                }
              : () => {}
          }
        >
          <Pulse active={animation === 6}>
            <Grid alignItems="center" container justifyContent="center">
              <NotificationBadge
                count={0}
                effect={Effect.SCALE}
                key={customKey}
              />
              <IconsMenu icon="NewContabilIcon" color="#f8c880" />
              {/* ADD ICON */}
            </Grid>
          </Pulse>
        </StyledBotActionButton>
      </Tooltip>
      <Tooltip
        title={
          isInClientView
            ? "Para que essa função seja ativada, você necessita sair da visão de cliente e retornar à visão de contador."
            : "Atendimento e Suporte Técnico dos Produtos Qyon"
        }
      >
        <StyledBotActionButton
          color="primary"
          aria-label="add"
          size="medium"
          hasAccess={!isInClientView}
          onClick={() => {
            if (isInClientView) return;
            AumentarChat("1");
          }}
        >
          <div
            id="chatGeiko"
            className="topcornerGeiko"
            style={{ display: "none", marginRight: 318 }}
          ></div>

          <IconsMenu icon="SupportIcon" color="#0a73db"></IconsMenu>
        </StyledBotActionButton>
      </Tooltip>

      {openOptionModal.includes("interrogationBot") && (
        <InterrogationBot open={openOptionModal} setOpen={setOpenOptionModal} />
      )}

      {openActionModal && (
        <BotNotificationModal
          open={openActionModal}
          product={actionModalProduct}
          onDecreaseBadge={onDecreaseBadge}
          onCloseActionModal={onCloseActionModal}
        />
      )}

      {chat.open && <Chat />}
    </StyledBotActionBar>
  );
};
