import { search, searchBlob } from "../../../../BaseRepositoryComponents";

const path = `${process.env.REACT_APP_PAINEL_API_URL}/api/v1/integrations/rpa/entity`;

const newPath = `${process.env.REACT_APP_CND_API_URL}/v1/entity`;

export const INTERNAL_CODES = {
  EntityNotFound: 4001,
};

export async function get({ document }) {
  return search({
    path: `${path}${document ? `/${document}` : ""}`,
  });
}

export async function getEntityConfig({ document }) {
  return search({
    path: `${newPath}/integrations/rpa/${document}`,
  });
}

export async function post({ body }) {
  return search({
    method: "POST",
    path: `${path}`,
    myBody: body,
  });
}

export async function put({ body, document }) {
  return search({
    method: "PUT",
    path: `${path}/${document}`,
    myBody: body,
  });
}

export async function getRequestedSchedulledExecutions({ document }) {
  return search({
    path: `${newPath}/${document}/cnd`,
  });
}

export async function getTargetedSchedulledExecutions({ document }) {
  return search({
    path: `${newPath}/${document}/cnd/favorites`,
  });
}

export async function getPdfLastAgenda({
  parentDocument,
  clientDocument,
  cndId,
}) {
  return searchBlob({
    path: `${newPath}/${parentDocument}/cnd/${clientDocument}/view/${cndId}`,
  });
}

export async function refreshAgenda({ parentDocument, clientDocument, body }) {
  return search({
    path: `${newPath}/${parentDocument}/schedule/${clientDocument}/refresh`,
    method: "POST",
    myBody: body,
  });
}

export async function manualUpload({ document, agendaId, body }) {
  return search({
    path: `${path}/${document}/agendas/${agendaId}`,
    method: "PUT",
    myBody: body,
  });
}

export async function agendasFavorites({ document, schedulleID }) {
  return search({
    path: `${newPath}/favorite/${document}/${schedulleID}`,
    method: "POST",
  });
}

export async function removeCNDFavorite({ document, schedulleID }) {
  return search({
    path: `${newPath}/favorite/${document}/${schedulleID}`,
    method: "DELETE",
  });
}

// export async function agendasFavorites({ document, body }) {
//   return search({
//     path: `${path}/${document}/agendas/favorites`,
//     method: "PUT",
//     myBody: body,
//   });
// }

export async function totalRequestedSchedulledExecutions({ document }) {
  return search({
    path: `${path}/${document}/total-requested-schedulled-executions?agFregateByEntity=true`,
  });
}

export async function totalTargetedSchedulledExecutions({ document }) {
  return search({
    path: `${path}/${document}/total-targeted-schedulled-executions`,
  });
}

export async function sendEmail({ body, parentDocument }) {
  return search({
    method: "POST",
    path: `${newPath}/${parentDocument}/email`,
    myBody: body,
  });
}

export async function getRecentResponses({
  agendaID,
  clientDocument,
  parentDocument,
}) {
  return search({
    path: `${newPath}/${parentDocument}/schedule/${clientDocument}/history/${agendaID}`,
  });
}

export async function getCndFromClient({ document, clientDocument }) {
  return search({
    path: `${newPath}/${document}/cnd/${clientDocument}`,
  });
}

export async function getMyCnds({ document }) {
  return search({
    path: `${newPath}/${document}/cnd/my`,
  });
}

export async function getMyCndsStandAlone({ documentCont, documentClient }) {
  return search({
    path: `${newPath}/${documentCont}/cnd/${documentClient}/my`,
  });
}

export async function getAgendas({ document }) {
  return search({
    path: `${newPath}/${document}/schedule`,
  });
}

export async function listCNDsActiveInactive({ document }) {
  return search({
    path: `${newPath}/${document}/active-cnds`,
  });
}

export async function getGroups({ document }) {
  return search({
    path: `${newPath}/${document}/group`,
  });
}


export async function sendEmailConfirmation(document) {
  return search({
    method: "POST",
    path: `${newPath}/${document}/email/verify`,
    myBody: { document: document},
  });
}