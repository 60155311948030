import {
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@material-ui/core";
import { useContext, useEffect, useState } from "react";
import SelectErp from "../../../components/basics/SelectErp";
import Btn from "../../../components/buttons/Btn";
import {
  GridStyled,
  LabelStyled,
  Margin,
  Title,
} from "../../../styles/GlobalStyle";
import { DashboardSettingContext } from "../contexts/DashboardSettingContext";
import DashboardSettingController from "../controllers/DashboardSettingController";
import { DailogSettings, PreviewWidget } from "../styles/DashboardSettings";

export default function ModalSelectWidget() {
  const [showButton, setShowButton] = useState(false);

  const {
    errors,
    idsWidgetsHomePage,
    setErrors,
    widgetEdit,
    setWidgetEdit,
    versionWidget,
    setVersionWidget,
    loadingSave,
  } = useContext(DashboardSettingContext);
  const { handlePutWidgets, handleDeleteWidgets } =
    DashboardSettingController();

  useEffect(() => setErrors({}), []);

  useEffect(() => {
    const currentVersion =
      (widgetEdit?.widget_versions || []).find((version) =>
        idsWidgetsHomePage.includes(version?.id)
      ) || null;

    setShowButton(currentVersion ? true : false);

    setVersionWidget(currentVersion);

    if (!currentVersion) {
      setVersionWidget(
        widgetEdit.widget_versions[widgetEdit.widget_versions.length - 1]
      );
    }
  }, [widgetEdit?.widget_versions, idsWidgetsHomePage]);

  return (
    <DailogSettings
      open={widgetEdit.id ? true : false}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
    >
      <DialogTitle id="alert-dialog-title">
        <Title subtitle>Visualização Card</Title>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2} justifyContent="center" alignItems="center">
          <PreviewWidget src={versionWidget?.preview} />
          <Margin top={25} />
          <Grid item xs={12}>
            <SelectErp
              error={errors.versionWidget ? true : false}
              helperText={errors.versionWidget || ""}
              label="Versão"
              value={versionWidget}
              setValue={setVersionWidget}
              optionsList={widgetEdit?.widget_versions || []}
              descriptionField="version"
            />
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        {!loadingSave && (
          <>
            {showButton && (
              <Btn
                bg-color="red"
                label="Desabilitar"
                onClick={() => handleDeleteWidgets({})}
              />
            )}

            <Btn
              label="Cancelar"
              bg-color="tertiary"
              onClick={() => setWidgetEdit({})}
            />
          </>
        )}

        <Btn
          label={
            loadingSave ? (
              <>
                <LabelStyled margin="0px 10px 0px 0">Salvando</LabelStyled>
                <CircularProgress style={{ width: 25, height: 25 }} />
              </>
            ) : (
              "Habilitar"
            )
          }
          onClick={loadingSave ? () => {} : () => handlePutWidgets({})}
          bg-color={loadingSave ? "tertiary" : "blue"}
        />
      </DialogActions>
    </DailogSettings>
  );
}
