import { useContext } from "react";

import { putUser } from "../../../repositories/panel/v1/UserRepository";
import { GlobalContext } from "../../../contexts/GlobalContext";

export default function useVideoFirstAccessController() {
  const {
    setUserLogged,
    userLogged,
    // setAnimation,
    // setTextAnimation,
    // setOverlap,
  } = useContext(GlobalContext);

  const handleSubmitVideo = async () => {
    try {
      setUserLogged({ showVideoIntro: "false" });
      initialAnimation();
    } catch (error) {
      console.error(error);
    } finally {
    }
  };

  const initialAnimation = async () => {
    // setOverlap(true);

    // setTimeout(() => {
    //   setTextAnimation("Seja bem vindo(a) ao Qyon Portal");
    // }, 1000);

    // setTimeout(() => {
    //   setTextAnimation("");
    // }, 4000);

    // setTimeout(() => {
    //   setTextAnimation("Vamos conhecer algumas funcionalidades");
    // }, 5000);

    // setTimeout(() => {
    //   setTextAnimation("");
    // }, 9000);

    // setTimeout(() => {
    //   setAnimation(1);
    //   setTextAnimation("Arraste aqui o logo de sua empresa");
    // }, 10000);

    // setTimeout(() => {
    //   setTextAnimation("");
    // }, 14000);

    // setTimeout(() => {
    //   setAnimation(2);
    //   setTextAnimation("Mova os Widgets como quiser");
    // }, 15000);

    // setTimeout(() => {
    //   setTextAnimation("");
    // }, 19000);

    // setTimeout(() => {
    //   setAnimation(3);
    //   setTextAnimation("E após isso, salve como desejar.");
    // }, 20000);

    // setTimeout(() => {
    //   setTextAnimation("");
    // }, 24000);

    // setTimeout(() => {
    //   setAnimation(4);
    //   setTextAnimation("Você ainda pode configurar seu dashboard");
    // }, 25000);

    // setTimeout(() => {
    //   setTextAnimation("");
    // }, 29000);

    // setTimeout(() => {
    //   setTextAnimation("Escolhendo o que quer ou não visualizar.");
    // }, 30000);

    // setTimeout(() => {
    //   setTextAnimation("");
    // }, 34000);

    // setTimeout(() => {
    //   setAnimation(5);
    //   setTextAnimation("Arraste arquivos para o Qyon Bot");
    // }, 35000);

    // setTimeout(() => {
    //   setTextAnimation("");
    // }, 39000);

    // setTimeout(() => {
    //   setTextAnimation("E ele faz o processo automático pra você");
    // }, 40000);

    // setTimeout(() => {
    //   setTextAnimation("");
    // }, 44000);

    // setTimeout(() => {
    //   setAnimation(6);
    //   setTextAnimation("Além de varias outras funcionalidades.");
    // }, 45000);

    // setTimeout(() => {
    //   setTextAnimation("");
    // }, 49000);

    // if (localStorage.getItem("isAccountantPanel") === "true") {
    //   setTimeout(() => {
    //     setAnimation(7);
    //     setTextAnimation("Faça o cadastro de seus clientes.");
    //   }, 50000);

    //   setTimeout(() => {
    //     setTextAnimation("");
    //   }, 54000);
    // }

    // setTimeout(() => {
    //   setAnimation(8);
    //   setTextAnimation("Configure sua empresa e seu usuário");
    // }, 55000);

    // setTimeout(() => {
    //   setTextAnimation("");
    // }, 59000);

    // setTimeout(() => {
    //   setAnimation(9);
    //   setTextAnimation("Além do acesso automático a outros produtos Qyon!");
    // }, 60000);

    // setTimeout(() => {
    //   setAnimation(0);
    //   setTextAnimation("");
    // }, 66000);

    // setTimeout(() => {
    //   setTextAnimation("Tudo isso e muito mais.");
    // }, 67000);

    // setTimeout(async () => {
    // setTextAnimation("");
    // setOverlap(false);
    await putUser({
      body: {
        show_video_intro: false,
        first_name: userLogged.firstName,
        last_name: userLogged.lastName,
        email: userLogged.email,
      },
      id: userLogged.userId,
    });
    // }, 71000);
  };

  return {
    handleSubmitVideo,
  };
}
