import {
  Checkbox,
  CircularProgress,
  DialogActions,
  DialogContent,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  Modal,
  TextField,
  Tooltip,
} from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import {
  ButtonCancel,
  ButtonSave,
  CloseIconStyle,
  DialogHeader,
  DialogStyle,
} from "../../../components/modal/Modal";
import SubdirectoryArrowLeftIcon from "@material-ui/icons/SubdirectoryArrowLeft";
import { Add } from "@material-ui/icons";
import MaskService from "../../../utils/mask/maskService";
import { downloadPDF } from "../../../repositories/panel/v1/DFeRepository";
import {
  GridDuplicataContainer,
  IconButtonDuplicata,
  LabelBillToPay,
  ListDuplicatas,
} from "../styles/BillToPay";
import { GlobalContext } from "../../../contexts/GlobalContext";
import PreviewIcon from "../../../components/icons/PreviewIcon";
import CloseIcon from "../../../components/icons/CloseIcon";
import { notify } from "../../../utils/notify";
import WarningIcon from "../../../components/icons/WarningIcon";
import { getAccountantClientDataById } from "../../../repositories/portal_api/AccountantClient";
import { BillToPayContext } from "../contexts/BillToPayContext";

export const DialogSendBillToPay = ({
  open,
  handleClose,
  handleSend,
  dfeData,
  dfeId,
  isAlreadySended,
  ...props
}) => {
  const [emit, setEmit] = useState("");
  const [dest, setDest] = useState("");
  const [nNF, setNNF] = useState("");
  const [chNFe, setChNFe] = useState("");
  const [duplicatas, setDuplicatas] = useState([]);

  const [isLoadingPdf, setIsLoadingPdf] = useState(false);

  const [registerProducts, setRegisterProducts] = useState(false);
  const [registerSupplier, setRegisterSupplier] = useState(true);
  const [isSendToBillToPay, setIsSendToBillToPay] = useState(true);

  const { companyUser } = useContext(GlobalContext);
  const { setLoadingPage } = useContext(BillToPayContext);

  useEffect(() => {
    if (isSendToBillToPay) {
      setRegisterSupplier(true);
    }
  }, [isSendToBillToPay]);

  useEffect(() => {
    if (dfeData) {
      setEmit(dfeData?.json?.nfeProc?.NFe?.infNFe?.emit?.xNome);
      setDest(dfeData?.json?.nfeProc?.NFe?.infNFe?.dest?.xNome);
      setNNF(dfeData?.json?.nfeProc?.NFe?.infNFe?.ide?.nNF);
      setChNFe(dfeData?.json?.nfeProc?.protNFe?.infProt?.chNFe);
      if (dfeData?.json?.nfeProc?.NFe?.infNFe?.cobr?.dup) {
        if (
          typeof dfeData?.json?.nfeProc?.NFe?.infNFe?.cobr?.dup === "object"
        ) {
          setDuplicatas([
            {
              nDup: dfeData?.json?.nfeProc?.NFe?.infNFe?.cobr?.dup?.nDup,
              dVenc: dfeData?.json?.nfeProc?.NFe?.infNFe?.cobr?.dup?.dVenc,
              vDup: MaskService.toMask(
                "decimal",
                dfeData?.json?.nfeProc?.NFe?.infNFe?.cobr?.dup?.vDup
              ),
            },
          ]);
        } else if (
          Array.isArray(dfeData?.json?.nfeProc?.NFe?.infNFe?.cobr?.dup)
        ) {
          let formattedDups =
            dfeData?.json?.nfeProc?.NFe?.infNFe?.cobr?.dup.map((item) => {
              return {
                nDup: item.nDup,
                dVenc: item.dVenc,
                vDup: MaskService.toMask("decimal", item.vDup),
              };
            });
          setDuplicatas(formattedDups);
        }
      }
    }
    // eslint-disable-next-line
  }, [open]);
  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogStyle style={{ width: 1000 }}>
          <DialogHeader id="alert-dialog-title">
            <div className="row">
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginBottom: 20,
                }}
              >
                Envio para o Gestão Fácil
              </div>
              <CloseIconStyle
                style={{ marginRight: "6px" }}
                onClick={() => {
                  handleClose();
                }}
              />
            </div>
          </DialogHeader>
          <DialogContent>
            {isAlreadySended && (
              <div
                style={{
                  background: "#FFF7E8",
                  color: "#EE7F00",
                  borderRadius: 8,
                  display: "flex",
                  alignItems: "center",
                  marginBottom: 16,
                  padding: "10px 16px",
                }}
              >
                <WarningIcon />
                <h3
                  style={{
                    color: "#EE7F00",
                    marginTop: 0,
                    marginBottom: 0,
                    marginRight: 0,
                    marginLeft: 8,
                    padding: 0,
                  }}
                >
                  Atenção: Esta nota já foi enviada para o Gestão Fácil
                </h3>
              </div>
            )}
            <div style={{ display: "flex", gap: 10 }}>
              <Grid container>
                <Grid item xs={12}>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <h3 style={{ marginTop: 0 }}>Dados</h3>
                        <div>
                          <Tooltip title="Visualizar PDF DANFE">
                            <IconButton
                              size="small"
                              onClick={() => {
                                setIsLoadingPdf(true);
                                downloadPDF(dfeId)
                                  .then((response) => {
                                    console.log(response);
                                    const fileURL =
                                      URL.createObjectURL(response);
                                    window.open(fileURL, "_blank");
                                  })
                                  .catch((error) => {
                                    console.log(error);
                                  })
                                  .finally(() => {
                                    setIsLoadingPdf(false);
                                  });
                              }}
                            >
                              {isLoadingPdf ? (
                                <CircularProgress size={25} color="primary" />
                              ) : (
                                <PreviewIcon fill="#616161" />
                              )}
                            </IconButton>
                          </Tooltip>
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={12}>
                      <LabelBillToPay>Emitente</LabelBillToPay>
                      <TextField
                        value={emit}
                        variant="outlined"
                        fullWidth
                        size="small"
                        disabled
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <LabelBillToPay>Destinatário</LabelBillToPay>
                      <TextField
                        value={dest}
                        variant="outlined"
                        fullWidth
                        size="small"
                        disabled
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <LabelBillToPay>Número da Nota Fiscal</LabelBillToPay>
                      <TextField
                        value={nNF}
                        variant="outlined"
                        fullWidth
                        size="small"
                        disabled
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <LabelBillToPay>Chave de Acesso</LabelBillToPay>
                      <TextField
                        value={chNFe}
                        variant="outlined"
                        fullWidth
                        size="small"
                        disabled
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={registerSupplier}
                            disabled={isSendToBillToPay}
                            onChange={(e) =>
                              setRegisterSupplier(e.target.checked)
                            }
                            color="primary"
                          />
                        }
                        label="Cadastrar fornecedor"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={registerProducts}
                            onChange={(e) =>
                              setRegisterProducts(e.target.checked)
                            }
                            color="primary"
                          />
                        }
                        label="Cadastrar produtos"
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Divider orientation="vertical" flexItem />

              <Grid container>
                <Grid item xs={12}>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <h3 style={{ marginTop: 0 }}>Duplicatas</h3>
                        <div>
                          <IconButton
                            style={{ background: "#0036B3", color: "#FFF" }}
                            size="small"
                            onClick={() => {
                              setDuplicatas([
                                ...duplicatas,
                                {
                                  nDup:
                                    duplicatas.length + 1 < 10
                                      ? "00" + (duplicatas.length + 1)
                                      : duplicatas.length + 1 < 100
                                      ? "0" + (duplicatas.length + 1)
                                      : duplicatas.length + 1,
                                  dVenc: "",
                                  vDup: MaskService.toMask("decimal", "0"),
                                },
                              ]);
                            }}
                          >
                            <Add />
                          </IconButton>
                        </div>
                      </div>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      style={{ maxHeight: 305, minHeight: 305 }}
                    >
                      {duplicatas.length === 0 && (
                        <div
                          style={{
                            color: "#616161",
                          }}
                        >
                          Adicione duplicatas para enviar ao contas a pagar
                        </div>
                      )}
                      <ListDuplicatas
                        style={{
                          paddingRight: 5,
                          maxHeight: 302,
                          minHeight: 302,
                          overflowY: "auto",
                          overflowX: "hidden",
                        }}
                      >
                        {duplicatas.map((item, index) => {
                          return (
                            <>
                              <GridDuplicataContainer container key={index}>
                                <Grid container spacing={1}>
                                  <Grid item xs={1}></Grid>
                                  <Grid item xs={2}>
                                    <LabelBillToPay>Número</LabelBillToPay>
                                    <TextField
                                      value={item.nDup}
                                      onChange={(e) => {
                                        duplicatas[index].nDup = e.target.value;
                                        setDuplicatas([...duplicatas]);
                                      }}
                                      variant="outlined"
                                      fullWidth
                                      size="small"
                                    />
                                  </Grid>
                                  <Grid item xs={4}>
                                    <LabelBillToPay>Vencimento</LabelBillToPay>
                                    <TextField
                                      type="date"
                                      value={item.dVenc}
                                      onChange={(e) => {
                                        duplicatas[index].dVenc =
                                          e.target.value;
                                        setDuplicatas([...duplicatas]);
                                      }}
                                      variant="outlined"
                                      fullWidth
                                      size="small"
                                    />
                                  </Grid>
                                  <Grid item xs={4}>
                                    <LabelBillToPay>Valor</LabelBillToPay>
                                    <TextField
                                      value={item.vDup}
                                      onChange={(e) => {
                                        duplicatas[index].vDup =
                                          MaskService.toMask(
                                            "decimal",
                                            e.target.value
                                          );
                                        setDuplicatas([...duplicatas]);
                                      }}
                                      variant="outlined"
                                      fullWidth
                                      size="small"
                                    />
                                  </Grid>
                                  <Grid
                                    item
                                    xs={1}
                                    container
                                    justifyContent="flex-end"
                                    alignItems="flex-start"
                                  >
                                    <div>
                                      <IconButtonDuplicata
                                        onClick={() => {
                                          setDuplicatas(
                                            duplicatas.filter(
                                              (_, i) => i !== index
                                            )
                                          );
                                        }}
                                      >
                                        <CloseIcon
                                          stroke="#616161"
                                          height={15}
                                          width={15}
                                        />
                                      </IconButtonDuplicata>
                                    </div>
                                  </Grid>
                                </Grid>
                              </GridDuplicataContainer>
                            </>
                          );
                        })}
                      </ListDuplicatas>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={isSendToBillToPay}
                            onChange={(e) =>
                              setIsSendToBillToPay(e.target.checked)
                            }
                            color="primary"
                          />
                        }
                        label="Enviar duplicatas ao Contas a Pagar"
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </DialogContent>
          <DialogActions style={{ paddingTop: 24, paddingBottom: 24 }}>
            <ButtonCancel
              style={{
                marginRight: 24,
                float: "right",
              }}
              onClick={handleClose}
              startIcon={<SubdirectoryArrowLeftIcon />}
            >
              Cancelar
            </ButtonCancel>
            <ButtonSave
              style={{
                float: "right",
                right: 14,
              }}
              onClick={() => {
                if (isSendToBillToPay) {
                  if (duplicatas?.length === 0) {
                    return notify(
                      "Para enviar ao contas a pagar, adicione ao menos uma duplicata"
                    );
                  }
                }

                setLoadingPage(true);

                getAccountantClientDataById(companyUser.companyId)
                  .then((response) => {
                    if (response?.success) {
                      if (
                        !response?.data?.gf_plan ||
                        response?.data?.gf_plan === "QYON Gestão Fácil Emissor"
                      ) {
                        return notify(
                          "Parece que você não possuí esta funcionalidade habilitada em seu plano, entre em contato com nosso comercial para mais informações."
                        );
                      }

                      const data = {
                        id: dfeId,
                        accountantDocument: companyUser.isAccountant
                          ? companyUser.document
                          : companyUser.accountantCnpj,
                        duplicatas,
                        products: registerProducts,
                        provider: registerSupplier,
                        sendToBillToPay: isSendToBillToPay,
                      };
                      handleSend(data);
                    }
                  })
                  .catch((error) => {
                    console.error(error);
                    notify(
                      "Erro ao enviar nota ao contas a pagar",
                      true,
                      "error"
                    );
                  })
                  .finally(() => {
                    setLoadingPage(false);
                  });
              }}
            >
              Enviar
            </ButtonSave>
          </DialogActions>
        </DialogStyle>
      </Modal>
    </>
  );
};
