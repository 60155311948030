import React, { useEffect, useRef, useState } from "react";
// import HTMLEditor from 'mui-rte';
// Import Icons from material ui
import ArrowDropDownCircleIcon from "@material-ui/icons/ArrowDropDownCircle";
import parse from "html-react-parser";
// Import components from material ui
import { Fab, Grid } from "@material-ui/core";

import "../styles/Attendance.css";

import useAttendanceController from "../controllers/AttendanceController";
import { CardMessage, ContainerMessages } from "./styles/InteractionHistoric";

export default function InteractionHistoricTicket({
  messages,
  uploadedFiles = [],
  originRender,
}) {
  const [viewMore, setViewMore] = useState(5);
  const { handleShowFile } = useAttendanceController();
  const mainRef = useRef(null);

  useEffect(() => {
    renderMessages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewMore, messages]);

  const handleScroll = () => {
    mainRef.current.scrollTop = mainRef.current.scrollHeight;
  };

  const styleByOriginRender = {
    accordion: { position: "absolute", bottom: 20, right: 40 },
    previewMessages: { position: "absolute", bottom: 70, right: 40 },
  };

  const Message = ({ typeMessage, message, date, username, privateFlag }) => {
    if (!message) return <></>;

    return (
      <>
        <CardMessage privateFlag={privateFlag} typeMessage={typeMessage}>
          <Grid item xs={8}>
            <Grid className="messageContent">
              <b>
                {username}
                <br />
              </b>

              <p>{message && parse(message)}</p>
            </Grid>
            {date && <Grid className="date">{date}</Grid>}
          </Grid>
        </CardMessage>
      </>
    );
  };

  const returnHtmlFiles = ({
    base64,
    fileId,
    fileName,
    fileMimeType,
    fileExtension,
    pathFile,
  }) => {
    let src;
    if (fileMimeType.includes("image")) {
      src = handleShowFile(base64, fileMimeType);
      return `<img style="max-width: 100%; display: block; object-fit: contain;" id='file_s3-${fileId}' class='messagesImages' src='${
        src ? src : pathFile
      }'/>`;
    } else {
      return `<a style='color: #f5f5f9' href='${pathFile}' target="_blank" >${fileName}</a>`;
    }
  };

  const renderMessages = () => {
    if (!messages || !messages.length) return null;
    const messagesView = messages.slice(0).slice(-viewMore);

    return messagesView.map((message) => {
      if (message?.htmlDescription) {
        message.description = message?.htmlDescription;
      }
      if (
        message?.description !== null &&
        uploadedFiles &&
        uploadedFiles?.length > 0
      ) {
        uploadedFiles?.forEach((file) => {
          if (message?.description.includes(file.id)) {
            const htmlResult = returnHtmlFiles({
              base64: file?.elementBase64,
              fileId: file?.id,
              fileName: file?.name,
              fileMimeType: file?.file?.type,
              fileExtension: file?.file?.extension,
              pathFile: file?.preview,
            });
            return (message.description = message?.description.replace(
              `<div class=divHistoricoEtapasAnexo>{{Arquivo: ${
                message?.description.includes(file.id) && file.id
              }}}</div>`,
              htmlResult ? htmlResult : "Arquivo não encontrado!"
            ));
          }
        });
      }

      const type = message?.flagAcl ? "customer" : "attendant";

      return (
        <>
          <Message
            privateFlag={message?.privateFlag}
            typeMessage={type}
            message={message?.description}
            attachmentMessage={message?.attachment}
            date={message.dateTime.replace(/[a-zA-Z]+/gm, " ").split(".")[0]}
            username={message?.username || message?.contact}
          />
        </>
      );
    });
  };

  const LoadMessages = () => {
    return (
      <Grid id="containerMessages" item xs={12} rowSpacing={50} ref={mainRef}>
        {renderMessages()}

        <Fab
          size="small"
          aria-label="Descer"
          color="grayPrimary"
          style={styleByOriginRender[originRender]}
          onClick={() => {
            handleScroll();
          }}
        >
          <ArrowDropDownCircleIcon />
        </Fab>
      </Grid>
    );
  };

  return (
    <ContainerMessages>
      <>
        {viewMore < messages?.length && (
          <div className="viewMore" onClick={() => setViewMore(viewMore + 5)}>
            Ver Mais
          </div>
        )}
        <LoadMessages />
      </>
    </ContainerMessages>
  );
}
