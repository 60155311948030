import React from "react";

import LinkIcon from "@material-ui/icons/Link";
import { Checkbox, Grid, Tooltip, makeStyles } from "@material-ui/core";

import { notify } from "../../../utils/notify";

import { v4 as uuidv4 } from "uuid";
import filesize from "filesize";
import Swal from "sweetalert2";
import Dropzone from "react-dropzone";
import {
  ContainerIndex,
  Content,
  DropContainer,
  UploadMessage,
  Container,
  FileInfo,
  Preview,
} from "./styles/UploadFiles";

export default function UploadFiles({
  uploadedFiles = [],
  setUploadedFiles = () => {},
  accept = "image/*",
  label = "Arraste arquivos aqui...",
  multiple = false,
  main,
  ...props
}) {
    const useStyle = ((theme) => ({
        preview: props.previewStyle,
    }))

    const classes = useStyle();

  const handleUpload = (files) => {
    if (!multiple) {
      const file = files[0];

      setUploadedFiles([
        {
          file,
          id: uuidv4(),
          name: file.name,
          readableSize: filesize(file.size),
          preview: URL.createObjectURL(file),
          uploaded: false,
          deleted: false,
          error: false,
          isInteractionAttendance: false
        },
      ]);

      return;
    }

    const uploadeds = files.map((file) => ({
      file,
      id: uuidv4(),
      name: file.name,
      readableSize: filesize(file.size),
      preview: URL.createObjectURL(file),
      uploaded: false,
      deleted: false,
      error: false,
      isInteractionAttendance: false
    }));

    setUploadedFiles(uploadedFiles.concat(uploadeds));
  };

  return (
    <ContainerIndex>
      <Content>
        <Dropzone maxFiles={5}  maxSize={8000000} accept={accept} onDropAccepted={handleUpload}>
          {({ getRootProps, getInputProps, isDragActive, isDragReject }) => (
            <DropContainer
            style={{height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}
              {...getRootProps()}
              isDragActive={isDragActive}
              isDragReject={isDragReject}
            >
              <input {...getInputProps()} multiple={multiple} />
              <UploadMessage>
                {isDragActive && !isDragReject
                  ? "Solte os arquivos aqui"
                  : isDragReject
                  ? "Arquivo não suportado"
                  : label}
              </UploadMessage>
            </DropContainer>
          )}
        </Dropzone>
      </Content>
    </ContainerIndex>
  );
}
