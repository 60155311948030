import { useContext, useEffect, useState } from "react";
import { Margin } from "../../styles/GlobalStyle";
import {
  Button,
  Grid,
  InputAdornment,
  LinearProgress,
  TextField,
} from "@material-ui/core";
import moment from "moment/moment";
import { GridFooterContainer, GridOverlay } from "@material-ui/data-grid";
import { SearchIcon } from "../../components/icons/SearchIcon";
import {
  BillToPayDataGrid,
  CompanyColumn,
  CompanyDocumentColumn,
  CompanyNameColumn,
  DateColumn,
  DateHourColumn,
  DatePrincipalColumn,
  DocumentNumberColumn,
  FileColumn,
  InputColumn,
  SearchLabel,
} from "./styles/BillToPay";
import { getDFes } from "../../repositories/panel/v1/DFeRepository";
import { ArrowBackIos, ArrowForwardIos } from "@material-ui/icons";
import { GlobalContext } from "../../contexts/GlobalContext";
import { DFEActionsCell } from "./children/DFEActionsCell";

import { SentToErpCell } from "./children/SentToErpCell";

function formatCNPJorCPF(document) {
  return document.length === 14
    ? document.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5")
    : document.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
}

export default function BillToPayListView({ ...props }) {
  const [loading, setLoading] = useState(true);
  // eslint-disable-next-line no-unused-vars
  const [rows, setRows] = useState([]);
  const [tmpRows, setTmpRows] = useState([]);
  const [textFilter, setTextFilter] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [pages, setPages] = useState([0]);
  // eslint-disable-next-line no-unused-vars
  const [pageSize, setPageSize] = useState(100);
  const [paginationKey, setPaginationKey] = useState("");

  const { companyUser } = useContext(GlobalContext);

  let columns = [
    {
      field: "number",
      headerName: "N.",
      minWidth: 50,
      sortable: false,
    },
    {
      field: "file",
      headerName: "Arquivo",
      minWidth: 85,
      sortable: false,
      renderCell: ({ row }) => {
        return <FileColumn>NF-e</FileColumn>;
      },
    },
    {
      field: "type",
      headerName: "Tipo",
      minWidth: 100,
      sortable: false,
      renderCell: ({ row }) => {
        return <InputColumn>Entrada</InputColumn>;
      },
    },
    {
      field: "doc_id",
      headerName: "Chave de acesso",
      flex: 1,
      minWidth: 375,
      sortable: false,
      renderCell: ({ row }) => {
        return <div>{row.doc_id}</div>;
      },
    },
    {
      field: "doc_number",
      headerName: "N. Documento",
      minWidth: 125,
      sortable: false,
      renderCell: ({ row }) => {
        return <DocumentNumberColumn>{row.doc_number}</DocumentNumberColumn>;
      },
    },
    {
      field: "date",
      headerName: "Data de Emissão",
      minWidth: 150,
      sortable: false,
      renderCell: ({ row }) => {
        return (
          <DateColumn>
            <DatePrincipalColumn>
              {formatDate(row.doc_date)}
            </DatePrincipalColumn>
            <DateHourColumn>{formatHour(row.doc_date)}</DateHourColumn>
          </DateColumn>
        );
      },
    },
    {
      field: "company",
      headerName: "Empresa",
      minWidth: 300,
      flex: 1,
      sortable: false,
      renderCell: ({ row }) => {
        return (
          <CompanyColumn>
            <CompanyNameColumn>{row.emit_name}</CompanyNameColumn>
            <CompanyDocumentColumn>
              {formatCNPJorCPF(row.emit_doc)}
            </CompanyDocumentColumn>
          </CompanyColumn>
        );
      },
    },
    {
      field: "sent_to_erp",
      headerName: "Enviado ao Contas a Pagar",
      align: "center",
      minWidth: 100,
      sortable: false,
      renderCell: ({ row }) => {
        return <SentToErpCell row={row} />;
      },
    },
    // {
    //   field: "category",
    //   headerName: "Categoria",
    //   width: 200,
    //   sortable: false,
    //   renderCell: ({ row }) => {
    //     return <CategoriesCell categories={[]} />;
    //   },
    // },
    {
      field: "actions",
      headerName: "Ações",
      minWidth: 170,
      sortable: false,
      renderCell: ({ row }) => {
        return <DFEActionsCell row={row} updateRows={updateRowsSentToErp} />;
      },
    },
  ];

  useEffect(() => {
    setLoading(true);
    let sd = moment().subtract(3000, "days").format("YYYY-MM-DD");
    let ed = moment().format("YYYY-MM-DD");
    setStartDate(sd);
    setEndDate(ed);

    refresh(sd, ed);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateRowsSentToErp = async (id, sent) => {
    setLoading(true);

    let newRows = rows.map((row) => {
      if (row.id === id) {
        row.sent_to_erp = sent;
      }
      return row;
    });

    setRows(newRows);
    setTmpRows(newRows);

    setLoading(false);
  };

  const refresh = async (
    startDate,
    endDate,
    search = null,
    paramPaginationKey = paginationKey
  ) => {
    setLoading(true);

    await getDFes({
      pageSize: pageSize,
      startDate: startDate,
      endDate: endDate,
      paginationKey: paramPaginationKey,
      document: companyUser.document,
      search: search ? search : null,
    })
      .then((dfes) => {
        if (dfes !== null) {
          setPaginationKey(
            dfes.pagination_key === null ? "" : dfes.pagination_key
          );
          let items = [];

          console.log(currentPage === 0 && !paramPaginationKey);
          console.log(currentPage, paramPaginationKey);

          if (!paramPaginationKey) {
            items = [...dfes.dfes];
          } else {
            items = [...rows, ...dfes.dfes];
          }

          for (let i = 0; i < items.length; i++) {
            items[i].number = i + 1;
          }

          console.log(items);

          setRows(items);
          setTmpRows(items);
        }
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const isLastPage = () => {
    const max = Math.max.apply(Math, pages);
    return currentPage === max && paginationKey === "";
  };

  const getNextPage = (page) => {
    if (loading) {
      return;
    }
    if (page in pages) {
      setCurrentPage(page);

      return;
    }

    if (isLastPage()) {
      return;
    }
    setCurrentPage(page);

    setPages([...pages, page]);
    refresh(startDate, endDate);
  };

  const getPreviousPage = (page) => {
    if (page < 0) {
      return;
    }
    setCurrentPage(page);
  };

  const formatDate = (date) => {
    if (!date) {
      return "";
    }
    return moment(date).format("DD/MM/YYYY");
  };

  const formatHour = (date) => {
    if (!date) {
      return "";
    }
    return moment(date).format("HH:mm:ss");
  };

  return (
    <>
      <div
        container
        style={{
          background: "#fff",
          border: "1px solid #E0E0E0",
          borderRadius: 15,
          padding: 15,
          height: "100%",
          flex: 1,
        }}
      >
        <Margin top="10" />
        <Grid container spacing={2} alignItems="flex-end">
          <Grid item xs={4}>
            <SearchLabel>Pesquisar</SearchLabel>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              placeholder="Pesquisar por CPF/CNPJ da empresa ou chave de acesso"
              value={textFilter}
              onChange={(e) => {
                setTextFilter(e.target.value.replace(/[a-zA-Z]+/g, ""));
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <SearchLabel>De</SearchLabel>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              type="date"
              value={startDate}
              onChange={(ev) => {
                setStartDate(ev.target.value);
                setPages([0]);
                setCurrentPage(0);
                refresh(ev.target.value, endDate);
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <SearchLabel>Até</SearchLabel>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              type="date"
              value={endDate}
              onChange={(ev) => {
                setEndDate(ev.target.value);
                refresh(startDate, ev.target.value);
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              fullWidth
              onClick={() => {
                setPages([0]);
                setCurrentPage(0);
                setPaginationKey("");
                refresh(
                  startDate,
                  endDate,
                  textFilter.replace(/[^0-9]+/g, ""),
                  ""
                );
              }}
            >
              <SearchIcon fill="#fff" />
              Pesquisar
            </Button>
          </Grid>
        </Grid>

        <BillToPayDataGrid
          onPageChange={(page) => {
            setCurrentPage(page);
          }}
          rowCount={tmpRows.length}
          disableColumnFilter
          disableColumnMenu
          style={{ marginTop: 20, height: "calc(100% - 65px)" }}
          rows={loading ? [] : tmpRows}
          columns={columns}
          disableSelectionOnClick
          slots={{
            loadingOverlay: LinearProgress,
          }}
          loading={loading}
          pageSize={pageSize}
          page={currentPage}
          components={{
            Footer: () => {
              return (
                <GridFooterContainer>
                  Página {currentPage + 1}
                  <div>
                    <ArrowBackIos
                      onClick={() => {
                        getPreviousPage(currentPage - 1);
                      }}
                      style={{
                        fontSize: 16,
                        marginRight: 20,
                        cursor: "pointer",
                        color:
                          currentPage > 0 ? "black" : "rgba(16, 16, 16, 0.3)",
                      }}
                    />

                    <ArrowForwardIos
                      onClick={() => {
                        getNextPage(currentPage + 1);
                      }}
                      style={{
                        fontSize: 16,
                        cursor: "pointer",
                        color: !isLastPage()
                          ? "black"
                          : "rgba(16, 16, 16, 0.3)",
                      }}
                    />
                  </div>
                </GridFooterContainer>
              );
            },
            NoRowsOverlay: () =>
              !loading && (
                <GridOverlay>
                  <h4>
                    Não foi encontrado nenhuma nota de entrada!<br></br>
                    Para começar a receber as notas, você deve possuir os
                    módulos do gestão fácil e do XML ativados
                  </h4>
                </GridOverlay>
              ),
          }}
        />
      </div>
    </>
  );
}
