import React, { useContext, useEffect } from "react";

import {
  CircularProgress,
  Grid,
  IconButton,
  LinearProgress,
} from "@material-ui/core";
import AddCircleOutline from "@material-ui/icons/AddCircleOutline";
import BarChartRoundedIcon from "@material-ui/icons/BarChartRounded";
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import StarIcon from "@material-ui/icons/Star";
import StarBorderIcon from "@material-ui/icons/StarBorder";

import Loading from "../../components/basics/Loading";
import SelectErp from "../../components/basics/SelectErp";
import Btn from "../../components/buttons/Btn";
import { GlobalContext } from "../../contexts/GlobalContext";
import { getPanelsUser } from "../../repositories/panel/v1/UserRepository";
import {
  getHomePageWidgets,
  getWidgets,
} from "../../repositories/panel/v1/WidgetRepository";
import {
  ContainerIcon,
  GridStyled,
  LightTooltip,
  Margin,
  Title,
} from "../../styles/GlobalStyle";
import { notify } from "../../utils/notify";
import { usePortalAsClient } from "../../utils/viewPortalAsClientHook";
import ListCard from "./children/ListCard";
import ModalPanel from "./children/ModalPanel";
import ModalSelectWidget from "./children/ModalSelectWidget";
import { DashboardSettingContext } from "./contexts/DashboardSettingContext";
import useDashboardSettingController from "./controllers/DashboardSettingController";

export default function DashboardSetting() {
  const {
    setWidgets,
    setIdsWidgetsHomePage,
    panelSelected,
    setPanelSelected,
    panelsList,
    copyPanelSelected,
    setCopyPanelSelected,
    setPanelsList,
    widgetEdit,
    modalPanel,
    setModalPanel,
    loading,
    setLoading,
    loadingInputs,
    setLoadingInputs,
    refreshPanel,
  } = useContext(DashboardSettingContext);
  const { handleNewPanel, handleDeletePanel, handleFavoritePanel } =
    useDashboardSettingController();
  const { userLogged, companyUser } = useContext(GlobalContext);
  const { isInClientView, clientViewed } = usePortalAsClient();

  useEffect(() => {
    setCopyPanelSelected(null);
    setPanelSelected(
      panelsList.find((p) => p.favorited) || panelsList[0] || null
    );
  }, [panelsList]);

  useEffect(() => {
    (async () => {
      try {
        setLoadingInputs((current) => ({ current, listPanels: true }));

        const panelsResp = await getPanelsUser({
          id: isInClientView ? clientViewed?.id : userLogged?.userId,
          ownerentity: isInClientView && clientViewed?.company,
        });

        if (panelsResp?.success && Array.isArray(panelsResp?.data)) {
          setPanelsList(panelsResp?.data);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoadingInputs((current) => ({ current, listPanels: false }));
      }
    })();
  }, [companyUser?.companyId, refreshPanel]);

  useEffect(() => {
    if (!panelSelected) {
      setIdsWidgetsHomePage([]);
      setWidgets({});
      setCopyPanelSelected(0);
      return;
    }

    if (copyPanelSelected?.id === panelSelected?.id) return;

    setCopyPanelSelected(panelSelected);

    (async () => {
      try {
        setLoading(true);

        const ids = [];
        const category = {
          ERP: [],
          CRM: [],
          XML: [],
          FOLHA: [],
          Contábil: [],
          "Escrita Fiscal": [],
          OUTROS: [],
        };

        const mainResponse = await getWidgets(
          userLogged.userId,
          companyUser.companyId
        );

        if (!mainResponse?.success || !Array.isArray(mainResponse?.data)) {
          return;
        }

        mainResponse.data.forEach((element) => {
          category[element?.system?.name || "OUTROS"].push(element);
        });

        const respWidgets = await getHomePageWidgets(panelSelected.id);

        if (!respWidgets?.success || !Array.isArray(respWidgets?.data)) {
          return;
        }

        respWidgets.data.forEach((value) => {
          ids.push(value.widget_version.id);
        });

        setIdsWidgetsHomePage(ids);

        setWidgets(category);
      } catch (error) {
        console.error(error);

        notify("Não foi possível obter os dados.");
      } finally {
        setLoading(false);
      }
    })();
  }, [panelSelected]);

  return (
    <>
      <Loading open={loadingInputs?.blockScreen} />
      {widgetEdit?.id && <ModalSelectWidget />}
      {modalPanel && <ModalPanel />}
      <Margin bottom="30">
        <Grid container spacing={5} justifyContent="center">
          <Grid item container>
            <Grid item xs={12}>
              <Title color="blue">Configurações</Title>

              <Margin bottom="10" />
            </Grid>

            <GridStyled
              item
              container
              justifyContent="space-between"
              bg-color="blue"
              spacing={2}
            >
              <Grid item flex={1}>
                <Title subtitle color="white">
                  Configurar Dashboard
                </Title>
              </Grid>
              <Grid item flex={1}>
                <ContainerIcon iconColor="white" cursor="initial">
                  <BarChartRoundedIcon />
                </ContainerIcon>
              </Grid>
            </GridStyled>
          </Grid>

          <Grid item container>
            <GridStyled
              item
              container
              justifyContent="space-between"
              bg-color="white"
              spacing={2}
            >
              <Grid item sm={6} xs={12} flex={1}>
                <Grid item justifyContent="center">
                  <SelectErp
                    loading={loadingInputs?.listPanels}
                    label="Painel"
                    value={panelSelected}
                    setValue={setPanelSelected}
                    optionsList={panelsList || []}
                    descriptionField="name"
                  />
                </Grid>
              </Grid>
              <Grid
                item
                container
                sm={6}
                xs={12}
                justifyContent="flex-end"
                alignItems="center"
                spacing={1}
              >
                {panelSelected && (
                  <Grid item>
                    {loadingInputs?.favorited ? (
                      <CircularProgress
                        style={{
                          width: 26,
                          height: 26,
                        }}
                      />
                    ) : (
                      <LightTooltip
                        title={
                          panelSelected.favorited
                            ? "Remover Favorito"
                            : "Favoritar Painel"
                        }
                      >
                        <IconButton
                          style={{ padding: "5px" }}
                          onClick={() =>
                            handleFavoritePanel(
                              panelSelected?.id,
                              Boolean(!panelSelected.favorited)
                            )
                          }
                        >
                          {panelSelected.favorited ? (
                            <StarIcon
                              style={{
                                color: "#d8bb18",
                                width: 26,
                                height: 26,
                              }}
                            />
                          ) : (
                            <StarBorderIcon
                              style={{
                                color: "#d8bb18",
                                width: 26,
                                height: 26,
                              }}
                            />
                          )}
                        </IconButton>
                      </LightTooltip>
                    )}
                  </Grid>
                )}

                {panelSelected && (
                  <Grid item>
                    <LightTooltip title="Editar Painel Selecionado">
                      <IconButton
                        style={{ padding: "5px" }}
                        onClick={() => setModalPanel(true)}
                      >
                        <EditOutlinedIcon />
                      </IconButton>
                    </LightTooltip>
                  </Grid>
                )}

                {panelSelected && panelsList.length > 1 && (
                  <Grid item>
                    <LightTooltip title="Excluir Painel Selecionado">
                      <IconButton
                        style={{ padding: "5px" }}
                        onClick={() => handleDeletePanel(panelSelected?.id)}
                      >
                        <DeleteOutlineOutlinedIcon />
                      </IconButton>
                    </LightTooltip>
                  </Grid>
                )}

                <Grid item>
                  <Btn
                    label="Criar Painel"
                    startIcon={<AddCircleOutline />}
                    onClick={() => handleNewPanel()}
                  />
                </Grid>
              </Grid>
            </GridStyled>
          </Grid>

          {loading ? (
            <Grid item xs={12}>
              <LinearProgress />
            </Grid>
          ) : (
            <GridStyled
              item
              container
              border-radius="5"
              margin="12"
              bg-color="white"
              spacing={6}
            >
              <ListCard keyList="ERP" title="Gestão Fácil" />

              <ListCard keyList="Escrita Fiscal" title="Escrita Fiscal" />

              <ListCard keyList="Contábil" title="Contabilidade" />

              <ListCard keyList="CRM" title="Gestão de Relacionamento" />

              <ListCard keyList="XML" title="XML" />

              <ListCard keyList="FOLHA" title="Folha" />

              <ListCard keyList="OUTROS" title="Outros" />
            </GridStyled>
          )}
        </Grid>
      </Margin>
    </>
  );
}
