import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  RadioGroup,
} from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import { ERPPlanRadioButton } from "./ERPPlanRadioButton";
import { ConfigureERPContext } from "../contexts/ConfigureERPContext";
import { TableComparisonERPPlans } from "./TableComparisonERPPlans";

import { ReactComponent as IconPlus } from "../../../assets/icons/add-one.svg";
import { ReactComponent as IconMinus } from "../../../assets/icons/minus_icon.svg";
import SelectErp from "../../../components/basics/SelectErp";
import { enableSystemVersionForClient } from "../../../repositories/portal_api/Systems";
import { notify } from "../../../utils/notify";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { GlobalContext } from "../../../contexts/GlobalContext";
import { ERPCardCurrentPlan } from "./ERPCardCurrentPlan";

export const ERPPlansTab = ({
  setLoading,
  accountantClientId,
  configured,
  gfPlan,
  gfPayer,
  lockedGetErp,
  ...props
}) => {
  const [showComparisons, setShowComparisons] = useState(false);
  const [isComparisonExpanded, setIsComparisonExpanded] = useState(false);
  const [checkedConfirmation, setCheckedConfirmation] = useState(false);
  const { erpPlansList, selectedPlan, setSelectedPlan } =
    useContext(ConfigureERPContext);
  const { companyUser } = useContext(GlobalContext);

  const payerOptions = [
    {
      value: "cont",
      description: "Em meu nome",
    },
    //! SEM FLUXO PARA CONTRATAR EM NOME DO CLIENTE
    // {
    //   value: "client",
    //   description: "Em nome do cliente",
    // }
  ];

  const { push } = useHistory();

  const [payer, setPayer] = useState("");

  const handleContractProduct = () => {
    if (checkedConfirmation) {
      setLoading(true);
      const plan = erpPlansList.find(
        (plan) => plan.service_id === selectedPlan
      );

      if (!plan) {
        return notify("Erro ao selecionar o plano", true, "error");
      }

      // required fields "payer", "entity_id", "version_id", "service_id"
      const body = {
        payer: payer.value,
        entity_id: accountantClientId,
        version_id: plan.version_id,
        service_id: plan.service_id,
        accountant_id: companyUser.companyId,
      };

      enableSystemVersionForClient(body)
        .then((resp) => {
          if (resp?.success) {
            notify("Produto contratado com sucesso", true, "success");
            push("/customermanagement/list");
          } else {
            notify("Um erro ocorreu ao contratar o produto", true, "error");
          }
        })
        .catch((error) => {
          console.error(error);
          notify("Um erro ocorreu ao contratar o produto", true, "error");
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      notify("Você precisa concordar com os termos para contratar o produto");
    }
  };

  useEffect(() => {
    setPayer({
      value: "cont",
      description: "Em meu nome",
    });
  }, []);

  useEffect(() => {
    //call api to get plans /accountant
    if (!erpPlansList) {
      return;
    }
    if (erpPlansList.length > 0) {
      const listComparison = erpPlansList.filter(
        (plan) =>
          plan.service_id === 203 ||
          plan.service_id === 204 ||
          plan.service_id === 205
      );

      if (
        erpPlansList.filter((plan) => plan.service_id === 204).length > 0 &&
        !configured
      ) {
        setSelectedPlan(204);
      }

      if (listComparison.length === 3) {
        setShowComparisons(true);
      }
    }
  }, [erpPlansList, setSelectedPlan, configured]);

  return (
    <div style={{ marginTop: 30 }}>
      {configured && (
        <>
          <div>
            <h3 style={{ padding: 0, margin: "0px 0px 5px 0px" }}>
              Plano Atual
            </h3>
            <Divider></Divider>
          </div>
          <div>
            <Grid container spacing={1}>
              <Grid item xs={4} style={{ marginTop: 20, marginBottom: 20 }}>
                <ERPCardCurrentPlan gfPlan={gfPlan} gfPayer={gfPayer} />
              </Grid>
            </Grid>
          </div>
        </>
      )}

      <div>
        <h3 style={{ padding: 0, margin: "0px 0px 5px 0px" }}>
          Selecione um Plano
        </h3>
        <Divider />
      </div>
      <div
        style={{
          marginTop: 20,
        }}
      >
        <RadioGroup name="radio-buttons-group" value={selectedPlan}>
          <Grid container spacing={1}>
            {erpPlansList &&
              erpPlansList.map((plan) => {
                let tagDescription = "";

                switch (plan.service_id) {
                  case 203:
                    tagDescription = "Básico";
                    break;
                  case 204:
                    tagDescription = "Mais Popular";
                    break;
                  case 205:
                    tagDescription = "Avançado";
                    break;
                  default:
                    tagDescription = "Contrate Já";
                    break;
                }

                return (
                  <ERPPlanRadioButton
                    planAmount={plan.price}
                    planName={plan.version_description}
                    selectValue={setSelectedPlan}
                    value={plan.service_id}
                    mostPopular={plan.service_id === 204}
                    tagDescription={tagDescription}
                    disabled={configured}
                  ></ERPPlanRadioButton>
                );
              })}
          </Grid>
        </RadioGroup>

        {lockedGetErp && (
          <Grid container>
            <Grid item xs={12}>
              <p style={{ color: "gray" }}>
                *Parece que seu contrato não possibilita que você libere versões
                do Gestão Fácil ERP para seus clientes. Entre em contato com
                nosso comercial para mais informações.
              </p>
            </Grid>
          </Grid>
        )}
      </div>

      {showComparisons && (
        <div style={{ marginTop: 48 }}>
          <Accordion
            expanded={isComparisonExpanded}
            onChange={() => {
              setIsComparisonExpanded(!isComparisonExpanded);
            }}
            variant="outlined"
          >
            <AccordionSummary>
              <div style={{ marginRight: 10 }}>
                {isComparisonExpanded ? <IconMinus /> : <IconPlus />}
              </div>

              <h3 style={{ padding: 0, margin: "0px 0px 5px 0px" }}>
                Compare os planos e atributos de cada versão
              </h3>
            </AccordionSummary>
            <AccordionDetails>
              <TableComparisonERPPlans />
            </AccordionDetails>
          </Accordion>
        </div>
      )}

      {!lockedGetErp && (
        <>
          <div style={{ marginTop: 30 }}>
            <h3 style={{ padding: 0, margin: "0px 0px 5px 0px" }}>
              Contratar Gestão Fácil ERP
            </h3>
            <Divider />
          </div>

          <div
            style={{
              marginTop: 20,
              background: "#fff",
              border: "1px solid #E0E0E0",
              borderRadius: 15,
              padding: 24,
            }}
          >
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <div>Forma de Pagamento</div>
                <SelectErp
                  fullWidth
                  variant="outlined"
                  value={payer}
                  setValue={setPayer}
                  optionsList={payerOptions}
                  descriptionField="description"
                  disabled
                ></SelectErp>
              </Grid>
            </Grid>
            <Grid container style={{ marginTop: 20 }}>
              <Grid item xs={8}>
                <FormControlLabel
                  disabled={configured || lockedGetErp}
                  value={checkedConfirmation}
                  onClick={(e) => {
                    if (configured) return;
                    setCheckedConfirmation(e.target.checked);
                  }}
                  control={<Checkbox color="primary" />}
                  label="Concordo que estou de acordo com a inclusão do aditivo contratual referente a liberação do produto selecionado em meu contrato atual, o que pode implicar em alterações de preços e/ou serviços."
                />
              </Grid>
              <Grid item xs={4} container direction="column">
                <div
                  style={{
                    height: "100%",
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "flex-end",
                  }}
                >
                  <Button
                    disabled={!checkedConfirmation || configured}
                    style={{
                      textTransform: "none",
                      background: checkedConfirmation ? "#0036b3" : "#BDBDBD",
                      color: "#FFF",
                      maxHeight: 40,
                    }}
                    onClick={() => {
                      if (configured) return;
                      handleContractProduct();
                    }}
                  >
                    Contratar Gestão Fácil (ERP)
                  </Button>
                </div>
              </Grid>
            </Grid>
          </div>
        </>
      )}
    </div>

    /*
    {
    "success": true,
    "code": 200,
    "internalCode": 0,
    "msg": "",
    "message": "",
    "data": [
        {
            "PrecosServicosId": 5,
            "Preco": 49.9,
            "IdServico": 203,
            "DescricaoServico": "QYON Gest\u00e3o F\u00e1cil Emissor",
            "IdVersao": 43,
            "DescricaoVersao": "QYON Gest\u00e3o F\u00e1cil Emissor"
        },
        {
            "PrecosServicosId": 6,
            "Preco": 99.9,
            "IdServico": 204,
            "DescricaoServico": "QYON Gest\u00e3o F\u00e1cil Essencial",
            "IdVersao": 44,
            "DescricaoVersao": "QYON Gest\u00e3o F\u00e1cil Essencial"
        },
        {
            "PrecosServicosId": 7,
            "Preco": 149.9,
            "IdServico": 205,
            "DescricaoServico": "QYON Gest\u00e3o F\u00e1cil Avan\u00e7ado",
            "IdVersao": 45,
            "DescricaoVersao": "QYON Gest\u00e3o F\u00e1cil Avan\u00e7ado"
        }
    ],
    "totalindata": 3
}
    */
  );
};
