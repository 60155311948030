import styled from "styled-components";

export const ListStyled = styled.ul`
  list-style: none;
  padding-left: 0;
`;

export const ItemListStyled = styled.li`
  display: flex;
  align-items: center;
  border: solid 2px #d0d0d0;
  border-radius: 0.2em;
  margin-bottom: 1em;
`;
