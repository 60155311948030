import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  InputLabel,
  List,
  MenuItem,
  Select,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { ExpandMore as ExpandMoreIcon } from "@material-ui/icons";
import Close from "@material-ui/icons/Close";
import React from "react";
import { useAuth } from "../../../contexts/crm/Auth";
import { ContainerIcon } from "../../../styles/GlobalStyle";
import { useChatContext } from "../contexts/ChatContext";
import ContactController from "../controllers/ContactController";
import SettingsController from "../controllers/SettingsController";
import useWebSocketClient from "../controllers/WebSocketClient";
import { AnimationSpin } from "../styles/Animations";
import { calcWaitingTime } from "../utils";

export default function Settings() {
  const { saveStatus, saveAttendanceParameters, saveNotificationsParameters } =
    SettingsController();
  const webSocketClient = useWebSocketClient();
  const { fetchQueue } = ContactController();

  const { settings, setSettings, chat, setChat, SCREEN_ENUM, setAccordion } =
    useChatContext();

  const { company } = useAuth();

  const { accordion } = settings;

  const ON_OFF = [
    {
      description: "Online",
      state: "0",
    },
    {
      description: "Offline",
      state: "1",
    },
  ];

  const ATTENDANCE_AMOUNT = ["0", "1", "2", "3", "4", "5"];

  const SelectItemList = ({ arrayItens, value, prop, action, label }) => {
    const onChangeSelectValue = (e) => {
      const propNotification =
        prop === "notificationState" ? "state" : "intensity";
      const execFunctions = {
        saveStatus: () => saveStatus({ state: e.target.value, prop: prop }),
        saveAttendanceParameters: () =>
          saveAttendanceParameters({ state: e.target.value, prop: prop }),
        saveNotificationsParameters: () =>
          saveNotificationsParameters({ [propNotification]: e.target.value }),
      };
      execFunctions[action]();
      setSettings({ ...settings, [prop]: e.target.value.toString() });
    };
    return (
      <FormGroup>
        <InputLabel id={`select-label-${prop}`}>{label}</InputLabel>
        <Select
          labelId={`select-label-${prop}`}
          value={value}
          defaultValue={value}
          onChange={onChangeSelectValue}
          label={label}
          fullWidth
        >
          {arrayItens.map((item) => (
            <MenuItem value={item.state || item}>
              {prop === "notificationState"
                ? item.state === "0"
                  ? "Sim"
                  : "Não"
                : item.description || item}
            </MenuItem>
          ))}
        </Select>
      </FormGroup>
    );
  };

  const checkDepartament = async (e) => {
    if (e.target && e.target.defaultValue) {
      const idElement = e.target.defaultValue.replace("checked-", "");
      const queueId = `${company.id}-${chat.roomId}-${idElement}`;
      const topic =
        webSocketClient.TOPICS.subscriptions.chatQueueAttendant(queueId);
      if (e.target.checked) {
        if (!webSocketClient.isSubscribedOn(topic)) {
          webSocketClient.subscribe(topic, (data) => {
            const {
              created_at,
              session_id,
              name_customer,
              username,
              product_Name,
              action,
            } = data;
            if (action === "newQueue") {
              const queueItem = [...chat.queue];
              queueItem.push({
                name: username,
                companyName: name_customer,
                waitingTime: calcWaitingTime(created_at),
                productName: product_Name,
                created_at,
                sessionId: session_id,
              });

              setChat({ queue: queueItem });
            } else {
              let newQueueList = chat.queue.filter((elem) => {
                return elem.sessionId !== session_id;
              });

              setChat({ queue: newQueueList });
            }
          });
        }
      } else {
        if (webSocketClient.isSubscribedOn(topic)) {
          webSocketClient.removeListeners(topic);
        }
      }

      settings.departments[
        settings.departments.indexOf(
          settings.departments.find((each) => each.id == idElement)
        )
      ].selected = e.target.checked;
      setSettings({ departments: [...settings.departments] });
      await saveAttendanceParameters({
        state: settings.departments,
        prop: "department",
        action: e.target.checked ? "insert" : "delete",
      });

      await fetchQueue();
    }
  };

  const GroupList = ({ children }) => {
    return chat.isLoading ? (
      <Grid
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
        }}
      >
        <AnimationSpin />
      </Grid>
    ) : (
      <div
        style={{
          width: "100%",
          overflowY: "scroll",
          maxHeight: "445.5px",
          justifyContent: "flex-end",
        }}
      >
        <Grid
          item
          container
          xs
          justifyContent="flex-end"
          style={{ height: "35px" }}
        >
          <Tooltip title="Fechar">
            <ContainerIcon
              onClick={() =>
                setChat({
                  currentScreen: SCREEN_ENUM.conversation,
                })
              }
              // style={{ borderRadius: '20%'}}
              iconColor="#CCCFD6"
              fontSize="20"
            >
              <IconButton>
                <Close />
              </IconButton>
            </ContainerIcon>
          </Tooltip>
        </Grid>
        <List
          style={{ width: "100%", overflowY: "scroll" }}
          component="nav"
          aria-labelledby="nested-list-subheader"
        >
          {children}
        </List>
      </div>
    );
  };

  const Group = ({ title, icon, expanded, children, onClick }) => {
    return (
      <Accordion
        expanded={expanded}
        style={{
          width: "320px",
          marginLeft: "auto",
          marginRight: "auto",
          marginBottom: "15px",
        }}
      >
        <AccordionSummary
          expandIcon={icon}
          style={{ maxHeight: "48px" }}
          onClick={onClick}
        >
          <Grid
            style={{
              backgroundColor: "#0A73DB",
              width: "5px",
              height: "30px",
              marginRight: "5px",
            }}
          ></Grid>
          <Typography>{title}</Typography>
        </AccordionSummary>
        <AccordionDetails>{children}</AccordionDetails>
      </Accordion>
    );
  };

  const GroupStatus = () => {
    return (
      <Group
        title={"Status"}
        icon={<ExpandMoreIcon />}
        expanded={accordion.status}
        onClick={() => setAccordion({ accordion, status: !accordion.status })}
      >
        <Grid container>
          <Grid item xs={12}>
            <SelectItemList
              arrayItens={ON_OFF}
              prop={"roomState"}
              value={settings.roomState}
              action="saveStatus"
              label="Sala"
            />
          </Grid>
          <Grid item xs={12} style={{ marginBottom: "15px" }}></Grid>
          <Grid item xs={12}>
            <SelectItemList
              arrayItens={settings.reason}
              prop={"myState"}
              value={settings.myState}
              action="saveStatus"
              label="Meu Status"
            />
          </Grid>
        </Grid>
      </Group>
    );
  };

  const GroupAttendance = () => {
    return (
      <Group
        title={"Atendimento"}
        icon={<ExpandMoreIcon />}
        expanded={accordion.attendance}
        onClick={() =>
          setAccordion({ accordion, attendance: !accordion.attendance })
        }
      >
        <Grid container>
          <Grid item xs={12}>
            <SelectItemList
              arrayItens={ATTENDANCE_AMOUNT}
              prop={"attendanceAmount"}
              value={settings.attendanceAmount}
              action="saveAttendanceParameters"
              label="Clientes Simultâneos"
            />
          </Grid>
          <Grid item xs={12} style={{ marginBottom: "20px" }}></Grid>
          <Grid item xs={12} style={{ textAlign: "left" }}>
            Departamentos
          </Grid>
          <Grid item xs={12} style={{ paddingLeft: "10px" }}>
            <FormGroup>
              {settings.departments?.map((departament) => (
                <FormControlLabel
                  style={{ fontSize: "10px" }}
                  control={
                    <Checkbox
                      value={"checked-" + departament.id}
                      color="Primary"
                      defaultChecked={departament.selected}
                    />
                  }
                  onClick={checkDepartament}
                  label={departament.description}
                />
              ))}
            </FormGroup>
          </Grid>
        </Grid>
      </Group>
    );
  };

  return (
    <GroupList>
      <GroupStatus />
      <GroupAttendance />
    </GroupList>
  );
}
