import React, { Fragment, memo } from "react";

import { Box, TextField } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import Autocomplete from "@material-ui/lab/Autocomplete";

import "../../../components/basics/.Basics.css";

const shapeCircleStyles = (color, hasBoxShadow) => {
  return {
    borderRadius: 25,
    bgcolor: `${color}`,
    padding: 10,
    marginRight: 10,
    borderColor: "1px solid black",
    boxShadow: `${hasBoxShadow ? "0px 0px 0px 1px black inset" : ""}`,
  };
};

const AutoCompleteCRM = ({
  optionsList = [],
  descriptionField,
  getOptionLabel,
  size = "small",
  value,
  setValue,
  required = false,
  error = false,
  helperText,
  disabled = false,
  variantAutocomplete,
  iconInfo,
  name,
  getOptionSelected = (option, value) => {
    return option && value ? option.id === value.id : "";
  },
  onChange = (value) => {
    setValue(value);
  },
  loading = false,
  className = "filter-input",
  ...props
}) => {
  // É possível padronizar, colocando um limite de itens mostrados, a maneira de buscar, etc...
  // const filterOptions = createFilterOptions({
  // limit: 50,
  // matchFrom: "start",
  // stringify: (option) => option.title,
  // });
  return (
    <Autocomplete
      name={name}
      key={"filter_autocomplete_" + props.label}
      options={Array.isArray(optionsList) ? optionsList : []}
      value={value}
      size={size}
      disabled={disabled}
      loading={loading}
      defaultValue={props.defaultValue || ""}
      onChange={(event, newValue) => {
        onChange(newValue, name);
      }}
      noOptionsText={"Nenhuma opção"}
      loadingText={`Carregando as opções`}
      getOptionSelected={getOptionSelected}
      getOptionLabel={
        getOptionLabel
          ? getOptionLabel
          : (option) => {
              return option && descriptionField ? option[descriptionField] : "";
            }
      }
      style={{ width: "100%" }}
      {...props}
      renderOption={(option) => {
        return (
          <Fragment>
            {option?.color && (
              <Box
                component="span"
                sx={{
                  ...shapeCircleStyles(option?.color, option?.hasBoxShadow),
                }}
              />
            )}
            {option.title}
          </Fragment>
        );
      }}
      renderInput={(params) => (
        <TextField
          {...props}
          {...params}
          helperText={helperText}
          required={required}
          error={error}
          label={props.label}
          variant={variantAutocomplete || "standard"}
          className={className}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
};

export default memo(AutoCompleteCRM);
