import React, { createContext } from "react";

const CertificateContext = createContext();

const CertificateContextProvider = ({ children }) => {
  return (
    <CertificateContext.Provider value={{}}>
      {children}
    </CertificateContext.Provider>
  );
};

export { CertificateContextProvider, CertificateContext };
