import { Chip, Grid } from "@material-ui/core";
import React from "react";

export const ERPCardCurrentPlan = ({ gfPlan, gfPayer, ...props }) => {
  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      style={{
        padding: 16,
        borderRadius: 10,
        border: "1px solid #E0E0E0",
        background: "#FBFBFD",
        height: "100%",
      }}
    >
      <Grid item xs={8} direction="column">
        <div>Versão:</div>

        <b style={{ fontSize: 16 }}>{gfPlan}</b>
      </Grid>
      <Grid item xs={4} direction="column" style={{ textAlign: "right" }}>
        <div>Pagador: </div>
        <Chip
          size="small"
          style={{
            marginTop: 4,
            fontSize: 10,
            fontWeight: "bold",
            color: gfPayer === "accountant" ? "#0277BD" : "#E65100",
            background: gfPayer === "accountant" ? "#B3E5FC" : "#FFE0B2",
          }}
          label={gfPayer === "accountant" ? "Contador (você)" : "Cliente"}
        ></Chip>
      </Grid>
    </Grid>
  );
};
