import styled from "styled-components";

import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";




export const Title = styled(Typography)`
    font-size: 22px;
    font-weight: bold;
    background: rgb(19, 142, 247);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    padding: 20px;
    color: white;
    margin-bottom: 20px;
`;


export const Subtitle = styled(Typography)`
    margin-bottom: 20px;
    font-weight: bold;
    color: red;
    font-size: 14px;
`;





export const CompanyName = styled(Typography)`
font-size: 16px;
font-weight: bold;
`;


export const GovId = styled(Typography)`
font-size: 14px;
`;

export const MigrateEsocial = styled(Typography)`
font-size: 14px;
font-weight: bold;
`;




export const StyledBox = styled(Box)`
    background-color: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 600px;
    box-shadow: 24px;
    border-radius: 10px;
`;

export const BodyBox = styled(Box)`
    max-height: 400px;
    padding-height: 20px;
    padding-left: 20px;
    padding-right: 20px;
    overflow-y: auto;
    margin-bottom: 10px;
`;



export const Loading = styled(CircularProgress)`
    margin-left: 39%;
    width: 100px !important;
    height: 100px !important;
    margin-top: 74px;
    margin-bottom: 57px;
`;


export const NoButton = styled(Button)`
    background: red;
    font-weight: bold;
    width: 150px;
    color: white;
     &:hover {
        background: red;
    }
`;





export const YesButton = styled(Button)`
    font-weight: bold;
    background: rgb(19, 142, 247);
    color: white;
    width: 150px;
    right: 0;
    float: right;
    &:hover {
        background: rgb(19, 142, 247);
    }
`;





export const RowButton = styled(Box)`
    position: relative;
    right: 10px;
    bottom: 10px;
    float: right;
    margin-top: 20px;
`;




export const CompanyBox = styled(Box)`
    position: relative;
    margin-top: 25px;
    padding-left: 30px;
`;




export const CloseBoxIcon = styled(Box)`
    position: relative;
    margin-top: 25px;
    padding-left: 30px;
    cursor: pointer;
`;


export const Row = styled(Box)`
display: flex; 
flex-direction: row;
position: relative;
`;








