import React, { createContext, useContext, useState } from "react";
import { GlobalContext } from "../../../contexts/GlobalContext";

const ModalCurrentUserContext = createContext();

const ModalCurrentUserContextProvider = ({ children }) => {
  const { userLogged } = useContext(GlobalContext);
  const [currentUsersCostumer, setCurrentUsersCostumer] = useState([]);
  const [currentUserModalOpen, setCurrentUserModalOpen] = useState(false);
  const [currentUserModalIndex, setCurrentUserModalIndex] = useState(-1);
  const [id, setId] = useState(userLogged.userId || "");
  const [firstName, setFirstName] = useState(userLogged.firstName || "");
  const [lastName, setLastName] = useState(userLogged.lastName || "");
  const [errors, setErrors] = useState({});
  const [expanded, setExpanded] = useState({ general: true });
  const [loading, setLoading] = useState(false);
  return (
    <ModalCurrentUserContext.Provider
      value={{
        currentUsersCostumer,
        setCurrentUsersCostumer,
        currentUserModalOpen,
        setCurrentUserModalOpen,
        currentUserModalIndex,
        setCurrentUserModalIndex,
        id,
        setId,
        firstName,
        setFirstName,
        lastName,
        setLastName,

        loading,
        setLoading,
        errors,
        setErrors,
        expanded,
        setExpanded,
      }}
    >
      {children}
    </ModalCurrentUserContext.Provider>
  );
};

export { ModalCurrentUserContextProvider, ModalCurrentUserContext };
