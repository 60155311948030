import axios from "axios";
import { search } from "../../../BaseRepositoryComponents";

const path = `${process.env.REACT_APP_PAINEL_API_URL}/api/v1/integrations/qyon-services/cognito`;
const newPath = `${process.env.REACT_APP_NEW_PAINEL_API_URL}`;

const myHeaders = new Headers({
  Authorization: "Bearer " + localStorage.getItem("tokenPanel"),
  "Entity-Id": localStorage.getItem("companyIdPanel"),
  "Content-Type": "application/json",
  "Content-Language": "pt-BR",
});

export async function postChangeFirsAccess({ body }) {
  return search({
    path: `${path}/change-password-access-first`,
    method: "POST",
    myBody: body,
  });
}

export async function postCodeRecover({ body }) {
  try {
    const response = await axios.post(
      `${newPath}/v1/public/account/send-code-reset-password`,
      body,
      {
        headers: myHeaders,
      }
    );

    return response?.data || null;
  } catch (error) {
    console.log("ERROR => postCodeRecover", error.response);
    return error?.response?.data || null;
  }
}

export async function postChangePassRecover({ body }) {
  try {
    const response = await axios.post(
      `${newPath}/v1/public/account/confirm-code-reset-password`,
      body,
      {
        headers: myHeaders,
      }
    );
    return response?.data || null;
  } catch (error) {
    console.log("ERROR => postChangePassRecover", error.response);
    return error?.response.data || null;
  }

  // return search({
  //   path: `${newPath}/v1/public/account/confirm-code-reset-password`,
  //   method: "POST",
  //   myBody: body,
  // });
}

export async function postUserCognito({ body }) {
  return search({
    path: `${path}/register-user`,
    method: "POST",
    myBody: body,
  });
}

export async function confirmUserCognito({ body }) {
  return search({
    path: `${path}/confirm-user-registration`,
    method: "POST",
    myBody: body,
  });
}
