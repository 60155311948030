import styled from "styled-components";
import { Grid } from "@material-ui/core";

export const ActualTabGrid = styled(Grid)`
  display: flex;
  padding: 16px;
  justify-content: space-between;
  margin-bottom: 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid #cfd8dc;
  background: #fafafa;
`;

export const ActualTabGridText = styled(Grid)`
  display: flex;
  align-items: center;
`;

export const ActualTabGridPlayerIcon = styled(Grid)`
  display: flex;
  width: 40px;
  height: 40px;
  padding: 7.99px 22px 7.98px 21.99px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
`;

export const PlayerIconsSize = styled(Grid)`
  width: 24px;
  height: 24px;
`;

export const ConfigTitle = styled(Grid)`
  color: #0036b3;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 32.016px;
  cursor: pointer;
`;

export const Arrow = styled.div({
  width: "20px",
  height: "20px",
  display: "flex",
  alignItems: "center",
});

export const ConfigVersion = styled(Grid)`
  color: #424242;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
`;
