import React, { useEffect } from "react";

import Lottie from "react-lottie";

import { Grid, Zoom } from "@material-ui/core";
import { GridStyled, Title } from "../../styles/GlobalStyle";

export default function RenderAnimation({
  height = 150,
  width = 190,
  speed = 1,
  label,
  labelColor = "gray",
  open = false,
  setOpen = () => {},
  animation,
  timeout,
  options = {
    loop: true,
    autoplay: true,
    animationData: animation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  },
}) {
  useEffect(() => {
    let interval = null;
    if (open && timeout) {
      interval = setInterval(() => {
        setOpen(false);
      }, [timeout]);
    } else {
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [open, timeout]);

  return (
    <>
      <Zoom in={open} timeout={700} mountOnEnter unmountOnExit>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          style={{ height: "100vh", width: "100%" }}
        >
          <Grid
            item
            flex={1}
            style={{
              background: "#eeeeee00",
              borderRadius: 15,
              boxshadow: "rgba(0, 0, 0, 0.2) 0px 40px 40px -7px",
            }}
            container
            justifyContent="center"
            alignItems="center"
          >
            <Grid item flex={1}>
              <Lottie
                options={options}
                height={height}
                width={width}
                isStopped={!open}
                isPaused={false}
                speed={speed}
              />
            </Grid>

            {label && (
              <GridStyled
                margin="10px 0px 0px 0"
                item
                flex={1}
                container
                justifyContent="center"
              >
                <Title subtitle color={labelColor}>
                  {label}
                </Title>
              </GridStyled>
            )}
          </Grid>
        </Grid>
      </Zoom>
    </>
  );
}
