import { useState } from "react";

import { ExpandMore } from "@material-ui/icons";

import {
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  CircularProgress,
  Grid,
} from "@material-ui/core";

import SelectErp from "../../../components/basics/SelectErp";
import TextFieldErp from "../../../components/basics/TextFieldErp";
import Btn from "../../../components/buttons/Btn";
import { requestNewCnd } from "../../../repositories/integrations/erp/CndRepository";
import {
  citiesRequestCNDs,
} from "../../../repositories/panel/v1/CityRepository";
import {
  AccordionStyled,
  LabelStyled,
  Title,
} from "../../../styles/GlobalStyle";
import MaskService from "../../../utils/mask/maskService";
import { notify } from "../../../utils/notify";
import { UFOptions } from "../controllers/StatesCnds";

export const AccordionAccountantRequestCnd = () => {
  const [name, setName] = useState("");

  const [type, setType] = useState(null);
  const [typeOptions] = useState([
    {
      value: "GOVERNMENT",
      name: "Federal",
    },
    {
      value: "STATE",
      name: "Estadual",
    },
    {
      value: "CITY",
      name: "Municipal",
    },
    {
      value: "OTHER",
      name: "Outros",
    },
  ]);
  const [url, setUrl] = useState("");

  const [uf, setUf] = useState(null);
  const [ibge, setIbge] = useState(null);
  const [cityOptions, setCityOptions] = useState([]);
  const [municipalRegistration, setMunicipalRegistration] = useState("");
  const [stateRegistration, setStateRegistration] = useState("");
  const [responsablePersonDocument, setResponsablePersonDocument] =
    useState("");
  const [loading, setLoading] = useState(false);

  const getStates = async (value) => {
    setUf(value);
    const cities = await citiesRequestCNDs(value?.uf);
    setCityOptions(cities);
  };

  const handleResponsablePersonDocument = (text) => {
    const mask =
      text.target.value.replace(/[^0-9]+/g, "").length <= 11 ? "cpf" : "cnpj";

    const newDocument = MaskService.toMask(mask, text.target.value);
    setResponsablePersonDocument(newDocument);
  };

  const handleSave = async () => {
    if (type === null) {
      notify("Informe o tipo da CND!");
      return;
    }

    if (name === "") {
      notify("Informe o nome da CND!");
      return;
    }

    if (url === "") {
      notify("Informe a URL do website onde é possível obter a CND!");
      return;
    }

    if (type.value === "STATE" || type.value === "CITY") {
      if (uf === null) {
        notify("Informe o estado!");
        return;
      }

      if (stateRegistration === "") {
        notify("Informe a inscrição estadual!");
        return;
      }
    }

    if (type.value === "CITY") {
      if (ibge === null) {
        notify("Informe a cidade!");
        return;
      }

      if (municipalRegistration === "") {
        notify("Informe a inscrição municipal!");
        return;
      }
    }

    let ufVal = null;
    if (uf) {
      ufVal = uf.uf;
    }

    let ibgeVal = null;
    if (ibge) {
      if (ibge?.id) {
        ibgeVal = ibge.id;
      } else {
        ibgeVal = ibge;
      }
    }

    const request = {
      type: type.value,
      name: name,
      uf: ufVal,
      ibge: ibgeVal,
      client: localStorage.getItem("companyNamePanel"),
      document: localStorage.getItem("documentPanel"),
      municipal_registration: municipalRegistration,
      state_registration: stateRegistration,
      responsable_person_document: responsablePersonDocument,
      website_url: url,
    };

    setLoading(true);

    const response = await requestNewCnd(request);

    try {
      if (response.success === true) {
        notify(
          "CND solicitada com sucesso. Assim que ela for integrada em nossos sistemas você receberá um e-mail",
          true,
          "success"
        );
        setLoading(false);
        setName("");
        setUrl("");
        setUf(null);
        setIbge(null);
        setMunicipalRegistration("");
        setStateRegistration("");
        setResponsablePersonDocument("");
      }
    } catch (err) {
      notify("Ocorreu um erro ao solicitar a CND, por favor tente novamente!");
      setLoading(false);
    }
  };

  return (
    <AccordionStyled defaultExpanded={true}>
      <AccordionSummary expandIcon={<ExpandMore />}>
        <Title subtitle cursor="pointer">
          SOLICITAR CND
        </Title>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <SelectErp
              label="Categoria"
              value={type}
              setValue={setType}
              optionsList={typeOptions}
              descriptionField="name"
            />
          </Grid>

          <Grid item xs={12}>
            <TextFieldErp
              value={name}
              setValue={(text) => setName(text)}
              label="Nome da CND*"
              maxLength={30}
            />
          </Grid>

          <Grid item lg={12} md={12} sm={12} xs={12}>
            <TextFieldErp
              value={url}
              setValue={(text) => setUrl(text)}
              label="URL do website que gera a CND*"
            />
          </Grid>

          {type !== null && (type.value === "STATE" || type.value === "CITY") && (
            <>
              <Grid item lg={6} md={6} sm={6} xs={12}>
                <SelectErp
                  loading={UFOptions.length === 0}
                  label="Estado*"
                  value={uf}
                  onChange={getStates}
                  optionsList={UFOptions}
                  descriptionField="name"
                />
              </Grid>

              <Grid item lg={6} md={6} sm={6} xs={12}>
                <TextFieldErp
                  value={stateRegistration}
                  setValue={setStateRegistration}
                  label="Inscrição estadual*"
                  maxLength={30}
                />
              </Grid>
            </>
          )}

          {type !== null && type.value === "CITY" && (
            <>
              <Grid item lg={4} md={6} sm={6} xs={12}>
                <SelectErp
                  loading={cityOptions.length === 0}
                  label="Município*"
                  value={ibge}
                  onChange={setIbge}
                  optionsList={cityOptions}
                  descriptionField="nome"
                />
              </Grid>

              <Grid item lg={4} md={6} sm={6} xs={12}>
                <TextFieldErp
                  value={municipalRegistration}
                  setValue={setMunicipalRegistration}
                  label="Inscrição municipal*"
                  maxLength={30}
                />
              </Grid>

              <Grid item lg={4} md={6} sm={6} xs={12}>
                <TextFieldErp
                  value={responsablePersonDocument}
                  onChange={handleResponsablePersonDocument}
                  label="CNPJ do responsável"
                  maxLength={50}
                />
              </Grid>
            </>
          )}
        </Grid>
      </AccordionDetails>
      <AccordionActions>
        <Btn
          label={
            loading ? (
              <>
                <LabelStyled margin="0px 10px 0px 0">Salvando</LabelStyled>
                <CircularProgress style={{ width: 25, height: 25 }} />
              </>
            ) : (
              "Salvar"
            )
          }
          onClick={loading ? () => {} : () => handleSave()}
          bg-color={loading ? "tertiary" : "blue"}
        />
      </AccordionActions>
    </AccordionStyled>
  );
};
