import styled, { css } from "styled-components";

const gray = (props) => props.theme.palette.grayPrimary;
const white = (props) => props.theme.palette.white;

export const StyledCard = styled.div`
  background-color: ${white};
  border: 0.725px solid ${gray};
  padding: 12px;
  border-radius: 4px;
  box-shadow: 3px 3px 3px ${gray};
  box-sizing: border-box;

  ${(props) =>
    props.padding &&
    css`
      padding: ${(props) => props.padding.replace(/( )+/g, "px ")}px !important;
    `}
  ${(props) =>
    props.line === "left" &&
    css`
      border-left: 6px solid ${(props) => props.theme.palette[props.lineColor]};
    `}
  ${(props) =>
    props.line === "right" &&
    css`
      border-right: 6px solid ${(props) => props.theme.palette[props.lineColor]};
    `};

  ${(props) =>
    props.full &&
    css`
      height: 100%;
      width: 100%;
    `};

  ${(props) =>
    props.fullWidth &&
    css`
      width: 100%;
    `};

  ${(props) =>
    props.fullHeight &&
    css`
      height: 100%;
    `};
`;

export const Padding = styled.div`
  padding: ${(props) => props.padding}px;
`;
