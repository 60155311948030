import { useCallback, useContext } from "react";
import { useHistory } from "react-router";
import { useAuth } from "../../../contexts/crm/Auth";
import { GlobalContext } from "../../../contexts/GlobalContext";
import {
  getcheckEmailToCreatAtendance,
  getDepartaments,
  getRoom,
  saveAttendance,
} from "../../../repositories/integrations/crm/AttendanceRepository";
import { postActiveChat } from "../../../repositories/integrations/crm/ChatRepository";
import { notify } from "../../../utils/notify";
import { useAttendance } from "../contexts/AttendanceContext";

export default function useAttendanceController() {
  const { push } = useHistory();

  const { setErrorsState } = useAttendance();

  const { user, company } = useAuth();

  const { companyUser } = useContext(GlobalContext);

  const accountantCnpj =
    companyUser?.isAccountant === "true"
      ? companyUser.document
      : companyUser.accountantCnpj;

  const fetchRoom = async ({ roomId }) => {
    try {
      const result = await getRoom({ roomId });

      if (result.hasError) {
        return {};
      }
      return result;
    } catch (error) {
      console.erro(error);
    }
  };

  const fetchDepartaments = async () => {
    try {
      let typeUser =
        companyUser.isAccountant === "true" ? "attendant" : "customer";
      const result = await getDepartaments({
        idUser: user?.id,
        typeUser: typeUser,
      });

      refreshToken({ cognitoPanel: result });
      if (result?.hasError) {
        return {};
      }
      return result;
    } catch (error) {
      console.error(error);
    }
  };

  const handleShowFile = (file, contentType) => {
    if (!file || typeof file !== "string" || !contentType) {
      return;
    }

    const byteCharacters = atob(file);

    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += 1024) {
      const slice = byteCharacters.slice(offset, offset + 1024);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }
    const blob = new Blob(byteArrays, { type: contentType });
    const blobUrl = URL.createObjectURL(blob);

    return blobUrl;
  };

  const handleDownloadFile = (fileBase64, filename, hasReturn) => {
    if (!fileBase64 || typeof fileBase64 !== "string") return;

    try {
      if (hasReturn) {
        return {
          dataApplication:
            "data:application/octet-stream;base64," +
            encodeURIComponent(fileBase64),
        };
      }
      var element = document.createElement("a");
      element.setAttribute(
        "href",
        "data:application/octet-stream;base64," + encodeURIComponent(fileBase64)
      );
      element.setAttribute("download", `${filename}`);
      element.style.display = "none";
      document.body.appendChild(element);

      element.click();

      document.body.removeChild(element);
      return true;
    } catch (error) {
      console.error("ERROR ButtonsAccountant executeDownload:", error);
      return false;
    }
  };

  const getNow = () => {
    const d = new Date();
    return `${d.getFullYear()}-${
      d.getMonth() + 1
    }-${d.getDate()} ${d.getHours()}:${d.getMinutes()}:${d.getSeconds()}:${d.getMilliseconds()}`;
  };

  const validFields = (obj, typeScreen) => {
    let errorsValidation = {};
    let hasError = false;
    let fields = [];

    let info = [];

    fields = [
      {
        customer: "Selecione um Cliente",
        situation: "Selecione uma Situação",
        departament: "Selecione um Departamento",
        classification: "Selecione uma Classificação",
        subject: "Digite o Assunto",
        request: "Digite a solicitação",
      },
    ];

    obj.forEach((element) => {
      if (!element.value) {
        if (element.key !== "fieldBlocked") {
          errorsValidation[element.key] = true;
          info.push({
            key: element.key,
            notification: fields[0][element.key],
          });
          hasError = true;
        }
      } else {
        let index = info.indexOf(element.key);
        errorsValidation[element.key] = false;
        info.splice(index, index !== -1 ? 1 : 0);
      }
    });
    return { hasError, errorsValidation, info };
  };

  // TODO: const findParameterValidate = (key) => {
  //   const found = parameters[0].find((element) => element.paramKey === key);
  //   return found
  //     ? found.value === "NAO" || found.value === ""
  //       ? false
  //       : true
  //     : true;
  // };

  const handleSave = async ({ value, path = "/attendance/insert" }) => {
    let valid = [];

    // TODO: const paramsValidator = {
    //   situation: "situation",
    //   departament: "departament",
    //   classification: "classification",
    // };

    try {
      if (!value?.reopenAttendance) {
        value.typeScreen === "insert"
          ? (valid = validFields(
              [
                { value: value.customer, key: "customer" },
                {
                  value:
                    companyUser.isAccountant === "true" ? value.situation : "0",
                  key: "situation",
                },
                {
                  value: value.department,
                  key: "departament",
                },
                {
                  value: value.classification,
                  key: "classification",
                },
                { value: value.subject, key: "subject" },
                { value: value.request, key: "request" },
              ],
              value.typeScreen
            ))
          : (valid = validFields(
              [
                {
                  value:
                    companyUser.isAccountant === "true" ? value.situation : "0",
                  key: "situation",
                },
                {
                  value: value.department,
                  key: "departament",
                },
                {
                  value: value.classification,
                  key: "classification",
                },
                { value: value.subject, key: "subject" },
              ],
              value.typeScreen
            ));

        valid.info.map((item) => {
          valid.hasError && notify(item.notification, true, "error");
          return true;
        });

        setErrorsState(valid.errorsValidation);
      }

      const dataAttendance = {
        customerId: value.customer,
        attendanceClassificationId: value?.reopenAttendance
          ? undefined
          : value.classification,
        classificationDescription: value.classificationDescription,
        situationAttendanceId: value?.reopenAttendance
          ? undefined
          : value.situation,
        situationDescription: value.situationDescription,
        flagUra: "0",
        title: value?.reopenAttendance ? undefined : value.subject,
        interationAttendanceFlag: "1",
        solucion: value.request,
        productId: value?.reopenAttendance ? undefined : value.department,
        departmentDescription: value.departmentDescription,
        visibleCustomer: "0",
        slaAnswerFlag: "1",
        emailInteractionFlag: companyUser?.isAccountant === "true" ? "0" : "1",
        attendantId: value?.reopenAttendance
          ? undefined
          : value.attendantTransferId,
        attendanceId: +value.attendanceId,
        userId: companyUser?.isAccountant === "true" ? user.id : null,
        userName: companyUser?.isAccountant === "true" ? user.name : null,
        date: getNow(),
        solucionDate: value?.solucionDate || false,
        interaction: value?.interaction,
        attachaments: value?.attachaments,
        typeEmail: value?.typeEmail,
        accountantCnpj,
        tokenPanel: value.tokenPanel,
      };
      if (!valid.hasError) {
        const result = await saveAttendance({
          body: dataAttendance,
          typeScreen: value?.typeScreen,
        });

        if (result?.success) {
          refreshToken({ cognitoPanel: result });
          if (value.typeScreen === "insert") {
            notify("Atendimento cadastrado com sucesso", true, "success");
            //chamar evento de notificação de cadastro
          } else {
            notify(
              `${
                value?.solucionDate
                  ? "Atendimento finalizado com sucesso"
                  : "Atendimento alterado com sucesso"
              }`,
              true,
              "success"
            );
            //chamar evento de notificação de alteração
          }

          push(
            value.typeScreen === "insert"
              ? `./edit/${result.attendanceId}`
              : `../edit/${value.attendanceId}`
          );
        } else {
          notify("Não foi possível salvar o atendimento", true, "warning");
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleFieldValue = (value, setValue) => {
    setValue(value);
  };

  const refreshToken = async ({ cognitoPanel }) => {
    if (cognitoPanel?.extraData?.newAccessToken) {
      let token = cognitoPanel?.extraData?.newAccessToken;
      const oldJsonAccessToken = JSON.parse(
        localStorage.getItem("cognitoPanel")
      );
      oldJsonAccessToken.AccessToken = token;
      localStorage.setItem("cognitoPanel", JSON.stringify(oldJsonAccessToken));
      localStorage.setItem("tokenCognitoPanel", token);
    }
  };

  const postCustomerActiveChat = async ({
    company: companyData,
    roomId,
    attendanceId,
    attendantId,
    customerCompanyId,
    customerContact,
  }) => {
    const result = await postActiveChat({
      body: {
        companyId: companyData?.id,
        roomId: roomId,
        ticketId: attendanceId,
        companyAlias: companyData.alias,
        attendantCode: attendantId,
        origin: "painel",
        customerCompanyId,
        customerContact,
      },
      path: company?.urlSocket,
    });

    if (result?.hasError || !result?.success) {
      notify("Não foi possível chamar o cliente", true, "warning");
      return {};
    }
  };

  const checkEmailToCreatAtendance = useCallback(async () => {
    const crmPermission = companyUser?.systemsPermissions?.filter(
      (item) => item.name === "CRM"
    )[0];
    if (crmPermission)
      await getcheckEmailToCreatAtendance({
        url: crmPermission?.url,
        companyId: company.id,
      });
  }, [company.id, companyUser?.systemsPermissions]);

  return {
    handleSave,
    handleFieldValue,
    fetchDepartaments,
    refreshToken,
    handleDownloadFile,
    handleShowFile,
    fetchRoom,
    postCustomerActiveChat,
    checkEmailToCreatAtendance,
  };
}
