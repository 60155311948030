import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";

const UseAttendanceContext = createContext();
const initialValues = {
  currentAttendance: null,
  situations: [],
  customers: [],
  attendances: [],
  departaments: [],
  classifications: [],
  users: [],
  errors: {
    departament: false,
    request: false,
    customer: false,
    classification: false,
    subject: false,
    situation: false,
  },
  attachmentDelete: [],
  parameters: [],
};

const UseAttendanceProvider = ({ children }) => {
  const [currentAttendance, setCurrentAttendance] = useState({
    ...initialValues.currentAttendance,
  });
  const [customers, setCustomers] = useState(...initialValues.customers);
  const [attendances, setAttendances] = useState(...initialValues.attendances);
  const [situations, setSituations] = useState(...initialValues.situations);
  const [departaments, setDepartaments] = useState(
    ...initialValues.departaments
  );
  const [classifications, setClassifications] = useState(
    ...initialValues.classifications
  );
  const [errors, setErrors] = useState({ ...initialValues.errors });
  const [users, setUsers] = useState({ ...initialValues.users });
  const [attachmentDelete, setAttachmentDelete] = useState({
    ...initialValues.attachmentDelete,
  });
  const [parameters, setParameters] = useState({ ...initialValues.parameters });
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(false);

  const setCurrentAttendanceState = useCallback(
    /**
     * @param {{
     *   attendanceId: number,
     * }} props
     */
    (props = {}) => {
      setCurrentAttendance((current) => ({ ...current, ...props }));
    },
    [setCurrentAttendance]
  );

  const setErrorsState = useCallback(
    /**
     * @param {{
     *   departament: boolean,
     *   request: boolean,
     *   customer: boolean,
     *   classification: boolean,
     *   subject: boolean,
     *   situation: boolean,
     * }} props
     */
    (props = {}) => {
      setErrors((current) => ({ ...current, ...props }));
    },
    [setErrors]
  );

  const setAttendancesState = useCallback(
    /**
     * @param {{
     *   attendanceId: number,
     *   title: string,
     *   customer: string,
     *   situation: string,
     * }} props
     */
    (props = {}) => {
      setAttendances((current) => ({ ...current, ...props }));
    },
    [setAttendances]
  );

  const setDepartamentsState = useCallback(
    /**
     * @param {{
     *   departamentId: number,
     *   description: string,
     *   active
     * }} props
     */
    (props = {}) => {
      setDepartaments((current) => ({ ...current, ...props }));
    },
    [setDepartaments]
  );

  const setClassificationsState = useCallback(
    /**
     * @param {{
     *   departamentId: number,
     *   description: string,
     *   active
     * }} props
     */
    (props = {}) => {
      setClassifications((current) => ({ ...current, ...props }));
    },
    [setClassifications]
  );

  const setSituationsState = useCallback(
    /**
     * @param {{
     *   attendanceSituationId: number,
     *   description: string,
     *   color: string,
     *   active: string,
     *   descriptionCustomerArea: object,
     *   downloadAutoFlag: object,
     *   slaPauseFlag: object,
     * }} props
     */
    (props = {}) => {
      setSituations((current) => ({ ...current, ...props }));
    },
    [setSituations]
  );

  const setCustomersState = useCallback(
    /**
     * @param {{
     *   attendanceSituationId: number,
     *   description: string,
     *   color: string,
     *   active: string,
     *   descriptionCustomerArea: object,
     *   downloadAutoFlag: object,
     *   slaPauseFlag: object,
     * }} props
     */
    (props = {}) => {
      setCustomers((current) => ({ ...current, ...props }));
    },
    [setCustomers]
  );

  const setUsersState = useCallback(
    /**
     * @param {{
     *   value: number,
     *   title: string,
     * }} props
     */
    (props = {}) => {
      setUsers((current) => ({ ...current, ...props }));
    },
    [setUsers]
  );

  const setParametersState = useCallback(
    /**
     * @param {{
     *   paramId: number
     *   typeParamId: number
     *   paramKey: string
     *   description: string
     *   value: string
     * }} props
     */
    (props = {}) => {
      setParameters((current) => ({ ...current, ...props }));
    },
    [setParameters]
  );

  const setAttachmentDeleteState = useCallback(
    /**
     * @param {{
     *   attachmentId: string
     * }} props
     */

    (props = {}) => {
      setAttachmentDelete((current) => ({ ...current, ...props }));
    },
    [setAttachmentDelete]
  );

  const contextValue = useMemo(
    () => ({
      currentAttendance,
      setCurrentAttendanceState,
      customers,
      setCustomersState,
      attendances,
      setAttendancesState,
      situations,
      setSituationsState,
      departaments,
      setDepartamentsState,
      classifications,
      setClassificationsState,
      errors,
      setErrorsState,
      users,
      setUsersState,
      attachmentDelete,
      setAttachmentDeleteState,
      parameters,
      setParametersState,
      page,
      setPage,
      loading,
      setLoading,
    }),
    [
      currentAttendance,
      setCurrentAttendanceState,
      customers,
      setCustomersState,
      attendances,
      setAttendancesState,
      situations,
      setSituationsState,
      departaments,
      setDepartamentsState,
      classifications,
      setClassificationsState,
      errors,
      setErrorsState,
      users,
      setUsersState,
      attachmentDelete,
      setAttachmentDeleteState,
      parameters,
      setParametersState,
      page,
      setPage,
      loading,
      setLoading,
    ]
  );

  return (
    <UseAttendanceContext.Provider value={contextValue}>
      {children}
    </UseAttendanceContext.Provider>
  );
};

const useAttendance = () => {
  const context = useContext(UseAttendanceContext);

  if (!context)
    throw new Error(
      "useAttendance must be used within an UseAttendanceProvider"
    );

  return context;
};

export { UseAttendanceProvider, useAttendance };
