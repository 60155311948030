import { useEffect, useRef, useState } from "react";

import TabCustom from "../../components/tabs/TabCustom";
import ModalAttachment from "../../utils/ModalAttachment";
import { notify } from "../../utils/notify";
import ModalSendEmail from "./children/ModalSendEmail";
import TableTabGuideReport from "./children/TableTabGuideReport";
import { useGuideReport } from "./contexts/GuideReportContext";
import useGuideReportController from "./controllers/GuideReportController";
import { ButtonsDocument, Container, ContainerTab } from "./styles/GuideReport";
import { SubHeader } from "../../components/subHeader/SubHeader";
import { BotButtonCopilot } from "../../components/botCopilot/BotButtonCopilot";
import { RefreshIcon, VideoIcon } from "./GuideReportListIcons";

import { ButtonTogleStyle, DivStyle } from "../../components/modal/Modal";

export default function GuideReportList(props) {
  const {
    dataModalSend,
    tabIndex,
    setTabIndex,
    setAllFilesToFilter,
    allFiles,
    setAllFiles,
    filesPerDepartament,
    setFilesPerDepartament,
    departaments,
    setDepartament,
    loading,
    setLoading,
  } = useGuideReport();

  const { findDepartaments, findGuideReports } = useGuideReportController();
  const refFilter = useRef(null);
  const [value, setValue] = useState(0);

  const [stateEnumTypeFile, setStateEnumTypeFile] = useState({
    ["/guide"]: {
      typeFileKey: "GUIDE",
      title: "Guias",
      messageNoRows: "Nenhuma guia [%type%] foi encontrada.",
    },
    ["/report"]: {
      typeFileKey: "REPORT",
      title: "Relatórios",
      messageNoRows: "Nenhum relatório [%type%] foi encontrado.",
    },
    ["/document"]: {
      typeFileKey: "DOC",
      title: "Documentos",
      messageNoRows: "",
    },
  });
  const [statePath, setStatePath] = useState(props.location.pathname);
  const [openModalFile, setOpenModalFile] = useState(false);
  const [urlFileState, setUrlFileState] = useState("");

  const filterFilesByParams = (files) => {
    const newData = files.filter((item) => {
      let valueFound = false;
      if (!valueFound) {
        valueFound = item["id"]
          ? item["id"]
              .toString()
              .normalize("NFD")
              .toLowerCase()
              .replace(/[^a-z0-9_]/g, "") === props.match.params.id
          : false;
      }
      return valueFound;
    });
    return newData;
  };

  useEffect(() => {
    if (!allFiles || !allFiles.length) return;
    if (!departaments || !departaments.length) return;

    let files =
      props.match.params.id &&
      (!refFilter.current || refFilter.current?.value.length === "")
        ? filterFilesByParams(allFiles)
        : allFiles;
    const filesPerDepartamentTmp = { Favoritos: [] };

    departaments.forEach((dep) => {
      filesPerDepartamentTmp[dep.tabDescription] = [];
    });

    files.forEach(({ file, department }) => {
      if (file.starred && filesPerDepartamentTmp.Favoritos) {
        filesPerDepartamentTmp.Favoritos.push(file);
      }

      if (!department?.name || !filesPerDepartamentTmp[department?.name]) {
        return;
      }

      filesPerDepartamentTmp[department.name].push(file);
    });
    setFilesPerDepartament(filesPerDepartamentTmp);
  }, [allFiles, departaments]);

  useEffect(() => {
    refresh();
  }, [value]);

  const refresh = async () => {
    try {
      setLoading(true);
      setStatePath(props.location.pathname);

      const responseDepartamentsGuideReport = await findDepartaments({
        typeFile: getFileType(),
      });
      if (responseDepartamentsGuideReport?.length > 0) {
        setDepartament(responseDepartamentsGuideReport);
      }
      const responseFilesGuideReport = await findGuideReports({
        typeFile: getFileType(),
      });
      if (responseFilesGuideReport?.length > 0) {
        setAllFiles(responseFilesGuideReport);
        setAllFilesToFilter(responseFilesGuideReport);
      }
    } catch (error) {
      console.error(error);
      notify("Não foi possível obter os dados.");
    } finally {
      setLoading(false);
    }
  };

  const getFileType = () => {
    if (value === 0) {
      return "GUIDE";
    }

    if (value === 1) {
      return "REPORT";
    }

    if (value === 2) {
      return "DOC";
    }

    return "";
  };

  const arrayButtons = [
    { label: "Guias", value: 0 },
    { label: "Relatórios", value: 1 },
    { label: "Documentos", value: 2 },
  ];

  const children = () =>
    departaments.map((dep) => (
      <TableTabGuideReport
        tab={dep.tabDescription}
        typeFile={getFileType()}
        rows={loading ? [] : filesPerDepartament[dep.tabDescription] || []}
        pageSize={10}
        messageNoRows={stateEnumTypeFile[statePath]?.messageNoRows}
        loadingTabs={loading}
        setUrlFileState={setUrlFileState}
        setOpenModalFile={setOpenModalFile}
        refresh={refresh}
      />
    ));

  return (
    <div style={{position: 'relative', top: -20, width: '100%', height: '100%'}}>
      <SubHeader
        title={"Guias, documentos e relatórios"}
        // rightComponent={
        //   <div
        //     style={{
        //       display: "flex",
        //       alignItems: "center",
        //       justifyContent: "center",
        //       backgroundColor: "white",
        //     }}
        //   >
        //     <BotButtonCopilot />
        //     <span style={{ marginRight: 10, marginLeft: 15 }}>
        //       <VideoIcon />
        //     </span>
        //     <span style={{ marginRight: 20 }}>
        //       <RefreshIcon />
        //     </span>
        //   </div>
        // }
      />
      {dataModalSend && <ModalSendEmail />}
      {openModalFile && (
        <ModalAttachment
          open={openModalFile}
          setOpen={setOpenModalFile}
          urlFile={urlFileState}
        />
      )}

      <Container style={{ backgroundColor: "white" }}>
        <DivStyle style={{  marginLeft: 15, marginTop: 10 }}>
          {arrayButtons.map((item) => (
            <>
              <ButtonTogleStyle
                active={value === item.value}
                onClick={() => {
                  setValue(item.value);
                }}
              >
                {item.label}
              </ButtonTogleStyle>
              &nbsp;
            </>
          ))}
        </DivStyle>

        <ContainerTab item xs>
          <TabCustom
            children={children()}
            headers={departaments.map((dep) => ({
              label:
                dep.tabDescription === "Folha"
                  ? "Folha de Pagamento"
                  : dep.tabDescription === "Fiscal"
                  ? "Escrita Fiscal"
                  : dep.tabDescription,
            }))}
            tabIndex={tabIndex}
            setTabIndex={setTabIndex}
            value={value}
            onChange={(event, value) => {
              if (Number.isInteger(value)) {
                setTabIndex(value);
              }
            }}
          />
        </ContainerTab>
      </Container>
    </div>
  );
}
