import { useContext, useEffect, useState } from "react";

/**
 * Icons
 */
import { ExpandMore } from "@material-ui/icons";

/**
 * Components
 */
import {
  Grid,
  FormControlLabel,
  Switch,
  Checkbox,
  AccordionSummary,
  AccordionDetails,
  CardContent,
} from "@material-ui/core";

/**
 * Styled Components
 */
import { AccordionStyled, Title } from "../../../styles/GlobalStyle";

import { StyledCardSettings, StyledCardHeader } from "../styles/CndAccountant";

/**
 * Context
 */
import { CndAccountantContext } from "../contexts/CndAccountantContext";

/**
 * Controller
 */
import { useCndAccountantController } from "../controllers/CndAccountantController";

let saveTimer = null;

export const AccordionAccountantGeneralSettings = () => {
  const { getAccountantCndSettings, saveAccountantCndGeneralSettings } =
    useCndAccountantController();
  const {
    sendMailPositiveFlag,
    setSendMailPositiveFlag,
    sendMailNegativeFlag,
    setSendMailNegativeFlag,
    sendMailPositiveNegativeFlag,
    setSendMailPositiveNegativeFlag,
    clientAllowedPositiveFlag,
    setClientAllowedPositiveFlag,
    clientAllowedNegativeFlag,
    setClientAllowedNegativeFlag,
    clientAllowedPositiveNegativeFlag,
    setClientAllowedPositiveNegativeFlag,
    clientAllowedOthersFlag,
    setClientAllowedOthersFlag,
  } = useContext(CndAccountantContext);

  const [configChanged, setConfigChanged] = useState(false);

  const handleChangeSendMail = (event) => {
    setSendMailPositiveFlag(event.target.checked);
    setSendMailNegativeFlag(event.target.checked);
    setSendMailPositiveNegativeFlag(event.target.checked);
    setConfigChanged(true);
  };

  const handleChangeClientAllowed = (event) => {
    setClientAllowedPositiveFlag(event.target.checked);
    setClientAllowedNegativeFlag(event.target.checked);
    setClientAllowedPositiveNegativeFlag(event.target.checked);
    setClientAllowedOthersFlag(event.target.checked);
    setConfigChanged(true);
  };

  useEffect(() => {
    getAccountantCndSettings();
  }, [getAccountantCndSettings]);

  useEffect(() => {
    clearTimeout(saveTimer);

    if (configChanged) {
      saveTimer = setTimeout(() => {
        try {
          saveAccountantCndGeneralSettings();
        } catch (error) {
          console.error(error);
        } finally {
          setConfigChanged(false);
        }
      }, 2000);
    }
  }, [
    configChanged,
    sendMailPositiveFlag,
    sendMailNegativeFlag,
    sendMailPositiveNegativeFlag,
    clientAllowedPositiveFlag,
    clientAllowedNegativeFlag,
    clientAllowedPositiveNegativeFlag,
    clientAllowedOthersFlag,
    saveAccountantCndGeneralSettings,
  ]);

  return (
    <AccordionStyled defaultExpanded={true}>
      <AccordionSummary expandIcon={<ExpandMore />}>
        <Title subtitle cursor="pointer">
          GERAL
        </Title>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={4}>
          <Grid
            item
            xs={6}
            container
            direction="column"
            justifyContent="space-between"
          >
            <Grid item>
              <StyledCardSettings variant="outlined">
                <StyledCardHeader
                  title={
                    <FormControlLabel
                      control={
                        <Switch
                          checked={
                            sendMailPositiveFlag ||
                            sendMailNegativeFlag ||
                            sendMailPositiveNegativeFlag
                          }
                          onChange={handleChangeSendMail}
                          color="primary"
                        />
                      }
                      label="Envio automático por e-mail"
                    />
                  }
                />
                <CardContent>
                  <Grid container justifyContent="space-between">
                    <Grid item sm={6} flex={1}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={sendMailPositiveFlag}
                            onChange={(event) => {
                              setSendMailPositiveFlag(event.target.checked);
                              setConfigChanged(true);
                            }}
                            color="primary"
                            size="small"
                          />
                        }
                        label="Positivas"
                      />
                    </Grid>
                    <Grid item sm={6} flex={1}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={sendMailNegativeFlag}
                            onChange={(event) => {
                              setSendMailNegativeFlag(event.target.checked);
                              setConfigChanged(true);
                            }}
                            color="primary"
                            size="small"
                          />
                        }
                        label="Negativas"
                      />
                    </Grid>
                    {/* <Grid item sm={6} flex={1}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={sendMailPositiveNegativeFlag}
                            onChange={(event) => {
                              setSendMailPositiveNegativeFlag(event.target.checked);
                              setConfigChanged(true);
                            }}
                            color="primary"
                            size="small"
                          />
                        }
                        label="Positivas Negativas"
                      />
                    </Grid> */}
                  </Grid>
                </CardContent>
              </StyledCardSettings>
            </Grid>
          </Grid>
          <Grid
            item
            xs={6}
            container
            direction="column"
            justifyContent="space-between"
          >
            <Grid item>
              <StyledCardSettings variant="outlined">
                <StyledCardHeader
                  title={
                    <FormControlLabel
                      control={
                        <Switch
                          checked={
                            clientAllowedPositiveFlag ||
                            clientAllowedNegativeFlag ||
                            clientAllowedPositiveNegativeFlag ||
                            clientAllowedOthersFlag
                          }
                          onChange={handleChangeClientAllowed}
                          color="primary"
                        />
                      }
                      label="Acesso do cliente através da plataforma"
                    />
                  }
                />
                <CardContent>
                  <Grid container justifyContent="space-between">
                    <Grid item sm={6} flex={1}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={clientAllowedPositiveFlag}
                            onChange={(event) => {
                              setClientAllowedPositiveFlag(
                                event.target.checked
                              );
                              setConfigChanged(true);
                            }}
                            color="primary"
                            size="small"
                          />
                        }
                        label="Positivas"
                      />
                    </Grid>
                    <Grid item sm={6} flex={1}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={clientAllowedNegativeFlag}
                            onChange={(event) => {
                              setClientAllowedNegativeFlag(
                                event.target.checked
                              );
                              setConfigChanged(true);
                            }}
                            color="primary"
                            size="small"
                          />
                        }
                        label="Negativas"
                      />
                    </Grid>
                    <Grid item sm={6} flex={1}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={clientAllowedPositiveNegativeFlag}
                            onChange={(event) => {
                              setClientAllowedPositiveNegativeFlag(
                                event.target.checked
                              );
                              setConfigChanged(true);
                            }}
                            color="primary"
                            size="small"
                          />
                        }
                        label="Positiva Negativa"
                      />
                    </Grid>
                    <Grid item sm={6} flex={1}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={clientAllowedOthersFlag}
                            onChange={(event) => {
                              setClientAllowedOthersFlag(event.target.checked);
                              setConfigChanged(true);
                            }}
                            color="primary"
                            size="small"
                          />
                        }
                        label="Outros"
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </StyledCardSettings>
            </Grid>
          </Grid>
        </Grid>
      </AccordionDetails>
    </AccordionStyled>
  );
};
