import { useEffect, useState } from "react";
import { Margin } from "../../styles/GlobalStyle";
import {
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from "@material-ui/core";
import { Search, OpenInNew } from "@material-ui/icons";
import { getCertificatesFromAccountant } from "../../repositories/panel/v1/CertificateRepository";
import moment from "moment/moment";
import {
  CertificateColumn,
  CertificateDataGrid,
  CertificateNormal,
  CertificateWillExpire,
  CompanyColumn,
  DateColumn,
  DateColumnExpired,
  DocumentColumn,
  EnterpriseColumn,
  ExpiredCertificate,
  SearchLabel,
} from "./styles/Certificate";
import {
  getExpirationInDays,
  isExpired,
  isWarningInXDays,
} from "./util/DateUtil";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { GridOverlay } from "@material-ui/data-grid";
import { ArrowRightIcon, RpaTrackingIcon } from "./CertificateIcon";
import { SearchIcon } from "../../components/icons/SearchIcon";

function formatCNPJorCPF(document) {
  return document.length === 14
    ? document.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5")
    : document.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
}

export default function CertificateListView() {
  const [loading, setLoading] = useState(true);
  const [rows, setRows] = useState([]);
  const [tmpRows, setTmpRows] = useState([]);
  const [textFilter, setTextFilter] = useState("");
  const [certificateFilter, setCertificateFilter] = useState("ALL");
  const { push } = useHistory();

  let columns = [
    {
      field: "company",
      headerName: "Empresa",
      flex: 1,
      sortable: false,
      renderCell: ({ row }) => {
        return (
          <EnterpriseColumn>
            <CompanyColumn>{row.company_name.toLowerCase()}</CompanyColumn>
            <DocumentColumn>{formatCNPJorCPF(row.document)}</DocumentColumn>
          </EnterpriseColumn>
        );
      },
    },
    {
      field: "certificate",
      headerName: "Certificado",
      width: 150,
      sortable: false,

      renderCell: ({ row }) => {
        if (!row.technology) {
          return "";
        }
        return <CertificateColumn>{row.technology}</CertificateColumn>;
      },
    },
    {
      field: "start_in",
      headerName: "Início",
      width: 150,
      sortable: false,

      renderCell: ({ row }) => {
        if (!row.valid_from) {
          return "";
        }

        return <DateColumn>{formatDate(row.valid_from)}</DateColumn>;
      },
    },
    {
      field: "expire_in",
      headerName: "Expiração",
      width: 150,
      sortable: false,

      renderCell: ({ row }) => {
        if (!row.valid_to) {
          return "";
        }

        let daysUntilExpire = getExpirationInDays(row.valid_to);

        if (daysUntilExpire <= 0) {
          return (
            <Tooltip title={moment(row.valid_to).format("DD/MM/YYYY")}>
              <ExpiredCertificate>Expirado</ExpiredCertificate>
            </Tooltip>
          );
        }

        if (daysUntilExpire <= 7) {
          return (
            <Tooltip title={moment(row.valid_to).format("DD/MM/YYYY")}>
              <CertificateWillExpire>
                {daysUntilExpire} Dias
              </CertificateWillExpire>
            </Tooltip>
          );
        }

        return (
          <Tooltip title={moment(row.valid_to).format("DD/MM/YYYY")}>
            <CertificateNormal>
              {getExpirationInDays(row.valid_to)} Dias
            </CertificateNormal>
          </Tooltip>
        );
      },
    },
    {
      field: "actions",
      headerName: "Ações",
      width: 100,
      sortable: false,

      renderCell: ({ row }) => {
        if (!row.technology) {
          return "";
        }
        return (
          <>
            <Tooltip title={"Checagens procuração"}>
              <div
                style={{ cursor: "pointer" }}
                onClick={() => {
                  seeEcac(row);
                }}
              >
                <RpaTrackingIcon />
              </div>
            </Tooltip>

            <Tooltip title={"Visualizar procurações"}>
              <div
                style={{ marginLeft: 20, cursor: "pointer" }}
                onClick={() => {
                  seeDetails(row);
                }}
              >
                <ArrowRightIcon />
              </div>
            </Tooltip>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    setLoading(true);
    getCertificatesFromAccountant().then((certificates) => {
      setRows(certificates);
      setTmpRows(certificates);
      setLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (loading === true) {
      return;
    }
    const delayDebounceFn = setTimeout(() => {
      if (textFilter === "") {
        setTmpRows(rows);
        return;
      }
      const rowsFiltered = rows.filter((e) => {
        const search = textFilter
          .toLowerCase()
          .replace("-", "")
          .replace("-", "")
          .replace("/", "")
          .replace(".", "");
        const name = e.company_name.toLowerCase();
        const document = e.document
          .replace("-", "")
          .replace("-", "")
          .replace("/", "")
          .replace(".", "");

        if (name.includes(search) || document.includes(search)) {
          return true;
        }

        return false;
      });

      setTmpRows(rowsFiltered);
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [textFilter]);

  const handleCertificateFilterChange = (event) => {
    setCertificateFilter(event.target.value);
    if (loading === true) {
      return;
    }

    if (event.target.value === "ALL") {
      setTmpRows(rows);
      return;
    }

    const filteredRows = rows.filter((e) => {
      if (event.target.value === "30_DAYS") {
        if (isWarningInXDays(e, 30) && !isExpired(e)) {
          return true;
        }
      }

      if (event.target.value === "15_DAYS") {
        if (isWarningInXDays(e, 15) && !isExpired(e)) {
          return true;
        }
      }

      if (event.target.value === "EXPIRED") {
        if (isExpired(e)) {
          return true;
        }
      }

      if (event.target.value === "NO_CERTIFICATE") {
        if (!e.technology) {
          return true;
        }
      }

      return false;
    });

    setTmpRows(filteredRows);
  };

  const formatDate = (date) => {
    if (!date) {
      return "";
    }
    return moment(date).format("DD/MM/YYYY");
  };

  const seeDetails = (row) => {
    push("/certificate/detail", {
      data: row,
    });
  };

  const seeEcac = (row) => {
    push("/certificate/ecac", {
      data: row,
    });
  };
  return (
    <>
      <div
        container
        style={{
          background: "#fff",
          border: "1px solid #E0E0E0",
          borderRadius: 15,
          padding: 15,
          height: "100%",
          flex: 1,
        }}
      >
        <Margin top="10" />

        <Grid container spacing={2}>
          <Grid item xs={6}>
            <SearchLabel>Pesquisar</SearchLabel>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              placeholder="Pesquisar por nome ou CPF/CNPJ da empresa"
              value={textFilter}
              onChange={(e) => setTextFilter(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <SearchLabel>Vencimento do Certificado</SearchLabel>

            <Select
              variant="outlined"
              fullWidth
              style={{ height: 40 }}
              onChange={handleCertificateFilterChange}
              value={certificateFilter}
            >
              <MenuItem value={"ALL"}>Todos</MenuItem>
              <MenuItem value={"30_DAYS"}>Menos de 30 dias</MenuItem>
              <MenuItem value={"15_DAYS"}>Menos de 15 dias</MenuItem>
              <MenuItem value={"EXPIRED"}>Vencidos</MenuItem>
              <MenuItem value={"NO_CERTIFICATE"}>Não inseridos</MenuItem>
            </Select>
          </Grid>
        </Grid>

        <CertificateDataGrid
          disableColumnFilter
          disableColumnMenu
          style={{ marginTop: 20, height: "calc(100% - 65px)" }}
          rows={loading ? [] : tmpRows}
          columns={columns}
          disableSelectionOnClick
          slots={{
            loadingOverlay: LinearProgress,
          }}
          loading={loading}
          components={{
            NoRowsOverlay: () =>
              !loading && (
                <GridOverlay>
                  <h4>
                    Nenhum certificado encontrado!<br></br>
                    Para cadastrar um novo certificado, arraste e solte um
                    arquivo .pfx ou .p12 sobre o Qyon Bot
                  </h4>
                </GridOverlay>
              ),
          }}
        />
      </div>
    </>
  );
}
