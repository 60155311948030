/* eslint-disable react-hooks/exhaustive-deps */

import { useState, useRef, forwardRef, useEffect, useContext } from "react";
import { v4 as uuidv4 } from "uuid";

import moment from "moment";
import {
  Badge,
  Grid,
  Modal,
  Slide,
  Backdrop,
  Tooltip,
  LinearProgress,
  IconButton,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/ArrowBack";
import BackIcon from "@material-ui/icons/ArrowForwardIos";
import parse from "html-react-parser";

import ScrollableFeed from "react-scrollable-feed";

import { GlobalContext } from "../../../../contexts/GlobalContext";
import AIIcon from "../../../../assets/icons/icon_ia.png";

import {
  Container,
  Title,
  MessageContainer,
  BotMessage,
  HumanMessage,
  BotMessageText,
  HumanMessageText,
  SendMessageContainer,
  ExampleContainer,
  QyonBotContainer,
  SendButton,
  GoBack,
  InputContainer,
  AudioButton,
  CustomTextField,
  QuestionContainer,
  Disclaimer,
  KnowledgeBaseContainer,
  KnowledgeBaseText,
  KnowledgeBaseCardContainer,
  KnowledgeBaseCard,
  KnowledgeBaseCardText,
  CourseCardContainer,
  CourseCard,
  CourseText,
  PlaySoundIcon,
  PlaySoundContainer,
  StopSoundIcon,
  AudioSize,
  BadgeButton,
  ItemRelatedButton,
  TopicContainer,
  PopularTopic,
  TopicCard,
  TopicTitle,
  TopicDescription,
  FastTitle,
  BotChatContainer,
  BotMessageOption,
  HumanShowMore,
} from "./styles/BotChatModal";

import { getBotMessage, getQuestions } from "../../../../repositories/Bot";
import { getMP3AudioAsync } from "../../../../repositories/AudioHandler";
import { usePermissions } from "../../../../utils/permissionsHook";

import { Check, School } from "@material-ui/icons";
import { GlobalVariables } from "../../../../GlobalVariables";
import { useMemo } from "react";
import { shouldOpenFolhaFiscalContabilXmlERP } from "./BotActionUtils";
import {
  BotLogoType,
  ChatAction,
  FastAction,
  MagicWand,
  KnowledgeBaseIcon,
  // eslint-disable-next-line no-unused-vars
  Folha,
} from "./BotIcons";
import { notify } from "../../../../utils/notify";
import { NewBotContext } from "../../../newBot/context/NewBotContext";
import BotActionIcons from "./components/BotActionIcons";
import {
  isAccountant,
  isAccountantClient,
  isCompany,
} from "../../../../utils/panelType";
// eslint-disable-next-line no-unused-vars
import { getCustomersEntityNew } from "../../../../repositories/panel/v1/EntityRepository";
import { getEmployees } from "../../../../repositories/panel/v1/integrations/FolhaRepository";

const BotChatModal = forwardRef((props, ref) => {
  const { companyUser, userLogged } = useContext(GlobalContext);
  const botContext = useContext(NewBotContext);

  const {
    checkIfUserHasPartialPermission,
    handleProductMenuHide,
    validateProductStatus,
  } = usePermissions();

  const userPermissons = useMemo(() => {
    return {
      customerManagement: checkIfUserHasPartialPermission(
        GlobalVariables.PERMISSIONS.CLIENTES_VISUALIZATION
      ),
      gestaoFacil: checkIfUserHasPartialPermission(
        GlobalVariables.PERMISSIONS.GESTAO_FACIL
      ),
      contabil: checkIfUserHasPartialPermission(
        GlobalVariables.PERMISSIONS.CONTABIL
      ),
      fiscal: checkIfUserHasPartialPermission(
        GlobalVariables.PERMISSIONS.FISCAL
      ),
      folha: checkIfUserHasPartialPermission(GlobalVariables.PERMISSIONS.FOLHA),
      cnd: checkIfUserHasPartialPermission(
        GlobalVariables.PERMISSIONS.CND_VISUALIZATION
      ),
      crm: checkIfUserHasPartialPermission(GlobalVariables.PERMISSIONS.CRM),
      xml: checkIfUserHasPartialPermission(GlobalVariables.PERMISSIONS.XML),
      settings: checkIfUserHasPartialPermission(
        GlobalVariables.PERMISSIONS.SETTINGS
      ),
    };
  }, [checkIfUserHasPartialPermission]);

  const isClient = !companyUser.isAccountant && !!companyUser.accountantId;
  const isStandaloneClient =
    !companyUser.isAccountant && !companyUser.accountantId;

  const messagesEndRef = useRef();
  const [recognition, setRecognition] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [clients, setClients] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [client, setClient] = useState(null);

  const [audioListening, setAudioListening] = useState(false);
  const [text, setText] = useState("");
  const [messages, setMessages] = useState([
    {
      id: uuidv4(),
      from: "BOT",
      message: "Olá, como posso te ajudar? ",

      time: moment().format("HH:mm"),
    },
  ]);
  const [generalQuestionList, setGeneralQuestionList] = useState([]);
  const [myQuestionList, setMyQuestionList] = useState([]);
  const [knowledgeBaseQuestions, setKnowledgeBaseQuestionList] = useState([]);
  const [contextMessages, setContextMessages] = useState([]);

  const [baseUrls, setBaseUrls] = useState(null);
  const [done, setDone] = useState(false);

  useEffect(() => {
    props.botRef.current.start_bot();
    start();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const start = async () => {
    let question = await getQuestions();
    setupBaseUrlForProducts();
    setGeneralQuestionList(question.generalQuestions);
    setMyQuestionList(question.myQuestions);
    setKnowledgeBaseQuestionList(question.knowledgeBaseQuestions);
    setDone(true);
  };

  useEffect(() => {
    if (botContext.botText === "") {
      return;
    }
    if (done === false) {
      return;
    }

    sendMessage(botContext.botText);
  }, [botContext.botText, done]);

  useEffect(() => {
    if (botContext.botMode !== "CHAT_CONTEXT") {
      return;
    }

    if (botContext.botTextContext.length === 0) {
      return;
    }

    if (done === false) {
      return;
    }

    const mode =
      botContext.botMode === "CHAT" ||
      botContext.botMode === "GENERAL" ||
      botContext.botMode === "CHAT_CONTEXT"
        ? "CHAT"
        : "KNOWLEDGE_BASE";

    setMessages([
      {
        id: uuidv4(),
        from: "HUMAN",
        message: botContext.botTextContext[0],

        time: moment().format("HH:mm"),
      },
      {
        id: uuidv4(),
        from: "BOT",
        message: "Aguarde um momento...",

        time: moment().format("HH:mm"),
      },
    ]);

    setContextMessages([botContext.botTextContext[0]]);

    getBotMessage(botContext.botTextContext, mode, botActionId)
      .then((response) => {
        if (response.status !== 200) {
          throw new Error("Erro ao obter resposta do bot");
        }
        let questions = response.data.text.split("\n");
        if (botContext.botCurrentContext === "XML_UPLOAD") {
          questions.push("6. Qual o produto que mais pagou imposto?");
          questions.push("7. Qual o total de impostos da nota?");
          questions.push("8. Qual é o ICMS ST (Substituição Tributária)?");
        }

        if (botContext.botCurrentContext === "PAYROLL") {
          questions.push(
            "1. Quem são os aniversariantes do mês de " +
              moment().format("MM") +
              "/" +
              moment().format("YYYY") +
              "?"
          );
          questions.push("2. Qual o maior e menor salário?");
          questions.push("3. Quem tem a maior quantidade de horas extras?");
          questions.push("4. Quem foi admitido e demitido no mês?");
        }

        for (let question of questions) {
          setMessages((old) => [
            ...old,
            {
              id: uuidv4(),
              from: "BOT_OPTION",
              message: question,
              time: moment().format("HH:mm"),
            },
          ]);
        }
      })
      .catch((err) => {});
  }, [botContext.botTextContext, done, botContext.botMode]);

  const sendMessageOption = async (messageHuman) => {
    setMessages((old) => [
      ...old,
      {
        id: uuidv4(),
        from: "HUMAN",
        message: messageHuman,
        time: moment().format("HH:mm"),
      },
      {
        id: uuidv4(),
        from: "BOT",
        message: "Aguarde um momento...",
        time: moment().format("HH:mm"),
      },
    ]);

    const mode =
      botContext.botMode === "CHAT" ||
      botContext.botMode === "GENERAL" ||
      botContext.botMode === "CHAT_CONTEXT"
        ? "CHAT"
        : "KNOWLEDGE_BASE";

    let msgs = Object.assign([], contextMessages);
    msgs.push(messageHuman);

    getBotMessage(msgs, mode, botActionId)
      .then(async (response) => {
        if (response.status !== 200) {
          throw new Error("Erro ao obter resposta do bot");
        }
        const answer = response.data.text;

        const sound = await getMP3AudioAsync(answer);
        sound.play();

        setMessages((old) => [
          ...old,
          {
            id: uuidv4(),
            from: "BOT",
            message: answer,
            sound: sound,
            time: moment().format("HH:mm"),
          },
        ]);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    const messageAppend =
      botContext.botMode !== "CHAT_KB"
        ? ""
        : "Estou conectado com as bases de conhecimento da QYON.";

    let newMessages = Object.assign([], messages);
    newMessages[0]["message"] = newMessages[0]["message"] + messageAppend;

    setMessages(newMessages);
  }, [botContext.botMode]);

  const setupBaseUrlForProducts = () => {
    const objMeus = {};

    if (!Array.isArray(companyUser?.systemsPermissions)) return;
    companyUser?.systemsPermissions.forEach((menu) => {
      objMeus[menu.id] = { url: menu.url };
    });

    setBaseUrls(objMeus);
  };

  const keyPress = async (e) => {
    if (e.keyCode === 13) {
      setText("");
      const text = e.target.value;
      sendMessage(text);
    }
  };

  const keyPressContext = async (e) => {
    if (e.keyCode === 13) {
      setText("");
      const text = e.target.value;
      sendMessageOption(text);
    }
  };

  const [botActionId, setBotActionId] = useState(null);

  const sendMessage = async (text) => {
    if (baseUrls === null) {
      return;
    }

    if (text.trim() === "") {
      return;
    }

    setText("");

    props.botRef.current.think();

    const newMessages = Object.assign([], messages);
    newMessages.push({
      id: uuidv4(),
      from: "HUMAN",
      message: text,
      time: moment().format("HH:mm"),
    });

    newMessages.push({
      id: uuidv4(),
      from: "BOT",
      message: "Aguarde um momento...",
      time: moment().format("HH:mm"),
    });
    setMessages(newMessages);

    const mode =
      botContext.botMode === "CHAT" ||
      botContext.botMode === "GENERAL" ||
      botContext.botMode === "CHAT_CONTEXT"
        ? "CHAT"
        : "KNOWLEDGE_BASE";

    // eslint-disable-next-line no-unused-vars
    let botMessage = await getBotMessage(text, mode, botActionId);

    if (botMessage === null || botMessage.status !== 200) {
      props.botRef.current.answer();

      const newMessagesNew = Object.assign([], newMessages);

      newMessagesNew[newMessagesNew.length - 1]["message"] =
        "Desculpa não entendi.";

      const sound = await getMP3AudioAsync("Desculpa, não entendi");
      sound.play();
      newMessagesNew[newMessagesNew.length - 1]["sound"] = sound;

      setMessages(newMessagesNew);
    } else {
      setBotActionId(botMessage.data.bot_question_id);
      props.botRef.current.answer();

      const newMessagesNew = Object.assign([], newMessages);

      let text = botMessage.data.text;

      newMessagesNew[newMessagesNew.length - 1]["message"] = text;

      if (botMessage.data.file) {
        newMessagesNew[newMessagesNew.length - 1]["file"] =
          botMessage.data.file;
      }

      if (botMessage.data.hits) {
        newMessagesNew[newMessagesNew.length - 1]["hits"] =
          botMessage.data.hits;
      }

      if (botMessage.data.courses && botMessage.data.courses.length > 0) {
        newMessagesNew[newMessagesNew.length - 1]["courses"] =
          botMessage.data.courses;
      }

      const { thereIsANewText, newText } = doAction(botMessage);
      if (thereIsANewText) {
        text = newText;
        newMessagesNew[newMessagesNew.length - 1]["message"] = newText;
      }

      newMessagesNew[newMessagesNew.length - 1]["model"] =
        botMessage.data.model;

      newMessagesNew[newMessagesNew.length - 1]["origin"] =
        botMessage.data.origin;

      const sound = await getMP3AudioAsync(text);

      sound.play();

      newMessagesNew[newMessagesNew.length - 1]["sound"] = sound;

      setMessages(newMessagesNew);
    }
  };

  const doAction = (botMessage) => {
    if (botMessage.data && botMessage.data.data) {
      if (typeof botMessage.data.data == "string") {
        if (JSON.parse(botMessage.data.data).event === "SHOW_PANEL_TO_CLIENT") {
          props.setOpenPanelVideo(true);
        }

        if (JSON.parse(botMessage.data.data).event === "CONFIGURE_WEBSITE") {
          window.open(JSON.parse(botMessage.data.data).data, "_blank");
        }

        if (JSON.parse(botMessage.data.data).event === "SHOW_VIDEO_LIST") {
          props.setVideoList(JSON.parse(botMessage.data.data).data);
          props.setOpenShowVideoList(true);
        }

        if (JSON.parse(botMessage.data.data).event === "OPEN_FOLHA") {
          const shoudOpen = shouldOpenFolhaFiscalContabilXmlERP(
            "Folha",
            userPermissons,
            isStandaloneClient,
            handleProductMenuHide,
            validateProductStatus
          );

          if (shoudOpen !== null) {
            return shoudOpen;
          }

          const path = `${baseUrls[2]["url"]}/panel/${
            userLogged.token
          }?company_document=${
            !isClient ? companyUser.document : companyUser.accountantCnpj
          }`;
          window.open(path, "_blank");
          return {
            thereIsANewText: false,
            newText: "",
          };
        }

        if (JSON.parse(botMessage.data.data).event === "OPEN_CONTABIL") {
          const shoudOpen = shouldOpenFolhaFiscalContabilXmlERP(
            "Contabil",
            userPermissons,
            isStandaloneClient,
            handleProductMenuHide,
            validateProductStatus
          );

          if (shoudOpen !== null) {
            return shoudOpen;
          }

          const path = `${baseUrls[5]["url"]}/panel/${userLogged.token}?company_document=${companyUser.document}`;
          window.open(path, "_blank");
          return {
            thereIsANewText: false,
            newText: "",
          };
        }
        if (JSON.parse(botMessage.data.data).event === "OPEN_FISCAL") {
          const shoudOpen = shouldOpenFolhaFiscalContabilXmlERP(
            "Fiscal",
            userPermissons,
            isStandaloneClient,
            handleProductMenuHide,
            validateProductStatus
          );

          if (shoudOpen !== null) {
            return shoudOpen;
          }

          const path = `${baseUrls[6]["url"]}/panel/${userLogged.token}?company_document=${companyUser.document}`;
          window.open(path, "_blank");
          return {
            thereIsANewText: false,
            newText: "",
          };
        }

        if (JSON.parse(botMessage.data.data).event === "OPEN_XML") {
          const shoudOpen = shouldOpenFolhaFiscalContabilXmlERP(
            "XML",
            userPermissons,
            isStandaloneClient,
            handleProductMenuHide,
            validateProductStatus
          );

          if (shoudOpen !== null) {
            return shoudOpen;
          }

          const path = `${baseUrls[3]["url"]}/#/remote?token=${userLogged.tokenCognito}&cnpj=${companyUser.document}&painel=true`;
          window.open(path, "_blank");
          return {
            thereIsANewText: false,
            newText: "",
          };
        }

        if (JSON.parse(botMessage.data.data).event === "OPEN_ERP") {
          const shoudOpen = shouldOpenFolhaFiscalContabilXmlERP(
            "ERP",
            userPermissons,
            isStandaloneClient,
            handleProductMenuHide,
            validateProductStatus
          );

          if (shoudOpen !== null) {
            return shoudOpen;
          }

          const path = `${baseUrls[1]["url"]}/zf/login/loginpanel?tokenCognito=${userLogged.token}&document=${companyUser.document}`;
          window.open(path, "_blank");
          return {
            thereIsANewText: false,
            newText: "",
          };
        }

        if (JSON.parse(botMessage.data.data).event === "OPEN_CND") {
          if (!userPermissons.cnd) {
            return {
              thereIsANewText: true,
              newText: "Acesso restrito ao usuário",
            };
          }

          const path =
            !companyUser.isAccountant && companyUser.accountantId
              ? "/cnd/customer"
              : "/cnd/accountant";
          window.open(path, "_blank");
          return {
            thereIsANewText: false,
            newText: "",
          };
        }

        if (JSON.parse(botMessage.data.data).event === "OPEN_VERSION_CONTROL") {
          const path = "/version";
          window.open(path, "_blank");
          return {
            thereIsANewText: false,
            newText: "",
          };
        }

        if (JSON.parse(botMessage.data.data).event === "OPEN_CRM") {
          if (!userPermissons.crm) {
            return {
              thereIsANewText: true,
              newText: "Acesso restrito ao usuário",
            };
          }

          const path = `${baseUrls[4]["url"]}/crm/PartnerLogin.asp?token=${userLogged.tokenCognito}&cnpj=${companyUser.document}`;
          window.open(path, "_blank");
          return {
            thereIsANewText: false,
            newText: "",
          };
        }

        if (JSON.parse(botMessage.data.data).event === "OPEN_BANK") {
          window.open("https://bank.qyon.com/");
          return {
            thereIsANewText: false,
            newText: "",
          };
        }
      } else {
        if (botMessage.data.action === "UPLOAD_CERTIFICATE") {
          window.open(
            "https://www.qyonxml.com/contabilidade?X-Remote=8b6b3315-30b2-4520-9c9f-68fc23ff4e5d",
            "_blank"
          );
          return {
            thereIsANewText: false,
            newText: "",
          };
        }
      }
    }

    return {
      thereIsANewText: false,
      newText: "",
    };
  };

  const doDisableAudioRecording = () => {
    recognition.stop();
  };

  const doAudioRecording = () => {
    try {
      props.botRef.current.listen();

      window.SpeechRecognition =
        window.webkitSpeechRecognition || window.SpeechRecognition;
      let recognition = new window.SpeechRecognition();
      setRecognition(recognition);

      recognition.lang = "pt-BR";
      //recognition.continuous = true;
      recognition.onresult = async (event) => {
        const speechToText = event.results[event.resultIndex][0].transcript;
        if (botContext.botMode === "CHAT") {
          sendMessage(speechToText);
        }
        if (botContext.botMode === "CHAT_CONTEXT") {
          sendMessageOption(speechToText);
        }

        recognition.stop();
        setAudioListening(false);
      };

      recognition.start();
    } catch (err) {
      console.error(err);
    }
  };

  const formatDuration = (seconds) => {
    seconds = parseInt(seconds);
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${String(minutes).padStart(2, "0")}:${String(
      remainingSeconds
    ).padStart(2, "0")}`;
  };

  const showItems = (value, prop) => {
    const newMessages = Object.assign([], messages);
    newMessages.forEach((message) => {
      if (message.id === value.id) {
        if (message[prop]) {
          message[prop] = false;
          return;
        }
        message[prop] = true;
      }
    });
    setMessages(newMessages);
  };

  const goToGenial4 = (value) => {
    let type = "";
    if (isAccountant()) {
      type = "ACCOUNTANT";
    }

    if (isAccountantClient()) {
      type = "ACCOUNTANT_CLIENT";
    }

    if (isCompany()) {
      type = "COMPANY";
    }

    let relationships = "";
    if (
      companyUser.systemsPermissions !== null &&
      companyUser.systemsPermissions !== undefined
    ) {
      if (companyUser.systemsPermissions.length > 0) {
        let systemsActive = companyUser.systemsPermissions.filter((system) => {
          return system.status_product === "A";
        });

        systemsActive.forEach((system, index) => {
          switch (system.id) {
            case 1:
              relationships += "erp";
              break;
            case 2:
              relationships += "folha";
              break;
            case 3:
              relationships += "xml";
              break;
            case 4:
              relationships += "crm";
              break;
            case 5:
              relationships += "contabil";
              break;
            case 6:
              relationships += "fiscal";
              break;
            default:
              break;
          }
          if (index !== systemsActive.length - 1) {
            relationships += ",";
          }
        });
      }
    }
    window.open(
      `https://qyon.genial4.com?token=${localStorage.getItem(
        "tokenCognitoPanel"
      )}&email=${localStorage.getItem("emailPanel")}&first_name=${
        userLogged.firstName
      }&last_name=${userLogged.lastName}&govid=${localStorage.getItem(
        "documentPanel"
      )}&type=${type}${
        relationships !== "" ? "&relationships_user=" + relationships : ""
      }&kbId=${value._source.knowledge_base_id}&pageId=${
        value._id
      }&institutionId=${value._source.institution_id}`,
      "_blank"
    );
  };

  const resetChat = () => {
    setMessages([
      {
        id: uuidv4(),
        from: "BOT",
        message: "Olá, como posso te ajudar? ",

        time: moment().format("HH:mm"),
      },
    ]);
  };

  const [isOverflowing, setIsOverflowing] = useState([]);

  const checkOverflow = () => {
    const overflowStatus = messages.map((message, index) => {
      const element = document.getElementById(`message-${index}`);
      if (element === null) {
        return null;
      }
      return element.innerText.length > 200;
    });
    setIsOverflowing(overflowStatus);
  };

  useEffect(() => {
    checkOverflow();
  }, [messages]);

  function formatCNPJorCPF(document) {
    return document.length === 14
      ? document.replace(
          /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
          "$1.$2.$3/$4-$5"
        )
      : document.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
  }

  return (
    <Slide direction="left" in={props.openModal} mountOnEnter unmountOnExit>
      <Container>
        <div
          style={{
            position: "relative",
          }}
        >
          <GoBack
            onClick={() => {
              resetChat();
              props.closeBotChatModal();
            }}
          >
            <CloseIcon
              style={{
                position: "relative",
                color: "#6d6d6d",
                fontSize: 20,
              }}
            />
          </GoBack>
          <Grid
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: 20,
              marginBottom: 20,
            }}
          >
            <BotLogoType />
          </Grid>
        </div>

        {(botContext.botMode === "CHAT" ||
          botContext.botMode === "CHAT_KB") && (
          <BotChatContainer>
            <MessageContainer ref={messagesEndRef}>
              <ScrollableFeed key={messages}>
                <>
                  {messages.map((value, index) => {
                    if (value.from === "BOT") {
                      return (
                        <div className="botMsg">
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <BotMessage>
                              <BotMessageText className="botMessageText">
                                {value?.message && (
                                  <span>
                                    {parse(
                                      value?.message?.replace(
                                        "<a ",
                                        "<a target='_blank'"
                                      )
                                    )}
                                  </span>
                                )}

                                {value.file && (
                                  <div>
                                    <a
                                      style={{ color: "white" }}
                                      href={
                                        "data:application/octet-stream;base64," +
                                        value.file.base64
                                      }
                                      download={value.file.name}
                                    >
                                      {value.file.name}
                                    </a>
                                  </div>
                                )}

                                {value.model && value.model === "DEEP_AI" && (
                                  <Disclaimer>
                                    * Essa informação foi obtida da internet e
                                    requer verificação antes de uso.
                                  </Disclaimer>
                                )}
                              </BotMessageText>
                            </BotMessage>

                            {value.sound && value.sound.paused && (
                              <PlaySoundContainer>
                                <PlaySoundIcon
                                  onClick={() => {
                                    value.sound.play();

                                    const newMessagesNew = Object.assign(
                                      [],
                                      messages
                                    );

                                    newMessagesNew[newMessagesNew.length - 1][
                                      "sound"
                                    ] = value.sound;
                                    setMessages(newMessagesNew);
                                  }}
                                />
                                <Grid
                                  spacing={1}
                                  container
                                  style={{
                                    marginTop: 5,
                                    paddingLeft: 10,
                                    paddingRight: 10,
                                  }}
                                >
                                  <Grid xs item>
                                    <LinearProgress
                                      value={0}
                                      variant="determinate"
                                    />
                                  </Grid>
                                </Grid>

                                {value.sound.duration &&
                                  !isNaN(value.sound.duration) && (
                                    <AudioSize>
                                      {formatDuration(value.sound.duration)}
                                    </AudioSize>
                                  )}
                              </PlaySoundContainer>
                            )}

                            {value.sound && !value.sound.paused && (
                              <PlaySoundContainer>
                                <StopSoundIcon
                                  onClick={() => {
                                    value.sound.pause();

                                    const newMessagesNew = Object.assign(
                                      [],
                                      messages
                                    );

                                    newMessagesNew[newMessagesNew.length - 1][
                                      "sound"
                                    ] = value.sound;
                                    setMessages(newMessagesNew);
                                  }}
                                />
                                <Grid
                                  spacing={1}
                                  container
                                  style={{
                                    marginTop: 5,
                                    paddingLeft: 10,
                                    paddingRight: 10,
                                  }}
                                >
                                  <Grid xs item>
                                    <LinearProgress />
                                  </Grid>
                                </Grid>

                                {value.sound.duration &&
                                  !isNaN(value.sound.duration) && (
                                    <AudioSize>
                                      {formatDuration(value.sound.duration)}
                                    </AudioSize>
                                  )}
                              </PlaySoundContainer>
                            )}
                          </div>

                          {value.hits && (
                            <KnowledgeBaseCardContainer>
                              {value.hits.hits.hits.length > 0 && (
                                <ItemRelatedButton
                                  onClick={() => {
                                    showItems(value, "showKb");
                                  }}
                                >
                                  Artigos relacionados
                                  <BadgeButton>
                                    {value.hits.hits.hits.length}
                                  </BadgeButton>
                                </ItemRelatedButton>
                              )}

                              {value.hits.hits.hits.length > 0 &&
                                value.showKb === true && (
                                  <div>
                                    {value.hits.hits.hits.map(
                                      (value, index) => {
                                        return (
                                          <KnowledgeBaseCard
                                            onClick={() => {
                                              goToGenial4(value);
                                            }}
                                          >
                                            <div style={{ fontWeight: "bold" }}>
                                              {value._source.page_name}
                                            </div>
                                            <KnowledgeBaseCardText>
                                              {value._source.resume}
                                            </KnowledgeBaseCardText>
                                          </KnowledgeBaseCard>
                                        );
                                      }
                                    )}
                                  </div>
                                )}
                            </KnowledgeBaseCardContainer>
                          )}

                          {value.courses && (
                            <div style={{ marginTop: 10 }}>
                              <KnowledgeBaseCardContainer>
                                {value.courses.length > 0 && (
                                  <ItemRelatedButton
                                    style={{ marginTop: 10 }}
                                    onClick={() => {
                                      showItems(value, "showCourse");
                                    }}
                                  >
                                    Cursos relacionados
                                    <BadgeButton>
                                      {value.courses.length}
                                    </BadgeButton>
                                  </ItemRelatedButton>
                                )}

                                {value.courses.length > 0 &&
                                  value.showCourse === true && (
                                    <div>
                                      {value.courses.map((value, index) => {
                                        return (
                                          <CourseCard
                                            onClick={() => {
                                              //https://qyon.genial4.com/main/knowledge_base/{ID}?page={PAGEID}&token={TOKEN}
                                              window.open(
                                                `https://app.genial4.com/main/grade/${value.mooc_id}?resource=mooc`,
                                                "_blank"
                                              );
                                            }}
                                          >
                                            <img
                                              alt="img"
                                              src={`https://dartiv-sa.s3-sa-east-1.amazonaws.com/prd/mooc/header/${value.mooc_id}.advertising_picture.jpg`}
                                              style={{
                                                width: "99%",
                                                height: 100,
                                                borderTopLeftRadius: 10,
                                                borderTopRightRadius: 10,
                                                objectFit: "cover",
                                              }}
                                            />
                                            <div
                                              style={{
                                                fontWeight: "bold",
                                                padding: 10,
                                                fontSize: 12,
                                              }}
                                            >
                                              {value.name}
                                            </div>
                                            <KnowledgeBaseCardText
                                              style={{ padding: 10 }}
                                            >
                                              {value.instructor_name} -{" "}
                                              {value.instructor_email}
                                            </KnowledgeBaseCardText>
                                          </CourseCard>
                                        );
                                      })}
                                    </div>
                                  )}
                              </KnowledgeBaseCardContainer>
                            </div>
                          )}
                        </div>
                      );
                    }

                    return (
                      <div>
                        <HumanMessage key={index}>
                          <HumanMessageText
                            id={`message-${index}`}
                            textOverflow={
                              isOverflowing[index] === null
                                ? true
                                : isOverflowing[index]
                            }
                          >
                            {value.message}
                          </HumanMessageText>
                          {(isOverflowing[index] === null ||
                            isOverflowing[index]) && (
                            <HumanShowMore
                              onClick={() => {
                                setIsOverflowing(
                                  isOverflowing.map((item, i) => {
                                    if (i === index) {
                                      return !item;
                                    }
                                    return item;
                                  })
                                );
                              }}
                            >
                              Mostrar mais
                            </HumanShowMore>
                          )}
                        </HumanMessage>

                        <div
                          style={{
                            marginLeft: 20,
                            bottom: 15,
                            position: "relative",
                            fontSize: 10,
                            textAlign: "right",
                            marginRight: 20,
                            color: "black",
                          }}
                        >
                          Enviado às {value.time}
                        </div>
                      </div>
                    );
                  })}
                </>
              </ScrollableFeed>
            </MessageContainer>
            <SendMessageContainer>
              <InputContainer>
                <CustomTextField
                  variant="standard"
                  InputProps={{
                    style: {
                      fontSize: 12,
                      textAlign: "center",
                    },
                    endAdornment: (
                      <>
                        <IconButton
                          size="small"
                          onClick={() => {
                            sendMessage(text);
                            setText("");
                          }}
                        >
                          <BotActionIcons
                            icon="send"
                            color="#808080"
                          ></BotActionIcons>
                        </IconButton>
                        <AudioButton
                          onClick={() => {
                            if (audioListening === false) {
                              setAudioListening(true);
                              doAudioRecording();
                            } else {
                              setAudioListening(false);
                              doDisableAudioRecording();
                            }
                          }}
                        >
                          <BotActionIcons
                            icon="microphone"
                            color={audioListening ? "red" : "#808080"}
                          />
                        </AudioButton>
                      </>
                    ),
                    disableUnderline: true,
                  }}
                  onKeyDown={(e) => keyPress(e)}
                  inputRef={(input) => input && input.focus()}
                  value={text}
                  onChange={(e) => setText(e.target.value)}
                  placeholder="Escreva sua mensagem"
                  focused
                />
              </InputContainer>
            </SendMessageContainer>
          </BotChatContainer>
        )}

        {botContext.botMode === "CHAT_CONTEXT" && (
          <BotChatContainer>
            <MessageContainer ref={messagesEndRef}>
              <ScrollableFeed key={messages}>
                <>
                  {messages.map((value, index) => {
                    if (value.from === "BOT") {
                      return (
                        <div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <BotMessage style={{ marginTop: 10 }}>
                              <BotMessageText className="botMessageText">
                                {value?.message && (
                                  <span>
                                    {parse(
                                      value?.message?.replace(
                                        "<a ",
                                        "<a target='_blank'"
                                      )
                                    )}
                                  </span>
                                )}

                                {value.file && (
                                  <div>
                                    <a
                                      style={{ color: "white" }}
                                      href={
                                        "data:application/octet-stream;base64," +
                                        value.file.base64
                                      }
                                      download={value.file.name}
                                    >
                                      {value.file.name}
                                    </a>
                                  </div>
                                )}

                                {value.model && value.model === "DEEP_AI" && (
                                  <Disclaimer>
                                    * Essa informação foi obtida da internet e
                                    requer verificação antes de uso.
                                  </Disclaimer>
                                )}
                              </BotMessageText>
                            </BotMessage>

                            {value.sound && value.sound.paused && (
                              <PlaySoundContainer>
                                <PlaySoundIcon
                                  onClick={() => {
                                    value.sound.play();

                                    const newMessagesNew = Object.assign(
                                      [],
                                      messages
                                    );

                                    newMessagesNew[newMessagesNew.length - 1][
                                      "sound"
                                    ] = value.sound;
                                    setMessages(newMessagesNew);
                                  }}
                                />
                                <Grid
                                  spacing={1}
                                  container
                                  style={{
                                    marginTop: 5,
                                    paddingLeft: 10,
                                    paddingRight: 10,
                                  }}
                                >
                                  <Grid xs item>
                                    <LinearProgress
                                      value={0}
                                      variant="determinate"
                                    />
                                  </Grid>
                                </Grid>

                                {value.sound.duration &&
                                  !isNaN(value.sound.duration) && (
                                    <AudioSize>
                                      {formatDuration(value.sound.duration)}
                                    </AudioSize>
                                  )}
                              </PlaySoundContainer>
                            )}

                            {value.sound && !value.sound.paused && (
                              <PlaySoundContainer>
                                <StopSoundIcon
                                  onClick={() => {
                                    value.sound.pause();

                                    const newMessagesNew = Object.assign(
                                      [],
                                      messages
                                    );

                                    newMessagesNew[newMessagesNew.length - 1][
                                      "sound"
                                    ] = value.sound;
                                    setMessages(newMessagesNew);
                                  }}
                                />
                                <Grid
                                  spacing={1}
                                  container
                                  style={{
                                    marginTop: 5,
                                    paddingLeft: 10,
                                    paddingRight: 10,
                                  }}
                                >
                                  <Grid xs item>
                                    <LinearProgress />
                                  </Grid>
                                </Grid>

                                {value.sound.duration &&
                                  !isNaN(value.sound.duration) && (
                                    <AudioSize>
                                      {formatDuration(value.sound.duration)}
                                    </AudioSize>
                                  )}
                              </PlaySoundContainer>
                            )}
                          </div>

                          {value.hits && (
                            <KnowledgeBaseCardContainer>
                              {value.hits.hits.hits.length > 0 && (
                                <ItemRelatedButton
                                  onClick={() => {
                                    showItems(value, "showKb");
                                  }}
                                >
                                  Artigos relacionados
                                  <BadgeButton>
                                    {value.hits.hits.hits.length}
                                  </BadgeButton>
                                </ItemRelatedButton>
                              )}

                              {value.hits.hits.hits.length > 0 &&
                                value.showKb === true && (
                                  <div>
                                    {value.hits.hits.hits.map(
                                      (value, index) => {
                                        return (
                                          <KnowledgeBaseCard
                                            onClick={() => {
                                              goToGenial4(value);
                                            }}
                                          >
                                            <div style={{ fontWeight: "bold" }}>
                                              {value._source.page_name}
                                            </div>
                                            <KnowledgeBaseCardText>
                                              {value._source.resume}
                                            </KnowledgeBaseCardText>
                                          </KnowledgeBaseCard>
                                        );
                                      }
                                    )}
                                  </div>
                                )}
                            </KnowledgeBaseCardContainer>
                          )}

                          {value.courses && (
                            <div style={{ marginTop: 10 }}>
                              <KnowledgeBaseCardContainer>
                                {value.courses.length > 0 && (
                                  <ItemRelatedButton
                                    style={{ marginTop: 10 }}
                                    onClick={() => {
                                      showItems(value, "showCourse");
                                    }}
                                  >
                                    Cursos relacionados
                                    <BadgeButton>
                                      {value.courses.length}
                                    </BadgeButton>
                                  </ItemRelatedButton>
                                )}

                                {value.courses.length > 0 &&
                                  value.showCourse === true && (
                                    <div>
                                      {value.courses.map((value, index) => {
                                        return (
                                          <CourseCard
                                            onClick={() => {
                                              //https://qyon.genial4.com/main/knowledge_base/{ID}?page={PAGEID}&token={TOKEN}
                                              window.open(
                                                `https://app.genial4.com/main/grade/${value.mooc_id}?resource=mooc`,
                                                "_blank"
                                              );
                                            }}
                                          >
                                            <img
                                              alt="img"
                                              src={`https://dartiv-sa.s3-sa-east-1.amazonaws.com/prd/mooc/header/${value.mooc_id}.advertising_picture.jpg`}
                                              style={{
                                                width: "99%",
                                                height: 100,
                                                borderTopLeftRadius: 10,
                                                borderTopRightRadius: 10,
                                                objectFit: "cover",
                                              }}
                                            />
                                            <div
                                              style={{
                                                fontWeight: "bold",
                                                padding: 10,
                                                fontSize: 12,
                                              }}
                                            >
                                              {value.name}
                                            </div>
                                            <KnowledgeBaseCardText
                                              style={{ padding: 10 }}
                                            >
                                              {value.instructor_name} -{" "}
                                              {value.instructor_email}
                                            </KnowledgeBaseCardText>
                                          </CourseCard>
                                        );
                                      })}
                                    </div>
                                  )}
                              </KnowledgeBaseCardContainer>
                            </div>
                          )}
                        </div>
                      );
                    }

                    if (value.from === "BOT_OPTION") {
                      return (
                        <div className="botOption">
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            <BotMessageOption
                              onClick={() => {
                                sendMessageOption(value.message);
                              }}
                            >
                              <BotMessageText className="botMessageText">
                                {value?.message && (
                                  <span>
                                    {parse(
                                      value?.message?.replace(
                                        "<a ",
                                        "<a target='_blank'"
                                      )
                                    )}
                                  </span>
                                )}
                                {value.model && value.model === "DEEP_AI" && (
                                  <Disclaimer>
                                    * Essa informação foi obtida da internet e
                                    requer verificação antes de uso.
                                  </Disclaimer>
                                )}
                              </BotMessageText>
                            </BotMessageOption>
                          </div>
                        </div>
                      );
                    }

                    return (
                      <div>
                        <HumanMessage key={index}>
                          <HumanMessageText
                            id={`message-${index}`}
                            textOverflow={
                              isOverflowing[index] === null
                                ? true
                                : isOverflowing[index]
                            }
                          >
                            {value.message}
                          </HumanMessageText>
                          {(isOverflowing[index] === null ||
                            isOverflowing[index]) && (
                            <HumanShowMore
                              onClick={() => {
                                setIsOverflowing(
                                  isOverflowing.map((item, i) => {
                                    if (i === index) {
                                      return !item;
                                    }
                                    return item;
                                  })
                                );
                              }}
                            >
                              Mostrar mais
                            </HumanShowMore>
                          )}
                        </HumanMessage>
                        <div
                          style={{
                            marginLeft: 20,
                            bottom: 15,
                            position: "relative",
                            fontSize: 10,
                            textAlign: "right",
                            marginRight: 20,
                            color: "black",
                          }}
                        >
                          Enviado às {value.time}
                        </div>
                      </div>
                    );
                  })}
                </>
              </ScrollableFeed>
            </MessageContainer>
            <SendMessageContainer>
              <InputContainer>
                <CustomTextField
                  variant="standard"
                  InputProps={{
                    style: {
                      fontSize: 12,
                      textAlign: "center",
                    },
                    endAdornment: (
                      <AudioButton
                        onClick={() => {
                          if (audioListening === false) {
                            setAudioListening(true);
                            doAudioRecording();
                          } else {
                            setAudioListening(false);
                            doDisableAudioRecording();
                          }
                        }}
                      >
                        <BotActionIcons
                          icon="microphone"
                          color={audioListening ? "red" : "#808080"}
                        />
                      </AudioButton>
                    ), // <== adjusted this
                    disableUnderline: true, // <== added this
                  }}
                  onKeyDown={(e) => keyPressContext(e)}
                  inputRef={(input) => input && input.focus()}
                  value={text}
                  onChange={(e) => setText(e.target.value)}
                  placeholder="Escreva sua mensagem"
                  focused
                />
              </InputContainer>
            </SendMessageContainer>
          </BotChatContainer>
        )}

        {botContext.botMode === "SELECT_CLIENT" && (
          <div style={{ overflowY: "auto", marginBottom: 0 }}>
            <BotMessage style={{ marginTop: 10 }}>
              <BotMessageText className="botMessageText">
                Selecione uma de suas empresas para que eu possa te ajudar
                melhor
              </BotMessageText>
            </BotMessage>
            {clients.map((value, index) => {
              return (
                <div
                  onClick={async () => {
                    setClient(value);
                    setMessages((old) => [
                      ...old,
                      {
                        id: uuidv4(),
                        from: "HUMAN",
                        message:
                          "Me ajude com a folha de pagamentos na empresa " +
                          value.company_name,
                        time: moment().format("HH:mm"),
                      },
                      {
                        id: uuidv4(),
                        from: "BOT",
                        message:
                          "Aguarde um momento, estou acessando os dados dos funcionários da empresa",
                        time: moment().format("HH:mm"),
                      },
                    ]);
                    const employeesText = await getEmployees(value.document);
                    const prompt = [
                      employeesText,
                      "diga: 'Pronto, acessei os dados dos funcionários, pode me perguntar qualquer coisa'",
                    ];

                    botContext.setBotTextContext(prompt);
                    botContext.setBotCurrentContext("PAYROLL");
                    botContext.setBotMode("CHAT_CONTEXT");
                    botContext.setBotPresentationRunning(null);
                    botContext.setOpenBotChatModal(true);
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <BotMessage
                      style={{
                        marginTop: 10,
                        fontWeight: "bold",
                        cursor: "pointer",
                      }}
                    >
                      <BotMessageText className="botMessageText">
                        {index + 1}. {value.company_name}
                        <br></br>
                        {formatCNPJorCPF(value.document)}
                      </BotMessageText>
                    </BotMessage>
                  </div>
                </div>
              );
            })}
          </div>
        )}

        {botContext.botMode === "GENERAL" && (
          <>
            <PopularTopic>
              <MagicWand />
              <span style={{ marginLeft: 5, bottom: 5, position: "relative" }}>
                Exemplos de ações rápidas
              </span>
            </PopularTopic>
            <div
              style={{ overflowY: "auto", marginBottom: 0, paddingBottom: 10 }}
            >
              {generalQuestionList.map((value, index) => {
                return (
                  <TopicCard
                    onClick={() => {
                      setText(value.text);
                      sendMessage(value.text);
                      botContext.setBotMode("CHAT");
                    }}
                  >
                    <FastTitle>{value.text}</FastTitle>
                  </TopicCard>
                );
              })}
            </div>
          </>
        )}

        {botContext.botMode === "TOPIC" && (
          <>
            <MessageContainer ref={messagesEndRef}>
              <TopicContainer>
                <PopularTopic>Tópicos populares</PopularTopic>
                <TopicCard
                  onClick={() => {
                    resetChat();

                    botContext.setBotMode("CHAT");
                  }}
                >
                  <div style={{ display: "flex" }}>
                    <ChatAction />
                    <TopicTitle style={{ marginLeft: 10 }}>
                      Iniciar nova conversa com QYON Bot
                    </TopicTitle>
                  </div>
                  <TopicDescription>Pergunte o que desejar</TopicDescription>
                </TopicCard>
                <TopicCard
                  onClick={() => {
                    resetChat();

                    botContext.setBotMode("GENERAL");
                  }}
                >
                  <div style={{ display: "flex" }}>
                    <FastAction />
                    <TopicTitle>Exemplos de Ações Rápidas</TopicTitle>
                  </div>
                  <TopicDescription>
                    Explore exemplos de ações do Bot
                  </TopicDescription>
                </TopicCard>
                <TopicCard
                  onClick={() => {
                    resetChat();

                    botContext.setBotMode("CHAT_KB");
                  }}
                >
                  <div style={{ display: "flex" }}>
                    <KnowledgeBaseIcon />
                    <TopicTitle>
                      Base de<br></br>conhecimento
                    </TopicTitle>
                  </div>
                  <TopicDescription>
                    Aprenda rápido com resumos e artigos
                  </TopicDescription>
                </TopicCard>

                {/*
                  <TopicCard
                    onClick={async () => {
                      resetChat();
                      let clients = await getCustomersEntityNew({
                        id: companyUser.companyId,
                      });

                      setClients(clients.data);

                      botContext.setBotMode("SELECT_CLIENT");
                    }}
                  >
                    <div style={{ display: "flex" }}>
                      <Folha style={{ marginLeft: 2, marginRight: 4 }} />
                      <TopicTitle>
                        Folha de<br></br>pagamento
                      </TopicTitle>
                    </div>
                    <TopicDescription>
                      Tire suas dúvidas sobre a folha de pagamento
                    </TopicDescription>
                  </TopicCard>
                  */}
              </TopicContainer>
            </MessageContainer>
          </>
        )}
      </Container>
    </Slide>
  );
});

export default BotChatModal;
