import {
  FormControl,
  FormControlLabel,
  Grid,
  Popover,
} from "@material-ui/core";
import styled, { css } from "styled-components";

export const CompanyName = styled.div`
  color: ${(props) => props.theme.palette.black};
  font-size: 20px;
  font-family: "Roboto", sans-serif !important;
`;

export const StyledSubheader = styled(Grid)`
  position: sticky;
  width: calc(100% + 28px);
  padding-left: 50px;
  float: right;
  padding-bottom: 10px;
  z-index: 1200;
  background-color: #f7f7f7;
  border-bottom: 1px solid #e8ecf4;
`;

export const CompanyLogo = styled.img`
  max-height: 50px;
  border-width: 2px;
  border-style: solid;
  border-radius: 5px 5px 5px 5px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.16);
  border-color: #ffffff;
`;

export const CompanyLogoDefault = styled.div`
  height: 50px;
  width: 50px;
  border-width: 2px;
  border-style: solid;
  border-color: #fff;
  border-radius: 5px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.16);
  font-size: 20px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #0a73db;
  color: #fff;
`;

export const ButtonSystem = styled.button`
align-items: center;
background-color: #f0f1f3;
border: 0;
border-radius: 100px;
box-sizing: border-box;
cursor: pointer;
display: inline-flex;
font-size: 12px;
font-weight: 500;
justify-content: center;
line-height: 10px;
max-width: 400px;
min-height: 35px;
min-width: 0px;
overflow: hidden;
padding: 0px;
padding-left: 20px;
padding-right: 20px;
text-align: center;
vertical-align: middle;
margin-top: 10px;
margin-left: 15px;
margin-bottom: 10px;
}

&&:hover {
  background: #e7eefe;
  color: #0c2d6a;
  tex-weight: 800px;
}

&&:active {
  background-color: #063bb5;
  color: #fff;
}

&&:focus {
  outline: none;
  background-color: #063bb5;
  color: #fff;
}

${(props) =>
  props.actived &&
  css`
    background-color: #063bb5;
    color: #fff;
  `}
`;

export const PopoverStyled = styled(Popover)`
  height: 500px;
`;

export const DivCompanyAbbreviation = styled.div`
  height: 50px;
  width: 50px;
  border-width: 2px;
  border-style: solid;
  border-color: #fff;
  border-radius: 5px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.16);
  font-size: 20px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #0a73db;
  color: #fff;
`;

export const FormControlStyled = styled(FormControl)`
  background-color: #f0f1f3;
  color: #063bb5;
  width: 100%;
`;

export const GridStyled = styled(Grid)`
  justify-content: center;
  justify-items: center;
  display: flex;
  margin-top: 40px;
  margin-bottom: 40px;
`;

export const ButtonRestoreDefault = styled.button`
align-items: center;
background-color: #ffffff;
color: #c2c2c2;
border: 0;
border-radius: 100px;
cursor: pointer;
display: inline-flex;
font-size: 12px;
font-weight: 500;
justify-content: center;
line-height: 10px;
min-width: 0px;
overflow: hidden;
padding: 0px;
padding-left: 20px;
padding-right: 20px;
text-align: center;
vertical-align: middle;
margin-top: 15px;
margin-left: 5px;
margin-bottom: 15px;
}

&&:hover {
  color: #696969;
  tex-weight: 800px;
}
`;

export const FormControlDashboard = styled(FormControl)`
  max-height: 55vh;
  overflow: auto;
  width: 100%;
`;

export const FormControlLabelStyled = styled(FormControlLabel)`
  margin-left: 20px;
  margin-top: 10px;
`;

export const DivNotResult = styled.div`
  margin-top: 50px;
  margin-bottom: 50px;
  margin-left: 170px;
`;
