import React from "react";
import { GridStyled } from "../../../styles/GlobalStyle";
import { useMonitor } from "../contexts/ChatMonitorContext";
import { PaperStyled } from "../styles/ChatMonitor";
import { TypographyStyled } from "../styles/GeneralStyle";
import RenderList from "./RenderList";
import RenderListItemCustomer from "./RenderListItemCustomer";

const Customers = () => {
  const { lisCurrentConversations } = useMonitor();

  return (
    <GridStyled width="100%" padding="10px 0">
      <PaperStyled padding-bottom="10px">
        {lisCurrentConversations?.length > 0 ? (
          <RenderList title={"Operadores disponíveis no chat"}>
            {lisCurrentConversations.map((value, index) => (
              <RenderListItemCustomer key={index} item={value} />
            ))}
          </RenderList>
        ) : (
          <GridStyled
            alignContent="center"
            alignItems="center"
            container
            xs
            padding="15px 10"
            text
          >
            <TypographyStyled
              component="span"
              variant="subtitle1"
              textAlign="center"
            >
              Nenhuma conversa em andamento encontrada
            </TypographyStyled>
          </GridStyled>
        )}
      </PaperStyled>
    </GridStyled>
  );
};

export default Customers;
