// Components
import { Grid } from "@material-ui/core";

import { useContext } from "react";
import { GlobalContext } from "../../../contexts/GlobalContext";
import { AccordionAccountantCndSettings } from "./AccordionAccountantCndSettings";
import { AccordionAccountantGeneralSettings } from "./AccordionAccountantGeneralSettings";
import { AccordionAccountantGroupSettings } from "./AccordionAccountantGroupSettings";

export const TabAccountantCndSettings = () => {
  const { companyUser } = useContext(GlobalContext);
  const isStandaloneClient =
    !companyUser.isAccountant && !companyUser.accountantId;
  return (
    <Grid container direction="column" spacing={3}>
      {!isStandaloneClient && (
        <Grid item>
          <AccordionAccountantGeneralSettings />
        </Grid>
      )}
      <Grid item>
        <AccordionAccountantCndSettings />
      </Grid>
      {!isStandaloneClient && (
        <Grid item>
          <AccordionAccountantGroupSettings />
        </Grid>
      )}
    </Grid>
  );
};
