import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  makeStyles,
} from "@material-ui/core";
import Btn from "../../../components/buttons/Btn";
import { Margin, Title } from "../../../styles/GlobalStyle";
import { useAttendance } from "../contexts/AttendanceContext";
import AutoCompleteCRM from "./AutoCompleteCRM";

const useStyles = makeStyles({
  dialog: {
    position: "absolute",
    left: "60%",
    bottom: 50,
  },
});

const ModalTransfer = ({
  setOpen,
  setIdTransfer,
  idTransfer,
  setFlagMessagePrivate,
  setStateCheckBoxEmail,
  flagMessagePrivate,
  modalAction,
  ...props
}) => {
  const classes = useStyles();
  const { users } = useAttendance();

  const cancelButton = () => {
    modalAction("cancel");
    setOpen(!props?.open);
  };

  const saveButton = () => {
    modalAction("save");
    setOpen(!props?.open);
  };

  const handleCheckPrivate = (e) => {
    setFlagMessagePrivate(e.target.checked);
  };

  const optionsList = (arr) => {
    try {
      let json = [];
      arr.items.forEach((item) => {
        json.push({
          title:
            item.description ||
            item.socialName ||
            item.fantasyName ||
            item.name,
          value: item.attendanceSituationId || item.id || item.productId,
        });
      });
      return json;
    } catch (error) {
      console.log("Error:", error);
      return [];
    }
  };

  return (
    <Dialog
      open={props?.open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="lg"
      classes={{
        paper: classes.dialog,
      }}
    >
      <DialogTitle id="alert-dialog-title">
        <Title subtitle>Transferir Atendimento</Title>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Margin left="10">
              <AutoCompleteCRM
                value={idTransfer}
                setValue={setIdTransfer}
                onChange={setIdTransfer}
                optionsList={optionsList(users)}
                label="Atendente"
                descriptionField="title"
              />
              <FormGroup>
                <FormControlLabel
                  control={<Checkbox color="Primary" />}
                  label="Transferência Privada"
                  value={flagMessagePrivate}
                  onClick={handleCheckPrivate}
                />
              </FormGroup>
            </Margin>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Btn
          label="Cancelar"
          bg-color="tertiary"
          onClick={() => {
            setIdTransfer(null);
            cancelButton();
          }}
        />
        {idTransfer && (
          <Btn
            label="Transferir"
            onClick={() => {
              setStateCheckBoxEmail({
                typeEmail: "transferAttendant",
                active: flagMessagePrivate ? false : true,
              });
              saveButton();
            }}
          />
        )}
      </DialogActions>
    </Dialog>
  );
};

export default ModalTransfer;
