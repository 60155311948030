import styled from "styled-components";
import { Grid } from "@material-ui/core";

export const GridListAttendance = styled(Grid)`
  border-radius: 10px 10px 10px 10px;
  background: #ffffff;
  height: 60px;
  width: 95%;
  margin: 3% 3% 10% 3%;
  box-shadow: 1px 1px 1px 0 #b3b3b3;
  display: flex;
  position: relative !important;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  ${(props) => (props.select === "selected" ? "border: 2px solid #1A7BDC" : "")}
`;
