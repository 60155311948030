import axios from "axios";

const newPath = `${process.env.REACT_APP_NEW_PAINEL_API_URL}/v1`;

export async function getRPAExecutions({ document }) {
  return await axios
    .get(`${newPath}/certificate/${document}/rpa/list`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("tokenPanel"),
        "Entity-Id": localStorage.getItem("companyIdPanel"),
        "Content-Type": "application/json",
        "Content-Language": "pt-BR",
      },
    })
    .then((response) => {
      return response.data.data;
    })
    .catch((err) => {
      return null;
    });
}

export async function startRPAExecution({ document }) {
  return await axios.post(
    `${newPath}/certificate/${document}/rpa/start`,
    {},
    {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("tokenPanel"),
        "Entity-Id": localStorage.getItem("companyIdPanel"),
        "Content-Type": "application/json",
        "Content-Language": "pt-BR",
      },
    }
  );
}
