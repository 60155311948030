import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import AutoCompleteCRM from "../../../pages/attendance/childrens/AutoCompleteCRM";
import { useChatContext } from "../contexts/ChatContext";
import { useMonitor } from "../contexts/ChatMonitorContext";
import { optionsList } from "../utils";

const AttendantFilters = () => {
  const {
    setStatusFilter,
    statusFilter,
    setDepartmentFilter,
    departmentFilter,
  } = useMonitor();

  const { settings } = useChatContext();

  return (
    <>
      <AutoCompleteCRM
        label="Departamentos"
        descriptionField="title"
        lg={2}
        value={departmentFilter}
        optionsList={optionsList(settings?.departments)}
        onChange={setDepartmentFilter}
        variantAutocomplete="filled"
      />
      <br />
      <FormControl style={{ width: "100%" }} variant="filled" size="small">
        <InputLabel id="select-label-status">Status</InputLabel>
        <Select
          labelId="select-label-status"
          id="demo-simple-select"
          onChange={(e) => setStatusFilter(e.target.value)}
          value={statusFilter}
        >
          <MenuItem value="">Nenhum</MenuItem>
          {settings.reason.map((status, index) => (
            <MenuItem key={index} value={status.state}>
              {status.description}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
};

export default AttendantFilters;
