import React, { useContext, useState } from "react";
import {
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
} from "@material-ui/core";
import { ConfigureERPContext } from "../contexts/ConfigureERPContext";
import MaskService from "../../../utils/mask/maskService";
import { requestComercialContact } from "../../../repositories/portal_api/AccountantClient";
import { validEmail } from "../../../utils/validations";
import { notify } from "../../../utils/notify";

export const ComercialContactTab = ({
  setLoading,
  accountantClientId,
  ...props
}) => {
  // TODO: IMPLEMENT API LOGIC TO SEND CONTACT EMAIL AND SALES CONTACT

  const {
    comercialContactName,
    setComercialContactName,
    comercialContactDocument,
    setComercialContactDocument,
    comercialContactPhone,
    setComercialContactPhone,
    comercialContactEmail,
    setComercialContactEmail,
    comercialContactAlreadyRequested,
  } = useContext(ConfigureERPContext);

  const [selectedOptionContact, setSelectedOptionContact] = useState(null);
  const [errorName, setErrorName] = useState(false);
  const [errorDocument, setErrorDocument] = useState(false);
  const [errorPhone, setErrorPhone] = useState(false);
  const [errorEmail, setErrorEmail] = useState(false);
  const [checkedConfirmation, setCheckedConfirmation] = useState(false);

  const validateFields = () => {
    let valid = true;
    if (!comercialContactName) {
      valid = false;
      setErrorName(true);
    }
    if (selectedOptionContact === "comercial_contact") {
      if (!comercialContactPhone) {
        valid = false;
        setErrorPhone(true);
      }

      if (comercialContactPhone.replace(/[^\d]+/g, "").length < 10) {
        valid = false;
        setErrorPhone(true);
      }
    }

    if (!comercialContactEmail) {
      valid = false;
      setErrorEmail(true);
    }

    if (!validEmail(comercialContactEmail.trim())) {
      valid = false;
      setErrorEmail(true);
    }

    if (!comercialContactDocument) {
      valid = false;
      setErrorDocument(true);
    }

    if (
      comercialContactDocument.replace(/[^\d]+/g, "").length < 11 ||
      comercialContactDocument.replace(/[^\d]+/g, "").length > 14
    ) {
      valid = false;
      setErrorDocument(true);
    }

    return valid;
  };

  const handleChangeTypeContact = (typeContact) => {
    switch (typeContact) {
      case "send_email":
        setCheckedConfirmation(false);
        setSelectedOptionContact("send_email");
        break;
      case "comercial_contact":
        if (selectedOptionContact !== "comercial_contact") {
          setCheckedConfirmation(false);
          setSelectedOptionContact("comercial_contact");
        }
        break;
      default:
        break;
    }
  };

  const sendComercialContact = () => {
    if (validateFields()) {
      setLoading(true);

      let body = {
        name: comercialContactName,
        document: comercialContactDocument.replace(/[^\d]+/g, ""),
        type_contact: selectedOptionContact,
        email: comercialContactEmail.trim(),
      };

      if (selectedOptionContact === "comercial_contact") {
        body = {
          ...body,
          phone: comercialContactPhone.replace(/[^\d]+/g, ""),
        };
      }

      requestComercialContact(accountantClientId, body)
        .then((response) => {
          if (response?.success) {
            notify(
              "Solicitação de contato comercial enviada com sucesso",
              true,
              "success"
            );
          } else {
            notify(
              response?.message ??
                "Erro ao enviar solicitação de contato comercial",
              true
            );
          }
        })
        .catch((error) => {
          notify(
            "Erro ao enviar solicitação de contato comercial",
            true,
            "error"
          );
          console.log("Erro ao enviar solicitação de contato comercial", error);
        })
        .finally(() => {
          setLoading(false);
        });

      // onConfirm();
    } else {
      notify("Preencha todos os campos corretamente");
    }
  };

  return (
    <div style={{ marginTop: 30 }}>
      <div>
        <h3 style={{ padding: 0, margin: "0px 0px 5px 0px" }}>
          Dados do Meu Cliente para Contato Comercial
        </h3>
        <Divider />
      </div>
      <div
        style={{
          background: "#fff",
          border: "1px solid #E0E0E0",
          borderRadius: 15,
          padding: 15,
          marginTop: 20,
        }}
      >
        <Grid container spacing={1}>
          <Grid
            item
            xs={12}
            sm={selectedOptionContact === "comercial_contact" ? 3 : 4}
          >
            <div>Nome para Contato*</div>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              onBlur={() => {
                setErrorName(false);
              }}
              error={errorName}
              placeholder="Nome para Contato"
              value={comercialContactName}
              onChange={(e) => {
                setComercialContactName(e.target.value);
              }}
            />
          </Grid>

          <Grid
            item
            xs={12}
            sm={selectedOptionContact === "comercial_contact" ? 3 : 4}
          >
            <div>CPF/CNPJ*</div>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              onBlur={() => {
                setErrorDocument(false);
              }}
              error={errorDocument}
              placeholder="CPF/CNPJ"
              disabled={true}
              value={comercialContactDocument}
              onChange={(e) => {
                if (e.target.value.replace(/[^\d]+/g, "").length <= 11) {
                  setComercialContactDocument(
                    MaskService.toMask("cpf", e.target.value, "")
                  );
                } else {
                  setComercialContactDocument(
                    MaskService.toMask("cnpj", e.target.value, "")
                  );
                }
              }}
            />
          </Grid>

          <Grid
            item
            xs={12}
            sm={selectedOptionContact === "comercial_contact" ? 3 : 4}
          >
            <div>E-mail*</div>

            <TextField
              variant="outlined"
              size="small"
              fullWidth
              placeholder="contato@email.com"
              onBlur={() => {
                setErrorEmail(false);
              }}
              error={errorEmail}
              value={comercialContactEmail}
              onChange={(e) => {
                setComercialContactEmail(e.target.value.toLowerCase());
              }}
            />
          </Grid>

          {selectedOptionContact === "comercial_contact" && (
            <Grid item xs={12} sm={3}>
              <div>Telefone*</div>
              <TextField
                variant="outlined"
                size="small"
                fullWidth
                onBlur={() => {
                  setErrorPhone(false);
                }}
                error={errorPhone}
                placeholder="Telefone"
                value={comercialContactPhone}
                onChange={(e) => {
                  setComercialContactPhone(
                    MaskService.toMask("cel-phone", e.target.value, "")
                  );
                }}
              />
            </Grid>
          )}

          <Grid item xs={12}>
            <RadioGroup value={selectedOptionContact} row>
              <FormControlLabel
                value="send_email"
                disabled // FIXME - Desabilitado até que o design do email esteja pronto
                control={<Radio color="primary" />}
                label="Enviar as informações de contato da Qyon para o e-mail do meu
                cliente."
                onClick={() => {
                  notify("Funcionalidade em desenvolvimento");
                  // handleChangeTypeContact("send_email"); #FIXME
                }}
              />

              <FormControlLabel
                value="comercial_contact"
                style={{
                  cursor: comercialContactAlreadyRequested
                    ? "not-allowed"
                    : "pointer",
                }}
                control={
                  <Radio
                    color="primary"
                    disabled={comercialContactAlreadyRequested}
                  />
                }
                label="Solicito que a Qyon entre em contato com meu cliente para
              apresentar a solução Gestão Fácil (ERP)."
                onClick={() => {
                  if (comercialContactAlreadyRequested) {
                    return notify("Contato comercial já solicitado");
                  }

                  handleChangeTypeContact("comercial_contact");
                }}
              />
            </RadioGroup>
          </Grid>
        </Grid>
        <Grid
          container
          justifyContent="space-between"
          direction="row"
          spacing={1}
          style={{ marginTop: 20 }}
        >
          <Grid item xs={8}>
            {selectedOptionContact === "comercial_contact" && (
              <FormControlLabel
                value={checkedConfirmation}
                onClick={(e) => {
                  setCheckedConfirmation(e.target.checked);
                }}
                control={<Checkbox color="primary" />}
                label="Estou ciente e autorizo o compartilhamento dos dados do meu cliente para que a Qyon realize o contato"
              />
            )}
          </Grid>

          <Grid
            item
            xs={4}
            container
            justifyContent="flex-end"
            direction="column"
          >
            <Button
              disabled={
                (!checkedConfirmation &&
                  selectedOptionContact === "comercial_contact") ||
                selectedOptionContact === null
              }
              style={{
                textTransform: "none",
                background:
                  (!checkedConfirmation &&
                    selectedOptionContact === "comercial_contact") ||
                  selectedOptionContact === null
                    ? "#E0E0E0"
                    : "#0036b3",
                color: "#FFF",
              }}
              onClick={() => {
                sendComercialContact();
              }}
            >
              Solicitar Contato Comercial da Qyon
            </Button>
            {/* </div> */}
          </Grid>
        </Grid>
      </div>
    </div>
  );
};
