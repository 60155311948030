import { useEffect, useState } from "react";
import { Search } from "@material-ui/icons";
import {
  Astyled,
  DivDataGrid,
  Headerstyled,
  Listyled,
  LogoQyon,
  Navstyled,
  ULtyled,
} from "../styles/cssProducts";
import { getListCND } from "./apiGetPrefectureNFSE";
import { legendGroupCND } from "./optionsXMLIcons";
import { DataGridDefault, GridStyled } from "../../../styles/GlobalStyle";
import QyonSidebar from "../../../assets/images/QYON-Sistemas-Inteligentes_Black.png";
import {
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Tooltip,
} from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { useHistory } from "react-router";
import { MenuItem, Select } from "@material-ui/core";

export const TableCND = () => {
  const [loading, setLoading] = useState(false);
  const [listCNDs, setListCNDs] = useState([]);
  const [filteredRows, setFilteredRows] = useState(listCNDs);
  const { push } = useHistory();
  const columns = [
    {
      field: "description",
      headerName: "CND",
      width: 280,
      renderCell: ({ row }) => (
        <div className="MuiDataGrid-columnHeaderTitle">{row?.description}</div>
      ),
    },
    {
      field: "ibge",
      headerName: "Código IBGE",
      headerAlign: "center",
      align: "center",
      width: 200,
      renderCell: ({ row }) => (
        <div className="MuiDataGrid-columnHeaderTitle">{row.configs?.ibge}</div>
      ),
    },
    {
      field: "city",
      headerName: "Cidade",
      headerAlign: "center",
      align: "center",
      width: 200,
      renderCell: ({ row }) => (
        <div className="MuiDataGrid-columnHeaderTitle">{row.configs?.city}</div>
      ),
    },
    {
      field: "state",
      headerName: "Estado",
      headerAlign: "center",
      align: "center",
      width: 220,
      renderCell: ({ row }) => (
        <div className="MuiDataGrid-columnHeaderTitle">
          {row.configs?.state}
        </div>
      ),
    },
    {
      field: "scope",
      headerName: "Grupo",
      align: "center",
      headerAlign: "center",
      width: 260,
      renderCell: ({ row }) => (
        <GridStyled container alignItems="center" justifyContent="center">
          <Grid item flex={1}>
            {legendGroupCND(row.configs?.scope)}
          </Grid>
        </GridStyled>
      ),
    },
  ];

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        let response = await getListCND();
        if (response.status === 200) {
          setLoading(false);
        }
        setListCNDs(response.data.data);
        setFilteredRows(response.data.data);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  function handleFilterGroup(event) {
    const value = event.target.value.toLowerCase();
    if (value === "todos") {
      setFilteredRows(listCNDs);
    } else {
      const filteredData = listCNDs.filter((row) =>
        row.configs?.scope.toLowerCase().includes(value)
      );
      setFilteredRows(filteredData);
    }
  }

  function handleFilterChange(event) {
    const value = event.target.value.toLowerCase();
    const filteredData = listCNDs.filter(
      (row) =>
        row.description.toLowerCase().includes(value) ||
        row.configs?.state.toLowerCase().includes(value) ||
        row.configs?.city.toLowerCase().includes(value) ||
        row.configs?.ibge.toLowerCase().includes(value)
    );
    setFilteredRows(filteredData);
  }
  return (
    <>
      <Headerstyled>
        <IconButton
          onClick={() => push("/versoes")}
          style={{ marginLeft: "2px" }}
        >
          <ArrowBackIcon />
        </IconButton>
        <LogoQyon src={QyonSidebar} style={{ marginTop: "2px" }} />
        <Navstyled>
          <ULtyled>
            <Listyled>
              <Astyled
                href="https://portal.qyon.com/login?logout=true"
                data-item="Plataforma Qyon"
              >
                Plataforma Qyon
              </Astyled>
            </Listyled>
            <Listyled>
              <Astyled href="https://www.qyon.com/" data-item="Sobre Nós">
                Sobre Nós
              </Astyled>
            </Listyled>
            <Listyled>
              <Astyled href="https://qyon.com/contato/" data-item="Contato">
                Contato
              </Astyled>
            </Listyled>
          </ULtyled>
        </Navstyled>
      </Headerstyled>

      <Grid container>
        <Grid item xs={6}>
          <Tooltip title="Pesquise através do nome da CND, Código IBGE, Cidade, Estado ou Grupo">
            <FormControl fullWidth sx={{ m: 1 }} variant="outlined">
              <InputLabel style={{ marginTop: "25px", marginLeft: "55px" }}>
                Pesquisar
              </InputLabel>
              <OutlinedInput
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton edge="end">{<Search />}</IconButton>
                  </InputAdornment>
                }
                label="Pesquisar"
                onChange={handleFilterChange}
                style={{
                  marginTop: "25px",
                  marginLeft: "55px",
                  marginBottom: "10px",
                }}
              />
            </FormControl>
          </Tooltip>
        </Grid>

        <Grid item xs={4} style={{ marginLeft: "25px" }}>
          <FormControl style={{ width: "200px" }} variant="outlined">
            <InputLabel style={{ marginTop: "25px", marginLeft: "25px" }}>
              Grupo
            </InputLabel>
            <Select
              onChange={handleFilterGroup}
              style={{ marginTop: "25px", marginLeft: "25px" }}
              label="Grupo"
            >
              <MenuItem value={"Federal"}>Federal</MenuItem>
              <MenuItem value={"Municipal"}>Municipal</MenuItem>
              <MenuItem value={"Estadual"}>Estadual</MenuItem>
              <MenuItem value={"Outros"}>Outros</MenuItem>
              <MenuItem value={"Todos"}>Todos</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <DivDataGrid>
          <DataGridDefault
            style={{ marginLeft: "5px" }}
            fullWidth
            getRowId={(row) => row.id}
            rows={filteredRows}
            columns={columns}
            pageSize={35}
            rowHeight={listCNDs.length === 0 ? 15 : 40}
            loading={loading}
          />
        </DivDataGrid>
      </Grid>
    </>
  );
};
