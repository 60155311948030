import axios from "axios";

const newPath = `${process.env.REACT_APP_CND_API_URL}/v1/entity`;

const headers = {
  "Content-Type": "application/json",
  Authorization: "Bearer " + localStorage.getItem("tokenPanel"),
};

export const CreateTemplate = async (document, body) => {
  return await axios
    .post(`${newPath}/${document}/template_email`, body, { headers: headers })
    .then((response) => {
      if (response.data.code !== 201) {
        return null;
      } else {
        return response;
      }
    })
    .catch((err) => {
      if (err.response) {
        return err.response;
      }
      return null;
    });
};

export const ListTemplate = async (document) => {
  return await axios
    .get(`${newPath}/${document}/template_email`, { headers: headers })
    .then((response) => {
      if (response.data.code !== 200) {
        return null;
      } else {
        return response;
      }
    })
    .catch((err) => {
      if (err.response) {
        return err.response;
      }
      return null;
    });
};

export const IDTemplate = async (document, ID) => {
  return await axios
    .get(`${newPath}/${document}/template_email/${ID}`, { headers: headers })
    .then((response) => {
      if (response.data.code !== 200) {
        return null;
      } else {
        return response;
      }
    })
    .catch((err) => {
      if (err.response) {
        return err.response;
      }
      return null;
    });
};

export const RemoveTemplate = async (document, ID) => {
  return await axios
    .delete(`${newPath}/${document}/template_email/${ID}`, { headers: headers })
    .then((response) => {
      if (response.data.code !== 200) {
        return null;
      } else {
        return response;
      }
    })
    .catch((err) => {
      if (err.response) {
        return err.response;
      }
      return null;
    });
};

export const PatternTemplate = async (document, body, id) => {
  return await axios
    .put(`${newPath}/${document}/template_email/${id}/default`, body, {
      headers: headers,
    })
    .then((response) => {
      if (response.data.code !== 200) {
        return null;
      } else {
        return response;
      }
    })
    .catch((err) => {
      if (err.response) {
        return err.response;
      }
      return null;
    });
};

export const DeletePatternTemplate = async (document, id) => {
  return await axios
    .delete(`${newPath}/${document}/template_email/${id}/default`, {
      headers: headers,
    })
    .then((response) => {
      if (response.data.code !== 200) {
        return null;
      } else {
        return response;
      }
    })
    .catch((err) => {
      if (err.response) {
        return err.response;
      }
      return null;
    });
};

export const APISendEmail = async (document, email, base64) => {
  const body = {
    email: email,
    base64_html: base64,
  };

  return await axios

    .post(`${newPath}/${document}/email/try`, body, { headers: headers })
    .then((response) => {
      if (response.data.code !== 200) {
        return null;
      } else {
        return response;
      }
    })
    .catch((err) => {
      if (err.response) {
        return err.response;
      }
      return null;
    });
};

export const ActualizacionTemplate = async (document, id, body) => {
  return await axios
    .put(`${newPath}/${document}/template_email/${id}`, body, {
      headers: headers,
    })
    .then((response) => {
      if (response.data.code !== 200) {
        return null;
      } else {
        return response;
      }
    })
    .catch((err) => {
      if (err.response) {
        return err.response;
      }
      return null;
    });
};
