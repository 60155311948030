import * as React from "react";

function WarningIcon(props) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <mask
        id="a"
        style={{
          maskType: "alpha",
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={24}
        height={24}
      >
        <path fill="#D9D9D9" d="M0 0H24V24H0z" />
      </mask>
      <g mask="url(#a)">
        <path
          d="M2.734 21a1 1 0 01-.865-1.501l9.266-16.004a1 1 0 011.73 0l9.266 16.004A1 1 0 0121.266 21H2.734zm2.152-2.751A.5.5 0 005.32 19H18.68a.5.5 0 00.433-.751L12.432 6.744a.5.5 0 00-.864 0L4.886 18.25zM12 18c.283 0 .52-.096.713-.288A.968.968 0 0013 17a.968.968 0 00-.287-.712A.968.968 0 0012 16a.968.968 0 00-.713.288A.968.968 0 0011 17c0 .283.096.52.287.712.192.192.43.288.713.288zm-1-3.8a.8.8 0 00.8.8h.4a.8.8 0 00.8-.8v-3.4a.8.8 0 00-.8-.8h-.4a.8.8 0 00-.8.8v3.4z"
          fill="#EE7F00"
        />
      </g>
    </svg>
  );
}

export default WarningIcon;
