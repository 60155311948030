import axios from "axios";

const newPath = `${process.env.REACT_APP_NEW_PAINEL_API_URL}/v1`;

export async function getProcurations({ grantedDocument }) {
  return await axios
    .get(
      `${newPath}/certificate/procuration?granted_document=${grantedDocument}`,
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("tokenPanel"),
          "Entity-Id": localStorage.getItem("companyIdPanel"),
          "Content-Type": "application/json",
          "Content-Language": "pt-BR",
        },
      }
    )
    .then((response) => {
      return response.data.data;
    })
    .catch((err) => {
      return null;
    });
}

export async function getProcurationPermission({
  grantorDocument,
  grantedDocument,
}) {
  return await axios
    .get(
      `${newPath}/certificate/procuration/permission?grantor_document=${grantorDocument}&granted_document=${grantedDocument}`,
      {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("tokenPanel"),
          "Entity-Id": localStorage.getItem("companyIdPanel"),
          "Content-Type": "application/json",
          "Content-Language": "pt-BR",
        },
      }
    )
    .then((response) => {
      return response.data.data;
    })
    .catch((err) => {
      return [];
    });
}
