import { Grid } from "@material-ui/core";
import React from "react";
import CardButton from "../../components/cards/CardButton";
import { Margin, Title } from "../../styles/GlobalStyle";

import { useHistory } from "react-router-dom";

export default function ReportAttendance() {
  const { push } = useHistory();
  return (
    <Grid container justifyContent="space-between">
      <Grid item xs={12} container alignItems="center">
        <Grid item flex={1}>
          <Title color="blue">Relatórios</Title>
        </Grid>

        <Margin top="20" />

        <Grid item container>
          <Grid xs={6}>
            <CardButton
              onClick={() => push("/dashboard/setting")}
              label="Atendimento"
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
