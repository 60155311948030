import { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";

import { Grid, LinearProgress, Tooltip } from "@material-ui/core";
import { Add } from "@material-ui/icons";
import moment from "moment/moment";
import {
  CertificateDataGrid,
  DateColumn,
  DocumentColumn,
  EnterpriseColumn,
  IconButtonAddStyled,
} from "./styles/Certificate";
import {
  getRPAExecutions,
  startRPAExecution,
} from "../../repositories/panel/v1/ECACRPARepository";
import { GridOverlay, GridToolbarContainer } from "@material-ui/data-grid";
import { formatCNPJorCPF } from "./util/Formatter";
import {
  ErrorColumn,
  PendingColumn,
  ScheduledColumn,
  SuccessColumn,
} from "./styles/CertificateECAC";
import { notify } from "../../utils/notify";

export default function CertificateECACView({ certificateECAC }) {
  const [loading, setLoading] = useState(true);
  const [tmpRows, setTmpRows] = useState([]);

  let columns = [
    {
      field: "certificateCnpj",
      headerName: "CPF/CNPJ",
      flex: 1,
      sortable: false,
      renderCell: ({ row }) => {
        return (
          <EnterpriseColumn>
            <DocumentColumn style={{ fontSize: 14 }}>
              {formatCNPJorCPF(row.certificateCnpj)}
            </DocumentColumn>
          </EnterpriseColumn>
        );
      },
    },
    {
      field: "currentTime",
      headerName: "Data",
      flex: 1,
      sortable: false,

      renderCell: ({ row }) => {
        return <DateColumn>{formatDate(row.currentTime)}</DateColumn>;
      },
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      sortable: false,

      renderCell: ({ row }) => {
        if (!row.status) {
          return "";
        }

        if (row.status === "SUCCESS") {
          return <SuccessColumn>Sucesso</SuccessColumn>;
        }

        if (row.status === "ERROR") {
          return <ErrorColumn>Erro</ErrorColumn>;
        }

        if (row.status === "SCHEDULED") {
          return <ScheduledColumn>Agendado</ScheduledColumn>;
        }

        if (row.status === "PENDING") {
          return <PendingColumn>Processando</PendingColumn>;
        }

        return <div>Desconhecido</div>;
      },
    },
  ];

  useEffect(() => {
    refresh();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const refresh = () => {
    setLoading(true);
    getRPAExecutions({
      document: certificateECAC.document,
    }).then((executions) => {
      const currentDate = moment();

      const firstDayOfNextMonth = currentDate
        .clone()
        .add(1, "month")
        .startOf("month");

      const millis = firstDayOfNextMonth.valueOf();

      executions.unshift({
        id: uuidv4(),
        certificateCnpj: certificateECAC.document,
        currentTime: millis,
        status: "SCHEDULED",
      });

      setTmpRows(executions);
      setLoading(false);
    });
  };
  const formatDate = (date) => {
    const timestamp = parseInt(date);
    const formattedDate = moment(timestamp).format("DD/MM/YYYY");
    return formattedDate;
  };

  const CustomToolbar = () => {
    return (
      <GridToolbarContainer
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginBottom: 20,
          borderRadius: 5,
          background: "#F5F5F5",
          padding: 5,
          gap: 5,
        }}
      >
        <Grid item>
          <IconButtonAddStyled
            size="small"
            onClick={() => {
              startRPA();
            }}
          >
            <Tooltip title={"Iniciar checagem das procurações"}>
              <Add height={20} />
            </Tooltip>
          </IconButtonAddStyled>
        </Grid>
      </GridToolbarContainer>
    );
  };

  const startRPA = async () => {
    const { status } = await startRPAExecution({
      document: certificateECAC.document,
    }).catch((e) => {
      if (e.response) {
        return { status: e.response.status };
      }
      return {
        status: 500,
      };
    });

    if (status === 201) {
      refresh();

      notify(
        "Sua checagem será iniciada em breve! Isso pode levar um tempo, porém tudo já está agendado para iniciar!",
        true,
        "success"
      );
      return;
    }

    if (status === 403) {
      notify(
        "Você só pode realizar uma checagem das procurações a cada cinco dias! Aguarde esse período e faça uma nova tentativa",
        true,
        "error"
      );
      return;
    }

    notify(
      "Ocorreu um erro ao iniciar a checagem das procurações. Tente novamente mais tarde ou entre em contato com o suporte!",
      true,
      "error"
    );
  };

  return (
    <>
      <div
        container
        style={{
          background: "#fff",
          border: "1px solid #E0E0E0",
          borderRadius: 15,
          padding: 15,
          height: "100%",
          flex: 1,
        }}
      >
        <CertificateDataGrid
          disableColumnFilter
          disableColumnMenu
          style={{ marginTop: 0, height: "100%" }}
          rows={loading ? [] : tmpRows}
          columns={columns}
          slots={{
            loadingOverlay: LinearProgress,
          }}
          loading={loading}
          disableSelectionOnClick
          components={{
            Toolbar: CustomToolbar,
            NoRowsOverlay: () =>
              !loading && (
                <GridOverlay>
                  <h4>Nenhum agendamento encontrado no momento!</h4>
                </GridOverlay>
              ),
          }}
        />
      </div>
    </>
  );
}
