import React from "react";
import { ListStyled, ListSubheaderStyled } from "../styles/GeneralStyle";

export default function Group({ title, children, flag, setFlag = () => {} }) {
  setFlag(flag);

  return (
    <>
      <ListStyled
        component="nav"
        aria-labelledby="nested-list-subheader"
        subheader={
          <ListSubheaderStyled component="div" id="nested-list-subheader">
            {title}
          </ListSubheaderStyled>
        }
      >
        {children}
      </ListStyled>
    </>
  );
}
