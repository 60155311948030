import styled, { css } from "styled-components";

const gray = (props) => props.theme.palette.gray;

export const Preview = styled.img`
  max-width: 200px;
  max-height: 60px;
  border-radius: 4px;
  cursor: pointer;
`;

export const DropContainer = styled.div.attrs({
  className: "dropzone",
})`
  ${(props) =>
    !props.uploaded &&
    css`
      border: 1px dashed ${gray};
      border-radius: 4px;
      background-color: #f5f5f9;
    `}

  cursor: pointer;
  transition: height 0.2s ease;
`;

export const UploadMessage = styled.p`
  color: ${gray};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px 8px;
`;

export const DropMessage = styled.p`
  color: ${(props) =>
    props.isDragReject ? props.theme.palette.red : props.theme.palette.gray};
  display: flex;
  align-items: center;
`;
