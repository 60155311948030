import React from "react";
import { GridStyled } from "../../../styles/GlobalStyle";
import { useMonitor } from "../contexts/ChatMonitorContext";
import { PaperStyled } from "../styles/ChatMonitor";
import { TypographyStyled } from "../styles/GeneralStyle";
import RenderList from "./RenderList";
import RenderListItemAttendat from "./RenderListItemAttendat";

const Attendants = () => {
  const { listAttendantsFilter } = useMonitor();

  return (
    <GridStyled width="100%" padding="10px 0">
      <PaperStyled padding-bottom="10px">
        {listAttendantsFilter?.length > 0 ? (
          <RenderList title={"Operadores disponíveis no chat"}>
            {listAttendantsFilter
              .sort((a, b) => b?.statusAttendance < a?.statusAttendance)
              .map((value, index) => (
                <RenderListItemAttendat key={index} item={value} />
              ))}
          </RenderList>
        ) : (
          <GridStyled
            alignContent="center"
            alignItems="center"
            container
            xs
            padding="15px 10"
            text
          >
            <TypographyStyled
              component="span"
              variant="subtitle1"
              textAlign="center"
            >
              Nenhum operador conectado foi encontrado
            </TypographyStyled>
          </GridStyled>
        )}
      </PaperStyled>
    </GridStyled>
  );
};

export default Attendants;
