import "./styles/scrollBar.css";

import { Grid, makeStyles, Tooltip } from "@material-ui/core";

import { Delete, GetApp } from "@material-ui/icons";
import Swal from "sweetalert2";

import UploadFiles from "./UploadFiles";

// import VisibilityIcon from '@material-ui/icons/Preview';
import VisibilityIcon from "@material-ui/icons/Visibility";

import { ContainerIcon } from "../../../styles/GlobalStyle";

import { GridListFiles } from "../styles/GridListFile";

import useAttendanceController from "../controllers/AttendanceController";

export default function ListFiles({
  uploadedFiles = [],
  setUploadedFiles,
  statusAttendance,
  setOpenModal,
  setUrlFileState,
  typeScreen,
  ...props
}) {
  const { handleDownloadFile } = useAttendanceController();

  const useStyle = makeStyles((theme) => ({
    mainGrid: {
      borderRadius: "4px",
      height: "100%",
      display: "flex",
      overflowY: "scroll",
      maxHeight: "200px",
      width: "100%",
    },
    previewFile: {
      backgroundRepeat: "no-repeat",
      display: "flex",
      backgroundSize: "cover",
      backgroundPosition: "50% 50%",
      marginRight: "10px",
      minWidth: "36px",
      minHeight: "36px",
      maxWidth: "40px",
      maxHeight: "40px",
      alignItems: "center",
      alignContent: "center",
    },
    generalDescriptionFile: {
      paddingLeft: "10px",
      maxwidth: "280px",
      wordBreak: "break-all",
      textAlign: "left",
    },
    iconFile: {
      fontSize: "36px",
    },
    fileName: {
      textAlign: "left",
    },
  }));
  const classes = useStyle();
  const handleDelete = (id) => {
    Swal.fire({
      icon: "question",
      text: "Tem certeza que deseja excluir?",
      cancelButtonText: "Cancelar",
      confirmButtonText: "Sim",
      showConfirmButton: true,
      showCancelButton: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        setUploadedFiles(
          uploadedFiles.map((file) => {
            return file.id === id
              ? {
                  ...file,
                  deleted: true,
                }
              : {
                  ...file,
                };
          })
        );
      }
    });
  };

  return (
    <Grid
      container
      className={!!uploadedFiles?.length && classes.mainGrid}
      class="mainGrid"
    >
      <Grid xs={12} container>
        {!statusAttendance && (
          <Grid
            style={{
              maxHeight: "100px",
              marginTop: "5px",
              marginBottom: "10px",
              width: "32%",
              marginRight: "10px",
            }}
          >
            <UploadFiles
              accept="image/*, .pdf, .doc, .ppt, .gif, application/*"
              multiple={true}
              label={"Arraste arquivos ou clique aqui"}
              uploadedFiles={uploadedFiles}
              setUploadedFiles={setUploadedFiles}
            />
          </Grid>
        )}
        {!!uploadedFiles?.length ? (
          uploadedFiles?.map((files) => {
            return (
              <>
                {files.deleted === false && (
                  <Grid
                    className="gridStyleItemFiles"
                    key={files.id}
                    style={{
                      height: "100px",
                      maxHeight: "100px",
                      width: "32%",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <GridListFiles
                        xs={3}
                        type={files?.file?.type}
                        bgImagefiles={files?.preview}
                      ></GridListFiles>
                      <Grid
                        container
                        xs={9}
                        className={classes.generalDescriptionFile}
                      >
                        <Grid
                          title={files.name}
                          className={classes.fileName}
                          xs={12}
                          style={{ color: "#4b4c4e" }}
                        >
                          <strong>
                            {files.name.length > 15
                              ? files.name
                                  .replace(files.name.substring(15))
                                  .replace("undefined", "") +
                                "[...]" +
                                files.name.replace(
                                  files.name.substring(
                                    0,
                                    files.name.lastIndexOf(".")
                                  ),
                                  ""
                                )
                              : files.name}
                          </strong>
                        </Grid>
                        <Grid
                          container
                          className={classes.descriptionFile}
                          xs={12}
                          style={{
                            fontSize: "12px",
                            color: "#999",
                            marginTop: "5px",
                          }}
                        >
                          <Grid xs={6}>{files.readableSize}</Grid>
                          <Grid xs={6} container>
                            {files.preview && (
                              <>
                                <Grid item xs={4} flex={1}>
                                  <Tooltip title="Visualizar arquivo">
                                    <ContainerIcon
                                      onClick={() => {
                                        setUrlFileState(files);
                                        setOpenModal(true);
                                      }}
                                    >
                                      <VisibilityIcon
                                        style={{ color: "rgb(10, 115, 219)" }}
                                        size={10}
                                      />
                                    </ContainerIcon>
                                  </Tooltip>
                                </Grid>
                                {typeScreen === "edit" && (
                                  <Grid xs={4} item flex={1}>
                                    <Tooltip title="Baixar arquivo">
                                      <ContainerIcon
                                        onClick={() => {
                                          handleDownloadFile(
                                            files?.elementBase64,
                                            `${files?.name}.${files?.file?.extension}`
                                          );
                                        }}
                                        onMouseOver={(e) =>
                                          (e.target.style.color = "#8995a6")
                                        }
                                        onMouseOut={(e) =>
                                          (e.target.style.color =
                                            "rgb(10, 115, 219)")
                                        }
                                      >
                                        <GetApp
                                          style={{ color: "rgb(10, 115, 219)" }}
                                          size={10}
                                        />
                                      </ContainerIcon>
                                    </Tooltip>
                                  </Grid>
                                )}

                                <Grid xs={4} item flex={1}>
                                  <Tooltip title="Excluir arquivo">
                                    <ContainerIcon
                                      onClick={() => {
                                        handleDelete(files.id);
                                      }}
                                      onMouseOver={(e) =>
                                        (e.target.style.color = "#e74c3c")
                                      }
                                      onMouseOut={(e) =>
                                        (e.target.style.color =
                                          "rgb(10, 115, 219)")
                                      }
                                    >
                                      <Delete
                                        className="buttonDelete"
                                        style={{ color: "rgb(10, 115, 219)" }}
                                        size={10}
                                      />
                                    </ContainerIcon>
                                  </Tooltip>
                                </Grid>
                              </>
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                    </div>
                  </Grid>
                )}
              </>
            );
          })
        ) : (
          <Grid
            style={{
              color: "#4b4c4e",
              display: "flex",
              alignItems: "center",
              textContent: "center",
              maxHeight: "100px",
              marginTop: "10px",
              marginBottom: "10px",
              width: statusAttendance ? "100%" : "66%",
            }}
          >
            <strong style={{ width: "100%" }}>
              Nenhum arquivo encontrado para este atendimento
            </strong>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}
