import { Switch } from "react-router-dom";
import MiddlewareRoute from "../../routes/Middleware";
import { HomeContextProvider } from "../default/contexts/HomeContext";
import { ComercialQyon } from "./home";
import { TableXML } from "./children/tableXML";
import { TableCND } from "./children/tableCND";

export default function QyonComercial() {
  return (
    <HomeContextProvider>
      <Switch>
        <MiddlewareRoute exact path="/versoes" component={ComercialQyon} />
        <MiddlewareRoute exact path="/versoes/xml" component={TableXML} />
        <MiddlewareRoute exact path="/versoes/cnd" component={TableCND} />

      </Switch>
    </HomeContextProvider>
  );
}
