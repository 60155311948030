import { useContext, useState } from "react";

import { Grid } from "@material-ui/core";
import { TextField } from "@material-ui/core";
import Clear from "@material-ui/icons/Clear";
import Add from "@material-ui/icons/Add";

import { notify } from "../../../utils/notify";

import useHomeController from "../controllers/HomeController";
import { HomeContext } from "../contexts/HomeContext";
import { BtnService, Content, Section, Title } from "../styles/Home";

import { IconButtonStyled } from "../../../styles/GlobalStyle";
import { DeleteImageButton } from "./styles/UploadAboutUsImage";

const SectionServices = ({ ...props }) => {
  const { landTheme, services, setServices, editEnable } =
    useContext(HomeContext);

  const { saveWebsite } = useHomeController();

  const [hoverService, setHoverService] = useState(null);
  const [focusService, setFocusService] = useState(null);

  const handleAddService = () => {
    if (services.length < 9) {
      setServices((services) => [...services, { description: "" }]);
    } else {
      notify(
        "O limite de nove cards de serviços foi atingido",
        true,
        "warning"
      );
    }
  };

  const handleDeleteService = (index) => {
    setServices((services) =>
      services.map((service, serviceIndex) => {
        if (serviceIndex === index) {
          service.remove = true;
        }
        return service;
      })
    );
    saveWebsite();
  };

  const handleBlurService = (index) => {
    setFocusService(null);
    saveWebsite();
  };

  const handleChangeService = (e, index) => {
    if (e.target.value.length < 80) {
      let newServices = [...services];
      newServices[index].description = e.target.value;
      setServices(newServices);
    }
  };

  return (
    <Section $backgroundColor={landTheme.color_pallete.secondary}>
      <Content maxWidth="lg" id="section-services">
        <Grid container>
          <Grid item md={6} xs>
            <Title $color={landTheme.color_pallete.tertiary}>
              <h2>Serviços</h2>
            </Title>
          </Grid>
          {editEnable && (
            <Grid item container justifyContent="flex-end" alignItems="center">
              <IconButtonStyled onClick={handleAddService}>
                <Add />
              </IconButtonStyled>
            </Grid>
          )}
        </Grid>
        <Grid container spacing={2} justifyContent="center">
          {services.map(
            (service, index) =>
              !service.remove && (
                <Grid
                  item
                  lg={4}
                  sm={6}
                  xs={12}
                  onMouseEnter={() => {
                    setHoverService(index);
                  }}
                  onMouseLeave={() => setHoverService(null)}
                >
                  <BtnService
                    $color={landTheme.color_pallete.secondary}
                    $backgroundColor={landTheme.color_pallete.primary}
                    $hoverColor={landTheme.color_pallete.primary}
                    $hoverBackgroundColor={landTheme.color_pallete.secondary}
                    $borderHoverColor={landTheme.color_pallete.primary}
                  >
                    {editEnable &&
                    (hoverService === index || focusService === index) ? (
                      <>
                        <DeleteImageButton
                          onClick={() => handleDeleteService(index)}
                          $gray={landTheme.color_pallete.gray}
                        >
                          <Clear />
                        </DeleteImageButton>
                        <TextField
                          value={service.description}
                          onChange={(e) => handleChangeService(e, index)}
                          onFocus={() => setFocusService(index)}
                          onBlur={() => handleBlurService(index)}
                        />
                      </>
                    ) : (
                      service.description
                    )}
                  </BtnService>
                </Grid>
              )
          )}
        </Grid>
      </Content>
    </Section>
  );
};

export default SectionServices;
