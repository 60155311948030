import styled, { keyframes } from "styled-components";

import { Grid } from "@material-ui/core";

const SpinAnimation = keyframes`
    to {
        transform: rotate(360deg);
    }
`

export const AnimationSpin = styled.div`
    width: 4em;
    height: 4em;
    border: 3px solid hsla(185, 100%, 62%, 0.2);
    border-top-color: #006fde;
    border-radius: 50%;
    animation: ${SpinAnimation} 1.5s linear infinite;
`