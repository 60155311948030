import React, { Suspense, useState } from "react";

import { CircularProgress, Grid } from "@material-ui/core";

import { Margin } from "../../styles/GlobalStyle";
import {
  SelectionBorder,
  SelectPage,
  SelectArea,
} from "../../components/settings/styles/SelectionCard";
import ActualConfig from "../../components/settings/ActualConfig";

export const System = () => {
  const mapping = {
    1: {
      component: () => <p>1</p>,
      tabName: "Dados Cadastrais",
    },
    2: {
      component: () => <p>2</p>,
      tabName: "Chat",
    },
    3: {
      component: React.lazy(() =>
        import("../../components/settings/ReleaseVersion")
      ),
      tabName: "Notas da Versão",
    },
    4: {
      component: React.lazy(() =>
        import("../../components/settings/VersionControl")
      ),
      tabName: "Gerenciador de Versões",
    },
  };

  const [tabSelected, setTabSelected] = useState(3);

  const { component: Component, tabName } = mapping[tabSelected];

  return (
    <Grid container>
      <ActualConfig ActualTab={tabName} />

      {/* NAVEGAÇÃO ENTRE AS PAGINAS  */}
      <Grid item xs={12} container alignItems="center" style={SelectionBorder}>
        <Grid item flex={1}></Grid>
        <Grid xs={12} style={SelectArea}>
          {/* <SelectPage
            active={tabSelected === 1}
            onClick={() => setTabSelected(1)}
            xs={3}
          >
            Dados Cadastrais
          </SelectPage>
          <SelectPage
            active={tabSelected === 2}
            onClick={() => setTabSelected(2)}
            xs={3}
          >
            Chat
          </SelectPage>*/}
          <SelectPage
            active={tabSelected === 3}
            onClick={() => setTabSelected(3)}
            xs={3}
          >
            Notas da Versão
          </SelectPage>
          <SelectPage
            active={tabSelected === 4}
            onClick={() => setTabSelected(4)}
            xs={3}
          >
            Gerenciador de Versões
          </SelectPage>
        </Grid>
        <Margin top="15" />

        <Suspense fallback={<CircularProgress />}>
          <Component />
        </Suspense>
      </Grid>
    </Grid>
  );
};
