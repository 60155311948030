import { notify } from "../../utils/notify";

const path = process.env.REACT_APP_BOT_API_URL + "/v1/bot/data";

const token = localStorage.getItem("tokenPanel");
const userDocument = localStorage.getItem("documentPanel");

export async function findHubByCNPJ(cnpj = "") {
  let requestOptions = {
    method: "GET",
    redirect: "follow",
    headers: {
      authorization: "Bearer " + token,
      govid: userDocument,
    },
  };

  return fetch(
    `${path}/company/${cnpj.replace(/[^0-9]+/g, "")}`,
    requestOptions
  )
    .then((response) => {
      const promise = response.json();
      return promise;
    })
    .catch((error) => {
      console.error(error);
      console.debug(error);
      notify(error || "Não foi possível realizar a ação, tente novamente.");

      return { success: false, mensagem: error };
    });
}

export async function findHubByCEP(cep = "") {
  let requestOptions = {
    method: "GET",
    redirect: "follow",
    headers: {
      authorization: "Bearer " + token,
      govid: userDocument,
    },
  };

  return fetch(`${path}/address/${cep.replace(/[^0-9]+/g, "")}`, requestOptions)
    .then((response) => {
      const promise = response.json();
      return promise;
    })
    .catch((error) => {
      console.error(error);
      console.debug(error);
      notify(error || "Não foi possível realizar a ação, tente novamente.");

      return { success: false, mensagem: error };
    });
}
