import { Grid, IconButton, TextField, Tooltip } from "@material-ui/core";
import { ArrowBackIos as ArrowBackIosIcon, Close } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { useAuth } from "../../../contexts/crm/Auth";
import useAttendanceController from "../../../pages/attendance/controllers/AttendanceController";
import { getWhatsAppMessagesTemplates } from "../../../repositories/integrations/crm/ChatRepository";
import {
  getCustomerContact,
  getCustomers,
} from "../../../repositories/integrations/crm/CustomerRepository";
import { ContainerIcon } from "../../../styles/GlobalStyle";
import { notify } from "../../../utils/notify";
import RenderContactsList from "../children/RenderContactList";
import RenderCustomersList from "../children/RenderCustomersList";
import { useChatContext } from "../contexts/ChatContext";
import useChatController from "../controllers/ChatController";
import useWebSocketClient from "../controllers/WebSocketClient";
import { getCustomerConversation } from "../services/attendance";
import { AnimationSpin } from "../styles/Animations";
import {
  BoxStyled,
  GridStyled,
  TypographyStyled,
} from "../styles/GeneralStyle";

const formatString = (string) => {
  if (string.length > 17) {
    return `${string.substring(0, 20)}...`;
  } else {
    return string;
  }
};

const CallListContacts = () => {
  const { setChat, SCREEN_ENUM, chat } = useChatContext();
  const { user, company } = useAuth();
  const { subscribeOnCallCustumerToConversation } = useChatController();
  const { postCustomerActiveChat } = useAttendanceController();
  const webSocketClient = useWebSocketClient();

  const [listCustomers, setListCustomers] = useState([]);
  const [listUpdateCustomers, setListUpdateCustomers] = useState([]);
  const [listContacts, setListContacts] = useState([]);
  const [listUpdateContacts, setListUpdateContacts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [fieldValue, setFieldValue] = useState("");
  const [flag, setFlag] = useState("clientes");
  const [callSelectedCustomer, setcallSelectedCustomer] = useState({});
  const [showWarningMessageIntegration, setShowWarningMessageIntegration] =
    useState(false);

  const validateShowMessageIntegrationUnvaliable = ({
    id,
    category,
    status,
    language,
    components,
    name,
  }) => {
    if (
      !chat.tokenWhats ||
      !chat.whatsappAccountId ||
      !chat.whatsappFlagIntegration ||
      !chat.whatsappFlagIntegration
    )
      return setShowWarningMessageIntegration(false);

    if (!status.toLowerCase().includes("approved"))
      return setShowWarningMessageIntegration(true);

    return setShowWarningMessageIntegration(false);
  };

  const setCallCustomer = async (id) => {
    const found_customer = listCustomers.filter((v) => v.id === id);
    await fetchCustomerContacts(id);
    setcallSelectedCustomer(found_customer[0]);
    setFieldValue("");
  };

  const handleChange = (e) => {
    setFieldValue(e.target.value);
  };

  const fetchCustomerContacts = async (id) => {
    try {
      setLoading(true);

      const contactsFetch = await getCustomerContact({ customerId: id });
      if (Array.isArray(contactsFetch?.items)) {
        const resultado = contactsFetch?.items.map((item) => ({
          ...item,
          email: [item?.email],
        }));
        setListContacts(resultado);
        setListUpdateContacts(resultado);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
    setLoading(false);
  };

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        if (company?.sgbd?.includes("postgres")) {
          try {
            const statusTemplate = await getWhatsAppMessagesTemplates({
              templateName: "attendace_call_customer",
              whatsappAccountId: chat.whatsappAccountId,
              whatsappToken: chat?.tokenWhats,
            });

            if (statusTemplate?.success && statusTemplate?.data?.length)
              validateShowMessageIntegrationUnvaliable(statusTemplate?.data[0]);
          } catch (error) {
            console.error(error);
          }
        }
        const customersFetch = await getCustomers({ id: false });
        if (Array.isArray(customersFetch?.items)) {
          setListCustomers(customersFetch?.items);
          setListUpdateCustomers(customersFetch?.items);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
      setLoading(false);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (Object.keys(callSelectedCustomer).length !== 0) {
      let result = listContacts;
      result.unshift({
        id: +callSelectedCustomer?.id,
        name: "Contato Principal",
        fantasyName: callSelectedCustomer?.name,
        email: callSelectedCustomer?.email,
        cellphone: callSelectedCustomer?.phone,
        tellphone: callSelectedCustomer?.telphone,
        isContactPrincipal: true,
      });
      setListContacts(result);
      setListUpdateContacts(result);
    }
  }, [callSelectedCustomer, listContacts]);

  useEffect(() => {
    if (flag === "clientes") {
      let listUpdate = listCustomers.filter((item) => {
        return (
          (fieldValue &&
            item?.name?.toLowerCase()?.includes(fieldValue?.toLowerCase())) ||
          item?.fantasyName?.toLowerCase()?.includes(fieldValue?.toLowerCase())
        );
      });

      setListUpdateCustomers(fieldValue ? listUpdate : listCustomers);
    }
  }, [fieldValue, listCustomers, flag]);

  useEffect(() => {
    if (flag !== "clientes") {
      let listUpdate = listContacts.filter((item) => {
        return (
          (fieldValue &&
            item?.name.toLowerCase()?.includes(fieldValue?.toLowerCase())) ||
          item?.fantasyName?.toLowerCase()?.includes(fieldValue?.toLowerCase())
        );
      });

      setListUpdateContacts(fieldValue ? listUpdate : listContacts);
    }
  }, [fieldValue, listContacts, flag]);

  const openConversationInRoom = async ({
    roomId,
    nameCustomerContact,
    email,
  }) => {
    setLoading(true);
    const customerConversations = await getCustomerConversation({
      webSocketClient,
      companyId: company.id,
      email: email[0],
    });

    const hasConversation =
      customerConversations.customerInAttendance.length > 0;

    if (hasConversation) {
      notify("Cliente já possui uma conversa em andamento");
      setLoading(false);
      return;
    }

    let found_contacts = listContacts.filter(
      (v) => v.name === nameCustomerContact
    );

    let contact = found_contacts[0];

    postCustomerActiveChat({
      company,
      roomId,
      attendantId: user?.id,
      customerCompanyId: callSelectedCustomer?.id,
      customerContact: contact,
    });

    subscribeOnCallCustumerToConversation({
      customerId: callSelectedCustomer?.id,
      companyId: company?.id,
      roomId,
      contactId: contact?.isContactPrincipal ? "" : contact?.id,
    });
  };

  return (
    <BoxStyled width="100%" height="100%" overflowY={`${!loading && "scroll"}`}>
      {loading ? (
        <GridStyled
          alignContent="center"
          alignItems="center"
          display="flex"
          padding="175px 0 0 !important"
          width="100%"
          justifyContent="center"
        >
          <AnimationSpin />
        </GridStyled>
      ) : (
        <Grid>
          <GridStyled
            item
            display="flex"
            xs
            justifyContent="space-between"
            height="30px"
            padding="0 16px"
            margin="0 0 12px"
          >
            <TypographyStyled
              display="inline"
              font-size="18px"
              padding="10px 0"
              fontWeight="bold"
              component="span"
              variant="body"
              color="black"
            >
              {flag === "clientes" ? "Lista de Clientes" : "Contatos"}
            </TypographyStyled>

            {flag === "clientes" ? (
              <Tooltip title="Fechar">
                <ContainerIcon
                  iconColor="#CCCFD6"
                  fontSize="20"
                  onClick={() =>
                    setChat({
                      currentScreen: SCREEN_ENUM.conversation,
                    })
                  }
                >
                  <IconButton>
                    <Close />
                  </IconButton>
                </ContainerIcon>
              </Tooltip>
            ) : (
              <Tooltip title="Voltar">
                <ContainerIcon
                  onClick={() => {
                    setcallSelectedCustomer({});
                    setFieldValue("");
                  }}
                  iconColor="#CCCFD6"
                  fontSize="20"
                >
                  <IconButton>
                    <ArrowBackIosIcon />
                  </IconButton>
                </ContainerIcon>
              </Tooltip>
            )}
          </GridStyled>
          {showWarningMessageIntegration && (
            <GridStyled display="flex" padding="0 16px">
              <Tooltip title=" Template para chamar o cliente pendente ou não aceito pela Meta">
                <TypographyStyled
                  display="inline"
                  font-size="12px"
                  // padding="10px 0"
                  fontWeight="bold"
                  component="span"
                  variant="body"
                  color="red"
                >
                  Chamar o cliente no WhastApp indisponível
                </TypographyStyled>
              </Tooltip>
            </GridStyled>
          )}
          <GridStyled display="flex" padding="0 16px" margin="0 0 10px">
            <TextField
              id="field"
              label={
                flag === "clientes"
                  ? "Pesquise Clientes"
                  : `Pesquise contatos ${
                      callSelectedCustomer?.name
                        ? `do ${formatString(`${callSelectedCustomer?.name}`)}`
                        : ""
                    }`
              }
              variant="standard"
              fullWidth
              value={fieldValue}
              onChange={handleChange}
            />
          </GridStyled>
          {Object.keys(callSelectedCustomer).length ? (
            <RenderContactsList
              listContacts={listContacts}
              fieldValue={fieldValue}
              setFieldValue={setFieldValue}
              setCallCustomer={setCallCustomer}
              setFlag={setFlag}
              listUpdateContacts={listUpdateContacts}
              openConversationInRoom={openConversationInRoom}
            />
          ) : (
            <RenderCustomersList
              listCustomers={listCustomers}
              fieldValue={fieldValue}
              setFieldValue={setFieldValue}
              setCallCustomer={setCallCustomer}
              listUpdateCustomers={listUpdateCustomers}
              setFlag={setFlag}
            />
          )}
        </Grid>
      )}
    </BoxStyled>
  );
};

export default CallListContacts;
