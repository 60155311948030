import { useContext, useEffect, useMemo } from "react";
import { useHistory } from "react-router";

import { usePermissions } from "../../utils/permissionsHook";
import { notify } from "../../utils/notify";
import { GlobalContext } from "../../contexts/GlobalContext";
import { GlobalVariables } from "../../GlobalVariables";
import { ModalConfigSidebarContext } from "./contexts/ModalConfigSidebarContext";
import useWebSocketClient from "../chat/controllers/WebSocketClient";

export default function useMenuDataOfficeController() {
  const { setOpenConfig } = useContext(ModalConfigSidebarContext);
  const { push } = useHistory();
  const { disconnect } = useWebSocketClient();

  const {
    checkIfUserHasPartialPermission,
    handleProductMenuHide,
    validateProductStatus,
  } = usePermissions();

  const userPermissons = useMemo(() => {
    return {
      customerManagement: checkIfUserHasPartialPermission(
        GlobalVariables.PERMISSIONS.CLIENTES_VISUALIZATION
      ),
      gestaoFacil: checkIfUserHasPartialPermission(
        GlobalVariables.PERMISSIONS.GESTAO_FACIL
      ),
      contabil: checkIfUserHasPartialPermission(
        GlobalVariables.PERMISSIONS.CONTABIL
      ),
      fiscal: checkIfUserHasPartialPermission(
        GlobalVariables.PERMISSIONS.FISCAL
      ),
      folha: checkIfUserHasPartialPermission(GlobalVariables.PERMISSIONS.FOLHA),
      cnd: checkIfUserHasPartialPermission(
        GlobalVariables.PERMISSIONS.CND_VISUALIZATION
      ),
      crm: checkIfUserHasPartialPermission(GlobalVariables.PERMISSIONS.CRM),
      xml: checkIfUserHasPartialPermission(GlobalVariables.PERMISSIONS.XML),
      settings: checkIfUserHasPartialPermission(
        GlobalVariables.PERMISSIONS.SETTINGS
      ),
    };
  }, [checkIfUserHasPartialPermission]);

  useEffect(() => {}, []);

  const { companyUser, userLogged } = useContext(GlobalContext);

  const isAccountant = !!companyUser.isAccountant;
  const isInClientView = sessionStorage.getItem("isInClientView");
  const isClient =
    (!companyUser.isAccountant && !!companyUser.accountantId) || isInClientView;
  const isStandaloneClient =
    !companyUser.isAccountant && !companyUser.accountantId;

  const optionsClient = [
    {
      systemId: GlobalVariables.SYSTEMS.CRM,
      hasASubMenu: false,
      label: "Documentos e Guias",
      value: "GUIDE",
      icon: "GuiasIcon",
      path: "/guide",
      hide: isAccountant || isStandaloneClient,
      blur: !validateProductStatus("CRM").isActive || !userPermissons.crm,
      customTooltip:
        validateProductStatus("CRM").message ??
        (!userPermissons.crm && "Acesso restrito ao usuário"),
    },
    {
      label: "Atendimentos",
      value: "ATTENDANCE",
      hasASubMenu: false,
      icon: "AtendimentoIcon",
      path: "/attendance/search",
      hide: isAccountant || isStandaloneClient,
      standard: true,
      blur:
        isInClientView ||
        !validateProductStatus("CRM").isActive ||
        !userPermissons.crm,
      customTooltip: isInClientView
        ? "Para que essa função seja ativada, você necessita sair da visão de cliente e retornar à visão de contador."
        : validateProductStatus("CRM").message ??
          (!userPermissons.crm && "Acesso restrito ao usuário"),
      functionMenu: () => {
        if (isInClientView) {
          return notify(
            "Para que essa função seja ativada, você necessita sair da visão de cliente e retornar à visão de contador."
          );
        }

        if (!userPermissons.crm) {
          return notify("Acesso não liberado.");
        }

        if (!validateProductStatus("CRM").isActive) {
          return notify(validateProductStatus("CRM").message);
        }

        push("/attendance/search");
      },
    },
  ];

  let optionsMenu = [
    {
      label: "Gestão de Clientes",
      value: "CLIENT_MANAGEMENT",
      hasASubMenu: true,
      icon: "BpoIcon",
      path: "/customermanagement/list",
      hide: isClient || isStandaloneClient,
      blur: !userPermissons.customerManagement,
      customTooltip:
        !userPermissons.customerManagement && "Acesso restrito ao usuário",
      standard: true,
    },

    {
      systemId: GlobalVariables.SYSTEMS.XML,
      label: "Gestão de NFs",
      hasASubMenu: false,
      value: "BILL_TO_PAY",
      icon: "BillToPayIcon",
      isBeta: true,
      blur:
        !userPermissons.xml ||
        (isStandaloneClient &&
          handleProductMenuHide({ product: "XML", mustBeActive: true })),
      customTooltip:
        validateProductStatus("XML").message ??
        (isStandaloneClient && handleProductMenuHide({ product: "XML" })
          ? "Entre em contato com o nosso comercial: 0800 591 0486."
          : !userPermissons.xml && "Acesso restrito ao usuário"),
      functionMenu: (pathExternal) => {
        if (!userPermissons.xml) {
          return notify("Acesso não liberado.");
        }

        if (isStandaloneClient && handleProductMenuHide({ product: "XML" })) {
          return notify(
            "Para adquirir o produto entre em contato com o nosso comercial: 0800 591 0486."
          );
        }

        if (!validateProductStatus("XML").isActive) {
          return notify(validateProductStatus("XML").message);
        }

        push("/dfe/management");
      },
    },

    {
      systemId: GlobalVariables.SYSTEMS.CRM,
      label: "Atendimentos",
      value: "ATTENDANCE_ACCOUNTANT",
      hasASubMenu: false,
      icon: "AtendimentoIcon",
      hide: isStandaloneClient || !isAccountant,
      blur: !validateProductStatus("CRM").isActive || !userPermissons.crm,
      functionMenu: () => {
        if (isInClientView) {
          return notify(
            "Para que essa função seja ativada, você necessita sair da visão de cliente e retornar à visão de contador."
          );
        }

        if (!userPermissons.crm) {
          return notify("Acesso não liberado.");
        }

        if (!validateProductStatus("CRM").isActive) {
          return notify(validateProductStatus("CRM").message);
        }

        push("/attendance/search");
      },
    },

    {
      label: "Meus Produtos",
      value: "PRODUCTS",
    },

    {
      systemId: GlobalVariables.SYSTEMS.ERP,
      label: companyUser?.isAccountant ? "Gestão Financeira" : "Gestão Fácil",
      hasASubMenu: false,
      value: "ERP",
      icon: "ErpIcon",
      pathExternal: `/zf/login/loginpanel?tokenCognito=${userLogged.token}&document=${companyUser.document}`,
      blur:
        !userPermissons.gestaoFacil ||
        (isStandaloneClient &&
          handleProductMenuHide({ product: "ERP", mustBeActive: true })),
      customTooltip:
        validateProductStatus("ERP").message ??
        (isStandaloneClient && handleProductMenuHide({ product: "ERP" })
          ? "Entre em contato com o nosso comercial: 0800 591 0486."
          : !userPermissons.gestaoFacil && "Acesso restrito ao usuário"),
      functionMenu: (pathExternal) => {
        if (!userPermissons.gestaoFacil) {
          return notify("Acesso não liberado.");
        }

        if (handleProductMenuHide({ product: "ERP" })) {
          setOpenConfig(true);
          return;
        }

        if (!validateProductStatus("ERP").isActive) {
          return notify(validateProductStatus("ERP").message);
        }

        window.open(pathExternal, "_blank");
      },
    },
    {
      systemId: GlobalVariables.SYSTEMS.BANK,
      label: "Qyon Bank",
      hasASubMenu: false,
      value: "BANK",
      icon: "BankIcon",
      pathExternal: `https://bank.qyon.com/`,
      customTooltip: "Acessar Qyon Bank",
      forceRedirect: true,
    },
    {
      systemId: GlobalVariables.SYSTEMS.Contabil,
      label: "Solução contábil",
      value: "ACCOUNTING",
      hasASubMenu: true,
      icon: "ContabilIcon",
    },
    {
      label: "Radar",
      value: "RADAR",
      isBeta: true,
      hasASubMenu: true,
      icon: "CndIcon",
    },
    {
      systemId: GlobalVariables.SYSTEMS.XML,
      hasASubMenu: false,
      label: "XML",
      value: "XML",
      icon: "XmlIcon",
      pathExternal: `#/remote?token=${userLogged.tokenCognito}&cnpj=${companyUser.document}&painel=true`,
      hide: false,
      blur:
        !userPermissons.xml ||
        (isStandaloneClient &&
          handleProductMenuHide({ product: "XML", mustBeActive: true })),
      customTooltip:
        validateProductStatus("XML").message ??
        (isStandaloneClient && handleProductMenuHide({ product: "XML" })
          ? "Entre em contato com o nosso comercial: 0800 591 0486."
          : !userPermissons.xml && "Acesso restrito ao usuário"),
      functionMenu: (pathExternal) => {
        if (!userPermissons.xml) {
          return notify("Acesso não liberado.");
        }

        if (isStandaloneClient && handleProductMenuHide({ product: "XML" })) {
          return notify(
            "Para adquirir o produto entre em contato com o nosso comercial: 0800 591 0486."
          );
        }

        if (!validateProductStatus("XML").isActive) {
          return notify(validateProductStatus("XML").message);
        }

        window.open(pathExternal, "_blank");
      },
    },
  ];

  if (isClient) {
    optionsMenu.splice(0, 0, ...optionsClient);
  } else {
    optionsMenu.splice(5, 0, ...optionsClient);
  }

  const optionSubMenus = useMemo(
    () => ({
      CLIENT_MANAGEMENT: [
        {
          label: "Clientes",
          value: "CLIENT_MANAGEMENT",
          hasASubMenu: false,
          icon: "BpoIcon",
          path: "/customermanagement/list",
          hide: isClient || isStandaloneClient,
          blur: !userPermissons.customerManagement,
          customTooltip:
            !userPermissons.customerManagement && "Acesso restrito ao usuário",
          standard: true,
        },
        {
          label: "Certificados",
          value: "CERTIFICATE",
          hasASubMenu: false,
          icon: "BpoIcon",
          path: "/certificate",
          hide: isClient || isStandaloneClient,
          blur: !userPermissons.customerManagement,
          customTooltip:
            !userPermissons.customerManagement && "Acesso restrito ao usuário",
          standard: true,
          isBeta: true,
        },
      ],

      RADAR: [
        {
          label: "CNDs",
          blur:
            !userPermissons.cnd ||
            (handleProductMenuHide({ product: "ERP", mustBeActive: true }) &&
              handleProductMenuHide({ product: "XML", mustBeActive: true })),
          hasPermission:
            !handleProductMenuHide({
              product: "ERP",
              mustBeActive: true,
            }) ||
            !handleProductMenuHide({
              product: "XML",
              mustBeActive: true,
            }),
          hide: false,
          functionMenu: () => {
            if (!userPermissons.cnd) {
              return notify("Acesso não liberado.");
            } else {
              let userId = localStorage.getItem("userIdPanel");
              let document = localStorage.getItem("documentPanel");
              let token = localStorage.getItem("tokenPanel");
              let baseUrl =
                localStorage.getItem("environment") === "prod"
                  ? "https://radar.qyon.com/"
                  : "https://radar-staging.qyon.com/";

              const url = `${baseUrl}/#/?iduser=${userId}&document=${document}&page=cnd&token=${token}`;
              window.open(url, "_blank");
            }
          },
        },
        {
          label: "ECAC",
          blur:
            !userPermissons.cnd ||
            (handleProductMenuHide({ product: "ERP", mustBeActive: true }) &&
              handleProductMenuHide({ product: "XML", mustBeActive: true })),
          hasPermission:
            !handleProductMenuHide({
              product: "ERP",
              mustBeActive: true,
            }) ||
            !handleProductMenuHide({
              product: "XML",
              mustBeActive: true,
            }),
          hide: isClient,
          functionMenu: () => {
            if (!userPermissons.cnd) {
              return notify("Acesso não liberado.");
            } else {
              let userId = localStorage.getItem("userIdPanel");
              let document = localStorage.getItem("documentPanel");
              let token = localStorage.getItem("tokenPanel");
              let baseUrl =
                localStorage.getItem("environment") === "prod"
                  ? "https://radar.qyon.com/"
                  : "https://radar-staging.qyon.com/";

              const url = `${baseUrl}/#/?iduser=${userId}&document=${document}&page=ecac&token=${token}`;
              window.open(url, "_blank");
            }
          },
        },
      ],
      ACCOUNTING: [
        {
          systemId: GlobalVariables.SYSTEMS.Contabil,
          label: "Contabilidade",
          value: "ACCOUNTING",
          hasASubMenu: false,
          icon: "ContabilIcon",
          pathExternal: `/panel/${userLogged.token}?company_document=${companyUser.document}`,
          hide: isClient,
          hasPermission: userPermissons.contabil,
          blur:
            (isStandaloneClient &&
              handleProductMenuHide({
                product: "Contabil",
                mustBeActive: true,
              })) ||
            !userPermissons.contabil,
          customTooltip:
            validateProductStatus("Contabil").message ??
            (isStandaloneClient &&
            handleProductMenuHide({ product: "Contabil" })
              ? "Entre em contato com o nosso comercial: 0800 591 0486."
              : !userPermissons.contabil && "Acesso restrito ao usuário"),
          functionMenu: (pathExternal) => {
            if (!userPermissons.contabil) {
              return notify("Acesso não liberado.");
            }

            if (
              isStandaloneClient &&
              handleProductMenuHide({ product: "Contabil" })
            ) {
              return notify(
                "Para adquirir o produto entre em contato com o nosso comercial: 0800 591 0486."
              );
            }

            if (!validateProductStatus("Contabil").isActive) {
              return notify(validateProductStatus("Contabil").message);
            }

            window.open(pathExternal, "_blank");
          },
        },
        {
          systemId: GlobalVariables.SYSTEMS.Fiscal,
          SubMenu: true,
          label: "Escrita Fiscal",
          value: "TAX_WRITING",
          icon: "EscritaIcon",
          pathExternal: `/panel/${userLogged.token}?company_document=${companyUser.document}`,
          hide: isClient,
          hasPermission: userPermissons.fiscal,
          blur:
            (isStandaloneClient &&
              handleProductMenuHide({
                product: "Fiscal",
                mustBeActive: true,
              })) ||
            !userPermissons.fiscal,
          customTooltip:
            validateProductStatus("Fiscal").message ??
            (isStandaloneClient && handleProductMenuHide({ product: "Fiscal" })
              ? "Entre em contato com o nosso comercial: 0800 591 0486."
              : !userPermissons.fiscal && "Acesso restrito ao usuário"),
          functionMenu: (pathExternal) => {
            if (!userPermissons.fiscal) {
              return notify("Acesso não liberado.");
            }

            if (
              isStandaloneClient &&
              handleProductMenuHide({ product: "Fiscal" })
            ) {
              return notify(
                "Para adquirir o produto entre em contato com o nosso comercial: 0800 591 0486."
              );
            }

            if (!validateProductStatus("Fiscal").isActive) {
              return notify(validateProductStatus("Fiscal").message);
            }

            window.open(pathExternal, "_blank");
          },
        },
        {
          systemId: GlobalVariables.SYSTEMS.Folha,
          SubMenu: true,
          label: !isClient ? "Folha de Pagamento" : "Simulação Folha",
          value: "PAYROLL",
          icon: "FolhaIcon",
          pathExternal: `/panel/${userLogged.token}?company_document=${
            !isClient ? companyUser.document : companyUser.accountantCnpj
          }${isClient ? "&company_access=" + companyUser.document : ""}`,
          hasPermission: userPermissons.folha,
          blur:
            (isStandaloneClient &&
              handleProductMenuHide({
                product: "Folha",
                mustBeActive: true,
              })) ||
            !userPermissons.folha,
          customTooltip:
            validateProductStatus("Folha").message ??
            (isStandaloneClient && handleProductMenuHide({ product: "Folha" })
              ? "Entre em contato com o nosso comercial: 0800 591 0486."
              : !userPermissons.folha && "Acesso restrito ao usuário"),
          functionMenu: (pathExternal) => {
            if (isInClientView) {
              return notify(
                "Essa função não está disponível na visão de cliente."
              );
            }

            if (!userPermissons.folha) {
              return notify("Acesso não liberado.");
            }

            if (
              isStandaloneClient &&
              handleProductMenuHide({ product: "Folha" })
            ) {
              return notify(
                "Para adquirir o produto entre em contato com o nosso comercial: 0800 591 0486."
              );
            }

            if (!validateProductStatus("Folha").isActive) {
              return notify(validateProductStatus("Folha").message);
            }

            window.open(pathExternal, "_blank");
          },
        },
      ],
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [userPermissons]
  );

  return {
    userPermissons,
    isAccountant,
    isClient,
    isStandaloneClient,
    isInClientView,
    optionsMenu,
    optionSubMenus,
    push,
    handleProductMenuHide,
    validateProductStatus,
    disconnect,
  };
}
