exports.RpaStatus = {
  POSITIVA: {
    id: 1,
    description: "Positiva",
  },
  NEGATIVA_POSITIVA: {
    id: 2,
    description: "Positiva com efeito Negativa",
  },
  NEGATIVA: {
    id: 3,
    description: "Negativa",
  },
  INCONSISTENTE: {
    id: 4,
    description: "Inconsistente",
  },
  FALHA: {
    id: 5,
    description: "Falha",
  },

  ERRO: {
    id: 6,
    description: "Erro",
  },
  PROCESSANDO: {
    id: 7,
    description: "Processando",
  },
};
