import styled, { css } from "styled-components";

import { FavoriteOutlined } from "@material-ui/icons";

import {
  Paper,
  Tabs,
  Tab,
  ButtonGroup,
  Button,
  CardHeader,
  Typography,
} from "@material-ui/core";
import ToggleButton from "@material-ui/lab/ToggleButton";

const white = (props) => props.theme.palette.white;
const blue = (props) => props.theme.palette.blue;
const green = (props) => props.theme.palette.green;
const orange = (props) => props.theme.palette.orange;

export const TabBody = styled(Paper)`
  padding: 16px;
  width: 100%;
`;

export const TabStyled = styled(Tab)`
  opacity: 0.8;

  &.Mui-selected {
    color: ${white};
    font-weight: bold;
    opacity: 1;
    z-index: 1;
  }

  &.Mui-disabled {
    opacity: 0.5;
  }
`;

export const MainTabsStyled = styled(Tabs)`
  background-color: ${blue};
  height: 100%;

  .MuiTabs-indicator {
    background-color: ${white};
    height: 4px;
  }

  ${TabStyled} {
    color: ${white};
  }
`;

export const TabsStyled = styled(Tabs)`
  background-color: ${white};
  height: 100%;

  .MuiTabs-indicator {
    background-color: ${blue};
    width: 100%;
  }
`;

export const TabPanelContainer = styled.div`
  flex-grow: 1;
`;

export const ButtonStyled = styled(Button)`
  background-color: ${blue} !important;
  color: ${white} !important;

  &:hover {
    background-color: ${orange} !important;
  }
`;

export const ButtonGroupCheckAll = styled(ButtonGroup)`
  height: 100%;
`;

export const PermissionIcon = styled.img`
  width: 32px;
  height: 32px;
`;

export const ToggleButtonStyled = styled(ToggleButton)`
  width: 100%;
  border-radius: 12px;
  border-width: 3px;
  padding: 5px;

  &.square {
    aspect-ratio: 1 / 1;
  }

  &.Mui-selected {
    border-color: ${(props) => (props.$checkedByGroup ? blue : green)};
    color: ${(props) => (props.$checkedByGroup ? blue : green)};
  }
`;

export const CardHeaderStyled = styled(CardHeader)`
  flex-wrap: wrap;

  .MuiCardHeader-content {
    white-space: nowrap;
  }

  .MuiCardHeader-action {
    align-items: center;
    margin-top: 0;
  }
`;

export const FavoriteOutlinedStyled = styled(FavoriteOutlined)`
  ${(props) =>
    props.$color &&
    css`
      color: ${(props) => props.theme.palette[props.$color || "black"]};
    `};
`;

export const CardPermissionLabel = styled(Typography)`
  font-size: 12px;
`;
