import styled, { css } from "styled-components";

import {
  Container,
  Grid,
  TextField,
  IconButton,
  Card,
  Button,
} from "@material-ui/core";
import SpeedDial from "@material-ui/lab/SpeedDial";
import SpeedDialAction from "@material-ui/lab/SpeedDialAction";
import CircularProgress from "@material-ui/core/CircularProgress";

import ReactPlayer from "react-player";

/**
 * Grids
 */
export const Content = styled(Container)`
  padding: 100px 24px;
  height: auto;
  @media only screen and (max-width: 768px) {
    padding: 25px;
  }
  padding-left: 80px;
  padding-right: 80px;
`;

export const Section = styled.section`
  margin: 0;
  width: 100%;
  height: auto;
  font-size: 62.5%;
  transition: 1s;

  ${(props) =>
    props.$backgroundColor &&
    css`
      background-color: ${(props) => props.$backgroundColor};
    `};

  ${(props) =>
    props.$backgroundImage &&
    css`
      transition: 0.8s;
      background-image: url(${(props) => props.$backgroundImage});
      background-repeat: round;
    `};
`;

export const LoginContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-right: 20px;
  padding-top: 20px;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
    margin-left: 20px;
    padding: 20px;
  }
`;

export const TextFieldLogin = styled(TextField)`
  border-radius: 30px;
  font-size: 14px;
  background-color: white;
  margin-right: 10px;
  .MuiOutlinedInput-notchedOutline {
    border: none !important;
  }
  @media only screen and (max-width: 768px) {
    width: 100%;
    margin-bottom: 20px;
  }
`;

export const ButtonLogin = styled(Button)`
  background: white;
  border-radius: 30px;
  width: 150px;
  height: 60px;
  text-transform: capitalize;
  font-size: 18px;
  :hover {
    background: white;
  }
  @media only screen and (max-width: 768px) {
    width: 100%;
  }
`;

export const Li = styled.li`
  border: 1px solid ${(props) => props.$color};
  color: ${(props) => props.$color};

  ${(props) =>
    props.$border &&
    css`
      border: ${(props) => props.$border} !important;
    `};

  ${(props) =>
    props.$backgroundColor &&
    css`
      background-color: ${(props) => props.$backgroundColor} !important;
    `};

  :hover {
    color: black;
    cursor: pointer;

    ${(props) =>
      props.$hoverBackgroundColor &&
      css`
        background-color: ${(props) => props.$hoverBackgroundColor} !important;
      `};

    ${(props) =>
      props.$hoverColor &&
      css`
        color: ${(props) => props.$hoverColor} !important;
      `};
  }
`;

export const Nav = styled.nav`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-left: 60px;

  @media only screen and (max-width: 768px) {
    margin-left: 0px;
    padding: 20px;
  }

  ul {
    display: flex;
    list-style: none;
    padding: 0;
    justify-content: space-between;
    width: 100%;

    @media only screen and (max-width: 768px) {
      display: block;
      margin-left: 0px;
    }

    ${Li} {
      padding: 12px;
      margin: 0;
      width: 20%;
      border-radius: 10vh;
      background-color: transparent;
      font-size: 1.2rem;
      transition: 0.825s;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;

      @media only screen and (max-width: 768px) {
        width: 100%;
        padding: 0;
        margin: 0;
        margin-bottom: 25px;
        height: 50px;
      }
    }
  }
`;

export const HeaderButtons = styled(Grid)`
  justify-content: center;
  align-items: center;
`;

/**
 * Elementos
 */
export const MainTextArea = styled.div`
  margin-top: 75px;
  width: 100%;
  @media only screen and (max-width: 768px) {
    margin-top: 0px;
    margin-bottom: 25px;
  }
`;

export const FooterTextArea = styled.div`
  margin-top: 40px;
  width: 100%;
  @media only screen and (max-width: 768px) {
    p {
      text-align: center !important;
    }
  }
`;

export const IconArea = styled.div`
  width: 100%;
  margin-top: 60px;

  .MuiSvgIcon-root {
    padding: 0;
    font-size: 2.8rem;
    color: white;
    margin-right: 20px;
    margin-bottom: 10px;
    transition: 0.825s;

    @media only screen and (max-width: 768px) {
      font-size: 3.4rem;
      margin-right: 40px;
    }

    :hover {
      ${(props) =>
        props.$editable === true &&
        css`
          margin-bottom: 15px;
          border: 1px solid white;
          border-radius: 8px;
          cursor: pointer;
        `};
    }
  }

  @media only screen and (max-width: 768px) {
    justify-content: center;
    align-items: center;
    margin-bottom: 55px;
    display: flex;
  }
`;

export const IframeMap = styled.iframe`
  width: 100%;
  height: 100%;
  border-radius: 2vh;
  border: none;

  @media only screen and (max-width: 768px) {
    min-height: 400px;
  }
`;

export const Slogan = styled.div`
  width: 100%;
  padding: 0;
  justify-content: center;
  align-items: center;
  display: flex;
  min-height: 90px;
  height: auto;
  text-align: center;

  h1 {
    margin: 0;
    color: ${(props) => props.$color};
    font-size: 3.475rem;
    font-family: "Roboto", sans-serif;
    width: 100%;

    @media only screen and (max-width: 768px) {
      font-size: 2.875rem;
    }
  }

  :hover {
    ${(props) =>
      props.$editable === true &&
      css`
        border: 1px solid white;
        cursor: text;
      `};
  }
`;

export const SubSlogan = styled.div`
  padding: 0;
  justify-content: center;
  align-items: center;
  display: flex;
  width: 100%;

  h2 {
    width: 100%;
    text-align: center;
    margin: 0;
    color: ${(props) => props.$color};
    font-size: 1.575rem;

    @media only screen and (max-width: 768px) {
      margin-top: 25px;
      font-size: 1.175rem;
    }
  }

  textarea {
    @media only screen and (max-width: 768px) {
      font-size: 1.175rem;
    }
  }

  :hover {
    ${(props) =>
      props.$editable === true &&
      css`
        border: 1px solid white;
        cursor: text;
      `};
  }
`;

export const Title = styled.div`
  h2 {
    margin: 0;
    font-family: "Roboto", sans-serif;
    color: ${(props) => props.$color} !important;
    font-size: 2.775rem;
    font-weight: bolder;
  }

  @media only screen and (max-width: 768px) {
    margin-top: 25px;
    width: 100%;
    text-align: center;
  }
`;

export const Text = styled.p`
  margin-top: 10px;
  font-size: 1.275rem;
  color: ${(props) => props.$color};
  font-family: "Roboto", sans-serif;
  white-space: pre-line;
  ${(props) =>
    props.$fontWeight &&
    css`
      font-weight: ${(props) => props.$fontWeight} !important;
    `};

  @media only screen and (max-width: 768px) {
    margin-top: 30px;
    text-align: justify;
  }
`;

export const Powered = styled.div`
  margin: 0;
  bottom: 0;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  p {
    color: black;
    font-size: 0.875rem;
  }
`;

export const LogoImage = styled.img`
  max-width: 100%;
  max-height: 180px;
  @media only screen and (max-width: 768px) {
    width: auto;
    max-width: 85%;
  }
`;

export const BtnService = styled.div`
  margin-top: 40px;
  width: 100%;
  background-color: ${(props) => props.$backgroundColor};
  border-radius: 2vh;
  height: 80px;
  font-size: 1.2rem;
  color: ${(props) => props.$color};
  transition: 1.225s;
  border: none;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  :hover {
    margin-top: 25px;

    @media only screen and (max-width: 768px) {
      margin-top: 35px;
    }

    ${(props) =>
      props.$hoverBackgroundColor &&
      css`
        background-color: ${(props) => props.$hoverBackgroundColor} !important;
      `};

    ${(props) =>
      props.$hoverColor &&
      css`
        color: ${(props) => props.$hoverColor} !important;
      `};

    ${(props) =>
      props.$borderHoverColor &&
      css`
        border: 1px solid ${(props) => props.$borderHoverColor} !important;
      `};
  }

  @media only screen and (max-width: 768px) {
    margin-top: 50px;
  }
`;

export const FloatingButton = styled.button`
  padding: 10px 60px 10px 30px;
  position: fixed;
  bottom: 45px;
  right: 45px;
  background-color: #25c862;
  border: none;
  border-radius: 100vh;
  align-items: center;
  justify-content: center;
  display: flex;
  color: white;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  transition: 0.8s;
  font-family: "Roboto", sans-serif;
  font-size: 1.125rem;
  z-index: 10;

  @media only screen and (max-width: 768px) {
    height: 55px;
    padding-right: 20px;
    padding-left: 30px;
  }

  p {
    padding: 0;
    margin: 0;

    @media only screen and (max-width: 768px) {
      display: none;
    }
  }

  .MuiSvgIcon-root {
    font-size: 3.375rem;
    left: 100%;
    transform: translateX(-95%);
    position: absolute;
  }

  :hover {
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.55) 0px 7px 20px;
    bottom: 50px;
  }
`;

export const CircularSaving = styled(CircularProgress)``;

export const FloatingSaving = styled.div`
  position: fixed;
  bottom: 45px;
  left: 45px;
  padding: 10px 30px 10px 30px;
  background-color: #d4d3d3;
  border: none;
  border-radius: 100vh;
  align-items: center;
  justify-content: center;
  display: flex;
  color: ${(props) => props.$color};
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  font-family: "Roboto", sans-serif;
  font-size: 1.125rem;
  z-index: 10;
  ${CircularSaving} {
    margin-right: 8px;
    height: 20px !important;
    width: 20px !important;
  }
`;

export const AboutUsContainer = styled(Container)`
  height: auto;
  padding: 100px 24px;
  padding-left: 80px;
  padding-right: 80px;
`;

export const AboutUsContent = styled(Grid)`
  height: auto;
  height: 100%;

  .aboutUsCenter {
    margin: 0px -16px;
  }

  .aboutUsImageBox {
    height: fit-content;

    @media only screen and (max-width: 768px) {
      .preview-upload-img {
        display: flex !important;
        align-items: center;
        justify-content: center;
      }
    }

    img {
      width: 100%;
      @media only screen and (max-width: 768px) {
        width: 65%;
      }
    }
  }

  .aboutUsTextBox {
    height: 100%;
  }
`;

export const AboutTextContent = styled(Grid)`
  height: 100%;

  :hover {
    ${(props) =>
      props.$editable === true &&
      css`
        border: 1px solid ${(props) => props.$borderColor};
        cursor: text;
      `};
  }

  @media only screen and (max-width: 768px) {
    padding: 10px;
  }
`;

export const ItemSelector = styled(SpeedDialAction)`
  background-color: ${(props) => props.$backgroundColor} !important;
  color: white !important;
`;

export const FloatingSelector = styled(SpeedDial)`
  position: absolute;
  top: 45px;
  right: 45px;

  .MuiFab-primary {
    background-color: ${(props) => props.$backgroundColor} !important;
  }
`;

/**
 * Edição
 */

export const TextEdit = styled.textarea`
  margin: 0;
  width: 100%;
  border: none;
  box-shadow: none;
  outline: 0;
  font-size: ${(props) => props.$fontSize}rem;
  text-align: center;
  font-family: "Roboto", sans-serif;
  background-color: transparent;
  color: ${(props) => props.$color};

  ${(props) =>
    props.$minHeight &&
    css`
      min-height: ${(props) => props.$minHeight}px !important;
    `};

  ${(props) =>
    props.$textAlign &&
    css`
      text-align: ${(props) => props.$textAlign} !important;
    `};

  @media only screen and (max-width: 768px) {
    min-height: 140px;
  }
`;

export const TextAreaEdit = styled.textarea`
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  border: none;
  box-shadow: none;
  outline: 0;
  min-height: inherit;
  height: auto;
  font-family: "Roboto", sans-serif;
  background-color: transparent;
  font-size: ${(props) => props.$fontSize}rem;

  @media only screen and (max-width: 768px) {
    margin-top: 30px;
    text-align: justify !important;
  }
`;

export const HeaderDialog = styled.div`
  .MuiDialogTitle-root {
    padding: 0px;
    .MuiTypography-h6 {
      line-height: unset;
    }
  }

  .MuiChip-root {
    border-radius: 0px;
    width: 100%;
    background-color: ${(props) => props.$backgroundColor};
    color: ${(props) => props.$color};

    .MuiChip-label {
      width: 100%;
      align-items: center;
      justify-content: center;
      display: flex !important;
      font-size: 1.275rem;
      font-family: "Roboto", sans-serif;
    }
  }
`;

export const GridLink = styled(Grid)`
  margin-bottom: 10px !important;
`;

export const TextLink = styled(TextField)`
  width: 100%;
`;

export const ButtonHelper = styled(IconButton)`
  padding: 5px !important;
`;

/**
 * Responsividade
 */

export const WebsiteTypeContainer = styled.div`
  position: absolute;
  padding-top: 45px;
  padding-left: 45px;
  display: flex;
  align-items: flex-start;
  height: 80px;
  font-family: "Roboto", sans-serif;
`;

export const WebsiteTypeDialAction = styled(SpeedDialAction)``;

export const WebsiteTypeDial = styled(SpeedDial)`
  font-family: "Roboto", sans-serif;
  .MuiSpeedDialAction-staticTooltipLabel {
    width: max-content;
    height: 35px;
    display: flex;
    align-items: center;
  }
`;

export const WebsiteTypeCard = styled(Card)`
  margin-left: 16px;
  font-size: 1rem;
  padding: 16px;
  font-family: "Roboto", sans-serif;
  .MuiSvgIcon-fontSizeLarge {
    margin-top: 4px;
  }
`;

export const UrlWebsiteField = styled(TextField)`
  width: 100%;
`;

export const VideoPlayer = styled(ReactPlayer)`
  iframe {
    border-radius: 16px !important;
  }
`;

export const VideoContainer = styled(Container)`
  height: auto;
  padding: 100px 24px;
  padding-left: 80px;
  padding-right: 80px;

  .video-grid {
    margin-top: 0;
    margin-bottom: 0;
    height: auto;
  }
`;

export const VideoUrlField = styled(TextField)`
  margin-top: 12px !important;
  width: 100%;
`;

export const VideoTextDiv = styled.div`
  padding: 0;
  justify-content: center;
  align-items: center;
  display: flex;
  width: 100%;

  h2 {
    width: 100%;
    margin: 0;
    color: ${(props) => props.$color};
    font-size: 3rem;

    @media only screen and (max-width: 768px) {
      margin-top: 25px;
      font-size: 3rem;
    }
  }

  textarea {
    @media only screen and (max-width: 768px) {
      font-size: 3rem;
    }
  }

  :hover {
    ${(props) =>
      props.$editable === true &&
      css`
        border: 1px solid white;
        cursor: text;
      `};
  }
`;
