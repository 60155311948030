import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import {
  ActualTabGrid,
  ActualTabGridPlayerIcon,
  ActualTabGridText,
  Arrow,
  ConfigTitle,
  ConfigVersion,
  // PlayerIconsSize,
} from "./styles/ActualConfig";

export default function ActualConfig({ ActualTab }) {
  const { push } = useHistory();

  return (
    <ActualTabGrid container alignItems="center">
      <ActualTabGridText>
        <ConfigTitle
          onClick={() => {
            push("/setting");
          }}
        >
          {" "}
          Configuração
        </ConfigTitle>
        <Arrow>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
          >
            <g opacity="0.99">
              <path
                opacity="0.99"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M7.32739 4.41107C7.65283 4.08563 8.18047 4.08563 8.5059 4.41107L13.5059 9.41107C13.8313 9.73651 13.8313 10.2641 13.5059 10.5896L8.5059 15.5896C8.18047 15.915 7.65283 15.915 7.32739 15.5896C7.00195 15.2641 7.00195 14.7365 7.32739 14.4111L11.7381 10.0003L7.32739 5.58958C7.00195 5.26414 7.00195 4.73651 7.32739 4.41107Z"
                fill="#424242"
              />
            </g>
          </svg>
        </Arrow>
        <ConfigVersion>{ActualTab}</ConfigVersion>
      </ActualTabGridText>
      <ActualTabGridPlayerIcon>
        {/* <PlayerIconsSize>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M9.745 10.1076C10.0544 9.92897 10.4356 9.92897 10.745 10.1076L15.995 13.1387C16.3044 13.3173 16.495 13.6475 16.495 14.0047C16.495 14.362 16.3044 14.6921 15.995 14.8708L10.745 17.9019C10.4356 18.0805 10.0544 18.0805 9.745 17.9019C9.43559 17.7232 9.245 17.3931 9.245 17.0358V10.9736C9.245 10.6164 9.43559 10.2862 9.745 10.1076Z"
              fill="#616161"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M1.995 4.50488C1.995 3.12417 3.11429 2.00488 4.495 2.00488H19.495C20.8757 2.00488 21.995 3.12418 21.995 4.50488V19.5049C21.995 20.8856 20.8757 22.0049 19.495 22.0049H4.495C3.1143 22.0049 1.995 20.8856 1.995 19.5049V4.50488ZM8.62655 4.00488H4.495C4.21885 4.00488 3.995 4.22874 3.995 4.50488V6.50488H6.95988L8.62655 4.00488ZM9.36358 6.50488L11.0302 4.00488H14.6265L12.9599 6.50488H9.36358ZM7.47344 8.50488C7.48721 8.50517 7.50097 8.50517 7.51472 8.50488H13.4734C13.4872 8.50517 13.501 8.50517 13.5147 8.50488H19.995V19.5049C19.995 19.781 19.7712 20.0049 19.495 20.0049H4.495C4.21884 20.0049 3.995 19.781 3.995 19.5049V8.50488H7.47344ZM17.0302 4.00488L15.3636 6.50488H19.995V4.50488C19.995 4.22873 19.7711 4.00488 19.495 4.00488H17.0302Z"
              fill="#616161"
            />
          </svg>
        </PlayerIconsSize> */}
      </ActualTabGridPlayerIcon>
    </ActualTabGrid>
  );
}
