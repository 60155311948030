import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  FormControl,
  Input,
  MenuItem,
  Select,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import EmailEditor from "react-email-editor";
import { LabelStyled } from "../../styles/CDNTableCndEmail";
import { TableDescriptionEmail } from "./LegendEditor";

export const ModalNewTemplate = (props) => {
  return (
    <div>
      <Accordion defaultExpanded={true} style={{ marginTop: "10px" }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <LabelStyled>Nome do Template</LabelStyled>
        </AccordionSummary>
        <AccordionDetails>
          <FormControl fullWidth sx={{ m: 1 }} variant="standard">
            <Input
              size="small"
              id="standard-adornment-amount"
              value={props.nameTemplate}
              onChange={props.handleChangeName}
            />
          </FormControl>
        </AccordionDetails>
      </Accordion>

      <Accordion defaultExpanded={true}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <LabelStyled>Tipo da CND</LabelStyled>
        </AccordionSummary>
        <AccordionDetails>
          <FormControl fullWidth>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={props.TypeCND}
              label="Age"
              onChange={props.handleChangeType}
            >
              <MenuItem value={"Negativa"}>Negativa</MenuItem>
              <MenuItem value={"Positiva"}>Positiva</MenuItem>
              <MenuItem value={"Erro"}>Erro</MenuItem>
              <MenuItem value={"Inconsistente"}>Inconsistente</MenuItem>
            </Select>
          </FormControl>
        </AccordionDetails>
      </Accordion>

      <Accordion defaultExpanded={true}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <LabelStyled>Template</LabelStyled>
        </AccordionSummary>
        <AccordionDetails>
          <EmailEditor ref={props.emailEditorRef} onReady={props.onLoad} />
        </AccordionDetails>
        {props.value !== "" && <TableDescriptionEmail />}
      </Accordion>
    </div>
  );
};
