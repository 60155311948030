import React, { useContext, useRef, useState } from "react";

import { AvatarPersonIconStyled, SidebarButton } from "./styles/SidebarDrawer";
import {
  Badge,
  CssBaseline,
  Drawer,
  FormControl,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  Toolbar,
  debounce,
  makeStyles,
} from "@material-ui/core";
import clsx from "clsx";
import { Close as CloseIcon } from "@material-ui/icons";
import {
  ArrowBackIosOutlined,
  ArrowForwardIosOutlined,
  ExitToApp,
  SearchOutlined,
} from "@material-ui/icons";
import { InfoUser, InfoUserContainer } from "../sidebar/styles/Sidebar";
import { DividerStyled } from "../chat/styles/GeneralStyle";
import MenuDataOffice from "../sidebar/MenuDataOffice";
import { Sobreposition, TextApresentation } from "../../styles/GlobalStyle";
import { GlobalContext } from "../../contexts/GlobalContext";
import { useEffect } from "react";
import useWebSocketClient from "../chat/controllers/WebSocketClient";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import PersonOutlineOutlinedIcon from "@material-ui/icons/PersonOutlineOutlined";

export const SidebarDrawer = ({ notificationOpen, ...props }) => {
  const {
    sidebar,
    setSidebar,
    userLogged,
    companyUser,
    animation,
    textAnimation,
    overlap,
  } = useContext(GlobalContext);

  const inputRef = useRef(null);
  const [inputValue, setInputValue] = useState("");
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    const debouncedHandleSize = debounce(function handleResize() {
      if (window.innerWidth < 700) {
        setSidebar((current) => ({ ...current, open: false }));
      }
    }, 50);

    window.addEventListener("resize", debouncedHandleSize);

    const handleKeyDown = (event) => {
      if (event.altKey && event.key === "k") {
        if (inputRef.current) {
          inputRef.current.focus();
        }
      }
    };

    // Add the event listener when the component mounts
    window.addEventListener("keydown", handleKeyDown);

    // Remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      window.removeEventListener("resize", debouncedHandleSize);
    };
  }, [setSidebar]);

  const handleChange = (event) => {
    const newValue = event.target.value;
    setInputValue(newValue);
    setSearchText(newValue);
  };

  const useStyles = makeStyles((theme) => ({
    drawer: {
      zIndex: 1,
      width: 203,
      flexShrink: 0,
      whiteSpace: "nowrap",
    },
    drawerOpen: {
      paddingTop: notificationOpen ? 65 + 50 : 40,
      zIndex: 1,
      backgroundColor: "#f0f1f3",
      width: 240,
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerClose: {
      zIndex: 1,
      marginTop: notificationOpen === true ? 65 + 50 : 40,
      backgroundColor: "#f0f1f3",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: "hidden",
      width: theme.spacing(7) + 1,
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9) + 1,
      },
    },
  }));

  const styleSyncIcon = makeStyles({
    squareIconButton: {
      marginBottom: "40px",
      width: "37px",
      height: "35px",
    },
  });
  const classIcon = styleSyncIcon();

  const buttonSyles = makeStyles({
    squareButtonStyle: {
      width: "10px",
      height: "10px",
      marginTop: "50px",
      marginBottom: "20px",
      border: "2px solid #ebecee",
      backgroundColor: "#ffffff",
      borderRadius: "4px",
      "&:hover": {
        backgroundColor: "#0338b4",
        color: "white",
      },
    },
    IconStyle: {
      fontSize: 12,
      "&:hover": {
        backgroundColor: "#0338b4",
        color: "white",
      },
    },
  });

  const classButton = buttonSyles();

  const { disconnect } = useWebSocketClient();

  const { push } = useHistory();

  const classes = useStyles({ notificationOpen });

  const handleDrawerOpen = () => {
    if (window.innerWidth < 700) return;

    setSidebar((current) => ({ ...current, open: true }));
    setTimeout(() => {
      dispatchEvent(new Event("resize"));
    }, 0);
  };

  const handleDrawerClose = () => {
    setSidebar((current) => ({ ...current, open: false }));
    setTimeout(() => {
      dispatchEvent(new Event("resize"));
    }, 0);
  };

  return (
    <>
      <CssBaseline />
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: sidebar.open,
          [classes.drawerClose]: !sidebar.open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: sidebar.open,
            [classes.drawerClose]: !sidebar.open,
          }),
        }}
      >
        <Sobreposition overlap={overlap} top={animation === 2}>
          <TextApresentation text={textAnimation !== ""}>
            {textAnimation}
          </TextApresentation>
        </Sobreposition>

        <Toolbar open={sidebar.open} style={{ paddingRight: 5 }}>
          <Grid
            container
            style={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <IconButton
              className={classButton.squareButtonStyle}
              onClick={sidebar.open ? handleDrawerClose : handleDrawerOpen}
            >
              {sidebar.open ? (
                <ArrowBackIosOutlined className={classButton.IconStyle} />
              ) : (
                <ArrowForwardIosOutlined className={classButton.IconStyle} />
              )}
            </IconButton>
          </Grid>
        </Toolbar>

        <Grid
          container
          justifyContent="space-between"
          style={{ overflowX: "hidden", height: "100%" }}
        >
          <Grid item xs={12}>
            {sidebar.open && (
              <FormControl
                variant="standard"
                style={{
                  marginRight: 10,
                  marginLeft: 10,
                  color: "#063bb5",
                }}
              >
                <Input
                  inputRef={inputRef}
                  value={inputValue}
                  onChange={handleChange}
                  style={{ color: "grey" }}
                  placeholder={sidebar.open ? "Buscar ALT + K" : ""}
                  startAdornment={
                    <InputAdornment position="start">
                      <Badge size="small">
                        <SearchOutlined
                          style={{
                            color: "#063bb5",
                            marginBottom: 5,
                            marginLeft: sidebar.open ? 5 : 15,
                          }}
                        />
                      </Badge>
                    </InputAdornment>
                  }
                  endAdornment={
                    inputValue && (
                      <InputAdornment position="start">
                        <IconButton
                          size="small"
                          onClick={() => {
                            setInputValue("");
                            setSearchText("");
                          }}
                        >
                          <CloseIcon></CloseIcon>
                        </IconButton>
                      </InputAdornment>
                    )
                  }
                />
              </FormControl>
            )}

            <MenuDataOffice open={sidebar.open} search={searchText} />
          </Grid>
        </Grid>

        <DividerStyled style={{ marginBottom: 10 }} />

        {!sidebar.open && (
          <Grid item container alignItems="center" justify="center">
            <AvatarPersonIconStyled
              className={classIcon.squareIconButton}
              style={{ marginBottom: 20 }}
              size="small"
            >
              <PersonOutlineOutlinedIcon />
            </AvatarPersonIconStyled>
            <SidebarButton
              startIcon={<ExitToApp style={{ fontSize: 24 }} />}
              size="small"
              style={{
                color: "#424c5d",
                textTransform: "capitalize",
                fontSize: 12,
              }}
              onClick={() => {
                disconnect();
                push("/login", { logout: true });
              }}
            ></SidebarButton>
          </Grid>
        )}

        <Grid container>
          <InfoUserContainer
            style={{
              marginBottom: notificationOpen === true ? "55px" : "5px",
            }}
            open={sidebar.open}
            is-accountant={companyUser.isAccountant === "true"}
          >
            {sidebar.open && (
              <Grid container alignItems="center">
                <Grid item xs={12} sm={8} md={9} lg={9}>
                  <Grid container>
                    <Grid item>
                      <AvatarPersonIconStyled
                        className={classIcon.squareIconButton}
                        style={{ marginRight: 5 }}
                        size="small"
                      >
                        <PersonOutlineOutlinedIcon />
                      </AvatarPersonIconStyled>
                    </Grid>

                    <Grid item>
                      <InfoUser style={{ fontWeight: 600 }}>
                        {`${userLogged?.firstName || ""} ${
                          userLogged?.lastName || ""
                        }`.substring(0, 15)}
                        {`${userLogged?.firstName || ""} ${
                          userLogged?.lastName || ""
                        }`.length > 15
                          ? "..."
                          : ""}
                      </InfoUser>
                      <InfoUser>
                        {(userLogged?.email || "").substring(0, 20)}
                        {userLogged?.email.length > 20 ? "..." : ""}
                      </InfoUser>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} sm={4} md={3} lg={3}>
                  <SidebarButton
                    startIcon={<ExitToApp style={{ fontSize: 14 }} />}
                    size="small"
                    style={{
                      color: "#424c5d",
                      textTransform: "capitalize",
                      paddingBottom: 50,
                      fontSize: 12,
                    }}
                    onClick={() => {
                      disconnect();
                      push("/login", { logout: true });
                    }}
                  >
                    Sair
                  </SidebarButton>
                </Grid>
              </Grid>
            )}
          </InfoUserContainer>
        </Grid>
      </Drawer>
    </>
  );
};
