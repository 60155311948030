import { useCallback } from "react";
import io from "socket.io-client";
import { useChatContext } from "../contexts/ChatContext";

const TOPICS = {
  emit: {
    connect: "/connect",
    start: "/chat/start",
    message: "/chat/message",
    queue: "/chat/queue",
    chatList: "/chat/list",
    loginAttendant: "/loginAttendant",
    onOffAtendants: "/onOffAtendants",
    quit: "/chat/quit",
    transfer: "/chat/transfer",
    attendanceStart: "/chat/attendance/start",
    typing: "chat/typing",
  },
  subscriptions: {
    loginAttendantResponse: (id) => `/loginAttendant/response/${id}`,
    chatAttendant: ({ attendantId, companyId }) =>
      `/attendant/${attendantId}/company/${companyId}`,
    onOffAtendantsResponse: (companyId, roomId, teamId) =>
      `/onOffAtendants/response/${companyId}/${roomId}/${teamId}`,
    connectionResponse: (id) => `/connect/response/${id}`,
    chatStartResponse: (id) => `/chatStart/response/${id}`,
    chatEnterQueue: (sessionId) => `/enterQueue/${sessionId}`,
    chatQueueAttendant: (queueId) => `/attendant/enterQueue/${queueId}`,
    queue: (queueId) => `/chat/queue/${queueId}`,
    productQueue: (roomId, productId) =>
      `/chat/sala/${roomId}/queue/product/${productId}`,
    chatList: (roomId, attendantId) =>
      `/chat/sala/${roomId}/list/attendant/${attendantId}`,
    chatAttendanceResponse: (roomId, sessionId) =>
      `/chat/sala/${roomId}/${sessionId}/attendance/response`,
    attendanceStartResponse: (roomId, sessionId) =>
      `/chat/sala/${roomId}/${sessionId}/attendance/start/response`,
    message: (roomId, sessionId) => `/chat/sala/${roomId}/${sessionId}/message`,
    previousMessages: (roomId, sessionId) =>
      `/chat/sala/${roomId}/${sessionId}/message/previous`,
    disconnect: (roomId, sessionId) =>
      `/chat/sala/${roomId}/${sessionId}/disconnect`,
    typingResponse: (roomId, sessionId) =>
      `chat/sala/${roomId}/${sessionId}/typing/`,
    roomStatus: (roomId) => `sala/${roomId}/status`,
    transferResponse: (sessionId) => `/transfer/response/${sessionId}`,
    callCustumerToConversation: ({
      roomId,
      customerId,
      companyId,
      contactId,
    }) =>
      `/chat/sala/${roomId}/company/${companyId}/customer/${customerId}/contact/${contactId}`,
    chatMonitorResponse: (companyId, roomId) =>
      `chat/monitor/response/company/${companyId}/room/${roomId}`,
  },
};

let client;
let onConnectCallback = [];

export default function useWebSocketClient() {
  const { socket, setSocket } = useChatContext();
  const urlWs = localStorage.getItem("URL_WS");
  try {
    if (!client && urlWs) {
      client = io.connect(urlWs, { transports: ["websocket"] });

      client.on("connect", () => {
        setSocket({ connected: true, clientId: client.id });
        if (onConnectCallback.length > 0) {
          onConnectCallback.map((callback) =>
            callback({
              socket: {
                connected: client.connected,
                id: client.id,
              },
            })
          );
          onConnectCallback = [];
        }
      });
      client.on("disconnect", () => {
        setSocket({ connected: false, clientId: null });
      });
      client.on("reconnect_attempt", () => {
        setSocket({ connected: false, clientId: null });
      });
      client.on("reconnect", () => {
        setSocket({ connected: true, clientId: client.id });
      });
      client.on("error", (error) => {
        console.error("error ", error);
      });
    }
  } catch (error) {
    console.error("Error create socket client ", error);
  }
  const subscribe = useCallback((topic, callback) => {
    if (client.connected) client.on(topic, callback);
  }, []);
  const disconnect = useCallback(() => {
    if (client) {
      if (client?.connected) {
        client.disconnect();
      }
    }
  }, []);

  const connect = useCallback(() => {
    if (!client.connected) client.connect();
  }, []);

  const send = useCallback(({ topic, message, unique }) => {
    if (client.connected) {
      if (unique) {
        setSocket({
          startedTopics: {
            ...socket.startedTopics,
            [topic.replace("/", "")]: true,
          },
        });
      }
      client.emit(topic, message);
    }
  }, []);

  const removeListeners = useCallback((topic) => {
    client.removeAllListeners(topic);
  }, []);

  const onConnect = (callback) => {
    if (client.connected) {
      if (!socket.clientId || !Object.keys(socket.clientId).length > 0) {
        setSocket({ connected: true, clientId: client.id });
        sessionStorage.setItem("SOCKET_ID", client.id);
      }

      callback({
        socket: {
          connected: client.connected,
          id: client.id,
        },
      });
    } else {
      onConnectCallback.push(callback);
    }
  };

  const isSubscribedOn = (topic) => {
    return client.listeners(topic).length > 0;
  };

  return {
    socket: {
      connected: socket.connected,
      id: socket.clientId,
    },
    urlWebSocketClient: urlWs,
    TOPICS,
    subscribe,
    send,
    removeListeners,
    onConnect,
    isSubscribedOn,
    disconnect,
    connect,
  };
}
