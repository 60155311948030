import { useContext, useEffect, useState } from "react";
import { GlobalSubheader } from "../../components/subHeader/GlobalSubheader";
import { GlobalContext } from "../../contexts/GlobalContext";
import { DefaultPageContainer } from "../../components/layoutContainer/styles/LayoutContainer";
import CertificateListView from "./CertificateList";
import CertificateDetailView from "./CertificateDetail";
import CertificateECACView from "./CertificateECAC";

export default function CertificateView({ location }) {
  const { setBreadcrumbs } = useContext(GlobalContext);
  const [certificateDetail, setCertificateDetail] = useState(null);
  const [certificateECAC, setCertificateECAC] = useState(null);

  useEffect(() => {
    if (location.pathname === "/certificate/detail" && location?.state?.data) {
      setCertificateDetail(location?.state?.data);
      setBreadcrumbs([
        {
          name: location?.state?.data.company_name,
          href: "",
        },
        {
          name: "Procurações",
          href: "",
        },
      ]);
    } else if (
      location.pathname === "/certificate/ecac" &&
      location?.state?.data
    ) {
      setCertificateECAC(location?.state?.data);
      setBreadcrumbs([
        {
          name: location?.state?.data.company_name,
          href: "",
        },
        {
          name: "Checagens Procuração",
          href: "",
        },
      ]);
    } else {
      setBreadcrumbs([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <GlobalSubheader title={"Certificados"} titleLocation={"/certificate"} />

      <DefaultPageContainer
        style={{
          height: "calc(100% - 60px)",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {certificateDetail && (
          <CertificateDetailView certificateDetail={certificateDetail} />
        )}

        {certificateECAC && (
          <CertificateECACView certificateECAC={certificateECAC} />
        )}

        {!certificateDetail && !certificateECAC && <CertificateListView />}
      </DefaultPageContainer>
    </>
  );
}
