import { useFormik } from "formik";
import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { useMutation, useQueryClient } from "react-query";
import {
  postFilesS3,
  postMessages,
  putTicket,
} from "../../../repositories/integrations/crm/SupportRepository";
import { formatInteraction } from "../middlewares";

import { schemaData } from "../schema";

const initialValues = {
  subject: "",
  solicitation: "",
  customer: "",
  department: "",
  classification: "",
  situation: "",
  customerCPFCNPJ:
    localStorage.getItem("isAccountantPanel") === "true"
      ? localStorage.getItem("documentPanel")
      : localStorage.getItem("accountantCnpjPanel"),
  email: localStorage.getItem("emailPanel") || "",
};

const UseTicketContext = createContext();

const UseTicketProvider = ({ children }) => {
  const queryClient = useQueryClient();

  const [fields, setfields] = useState({ ...initialValues });

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: schemaData,
    onSubmit: (values) => {
      handleSave(values);
    },
  });

  const {
    handleChange,
    handleBlur,
    setFieldValue,
    handleSubmit,
    values,
    touched,
    errors,
  } = formik;

  const setGeneralData = useCallback(
    async (data) => {
      setfields((prev) => ({
        ...prev,
        subject: data?.subject || "",
        solicitation: data?.description || "",
        customer:
          {
            value: data?.customer?.id,
            title: data?.customer?.socialName || data?.customer?.fantasyName,
          } || "",
        department:
          {
            value: data?.productId,
            title: data?.product,
          } || "",
        classification:
          {
            value: data?.attendanceClassificationId,
            title: data?.classificationDescription,
          } || "",
        situation: {
          value: data?.situation?.situationAttendanceId,
          title: data?.situation?.situationDescription,
        },
      }));
      setFieldValue("subject", data?.subject || "");
      setFieldValue("solicitation", data?.description || "");
      setFieldValue("customer", data?.customer?.id || "");
      setFieldValue("department", data?.productId || "");
      setFieldValue("classification", data?.attendanceClassificationId || "");
      setFieldValue("situation", data?.situation?.situationAttendanceId || "");
    },
    [setFieldValue]
  );

  // -------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

  const handleInputChange = useCallback(
    (value, id) => {
      setfields((prev) => ({
        ...prev,
        [id]: value,
      }));
      setFieldValue(id, value?.value || "");
    },
    [setFieldValue]
  );

  // -------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

  const saveInteraction = useMutation({
    mutationFn: (body) => postMessages({ ...body }),
  });

  const saveFiles = useMutation({
    mutationFn: (body) => postFilesS3({ ...body }),
  });

  const updateTicket = useMutation({
    mutationFn: (body) => putTicket({ ...body }),

    onSuccess: async (res) => {
      if (res?.data?.success) {
        queryClient.invalidateQueries("support-tickets");
      }
    },
  });

  const handleSave = useCallback(
    async ({ messages, files, ticketData }) => {
      const idAttendance = ticketData?.attendanceId;
      let errors = [];

      const data = {
        ...values,
        attendanceId: idAttendance,
        solucion: ticketData?.solutionDate,
        emailInteractionFlag: true,
      };

      updateTicket.mutate(data);

      let resFiles = null;

      if (files?.length > 0) {
        resFiles = await saveFiles.mutateAsync({
          attendanceId: idAttendance,
          attachments: [...files],
        });

        if (!resFiles?.data?.success) {
          errors.push(
            resFiles?.data?.message ||
              "Não foi possível salvar um ou mais arquivos!"
          );
        } else {
          queryClient.invalidateQueries("files");
        }
      }

      const messageDescription = await formatInteraction({
        files: resFiles?.response || [],
        messages,
      });

      const resMessages = await saveInteraction.mutateAsync({
        attendanceId: idAttendance,
        contact:
          localStorage.getItem("firstNamePanel") ||
          "" + localStorage.getItem("lastNamePanel") ||
          "",
        interactions: [messageDescription],
      });

      if (!resMessages?.data?.success) {
        errors.push(
          resMessages?.data?.message || "Não foi possível salvar a interação!"
        );
      } else {
        queryClient.invalidateQueries("messages");
      }

      return errors;
    },

    [queryClient, saveFiles, saveInteraction, updateTicket, values]
  );

  // -------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

  const contextValue = useMemo(
    () => ({
      handleChange,
      handleBlur,
      handleSubmit,
      setFieldValue,
      values,
      touched,
      errors,

      handleInputChange,
      fields,

      updateTicket,

      handleSave,

      setGeneralData,
    }),
    [
      handleChange,
      handleBlur,
      handleSubmit,
      setFieldValue,
      values,
      touched,
      errors,

      handleInputChange,
      fields,

      updateTicket,

      handleSave,

      setGeneralData,
    ]
  );

  return (
    <UseTicketContext.Provider value={contextValue}>
      {children}
    </UseTicketContext.Provider>
  );
};

const useTicket = () => {
  const context = useContext(UseTicketContext);

  if (!context)
    throw new Error("UseTicket must be used within an UseTicketProvider");

  return context;
};

export { UseTicketProvider, useTicket };
