import React, { useContext, useEffect, useState } from "react";

import {
  Checkbox,
  DialogTitle,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import { CloseOutlined, ListAltOutlined } from "@material-ui/icons";

import Loading from "../../../components/basics/Loading";
import TextFieldErp from "../../../components/basics/TextFieldErp";
import Btn from "../../../components/buttons/Btn";
import { GridStyled, LabelStyled } from "../../../styles/GlobalStyle";
import MaskService from "../../../utils/mask/maskService";
import { ModalRequestVisitContext } from "../contexts/ModalRequestVisitContext";
import useModalRequestVisitController from "../controllers/ModaRequestVisitController";
import {
  ContainerTitleRequestVisit,
  DialogContentRequestVisit,
  DialogRequestVisit,
  FormControlLabelRequestVisit,
} from "../styles/ModalRequestVisit";

export default function ModalRequestVisit({ data }) {
  const {
    name,
    setName,
    documentCustomer,
    setDocumentCustomer,
    email,
    setEmail,
    contactName,
    setContactName,
    contactPhone,
    setContactPhone,
    acceptTerms,
    setAcceptTerms,
    errors,
    setErrors,
    loading,
    modalVisitOpen,
    setModalVisitOpen,
  } = useContext(ModalRequestVisitContext);
  const { handleChangeEmail, handleSubmit } = useModalRequestVisitController();
  const [contactOption, setContactOption] = useState("qyon"); //"qyon" || "email"

  useEffect(() => {
    const doc = data?.document || "";
    setName(data?.company_name);
    setDocumentCustomer(
      MaskService.toMask(doc.length > 11 ? "cnpj" : "cpf", doc)
    );
    setEmail(data?.master_email || "");
    setContactName("");
    setContactPhone("");
    setAcceptTerms(true);
    setErrors({});
  }, [
    data,
    setAcceptTerms,
    setContactName,
    setContactPhone,
    setDocumentCustomer,
    setEmail,
    setErrors,
    setName,
  ]);

  const handleSelectContactOption = (event) =>
    setContactOption(event.target.value);

  return (
    <>
      <Loading open={loading} message={"Solicitando contato..."} />
      <DialogRequestVisit
        open={modalVisitOpen}
        maxWidth="xs"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        disableScrollLock
        onClose={() => setModalVisitOpen(false)}
      >
        <DialogTitle id="alert-dialog-title">
          <Grid container justify="flex-end">
            <IconButton onClick={() => setModalVisitOpen(false)}>
              <CloseOutlined />
            </IconButton>
          </Grid>
          <GridStyled container justify="center">
            <ContainerTitleRequestVisit>
              <Grid item flex={1}>
                <ListAltOutlined style={{ fontSize: 67 }} />
              </Grid>
              <Grid item xs>
                <GridStyled item xs={12}>
                  <LabelStyled
                    height="1.5rem"
                    color="black"
                    fontSize="25"
                    fontWeight={800}
                  >
                    DADOS DO MEU CLIENTE
                  </LabelStyled>
                </GridStyled>
                <GridStyled item xs={12} margin="-10px 0px 0px 0">
                  <LabelStyled
                    height="1.5rem"
                    color="blue"
                    fontSize="25"
                    fontWeight={600}
                  >
                    CONTATO COMERCIAL
                  </LabelStyled>
                </GridStyled>
              </Grid>
            </ContainerTitleRequestVisit>
          </GridStyled>
        </DialogTitle>

        <DialogContentRequestVisit>
          <form
            onSubmit={(e) => {
              handleSubmit(
                e,
                data?.id,
                contactOption === "email",
                contactOption === "qyon"
              );
            }}
          >
            <GridStyled padding="15" container spacing={2}>
              <Grid item xs={12}>
                <TextFieldErp
                  error={errors.name ? true : false}
                  helperText={errors.name || ""}
                  value={name}
                  disabled={true}
                  maxLength={50}
                  label="Nome*"
                  variant="outlined"
                />
              </Grid>

              <Grid item xs={12}>
                <TextFieldErp
                  error={errors.documentCustomer ? true : false}
                  helperText={errors.documentCustomer || ""}
                  value={documentCustomer}
                  disabled={true}
                  maxLength={50}
                  label="CNPJ/CPF*"
                  variant="outlined"
                />
              </Grid>

              <Grid item xs={12}>
                <TextFieldErp
                  error={errors.email ? true : false}
                  helperText={errors.email || ""}
                  value={email}
                  onChange={handleChangeEmail}
                  maxLength={50}
                  label="E-mail*"
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextFieldErp
                  error={errors.contactName ? true : false}
                  helperText={errors.contactName || ""}
                  value={contactName}
                  setValue={setContactName}
                  maxLength={50}
                  label="Contato*"
                  placeholder="Nome para contato"
                  variant="outlined"
                />
              </Grid>
              <Grid item md={6} xs={12}>
                <TextFieldErp
                  error={errors.contactPhone ? true : false}
                  helperText={errors.contactPhone || ""}
                  value={contactPhone}
                  setValue={setContactPhone}
                  mask="cel-phone"
                  label="Telefone Contato*"
                  placeholder="Ex.: (19)9999-9999"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12}>
                <RadioGroup
                  name="contactOptions"
                  value={contactOption}
                  onChange={handleSelectContactOption}
                >
                  <FormControlLabelRequestVisit
                    value="email"
                    control={<Radio color="primary" />}
                    label="Enviar o contato da Qyon para o e-mail do meu cliente para conhecer o Gestão Fácil (ERP)."
                  />
                  <FormControlLabelRequestVisit
                    value="qyon"
                    control={<Radio color="primary" />}
                    label="Solicito que a Qyon entre em contato com meu cliente para oferecer o Gestão Fácil (ERP)."
                  />
                </RadioGroup>
              </Grid>
              {contactOption === "qyon" && (
                <Grid item xs={12}>
                  <FormControlLabelRequestVisit
                    control={
                      <Checkbox
                        checked={acceptTerms}
                        onChange={(event) =>
                          setAcceptTerms(event.target.checked)
                        }
                        name="checkedB"
                        color="primary"
                      />
                    }
                    label={
                      "Estou ciente e autorizo o compartilhamento dos dados do meu cliente para que a Qyon realize o contato comercial sem compromisso."
                    }
                  />
                </Grid>
              )}

              {errors.acceptTerms && (
                <Grid item xs={12}>
                  <LabelStyled fontSize="12" color="red">
                    Campo obrigatório
                  </LabelStyled>
                </Grid>
              )}

              <GridStyled margin="10px 0px 0px 0" item xs={12}>
                <Btn label="Confirmar" bgColor="blue" full type="submit" />
              </GridStyled>
            </GridStyled>
          </form>
        </DialogContentRequestVisit>
      </DialogRequestVisit>
    </>
  );
}
