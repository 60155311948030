import { search } from "../../BaseRepositoryComponents";

const path = `${process.env.REACT_APP_PAINEL_API_URL}/api/v1/integrations/crm`;
const objTokenCognito = JSON.parse(localStorage.getItem("cognitoPanel"));
const headers = new Headers({
  Authorization: objTokenCognito && objTokenCognito.AccessToken,
  "Entity-Id": localStorage.getItem("companyIdPanel"),
  "Content-Type": "application/json",
  "qyon-customer-cnpj":
    localStorage.getItem("accountantCnpjPanel") ||
    localStorage.getItem("documentPanel"),
  "Content-Language": "pt-BR",
  isInClientView: sessionStorage.getItem("isInClientView") === "true",
  email: JSON.parse(sessionStorage.getItem("client"))
    ? JSON.parse(sessionStorage.getItem("client")).email
    : null,
});

export async function changeFavorite(fileId, starred) {
  return search({
    path: `${path}/starred`,
    method: "PUT",
    myBody: { fileId, starredType: 1, starred },
    myHeaders: headers,
  });
}

export async function findGuideReports({ typeFile }) {
  return await new Promise((resolve, reject) => {
    search({
      path: `${path}/files?typeFileKey=${typeFile}`,
      method: "GET",
      myHeaders: headers,
    })
      .then((res) => {
        if (res?.data?.files?.items) {
          res.data.files.items
            .filter((i) => !i.department)
            .map((i) => {
              i.department = {
                id: 0,
                name: "Outros",
              };
              return i;
            });
          resolve(res.data.files.items);
        } else {
          resolve([]);
        }
      })
      .catch(reject);
  });
}

export async function findDepartaments() {
  return await new Promise((resolve, reject) => {
    search({
      path: `${path}/departaments`,
      method: "GET",
      myHeaders: headers,
    })
      .then((res) => {
        if (res?.data?.departaments?.items) {
          res.data.departaments.items.push({
            id: 0,
            name: "Outros",
          });
          resolve(res.data.departaments.items);
        } else {
          resolve([]);
        }
      })
      .catch(reject);
  });
}
