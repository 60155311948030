import { useContext, useMemo } from "react";
import { GlobalVariables } from "../../GlobalVariables";
import { usePermissions } from "../../utils/permissionsHook";

// Components
import { Grid } from "@material-ui/core";

import Loading from "../../components/basics/Loading";
import TabCustom from "../../components/tabs/TabCustom";

import { TabAccountantCndSettings } from "./children/TabAccountantCndSettings";
import { TabCndAccountantRegister } from "./children/TabCndAccountantRegister";
import { TableTabCndAccountant } from "./children/TableTabCndAccountant";
import { TableTabCndCustomerList } from "./children/TableTabCndCustomerList";

// Styled Components
import { Margin, Title } from "../../styles/GlobalStyle";

import {
  CircularSaving,
  ContainerTab,
  FloatingSaving,
  TitleIcon,
} from "./styles/CndAccountant";

// Contexts
import { useEffect } from "react";
import { GlobalContext } from "../../contexts/GlobalContext";
import {
  CndAccountantContext,
  CndAccountantContextProvider,
} from "./contexts/CndAccountantContext";
import { TableTabCndRequest } from "./children/TableTabCndRequest";
import { TabAccountantCndEmail } from "./children/TabAccountantCndEmail";

const View = ({ props }) => {
  const { checkIfUserHasPermission } = usePermissions();
  const { tabIndex, setTabIndex, loading, saving } =
    useContext(CndAccountantContext);
  const { companyUser } = useContext(GlobalContext);

  const isStandaloneClient =
    !companyUser.isAccountant && !companyUser.accountantId;

  useEffect(() => {
    if (isStandaloneClient) setTabIndex(0);
  }, [isStandaloneClient, setTabIndex]);

  const hasEditionPermission = useMemo(
    () =>
      checkIfUserHasPermission(GlobalVariables.PERMISSIONS.CND_SOLICITATION),
    [checkIfUserHasPermission]
  );

  const tabs = [
    {
      label: "Cadastro",
      disabled: !hasEditionPermission,
      hide: isStandaloneClient,
      child: <TabCndAccountantRegister />,
    },
    {
      label: "Controle",
      hide: isStandaloneClient,
      child: <TableTabCndAccountant />,
    },
    {
      label: "Minhas CNDs",
      child: <TableTabCndCustomerList />,
    },
    {
      label: "Solicitar CNDs",
      child: <TableTabCndRequest />,
    },
    {
      label: "Configuração",
      disabled: !hasEditionPermission,
      child: <TabAccountantCndSettings />,
    },
    {
      label: "Email",
      disabled: !hasEditionPermission,
      child: <TabAccountantCndEmail />,
    },
  ];

  const headers = tabs
    .filter((tab) => !tab.hide)
    .map((tab) => ({
      label: tab.label,
      disabled: tab.disabled,
      hide: tab.disabled,
    }));

  const children = tabs.filter((tab) => !tab.hide).map((tab) => tab.child);

  return (
    <>
      <Loading open={Object.keys(loading).length > 0} />

      <Grid container>
        <Grid
          item
          container
          justifyContent="space-between"
          alignItems="flex-end"
        >
          <Grid item flex={1}>
            <Title color="blue">
              <TitleIcon
                primaryColor="#0a73db"
                secondaryColor="#e8ecf4"
                size="24"
              />
              CND - Painel de Controle
            </Title>
          </Grid>
        </Grid>

        <Margin top="10" />

        <Grid item xs={12}>
          <ContainerTab>
            <TabCustom
              children={children}
              headers={headers}
              tabIndex={tabIndex}
              onChange={(event, value) => {
                if (Number.isInteger(value)) {
                  setTabIndex(value);
                }
              }}
            />
          </ContainerTab>
        </Grid>
      </Grid>

      {saving && (
        <FloatingSaving>
          <CircularSaving /> Salvando ...
        </FloatingSaving>
      )}
    </>
  );
};

const CndAccountantList = ({ ...props }) => {
  return (
    <CndAccountantContextProvider>
      <View {...props} />
    </CndAccountantContextProvider>
  );
};

export { CndAccountantList };
