import { useContext } from "react";
import Swal from "sweetalert2";
import {
  deleteUser,
  postUser,
  putUser,
} from "../../../repositories/panel/v1/UserRepository";
import { notify } from "../../../utils/notify";
import { validNotNull } from "../../../utils/validations";
import { UserContext } from "../contexts/UserContext";

export default function useUserController() {
  const {
    //Users
    setUsersCostumer,
    setUserModalOpen,
    setUserModalIndex,
    id,
    firstName,
    lastName,
    email,
    profilesFilter,

    setLoading,
    setExpanded,
    setErrors,
  } = useContext(UserContext);

  const handleCloseUser = () => {
    setUserModalOpen(false);
    setUserModalIndex(-1);
  };

  const handleSaveUser = async () => {
    try {
      const valid = validNotNull([
        { value: firstName, key: "firstName" },
        { value: lastName, key: "lastName" },
        { value: email, key: "email" },
      ]);

      setErrors(valid.errorsValidation);
      if (valid.error) return;

      const body = {
        first_name: firstName,
        last_name: lastName,
        email,
        systems: profilesFilter.map((s) => s.id),
      };

      let respUser;

      if (id) {
        body.id = id;
        respUser = await putUser({ body: body, id: id });
      } else {
        respUser = await postUser({ body: body });
      }

      if (respUser?.success) {
        notify("Perfil atualizado com sucesso", true, "success");
        setUserModalOpen(false);
      }
      return;
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteUser = (id) => {
    Swal.fire({
      icon: "question",
      text: "Tem certeza que deseja remover este registro?",
      showConfirmButton: true,
      showCancelButton: true,
      cancelButtonText: "Cancelar",
      confirmButtonText: "Sim",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const respUser = await deleteUser({ id: id });
        if (respUser.success) {
          return notify("O usuário foi deletado!", true, "success");
        } else {
          return notify("Algo deu errado, tente novamente por favor!");
        }
      }
    });
  };

  const clearContext = () => {
    //Usuários
    setUsersCostumer([]);
    setUserModalOpen(false);
    setUserModalIndex(-1);

    setExpanded({ general: true });
    setErrors({});
  };

  const handleChangeOpenAcordion = (accordion) => {
    setExpanded((current) => ({
      ...current,
      [accordion]: !current[accordion],
    }));
  };

  return {
    //Functions
    handleSaveUser,
    handleCloseUser,
    handleDeleteUser,
    clearContext,
    handleChangeOpenAcordion,
  };
}
