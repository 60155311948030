// let url =
//   localStorage.getItem("environment") === "prod"
//     ? "https://api-cnd-hml.qyon.info/v1/entity/[PRINCIPAL_DOCUMENT]/email/[DOCUMENT]/cnd/[CND_ID]"
//     : "https://api-cnd.qyon.info/v1/entity/[PRINCIPAL_DOCUMENT]/email/[DOCUMENT]/cnd/[CND_ID]";

let url = "[URL_CND]";

export const CNDInconsistent = {
  counters: {
    u_column: 27,
    u_row: 16,
    u_content_heading: 11,
    u_content_image: 6,
    u_content_text: 2,
    u_content_divider: 2,
    u_content_button: 12,
    u_content_html: 1,
  },
  body: {
    id: "yc2Jnr3o8l",
    rows: [
      {
        id: "Cjg2lOhcRD",
        cells: [1],
        columns: [
          {
            id: "A9olqUCmj8",
            contents: [
              {
                id: "DM9ZjCS3oA",
                type: "image",
                values: {
                  containerPadding: "4px",
                  anchor: "",
                  src: {
                    url: "https://assets.unlayer.com/stock-templates1674494989043-Captura%20de%20tela_20230123_112015.png",
                    width: 199,
                    height: 89,
                    autoWidth: false,
                    maxWidth: "24%",
                  },
                  textAlign: "left",
                  altText: "",
                  action: {
                    name: "web",
                    values: {
                      href: "",
                      target: "_blank",
                    },
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: "u_content_image_3",
                    htmlClassNames: "u_content_image",
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                },
              },
            ],
            values: {
              backgroundColor: "#ced4d9",
              padding: "0px",
              border: {},
              borderRadius: "0px",
              _meta: {
                htmlID: "u_column_9",
                htmlClassNames: "u_column",
              },
            },
          },
        ],
        values: {
          displayCondition: null,
          columns: false,
          backgroundColor: "",
          columnsBackgroundColor: "",
          backgroundImage: {
            url: "",
            fullWidth: true,
            repeat: "no-repeat",
            size: "custom",
            position: "center",
          },
          padding: "0px",
          anchor: "",
          hideDesktop: false,
          _meta: {
            htmlID: "u_row_7",
            htmlClassNames: "u_row",
          },
          selectable: true,
          draggable: true,
          duplicatable: true,
          deletable: true,
          hideable: true,
        },
      },
      {
        id: "M7C6RgtKBx",
        cells: [1],
        columns: [
          {
            id: "evc9wcQWky",
            contents: [
              {
                id: "QaomOHKat9",
                type: "text_deprecated",
                values: {
                  containerPadding: "10px 7px 10px 34px",
                  anchor: "",
                  textAlign: "left",
                  lineHeight: "170%",
                  linkStyle: {
                    inherit: true,
                    linkColor: "#0000ee",
                    linkHoverColor: "#0000ee",
                    linkUnderline: true,
                    linkHoverUnderline: true,
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: "u_content_text_1",
                    htmlClassNames: "u_content_text",
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                  text: '<p style="font-size: 14px; line-height: 170%;"> </p>\n<p style="font-size: 14px; line-height: 170%;"><span style="font-size: 22px; line-height: 37.4px; color: #f2a23c;"><strong>Nova CND </strong>Disponível</span></p>\n<p style="font-size: 14px; line-height: 170%;"> </p>\n<p style="font-size: 14px; line-height: 170%;"><span style="font-size: 16px; line-height: 27.2px; color: #000000;"><strong>[NOME_CLIENTE],</strong></span></p>\n<p style="font-size: 14px; line-height: 170%;"> </p>\n<p style="font-size: 14px; line-height: 170%;"><span style="font-size: 14px; line-height: 23.8px;"><span style="line-height: 23.8px; color: #000000; font-size: 14px;"><span style="line-height: 23.8px; font-size: 14px;">Uma nova CND foi emitida para sua empresa na <strong>Plataforma Qyon</strong>. Para visualizar a CND, <span style="color: #155aff; line-height: 23.8px; font-size: 14px;"><span style="line-height: 23.8px; font-size: 14px;"><strong>clique no botão abaixo</strong></span>.</span></span><span style="color: #155aff; line-height: 23.8px; font-size: 14px;"><strong> </strong></span></span></span></p>\n<p style="font-size: 14px; line-height: 170%;"> </p>',
                },
              },
            ],
            values: {
              backgroundColor: "",
              padding: "0px",
              border: {},
              _meta: {
                htmlID: "u_column_1",
                htmlClassNames: "u_column",
              },
            },
          },
        ],
        values: {
          displayCondition: null,
          columns: false,
          backgroundColor: "",
          columnsBackgroundColor: "",
          backgroundImage: {
            url: "",
            fullWidth: true,
            repeat: "no-repeat",
            size: "custom",
            position: "center",
          },
          padding: "0px",
          anchor: "",
          hideDesktop: false,
          _meta: {
            htmlID: "u_row_1",
            htmlClassNames: "u_row",
          },
          selectable: true,
          draggable: true,
          duplicatable: true,
          deletable: true,
          hideable: true,
        },
      },
      {
        id: "7yMlFW3asB",
        cells: [16, 35.77, 48.23],
        columns: [
          {
            id: "hnx9jin6j4",
            contents: [
              {
                id: "1b1cE7ony_",
                type: "image",
                values: {
                  containerPadding: "9px 0px 0px 8px",
                  anchor: "",
                  src: {
                    url: "https://assets.unlayer.com/stock-templates1674503160671-Inconsistente-removebg-preview.png",
                    width: 444,
                    height: 401,
                    autoWidth: false,
                    maxWidth: "100%",
                  },
                  textAlign: "center",
                  altText: "",
                  action: {
                    name: "web",
                    values: {
                      href: "",
                      target: "_blank",
                    },
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: "u_content_image_6",
                    htmlClassNames: "u_content_image",
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                  _override: {
                    mobile: {
                      containerPadding: "0px",
                      src: {
                        autoWidth: true,
                        maxWidth: "50%",
                      },
                    },
                  },
                },
              },
            ],
            values: {
              backgroundColor: "#f2f2f2",
              padding: "6px 0px 0px",
              border: {
                borderTopWidth: "0px",
                borderTopStyle: "solid",
                borderTopColor: "#CCC",
                borderLeftWidth: "33px",
                borderLeftStyle: "solid",
                borderLeftColor: "#ffffff",
                borderRightWidth: "0px",
                borderRightStyle: "solid",
                borderRightColor: "#CCC",
                borderBottomWidth: "0px",
                borderBottomStyle: "solid",
                borderBottomColor: "#CCC",
              },
              borderRadius: "0px",
              _meta: {
                htmlID: "u_column_25",
                htmlClassNames: "u_column",
              },
              _override: {
                mobile: {
                  backgroundColor: "",
                  padding: "0px",
                  border: {
                    borderTopWidth: "0px",
                    borderTopStyle: "solid",
                    borderTopColor: "#CCC",
                    borderLeftWidth: "33px",
                    borderLeftStyle: "solid",
                    borderLeftColor: "#f2f2f2",
                    borderRightWidth: "0px",
                    borderRightStyle: "solid",
                    borderRightColor: "#CCC",
                    borderBottomWidth: "0px",
                    borderBottomStyle: "solid",
                    borderBottomColor: "#CCC",
                  },
                },
              },
            },
          },
          {
            id: "CNVvkBtMjv",
            contents: [
              {
                id: "GIFT1-m1up",
                type: "heading",
                values: {
                  containerPadding: "14px 13px 10px 17px",
                  anchor: "",
                  headingType: "h4",
                  fontSize: "14px",
                  textAlign: "left",
                  lineHeight: "140%",
                  linkStyle: {
                    inherit: true,
                    linkColor: "#0000ee",
                    linkHoverColor: "#0000ee",
                    linkUnderline: true,
                    linkHoverUnderline: true,
                    body: false,
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: "u_content_heading_11",
                    htmlClassNames: "u_content_heading",
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                  text: "<strong>CND</strong>:  [NOME_CND]<br /><strong>Status</strong>:  [STATUS]",
                },
              },
            ],
            values: {
              backgroundColor: "#f2f2f2",
              padding: "0px",
              border: {
                borderTopWidth: "0px",
                borderTopStyle: "solid",
                borderLeftWidth: "0px",
                borderLeftStyle: "solid",
                borderRightWidth: "0px",
                borderRightStyle: "solid",
                borderBottomWidth: "0px",
                borderBottomStyle: "solid",
              },
              borderRadius: "0px",
              _meta: {
                htmlID: "u_column_26",
                htmlClassNames: "u_column",
              },
            },
          },
          {
            id: "yU8fT4ler_",
            contents: [
              {
                id: "jkvzwQA33s",
                type: "button",
                values: {
                  containerPadding: "10px",
                  anchor: "",
                  href: {
                    name: "web",
                    values: {
                      href: url,
                      target: "_blank",
                    },
                    attrs: {
                      href: "{{href}}",
                      target: "{{target}}",
                    },
                  },
                  buttonColors: {
                    color: "#FFFFFF",
                    backgroundColor: "#155aff",
                    hoverColor: "#FFFFFF",
                    hoverBackgroundColor: "#3AAEE0",
                  },
                  size: {
                    autoWidth: true,
                    width: "100%",
                  },
                  fontSize: "14px",
                  textAlign: "center",
                  lineHeight: "100%",
                  padding: "10px 20px",
                  border: {},
                  borderRadius: "4px",
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: "u_content_button_12",
                    htmlClassNames: "u_content_button",
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                  text: "Visualizar o documento na <strong>Plataforma Qyon</strong>",
                  calculatedWidth: 248,
                  calculatedHeight: 34,
                },
              },
            ],
            values: {
              backgroundColor: "#f2f2f2",
              padding: "6px 0px 0px",
              border: {
                borderTopWidth: "0px",
                borderTopStyle: "solid",
                borderTopColor: "#CCC",
                borderLeftWidth: "0px",
                borderLeftStyle: "solid",
                borderLeftColor: "#CCC",
                borderRightWidth: "31px",
                borderRightStyle: "solid",
                borderRightColor: "#ffffff",
                borderBottomWidth: "0px",
                borderBottomStyle: "solid",
                borderBottomColor: "#CCC",
              },
              borderRadius: "0px",
              _meta: {
                htmlID: "u_column_27",
                htmlClassNames: "u_column",
              },
              _override: {
                mobile: {
                  border: {
                    borderTopWidth: "0px",
                    borderTopStyle: "solid",
                    borderTopColor: "#CCC",
                    borderLeftWidth: "0px",
                    borderLeftStyle: "solid",
                    borderLeftColor: "#CCC",
                    borderRightWidth: "0px",
                    borderRightStyle: "solid",
                    borderRightColor: "#ffffff",
                    borderBottomWidth: "0px",
                    borderBottomStyle: "solid",
                    borderBottomColor: "#CCC",
                  },
                },
              },
            },
          },
        ],
        values: {
          displayCondition: null,
          columns: false,
          backgroundColor: "",
          columnsBackgroundColor: "",
          backgroundImage: {
            url: "",
            fullWidth: true,
            repeat: "no-repeat",
            size: "custom",
            position: "center",
          },
          padding: "0px",
          anchor: "",
          hideDesktop: false,
          _meta: {
            htmlID: "u_row_16",
            htmlClassNames: "u_row",
          },
          selectable: true,
          draggable: true,
          duplicatable: true,
          deletable: true,
          hideable: true,
        },
      },
      {
        id: "PZdcqfVHqJ",
        cells: [1],
        columns: [
          {
            id: "OPaJwjDf2r",
            contents: [
              {
                id: "A4s2PQL-W6",
                type: "heading",
                values: {
                  containerPadding: "16px 13px 13px",
                  anchor: "",
                  headingType: "h4",
                  fontFamily: {
                    label: "Global Font",
                    value: "inherit",
                  },
                  fontSize: "14px",
                  textAlign: "left",
                  lineHeight: "160%",
                  linkStyle: {
                    inherit: true,
                    linkColor: "#0000ee",
                    linkHoverColor: "#0000ee",
                    linkUnderline: true,
                    linkHoverUnderline: true,
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: "u_content_heading_6",
                    htmlClassNames: "u_content_heading",
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                  text: "<em>Obrigado!</em><br /><em>QYON Systemas Inteligentes</em>",
                },
              },
            ],
            values: {
              backgroundColor: "",
              padding: "0px 0px 0px 22px",
              border: {
                borderTopWidth: "0px",
                borderTopStyle: "solid",
                borderLeftWidth: "0px",
                borderLeftStyle: "solid",
                borderRightWidth: "0px",
                borderRightStyle: "solid",
                borderRightColor: "#CCC",
                borderBottomWidth: "0px",
                borderBottomStyle: "solid",
                borderBottomColor: "#CCC",
              },
              borderRadius: "0px",
              _meta: {
                htmlID: "u_column_10",
                htmlClassNames: "u_column",
              },
              _override: {
                mobile: {
                  padding: "1px 3px 0px 13px",
                  border: {
                    borderTopWidth: "0px",
                    borderTopStyle: "solid",
                    borderTopColor: "#CCC",
                    borderLeftWidth: "0px",
                    borderLeftStyle: "solid",
                    borderLeftColor: "#CCC",
                    borderRightWidth: "0px",
                    borderRightStyle: "solid",
                    borderRightColor: "#CCC",
                    borderBottomWidth: "0px",
                    borderBottomStyle: "solid",
                    borderBottomColor: "#CCC",
                  },
                },
              },
            },
          },
        ],
        values: {
          displayCondition: null,
          columns: false,
          backgroundColor: "",
          columnsBackgroundColor: "",
          backgroundImage: {
            url: "",
            fullWidth: true,
            repeat: "no-repeat",
            size: "custom",
            position: "center",
          },
          padding: "0px",
          anchor: "",
          hideDesktop: false,
          _meta: {
            htmlID: "u_row_8",
            htmlClassNames: "u_row",
          },
          selectable: true,
          draggable: true,
          duplicatable: true,
          deletable: true,
          hideable: true,
        },
      },
    ],
    values: {
      popupPosition: "center",
      popupWidth: "600px",
      popupHeight: "auto",
      borderRadius: "10px",
      contentAlign: "center",
      contentVerticalAlign: "center",
      contentWidth: 540,
      fontFamily: {
        label: "Arial",
        value: "arial,helvetica,sans-serif",
      },
      textColor: "#000000",
      popupBackgroundColor: "#FFFFFF",
      popupBackgroundImage: {
        url: "",
        fullWidth: true,
        repeat: "no-repeat",
        size: "cover",
        position: "center",
      },
      popupOverlay_backgroundColor: "rgba(0, 0, 0, 0.1)",
      popupCloseButton_position: "top-right",
      popupCloseButton_backgroundColor: "#DDDDDD",
      popupCloseButton_iconColor: "#000000",
      popupCloseButton_borderRadius: "0px",
      popupCloseButton_margin: "0px",
      popupCloseButton_action: {
        name: "close_popup",
        attrs: {
          onClick:
            "document.querySelector('.u-popup-container').style.display = 'none';",
        },
      },
      backgroundColor: "#ffffff",
      backgroundImage: {
        url: "",
        fullWidth: true,
        repeat: "no-repeat",
        size: "custom",
        position: "center",
      },
      preheaderText: "",
      linkStyle: {
        body: true,
        linkColor: "#0000ee",
        linkHoverColor: "#0000ee",
        linkUnderline: true,
        linkHoverUnderline: true,
      },
      _meta: {
        htmlID: "u_body",
        htmlClassNames: "u_body",
      },
    },
  },
  schemaVersion: 12,
};
