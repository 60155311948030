import BaseMask from "./_base.mask";

const CNPJ_MASK = "99.999.999/9999-99";

const validateCnpj = (cnpj = "") => {
  const exp = /\.|-|\//g;
  cnpj = cnpj.toString().replace(exp, "");
  return cnpj.length === 14;
};

export class CnpjMask extends BaseMask {
  static getType() {
    return "cnpj";
  }

  getValue(value, settings) {
    return this.getVMasker().toPattern(value, CNPJ_MASK);
  }

  getRawValue(maskedValue, settings) {
    return super.removeNotNumbers(maskedValue);
  }

  validate(value, settings) {
    return validateCnpj(value);
  }
}
