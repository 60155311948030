const getApi = async ({ path, token, cnpj, customerCnpj }) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_CRM_API_URL}${path}`,
      {
        method: "get",
        headers: {
          Authorization: token,
          "qyon-customer-cnpj": cnpj,
          "customer-cnpj": customerCnpj || "",
          isInClientView: sessionStorage.getItem("isInClientView") === "true",
          email: sessionStorage.getItem("client")
            ? JSON.parse(sessionStorage.getItem("client")).email
            : null,
        },
      }
    );
    const result = await response.json();
    return result;
  } catch (error) {
    console.error("error ", error);
  }
};

const optionsList = (arr, aditionalObj) => {
  try {
    let finalArray = [];
    if (!arr) return [];

    arr?.forEach((item) => {
      finalArray.push({
        title:
          item.description || item.socialName || item.fantasyName || item.name,
        value: item.attendanceSituationId || item.id || item.productId,
        color: item?.color || null,
      });
    });

    if (aditionalObj) {
      finalArray.push(aditionalObj);
    }

    return finalArray;
  } catch (error) {
    console.error("Error:", error);
    return [];
  }
};

export { getApi, optionsList };
