import { useCallback, useContext } from "react";

import { GlobalContext } from "../../../contexts/GlobalContext";
import { CndAccountantContext } from "../contexts/CndAccountantContext";

import {
  getCustomersEntityNew,
  getEntityByDocument,
} from "../../../repositories/panel/v1/EntityRepository";
import * as rpaAgendaRepository from "../../../repositories/panel/v1/integrations/qyonMsRpa/agenda";
import * as rpaEntityRepository from "../../../repositories/panel/v1/integrations/qyonMsRpa/entity";
import * as rpaGroupRepository from "../../../repositories/panel/v1/integrations/qyonMsRpa/group";
import * as rpaTypeRepository from "../../../repositories/panel/v1/integrations/qyonMsRpa/type";

import Swal from "sweetalert2";
import { GlobalVariables } from "../../../GlobalVariables";
import { notify } from "../../../utils/notify";
import { RpaStatus } from "../../../utils/RpaStatus";
import { validNotNull } from "../../../utils/validations";
import { usePortalAsClient } from "../../../utils/viewPortalAsClientHook";

export function useCndAccountantController() {
  const { companyUser } = useContext(GlobalContext);
  const {
    setLoading,
    setSaving,

    sendMailPositiveFlag,
    setSendMailPositiveFlag,
    sendMailNegativeFlag,
    setSendMailNegativeFlag,
    sendMailPositiveNegativeFlag,
    setSendMailPositiveNegativeFlag,
    clientAllowedPositiveFlag,
    setClientAllowedPositiveFlag,
    clientAllowedNegativeFlag,
    setClientAllowedNegativeFlag,
    clientAllowedPositiveNegativeFlag,
    setClientAllowedPositiveNegativeFlag,
    clientAllowedOthersFlag,
    setClientAllowedOthersFlag,

    allClients,
    setAllclients,
    registeredClientes,
    setRegisteredClientes,
    setNotRegisteredClientes,
    registeredClientesEdited,

    agendas,
    setAgendas,

    setLIstCNDsActiveInactive,

    requestedCnd,
    setRequestedCnd,

    group,
    cnd,

    setGroupOptionsJson,
    editedGroupOptions,
    setEditedGroupOptions,

    manualUploadedCnd,
    setManualUploadedCnd,
    manualIssueDate,
    setManualIssueDate,
    manualDueDate,
    setManualDueDate,
    manualStatus,
    setManualStatus,
    cndToUpload,
    setCndToUpload,
    clientCndToUpload,
    setClientCndToUpload,
    setErrors,
    cndTypes,
    setCndTypes,
    editedAgendasIds,
    setEditedAgendasIds,
    setLastProcessing,
  } = useContext(CndAccountantContext);
  const { isInClientView, clientViewed } = usePortalAsClient();

  const getAllClients = useCallback(async () => {
    try {
      setLoading((prev) => ({ ...prev, getAllClients: true }));

      const response = await getCustomersEntityNew({
        id: isInClientView ? clientViewed?.company : companyUser.companyId,
      });

      if (response.success && Array.isArray(response.data)) {
        setAllclients(
          response.data.filter((row) => row.entity_type === "legal")
        );
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading((prev) => {
        let prevLoading = { ...prev };
        delete prevLoading.getAllClients;
        return prevLoading;
      });
    }
  }, [
    companyUser,
    setAllclients,
    setLoading,
    clientViewed?.company,
    isInClientView,
  ]);

  const getGroupEntities = useCallback(
    async (id) => {
      try {
        setLoading((prev) => ({ ...prev, getGroupEntities: true }));

        const callback = await rpaGroupRepository.getEntities({
          id: id,
          document: companyUser.document,
        });

        if (callback?.success && Array.isArray(callback?.data)) {
          return callback.data;
        } else {
          return [];
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading((prev) => {
          let prevLoading = { ...prev };
          delete prevLoading.getGroupEntities;
          return prevLoading;
        });
      }
    },
    [setLoading]
  );

  const getAgendaEntities = useCallback(
    async (id) => {
      try {
        setLoading((prev) => ({ ...prev, getAgendaEntities: true }));

        const callback = await rpaAgendaRepository.getEntitiesAgenda({
          id: id,
        });

        if (callback?.success && Array.isArray(callback?.data)) {
          return callback.data;
        } else {
          return [];
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading((prev) => {
          let prevLoading = { ...prev };
          delete prevLoading.getAgendaEntities;
          return prevLoading;
        });
      }
    },
    [setLoading]
  );

  const clearRegisteredClients = useCallback(() => {
    setNotRegisteredClientes([]);
    setRegisteredClientes([]);
  }, [setNotRegisteredClientes, setRegisteredClientes]);

  const splitRegisteredClients = useCallback(
    (registeredDocuments) => {
      const registered = allClients.filter((client) =>
        registeredDocuments.includes(client.document)
      );
      setRegisteredClientes(
        registered.sort((a, b) =>
          a.company_name > b.company_name
            ? 1
            : b.company_name > a.company_name
            ? -1
            : 0
        )
      );

      const notRegistered = allClients.filter(
        (client) => !registeredDocuments.includes(client.document)
      );
      setNotRegisteredClientes(
        notRegistered.sort((a, b) =>
          a.company_name > b.company_name
            ? 1
            : b.company_name > a.company_name
            ? -1
            : 0
        )
      );
    },
    [allClients, setNotRegisteredClientes, setRegisteredClientes]
  );

  const getAccountantCndSettings = useCallback(async () => {
    try {
      setLoading((prev) => ({ ...prev, getAccountantCndSettings: true }));
      const callback = await rpaEntityRepository.getEntityConfig({
        document: companyUser.document,
      });

      if (callback.success && callback.data) {
        setSendMailPositiveFlag(callback.data.send_positives_mail || false);
        setSendMailNegativeFlag(callback.data.send_negatives_mail || false);
        setSendMailPositiveNegativeFlag(
          callback.data.send_positives_negatives_mail || false
        );
        setClientAllowedPositiveFlag(
          callback.data.clients_can_see_positives || false
        );
        setClientAllowedNegativeFlag(
          callback.data.clients_can_see_negatives || false
        );
        setClientAllowedPositiveNegativeFlag(
          callback.data.clients_can_see_positives_negatives || false
        );
        setClientAllowedOthersFlag(
          callback.data.clients_can_see_others || false
        );
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading((prev) => {
        let prevLoading = { ...prev };
        delete prevLoading.getAccountantCndSettings;
        return prevLoading;
      });
    }
  }, [
    setLoading,
    companyUser.document,
    setSendMailPositiveFlag,
    setSendMailNegativeFlag,
    setSendMailPositiveNegativeFlag,
    setClientAllowedPositiveFlag,
    setClientAllowedNegativeFlag,
    setClientAllowedPositiveNegativeFlag,
    setClientAllowedOthersFlag,
  ]);

  const saveAccountantCndGeneralSettings = async () => {
    try {
      setSaving(true);

      const body = {
        send_positives_mail: sendMailPositiveFlag,
        send_negatives_mail: sendMailNegativeFlag,
        send_positives_negatives_mail: sendMailPositiveNegativeFlag,
        clients_can_see_positives: clientAllowedPositiveFlag,
        clients_can_see_negatives: clientAllowedNegativeFlag,
        clients_can_see_positives_negatives: clientAllowedPositiveNegativeFlag,
        clients_can_see_others: clientAllowedOthersFlag,
      };

      await rpaAgendaRepository.putEmailConfig({
        body,
        document: companyUser.document,
      });
    } catch (error) {
      console.error(error);
    } finally {
      setSaving(false);
    }
  };

  const storeEntity = useCallback(async () => {
    try {
      const accountant = await getEntityByDocument(companyUser.document);

      const callback = await rpaEntityRepository.post({
        body: {
          entity: {
            document: companyUser.document,
            name: companyUser.companyName,
            mail: accountant.data.email || companyUser.masterUserEmail || "",
            ie: accountant.data.ie,
            im: accountant.data.im,
            ccm: accountant.data.ccm,
            send_positives_mail: true,
            send_negatives_mail: true,
            clients_can_see_positives: true,
            clients_can_see_negatives: true,
            clients_can_see_positives_negatives: true,
            clients_can_see_others: true,
            responsable_person_document:
              accountant.data.responsable_person_document,
          },
          loadDefaults: ["CND"],
        },
      });

      return callback;
    } catch (error) {
      console.error(error);
      return { success: false };
    }
  }, [
    companyUser.companyName,
    companyUser.document,
    companyUser.masterUserEmail,
  ]);

  const getRequestedSchedulledExecutions = useCallback(async () => {
    try {
      setLoading((prev) => ({
        ...prev,
        getRequestedSchedulledExecutions: true,
      }));
      const callback =
        await rpaEntityRepository.getRequestedSchedulledExecutions({
          document: companyUser.document,
        });
      if (callback.success) {
        const requested = callback.data || [];

        setRequestedCnd(
          requested.sort((a, b) =>
            a.name > b.name ? 1 : b.name > a.name ? -1 : 0
          )
        );
      } else if (callback.message === "Entity not found") {
        await storeEntity();
        setRequestedCnd([]);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading((prev) => {
        let prevLoading = { ...prev };
        delete prevLoading.getRequestedSchedulledExecutions;
        return prevLoading;
      });
    }
  }, [companyUser.document, setLoading, setRequestedCnd, storeEntity]);

  const getRequestedSchedulledExecutionsDetails = async (clientDocument) => {
    try {
      const callback = await rpaEntityRepository.getCndFromClient({
        clientDocument: clientDocument,
        document: companyUser.document,
      });

      if (callback.success) {
        return callback;
      }
      if (!callback.success && callback.message === "Check the client cnpj") {
        notify(
          "O CNPJ está incorreto. Verifique e tente novamente!",
          true,
          "error"
        );
      }
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const getEntityAgendas = useCallback(async () => {
    try {
      setLoading((prev) => ({ ...prev, getEntityAgendas: true }));
      const callback = await rpaTypeRepository.getNew();

      if (callback.success) {
        setAgendas(callback.data || []);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading((prev) => {
        let prevLoading = { ...prev };
        delete prevLoading.getEntityAgendas;
        return prevLoading;
      });
    }
  }, [setLoading, setAgendas]);

  const getEntityAgendasSettings = useCallback(async () => {
    try {
      setLoading((prev) => ({ ...prev, getEntityAgendas: true }));
      const callback = await rpaEntityRepository.getAgendas({
        document: companyUser.document,
      });

      if (callback.success) {
        setAgendas(callback.data || []);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading((prev) => {
        let prevLoading = { ...prev };
        delete prevLoading.getEntityAgendas;
        return prevLoading;
      });
    }
  }, [setLoading, setAgendas, companyUser.document]);

  const getListActiveInact = useCallback(async () => {
    try {
      setLoading((prev) => ({ ...prev, getListActiveInact: true }));
      const callback = await rpaEntityRepository.listCNDsActiveInactive({
        document: companyUser.document,
      });

      if (callback.status) {
        setLIstCNDsActiveInactive(callback.data || []);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading((prev) => {
        let prevLoading = { ...prev };
        delete prevLoading.getListActiveInact;
        return prevLoading;
      });
    }
  }, [setLoading, companyUser.document, setLIstCNDsActiveInactive]);

  const getEntityGroups = useCallback(async () => {
    try {
      setLoading((prev) => ({ ...prev, getEntityGroups: true }));
      const callback = await rpaEntityRepository.getGroups({
        document: companyUser.document,
      });

      if (callback.success && callback.data) {
        setGroupOptionsJson(JSON.stringify(callback.data));
        setEditedGroupOptions(callback.data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading((prev) => {
        let prevLoading = { ...prev };
        delete prevLoading.getEntityGroups;
        return prevLoading;
      });
    }
  }, [
    companyUser.document,
    setEditedGroupOptions,
    setGroupOptionsJson,
    setLoading,
  ]);

  const handleRequestCnd = useCallback(
    async ({
      parentDocument,
      clientDocument,
      agendaId,
      setListCnds,
      listCnds,
    }) => {
      try {
        setLoading((prev) => ({ ...prev, handleRequestCnd: true }));

        const body = agendaId ? { schedules: [agendaId] } : { schedules: [] };

        const response = await rpaEntityRepository.refreshAgenda({
          parentDocument,
          clientDocument,
          body,
        });

        if (!response.success)
          return notify("Não foi possível obter os dados.");

        const copy = [...listCnds];
        copy.forEach((cnd, cndIndex) => {
          if (cnd.agendaID === agendaId || agendaId === null) {
            copy[cndIndex].expirationDate = null;
            copy[cndIndex].issuanceDate = null;
            copy[cndIndex].status = "Processando";
          }
        });
        listCnds = copy;
        notify("CNDs solicitadas com sucesso!", true, "success");
      } catch (error) {
        console.error(error);
      } finally {
        setLoading((prev) => {
          let prevLoading = { ...prev };
          delete prevLoading.handleRequestCnd;
          return prevLoading;
        });
      }
    },
    [setLoading]
  );

  const handleClose = useCallback(() => {
    setManualIssueDate("");
    setManualDueDate("");
    setManualStatus(null);
    setManualUploadedCnd([]);

    setCndToUpload({});
    setClientCndToUpload(null);
    setErrors({});
  }, [
    setClientCndToUpload,
    setCndToUpload,
    setManualDueDate,
    setManualIssueDate,
    setManualStatus,
    setManualUploadedCnd,
    setErrors,
  ]);

  function convertToBase64(fileToConvert) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(fileToConvert);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  const handleSubmitCnd = useCallback(async () => {
    const validRequired = validNotNull([
      { value: manualIssueDate, key: "manualIssueDate" },
      { value: manualDueDate, key: "manualDueDate" },
      { value: manualStatus, key: "manualStatus" },
      { value: manualUploadedCnd.length, key: "manualUploadedCnd" },
    ]);

    setErrors(validRequired.errorsValidation);
    if (validRequired.error) return;

    try {
      setLoading((prev) => ({ ...prev, handleSubmitCnd: true }));
      const fileInBase64 = await convertToBase64(
        manualUploadedCnd[0].file
      ).then((data) => {
        return data;
      });

      const body = {};

      if (fileInBase64.includes("base64")) {
        body.base64_file = fileInBase64;
      }

      if (!body.base64_file)
        return notify("Arquivo corrompido, insira um arquivo válido.");

      const fileType = manualUploadedCnd[0].file.type.substring(
        manualUploadedCnd[0].file.type.split("").findIndex((e) => e === "/") + 1
      );

      const document = clientCndToUpload.companyDocument;
      const agendaId = cndToUpload?.last_schedulled_execution?.agenda_id;

      body.status_id = manualStatus.value;
      body.file_type = fileType;
      body.issue_date = manualIssueDate;
      body.due_date = manualDueDate;

      const response = await rpaEntityRepository.manualUpload({
        document,
        agendaId,
        body,
      });

      if (!response.success) {
        return Swal.fire({
          icon: "error",
          text: "Falha no upload da CND!",
          showConfirmButton: false,
          timer: 2500,
        });
      }

      const copy = [...requestedCnd];
      copy.forEach((cliente, clientIndex) => {
        if (cliente.document === document) {
          cliente.agendas.forEach((agenda, agendaIndex) => {
            if (agendaId === agenda.id) {
              copy[clientIndex].agendas[
                agendaIndex
              ].last_schedulled_execution.issue_date = manualIssueDate;
              copy[clientIndex].agendas[
                agendaIndex
              ].last_schedulled_execution.due_date = manualDueDate;
              copy[clientIndex].agendas[
                agendaIndex
              ].last_schedulled_execution.status_id = manualStatus.value;
              copy[clientIndex].agendas[
                agendaIndex
              ].last_schedulled_execution.status = {
                id: manualStatus.value,
                description: Object.values(RpaStatus).filter((status) => {
                  return status.id === manualStatus.value;
                })[0].description,
              };
            }
          });
        }
      });
      setRequestedCnd(copy);

      Swal.fire({
        icon: "success",
        text: "Upload da CND concluído",
        showConfirmButton: false,
        timer: 2500,
      });
    } catch (error) {
      console.error(error);
    } finally {
      setLoading((prev) => {
        let prevLoading = { ...prev };
        delete prevLoading.handleSubmitCnd;
        return prevLoading;
      });
      handleClose();
    }
  }, [
    clientCndToUpload,
    cndToUpload,
    manualDueDate,
    manualIssueDate,
    manualStatus,
    manualUploadedCnd,
    setErrors,
    setLoading,
    handleClose,
    requestedCnd,
    setRequestedCnd,
  ]);

  const getCndTypes = useCallback(async () => {
    try {
      setLoading((prev) => ({ ...prev, getCndTypes: true }));

      const response = await rpaTypeRepository.getNew();

      if (response?.success && Array.isArray(response?.data)) {
        setCndTypes(response?.data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading((prev) => {
        let prevLoading = { ...prev };
        delete prevLoading.getCndTypes;
        return prevLoading;
      });
    }
  }, [setCndTypes, setLoading]);

  const storeAgenda = useCallback(
    async (row) => {
      try {
        setLoading((prev) => ({ ...prev, storeAgenda: true }));

        const currentDate = new Date();
        const formattedDate = `${currentDate.getFullYear()}-${
          currentDate.getMonth() + 1
        }-${currentDate.getDate()}`;

        const body = {
          owner_entity_document: companyUser.document,
          type_id: row.id,
          auto: row.hasAuto,
          start_date: formattedDate,
          antecedence_days: 5,
          request_own_schedule: false,
        };

        const response = await rpaAgendaRepository.postNew({ body });

        if (!response.success) {
          return notify("Não foi possível ativar a CND!", true);
        }

        const agendaCopy = [...agendas];

        agendaCopy.push(response.data);
        setAgendas(agendaCopy);

        notify("CND ativada com sucesso", true, "success");
      } catch (error) {
        console.error(error);
      } finally {
        setLoading((prev) => {
          let prevLoading = { ...prev };
          delete prevLoading.storeAgenda;
          return prevLoading;
        });
      }
    },
    [agendas, companyUser.document, setAgendas, setLoading, cndTypes]
  );

  const updateAgenda = useCallback(async () => {
    try {
      setSaving(true);

      Promise.all(
        editedAgendasIds.map((id) => {
          const agenda = agendas.find((a) => a.id === id);
          const body = {
            auto: agenda.auto,
            antecedence_days: agenda.antecedence_days || 5,
            request_own_schedule: agenda.request_own_schedule,
          };
          return rpaAgendaRepository.put({
            id,
            body,
          });
        })
      ).then((values) => {
        setEditedAgendasIds([]);
        const errors = values.filter((value) => !value.success);

        if (errors.length > 0) {
          getEntityAgendas();
          return notify("Não foi possível atualizar as CNDs");
        }

        notify("CNDs atualizadas com sucesso", true, "success");
      });
    } catch (error) {
      console.error(error);
    } finally {
      setSaving(false);
    }
  }, [
    agendas,
    editedAgendasIds,
    getEntityAgendas,
    setEditedAgendasIds,
    setSaving,
  ]);

  const destroyAgenda = useCallback(
    async (id) => {
      Swal.fire({
        title: "ATENÇÃO",
        html: "Ao desativar a CND todos os processamentos relacionados serão perdidos! <br>Tem certeza que deseja executar a ação?",

        showCancelButton: true,
        confirmButtonText: `Sim, desativar`,
        cancelButtonText: `Não, cancelar`,
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            setLoading((prev) => ({ ...prev, destroyAgenda: true }));

            const response = await rpaAgendaRepository.destroyNew({ id });
            if (response.code === 400) {
              return notify(response.message);
            }

            if (!response.success) {
              return notify("Não foi possível desativar a CND!", true);
            }

            const agendasCopy = agendas.filter((agenda) => agenda.id !== id);

            setAgendas(agendasCopy);

            notify("CND desativada com sucesso", true, "success");
          } catch (error) {
            console.error(error);
          } finally {
            setLoading((prev) => {
              let prevLoading = { ...prev };
              delete prevLoading.destroyAgenda;
              return prevLoading;
            });
          }
        }
      });
    },
    [agendas, setAgendas, setLoading]
  );

  const lastResponses = useCallback(
    async (row) => {
      try {
        setLoading((prev) => ({ ...prev, lastResponses: true }));

        const response = await rpaEntityRepository.getRecentResponses({
          agendaID: row.agendaId,
          clientDocument: row.clientDocument,
          parentDocument: row.parentDocument,
        });

        if (!response.success) {
          return notify(
            "Não foi possível obter os últimos processamentos!",
            true
          );
        }

        setLastProcessing(response.data);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading((prev) => {
          let prevLoading = { ...prev };
          delete prevLoading.lastResponses;
          return prevLoading;
        });
      }
    },
    [setLastProcessing, setLoading]
  );
  return {
    getAllClients,
    getGroupEntities,
    getAgendaEntities,
    clearRegisteredClients,
    splitRegisteredClients,
    getAccountantCndSettings,
    saveAccountantCndGeneralSettings,
    getRequestedSchedulledExecutions,
    storeEntity,
    getEntityAgendas,
    getEntityAgendasSettings,
    getListActiveInact,
    getEntityGroups,
    handleRequestCnd,
    handleSubmitCnd,
    handleClose,
    getCndTypes,
    storeAgenda,
    updateAgenda,
    destroyAgenda,
    lastResponses,
    getRequestedSchedulledExecutionsDetails,
  };
}
