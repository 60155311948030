import axios from "axios";

export const getListPrefectureNFSe = async () => {
  return await axios

    .get(
      "https://api-microservice.qyon.info/v1/fiscal-intelligence/nfse/prefecture/list",
      {
        headers: {
          Authorization: "FE858C2E-803E-11EC-A8A3-0242AC120002",
        },
      }
    )
    .then((response) => {
      if (response.status !== 200) {
        return null;
      } else {
        return response;
      }
    })
    .catch((err) => {
      if (err.response) {
        return err.response;
      }
      return null;
    });
};

export const getListCND = async () => {
  return await axios
    .get(`${process.env.REACT_APP_CND_API_URL}/v1/public/cnd`)
    .then((response) => {
      if (response.status !== 200) {
        return null;
      } else {
        return response;
      }
    })
    .catch((err) => {
      if (err.response) {
        return err.response;
      }
      return null;
    });
};
