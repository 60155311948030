import styled, { css } from "styled-components";

const white = (props) => props.theme.palette.white;
const gray = (props) => props.theme.palette.gray;
const red = (props) => props.theme.palette.red;
const green = (props) => props.theme.palette.green;
const black = (props) => props.theme.palette.black;

export const ContainerIndex = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Content = styled.div`
  width: 100%;
  background: ${white};
  border-radius: 4px;
`;

export const Container = styled.ul`
  margin-block-start: 10px !important;
  margin-block-end: 0px !important;
  margin-inline-start: 20px !important;
  margin-inline-end: 20px !important;
  padding-inline-start: 0px !important;
  li {
    padding: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    & + li {
      margin-top: 10px;
    }
    &:hover {
      border-radius: 5px;
      background-color: #f5f5f9;
    }
  }
`;

export const FileInfo = styled.div`
  display: flex;
  align-items: center;
  div {
    display: flex;
    flex-direction: column;
    strong {
      color: ${black};
    }
    span {
      font-size: 12px;
      color: #999;
      margin-top: 5px;
      button {
        border: 0;
        background: transparent;
        color: ${red};
        margin-left: 5px;
        cursor: pointer;
      }
    }
  }
`;

export const Preview = styled.div`
  width: 36px;
  height: 36px;
  border-radius: 5px;
  background-image: url(${(props) => props.src});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  margin-right: 10px;
`;

const dragActive = css`
  border-color: ${green};
  background-color: #7fc8a94d;
`;

const dragReject = css`
  border-color: ${red};
  background-color: #fa807275;
`;

export const DropContainer = styled.div.attrs({
  className: "dropzone",
})`
  border: 1px dashed ${gray};
  border-radius: 4px;
  cursor: pointer;
  background-color: #f5f5f9;
  transition: height 0.2s ease;
  ${(props) => props.isDragActive && dragActive};
  ${(props) => props.isDragReject && dragReject};
`;

export const UploadMessage = styled.p`
  color: ${gray};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px 0;
`;
