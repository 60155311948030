
import {getCustomerPlug, getMaps} from '../repositories/panel/v1/integrations/PlugRepository';
export const getCompanyData = async (cnpj) => {

    var myHeaders = new Headers();
    myHeaders.append("x-auth-token", "PANEL@J_xLM48Oo8iKMJFt-YeqUyxk1x7wWoZCKp1jdSGMOB_8nNs-po");

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };


    let response = await getCustomerPlug({document: cnpj});


    return response;


    
}



export const clientHasERP = async (cnpj) => {

    var myHeaders = new Headers();
    myHeaders.append("x-auth-token", "PANEL@J_xLM48Oo8iKMJFt-YeqUyxk1x7wWoZCKp1jdSGMOB_8nNs-po");

    var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
    };


    
    let response = await getMaps(`?IdFamilia=1&ClienteCNPJ=${cnpj}&columns=Rota,ClienteCNPJ`)


    return response;


    
}