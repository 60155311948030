import React, { useState, useEffect, useContext } from "react";

import InvertColorsIcon from "@material-ui/icons/InvertColors";
import PaletteIcon from "@material-ui/icons/Palette";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";

import Loading from "../../components/basics/Loading";

import SectionHeader from "./children/SectionHeader";
import SectionAbout from "./children/SectionAbout";
import SectionVideo from "./children/SectionVideo";
import SectionServices from "./children/SectionServices";
import SectionFooter from "./children/SectionFooter";

import { HomeContext } from "./contexts/HomeContext";

import useHomeController from "./controllers/HomeController";
import {
  FloatingButton,
  FloatingSelector,
  FloatingSaving,
  ItemSelector,
  CircularSaving,
} from "./styles/Home";

const themes = [
  {
    id: 1,
    color_pallete: {
      primary: "#EA9E24",
      secondary: "#F4F4F4",
      tertiary: "#9F5607",
      white: "#FFFFFF",
      gray: "#D4D3D3",
    },
    images: {
      header:
        "https://qpanel-public.s3.sa-east-1.amazonaws.com/websiteHeaders/%23EA9E24.jpg",
    },
  },
  {
    id: 2,
    color_pallete: {
      primary: "#995734",
      secondary: "#F4F4F4",
      tertiary: "#4D2B0D",
      white: "#FFFFFF",
      gray: "#D4D3D3",
    },
    images: {
      header:
        "https://qpanel-public.s3.sa-east-1.amazonaws.com/websiteHeaders/%23995734.jpg",
    },
  },
  {
    id: 3,
    color_pallete: {
      primary: "#A24B4D",
      secondary: "#F4F4F4",
      tertiary: "#480102",
      white: "#FFFFFF",
      gray: "#D4D3D3",
    },
    images: {
      header:
        "https://qpanel-public.s3.sa-east-1.amazonaws.com/websiteHeaders/%23A24B4D.jpg",
    },
  },
  {
    id: 4,
    color_pallete: {
      primary: "#704095",
      secondary: "#F4F4F4",
      tertiary: "#3A0D43",
      white: "#FFFFFF",
      gray: "#D4D3D3",
    },
    images: {
      header:
        "https://qpanel-public.s3.sa-east-1.amazonaws.com/websiteHeaders/%23704095.jpg",
    },
  },
  {
    id: 5,
    color_pallete: {
      primary: "#2CCEFD",
      secondary: "#F4F4F4",
      tertiary: "#012C67",
      white: "#FFFFFF",
      gray: "#D4D3D3",
    },
    images: {
      header:
        "https://qpanel-public.s3.sa-east-1.amazonaws.com/websiteHeaders/%232CCEFD.jpg",
    },
  },
  {
    id: 6,
    color_pallete: {
      primary: "#299F5B",
      secondary: "#F4F4F4",
      tertiary: "#0C3E28",
      white: "#FFFFFF",
      gray: "#D4D3D3",
    },
    images: {
      header:
        "https://qpanel-public.s3.sa-east-1.amazonaws.com/websiteHeaders/%23299F5B.jpg",
    },
  },
  {
    id: 7,
    color_pallete: {
      primary: "#1A5DC9",
      secondary: "#F4F4F4",
      tertiary: "#001D50",
      white: "#FFFFFF",
      gray: "#D4D3D3",
    },
    images: {
      header:
        "https://qpanel-public.s3.sa-east-1.amazonaws.com/websiteHeaders/%231A5DC9.jpg",
    },
  },
];

const Home = ({ location }) => {
  const { loading, editEnable, landTheme, setLandTheme, whatsapp, saving } =
    useContext(HomeContext);

  const { populateContext, saveWebsite } = useHomeController();

  const [open, setOpen] = useState(false);

  useEffect(() => {
    populateContext();
  }, [populateContext]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleSelectTheme = (theme) => {
    setLandTheme(theme);
    if (theme.id) {
      saveWebsite(theme);
    }
    handleClose();
  };

  const handlerWhatsappClick = () => {
    window.open(`https://api.whatsapp.com/send?phone=55${whatsapp}`);
  };

  return (
    <div style={{ overflowY: "auto" }}>
      {loading ? (
        <Loading open={loading} />
      ) : (
        <>
          {editEnable && (
            <FloatingSelector
              onClose={handleClose}
              onOpen={handleOpen}
              direction="down"
              open={open}
              ariaLabel="Temas"
              icon={<PaletteIcon />}
              $backgroundColor={landTheme.color_pallete.primary}
            >
              {themes.map((theme) => (
                <ItemSelector
                  key={theme.color_pallete.id}
                  tooltipTitle="Clique aqui"
                  $backgroundColor={theme.color_pallete.primary}
                  icon={<InvertColorsIcon />}
                  onClick={() => handleSelectTheme(theme)}
                />
              ))}
            </FloatingSelector>
          )}
          {whatsapp && (
            <FloatingButton onClick={handlerWhatsappClick}>
              <p>Fale Conosco</p> <WhatsAppIcon />
            </FloatingButton>
          )}

          {saving && (
            <FloatingSaving
              onClick={handlerWhatsappClick}
              $color={landTheme.color_pallete.primary}
            >
              <CircularSaving /> Salvando ...
            </FloatingSaving>
          )}
          <header>
            <SectionHeader />
          </header>
          <main>
            <SectionAbout />
            <SectionServices />
            <SectionVideo />
          </main>
          <footer>
            <SectionFooter />
          </footer>
        </>
      )}
    </div>
  );
};

export default Home;
