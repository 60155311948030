import * as React from "react";

function BusinessIcon(props) {
  return (
    <svg
      width={24}
      height={25}
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <mask
        id="a"
        style={{
          maskType: "alpha",
        }}
        maskUnits="userSpaceOnUse"
        x={0}
        y={0}
        width={24}
        height={25}
      >
        <path d="M0 0.0429688H24V24.0429688H0z" />
      </mask>
      <g mask="url(#a)">
        <path d="M4 21.043c-.55 0-1.02-.196-1.413-.587A1.926 1.926 0 012 19.043v-11c0-.55.196-1.02.587-1.413A1.926 1.926 0 014 6.043h4v-2c0-.55.196-1.02.588-1.413A1.926 1.926 0 0110 2.043h4c.55 0 1.02.196 1.412.587.392.392.588.863.588 1.413v2h4c.55 0 1.02.196 1.413.587.391.392.587.863.587 1.413v11c0 .55-.196 1.02-.587 1.413a1.926 1.926 0 01-1.413.587H4zm6-15h4v-2h-4v2zm10 9h-5v2H9v-2H4v4h16v-4zm-9 0h2v-2h-2v2zm-7-2h5v-2h6v2h5v-5H4v5z" />
      </g>
    </svg>
  );
}

export default BusinessIcon;
