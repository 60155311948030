import { api } from "./api";

export const getStageAttendance = async ({
  webSocketClient,
  sessionId,
  socketId,
}) => {
  const response = await fetch(
    `${
      webSocketClient.urlWebSocketClient || localStorage.getItem("URL_WS")
    }/attendance/stage/${sessionId}`,
    {
      headers: {
        "Content-Type": "application/json",
        sessionId,
        customerSocketClientId: socketId,
      },
    }
  );

  if (response.status !== 200) return null;
  const data = await response.json();

  const {
    attendant_code,
    info_stage,
    product_code,
    queue_id,
    room_code,
    hasErrors,
  } = data;

  if (hasErrors) return null;

  return {
    success: true,
    attendant_code,
    info_stage,
    product_code,
    queue_id,
    room_code,
  };
};

export const getCustomerConversation = async ({
  webSocketClient,
  companyId,
  email,
}) => {
  const response = await fetch(
    `${
      webSocketClient.urlWebSocketClient || localStorage.getItem("URL_WS")
    }/customer/attendance?roomId=1&companyId=${companyId}&email=${email}`
  );

  const data = await response.json();
  return data;
};

export const setLastUpdateClientInAttendance = async ({
  webSocketClient,
  sessionId,
}) => {
  const socketId =
    sessionStorage.getItem("SOCKET_ID") || webSocketClient.socket.id;
  api
    .post(
      `${
        webSocketClient.urlWebSocketClient || localStorage.getItem("URL_WS")
      }/attendance/${sessionId}`,
      {
        headers: {
          "Content-Type": "application/json",
          sessionId: sessionId,
          customerSocketClientId: socketId,
        },
      }
    )
    .then((response) => {})
    .catch((error) => {
      console.error("setLastUpdateClient error: ", { error });
    });
};
