import React, { useState } from "react";

import { ExpandMore } from "@material-ui/icons";

import {
  AccordionDetails,
  AccordionSummary,
  Grid,
  Switch,
  Tooltip,
  Zoom,
} from "@material-ui/core";

import {
  AccordionStyled,
  ContainerIcon,
  GridStyled,
  Title,
} from "../../../styles/GlobalStyle";

import { HelpOutline as HelpIcon } from "@material-ui/icons";

import { withStyles } from "@material-ui/core/styles";

export default function AccordionCard({
  title,
  children,
  setValue,
  defaultExpanded = false,
  help,
  helpText,
  bgColor,
  ...props
}) {
  const [expanded, setExpanded] = useState(defaultExpanded);
  const DarkTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: theme.palette.common.black,
      color: "#fff",
      boxshadow: theme.shadows[2],
      placement: "right",
      fontSize: 13,
    },
  }))(Tooltip);
  return (
    <AccordionStyled
      expanded={props.hasOwnProperty("value") ? props.value : expanded}
      style={{ marginBottom: "25px" }}
      bg-color={bgColor || "gray"}
    >
      <AccordionSummary
        onClick={() => !props?.icon && setExpanded(!expanded)}
        expandIcon={
          !props?.icon && (
            <ContainerIcon iconColor="white">
              <ExpandMore />
            </ContainerIcon>
          )
        }
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Grid container xs={9}>
          <Title
            subtitle
            color="white"
            cursor="pointer"
            style={{
              marginRight: props?.icon && "64%",
              display: "flex",
              alignItems: "center",
              fontSize: 10,
              color: "#000",
            }}
          >
            {title}{" "}
            {help && (
              <DarkTooltip
                title={helpText}
                placement="right"
                TransitionComponent={Zoom}
                TransitionProps={{ timeout: 100 }}
              >
                <ContainerIcon
                  iconColor="#0a73db"
                  style={{
                    marginLeft: "5px",
                    display: "flex",
                    alignSelf: "center",
                  }}
                >
                  <HelpIcon />
                </ContainerIcon>
              </DarkTooltip>
            )}
          </Title>
        </Grid>
        {props?.icon && (
          <Grid style={{ textAlign: "end", display: "initial" }} xs={3}>
            <Switch
              onClick={() => {
                setValue(!props?.value);
              }}
              checked={props?.value}
            />
          </Grid>
        )}
      </AccordionSummary>
      <AccordionDetails>
        <GridStyled
          container
          spacing={2}
          bg-color="white"
          border-radius={5}
          padding={5}
        >
          {children}
        </GridStyled>
      </AccordionDetails>
    </AccordionStyled>
  );
}
