export const shouldOpenFolhaFiscalContabilXmlERP = (
  type,
  userPermissons,
  isStandaloneClient,
  handleProductMenuHide,
  validateProductStatus
) => {
  if (isStandaloneClient && handleProductMenuHide({ product: type })) {
    return {
      thereIsANewText: true,
      newText:
        "Para adquirir o produto entre em contato com o nosso comercial: 0800 591 0486.",
    };
  }

  let tp = type.toLowerCase();
  if (type === "ERP") {
    tp = "gestaoFacil";
  }
  if (!userPermissons[tp]) {
    return {
      thereIsANewText: true,
      newText: "Acesso não liberado! Entre em contato com o suporte.",
    };
  }

  if (!validateProductStatus(type).isActive) {
    let msg = validateProductStatus(type).message;
    if (msg === null) {
      msg = "Acesso não liberado! Entre em contato com o suporte.";
    }
    return {
      thereIsANewText: true,
      newText: msg,
    };
  }

  return null;
};
