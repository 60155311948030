import React, { createContext, useContext, useMemo, useState } from "react";

const UseGuideReportContext = createContext();

const UseGuideReportProvider = ({ children }) => {
  const [dataModalSend, setDataModalSend] = useState(null);
  const [tabIndex, setTabIndex] = useState(0);
  const [allFiles, setAllFiles] = useState([]);
  const [allFilesToFilter, setAllFilesToFilter] = useState([]);
  const [filesPerDepartament, setFilesPerDepartament] = useState({});
  const [departaments, setDepartament] = useState([]);
  const [expandedTable, setExpandedTable] = useState({
    Favoritos: "",
    Contabil: "",
    Fiscal: "",
  });
  const [company, setCompany] = useState({
    id: "",
    alias: "",
    cnpj: "",
  });

  const [loading, setLoading] = useState(false);
  const [loadingTabs, setLoadingTabs] = useState(false);

  const contextValue = useMemo(
    () => ({
      dataModalSend,
      setDataModalSend,
      tabIndex,
      setTabIndex,
      allFiles,
      setAllFiles,
      allFilesToFilter,
      setAllFilesToFilter,
      filesPerDepartament,
      setFilesPerDepartament,
      departaments,
      setDepartament,
      expandedTable,
      setExpandedTable,
      loading,
      setLoading,
      loadingTabs,
      setLoadingTabs,
      company,
      setCompany,
    }),
    [
      dataModalSend,
      setDataModalSend,
      tabIndex,
      setTabIndex,
      allFiles,
      setAllFiles,
      allFilesToFilter,
      setAllFilesToFilter,
      filesPerDepartament,
      setFilesPerDepartament,
      departaments,
      setDepartament,
      expandedTable,
      setExpandedTable,
      loading,
      setLoading,
      loadingTabs,
      setLoadingTabs,
      company,
      setCompany,
    ]
  );

  return (
    <UseGuideReportContext.Provider value={contextValue}>
      {children}
    </UseGuideReportContext.Provider>
  );
};

const useGuideReport = () => {
  const context = useContext(UseGuideReportContext);

  if (!context)
    throw new Error(
      "useGuideReport must be used within an UseGuideReportContext"
    );

  return context;
};

export { UseGuideReportProvider, useGuideReport };
