import { useCallback, useContext } from "react";

import Swal from "sweetalert2";

import {
  deleteEntity,
  putBpo,
} from "../../../repositories/panel/v1/EntityRepository";
import { postInvite } from "../../../repositories/panel/v1/integrations/EmailRepository";
import { notify } from "../../../utils/notify";
import { CustumerManagementContext } from "../contexts/CustomerManagementContext";
import { ModalRequestVisitContext } from "../contexts/ModalRequestVisitContext";

export default function useCustomerManagementListController() {
  const {
    customersBpo,
    setCustomers,

    //Personalizar Tabela
    setCustomTableModalOpen,
    setCheckColumns,
    setOrderColumns,
    copyOrderColumns,
    setCopyOrderColumns,
    copyCheckColumns,
    setCopyCheckColumns,

    //Lista de BPOs Ativados
    setCustomersBpo,

    setLoading,
  } = useContext(CustumerManagementContext);
  const { setModalVisitOpen } = useContext(ModalRequestVisitContext);
  const clearContext = () => {};

  const handleCheckColumnCustom = (column) => {
    setCopyCheckColumns((current) => ({
      ...current,
      [column.id]: !current[column.id],
    }));

    const indexColumn = copyOrderColumns.findIndex(
      (item) => item.id === column.id
    );
    if (indexColumn !== -1) {
      const copy = [...copyOrderColumns];
      copy.splice(indexColumn, 1);
      setCopyOrderColumns(copy);
      return;
    }

    setCopyOrderColumns((current) => [...current, column]);
  };

  const handleSaveCustomTable = () => {
    localStorage.setItem(
      "orderColumns",
      JSON.stringify({ orderColumns: copyOrderColumns })
    );
    localStorage.setItem(
      "checkColumns",
      JSON.stringify({ checkColumns: { ...copyCheckColumns } })
    );
    setOrderColumns(copyOrderColumns);
    setCheckColumns(copyCheckColumns);
    setCustomTableModalOpen(false);
  };

  const handleClickBpo = useCallback(
    async (active, customerId) => {
      try {
        setLoading(true);

        const respBpo = await putBpo({
          bpo_active: active,
          customerId,
        });

        if (respBpo?.success) {
          const copy = [...customersBpo];
          const index = copy.findIndex((item) => item.id === customerId);
          copy[index] = { ...copy[index], bpo_active: active };

          notify(`BPO ${active ? "ativado" : "desativado"}`, true, "success");
          setCustomersBpo(copy);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    },
    [customersBpo, setCustomersBpo, setLoading]
  );

  const handleDeleteClient = useCallback(
    async (id) => {
      Swal.fire({
        icon: "question",
        text: "Tem certeza que deseja remover este cliente?",
        showConfirmButton: true,
        showCancelButton: true,
        cancelButtonText: "Cancelar",
        confirmButtonText: "Sim",
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            setLoading(true);
            const repsDelete = await deleteEntity(id);

            if (repsDelete?.success) {
              notify("Cliente removido com sucesso.", true, "success");
              setCustomers((current) =>
                current.filter((item) => item?.id !== id)
              );
            }
          } catch (error) {
            console.error(error);
          } finally {
            setLoading(false);
          }
        }
      });
    },
    [setCustomers, setLoading]
  );

  const handleSendInvite = useCallback(
    async (row) => {
      try {
        setLoading(true);

        const respInvite = await postInvite({
          client_id: row.id,
          user_id: row.master_user_id,
          accountant_id: localStorage.getItem("companyIdPanel"),
        });

        if (respInvite?.success) {
          const copy = [...customersBpo];
          const index = copy.findIndex((item) => item.id === row.id);
          copy[index] = { ...copy[index], portal_invite_sended: true };
          notify(
            `Convite enviado para o email ${row.master_email}`,
            true,
            "success"
          );
          setCustomersBpo(copy);
        }
      } catch (error) {
        notify(`Oops, algo deu errado ${error}`);
      } finally {
        setLoading(false);
      }
    },
    [customersBpo, setCustomersBpo, setLoading]
  );

  const handleVisitRequest = useCallback(
    async (visited) => {
      if (visited)
        return notify("Já foi solicitado o contato para este cliente.");

      setModalVisitOpen(true);
    },
    [setModalVisitOpen]
  );

  return {
    //Functions
    clearContext,
    handleCheckColumnCustom,
    handleSaveCustomTable,
    handleClickBpo,
    handleDeleteClient,
    handleSendInvite,
    handleVisitRequest,
  };
}
