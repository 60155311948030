import { Grid } from "@material-ui/core";
import styled from "styled-components";

export const AccoutantInClientViewFooter = styled(Grid)`
  z-index: 9999;
  width: 100%;
  padding: 16px 24px;
  color: white;
  gap: 16px;
  align-items: center;
  background-color: #0c2d6a;
  box-shadow: rgba(0, 0, 0, 0.06) 0px 5px 8px 0px inset;
  position: fixed;
  bottom: 0;
`;
