import { useCallback, useContext } from "react";
import { GlobalContext } from "../../../contexts/GlobalContext";
import { ManagementContext } from "../contexts/ManagementContext";

import Swal from "sweetalert2";
import { notify } from "../../../utils/notify";
import { validNotNull } from "../../../utils/validations";

import * as EntityRepository from "../../../repositories/panel/v1/EntityRepository";

import { usePortalAsClient } from "../../../utils/viewPortalAsClientHook";

export function useManagementController() {
  const { companyUser } = useContext(GlobalContext);
  const {
    setSaving,
    setOpenModalCreateGroup,
    groupSelected,
    permissionTree,
    userSelected,
    setUserSelected,
    setGroupSelected,
    setErrors,
    setLoading,
    edited,
    setEdited,
    setPermissionTree,
    groupOptions,
    setGroupOptions,
    groupCheckedPermissions,
    setGroupCheckedPermissions,
    userCheckedPermissions,
    setUserCheckedPermissions,
    setUserOptions,
  } = useContext(ManagementContext);

  const { isInClientView, clientViewed } = usePortalAsClient();

  const handleChangeGroup = useCallback(
    (group) => {
      setGroupSelected(group);
      setGroupCheckedPermissions(group?.permissions || []);
    },
    [setGroupCheckedPermissions, setGroupSelected]
  );

  const handleChangeUser = useCallback(
    async (user) => {
      if (!user) {
        setUserSelected(null);
        setUserCheckedPermissions([]);
        handleChangeGroup(null);
        return;
      }

      try {
        setLoading((prev) => ({ ...prev, handleChangeUser: true }));

        const userDetail = await EntityRepository.getUserPermission({
          document: companyUser.document,
          userId: user.id,
          withDetails: true,
        });

        if (userDetail.success && userDetail.data) {
          setUserSelected(user);
          setUserCheckedPermissions(userDetail.data.directPermissions || []);
          handleChangeGroup(
            groupOptions.find(
              (group) => group.id === userDetail.data.groupPermissionId
            ) || null
          );
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading((prev) => {
          let prevLoading = { ...prev };
          delete prevLoading.handleChangeUser;
          return prevLoading;
        });
      }
    },
    [
      companyUser,
      groupOptions,
      setLoading,
      setUserCheckedPermissions,
      setUserSelected,
      handleChangeGroup,
    ]
  );

  const handleChangeUserGroup = useCallback(
    (newGroup) => {
      if (userSelected.id === parseInt(companyUser.masterUserId)) {
        return notify(
          "O usuário selecionado é o proprietário do sistema e não pode ter as permissões alteradas"
        );
      }

      handleChangeGroup(newGroup);
      setEdited(true);
    },
    [companyUser, handleChangeGroup, setEdited, userSelected]
  );

  const handleCloseCreateGroup = useCallback(
    (clearSelected = true) => {
      if (clearSelected) handleChangeGroup(null);
      setOpenModalCreateGroup(false);
    },
    [setOpenModalCreateGroup, handleChangeGroup]
  );

  const storePermissionGroup = useCallback(async () => {
    const response = await EntityRepository.postPermissionGroup({
      document: companyUser.document,
      body: {
        name: groupSelected.name,
      },
    });

    if (response.success && response.data) {
      setGroupOptions((prev) => (prev || []).concat([response.data]));
      setEdited(false);
      handleChangeGroup(response.data);
    }
  }, [
    companyUser,
    groupSelected,
    setEdited,
    setGroupOptions,
    handleChangeGroup,
  ]);

  const updatePermissionGroup = useCallback(async () => {
    const response = await EntityRepository.putPermissionGroup({
      document: companyUser.document,
      groupId: groupSelected.id,
      body: {
        name: groupSelected.name,
      },
    });

    if (response.success && response.data) {
      setGroupOptions((prev) =>
        prev.map((group) => {
          if (group.id === groupSelected.id) {
            group.name = groupSelected.name;
          }
          return group;
        })
      );
      setEdited(false);
    }
  }, [companyUser, groupSelected, setEdited, setGroupOptions]);

  const handleSaveGroup = useCallback(async () => {
    try {
      setSaving(true);

      const valid = validNotNull([
        { value: groupSelected?.name || "", key: "name" },
      ]);

      setErrors(valid.errorsValidation);
      if (valid.error) return;

      groupSelected.id
        ? await updatePermissionGroup()
        : await storePermissionGroup();

      handleCloseCreateGroup(false);
    } catch (error) {
      console.error(error);
    } finally {
      setSaving(false);
    }
  }, [
    setSaving,
    groupSelected,
    setErrors,
    handleCloseCreateGroup,
    updatePermissionGroup,
    storePermissionGroup,
  ]);

  const handleDeleteGroup = useCallback(async () => {
    if (!groupSelected?.id)
      return notify("Grupo de permissões não encontrado.");

    Swal.fire({
      icon: "question",
      title: "Tem certeza que deseja excluir o grupo selecionado?",
      allowOutsideClick: false,
      allowEscapeKey: false,
      showConfirmButton: true,
      confirmButtonText: "Excluir",
      showDenyButton: true,
      denyButtonText: `Cancelar`,
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          setSaving(true);

          const response = await EntityRepository.deletePermissionGroup({
            document: companyUser.document,
            groupId: groupSelected.id,
          });

          if (response.success) {
            setGroupOptions((prev) =>
              prev.filter((group) => group.id !== groupSelected.id)
            );
            handleChangeGroup(null);
            notify("Grupo de permissões excluído!", true, "success");
          } else {
            notify(
              "Não foi possível excluir o grupo de permissões!",
              true,
              "error"
            );
          }
        } catch (error) {
          console.error(error);
        } finally {
          setSaving(false);
        }
      }
    });
  }, [
    companyUser,
    groupSelected,
    handleChangeGroup,
    setGroupOptions,
    setSaving,
  ]);

  const handleSaveDeafaultGroup = useCallback(async () => {
    if (!groupSelected?.id)
      return notify("Grupo de permissões não encontrado.");

    try {
      setSaving(true);

      const response = await EntityRepository.putPermissionGroup({
        document: companyUser.document,
        groupId: groupSelected.id,
        body: {
          default: true,
        },
      });

      if (response.success) {
        setGroupOptions((prev) =>
          prev.map((group) => {
            if (group.id === groupSelected.id) {
              group.default = true;
            } else {
              group.default = false;
            }
            return group;
          })
        );
        setGroupSelected((prev) => ({ ...prev, default: true }));
      }
    } catch (error) {
      console.error(error);
    } finally {
      setSaving(false);
    }
  }, [
    companyUser,
    groupSelected,
    setGroupOptions,
    setGroupSelected,
    setSaving,
  ]);

  const getEntityGroups = useCallback(async () => {
    try {
      setLoading((prev) => ({ ...prev, getEntityGroups: true }));

      const response = await EntityRepository.getPermissionGroup({
        document: companyUser.document,
      });

      if (response.success && Array.isArray(response.data)) {
        setGroupOptions(response.data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading((prev) => {
        let prevLoading = { ...prev };
        delete prevLoading.getEntityGroups;
        return prevLoading;
      });
    }
  }, [companyUser, setGroupOptions, setLoading]);

  const getEntityUsers = useCallback(async () => {
    try {
      setLoading((prev) => ({ ...prev, getEntityGroups: true }));

      const response = await EntityRepository.getUser(
        isInClientView ? clientViewed?.company : companyUser.companyId
      );

      if (response.success && Array.isArray(response.data)) {
        setUserOptions(response.data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading((prev) => {
        let prevLoading = { ...prev };
        delete prevLoading.getEntityGroups;
        return prevLoading;
      });
    }
  }, [
    clientViewed?.company,
    companyUser.companyId,
    isInClientView,
    setLoading,
    setUserOptions,
  ]);

  const getPermissionTree = useCallback(async () => {
    try {
      setLoading((prev) => ({ ...prev, getPermissionTree: true }));

      const response = await EntityRepository.getPermissionTree({
        document: companyUser.document,
      });

      if (response.success && response.data) {
        setPermissionTree(response.data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading((prev) => {
        let prevLoading = { ...prev };
        delete prevLoading.getPermissionTree;
        return prevLoading;
      });
    }
  }, [setLoading, companyUser, setPermissionTree]);

  /**
   * Buscar recursivamente os IDs dos filhos
   * Traz todos os ids filhos em um unico array de uma dimensão
   *
   * @param {Object} parent node da arvore de permissões
   * @returns {Array} children ids
   */
  const getChildrensRecursive = (parent) => {
    let children = [];
    (parent.children_recursive || []).forEach((child) => {
      children.push(child.id);
      children = children.concat(getChildrensRecursive(child));
    });
    return children;
  };

  /**
   * Busca um nó na arvore de permissões e retorna o objeto encontrado
   * ou nulo caso não encontre
   *
   * @param {Object} tree arvore onde realizar a busca
   * @param {string} id uuid do nó buscado
   * @returns {(Object|null)} nó encontrado ou null
   */
  const searchTree = (id) => {
    const stack = [permissionTree];
    while (stack.length) {
      const node = stack.pop();
      if (node.id === id) return node;

      node.children_recursive && stack.push(...node.children_recursive);
    }
    return null;
  };

  /**
   * Marca uma permissão como ativa.
   * Utiliza recursividade para desmarcar os filhos,
   * ou marcar o pai caso todas as permissões irmãs estejam marcadas
   *
   * @param {Object} tree arvore onde realizar a busca
   * @param {Object} permission permissão que deseja ativar
   * @returns
   */
  const checkPermission = (permission, checkedIds, setCheckedIds) => {
    let current = [...checkedIds];

    let parent = searchTree(permission.parent_id);
    if (parent) {
      // Verifica se possui ainda alguma permissão irma desmarcada
      let uncheckedBrothers = parent.children_recursive.filter(
        (child) => !current.includes(child.id) && child.id !== permission.id
      );

      // Se todos os irmãos foram marcados,
      // recursivamente passa a marcação para o pai
      if (!uncheckedBrothers.length) {
        checkPermission(parent, checkedIds, setCheckedIds);
        return;
      }
    }

    // Se não tem todos os irmãos marcados,
    // Marca a permissão atual, e remove todos o filhos
    let children = getChildrensRecursive(permission);
    setCheckedIds((current) => {
      return current
        .filter((val) => !children.includes(val))
        .concat([permission.id]);
    });
    if (!edited) setEdited(true);
  };

  /**
   * Desmarca uma permissão como ativa.
   * Utiliza recursividade para desmarcar o pai e marcar os irmãos caso nescessário
   *
   * @param {Object} tree arvore de permissões
   * @param {Object} permission permissão que deseja ativar
   * @returns {void}
   */
  const uncheckPermission = (
    permission,
    checkedIds,
    setCheckedIds,
    checkedChild = [],
    uncheckedChild = []
  ) => {
    if (!permission) return;

    let current = [...checkedIds];

    // Se a permissão for apenas parcial (somente filhos estão marcado)
    // Desmarca todos os filhos
    const allChildrenIds = getChildrensRecursive(permission);
    if (allChildrenIds.some((childId) => current.includes(childId))) {
      setCheckedIds(current.filter((id) => !allChildrenIds.includes(id)));
      if (!edited) setEdited(true);

      // quando a permissão marcada, ou pai marcado é encontrado, desmarca
    } else if (current.includes(permission.id)) {
      // caso esteja desmarcando um pai por recursividade,
      // e tiver irmãos que devem permanecer marcados,
      // adiciona esses irmãos ao array de marcados
      if (checkedChild.length > 0) {
        current = current.concat(checkedChild);
      }

      // remove dos ids atuais a permissão que deseja desmarcar e todos seus filhos
      current = current.filter(
        (id) => id !== permission.id && !uncheckedChild.includes(id)
      );

      setCheckedIds(current);
      if (!edited) setEdited(true);

      // Se essa permissão não estiver marcada,
      // sobe um nivel na arvore para desmarcar o pai
    } else if (permission.parent_id) {
      let parent = searchTree(permission.parent_id);

      // verifica os irmãos do nó atual para marca-los quando o pai for desmarcado
      parent.children_recursive.forEach((child) => {
        permission.id === child.id
          ? uncheckedChild.push(child.id)
          : checkedChild.push(child.id);
      });

      // recursivamente desmarca o pai
      uncheckPermission(
        parent,
        checkedIds,
        setCheckedIds,
        checkedChild,
        uncheckedChild
      );
    }
  };

  const handleSaveGroupPermissions = async () => {
    if (!groupSelected?.id)
      return notify("Grupo de permissões não encontrado.");

    try {
      setSaving(true);

      const response = await EntityRepository.putPermissionGroup({
        document: companyUser.document,
        groupId: groupSelected.id,
        body: {
          permissions: {
            method: "sync",
            permissions: groupCheckedPermissions,
          },
        },
      });

      if (response.success) {
        setGroupOptions((prev) =>
          prev.map((group) => {
            if (group.id === groupSelected.id) {
              group.permissions = groupCheckedPermissions;
            }
            return group;
          })
        );
        setGroupSelected((prev) => ({
          ...prev,
          permisssions: groupCheckedPermissions,
        }));

        setEdited(false);

        notify("Permissões do grupo atualizadas!", true, "success");
      } else {
        notify(
          "Não foi possível atualizar as permissões do grupo!",
          true,
          "error"
        );
      }
    } catch (error) {
      console.error(error);
    } finally {
      setSaving(false);
    }
  };

  const handleSaveUserPermissions = async () => {
    if (!userSelected?.id) return notify("Usuário não encontrado.");

    try {
      setSaving(true);

      const valid = validNotNull([
        { value: groupSelected?.id || "", key: "groupSelected" },
      ]);

      setErrors(valid.errorsValidation);
      if (valid.error) return;

      const response = await EntityRepository.postUserPermission({
        document: companyUser.document,
        userId: userSelected.id,
        body: {
          permission_group_id: groupSelected?.id || null,
          direct_permissions: {
            method: "sync",
            permissions: userCheckedPermissions.filter(
              (permissionId) => !!permissionId
            ),
          },
        },
      });

      if (response.success) {
        setUserOptions((prev) =>
          prev.map((user) => {
            if (user.id === userSelected.id) {
              user.permission_group = groupSelected;
              user.permissions = userCheckedPermissions;
            }
            return user;
          })
        );

        setUserSelected((prev) => ({
          ...prev,
          permission_group: groupSelected,
          permissions: userCheckedPermissions,
        }));

        setEdited(false);
        notify("Permissões do usuário atualizadas!", true, "success");
      } else {
        notify(
          "Não foi possível atualizar as permissões do usuario!",
          true,
          "error"
        );
      }
    } catch (error) {
      console.error(error);
    } finally {
      setSaving(false);
    }
  };

  const handleDiscartGroupChanges = () => {
    handleChangeGroup(groupSelected);
    setEdited(false);
  };

  const hadleDiscardUserChanges = () => {
    handleChangeUser(userSelected);
    setEdited(false);
  };

  /**
   * Verifica se uma permissão esta marcada para o grupo selecionado,
   * Ou se recebe permissão por consequencia de um parent marcado
   *
   * @param {Object} permission
   * @returns {boolean}
   */
  const groupHasPermission = (permission) => {
    if (groupCheckedPermissions.includes(permission.id)) {
      return true;
    }

    // Verifica se alguma permissão pai esta marcada recursivamente
    if (permission.parent_id) {
      return groupHasPermission(searchTree(permission.parent_id));
    }

    return false;
  };

  /**
   * Valida e direciona a marcação ou desmarcação de uma permissão
   *
   * @param {Boolean} check
   * @param {Object} permission
   * @returns {void}
   */
  const handleListItemClick = (check, permission) => {
    if (userSelected && !check && groupHasPermission(permission)) {
      return notify(
        "Essa permissão pertence ao grupo em que o usuário se encontra e não pode ser removida!"
      );
    }

    const checked = userSelected
      ? { values: userCheckedPermissions, setter: setUserCheckedPermissions }
      : { values: groupCheckedPermissions, setter: setGroupCheckedPermissions };

    check
      ? checkPermission(permission, checked.values, checked.setter)
      : uncheckPermission(permission, checked.values, checked.setter);
  };

  return {
    handleChangeGroup,
    handleChangeUser,
    handleChangeUserGroup,
    handleSaveGroup,
    handleDeleteGroup,
    handleSaveDeafaultGroup,
    handleCloseCreateGroup,
    getEntityUsers,
    getEntityGroups,
    getPermissionTree,
    checkPermission,
    uncheckPermission,
    handleSaveGroupPermissions,
    handleSaveUserPermissions,
    handleDiscartGroupChanges,
    hadleDiscardUserChanges,
    handleListItemClick,
  };
}
