import { useCallback } from "react";
import Pusher from "pusher-js";

import { usePermissions } from "../utils/permissionsHook";
import { notify } from "../utils/notify";

const usePusher = () => {
  const { handleLoggedUserPermissionsAndSaveOnLocalStorage } = usePermissions();

  const permissionSocketConect = useCallback(
    (entityDocument, userId) => {
      var pusher = new Pusher(process.env.REACT_APP_PAINEL_PUSHER_KEY, {
        cluster: process.env.REACT_APP_PAINEL_PUSHER_CLUSTER,
      });

      pusher.connection.bind("error", function (err) {
        console.error("Panel Pusher Error: ", err);
      });

      var channel = pusher.subscribe(`${entityDocument}.${userId}`);

      channel.bind("change-permissions", async () => {
        const permissionUpdated =
          await handleLoggedUserPermissionsAndSaveOnLocalStorage(
            entityDocument,
            userId
          );

        if (permissionUpdated) {
          notify(
            "Suas permissões de acesso foram alteradas e serão aplicadas ao navegar pelo sistema ou recarregando a página",
            true,
            "info",
            undefined,
            10000
          );
        }
      });

      return pusher;
    },
    [handleLoggedUserPermissionsAndSaveOnLocalStorage]
  );

  return {
    permissionSocketConect,
  };
};

export { usePusher };
