import { Switch } from "react-router-dom";
import { GlobalVariables } from "../../GlobalVariables";

import MiddlewareRoute from "../Middleware";
import UserList from "../../pages/users/UserList";
import { UserContextProvider } from "../../pages/users/contexts/UserContext";

export default function User() {
  return (
    <UserContextProvider>
      <Switch>
        <MiddlewareRoute
          routePermissionId={GlobalVariables.PERMISSIONS.SETTINGS_USER}
          path="/user/list"
          component={UserList}
          sidebar
        />
      </Switch>
    </UserContextProvider>
  );
}
