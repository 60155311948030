import { Box, Modal, Typography } from "@material-ui/core";
import React from "react";

export const FileNotRecognizedModal = ({
  openFileNotRecognizedModal,
  setOpenFileNotRecognizedModal,
  ...props
}) => {
  return (
    <Modal
      open={openFileNotRecognizedModal}
      onClose={() => {
        setOpenFileNotRecognizedModal(false);
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 600,
          bgcolor: "background.paper",
          boxShadow: 24,
          borderRadius: "10px",
          p: 4,
        }}
      >
        <Typography
          id="modal-modal-title"
          variant="h5"
          component="h2"
          style={{ marginBottom: 20, fontWeight: "bold" }}
        >
          Não conseguimos entender o arquivo que você tentou enviar
        </Typography>

        <Typography
          id="modal-modal-title"
          variant="h5"
          component="h2"
          style={{ marginBottom: 20, fontSize: 14 }}
        >
          <div style={{}}>Por exemplo:</div>
          <ul>
            <li>
              Você pode enviar uma lista de CNPJ para criarmos os clientes
              automaticamente para você.
            </li>
            <li>
              Você pode enviar o certificado A1 de seu cliente, que podemos
              cadastrar e/ou atualizar em todos os produtos para você.
            </li>
          </ul>
          Podemos fazer muito mais, clique aqui para saber o que o Qyon BOT pode
          fazer por você!
        </Typography>
      </Box>
    </Modal>
  );
};
