import { useContext } from "react";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  Switch,
  Typography,
} from "@material-ui/core";

import { useEffect } from "react";
import Btn from "../../../components/buttons/Btn";
import Card from "../../../components/cards/Card";
import DragDropList from "../../../components/dragAndDropLists/DragAndDropList";
import { Title } from "../../../styles/GlobalStyle";
import { CustumerManagementContext } from "../contexts/CustomerManagementContext";
import useCustomerManagementListController from "../controllers/CustomerManagementListController";
import { ItemDraggable } from "../styles/CustomerManagementStyle";

export default function ModalCustomTable() {
  const {
    customTableModalOpen,
    setCustomTableModalOpen,
    copyOrderColumns,
    setCopyOrderColumns,
    copyCheckColumns,
    orderColumns,
    checkColumns,
    setCopyCheckColumns,
  } = useContext(CustumerManagementContext);

  const { handleSaveCustomTable, handleCheckColumnCustom } =
    useCustomerManagementListController();

  const columns = [
    { name: "Ações", id: 1 },
    { name: "Acesso Plataforma", id: 2 },
    { name: "Gestão Fácil", id: 3 },
    { name: "Acesso GF", id: 4 },
    { name: "Cliente", id: 5 },
    { name: "Mensalidade", id: 6 },
    { name: "Usuários", id: 7 },
    { name: "Certificado Digital", id: 8 },
    { name: "Cont. Pagar", id: 9 },
    { name: "Cont. Receber", id: 10 },
    { name: "NF. Entrada", id: 11 },
  ];

  useEffect(() => {
    setCopyOrderColumns([...orderColumns]);
  }, [orderColumns, setCopyOrderColumns]);

  useEffect(() => {
    setCopyCheckColumns({ ...checkColumns });
  }, [checkColumns, setCopyCheckColumns]);

  return (
    <Dialog
      open={customTableModalOpen}
      maxWidth="md"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        <Title subtitle>Personalizar Tabela</Title>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={4}>
          <Grid item xs={6}>
            <Card line="left" lineColor="blue">
              <Grid item container spacing={1}>
                <Grid item container>
                  <Title fontSize="15">Colunas</Title>
                </Grid>

                <Grid item container>
                  {columns.map((column) => (
                    <FormControlLabel
                      key={column.id}
                      control={
                        <Switch
                          checked={copyCheckColumns[column.id] || false}
                          onChange={() => handleCheckColumnCustom(column)}
                          name="checkedA"
                          color="primary"
                        />
                      }
                      label={column.name}
                    />
                  ))}
                </Grid>
              </Grid>
            </Card>
          </Grid>

          <Grid item container xs={6} justifyContent="center">
            <Card line="left" lineColor="blue" fullWidth>
              <Grid item container>
                <Grid item container>
                  <Title fontSize="15">Ordenação</Title>
                </Grid>

                <DragDropList
                  list={copyOrderColumns}
                  setList={setCopyOrderColumns}
                  component={({ item, index }) => (
                    <ItemDraggable container spacing={1} key={item.id}>
                      <Typography>{`${index + 1}° - ${item.name}`}</Typography>
                    </ItemDraggable>
                  )}
                />
              </Grid>
            </Card>
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <Btn
          label="Cancelar"
          bg-color="tertiary"
          onClick={() => setCustomTableModalOpen(false)}
        />
        <Btn label="Salvar" onClick={handleSaveCustomTable} />
      </DialogActions>
    </Dialog>
  );
}
