const filterTable = ({ data = [], text = "", columns = [] }) => {
  text = text
    .normalize("NFD")
    .toLowerCase()
    .replace(/[^a-z0-9_]/g, "");

  const newData = data.filter((item) => {
    let valueFound = false;

    columns.forEach((column) => {
      if (!valueFound) {
        valueFound = item[column]
          ? item[column]
              .toString()
              .normalize("NFD")
              .toLowerCase()
              .replace(/[^a-z0-9_]/g, "")
              .includes(text)
          : false;
      }
    });

    return valueFound;
  });

  return newData;
};

export default filterTable;
