import React, { memo } from "react";

import { TextField } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import MaskService from "../../utils/mask/maskService";

const TextFieldErp = ({
  setValue = () => {},
  size = "small",
  inputRef,
  maxLength,
  mask,
  onChange = (e) => {
    if (maxLength) {
      e.target.value = e.target.value.substring(0, maxLength);
    }

    mask
      ? setValue(MaskService.toMask(mask, e.target.value))
      : setValue(e.target.value);
  },
  className = "filter-input",
  loading,
  endAdornment = null,
  inputProps = {},
  ...props
}) => {
  return (
    <TextField
      {...props}
      inputRef={inputRef}
      size={size}
      onChange={(e) => {
        onChange(e);
      }}
      className={className}
      InputProps={{
        endAdornment: (
          <>
            {loading ? (
              <CircularProgress color="inherit" size={20} />
            ) : (
              endAdornment
            )}
          </>
        ),
        inputProps,
      }}
    />
  );
};

export default memo(TextFieldErp);
