import { useContext, useEffect } from "react";

import {
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@material-ui/core";

import TextFieldErp from "../../../components/basics/TextFieldErp";
import Btn from "../../../components/buttons/Btn";
import { LabelStyled, Title } from "../../../styles/GlobalStyle";
import { DashboardSettingContext } from "../contexts/DashboardSettingContext";
import useDashboardSettingController from "../controllers/DashboardSettingController";
import { DailogSettings } from "../styles/DashboardSettings";

export default function ModalPanel() {
  const {
    panelSelected,
    modalPanel,
    setModalPanel,
    newPanel,
    setPanelId,
    panelId,
    setPanelName,
    panelName,
    setNewPanel,
    errors,

    loadingSave,
  } = useContext(DashboardSettingContext);

  const { handleSavePanel } = useDashboardSettingController();

  useEffect(() => {
    if (newPanel) {
      setPanelId("");
      setPanelName("");
    } else {
      setPanelId(panelSelected?.id ?? "");
      setPanelName(panelSelected?.name ?? "");
    }
  }, [panelSelected, modalPanel]);
  return (
    <DailogSettings
      open={modalPanel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      onClose={
        loadingSave
          ? () => {}
          : () => {
              setNewPanel(false);
              setModalPanel(false);
            }
      }
    >
      <DialogTitle id="alert-dialog-title">
        <Title subtitle>{panelId ? "Edição" : "Cadastro"} de Painel</Title>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2} justifyContent="center" alignItems="center">
          <Grid item xs={12}>
            <TextFieldErp
              error={errors.panelName ? true : false}
              helperText={errors.panelName || ""}
              label="Nome do Painel*"
              value={panelName}
              setValue={setPanelName}
            />
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        {!loadingSave && (
          <Btn
            label="Cancelar"
            bg-color="tertiary"
            onClick={() => {
              setNewPanel(false);
              setModalPanel(false);
            }}
          />
        )}

        <Btn
          label={
            loadingSave ? (
              <>
                <LabelStyled margin="0px 10px 0px 0">Salvando</LabelStyled>
                <CircularProgress style={{ width: 25, height: 25 }} />
              </>
            ) : (
              "Salvar"
            )
          }
          onClick={loadingSave ? () => {} : () => handleSavePanel({})}
          bg-color={loadingSave ? "tertiary" : "blue"}
        />
      </DialogActions>
    </DailogSettings>
  );
}
