import { Modal, Typography } from "@material-ui/core";
import React from "react";
import {
  BorderlessBodyStyle,
  ButtonCancel,
  CloseIconStyle,
  DialogHeader,
  DialogStyle,
} from "../../../components/modal/Modal";
export const NotFoundCompanyModal = (props) => {
  return (
    <Modal
      open={props.openModal}
      onClose={() => {
        props.closeCompanyModal();
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <DialogStyle>
        <DialogHeader>
          <div className="row">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginRight: 20,
                marginBottom: 10,
              }}
            >
              Não foi possível localizar a empresa neste arquivo
            </div>

            <CloseIconStyle
              onClick={() => {
                props.closeModal();
              }}
            />
          </div>
        </DialogHeader>

        <BorderlessBodyStyle>
          <Typography
            id="modal-modal-title"
            variant="h5"
            component="h2"
            style={{
              fontWeight: 400,
              fontSize: 14,
              color: "#364461",
            }}
          >
            O CNPJ inserido pode estar icorreto. Certifique-se de que o arquivo
            esteja no formato padrão (CNPJ;E-mail;).
            <br />
            <br />
            <bold style={{ fontWeight: 700 }}>12.345.678/0001-01</bold>
            ;exemplo@emailcliente.com
            <br />
            <bold style={{ fontWeight: 700 }}>12.345.678/0001-02</bold>
            ;exemplo2@emailcliente.com
            <br />
          </Typography>
        </BorderlessBodyStyle>
        <div
          style={{
            display: "flex",
            justifyContent: "end",
            marginTop: 20,
            marginBottom: 20,
          }}
        >
          <ButtonCancel
            style={{ marginRight: 16 }}
            onClick={() => {
              props.closeModal();
            }}
          >
            Fechar
          </ButtonCancel>
        </div>
      </DialogStyle>
    </Modal>
  );
};
