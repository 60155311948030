import * as React from "react";

function WaitingIcon(props) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      {...props}
    >
      <path
        d="M24 44c11.046 0 20-8.954 20-20S35.046 4 24 4 4 12.954 4 24s8.954 20 20 20z"
        strokeWidth={4}
        strokeLinejoin="round"
      />
      <path
        d="M24.008 12V24.01l8.479 8.48"
        strokeWidth={4}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default WaitingIcon;
