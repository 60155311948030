import styled from "styled-components";
import { Grid } from "@material-ui/core";

export const Container = styled(Grid)`
  border-bottom: 1px solid rgba(207, 216, 220, 1);
  padding-left: 20px;
  padding-top: 20px;
  display: flex;
  float: right;
  background: white;
  width: calc(100% + 28px);
  padding-bottom: 20px;
`;

export const StyledSubheader = styled(Grid)`
  padding: 10px 20px;
  position: sticky;
  z-index: 1200;
  background-color: "#f7f7f7";
  border-bottom: 1px solid #e8ecf4;
`;

export const Title = styled.div`
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(0, 54, 179, 1);
  flex: 1;
`;

export const TitleBreadCrumb = styled.div`
  color: #0036b3;
  font-weight: 700;
  font-size: 16px;
  margin-right: 5px;

  &:hover {
    color: #0c2d6a;
    cursor: pointer;
    text-decoration: underline;
  }
`;

export const TextBreadCrumb = styled.div`
  color: #424242;
  font-weight: 400;
  font-size: 16px;
  margin-left: 5px;
  margin-right: 5px;
`;
