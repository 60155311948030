import { useContext } from "react";
import { ManagementContext } from "../contexts/ManagementContext";
import { useManagementController } from "../controllers/ManagementController";

import { Grid } from "@material-ui/core";

import SelectErp from "../../../components/basics/SelectErp";
import Btn from "../../../components/buttons/Btn";

// Styled Components
import { GridStyled } from "../../../styles/GlobalStyle";

export const TabMainUsers = () => {
  const {
    handleChangeUser,
    handleChangeUserGroup,
    handleSaveUserPermissions,
    hadleDiscardUserChanges,
  } = useManagementController();

  const {
    edited,
    groupOptions,
    groupSelected,
    userSelected,
    userOptions,
    errors,
  } = useContext(ManagementContext);

  return (
    <GridStyled
      container
      justifyContent="space-between"
      bg-color="white"
      padding="8"
    >
      <GridStyled item xs>
        <GridStyled container spacing={2}>
          <Grid item xs={edited ? 4 : 6}>
            <SelectErp
              label="Usuário"
              value={userSelected}
              onChange={handleChangeUser}
              optionsList={userOptions}
              getOptionLabel={(option) =>
                `${option.first_name || ""} ${option.last_name || ""}`.trim()
              }
            />
          </Grid>
          <Grid item xs={edited ? 4 : 6}>
            <SelectErp
              error={!!errors.groupSelected}
              helperText={errors.groupSelected || ""}
              label="Grupo"
              value={groupSelected}
              optionsList={groupOptions}
              disabled={!userSelected}
              descriptionField="name"
              onChange={handleChangeUserGroup}
            />
          </Grid>
          {edited && (
            <>
              <Grid item xs={2}>
                <Btn
                  label="Cancelar"
                  bg-color="tertiary"
                  full
                  onClick={hadleDiscardUserChanges}
                />
              </Grid>
              <Grid item xs={2}>
                <Btn label="Salvar" full onClick={handleSaveUserPermissions} />
              </Grid>
            </>
          )}
        </GridStyled>
      </GridStyled>
    </GridStyled>
  );
};
