import {
  DialogActions,
  DialogContent,
  Grid,
  Modal,
  TextField,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import {
  ButtonCancel,
  ButtonSave,
  CloseIconStyle,
  DialogHeader,
  DialogStyle,
} from "../../../components/modal/Modal";
import SelectErp from "../../../components/basics/SelectErp";
import { notify } from "../../../utils/notify";
import SubdirectoryArrowLeftIcon from "@material-ui/icons/SubdirectoryArrowLeft";
import { getAccountantClientPermissionGroups } from "../../../repositories/portal_api/AccountantClient";
import { validEmail } from "../../../utils/validations";

export const DialogAddUser = ({
  open,
  entityId,
  firstUser = false,
  user,
  handleClose,
  handleSave,
}) => {
  const [userFirstName, setUserFirstName] = useState("");
  const [userLastName, setUserLastName] = useState("");
  const [email, setEmail] = React.useState("");
  const [selectedPermissionGroup, setSelectedPermissionGroup] = useState(null);
  const [listPermissionGroups, setListPermissionGroups] = React.useState([]);

  const [loadingPermissions, setLoadingPermissions] = React.useState(false);

  const [firstNameError, setFirstNameError] = useState(false);
  const [lastNameError, setLastNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [permissionGroupError, setPermissionGroupError] = useState(false);

  const validateFields = () => {
    if (!userFirstName) {
      notify("Primeiro Nome é obrigatório");
      setFirstNameError(true);
      return false;
    }

    if (!userLastName) {
      notify("Último Nome é obrigatório");
      setLastNameError(true);
      return false;
    }

    if (!email) {
      notify("Email é obrigatório");
      setEmailError(true);
      return false;
    }

    //validate email
    if (!validEmail(email)) {
      notify("Email inválido");
      setEmailError(true);
      return false;
    }

    if (!firstUser && !selectedPermissionGroup) {
      setPermissionGroupError(true);
      notify("Grupo de Permissões é obrigatório");
      return false;
    }

    return true;
  };

  useEffect(() => {
    if (user?.id) {
      setUserFirstName(user.first_name);
      setUserLastName(user.last_name);
      setEmail(user.email);
      setSelectedPermissionGroup({
        id: user.permission_group_id,
        name: user.permission_group_name,
      });
    } else {
      setUserFirstName("");
      setUserLastName("");
      setEmail("");
      setSelectedPermissionGroup(null);
    }

    setLoadingPermissions(true);

    if (!firstUser) {
      setLoadingPermissions(true);

      getAccountantClientPermissionGroups(entityId)
        .then((response) => {
          if (response?.success) {
            setListPermissionGroups(response.data);
          } else {
            notify("Houve um erro ao obter os grupos de permissão");
            setListPermissionGroups([]);
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setLoadingPermissions(false);
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?.id, open]);

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogStyle>
          <DialogHeader id="alert-dialog-title">
            <div className="row">
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginBottom: 20,
                }}
              >
                {user?.id ? "Edição" : "Cadastro"} de usuário da Plataforma
              </div>
              <CloseIconStyle
                style={{ marginRight: "6px" }}
                onClick={() => {
                  handleClose();
                }}
              />
            </div>
          </DialogHeader>
          <DialogContent>
            <Grid container spacing={1}>
              {firstUser && (
                <Grid item xs={12}>
                  Para confirmar o cadastro da empresa como cliente, insira os
                  dados do <b>usuário master</b> para acesso ao{" "}
                  <b>Plataforma do Cliente</b>
                </Grid>
              )}

              <Grid item xs={6}>
                <div>Primeiro Nome*</div>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  onFocus={() => {
                    setFirstNameError(false);
                  }}
                  disabled={user?.id}
                  error={firstNameError}
                  placeholder="Primeiro Nome"
                  value={userFirstName}
                  onChange={(e) => {
                    setUserFirstName(e.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <div>Último Nome*</div>
                <TextField
                  variant="outlined"
                  size="small"
                  disabled={user?.id}
                  fullWidth
                  onFocus={() => {
                    setLastNameError(false);
                  }}
                  error={lastNameError}
                  placeholder="Ultimo nome"
                  value={userLastName}
                  onChange={(e) => {
                    setUserLastName(e.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <div>Email*</div>
                <TextField
                  variant="outlined"
                  size="small"
                  onFocus={() => {
                    setEmailError(false);
                  }}
                  disabled={user?.id}
                  fullWidth
                  error={emailError}
                  placeholder="acesso@email.com"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value.toLowerCase());
                  }}
                />
              </Grid>
              {!firstUser && (
                <Grid item xs={12}>
                  <div>Grupo de Permissões*</div>
                  <SelectErp
                    variant="outlined"
                    placeholder="Grupo de permissões"
                    onFocus={() => {
                      setPermissionGroupError(false);
                    }}
                    loading={loadingPermissions}
                    value={selectedPermissionGroup}
                    error={permissionGroupError}
                    setValue={setSelectedPermissionGroup}
                    optionsList={listPermissionGroups}
                    descriptionField="name"
                  />
                </Grid>
              )}
            </Grid>
          </DialogContent>
          <DialogActions style={{ paddingTop: 24, paddingBottom: 24 }}>
            <ButtonCancel
              style={{
                marginRight: 24,
                float: "right",
              }}
              onClick={handleClose}
              startIcon={<SubdirectoryArrowLeftIcon />}
            >
              {"Cancelar"}
            </ButtonCancel>
            <ButtonSave
              style={{
                float: "right",
                right: 14,
              }}
              onClick={() => {
                if (validateFields()) {
                  handleSave({
                    id: user?.id,
                    first_name: userFirstName,
                    last_name: userLastName,
                    permission_group_id: selectedPermissionGroup?.id,
                    permission_group_name: selectedPermissionGroup?.name,
                    is_master: user?.is_master ?? false,
                    email,
                  });
                }
              }}
            >
              {"Enviar"}
            </ButtonSave>
          </DialogActions>
        </DialogStyle>
      </Modal>
    </>
  );
};
