import { useState } from "react";

import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@material-ui/core";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import RemoveCircleIcon from "@material-ui/icons/Delete";

import TextFieldErp from "../../../components/basics/TextFieldErp";
import Btn from "../../../components/buttons/Btn";
import { ContainerIcon, GridStyled, Title } from "../../../styles/GlobalStyle";
import { useGuideReport } from "../contexts/GuideReportContext";
import useGuideReportController from "../controllers/GuideReportController";
import { DialogSendEmail } from "../styles/GuideReport";

export default function ModalSendEmail() {
  const { handleSendEmail } = useGuideReportController();
  const { setDataModalSend } = useGuideReport();
  const [emails, setEmails] = useState([""]);

  const handleAdd = () => {
    if (emails.length < 5) {
      setEmails([...emails, ""]);
    }
  };

  const handleRemove = (index) => {
    const copy = [...emails];
    copy.splice(index, 1);
    setEmails(copy);
  };

  return (
    <DialogSendEmail
      open={true}
      maxWidth="sm"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        <Title subtitle>Enviar E-mail</Title>
      </DialogTitle>
      <DialogContent>
        <GridStyled margin="0px 0px 10px 0" container spacing={2}>
          {emails.map((email, index) => (
            <Grid container spacing={2} key={index} alignItems="center">
              <Grid item xs>
                <TextFieldErp
                  type="email"
                  required
                  label={`${index + 1}º Email`}
                  lg={2}
                  value={emails[index]}
                  setValue={(value) => {
                    const copy = [...emails];
                    copy[index] = value.toLowerCase();
                    setEmails(copy);
                  }}
                />
              </Grid>
              <Grid item flex={1}>
                <GridStyled
                  margin="6px 0px 0px 0"
                  item
                  container
                  alignItems="center"
                >
                  {index === emails.length - 1 && emails.length < 5 && (
                    <ContainerIcon iconColor="black">
                      <AddCircleIcon onClick={handleAdd} />
                    </ContainerIcon>
                  )}

                  {index !== 0 && (
                    <ContainerIcon iconColor="red">
                      <RemoveCircleIcon onClick={() => handleRemove(index)} />
                    </ContainerIcon>
                  )}
                </GridStyled>
              </Grid>
            </Grid>
          ))}
        </GridStyled>
      </DialogContent>

      <DialogActions>
        <Btn
          label="Cancelar"
          bg-color="tertiary"
          onClick={() => setDataModalSend(null)}
        />
        <Btn label="Salvar" onClick={() => handleSendEmail(emails)} />
      </DialogActions>
    </DialogSendEmail>
  );
}
