import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import EmailController from "../../components/chat/controllers/EmailController";
import { getAuth } from "../../repositories/integrations/crm/AuthRepository";
import {
  getCustomerContact,
  getCustomers,
} from "../../repositories/integrations/crm/CustomerRepository";
import { getUser } from "../../repositories/integrations/crm/UserRepository";
import { GlobalContext } from "../GlobalContext";

const UseAuthContext = createContext();

const initialValues = {
  company: {
    id: null,
    alias: null,
    cnpj: null,
    sgbd: null,
    urlSocket: null,
    hasSMTPConfiguration: false,
  },
  user: {
    id: null,
    type: "",
    teamId: null,
    name: "",
    company: null,
  },
  urlWs: "",
};

const UseAuthProvider = ({ children }) => {
  const { companyUser, userLogged } = useContext(GlobalContext);
  const [company, setCompany] = useState({ ...initialValues.company });
  const [user, setUser] = useState({ ...initialValues.user });
  const [urlWs, setUrlWs] = useState(localStorage.getItem("URL_WS"));
  const emailController = EmailController();

  let userType;
  let authPath = "";
  let accountantCnpj;

  if (typeof companyUser?.isAccountant === "string") {
    if (companyUser.isAccountant === "true") userType = "company";
    else
      userType =
        userLogged.userId === companyUser.masterUserId
          ? "customer"
          : "customer-user";

    accountantCnpj =
      companyUser.isAccountant === "true"
        ? companyUser.document
        : companyUser.accountantCnpj;
    const customerCnpj =
      companyUser.isAccountant === "true" ? "" : companyUser.document;

    authPath += `auth?autoCreate=true&name=${
      userLogged.firstName + " " + userLogged.lastName
    }&userType=${userType}&companyCnpj=${accountantCnpj}&customerCnpj=${customerCnpj}`;

    if (
      companyUser.isAccountant === "true" &&
      userLogged.userId === companyUser.masterUserId
    )
      authPath += "&master=true";
    if (companyUser.isAccountant === "true") {
      authPath += `&companyLegalName=${companyUser.companyName}&companyFantasyName=${companyUser.tradingName}`;
    }
  }

  const auth = useCallback(async () => {
    if (authPath) {
      const responseAuth = await getAuth({ authPath });

      if (responseAuth.company) {
        const responseEmailController =
          await emailController.fetchEmailIsConfigured({
            companyId: responseAuth.company.id,
            tokenCognito: userLogged?.tokenCognito,
          });
        setUrlWs(
          !responseAuth?.company?.sgbd?.includes("postgres")
            ? process.env.REACT_APP_CRM_CHAT_WS
            : process.env.REACT_APP_CRM_WS_POSTGRES_URL
        );
        localStorage.setItem(
          "URL_WS",
          !responseAuth?.company?.sgbd?.includes("postgres")
            ? process.env.REACT_APP_CRM_CHAT_WS
            : process.env.REACT_APP_CRM_WS_POSTGRES_URL
        );

        let companyResponse = {
          id: responseAuth.company.id,
          alias: responseAuth.company.alias,
          sgbd: responseAuth.company.sgbd,
          cnpj: accountantCnpj,
          urlSocket: !responseAuth?.company?.sgbd?.includes("postgres")
            ? process.env.REACT_APP_CRM_CHAT_WS
            : process.env.REACT_APP_CRM_WS_POSTGRES_URL,
          hasSMTPConfiguration: responseEmailController.length ? true : false,
        };
        setCompany(companyResponse);
      }

      if (responseAuth.identifiers) {
        const authUser = Object.fromEntries(
          Object.entries(responseAuth.identifiers).filter(([_, v]) => v != null)
        );

        let userInfo;

        if (companyUser?.isAccountant === "true") {
          const res = await getUser({ id: authUser.userId });

          userInfo = {
            ...res,
            type: "attendant",
          };
        } else {
          userInfo = {
            id: null,
            name: `${userLogged.firstName} ${userLogged.lastName}`,
            contactId: "",
            type: "customer",
          };

          let responseUserType = {};
          if (!authUser.customerContactId && authUser.customerId) {
            responseUserType = await getCustomers({ id: authUser.customerId });
            userInfo = {
              ...userInfo,
              id: responseUserType.id,
            };
          } else {
            responseUserType = await getCustomerContact({
              id: authUser.customerContactId,
            });
            userInfo = {
              ...userInfo,
              id: responseUserType.customer?.id,
              contactId: responseUserType.id,
            };
          }
        }

        userInfo && setUser(userInfo);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    accountantCnpj,
    authPath,
    companyUser?.isAccountant,
    userLogged.firstName,
    userLogged.lastName,
  ]);

  useEffect(() => {
    auth();
  }, [auth]);

  const contextValue = useMemo(
    () => ({ company, user, urlWs }),
    [company, user, urlWs]
  );

  return (
    <UseAuthContext.Provider value={contextValue}>
      {children}
    </UseAuthContext.Provider>
  );
};

const useAuth = () => {
  const context = useContext(UseAuthContext);
  if (!context)
    throw new Error("useAuth must be used within an UseAuthProvider");

  return context;
};

export { UseAuthProvider, useAuth };
