import { Switch } from "react-router-dom";
import { DashboardViewContextProvider } from "../../pages/dashboard/contexts/DashboardViewContext";
import DashboardView from "../../pages/dashboard/DashboardView";
import { DashboardSettingContextProvider } from "../../pages/dashboardSettings/contexts/DashboardSettingContext";
import DashboardSetting from "../../pages/dashboardSettings/DashboardSetting";
import MiddlewareRoute from "../Middleware";

export default function Dashboard() {
  return (
    <>
      <DashboardViewContextProvider>
        <Switch>
          <MiddlewareRoute
            path="/dashboard/view"
            component={DashboardView}
            sidebar
          />
        </Switch>
      </DashboardViewContextProvider>

      <DashboardSettingContextProvider>
        <Switch>
          <MiddlewareRoute
            path="/dashboard/setting"
            component={DashboardSetting}
            sidebar
          />
        </Switch>
      </DashboardSettingContextProvider>
    </>
  );
}
