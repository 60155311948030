const getApi = async ({ path, token, refreshToken, cnpj }) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_CRM_API_URL}${path}`,
      {
        method: "get",
        headers: {
          Authorization: token,
          refreshToken: refreshToken,
          "qyon-customer-cnpj": cnpj,
          isInClientView: sessionStorage.getItem("isInClientView") === "true",
          email: sessionStorage.getItem("client")
            ? JSON.parse(sessionStorage.getItem("client")).email
            : null,
        },
      }
    );
    const result = await response.json();
    return result;
  } catch (error) {
    console.error("error ", error);
  }
};

const calcWaitingTime = (created_at) => {
  const today = new Date();
  const dateEnterInQueue = new Date(created_at);
  const diffMs = today - dateEnterInQueue;

  const diffDays = Math.floor(diffMs / 86400000);
  const diffHours = Math.floor((diffMs % 86400000) / 3600000);
  const diffMinutes = Math.round(((diffMs % 86400000) % 3600000) / 60000);
  const returnTime =
    (diffDays >= 1 ? `${diffDays} Dia(s) ` : "") +
    (diffHours >= 1 ? `${diffHours} Hora(s) ` : "") +
    (diffMinutes >= 1 ? `${diffMinutes} Min ` : "0 Min");

  return returnTime;
};

const convertToTimeLocale = (time) => {
  const dateMessageServer = new Date(time);
  const dateMessageLocale = new Date(
    dateMessageServer.getTime() + dateMessageServer.getTimezoneOffset() / 60
  );
  const timeLocale =
    (dateMessageLocale.getHours() < 10 ? "0" : "") +
    dateMessageLocale.getHours() +
    ":" +
    (dateMessageLocale.getMinutes() < 10 ? "0" : "") +
    dateMessageLocale.getMinutes();

  return timeLocale;
};

const changeAccessTokenStorage = (token) => {
  if (token) {
    let oldJsonAccessToken = JSON.parse(localStorage.getItem("cognitoPanel"));
    oldJsonAccessToken.AccessToken = token;
    localStorage.setItem("cognitoPanel", JSON.stringify(oldJsonAccessToken));
    localStorage.setItem("tokenCognitoPanel", token);
  }
};

const optionsList = (arr) => {
  try {
    let json = [];
    let arrItems = arr?.items || arr;
    arrItems?.forEach((item) =>
      json.push({
        title:
          item?.description ||
          item?.socialName ||
          item?.fantasyName ||
          item?.name,
        value: item?.attendanceSituationId || item?.id || item?.productId,
        color: item?.color || null,
      })
    );
    return json;
  } catch (error) {
    console.error("Error:", error);
    return [];
  }
};

const getDateDiff = ({ startDate, currentDate }) => {
  const diff = Math.floor(
    (currentDate ? Date.parse(currentDate) : new Date()) - Date.parse(startDate)
  );

  const times = {
    seconds: diff / 1000,
    minutes: diff / (1000 * 60),
    hour: diff / (1000 * 60 * 60),
    days: diff / (1000 * 60 * 60 * 24),
  };

  if (times?.days >= 1)
    return `${Math.floor(times?.days)} ${
      Math.floor(times?.days) > 1 ? "dias" : "dia"
    }`;
  else if (times?.hour >= 1)
    return `${Math.floor(times.hour)} ${
      Math.floor(times.hour) > 1 ? "horas" : "hora"
    } `;
  else if (times?.minutes >= 1) return `${Math.floor(times?.minutes)} min`;
  else return `0 min`;
};

const getAvatarContent = (text) => {
  if (text) {
    let result = text.charAt(0);
    const splited = text.split(" ");
    if (splited.length > 1) result += splited[splited.length - 1].charAt(0);
    else result += text.charAt(1);
    return result.toUpperCase();
  } else {
    return "";
  }
};

export {
  optionsList,
  getApi,
  calcWaitingTime,
  convertToTimeLocale,
  changeAccessTokenStorage,
  getDateDiff,
  getAvatarContent,
};
