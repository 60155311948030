import styled, { css } from "styled-components";

/**
 * Icons
 */
import { Settings } from "@material-ui/icons";

import { Cnd } from "../../../assets/icons/Cnd";

/**
 * Components
 */
import {
  TableRow,
  TableCell,
  Chip,
  Card,
  FormControl,
  CircularProgress,
  CardHeader,
  AccordionDetails,
} from "@material-ui/core";

/**
 * Theme
 */
const white = (props) => props.theme.palette.white;
const blue = (props) => props.theme.palette.blue;
const gray = (props) => props.theme.palette.gray;
const grayLight = (props) => props.theme.palette.grayLight;

const roboto = (props) => props.theme.fonts.roboto;

/**
 * Styled
 */

export const ContainerTab = styled.div`
  width: 100%;
  .MuiTabs-root {
    background: ${blue};
  }

  .MuiTabs-indicator {
    background-color: ${white};
  }

  .MuiTab-wrapper {
    font-family: ${roboto}, sans-serif !important;
    color: ${white};
  }
`;

export const TitleIcon = styled(Cnd)`
  margin-right: 8px;
  font-size: 22px;
`;

export const StyledSettingsIcon = styled(Settings)`
  color: ${blue};
  font-size: 35px;
  cursor: pointer;
`;

export const StyledCardHeader = styled(CardHeader)`
  background-color: ${grayLight};
`;

export const StyledCardSettings = styled(Card)`
  height: 100%;
`;

export const StyledTableRow = styled(TableRow)`
  background-color: ${gray};

  .MuiTableCell-root {
    color: ${white};
  }
`;

export const StatusBall = styled.span`
  ${(props) =>
    props.$bgColor &&
    css`
      background-color: ${(props) => props.theme.palette[props.$bgColor]};
    `};

  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  color: ${white};
`;

export const StyledTableCell = styled(TableCell)`
  &.MuiTableCell-sizeSmall {
    padding: 6px 16px;
  }

  &.action {
    min-width: 190px;
    width: 185px;
  }

  ${(props) =>
    props.width &&
    css`
      width: ${(props) => props.width};
    `};
`;

export const TotalCndChip = styled(Chip)`
  .MuiChip-label {
    font-size: 1rem;
  }
`;

export const StyledFormControll = styled(FormControl)`
  width: 100%;
`;

export const GroupsSettingsContainer = styled.div`
  width: 100%;
`;

export const CircularSaving = styled(CircularProgress)``;

export const FloatingSaving = styled.div`
  position: fixed;
  bottom: 45px;
  left: 235px;
  padding: 10px 30px 10px 30px;
  background-color: #d4d3d3;
  border: none;
  border-radius: 100vh;
  align-items: center;
  justify-content: center;
  display: flex;
  color: ${blue};
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  font-family: "roboto", sans-serif;
  font-size: 1.125rem;
  z-index: 10;
  ${CircularSaving} {
    margin-right: 8px;
    height: 20px !important;
    width: 20px !important;
  }
`;

export const StyledAccordionDetails = styled(AccordionDetails)`
  background-color: ${grayLight};
`;
