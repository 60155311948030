import { useContext, useRef, useState, useEffect, useCallback } from "react";

import Grid from "@material-ui/core/Grid";

import {
  Section,
  VideoContainer,
  TextEdit,
  VideoPlayer,
  VideoTextDiv,
  VideoUrlField,
} from "../styles/Home";
import useHomeController from "../controllers/HomeController";

import { HomeContext } from "../contexts/HomeContext";

const SectionVideo = ({ ...props }) => {
  const {
    landTheme,
    editEnable,
    videoUrl,
    setVideoUrl,
    videoText,
    setVideoText,
  } = useContext(HomeContext);
  const { saveWebsite, saveWebsiteType } = useHomeController();

  const handleBlur = () => {
    saveWebsite();
  };

  const handleBlurEntity = () => {
    saveWebsiteType();
  };
  

  const [refWidth, setRefWidth] = useState(300);
  const videoFrame = useRef();

  const reziseVideoPlayer = useCallback(() => {
    if (videoFrame?.current) {
      setRefWidth(videoFrame.current.clientWidth);
    }
  }, []);

  useEffect(() => {
    reziseVideoPlayer();
    window.addEventListener("resize", reziseVideoPlayer);
  }, [reziseVideoPlayer]);

  return (
    <Section $backgroundColor={landTheme.color_pallete.white}>
      <VideoContainer maxWidth="lg" id="section-video">
        <Grid
          container
          className="video-grid"
          spacing={8}
          justifyContent="center"
          alignItems="center"
        >
          <Grid
            item
            md={7}
            className="aboutUsImageBox"
            container
            justifyContent="center"
            alignItems="center"
            ref={videoFrame}
          >
            <VideoPlayer
              url={videoUrl}
              controls={true}
              width="100%"
              heigth={`${(refWidth / 16.0) * 9.0}px`}
            />

            {editEnable && (
              <VideoTextDiv $editable={editEnable}>
                <VideoUrlField
                  label="Vídeo URL"
                  value={videoUrl}
                  onChange={(e) => {
                    setVideoUrl(e.target.value);
                  }}
                  onBlur={handleBlurEntity}
                />
              </VideoTextDiv>
            )}
          </Grid>

          <Grid
            item
            md={5}
            className="aboutUsTextBox"
            justifyContent="center"
            alignItems="center"
            container
            direction="column"
          >
            <VideoTextDiv
              $editable={editEnable}
              $color={landTheme.color_pallete.tertiary}
            >
              {editEnable ? (
                <TextEdit
                  type="text"
                  value={videoText}
                  $color={landTheme.color_pallete.tertiary}
                  $fontSize={3}
                  $textAlign="unset"
                  $minHeight={200}
                  onChange={(e) => {
                    setVideoText(e.target.value);
                  }}
                  onBlur={handleBlur}
                />
              ) : (
                <h2>{videoText}</h2>
              )}
            </VideoTextDiv>
          </Grid>
        </Grid>
      </VideoContainer>
    </Section>
  );
};

export default SectionVideo;
