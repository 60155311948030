import { toast } from "react-toastify";
import "../../node_modules/react-toastify/dist/ReactToastify.css";

const blackList = [
  "The email must be a valid email address.",
  "Data validation error",
];

export function notify(
  message,
  autoClose = true,
  type = "warning",
  onClose,
  timeToClose = 7000,
  className = ""
) {
  if (blackList.includes(message)) return;

  toast(message, {
    type: type,
    autoClose: autoClose ? timeToClose : false,
    onClose,
    className,
  });
}

export const removeNotify = (id) => {
  return toast.dismiss(id);
};
