import styled from "styled-components";
import { Button, Grid, TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";

export const TabTextField = styled(({ ...props }) => (
  <TextField
    InputLabelProps={{ style: { marginTop: "-8px" } }}
    {...props}
  ></TextField>
))`
  margin-bottom: ${(props) => (props.final ? "20px" : "5px")};
  width: 100%;
`;

export const gridSectionTitle = {
  textAlign: "start",
  fontSize: "16px",
  fontWeight: "bold",
  color: "gray",
};

export const noApuratedDebitLabel = {
  textAlign: "start",
  fontSize: "14px",
  color: "gray",
};

export const valueFormStyle = {
  marginBottom: 5,
  width: "100%",
  border: "1px solid #b7b5b5",
  borderRadius: "5px",
  position: "relative",
  paddingTop: 8,
  height: 37,
};

export const dialogStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: 10,
};

export const bodyStyle = {
  maxHeight: 500,
  minHeight: 100,
  paddingLeft: 20,
  paddingRight: 20,
  overflowY: "auto",
  overflowX: "hidden",
  marginBottom: 10,
  paddingTop: 10,
};

export const dialogLoading = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  background: "rgba(0,0,0,0.5)",
  position: "absolute",
  zIndex: 1000,
  width: "100%",
  height: bodyStyle.maxHeight - 10,
  borderBottomLeftRadius: 10,
  borderBottomRightRadius: 10,
};

export const dialogLoadingPlayer = { height: "300px", width: "300px" };

export const dialogTitle = {
  fontWeight: "bold",
  background: "rgb(19, 142, 247)",
  borderTopLeftRadius: 10,
  borderTopRightRadius: 10,
  padding: 20,
  color: "white",
};

export const dialogTitleChildrenCotantainer = {
  display: "flex",
  flexDirection: "row",
  position: "relative",
};

export const dialogTitleChildrenRow = {
  display: "flex",
  flexDirection: "row",
  position: "relative",
};

export const dialogTitleChildrenColumn = {
  display: "flex",
  flexDirection: "column",
};

export const dialogTitleClose = {
  position: "absolute",
  right: 0,
  cursor: "pointer",
};

export const documentContainer = {
  marginBottom: 10,
};

export const documentTab = (value, active) => {
  return {
    color: "#fff",
    width: 50,
    background: active === value ? "rgba(255,255,255,0.3)" : "",
  };
};

export const tabPanelStyle = {
  background: "#f2f2f2",
  borderBottomLeftRadius: 10,
  borderBottomRightRadius: 10,
  minHeight: 372,
  maxHeight: 372,
  overflowY: "scroll",
  overflowX: "hidden",
};

export const WarningBoletoExists = styled.div`
  color: red;
  font-weight: bold;
  text-align: center;
`;

export const GridSectionTitle = styled(Grid)`
  text-align: start;
`;

export const nameDocumentStyle = (loading, documentType) => {
  return {
    position: "relative",
    bottom: 5,
    left: 5,
    color:
      loading === true
        ? "grey"
        : documentType === "NOT_IDENTIFIED"
        ? "#F44336"
        : "#4CAF50",
  };
};

export const tabsDocumentStyle = (loading, documentType) => {
  return {
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    background:
      loading === true
        ? "grey"
        : documentType === "NOT_IDENTIFIED"
        ? "#F44336"
        : "#4CAF50",
  };
};

export const CustomAutocomplete = styled(Autocomplete)`
  width: 100%;
  margin-bottom: 10px;
`;

export const DocumentButton = styled(Button)`
  z-index: 1000;
  background: ${(props) => (props.loading ? "grey" : "rgb(19, 142, 247)")};
  color: white;
  font-size: 16;
  font-weight: bold;
  height: 40px;
  width: 150px;
  ${(props) => (props.isSend ? "margin-right: 10px;" : "")}
  &:hover {
    background: rgb(19, 142, 247);
  }
  &:disabled {
    color: white;
  }
`;

export const PdfIframe = styled.iframe`
  overflow: hidden;
  border: none;
  width: 100%;
  height: 400px;
`;

export const DivTextLeft = styled.div`
  text-align: left;
`;

export const DivSecondTitle = styled.div`
  font-size: 14px;
`;

export const NotIdentifiedDocumentName = styled.div`
  margin-right: 20;
  width: 100%;
`;

export const PdfImgWrapperDiv = styled.div`
  background: white;
  border-radius: 10px;
  padding: 10px;
`;

export const pdfImg = () => {
  return {
    width: "100%",
  };
};

export const DocumentModalFooterWrapper = styled.div`
  margin-top: 10px;
`;

export const floatAlign = (direction) => {
  return {
    float: direction || "left",
  };
};

export const TextFieldDocumentName = styled(({ ...props }) => (
  <TextField
    InputLabelProps={{ style: { marginTop: "-8px" } }}
    {...props}
  ></TextField>
))`
  margin-bottom: 20px;
  width: 100%;
`;
