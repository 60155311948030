import React, { useContext, useEffect, useState } from "react";

import { DialogActions, Checkbox, Grid, IconButton } from "@material-ui/core";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";

import { GlobalContext } from "../../../contexts/GlobalContext";
import { GridStyled, LabelStyled } from "../../../styles/GlobalStyle";
import useBannerBankController from "../controllers/BannerBankController";
import {
  DialogBanner,
  DialogContentBanner,
  ButtonBanner,
  FormControlLabelBanner,
  DialogTitleBanner,
} from "./styles/CodeCardLiberation";
import { getDataBannerBank } from "../../../repositories/panel/v1/EntityRepository";

const configBanner = {
  images: {
    black:
      "https://qerp-public.s3.sa-east-1.amazonaws.com/38469354000152_Mzg0NjkzNTQwMDAxNTIxNGVlNWZmMC1mNmI1LTQxOTUtOTJiZi1kMzliNmQ2Y2Q1NjI%3D.png",
    platinum:
      "https://qerp-public.s3.sa-east-1.amazonaws.com/38469354000152_Mzg0NjkzNTQwMDAxNTI2OGMwYjZhYi0wMWM3LTRkM2ItYTczOS04OGZiY2UzMDUxN2M%3D.png",
  },
  labels: {
    withoutConfigErp: (
      <>
        Vimos que você já tem uma conta na Qyon Bank.
        <br />
        <br />
        Agora basta você fazer a integração, e a emissão dos seus Boletos PIX
        ilimitados já estarão disponíveis.
      </>
    ),
    withoutBank:
      "Você precisa abrir uma conta na Qyon Bank para fazer a integração à nossa plataforma e a emissão dos seus Boletos PIX ilimitados já estarão disponíveis.",
  },
};

export default function BannerQyonBank() {
  const [banner, setBanner] = useState({});
  const [noDisplay, setNoDisplay] = useState(false);
  const { userLogged, companyUser } = useContext(GlobalContext);
  const { handleSubmitBanner } = useBannerBankController();

  useEffect(() => {
    try {
      setBanner({});

      if (companyUser?.masterUserId !== userLogged.userId) return;

      getDataBannerBank({ id: companyUser?.companyId }).then(
        (dataBannerBank) => {
          if (
            dataBannerBank?.success &&
            typeof dataBannerBank?.data === "object"
          ) {
            localStorage.setItem(
              "dataBannerQyonBank",
              JSON.stringify(dataBannerBank.data)
            );
          } else {
            localStorage.setItem("dataBannerQyonBank", "{}");
          }

          let bannerData = localStorage.getItem("dataBannerQyonBank");
          processDataBanner(bannerData);
        }
      );
    } catch (err) {
      console.error(err);
    }
  }, [companyUser, userLogged]);

  const processDataBanner = (dataBanner) => {
    const jsonDataBanner = JSON.parse(dataBanner);

    if (
      jsonDataBanner?.show_banner_bank &&
      jsonDataBanner?.code &&
      (!jsonDataBanner?.qyon_bank || !jsonDataBanner?.erp_bank)
    ) {
      setBanner({
        label:
          configBanner.labels[
            !jsonDataBanner?.qyon_bank ? "withoutBank" : "withoutConfigErp"
          ],
        image:
          configBanner.images[
            jsonDataBanner?.code.includes("4579") ? "black" : "platinum"
          ],
      });
    }
  };

  return (
    <>
      <DialogBanner
        open={!!banner?.label}
        maxWidth="sm"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        disableScrollLock
      >
        <DialogTitleBanner>
          <img src={banner?.image} style={{ width: "100%" }} alt="Boleto PIX" />
          <GridStyled
            container
            justifyContent="flex-end"
            position="absolute"
            top="0px"
          >
            <IconButton onClick={() => setBanner({})}>
              <CloseOutlinedIcon />
            </IconButton>
          </GridStyled>
        </DialogTitleBanner>

        <DialogContentBanner>
          <GridStyled
            container
            alignItems="center"
            justifyContent="center"
            height="140px"
          >
            <GridStyled margin="0px 0px 15px 0" item flex={1}>
              <LabelStyled color="grayMedium" fontSize="25" fontWeight="500">
                CARTÃO BOLETO PIX ILIMITADO
              </LabelStyled>
            </GridStyled>
            <Grid item flex={1}>
              <LabelStyled color="gray" fontSize="17" fontWeight="600">
                {banner?.label}
              </LabelStyled>
            </Grid>
          </GridStyled>
        </DialogContentBanner>
        <DialogActions>
          <GridStyled container alignItems="center" justifyContent="center">
            <Grid item>
              <ButtonBanner
                onClick={() => handleSubmitBanner({ noDisplay, setBanner })}
              >
                CLIQUE AQUI
              </ButtonBanner>
            </Grid>

            <Grid item container justifyContent="center">
              <FormControlLabelBanner
                control={
                  <Checkbox
                    checked={noDisplay}
                    onChange={() => setNoDisplay((current) => !current)}
                    name="checkBanner"
                  />
                }
                label="Não exibir novamente."
              />
            </Grid>
          </GridStyled>
        </DialogActions>
      </DialogBanner>
    </>
  );
}
