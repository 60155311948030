import { useState, useContext, useMemo } from "react";
import PropTypes from "prop-types";
import { usePermissions } from "../../../utils/permissionsHook";
import { GlobalVariables } from "../../../GlobalVariables";

/**
 * Icons
 */
import {
  KeyboardArrowDown,
  KeyboardArrowUp,
  Cached,
  CheckCircleOutline,
  CancelOutlined,
  MailOutline,
} from "@material-ui/icons";

/**
 * Components
 */
import {
  Grid,
  IconButton,
  Tooltip,
  TableRow,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Modal,
  Typography,
  Box,
  TextField,
  InputAdornment,
  FormControl,
  InputLabel,
  OutlinedInput,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

import { TableTabCndAccountantSubTable } from "./TableTabCndAccountantSubTable";
import { RpaStatus } from "../../../utils/RpaStatus";
/**
 * Styled Components
 */
import { LightTooltip } from "../../../styles/GlobalStyle";

import {
  StatusBall,
  StyledTableCell,
  TotalCndChip,
} from "../styles/CndAccountant";

/**
 * controllers
 */
import { useCndAccountantController } from "../controllers/CndAccountantController";

/**
 * Context
 */

import { GlobalContext } from "../../../contexts/GlobalContext";
import { notify } from "../../../utils/notify";
import { sendEmailConfirmation } from "../../../repositories/panel/v1/integrations/qyonMsRpa/entity";

export const TableTabCndAccountantRow = (props) => {
  const { row } = props;

  const { companyUser } = useContext(GlobalContext);

  const { handleRequestCnd, getRequestedSchedulledExecutionsDetails } =
    useCndAccountantController();

  const [open, setOpen] = useState(false);
  const [listCndsClient, setListCndsClient] = useState([]);
  const [subtableLoading, setSubtableLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  const { checkIfUserHasPermission } = usePermissions();
  const hasEditionPermission = useMemo(
    () =>
      checkIfUserHasPermission(GlobalVariables.PERMISSIONS.CND_SOLICITATION),
    [checkIfUserHasPermission]
  );

  const handleOpenSubTable = (newStatus) => {
    setOpen(newStatus);
    setListCndsClient([]);
    setSubtableLoading(true);
    setIsError(false);

    if (newStatus) {
      getRequestedSchedulledExecutionsDetails(row.document)
        .then((value) => {
          if (!value.data) setIsError(true);
          setListCndsClient(value.data || []);
        })
        .catch((err) => {
          setIsError(true);
          console.error(err);
        })
        .finally(() => {
          setSubtableLoading(false);
        });
    }
  };

  const [openDialog, setOpenDialog] = useState(false);

  const handleClose = () => {
    setOpenDialog(false);
  };

  const emailConfirmation = async () => {
    let response = await sendEmailConfirmation(row.document);
    if(response.success === true){
      notify("Email enviado com sucesso!", true, "success");
      setOpenDialog(false);
    }
  }

  return (
    <>
      <TableRow>
        <StyledTableCell align="center">
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => handleOpenSubTable(!open)}
          >
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </StyledTableCell>
        <StyledTableCell align="left">{row.document}</StyledTableCell>
        <StyledTableCell align="left">{row.name}</StyledTableCell>

        <StyledTableCell align="center">
          <Grid container justifyContent="center">
            {row.mail !== null && (
              <Tooltip
                title={
                  row.email_verified === false ? "Não verificado" : "Verificado"
                }
                endIcon={
                  row.email_verified === true ? (
                    <CheckCircleOutline />
                  ) : (
                    <CancelOutlined />
                  )
                }
              >
                <Button
                  size="small"
                  style={{ textTransform: "lowercase", color: "dimGray", fontSize: '12px' }}
                  onClick={() => {
                    setOpenDialog(true);
                  }}
                >
                  {row.mail}
                </Button>
              </Tooltip>
              )}
              {row.mail === null && (
              <Button
              size="small"
              style={{ textTransform: "lowercase", color: "dimGray", fontSize: '12px' }}
              >
              Sem email cadastrado
              </Button>
              )}
          </Grid>
        </StyledTableCell>

        <StyledTableCell align="center">
          <Grid container spacing={1} justifyContent="center">
            <Grid item>
              <Tooltip title="Negativa">
                <StatusBall $bgColor="green">{row.negative}</StatusBall>
              </Tooltip>
            </Grid>
            <Grid item>
              <Tooltip title="Positiva com efeito Negativa">
                <StatusBall $bgColor="blue">
                  {row.positiveNegativeEffect}
                </StatusBall>
              </Tooltip>
            </Grid>
            <Grid item>
              <Tooltip title="Positiva">
                <StatusBall $bgColor="orange">{row.positive}</StatusBall>
              </Tooltip>
            </Grid>

            <Grid item>
              <Tooltip title="Inconsistente">
                <StatusBall $bgColor="grayBlue">{row.inconsistent}</StatusBall>
              </Tooltip>
            </Grid>
            <Grid item>
              <Tooltip title="Erro">
                <StatusBall $bgColor="yellow">{row.error}</StatusBall>
              </Tooltip>
            </Grid>
            <Grid item>
              <Tooltip title="Processando">
                <StatusBall $bgColor="gray">{row.processing}</StatusBall>
              </Tooltip>
            </Grid>
          </Grid>
        </StyledTableCell>
        <StyledTableCell align="center">
          <Grid container spacing={0} justifyContent="center">
            <Grid item>
              <Tooltip title="A Vencer">
                <StatusBall $bgColor="purple">
                  {row.closeToExpiration}
                </StatusBall>
              </Tooltip>
            </Grid>
            <Grid item>
              <Tooltip title="Vencida">
                <StatusBall $bgColor="red" style={{marginLeft: '5px'}}>{row.expired}</StatusBall>
              </Tooltip>
            </Grid>
          </Grid>
        </StyledTableCell>
        <StyledTableCell align="center">
          <TotalCndChip
            label={row.total}
            onDelete={() => {
              hasEditionPermission
                ? handleRequestCnd({
                    parentDocument: localStorage.getItem("documentPanel"),
                    clientDocument: row.document,
                    agendaId: null,
                  })
                : notify(
                    "Seu usuário não tem permissão para realizar essa ação"
                  );
            }}
            deleteIcon={
              <LightTooltip title="Solicitar CNDs do Cliente">
                <Cached />
              </LightTooltip>
            }
            variant="outlined"
          />
        </StyledTableCell>
      </TableRow>
      <TableRow>
        <TableTabCndAccountantSubTable
          open={open}
          listCnds={listCndsClient}
          isLoading={subtableLoading}
          isError={isError}
          companyName={row.name}
          companyDocument={row.document}
          companyMail={row.mail ? row.mail : companyUser.masterUserEmail}
          handleOpenSubTable={handleOpenSubTable}

        />
      </TableRow>

      <Dialog
        open={openDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div>
          <CloseIcon
            style={{
              position: "absolute",
              right: 4,
              cursor: "pointer",
              marginTop: "10px",
            }}
            onClick={handleClose}
          />
          <DialogTitle id="alert-dialog-title">Email</DialogTitle>
        </div>

        <DialogContent style={{ fontSize: "16px" }}>
          Deseja reenviar o email de confirmação?
          <FormControl variant="outlined" fullWidth style={{marginTop: '20px'}}>
            <OutlinedInput
              startAdornment={
                <InputAdornment position="start">
                  <IconButton edge="start">
                    <MailOutline />
                  </IconButton>
                </InputAdornment>
              }
              readOnly 
              value={row.mail}
            />
          </FormControl>
        </DialogContent>

        <DialogActions>
          <Button variant="contained"  color="primary" autoFocus onClick={emailConfirmation}>Enviar</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

TableTabCndAccountantRow.propTypes = {
  row: PropTypes.shape({
    document: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    agendas: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        description: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
        status: PropTypes.string.isRequired,
        situation: PropTypes.string,
      })
    ).isRequired,
  }).isRequired,
};
