import { search } from "../../../BaseRepositoryComponents";

const path = `${process.env.REACT_APP_PAINEL_API_URL}/api/v1/integrations/plug`;

export async function postMap({ body }) {
  return search({ path: `${path}/maps`, method: "POST", myBody: body });
}

export async function postContract({ body }) {
  return search({ path: `${path}/contracts`, method: "POST", myBody: body });
}

export async function getMaps({ params = "" }) {
  return search({
    path: `${path}/maps${params}`,
    method: "GET",
  });
}

export async function getCustomerPlug({ document }) {
  return search({
    path: `${path}/clients/${document}`,
    method: "GET",
  });
}

export async function getContractsByCNPJ({ document }) {
  return search({
    path: `${path}/contracts/${document}`,
    method: "GET",
  });
}
