import axios from "axios";

import { search } from "../../BaseRepositoryComponents";
import { notify } from "../../../utils/notify";

const path = `${process.env.REACT_APP_PAINEL_API_URL}/api/v1/entity`;
const newPath = `${process.env.REACT_APP_NEW_PAINEL_API_URL}/v1`;

export async function getEntities({ id }) {
  return search({ path: `${path}${id ? `/${id}` : ""}`, method: "GET" });
}

export async function getSystemsEntity({ id }) {
  return search({ path: `${path}/${id}/system`, method: "GET" });
}

export async function postEntity({ body }) {
  return search({ path: `${path}`, method: "POST", myBody: body });
}

export async function putEntity({ body, id }) {
  return search({ path: `${path}/${id}`, method: "PUT", myBody: body });
}

export async function getCustomersEntity({ id }) {
  return search({
    path: `${path}${id ? `/${id}` : ""}/clients`,
    method: "GET",
  });
}

export async function getCustomersEntityNew({ id }) {
  return search({
    path: `${newPath}/${id}/client`,
    method: "GET",
  });
}

export async function putBpo({ bpo_active, customerId }) {
  return search({
    path: `${path}/${customerId}/active-bpo?active=${bpo_active}`,
    method: "PUT",
  });
}

export async function postRequestVisit({ body, id }) {
  return search({
    path: `${path}/${id}/client-visit-request`,
    method: "POST",
    myBody: body,
  });
}

export async function postGenerateAccessLog({ entityId, userId }) {
  return search({
    path: `${path}/${entityId}/user/${userId}/generate-access-log`,
    method: "POST",
  });
}

export async function getUser(idEntity) {
  return search({ path: `${path}/${idEntity}/users`, method: "GET" });
}

export async function getPermission(idEntity) {
  return search({ path: `${path}/${idEntity}/system`, method: "GET" });
}

export async function getCustomersBpo() {
  return search({ path: `${path}/clients-status`, method: "GET" });
}

export async function getPanelsEntity(idEntity) {
  return search({ path: `${path}/${idEntity}/panel`, method: "GET" });
}

export async function getLogoBySubdomain(subdomain) {
  return search({
    path: `${path}/logo/by/subdomain/${subdomain}`,
    method: "GET",
  });
}

export async function getPromoVideoBySubdomain(subdomain) {
  return search({
    path: `${path}/promovideo/by/subdomain/${subdomain}`,
    method: "GET",
  });
}

export async function getExistsBySubdomain(subdomain) {
  return search({
    path: `${path}/exists/by/subdomain/${subdomain}`,
    method: "GET",
  });
}

export async function getWebsiteBySubdomain(subdomain) {
  return search({
    path: `${path}/website/by/subdomain/${subdomain}`,
    method: "GET",
  });
}

export async function postAccountantWebsite(body, idEntity) {
  return search({
    path: `${path}/${idEntity}/website`,
    method: "POST",
    myBody: body,
  });
}

export async function putAccountantWebsite(body, idEntity) {
  return search({
    path: `${path}/${idEntity}/website`,
    method: "PUT",
    myBody: body,
  });
}

export async function getEntityByDocument(cnpj) {
  return search({ path: `${path}/${cnpj}/find`, method: "GET" });
}

export async function deleteEntity(id) {
  return search({ path: `${path}/${id}/disable-accountant`, method: "DELETE" });
}

export async function getDataBannerBank({ id }) {
  return search({ path: `${path}/${id}/bank-status`, method: "GET" });
}

export async function getPermissionTree({ document }) {
  return search({
    path: `${path}/${document}/permissions`,
  });
}

export async function getPermissionGroup({ document, groupId }) {
  return search({
    path: `${path}/${document}/permission-group${groupId ? `/${groupId}` : ""}`,
    method: "GET",
  });
}

export async function postPermissionGroup({ document, body }) {
  return search({
    path: `${path}/${document}/permission-group`,
    method: "POST",
    myBody: body,
  });
}

export async function putPermissionGroup({ document, groupId, body }) {
  return search({
    path: `${path}/${document}/permission-group/${groupId}`,
    method: "PUT",
    myBody: body,
  });
}

export async function getUserPermission({
  document,
  userId,
  withDetails = false,
}) {
  return search({
    path: `${path}/${document}/user/${userId}/permission${
      withDetails ? "?withDetails=true" : ""
    }`,
    method: "GET",
  });
}

export async function postUserPermission({ document, userId, body }) {
  return search({
    path: `${path}/${document}/user/${userId}/permission`,
    method: "POST",
    myBody: body,
  });
}

export async function deletePermissionGroup({ document, groupId }) {
  return search({
    path: `${path}/${document}/permission-group/${groupId}`,
    method: "DELETE",
  });
}

export async function getSystemsInPreviewFromEntity() {
  return axios
    .get(`${newPath}/entity/${localStorage.getItem("companyIdPanel")}/beta`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("tokenPanel"),
        "Content-Type": "application/json",
        "Content-Language": "pt-BR",
      },
    })
    .then((response) => {
      return response.data.data;
    })
    .catch((err) => {
      notify("Ocorreu um erro ao buscar os sistemas em preview!");
      return null;
    });
}
