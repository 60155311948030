import React, { useState, useEffect } from "react";

import { Grid, CircularProgress, Typography, Modal } from "@material-ui/core";

import { createCompany } from "./events/CreateCompany";
import { notify } from "../../../utils/notify";
import { getMP3Audio } from "../../../repositories/AudioHandler";
import { validEmail } from "../../../utils/validations";
import CloseIcon from "@material-ui/icons/Close";
import { useContext } from "react";
import { GlobalContext } from "../../../contexts/GlobalContext";
import {
  BorderlessBodyStyle,
  ButtonCancel,
  ButtonSave,
  CircleIcon,
  DialogHeader,
  DialogStyle,
} from "../../../components/modal/Modal";
import MaskService from "../../../utils/mask/maskService";
import { CompanyCard } from "./styles/CompanyModal";
import SubdirectoryArrowLeftIcon from "@material-ui/icons/SubdirectoryArrowLeft";

export const CompanyModal = (props) => {
  const [companyListWithoutEmail, setCompanyListWithoutEmail] = useState([]);
  const { userLogged, companyUser } = useContext(GlobalContext);
  const { token } = userLogged;
  const { document } = companyUser;

  useEffect(() => {
    const newCompanyList = props.companyList.filter((item) => {
      if (item.email === "" || !validEmail(item.email || "")) return item;
      return false;
    });

    setCompanyListWithoutEmail([...newCompanyList]);
  }, [props.companyList]);

  const getTitle = () => {
    if (companyListWithoutEmail.length >= 1) {
      if (props.companyList.length === 1) {
        return "Detectei uma empresa, porém o arquivo não está no padrão esperado.";
      }

      if (props.companyList.length > 1) {
        return `Detectei uma lista de ${props.companyList.length} empresas, porém o arquivo não está no padrão esperado.`;
      }
    } else {
      if (props.companyList.length === 1) {
        return "Detectei uma empresa neste arquivo, posso criar essa empresa para você?";
      } else {
        return `Detectei uma lista de ${props.companyList.length} empresas neste arquivo, posso criar essas empresas para você?`;
      }
    }
  };

  const confirmCompany = async () => {
    props.loading(true);

    let emailError = false;
    let success = true;
    let companyWithError = null;
    for (let company of props.companyList) {
      if (
        company.email === null ||
        company.email === undefined ||
        company.email === ""
      ) {
        emailError = true;
        success = false;
        break;
      }

      let response = await createCompany(company, token, document);
      if (response === null) {
        companyWithError = company;
        success = false;
        break;
      }
    }

    if (emailError === true) {
      props.loading(false);

      notify("Informe o e-mail!", true, "success");

      emailError = false;
    } else {
      if (success) {
        notify(
          "Estamos realizando o cadastro de todas as empresas!",
          true,
          "success"
        );
        props.closeCompanyModal();
      } else {
        notify(
          `Ocorreu um problema ao tentar cadastrar a empresa ${
            companyWithError.name || companyWithError.companyName
          }! Tente novamente!`
        );
        props.closeCompanyModal();
      }
    }
  };

  useEffect(() => {
    if (props.companyList.length !== 0 && !props.loadingCompany) {
      let title = getTitle();
      getMP3Audio(title).then((result) => {
        var snd = new Audio("data:audio/wav;base64," + result);
        snd.play();
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.companyList.length, props.loadingCompany]);

  return (
    <Modal
      open={props.openModal}
      onClose={() => {
        props.closeCompanyModal();
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <DialogStyle>
        {props.loadingCompany === false && (
          <DialogHeader>
            <div className="row">
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginRight: 20,
                  marginBottom: 10,
                }}
              >
                {getTitle()}
              </div>

              <CloseIcon
                style={{
                  position: "absolute",
                  right: 0,
                  cursor: "pointer",
                  color: "rgba(126, 137, 158, 1)",
                }}
                onClick={() => {
                  props.closeCompanyModal();
                }}
              />
            </div>
          </DialogHeader>
        )}
        {props.loadingCompany === true && (
          <CircularProgress
            style={{
              marginLeft: "39%",
              width: 100,
              height: 100,
              marginTop: 74,
              marginBottom: 57,
            }}
          />
        )}

        {props.loadingCompany === false &&
          props.companyList.length !== 0 &&
          companyListWithoutEmail.length === 0 && (
            <div
              style={{
                paddingRight: 24,
                paddingLeft: 24,
                maxHeight: "300px",
                overflow: "auto",
              }}
            >
              {props.companyList.map((company, index) => {
                return (
                  <>
                    {index >= 1 && <br />}
                    <CompanyCard style={{ marginTop: index >= 1 ? 10 : 0 }}>
                      <Grid container>
                        <Grid
                          item
                          xs={2}
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <CircleIcon>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="30"
                              fill="none"
                              viewBox="0 0 24 30"
                            >
                              <path
                                fill="#0036B3"
                                fillRule="evenodd"
                                d="M1 2.5a1 1 0 0 1 1-1h4a1 1 0 0 1 1 1v6.382l6.553-3.276A1 1 0 0 1 15 6.5v2.382l6.553-3.276A1 1 0 0 1 23 6.5v16a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1v-20Zm2 1v18h18V8.118l-6.553 3.276A1 1 0 0 1 13 10.5V8.118l-6.553 3.276A1 1 0 0 1 5 10.5v-7H3Zm2 11a1 1 0 0 1 1-1h4a1 1 0 0 1 1 1v4a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1v-4Zm2 1v2h2v-2H7Zm6-1a1 1 0 0 1 1-1h4a1 1 0 0 1 1 1v4a1 1 0 0 1-1 1h-4a1 1 0 0 1-1-1v-4Zm2 1v2h2v-2h-2Z"
                                clipRule="evenodd"
                              />
                            </svg>
                          </CircleIcon>
                        </Grid>

                        <Grid item xs={10}>
                          <Typography
                            id="modal-modal-title"
                            style={{ fontWeight: 700, fontSize: 18 }}
                          >
                            {company.companyName}
                          </Typography>

                          <Typography id="modal-modal-title" variant="body2">
                            {company.clientCnpj.length === 14 && (
                              <>
                                CNPJ:{" "}
                                {MaskService.toMask("cnpj", company.clientCnpj)}
                              </>
                            )}

                            {company.clientCnpj.length === 11 && (
                              <>
                                CPF:{" "}
                                {MaskService.toMask("cpf", company.clientCnpj)}
                              </>
                            )}
                          </Typography>
                        </Grid>
                      </Grid>
                    </CompanyCard>
                  </>
                );
              })}

              {props.companyErrorList.map((company, index) => {
                return (
                  <>
                    {index >= 1 && <br />}
                    <CompanyCard style={{ marginTop: 10 }}>
                      <Grid container>
                        {/* {index >= 1 && <Divider style={{ width: "100%" }} />} */}

                        <Grid
                          item
                          xs={2}
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <CircleIcon backgroundColor="#FFDDDD">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="30"
                              fill="none"
                              viewBox="0 0 24 30"
                            >
                              <path
                                fill="red"
                                fillRule="evenodd"
                                d="M1 2.5a1 1 0 0 1 1-1h4a1 1 0 0 1 1 1v6.382l6.553-3.276A1 1 0 0 1 15 6.5v2.382l6.553-3.276A1 1 0 0 1 23 6.5v16a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1v-20Zm2 1v18h18V8.118l-6.553 3.276A1 1 0 0 1 13 10.5V8.118l-6.553 3.276A1 1 0 0 1 5 10.5v-7H3Zm2 11a1 1 0 0 1 1-1h4a1 1 0 0 1 1 1v4a1 1 0 0 1-1 1H6a1 1 0 0 1-1-1v-4Zm2 1v2h2v-2H7Zm6-1a1 1 0 0 1 1-1h4a1 1 0 0 1 1 1v4a1 1 0 0 1-1 1h-4a1 1 0 0 1-1-1v-4Zm2 1v2h2v-2h-2Z"
                                clipRule="evenodd"
                              />
                            </svg>
                          </CircleIcon>
                        </Grid>

                        <Grid item xs={10}>
                          <Typography
                            id="modal-modal-title"
                            style={{ fontWeight: 700, fontSize: 18 }}
                          >
                            {company.companyName}
                          </Typography>

                          <Typography id="modal-modal-title" variant="body2">
                            {company.clientCnpj.length === 14 && (
                              <>
                                CNPJ:{" "}
                                {MaskService.toMask("cnpj", company.clientCnpj)}
                              </>
                            )}

                            {company.clientCnpj.length === 11 && (
                              <>
                                CPF:{" "}
                                {MaskService.toMask("cpf", company.clientCnpj)}
                              </>
                            )}
                          </Typography>

                          <Typography
                            style={{
                              fontSize: 14,
                              color: "red",
                              fontWeight: "bold",
                            }}
                          >
                            {company.error ||
                              "Empresa não encontrada (possivelmente o CNPJ é inválido)"}
                          </Typography>
                        </Grid>
                      </Grid>
                    </CompanyCard>
                  </>
                );
              })}

              <div
                style={{
                  display: "flex",
                  justifyContent: "end",
                  marginTop: 20,
                  marginBottom: 20,
                }}
              >
                <ButtonCancel
                  style={{ marginRight: 16 }}
                  onClick={() => {
                    props.closeCompanyModal();
                  }}
                  startIcon={<SubdirectoryArrowLeftIcon />}
                >
                  Cancelar
                </ButtonCancel>
                &nbsp;
                <ButtonSave
                  onClick={async () => {
                    confirmCompany();
                  }}
                  disabled={props.disabled}
                >
                  {props.disabled ? "Carregando..." : "Cadastrar / Atualizar"}
                </ButtonSave>
              </div>
            </div>
          )}
      </DialogStyle>
    </Modal>
  );
};
