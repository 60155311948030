import React, { useContext, useEffect, useState } from "react";
import moment from "moment";

import {
  AccoutantPaymentSlipExpiration,
  TypographyAlertBill,
} from "../../sidebar/children/styles/NotifyPaymentSlipExpiration";
import { Button, Grid } from "@material-ui/core";
import { Close as CloseIcon } from "@material-ui/icons";
import { GlobalContext } from "../../../contexts/GlobalContext";
import {
  getBoletoById,
  getUserBilling,
} from "../../../repositories/integrations/erp/ContractQyonRepository";
import { ClientBillModal } from "../../../pages/dashboard/components/ClientBillModal";

export const AlertBillExpiration = ({ ...props }) => {
  const { companyUser } = useContext(GlobalContext);
  const [openDialogPaymentBill, setOpenDialogPaymentBill] = useState(false);
  const [daysBillExpiration, setDaysBillExpiration] = useState("");
  const [clientBillingData, setClientBillingData] = useState({
    loading: true,
    base64: "",
    name: "",
    dueDate: "",
    total: null,
    emittedDate: "",
    enableBoleto: false,
    typeableLine: "",
  });
  // eslint-disable-next-line no-unused-vars
  const [notificationOpen, setNotificationOpen] = useState(false);

  const buttonPaymentBill = async () => {
    setOpenDialogPaymentBill(true);

    if (!clientBillingData.enableBoleto) {
      return setClientBillingData((prev) => ({
        ...prev,
        loading: false,
        base64: "",
        name: "",
      }));
    }

    const response = await getBoletoById(clientBillingData.id);

    if (response?.success) {
      const { archive_base64, archive_name } = await response.data;

      setClientBillingData((prev) => ({
        ...prev,
        loading: false,
        base64: archive_base64,
        name: archive_name,
      }));
    } else {
      setClientBillingData((prev) => ({
        ...prev,
        loading: false,
        base64: "",
        name: "",
      }));
    }
  };
  const closeAlertDaysBill = () => {
    setNotificationOpen(false);
  };

  const closeDialogBill = () => {
    setOpenDialogPaymentBill(false);
  };

  useEffect(() => {
    (async () => {
      const response = await getUserBilling(companyUser.document);

      const billingList = response?.data?.find((item) => {
        return item.modalidade === "R";
      });

      if (billingList?.contas_receber) {
        const { contas_receber } = billingList;
        if (contas_receber.length > 0) {
          contas_receber.sort((a, b) => {
            if (a.datavencto < b.datavencto) return -1;
            if (a.datavencto > b.datavencto) return 1;
            return 0;
          });
          const {
            datavencto,
            id,
            valordocto,
            dataemissao,
            keyboleto,
            keyboletopix,
            keyboletoqyon,
            linha_digitavel,
          } = contas_receber[0];

          setClientBillingData((prev) => ({
            ...prev,
            id,
            dueDate: datavencto,
            total: valordocto,
            emittedDate: dataemissao,
            enableBoleto: !!keyboleto || !!keyboletopix || !!keyboletoqyon,
            typeableLine: linha_digitavel,
          }));

          const now = moment().format("YYYY-MM-DD");
          const diff = moment(now).diff(datavencto, "days") * -1;
          setDaysBillExpiration(diff);

          if (diff > 7) {
            setNotificationOpen(false);
          } else {
            setNotificationOpen(true);
          }
        }
      } else {
        if (response?.internalCode === 3031) {
          setNotificationOpen(false);
        }
      }
    })();
  }, [companyUser.document]);

  return (
    <>
      {false === true && (
        /*daysBillExpiration <= 1 &&
        daysBillExpiration !== -1 &&
        notificationOpen === true */ <AccoutantPaymentSlipExpiration
          container
          // colorMessage={"#ffd48a"}
          colorMarginLeft={"#FBB03B"}
          colorLetter={"black"}
        >
          <Grid item xs>
            <TypographyAlertBill>
              {daysBillExpiration === 1 ? (
                <strong> Falta {daysBillExpiration} dia</strong>
              ) : (
                <strong> Faltam {daysBillExpiration} dias</strong>
              )}{" "}
              para seu boleto vencer
            </TypographyAlertBill>
          </Grid>
          <Grid item>
            <Button
              size="small"
              variant="outlined"
              onClick={() => buttonPaymentBill()}
              style={{ backgroundColor: "white" }}
            >
              Ver boleto
            </Button>
          </Grid>
          <Grid item>
            <CloseIcon
              style={{
                fontSize: 20,
                cursor: "pointer",
              }}
              onClick={() => {
                closeAlertDaysBill();
              }}
            ></CloseIcon>
          </Grid>
        </AccoutantPaymentSlipExpiration>
      )}
      {openDialogPaymentBill === true && (
        <ClientBillModal
          openModal={openDialogPaymentBill}
          closeModal={closeDialogBill}
          data={clientBillingData}
        />
      )}
    </>
  );
};
