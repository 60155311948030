import { Menu, MenuItem } from "@material-ui/core";
import React from "react";

export default function MenuChangeStatusUser({
  anchorElementState,
  handleClose,
  statusListTemp,
  open,
  myState,
}) {
  return (
    <Menu
      style={{ zIndex: "99999999999", top: "5%" }}
      id="basic-menu"
      anchorEl={anchorElementState}
      open={open}
      onClose={handleClose}
      MenuListProps={{
        "aria-labelledby": "basic-button",
      }}
    >
      {statusListTemp?.map((e, index) => {
        return (
          <MenuItem
            selected={
              index === parseInt(typeof myState === "string" ? myState : "0")
            }
            onClick={(event) => handleClose(e?.state?.toString())}
          >
            {e?.description}
          </MenuItem>
        );
      })}
    </Menu>
  );
}
