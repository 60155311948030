import React, { useContext, useEffect, useState } from "react";

import { useHistory } from "react-router-dom";
import { GlobalVariables } from "../../GlobalVariables";

import { Grid, IconButton, InputAdornment } from "@material-ui/core";

import Loading from "../../components/basics/Loading";
import TextFieldErp from "../../components/basics/TextFieldErp";

import { GridStyled, Margin } from "../../styles/GlobalStyle";
import {
  BlueRectangleContainer,
  Copyright,
  EndDotRectangle,
  IconsRectangle,
  PrincipalContainer,
  QyonText,
  SideContainer,
  StartDotRectangle,
} from "./styles/Login";

import { LoginContext } from "./contexts/LoginContext";
import useLoginController from "./controllers/LoginController";
import {
  BlueRectangle,
  DotRectangle,
  LoginIcons,
  PasswordIcon,
  QyonLogo,
} from "./LoginIcon";
import {
  FormRecoveryPassword,
  LogoQyonContainer,
  RecoveryCancelButton,
  RecoveryContainer,
  RecoveryLabel,
  RecoveryPasswordHeader,
  RecoveryPasswordHeaderSecondary,
  RecoverySendButton,
} from "./styles/RecoveryPassword";
import PasswordRequirements from "./PasswordRequirements";

const View = () => {
  const { handleChangePassword } = useLoginController();
  const {
    password,
    setPassword,
    confirmNewPassword,
    setConfirmNewPassword,
    errors,
    loading,
  } = useContext(LoginContext);
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);

  const { push } = useHistory();

  useEffect(() => {
    setPassword("");
    setConfirmNewPassword("");
  }, [setPassword, setConfirmNewPassword]);

  let iconSize = 0;
  if (window.innerHeight <= 800) {
    iconSize = 400;
  } else {
    iconSize = 500;
  }

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowPasswordConfirm = () =>
    setShowPasswordConfirm((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  return (
    <>
      <Loading open={loading} />
      <PrincipalContainer>
        <SideContainer>
          <StartDotRectangle>
            <DotRectangle />
          </StartDotRectangle>
          <IconsRectangle>
            <LoginIcons width={iconSize} height={iconSize} />
          </IconsRectangle>
          <QyonText>
            Potencializando a contabilidade do futuro com Inteligência
            Artificial
          </QyonText>

          <BlueRectangleContainer>
            <BlueRectangle />
          </BlueRectangleContainer>

          <EndDotRectangle>
            <DotRectangle />
          </EndDotRectangle>
        </SideContainer>

        <RecoveryContainer>
          <div>
            <FormRecoveryPassword>
              <GridStyled>
                <Margin top="72" />
                <LogoQyonContainer>
                  <QyonLogo />
                </LogoQyonContainer>

                <Margin top="40" />

                <RecoveryPasswordHeader style={{ textAlign: "center" }}>
                  Troca de Senha
                </RecoveryPasswordHeader>
                <Margin top="8" />
                <RecoveryPasswordHeaderSecondary
                  style={{ textAlign: "center" }}
                >
                  No seu Primeiro acesso é necessario a alteração da senha
                </RecoveryPasswordHeaderSecondary>

                <Margin top="10" />
                <form onSubmit={handleChangePassword} autoComplete="off">
                  <Grid container justifyContent="center">
                    <GridStyled
                      justifyContent="center"
                      container
                      direction="column"
                    >
                      <Grid justifyContent="flex" container>
                        <RecoveryLabel>Nova Senha</RecoveryLabel>
                        <TextFieldErp
                          error={errors.password ? true : false}
                          helperText={errors.password || ""}
                          variant="outlined"
                          placeholder="Digite a nova senha"
                          type={showPasswordConfirm ? "text" : "password"}
                          inputProps={{
                            autoComplete: "off",
                          }}
                          value={password}
                          onChange={(e) => {
                            setPassword(e.target.value);
                          }}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                onClick={handleClickShowPasswordConfirm}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                              >
                                <PasswordIcon />
                              </IconButton>
                            </InputAdornment>
                          }
                        />
                      </Grid>

                      <Grid item>
                        <RecoveryLabel>Confirme a Senha</RecoveryLabel>
                        <TextFieldErp
                          error={errors.confirmNewPassword ? true : false}
                          helperText={errors.confirmNewPassword || ""}
                          variant="outlined"
                          placeholder="Repita a nova senha"
                          type={showPassword ? "text" : "password"}
                          value={confirmNewPassword}
                          setValue={setConfirmNewPassword}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                              >
                                <PasswordIcon />
                              </IconButton>
                            </InputAdornment>
                          }
                        />
                      </Grid>
                    </GridStyled>

                    <Margin top="10" />
                    <PasswordRequirements password={password} />

                    <Margin top="40" />
                    <Grid
                      container
                      spacing={3}
                      rowSpacing={10}
                      columnSpacing={{ xs: 1, sm: 3, md: 3 }}
                    >
                      <Grid item xs={6}>
                        <RecoveryCancelButton
                          type="submit"
                          full
                          variant="contained"
                          color="#364461"
                          label="Cancelar"
                          onClick={() => push("/login")}
                        >
                          Cancelar
                        </RecoveryCancelButton>
                      </Grid>
                      <Grid item xs={6}>
                        <RecoverySendButton
                          type="submit"
                          full
                          variant="contained"
                          color="primary"
                          label="Enviar"
                        >
                          Salvar
                        </RecoverySendButton>
                      </Grid>
                    </Grid>
                  </Grid>
                </form>
              </GridStyled>
            </FormRecoveryPassword>

            <Copyright>
              Todos os direitos reservados a QYON Sistemas Inteligentes © Versão{" "}
              {GlobalVariables.SISTEM_VERSION_DATE}
            </Copyright>
          </div>
        </RecoveryContainer>
      </PrincipalContainer>
    </>
  );
};

const ChangePassword = ({ ...props }) => {
  return <View {...props} />;
};

export { ChangePassword };
