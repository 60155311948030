import styled, { css } from "styled-components";

import { IconButtonStyled } from "../../../../styles/GlobalStyle";

const gray = (props) => props.theme.palette.gray;

export const Preview = styled.img`
  border-radius:3vh !important;
  cursor: pointer;
`;

export const DropContainer = styled.div.attrs({
  className: "dropzone",
})`
  ${(props) =>
    !props.uploaded &&
    css`
      border: 1px dashed ${gray};
      border-radius: 4px;
      background-color: #f5f5f9;
    `}

  cursor: pointer;
  transition: height 0.2s ease;
  position: relative;
  width:100%;
`;

export const UploadMessage = styled.p`
  color: ${gray};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 180px 8px;
`;

export const DropMessage = styled.p`
  color: ${(props) =>
    props.isDragReject ? props.theme.palette.red : props.theme.palette.gray};
  display: flex;
  align-items: center;
`;

export const DeleteImageButton = styled(IconButtonStyled)`
  position: absolute !important;
  right: 16px;
  top: 16px;
  background-color: ${props => props.$gray} !important;
  :hover {
    background-color: #FF6961 !important;
  }
`
