import { UploadXml } from "./UploadXml";
import axios from "axios";
import { notify } from "../../../utils/notify";

export const getRPSData = async (base64) => {
  const headers = {
    "Content-Type": "application/json",
  };
    let response = await axios.post(
      localStorage.getItem("environment") === "prod" ? 
      "https://qyon-bot.qyon.info/v1/bot/scan/rps" : 
      "https://qyon-bot-stg.qyon.info/v1/bot/scan/rps",
      {
        base64,
      },
      {
        headers: headers,
      }
    );
    return response.status === 200 ? response.data : null;
};

export const SendXml = async (xmlList) => {
  let error = false;
  for (let xml of xmlList) {
    let resp = await UploadXml(xml);
    if (resp.status !== 201) {
      error = true;
      break;
    }
  }

  if (error === true) {
    notify("Ocorreu um problema, tente novamente mais tarde!");
    return;
  }
};
