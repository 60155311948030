import React, { useContext } from "react";

import { Grid } from "@material-ui/core";

import VisibilityOutlinedIcon from "@material-ui/icons/VisibilityOutlined";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";

import { GridStyled, LabelStyled, Margin } from "../../../styles/GlobalStyle";

import {
  BodyCard,
  FooterCard,
  IconCard,
  PaperStyled,
} from "../styles/DashboardSettings";
import { DashboardSettingContext } from "../contexts/DashboardSettingContext";

export default function ListCard({ keyList = "", title = "" }) {
  const { widgets, idsWidgetsHomePage, setWidgetEdit } = useContext(
    DashboardSettingContext
  );

  return (
    <>
      {(widgets[keyList] || []).length ? (
        <GridStyled
          container
          item
          md={6}
          xs={12}
          justifyContent="center"
          alignItems="flex-start"
          spacing={1}
        >
          <GridStyled border-radius="8" container item spacing={2}>
            <Grid item container justifyContent="center">
              <LabelStyled color="blue" fontSize="25" fontWeight="700">
                {title}
              </LabelStyled>
            </Grid>
            {(widgets[keyList] || []).map((card) => {
              const active = card.widget_versions.find((version) =>
                idsWidgetsHomePage.includes(version.id)
              );

              return (
                <GridStyled
                  key={card.id}
                  item
                  lg={4}
                  md={6}
                  xs={6}
                  alignItems="stretch"
                  bgColor="red"
                >
                  <PaperStyled onClick={() => setWidgetEdit(card)}>
                    <Grid
                      container
                      justifyContent="center"
                      alignItems="stretch"
                    >
                      <BodyCard xs={12} item active={active}>
                        <GridStyled
                          item
                          container
                          alignItems="space-between"
                          justifyContent="center"
                        >
                          <GridStyled
                            item
                            xs={12}
                            overflow="hidden"
                            whiteSpace="nowrap"
                            margin="0px 0px 7px 0"
                          >
                            <LabelStyled cursor="pointer" fontSize="9">
                              {card.title || ""}
                            </LabelStyled>
                          </GridStyled>

                          {card.icon ? (
                            <IconCard src={card.icon} />
                          ) : (
                            <HelpOutlineIcon
                              style={{ height: 38, width: 38 }}
                            />
                          )}

                          <Margin bottom="10" />
                        </GridStyled>
                      </BodyCard>

                      <FooterCard
                        item
                        container
                        alignItens="center"
                        justifyContent="center"
                        active={active}
                      >
                        <Margin top="2" />
                        <LabelStyled
                          margin="0px 5px 0px 0"
                          fontSize="12"
                          color={`${active ? "white" : "gray"}`}
                        >
                          Visualizar
                        </LabelStyled>
                        <VisibilityOutlinedIcon
                          style={{
                            color: `${active ? "white" : "#8995a6"}`,
                            fontSize: 19,
                          }}
                        />
                      </FooterCard>
                    </Grid>
                  </PaperStyled>
                </GridStyled>
              );
            })}
          </GridStyled>
        </GridStyled>
      ) : (
        <></>
      )}
    </>
  );
}
