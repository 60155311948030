import { Switch } from "react-router-dom";

import MiddlewareRoute from "../Middleware";
import { GlobalVariables } from "../../GlobalVariables";

import { CndCustomerList } from "../../pages/cnd/CndCustomerList";
import { CndAccountantList } from "../../pages/cnd/CndAccountantList";

export default function Cnd() {
  return (
    <Switch>
      <MiddlewareRoute
        routePermissionId={GlobalVariables.PERMISSIONS.CND}
        path="/cnd/customer"
        sidebar
        component={CndCustomerList}
      />
      <MiddlewareRoute
        routePermissionId={GlobalVariables.PERMISSIONS.CND}
        path="/cnd/accountant"
        sidebar
        component={CndAccountantList}
      />
    </Switch>
  );
}
