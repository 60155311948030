import { Switch } from "react-router-dom";
import { ModalCurrentUserContextProvider } from "../../pages/currentUser/contexts/ModalCurrentUserContext";
import { ChatContextProvider } from "../../components/chat/contexts/ChatContext";
import ChatSettings from "../../pages/chatSettings/ChatSettings";
import MiddlewareRoute from "../Middleware";
import { GlobalVariables } from "../../GlobalVariables";

export default function ChatSetting() {
  return (
    <>
      <ChatContextProvider>
        <ModalCurrentUserContextProvider>
          <Switch>
            <MiddlewareRoute
              routePermissionId={GlobalVariables.PERMISSIONS.CRM}
              path="/chat/settings"
              component={ChatSettings}
              sidebar
            />
          </Switch>
        </ModalCurrentUserContextProvider>
      </ChatContextProvider>
    </>
  );
}
