import { DialogActions, DialogContent, Modal } from "@material-ui/core";
import React from "react";
import {
  ButtonCancel,
  ButtonSave,
  CloseIconStyle,
  DialogHeader,
  DialogStyle,
} from "../../../components/modal/Modal";
import SubdirectoryArrowLeftIcon from "@material-ui/icons/SubdirectoryArrowLeft";
import { notify } from "../../../utils/notify";
import { GetApp } from "@material-ui/icons";

export const DialogTXTPattern = ({ open, handleClose, ...props }) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogStyle>
        <DialogHeader id="alert-dialog-title">
          <div className="row">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginBottom: 20,
              }}
            >
              Padrão Arquivo de Texto (.txt)
            </div>
            <CloseIconStyle
              style={{ marginRight: "6px" }}
              onClick={() => {
                handleClose();
              }}
            />
          </div>
        </DialogHeader>
        <DialogContent>
          <div>
            Para realizar o cadastro simplificado de clientes através de um
            arquivo de texto (.txt) o mesmo deve seguir o padrão CNPJ;EMAIL; com
            um registro por linha, como demonstrado abaixo:
          </div>
          <br />
          <br />
          <div style={{ fontWeight: "bold" }}>
            <div>12.345.678/9012-34;email@cliente1.com;</div>
            <div>98.765.432/1098-76;email@cliente2.com;</div>
          </div>
          <br />
          <div>
            Você pode baixar um arquivo de exemplo clicando no botão abaixo.
          </div>
        </DialogContent>
        <DialogActions style={{ paddingTop: 24, paddingBottom: 24 }}>
          <ButtonCancel
            style={{
              marginRight: 24,
              float: "right",
            }}
            onClick={() => {
              handleClose();
            }}
            startIcon={<SubdirectoryArrowLeftIcon />}
          >
            Fechar
          </ButtonCancel>
          <ButtonSave
            startIcon={<GetApp />}
            onClick={() => {
              fetch(
                "https://qyon-core-docs.s3.sa-east-1.amazonaws.com/portal/template_cadastro_cliente.txt"
              ).then((response) => {
                response
                  .blob()
                  .then((blob) => {
                    let url = window.URL.createObjectURL(blob);
                    let a = document.createElement("a");
                    a.href = url;
                    a.download = "template_cadastro_cliente.txt";
                    a.click();
                  })
                  .catch((error) => {
                    console.error(error);
                    notify("error", true, "Erro ao baixar o arquivo");
                  });
              });
            }}
          >
            Download
          </ButtonSave>
        </DialogActions>
      </DialogStyle>
    </Modal>
  );
};
