import { useContext, useState } from "react";

import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@material-ui/core";
import { AddCircle, Delete } from "@material-ui/icons";

import TextFieldErp from "../../../components/basics/TextFieldErp";
import Btn from "../../../components/buttons/Btn";
import { ContainerIcon, GridStyled, Title } from "../../../styles/GlobalStyle";
import { DialogCnd } from "../styles/CndCustomer";
import { useCndGeneralController } from "../controllers/CndGeneralController";
import Loading from "../../../components/basics/Loading";
import { GlobalContext } from "../../../contexts/GlobalContext";

export default function ModalSendEmail({ handleClose, row }) {
  const [emails, setEmails] = useState([row.defaultMail]);
  const [loading, setLoading] = useState(false);
  const { companyUser } = useContext(GlobalContext);

  const handleAdd = () => {
    if (emails.length < 5) {
      setEmails([...emails, ""]);
    }
  };

  const handleRemove = (index) => {
    const copy = [...emails];
    copy.splice(index, 1);
    setEmails(copy);
  };

  const { handleSendEmail } = useCndGeneralController();

  return (
    <>
      <DialogCnd
        open={true}
        maxWidth="sm"
        fullWidth
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <Title subtitle>Enviar E-mail</Title>
        </DialogTitle>
        <form
          onSubmit={(e) =>
            handleSendEmail({
              e,
              emails,
              row,
              handleClose,
              setLoading,
              parentDocument: companyUser.document,
            })
          }
        >
          <DialogContent>
            <GridStyled margin="0px 0px 10px 0" container>
              {emails.map((email, index) => (
                <Grid container spacing={2} key={index} alignItems="center">
                  <Grid item xs>
                    <TextFieldErp
                      type="email"
                      required
                      label={`${index + 1}º Email`}
                      lg={2}
                      value={emails[index]}
                      setValue={(value) => {
                        const copy = [...emails];
                        copy[index] = value.toLowerCase();
                        setEmails(copy);
                      }}
                    />
                  </Grid>
                  <Grid item flex={1}>
                    <GridStyled
                      margin="6px 0px 0px 0"
                      item
                      container
                      alignItems="center"
                    >
                      {index === emails.length - 1 && emails.length < 5 && (
                        <ContainerIcon iconColor="black">
                          <AddCircle onClick={handleAdd} />
                        </ContainerIcon>
                      )}

                      {index !== 0 && (
                        <ContainerIcon iconColor="red">
                          <Delete onClick={() => handleRemove(index)} />
                        </ContainerIcon>
                      )}
                    </GridStyled>
                  </Grid>
                </Grid>
              ))}
            </GridStyled>
          </DialogContent>

          <DialogActions>
            <Btn label="Cancelar" bg-color="tertiary" onClick={handleClose} />
            <Btn label="Enviar" type="submit" />
          </DialogActions>
        </form>
      </DialogCnd>
      {loading && <Loading open={loading} />}
    </>
  );
}
