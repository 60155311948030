import { ListItem } from "@material-ui/core";
import React from "react";
import { BoxStyled, TypographyStyled } from "../styles/GeneralStyle";
import Group from "./Group";
import GroupItem from "./GroupItem";

export default function RenderContactsList({
  listContacts,
  setCallCustomer,
  fieldValue,
  setFieldValue,
  setFlag = () => {},
  openConversationInRoom,
  listUpdateContacts,
}) {
  setFlag("contatos");
  return (
    <Group fieldValue={fieldValue} setFieldValue={setFieldValue}>
      {listUpdateContacts &&
        listUpdateContacts.map(
          (i, index) =>
            i.name && (
              <GroupItem
                key={index}
                title={i?.name}
                email={i?.email}
                sessionId={i?.id}
                tooltipSubTitle={i?.customer?.fantasyName || i?.name}
                subTitle={(
                  i?.customer?.fantasyName ||
                  i?.customer?.socialName ||
                  i?.fantasyName ||
                  i?.name
                ).substring(0, 20)}
                setCallCustomer={setCallCustomer}
                openConversationInRoom={openConversationInRoom}
              />
            )
        )}
      {listContacts && listContacts?.length === 0 && (
        <ListItem alignItems="flex-start">
          <BoxStyled
            width="100%"
            textAlign="center"
            paddingTop={2}
            paddingBottom={2}
          >
            <TypographyStyled display="inline" component="span" color="gray">
              Você não possui nenhum contato!
            </TypographyStyled>
          </BoxStyled>
        </ListItem>
      )}
    </Group>
  );
}
