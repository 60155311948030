import { Tabs } from "@material-ui/core";
import { TabPanel } from "@material-ui/lab";
import styled from "styled-components";

export const CenteredContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const CustomBillTabs = styled(Tabs)`
  .MuiTabs-indicator {
    background-color: #0036b3;
  }
`;

export const CustomBillTabPanel = styled(TabPanel)`
  padding: 0px;
  margin: 0px;
  overflow: hidden;
`;
