import { search } from "../../BaseRepositoryComponents";

const token = process.env.REACT_APP_ERP_API_TOKEN;

const headers = new Headers();
headers.append("Authorization", token);
headers.append("content-type", "application/json");
headers.append("Content-Language", "pt-BR");

export async function getUserBilling(cnpj) {
  return search({
    path: `${process.env.REACT_APP_ERP_API_URL}/api/erp/faturamento/contratos/v1/contasreceber-abertos?empresa_cnpj=38469354000152&cliente_cpfcnpj=${cnpj}`,
    myHeaders: headers,
  });
}

export async function getBoletoById(id) {
  return search({
    path: `${process.env.REACT_APP_ERP_API_URL}/api/erp/financeiro/contasreceber/v1/downloadboleto/${id}?empresa_cnpj=38469354000152`,
    myHeaders: headers,
  });
}
