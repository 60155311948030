import {
  Checkbox,
  DialogActions,
  DialogContent,
  FormControlLabel,
  Grid,
  Modal,
  TextField,
} from "@material-ui/core";
import React, { useEffect } from "react";
import {
  ButtonCancel,
  ButtonSave,
  CloseIconStyle,
  DialogHeader,
  DialogStyle,
} from "../../../components/modal/Modal";

import listCities from "../../../utils/cities.json";
import listCountries from "../../../utils/countries.json";
import listStates from "../../../utils/states.json";
import SelectErp from "../../../components/basics/SelectErp";

import SubdirectoryArrowLeftIcon from "@material-ui/icons/SubdirectoryArrowLeft";
import MaskService from "../../../utils/mask/maskService";
import { findHubByCEP } from "../../../repositories/integrations/Hub";
import Loading from "../../../components/basics/Loading";
import { notify } from "../../../utils/notify";
export const DialogAddress = ({
  address,
  open,
  handleClose,
  handleSave,
  ...props
}) => {
  const [selectedCity, setSelectedCity] = React.useState(undefined);
  const [selectedCountry, setSelectedCountry] = React.useState(undefined);
  const [selectedState, setSelectedState] = React.useState(undefined);

  const [cep, setCep] = React.useState("");
  const [street, setStreet] = React.useState("");
  const [district, setDistrict] = React.useState("");
  const [addressNumber, setAddressNumber] = React.useState("");
  const [addressComplement, setAddressComplement] = React.useState("");
  const [mainAddress, setMainAddress] = React.useState(false);

  const [loading, setLoading] = React.useState(false);

  const validateFields = () => {
    if (!cep) {
      return false;
    }
    if (cep.replace(/[^0-9]+/g, "").length !== 8) {
      return false;
    }
    if (!street) {
      return false;
    }
    if (!district) {
      return false;
    }
    if (!addressNumber && addressNumber !== 0) {
      return false;
    }
    if (!selectedCity) {
      return false;
    }
    if (!selectedState) {
      return false;
    }
    if (!selectedCountry) {
      return false;
    }
    return true;
  };

  useEffect(() => {
    if (address?.id) {
      setCep(MaskService.toMask("cep", address.postal_code || ""));
      setStreet(address.street || "");
      setDistrict(address.district || "");
      setAddressComplement(address.address_complement || null);
      setAddressNumber(address.address_number || 0);
      setMainAddress(address.main_address || false);

      setSelectedCity(
        listCities.find((city) => city.id === address.city_id) || null
      );

      setSelectedState(
        listStates.find((state) => state.id === address.state_id) || null
      );

      setSelectedCountry(
        listCountries.find((opt) => opt?.name.toString() === "Brasil") || null
      );
    } else {
      setSelectedCity(null);
      setSelectedState(null);
      setSelectedCountry(null);
      setCep("");
      setStreet("");
      setDistrict("");
      setAddressComplement("");
      setAddressNumber("");
      setMainAddress(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address?.id, open]);

  const handleChangeCep = async (value) => {
    setCep(MaskService.toMask("cep", value || ""));

    if (value.replace(/[^0-9]+/g, "").length === 8) {
      setLoading(true);
      try {
        const response = await findHubByCEP(value.replace(/[^0-9]+/g, ""));

        setSelectedCity(
          listCities.find((city) => city.name === response.result.city || null)
        );

        setSelectedState(
          listStates.find((state) => state.uf === response.result.state) || null
        );

        setSelectedCountry(
          listCountries.find((opt) => opt?.name.toString() === "Brasil") || null
        );

        setStreet(response.result.street || "");
        setDistrict(response.result.neighborhood || "");
        setAddressComplement(response.result.complement || null);
        setAddressNumber(0);

        document.getElementById("addressNumber").focus();
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <>
      <Loading open={loading}></Loading>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogStyle>
          <DialogHeader id="alert-dialog-title">
            <div className="row">
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginBottom: 20,
                }}
              >
                {address?.id ? "Editar" : "Cadastrar"} Endereço
              </div>
              <CloseIconStyle
                style={{ marginRight: "6px" }}
                onClick={() => {
                  handleClose();
                }}
              />
            </div>
          </DialogHeader>
          <DialogContent>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <div>CEP</div>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  placeholder="CEP"
                  value={cep}
                  onChange={(e) => {
                    handleChangeCep(e.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <div>País</div>
                <SelectErp
                  variant="outlined"
                  placeholder="País"
                  value={selectedCountry}
                  setValue={setSelectedCountry}
                  optionsList={listCountries}
                  descriptionField="name"
                />
              </Grid>
              <Grid item xs={2}>
                <div>Estado (UF)</div>
                <SelectErp
                  variant="outlined"
                  placeholder="UF"
                  value={selectedState}
                  setValue={setSelectedState}
                  optionsList={listStates}
                  descriptionField="uf"
                />
              </Grid>
              <Grid item xs={6}>
                <div>Cidade</div>
                <SelectErp
                  variant="outlined"
                  placeholder="Cidade"
                  value={selectedCity}
                  setValue={setSelectedCity}
                  optionsList={listCities}
                  descriptionField="name"
                />
              </Grid>
              <Grid item xs={6}>
                <div>Bairro</div>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  placeholder="Bairro"
                  value={district}
                  onChange={(e) => setDistrict(e.target.value)}
                />
              </Grid>
              <Grid item xs={6}>
                <div> Endereço</div>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  placeholder="Endereço"
                  value={street}
                  onChange={(e) => setStreet(e.target.value)}
                />
              </Grid>
              <Grid item xs={6}>
                <div>Número</div>
                <TextField
                  id="addressNumber"
                  type="number"
                  variant="outlined"
                  size="small"
                  fullWidth
                  placeholder="Número"
                  value={addressNumber}
                  InputProps={{ inputProps: { min: 0 } }}
                  onChange={(e) => {
                    if (e.target.value.length < 0) {
                      setAddressNumber(null);
                    }
                    setAddressNumber(e.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <div>Complemento</div>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  placeholder="Complemento"
                  value={addressComplement}
                  onChange={(e) => setAddressComplement(e.target.value)}
                />
              </Grid>

              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      checked={mainAddress}
                      onChange={(e) => {
                        setMainAddress(e.target.checked);
                      }}
                    />
                  }
                  label="Considerar como endereço principal"
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions style={{ paddingTop: 24, paddingBottom: 24 }}>
            <ButtonCancel
              style={{
                marginRight: 24,
                float: "right",
              }}
              onClick={handleClose}
              startIcon={<SubdirectoryArrowLeftIcon />}
            >
              {"Cancelar"}
            </ButtonCancel>
            <ButtonSave
              style={{
                float: "right",
                right: 14,
              }}
              onClick={() => {
                if (!validateFields()) {
                  notify("Preencha todos os campos");
                  return;
                }

                const newAddress = {
                  ...address,
                  id: address?.id || "NEW_" + Math.random(),
                  postal_code: cep.replace(/[^0-9]+/g, ""),
                  street,
                  district,
                  address_number: addressNumber,
                  address_complement: addressComplement || null,
                  main_address: mainAddress,
                  city_id: selectedCity?.id,
                  state_id: selectedState?.id,
                  country_id: selectedCountry?.id,
                };

                handleSave(newAddress);
              }}
            >
              {"Enviar"}
            </ButtonSave>
          </DialogActions>
        </DialogStyle>
      </Modal>
    </>
  );
};
