import { Container, Title } from "./styles/Subheader.js";
import { Grid } from "@material-ui/core";

export const SubHeader = ({ title, rightComponent }) => {
  return (
      <Container container alignItems="center" justify="space-between">
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Title>{title}</Title>
        </Grid>
        <Grid item xs={12} sm={5} md={5} lg={5} xl={5}>
          {rightComponent}
        </Grid>
      </Container>
  );
};
