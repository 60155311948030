import styled from "styled-components";

import { Grid } from "@material-ui/core";

const blue = (props) => props.theme.palette.blue;

export const ContainerInterrogation = styled(Grid)`
  position: absolute;
  width: 280px;
  height: 300px;
  top: 270px;
  right: 300px;
  z-index: 9999;
`;

export const CardInterrogation = styled(Grid)`
  border-radius: 20px;
  border: solid 3px ${blue};
  height: 100%;
  background-color: white;
`;
