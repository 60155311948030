/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useRef, useState } from "react";

import { useHistory } from "react-router";

import { Button, Grid, LinearProgress, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import GridDragDrop from "../../components/grid-drag-drop/GridDragDrop";
import TabCustom from "../../components/tabs/TabCustom";
import { GlobalContext } from "../../contexts/GlobalContext";
import { getPanelPositions } from "../../repositories/panel/v1/PanelRepository";
import { getPanelsUser } from "../../repositories/panel/v1/UserRepository";
import { Pulse } from "../../styles/GlobalStyle";
import { notify } from "../../utils/notify";
import { usePortalAsClient } from "../../utils/viewPortalAsClientHook";
import BannerQyonBank from "./children/BannerQyonBank";
import ModalNews from "./children/ModalNews";
import VideoFirstAccess from "./children/VideoFirstAccess";
import { DashboardViewContext } from "./contexts/DashboardViewContext";
import useDashboardViewController from "./controllers/DashboardViewController";
import { Subheader } from "./components/SubHeader";
import { AlertBillExpiration } from "../../components/layoutContainer/components/AlertBillExpiration";
/*import { NoWidgets } from "./DashboardViewIcons";
import {
  AddFirstButton,
  AddFirstContainer,
  AddFirstText,
  EmptyDashboardContainer,
  EmptyDashboardHeader,
  NoWidgetsMessage,
  WidgetContainer,
} from "./styles/DashboardView";
*/
import { getWidgets } from "../../repositories/panel/v1/WidgetRepository";
import { DefaultPageContainer } from "../../components/layoutContainer/styles/LayoutContainer";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  label: {
    fontSize: "12px",
    color: "#2887f2",
    fontWeight: "bold",
    textTransform: "none",
  },
  large: {
    width: theme.spacing(15),
    height: theme.spacing(15),
  },
  button: {
    margin: theme.spacing(1),
  },
  logo: {
    maxWidth: 400,
    maxHeight: 80,
    cursor: "pointer",
  },

  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: "#fff",
    color: "#000",
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    flexGrow: 1,
  },
}));

// Links para referencia - itens não implementados - React-Grid-Layout (View this example's source)
// https://react-grid-layout.github.io/react-grid-layout/examples/14-toolbox.html
// https://react-grid-layout.github.io/react-grid-layout/examples/15-drag-from-outside.html
// https://react-grid-layout.github.io/react-grid-layout/examples/16-bounded.html

const DashboardView = ({ location }) => {
  const containerWidgetsRef = useRef(null);

  const [sizeDragDrop, setSizeDragDrop] = useState(0);
  const { layoutDashboard, setLayoutDashboard } =
    useContext(DashboardViewContext);
  const { sidebar, companyUser, userLogged, animation, reloadWidgets } =
    useContext(GlobalContext);
  const classes = useStyles();
  const {
    panelSelected,
    setPanelSelected,

    panelsList,
    setPanelsList,
    setLoading,
    loading,
  } = useContext(DashboardViewContext);
  const { handleSavePositions } = useDashboardViewController();
  const { push } = useHistory();
  const { isInClientView, clientViewed } = usePortalAsClient();

  useEffect(() => {
    if (reloadWidgets === null) {
      return;
    }

    loadWidgets();
  }, [reloadWidgets]);

  useEffect(
    () =>
      location?.state?.restrictPageRedirect &&
      notify("Você foi redirecionado! Página com acesso restrito"),
    [location]
  );

  const [modalNewsOpen, setModalNewsOpen] = useState(false);
  const [showNewsButton, setShowNewsButton] = useState(
    companyUser?.contracts?.length > 0
  );

  useEffect(() => {
    setPanelSelected(null);
  }, [setPanelSelected]);

  useEffect(() => {
    (async () => {
      try {
        setLoading((current) => ({ ...current, panelsList: true }));

        const panelsResp = await getPanelsUser({
          id: isInClientView ? clientViewed?.id : userLogged?.userId,
          ownerentity: isInClientView && clientViewed?.company,
        });

        if (panelsResp?.success && Array.isArray(panelsResp?.data)) {
          setPanelsList(panelsResp?.data);
          setPanelSelected(
            panelsResp?.data.find((p) => p.favorited) ||
              panelsResp?.data[0] ||
              null
          );
          return;
        }

        setPanelSelected(null);
        setPanelsList([]);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading((current) => ({ ...current, panelsList: false }));
      }
    })();
  }, [
    userLogged?.userId,
    setLoading,
    setPanelSelected,
    setPanelsList,
    isInClientView,
    clientViewed,
  ]);

  useEffect(() => {
    if (!panelSelected?.id) {
      setLayoutDashboard([]);
      return;
    }

    loadWidgets();
  }, [panelSelected, setLoading, isInClientView, setLayoutDashboard]);

  function debounce(fn, ms) {
    let timer;
    return (_) => {
      clearTimeout(timer);
      timer = setTimeout((_) => {
        timer = null;
        fn.apply(this, arguments);
      }, ms);
    };
  }

  const loadWidgets = async () => {
    try {
      setLoading((current) => ({ ...current, widgets: true }));

      const respWidgets = await getPanelPositions({ id: panelSelected?.id });

      let widgetsDisplay = [];
      const itemsNotDuplicated = [];

      for (let item of respWidgets.data) {
        if (!item.widget_version) {
          continue;
        }
        let itemId = item.widget_version.id;

        if (itemsNotDuplicated.includes(itemId)) {
          continue;
        }

        if (item.widget_version.widget.id === 189) {
          setShowNewsButton(false);
        }

        widgetsDisplay.push({
          id: item.id,
          i: item.widget_version.id.toString(),
          x: item.postion_x,
          y: item.position_y,
          w: item.width || item.widget_version.minimum_width || 1,
          h: item.height || item.widget_version.minimum_height || 1,
          minW: item.widget_version.minimum_width || 1,
          minH: item.widget_version.minimum_height || 1,
          maxW: item.widget_version.maximum_width || 12,
          maxH: item.widget_version.maximum_height || 12,
          titulo: item.widget_version.widget.title,
          hideHeader: item.widget_version.hide_header,
          stringChildren: item.widget_version.widget_body,
          importScripts: (item.widget_version?.external_scripts || "").split(
            ","
          ),
          stringScripts: [item.widget_version.internal_scripts],
          onRefresh: item.widget_version.refresh_function,
          refreshInterval: item.widget_version.refresh_interval
            ? parseInt(item.widget_version.refresh_interval)
            : 0,
          refreshOnLoad: true,
          visualizar: item.widget_version.active,
        });

        itemsNotDuplicated.push(item.widget_version.id);
      }

      if (widgetsDisplay.length === 0) {
        let response = await getWidgets(
          userLogged.userId,
          companyUser.companyId
        );
        if (response.code === 200) {
          const widgetIds = [181, 153, 155, 156, 187];
          const widgetPosition = [
            {
              id: 153,
              x: 0,
              y: 13,
              w: 12,
              h: 4,
              minW: 12,
              minH: 4,
              maxW: 12,
              maxH: 4,
            },
            {
              id: 155,
              x: 3,
              y: 6,
              w: 4,
              h: 7,
              minW: 4,
              minH: 6,
              maxW: 12,
              maxH: 7,
            },
            {
              id: 156,
              x: 0,
              y: 6,
              w: 3,
              h: 7,
              minW: 3,
              minH: 6,
              maxW: 12,
              maxH: 12,
            },
            {
              id: 181,
              x: 0,
              y: 0,
              w: 12,
              h: 6,
              minW: 3,
              minH: 4,
              maxW: 12,
              maxH: 12,
            },
            {
              id: 187,
              x: 7,
              y: 6,
              w: 5,
              h: 7,
              minW: 1,
              minH: 1,
              maxW: 12,
              maxH: 12,
            },
          ];
          response.data.forEach((item) => {
            if (
              widgetIds.includes(item.id) &&
              item.widget_versions.length !== 0 &&
              item.access_type === "public"
            ) {
              const { id, x, y, w, h, minW, minH, maxW, maxH } =
                widgetPosition.filter((e) => e.id === item.id)[0];
              widgetsDisplay.push({
                id: id,
                i: item.widget_versions[0].id.toString(),
                x: x,
                y: y,
                w: w,
                h: h,
                minW: minW,
                minH: minH,
                maxW: maxW,
                maxH: maxH,
                titulo: item.title,
                hideHeader: item.widget_versions[0].hide_header,
                stringChildren: item.widget_versions[0].widget_body,
                importScripts: (
                  item.widget_versions[0]?.external_scripts || ""
                ).split(","),
                stringScripts: [item.widget_versions[0].internal_scripts],
                onRefresh: item.widget_versions[0].refresh_function,
                refreshInterval: item.widget_versions[0].refresh_interval
                  ? parseInt(item.widget_versions[0].refresh_interval)
                  : 0,
                refreshOnLoad: true,
                visualizar: item.widget_versions[0].active,
              });

              itemsNotDuplicated.push(item.widget_versions[0].id);
            }
          });
        }

        setLayoutDashboard(widgetsDisplay);
        localStorage.setItem(
          `widgesPositionsPanel_${panelSelected?.id}`,
          widgetsDisplay
        );
      } else {
        setLayoutDashboard(widgetsDisplay);
        localStorage.setItem(
          `widgesPositionsPanel_${panelSelected?.id}`,
          widgetsDisplay
        );
      }
    } catch (error) {
      console.log(error);

      notify("Não foi possível obter os dados.");
    } finally {
      setLoading((current) => ({ ...current, widgets: false }));
    }
  };

  // useEffect que roda ao renderizar, para setar o width inicial do container
  useEffect(() => {
    setSizeDragDrop(containerWidgetsRef?.current?.offsetWidth - 50 || 0);
  }, []);

  // useEffect que roda sempre que a tela sofrer um "resize" para setar
  // a largura do container.
  useEffect(() => {
    const debouncedHandleContainer = debounce(function handleResize() {
      setSizeDragDrop(containerWidgetsRef?.current?.offsetWidth - 50 || 0);
    }, 200);

    window.addEventListener("resize", debouncedHandleContainer);

    return () => window.removeEventListener("resize", debouncedHandleContainer);
  }, [
    setSizeDragDrop,
    sidebar.open,
    containerWidgetsRef?.current?.offsetWidth,
  ]);

  const onLayoutChange = (layouts) => {
    try {
      localStorage.setItem(
        `widgesPositionsPanel_${panelSelected?.id}`,
        JSON.stringify(layouts)
      );
    } catch (error) {
      console.error(error);
    }
  };

  const children = [
    loading.panelsList || loading.widgets ? (
      <LinearProgress key={0} />
    ) : (
      <>
        {/*layoutDashboard.length === 0 && (
          
          <EmptyDashboardContainer>
            <EmptyDashboardHeader>
              Escolha uma opção para começar a visualizar os cards.
            </EmptyDashboardHeader>
            <WidgetContainer>
              <NoWidgets />
              <NoWidgetsMessage>
                Não há widgets para mostrar nesta exibição
              </NoWidgetsMessage>

              <AddFirstContainer>
                <AddFirstText>Deseja adicionar o primeiro widget?</AddFirstText>
                <AddFirstButton>Adicionar</AddFirstButton>
              </AddFirstContainer>
            </WidgetContainer>
          </EmptyDashboardContainer>
          
         <></>
        )*/}
        {
          /*layoutDashboard.length !== 0 && (*/

          <GridDragDrop
            key={1}
            width={sizeDragDrop}
            onLayoutChange={(layout) => {
              onLayoutChange(layout, 0);
              handleSavePositions();
            }}
            layout={layoutDashboard}
          />
          /*)*/
        }
      </>
    ),
  ];

  // const handleOpenModalNews = () => {
  //   setModalNewsOpen(true);
  // };

  /*

  */
  return (
    // FIXME: Utilizar o componente DefaultPageContainer para todas as páginas
    <>
      <Subheader />
      <div>
        <BannerQyonBank />
        {!!(
          userLogged.showVideoIntro === "true" &&
          companyUser.isAccountant === "true"
        ) && <VideoFirstAccess />}

        {/* Exibição temporária do card contratos qyon */}
        {modalNewsOpen && (
          <ModalNews open={modalNewsOpen} setOpen={setModalNewsOpen} />
        )}

        <Grid container>
          <Grid item xs={12}>
            <AlertBillExpiration />

            <Grid
              item
              container
              justifyContent="center"
              style={{ width: "100%" }}
              ref={containerWidgetsRef}
            >
              <Pulse active={animation === 2} style={{ width: "100%" }}>
                <TabCustom children={children} tabIndex={0} />
              </Pulse>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default DashboardView;
