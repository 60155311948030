import React, { createContext, useState } from "react";

const ConfigureERPContext = createContext();

const ConfigureERPContextProvider = ({ children }) => {
  const [comercialContactName, setComercialContactName] = useState("");
  const [comercialContactDocument, setComercialContactDocument] = useState("");
  const [comercialContactPhone, setComercialContactPhone] = useState("");
  const [comercialContactEmail, setComercialContactEmail] = useState("");
  const [
    comercialContactAlreadyRequested,
    setComercialContactAlreadyRequested,
  ] = useState(false);

  const [erpPlansList, setErpPlansList] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState({});

  return (
    <ConfigureERPContext.Provider
      value={{
        comercialContactName,
        setComercialContactName,
        comercialContactDocument,
        setComercialContactDocument,
        comercialContactPhone,
        setComercialContactPhone,
        erpPlansList,
        setErpPlansList,
        selectedPlan,
        setSelectedPlan,
        comercialContactEmail,
        setComercialContactEmail,
        comercialContactAlreadyRequested,
        setComercialContactAlreadyRequested,
      }}
    >
      {children}
    </ConfigureERPContext.Provider>
  );
};

export { ConfigureERPContext, ConfigureERPContextProvider };
