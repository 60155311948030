import { Button, Grid } from "@material-ui/core";
import Modal from "@material-ui/core/Modal";
import CloseIcon from "@material-ui/icons/Close";
import React, { useContext, useEffect, useState } from "react";
import { getMP3Audio } from "../../repositories/AudioHandler";
import { ClassifyDocuments } from "./controllers/ClassifyDoc";
import { getSubtitle, getTitle } from "./controllers/Message";
import { SendXml } from "./controllers/SendXML";

import { DocumentErrorCNPJ } from "./children/Documents";
import {
  BodyStyle,
  CircularProgressStyled,
  DivNew,
  DivStyle,
  TypographyStyle,
} from "./styles/XmlModalCss";
import { NewBotContext } from "../newBot/context/NewBotContext";
import { CloseIconStyle, DialogHeader, DialogStyle } from "../modal/Modal";

const RenderItem = (xml, index, botContext, props, functionAI) => {
  return (
    <Grid container spacing={0} key={"XML" + index}>
      <Grid item xs={12}>
        {ClassifyDocuments(xml, botContext, props, functionAI)}
      </Grid>
      <DivStyle />
    </Grid>
  );
};

const functionAI = (xml, modalProps, botContext) => {
  const prompt = [xml.xml, "gere 5 perguntas sobre esse xml"];
  botContext.setBotTextContext(prompt);
  botContext.setBotCurrentContext("XML_UPLOAD");
  botContext.setBotMode("CHAT_CONTEXT");
  botContext.setBotPresentationRunning(null);
  botContext.setOpenBotChatModal(true);
  modalProps.closeXmlModal();
};

export const XmlModal = (props) => {
  const botContext = useContext(NewBotContext);

  const [xmlList, setXmlList] = useState([]);
  const [sendButtonVisible, setSendButtonVisible] = useState(true);
  const [xmlNotPartClients, setxmlNotPartClients] = useState([]);

  useEffect(() => {
    if (props.loading) {
      return;
    }

    if (!props.clients) {
      return;
    }

    const getTitleEffect = () => {
      return props.xmlList.length === 1
        ? "Identifiquei um XML - O que você deseja fazer com ele?"
        : `Identifiquei uma lista de ${props.xmlList.length} XMLs neste arquivo`;
    };

    let listaCnpjs = [];

    if (props.clients.data) {
      listaCnpjs = props.clients.data.map((e) => e.document);
    }
    let xmlListTmp = [];
    let xmlTempNotPartClients = [];

    for (let xml of props.xmlList) {
      let find = false;

      if (
        xml.data.xmlType === "NFe" ||
        xml.data.xmlType === "CTe" ||
        xml.data.xmlType === "CFe"
      ) {
        for (let cnpj of listaCnpjs) {
          if (xml.data.emit.document === cnpj) {
            const xmlTmp = Object.assign({}, xml);
            xmlTmp["subtype"] = "output";
            xmlTmp["cnpj"] = cnpj;
            xmlListTmp.push(xmlTmp);
            find = true;
          }
          if (xml.data.dest.document === cnpj) {
            const xmlTmp = Object.assign({}, xml);
            xmlTmp["subtype"] = "input";
            xmlTmp["cnpj"] = cnpj;
            xmlListTmp.push(xmlTmp);
            find = true;
          }
        }
      } else if (xml.data.xmlType === "MDFe") {
        for (let cnpj of listaCnpjs) {
          if (xml.data.emit.document === cnpj) {
            const xmlTmp = Object.assign({}, xml);
            xmlTmp["subtype"] = "output";
            xmlTmp["cnpj"] = cnpj;
            xmlListTmp.push(xmlTmp);
            find = true;
          }
        }
      } else if (xml.data.xmlType === "NFSe") {
        for (let cnpj of listaCnpjs) {
          if (xml.data.prest.document === cnpj) {
            const xmlTmp = Object.assign({}, xml);
            xmlTmp["subtype"] = "output";
            xmlTmp["cnpj"] = cnpj;
            xmlListTmp.push(xmlTmp);
            find = true;
          }
          if (xml.data.toma.document === cnpj) {
            const xmlTmp = Object.assign({}, xml);
            xmlTmp["subtype"] = "input";
            xmlTmp["cnpj"] = cnpj;
            xmlListTmp.push(xmlTmp);
            find = true;
          }
        }
      } else if (
        xml.data.xmlType === "EventoNFe" ||
        xml.data.xmlType === "EventoCTe" ||
        xml.data.xmlType === "EventoMDFe"
      ) {
        for (let cnpj of listaCnpjs) {
          if (xml.data.info.document === cnpj) {
            const xmlTmp = Object.assign({}, xml);
            xmlTmp["subtype"] = "event";
            xmlTmp["cnpj"] = cnpj;
            xmlListTmp.push(xmlTmp);
            find = true;
          }
        }
      } else if (xml.data.xmlType === "NotIdentified") {
        const xmlTmp = Object.assign({}, xml);
        xmlTmp["subtype"] = "XMLNotIdentified";
        xmlListTmp.push(xmlTmp);
        find = true;
      }

      if (!find) {
        const xmlTmp = Object.assign({}, xml);
        xmlTmp["subtype"] = "xmlNotPartClients";
        xmlTempNotPartClients.push(xmlTmp);
      }
    }
    setxmlNotPartClients(xmlTempNotPartClients);
    setXmlList(xmlListTmp);

    let qtdNotidentified = xmlListTmp.filter(
      (e) => e.subtype === "XMLNotIdentified"
    ).length;

    if (qtdNotidentified === xmlListTmp.length) {
      setSendButtonVisible(false);
    }

    const getData = async () => {
      if (xmlListTmp.length !== 0) {
        let title = getTitleEffect();

        getMP3Audio(title).then((result) => {
          var snd = new Audio("data:audio/wav;base64," + result);
          snd.play();
        });
      }
    };

    getData();
  }, []);

  return (
    <Modal
      open={props.openModal}
      onClose={() => {
        props.closeXmlModal();
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <DialogStyle>
        {props.loading === false && (
          <>
            <DialogHeader>
              <div className="row">
                <div style={{ display: "flex", flexDirection: "column" }}>
                  {getTitle(props)}

                  <div style={{ fontSize: 14 }}>{getSubtitle(xmlList)}</div>
                </div>

                <CloseIconStyle
                  onClick={() => {
                    props.closeXmlModal();
                  }}
                />
              </div>
            </DialogHeader>
          </>
        )}
        {props.loading && <CircularProgressStyled />}

        {!props.loading && (
          <>
            <BodyStyle style={{ maxHeight: "450px", overflowY: "auto" }}>
              {xmlList.map((xml, index) => {
                return RenderItem(xml, index, botContext, props, functionAI);
              })}
              {xmlNotPartClients.map((xml, index) => {
                return DocumentErrorCNPJ(xml, botContext, props, functionAI);
              })}
            </BodyStyle>

            <DivNew>
              <Button
                className="Button"
                onClick={() => {
                  props.closeXmlModal();
                }}
              >
                Cancelar envio
              </Button>
              &nbsp;
              {sendButtonVisible && (
                <Button
                  className="ButtonSecondary"
                  onClick={async () => {
                    SendXml(props, xmlList);
                  }}
                >
                  Enviar XML
                </Button>
              )}
            </DivNew>
          </>
        )}
      </DialogStyle>
    </Modal>
  );
};
