import { useContext, useEffect, useState } from "react";
import { usePermissions } from "../../../utils/permissionsHook";

import {
  Avatar,
  Button,
  Chip,
  Grid,
  Icon,
  IconButton,
  InputAdornment,
  MenuItem,
  SvgIcon,
  TextField,
  Tooltip,
} from "@material-ui/core";

import {
  Add,
  CheckCircle as CheckCircleIcon,
  GetApp,
} from "@material-ui/icons";
import { useMemo } from "react";
import { useHistory } from "react-router-dom";
import Loading from "../../../components/basics/Loading";
import { GlobalContext } from "../../../contexts/GlobalContext";
import { GlobalVariables } from "../../../GlobalVariables";
import {
  GridStyled,
  IconButtonStyled,
  LabelStyled,
  LightTooltip,
  Margin,
} from "../../../styles/GlobalStyle";
import { filter } from "../../../utils/filter";
import MaskService from "../../../utils/mask/maskService";
import { notify } from "../../../utils/notify";
import { orderBy } from "../../../utils/orderBy";
import { usePortalAsClient } from "../../../utils/viewPortalAsClientHook";
import { CustumerManagementContext } from "../contexts/CustomerManagementContext";
import { ModalRequestVisitContext } from "../contexts/ModalRequestVisitContext";
import ModalConfigAccess from "./ModalConfigAccess";
import ModalCustomTable from "./ModalCustomTable";
import ModalRequestVisit from "./ModalRequestVisit";
import {
  CertificateStatus,
  ClientDataGrid,
  CustomToolgridButtonExport,
  IconButtonAddStyled,
  SearchLabel,
  SelectFilterStyled,
} from "../styles/CustomerManagementStyle";
import { ReactComponent as EditIcon } from "../../../assets/icons/edit.svg";
import { ReactComponent as ConfigGfIcon } from "../../../assets/icons/config-gf.svg";
import ConfigPortalIcon from "../../../assets/icons/config-portal.png";
import {
  getAccountantClients,
  getDataClientView,
  updateBpoActive,
  verifyUpdateBpo,
} from "../../../repositories/portal_api/AccountantClient";
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  ptBR,
} from "@material-ui/data-grid";
import { SearchIcon } from "../../../components/icons/SearchIcon";
import IconRedirect from "./IconRedirect";
import BusinessIcon from "../../../components/icons/BusinessIcon";
import PersonIcon from "../../../components/icons/PersonIcon";
import ConfigureTableIcon from "../../../components/icons/ConfigureTableIcon";

export default function CustomerManagementWithBPO() {
  const { checkIfUserHasPermission } = usePermissions();
  const [page, setPage] = useState(0);
  const [textFilter, setTextFilter] = useState("");
  // const [contractFilter, setContractFilter] = useState("all");
  // const [gfPlanFilter, setGfPlanFilter] = useState("all");
  const [isFilterBpoActive] = useState(false);
  const [dataTableFilter, setDataTableFilter] = useState([]);
  const [columns, setColumns] = useState([]);
  const { push } = useHistory();
  const [dataToModal] = useState(null);
  const { isInClientView, clientViewed } = usePortalAsClient();

  const {
    loading,
    setLoading,
    customersBpo,
    setCustomersBpo,
    customTableModalOpen,
    configAccessModalOpen,
  } = useContext(CustumerManagementContext);
  const { modalVisitOpen } = useContext(ModalRequestVisitContext);
  const { companyUser, userLogged } = useContext(GlobalContext);
  const [loadingTab, setLoadingTab] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState("");
  const [pageSize, setPageSize] = useState(50);
  const { setPortalAsClientView } = usePortalAsClient();
  const [planGfList, setPlanGfList] = useState([]);
  const [filterPlanGf, setFilterPlangGf] = useState("all");
  const [filterPayer, setFilterPayer] = useState("all");

  const optionsColumns = useMemo(
    () => [
      {
        id: 1,
        field: "company_name",
        headerName: "Empresa",
        flex: 1,
        renderCell: ({ row }) => {
          return (
            <>
              <GridStyled
                container
                justifyContent="center"
                alignItems="flex-start"
                height={5}
                line-height={1.5}
              >
                <GridStyled container height={25}>
                  <LabelStyled
                    fontSize={14}
                    style={{
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {row.company_name}
                  </LabelStyled>
                </GridStyled>

                <GridStyled container height={25}>
                  <LabelStyled fontSize={12}>
                    {MaskService.toMask(
                      row.document.replace(/[^0-9]+/g, "").length <= 11
                        ? "cpf"
                        : "cnpj",
                      row.document || ""
                    )}
                  </LabelStyled>
                </GridStyled>
              </GridStyled>
            </>
          );
        },
      },
      {
        // TODO: mostrar tag com quantos dias faltam para expirar o certificado de acordo com as cores abaixo:
        // Laranja: a partir de 40 dias pra menos
        // Vermelho: expirado
        // Verde: 41 dias ou mais.
        id: 2,
        field: "certificate_valid_to",
        headerName: "Status do Certificado",
        flex: 0.8,
        renderCell: ({ row }) => {
          const certificateValidityDate = row?.certificate_valid_to;
          if (row.certificate_valid_to) {
            let date_certificate = Date.parse(certificateValidityDate);
            let date_now = Date.now();
            let difference = date_certificate - date_now;
            let days = Math.ceil(difference / (1000 * 60 * 60 * 24));
            if (days <= 0) {
              return (
                <CertificateStatus status="expired">Expirado</CertificateStatus>
              );
            } else if (days <= 7) {
              return (
                <CertificateStatus status="expiring">
                  Expira em {days} dias
                </CertificateStatus>
              );
            } else {
              return (
                <CertificateStatus status="active">
                  Expira em {days} dias
                </CertificateStatus>
              );
            }
          }

          return (
            <Tooltip title="Para importar um Certificado Digital para seu cliente, basta arrastar o arquivo do Certificado Digital de seu cliente no Qyon Bot à direita de sua tela, ou clicar no ícone de núvem na barra de ações abaixo do Qyon Bot.">
              <CertificateStatus status="expired">
                Nenhum certificado ativo
              </CertificateStatus>
            </Tooltip>
          );
        },
      },
      {
        id: 3,
        field: "bpo_active",
        headerName: "Acesso Gestão Fácil",
        flex: 0.4,
        minWidth: 150,
        renderHeader: () => (
          <Grid container justifyContent="center">
            <div
              className="MuiDataGrid-columnHeaderTitle"
              style={{ textAlign: "center" }}
            >
              Acesso Gestão Fácil
            </div>
          </Grid>
        ),
        renderCell: ({ row }) => (
          <Grid container justifyContent="center">
            {row?.gf_plan && (
              <LightTooltip
                title={
                  row?.gf_payer === "accountant"
                    ? "Gestão Fácil"
                    : row?.bpo_active
                    ? "Gestão Fácil"
                    : "Necessário que o cliente libere seu acesso através da Plataforma do Cliente"
                }
                placement="top"
              >
                <IconButtonStyled
                  bg-color="transparent"
                  bghovercolor="grayTertiary"
                  iconColor={
                    row.portal_invite_sended
                      ? "orange"
                      : row.customer_using_portal
                      ? "green"
                      : "gray"
                  }
                  padding="0.2rem"
                  onClick={() => {
                    if (!row.bpo_active && row?.gf_payer === "customer")
                      return notify(
                        "Necessário que o cliente libere seu acesso através da Plataforma do Cliente"
                      );

                    if (
                      !checkIfUserHasPermission(
                        GlobalVariables.PERMISSIONS.CLIENTES_BPO
                      )
                    ) {
                      return notify(
                        `Acesso negado! Permissão 'Menus - Gestão de clientes - Acesso BPO' necessária`
                      );
                    }

                    const productErp = (
                      companyUser?.systemsPermissions || []
                    ).find(
                      (system) =>
                        system.id === GlobalVariables.ID_PRODUCT_ERP_PANEL
                    );

                    if (!productErp?.url) {
                      return notify(
                        "Não foi possível redirecionar para o sistema."
                      );
                    }

                    setLoading(true);

                    verifyUpdateBpo(row.id)
                      .then((resp) => {
                        if (resp?.success) {
                          if (resp?.data?.need_update) {
                            updateBpoActive(
                              row.id,
                              resp?.data?.bpo_should_be
                            ).then((respUpdate) => {
                              if (respUpdate?.success) {
                                console.log("legal");
                              }
                            });

                            window.open(
                              `${
                                productErp?.url
                              }/zf/login/loginpanel?master=true&tokenCognito=${
                                userLogged.token
                              }&document=${(row?.document || "").replace(
                                /[^0-9]+/g,
                                ""
                              )}`,
                              "_blank"
                            );
                          } else {
                            window.open(
                              `${
                                productErp?.url
                              }/zf/login/loginpanel?master=true&tokenCognito=${
                                userLogged.token
                              }&document=${(row?.document || "").replace(
                                /[^0-9]+/g,
                                ""
                              )}`,
                              "_blank"
                            );
                          }
                        }
                      })
                      .catch((ex) => {
                        console.error(ex);
                        window.open(
                          `${
                            productErp?.url
                          }/zf/login/loginpanel?master=true&tokenCognito=${
                            userLogged.token
                          }&document=${(row?.document || "").replace(
                            /[^0-9]+/g,
                            ""
                          )}`,
                          "_blank"
                        );
                      })
                      .finally(() => {
                        setLoading(false);
                      });
                  }}
                >
                  <IconRedirect
                    fill={
                      row?.gf_payer === "accountant"
                        ? "#616161"
                        : row?.bpo_active
                        ? "#616161"
                        : "#b1b1b1"
                    }
                  />
                </IconButtonStyled>
              </LightTooltip>
            )}
            {!row?.configured && (
              <Button
                variant="text"
                style={{
                  background: "#E7EEFE",
                  color: "#0036B3",
                  textTransform: "none",
                }}
                onClick={() => {
                  checkIfUserHasPermission(
                    GlobalVariables.PERMISSIONS.CLIENTES_MANAGEMENT
                  )
                    ? push("/customermanagement/configure-erp", {
                        customerId: row.id,
                      })
                    : notify(
                        `Acesso negado! Permissão 'Menus - Gestão de clientes - Gerenciamento' necessária`
                      );
                }}
              >
                Configurar
              </Button>
            )}
          </Grid>
        ),
      },
      {
        id: 4,
        field: "gf_plan",
        flex: 0.8,
        minWidth: 200,
        headerName: "Plano Gestão Fácil",
        renderHeader: () => (
          <Grid container justifyContent="center">
            <div
              className="MuiDataGrid-columnHeaderTitle"
              style={{ textAlign: "center" }}
            >
              Plano Gestão Fácil
            </div>
          </Grid>
        ),

        renderCell: (props) => {
          const { row } = props;

          if (
            row.gf_plan === null ||
            row.gf_plan === "" ||
            row.gf_plan === undefined
          ) {
            return <Grid container>Não Contratado</Grid>;
          }

          const exibition_plan = row.gf_plan.replace("QYON ", "");

          let planBackground = "";

          let payer = "";

          switch (exibition_plan) {
            case "Gestão Fácil Emissor":
              planBackground = "#5E8EFF";
              break;
            case "Gestão Fácil Essencial":
              planBackground = "#1E1EFB";
              break;
            case "Gestão Fácil Avançado":
              planBackground = "#020275";
              break;
            default:
              planBackground = "#66B2B2";
          }

          switch (row.gf_payer) {
            case "accountant":
              payer = "Contratado pelo Escritório";
              break;
            case "customer":
              payer = "Contratado pelo Cliente";
              break;
            default:
              payer = row.gf_payer;
          }

          return (
            <Grid container>
              <Grid item>
                <Tooltip title={payer}>
                  <div
                    style={{ display: "flex", alignItems: "center", gap: 2 }}
                  >
                    <Chip
                      label={exibition_plan}
                      style={{ background: planBackground, color: "#FFF" }}
                    />
                    {row.gf_payer === "accountant" && (
                      <Avatar
                        style={{ height: 30, width: 30, background: "#E1F5FE" }}
                      >
                        <BusinessIcon fill="#0277BD" />
                      </Avatar>
                    )}
                    {row.gf_payer === "customer" && (
                      <Avatar
                        style={{ height: 30, width: 30, background: "#FFF3E0" }}
                      >
                        <PersonIcon fill="#E65100" />
                      </Avatar>
                    )}
                  </div>
                </Tooltip>
              </Grid>
            </Grid>
          );
        },
      },

      {
        id: 5,
        field: "access_portal",
        headerName: "Acesso Plataforma",
        flex: 0.4,
        minWidth: 150,
        align: "center",
        // headerName: "Acesso Portal do Cliente",
        renderHeader: () => (
          <Grid container justifyContent="center">
            <div
              className="MuiDataGrid-columnHeaderTitle"
              style={{ textAlign: "center" }}
            >
              Acesso Plataforma do Cliente
            </div>
          </Grid>
        ),
        renderCell: ({ row }) => (
          <LightTooltip
            title={"Acessar a Plataforma com a visão de cliente"}
            placement="top"
          >
            <IconButton
              size="small"
              onClick={async () => {
                setLoadingMessage(
                  "Acessando a Platafroma com a visão do cliente..."
                );

                await getDataClientView(row.id).then(async (resp) => {
                  if (resp?.success) {
                    const data = resp?.data;

                    await setPortalAsClientView(
                      row.document,
                      data?.master_user_id,
                      row.id,
                      `${data?.master_first_name} ${data?.master_last_name}`,
                      row.company_name,
                      data?.logo_url,
                      data?.master_user_email,
                      setLoading
                    );
                  }
                });
              }}
            >
              <SvgIcon>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="#616161"
                    fill-rule="evenodd"
                    d="M8.5 5.5a1 1 0 0 1 1-1h9a1 1 0 0 1 1 1v9a1 1 0 1 1-2 0V7.914L6.48 18.935a1 1 0 0 1-1.415-1.414L16.085 6.5H9.5a1 1 0 0 1-1-1Z"
                    clip-rule="evenodd"
                  />
                </svg>
              </SvgIcon>
            </IconButton>
          </LightTooltip>
        ),
      },
      {
        id: 6,
        field: "Ações",
        headerName: "Ações",
        minWidth: 110,
        flex: 0.6,
        sticky: true,
        renderCell: ({ row }) => (
          <>
            <Grid container direction="row" justifyContent="center">
              <Grid item>
                <LightTooltip title="Configurar Gestão Fácil" placement="top">
                  <IconButton
                    size="small"
                    onClick={() => {
                      checkIfUserHasPermission(
                        GlobalVariables.PERMISSIONS.CLIENTES_MANAGEMENT
                      )
                        ? push("/customermanagement/configure-erp", {
                            customerId: row.id,
                          })
                        : notify(
                            `Acesso negado! Permissão 'Menus - Gestão de clientes - Gerenciamento' necessária`
                          );
                    }}
                  >
                    <ConfigGfIcon />
                  </IconButton>
                </LightTooltip>
              </Grid>
              <Grid item>
                <LightTooltip title="Configurar Plataforma" placement="top">
                  <IconButton
                    size="small"
                    onClick={() =>
                      checkIfUserHasPermission(
                        GlobalVariables.PERMISSIONS.CLIENTES_MANAGEMENT
                      )
                        ? push("/customermanagement/configure-panel", {
                            customerId: row.id,
                            companyName: row.company_name,
                          })
                        : notify(
                            `Acesso negado! Permissão 'Menus - Gestão de clientes - Gerenciamento' necessária`
                          )
                    }
                  >
                    <Icon>
                      <img
                        src={ConfigPortalIcon}
                        alt="ícone para configuração da Plataforma do Cliente"
                      />
                    </Icon>
                  </IconButton>
                </LightTooltip>
              </Grid>
              <Grid item>
                <LightTooltip size="small" title="Editar" placement="top">
                  <IconButton
                    onClick={() => {
                      checkIfUserHasPermission(
                        GlobalVariables.PERMISSIONS.CLIENTES_MANAGEMENT
                      )
                        ? push("/customermanagement/edit", {
                            customerId: row.id,
                          })
                        : notify(
                            `Acesso negado! Permissão 'Menus - Gestão de clientes - Gerenciamento' necessária`
                          );
                    }}
                  >
                    <EditIcon color="#616161" />
                  </IconButton>
                </LightTooltip>
              </Grid>
            </Grid>
          </>
        ),
        renderHeader: () => (
          <Grid container justifyContent="center">
            <div className="MuiDataGrid-columnHeaderTitle">Ações</div>
          </Grid>
        ),
      },
    ],
    [
      checkIfUserHasPermission,
      companyUser?.systemsPermissions,
      userLogged.token,
      setPortalAsClientView,
      push,
      setLoading,
    ]
  );

  function CustomToolbar() {
    return (
      <GridToolbarContainer
        style={{
          display: "flex",
          justifyContent: "flex-end",

          marginBottom: 10,
          background: "#F5F5F5",
          padding: 5,
          gap: 5,
        }}
      >
        <Grid item>
          <Tooltip title="Configurar Colunas">
            <IconButton
              size="small"
              onClick={() => {
                document.getElementById("original_button_columns").click();
              }}
            >
              <ConfigureTableIcon></ConfigureTableIcon>
            </IconButton>
          </Tooltip>
        </Grid>

        <GridToolbarColumnsButton
          id="original_button_columns"
          style={{ display: "none" }}
        />

        <Grid item>
          <Tooltip title="Download Excel">
            <IconButton
              size="small"
              onClick={() => {
                document.getElementById("original_button_exports").click();
              }}
            >
              <GetApp />
            </IconButton>
          </Tooltip>
        </Grid>

        <CustomToolgridButtonExport id="original_button_exports" style={{}} />
        <Grid item>
          <Tooltip title="Cadastrar Cliente">
            <Button
              variant="contained"
              color="primary"
              endIcon={<Add />}
              style={{ textTransform: "none" }}
              onClick={() => {
                push("/customermanagement/edit");
              }}
            >
              Cadastrar
            </Button>
          </Tooltip>
        </Grid>
      </GridToolbarContainer>
    );
  }

  useEffect(() => {
    (async () => {
      try {
        setLoadingTab(true);
        const respBpo = await getAccountantClients();

        if (respBpo?.success && Array.isArray(respBpo?.data)) {
          const newArr = orderBy(respBpo?.data, "company_name");
          setCustomersBpo(newArr);
          setDataTableFilter(newArr);
          setPage(0);

          let copyPlans = [...planGfList];

          newArr.forEach((item) => {
            if (
              !copyPlans.includes(item?.gf_plan) &&
              item?.gf_plan !== "" &&
              item?.gf_plan !== null &&
              item?.gf_plan !== undefined
            ) {
              copyPlans.push(item?.gf_plan);
            }
          });

          setPlanGfList(copyPlans);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoadingTab(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setCustomersBpo, companyUser, isInClientView, clientViewed?.company]);

  useEffect(() => {
    setColumns(optionsColumns);
  }, [setColumns, optionsColumns]);

  useEffect(() => {
    let rowsFilter = [...customersBpo];

    if (textFilter) {
      rowsFilter = filter({
        data: rowsFilter,
        text: textFilter,
        columns: ["company_name", "document", "certificateValidityDate"],
      });
    }

    if (filterPlanGf !== "all") {
      rowsFilter = rowsFilter.filter((row) => row.gf_plan === filterPlanGf);
    }

    if (filterPayer !== "all") {
      rowsFilter = rowsFilter.filter((row) => row.gf_payer === filterPayer);
    }

    if (isFilterBpoActive)
      rowsFilter = rowsFilter.filter((row) => row.bpo_active);

    setDataTableFilter(orderBy(rowsFilter, "company_name"));
    setPage(0);
  }, [
    textFilter,
    customersBpo,
    isFilterBpoActive,
    setDataTableFilter,
    setPage,
    filterPlanGf,
    filterPayer,
  ]);

  return (
    <>
      {customTableModalOpen && <ModalCustomTable />}
      {configAccessModalOpen && <ModalConfigAccess data={dataToModal} />}
      {modalVisitOpen && <ModalRequestVisit data={dataToModal} />}
      <Loading open={loading} message={loadingMessage} />

      <div
        container
        style={{
          background: "#fff",
          border: "1px solid #E0E0E0",
          borderRadius: 15,
          padding: 15,
          height: "100%",
          flex: 1,
        }}
      >
        <Margin top="10" />

        <Grid container direction="row" spacing={1}>
          <Grid item xs={6}>
            <SearchLabel>Pesquisar</SearchLabel>
            <TextField
              variant="outlined"
              size="small"
              fullWidth
              placeholder="Pesquisar"
              value={textFilter}
              onChange={(e) => setTextFilter(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>

          <Grid item xs={3}>
            <div>Plano Gestão Fácil</div>
            <SelectFilterStyled
              variant="outlined"
              size="small"
              fullWidth
              defaultValue="all"
              value={filterPlanGf}
              onChange={(e) => setFilterPlangGf(e.target.value)}
            >
              <MenuItem value="all">Todos</MenuItem>
              {planGfList.map((plan) => (
                <MenuItem value={plan}>{plan}</MenuItem>
              ))}
            </SelectFilterStyled>
          </Grid>

          <Grid item xs={3}>
            <div>Contrato Gestão Fácil</div>
            <SelectFilterStyled
              variant="outlined"
              size="small"
              fullWidth
              defaultValue="all"
              value={filterPayer}
              onChange={(e) => setFilterPayer(e.target.value)}
            >
              <MenuItem value="all">Todos</MenuItem>
              <MenuItem value="customer">Contratado pelo Cliente</MenuItem>
              <MenuItem value="accountant">Contratado pelo Escritório</MenuItem>
            </SelectFilterStyled>
          </Grid>
        </Grid>

        <ClientDataGrid
          id="CLIENTS_DATA_GRID"
          localeText={ptBR.props.MuiDataGrid.localeText}
          loading={loadingTab}
          disableColumnMenu
          rows={loadingTab ? [] : dataTableFilter}
          columns={columns}
          style={{ marginTop: 20, height: "calc(100% - 65px)" }}
          pageSize={pageSize}
          onPageSizeChange={(size) => setPageSize(size)}
          rowsPerPageOptions={[10, 25, 50, 100]}
          disableSelectionOnClick
          scrollbarSize={13}
          page={page}
          onPageChange={(p) => setPage(p)}
          components={{
            Toolbar: CustomToolbar,
          }}
        />
      </div>
    </>
  );
}
