import { useContext, useState, useMemo } from "react";
import PropTypes from "prop-types";
import { usePermissions } from "../../../utils/permissionsHook";
import { GlobalVariables } from "../../../GlobalVariables";

// Icons
import {
  EmailOutlined,
  Cached,
  PlayForWorkOutlined,
  RemoveRedEyeOutlined,
  History,
  PublishOutlined,
} from "@material-ui/icons";

// Components
import {
  Grid,
  Box,
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableRow,
  Collapse,
} from "@material-ui/core";

import { OptionsIcons } from "./OptionsIcons";

// Styled Components
import {
  ContainerIcon,
  GridStyled,
  LightTooltip,
} from "../../../styles/GlobalStyle";
import { notify } from "../../../utils/notify";

import { StyledTableRow, StyledTableCell } from "../styles/CndAccountant";

// controllers
import { useCndAccountantController } from "../controllers/CndAccountantController";
import { useCndGeneralController } from "../controllers/CndGeneralController";

// Contexts
import { CndAccountantContext } from "../contexts/CndAccountantContext";
import ModalSendEmail from "./ModalSendEmail";
import { ModalLastDate } from "./ModalLastDate";
import { Skeleton } from "@material-ui/lab";
import { GlobalContext } from "../../../contexts/GlobalContext";

export const TableTabCndAccountantSubTable = (props) => {
  const {
    open,
    listCnds,
    companyName,
    companyDocument,
    companyMail,
    isLoading,
    isError,
    setListCnds,
    handleOpenSubTable,
  } = props;

  const { companyUser } = useContext(GlobalContext);
  const { setCndToUpload, setClientCndToUpload, setLoading } =
    useContext(CndAccountantContext);
  const { getFile, disableActionFile } = useCndGeneralController();
  const [dataSendEmail, setDataSendEmail] = useState({});
  const [dataLastDate, setDataLastDate] = useState({});
  const { handleRequestCnd } = useCndAccountantController();

  const { checkIfUserHasPermission } = usePermissions();
  const hasEditionPermission = useMemo(
    () =>
      checkIfUserHasPermission(GlobalVariables.PERMISSIONS.CND_SOLICITATION),
    [checkIfUserHasPermission]
  );

  //FIXME VERIFICAR FLUXO DE A VENCER E VENCIDA

  // const processExpiration = (expirationDate) => {
  //   if (expirationDate === "None") return "";

  //   if (moment(expirationDate).isAfter(moment.now())) return "aVencer";

  //   if (moment(expirationDate).isBefore(moment.now())) return "Vencida";
  // };

  return (
    <>
      {dataSendEmail?.id && (
        <ModalSendEmail
          handleClose={() => setDataSendEmail({})}
          row={dataSendEmail}
        />
      )}

      {dataLastDate.cndId && (
        <ModalLastDate
          handleClose={() => setDataLastDate({})}
          row={dataLastDate}
        />
      )}
      <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={8}>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <Box margin={1}>
            <Table size="small" aria-label="purchases">
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell className="action" align="center">
                    Ações
                  </StyledTableCell>
                  <StyledTableCell align="left">Âmbito</StyledTableCell>
                  <StyledTableCell align="left">Certidão</StyledTableCell>
                  <StyledTableCell align="center">Status</StyledTableCell>
                  <StyledTableCell align="center">Emissão</StyledTableCell>
                  <StyledTableCell align="center">Vencimento</StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {isLoading && (
                  <TableRow>
                    <StyledTableCell className="action" align="center">
                      <Skeleton></Skeleton>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <Skeleton></Skeleton>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <Skeleton></Skeleton>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      <Skeleton></Skeleton>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Skeleton></Skeleton>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Skeleton></Skeleton>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Skeleton></Skeleton>
                    </StyledTableCell>
                  </TableRow>
                )}

                {listCnds.map((cnd) => (
                  <TableRow key={cnd?.agendaId}>
                    <StyledTableCell className="action" align="center">
                      <GridStyled container spacing={1} alignItems="center">
                        <Grid item flex={1}>
                          <LightTooltip title="Abrir em outra aba">
                            <ContainerIcon
                              iconColor={
                                disableActionFile(cnd) ? "grayPrimary" : "blue"
                              }
                            >
                              <RemoveRedEyeOutlined
                                onClick={() => {
                                  !disableActionFile(cnd)
                                    ? getFile(
                                        companyUser.document,
                                        cnd?.cndId,
                                        "View",
                                        setLoading,
                                        companyDocument
                                      )
                                    : notify("CND ainda não processada");
                                }}
                              />
                            </ContainerIcon>
                          </LightTooltip>
                        </Grid>
                        <Grid item flex={1}>
                          <LightTooltip title="Download">
                            <ContainerIcon
                              iconColor={
                                disableActionFile(cnd) ? "grayPrimary" : "blue"
                              }
                            >
                              <PlayForWorkOutlined
                                onClick={() => {
                                  !disableActionFile(cnd)
                                    ? getFile(
                                        companyUser.document,
                                        cnd?.cndId,
                                        "Download",
                                        setLoading,
                                        companyDocument
                                      )
                                    : notify("CND ainda não processada");
                                }}
                              />
                            </ContainerIcon>
                          </LightTooltip>
                        </Grid>
                        <Grid item flex={1}>
                          <LightTooltip title="Enviar">
                            <ContainerIcon
                              iconColor={
                                disableActionFile(cnd) ? "grayPrimary" : "blue"
                              }
                            >
                              <EmailOutlined
                                onClick={() => {
                                  !disableActionFile(cnd)
                                    ? setDataSendEmail({
                                        id: cnd?.cndId,
                                        document: companyDocument,
                                        defaultMail: companyMail,
                                      })
                                    : notify("CND ainda não processada");
                                }}
                              />
                            </ContainerIcon>
                          </LightTooltip>
                        </Grid>
                        <Grid item flex={1}>
                          <LightTooltip title="Último Processamento">
                            <ContainerIcon iconColor="blue">
                              <History
                                onClick={() =>
                                  setDataLastDate({
                                    ...cnd,
                                    clientDocument: companyDocument,
                                    parentDocument: companyUser.document,
                                  })
                                }
                              />
                            </ContainerIcon>
                          </LightTooltip>
                        </Grid>
                        <Grid item flex={1}>
                          <LightTooltip
                            title={
                              cnd?.auto ? "Solicitar CND" : "Upload manual"
                            }
                          >
                            <ContainerIcon iconColor="blue">
                              {cnd.auto ? (
                                <Cached
                                  onClick={() => {
                                    if (!hasEditionPermission)
                                      return notify(
                                        "Seu usuário não tem permissão para realizar essa ação"
                                      );
                                    handleRequestCnd({
                                      clientDocument: companyDocument,
                                      parentDocument: companyUser.document,
                                      agendaId: cnd?.agendaId,
                                      setListCnds,
                                      listCnds,
                                    }).then(() => {
                                      handleOpenSubTable(true);
                                    });
                                  }}
                                />
                              ) : (
                                <PublishOutlined
                                  onClick={() => {
                                    if (!hasEditionPermission)
                                      return notify(
                                        "Seu usuário não tem permissão para realizar essa ação"
                                      );

                                    setClientCndToUpload({
                                      companyName,
                                      companyDocument,
                                    });
                                    setCndToUpload(cnd);
                                  }}
                                />
                              )}
                            </ContainerIcon>
                          </LightTooltip>
                        </Grid>
                      </GridStyled>
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {cnd?.scope.scope || ""}
                    </StyledTableCell>
                    <StyledTableCell align="left">
                      {cnd?.cndName}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <GridStyled
                        container
                        alignItems="center"
                        justifyContent="center"
                      >
                        <Grid item flex={1}>
                          {
                            OptionsIcons[
                              cnd?.status
                              // + processExpiration(cnd?.expirationDate)
                            ]
                          }
                        </Grid>
                      </GridStyled>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {cnd.issuanceDate === "None"
                        ? ""
                        : cnd.issuanceDate
                        ? cnd.issuanceDate.split("-").reverse().join("/")
                        : ""}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {cnd.expirationDate === "None"
                        ? ""
                        : cnd.expirationDate
                        ? cnd.expirationDate.split("-").reverse().join("/")
                        : ""}
                    </StyledTableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            {isError && (
              <TableRow
                style={{
                  color: "red",
                  fontSize: "14px",
                }}
              >
                Erro ao realizar a busca dos dados
              </TableRow>
            )}
          </Box>
        </Collapse>
      </TableCell>
    </>
  );
};

TableTabCndAccountantSubTable.propTypes = {
  SubTable: PropTypes.shape({
    open: PropTypes.bool.isRequired,
  }).isRequired,
};
