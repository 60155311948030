import { v4 as uuidv4 } from "uuid";

import { isCompany } from "../../../../utils/panelType";
import { notify } from "../../../../utils/notify";
import { getEnvironment } from "../../../../utils/environment";

export const uploadCertificate = async (
  fileName,
  base64,
  password,
  cnpjCertificateOwner
) => {
  let accountantCnpj =
    localStorage.getItem("isAccountantPanel") === "true"
      ? localStorage.getItem("documentPanel")
      : localStorage.getItem("accountantCnpjPanel");

  if (isCompany() === true) {
    accountantCnpj = localStorage.getItem("documentPanel");
  }
  let environment = getEnvironment();

  let uploadJson = {
    id: uuidv4(),
    version: "v1.0.0",
    from: "QYON_DASH",
    to: "ALL",
    timestamp: new Date().getTime(),
    payload: {
      cnpj: cnpjCertificateOwner,
      fileName: fileName,
      password: password,
      pfx: base64,
    },
    extraData: {
      uiMessage: `Upload do certificado ${fileName} sendo realizado!`,
      cnpj: localStorage.getItem("documentPanel"),
      mainCompanyCnpj: localStorage.getItem("mainEntityCnpjPanel"),
      parentCnpj: accountantCnpj,
      accountantCnpj: accountantCnpj,
      sub: localStorage.getItem("subCognitoPanel"),
      createAccess: isCompany() === true ? false : true,
      env: environment,
    },
    event: "A1_UPLOAD",
    priority: "CRITICAL",
  };

  let myHeaders = new Headers();
  myHeaders.append("Authorization", "18309cd6-30ca-4647-b289-fb2ae3e1d2f6");
  myHeaders.append("Content-Type", "application/json");

  let requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: JSON.stringify(uploadJson),
    redirect: "follow",
  };

  let response = await new Promise((resolve, reject) => {
    fetch("https://hub.qyon.info/v1/hub/message", requestOptions)
      .then((response) => resolve(response))
      .catch((error) => resolve(null));
  });

  return response;
};

export const uploadCertificateProcuration = async (
  fileName,
  base64,
  password,
  cnpjCompanyList
) => {
  try {
    if (cnpjCompanyList.length > 0) {
      let accountantCnpj =
        localStorage.getItem("isAccountantPanel") === "true"
          ? localStorage.getItem("documentPanel")
          : localStorage.getItem("accountantCnpjPanel");

      if (isCompany() === true) {
        accountantCnpj = localStorage.getItem("documentPanel");
      }

      let environment = getEnvironment();

      cnpjCompanyList.forEach(async (cnpjCompany) => {
        let uploadJson = {
          id: uuidv4(),
          version: "v1.0.0",
          from: "QYON_DASH",
          to: "ALL",
          timestamp: new Date().getTime(),
          payload: {
            cnpj: cnpjCompany.replace(/[^\d]/g, ""),
            fileName: fileName,
            password: password,
            pfx: base64,
          },
          extraData: {
            uiMessage: `Upload do certificado ${fileName} sendo realizado!`,
            cnpj: localStorage.getItem("documentPanel"),
            mainCompanyCnpj: localStorage.getItem("mainEntityCnpjPanel"),
            parentCnpj: accountantCnpj,
            accountantCnpj: accountantCnpj,
            sub: localStorage.getItem("subCognitoPanel"),
            createAccess: isCompany() === true ? false : true,
            env: environment,
          },
          event: "A1_UPLOAD_PROCURATION",
          priority: "CRITICAL",
        };

        let myHeaders = new Headers();
        myHeaders.append(
          "Authorization",
          "18309cd6-30ca-4647-b289-fb2ae3e1d2f6"
        );
        myHeaders.append("Content-Type", "application/json");

        let requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: JSON.stringify(uploadJson),
          redirect: "follow",
        };

        let response = await new Promise((resolve, reject) => {
          fetch("https://hub.qyon.info/v1/hub/message", requestOptions)
            .then((response) => resolve(response))
            .catch((error) => resolve(null));
        });

        return response;
      });
    }
  } catch (e) {
    console.log(e);
    notify("Um erro ocorreu ao realizar o upload do certificado!");
    return null;
  }
};
