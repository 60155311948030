import styled from "styled-components";

import {
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
} from "@material-ui/core";

const montserrat = (props) => props.theme.fonts.montserrat;
const gray = (props) => props.theme.palette.gray;
const grayDark = (props) => props.theme.palette.grayDark;
const grayMedium = (props) => props.theme.palette.grayMedium;

export const DialogBanner = styled(Dialog)`
  .MuiDialog-paperScrollPaper {
    background-color: white;
    box-shadow: none;
    border-radius: 22px;
    z-index: 500;
  }
  z-index: 9999 !important;
  width: 100%;
  height: 100vh;
`;

export const DialogTitleBanner = styled(DialogTitle)`
  padding: 0px;

  img {
    width: 100%;
  }

  svg {
    color: ${gray};
  }
`;

export const DialogContentBanner = styled(DialogContent)`
  margin-bottom: 25px;
  text-align: center;
`;

export const ButtonBanner = styled.button`
  background: ${grayMedium};
  color: white;
  border-radius: 8px;
  height: 40px;
  width: 170px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;

  :hover {
    background: ${grayDark};
  }
`;

export const FormControlLabelBanner = styled(FormControlLabel)`
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    font-size: 12px !important;
    font-family: ${montserrat};
    font-weight: 600;
    color: ${gray};
  }
  input {
    width: 18px;
    height: 18px;
  }
  svg {
    width: 18px;
    height: 18px;
    color: ${gray};
  }
`;
