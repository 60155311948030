import { Route as RouteDom, Switch } from "react-router-dom";

import Login from "../../pages/authentications/Login";
import RecoverPassword from "../../pages/authentications/RecoverPassword";
import FirstLogin from "../../pages/authentications/FirstLogin";
import { ChangePassword } from "../../pages/authentications/ChangePassword";
import { LoginContextProvider } from "../../pages/authentications/contexts/LoginContext";
import NotFoundPage from "../../assets/lotties/NotFoundPage2.json";
import RenderAnimation from "../../components/basics/RenderAnimation";
import MiddlewareRoute from "../Middleware";
import CompanySelection from "../../pages/authentications/CompanySelection";

export default function Authentication() {
  return (
    <LoginContextProvider>
      <Switch>
        <RouteDom path="/login" component={Login} />

        <MiddlewareRoute
          partialLogin
          path="/companyselection"
          component={CompanySelection}
        />

        <RouteDom path="/firstlogin" component={FirstLogin} />

        <RouteDom path="/changepassword" component={ChangePassword} />
        {/* <RouteDom path="/recoverpassword" component={RecoverPassword} /> */}
        <RouteDom path="/recoverpassword" component={RecoverPassword} />

        <RouteDom
          path="/notfound"
          component={() => (
            <RenderAnimation
              height={420}
              width={500}
              speed={1}
              label="PÁGINA NÃO ENCONTRADA"
              labelColor="gray"
              open={true}
              animation={NotFoundPage}
              timeout={5000}
            />
          )}
        />
      </Switch>
    </LoginContextProvider>
  );
}
