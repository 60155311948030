import styled from "styled-components";

import { Dialog, DialogContent, FormControlLabel } from "@material-ui/core";

export const DialogRequestVisit = styled(Dialog)`
  .MuiDialog-paperScrollPaper {
    background-color: white;
    box-shadow: none;
    border-radius: 20px;
  }
`;

export const DialogContentRequestVisit = styled(DialogContent)`
  margin-bottom: 25px;
`;

export const ContainerTitleRequestVisit = styled.div`
  display: flex;
`;

export const FormControlLabelRequestVisit = styled(FormControlLabel)`
  span {
    font-size: 12px;
  }
`;
