import styled from "styled-components";

import { Grid } from "@material-ui/core";

const blue = (props) => props.theme.palette.blue;

export const ChatContainer = styled(Grid)`
  position: fixed;
  width: 350px;
  height: 550px;
  bottom: 0;
  right: 300px;
  z-index: 1000;
  @media (max-height: 620px) {
    height: 500px;
  }
  @media (max-height: 560px) {
    height: 500px;
  }
  @media (max-height: 500px) {
    height: 440px;
  }
  @media (max-width: 959px) {
    display: none;
  } ;
`;

export const ChatCard = styled(Grid)`
  border-radius: 20px;
  border: solid 3px ${blue};
  height: 100%;
  background-color: white;
  flex-direction: column;
`;

export const ButtonFooter = styled(Grid)`
  width: 48%;
  height: 89%;
  margin: auto;
  display: flex;
  align-items: center;
  font-size: 18px;
  cursor: pointer;
  ${(props) =>
    props.select === "selected"
      ? `background: #006FDE;
       color: white;`
      : `background: #FFFFFF;
       color: #006FDE;
    `}
  ${(props) =>
    props.typeChat === "customer"
      ? `border-radius: 0px 0px 0px 20px;`
      : `border-radius: 0px 0px 20px 0px;`}
`;
