import { useContext } from "react";
import { GlobalContext } from "../../contexts/GlobalContext";

export default function useSettingTranslate() {
  const { language } = useContext(GlobalContext);

  const ptBr = {
    settings: "CONFIGURAÇÕES",

    registrationData: "Dados Cadastrais",

    dashboardSettings: "Dashboard",

    user: "Usuário",

    userRegistration: "Cadastro de Usuários",
  };

  const enUs = {
    settings: "SETTINGS",

    registrationData: "Registration Data",

    dashboardSettings: "Dashboard Settings",

    user: "User",

    userRegistration: "User Registration",
  };

  const options = { "pt-BR": ptBr, "en-US": enUs };

  if (!options[language]) return enUs;

  return {
    settings: options[language].settings,

    registrationData: options[language].registrationData,

    dashboardSettings: options[language].dashboardSettings,

    user: options[language].user,

    userRegistration: options[language].userRegistration,
  };
}
