import {
  Badge,
  Button,
  Grid,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { FilterList as FilterListIcon } from "@material-ui/icons";
import React from "react";
import { ReactComponent as FilterListOffIcon } from "../../../assets/icons/filter-list-off.svg";
import { ContainerIcon, GridStyled } from "../../../styles/GlobalStyle";
import { useMonitor } from "../contexts/ChatMonitorContext";
import { GridFilters, PaperStyled } from "../styles/ChatMonitor";
import AttendantFilters from "./AttendantFilters";
import CustomersFilters from "./CustomersFilters";

const MonitorFilters = () => {
  const {
    currentScreen,
    badgeFilters,
    openFilters,
    setOpenFilters,
    nameFilter,
    setNameFilter,
    setDepartmentFilter,
    setStatusFilter,
  } = useMonitor();

  return (
    <>
      <GridStyled
        id="filters"
        container
        item
        spacing={1}
        justifyContent="space-between"
      >
        <GridStyled item xs={10}>
          <TextField
            style={{ width: "100%" }}
            label="Nome"
            variant="filled"
            size="small"
            onChange={(e) => setNameFilter(e.target.value)}
            value={nameFilter}
          />
        </GridStyled>
        <GridStyled item xs={2}>
          <Tooltip
            title={badgeFilters ? "Filtros Ativados" : "Filtros disponíves"}
            enterDelay={500}
          >
            <Badge
              color={badgeFilters ? "error" : ""}
              badgeContent={badgeFilters ? "!" : ""}
            >
              <GridFilters onClick={() => setOpenFilters(!openFilters)}>
                <ContainerIcon center={true} fontSize="20">
                  {openFilters ? <FilterListOffIcon /> : <FilterListIcon />}
                </ContainerIcon>
              </GridFilters>
            </Badge>
          </Tooltip>
        </GridStyled>
      </GridStyled>
      {openFilters && (
        <GridStyled item xs={12} margin-top="10">
          <PaperStyled padding="15px">
            <Typography>Filtros</Typography>
            <br />
            {currentScreen === "attendants" ? (
              <AttendantFilters />
            ) : (
              <CustomersFilters />
            )}
            <GridStyled
              container
              justifyContent="center"
              alignItems="center"
              spacing={1}
              margin-top="10"
            >
              <GridStyled item>
                <Button
                  variant="contained"
                  size="small"
                  color="primary"
                  onClick={() => {
                    setOpenFilters(!openFilters);
                  }}
                >
                  Ocultar Filtros
                </Button>
              </GridStyled>
              <Grid item>
                <Button
                  variant="contained"
                  size="small"
                  color="primary"
                  onClick={() => {
                    setNameFilter("");
                    setStatusFilter("");
                    setDepartmentFilter("");
                  }}
                >
                  Limpar Filtros
                </Button>
              </Grid>
            </GridStyled>
          </PaperStyled>
        </GridStyled>
      )}
    </>
  );
};

export default MonitorFilters;
