import { useContext, useEffect } from "react";
import { useHistory } from "react-router";

import {
  Container,
  FormControl,
  FormControlLabel,
  Grid,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import { GridStyled, Margin, LightTooltip } from "../../styles/GlobalStyle";
import Btn from "../../components/buttons/Btn";
import useLoginController from "./controllers/LoginController";
import Loading from "../../components/basics/Loading";
import useAuthenticationTranslate from "../../translations/authentications/Authentication";
import { LoginContext } from "./contexts/LoginContext";
import { Search } from "@material-ui/icons";
import { useState } from "react";
import {
  BlueRectangleContainer,
  EndDotRectangle,
  IconsRectangle,
  LoginButton,
  LoginContainer,
  LoginHeader,
  LoginLabel,
  LoginTextField,
  PrincipalContainer,
  QyonText,
  SelectCompanyLogin,
  SideContainer,
  StartDotRectangle,
} from "./styles/Login";
import { BlueRectangle, DotRectangle, LoginIcons } from "./LoginIcon";

export default function CompanySelection() {
  const { handleRadioChange, handleSelectEntity } = useLoginController();

  const { loading, companyChecked, setCompanyChecked, listCompanies } =
    useContext(LoginContext);

  const translate = useAuthenticationTranslate();
  const { push } = useHistory();

  const [filteredRows, setFilteredRows] = useState(listCompanies);

  useEffect(() => {
    if (filteredRows.length === 0) {
      push("/login");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const companyInitial = listCompanies.find(
      (company) => company?.accountant_id || company?.is_accountant
    );
    setCompanyChecked((companyInitial?.id || "").toString());
  }, [listCompanies, setCompanyChecked]);

  function handleFilterChange(event) {
    let value = event.target.value.toLowerCase();
    const filteredData = listCompanies.filter((row) => {
      if (row.trading_name.toLowerCase().includes(value.toLowerCase())) {
        return true;
      }

      if (
        value.replace(/\D/g, "") !== "" &&
        row.document.replace(/\D/g, "").includes(value.replace(/\D/g, ""))
      ) {
        return true;
      }

      return false;
    });

    setFilteredRows(filteredData);
  }

  function formatCNPJorCPF(document) {
    return document.length === 14
      ? document.replace(
          /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
          "$1.$2.$3/$4-$5"
        )
      : document.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
  }

  let iconSize = 0;
  if (window.innerHeight <= 800) {
    iconSize = 400;
  } else {
    iconSize = 500;
  }
  return (
    <>
      <Loading open={loading} />
      <PrincipalContainer>
        <SideContainer>
          <StartDotRectangle>
            <DotRectangle />
          </StartDotRectangle>
          <IconsRectangle>
            <LoginIcons width={iconSize} height={iconSize} />
          </IconsRectangle>
          <QyonText>
            Potencializando a contabilidade do futuro com Inteligência
            Artificial
          </QyonText>

          <BlueRectangleContainer>
            <BlueRectangle />
          </BlueRectangleContainer>

          <EndDotRectangle>
            <DotRectangle />
          </EndDotRectangle>
        </SideContainer>
        <LoginContainer>
          <SelectCompanyLogin>
            <GridStyled item spacing={2}>
              <Grid
                item
                xs={6}
                style={{
                  display: "flex",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                }}
              >
                <LoginHeader>Selecione a empresa</LoginHeader>
              </Grid>
              <FormControl
                fullWidth
                variant="outlined"
                size="small"
                style={{
                  marginRight: "20px",
                  marginBottom: "15px",
                }}
              >
                <GridStyled justifyContent="space-between" container>
                  <LoginLabel>Pesquisar</LoginLabel>
                  <LoginTextField
                    placeholder="Pesquisar"
                    fullWidth
                    size="medium"
                    variant="outlined"
                    autoComplete="email"
                    autoFocus
                    onChange={handleFilterChange}
                    endAdornment={
                      <InputAdornment position="end">
                        <Search style={{ color: "grey" }} />
                      </InputAdornment>
                    }
                  />
                </GridStyled>
              </FormControl>

              <FormControl
                component="fieldset"
                style={{
                  minHeight: "50vh",
                  maxHeight: "50vh",
                  overflow: "auto",
                  width: "100%",
                }}
              >
                <RadioGroup value={companyChecked} onChange={handleRadioChange}>
                  {filteredRows
                    .sort((a, b) =>
                      a.trading_name.localeCompare(b.trading_name)
                    )
                    .map((company) => (
                      <FormControlLabel
                        key={company.id}
                        value={company.id}
                        control={
                          <Radio
                            disabled={loading}
                            checked={parseInt(companyChecked) === company.id}
                            color="primary"
                          />
                        }
                        label={
                          <div>
                            <span
                              style={{
                                fontFamily: "Roboto",
                                fontSize: "14px",
                                marginTop: "20px",
                              }}
                            >
                              {company.trading_name}
                            </span>
                            <br />
                            <span
                              style={{
                                fontSize: "12px",
                                color: "grey",
                                fontFamily: "Roboto",
                              }}
                            >
                              {formatCNPJorCPF(company.document)}
                            </span>
                          </div>
                        }
                      />
                    ))}
                  {filteredRows.length === 0 && (
                    <div
                      style={{
                        marginTop: "50px",
                        marginBottom: "50px",
                        marginLeft: "170px",
                      }}
                    >
                      Nenhuma empresa encontrada
                    </div>
                  )}
                </RadioGroup>
                <Margin top="10" />
              </FormControl>

              <LoginButton
                style={{ marginTop: 10 }}
                type="submit"
                full
                variant="contained"
                color="primary"
                onClick={handleSelectEntity}
              >
                Confirmar
              </LoginButton>
            </GridStyled>
          </SelectCompanyLogin>
        </LoginContainer>
      </PrincipalContainer>
    </>
  );
}
