import React, { useContext, useEffect, useState } from "react";

import { Grid } from "@material-ui/core";
import Modal from "@material-ui/core/Modal";

import { GlobalContext } from "../../../contexts/GlobalContext";
import { getMP3Audio } from "../../../repositories/AudioHandler";
import {
  getEntities,
  putEntity,
} from "../../../repositories/panel/v1/EntityRepository";
import { notify } from "../../../utils/notify";
import { usePortalAsClient } from "../../../utils/viewPortalAsClientHook";
import { DashboardViewContext } from "../contexts/DashboardViewContext";
import CloseIcon from "@material-ui/icons/Close";
import {
  BodyStyle,
  ButtonCancel,
  ButtonSave,
  DialogHeader,
  DialogStyle,
} from "../../../components/modal/Modal";

const bodyStyle = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
};

const imageStyle = {
  width: "100%",
  maxHeight: 300,
  objectFit: "contain",
};

export const ImageModal = (props) => {
  const { setUploadedLogo } = useContext(DashboardViewContext);
  const { companyUser, setCompanyUser } = useContext(GlobalContext);
  const [loading, setLoading] = useState(false);
  const [imageLimitExceed, setImageLimitExceed] = useState(false);
  const { isInClientView, clientViewed } = usePortalAsClient();

  useEffect(() => {
    getMP3Audio(
      "Encontramos um novo arquivo de imagem. Deseja atualizar o logo da sua empresa agora?"
    ).then((result) => {
      var snd = new Audio("data:audio/wav;base64," + result);
      snd.play();
    });
  }, []);

  const handleUpload = async () => {
    setLoading(true);
    const body = {};
    body.logo_base64 = "data:image/png;base64, " + props.image;

    const respCustomer = await putEntity({
      body,
      id: isInClientView ? clientViewed?.company : companyUser.companyId,
    });

    if (respCustomer?.success) {
      setUploadedLogo([{ preview: "data:image/png;base64, " + props.image }]);
      const enitity = await getEntities({
        id: companyUser?.companyId,
      });

      if (enitity?.success) {
        let newCompanyUserObj = { ...companyUser };

        newCompanyUserObj.logoUrl = enitity?.data?.logo_url;
        newCompanyUserObj.logoB64 = enitity?.data?.logo_url;
        newCompanyUserObj.accountantLogoUrl =
          enitity?.data?.accountant_logo_url;

        await setCompanyUser(newCompanyUserObj);

        localStorage.setItem("logoUrlPanel", enitity?.data?.logo_url);
        localStorage.setItem("logoB64Panel", enitity?.data?.logo_url);
        localStorage.setItem(
          "accountantLogoUrlPanel",
          enitity?.data?.accountant_logo_url
        );
      }
      notify("Logomarca atualizada.", true, "success");
    } else {
      notify("Ocorreu um erro ao atualizar a logomarca.", true, "warning");
    }

    props.closeImageModal();

    setLoading(false);
  };

  useEffect(() => {
    if (props.image) {
      const last = props.image.includes("==") ? 2 : 1;
      const size = props.image.length * (3 / 4) - last;

      setImageLimitExceed(size > 290000);
    }
  }, [props.image]);

  return (
    <Modal
      open={props.openModal}
      onClose={() => {
        props.closeImageModal();
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <DialogStyle>
        <DialogHeader>
          <div className="row">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginBottom: 20,
                marginRight: 20,
              }}
            >
              Encontramos um novo arquivo de imagem
              {imageLimitExceed
                ? ", mas ele ultrapassa o limite de tamanho (290 Kb), tente outro."
                : ". Deseja atualizar o logo da sua empresa agora?"}
            </div>

            <CloseIcon
              style={{
                position: "absolute",
                right: 0,
                cursor: "pointer",
                color: "rgba(126, 137, 158, 1)",
              }}
              onClick={() => {
                props.closeImageModal();
              }}
            />
          </div>
        </DialogHeader>

        {props.image != null && (
          <>
            <BodyStyle style={{ marginRight: 20, marginLeft: 20 }}>
              <img
                src={"data:image/png;base64, " + props.image}
                style={imageStyle}
                alt="Empresa Novo Logo"
              />
            </BodyStyle>

            <Grid
              container
              justifyContent="flex-end"
              direction="row"
              spacing={1}
              style={{ padding: 20 }}
            >
              {loading === false && (
                <Grid item>
                  <ButtonCancel
                    onClick={() => {
                      props.closeImageModal();
                    }}
                  >
                    Cancelar
                  </ButtonCancel>
                </Grid>
              )}
              &nbsp;
              {!imageLimitExceed && (
                <Grid item>
                  <ButtonSave
                    background={loading}
                    onClick={async () => {
                      handleUpload();
                    }}
                  >
                    {loading ? "Carregando..." : "Atualizar Logo"}
                  </ButtonSave>
                </Grid>
              )}
            </Grid>
          </>
        )}
      </DialogStyle>
    </Modal>
  );
};
