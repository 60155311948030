import { useContext, useState, useEffect } from "react";
import { Grid } from "@material-ui/core";

import InstagramIcon from "@material-ui/icons/Instagram";
import FacebookIcon from "@material-ui/icons/Facebook";
import LinkedInIcon from "@material-ui/icons/LinkedIn";

import { HomeContext } from "../contexts/HomeContext";
import {
  Content,
  FooterTextArea,
  IconArea,
  IframeMap,
  Powered,
  Section,
  Text,
  Title,
} from "../styles/Home";
import DialogSocialMedia from "./DialogSocialMedia";
import { GlobalContext } from "../../../contexts/GlobalContext";

const SectionFooter = ({ ...props }) => {
  const {
    landTheme,
    street,
    number,
    neighborhood,
    city,
    state,
    cep,
    phone,
    email,
    editEnable,
    googleMaps,
    instagram,
    facebook,
    linkedin,
  } = useContext(HomeContext);

  const { companyUser } = useContext(GlobalContext);

  const [socialOpen, setSocialOpen] = useState(false);
  const [iframeLink, setIframeLink] = useState(null);

  const handlerSocialClick = (link) => {
    window.open(link);
  };

  useEffect(() => {
    setIframeLink(
      googleMaps
        ? () => {
            let step1 = googleMaps.split('src="');
            return step1.length !== 2 ? null : step1[1].split('"', 1)[0];
          }
        : null
    );
  }, [googleMaps]);

  return (
    <>
      {socialOpen && (
        <DialogSocialMedia open={socialOpen} setOpen={setSocialOpen} />
      )}
      <Section $backgroundColor={landTheme.color_pallete.tertiary}>
        <Content maxWidth="lg" id="section-footer">
          <Grid container>
            <Grid item lg={5} xs={12}>
              <Title $color={landTheme.color_pallete.white}>
                <h2>Contato</h2>
              </Title>
              <FooterTextArea>
                <Text $color={landTheme.color_pallete.white}>
                  {companyUser.tradingName}
                </Text>
                <Text $color={landTheme.color_pallete.secondary}>
                  {street &&
                    number &&
                    neighborhood &&
                    `${street}, ${number} - ${neighborhood}`}
                </Text>
                <Text $color={landTheme.color_pallete.secondary}>
                  {cep && city && state && `CEP: ${cep} - ${city} ${state}`}
                </Text>
                {phone && (
                  <Text $color={landTheme.color_pallete.secondary}>
                    Telefone: {phone}
                  </Text>
                )}
                <Text $color={landTheme.color_pallete.secondary}>{email}</Text>
              </FooterTextArea>
              <IconArea $editable={editEnable}>
                {(instagram || editEnable) && (
                  <InstagramIcon
                    onClick={() => {
                      if (!editEnable) {
                        handlerSocialClick(instagram);
                      } else {
                        setSocialOpen(true);
                      }
                    }}
                  />
                )}
                {(facebook || editEnable) && (
                  <FacebookIcon
                    onClick={() => {
                      if (!editEnable) {
                        handlerSocialClick(facebook);
                      } else {
                        setSocialOpen(true);
                      }
                    }}
                  />
                )}
                {(linkedin || editEnable) && (
                  <LinkedInIcon
                    onClick={() => {
                      if (!editEnable) {
                        handlerSocialClick(linkedin);
                      } else {
                        setSocialOpen(true);
                      }
                    }}
                  />
                )}
              </IconArea>
            </Grid>
            {iframeLink && (
              <Grid item lg={7} xs={12}>
                <IframeMap src={iframeLink} />
              </Grid>
            )}
          </Grid>
        </Content>
        <Powered>
          <p>Desenvolvido por QYON</p>
        </Powered>
      </Section>
    </>
  );
};

export default SectionFooter;
