import { Zoom } from "@material-ui/core";
import { LightTooltip } from "../../styles/GlobalStyle";

export const TooltipContainer = ({
  title,
  children,
  sidebar,
  customTooltip,
  beta,
}) =>
  sidebar?.open && !customTooltip ? (
    <>{children}</>
  ) : (
    <LightTooltip
      title={title}
      placement="right"
      TransitionComponent={Zoom}
      TransitionProps={{ timeout: 400 }}
    >
      {children}
    </LightTooltip>
  );
