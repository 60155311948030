import { search } from "../../BaseRepositoryComponents";

const path = `${process.env.REACT_APP_PAINEL_API_URL}/api/v1/system`;

export async function getAccountantSystemVersions({ id }) {
  return search({
    path: `${path}${id ? `/${id}` : ""}/version/accountant`,
    method: "GET",
  });
}
