import React, { createContext, useState } from "react";

const ModalAddressContext = createContext();

const ModalAddressContextProvider = ({ children }) => {
  const [mainAddress, setMainAddress] = useState(false);
  const [postalCode, setPostalCode] = useState("");
  const [street, setStreet] = useState("");
  const [addressNumber, setAddressNumber] = useState("");
  const [district, setDistrict] = useState("");
  const [addressComplement, setAddressComplement] = useState("");
  const [city, setCity] = useState(null);
  const [state, setState] = useState(null);
  const [country, setCountry] = useState(null);

  const [listState, setListState] = useState([]);
  const [listCity, setListCity] = useState([]);
  const [listCountry, setListCountry] = useState([]);
  const [loadingInputsAddress, setLoadingInputsAddress] = useState({});

  return (
    <ModalAddressContext.Provider
      value={{
        mainAddress,
        setMainAddress,
        postalCode,
        setPostalCode,
        street,
        setStreet,
        addressNumber,
        setAddressNumber,
        district,
        setDistrict,
        addressComplement,
        setAddressComplement,
        city,
        setCity,
        state,
        setState,
        country,
        setCountry,

        listState,
        setListState,
        listCity,
        setListCity,
        listCountry,
        setListCountry,
        loadingInputsAddress,
        setLoadingInputsAddress,
      }}
    >
      {children}
    </ModalAddressContext.Provider>
  );
};

export { ModalAddressContextProvider, ModalAddressContext };
