import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";

const UseChatMonitorContext = createContext();

const UseChatMonitorProvider = ({ children }) => {
  const [attendants, setAttendants] = useState([]);
  const [currentConversations, setCurrentConversations] = useState([]);
  const [companyValidation, setCompanyValidation] = useState(null);
  const [currentScreen, setCurrentScreen] = useState("attendants");
  const [badgeFilters, setBadgeFilters] = useState(false);
  const [openFilters, setOpenFilters] = useState(false);
  const [nameFilter, setNameFilter] = useState("");
  const [statusFilter, setStatusFilter] = useState("");
  const [departmentFilter, setDepartmentFilter] = useState("");

  const listAttendantsFilter = useMemo(() => {
    if (attendants?.length)
      return attendants.filter((attendant) => {
        if (
          statusFilter &&
          !(Number(attendant?.statusAttendance) === +statusFilter)
        )
          return false;

        if (
          nameFilter &&
          !attendant?.attendant?.name
            ?.toLowerCase()
            .includes(nameFilter?.toLowerCase())
        )
          return false;

        if (departmentFilter) {
          return attendant?.departments?.some(
            (department) => +department.productId === +departmentFilter?.value
          );
        }
        return true;
      });
  }, [attendants, departmentFilter, nameFilter, statusFilter]);

  const lisCurrentConversations = useMemo(() => {
    if (currentConversations?.length)
      return currentConversations.filter((customer) => {
        if (
          nameFilter &&
          !customer?.name?.toLowerCase().includes(nameFilter?.toLowerCase()) &&
          !customer?.company?.name
            ?.toLowerCase()
            .includes(nameFilter?.toLowerCase())
        )
          return false;

        if (
          departmentFilter &&
          !(customer?.department?.id?.toString() === departmentFilter?.value)
        )
          return false;
        return true;
      });
  }, [currentConversations, departmentFilter, nameFilter]);

  const validationBadgeFilters = useCallback(() => {
    if (currentScreen === "attendants") {
      return !openFilters && (statusFilter || departmentFilter);
    } else {
      return departmentFilter && !openFilters;
    }
  }, [currentScreen, departmentFilter, openFilters, statusFilter]);

  const contextValue = useMemo(
    () => ({
      attendants,
      setAttendants,
      currentConversations,
      setCurrentConversations,
      companyValidation,
      setCompanyValidation,
      currentScreen,
      setCurrentScreen,
      badgeFilters,
      setBadgeFilters,
      openFilters,
      setOpenFilters,
      nameFilter,
      setNameFilter,
      statusFilter,
      setStatusFilter,
      departmentFilter,
      setDepartmentFilter,
      listAttendantsFilter,
      lisCurrentConversations,
      validationBadgeFilters,
    }),
    [
      attendants,
      setAttendants,
      currentConversations,
      setCurrentConversations,
      companyValidation,
      setCompanyValidation,
      currentScreen,
      setCurrentScreen,
      badgeFilters,
      setBadgeFilters,
      openFilters,
      setOpenFilters,
      nameFilter,
      setNameFilter,
      statusFilter,
      setStatusFilter,
      departmentFilter,
      setDepartmentFilter,
      listAttendantsFilter,
      lisCurrentConversations,
      validationBadgeFilters,
    ]
  );

  return (
    <UseChatMonitorContext.Provider value={contextValue}>
      {children}
    </UseChatMonitorContext.Provider>
  );
};

const useMonitor = () => {
  const context = useContext(UseChatMonitorContext);

  if (!context)
    throw new Error("useMonitor must be used within an UseChatMonitorProvider");

  return context;
};

export { UseChatMonitorProvider, useMonitor };
