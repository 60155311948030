import { v4 as uuidv4 } from "uuid";

import { isCompany } from "../../../utils/panelType";
import { getEnvironment } from "../../../utils/environment";

export const createDocumentEmitted = async (
  body,
  cnpj,
  generateTicket,
  email = ""
) => {
  let accountantCnpj =
    localStorage.getItem("isAccountantPanel") === "true"
      ? localStorage.getItem("documentPanel")
      : localStorage.getItem("accountantCnpjPanel");

  if (isCompany() === true) {
    accountantCnpj = localStorage.getItem("documentPanel");
  }

  let environment = getEnvironment();

  let createCompanyJson = {
    id: uuidv4(),
    version: "v1.0.0",
    from: "QYON_DASH",
    to: "ALL",
    timestamp: new Date().getTime(),
    payload: {
      email: {
        to: email !== "" ? email : localStorage.getItem("emailPanel"),
        cc: "",
        body: "",
      },
      documents: [
        {
          ...body,
        },
      ],
    },
    extraData: {
      cnpj: cnpj,
      accountantCnpj: accountantCnpj,
      generateTicket: generateTicket,
      sub: localStorage.getItem("subCognitoPanel"),
      email: localStorage.getItem("emailPanel"),
      createAccess: isCompany() === true ? false : true,
      env: environment,
    },
    event: "DOCUMENT_EMITTED",
    priority: "NOT_CRITICAL",
  };

  let myHeaders = new Headers();
  myHeaders.append("Authorization", "18309cd6-30ca-4647-b289-fb2ae3e1d2f6");
  myHeaders.append("Content-Type", "application/json");

  let requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: JSON.stringify(createCompanyJson),
    redirect: "follow",
  };

  let response = await new Promise((resolve, reject) => {
    fetch("https://hub.qyon.info/v1/hub/message", requestOptions)
      .then((response) => resolve(response))
      .catch((error) => resolve(null));
  });

  return response;
};
