function validEmail(email) {
  const re =
    /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

function validNotNull(data) {
  let errorsValidation = {};
  let error = false;

  data.forEach((element) => {
    if (!element.value) {
      errorsValidation[element.key] = "Campo obrigatório";
      error = true;
    } else {
      errorsValidation[element.key] = false;
    }
  });

  return { error, errorsValidation };
}

function validTypeFile({ file, types }) {
  try {
    if (!file || !Array.isArray(types) || types.length < 1) {
      return false;
    }

    const valid = types.filter((e) => {
      if (file.name.toLowerCase().includes(e.toLowerCase())) {
        return e;
      } else {
        return false;
      }
    });

    if (!file.name.includes(valid[0])) {
      return false;
    }

    return true;
  } catch (err) {
    console.error(err);
    return false;
  }
}

function validJson(item) {
  item = typeof item !== "string" ? JSON.stringify(item) : item;

  try {
    item = JSON.parse(item);
  } catch (e) {
    return false;
  }

  if (typeof item === "object" && item !== null) {
    return true;
  }

  return false;
}

function validPasswordCoginito(password) {
  try {
    let passValidation = {
      error: false,
      lowerCase: false,
      uperCase: false,
      number: false,
      minLength: false,
    };

    if (password.length >= 8) {
      passValidation.minLength = true;
    }

    if (password.length > 0) {
      password.split("").forEach((element) => {
        if (/^[A-Z]*$/.test(element)) {
          passValidation.uperCase = true;
        } else if (/^[a-z]*$/.test(element)) {
          passValidation.lowerCase = true;
        } else if (/[0-9]/.test(element)) {
          passValidation.number = true;
        }
      });
    }

    if (
      !passValidation.lowerCase ||
      !passValidation.uperCase ||
      !passValidation.number ||
      !passValidation.minLength
    ) {
      passValidation.error = true;
    }

    return passValidation;
  } catch (error) {
    return {
      error: true,
      lowerCase: false,
      uperCase: false,
      number: false,
      minLength: false,
    };
  }
}

function validateCnpj(cnpj) {
  cnpj = cnpj.replace(/[^\d]+/g, "");

  if (cnpj === "") return false;

  if (cnpj.length !== 14) return false;

  // Elimina CNPJs invalidos conhecidos
  if (
    cnpj === "00000000000000" ||
    cnpj === "11111111111111" ||
    cnpj === "22222222222222" ||
    cnpj === "33333333333333" ||
    cnpj === "44444444444444" ||
    cnpj === "55555555555555" ||
    cnpj === "66666666666666" ||
    cnpj === "77777777777777" ||
    cnpj === "88888888888888" ||
    cnpj === "99999999999999"
  )
    return false;

  // Valida DVs
  let tamanho = cnpj.length - 2;
  let numeros = cnpj.substring(0, tamanho);
  let digitos = cnpj.substring(tamanho);
  let soma = 0;
  let pos = tamanho - 7;
  for (let i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--;
    if (pos < 2) pos = 9;
  }
  let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
  if (resultado !== digitos.charAt(0)) return false;

  tamanho = tamanho + 1;
  numeros = cnpj.substring(0, tamanho);
  soma = 0;
  pos = tamanho - 7;
  for (let i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--;
    if (pos < 2) pos = 9;
  }
  resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
  if (resultado !== digitos.charAt(1)) return false;

  return true;
}

function validateCPF(cpf) {
  cpf = cpf.replace(/[^\d]+/g, "");
  if (cpf.length !== 11 || /^(\d)\1{10}$/.test(cpf)) {
    return false;
  }
  let sum = 0;
  for (let i = 0; i < 9; i++) {
    sum += parseInt(cpf.charAt(i)) * (10 - i);
  }
  let rest = sum % 11;
  let digit1 = rest < 2 ? 0 : 11 - rest;
  sum = 0;
  for (let i = 0; i < 10; i++) {
    sum += parseInt(cpf.charAt(i)) * (11 - i);
  }
  rest = sum % 11;
  var digit2 = rest < 2 ? 0 : 11 - rest;
  return (
    digit1 === parseInt(cpf.charAt(9)) && digit2 === parseInt(cpf.charAt(10))
  );
}

export {
  validEmail,
  validNotNull,
  validTypeFile,
  validateCnpj,
  validJson,
  validPasswordCoginito,
  validateCPF,
};
