export const Cnd = ({
  primaryColor,
  secondaryColor = "#0a73db",
  size = "18",
}) => {
  return (
    <svg
      width={size}
      height={size}
      fill={primaryColor}
      xmlns="http://www.heightw fill={fill}3.org/2000/svg"
      viewBox="0 0 12 18"
    >
      <defs>
        <style>
          .a5f4f484-ddef-4abe-afd3-71271a15564c.acebd434-01cf-4cdd-964f-4790ffb92a29
        </style>
      </defs>
      <g id="b0ce1253-22ad-4cee-a9f3-e37d1d90d387" data-name="Camada 2">
        <g id="ff835384-897a-4740-9069-7e73c02aea15" data-name="Camada 1">
          <g id="b6aa2f60-c931-4445-9625-7dbe4331a7ee" data-name="Grupo 2032">
            <rect
              fill={primaryColor}
              id="aea3bf86-25ac-4e4d-ae09-39082aceb7bc"
              data-name="Retângulo 320"
              class="a5f4f484-ddef-4abe-afd3-71271a15564c"
              width="12"
              height="18"
              rx="2"
            />
            <rect
              fill={secondaryColor}
              id="e9a3d939-74d6-4a4d-9b8a-49c9d71b196a"
              data-name="Retângulo 335"
              class="acebd434-01cf-4cdd-964f-4790ffb92a29"
              x="2"
              y="4"
              width="8"
              height="2"
            />
            <rect
              fill={secondaryColor}
              id="bbeece46-da69-4f18-bfa3-dc627c6ac254"
              data-name="Retângulo 336"
              class="acebd434-01cf-4cdd-964f-4790ffb92a29"
              x="2"
              y="8"
              width="6"
              height="2"
            />
            <rect
              fill={secondaryColor}
              id="ab7b056c-b50c-4d4a-bae9-4ea556fffa15"
              data-name="Retângulo 337"
              class="acebd434-01cf-4cdd-964f-4790ffb92a29"
              x="2"
              y="12"
              width="4"
              height="2"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};
