import { Grid } from "@material-ui/core";

import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { GridStyled, Title } from "../../../styles/GlobalStyle";
import AccordionCard from "./AccordionCard";

import { useMemo, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import Loading from "../../../components/basics/Loading";
import Button from "../../../components/buttons/Btn";
import useAlerts from "../../../hooks/useAlerts";
import {
  getFilesS3,
  getMessages,
  getTicket,
} from "../../../repositories/integrations/crm/SupportRepository";
import ModalAttachment from "../../../utils/ModalAttachment";
import { useTicket } from "../contexts/EditSupportServiceContext";
import useTicketController from "../controllers/TicketController";
import { middlewareFiles } from "../middlewares";
import GeneralData from "./GeneralData";
import GridCard from "./GridCard";
import HTMLEditor from "./HTMLEditor";
import InteractionHistoricTicket from "./InteractionHistoricTicket";
import ListFiles from "./ListFiles";
import SkeletonCards from "./SkeletonCards";

const TicketData = ({ typeScreen, ticketId }) => {
  const queryClient = useQueryClient();
  const { toastMessage, dialogMessage } = useAlerts();
  const { push } = useHistory();

  const [loading, setLoading] = useState(false);
  const [urlFileState, setUrlFileState] = useState("");
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [openModalAttachment, setOpenModalAttachment] = useState(false);

  const { handleSave, fields, setGeneralData } = useTicket();

  const { getFileWithBase64, formatErrorMessage, objMessages } =
    useTicketController();

  // -------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

  const { isFetching: loadingTicket, data: resultTicket } = useQuery(
    ["support-tickets", ticketId],
    () =>
      getTicket({
        id: ticketId,
        ...fields,
      }),
    {
      enabled: !!ticketId,
      InitialData: null,
    }
  );

  const ticket = useMemo(() => {
    if (resultTicket) {
      if (resultTicket?.response) {
        return resultTicket?.response[0] || null;
      } else {
        dialogMessage({
          message: "Nenhum atendimento encontrado!",
          status: "warning",
        });
        push("../../attendance/search");
        queryClient.invalidateQueries("support-tickets");
      }
    }

    return null;
  }, [dialogMessage, push, queryClient, resultTicket]);

  const { isFetching: loadingMessages, data: resultMessages } = useQuery(
    ["messages", ticketId],
    () => getMessages({ attendanceId: ticketId }),
    {
      enabled: !!ticketId,
      InitialData: null,
    }
  );

  const messages = useMemo(() => {
    return resultMessages?.response || null;
  }, [resultMessages]);

  const { isFetching: loadingFiles, data: resultFiles } = useQuery(
    ["files", ticketId],
    () => getFilesS3({ attendanceId: ticketId }),
    {
      enabled: !!ticketId,
      InitialData: null,
    }
  );

  const files = useMemo(() => {
    return middlewareFiles(resultFiles?.response) || null;
  }, [resultFiles]);

  // -------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

  useEffect(() => {
    if (files) setUploadedFiles(files);
  }, [files]);

  useEffect(() => {
    if (ticket) {
      setGeneralData(ticket);
    }
  }, [setGeneralData, ticket]);

  // -------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

  const handleSubmit = async () => {
    setLoading(true);

    const interactionData = await objMessages();

    if (interactionData) {
      const converterUploadedFiles = await getFileWithBase64(uploadedFiles);

      const resultSave = await handleSave({
        ...interactionData,
        files: [...converterUploadedFiles, ...interactionData?.files],
        ticketData: ticket,
      });

      const errors = Array.isArray(resultSave) && resultSave.length > 0;

      dialogMessage({
        message: errors
          ? await formatErrorMessage(resultSave)
          : "Dados do atendimento salvos com sucesso!",
        status: errors ? "warning" : "success",
      });

      if (!errors)
        document.getElementById("publicInteraction_rte-edit-view").innerHTML =
          "<p><br></p>";
      document.getElementById("publicInteraction").firstChild.value = "";

      setLoading(false);
    } else {
      toastMessage({
        status: "warning",
        message: "É necessário inserir alguma interação!",
      });

      setLoading(false);
    }
  };

  // -------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------

  return (
    <>
      {openModalAttachment && (
        <ModalAttachment
          setOpen={setOpenModalAttachment}
          open={openModalAttachment}
          urlFile={urlFileState}
        />
      )}

      <Loading open={loading} />

      <Grid item xs={12} container justifyContent="space-between">
        <Title color="blue">Atendimento N° {ticketId}</Title>
      </Grid>

      {loadingTicket ? (
        <SkeletonCards />
      ) : (
        <AccordionCard title="Dados Gerais" defaultExpanded={true}>
          <GeneralData />
        </AccordionCard>
      )}

      {loadingFiles ? (
        <SkeletonCards />
      ) : (
        <AccordionCard
          title="Anexos"
          defaultExpanded={true}
          help={true}
          helpText={
            <>
              <center>Ajuda</center>
              <ul>
                <li>
                  Arraste arquivos e/ou imagens ou clique no campo para abrir o
                  Explorador de Arquivos do seu computador
                </li>
              </ul>
              <center>
                Importante: Só é permitido anexar arquivos e imagens que tenham
                menos de 8Mb(800.000Kb)
              </center>
            </>
          }
        >
          <GridStyled xs={12} text-align="center">
            <ListFiles
              uploadedFiles={uploadedFiles}
              statusAttendance={ticket?.solutionDate}
              setUploadedFiles={setUploadedFiles}
              setUrlFileState={setUrlFileState}
              setOpenModal={setOpenModalAttachment}
              typeScreen="edit"
            />
          </GridStyled>
        </AccordionCard>
      )}

      {loadingMessages || loadingFiles ? (
        <SkeletonCards />
      ) : (
        <AccordionCard title="Histórico de Interações" defaultExpanded={true}>
          <GridStyled xs={12} text-align="center">
            <InteractionHistoricTicket
              loading={loadingMessages}
              messages={messages}
              uploadedFiles={files}
              originRender="accordion"
            />
          </GridStyled>
        </AccordionCard>
      )}

      <GridCard
        title="Interação"
        help={true}
        helpText={
          <>
            <center>Dicas</center>
            <ul>
              <li>Cole imagens utilizando os atalhos Ctrl+C e Ctrl+V</li>
              <li>
                Estilize seu texto da forma que preferir, utilizando as
                ferramentas disponiveis, como: Negrito, Itálico, Sublinhado,
                dentre outras
              </li>
            </ul>
          </>
        }
      >
        <Grid xs={12}>
          <HTMLEditor id="publicInteraction" />
        </Grid>
      </GridCard>

      <GridStyled
        item
        container
        justifyContent="flex-end"
        spacing={1}
        margin-bottom="10"
      >
        <Grid item flex={1}>
          <Button
            label="Cancelar"
            bg-color="tertiary"
            onClick={() => push("../../attendance/search")}
          />
        </Grid>

        <Grid item flex={1}>
          <Button label="salvar" onClick={handleSubmit} />
        </Grid>
      </GridStyled>
    </>
  );
};

export default TicketData;
