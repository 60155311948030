import {
  Box,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";
import { Delete } from "@material-ui/icons";
import React from "react";
import { useState } from "react";
import InputMask from "react-input-mask";
import IntlCurrencyInput from "react-intl-currency-input";
import Btn from "../../buttons/Btn";
import useDocumentModalController from "../controllers/DocumentModalController";
import {
  gridSectionTitle,
  TabTextField,
  valueFormStyle,
} from "../styles/DocumentModal";

export const DocumentDctfReturnTab = (props) => {
  const [companyName, setCompanyName] = useState(
    props.document.data.company_name
  );

  const [govid, setGovid] = useState(props.document.data.govid);
  const [govidMask, setGovidMask] = useState(
    props.document.data.govid.replace(/[^\d]/g, "").length <= 11 &&
      props.document.data.govid.replace(/[^\d]/g, "").length > 0
      ? "999.999.999-999"
      : "99.999.999/9999-99"
  );
  const [receiptNumber, setReceiptNumber] = useState(
    props.document.data.receipt_number
  );

  const [receiptRectifiedNumber, setReceiptRectifiedNumber] = useState(
    props.document.data.rectified_declaration_receipt_number
  );

  const [tributaryClass, setTributaryClass] = useState(
    props.document.data.tributary_class
  );

  const [diplomaticMission, setDiplomaticMission] = useState(
    props.document.data.diplomatic_mission_consular_office
  );

  const [periodExibition, setPeriodExibition] = useState(
    `${props.document.data.period.substring(
      5,
      7
    )}/${props.document.data.period.substring(0, 4)}`
  );

  const [dateTransmission, setDateTransmission] = useState(
    props.document.data.date_transmission
  );

  const [abscenceTriggeringFacts, setAbscenceTriggeringFacts] = useState(
    props.document.data.abscence_triggering_facts
  );
  const [listDebitCredit, setListDebitCredit] = useState(
    props.document.data.debit_credit_list
  );

  const [documentName, setDocumentName] = useState(
    props.document.data.use_suggestion
      ? props.document.data.name_suggestion
      : props.document.name
  );

  const addListItemDebitCredit = () => {
    let debitCreditItem = {
      code_receita: "",
      description: "",
      period: "",
      debit_apurated: 0,
      to_pay: 0,
    };
    setListDebitCredit([...listDebitCredit, debitCreditItem]);

    props.document.data.debit_credit_list = [
      ...props.document.data.debit_credit_list,
      debitCreditItem,
    ];
  };

  const removeDebitCreditIndex = (index) => {
    if (listDebitCredit.length === 1) {
      setListDebitCredit([]);
    } else {
      let newListDebitCredit = [...listDebitCredit];
      newListDebitCredit.splice(index, 1);
      setListDebitCredit(newListDebitCredit);
    }
  };

  const { verifyAndChangeGovidMask } = useDocumentModalController();

  return (
    <Grid container spacing={2}>
      <Grid item xs={9}>
        <TabTextField
          label="Nome da empresa"
          variant="outlined"
          key={"NAME_COMPANY_" + props.index}
          value={companyName}
          onChange={(ev) => {
            setCompanyName(ev.target.value);
            props.document.data.company_name = ev.target.value;
          }}
        />
      </Grid>

      <Grid item xs={3}>
        <InputMask
          onBlur={(ev) => {
            props.setCpfCnpjFocus(false);
          }}
          mask={govidMask}
          disabled={false}
          key={"CPF_CNPJ" + props.index + props.govIdKey}
          maskChar=""
          value={govid}
          onChange={(ev) => {
            setGovid(ev.target.value.replace(/[^\d]/g, ""));
            props.document.data.govid = ev.target.value.replace(/[^\d]/g, "");
            verifyAndChangeGovidMask(
              ev.target.value.replace(/[^\d]/g, ""),
              setGovidMask
            );
            props.setCpfCnpjFocus(true);
          }}
        >
          {() => (
            <TabTextField
              autoFocus={props.cpfCnpjFocus}
              label="CPF/CNPJ"
              variant="outlined"
              key={"CPF_CNPJ" + props.index + props.documentKey}
            />
          )}
        </InputMask>
      </Grid>

      <Grid item xs={12}>
        <TabTextField
          label="Número do Recibo"
          variant="outlined"
          key={"DOC_NUMBER" + props.index}
          value={receiptNumber}
          onChange={(ev) => {
            setReceiptNumber(ev.target.value);
            props.document.data.receipt_number = ev.target.value;
          }}
        />
      </Grid>

      <Grid item xs={12}>
        <TabTextField
          label="Número do Recibo da Declaração Retificada"
          variant="outlined"
          key={"DOC_RETIF_NUMBER" + props.index}
          value={receiptRectifiedNumber}
          onChange={(ev) => {
            setReceiptRectifiedNumber(ev.target.value);
            props.document.data.rectified_declaration_receipt_number =
              ev.target.value;
          }}
        />
      </Grid>

      <Grid item xs={5}>
        <TabTextField
          label="Classe Tributária"
          variant="outlined"
          key={"TRIBUTARY_CLASS" + props.index}
          value={tributaryClass}
          onChange={(ev) => {
            setTributaryClass(ev.target.value);
            props.document.data.tributary_class = ev.target.value;
          }}
        />
      </Grid>
      <Grid item xs={7}>
        <TabTextField
          label="Missão Diplomática ou Repartição Consular"
          variant="outlined"
          key={"DIPLOMATIC_MISSION" + props.index}
          value={diplomaticMission}
          onChange={(ev) => {
            setDiplomaticMission(ev.target.value);
            props.document.data.diplomatic_mission_consular_office =
              ev.target.value;
          }}
        />
      </Grid>

      <Grid item xs={4}>
        <InputMask
          mask="99/9999"
          disabled={false}
          maskChar=""
          value={periodExibition}
          onChange={(ev) => {
            setPeriodExibition(ev.target.value);
            props.document.data.period = `${ev.target.value.substring(
              3,
              7
            )}-${ev.target.value.substring(0, 2)}`;
          }}
        >
          {() => (
            <TabTextField
              label="Período de Apuração"
              variant="outlined"
              key={"COMPETENCE" + props.index}
            />
          )}
        </InputMask>
      </Grid>

      <Grid item xs={4}>
        <div
          class="MuiFormControl-root MuiTextField-root "
          style={valueFormStyle}
        >
          <label
            style={{ background: "#f2f2f2" }}
            class="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiInputLabel-marginDense MuiInputLabel-outlined MuiFormLabel-filled"
            data-shrink="true"
          >
            Data de Transmissão
          </label>

          <div class="MuiInputBase-root MuiOutlinedInput-root MuiInputBase-formControl MuiInputBase-marginDense MuiOutlinedInput-marginDense">
            <input
              type="datetime-local"
              variant="outlined"
              class="MuiInputBase-input MuiOutlinedInput-input MuiInputBase-inputMarginDense MuiOutlinedInput-inputMarginDense"
              key={"TRANSMISSION_DATE" + props.index}
              value={dateTransmission}
              onChange={(ev) => {
                setDateTransmission(ev.target.value);
                props.document.data.date_transmission = ev.target.value;
              }}
            />
          </div>
        </div>
      </Grid>

      <Grid item xs={4}>
        <FormControl variant="outlined" fullWidth>
          <InputLabel>Ausência de Fatos Geradores</InputLabel>
          <Select
            autoWidth
            variant="outlined"
            label="Ausência de Fatos Geradores"
            key={"AUSENCIA_FATOS" + props.index}
            value={abscenceTriggeringFacts}
            onChange={(ev) => {
              setAbscenceTriggeringFacts(ev.target.value);
              props.document.data.abscence_triggering_facts = ev.target.value;
            }}
          >
            <MenuItem value={"Sim"}>Sim</MenuItem>
            <MenuItem value={"Não"}>Não</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} style={gridSectionTitle}>
        <label>Débitos Apurados e Créditos Vinculados</label>
      </Grid>
      {listDebitCredit.map((element, i) => (
        <>
          <Grid item xs={3}>
            <InputMask
              mask="9999-99"
              disabled={false}
              maskChar=""
              value={element.code_receita}
              onChange={(ev) => {
                let newListDebitCredit = [...listDebitCredit];
                newListDebitCredit[i].code_receita = ev.target.value;
                props.document.data.debit_credit_list[i].code_receita =
                  ev.target.value;
                setListDebitCredit(newListDebitCredit);
              }}
            >
              {() => (
                <TabTextField
                  label="Cód. Receita"
                  variant="outlined"
                  key={"CODE_RECEITA" + props.index + "_" + i}
                />
              )}
            </InputMask>
          </Grid>

          <Grid item xs={8}>
            <TabTextField
              label="Descrição"
              variant="outlined"
              key={"DESCRIPTION" + props.index + "_" + i}
              value={element.description}
              onChange={(ev) => {
                let newDebitCreditList = [...listDebitCredit];
                newDebitCreditList[i].description = ev.target.value;
                props.document.data.debit_credit_list[i].description =
                  ev.target.value;
                setListDebitCredit(newDebitCreditList);
              }}
            />
          </Grid>

          <Grid item xs={1}>
            <Box display="flex" justifyContent="flex-end" justifyItems="center">
              <IconButton
                component="label"
                onClick={() => removeDebitCreditIndex(i)}
              >
                <Delete />
              </IconButton>
            </Box>
          </Grid>

          <Grid item xs={4}>
            <InputMask
              mask="99/9999"
              disabled={false}
              maskChar=""
              value={element.period}
              onChange={(ev) => {
                let newDebitCreditList = [...listDebitCredit];
                newDebitCreditList[i].period = ev.target.value;
                props.document.data.debit_credit_list[i].period =
                  ev.target.value;
                setListDebitCredit(newDebitCreditList);
              }}
            >
              {() => (
                <TabTextField
                  final
                  label="Período"
                  variant="outlined"
                  key={"PERIOD_APURATION_DEBIT" + props.index + "_" + i}
                />
              )}
            </InputMask>
          </Grid>
          <Grid item xs={4}>
            <div
              class="MuiFormControl-root MuiTextField-root "
              style={valueFormStyle}
            >
              <label
                style={{ background: "#f2f2f2" }}
                class="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiInputLabel-marginDense MuiInputLabel-outlined MuiFormLabel-filled"
                data-shrink="true"
              >
                Débito Apurado
              </label>

              <div class="MuiInputBase-root MuiOutlinedInput-root MuiInputBase-formControl MuiInputBase-marginDense MuiOutlinedInput-marginDense">
                <IntlCurrencyInput
                  currency="BRL"
                  value={element.debit_apurated}
                  class="MuiInputBase-input MuiOutlinedInput-input MuiInputBase-inputMarginDense MuiOutlinedInput-inputMarginDense"
                  config={{
                    locale: "pt-BR",
                    formats: {
                      number: {
                        BRL: {
                          style: "currency",
                          currency: "BRL",
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        },
                      },
                    },
                  }}
                  onChange={(event, value, maskedValue) => {
                    event.preventDefault();
                    let newDebitCreditList = [...listDebitCredit];
                    newDebitCreditList[i].debit_apurated = maskedValue;
                    props.document.data.debit_credit_list[i].debit_apurated =
                      maskedValue;
                    setListDebitCredit(newDebitCreditList);
                  }}
                />
              </div>
            </div>
          </Grid>

          <Grid item xs={4}>
            <div
              class="MuiFormControl-root MuiTextField-root "
              style={valueFormStyle}
            >
              <label
                style={{ background: "#f2f2f2" }}
                class="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiInputLabel-marginDense MuiInputLabel-outlined MuiFormLabel-filled"
                data-shrink="true"
              >
                A Pagar
              </label>

              <div class="MuiInputBase-root MuiOutlinedInput-root MuiInputBase-formControl MuiInputBase-marginDense MuiOutlinedInput-marginDense">
                <IntlCurrencyInput
                  currency="BRL"
                  value={element.to_pay}
                  class="MuiInputBase-input MuiOutlinedInput-input MuiInputBase-inputMarginDense MuiOutlinedInput-inputMarginDense"
                  config={{
                    locale: "pt-BR",
                    formats: {
                      number: {
                        BRL: {
                          style: "currency",
                          currency: "BRL",
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        },
                      },
                    },
                  }}
                  onChange={(event, value, maskedValue) => {
                    event.preventDefault();
                    let newDebitCreditList = [...listDebitCredit];
                    newDebitCreditList[i].to_pay = maskedValue;
                    props.document.data.debit_credit_list[i].to_pay =
                      maskedValue;
                    setListDebitCredit(newDebitCreditList);
                  }}
                />
              </div>
            </div>
          </Grid>
        </>
      ))}

      <Grid item xs={12}>
        <Box display="flex" justifyContent="flex-start">
          <Btn
            label="Adicionar"
            onClick={() => {
              addListItemDebitCredit();
            }}
          />
        </Box>
      </Grid>

      {props.documentModalLoading === false && (
        <>
          <Grid item xs={12}>
            <TabTextField
              final
              label={
                props.document.use_suggestion
                  ? "Nome do documento (sugestão)"
                  : "Nome do documento (original)"
              }
              variant="outlined"
              key={"DOCUMENT_NAME" + props.index + " - " + props.documentKey}
              value={documentName}
              onChange={(ev) => {
                setDocumentName(ev.target.value);
                props.document.name_suggestion = ev.target.value;
              }}
            />
          </Grid>
        </>
      )}
    </Grid>
  );
};
