import React from "react";
import {
  Avatar,
  Box,
  Divider,
  Grid,
  List,
  ListItem,
  ListSubheader,
  Typography,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import {
  ChatBubbleOutlined,
  Forum as ForumIcon,
  WhatsApp as WhastAppIcon,
} from "@material-ui/icons";
import { useTheme, withStyles } from "@material-ui/core/styles";
import { useChatContext } from "../contexts/ChatContext";
import Close from "@material-ui/icons/Close";
import { ContainerIcon, LightTooltip } from "../../../styles/GlobalStyle";
import { IconOriginAttendance } from "../styles/Contact";
import ContactController from "../controllers/ContactController";

const DarkTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.black,
    color: "#fff",
    boxshadow: theme.shadows[2],
    placement: "right",
    fontSize: 13,
  },
}))(Tooltip);

const enumOriginChat = Object.freeze({
  WEB: {
    icon: <ChatBubbleOutlined styled={{ cursor: "default" }} />,
    tooltip: "Atendimento padrão Plataforma Qyon",
    badgeColor: "rgb(0, 111, 222)",
    iconSize: 11,
  },
  chat: {
    icon: <ChatBubbleOutlined styled={{ cursor: "default" }} />,
    tooltip: "Atendimento padrão Plataforma Qyon",
    badgeColor: "rgb(0, 111, 222)",
    iconSize: 11,
  },
  whatsappMeta: {
    icon: <WhastAppIcon styled={{ cursor: "default" }} />,
    tooltip: "Atendimento integrado ao WhastApp",
    badgeColor: "rgb(37,211,102)",
    iconSize: 15,
  },
});
export default function Contact() {
  const chatContext = useChatContext();
  const theme = useTheme();
  const contactController = ContactController();

  const Group = ({ title, children }) => {
    return (
      <>
        <Grid
          item
          container
          xs
          justifyContent="flex-end"
          style={{ height: "20px" }}
        >
          <Tooltip title="Fechar">
            <ContainerIcon
              onClick={() =>
                chatContext.setChat({
                  currentScreen: chatContext.SCREEN_ENUM.conversation,
                })
              }
              // style={{ borderRadius: '20%'}}
              iconColor="#CCCFD6"
              fontSize="20"
            >
              <IconButton>
                <Close />
              </IconButton>
            </ContainerIcon>
          </Tooltip>
        </Grid>
        <List
          style={{ width: "100%" }}
          component="nav"
          aria-labelledby="nested-list-subheader"
          subheader={
            <ListSubheader
              component="div"
              id="nested-list-subheader"
              style={{
                borderBottom: "1px solid #CCCFD6",
                width: "95%",
                margin: "auto",
                padding: "0",
                position: "relative",
              }}
            >
              {title}
            </ListSubheader>
          }
        >
          {children}
        </List>
      </>
    );
  };

  const GroupItem = ({
    title,
    subTitle,
    time,
    sessionId,
    tooltipSubTitle,
    integration,
  }) => {
    const getAvatarContent = (text) => {
      if (text) {
        let result = text.charAt(0);
        const splited = text.split(" ");
        if (splited.length > 1) result += splited[splited.length - 1].charAt(0);
        else result += text.charAt(1);
        return result;
      } else {
        return "";
      }
    };

    return (
      <>
        <ListItem alignItems="flex-start">
          <Grid container>
            <Grid xs={2} position="relative">
              <DarkTooltip title={title} enterDelay={300}>
                <Avatar alt={title}>{getAvatarContent(title)}</Avatar>
              </DarkTooltip>

              <DarkTooltip
                title={enumOriginChat[integration]?.tooltip}
                enterDelay={300}
              >
                <IconOriginAttendance
                  color={enumOriginChat[integration]?.badgeColor}
                >
                  <ContainerIcon
                    iconColor={"white"}
                    fontSize={enumOriginChat[integration]?.iconSize}
                  >
                    {enumOriginChat[integration]?.icon}
                  </ContainerIcon>
                </IconOriginAttendance>
              </DarkTooltip>
            </Grid>
            <Grid xs={7}>
              <Grid item style={{ fontSize: "16px", fontWeight: "700" }}>
                {title}
              </Grid>
              <Grid>
                <Grid
                  item
                  style={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    marginRight: "8px",
                  }}
                >
                  <Tooltip
                    title={tooltipSubTitle}
                    enterDelay={300}
                    leaveDelay={200}
                  >
                    <Typography
                      style={{ display: "inline", fontSize: "12px" }}
                      component="span"
                      variant="body2"
                      color="text.primary"
                    >
                      {subTitle}
                    </Typography>
                  </Tooltip>
                </Grid>
                {time && parseInt(time) > 0 ? (
                  <Grid item>há {time}</Grid>
                ) : (
                  <Grid item>Agora mesmo</Grid>
                )}
              </Grid>
            </Grid>
            <Grid
              xs={3}
              container
              alignContent="center"
              alignItems="center"
              style={{ justifyContent: "center" }}
            >
              <LightTooltip title="Conversar com o cliente" placement="top">
                <ContainerIcon
                  onClick={() => {
                    contactController.pullCustomer({ sessionId });
                  }}
                  bg-color="transparent"
                  padding="2px"
                >
                  <ForumIcon
                    style={{ width: "100%", color: "#006fde" }}
                    size={10}
                  />
                </ContainerIcon>
              </LightTooltip>
            </Grid>
          </Grid>
        </ListItem>
        <Divider variant="middle" />
      </>
    );
  };

  const RenderGroupQueue = () => {
    return (
      <Group title="Clientes na Fila">
        {chatContext.chat.queue &&
          chatContext.chat.queue.map(
            (i) =>
              i.name && (
                <GroupItem
                  title={i.name}
                  subTitle={
                    (i.companyName || "").substring(0, 10) +
                    " - " +
                    i.productName
                  }
                  time={i.waitingTime}
                  sessionId={i.sessionId}
                  tooltipSubTitle={i.companyName + " - " + i.productName}
                  integration={i.integrationType ?? i.origin}
                />
              )
          )}
        {chatContext.chat.queue.length === 0 && (
          <ListItem alignItems="flex-start">
            <Box
              style={{
                width: "100%",
                textAlign: "center",
                paddingTop: theme.spacing(2),
                paddingBottom: theme.spacing(2),
              }}
            >
              <Typography
                sx={{ display: "inline" }}
                component="span"
                variant="body2"
                color="text.primary"
              >
                Nenhum cliente na fila!
              </Typography>
            </Box>
          </ListItem>
        )}
      </Group>
    );
  };

  return (
    <Box style={{ width: "100%", overflowY: "scroll" }}>
      <RenderGroupQueue />
    </Box>
  );
}
