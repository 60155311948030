import React, { memo } from "react";

import Autocomplete from "@material-ui/lab/Autocomplete";
import { TextField } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";

import "./.Basics.css";

const SelectErp = ({
  optionsList = [],
  descriptionField,
  getOptionLabel,
  size = "small",
  value,
  setValue,
  required = false,
  error = false,
  helperText,
  getOptionSelected = (option, value) =>
    option && value ? option.id === value.id : "",
  onChange = (value) => {
    setValue(value);
  },
  loading = false,
  className = "filter-input",
  ...props
}) => {
  // É possível padronizar, colocando um limite de itens mostrados, a maneira de buscar, etc...
  // const filterOptions = createFilterOptions({
  // limit: 50,
  // matchFrom: "start",
  // stringify: (option) => option.title,
  // });
  return (
    <Autocomplete
      // filterOptions={filterOptions}
      key={"filter_autocomplete_" + props.label}
      options={Array.isArray(optionsList) ? optionsList : []}
      value={value}
      size={size}
      loading={loading}
      onChange={(event, newValue) => {
        onChange(newValue);
      }}
      getOptionSelected={getOptionSelected}
      getOptionLabel={
        getOptionLabel
          ? getOptionLabel
          : (option) => {
              return option && descriptionField ? option[descriptionField] : "";
            }
      }
      style={{ width: "100%" }}
      {...props}
      renderInput={(params) => (
        <TextField
          {...props}
          {...params}
          helperText={helperText}
          required={required}
          error={error}
          label={props.label}
          className={className}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
};

export default memo(SelectErp);
