import { useContext } from "react";

import { notify } from "../../../utils/notify";

import { GlobalContext } from "../../../contexts/GlobalContext";
import { GlobalVariables } from "../../../GlobalVariables";
import { getSystemsEntity } from "../../../repositories/panel/v1/EntityRepository";
import { postMap } from "../../../repositories/panel/v1/integrations/PlugRepository";
import { validEmail, validNotNull } from "../../../utils/validations";
import { usePortalAsClient } from "../../../utils/viewPortalAsClientHook";
import { ModalConfigSidebarContext } from "../contexts/ModalConfigSidebarContext";

export default function useModalConfigAccessController() {
  const {
    accessNameConfig,
    setAccessNameConfig,
    emailConfig,
    setTypeLimit,
    typeLimit,

    setErrors,
    setLoading,
    setOpenConfig,
  } = useContext(ModalConfigSidebarContext);
  const { companyUser, setCompanyUser, userLogged } = useContext(GlobalContext);
  const { clientViewed, isInClientView } = usePortalAsClient();

  const handleChangeAccessName = (e) => {
    setAccessNameConfig(
      e.target.value
        .normalize("NFD")
        .toLowerCase()
        .replace(/[^a-z0-9_]/g, "")
        .replace(/\s/g, "")
        .substr(0, 20)
    );
  };

  const handleSubmitConfig = async () => {
    try {
      setLoading(true);

      const valid = validNotNull([
        { value: accessNameConfig, key: "accessNameConfig" },
        { value: emailConfig, key: "emailConfig" },
      ]);

      if (!companyUser?.document) {
        valid.error = true;
        notify("O Cliente selecionado não possui documento.");
      }

      if (!validEmail(emailConfig)) {
        valid.error = true;
        valid.errorsValidation.emailConfig = "E-mail inválido";
      }

      setErrors(valid.errorsValidation);
      if (valid.error) return;

      const body = {
        ClienteCNPJ: companyUser?.document.replace(/[^0-9]+/g, ""),
        Rota: accessNameConfig,
        Trial: typeLimit === "trial",
        Freemium: typeLimit === "freemium",
        IdVersao: GlobalVariables.ID_ERP_VERSION_GESTAO_FACIL_PRO,
      };

      if (body.Trial) {
        const newDate = new Date();
        body.DataExpiracao = newDate.setDate(newDate.getDate() + 180);
      }

      const respMap = await postMap({
        body,
      });

      let urlErp = null;

      if (respMap?.success) {
        if (userLogged?.userId && companyUser?.companyId) {
          let systemsCompany = null;

          if (companyUser?.isAccountant) {
            systemsCompany = await getSystemsEntity({
              id: companyUser?.companyId,
            });
          } else {
            let systemsAccountant = null;

            if (companyUser?.accountantId)
              systemsAccountant = await getSystemsEntity({
                id: isInClientView
                  ? companyUser?.companyId
                  : companyUser?.accountantId,
              });

            let systemsCompanyLogged = await getSystemsEntity({
              id: isInClientView
                ? clientViewed?.company
                : companyUser?.companyId,
            });

            let allowedSystems = systemsAccountant ?? systemsCompanyLogged;

            systemsCompany = {
              success: true,
              data: (Array.isArray(allowedSystems?.data)
                ? allowedSystems?.data
                : []
              ).map((systAcc) => ({
                ...systAcc,
                configured:
                  (Array.isArray(systemsCompanyLogged?.data)
                    ? systemsCompanyLogged?.data
                    : []
                  ).find((systComp) => systComp.id === systAcc.id)
                    ?.configured || false,
              })),
            };
          }

          if (systemsCompany?.success && Array.isArray(systemsCompany?.data)) {
            systemsCompany = systemsCompany?.data;
          } else systemsCompany = [];

          urlErp = systemsCompany.find(
            (syst) => syst.id === GlobalVariables.ID_PRODUCT_ERP_PANEL
          );

          setCompanyUser({ systemsPermissions: systemsCompany });
        }

        notify("Acesso configurado com sucesso.", true, "success");
        setOpenConfig(false);

        if (urlErp?.url) {
          window.open(
            `${urlErp?.url}/zf/login/loginpanel?tokenCognito=${userLogged.token}&document=${companyUser.document}`,
            "_blank"
          );
        }

        return;
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleChangeTypeLimit = (e) => {
    setTypeLimit(e.target.value);
  };

  return {
    //Functions
    handleChangeAccessName,
    handleSubmitConfig,
    handleChangeTypeLimit,
  };
}
