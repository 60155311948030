import React, { createContext, useState } from "react";
const ManagementContext = createContext();

const ManagementContextProvider = ({ children }) => {
  // General
  const [loading, setLoading] = useState({});
  const [saving, setSaving] = useState(false);
  const [errors, setErrors] = useState(false);
  const [edited, setEdited] = useState(false);

  const [permissionTree, setPermissionTree] = useState(null);

  const [groupOptions, setGroupOptions] = useState(null);
  const [groupSelected, setGroupSelected] = useState(null);
  const [userOptions, setUserOptions] = useState(null);
  const [userSelected, setUserSelected] = useState(null);

  const [groupCheckedPermissions, setGroupCheckedPermissions] = useState([]);
  const [userCheckedPermissions, setUserCheckedPermissions] = useState([]);

  // Modal create group
  const [openModalCreateGroup, setOpenModalCreateGroup] = useState(false);

  return (
    <ManagementContext.Provider
      value={{
        loading,
        setLoading,
        saving,
        setSaving,
        errors,
        setErrors,
        edited,
        setEdited,

        permissionTree,
        setPermissionTree,

        groupOptions,
        setGroupOptions,
        groupSelected,
        setGroupSelected,
        userOptions,
        setUserOptions,
        userSelected,
        setUserSelected,

        groupCheckedPermissions,
        setGroupCheckedPermissions,
        userCheckedPermissions,
        setUserCheckedPermissions,

        openModalCreateGroup,
        setOpenModalCreateGroup,
      }}
    >
      {children}
    </ManagementContext.Provider>
  );
};

export { ManagementContextProvider, ManagementContext };
