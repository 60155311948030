import React, { createContext, useState } from "react";

const DashboardSettingContext = createContext();

const DashboardSettingContextProvider = ({ children }) => {
  const [widgets, setWidgets] = useState({});
  const [panelId, setPanelId] = useState(null);
  const [panelName, setPanelName] = useState("");
  const [homeWidgets, setHomeWidgets] = useState([]);
  const [versionWidget, setVersionWidget] = useState([]);
  const [idsWidgetsHomePage, setIdsWidgetsHomePage] = useState([]);
  const [uploadedLogo, setUploadedLogo] = useState([]);
  const [panelSelected, setPanelSelected] = useState(null);
  const [copyPanelSelected, setCopyPanelSelected] = useState(null);
  const [modalPanel, setModalPanel] = useState(false);
  const [newPanel, setNewPanel] = useState(false);
  const [loadingInputs, setLoadingInputs] = useState(false);
  const [refreshPanel, setRefreshPanel] = useState(false);

  const [panelsList, setPanelsList] = useState([]);
  const [listVersionsWidget, setListVersionsWidget] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingSave, setLoadingSave] = useState(false);
  const [errors, setErrors] = useState({});

  const [widgetEdit, setWidgetEdit] = useState({});

  return (
    <DashboardSettingContext.Provider
      value={{
        widgets,
        setWidgets,
        panelId,
        setPanelId,
        homeWidgets,
        setHomeWidgets,
        panelName,
        setPanelName,
        homeWidgets,
        setHomeWidgets,
        versionWidget,
        setVersionWidget,
        idsWidgetsHomePage,
        setIdsWidgetsHomePage,
        uploadedLogo,
        setUploadedLogo,
        panelSelected,
        setPanelSelected,
        copyPanelSelected,
        setCopyPanelSelected,
        panelsList,
        setPanelsList,
        versionWidget,
        setVersionWidget,
        widgetEdit,
        setWidgetEdit,
        modalPanel,
        setModalPanel,
        newPanel,
        setNewPanel,
        loadingInputs,
        setLoadingInputs,
        refreshPanel,
        setRefreshPanel,
        loadingSave,
        setLoadingSave,

        panelsList,
        setPanelsList,
        listVersionsWidget,
        setListVersionsWidget,
        loading,
        setLoading,
        errors,
        setErrors,
      }}
    >
      {children}
    </DashboardSettingContext.Provider>
  );
};

export { DashboardSettingContextProvider, DashboardSettingContext };
