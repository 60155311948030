import { useCallback, useContext } from "react";

import { postContract } from "../../../repositories/panel/v1/integrations/PlugRepository";
import { notify } from "../../../utils/notify";
import { validEmail, validNotNull } from "../../../utils/validations";
import { CustumerManagementContext } from "../contexts/CustomerManagementContext";
import { ModalConfigAccessContext } from "../contexts/ModalConfigAccessContext";
import { search } from "../../../repositories/BaseRepositoryComponents";

export default function useModalConfigAccessController() {
  const {
    accessNameConfig,
    setAccessNameConfig,
    emailConfig,
    setTypeLimit,
    errors,
    setPayer,
    setErrors,
  } = useContext(ModalConfigAccessContext);
  const {
    setLoading,
    setConfigAccessModalOpen,
    customersBpo,
    setCustomersBpo,
  } = useContext(CustumerManagementContext);

  const clearContext = () => {};

  const handleChangeAccessName = useCallback(
    (e) => {
      setAccessNameConfig(
        e.target.value
          .normalize("NFD")
          .toLowerCase()
          .replace(/[^a-z0-9_]/g, "")
          .replace(/\s/g, "")
          .substr(0, 20)
      );
    },
    [setAccessNameConfig]
  );

  const handleSubmitConfig = async (
    documentCustomer,
    id,
    versionConfigId,
    serviceId,
    payer
  ) => {
    try {
      setLoading(true);

      const fieldsToValidade = [
        { value: accessNameConfig, key: "accessNameConfig" },
        { value: emailConfig, key: "emailConfig" },
      ];

      if (versionConfigId !== 27)
        fieldsToValidade.push({ value: payer, key: "payer" });

      const valid = validNotNull(fieldsToValidade);

      if (!documentCustomer) {
        valid.error = true;
        notify("O Cliente selecionado não possui documento.");
      }

      if (!validEmail(emailConfig)) {
        valid.error = true;
        valid.errorsValidation.emailConfig = "E-mail inválido";
      }

      setErrors(valid.errorsValidation);
      if (valid.error) return;

      const body = {
        ClienteCNPJ: documentCustomer.replace(/[^0-9]+/g, ""),
        Rota: accessNameConfig,
        IdVersao: versionConfigId,
        IdServico: serviceId,
        Payer: payer,
      };

      const respMap = await postContract({
        body,
      });

      const mapErrors = respMap?.data?.errors;
      if (mapErrors?.length) {
        mapErrors.forEach((error) => notify(error));
        return;
      }

      if (respMap?.success) {
        notify("Acesso configurado com sucesso.", true, "success");
        setConfigAccessModalOpen(false);

        const copy = [...customersBpo];
        const index = copy.findIndex((item) => item.id === id);
        copy[index] = {
          ...copy[index],
          entity_systems: [
            {
              system_version_id: versionConfigId,
              configured: true,
            },
          ],
          configured: true,
        };

        setCustomersBpo(copy);
        return;
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleChangeTypeLimit = (e) => {
    setTypeLimit(e.target.value);
  };

  const handleSelectPayerOption = (payerOption) => {
    errors.payer = "";
    setPayer(payerOption);
  };

  const openCompanyProposal = async (cnpj) => {
    setLoading(true);
    const response = await search({
      path: `${process.env.REACT_APP_PANEL_API_URL}/v1/proposal/${cnpj}`,
      method: "GET",
    }).catch((e) => {
      setLoading(false);
      console.error(e);
      notify("Não foi possível abrir os termos da proposta");
    });
    setLoading(false);

    if (response) {
      const responseData = response?.data;
      if (response?.code === 200) {
        if (responseData?.propostas) {
          if (Array.isArray(responseData?.propostas)) {
            if (responseData?.propostas.length > 0) {
              //sort array responseData.propostas by data_proposta
              let sortedPropostas = responseData.propostas.sort((a, b) => {
                return new Date(b.data_venda) - new Date(a.data_venda);
              });

              //filter array by observacao includes 'BPO'
              let sortedWithBpoPropostas = sortedPropostas.filter((item) => {
                return item.observacao.includes("BPO");
              });

              let itemToOpen;

              if (sortedWithBpoPropostas.length > 0) {
                itemToOpen = sortedWithBpoPropostas[0];
              } else {
                itemToOpen = sortedPropostas[0];
              }

              window.open(
                `https://secretariadevendas.geiko.com.br/salesforce/Proposta/Print.asp?c=${
                  itemToOpen.codigo
                }&a=VIEW&k=${itemToOpen.codigo + 37}`,
                "_blank"
              );
            } else {
              notify("Não foi possível abrir os termos da proposta");
            }
          } else {
            window.open(
              `https://secretariadevendas.geiko.com.br/salesforce/Proposta/Print.asp?c=${
                responseData.propostas.codigo
              }&a=VIEW&k=${responseData.propostas.codigo + 37}`,
              "_blank"
            );
          }
        } else {
          notify("Não foi possível abrir os termos da proposta");
        }
      }
    }
  };

  return {
    //Functions
    clearContext,
    handleChangeAccessName,
    handleSubmitConfig,
    handleChangeTypeLimit,
    handleSelectPayerOption,
    openCompanyProposal,
  };
}
