import { GlobalVariables } from "../GlobalVariables";
import { notify } from "./notify";

export async function debuggerErrors(promise) {
  const response = await promise;

  if (
    response?.internalCode === GlobalVariables.INTERNAL_CODES.SessionExpired
  ) {
    notify("Sessão expirada.");

    window.location.href = `${
      process.env.REACT_APP_ENV_DEV ? "http" : "https"
    }://${window.location.host}/login?logout=true`;

    return;
  }

  if (
    response?.success ||
    [
      GlobalVariables.INTERNAL_CODES.ClientsBpoNotFound,
      GlobalVariables.INTERNAL_CODES.ClientsNotFound,
      GlobalVariables.INTERNAL_CODES.UserExistsInCognitoButNotInDatabase,
      GlobalVariables.INTERNAL_CODES.ThisUserDoesntHaveAnyPanel,
      GlobalVariables.INTERNAL_CODES.EntityNotFound,
      GlobalVariables.INTERNAL_CODES.CnpjDoesNotHaveErp,
      GlobalVariables.INTERNAL_CODES.EmailIsInvalid,
      GlobalVariables.INTERNAL_CODES.EntityHasntAnySystem,
      GlobalVariables.INTERNAL_CODES.EntityHasntAnyPanel,
      GlobalVariables.INTERNAL_CODES.UserTemporaryPasswordException,
      GlobalVariables.INTERNAL_CODES.AgendaRelatedToGroup,
      GlobalVariables.INTERNAL_CODES.Unauthorized,
      GlobalVariables.INTERNAL_CODES.RPA.InvalidToken,
    ].includes(response?.internalCode) ||
    [401].includes(response?.code)
  ) {
    return;
  }

  if (
    typeof response?.msg === "string" &&
    response?.msg &&
    response?.msg !==
      "NotAuthorizedException - Incorrect username or password." &&
    response.msg.toLowerCase() !== "error"
  ) {
    notify(response.msg);
  }

  if (typeof response?.data === "string") {
    notify(response.data);
  } else if (Array.isArray(response?.data)) {
    response.data.forEach((err) => {
      if (typeof err !== "string") return;

      notify(err);
    });
  } else if (response?.data !== null && typeof response?.data === "object") {
    Object.keys(response.data).forEach((key) => {
      if (typeof response.data[key] === "string")
        return notify(response.data[key]);
      else if (Array.isArray(response.data[key])) {
        return response.data[key].forEach((msgItem) => {
          if (typeof msgItem === "string") return notify(msgItem);
        });
      }
    });
  }
}
