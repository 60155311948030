import { callApi } from "./main";

const path =
  `${process.env.REACT_APP_CRM_API_URL}/v1` || "http://localhost:4000/v1";

export async function getUser({ id = null, roomId = 1 }) {
  return callApi({
    path: `${path}/user${id ? `/${id}` : ""}${
      roomId ? `?roomId=${roomId}` : ""
    }`,
    method: "GET",
  });
}
