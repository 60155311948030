import { Switch } from "react-router-dom";
import MiddlewareRoute from "../Middleware";
import { Versions } from "../../pages/settings/ReleaseVersions";

export default function AccessRelease() {
  return (
    <Switch>
      <MiddlewareRoute path="/version" sidebar component={Versions} />
    </Switch>
  );
}
