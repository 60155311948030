import { useContext, useState, useEffect } from "react";
import { useManagementController } from "./controllers/ManagementController";
import {
  ManagementContext,
  ManagementContextProvider,
} from "./contexts/ManagementContext";

import { GlobalVariables } from "../../GlobalVariables";

import Swal from "sweetalert2";

import { Typography, Box, Grid } from "@material-ui/core";
import Loading from "../../components/basics/Loading";
import { TabMainGroups } from "./children/TabMainGroups";
import { TabMainUsers } from "./children/TabMainUsers";
import { TabMenus } from "./children/TabMenus";
import { TabCards } from "./children/TabCards";
import { ModalCreateGroup } from "./children/ModalCreateGroup";

import {
  Title,
  FloatingSaving,
  CircularSaving,
  GridStyled,
} from "../../styles/GlobalStyle";
import {
  TabStyled,
  TabsStyled,
  TabPanelContainer,
  MainTabsStyled,
} from "./styles/management";

const View = () => {
  const {
    getEntityGroups,
    getPermissionTree,
    getEntityUsers,
    handleChangeUser,
  } = useManagementController();

  const {
    loading,
    saving,
    openModalCreateGroup,
    groupOptions,
    userOptions,
    permissionTree,
    edited,
    setEdited,
  } = useContext(ManagementContext);

  const [mainTabIndex, setMainTabIndex] = useState(0);
  const [verticalTabIndex, setVerticalTabIndex] = useState(0);

  const mainTabsHeaders = ["Grupos", "Usuários"];
  const mainTabsBody = [<TabMainGroups />, <TabMainUsers />];
  const verticalTabsHeaders = ["Menus", "Cards"];
  const verticalTabsBody = [
    <TabMenus
      menus={(permissionTree?.children_recursive || []).find(
        (permission) => permission.id === GlobalVariables.PERMISSIONS.MENUS
      )}
      mainTabIndex={mainTabIndex}
    />,
    <TabCards
      cards={(permissionTree?.children_recursive || []).find(
        (permission) => permission.id === GlobalVariables.PERMISSIONS.CARDS
      )}
      mainTabIndex={mainTabIndex}
    />,
  ];

  useEffect(() => {
    if (!permissionTree) getPermissionTree();
  }, [permissionTree, getPermissionTree]);

  useEffect(() => {
    if (!groupOptions) getEntityGroups();
  }, [groupOptions, getEntityGroups]);

  useEffect(() => {
    if (!userOptions) getEntityUsers();
  }, [userOptions, getEntityUsers]);

  const handleChangeMainTab = (event, newValue) => {
    if (edited) {
      Swal.fire({
        icon: "question",
        title:
          "Você tem alterações que não foram salvas. Deseja sair sem salvar?",
        allowOutsideClick: false,
        allowEscapeKey: false,
        showConfirmButton: true,
        confirmButtonText: "Sim",
        showDenyButton: true,
        denyButtonText: `Não`,
      }).then((result) => {
        if (result.isConfirmed) {
          handleChangeUser(null);
          setEdited(false);
          setMainTabIndex(newValue);
        }
      });
    } else {
      handleChangeUser(null);
      setMainTabIndex(newValue);
    }
  };

  const handleChangeVerticalTab = (event, newValue) => {
    setVerticalTabIndex(newValue);
  };

  return (
    <>
      <Grid container direction="column" style={{ paddingRight: 30 }}>
        <Grid item flex={1}>
          <Title color="blue">Perfil de Acesso - Gerenciamento</Title>
        </Grid>

        {/* Main Tabs de grupo ou user */}
        <GridStyled item flex={1}>
          <MainTabsStyled
            value={mainTabIndex}
            onChange={handleChangeMainTab}
            variant="fullWidth"
            centered
          >
            {mainTabsHeaders.map((header) => (
              <TabStyled label={header} />
            ))}
          </MainTabsStyled>
        </GridStyled>
        <GridStyled item flex={1}>
          {mainTabsBody.map((mainTabBody, index) => (
            <TabPanelContainer
              role="main-tabpanel"
              hidden={mainTabIndex !== index}
              id={`main-tabpanel-${index}`}
              aria-labelledby={`main-tab-${index}`}
            >
              {mainTabIndex === index && (
                <Box>
                  <Typography>{mainTabBody}</Typography>
                </Box>
              )}
            </TabPanelContainer>
          ))}
        </GridStyled>

        {/* Tabs verticais de permissões */}
        <GridStyled item margin-top="16">
          <GridStyled container spacing={2}>
            <GridStyled item xs={2}>
              <TabsStyled
                item
                xs={2}
                orientation="vertical"
                value={verticalTabIndex}
                onChange={handleChangeVerticalTab}
                aria-label="AccessProfileTabs"
              >
                {verticalTabsHeaders.map((header) => (
                  <TabStyled label={header} />
                ))}
              </TabsStyled>
            </GridStyled>
            <GridStyled item xs={10}>
              {verticalTabsBody.map((verticalTabBody, index) => (
                <TabPanelContainer
                  role="vertical-tabpanel"
                  hidden={verticalTabIndex !== index}
                  id={`vertical-tabpanel-${index}`}
                  aria-labelledby={`vertical-tab-${index}`}
                >
                  {verticalTabIndex === index && (
                    <Box>
                      <Typography>{verticalTabBody}</Typography>
                    </Box>
                  )}
                </TabPanelContainer>
              ))}
            </GridStyled>
          </GridStyled>
        </GridStyled>
      </Grid>

      {openModalCreateGroup && <ModalCreateGroup />}

      <Loading open={Object.keys(loading).length > 0} />
      {saving && (
        <FloatingSaving>
          <CircularSaving /> Salvando ...
        </FloatingSaving>
      )}
    </>
  );
};

const management = ({ ...props }) => {
  return (
    <ManagementContextProvider>
      <View {...props} />
    </ManagementContextProvider>
  );
};

export { management };
