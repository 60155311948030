import { Grid } from "@material-ui/core";
import React from "react";
import "./style/TextFieldCustom.css";

export default function TextFieldCustom({
  xs = 12,
  placeholder,
  label,
  width,
  value,
  id,
  setValue,
}) {
  const handleChange = (e) => {
    return setValue(e.target.value);
  };
  return (
    <Grid xs={xs}>
      <label for={id}>{label}</label>
      <textarea
        onChange={handleChange}
        id={id}
        style={{ width: width ? `${width}%` : "100%" }}
        className="scrolltextarea"
        placeholder={placeholder}
        rows={3}
        value={value}
      >
        {value}
      </textarea>
    </Grid>
  );
}
