import axios from "axios";

function startEnvironment() {
  require("dotenv").config({
    path: process.env.REACT_APP_CRM_API_URL,
  });
}

startEnvironment();

export const api = axios.create({
  baseURL: process.env.REACT_APP_CRM_API_URL,
});

// api.interceptors.request.use(
//   async (config) => {
//     config.headers = {
//       "Access-Control-Allow-Origin": "*",
//       "Content-Type": "application/json",
//       "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
//     };

//     return config;
//   },
//   (error) => Promise.reject(error)
// );
