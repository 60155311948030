import React, { createContext, useState } from "react";

const SettingContext = createContext();

const SettingContextProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);

  return (
    <SettingContext.Provider
      value={{
        loading,
        setLoading,
      }}
    >
      {children}
    </SettingContext.Provider>
  );
};

export { SettingContextProvider, SettingContext };
