import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@material-ui/core";
import { useContext, useEffect } from "react";
import Loading from "../../../components/basics/Loading";
import TextFieldErp from "../../../components/basics/TextFieldErp";
import Btn from "../../../components/buttons/Btn";
import { LabelStyled, Title } from "../../../styles/GlobalStyle";
import { ModalCurrentUserContext } from "../contexts/ModalCurrentUserContext";
import useCurrentUserController from "../controller/CurrentUserController";

export default function ModalCurrentUser() {
  const {
    currentUsersCostumer,
    currentUserModalOpen,
    currentUserModalIndex,
    firstName,
    setFirstName,
    lastName,
    setLastName,
    loading,
    errors,
  } = useContext(ModalCurrentUserContext);
  const { handleSaveUser, handleCloseUser } = useCurrentUserController();

  useEffect(() => {
    if (
      currentUserModalIndex === -1 ||
      !currentUsersCostumer[currentUserModalIndex]
    ) {
      return;
    }

    setFirstName(currentUsersCostumer[currentUserModalIndex].first_name);
    setLastName(currentUsersCostumer[currentUserModalIndex].last_name);
  }, [currentUsersCostumer, currentUserModalIndex]);

  return (
    <>
      <Dialog
        open={currentUserModalOpen}
        maxWidth="sm"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        onClose={loading ? () => {} : handleCloseUser}
      >
        <DialogTitle id="alert-dialog-title">
          <Title subtitle>Edição de Perfil</Title>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item sm={6} xs={12}>
              <TextFieldErp
                error={errors.firstName ? true : false}
                helperText={errors.firstName || ""}
                value={firstName}
                setValue={setFirstName}
                label="Primeiro Nome*"
                maxLength={50}
                disabled={currentUsersCostumer?.[currentUserModalIndex]?.id}
              />
            </Grid>

            <Grid item sm={6} xs={12}>
              <TextFieldErp
                error={errors.lastName ? true : false}
                helperText={errors.lastName || ""}
                value={lastName}
                setValue={setLastName}
                label="Último Nome*"
                maxLength={50}
                disabled={currentUsersCostumer?.[currentUserModalIndex]?.id}
              />
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          {!loading && (
            <Btn
              label="Cancelar"
              bg-color="tertiary"
              onClick={handleCloseUser}
            />
          )}

          <Btn
            label={
              loading ? (
                <>
                  <LabelStyled margin="0px 10px 0px 0">Salvando</LabelStyled>
                  <CircularProgress style={{ width: 25, height: 25 }} />
                </>
              ) : (
                "Salvar"
              )
            }
            onClick={loading ? () => {} : () => handleSaveUser({})}
            bg-color={loading ? "tertiary" : "blue"}
          />
        </DialogActions>
      </Dialog>
    </>
  );
}
