import styled from "styled-components";

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  width: 100%;
  margin: 3px 0px;
`;

export const Label = styled.p`
  color: #424242;
  display: block;
  /* Caption/normal */
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 133.333% */
  letter-spacing: 0.15px;
  margin: 0;
`;

export const LabelProgess = styled.p`
  color: #22b07d;

  /* Subtitle/small */
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 171.429% */
  letter-spacing: 0.15px;
`;
