export const Filter = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
    >
      <path
        d="M6.85346 2.55664C7.37721 2.55664 7.80179 2.98122 7.80179 3.50497L7.80179 10.0584C7.80179 10.5822 7.37721 11.0067 6.85346 11.0067C6.32972 11.0067 5.90514 10.5822 5.90514 10.0584L5.90514 3.50497C5.90514 2.98122 6.32972 2.55664 6.85346 2.55664Z"
        fill="#616161"
      />
      <path
        d="M19.9112 3.50497C19.9112 2.98122 19.4866 2.55664 18.9628 2.55664C18.4391 2.55664 18.0145 2.98122 18.0145 3.50497L18.0145 10.0584C18.0145 10.5822 18.4391 11.0067 18.9628 11.0067C19.4866 11.0067 19.9112 10.5822 19.9112 10.0584L19.9112 3.50497Z"
        fill="#616161"
      />
      <path
        d="M11.7635 21.6315C11.7635 22.1552 12.1881 22.5798 12.7119 22.5798C13.2356 22.5798 13.6602 22.1552 13.6602 21.6315V16.0781C13.6602 15.5544 13.2356 15.1298 12.7119 15.1298C12.1881 15.1298 11.7635 15.5544 11.7635 16.0781V21.6315Z"
        fill="#616161"
      />
      <path
        d="M12.7114 2.55664C13.2351 2.55664 13.6597 2.98122 13.6597 3.50497V10.7481H13.6762C14.3746 10.7481 14.9407 11.3142 14.9407 12.0126C14.9407 12.7109 14.3746 13.277 13.6762 13.277H11.7796C11.0812 13.277 10.5151 12.7109 10.5151 12.0126C10.5151 11.3197 11.0723 10.7571 11.763 10.7482V3.50497C11.763 2.98122 12.1876 2.55664 12.7114 2.55664Z"
        fill="#616161"
      />
      <path
        d="M18.015 21.6316L18.015 14.5415H17.9422C17.2438 14.5415 16.6777 13.9754 16.6777 13.277C16.6777 12.5787 17.2438 12.0126 17.9422 12.0126H19.8388C20.5371 12.0126 21.1033 12.5787 21.1033 13.277C21.1033 13.9509 20.5761 14.5017 19.9117 14.5394L19.9117 21.6316C19.9117 22.1553 19.4871 22.5799 18.9633 22.5799C18.4396 22.5799 18.015 22.1553 18.015 21.6316Z"
        fill="#616161"
      />
      <path
        d="M5.90538 14.5409C5.22407 14.5215 4.67773 13.963 4.67773 13.277C4.67773 12.5787 5.24384 12.0126 5.94217 12.0126H7.83882C8.53715 12.0126 9.10326 12.5787 9.10326 13.277C9.10326 13.9754 8.53715 14.5415 7.83882 14.5415H7.80203L7.80203 21.6316C7.80203 22.1553 7.37745 22.5799 6.8537 22.5799C6.32996 22.5799 5.90538 22.1553 5.90538 21.6316L5.90538 14.5409Z"
        fill="#616161"
      />
    </svg>
  );
};
