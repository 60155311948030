import {
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Modal,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import React from "react";
import { dialogTitleClose } from "../../../components/documentModal/styles/DocumentModal";
import { ChipLegend } from "../styles/cssProducts";
import { DialogHeader, DialogStyle } from "../../../components/modal/Modal";

export const DialoginfoDataGrid = (props) => {
  return (
    <>
      <Modal
        open={props.openModal}
        onClose={() => {
          props.closeModal();
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <DialogStyle>
          <DialogHeader style={{ backgroundColor: "#1976d2" }}>
            <div className="row">
              <div style={{ display: "flex", flexDirection: "column" }}>
                Informações
              </div>
              <CloseIcon
                style={dialogTitleClose}
                onClick={() => {
                  props.closeModal();
                }}
              />
            </div>
          </DialogHeader>

          <List style={{ marginBottom: "10px" }}>
            <ListItem>
              <ListItemAvatar>
                <ChipLegend
                  label="Sim"
                  style={{
                    backgroundColor: "#21ba45",
                    color: "white",
                  }}
                  variant="outlined"
                />
              </ListItemAvatar>
              <ListItemText primary="Habilitado para uso" />
            </ListItem>

            <ListItem>
              <ListItemAvatar>
                <ChipLegend
                  label="Não"
                  style={{
                    backgroundColor: "#ff0000",
                    color: "white",
                  }}
                  variant="outlined"
                />
              </ListItemAvatar>
              <ListItemText primary="Não habilitado" />
            </ListItem>

            <ListItem>
              <ListItemAvatar>
                <ChipLegend
                  label="DEV"
                  style={{
                    backgroundColor: "#31ccec",
                    color: "white",
                  }}
                  variant="outlined"
                />
              </ListItemAvatar>
              <ListItemText primary="Prefeitura está em desenvolvimento" />
            </ListItem>

            <ListItem>
              <ListItemAvatar>
                <ChipLegend
                  label="Parcial"
                  style={{
                    backgroundColor: "#ffa500",
                    color: "white",
                  }}
                  variant="outlined"
                />
              </ListItemAvatar>
              <ListItemText primary="Irá entrar no sistema, mas está pendente de verificação manual dos nossos analistas" />
            </ListItem>
          </List>
        </DialogStyle>
      </Modal>
    </>
  );
};
