import React, { createContext, useState } from "react";

const ModalRequestVisitContext = createContext();

const ModalRequestVisitContextProvider = ({ children }) => {
  const [name, setName] = useState("");
  const [documentCustomer, setDocumentCustomer] = useState("");
  const [email, setEmail] = useState("");
  const [contactName, setContactName] = useState("");
  const [contactPhone, setContactPhone] = useState("");
  const [acceptTerms, setAcceptTerms] = useState(true);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [modalVisitOpen, setModalVisitOpen] = useState(false);

  return (
    <ModalRequestVisitContext.Provider
      value={{
        name,
        setName,
        documentCustomer,
        setDocumentCustomer,
        email,
        setEmail,
        contactName,
        setContactName,
        contactPhone,
        setContactPhone,
        acceptTerms,
        setAcceptTerms,
        errors,
        setErrors,
        loading,
        setLoading,
        modalVisitOpen,
        setModalVisitOpen,
      }}
    >
      {children}
    </ModalRequestVisitContext.Provider>
  );
};

export { ModalRequestVisitContextProvider, ModalRequestVisitContext };
