import { List } from "@material-ui/core";
import { GridStyled } from "../../../styles/GlobalStyle";

const RenderList = ({ children, title }) => {
  return (
    <GridStyled width="100%">
      <List component="nav" aria-labelledby="nested-list-subheader">
        {children}
      </List>
    </GridStyled>
  );
};

export default RenderList;
