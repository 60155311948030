import React from "react";

import LinkIcon from "@material-ui/icons/Link";
import { Checkbox, Grid, Tooltip } from "@material-ui/core";

import { v4 as uuidv4 } from "uuid";
import filesize from "filesize";
import Swal from "sweetalert2";
import Dropzone from "react-dropzone";

import {
  ContainerIndex,
  Content,
  DropContainer,
  UploadMessage,
  Container,
  FileInfo,
  Preview,
} from "./styles/UploadFiles";

export default function UploadFiles({
  uploadedFiles = [],
  setUploadedFiles = () => {},
  accept = "image/*",
  label = "Arraste arquivos aqui...",
  multiple = false,
  main,
}) {
  const handleUpload = (files) => {
    if (!multiple) {
      const file = files[0];

      setUploadedFiles([
        {
          file,
          id: uuidv4(),
          name: file.name,
          readableSize: filesize(file.size),
          preview: URL.createObjectURL(file),
          uploaded: true,
          error: false,
        },
      ]);
      return;
    }

    const uploadeds = files.map((file) => ({
      file,
      id: uuidv4(),
      name: file.name,
      readableSize: filesize(file.size),
      preview: URL.createObjectURL(file),
      uploaded: true,
      error: false,
    }));

    setUploadedFiles(uploadedFiles.concat(uploadeds));
  };

  const handleDelete = (id) => {
    Swal.fire({
      icon: "question",
      text: "Tem certeza que deseja excluir?",
      cancelButtonText: "Cancelar",
      confirmButtonText: "Sim",
      showConfirmButton: true,
      showCancelButton: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        setUploadedFiles(uploadedFiles.filter((file) => file.id !== id));
      }
    });
  };

  const handleCheckMain = (id) => {
    const newArr = uploadedFiles.map((item) => {
      if (item.id === id) {
        return { ...item, checkMain: item.checkMain ? false : true };
      }

      return { ...item, checkMain: false };
    });

    setUploadedFiles(newArr);
  };

  return (
    <ContainerIndex>
      <Content>
        <Grid container>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Dropzone accept={accept} onDropAccepted={handleUpload}>
              {({
                getRootProps,
                getInputProps,
                isDragActive,
                isDragReject,
              }) => (
                <DropContainer
                  {...getRootProps()}
                  isDragActive={isDragActive}
                  isDragReject={isDragReject}
                >
                  <input {...getInputProps()} multiple={multiple} />
                  <UploadMessage>
                    {isDragActive && !isDragReject
                      ? "Solte os arquivos aqui"
                      : isDragReject
                      ? "Arquivo não suportado"
                      : label}
                  </UploadMessage>
                </DropContainer>
              )}
            </Dropzone>
          </Grid>
          {!!uploadedFiles.length && (
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <Container>
                {uploadedFiles.map((uploadedFile) => (
                  <li key={uploadedFile.id}>
                    <FileInfo>
                      <Preview src={uploadedFile.preview} />
                      <div>
                        <strong>{uploadedFile.name}</strong>
                        <span>
                          {uploadedFile.readableSize}

                          <button onClick={() => handleDelete(uploadedFile.id)}>
                            Excluir
                          </button>
                        </span>
                      </div>
                    </FileInfo>

                    <Grid
                      container
                      xs
                      alignItems="center"
                      justifyContent="flex-end"
                    >
                      {uploadedFile.preview && (
                        <Grid item flex={1}>
                          <Tooltip title="Abrir arquivo">
                            <a
                              href={uploadedFile.preview}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <LinkIcon
                                style={{ marginRight: 8, color: "black" }}
                                size={24}
                              />
                            </a>
                          </Tooltip>
                        </Grid>
                      )}

                      {main && (
                        <Grid item flex={1}>
                          <Tooltip title="Marcar Principal">
                            <Checkbox
                              checked={uploadedFile.checkMain || false}
                              onChange={() => handleCheckMain(uploadedFile.id)}
                              defaultChecked
                              size="small"
                              inputProps={{
                                "aria-label": "checkbox with small size",
                              }}
                            />
                          </Tooltip>
                        </Grid>
                      )}
                    </Grid>
                  </li>
                ))}
              </Container>
            </Grid>
          )}
        </Grid>
      </Content>
    </ContainerIndex>
  );
}
