import { callApi } from "./main";

const path =
  `${process.env.REACT_APP_CRM_API_URL}/v1` || "http://localhost:4000/v1";

export async function getCustomers({ id }) {
  return callApi({
    path: `${path}/customer${id ? `/${id}` : ""}`,
    method: "GET",
  });
}

export async function getCustomerContact({ id, customerId }) {
  return callApi({
    path: `${path}/customer/contact/${id ? id : ""}?customerId=${
      customerId ? customerId : ""
    }`,
    method: "GET",
  });
}
