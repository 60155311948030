import { Button } from "@material-ui/core";
import styled from "styled-components";

const white = (props) => props.theme.palette.white;

// Adapting based on props
export const PropsBox = styled.div((props) => ({
  height: "45px",
  width: "8px",
  background: props.theme.palette.blue,
}));

export const PropsBoxLogin = styled.div((props) => ({
  height: "40px",
  width: "4px",
  background: props.theme.palette.blue,
}));

export const Box = styled.div({
  background: "transparent",
  height: "25px",
  width: "8px",
});

export const ButtonCard = styled(Button)`
  margin: 5px !important;
  padding: 5px !important;
  background-color: ${white};
  height: 70px;
  width: 97%;
  font-size: 12px;
  margin-top: 40px;
`;

export const Label = styled.div((props) => ({
  fontSize: "18px",
  color: props.theme.palette.blue,
  textTransform: "none",
  fontWeight: "400",
  textAlign: "left",
}));

export const Icon = styled.div((props) => ({
  color: "#000",
  fontSize: "20px",
}));
