import React, { createContext, useState } from "react";
import { RpaStatus } from "../../../utils/RpaStatus";
const CndAccountantContext = createContext();

const CndAccountantContextProvider = ({ children }) => {
  // Page CND
  const [loading, setLoading] = useState({});
  const [saving, setSaving] = useState(false);
  const [tabIndex, setTabIndex] = useState(1);
  const [cndToUpload, setCndToUpload] = useState({});
  const [clientCndToUpload, setClientCndToUpload] = useState(null);

  // Configurações CND
  const [sendMailPositiveFlag, setSendMailPositiveFlag] = useState(true);
  const [sendMailNegativeFlag, setSendMailNegativeFlag] = useState(true);
  const [sendMailPositiveNegativeFlag, setSendMailPositiveNegativeFlag] = useState(true);

  const [clientAllowedPositiveFlag, setClientAllowedPositiveFlag] =
    useState(true);
  const [clientAllowedNegativeFlag, setClientAllowedNegativeFlag] =
    useState(true);
  const [clientAllowedPositiveNegativeFlag, setClientAllowedPositiveNegativeFlag] = useState(true);
  const [clientAllowedOthersFlag, setClientAllowedOthersFlag] = useState(true);

  const [cndTypes, setCndTypes] = useState([]);
  const [activeCnds, setActiveCnds] = useState([]);
  const [cndsFederal, setCndsFederal] = useState([]);
  const [cndsMunicipal, setCndsMunicipal] = useState([]);
  const [cndsEstadual, setCndsEstadual] = useState([]);
  const [others, setCndsOthers] = useState([]);
  const [lastProcessing, setLastProcessing] = useState([]);

  const [errors, setErrors] = useState({});
  const [fromDate, setFromDate] = useState("2022-05-03");
  const [expandedGroup, setExpandedGroup] = useState(true);
  const [cndToGroup, setCndToGroup] = useState(null);
  const [openModalAddGroup, setOpenModalAddGroup] = useState(false);
  const [newGroupName, setNewGroupName] = useState("");

  const [editedAgendasIds, setEditedAgendasIds] = useState([]);

  // Tab Cadastro
  const [allClients, setAllclients] = useState([]);
  const [groupOptionsJson, setGroupOptionsJson] = useState("[]");
  const [editedGroupOptions, setEditedGroupOptions] = useState([]);
  const [group, setGroup] = useState(null);

  const [cnd, setCnd] = useState(null);
  const [agendas, setAgendas] = useState([]);
  const [cndOptions, setCndOptions] = useState([]);
  const [notRegisteredClientes, setNotRegisteredClientes] = useState([]);
  const [registeredClientes, setRegisteredClientes] = useState([]);
  const [registeredClientesEdited, setRegisteredClientesEdited] =
    useState(false);

  // Tab Controle
  const [requestedCnd, setRequestedCnd] = useState([]);

  const [manualUploadedCnd, setManualUploadedCnd] = useState([]);
  const [manualIssueDate, setManualIssueDate] = useState("");
  const [manualDueDate, setManualDueDate] = useState("");
  const [manualStatus, setManualStatus] = useState(null);
  const [statusManualOptions] = useState([
    {
      value: RpaStatus.NEGATIVA.id,
      description: RpaStatus.NEGATIVA.description,
    },
    {
      value: RpaStatus.POSITIVA.id,
      description: RpaStatus.POSITIVA.description,
    },
    {
      value: RpaStatus.NEGATIVA_POSITIVA.id,
      description: RpaStatus.NEGATIVA_POSITIVA.description,
    },
  ]);

  return (
    <CndAccountantContext.Provider
      value={{
        loading,
        setLoading,
        saving,
        setSaving,
        tabIndex,
        setTabIndex,
        cndToUpload,
        setCndToUpload,
        clientCndToUpload,
        setClientCndToUpload,

        sendMailPositiveFlag,
        setSendMailPositiveFlag,
        sendMailNegativeFlag,
        setSendMailNegativeFlag,
        sendMailPositiveNegativeFlag,
        setSendMailPositiveNegativeFlag,

        clientAllowedPositiveFlag,
        setClientAllowedPositiveFlag,
        clientAllowedNegativeFlag,
        setClientAllowedNegativeFlag,
        clientAllowedPositiveNegativeFlag,
        setClientAllowedPositiveNegativeFlag,
        clientAllowedOthersFlag,
        setClientAllowedOthersFlag,

        errors,
        setErrors,
        fromDate,
        setFromDate,
        group,
        setGroup,
        cnd,
        setCnd,
        expandedGroup,
        setExpandedGroup,
        cndToGroup,
        setCndToGroup,
        openModalAddGroup,
        setOpenModalAddGroup,
        newGroupName,
        setNewGroupName,

        allClients,
        setAllclients,
        groupOptionsJson,
        setGroupOptionsJson,
        editedGroupOptions,
        setEditedGroupOptions,
        agendas,
        setAgendas,
        cndOptions,
        setCndOptions,
        registeredClientes,
        setRegisteredClientes,
        notRegisteredClientes,
        setNotRegisteredClientes,
        registeredClientesEdited,
        setRegisteredClientesEdited,

        requestedCnd,
        setRequestedCnd,

        manualUploadedCnd,
        setManualUploadedCnd,
        manualIssueDate,
        setManualIssueDate,
        manualDueDate,
        setManualDueDate,
        manualStatus,
        setManualStatus,
        statusManualOptions,
        cndTypes,
        setCndTypes,
        activeCnds,
        setActiveCnds,
        cndsFederal,
        setCndsFederal,
        cndsMunicipal,
        setCndsMunicipal,
        cndsEstadual,
        setCndsEstadual,
        others,
        setCndsOthers,
        editedAgendasIds,
        setEditedAgendasIds,
        lastProcessing,
        setLastProcessing,
      }}
    >
      {children}
    </CndAccountantContext.Provider>
  );
};

export { CndAccountantContextProvider, CndAccountantContext };
