import * as React from "react";

function PreviewIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={25}
      fill="none"
      viewBox="0 0 24 25"
      {...props}
    >
      <path
        d="M4.29 8.273c1.875-1.553 4.6-3.23 7.71-3.23 3.11 0 5.835 1.677 7.71 3.23a21.64 21.64 0 012.863 2.882 11.828 11.828 0 01.21.265l.012.017.004.005.002.002-.801.599c.802.598.801.599.801.599l-.002.002-.004.005-.012.017-.045.058a18.76 18.76 0 01-.78.925 21.633 21.633 0 01-2.249 2.164c-1.874 1.553-4.6 3.23-7.709 3.23-3.11 0-5.835-1.677-7.71-3.23a21.641 21.641 0 01-2.863-2.882 12.481 12.481 0 01-.21-.265l-.012-.017-.004-.005-.002-.002.801-.599-.801-.598.002-.003.004-.005.012-.017a7.01 7.01 0 01.21-.265A21.649 21.649 0 014.29 8.273zM2 12.043l-.801-.598a1 1 0 000 1.196L2 12.043zm1.292 0a19.64 19.64 0 002.275 2.23c1.745 1.447 4.02 2.77 6.433 2.77 2.413 0 4.688-1.323 6.433-2.77a19.645 19.645 0 002.275-2.23 19.46 19.46 0 00-2.275-2.23c-1.745-1.447-4.02-2.77-6.433-2.77-2.413 0-4.688 1.323-6.433 2.77a19.647 19.647 0 00-2.275 2.23zm18.708 0l.801.599a1 1 0 000-1.197l-.801.598zm-13.5 0a3.5 3.5 0 117 0 3.5 3.5 0 01-7 0zm3.5-1.5a1.5 1.5 0 100 3 1.5 1.5 0 000-3z"
        clipRule="evenodd"
      />
    </svg>
  );
}

export default PreviewIcon;
