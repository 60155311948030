import {
  AccordionDetails,
  AccordionSummary,
  Grid,
  IconButton,
  Tooltip,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import { useContext, useEffect, useState } from "react";
import Loading from "../../../components/basics/Loading";
import SelectErp from "../../../components/basics/SelectErp";
import TextFieldErp from "../../../components/basics/TextFieldErp";
import { GlobalContext } from "../../../contexts/GlobalContext";
import {
  getPermission,
  getUser,
} from "../../../repositories/panel/v1/EntityRepository";
import {
  AccordionStyled,
  DataGridDefault,
  GridStyled,
  IconButtonStyled,
  Title,
} from "../../../styles/GlobalStyle";
import { notify } from "../../../utils/notify";
import { usePortalAsClient } from "../../../utils/viewPortalAsClientHook";
import { UserContext } from "../contexts/UserContext";
import useUserController from "../controllers/UserController";
import ModaluUser from "./ModalUser";

export default function AccordionUser({ openDefault }) {
  const [dataTable, setDataTable] = useState([]);
  const {
    usersCostumer,
    setUsersCostumer,
    userModalOpen,
    setUserModalOpen,
    setUserModalIndex,
    profilesFilter,
    setProfilesFilter,
    listProfiles,
    setListProfiles,

    loading,
    setLoading,
    errors,
    expanded,
  } = useContext(UserContext);
  const { handleDeleteUser, handleChangeOpenAcordion } = useUserController();

  const { companyUser } = useContext(GlobalContext);

  const { isInClientView, clientViewed } = usePortalAsClient();

  useEffect(() => {
    if (openDefault) {
      handleChangeOpenAcordion("users");
    }
  }, []);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);

        const respUsers = await getUser(
          isInClientView ? clientViewed?.company : companyUser.companyId
        );

        if (!respUsers?.success) {
          return;
        }

        setUsersCostumer(respUsers.data);
      } catch (error) {
        console.error(error);

        notify("Não foi possível obter os dados.");
      } finally {
        setLoading(false);
      }
    })();
  }, [
    clientViewed?.company,
    companyUser.companyId,
    isInClientView,
    setLoading,
    setUsersCostumer,
  ]);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);

        const respPermission = await getPermission(
          isInClientView ? clientViewed?.company : companyUser.companyId
        );

        if (!respPermission?.success) {
          return;
        }

        setListProfiles(respPermission.data);
      } catch (error) {
        console.error(error);

        notify("Não foi possível obter os dados.");
      } finally {
        setLoading(false);
      }
    })();
  }, [companyUser.companyId, setListProfiles, setLoading]);

  const renderCellActions = (props) => {
    return (
      <Grid container justifyContent="center" spacing={1}>
        <Grid item flex={1}>
          <Tooltip title="Editar">
            <IconButton
              onClick={() => {
                setUserModalIndex(props.value);
                setUserModalOpen(true);
              }}
            >
              <EditOutlinedIcon />
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid item flex={1}>
          <Tooltip title="Deletar">
            <IconButton
              onClick={() => {
                handleDeleteUser(props.id);
              }}
            >
              <DeleteOutlineOutlinedIcon />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
    );
  };

  const columns = [
    {
      field: "acoes",
      headerName: "Ações",
      width: 125,
      renderCell: renderCellActions,
      renderHeader: () => (
        <Grid container justifyContent="center">
          <div className="MuiDataGrid-columnHeaderTitle">Ações</div>
        </Grid>
      ),
    },
    { field: "first_name", headerName: "Primeiro Nome", flex: 1 },
    { field: "last_name", headerName: "Último Nome", flex: 1 },
    { field: "email", headerName: "E-mail", flex: 1 },
  ];

  useEffect(() => {
    const newArr = usersCostumer.map((item, index) => ({
      ...item,
      idTable: item.idTable || item.id,
      acoes: index,
    }));

    setDataTable(newArr);
  }, [usersCostumer]);

  return (
    <>
      <Loading open={loading} />
      {userModalOpen && <ModaluUser />}
      <AccordionStyled expanded={expanded.users || openDefault ? true : false}>
        <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
          <Grid container spacing={1} alignItems="center">
            <GridStyled
              item
              xs
              height="100%"
              onClick={() => handleChangeOpenAcordion("users")}
              container
              alignItems="center"
            >
              <Title subtitle cursor="pointer">
                Usuários Plataforma
              </Title>
            </GridStyled>
            <Grid item flex={1}>
              <Tooltip title="Adicionar Endereço">
                <IconButtonStyled
                  bg-color="gray"
                  onClick={() => setUserModalOpen(true)}
                >
                  <AddIcon />
                </IconButtonStyled>
              </Tooltip>
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2}>
            <Grid item lg={6} md={5} sm={6} xs={8}>
              <TextFieldErp variant="filled" label="Pesquisar" />
            </Grid>

            <Grid item lg={4} md={5} sm={6} xs={8}>
              <SelectErp
                variant="filled"
                multiple
                error={errors.profilesFilter ? true : false}
                helperText={errors.profilesFilter || ""}
                value={profilesFilter}
                setValue={setProfilesFilter}
                label="Permissões"
                maxLength={50}
                optionsList={listProfiles}
                descriptionField="name"
              />
            </Grid>

            <Grid item xs={12}>
              <DataGridDefault
                autoHeight
                disableColumnMenu
                loading={false}
                rows={dataTable}
                columns={columns}
                pageSize={5}
                disableSelectionOnClick
                getRowId={(row) => row.idTable}
                scrollbarSize={13}
              />
            </Grid>
          </Grid>
        </AccordionDetails>
      </AccordionStyled>
    </>
  );
}
