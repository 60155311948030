const Colors = {
  primary: "#0c2d6a",
  black: "#4b4b4b",
  white: "white",
  backgroundTela: "#e5e5e5",
  backgroundDragDropItem: "#f7f7f7",
  blue: "#2887f2",
  lightBlue: "#e3f0ff",
  yellow: "#f7b400",
  orange: "orange",
  red: "#f22838",
  green: "#43cc53",
};

export default Colors;
