import {
  Accordion,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  OutlinedInput,
  Tooltip,
  withStyles,
} from "@material-ui/core";
import { DataGrid } from "@material-ui/data-grid";
import styled, { css } from "styled-components";

const white = (props) => props.theme.palette.white;
const gray = (props) => props.theme.palette.gray;
const grayLight = (props) => props.theme.palette.light.gray;
const blue = (props) => props.theme.palette.blue;

const roboto = (props) => props.theme.fonts.roboto;

export const ContainerIcon = styled.div`
  ${(props) =>
    props.containerHeight &&
    css`
      height: ${(props) => props.containerHeight}px;
    `};
  ${(props) =>
    props.marginLeft &&
    css`
      margin-left: ${(props) => props.marginLeft};
    `};
  ${(props) =>
    props.display &&
    css`
      display: ${(props) => props.display};
    `};
  ${(props) =>
    props.alignSelf &&
    css`
      align-self: ${(props) => props.alignSelf};
    `};
  .MuiSvgIcon-root {
    color: ${(props) => props.theme.palette[props.iconColor || "black"]};
    ${(props) =>
      props.border &&
      css`
        border: ${(props) => props.border};
      `};

    ${(props) =>
      props["border-radius"] &&
      css`
        border-radius: ${(props) => props["border-radius"]}px;
      `};

    ${(props) =>
      props.fontSize &&
      css`
        font-size: ${(props) => props.fontSize}px;
      `};

    cursor: ${(props) => props.cursor || "pointer"};

    ${(props) =>
      props.center &&
      css`
        display: flex;
        justifycontent: center;
      `}

    :hover {
      color: ${(props) =>
        props.theme.palette[
          props.iconHoverColor || props.iconColor || "black"
        ]};
    }
  }
`;

export const Padding = styled.div`
  ${(props) =>
    props.padding &&
    css`
      padding: ${(props) => props.padding.replace(/( )+/g, "px ")}px;
    `};

  ${(props) =>
    props.top &&
    css`
      padding-top: ${(props) => props.top}px;
    `};

  ${(props) =>
    props.left &&
    css`
      padding-left: ${(props) => props.left}px;
    `};

  ${(props) =>
    props.bottom &&
    css`
      padding-bottom: ${(props) => props.bottom}px;
    `};

  ${(props) =>
    props.right &&
    css`
      padding-right: ${(props) => props.right}px;
    `};
`;

export const Margin = styled.div`
  width: 100%;
  ${(props) =>
    props.margin &&
    css`
      margin: ${(props) => props.margin.replace(/( )+/g, "px ")}px;
    `};

  ${(props) =>
    props.top &&
    css`
      margin-top: ${(props) => props.top}px;
    `};

  ${(props) =>
    props.left &&
    css`
      margin-left: ${(props) => props.left}px;
    `};

  ${(props) =>
    props.bottom &&
    css`
      margin-bottom: ${(props) => props.bottom}px;
    `};

  ${(props) =>
    props.right &&
    css`
      margin-right: ${(props) => props.right}px;
    `};
`;

export const Title = styled.label`
  font-size: ${(props) =>
    props.fontSize || (props.subtitle ? 22 : 30)}px !important;
  font-weight: 500;
  font-family: "Roboto";
  color: ${(props) => props.theme.palette[props.color || "gray"]} !important;
  cursor: ${(props) => props.cursor || "default"};
`;

export const IconButtonStyled = styled(IconButton)`
  background-color: ${(props) =>
    props.theme.palette[props["bg-color"] || "blue"]} !important;
  color: white !important;

  .MuiSvgIcon-root {
    color: ${(props) =>
      props.theme.palette[props.iconColor || "white"]} !important;
  }

  :hover {
    background-color: ${(props) =>
      props.theme.palette[props.bghovercolor || "orange"]} !important;
  }
  ${(props) =>
    props.padding &&
    css`
      padding: ${(props) => props.padding};
    `};
  ${(props) =>
    props.height &&
    css`
      height: ${(props) => props.height};
    `};
  ${(props) =>
    props.width &&
    css`
      width: ${(props) => props.width};
    `};
`;

export const DataGridDefault = styled(DataGrid)`
  border: 0px !important;
  height: 60vh !important;

  .MuiDataGrid-window {
    ::-webkit-scrollbar {
      height: 13px !important;
    }

    ::-webkit-scrollbar-track {
      background-color: #efefef;
    }

    ::-webkit-scrollbar-thumb {
      background-color: ${grayLight};
    }
  }

  .MuiDataGrid-columnHeaderWrapper {
    font-family: ${roboto}, sans-serif !important;
    font-weight: 500;
    white-space: normal;
  }

  .MuiDataGrid-columnsContainer {
    min-height: 40px !important;
    line-height: 20px !important;
  }

  .MuiDataGrid-columnHeaderTitle {
    white-space: normal !important;
    text-overflow: unset !important;
  }

  .MuiDataGrid-root {
    border: 5px solid #000 !important;
  }

  div .MuiTablePagination-toolbar {
    .MuiTablePagination-caption:first-of-type {
      display: none;
    }
  }

  .MuiDataGrid-columnSeparator {
    display: none !important;
  }
`;

export const InputOutlined = styled(OutlinedInput)`
  .MuiOutlinedInput-input {
    padding: 8px 10px !important;
  }

  .MuiInputBase-input {
    font-size: 14px;
  }
`;

export const AccordionStyled = styled(Accordion)`
  ${(props) =>
    props["bg-color"] &&
    css`
      background-color: ${(props) => props.theme.palette[props["bg-color"]]};
    `};

  ${(props) =>
    props["textColor"] &&
    css`
      color: ${(props) => props.theme.palette[props.textColor]};
    `};

  ${(props) =>
    props["$dense"] &&
    css`
      margin: 0 !important;
    `};
`;

export const GridStyled = styled(Grid)`
  ${(props) =>
    props["min-height"] &&
    css`
      min-height: ${(props) => props["min-height"]};
    `};

  ${(props) =>
    props["max-width"] &&
    css`
      max-width: ${(props) => props["max-width"]};
    `};

  ${(props) =>
    props.whiteSpace &&
    css`
      white-space: ${(props) => props.whiteSpace};
    `};

  ${(props) =>
    props.overflow &&
    css`
      overflow: ${(props) => props.overflow};
    `};

  ${(props) =>
    props["overflow-y"] &&
    css`
      overflow-y: ${(props) => props["overflow-y"]};
    `};

  ${(props) =>
    props["overflow-x"] &&
    css`
      overflow-x: ${(props) => props["overflow-x"]};
    `};

  ${(props) =>
    props.height &&
    css`
      height: ${(props) => props.height};
    `};

  ${(props) =>
    props.width &&
    css`
      width: ${(props) => props.width};
    `};

  ${(props) =>
    props["border-radius"] &&
    css`
      border-radius: ${(props) => props["border-radius"]}px;
    `};

  ${(props) =>
    props.padding &&
    css`
      padding: ${(props) => props.padding}px!important;
    `};

  ${(props) =>
    props.margin &&
    css`
      margin: ${(props) => props.margin}px !important;
    `};

  ${(props) =>
    props["margin-bottom"] &&
    css`
      margin-bottom: ${(props) => props["margin-bottom"]}px;
    `};

  ${(props) =>
    props["margin-rigth"] &&
    css`
      margin-right: ${(props) => props["margin-rigth"]}px !important;
    `};

  ${(props) =>
    props["margin-left"] &&
    css`
      margin-left: ${(props) => props["margin-left"]}px;
    `};

  ${(props) =>
    props["margin-top"] &&
    css`
      margin-top: ${(props) => props["margin-top"]}px;
    `};

  ${(props) =>
    props["line-height"] &&
    css`
      line-height: ${(props) => props["line-height"]};
    `};

  ${(props) =>
    props["box-shadow"] &&
    css`
      box-shadow: ${(props) => props["box-shadow"]};
    `};

  ${(props) =>
    props.border &&
    css`
      border: ${(props) => props.border};
    `};

  ${(props) =>
    props["flexWrap"] &&
    css`
      flex-wrap: ${(props) => props["flexWrap"]};
    `};

  ${(props) =>
    props.borderColor &&
    css`
      border-color: ${(props) => props.theme.palette[props.borderColor]};
    `};

  ${(props) =>
    props.boxshadow &&
    css`
      box-shadow: ${(props) => props.boxshadow};
    `};

  ${(props) =>
    props.cursor &&
    css`
      cursor: ${(props) => props.cursor};
    `};

  background-color: ${(props) =>
    props.theme.palette[props["bg-color"] || "transparent"]};

  ${(props) =>
    props.bghovercolor &&
    css`
      :hover {
      }
      background-color: ${(props) => props.bghovercolor};
    `};

  ${(props) =>
    props.position &&
    css`
      position: ${(props) => props.position};
    `};

  ${(props) =>
    props.top &&
    css`
      top: ${(props) => props.top};
    `};

  ${(props) =>
    props.alignSelf &&
    css`
      align-self: ${(props) => props.alignSelf};
    `};

  ${(props) =>
    props["text-align"] &&
    css`
      text-align: ${(props) => props["text-align"]};
    `};
  ${(props) =>
    props.textAlign &&
    css`
      text-align: ${(props) => props.textAlign};
    `};
`;

export const LabelStyled = styled.label`
  ${(props) =>
    props.fontSize &&
    css`
      font-size: ${(props) => props.fontSize}px;
    `};

  ${(props) =>
    props.fontFamily &&
    css`
      font-family: ${(props) => props.fontFamily};
    `};

  ${(props) =>
    props.margin &&
    css`
      margin: ${(props) => props.margin}px;
    `};

  ${(props) =>
    props.color &&
    css`
      color: ${(props) => props.theme.palette[props.color]};
    `};

  ${(props) =>
    props.fontWeight &&
    css`
      font-weight: ${(props) => props.fontWeight};
    `};

  ${(props) =>
    props.cursor &&
    css`
      cursor: ${(props) => props.cursor};
    `};

  ${(props) =>
    props["text-align"] &&
    css`
      text-align: ${(props) => props["text-align"]};
    `};
`;

export const TextApresentation = styled.div`
  font-family: "Zen Kaku Gothic Antique";
  font-size: 56px;
  transition: 0.875s;
  transition-delay: 0.5s;
  opacity: 0;
  width: 60vw;
  text-align: center;
  align-items: center;
  display: flex;
  white-space: break-spaces;
  justify-content: center;
  ${(props) =>
    props.text &&
    css`
      opacity: 1 !important;
    `};
`;

export const Sobreposition = styled.div`
  opacity: 0;
  ${(props) =>
    props.overlap &&
    css`
      cursor: none !important;
      pointer-events: none !important;
      padding: 0px 0px 0px 0px !important;
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: 0;
      background: linear-gradient(
        rgba(0, 0, 0, 0.765),
        rgba(0, 0, 0, 0.765)
      ) !important;
      background-color: transparent !important;
      z-index: 99;
      justify-content: center;
      align-items: center;
      font-family: "Roboto", sans-serif;
      color: white;
      transition: 1.475s;
      display: flex;
      opacity: 1 !important;
    `};

  ${(props) =>
    props.top &&
    css`
      ${TextApresentation} {
        margin-top: -70vh;
      }
    `};
`;

export const Pulse = styled.div`
  ${(props) =>
    props.active &&
    css`
      opacity: 1 !important;
      z-index: 999 !important;
      position: relative !important;
      box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
      margin: 5px;
      transition: 0.875s;
      transform: scale(1);
      box-shadow: 5 5 5 5 rgba(52, 172, 224, 1);
      animation: pulse-blue 1.2s infinite;

      @keyframes pulse-blue {
        0% {
          transform: scale(0.95);
          box-shadow: 0 0 0 0 rgba(52, 172, 224, 0.7);
        }

        70% {
          transform: scale(1);
          box-shadow: 0 0 0 15px rgba(52, 172, 224, 0);
        }

        100% {
          transform: scale(0.95);
          box-shadow: 0 0 0 0 rgba(52, 172, 224, 0);
        }
      }
    `};

  ${(props) =>
    props.active &&
    !props.pointerEvent &&
    css`
      pointer-events: none !important;
    `};
`;

export const PulseIcon = styled.div`
  ${(props) =>
    props.active &&
    css`
      border-radius: 50%;
      box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
      margin: 10px;

      transform: scale(1);
      background: ${blue};
      box-shadow: 0 0 0 0 rgba(52, 172, 224, 1);
      animation: pulse-blue 1.2s infinite;

      @keyframes pulse-blue {
        0% {
          transform: scale(0.95);
          box-shadow: 0 0 0 0 rgba(52, 172, 224, 0.7);
        }

        70% {
          transform: scale(1);
          box-shadow: 0 0 0 15px rgba(52, 172, 224, 0);
        }

        100% {
          transform: scale(0.95);
          box-shadow: 0 0 0 0 rgba(52, 172, 224, 0);
        }
      }
    `},
`;

export const NoEvents = styled.div`
  ${(props) =>
    props.active &&
    css`
      cursor: none !important;
      pointer-events: none !important;
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: 0;
    `};
`;

export const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxshadow: theme.shadows[2],
    fontSize: 13,
  },
}))(Tooltip);

export const CircularSaving = styled(CircularProgress)``;
export const FloatingSaving = styled.div`
  position: fixed;
  bottom: 45px;
  left: 235px;
  padding: 10px 30px 10px 30px;
  background-color: #d4d3d3;
  border: none;
  border-radius: 100vh;
  align-items: center;
  justify-content: center;
  display: flex;
  color: ${blue};
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  font-family: "Roboto", sans-serif;
  font-size: 1.125rem;
  z-index: 10;
  ${CircularSaving} {
    margin-right: 8px;
    height: 20px !important;
    width: 20px !important;
  }
`;

export const ButtonStyled = styled(Button)`
  background: linear-gradient(
    315deg,
    rgba(8, 57, 169, 1) 0%,
    rgba(10, 115, 219, 1) 100%
  );
  text-transform: none;
  font-size: 12px;
  color: #fff;
`;

export const ButtonCopilotStyled = styled(Button)`
  background: linear-gradient(
    315deg,
    rgba(8, 57, 169, 1) 0%,
    rgba(10, 115, 219, 1) 100%
  );
  text-transform: none;
  font-size: 16px;
  color: #fff;
  padding-left: 20px;
  padding-right: 20px;
`;
