import React from "react";
import AttendanceData from "./childrens/AttendanceData";
import "./styles/Attendance.css";

export default function InsertAttendance() {
  return (
    <>
      <AttendanceData typeScreen={"insert"} />
    </>
  );
}
