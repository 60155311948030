import { Avatar, Button, Chip } from "@material-ui/core";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import styled from "styled-components";

export const SidebarButton = styled(Button)`
  background-color: "transparent";
  border: "none";
  margin-right: "5px";
  margin-top: "1px";
`;

export const AvatarPersonIconStyled = styled(Avatar)`
  background-color: "#bdbdbd";
  color: "white";
`;

export const ExpandLessStyled = styled(ExpandLess)`
  margin-right: 15px;
  height: 20px;
  color: ${(props) =>
    !props.ableToAccess ? "#162238" : props.clickMenu ? "white" : "grey"};
`;

export const ExpandMoreStyled = styled(ExpandMore)`
  margin-right: 15px;
  height: 20px;
  color: ${(props) =>
    !props.ableToAccess ? "#162238" : props.clickMenu ? "white" : "grey"};
`;

export const BetaChip = styled(Chip)`
  height: 15px;
  background-color: #29b6f6;
  color: #fff;
  border-radius: 5px;
  font-weight: 600;
  margin-top: 4px;
  margin-right: 42px;
  font-size: 0.6rem;
`;
