import { Box, Grid, Tab, Tabs, Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import React, { useState } from "react";
import "./style/TabsCustom.css";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function BasicTabs({ items, page, setPage }) {
  const [value, setValue] = useState(page || 0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setPage(newValue);
  };

  return (
    <Grid xs={12}>
      <Box style={{ width: "100%" }}>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",
            backgroundColor: "#0a73db",
            boxShadow: "0 2px 2px 1px #c2c7ce",
            padding: "0 2% 0 2%",
          }}
        >
          <Tabs
            scrollButtons="off"
            value={value}
            onChange={handleChange}
            style={{ width: "100%" }}
          >
            {items &&
              items.map((item) => (
                <Tab
                  style={{
                    width: "230px",
                    fontSize: "15px",
                    height: "50px",
                    color: "#fff",
                    borderBottomColor: "#fff",
                  }}
                  label={item.label}
                  {...a11yProps(item.value)}
                />
              ))}
          </Tabs>
        </Box>
        {items?.map((item) => (
          <TabPanel value={value} index={item.value}>
            {item.content}
          </TabPanel>
        ))}
      </Box>
    </Grid>
  );
}
