import styled from "styled-components";

export const CheckLabelContainer = styled.div`
  display: flex;
  align-items: center;

  p {
    margin-top: 0.5em;
    margin-bottom: 0.5em;
    user-select: none;
    font-size: 0.9rem;
  }

  .MuiSvgIcon-root {
    margin-left: 8px;
    color: ${(props) => props.theme.palette[props.tooltipColor]} !important;
    width: 0.9em;
  }
`;
