import React, { createContext } from "react";

const BillToPayContext = createContext();

const BillToPayContextProvider = ({ children }) => {
  const [loadingPage, setLoadingPage] = React.useState(false);

  return (
    <BillToPayContext.Provider
      value={{
        loadingPage,
        setLoadingPage,
      }}
    >
      {children}
    </BillToPayContext.Provider>
  );
};

export { BillToPayContextProvider, BillToPayContext };
