import BaseMask from "./_base.mask";

export class Decimal extends BaseMask {
  static getType() {
    return "decimal";
  }

  getValue(value, settings) {
    if (!value || value === "NaN") return "0,00";

    let removeMask = value.toString();

    removeMask = removeMask
      .substr(0, 20)
      .replace(/[^0-9]+/g, "")
      .split("");

    const last = removeMask[removeMask.length - 1];

    const penultimate = removeMask[removeMask.length - 2];

    removeMask[removeMask.length - 2] = ",";
    removeMask[removeMask.length - 1] = penultimate || "0";
    removeMask[removeMask.length] = last || "0";

    if (removeMask[0] === ",") {
      removeMask.unshift("0");
      removeMask.unshift("0");
    }

    if (
      removeMask.length > 3 &&
      removeMask[0] === "0" &&
      removeMask[1] !== ","
    ) {
      removeMask.splice(0, 1);
    }

    return parseFloat(removeMask.join("").replace(",", "."))
      .toLocaleString("pt-br", {
        style: "currency",
        currency: "BRL",
      })
      .replace("R$", "")
      .replace(/\s/g, "");
  }
}
