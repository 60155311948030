import React from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "react-responsive-carousel/lib/styles/carousel.css";

import { useEffect, useState } from "react";

import Modal from "@material-ui/core/Modal";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
} from "chart.js";

import { Bar } from "react-chartjs-2";
import {
  DialogHeader,
  DialogStyle,
  BodyStyle,
  ButtonFottterStyle,
  ButtonTogleStyle,
  CardMeterStyle,
  DivStyle,
  EventStyle,
  FooterStyle,
  ValueStyle,
  CloseIconStyle,
} from "../../modal/Modal";

import { ArrowBackIosOutlined, ArrowForwardIos } from "@material-ui/icons";
import { getEventsByTimestamp } from "../../../repositories/portal_api/Event";

ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement
);

export const MetricsModal = (props) => {
  const [meterList, setMeterList] = useState([]);
  const [index, setIndex] = useState(0);

  const [tab, setTab] = useState(0);

  const [barData, setBarData] = useState(null);

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {},
    },
  };

  useEffect(() => {
    getEventsByTimestamp((data) => {
      data.sort(function (a, b) {
        return parseInt(b.total) - parseInt(a.total);
      });

      let dataList = data.slice(0, 5);
      let bar = {
        labels: [],
        datasets: [
          {
            label: "Eventos",
            data: [],
            backgroundColor: "#0036B3",
          },
        ],
      };

      for (let item of dataList) {
        bar.labels.push(item.name);
        bar.datasets[0].data.push(item.total);
      }

      setBarData(bar);
      setMeterList(data);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChange = (index, item) => {
    setIndex(index);
  };

  return (
    <Modal
      open={props.openModal}
      onClose={() => {
        props.closeMetricsModal();
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <DialogStyle>
        <DialogHeader>
          <div className="row">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginBottom: 20,
              }}
            >
              Todas as Atividades do QYON Bot
            </div>

            <CloseIconStyle
              onClick={() => {
                props.closeMetricsModal();
              }}
            />
          </div>
        </DialogHeader>

        <div style={{ paddingLeft: "20px", paddingRight: "20px" }}>
          <DivStyle>
            <ButtonTogleStyle
              onClick={() => {
                setTab(0);
              }}
              active={tab === 0}
            >
              Dados
            </ButtonTogleStyle>
            &nbsp;
            <ButtonTogleStyle
              onClick={() => {
                setTab(1);
              }}
              active={tab === 1}
            >
              Gráfico
            </ButtonTogleStyle>
            &nbsp;
          </DivStyle>
        </div>

        <div style={{ padding: 20 }}>
          {tab === 1 && (
            <>
              {meterList.length !== 0 && (
                <BodyStyle backgroundColor={"#f0f1f3"}>
                  {barData && <Bar options={options} data={barData} />}
                </BodyStyle>
              )}
            </>
          )}

          {tab === 0 && (
            <>
              <Carousel
                showStatus={false}
                showThumbs={false}
                selectedItem={index}
                controlArrow={true}
                showArrows={true}
                onChange={onChange}
              >
                <div>
                  <BodyStyle backgroundColor={"#f0f1f3"}>
                    {meterList.slice(0, 8).map((meter) => (
                      <CardMeterStyle>
                        <EventStyle>{meter.name}</EventStyle>
                        <ValueStyle>{meter.total}</ValueStyle>
                      </CardMeterStyle>
                    ))}
                  </BodyStyle>
                </div>
                <div>
                  <BodyStyle backgroundColor={"#f0f1f3"}>
                    {meterList.slice(8).map((meter) => (
                      <CardMeterStyle>
                        <EventStyle>{meter.name}</EventStyle>
                        <ValueStyle>{meter.total}</ValueStyle>
                      </CardMeterStyle>
                    ))}
                  </BodyStyle>
                </div>
              </Carousel>

              <FooterStyle>
                <ButtonFottterStyle
                  variant="text"
                  startIcon={<ArrowBackIosOutlined />}
                  active={index !== 0}
                  onClick={() => {
                    setIndex(index - 1);
                  }}
                >
                  Voltar
                </ButtonFottterStyle>

                <ButtonFottterStyle
                  variant="text"
                  endIcon={<ArrowForwardIos />}
                  active={index === 0}
                  onClick={() => {
                    setIndex(index + 1);
                  }}
                >
                  Próximo
                </ButtonFottterStyle>
              </FooterStyle>
            </>
          )}
        </div>
      </DialogStyle>
    </Modal>
  );
};
