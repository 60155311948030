import { Grid } from "@material-ui/core";
import { LabelStyled } from "../../../styles/GlobalStyle";

export const TableDescription = () => {
  return (
    <Grid container justifyContent="space-between">
      <Grid container alignItems="center" spacing={2} item xs={8}>
        <Grid item flex={1}>
          <LabelStyled color="green">● Negativa</LabelStyled>
        </Grid>
        <Grid item flex={1}>
          <LabelStyled color="blue">● Positiva com efeito Negativa</LabelStyled>
        </Grid>
        <Grid item flex={1}>
          <LabelStyled color="orange">● Positiva</LabelStyled>
        </Grid>
        <Grid item flex={1}>
          <LabelStyled color="grayBlue">● Inconsistente</LabelStyled>
        </Grid>
        <Grid item flex={1}>
          <LabelStyled color="yellow">● Falha</LabelStyled>
        </Grid>
        <Grid item flex={1}>
          <LabelStyled color="gray">● Processando</LabelStyled>
        </Grid>
      </Grid>
      <Grid
        container
        alignItems="center"
        spacing={2}
        item
        xs={4}
        justifyContent="flex-end"
      >
        <Grid item flex={1}>
          <LabelStyled color="purple">● A Vencer</LabelStyled>
        </Grid>
        <Grid item flex={1}>
          <LabelStyled color="red">● Vencida</LabelStyled>
        </Grid>
      </Grid>
    </Grid>
  );
};
