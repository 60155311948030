import BaseMask from "./_base.mask";

const RG_MASK = "99.999.999-9";

const validateRg = (rg = "") => {
  const exp = /\.|-|\//g;
  rg = rg.toString().replace(exp, "");
  return rg.length === 9;
};

export class RgMask extends BaseMask {
  static getType() {
    return "rg";
  }

  getValue(value = "") {
    return this.getVMasker().toPattern(value, RG_MASK);
  }

  getRawValue(maskedValue = "") {
    return super.removeNotNumbers(maskedValue);
  }

  validate(value = "") {
    return validateRg(value);
  }
}
