import { Button, Grid, TextField } from "@material-ui/core";
import styled, { css } from "styled-components";

export const StyledNewBotContainer = styled(Grid)`
  overflow: auto;
`;

export const StyledBotFuncionalities = styled.div`
  padding: 20px 10px 10px 10px;
  overflow: auto;
  ${(props) => {
    let dimensionHeight = 0;
    if (props.hasBatteryMessage) {
      dimensionHeight += 0;
    }
    if (props.hasBoletoAlert) {
      dimensionHeight += 50;
    }

    return css`
      height: calc(100vh - 434px - 65px - ${dimensionHeight}px);
    `;
  }}
`;

export const StyledMessageField = styled(TextField)`
  background: #fff;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.16);
  font-size: 12px;
  text-align: center;
  border-radius: 5px;
`;

export const StyledGridBotFuncionality = styled(Grid)`
  margin-bottom: 10px;
  ${(props) =>
    !props.hasText &&
    css`
      line-height: 0;
    `}

  ${(props) =>
    props.active === false &&
    css`
      display: none;
    `}
`;

export const StyledButtonCaroussel = styled(Button)`
  max-width: 25px;
  width: 25px;
  min-width: 25px;
  height: 20px;
  background: #fff;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.16);
  margin-right: 2px;
`;

export const StyledGridBotActivity = styled(Grid)`
  margin-bottom: 5px;
  border-radius: 8px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.16);
  background: #fff;
  width: 100%;
  font-size: 10px;
`;

export const StyledGridBotActivityQuantity = styled(Grid)`
  display: flex;
  justify-content: center;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  padding: 5px;
  background: #e7eefe;
  color: #0839a9;
  font-weight: bold;
`;

export const StyledButtonBotActivity = styled(Button)`
  background: linear-gradient(
    315deg,
    rgba(8, 57, 169, 1) 0%,
    rgba(10, 115, 219, 1) 100%
  );
  text-transform: none;
  font-size: 12px;
  color: #fff;
`;

export const SubtitleBotActions = styled.div`
  font-size: 12px;
  font-family: Roboto;
  color: #0839a9;
  font-weight: bold;
`;

export const ContainerIconSubtitle = styled.div`
  margin-right: 5px;
  color: #fff;
  background: linear-gradient(
    315deg,
    rgba(8, 57, 169, 1) 0%,
    rgba(10, 115, 219, 1) 100%
  );
  border-radius: 5px;
  padding: 3px;
`;

export const DivStyledActions = styled.div`
  margin-right: 5px;
  color: #fff;
  background: linear-gradient(
    315deg,
    rgba(8, 57, 169, 1) 0%,
    rgba(10, 115, 219, 1) 100%
  );
  border-radius: 5px;
  padding: 3px;
`;

export const ButtonHistoryConversation = styled(Button)`
  font-size: 12px;
  text-transform: none;
  background: #fff;
  border-radius: 5px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.16);
`;

export const StyledGridBotActivityName = styled(Grid)`
  padding: 5px;
`;
