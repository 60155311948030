import React, { useContext, useEffect, useState } from "react";
// import HTMLEditor from 'mui-rte';
// Import Icons from material ui
import ArrowDropDownCircleIcon from "@material-ui/icons/ArrowDropDownCircle";
import parse from "html-react-parser";
// Import components from material ui
import { CircularProgress, Fab, Grid } from "@material-ui/core";
import moment from "moment";

import "../styles/Attendance.css";

import { GlobalContext } from "../../../contexts/GlobalContext";
import useAttendanceController from "../controllers/AttendanceController";

export default function InteractionHistoric({
  loading,
  messages,
  uploadedFiles,
  originRender,
}) {
  const { handleDownloadFile, handleShowFile } = useAttendanceController();
  const [viewMore, setViewMore] = useState(5);
  const { companyUser } = useContext(GlobalContext);

  useEffect(() => {
    renderMessages();
  }, [viewMore, messages]);

  const UserMessage = {
    attedantMessage: {
      direction: companyUser?.isAccountant === "true" ? "right" : "left",
      bgColorMessage: "#006FDE",
      border:
        companyUser?.isAccountant === "true"
          ? "8px 0 8px 8px"
          : "0 8px 8px 8px",
    },
    customerMessage: {
      direction: companyUser?.isAccountant === "true" ? "left" : "right",
      bgColorMessage: "#808080",
      border:
        companyUser?.isAccountant === "true"
          ? "0 8px 8px 8px"
          : "8px 0 8px 8px",
    },
    solicitationMessage: {
      direction: "center",
      bgColorMessage: "#FFFFCC",
      border: "8px 8px 8px 8px",
    },
  };

  const styleByOriginRender = {
    accordion: { position: "absolute", bottom: 20, right: 40 },
    previewMessages: { position: "absolute", bottom: 70, right: 40 },
  };

  const Message = ({
    typeMessage,
    message,
    attachmentMessage,
    date,
    username,
    privateFlag,
    base64Image,
  }) => {
    const { direction, bgColorMessage, border } =
      typeMessage === "solicitationMessage"
        ? UserMessage.solicitationMessage
        : typeMessage === "attendant"
        ? UserMessage.attedantMessage
        : UserMessage.customerMessage;

    if (!message) return <></>;
    return (
      <React.Fragment>
        <Grid
          item
          container
          xs={12}
          style={{
            display:
              privateFlag && companyUser.isAccountant !== "true"
                ? "none"
                : "flex",
            justifyContent: direction,
            marginTop: "10px",
          }}
        >
          <Grid item xs={8} style={{ textAlign: "right" }}>
            <Grid
              item
              style={{
                padding: "3%",
                borderRadius: border,
                background: privateFlag ? "#f5f5f9" : bgColorMessage,
                border: privateFlag ? "1px dashed #8995a6" : "",
                color:
                  typeMessage === "solicitationMessage"
                    ? "#808080"
                    : privateFlag
                    ? "#8995a6"
                    : "#fff",
                wordBreak: "break-word",
                display: "block",
                textAlign:
                  typeMessage === "solicitationMessage" ? "center" : "left",
              }}
            >
              {typeMessage !== "solicitationMessage" && (
                <b>
                  {username}
                  <br />
                </b>
              )}
              <p
                style={{
                  maxWidth:
                    typeMessage !== "solicitationMessage" ? "500px" : "",
                }}
              >
                {message && parse(message)}
              </p>
            </Grid>
            {typeMessage !== "solicitationMessage" && (
              <Grid
                style={{
                  textAlign: "right",
                  fontSize: "10px",
                  color: "black",
                  marginRight: "40px",
                }}
              >
                {date}
              </Grid>
            )}
          </Grid>
        </Grid>
      </React.Fragment>
    );
  };

  const returnHtmlFiles = ({
    base64,
    fileId,
    fileName,
    fileMimeType,
    fileExtension,
  }) => {
    let src;
    if (fileMimeType.includes("image")) {
      src = handleShowFile(base64, fileMimeType);
      return `<img style="max-width: 600px;" id='file_s3-${fileId}' class='messagesImages' src='${src}'/>`;
    } else {
      const attributes = handleDownloadFile(base64, fileName, true);
      return `<a style='color: #f5f5f9' href='${
        attributes?.dataApplication
      }' download='${fileName + fileExtension}'>${
        fileName + fileExtension
      }</a>`;
    }
  };

  const renderMessages = () => {
    if (!messages || !messages.length) return null;
    const messagesView = messages.slice(0).slice(-viewMore);

    return messagesView.map((message) => {
      if (message?.htmlDescription) {
        message.description = message?.htmlDescription;
      }
      if (message?.description !== null && uploadedFiles) {
        uploadedFiles.map((file) => {
          if (message?.description.includes(file.id) ? true : false) {
            const htmlResult = returnHtmlFiles({
              base64: file?.elementBase64,
              fileId: file?.id,
              fileName: file?.name,
              fileMimeType: file?.file?.type,
              fileExtension: file?.file?.extension,
            });
            message.description = message?.description.replace(
              `<div class=divHistoricoEtapasAnexo>{{Arquivo: ${
                message?.description.includes(file.id) && file.id
              }}}</div>`,
              htmlResult ? htmlResult : "Arquivo não encontrado!"
            );
          }
        });
      }

      const type = message?.solicitationMessage
        ? "solicitationMessage"
        : message?.userId
        ? "attendant"
        : "customer";
      return (
        <>
          <Message
            privateFlag={message?.privateFlag}
            typeMessage={type}
            message={message?.description}
            attachmentMessage={message?.attachment}
            date={moment(message.dateTime).format("DD/MM/YYYY HH:mm:ss")}
            username={message?.userId ? message?.username : message?.contact}
          />
        </>
      );
    });
  };

  const LoadMessages = () => {
    return (
      <Grid
        id="containerMessages"
        item
        xs={12}
        rowSpacing={50}
        style={{
          height: "100%",
          padding: "2%",
          overflowY: "scroll",
          maxHeight: "500px",
        }}
      >
        {renderMessages()}
        {document.getElementById("containerMessages") &&
          document.getElementById("containerMessages").scrollTop !==
            document.getElementById("containerMessages").scrollHeight && (
            <Fab
              size="small"
              aria-label="Descer"
              color="grayPrimary"
              style={styleByOriginRender[originRender]}
              onClick={() => {
                const scroll = setInterval(() => {
                  if (document.getElementById("containerMessages")) {
                    document.getElementById("containerMessages").scrollTop =
                      document.getElementById("containerMessages").scrollHeight;
                    clearInterval(scroll);
                  }
                }, 100);
              }}
            >
              <ArrowDropDownCircleIcon />
            </Fab>
          )}
      </Grid>
    );
  };

  return (
    <Grid xs={12} style={{ textAlign: "center", width: "100%" }}>
      {loading ? (
        <CircularProgress />
      ) : (
        <>
          {viewMore < messages?.length && (
            <div
              onClick={() => setViewMore(viewMore + 5)}
              style={{
                textAlign: "center",
                color: "#006FDE",
                cursor: "pointer",
              }}
            >
              Ver Mais
            </div>
          )}
          <LoadMessages />
        </>
      )}
    </Grid>
  );
}
