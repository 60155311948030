import { BrowserRouter } from "react-router-dom";
import QyonComercial from "../pages/comercialQyon/routerHome";

import AccessProfile from "./modules/AccessProfile";
import Attendances from "./modules/Attendance";
import Authentication from "./modules/Authentication";
import ChatSetting from "./modules/ChatSetting";
import Cnd from "./modules/Cnd";
import CustomerManagement from "./modules/CustomerManagement";
import Dashboard from "./modules/Dashboard";
import Default from "./modules/Default";
import Document from "./modules/Document";
import Guide from "./modules/Guide";
import Report from "./modules/Reports";
import Setting from "./modules/Setting";
import User from "./modules/User";
import AccessRelease from "./modules/Versions";
import UserConfirmation from "./UserConfirmation";
import Systems from "./modules/Systems";
import Certificate from "./modules/Certificate";
import BillToPay from "./modules/BillToPay";

const Routes = () => {
  return (
    <>
      <BrowserRouter
        getUserConfirmation={(message, callback) => {
          return UserConfirmation(message, callback);
        }}
      >
        <Authentication />
        <Dashboard />
        <CustomerManagement />
        <Certificate />
        <BillToPay />
        <User />
        <Setting />
        <Attendances />
        <Cnd />
        <Document />
        <Guide />
        <Report />
        <ChatSetting />
        <AccessProfile />
        <AccessRelease />
        <Systems />
        <QyonComercial />

        {/* Deixar sempre no fim */}
        <Default />
        {/* -------------------- */}
      </BrowserRouter>
    </>
  );
};

export default Routes;
