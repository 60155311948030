import styled from "styled-components";

import { Grid } from "@material-ui/core";

export const GridListFiles = styled(Grid)`
  width: 50px;
  height: 50px;
  border-radius: 5px;
  background-image: ${(props) =>
    props?.type?.includes("image")
      ? `url(${props?.bgImagefiles})`
      : `url(https://s3.sa-east-1.amazonaws.com/qyon.panel/images/certificado.png)`};
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  margin-right: 10px;
`;
