import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@material-ui/core";
import { useContext, useEffect } from "react";
import Loading from "../../../components/basics/Loading";
import SelectErp from "../../../components/basics/SelectErp";
import TextFieldErp from "../../../components/basics/TextFieldErp";
import Btn from "../../../components/buttons/Btn";
import { Title } from "../../../styles/GlobalStyle";
import { UserContext } from "../contexts/UserContext";
import useUserController from "../controllers/UserController";

export default function ModaluUser() {
  const {
    usersCostumer,
    userModalOpen,
    userModalIndex,
    setId,
    firstName,
    setFirstName,
    lastName,
    setLastName,
    email,
    setEmail,
    profilesFilter,
    setProfilesFilter,
    listProfiles,

    loading,
    errors,
  } = useContext(UserContext);
  const { handleSaveUser, handleCloseUser } = useUserController();

  useEffect(() => {
    if (userModalIndex === -1 || !usersCostumer[userModalIndex]) {
      return;
    }
    setId(usersCostumer[userModalIndex].id);
    setFirstName(usersCostumer[userModalIndex].first_name);
    setLastName(usersCostumer[userModalIndex].last_name);
    setEmail(usersCostumer[userModalIndex].email);
  }, [
    usersCostumer,
    userModalIndex,
    setEmail,
    setFirstName,
    setId,
    setLastName,
  ]);

  return (
    <>
      <Loading open={loading} />
      <Dialog
        open={userModalOpen}
        maxWidth="sm"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <Title subtitle>Cadastro de Usuário</Title>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item sm={6} xs={12}>
              <TextFieldErp
                error={errors.firstName ? true : false}
                helperText={errors.firstName || ""}
                value={firstName}
                setValue={setFirstName}
                label="Primeiro Nome*"
                maxLength={50}
              />
            </Grid>

            <Grid item sm={6} xs={12}>
              <TextFieldErp
                error={errors.lastName ? true : false}
                helperText={errors.lastName || ""}
                value={lastName}
                setValue={setLastName}
                label="Último Nome*"
                maxLength={50}
              />
            </Grid>

            <Grid item xs={12}>
              <TextFieldErp
                error={errors.email ? true : false}
                helperText={errors.email || ""}
                value={email}
                setValue={setEmail}
                label="E-mail*"
                maxLength={50}
              />
            </Grid>

            <Grid item xs={12}>
              <SelectErp
                multiple
                error={errors.profiles ? true : false}
                helperText={errors.profiles || ""}
                value={profilesFilter}
                setValue={setProfilesFilter}
                label="Permissões*"
                maxLength={50}
                optionsList={listProfiles}
                descriptionField="name"
              />
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions>
          <Btn label="Cancelar" bg-color="tertiary" onClick={handleCloseUser} />
          <Btn label="Salvar" onClick={handleSaveUser} />
        </DialogActions>
      </Dialog>
    </>
  );
}
