export async function getEmployees(document) {
  let office =
    localStorage.getItem("isAccountantPanel") === "true"
      ? localStorage.getItem("documentPanel")
      : localStorage.getItem("accountantCnpjPanel");

  if (office === null) {
    office = localStorage.getItem("documentPanel");
  }

  const myHeaders = new Headers();
  myHeaders.append(
    "access_key",
    "a49c58e5c3c7c0466e6f59cdde201ff2c559e5786673fe5ff7a73a167dac4c61-2a534ab5-9d77-4b0a-a9fc-3eb700f733c0"
  );
  myHeaders.append("registration_number_establishment", document);
  myHeaders.append("registration_number_office", office);

  /*
  const requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
  };

  let data = await fetch(
    "https://api.qyon-accounting.com/reports/v1/report-generator-payroll/payroll-informations-by-registration-number",
    requestOptions
  )
    .then((response) => response.json())
    .catch((error) => {
      return null;
    });

  console.log(response);
*/
  const data = {
    id: "8cba2c44-2fb1-4f92-8428-0a3fdc78838a",
    companies: [
      {
        id: "dd116c9d-2547-4f9a-915f-68b217d9b878",
        referenceId: "dd116c9d-2547-4f9a-915f-68b217d9b878",
        registrationNumber: "43114130000168",
        name: "FELIPE SOUZA DA SILVA 36457756840",
        establishments: [
          {
            id: "4114bf59-81ee-43b1-a39f-8a1b6c2e2451",
            referenceId: "4114bf59-81ee-43b1-a39f-8a1b6c2e2451",
            companyReferenceId: "dd116c9d-2547-4f9a-915f-68b217d9b878",
            registrationNumber: "43114130000168",
            name: "FELIPE SOUZA DA SILVA 36457756840",
            employeeCount: 0,
            payslipEmployeeProcessedCount: 0,
            employees: [
              {
                id: "46ba4e55-2dd1-4acb-8959-7da5e33d0b04",
                referenceId: "46ba4e55-2dd1-4acb-8959-7da5e33d0b04",
                establishmentReferenceId:
                  "4114bf59-81ee-43b1-a39f-8a1b6c2e2451",
                code: "000002",
                birthDate: "2002-02-15T02:00:00",
                registrationNumber: "47887500826",
                hiringDate: "2023-01-01T03:00:00",
                name: "Nathalia horista",
                payslips: [
                  {
                    id: "3428c1a0-c4bd-475a-badf-db058fe2708d",
                    employeeReferenceId: "46ba4e55-2dd1-4acb-8959-7da5e33d0b04",
                    paymentDate: "2023-10-01T00:00:00",
                    period: "2023-09-01T00:00:00",
                    type: 1,
                    payslipItems: [],
                  },
                ],
              },
              {
                id: "4db2f56b-6597-4536-adba-37ea89e319fa",
                referenceId: "6ae159c4-c853-4bc0-a4f3-9f4021cc59e7",
                establishmentReferenceId:
                  "4114bf59-81ee-43b1-a39f-8a1b6c2e2451",
                code: "000001",
                birthDate: "2000-08-14T03:00:00",
                registrationNumber: "46838838800",
                hiringDate: "2023-01-01T03:00:00",
                name: "Leonardo Abdi Munhoz Campos 2205",
                payslips: [
                  {
                    id: "e19c74d5-3999-457e-ab7f-47574210930a",
                    employeeReferenceId: "6ae159c4-c853-4bc0-a4f3-9f4021cc59e7",
                    paymentDate: "2023-10-01T00:00:00",
                    period: "2023-09-01T00:00:00",
                    type: 1,
                    payslipItems: [
                      {
                        id: "423f322c-011a-4dd3-859e-da533423f3a1",
                        payslipId: "e19c74d5-3999-457e-ab7f-47574210930a",
                        code: "1000.001",
                        description: "Salário",
                        type: 1,
                        reference: 30,
                        amount: 4160.0,
                      },
                      {
                        id: "419d7b27-b260-4f14-bc04-1e743e95e7a0",
                        payslipId: "e19c74d5-3999-457e-ab7f-47574210930a",
                        code: "9201.001",
                        description: "INSS - Mensal",
                        type: 2,
                        reference: 14.0,
                        amount: 408.31,
                      },
                      {
                        id: "3d0579dd-0ede-4de9-9837-ee6ceaf09a1f",
                        payslipId: "e19c74d5-3999-457e-ab7f-47574210930a",
                        code: "9203.001",
                        description: "IRRF - Mensal",
                        type: 2,
                        reference: 22.5,
                        amount: 192.4,
                      },
                    ],
                  },
                ],
              },
              {
                id: "ee7e79d9-9f8a-4b40-bb16-c9bfeafcdeed",
                referenceId: "72d00e3a-f2ef-407f-ae76-6cf783f351c7",
                establishmentReferenceId:
                  "4114bf59-81ee-43b1-a39f-8a1b6c2e2451",
                code: "000006",
                birthDate: "2000-01-01T02:00:00",
                registrationNumber: "13972162049",
                hiringDate: "2023-01-01T03:00:00",
                name: "José dos santos",
                payslips: [
                  {
                    id: "28cb8fe6-dbd8-4ec5-b3ed-b96a46062529",
                    employeeReferenceId: "72d00e3a-f2ef-407f-ae76-6cf783f351c7",
                    paymentDate: "2023-10-01T00:00:00",
                    period: "2023-09-01T00:00:00",
                    type: 1,
                    payslipItems: [
                      {
                        id: "32211345-39ba-4c5d-887a-22b0752bb1e9",
                        payslipId: "28cb8fe6-dbd8-4ec5-b3ed-b96a46062529",
                        code: "3506.001",
                        description:
                          "Retiradas (pró-labore) de diretores não empregados",
                        type: 1,
                        reference: 30,
                        amount: 3522.0,
                      },
                      {
                        id: "0a9b6d5d-0def-48a2-aee7-c0665c73dbbd",
                        payslipId: "28cb8fe6-dbd8-4ec5-b3ed-b96a46062529",
                        code: "9201.001",
                        description: "INSS - Mensal",
                        type: 2,
                        reference: 5.0,
                        amount: 66.0,
                      },
                      {
                        id: "e8adee03-f27d-4dba-a6d7-19a42563d065",
                        payslipId: "28cb8fe6-dbd8-4ec5-b3ed-b96a46062529",
                        code: "9203.001",
                        description: "IRRF - Mensal",
                        type: 2,
                        reference: 15.0,
                        amount: 148.0,
                      },
                    ],
                  },
                ],
              },
              {
                id: "c980cffd-357f-4587-bd83-277dc42aa005",
                referenceId: "632abf97-60bf-409b-8a8a-e39cc9a86474",
                establishmentReferenceId:
                  "4114bf59-81ee-43b1-a39f-8a1b6c2e2451",
                code: "000007",
                birthDate: "2000-01-01T02:00:00",
                registrationNumber: "59830846075",
                hiringDate: "2023-02-01T03:00:00",
                name: "Josemiro almeida",
                payslips: [],
              },
              {
                id: "db60678e-79e4-489d-b95b-2c6e9b30f9ea",
                referenceId: "68454aba-9ff6-44fa-a338-83ec2dfabf80",
                establishmentReferenceId:
                  "4114bf59-81ee-43b1-a39f-8a1b6c2e2451",
                code: "000005",
                birthDate: "2000-01-01T02:00:00",
                registrationNumber: "92305288034",
                hiringDate: "2022-01-01T03:00:00",
                name: "Sebastião pereira",
                payslips: [],
              },
              {
                id: "73e80d4f-c6b9-4062-9b2a-c7ab441533a3",
                referenceId: "d907502e-ffff-4599-8f73-c9e1ff9e450d",
                establishmentReferenceId:
                  "4114bf59-81ee-43b1-a39f-8a1b6c2e2451",
                code: "000003",
                birthDate: "2000-01-01T02:00:00",
                registrationNumber: "27490512077",
                hiringDate: "2022-01-01T03:00:00",
                name: "Maria Aparecida",
                payslips: [
                  {
                    id: "375dcfb4-85d3-4ec4-bb74-3b83ad792b09",
                    employeeReferenceId: "d907502e-ffff-4599-8f73-c9e1ff9e450d",
                    paymentDate: "2023-10-01T00:00:00",
                    period: "2023-09-01T00:00:00",
                    type: 1,
                    payslipItems: [
                      {
                        id: "7ff8e605-5cf9-4d5e-b406-556f076f0546",
                        payslipId: "375dcfb4-85d3-4ec4-bb74-3b83ad792b09",
                        code: "1000.001",
                        description: "Salário",
                        type: 1,
                        reference: 30,
                        amount: 2500.0,
                      },
                      {
                        id: "12ac5f20-c7a9-41ab-a7f2-9376ea96c814",
                        payslipId: "375dcfb4-85d3-4ec4-bb74-3b83ad792b09",
                        code: "9201.001",
                        description: "INSS - Mensal",
                        type: 2,
                        reference: 9.0,
                        amount: 205.2,
                      },
                      {
                        id: "fe245f30-4941-4c7f-a4e0-7eaf5c515e93",
                        payslipId: "375dcfb4-85d3-4ec4-bb74-3b83ad792b09",
                        code: "9203.001",
                        description: "IRRF - Mensal",
                        type: 2,
                        reference: 7.5,
                        amount: 13.71,
                      },
                    ],
                  },
                ],
              },
              {
                id: "79f3ca4b-cdd0-48c4-8937-baa3de44b473",
                referenceId: "7d573c2e-525e-457a-b913-5d985a977e66",
                establishmentReferenceId:
                  "4114bf59-81ee-43b1-a39f-8a1b6c2e2451",
                code: "000004",
                birthDate: "2000-01-01T02:00:00",
                registrationNumber: "30691307040",
                hiringDate: "2022-01-01T03:00:00",
                name: "Josecleiton",
                payslips: [
                  {
                    id: "56dfcb58-2895-4b1b-9f0f-21d0ad870f53",
                    employeeReferenceId: "7d573c2e-525e-457a-b913-5d985a977e66",
                    paymentDate: "2023-10-01T00:00:00",
                    period: "2023-09-01T00:00:00",
                    type: 1,
                    payslipItems: [
                      {
                        id: "49b9ef7c-25a4-4c2d-85a2-7e7c9460193e",
                        payslipId: "56dfcb58-2895-4b1b-9f0f-21d0ad870f53",
                        code: "1000.001",
                        description: "Salário",
                        type: 1,
                        reference: 30,
                        amount: 6500.0,
                      },
                      {
                        id: "12b4ce4d-96f1-4600-bd81-8fe950fa9482",
                        payslipId: "56dfcb58-2895-4b1b-9f0f-21d0ad870f53",
                        code: "9201.001",
                        description: "INSS - Mensal",
                        type: 2,
                        reference: 14.0,
                        amount: 735.91,
                      },
                      {
                        id: "95e8f947-77a7-45bf-b0fe-958c84863450",
                        payslipId: "56dfcb58-2895-4b1b-9f0f-21d0ad870f53",
                        code: "9203.001",
                        description: "IRRF - Mensal",
                        type: 2,
                        reference: 27.5,
                        amount: 700.16,
                      },
                    ],
                  },
                ],
              },
              {
                id: "fcc5daa8-3daf-4498-a736-584fc90204eb",
                referenceId: "ddbbe249-3048-4b7f-8b2b-d6bdea842521",
                establishmentReferenceId:
                  "4114bf59-81ee-43b1-a39f-8a1b6c2e2451",
                code: "000008",
                birthDate: "2002-02-15T02:00:00",
                registrationNumber: "09954974008",
                hiringDate: "2023-01-01T03:00:00",
                name: "Arlinda da Silva",
                payslips: [
                  {
                    id: "0319d21c-7a2e-494d-8700-30bcdd2aaae6",
                    employeeReferenceId: "ddbbe249-3048-4b7f-8b2b-d6bdea842521",
                    paymentDate: "2023-10-01T00:00:00",
                    period: "2023-09-01T00:00:00",
                    type: 1,
                    payslipItems: [
                      {
                        id: "e976e4ac-50de-42d5-85ab-30a5c1fbd007",
                        payslipId: "0319d21c-7a2e-494d-8700-30bcdd2aaae6",
                        code: "1000.001",
                        description: "Salário",
                        type: 1,
                        reference: 30,
                        amount: 2500.0,
                      },
                      {
                        id: "8adc80a1-7c6c-49f5-bf22-6055c1a982a6",
                        payslipId: "0319d21c-7a2e-494d-8700-30bcdd2aaae6",
                        code: "9201.001",
                        description: "INSS - Mensal",
                        type: 2,
                        reference: 9.0,
                        amount: 205.2,
                      },
                      {
                        id: "245a5e92-ba96-4f5f-9f9b-634f73bea7b7",
                        payslipId: "0319d21c-7a2e-494d-8700-30bcdd2aaae6",
                        code: "9203.001",
                        description: "IRRF - Mensal",
                        type: 2,
                        reference: 7.5,
                        amount: 13.71,
                      },
                    ],
                  },
                ],
              },
              {
                id: "44050157-364f-416b-aa8e-198af7888ea7",
                referenceId: "44050157-364f-416b-aa8e-198af7888ea7",
                establishmentReferenceId:
                  "4114bf59-81ee-43b1-a39f-8a1b6c2e2451",
                code: "000009",
                birthDate: "2000-01-01T02:00:00",
                registrationNumber: "98186690026",
                hiringDate: "2023-01-01T03:00:00",
                name: "Antenor dos santos",
                payslips: [],
              },
              {
                id: "417ab362-06a4-4580-b27d-3d36c9a00e1f",
                referenceId: "417ab362-06a4-4580-b27d-3d36c9a00e1f",
                establishmentReferenceId:
                  "4114bf59-81ee-43b1-a39f-8a1b6c2e2451",
                code: "000010",
                birthDate: "2000-12-12T02:00:00",
                registrationNumber: "66774155030",
                hiringDate: "2023-01-01T03:00:00",
                name: "Mauro batista",
                payslips: [
                  {
                    id: "fcfc45d0-a12d-414e-97c1-99d5e0b99efa",
                    employeeReferenceId: "417ab362-06a4-4580-b27d-3d36c9a00e1f",
                    paymentDate: "2023-10-01T00:00:00",
                    period: "2023-09-01T00:00:00",
                    type: 1,
                    payslipItems: [
                      {
                        id: "24a9512b-6b26-418a-97cb-b31047a46b62",
                        payslipId: "fcfc45d0-a12d-414e-97c1-99d5e0b99efa",
                        code: "1000.001",
                        description: "Salário",
                        type: 1,
                        reference: 30,
                        amount: 5200.0,
                      },
                      {
                        id: "36f3ba7c-f231-42a4-a621-15f24a8134b6",
                        payslipId: "fcfc45d0-a12d-414e-97c1-99d5e0b99efa",
                        code: "9201.001",
                        description: "INSS - Mensal",
                        type: 2,
                        reference: 14.0,
                        amount: 553.91,
                      },
                      {
                        id: "06d67336-9d20-44f3-bcaa-114d593102ce",
                        payslipId: "fcfc45d0-a12d-414e-97c1-99d5e0b99efa",
                        code: "9203.001",
                        description: "IRRF - Mensal",
                        type: 2,
                        reference: 22.5,
                        amount: 393.64,
                      },
                    ],
                  },
                ],
              },
              {
                id: "ff54586f-9e30-49f1-8192-35ce85edf691",
                referenceId: "ff54586f-9e30-49f1-8192-35ce85edf691",
                establishmentReferenceId:
                  "4114bf59-81ee-43b1-a39f-8a1b6c2e2451",
                code: "000012",
                birthDate: "2000-01-01T02:00:00",
                registrationNumber: "08763564041",
                hiringDate: "2023-01-01T03:00:00",
                name: "Gabriel da cruz",
                payslips: [
                  {
                    id: "4a5729ec-9b99-4b5d-8e8a-48cc094cddd6",
                    employeeReferenceId: "ff54586f-9e30-49f1-8192-35ce85edf691",
                    paymentDate: "2023-10-01T00:00:00",
                    period: "2023-09-01T00:00:00",
                    type: 1,
                    payslipItems: [],
                  },
                ],
              },
              {
                id: "340f9e7d-9f9a-4d88-a4d0-549d1ba8f5aa",
                referenceId: "dde14bdc-95bd-49ec-9ec0-6abc3d87612f",
                establishmentReferenceId:
                  "4114bf59-81ee-43b1-a39f-8a1b6c2e2451",
                code: "000013",
                birthDate: "2000-01-01T02:00:00",
                registrationNumber: "79507419020",
                hiringDate: "2022-01-01T03:00:00",
                name: "Gustavo contarato",
                payslips: [
                  {
                    id: "cdc64d2d-05ca-44d2-b8fb-10fd01f654e6",
                    employeeReferenceId: "dde14bdc-95bd-49ec-9ec0-6abc3d87612f",
                    paymentDate: "2023-10-01T00:00:00",
                    period: "2023-09-01T00:00:00",
                    type: 1,
                    payslipItems: [
                      {
                        id: "9c42394d-a577-4be1-b966-50160c2d10c2",
                        payslipId: "cdc64d2d-05ca-44d2-b8fb-10fd01f654e6",
                        code: "1000.001",
                        description: "Salário",
                        type: 1,
                        reference: 30,
                        amount: 3200.0,
                      },
                      {
                        id: "65e4aafa-efd3-405f-87b9-c05173272b07",
                        payslipId: "cdc64d2d-05ca-44d2-b8fb-10fd01f654e6",
                        code: "9201.001",
                        description: "INSS - Mensal",
                        type: 2,
                        reference: 12.0,
                        amount: 287.06,
                      },
                      {
                        id: "a36c620b-9aa0-49a8-b12b-7f3eae817b35",
                        payslipId: "cdc64d2d-05ca-44d2-b8fb-10fd01f654e6",
                        code: "9203.001",
                        description: "IRRF - Mensal",
                        type: 2,
                        reference: 15.0,
                        amount: 66.54,
                      },
                    ],
                  },
                ],
              },
              {
                id: "8f92b78c-1edb-48e6-8604-4ad9abae0ddc",
                referenceId: "8f92b78c-1edb-48e6-8604-4ad9abae0ddc",
                establishmentReferenceId:
                  "4114bf59-81ee-43b1-a39f-8a1b6c2e2451",
                code: "000015",
                birthDate: "2000-08-14T03:00:00",
                registrationNumber: "11304528081",
                hiringDate: "2023-01-01T03:00:00",
                name: "Marcela munhoz",
                payslips: [
                  {
                    id: "3a96f599-2887-4b6f-85ea-cccaf7764313",
                    employeeReferenceId: "8f92b78c-1edb-48e6-8604-4ad9abae0ddc",
                    paymentDate: "2023-10-01T00:00:00",
                    period: "2023-09-01T00:00:00",
                    type: 1,
                    payslipItems: [
                      {
                        id: "7c9a8888-8595-499f-89f3-155254f0de66",
                        payslipId: "3a96f599-2887-4b6f-85ea-cccaf7764313",
                        code: "1350.001",
                        description: "Bolsa de Estudo - Estagiário",
                        type: 1,
                        reference: 30,
                        amount: 1500,
                      },
                    ],
                  },
                ],
              },
              {
                id: "dba19ca0-fa6e-426b-a7e1-9a39b416a404",
                referenceId: "e6e3a658-be0e-4901-a885-18d3e4376c58",
                establishmentReferenceId:
                  "4114bf59-81ee-43b1-a39f-8a1b6c2e2451",
                code: "000016",
                birthDate: "2000-01-01T02:00:00",
                registrationNumber: "21655414097",
                hiringDate: "2022-01-01T03:00:00",
                name: "José pereira",
                payslips: [
                  {
                    id: "b9ca2e10-d55e-4324-b7b1-78ad56fe9fcd",
                    employeeReferenceId: "e6e3a658-be0e-4901-a885-18d3e4376c58",
                    paymentDate: "2023-10-01T00:00:00",
                    period: "2023-09-01T00:00:00",
                    type: 1,
                    payslipItems: [
                      {
                        id: "8802d48d-4d8b-43a7-915b-01d64b7c2018",
                        payslipId: "b9ca2e10-d55e-4324-b7b1-78ad56fe9fcd",
                        code: "1000.001",
                        description: "Salário",
                        type: 1,
                        reference: 30,
                        amount: 5200.0,
                      },
                      {
                        id: "64530beb-421a-4c6a-8f53-0d7b5ad69638",
                        payslipId: "b9ca2e10-d55e-4324-b7b1-78ad56fe9fcd",
                        code: "9201.001",
                        description: "INSS - Mensal",
                        type: 2,
                        reference: 14.0,
                        amount: 553.91,
                      },
                      {
                        id: "7be76d1c-b24f-400c-a0c6-f79a8aa0ebbd",
                        payslipId: "b9ca2e10-d55e-4324-b7b1-78ad56fe9fcd",
                        code: "9203.001",
                        description: "IRRF - Mensal",
                        type: 2,
                        reference: 22.5,
                        amount: 393.64,
                      },
                    ],
                  },
                ],
              },
              {
                id: "408a2669-0f95-47fe-bc8a-79c086aaaeae",
                referenceId: "6c35568b-e611-42b4-867c-e29c9d542235",
                establishmentReferenceId:
                  "4114bf59-81ee-43b1-a39f-8a1b6c2e2451",
                code: "000019",
                birthDate: "2000-01-01T02:00:00",
                registrationNumber: "73507367033",
                hiringDate: "2022-01-01T03:00:00",
                name: "Mauricio de souza",
                payslips: [
                  {
                    id: "f5fddc4f-05bc-4d2c-8384-0aa4f5e1eb57",
                    employeeReferenceId: "6c35568b-e611-42b4-867c-e29c9d542235",
                    paymentDate: "2023-10-01T00:00:00",
                    period: "2023-09-01T00:00:00",
                    type: 1,
                    payslipItems: [
                      {
                        id: "38a3169b-0e32-4fd0-a76f-a1e65a1456ad",
                        payslipId: "f5fddc4f-05bc-4d2c-8384-0aa4f5e1eb57",
                        code: "1000.001",
                        description: "Salário",
                        type: 1,
                        reference: 30,
                        amount: 2600.0,
                      },
                      {
                        id: "03a55dda-ddb3-43c4-a8b0-33b9c759d5eb",
                        payslipId: "f5fddc4f-05bc-4d2c-8384-0aa4f5e1eb57",
                        code: "9201.001",
                        description: "INSS - Mensal",
                        type: 2,
                        reference: 12.0,
                        amount: 215.06,
                      },
                      {
                        id: "416e2e6f-4c9e-4d45-b424-122c5b664565",
                        payslipId: "f5fddc4f-05bc-4d2c-8384-0aa4f5e1eb57",
                        code: "9203.001",
                        description: "IRRF - Mensal",
                        type: 2,
                        reference: 7.5,
                        amount: 20.47,
                      },
                    ],
                  },
                ],
              },
              {
                id: "c12bde4d-ba59-4589-986d-b2507fb65fc6",
                referenceId: "5548d2f8-f9ee-488f-8f53-85477b99c3be",
                establishmentReferenceId:
                  "4114bf59-81ee-43b1-a39f-8a1b6c2e2451",
                code: "000017",
                birthDate: "2000-01-01T02:00:00",
                registrationNumber: "99578952066",
                hiringDate: "2022-01-01T03:00:00",
                name: "Cristopher aparecido",
                payslips: [
                  {
                    id: "b00b84ee-31ac-46d7-a2ef-7c2f915ae951",
                    employeeReferenceId: "5548d2f8-f9ee-488f-8f53-85477b99c3be",
                    paymentDate: "2023-10-01T00:00:00",
                    period: "2023-09-01T00:00:00",
                    type: 1,
                    payslipItems: [
                      {
                        id: "72eefa09-7c6b-448c-b4f3-4eee876d9243",
                        payslipId: "b00b84ee-31ac-46d7-a2ef-7c2f915ae951",
                        code: "1000.001",
                        description: "Salário",
                        type: 1,
                        reference: 30,
                        amount: 5200.0,
                      },
                      {
                        id: "d522d7c7-f76d-4f2a-87fe-686bf8ab49f5",
                        payslipId: "b00b84ee-31ac-46d7-a2ef-7c2f915ae951",
                        code: "9201.001",
                        description: "INSS - Mensal",
                        type: 2,
                        reference: 14.0,
                        amount: 553.91,
                      },
                      {
                        id: "c820208f-c76c-46f3-b4d5-43e1be09329e",
                        payslipId: "b00b84ee-31ac-46d7-a2ef-7c2f915ae951",
                        code: "9203.001",
                        description: "IRRF - Mensal",
                        type: 2,
                        reference: 22.5,
                        amount: 393.64,
                      },
                    ],
                  },
                ],
              },
              {
                id: "afb35220-cf13-4291-ad56-a09357a85333",
                referenceId: "afb35220-cf13-4291-ad56-a09357a85333",
                establishmentReferenceId:
                  "4114bf59-81ee-43b1-a39f-8a1b6c2e2451",
                code: "000018",
                birthDate: "2000-01-15T02:00:00",
                registrationNumber: "62504090099",
                hiringDate: "2022-01-01T03:00:00",
                name: "Vinicius de oliveira",
                payslips: [
                  {
                    id: "8b1ba4ad-d3c2-425f-9b20-e6ef9f1baf28",
                    employeeReferenceId: "afb35220-cf13-4291-ad56-a09357a85333",
                    paymentDate: "2023-10-01T00:00:00",
                    period: "2023-09-01T00:00:00",
                    type: 1,
                    payslipItems: [
                      {
                        id: "e9ca2faf-c5e8-446b-a6c4-1b7c484a4bdc",
                        payslipId: "8b1ba4ad-d3c2-425f-9b20-e6ef9f1baf28",
                        code: "1000.001",
                        description: "Salário",
                        type: 1,
                        reference: 30,
                        amount: 6522.0,
                      },
                      {
                        id: "eb8b650b-c000-4dae-8df8-bf1fd3c62c76",
                        payslipId: "8b1ba4ad-d3c2-425f-9b20-e6ef9f1baf28",
                        code: "9201.001",
                        description: "INSS - Mensal",
                        type: 2,
                        reference: 14.0,
                        amount: 738.99,
                      },
                      {
                        id: "09c7610d-a80b-4a17-b980-813469ff78a0",
                        payslipId: "8b1ba4ad-d3c2-425f-9b20-e6ef9f1baf28",
                        code: "9203.001",
                        description: "IRRF - Mensal",
                        type: 2,
                        reference: 27.5,
                        amount: 705.37,
                      },
                    ],
                  },
                ],
              },
              {
                id: "f0dcde70-6e2f-40d3-86b3-64227b8e16e2",
                referenceId: "f0dcde70-6e2f-40d3-86b3-64227b8e16e2",
                establishmentReferenceId:
                  "4114bf59-81ee-43b1-a39f-8a1b6c2e2451",
                code: "000022",
                birthDate: "2000-01-01T02:00:00",
                registrationNumber: "57396047021",
                hiringDate: "2022-01-01T03:00:00",
                name: "Arnaldo alvarenga",
                payslips: [
                  {
                    id: "287cbd5d-7bf5-40f8-a816-42d4283c87de",
                    employeeReferenceId: "f0dcde70-6e2f-40d3-86b3-64227b8e16e2",
                    paymentDate: "2023-10-01T00:00:00",
                    period: "2023-09-01T00:00:00",
                    type: 1,
                    payslipItems: [
                      {
                        id: "685efe04-525e-46ad-b679-02b181381dd0",
                        payslipId: "287cbd5d-7bf5-40f8-a816-42d4283c87de",
                        code: "1000.001",
                        description: "Salário",
                        type: 1,
                        reference: 30,
                        amount: 2500.0,
                      },
                      {
                        id: "0f74a129-cfbc-457d-955d-52ae3aeea174",
                        payslipId: "287cbd5d-7bf5-40f8-a816-42d4283c87de",
                        code: "9201.001",
                        description: "INSS - Mensal",
                        type: 2,
                        reference: 9.0,
                        amount: 205.2,
                      },
                      {
                        id: "a6643c4a-a097-4695-bb01-1024b795438a",
                        payslipId: "287cbd5d-7bf5-40f8-a816-42d4283c87de",
                        code: "9203.001",
                        description: "IRRF - Mensal",
                        type: 2,
                        reference: 7.5,
                        amount: 13.71,
                      },
                    ],
                  },
                ],
              },
              {
                id: "a0aae267-61cc-4ce9-b159-d7f9c3107374",
                referenceId: "a0aae267-61cc-4ce9-b159-d7f9c3107374",
                establishmentReferenceId:
                  "4114bf59-81ee-43b1-a39f-8a1b6c2e2451",
                code: "000025",
                birthDate: "1999-12-22T02:00:00",
                registrationNumber: "44394349087",
                hiringDate: "2023-01-01T03:00:00",
                name: "Bruno oliveira",
                payslips: [
                  {
                    id: "73b78426-255a-47ba-ad27-33329053c741",
                    employeeReferenceId: "a0aae267-61cc-4ce9-b159-d7f9c3107374",
                    paymentDate: "2023-10-01T00:00:00",
                    period: "2023-09-01T00:00:00",
                    type: 1,
                    payslipItems: [],
                  },
                ],
              },
              {
                id: "a03ef9a5-24be-44f9-a986-13f285448a1f",
                referenceId: "a0792869-8373-4c48-96a3-5503ca3ef8ea",
                establishmentReferenceId:
                  "4114bf59-81ee-43b1-a39f-8a1b6c2e2451",
                code: "000020",
                birthDate: "2000-01-01T02:00:00",
                registrationNumber: "89949245079",
                hiringDate: "2022-01-01T03:00:00",
                name: "Josiane santos",
                payslips: [
                  {
                    id: "b917ce1f-5b81-40ef-b408-c4c64a71bfc6",
                    employeeReferenceId: "a0792869-8373-4c48-96a3-5503ca3ef8ea",
                    paymentDate: "2023-10-01T00:00:00",
                    period: "2023-09-01T00:00:00",
                    type: 1,
                    payslipItems: [
                      {
                        id: "694d3860-5dd7-4eeb-9e21-54984f325639",
                        payslipId: "b917ce1f-5b81-40ef-b408-c4c64a71bfc6",
                        code: "1000.001",
                        description: "Salário",
                        type: 1,
                        reference: 30,
                        amount: 3200.0,
                      },
                      {
                        id: "2d7e94d4-687a-479e-b104-75f300887151",
                        payslipId: "b917ce1f-5b81-40ef-b408-c4c64a71bfc6",
                        code: "9201.001",
                        description: "INSS - Mensal",
                        type: 2,
                        reference: 12.0,
                        amount: 287.06,
                      },
                      {
                        id: "7a4f29d4-d5da-4cba-8518-4ba899a809f7",
                        payslipId: "b917ce1f-5b81-40ef-b408-c4c64a71bfc6",
                        code: "9203.001",
                        description: "IRRF - Mensal",
                        type: 2,
                        reference: 15.0,
                        amount: 66.54,
                      },
                    ],
                  },
                ],
              },
              {
                id: "2cfcfebd-971f-4329-a85f-d0e0c47a0d12",
                referenceId: "2cfcfebd-971f-4329-a85f-d0e0c47a0d12",
                establishmentReferenceId:
                  "4114bf59-81ee-43b1-a39f-8a1b6c2e2451",
                code: "000026",
                birthDate: "2000-08-14T03:00:00",
                registrationNumber: "63366467002",
                hiringDate: "2023-01-01T03:00:00",
                name: "Marcela munhoz",
                payslips: [
                  {
                    id: "f2b8fd17-afd0-48bb-b93c-84a594d8c510",
                    employeeReferenceId: "2cfcfebd-971f-4329-a85f-d0e0c47a0d12",
                    paymentDate: "2023-10-01T00:00:00",
                    period: "2023-09-01T00:00:00",
                    type: 1,
                    payslipItems: [
                      {
                        id: "6d276274-b2ff-41f9-848e-74a641687143",
                        payslipId: "f2b8fd17-afd0-48bb-b93c-84a594d8c510",
                        code: "1000.001",
                        description: "Salário",
                        type: 1,
                        reference: 30,
                        amount: 2500.0,
                      },
                      {
                        id: "72a7f111-2623-4b15-be7b-a2574d808355",
                        payslipId: "f2b8fd17-afd0-48bb-b93c-84a594d8c510",
                        code: "9201.001",
                        description: "INSS - Mensal",
                        type: 2,
                        reference: 9.0,
                        amount: 205.2,
                      },
                      {
                        id: "cead0173-ac33-4f15-b4eb-7f13b6328f8d",
                        payslipId: "f2b8fd17-afd0-48bb-b93c-84a594d8c510",
                        code: "9203.001",
                        description: "IRRF - Mensal",
                        type: 2,
                        reference: 7.5,
                        amount: 13.71,
                      },
                    ],
                  },
                ],
              },
              {
                id: "41261e7f-b916-4d3d-b901-1845f48b30b1",
                referenceId: "41261e7f-b916-4d3d-b901-1845f48b30b1",
                establishmentReferenceId:
                  "4114bf59-81ee-43b1-a39f-8a1b6c2e2451",
                code: "000024",
                birthDate: "2000-01-01T02:00:00",
                registrationNumber: "42930569042",
                hiringDate: "2023-01-01T03:00:00",
                name: "Rita souza",
                payslips: [
                  {
                    id: "eb994481-4df7-48e7-a130-1b1ad48eda1f",
                    employeeReferenceId: "41261e7f-b916-4d3d-b901-1845f48b30b1",
                    paymentDate: "2023-10-01T00:00:00",
                    period: "2023-09-01T00:00:00",
                    type: 1,
                    payslipItems: [
                      {
                        id: "ea5d76bd-ae6d-47c3-bce3-9fcb0db1afad",
                        payslipId: "eb994481-4df7-48e7-a130-1b1ad48eda1f",
                        code: "1000.001",
                        description: "Salário",
                        type: 1,
                        reference: 30,
                        amount: 1200,
                      },
                      {
                        id: "56eb9e9a-9351-4aee-a221-511a23849c28",
                        payslipId: "eb994481-4df7-48e7-a130-1b1ad48eda1f",
                        code: "9201.001",
                        description: "INSS - Mensal",
                        type: 2,
                        reference: 7.5,
                        amount: 90.0,
                      },
                    ],
                  },
                ],
              },
              {
                id: "daae3251-30d9-4130-a110-7b76f57c742f",
                referenceId: "daae3251-30d9-4130-a110-7b76f57c742f",
                establishmentReferenceId:
                  "4114bf59-81ee-43b1-a39f-8a1b6c2e2451",
                code: "000029",
                birthDate: "2000-01-01T02:00:00",
                registrationNumber: "47314055050",
                hiringDate: "2023-01-01T03:00:00",
                name: "Ricardo rodrigues",
                payslips: [
                  {
                    id: "90a409f2-5d2a-4797-8577-df732eee367e",
                    employeeReferenceId: "daae3251-30d9-4130-a110-7b76f57c742f",
                    paymentDate: "2023-10-01T00:00:00",
                    period: "2023-09-01T00:00:00",
                    type: 1,
                    payslipItems: [
                      {
                        id: "6379132b-aaf4-4065-964c-9c1a1b0f19e2",
                        payslipId: "90a409f2-5d2a-4797-8577-df732eee367e",
                        code: "1000.001",
                        description: "Salário",
                        type: 1,
                        reference: 30,
                        amount: 2500.0,
                      },
                      {
                        id: "b05513f8-6542-4792-9ec2-21edc638c8af",
                        payslipId: "90a409f2-5d2a-4797-8577-df732eee367e",
                        code: "9201.001",
                        description: "INSS - Mensal",
                        type: 2,
                        reference: 9.0,
                        amount: 205.2,
                      },
                      {
                        id: "7c8f5580-8670-4bea-ac83-39ff71202f31",
                        payslipId: "90a409f2-5d2a-4797-8577-df732eee367e",
                        code: "9203.001",
                        description: "IRRF - Mensal",
                        type: 2,
                        reference: 7.5,
                        amount: 13.71,
                      },
                    ],
                  },
                ],
              },
              {
                id: "1978c8b5-9559-4416-a25c-7568886da29b",
                referenceId: "8d9bd08e-326d-4811-b365-43707a5bca52",
                establishmentReferenceId:
                  "4114bf59-81ee-43b1-a39f-8a1b6c2e2451",
                code: "000027",
                birthDate: "2000-01-01T02:00:00",
                registrationNumber: "45218429090",
                hiringDate: "2022-01-01T03:00:00",
                name: "Marcelo souza",
                payslips: [
                  {
                    id: "045379e8-67e2-43fa-97a9-94ed457ed2f1",
                    employeeReferenceId: "8d9bd08e-326d-4811-b365-43707a5bca52",
                    paymentDate: "2023-10-01T00:00:00",
                    period: "2023-09-01T00:00:00",
                    type: 1,
                    payslipItems: [
                      {
                        id: "378b796d-8a49-4e4a-9dad-1468892be29f",
                        payslipId: "045379e8-67e2-43fa-97a9-94ed457ed2f1",
                        code: "1000.001",
                        description: "Salário",
                        type: 1,
                        reference: 30,
                        amount: 2500.0,
                      },
                      {
                        id: "9bb4df2f-520b-42cc-af65-f96b8ac55130",
                        payslipId: "045379e8-67e2-43fa-97a9-94ed457ed2f1",
                        code: "9201.001",
                        description: "INSS - Mensal",
                        type: 2,
                        reference: 9.0,
                        amount: 205.2,
                      },
                      {
                        id: "642c167d-c284-4be7-8115-e6814d2e3dae",
                        payslipId: "045379e8-67e2-43fa-97a9-94ed457ed2f1",
                        code: "9203.001",
                        description: "IRRF - Mensal",
                        type: 2,
                        reference: 7.5,
                        amount: 13.71,
                      },
                    ],
                  },
                ],
              },
              {
                id: "118befe4-015b-43b0-94ff-12d513fecc06",
                referenceId: "118befe4-015b-43b0-94ff-12d513fecc06",
                establishmentReferenceId:
                  "4114bf59-81ee-43b1-a39f-8a1b6c2e2451",
                code: "000028",
                birthDate: "2000-10-01T03:00:00",
                registrationNumber: "62841526046",
                hiringDate: "2023-01-01T03:00:00",
                name: "Fernanda Costarelli",
                payslips: [
                  {
                    id: "21405ece-1136-4773-8ae5-345c0c0a26cd",
                    employeeReferenceId: "118befe4-015b-43b0-94ff-12d513fecc06",
                    paymentDate: "2023-10-01T00:00:00",
                    period: "2023-09-01T00:00:00",
                    type: 1,
                    payslipItems: [
                      {
                        id: "a5ab5e8c-3336-4ad9-9acb-513d939ddd75",
                        payslipId: "21405ece-1136-4773-8ae5-345c0c0a26cd",
                        code: "1000.001",
                        description: "Salário",
                        type: 1,
                        reference: 30,
                        amount: 2500.0,
                      },
                      {
                        id: "df3941dc-9bd5-4180-98c3-16c3dbf48fa1",
                        payslipId: "21405ece-1136-4773-8ae5-345c0c0a26cd",
                        code: "9201.001",
                        description: "INSS - Mensal",
                        type: 2,
                        reference: 9.0,
                        amount: 205.2,
                      },
                      {
                        id: "4c8e7be2-2b4c-4e77-914e-a60976d8b9e1",
                        payslipId: "21405ece-1136-4773-8ae5-345c0c0a26cd",
                        code: "9203.001",
                        description: "IRRF - Mensal",
                        type: 2,
                        reference: 7.5,
                        amount: 13.71,
                      },
                    ],
                  },
                ],
              },
              {
                id: "f2b14565-5421-4433-8d60-9886684f4099",
                referenceId: "62ed5857-6754-446f-bde2-45dfcc5e9306",
                establishmentReferenceId:
                  "4114bf59-81ee-43b1-a39f-8a1b6c2e2451",
                code: "000014",
                birthDate: "2000-01-01T02:00:00",
                registrationNumber: "34167142023",
                hiringDate: "2022-01-01T03:00:00",
                name: "João pereira",
                payslips: [],
              },
              {
                id: "c459ddfd-89d8-458d-a74e-c8fd68d1e906",
                referenceId: "c5e3e1b1-5715-478a-9c02-659367f4acec",
                establishmentReferenceId:
                  "4114bf59-81ee-43b1-a39f-8a1b6c2e2451",
                code: "000032",
                birthDate: "2000-01-01T02:00:00",
                registrationNumber: "54880375004",
                hiringDate: "2022-01-01T03:00:00",
                name: "Joselito da Silva",
                payslips: [
                  {
                    id: "4c4730b9-42a2-45d3-b425-31c537d2eae6",
                    employeeReferenceId: "c5e3e1b1-5715-478a-9c02-659367f4acec",
                    paymentDate: "2023-10-01T00:00:00",
                    period: "2023-09-01T00:00:00",
                    type: 1,
                    payslipItems: [
                      {
                        id: "b061db72-70dc-4430-8dbb-932c7a1ef071",
                        payslipId: "4c4730b9-42a2-45d3-b425-31c537d2eae6",
                        code: "1000.001",
                        description: "Salário",
                        type: 1,
                        reference: 30,
                        amount: 3500.0,
                      },
                      {
                        id: "d330b032-900a-4a22-a7fb-3d3f9b89038c",
                        payslipId: "4c4730b9-42a2-45d3-b425-31c537d2eae6",
                        code: "9201.001",
                        description: "INSS - Mensal",
                        type: 2,
                        reference: 12.0,
                        amount: 323.06,
                      },
                      {
                        id: "44917888-254a-4839-827b-8b7fa8b22af5",
                        payslipId: "4c4730b9-42a2-45d3-b425-31c537d2eae6",
                        code: "9203.001",
                        description: "IRRF - Mensal",
                        type: 2,
                        reference: 15.0,
                        amount: 106.14,
                      },
                    ],
                  },
                ],
              },
              {
                id: "cb43a244-eeb0-4ca4-b094-753dcbc54378",
                referenceId: "6976653e-f8df-476f-954e-121a8fa90d61",
                establishmentReferenceId:
                  "4114bf59-81ee-43b1-a39f-8a1b6c2e2451",
                code: "000031",
                birthDate: "2000-01-01T02:00:00",
                registrationNumber: "38928615011",
                hiringDate: "2023-01-01T03:00:00",
                name: "Ronaldo da costa",
                payslips: [],
              },
              {
                id: "bfc31619-b89e-4608-99b2-58dd1866d38a",
                referenceId: "bfc31619-b89e-4608-99b2-58dd1866d38a",
                establishmentReferenceId:
                  "4114bf59-81ee-43b1-a39f-8a1b6c2e2451",
                code: "000033",
                birthDate: "2000-01-01T02:00:00",
                registrationNumber: "74304358073",
                hiringDate: "2023-01-01T03:00:00",
                name: "Cristian astelfolli",
                payslips: [
                  {
                    id: "ef4f0322-8480-45af-b906-831ac5e4d159",
                    employeeReferenceId: "bfc31619-b89e-4608-99b2-58dd1866d38a",
                    paymentDate: "2023-10-01T00:00:00",
                    period: "2023-09-01T00:00:00",
                    type: 1,
                    payslipItems: [
                      {
                        id: "d70c1f45-c4eb-4c96-8f05-ae3f7869abd7",
                        payslipId: "ef4f0322-8480-45af-b906-831ac5e4d159",
                        code: "1000.001",
                        description: "Salário",
                        type: 1,
                        reference: 30,
                        amount: 2500.0,
                      },
                      {
                        id: "7fabcc5c-9b28-465b-b9d7-f134f5f82272",
                        payslipId: "ef4f0322-8480-45af-b906-831ac5e4d159",
                        code: "9201.001",
                        description: "INSS - Mensal",
                        type: 2,
                        reference: 9.0,
                        amount: 205.2,
                      },
                      {
                        id: "a008e6c0-3078-499e-bdbf-db22d92edb26",
                        payslipId: "ef4f0322-8480-45af-b906-831ac5e4d159",
                        code: "9203.001",
                        description: "IRRF - Mensal",
                        type: 2,
                        reference: 7.5,
                        amount: 13.71,
                      },
                    ],
                  },
                ],
              },
              {
                id: "c7d3dfa2-334e-4961-a45e-4dc833a826a1",
                referenceId: "c7d3dfa2-334e-4961-a45e-4dc833a826a1",
                establishmentReferenceId:
                  "4114bf59-81ee-43b1-a39f-8a1b6c2e2451",
                code: "000036",
                birthDate: "2000-01-01T02:00:00",
                registrationNumber: "91954694008",
                hiringDate: "2023-01-01T03:00:00",
                name: "Kelvin osvaldo pereira",
                payslips: [
                  {
                    id: "82e1f55b-c7d7-45de-807f-aa989b736bc4",
                    employeeReferenceId: "c7d3dfa2-334e-4961-a45e-4dc833a826a1",
                    paymentDate: "2023-10-01T00:00:00",
                    period: "2023-09-01T00:00:00",
                    type: 1,
                    payslipItems: [
                      {
                        id: "975a0d11-cc5c-4797-948b-02611fc4e897",
                        payslipId: "82e1f55b-c7d7-45de-807f-aa989b736bc4",
                        code: "1000.001",
                        description: "Salário",
                        type: 1,
                        reference: 30,
                        amount: 6520.0,
                      },
                      {
                        id: "8030ca82-cebf-4bae-a21f-1a0daafd1a57",
                        payslipId: "82e1f55b-c7d7-45de-807f-aa989b736bc4",
                        code: "9201.001",
                        description: "INSS - Mensal",
                        type: 2,
                        reference: 14.0,
                        amount: 738.71,
                      },
                      {
                        id: "bb1c1d2e-19f8-4f8d-a572-f8037eb241d0",
                        payslipId: "82e1f55b-c7d7-45de-807f-aa989b736bc4",
                        code: "9203.001",
                        description: "IRRF - Mensal",
                        type: 2,
                        reference: 27.5,
                        amount: 704.89,
                      },
                    ],
                  },
                ],
              },
              {
                id: "0339ab0f-ee2b-4e2b-8a05-81c93361e5a3",
                referenceId: "be0b9ffc-7d5c-4af7-a431-e2da923fbc1a",
                establishmentReferenceId:
                  "4114bf59-81ee-43b1-a39f-8a1b6c2e2451",
                code: "000021",
                birthDate: "2000-01-01T02:00:00",
                registrationNumber: "43734593042",
                hiringDate: "2022-01-01T03:00:00",
                name: "Kiara christofolli",
                payslips: [
                  {
                    id: "f07ab79f-85c7-4470-9cf5-a26044c6de6e",
                    employeeReferenceId: "be0b9ffc-7d5c-4af7-a431-e2da923fbc1a",
                    paymentDate: "2023-09-16T00:00:00",
                    period: "2023-09-01T00:00:00",
                    type: 2,
                    payslipItems: [
                      {
                        id: "0c73c1f7-9b3a-4cde-88c4-42399d2196e3",
                        payslipId: "f07ab79f-85c7-4470-9cf5-a26044c6de6e",
                        code: "5501.001",
                        description: "Adiantamento de Salário",
                        type: 1,
                        reference: 40.0,
                        amount: 500.0,
                      },
                      {
                        id: "d977965f-a838-4076-a1be-e077e285a6e3",
                        payslipId: "f07ab79f-85c7-4470-9cf5-a26044c6de6e",
                        code: "9203.002",
                        description: "IRRF - Adiantamento",
                        type: 2,
                        reference: 7.5,
                        amount: 37.5,
                      },
                    ],
                  },
                  {
                    id: "adb8a876-2498-4ed5-b2c6-b058504d3473",
                    employeeReferenceId: "be0b9ffc-7d5c-4af7-a431-e2da923fbc1a",
                    paymentDate: "2023-10-01T00:00:00",
                    period: "2023-09-01T00:00:00",
                    type: 1,
                    payslipItems: [
                      {
                        id: "2ebd16db-66ab-4e4f-9baa-c228bd661639",
                        payslipId: "adb8a876-2498-4ed5-b2c6-b058504d3473",
                        code: "1000.001",
                        description: "Salário",
                        type: 1,
                        reference: 15,
                        amount: 1250.0,
                      },
                      {
                        id: "bafbb150-d0c8-4b0e-93a7-83cf821aa2fe",
                        payslipId: "adb8a876-2498-4ed5-b2c6-b058504d3473",
                        code: "9200.001",
                        description: "Desconto de Adiantamento",
                        type: 2,
                        reference: 40.0,
                        amount: 500.0,
                      },
                      {
                        id: "ba0ca2fd-d0b6-45e5-b122-e033b2ce3b87",
                        payslipId: "adb8a876-2498-4ed5-b2c6-b058504d3473",
                        code: "1016.004",
                        description: "Férias no mês",
                        type: 1,
                        reference: 15,
                        amount: 1250.0,
                      },
                      {
                        id: "22f3768f-9b4a-4781-8a08-9a23b422fa92",
                        payslipId: "adb8a876-2498-4ed5-b2c6-b058504d3473",
                        code: "1017.004",
                        description: "1/3 de Férias no mês",
                        type: 1,
                        reference: 0,
                        amount: 416.67,
                      },
                      {
                        id: "64f9d41a-c901-4c06-b0c8-7aba8556e2dc",
                        payslipId: "adb8a876-2498-4ed5-b2c6-b058504d3473",
                        code: "9205.002",
                        description: "INSS Férias no mês",
                        type: 2,
                        reference: 9.0,
                        amount: 130.2,
                      },
                      {
                        id: "b7a92135-5e8f-41d2-aef1-006ef83b8e8b",
                        payslipId: "adb8a876-2498-4ed5-b2c6-b058504d3473",
                        code: "9221.001",
                        description: "Desconto de Férias Recebidas",
                        type: 2,
                        reference: 0,
                        amount: 1536.47,
                      },
                      {
                        id: "e797af41-cbc0-458f-8272-7272b9eb672a",
                        payslipId: "adb8a876-2498-4ed5-b2c6-b058504d3473",
                        code: "9201.001",
                        description: "INSS - Mensal",
                        type: 2,
                        reference: 12.0,
                        amount: 122.85,
                      },
                    ],
                  },
                  {
                    id: "e5884cbe-20a7-45fe-b61c-c7149127585f",
                    employeeReferenceId: "be0b9ffc-7d5c-4af7-a431-e2da923fbc1a",
                    paymentDate: "2023-09-30T03:00:00",
                    period: "2023-09-01T00:00:00",
                    type: 1,
                    payslipItems: [
                      {
                        id: "436b8fae-562b-47cb-8e23-9d2964ab8984",
                        payslipId: "e5884cbe-20a7-45fe-b61c-c7149127585f",
                        code: "6000.001",
                        description: "Saldo de Salários",
                        type: 1,
                        reference: 5,
                        amount: 416.67,
                      },
                      {
                        id: "a2278383-9341-4db0-9f45-1ec3b7edca46",
                        payslipId: "e5884cbe-20a7-45fe-b61c-c7149127585f",
                        code: "6001.001",
                        description:
                          "13º Salário sobre Aviso Prévio Indenizado",
                        type: 1,
                        reference: 1,
                        amount: 208.33,
                      },
                      {
                        id: "67dc8cf5-1277-40b3-95b2-ff44c73d3854",
                        payslipId: "e5884cbe-20a7-45fe-b61c-c7149127585f",
                        code: "6002.001",
                        description: "13º Salário Proporcional",
                        type: 1,
                        reference: 9,
                        amount: 1875.0,
                      },
                      {
                        id: "e39d3f40-2291-416a-b1f6-8ba4f2a1ca31",
                        payslipId: "e5884cbe-20a7-45fe-b61c-c7149127585f",
                        code: "6003.001",
                        description: "Aviso Prévio Indenizado",
                        type: 1,
                        reference: 33,
                        amount: 2750.0,
                      },
                      {
                        id: "b0ebb027-ba94-419d-abcc-cceecc5b700c",
                        payslipId: "e5884cbe-20a7-45fe-b61c-c7149127585f",
                        code: "6006.003",
                        description: "Férias sobre aviso prévio indenizado",
                        type: 1,
                        reference: 1,
                        amount: 208.33,
                      },
                      {
                        id: "0c5fb01a-8c5a-43e2-8424-1fd6dd4a3b57",
                        payslipId: "e5884cbe-20a7-45fe-b61c-c7149127585f",
                        code: "6006.004",
                        description:
                          "1/3 de Férias sobre aviso prévio indenizado ",
                        type: 1,
                        reference: 0,
                        amount: 69.44,
                      },
                      {
                        id: "af5eaf08-42ef-42a5-aec6-11a00a304acf",
                        payslipId: "e5884cbe-20a7-45fe-b61c-c7149127585f",
                        code: "6007.001",
                        description: "Férias Vencidas Indenizadas",
                        type: 1,
                        reference: 12,
                        amount: 2500.0,
                      },
                      {
                        id: "03f3b724-59d9-4958-a472-85143c9df0af",
                        payslipId: "e5884cbe-20a7-45fe-b61c-c7149127585f",
                        code: "6007.002",
                        description: "1/3 de Férias Vencidas Ind.",
                        type: 1,
                        reference: 0,
                        amount: 833.33,
                      },
                      {
                        id: "95300541-15ab-48aa-a7c2-dbc5de7bb518",
                        payslipId: "e5884cbe-20a7-45fe-b61c-c7149127585f",
                        code: "9200.001",
                        description: "Desconto de Adiantamento",
                        type: 2,
                        reference: 40.0,
                        amount: 500.0,
                      },
                      {
                        id: "3a642978-f187-4e96-85ee-c8f8cc37ff45",
                        payslipId: "e5884cbe-20a7-45fe-b61c-c7149127585f",
                        code: "9201.001",
                        description: "INSS - Mensal",
                        type: 2,
                        reference: 7.5,
                        amount: 31.25,
                      },
                      {
                        id: "a55cdabc-eee0-407a-b256-f295482658ec",
                        payslipId: "e5884cbe-20a7-45fe-b61c-c7149127585f",
                        code: "9201.003",
                        description: "INSS - 13º Salário",
                        type: 2,
                        reference: 9,
                        amount: 167.7,
                      },
                      {
                        id: "d83eb484-631b-4f5d-ad4d-9dd64203de79",
                        payslipId: "e5884cbe-20a7-45fe-b61c-c7149127585f",
                        code: "9989.001",
                        description: "Líquido na Rescisão",
                        type: 4,
                        reference: 0,
                        amount: 8162.15,
                      },
                    ],
                  },
                ],
              },
              {
                id: "f5809244-250c-47ff-a523-ed1f71415a40",
                referenceId: "f5809244-250c-47ff-a523-ed1f71415a40",
                establishmentReferenceId:
                  "4114bf59-81ee-43b1-a39f-8a1b6c2e2451",
                code: "000037",
                birthDate: "2000-01-01T02:00:00",
                registrationNumber: "21923322036",
                hiringDate: "2023-01-01T03:00:00",
                name: "Arlindo batista",
                payslips: [
                  {
                    id: "ad942732-4a6f-4024-bf4f-ece4fbc6f181",
                    employeeReferenceId: "f5809244-250c-47ff-a523-ed1f71415a40",
                    paymentDate: "2023-10-01T00:00:00",
                    period: "2023-09-01T00:00:00",
                    type: 1,
                    payslipItems: [
                      {
                        id: "fd7f4b84-9b3d-451b-ac1a-85a03dc56dab",
                        payslipId: "ad942732-4a6f-4024-bf4f-ece4fbc6f181",
                        code: "1000.001",
                        description: "Salário",
                        type: 1,
                        reference: 30,
                        amount: 6532.0,
                      },
                      {
                        id: "f866b8cc-9aaa-4476-9477-9ec13555ec75",
                        payslipId: "ad942732-4a6f-4024-bf4f-ece4fbc6f181",
                        code: "9201.001",
                        description: "INSS - Mensal",
                        type: 2,
                        reference: 14.0,
                        amount: 740.39,
                      },
                      {
                        id: "e5a93a67-fdb1-4fd5-a735-5b2b162e4713",
                        payslipId: "ad942732-4a6f-4024-bf4f-ece4fbc6f181",
                        code: "9203.001",
                        description: "IRRF - Mensal",
                        type: 2,
                        reference: 27.5,
                        amount: 707.73,
                      },
                    ],
                  },
                ],
              },
              {
                id: "3e264114-a31b-488b-93c5-3edb35d044a1",
                referenceId: "b5440aec-6da0-4036-a4d6-852db952cddf",
                establishmentReferenceId:
                  "4114bf59-81ee-43b1-a39f-8a1b6c2e2451",
                code: "000030",
                birthDate: "2000-01-01T02:00:00",
                registrationNumber: "09746004077",
                hiringDate: "2023-01-01T03:00:00",
                name: "Sebastião da Silva",
                payslips: [],
              },
              {
                id: "3746271a-b57f-40c9-a6ec-22f4a91e271b",
                referenceId: "638f8f84-1207-4ec5-a0c7-93e1893d6fd6",
                establishmentReferenceId:
                  "4114bf59-81ee-43b1-a39f-8a1b6c2e2451",
                code: "000023",
                birthDate: "2000-08-14T03:00:00",
                registrationNumber: "79512045010",
                hiringDate: "2023-01-01T03:00:00",
                name: "Pedro Henrique",
                payslips: [
                  {
                    id: "d0136f82-59d2-4c38-bdcf-045bcfa895c9",
                    employeeReferenceId: "638f8f84-1207-4ec5-a0c7-93e1893d6fd6",
                    paymentDate: "2023-10-01T00:00:00",
                    period: "2023-09-01T00:00:00",
                    type: 1,
                    payslipItems: [
                      {
                        id: "f5173e4a-d2f0-474b-b8d9-13af485557e4",
                        payslipId: "d0136f82-59d2-4c38-bdcf-045bcfa895c9",
                        code: "1000.001",
                        description: "Salário",
                        type: 1,
                        reference: 30,
                        amount: 1800,
                      },
                      {
                        id: "21a93b82-d9aa-483b-a5f0-a81451ed2e64",
                        payslipId: "d0136f82-59d2-4c38-bdcf-045bcfa895c9",
                        code: "9201.001",
                        description: "INSS - Mensal",
                        type: 2,
                        reference: 9.0,
                        amount: 142.2,
                      },
                    ],
                  },
                ],
              },
              {
                id: "661b5360-36d7-4845-9bcb-218dd4c39e0f",
                referenceId: "661b5360-36d7-4845-9bcb-218dd4c39e0f",
                establishmentReferenceId:
                  "4114bf59-81ee-43b1-a39f-8a1b6c2e2451",
                code: "000038",
                birthDate: "2000-01-01T02:00:00",
                registrationNumber: "93888058015",
                hiringDate: "2023-01-01T03:00:00",
                name: "Marcio gonçalves",
                payslips: [
                  {
                    id: "75353d51-a2ec-4d73-ade1-1f14a4272a9b",
                    employeeReferenceId: "661b5360-36d7-4845-9bcb-218dd4c39e0f",
                    paymentDate: "2023-10-01T00:00:00",
                    period: "2023-09-01T00:00:00",
                    type: 1,
                    payslipItems: [
                      {
                        id: "05bb97c0-c1c5-464d-ae60-0d586c61bfe2",
                        payslipId: "75353d51-a2ec-4d73-ade1-1f14a4272a9b",
                        code: "1000.001",
                        description: "Salário",
                        type: 1,
                        reference: 30,
                        amount: 2500.0,
                      },
                      {
                        id: "4ea285f5-e7f1-496b-af2a-fc0a1e96253f",
                        payslipId: "75353d51-a2ec-4d73-ade1-1f14a4272a9b",
                        code: "9201.001",
                        description: "INSS - Mensal",
                        type: 2,
                        reference: 9.0,
                        amount: 205.2,
                      },
                      {
                        id: "2d58df34-7a3c-4a91-af1a-e4585651510b",
                        payslipId: "75353d51-a2ec-4d73-ade1-1f14a4272a9b",
                        code: "9203.001",
                        description: "IRRF - Mensal",
                        type: 2,
                        reference: 7.5,
                        amount: 13.71,
                      },
                    ],
                  },
                ],
              },
              {
                id: "50a4fe68-c59d-4c0e-922b-83522215f478",
                referenceId: "50a4fe68-c59d-4c0e-922b-83522215f478",
                establishmentReferenceId:
                  "4114bf59-81ee-43b1-a39f-8a1b6c2e2451",
                code: "000034",
                birthDate: "2000-01-01T02:00:00",
                registrationNumber: "50356063097",
                hiringDate: "2023-01-01T03:00:00",
                name: "Josivaldo da Silva",
                payslips: [],
              },
              {
                id: "99ab8f31-eebd-46e4-b6d3-aef6bd5464a5",
                referenceId: "99ab8f31-eebd-46e4-b6d3-aef6bd5464a5",
                establishmentReferenceId:
                  "4114bf59-81ee-43b1-a39f-8a1b6c2e2451",
                code: "000035",
                birthDate: "2000-01-01T02:00:00",
                registrationNumber: "28557950012",
                hiringDate: "2023-01-01T03:00:00",
                name: "Astolfo pinto",
                payslips: [
                  {
                    id: "2e22a52f-29eb-49f2-8e94-d7586284be04",
                    employeeReferenceId: "99ab8f31-eebd-46e4-b6d3-aef6bd5464a5",
                    paymentDate: "2023-10-01T00:00:00",
                    period: "2023-09-01T00:00:00",
                    type: 1,
                    payslipItems: [
                      {
                        id: "b82def4b-9f86-4500-81c5-8197f86a49c6",
                        payslipId: "2e22a52f-29eb-49f2-8e94-d7586284be04",
                        code: "1350.001",
                        description: "Bolsa de Estudo - Estagiário",
                        type: 1,
                        reference: 30,
                        amount: 5200.0,
                      },
                      {
                        id: "c044b67a-3cd9-4e7c-8238-c2ee9a411453",
                        payslipId: "2e22a52f-29eb-49f2-8e94-d7586284be04",
                        code: "9203.001",
                        description: "IRRF - Mensal",
                        type: 2,
                        reference: 27.5,
                        amount: 545.04,
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  };

  try {
    let text =
      "Nome;Data de nascimento;Data Contratação;CPF/CNPJ;Período;Código Rubrica;Descrição rubrica;Valor em reais\n";
    const employees = data["companies"][0]["establishments"][0]["employees"];

    for (let employee of employees) {
      for (let payslip of employee.payslips) {
        for (let item of payslip.payslipItems) {
          text +=
            employee.name +
            ";" +
            employee.birthDate +
            ";" +
            employee.hiringDate +
            ";" +
            employee.registrationNumber +
            ";" +
            payslip.period +
            ";" +
            item.rubricCode +
            ";" +
            item.rubricDescription +
            ";" +
            item.amount +
            "\n";
        }
      }
    }

    return text;
  } catch (err) {
    return null;
  }
}
