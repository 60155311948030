import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  InputAdornment,
  InputLabel,
  Modal,
  OutlinedInput,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import { Search } from "@material-ui/icons";
import { CloseIconStyled } from "./styles/NFSeCSS";
import SyncAltIcon from "@material-ui/icons/SyncAlt";
import Loading from "../../basics/Loading";
import { DialogStyle } from "../../modal/Modal";

export const DialogUsersLogin = (props) => {
  function formatCNPJorCPF(document) {
    return document.length === 14
      ? document.replace(
          /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
          "$1.$2.$3/$4-$5"
        )
      : document.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
  }
  return (
    <>
      {props.loading ? (
        <Loading open={props.loading} />
      ) : (
        <Modal
          open={props.openModal}
          onClose={() => {
            props.closeModal();
          }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogStyle>
            <DialogTitle id="alert-dialog-title">
              <Grid container>
                <Grid
                  item
                  xs={6}
                  style={{ marginTop: "9px", fontFamily: "Roboto" }}
                >
                  {props.title}
                </Grid>
                <Grid item xs={6}>
                  <FormControl
                    fullWidth
                    sx={{ m: 1 }}
                    variant="outlined"
                    size="small"
                    style={{ marginTop: "7px", marginRight: "20px" }}
                  >
                    <InputLabel>Pesquisar</InputLabel>
                    <OutlinedInput
                      endAdornment={
                        <InputAdornment position="end">
                          <Search style={{ color: "grey" }} />
                        </InputAdornment>
                      }
                      label="Pesquisar"
                      style={{ marginRight: "20px" }}
                      onChange={props.searchItens}
                    />
                  </FormControl>
                </Grid>
                <CloseIconStyled
                  style={{ marginRight: "6px" }}
                  onClick={() => {
                    props.closeModal();
                  }}
                />
              </Grid>
            </DialogTitle>

            <DialogContent
              dividers
              style={{ height: "450px", overflowY: "scroll" }}
            >
              <RadioGroup
                aria-label="quiz"
                name="quiz"
                value={props.valueChecked}
                onChange={props.handleRadioChange}
              >
                {props.filteredRows
                  .sort((a, b) => a.trading_name.localeCompare(b.trading_name))
                  .map((company) => (
                    <FormControlLabel
                      key={company.id}
                      value={company.id}
                      control={
                        <Radio
                          size="small"
                          checked={parseInt(props.valueChecked) === company.id}
                          color="primary"
                        />
                      }
                      label={
                        <div>
                          <span
                            style={{ fontFamily: "Roboto", marginTop: "20px" }}
                          >
                            {company.trading_name}
                          </span>
                          <br />
                          <span
                            style={{
                              fontSize: "12px",
                              color: "grey",
                              fontFamily: "Roboto",
                            }}
                          >
                            {formatCNPJorCPF(company.document)}
                          </span>
                        </div>
                      }
                    />
                  ))}
              </RadioGroup>
            </DialogContent>
            <DialogActions>
              <Button
                endIcon={<SyncAltIcon />}
                style={{
                  background: props.loading ? "grey" : "rgb(19, 142, 247)",
                  color: "white",
                  fontWeight: "bold",
                  width: 150,
                  float: "right",
                  right: 10,
                }}
                onClick={() => {
                  props.buttonConfirm();
                }}
                disabled={props.loading}
              >
                {props.loading ? "Carregando..." : "Próximo"}
              </Button>
            </DialogActions>
          </DialogStyle>
        </Modal>
      )}
    </>
  );
};
