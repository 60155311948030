import {
  DialogActions,
  DialogContent,
  Divider,
  Grid,
  IconButton,
  Modal,
  Typography,
} from "@material-ui/core";
import Dropzone from "react-dropzone";
import { useContext, useState } from "react";

import CloseIcon from "@material-ui/icons/Close";
import { GlobalContext } from "../../../contexts/GlobalContext";
import { usePortalAsClient } from "../../../utils/viewPortalAsClientHook";
import {
  ButtonCancel,
  ButtonSave,
  CloseIconStyle,
  DialogHeader,
  DialogStyle,
} from "../../modal/Modal";
import SubdirectoryArrowLeftIcon from "@material-ui/icons/SubdirectoryArrowLeft";
import FileIcon from "./IconUploadDocuments";

export const DialogUploadDocument = ({
  handleFile,
  BotContext,
  botRef,
  closeBotChatModal,
  closeZipModal,
  ...props
}) => {
  const [fileList, setFileList] = useState([]);
  const { isInClientView, clientViewed } = usePortalAsClient();

  const { companyUser, userLogged } = useContext(GlobalContext);
  const { token } = userLogged;

  return (
    <>
      <Modal
        open={props.openModal}
        onClose={() => {
          setFileList([]);

          props.closeModal();
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogStyle>
          <DialogHeader id="alert-dialog-title">
            <div className="row">
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginBottom: 20,
                }}
              >
                {props.title}
              </div>
              <CloseIconStyle
                style={{ marginRight: "6px" }}
                onClick={() => {
                  setFileList([]);

                  props.closeModal();
                }}
              />
            </div>
          </DialogHeader>

          <DialogContent
            style={{
              height: fileList.length !== 0 ? "450px" : "",
              overflowY: "scroll",
            }}
          >
            <Grid container>
              <Grid item xs={12}>
                <Typography style={{ paddingTop: 4, paddingBottom: 30 }}>
                  Escolha um ou mais arquivos (PDF, imagem, certificado digital,
                  TXT, XML) para enviar para o Qyon Bot.
                </Typography>
              </Grid>
            </Grid>

            <Grid container>
              <Grid item xs={12} direction="row" justifyContent="center">
                <Dropzone
                  onDrop={(acceptedFiles) => {
                    let newFileList = [...fileList, ...acceptedFiles];
                    setFileList(newFileList);
                  }}
                >
                  {({ getRootProps, getInputProps }) => (
                    <div
                      {...getRootProps()}
                      style={{
                        borderStyle: "dashed",
                        borderColor: "#e0e0e0",
                        borderRadius: "5px",
                        minHeight: fileList.length !== 0 ? "25em" : "10em",
                      }}
                    >
                      <input {...getInputProps()} />
                      <p
                        style={{
                          textAlign: "center",
                          fontSize: 18,
                          color: "#656565",
                          marginTop: 10,
                        }}
                      >
                        Arraste e solte seu arquivo ou clique para selecionar!
                      </p>

                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="70"
                          height="70"
                          viewBox="0 0 81 80"
                          fill="none"
                        >
                          <path
                            d="M40.6667 12.583C35.0849 12.583 30.1461 14.3583 25.884 17.9004C21.7667 21.3223 19.0996 25.7061 17.8886 31.0215C13.6143 31.8011 10.0792 33.8557 7.31771 37.1751C4.44213 40.6316 3 44.64 3 49.1663C3 54.1699 4.81345 58.4916 8.41074 62.0889C12.008 65.6862 16.3297 67.4997 21.3333 67.4997H33.8333V60.833H21.3333C18.1054 60.833 15.3721 59.6873 13.0871 57.3726C10.7991 55.0548 9.66667 52.3062 9.66667 49.083C9.66667 45.7355 10.7824 42.9633 13.0093 40.7105C15.2393 38.4546 17.9415 37.333 21.1667 37.333H23.5833V36.4997C23.5833 31.7677 25.1965 27.7241 28.4366 24.3246C31.667 20.9353 35.6151 19.2497 40.3333 19.2497C45.0514 19.2497 48.9843 20.9351 52.1861 24.3221C55.3992 27.7212 57 31.7656 57 36.4997V44.333H63.0833C65.3609 44.333 67.2883 45.1331 68.9107 46.7556C70.5332 48.3781 71.3333 50.3054 71.3333 52.583C71.3333 54.8606 70.5332 56.7879 68.9107 58.4104C67.2883 60.0329 65.3609 60.833 63.0833 60.833H43.8333V42.1782L49.9167 48.2615L54.6785 43.4997L40.5 29.3212L26.3215 43.4997L31.0833 48.2615L37.1667 42.1782V61.6663C37.1667 63.2351 37.765 64.6098 38.9107 65.7556C40.0565 66.9014 41.4312 67.4997 43 67.4997H63.0833C67.1437 67.4997 70.6595 66.0187 73.5893 63.0889C76.519 60.1592 78 56.6434 78 52.583C78 48.2621 76.5307 44.6385 73.5802 41.7765C70.8366 39.1152 67.5167 37.7439 63.6667 37.6635V36.4997C63.6667 29.9591 61.4641 24.3233 57.0659 19.6377C52.6565 14.94 47.1718 12.583 40.6667 12.583Z"
                            fill="#0036B3"
                          />
                        </svg>
                      </div>

                      <div style={{ marginTop: 10 }}>
                        <Divider
                          variant="middle"
                          style={{
                            marginTop: 10,
                            marginLeft: 1,
                            marginRight: 1,
                          }}
                        />

                        {fileList.map((file, index) => (
                          <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            alignContent="center"
                            spacing={1}
                            style={{ marginBottom: 15, marginTop: 15 }}
                          >
                            <Grid
                              item
                              xs={12}
                              style={{ paddingRight: 24, paddingLeft: 24 }}
                            >
                              <div
                                style={{
                                  border: "1px solid #cfd8dc",
                                  display: "flex",
                                  borderRadius: 8,
                                  padding: "5px 16px",
                                }}
                              >
                                <Grid
                                  container
                                  direction="row"
                                  style={{ marginTop: 6 }}
                                >
                                  <FileIcon fileName={file.name} />

                                  <Grid item xs={10}>
                                    <div
                                      style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        paddingLeft: 10,
                                      }}
                                    >
                                      <div>
                                        Arquivos{" "}
                                        {file.name.length < 40
                                          ? file.name
                                          : file.name.substring(0, 40) + "..."}
                                      </div>
                                      <div
                                        style={{
                                          color: "#656565",
                                          fontSize: "12px",
                                        }}
                                      >
                                        Status: Arquivo pronto para envio
                                      </div>
                                    </div>
                                  </Grid>

                                  <Grid item xs={1}>
                                    <IconButton
                                      aria-label="delete"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        let newFileList = [...fileList];
                                        newFileList.splice(index, 1);
                                        setFileList(newFileList);
                                      }}
                                    >
                                      <CloseIcon />
                                    </IconButton>
                                  </Grid>
                                </Grid>
                              </div>
                            </Grid>
                          </Grid>
                        ))}
                      </div>
                    </div>
                  )}
                </Dropzone>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions style={{ paddingTop: 24, paddingBottom: 24 }}>
            <ButtonCancel
              style={{
                marginRight: 24,
                float: "right",
              }}
              onClick={() => {
                setFileList([]);
                props.closeModal();
              }}
              startIcon={<SubdirectoryArrowLeftIcon />}
            >
              {"Cancelar"}
            </ButtonCancel>
            <ButtonSave
              style={{
                float: "right",
                right: 14,
              }}
              onClick={() => {
                handleFile(
                  fileList,
                  botRef,
                  companyUser,
                  token,
                  isInClientView,
                  clientViewed,
                  closeZipModal,
                  closeBotChatModal,
                  BotContext
                );
                setFileList([]);

                props.closeModal();
              }}
            >
              {"Enviar"}
            </ButtonSave>
          </DialogActions>
        </DialogStyle>
      </Modal>
    </>
  );
};
