import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";

import styled from "styled-components";

const bluePrimary = (props) => props.theme.palette.bluePrimary;
const gray = (props) => props.theme.palette.gray;

export const AvatarStyled = styled(Avatar)`
  cursor: pointer;
  margin: 8px;
  background-color: ${bluePrimary} !important;
  color: ${gray} !important;
`;

export const LoginHeader = styled.div`
  color: #364461;
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 8px;
  text-align: center;
`;

export const LoginHeaderSecondary = styled.div`
  color: #0036b3;
  font-size: 32px;
  font-weight: 800;
  margin-bottom: 20px;
  text-align: center;
`;

export const LoginLabel = styled.div`
  color: #364461;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 5px;
  margin-top: 10px;
`;

export const LoginTextField = styled(TextField)`
  border: 1px solid #63708a;
  border-radius: 5px;
  font-size: 14px;
`;

export const FormLogin = styled.form`
  width: 560px;
  padding: 50px;
  padding-top: 30px;
  padding-bottom: 30px;
  background: white;
  border-radius: 40px;
  box-shadow: 0px 4px 24px 0px #1355ff66;
`;

export const SelectCompanyLogin = styled.div`
  width: 560px;
  padding: 50px;
  padding-top: 30px;
  padding-bottom: 30px;
  background: white;
  border-radius: 40px;
  box-shadow: 0px 4px 24px 0px #1355ff66;
`;

export const LoginButton = styled(Button)`
  width: 100%;
  font-weight: 600;
  height: 48px;
  background: linear-gradient(135deg, #007fff 0%, #0036b3 100%);
  text-transform: capitalize;
`;

export const PrincipalContainer = styled.div`
  display: flex;
  height: 100vh;
  background: rgba(71, 153, 238, 0.2);
`;

export const LoginContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 600px) {
    padding: 16px;
  }
`;

export const SideContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: #03226a;
  padding-top: 5px;
  padding-right: 5px;
  @media (max-width: 1190px) {
    display: none;
  }
`;

export const BlueRectangleContainer = styled.div`
  padding-left: 62px;
  padding-right: 62px;
  position: relative;
  top: -60px;
`;

export const QyonText = styled.div`
  padding-left: 62px;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-right: 62px;
  width: 350px;
  font-weight: 700;
  font-size: 30px;
  color: white;
  position: relative;
  top: -60px;
`;

export const StartDotRectangle = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-start;
  align-items: start;
`;

export const IconsRectangle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 62px;
  position: relative;
  top: -60px;
`;

export const EndDotRectangle = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-items: end;
`;

export const Copyright = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: #364461;
  position: fixed;
  padding-left: 50px;
  bottom: 10px;
  @media (max-height: 490px) {
    display: none;
  }
`;
