import styled from "styled-components";

// Adapting based on props
export const PropsBoxSelection = styled.div((props) => ({
  height: "100%",
  width: "6px",
  background: props.backgroundColor,
  borderRadius: "15px 0 0 15px",
}));

export const BoxSelection = styled.div({
  background: "transparent",
  height: "25px",
  width: "8px",
});

export const SelectionCardOption = styled.div`
  margin: 5px !important;
  background-color: #fafafa;
  max-height: 500px;
  width: 97%;
  font-size: 12px;
  margin-top: 40px;
  border-radius: 8px;
`;

export const Label = styled.h5({
  color: "#424242",
  fontFamily: "Roboto",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: "700",
  lineHeight: "24px",
  letterSpacing: "0.15px,",
  textAlign: "left",
  margin: 0,
});

export const Version = styled.p({
  color: "#364461",
  fontFamily: "Roboto",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: "700",
  lineHeight: "24px",
  letterSpacing: "0.15px,",
  textAlign: "right",
  margin: 0,
});

export const LabelVersion = styled.p({
  color: "#364461",
  fontFamily: "Roboto",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: "500",
  lineHeight: "22px",
  marginBottom: "2px",
});

export const LabelDiscription = styled.p({
  color: "#424242",
  fontFamily: "Roboto",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: "500",
  lineHeight: "21.98px",
  letterSpacing: "0.1px",
});

export const VersionDiscription = styled.p({
  color: "#424242",
  fontFamily: "Roboto",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: "400",
  lineHeight: "21.98px",
  letterSpacing: "0.1px",
});

export const SelectionBorder = {
  border: "1px solid rgba(0, 0, 0, 0.13)",
  padding: "10px",
  borderRadius: "16px",
  background: "#FFF",
  margin: "0px 20px 0 20px",
};

export const SelectArea = {
  display: "flex",
  alignItems: "flex-start",
  alignSelf: "stretch",
  justifyContent: "space-around",
};

export const SelectPage = styled.p((props) => ({
  color: "#424242",
  textAlign: "center",
  fontFamily: "Roboto",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: "400",
  lineHeight: "24.5px",
  letterSpacing: "0.4px",
  paddingBottom: "10px",
  marginTop: "10px",
  borderBottom: "1px solid #EEE",
  cursor: "pointer",

  width: "-webkit-fill-available",

  ...(props?.active && {
    color: "#0036B3",
    fontWeight: "700",
    borderBottom: "2px solid #0036B3",
    paddingBottom: "10px",
    width: "-webkit-fill-available",
  }),

  "&:hover": {
    color: "#0036B3",
    fontWeight: "700",
    borderBottom: "2px solid #0036B3",
    paddingBottom: "10px",
    width: "-webkit-fill-available",
  },
}));

export const SelectedPage = styled.p({
  color: "#0036B3",
  textAlign: "center",
  fontFamily: "Roboto",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: "700",
  lineHeight: "24.5px",
  letterSpacing: "0.4px",
  paddingBottom: "10px",
  borderBottom: "2px solid #0036B3",
  width: "-webkit-fill-available",
});
