import {
  Grid,
  Select,
  MenuItem,
  Box,
  FormGroup,
  InputLabel,
  TextField,
} from "@material-ui/core";

import React, { useContext, useState } from "react";

import { GlobalContext } from "../../../contexts/GlobalContext";
import { notify } from "../../../utils/notify";
import { useChatContext } from "../contexts/ChatContext";
import ConversationController from "../controllers/ConversationController";

import { GridStyled, ButtonStyled } from "../styles/GeneralStyle";

export default function ClosingConversationScreen() {
  const { companyUser } = useContext(GlobalContext);
  const { setChat, chat, SCREEN_ENUM } = useChatContext();
  const { emitQuitAttendance } = ConversationController();
  const [typeSelected, setTypeSelected] = useState("");
  const [subject, setSubject] = useState("");
  const [classificationSelected, setClassificationSelected] = useState("");

  function handleQuit() {
    if (companyUser?.isAccountant === "true") {
      if (!typeSelected || !classificationSelected || !subject)
        return notify("Preencha os campos", true, "warning");
    }

    emitQuitAttendance({
      type: typeSelected,
      subject,
      classification: classificationSelected,
    });
  }

  return (
    <GridStyled
      item
      direction="row"
      width="100%"
      textAlign="left"
      padding="10px"
    >
      {companyUser?.isAccountant === "true" ? (
        <>
          <GridStyled container xs={12} alignItems="end" marginBottom="20px">
            <TextField
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
              label="Assunto"
              variant="standard"
              style={{ width: "100%" }}
            />
          </GridStyled>
          <GridStyled container xs={12} alignItems="end">
            <Grid item xs={12}>
              <FormGroup>
                <InputLabel id={`select-label-tipo`}>Tipo</InputLabel>
                <Select
                  labelId={`select-label-tipo`}
                  label="Tipo"
                  onChange={(e) => setTypeSelected(e.target.value)}
                  value={typeSelected}
                  style={{ width: "100%" }}
                >
                  {chat.typeAttendace.map((type) => {
                    return (
                      <MenuItem value={type.code}>{type.description}</MenuItem>
                    );
                  })}
                </Select>
              </FormGroup>
            </Grid>
          </GridStyled>
          <GridStyled
            container
            xs={12}
            marginTop="10px"
            alignItems="end"
            marginBottom="32px"
          >
            <Grid item xs={12}>
              <FormGroup>
                <InputLabel id={`select-label-classificação`}>
                  Classificação
                </InputLabel>
                <Select
                  labelId={`select-label-classificação`}
                  label="Classificação"
                  onChange={(e) => setClassificationSelected(e.target.value)}
                  value={classificationSelected}
                  style={{ width: "100%" }}
                >
                  {chat.classificationAttendance.map((type) => {
                    return (
                      <MenuItem value={type.code}>{type.description}</MenuItem>
                    );
                  })}
                </Select>
              </FormGroup>
            </Grid>
          </GridStyled>
        </>
      ) : (
        <Box fontSize={16} mb={4}>
          Tem certeza que deseja encerrar o atendimento?
        </Box>
      )}

      <GridStyled
        display="flex"
        flexDirection="row"
        width="100%"
        justifyContent="space-between"
        textAlign="center"
      >
        <ButtonStyled
          flex="1"
          backgroundColor="#E3E3E3"
          color="#333"
          marginRight="8px"
          onClick={() => setChat({ currentScreen: SCREEN_ENUM.conversation })}
        >
          Voltar
        </ButtonStyled>

        <ButtonStyled
          flex="1"
          backgroundColor="#006FDE"
          color="#fff"
          marginLeft="8px"
          onClick={handleQuit}
        >
          Encerrar
        </ButtonStyled>
      </GridStyled>
    </GridStyled>
  );
}
