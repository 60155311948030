import { CircularProgress, Grid, IconButton, Tooltip } from "@material-ui/core";
import {
  GetAppOutlined,
  PictureAsPdfOutlined,
  SendOutlined,
} from "@material-ui/icons";
import React, { useContext, useState } from "react";
import {
  checkXML,
  downloadPDF,
  downloadXML,
  getDFeData,
  sendBillToPay,
} from "../../../repositories/panel/v1/DFeRepository";
import { notify } from "../../../utils/notify";
import { DialogSendBillToPay } from "./DialogSendBillToPay";
import { DialogConfirm } from "../../customerManagements/children/DialogConfirm";
import { BillToPayContext } from "../contexts/BillToPayContext";
import SendIcon from "../../../components/icons/SendIcon";
import PreviewIcon from "../../../components/icons/PreviewIcon";
import DownloadIcon from "../../../components/icons/DownloadIcon";

export const DFEActionsCell = ({ row, updateRows, ...props }) => {
  const [isLoadingPdf, setIsLoadingPdf] = useState(false);
  const [isLoadingXml, setIsLoadingXml] = useState(false);
  const [isLoadingSendBillToPay, setIsLoadingSendBillToPay] = useState(false);
  const [openDialogSendBillToPay, setOpenDialogSendBillToPay] = useState(false);
  const [openConfirmSendBillToPay, setOpenConfirmSendBillToPay] =
    useState(false);
  const [billToPayData, setBillToPayData] = useState({});
  const [payloadBillToPay, setPayloadBillToPay] = useState({});

  const { setLoadingPage } = useContext(BillToPayContext);

  const handleSendBillToPay = (payload) => {
    setPayloadBillToPay(payload);
    setOpenConfirmSendBillToPay(true);
  };

  const handleConfirmSendBillToPay = () => {
    // TODO: Enviar para contas a pagar
    setLoadingPage(true);

    const bodyRegister = {
      accountant_document: payloadBillToPay.accountantDocument,
      bill: payloadBillToPay.sendToBillToPay,
      provider: payloadBillToPay.provider,
      products: payloadBillToPay.products,
      transport: false,
      client: false,
    };

    checkXML(payloadBillToPay.id, bodyRegister)
      .then((response) => {
        if (response?.success) {
          if (payloadBillToPay.sendToBillToPay) {
            sendBillToPay(
              payloadBillToPay.id,
              payloadBillToPay.accountantDocument,
              payloadBillToPay.duplicatas
            )
              .then((billResponse) => {
                if (billResponse?.success) {
                  updateRows(payloadBillToPay.id, true);
                  notify(
                    "Nota enviada para o Gestão Fácil com sucesso!",
                    true,
                    "success"
                  );
                  setOpenConfirmSendBillToPay(false);
                  setOpenDialogSendBillToPay(false);
                } else {
                  notify(
                    "Erro ao enviar nota para o Gestão Fácil",
                    true,
                    "error"
                  );
                }
              })
              .catch((error) => {
                notify(
                  "Erro ao enviar nota para o Gestão Fácil",
                  true,
                  "error"
                );
                console.error(error);
              });
          } else {
            notify(
              "Nota enviada para o Gestão Fácil com sucesso!",
              true,
              "success"
            );
            setOpenConfirmSendBillToPay(false);
            setOpenDialogSendBillToPay(false);
          }
        } else {
          notify("Erro ao enviar nota ao Gestão Fácil", true, "error");
          console.error(response);
        }
      })
      .catch((error) => {
        notify("Erro ao enviar nota ao Gestão Fácil", true, "error");
        console.error(error);
      })
      .finally(() => {
        setLoadingPage(false);
      });

    setOpenConfirmSendBillToPay(false);
  };

  const handleClickSendBillToPay = () => {
    setIsLoadingSendBillToPay(true);
    getDFeData(row.id)
      .then((response) => {
        if (response?.success) {
          setBillToPayData(response.data);
          setOpenDialogSendBillToPay(true);
        } else {
          notify("Erro ao buscar dados da nota", true, "error");
        }
      })
      .catch((error) => {
        notify("Erro ao buscar dados da nota", true, "error");
        console.error(error);
      })
      .finally(() => {
        setIsLoadingSendBillToPay(false);
      });
  };

  return (
    <>
      <DialogSendBillToPay
        open={openDialogSendBillToPay}
        dfeData={billToPayData}
        dfeId={row.id}
        handleClose={() => setOpenDialogSendBillToPay(false)}
        handleSend={handleSendBillToPay}
        isAlreadySended={row.sent_to_erp}
      />
      <DialogConfirm
        open={openConfirmSendBillToPay}
        handleClose={() => setOpenConfirmSendBillToPay(false)}
        title="Confirmação de Envio"
        description="Deseja realmente enviar esta nota com suas duplicatas para o Contas a Pagar?"
        handleConfirm={handleConfirmSendBillToPay}
      />
      <Grid container spacing={1}>
        <Grid item>
          <Tooltip title="Enviar para contas a pagar">
            <IconButton
              size="small"
              onClick={() => {
                handleClickSendBillToPay();
              }}
            >
              {isLoadingSendBillToPay ? (
                <CircularProgress size={25} color="primary" />
              ) : (
                <SendIcon fill="#616161" />
              )}
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid item>
          <Tooltip title="Download XML">
            <IconButton
              size="small"
              onClick={() => {
                setIsLoadingXml(true);
                downloadXML(row.id)
                  .then((response) => {
                    const element = document.createElement("a");
                    const file = new Blob([response.data.xml], {
                      type: "text/xml",
                    });
                    element.href = URL.createObjectURL(file);
                    element.download = row.id + ".xml";
                    document.body.appendChild(element);
                    element.click();
                    document.body.removeChild(element);
                    notify("XML baixado com sucesso!", true, "success");
                  })
                  .catch((error) => {
                    notify("Erro ao baixar o XML", true, "error");
                    console.error(error);
                  })
                  .finally(() => {
                    setIsLoadingXml(false);
                  });
              }}
            >
              {isLoadingXml ? (
                <CircularProgress size={25} />
              ) : (
                <DownloadIcon fill="#616161" />
              )}
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid item>
          <Tooltip title="Visualizar PDF DANFE">
            <IconButton
              size="small"
              onClick={() => {
                setIsLoadingPdf(true);
                downloadPDF(row.id)
                  .then((response) => {
                    const fileURL = URL.createObjectURL(response);
                    window.open(fileURL, "_blank");
                  })
                  .catch((error) => {
                    console.log(error);
                  })
                  .finally(() => {
                    setIsLoadingPdf(false);
                  });
              }}
            >
              {isLoadingPdf ? (
                <CircularProgress size={25} color="primary" />
              ) : (
                <PreviewIcon fill="#616161" />
              )}
            </IconButton>
          </Tooltip>
        </Grid>

        {/* FIXME: PENSAR EM COMO FAZER */}

        {/* <Grid item>
          <Tooltip title="Adicionar Categoria">
            <IconButton
              size="small"
              onClick={() => {
                setIsLoadingXml(true);
                downloadXML(row.id)
                  .then((response) => {
                    const element = document.createElement("a");
                    const file = new Blob([response.data.xml], {
                      type: "text/xml",
                    });
                    element.href = URL.createObjectURL(file);
                    element.download = row.id + ".xml";
                    document.body.appendChild(element);
                    element.click();
                    document.body.removeChild(element);
                    notify("XML baixado com sucesso!", true, "success");
                  })
                  .catch((error) => {
                    notify("Erro ao baixar o XML", true, "error");
                    console.error(error);
                  })
                  .finally(() => {
                    setIsLoadingXml(false);
                  });
              }}
            >
              {isLoadingXml ? (
                <CircularProgress size={25} />
              ) : (
                <GetAppOutlined color="primary" />
              )}
            </IconButton>
          </Tooltip>
        </Grid> */}
      </Grid>
    </>
  );
};
