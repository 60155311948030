import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import React from "react";

import { ReactComponent as IconCheck } from "../../../assets/icons/check_icon.svg";
import { ReactComponent as IconUncheck } from "../../../assets/icons/uncheck_icon.svg";

export const TableComparisonERPPlans = () => {
  return (
    <Table style={{ transition: "0.3" }}>
      <TableHead>
        <TableRow>
          <TableCell>Atributos</TableCell>
          <TableCell align="center">QYON Gestão Fácil Emissor</TableCell>
          <TableCell align="center">QYON Gestão Fácil Essencial</TableCell>
          <TableCell align="center">QYON Gestão Fácil Avançado</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell>Plataforma com Qyon BOT IA</TableCell>
          <TableCell align="center">
            <IconCheck />
          </TableCell>
          <TableCell align="center">
            <IconCheck />
          </TableCell>
          <TableCell align="center">
            <IconCheck />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Emissão NF-e</TableCell>
          <TableCell align="center">
            <IconCheck />
          </TableCell>
          <TableCell align="center">
            <IconCheck />
          </TableCell>
          <TableCell align="center">
            <IconCheck />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Emissão NFS-e</TableCell>
          <TableCell align="center">
            <IconCheck />
          </TableCell>
          <TableCell align="center">
            <IconCheck />
          </TableCell>
          <TableCell align="center">
            <IconCheck />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Relatórios de Clientes</TableCell>
          <TableCell align="center">
            <IconCheck />
          </TableCell>
          <TableCell align="center">
            <IconCheck />
          </TableCell>
          <TableCell align="center">
            <IconCheck />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Relatórios de Fornecedores</TableCell>
          <TableCell align="center">
            <IconCheck />
          </TableCell>
          <TableCell align="center">
            <IconCheck />
          </TableCell>
          <TableCell align="center">
            <IconCheck />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Relatórios de Notas Fiscais</TableCell>
          <TableCell align="center">
            <IconCheck />
          </TableCell>
          <TableCell align="center">
            <IconCheck />
          </TableCell>
          <TableCell align="center">
            <IconCheck />
          </TableCell>
        </TableRow>

        <TableRow>
          <TableCell>Financeiro</TableCell>
          <TableCell align="center">
            <IconUncheck />
          </TableCell>
          <TableCell align="center">
            <IconCheck />
          </TableCell>
          <TableCell align="center">
            <IconCheck />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Gestão de Recebimentos</TableCell>
          <TableCell align="center">
            <IconUncheck />
          </TableCell>
          <TableCell align="center">
            <IconCheck />
          </TableCell>
          <TableCell align="center">
            <IconCheck />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Fluxo de Caixa</TableCell>
          <TableCell align="center">
            <IconUncheck />
          </TableCell>
          <TableCell align="center">
            <IconCheck />
          </TableCell>
          <TableCell align="center">
            <IconCheck />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Dashboard Financeiro</TableCell>
          <TableCell align="center">
            <IconUncheck />
          </TableCell>
          <TableCell align="center">
            <IconCheck />
          </TableCell>
          <TableCell align="center">
            <IconCheck />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Gestão de Pagamentos</TableCell>
          <TableCell align="center">
            <IconUncheck />
          </TableCell>
          <TableCell align="center">
            <IconCheck />
          </TableCell>
          <TableCell align="center">
            <IconCheck />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Movimentação Bancária</TableCell>
          <TableCell align="center">
            <IconUncheck />
          </TableCell>
          <TableCell align="center">
            <IconCheck />
          </TableCell>
          <TableCell align="center">
            <IconCheck />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Conciliação Bancária Qyon Bank</TableCell>
          <TableCell align="center">
            <IconUncheck />
          </TableCell>
          <TableCell align="center">
            <IconCheck />
          </TableCell>
          <TableCell align="center">
            <IconCheck />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Conciliação Bancária Arquivos OFX</TableCell>
          <TableCell align="center">
            <IconUncheck />
          </TableCell>
          <TableCell align="center">
            <IconCheck />
          </TableCell>
          <TableCell align="center">
            <IconCheck />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Cobrança Qyon Bank</TableCell>
          <TableCell align="center">
            <IconUncheck />
          </TableCell>
          <TableCell align="center">
            <IconCheck />
          </TableCell>
          <TableCell align="center">
            <IconCheck />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Cobrança CNAB (Demais Bancos)</TableCell>
          <TableCell align="center">
            <IconUncheck />
          </TableCell>
          <TableCell align="center">
            <IconCheck />
          </TableCell>
          <TableCell align="center">
            <IconCheck />
          </TableCell>
        </TableRow>

        <TableRow>
          <TableCell>Plano de Contas</TableCell>
          <TableCell align="center">
            <IconUncheck />
          </TableCell>
          <TableCell align="center">
            <IconCheck />
          </TableCell>
          <TableCell align="center">
            <IconCheck />
          </TableCell>
        </TableRow>

        <TableRow>
          <TableCell>Exportação Contábil</TableCell>
          <TableCell align="center">
            <IconUncheck />
          </TableCell>
          <TableCell align="center">
            <IconCheck />
          </TableCell>
          <TableCell align="center">
            <IconCheck />
          </TableCell>
        </TableRow>

        <TableRow>
          <TableCell>Dashboard de Serviços</TableCell>
          <TableCell align="center">
            <IconUncheck />
          </TableCell>
          <TableCell align="center">
            <IconUncheck />
          </TableCell>
          <TableCell align="center">
            <IconCheck />
          </TableCell>
        </TableRow>

        <TableRow>
          <TableCell>Despesas Reembolsáveis</TableCell>
          <TableCell align="center">
            <IconUncheck />
          </TableCell>
          <TableCell align="center">
            <IconUncheck />
          </TableCell>
          <TableCell align="center">
            <IconCheck />
          </TableCell>
        </TableRow>

        <TableRow>
          <TableCell>Gestão de Contratos</TableCell>
          <TableCell align="center">
            <IconUncheck />
          </TableCell>
          <TableCell align="center">
            <IconUncheck />
          </TableCell>
          <TableCell align="center">
            <IconCheck />
          </TableCell>
        </TableRow>

        <TableRow>
          <TableCell>Faturamento Oneclick</TableCell>
          <TableCell align="center">
            <IconUncheck />
          </TableCell>
          <TableCell align="center">
            <IconUncheck />
          </TableCell>
          <TableCell align="center">
            <IconCheck />
          </TableCell>
        </TableRow>

        <TableRow>
          <TableCell>Gestão de Usuários e Grupos</TableCell>
          <TableCell align="center">
            <IconUncheck />
          </TableCell>
          <TableCell align="center">
            <IconUncheck />
          </TableCell>
          <TableCell align="center">
            <IconCheck />
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};
