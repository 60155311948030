import styled, { css } from "styled-components";

import { Button, Grid, Paper } from "@material-ui/core";

export const ButtonIconMonitor = styled(Button)`
  ${(props) =>
    props.selected === props.value
      ? "background: #006fde"
      : "background: #fff "}
`;

export const ButtonIconFilter = styled(Button)`
  height: 100%;
  width: 100%;
  font-size: 20px;
  size: small;
  border: 1px solid #c4c4c4;
  ${(props) => (props.active ? "background: #CCCFD6" : "background: #fff ")};
`;

export const BadgeStatus = styled.div`
  background: ${(props) =>
    props?.online ? css`rgb(146, 195, 83)` : css`rgb(212, 19, 13)`};
  border-radius: "13px";
  padding: "6px";
  border: "2px solid #fff";
  position: "absolute";
  cursor: "pointer";
  bottom: "0";
  right: "0";
`;

export const divScene = styled.div`
  width: 200px;
  height: 260px;
  border: 1px solid #ccc;
  margin: 40px 0;
  perspective: 600px;
`;

export const divCard = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  cursor: pointer;
  transform-style: preserve-3d;
  transform-origin: center right;
  transition: transform 1s;
`;

export const BadgeAvatar = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  border-radius: 13px;
  padding: 6px;
  border: 2px solid #fff;

  ${(props) =>
    props["background-color"] &&
    css`
      background-color: ${(props) => props["background-color"]};
    `}
`;

export const PaperStyled = styled(Paper)`
  ${(props) =>
    props["padding-bottom"] &&
    css`
      padding-bottom: ${(props) => props["padding-bottom"]};
    `}

  ${(props) =>
    props["padding"] &&
    css`
      padding: ${(props) => props["padding"]} !important;
    `}
`;

export const GridFilters = styled(Grid)`
  height: 100%;
  width: 100%;
  border-radius: 5px;
  border: 1px solid #c4c4c4;
  border-color: black;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  padding: 13px 12px;

  &:hover {
    background-color: #fff;
  }
`;
