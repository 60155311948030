import { useEffect, useRef, useState } from "react";
import { getBotTips } from "../../../repositories/Bot";
import {
  DivStyledActions,
  StyledButtonCaroussel,
  StyledGridBotFuncionality,
  SubtitleBotActions,
} from "../styles/NewBot";
import BotActionIcons from "../../sidebar/children/bot/components/BotActionIcons";
import { Grid } from "@material-ui/core";

export const ActionCarousel = ({ ...props }) => {
  const botQuestions = useRef(null);
  const [active, setActive] = useState(null);

  /*
  const loopCarousel = () => {
    setTimeout(() => {
      if (botQuestions.current != null) {
        let value = getRandomArbitrary(0, botQuestions.current.length - 1);
        setCurrentCarouselValue(value);
      }

      loopCarousel();
    }, 10000);
  };*/

  useEffect(() => {
    getBotTips().then((result) => {
      if (result != null) {
        botQuestions.current = result.data;
        if (botQuestions.current.length > 0) {
          setActive(0);
        }
        //loopCarousel();
      }
    });
  }, []);

  const handleLeft = () => {
    const position = active - 1;
    if (position < 0) {
      return;
    }

    setActive(active - 1);
  };

  const handleRight = () => {
    const position = active + 1;
    if (position >= botQuestions.current.length) {
      return;
    }
    setActive(active + 1);
  };

  return (
    <>
      <StyledGridBotFuncionality
        item
        container
        direction="row"
        alignItems="center"
      >
        <DivStyledActions>
          <BotActionIcons icon="magicWand" color="#FFF" />
        </DivStyledActions>

        <SubtitleBotActions>Exemplos de Ações Rápidas</SubtitleBotActions>
      </StyledGridBotFuncionality>

      {botQuestions?.current != null &&
        botQuestions.current.map((question, index) => {
          return (
            <StyledGridBotFuncionality
              active={index === active ? true : false}
              hasText={true}
              item
              container
              direction="row"
              alignItems="center"
            >
              <div
                style={{
                  borderRadius: 5,
                  boxShadow: "0px 2px 4px 0px rgba(0,0,0,0.16)",
                  background: "#FFF",
                  width: "100%",
                  padding: 10,
                  fontSize: 10,
                }}
              >
                {question.tip}
              </div>
            </StyledGridBotFuncionality>
          );
        })}

      <Grid item container direction="row" justifyContent="flex-end">
        <StyledButtonCaroussel onClick={() => handleLeft()}>
          <BotActionIcons icon="left" color="#0839a9" />
        </StyledButtonCaroussel>
        <StyledButtonCaroussel onClick={() => handleRight()}>
          <BotActionIcons icon="right" color="#0839a9" />
        </StyledButtonCaroussel>
      </Grid>
    </>
  );
};
