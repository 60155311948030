import { Modal, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import {
  BorderlessBodyStyle,
  ButtonCancel,
  CloseIconStyle,
  DialogHeader,
  DialogStyle,
} from "../../../components/modal/Modal";
import { useContext } from "react";
import { NewBotContext } from "../../../components/newBot/context/NewBotContext";

export const CompanyHasAccountantModal = (props) => {
  const botContext = useContext(NewBotContext);
  const [hasAccountantList, setHasAccountantList] = useState([]);

  useEffect(() => {
    let list = [];
    list = botContext.companyErrorList.filter(
      (element) =>
        element.error === "Esta empresa já está vinculada a outro escritório."
    );
    setHasAccountantList(list);
  }, [botContext.companyErrorList]);

  return (
    <Modal
      open={props.openModal}
      onClose={() => {
        props.closeModal();
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <DialogStyle>
        <DialogHeader>
          <div className="row">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginRight: 20,
                marginBottom: 10,
              }}
            >
              Empresa vinculada em outro escritório
            </div>

            <CloseIconStyle
              onClick={() => {
                props.closeModal();
              }}
            />
          </div>
        </DialogHeader>

        <BorderlessBodyStyle>
          <Typography
            id="modal-modal-title"
            variant="h5"
            component="h2"
            style={{
              fontWeight: 400,
              fontSize: 14,
              color: "#364461",
            }}
          >
            As empresas abaixo já possuem contador vinculado:
            <br />
            <br />
            {hasAccountantList.map((company) => (
              <Typography
                style={{
                  fontWeight: 400,
                  fontSize: 14,
                  color: "#364461",
                }}
              >
                {company.companyName} - CNPJ:{" "}
                <bold style={{ fontWeight: 700 }}>{company.clientCnpj}</bold>
              </Typography>
            ))}
            <br />
            <br />
            Se você é o contador dessas empresas, entre em contato com o suporte
            para concluir o cadastro.
          </Typography>
        </BorderlessBodyStyle>
        <div
          style={{
            display: "flex",
            justifyContent: "end",
            marginTop: 20,
            marginBottom: 20,
          }}
        >
          <ButtonCancel
            style={{ marginRight: 16 }}
            onClick={() => {
              props.closeModal();
            }}
          >
            Fechar
          </ButtonCancel>
        </div>
      </DialogStyle>
    </Modal>
  );
};
