import React, { createContext, useState } from "react";

const CndCustomerContext = createContext();

const CndCustomerContextProvider = ({ children }) => {
  const [tabIndex, setTabIndex] = useState(0);
  const [myCnds, setMyCnds] = useState([]);

  const [cndsFederal, setCndsFederal] = useState([]);
  const [cndsMunicipal, setCndsMunicipal] = useState([]);
  const [cndsState, setCndsState] = useState([]);
  const [cndsFavorites, setCndsFavorites] = useState([]);
  const [cndsOthers, setCndsOthers] = useState([]);
  const [perPageFederal, setPerPageFederal] = useState(5);
  const [perPageMunicipal, setPerPageMunicipal] = useState(5);
  const [perPageState, setPerPageState] = useState(5);
  const [perPageOthers, setPerPageOthers] = useState(5);
  const [expandedTable, setExpandedTable] = useState({
    federal: false,
    state: false,
    municipal: false,
  });
  const [statusAccessCnd, setStatusAccessCnd] = useState([]);

  const [loading, setLoading] = useState({});

  // Standalone
  const [standAloneRequestedCnd, setStandAloneRequestedCnd] = useState([]);

  return (
    <CndCustomerContext.Provider
      value={{
        tabIndex,
        setTabIndex,
        myCnds,
        setMyCnds,
        standAloneRequestedCnd,
        setStandAloneRequestedCnd,
        cndsFederal,
        setCndsFederal,
        cndsMunicipal,
        setCndsMunicipal,
        cndsState,
        setCndsState,
        cndsFavorites,
        setCndsFavorites,
        cndsOthers,
        setCndsOthers,
        perPageFederal,
        setPerPageFederal,
        perPageMunicipal,
        setPerPageMunicipal,
        perPageState,
        setPerPageState,
        perPageOthers,
        setPerPageOthers,
        expandedTable,
        setExpandedTable,
        statusAccessCnd,
        setStatusAccessCnd,

        loading,
        setLoading,
      }}
    >
      {children}
    </CndCustomerContext.Provider>
  );
};

export { CndCustomerContextProvider, CndCustomerContext };
