import React, { useState, useEffect, useContext } from "react";

import { Button, Grid, CircularProgress } from "@material-ui/core";
import Modal from "@material-ui/core/Modal";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import BusinessIcon from "@material-ui/icons/Business";
import TextField from "@material-ui/core/TextField";

import { createCompany } from "./events/CreateCompany";
import { notify } from "../../../utils/notify";
import { getMP3Audio } from '../../../repositories/AudioHandler'
import { putEntity } from "../../../repositories/panel/v1/EntityRepository";
import { GlobalContext } from "../../../contexts/GlobalContext";
import { DashboardViewContext } from "../contexts/DashboardViewContext";
import { UploadZip } from "../../../pages/dashboard/components/events/UploadZip";

const dialogStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};
const bodyStyle = {
  maxHeight: 400,
  overflowY: "auto",
  marginBottom: 10,
};

export const ZipModal = (props) => {
  const [loading, setLoading] = useState(false);

  const getTitle = () => {
    return props.zipList.length == 1 ? 'Detectei alguns arquivos de eSocial.' : `Detectei uma lista com ${props.zipList.length} zips que contem arquivos de eSocial.`
  }

  useEffect(() => {
    if (props.loading) {
      return;
    }

    getMP3Audio(getTitle() + "Deseja que eu envie para processamento?").then(result => {
      var snd = new Audio("data:audio/wav;base64," + result);
      snd.play();
    });
  }, [])

  const sendToHub = async () => {
    props.loadingZipModal(true);

    let error = false;
    for (let zip of props.zipList) {
      let result = await UploadZip(zip.base64, zip.name);
      if (result.status != 200) {
        error = true;
        break;
      }
    }

    if (error) {
      notify("Ocorreu um problema, tente novamente mais tarde!");
      props.loadingZipModal(false);
      return;
    }

    notify("Estamos processando os arquivos de eSocial enviados!", true, "success");
    props.loadingZipModal(false);
    props.closeZipModal();
  };

  return (
    <Modal
      open={props.openModal}
      onClose={() => {
        props.closeZipModal();
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={dialogStyle}>
        {!props.loading && (
          <>
            <Typography
              id="modal-modal-title"
              variant="h5"
              component="h2"
              style={{ marginBottom: 20, fontWeight: "bold" }}
            >
              {getTitle()}
            </Typography>

            {!props.loading && props.zipList.length != 0 && (
              <>
                <Box sx={bodyStyle}>
                  Deseja que eu envie para processamento?
                </Box>

                <div style={{ position: "relative", right: 0, float: 'right', marginTop: 20 }}>
                  {!loading &&
                    <Button
                      onClick={() => {
                        props.closeZipModal();
                      }}
                      style={{
                        background: "red",
                        color: "white",
                      }}
                    >
                      Não
                    </Button>
                  }

                  &nbsp;

                  <Button
                    onClick={async () => {
                      sendToHub();
                    }}
                    style={{
                      background: loading ? "grey" : "#304ffe",
                      color: "white",
                      right: 0,
                      float: "right",
                    }}
                  >
                    {loading ? "Carregando..." : "Sim"}
                  </Button>
                </div>
              </>
            )}
          </>
        )}

        {props.loading && (
          <CircularProgress
            style={{
              marginLeft: "39%",
              width: 100,
              height: 100,
              marginTop: 74,
              marginBottom: 57,
            }}
          />
        )}
      </Box>
    </Modal>
  );
};
