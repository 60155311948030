import { useContext, useMemo } from "react";
import { ManagementContext } from "../contexts/ManagementContext";
import { useManagementController } from "../controllers/ManagementController";
import { GlobalVariables } from "../../../GlobalVariables";

import { Card, Typography, Checkbox, CardContent } from "@material-ui/core";
import {
  CheckBoxOutlined,
  CheckBoxOutlineBlankOutlined,
} from "@material-ui/icons";

import { notify } from "../../../utils/notify";
import { GridStyled, LightTooltip } from "../../../styles/GlobalStyle";
import {
  TabBody,
  ToggleButtonStyled,
  ButtonGroupCheckAll,
  ButtonStyled,
  CardHeaderStyled,
  PermissionIcon,
  CardPermissionLabel,
} from "../styles/management";

export const TabCards = ({ cards, mainTabIndex }) => {
  const { handleListItemClick } = useManagementController();

  const {
    groupCheckedPermissions,
    userCheckedPermissions,
    groupSelected,
    userSelected,
  } = useContext(ManagementContext);

  const allCheckedByGroup = useMemo(
    () =>
      groupSelected?.master ||
      groupCheckedPermissions.includes(cards?.id) ||
      groupCheckedPermissions.includes(GlobalVariables.PERMISSIONS.MASTER),
    [groupCheckedPermissions, groupSelected, cards]
  );

  const allCheckedByUser = useMemo(
    () =>
      userCheckedPermissions.includes(cards?.id) ||
      userCheckedPermissions.includes(GlobalVariables.PERMISSIONS.MASTER),
    [cards, userCheckedPermissions]
  );

  return (
    <TabBody>
      <GridStyled
        container
        direction="column"
        justifyContent="center"
        spacing={2}
      >
        <GridStyled item>
          <GridStyled
            container
            justifyContent="space-between"
            alignItems="center"
          >
            <GridStyled item>
              <Typography>
                {`Selecione os cards que esse ${
                  mainTabIndex === 0 ? "grupo de usuários" : "usuário"
                } terá
                acesso`}
              </Typography>
            </GridStyled>
            <GridStyled item>
              <ButtonGroupCheckAll variant="contained">
                <LightTooltip title="Desmarcar Todos">
                  <ButtonStyled
                    onClick={() => {
                      if (!userSelected && groupSelected?.master)
                        return notify(
                          "Grupo de acesso master não pode ser editado"
                        );

                      handleListItemClick(false, cards);
                    }}
                  >
                    <CheckBoxOutlineBlankOutlined />
                  </ButtonStyled>
                </LightTooltip>
                <LightTooltip title="Marcar Todos">
                  <ButtonStyled
                    onClick={() => {
                      if (!userSelected && groupSelected?.master)
                        return notify(
                          "Grupo de acesso master não pode ser editado"
                        );

                      handleListItemClick(true, cards);
                    }}
                  >
                    <CheckBoxOutlined />
                  </ButtonStyled>
                </LightTooltip>
              </ButtonGroupCheckAll>
            </GridStyled>
          </GridStyled>
        </GridStyled>
        <GridStyled item>
          <GridStyled container spacing={4}>
            {(userSelected || groupSelected) &&
              (cards?.children_recursive || []).map((systemCards) => {
                if (!systemCards.id) return <></>;

                const systemChecked = {
                  inGroup:
                    allCheckedByGroup ||
                    groupCheckedPermissions.includes(systemCards.id),
                  inUser:
                    allCheckedByUser ||
                    userCheckedPermissions.includes(systemCards.id),
                };

                let someCardChecked = (
                  systemCards?.children_recursive || []
                ).some(
                  (card) =>
                    groupCheckedPermissions.includes(card.id) ||
                    userCheckedPermissions.includes(card.id)
                );

                return (
                  <GridStyled item sm={6}>
                    <Card>
                      <CardHeaderStyled
                        title={
                          <>
                            <LightTooltip title="Liberar cards do sistema">
                              <Checkbox
                                color={systemChecked.inGroup ? "blue" : "green"}
                                checked={
                                  systemChecked.inGroup ||
                                  systemChecked.inUser ||
                                  someCardChecked
                                }
                                onChange={() => {
                                  if (!userSelected && groupSelected?.master)
                                    return notify(
                                      "Grupo de acesso master não pode ser editado"
                                    );

                                  handleListItemClick(
                                    !(
                                      systemChecked.inGroup ||
                                      systemChecked.inUser
                                    ),
                                    systemCards
                                  );
                                }}
                                indeterminate={
                                  !systemChecked.inGroup &&
                                  !systemChecked.inUser &&
                                  someCardChecked
                                }
                              />
                            </LightTooltip>
                            {systemCards.description}
                          </>
                        }
                      />
                      <CardContent>
                        <GridStyled container spacing={2}>
                          {(systemCards.children_recursive || []).map(
                            (card) => {
                              const cardChecked = {
                                inGroup: groupCheckedPermissions.includes(
                                  card.id
                                ),
                                inUser: userCheckedPermissions.includes(
                                  card.id
                                ),
                              };

                              const selectedCard =
                                systemChecked.inGroup ||
                                systemChecked.inUser ||
                                cardChecked.inGroup ||
                                cardChecked.inUser;

                              return (
                                <GridStyled item xs={4}>
                                  <ToggleButtonStyled
                                    value="visualizar"
                                    aria-label="visualizar"
                                    className="square"
                                    $checkedByGroup={
                                      systemChecked.inGroup ||
                                      cardChecked.inGroup
                                    }
                                    selected={selectedCard}
                                    onChange={() => {
                                      if (
                                        !userSelected &&
                                        groupSelected?.master
                                      )
                                        return notify(
                                          "Grupo de acesso master não pode ser editado"
                                        );

                                      handleListItemClick(!selectedCard, card);
                                    }}
                                  >
                                    <LightTooltip title={card.description}>
                                      <GridStyled
                                        container
                                        direction="column"
                                        justifyContent="center"
                                      >
                                        {card.icon_url && (
                                          <GridStyled item>
                                            <PermissionIcon
                                              src={card.icon_url}
                                              alt={`Icone do card ${card.description}`}
                                            />
                                          </GridStyled>
                                        )}
                                        <GridStyled item>
                                          <CardPermissionLabel>
                                            {card.description}
                                          </CardPermissionLabel>
                                        </GridStyled>
                                      </GridStyled>
                                    </LightTooltip>
                                  </ToggleButtonStyled>
                                </GridStyled>
                              );
                            }
                          )}
                        </GridStyled>
                      </CardContent>
                    </Card>
                  </GridStyled>
                );
              })}
          </GridStyled>
        </GridStyled>
      </GridStyled>
    </TabBody>
  );
};
