import { Grid, Typography } from "@material-ui/core";
import styled from "styled-components";

export const AccoutantPaymentSlipExpiration = styled(Grid)`
  width: 100%;
  padding: 10px 24px;
  margin-top: 10px;
  color: ${(props) => props.colorLetter};
  align-items: center;
  border-left-width: 16px;
  border-radius: 10px;
  border-left-style: solid;
  border-left-color: ${(props) => props.colorMarginLeft};
  margin-left: 10px;
  border: 1px solid #c2ccd2;
`;

export const TypographyAlertBill = styled(Typography)`
  font-size: 1rem;
`;
