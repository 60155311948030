export const getEnvironment = () => {
  if (localStorage.getItem("environment") === "prod") {
    return "production";
  }

  if (window.location.host.includes("portal3")) {
    return "homologation";
  }

  if (window.location.host.includes("portal2")) {
    return "preproduction";
  }

  return "";
};
