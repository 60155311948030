import React, { useContext, useEffect, useState } from "react";

import Dropzone from "react-dropzone";

import CircularProgress from "@material-ui/core/CircularProgress";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import ImageOutlinedIcon from "@material-ui/icons/ImageOutlined";
import Clear from "@material-ui/icons/Clear";

import useHomeController from "../controllers/HomeController";
import { HomeContext } from "../contexts/HomeContext";
import { LightTooltip } from "../../../styles/GlobalStyle";
import {
  DropContainer,
  UploadMessage,
  DropMessage,
  Preview,
  DeleteImageButton,
} from "./styles/UploadAboutUsImage";

export default function UploadAboutUsImage() {
  const { landTheme, aboutUsImage, setAboutUsImage, editEnable } =
    useContext(HomeContext);

  const { uploadAboutUsImage } = useHomeController();

  const [uploadedAboutUsImage, setUploadedAboutUsImage] = useState([]);
  const [loading, setLoading] = useState(false);
  const [hoverImage, setHoverImage] = useState(false);

  useEffect(() => {
    setUploadedAboutUsImage(aboutUsImage ? [{ preview: aboutUsImage }] : []);
  }, [aboutUsImage, setUploadedAboutUsImage]);

  const handleUpload = async (files) => {
    try {
      setLoading(true);
      await uploadAboutUsImage(files[0]);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleDeletImage = (e) => {
    e.stopPropagation();
    setUploadedAboutUsImage([]);
    setAboutUsImage("");
  };

  return (
    <>
      {loading ? (
        <CircularProgress />
      ) : (
        <>
          {!editEnable ? (
            <Preview src={uploadedAboutUsImage[0]?.preview} />
          ) : (
            <Dropzone accept="image/*" onDropAccepted={handleUpload}>
              {({
                getRootProps,
                getInputProps,
                isDragActive,
                isDragReject,
              }) => (
                <DropContainer
                  {...getRootProps()}
                  isDragActive={isDragActive}
                  isDragReject={isDragReject}
                  uploaded={uploadedAboutUsImage[0]?.preview ? true : false}
                  onMouseEnter={() => setHoverImage(true)}
                  onMouseLeave={() => setHoverImage(false)}
                >
                  <input {...getInputProps()} multiple={false} />
                  {uploadedAboutUsImage.length ? (
                    <>
                      {hoverImage && (
                        <DeleteImageButton
                          onClick={handleDeletImage}
                          $gray={landTheme.color_pallete.gray}
                        >
                          <Clear />
                        </DeleteImageButton>
                      )}
                      <div
                        style={{ display: "grid" }}
                        className="preview-upload-img"
                      >
                        <LightTooltip title="Clique ou arraste sua imagem aqui">
                          <Preview src={uploadedAboutUsImage[0]?.preview} />
                        </LightTooltip>
                      </div>
                    </>
                  ) : (
                    <UploadMessage>Arraste sua imagem aqui</UploadMessage>
                  )}

                  {isDragActive && (
                    <>
                      {isDragReject ? (
                        <DropMessage isDragReject>
                          <ErrorOutlineIcon /> Não suportado
                        </DropMessage>
                      ) : (
                        <DropMessage isDragReject={isDragReject}>
                          <ImageOutlinedIcon /> Solte Aqui
                        </DropMessage>
                      )}
                    </>
                  )}
                </DropContainer>
              )}
            </Dropzone>
          )}
        </>
      )}
    </>
  );
}
