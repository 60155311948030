import React, { createContext, useState } from "react";

const ModalConfigSidebarContext = createContext();

const ModalConfigSidebarContextProvider = ({ children }) => {
  const [accessNameConfig, setAccessNameConfig] = useState("");
  const [emailConfig, setEmailConfig] = useState("");
  const [typeLimit, setTypeLimit] = useState("trial");

  const [openConfig, setOpenConfig] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});

  return (
    <ModalConfigSidebarContext.Provider
      value={{
        accessNameConfig,
        setAccessNameConfig,
        emailConfig,
        setEmailConfig,
        typeLimit,
        setTypeLimit,

        openConfig,
        setOpenConfig,
        errors,
        setErrors,
        loading,
        setLoading,
      }}
    >
      {children}
    </ModalConfigSidebarContext.Provider>
  );
};

export { ModalConfigSidebarContextProvider, ModalConfigSidebarContext };
