import React, { useEffect, useState, useContext } from "react";

import { Grid, Modal, Box, Typography, Tabs, Tab } from "@material-ui/core";

import InputMask from "react-input-mask";
import { Player } from "@lottiefiles/react-lottie-player";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { TabPanel, TabContext } from "@material-ui/lab";
import CloseIcon from "@material-ui/icons/Close";
import { getMP3Audio } from "../../repositories/AudioHandler";
import DescriptionRoundedIcon from "@material-ui/icons/DescriptionRounded";
import { getDescription } from "../../utils/DocumentUtil";
import { DocumentDasTab } from "./children/DocumentDasTab";
import { DocumentDarfTab } from "./children/DocumentDarfTab";
import { DocumentBoletoTab } from "./children/DocumentBoletoTab";
import { DocumentFgtsTab } from "./children/DocumentFgtsTab";
import useDocumentModalController from "./controllers/DocumentModalController";
import { DocumentDctfReturnTab } from "./children/DocumentDctfReturnTab";
import { ModalCheckboxes } from "./children/ModalCheckboxes";
import {
  dialogStyle,
  bodyStyle,
  dialogLoading,
  dialogLoadingPlayer,
  dialogTitle,
  dialogTitleChildrenRow,
  dialogTitleChildrenColumn,
  dialogTitleClose,
  documentContainer,
  documentTab,
  tabPanelStyle,
  nameDocumentStyle,
  tabsDocumentStyle,
  PdfIframe,
  DivTextLeft,
  DocumentButton,
  DivSecondTitle,
  NotIdentifiedDocumentName,
  PdfImgWrapperDiv,
  DocumentModalFooterWrapper,
  pdfImg,
  floatAlign,
  TextFieldDocumentName,
  TabTextField,
} from "./styles/DocumentModal.js";
import { GlobalContext } from "../../contexts/GlobalContext";

export const DocumentModal = (props) => {
  const { companyUser } = useContext(GlobalContext);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [disabled, setDisabled] = useState(false);
  const [screen, setScreen] = useState("DOCUMENT_DATA");
  const [value, setValue] = useState([0]);
  const [users, setUsers] = useState([]);
  const [key, setKey] = useState(Math.random());
  const [cpfCnpjFocus, setCpfCnpjFocus] = useState(false);
  const [cpfCnpjFocus1, setCpfCnpjFocus1] = useState(false);
  const [govIdKey, setGovIdKey] = useState(1);
  const [typeFileState, setTypeFileState] = useState("guide");
  const [openTicketInCRM, setOpenTicketInCrm] = useState(false);
  const [clientDocument, setClientDocument] = useState("");
  const [clientDocumentMask, setClientDocumentMask] =
    useState("99.999.999/9999-99");

  const {
    handleUploadDocument,
    handleSetupDocument,
    getDocumentTypeExibition,
    verifyAndChangeGovidMask,
  } = useDocumentModalController();

  const getTitle = () => {
    if (props.documentModalLoading === false) {
      const recognized = props.documentList.filter(
        (e) => e.data.document_type !== "NOT_IDENTIFIED"
      );

      const unrecognized = props.documentList.filter(
        (e) => e.data.document_type === "NOT_IDENTIFIED"
      );
      const unique = [
        ...new Set(props.documentList.map((item) => item.data.document_type)),
      ].filter((e) => e !== "NOT_IDENTIFIED");
      let letter =
        props.documentList.length - unrecognized.length > 1 ? "s" : "";

      if (recognized.length === 0) {
        let lter = unrecognized.length > 1 ? "s" : "";

        return (
          "Identifiquei " +
          unrecognized.length +
          " documento" +
          lter +
          " não conhecido" +
          lter
        );
      }

      let msg =
        "Detectei um total de " +
        (props.documentList.length - unrecognized.length) +
        " documento" +
        letter;
      msg +=
        ", sendo este" +
        letter +
        " do tipo: " +
        getDocumentTypeExibition(unique.toString());

      return msg;
    }

    let letter = props.documentList.length > 1 ? "s" : "";

    return (
      "Estou analisando o" +
      letter +
      " documento" +
      letter +
      " enviado" +
      letter
    );
  };

  const getSecondTitle = () => {
    if (props.documentModalLoading === false) {
      const unique = props.documentList.filter(
        (e) => e.data.document_type === "NOT_IDENTIFIED"
      );

      if (unique.length === 0) {
        return "";
      }

      let letter = unique.length > 1 ? "s" : "";
      let was = unique.length > 1 ? "Foram" : "Foi";

      let msg =
        was +
        " encontrado" +
        letter +
        " " +
        unique.length +
        " documento" +
        letter +
        " não identificado" +
        letter;

      return msg;
    }
    return "Aguarde um instante...";
  };

  useEffect(() => {
    if (props.documentModalLoading === false) {
      handleSetupDocument(props.documentList, setValue, setUsers, companyUser);
    }
  }, [props.documentModalLoading]);

  useEffect(() => {
    let title = getTitle();
    getMP3Audio(title).then((result) => {
      var snd = new Audio("data:audio/wav;base64," + result);
      snd.play();
    });
  }, []);

  const verifyCheckBoxes = (index) => {
    if (props.documentList !== null && props.documentList.length === 0) {
      return;
    }
    if (
      props.documentList[index].outros === false &&
      props.documentList[index].fiscal === false &&
      props.documentList[index].contabil === false &&
      props.documentList[index].folha === false
    ) {
      props.documentList[index].openTicketInCRM = false;
      setOpenTicketInCrm(false);
    }

    if (props.documentList[index].doc_type !== typeFileState) {
      setTypeFileState(props.documentList[index].doc_type);
    }
  };

  return (
    <Modal
      open={props.openModal}
      onClose={() => {
        props.closeDocumentModal();
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <>
        {(!screen || screen === "DOCUMENT_DATA") && (
          <Box sx={dialogStyle}>
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              style={dialogTitle}
            >
              <div style={dialogTitleChildrenRow}>
                <div style={dialogTitleChildrenColumn}>
                  {getTitle()}

                  <DivSecondTitle>{getSecondTitle()}</DivSecondTitle>
                </div>

                <CloseIcon
                  style={dialogTitleClose}
                  onClick={() => {
                    props.closeDocumentModal();
                  }}
                />
              </div>
            </Typography>

            {props.documentModalLoading === true && (
              <div style={dialogLoading}>
                <Player
                  autoplay
                  src="https://assets1.lottiefiles.com/packages/lf20_elm7pc3z.json"
                  style={dialogLoadingPlayer}
                ></Player>
              </div>
            )}
            <Box sx={bodyStyle}>
              <Carousel
                showStatus={false}
                showThumbs={false}
                key={key}
                animationHandler="slide"
                showIndicators={false}
                onChange={(index, item) => {
                  setCurrentIndex(index);
                }}
              >
                {props.documentList.map((document, index) => {
                  return (
                    <Grid key={"DOCUMENT" + index}>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <div
                            style={documentContainer}
                            key={document.tab + "_" + document.tab}
                          >
                            <TabContext value={value[index]}>
                              <Tabs
                                value={value[index]}
                                aria-label="full width tabs example"
                                textColor="inherit"
                                variant="fullWidth"
                                style={tabsDocumentStyle(
                                  props.documentModalLoading,
                                  document?.data?.document_type
                                )}
                                indicatorColor="secondary"
                                onChange={(event, newValue) => {
                                  let newArr = Object.assign([], value);
                                  newArr[index] = newValue;
                                  setValue(newArr);
                                }}
                              >
                                <Tab
                                  label="Documento"
                                  style={documentTab(1, value)}
                                />
                                <Tab
                                  label="Dados"
                                  style={documentTab(0, value)}
                                />
                              </Tabs>

                              <TabPanel
                                value={1}
                                index={1}
                                style={tabPanelStyle}
                              >
                                {props.documentModalLoading === false &&
                                  document.data.document_type ===
                                    "NOT_IDENTIFIED" && (
                                    <DivTextLeft>
                                      <NotIdentifiedDocumentName>
                                        <TextFieldDocumentName
                                          label={"Nome do documento (original)"}
                                          variant="outlined"
                                          key={
                                            "DOCUMENT_NAME" +
                                            index +
                                            " - " +
                                            key
                                          }
                                          defaultValue={
                                            props.documentList[index].name
                                              ? props.documentList[index].name
                                              : props.documentList[index]
                                                  .name_suggestion
                                          }
                                          onChange={(ev) => {
                                            if (props.documentList[index].name)
                                              props.documentList[index].name =
                                                ev.target.value;
                                            else
                                              props.documentList[
                                                index
                                              ].name_suggestion =
                                                ev.target.value;
                                          }}
                                        />
                                      </NotIdentifiedDocumentName>
                                      {companyUser.isAccountant && (
                                        <InputMask
                                          mask={clientDocumentMask}
                                          disabled={false}
                                          key={"CLIENT_CPF_CNPJ" + index}
                                          maskChar=""
                                          value={clientDocument}
                                          onChange={(ev) => {
                                            setClientDocument(
                                              ev.target.value.replace(
                                                /[^\d]/g,
                                                ""
                                              )
                                            );

                                            props.documentList[index].client = {
                                              document: ev.target.value.replace(
                                                /[^\d]/g,
                                                ""
                                              ),
                                            };

                                            verifyAndChangeGovidMask(
                                              ev.target.value.replace(
                                                /[^\d]/g,
                                                ""
                                              ),
                                              setClientDocumentMask
                                            );
                                          }}
                                        >
                                          {() => (
                                            <TabTextField
                                              label="CPF/CNPJ do Cliente"
                                              variant="outlined"
                                              key={
                                                "CLIENT_CPF_CNPJ_TEXTFIELD" +
                                                index
                                              }
                                            />
                                          )}
                                        </InputMask>
                                      )}
                                    </DivTextLeft>
                                  )}

                                {props.documentModalLoading === false &&
                                  document.data.document_type !==
                                    "NOT_IDENTIFIED" &&
                                  document.data.document_type === "DARF" && (
                                    <>
                                      <DocumentDarfTab
                                        document={props.documentList[index]}
                                        index={index}
                                        govIdKey={govIdKey}
                                        setCpfCnpjFocus={setCpfCnpjFocus}
                                        cpfCnpjFocus={cpfCnpjFocus}
                                        documentModalLoading={
                                          props.documentModalLoading
                                        }
                                        documentKey={key}
                                        getDescription={getDescription}
                                      />
                                    </>
                                  )}

                                {props.documentModalLoading === false &&
                                  document.data.document_type !==
                                    "NOT_IDENTIFIED" &&
                                  document.data.document_type === "DAS" && (
                                    <>
                                      <DocumentDasTab
                                        document={props.documentList[index]}
                                        index={index}
                                        govIdKey={govIdKey}
                                        setCpfCnpjFocus={setCpfCnpjFocus}
                                        cpfCnpjFocus={cpfCnpjFocus}
                                        documentModalLoading={
                                          props.documentModalLoading
                                        }
                                        documentKey={key}
                                      />
                                    </>
                                  )}

                                {props.documentModalLoading === false &&
                                  document.data.document_type !==
                                    "NOT_IDENTIFIED" &&
                                  document.data.document_type === "BOLETO" && (
                                    <>
                                      <DocumentBoletoTab
                                        document={props.documentList[index]}
                                        index={index}
                                        govIdKey={govIdKey}
                                        setCpfCnpjFocus={setCpfCnpjFocus}
                                        cpfCnpjFocus={cpfCnpjFocus}
                                        documentModalLoading={
                                          props.documentModalLoading
                                        }
                                        cpfCnpjFocus1={cpfCnpjFocus1}
                                        documentKey={key}
                                        setCpfCnpjFocus1={setCpfCnpjFocus1}
                                      />
                                    </>
                                  )}

                                {props.documentModalLoading === false &&
                                  document.data.document_type !==
                                    "NOT_IDENTIFIED" &&
                                  document.data.document_type === "FGTS" && (
                                    <>
                                      <DocumentFgtsTab
                                        document={props.documentList[index]}
                                        index={index}
                                        govIdKey={govIdKey}
                                        setCpfCnpjFocus={setCpfCnpjFocus}
                                        cpfCnpjFocus={cpfCnpjFocus}
                                        documentModalLoading={
                                          props.documentModalLoading
                                        }
                                        documentKey={key}
                                      />
                                    </>
                                  )}
                                {props.documentModalLoading === false &&
                                  document.data.document_type !==
                                    "NOT_IDENTIFIED" &&
                                  document.data.document_type ===
                                    "DCTF_RETURN" && (
                                    <>
                                      <DocumentDctfReturnTab
                                        document={props.documentList[index]}
                                        index={index}
                                        govIdKey={govIdKey}
                                        setCpfCnpjFocus={setCpfCnpjFocus}
                                        cpfCnpjFocus={cpfCnpjFocus}
                                        documentModalLoading={
                                          props.documentModalLoading
                                        }
                                        documentKey={key}
                                      />
                                    </>
                                  )}
                                <ModalCheckboxes
                                  document={props.documentList[index]}
                                  index={index}
                                  typeFileState={typeFileState}
                                  setTypeFileState={setTypeFileState}
                                  verifyCheckBoxes={verifyCheckBoxes}
                                  users={users}
                                  key={key}
                                  setOpenTicketInCrm={setOpenTicketInCrm}
                                  openTicketInCRM={openTicketInCRM}
                                />
                              </TabPanel>
                              <TabPanel
                                value={0}
                                index={0}
                                style={tabPanelStyle}
                              >
                                <DivTextLeft>
                                  <DescriptionRoundedIcon
                                    style={{
                                      color:
                                        props.documentModalLoading === true
                                          ? "grey"
                                          : document.data.document_type ===
                                            "NOT_IDENTIFIED"
                                          ? "#F44336"
                                          : "#4CAF50",
                                    }}
                                  />
                                  <span
                                    style={nameDocumentStyle(
                                      props.documentModalLoading,
                                      document?.data?.document_type
                                    )}
                                  >
                                    {props.documentModalLoading === true
                                      ? "Carregando..."
                                      : document.data.document_type ===
                                        "NOT_IDENTIFIED"
                                      ? "Não identificado"
                                      : document.data.document_type}{" "}
                                    - {props.documentList[index].name}
                                  </span>
                                </DivTextLeft>

                                {props.documentModalLoading === true && (
                                  <PdfIframe
                                    title={`document_${key}`}
                                    src={
                                      "data:application/pdf;base64," +
                                      document.base64 +
                                      "#toolbar=0&navpanes=0#zoom=10?id=" +
                                      Math.random()
                                    }
                                    scrolling="no"
                                  />
                                )}

                                {props.documentModalLoading === false && (
                                  <PdfImgWrapperDiv>
                                    <img
                                      src={
                                        "data:image/png;base64, " +
                                        document.data.base64
                                      }
                                      alt={`document_${key}`}
                                      style={pdfImg()}
                                    />
                                  </PdfImgWrapperDiv>
                                )}
                              </TabPanel>
                            </TabContext>

                            <DocumentModalFooterWrapper>
                              {currentIndex !== 0 && (
                                <DocumentButton
                                  loading={
                                    disabled || props.documentModalLoading
                                  }
                                  color="variant"
                                  aria-label="add"
                                  style={floatAlign("left")}
                                  onClick={() => {
                                    window.document
                                      .getElementsByClassName(
                                        "control-arrow control-prev"
                                      )[0]
                                      .click();
                                  }}
                                >
                                  Voltar
                                </DocumentButton>
                              )}

                              <div style={floatAlign("right")}>
                                <DocumentButton
                                  isSend
                                  loading={
                                    disabled || props.documentModalLoading
                                  }
                                  disabled={
                                    disabled || props.documentModalLoading
                                  }
                                  onClick={async () => {
                                    if (
                                      document.data.document_type ===
                                      "NOT_IDENTIFIED"
                                    ) {
                                      document.client = {
                                        document: clientDocument,
                                      };
                                    }

                                    await handleUploadDocument(
                                      document,
                                      props,
                                      index,
                                      setDisabled,
                                      setKey
                                    );

                                    verifyCheckBoxes(index);
                                  }}
                                >
                                  Enviar{" "}
                                  {props.documentModalLoading === true
                                    ? ""
                                    : document.data.document_type ===
                                        "NOT_IDENTIFIED" ||
                                      document.data.document_type ===
                                        "DCTF_RETURN" ||
                                      document.data.document_type ===
                                        "DCTF_RECEIPT"
                                    ? ""
                                    : getDocumentTypeExibition(
                                        document.data.document_type
                                      )}
                                </DocumentButton>

                                {currentIndex !==
                                  props.documentList.length - 1 && (
                                  <DocumentButton
                                    loading={
                                      disabled || props.documentModalLoading
                                    }
                                    onClick={() => {
                                      window.document
                                        .getElementsByClassName(
                                          "control-arrow control-next"
                                        )[0]
                                        .click();
                                    }}
                                  >
                                    Próximo
                                  </DocumentButton>
                                )}
                              </div>
                            </DocumentModalFooterWrapper>
                          </div>
                        </Grid>
                      </Grid>
                    </Grid>
                  );
                })}
              </Carousel>
            </Box>
          </Box>
        )}
      </>
    </Modal>
  );
};
