import { callApi } from "./main";
const path =
  `${process.env.REACT_APP_CRM_API_URL}/v1/` || "http://localhost:4000/v1";

export async function getAuth({ authPath }) {
  let route = `${path}${authPath}`;

  return callApi({
    path: route,
    method: "GET",
  });
}
