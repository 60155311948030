import { search } from "../../../../BaseRepositoryComponents";

const path = `${process.env.REACT_APP_PAINEL_API_URL}/api/v1/integrations/rpa/type`;
const newPath = `${process.env.REACT_APP_CND_API_URL}/v1`;

export async function get() {
  return search({
    path: path,
  });
}

export async function getNew() {
  return search({
    path: `${newPath}/cnd`,
  });
}
