export const isFiscal = (code) => {
  return fiscalCodes.filter((e) => e === code).length === 0 ? false : true;
};

export const isContabil = (code) => {
  return contabilCodes.filter((e) => e === code).length === 0 ? false : true;
};

export const isFolha = (code) => {
  return folhaCodes.filter((e) => e === code).length === 0 ? false : true;
};

const fiscalCodes = [
  "8109",
  "2172",
  "6912",
  "5856",
  "2089",
  "2372",
  "2089",
  "8045",
  "1708",
  "3280",
  "3208",
  "5952",
  "5434",
  "5442",
  "0422",
  "1599",
  "2319",
  "0220",
  "2362",
  "3373",
  "5993",
  "2390",
  "2430",
  "2456",
  "2484",
  "2469",
  "6012",
  "2030",
  "6773",
  "6758",
];

const contabilCodes = [
  "1599",
  "2319",
  "0220",
  "2362",
  "3373",
  "5993",
  "2390",
  "2430",
  "2456",
  "2484",
  "2469",
  "6012",
  "2030",
  "6773",
  "6758",
];

const folhaCodes = ["0561", "0588"];

let map = new Map();
map.set("0013", "Taxa Fiscalização CVM");
map.set("0019", "Transferências Correntes dos Municípios");
map.set(
  "0026",
  "IRRF-Rendimentos do Trabalho de Qualquer Natureza, como os Provenientes de Pensão, Aposentadoria, Prêmios em Concursos e Comissões-Residentes no Exterior-Oper. Intra Orçament."
);
map.set("0030", "Alienação do Domínio Direto - SPU");
map.set(
  "0039",
  "IRRF-Remuneração Serviços Prestados por Pessoa Jurídica-Operações Intra Orçamentárias"
);
map.set(
  "0041",
  "Multa por Atraso na Entrega da Dirf Anual-Operações Intra Orçamentárias"
);
map.set("0046", "Permissão de Uso - SPU");
map.set(
  "0052",
  "Regularização de depósitos extrajudiciais não tributários - Lei nº 12.099/2009"
);
map.set(
  "0054",
  "Multa por Atraso na Entrega da Dipj-Operações Intraorçamentárias"
);
map.set(
  "0067",
  "Produtos-Retenção em Pagamentos por Órgãos Públicos-Operações Intra Orçamentárias"
);
map.set("0069", "Cessão de Uso - SPU");
map.set(
  "0070",
  "Transporte de Passageiros-Retenção em Pagamentos por Órgãos Públicos-Operações Intra Orçamentárias"
);
map.set("0075", "Ressarcimento Casa da Moeda - Lei 11.488/2007");
map.set("0081", "Indenização por Posse/Ocupação Ilícita - SPU");
map.set(
  "0082",
  "Financeiras-Retenção em Pagamentos por Órgãos Públicos-Operações Intra Orçamentárias"
);
map.set("0086", "II - Imposto Importação - outros");
map.set("0094", "II - Imposto Importação - Bagagem Acompanhada");
map.set(
  "0095",
  "Serviços-Retenção em Pagamentos por Órgãos Públicos-Operações Intra Orçamentárias"
);
map.set("0107", "IE - Imposto Exportação - outros");
map.set(
  "0110",
  "Medicamentos Adquiridos do Distribuidor ou Varejista-Retido por Órgão Público-Operações Intra Orçamentárias"
);
map.set(
  "0122",
  "Transporte Internacional de Passageiros-Empresas Nacionais-Retido por Órgão Público-Operações Intra Orçamentárias"
);
map.set(
  "0123",
  "Bens e serviços adquiridos de sociedades cooperativas e associações profissionais ou assemelhadas - Retido por órgão público - Operações Intra-orçamentárias"
);
map.set(
  "0135",
  "Contribuição sobre Receita de Loteria de Prognóstico Específico"
);
map.set("0148", "Cide - Combustíveis - Importação - Lançamento de Ofício");
map.set(
  "0163",
  "Receita dos Direitos Antidumping e dos Direitos Compensatórios - Depósito Judicial"
);
map.set(
  "0174",
  "Receita dos Direitos Antidumping e dos Direitos Compensatórios - Depósito Administrativo"
);
map.set("0176", "R D Ativa - Parcelamento Timemania - Clubes de Futebol");
map.set("0190", "IRPF - Carnê Leão");
map.set(
  "0191",
  "CSLL - Tributação Diferida/Opção pelo Simples - Lançamento de Ofício"
);
map.set(
  "0211",
  "IRPF - Declaração de Ajuste Anual, Declaração de Saída Definitiva do País e Declaração Final de Espólio"
);
map.set("0216", "Depósito Judicial - Outros - Aduaneiros");
map.set(
  "0220",
  "IRPJ-PJ Obrigadas ao Lucro Real - Entidades não Financeiras - Balanço Trimestral"
);
map.set("0229", "Depósito Administrativo - Outros - Aduaneiros");
map.set(
  "0231",
  "RPJ - Ganhos Líquidos em Operações na Bolsa - Lucro Presumido ou Arbitrado"
);
map.set("0244", "Ouro Ativo Financeiro - Lançamento de Ofício");
map.set("0246", "IRPF - Complementação Mensal");
map.set("0285", "Parcelamento para Ingresso no Simples Nacional");
map.set("0289", "Multa da CLT");
map.set("0298", "Imposto de Exportação-Lançamento de Ofício");
map.set("0300", "Receita Direitos Antidumping-Lançamento de Ofício");
map.set("0312", "Devolução de Restituição Indevida - IRPF - Não Tributário");
map.set(
  "0338",
  "Multa Isolada Receita Direitos Antidumping e Compensatorios art. 7 Lei 9019/95"
);
map.set(
  "0340",
  "Juros Isolados Receita Direitos Antidumping e Compensatorios art. 7 Lei 9019/95"
);
map.set("0353", "Parcelamento Timemania");
map.set("0381", "Multa por Omissão/Erro na Dirf Anual");
map.set(
  "0390",
  "Multa por Falta ou Atraso na Entrega da Declaraçao de Informaçoes sobre Movimentaçao Financeira - Dimof"
);
map.set("0394", "Contribuição Prêmios Prescritos-Lei 5.768/71");
map.set("0400", "R D Ativa - Parcelamento para Ingresso no Simples Nacional");
map.set(
  "0416",
  "IRPJ - Regime Especial de Tributação - Patrimônio de Afetação - Lançamento de Ofício"
);
map.set(
  "0422",
  "IRRF - Royalties e Assistência Técnica - Residentes no Exterior"
);
map.set("0434", "Cofins-Importação de Serviços-Lançamento de Ofício");
map.set(
  "0439",
  "Multa por Falta ou Atraso na Entrega da Declaraçao de Transferencia de Titularidade de Açoes Negociadas Fora da Bolsa - DTTA"
);
map.set("0445", "R D Ativa - Funcafé - Leilão");
map.set("0447", "PIS - Importação - Depósito Administrativo");
map.set("0449", "PIS-Importação de Serviços-Lançamento de Ofício");
map.set(
  "0450",
  "IRPJ - Ganho de Capital - Alienação de Ativos de ME/EPP Optantes pelo Simples - Lançamento de Ofício"
);
map.set("0457", "Receita Dívida Ativa - Contribuição Açúcar e Álcool");
map.set(
  "0468",
  "Cofins - Regime Especial de Tributação - Patrimônio de Afetação - Lançamento de Ofício"
);
map.set(
  "0473",
  "IRRF - Rendimentos do Trabalho e de Qualquer Natureza, como os Provenientes de Pensão, Aposentadoria, Prêmios em Concursos e Comissões - Residentes no Exterior"
);
map.set(
  "0474",
  "Cofins - Importaçao - Contribuiçao nao Paga devido a Suspensao"
);
map.set("0481", "IRRF - Juros e Comissões em Geral - Residentes no Exterior");
map.set(
  "0490",
  "IRRF - Aplicações em Fundos de Investimento de Conversão de Débitos Externos"
);
map.set("0497", "PIS - Importaçao - Nao Pago Devido a Suspensao");
map.set(
  "0507",
  "IRPJ - Ganho de Capital - Alienação de Ativos de ME/EPP Optantes pelo Simples Nacional"
);
map.set(
  "0513",
  "PIS - Regime Especial de Tributação - Patrimônio de Afetação - Lançamento de Ofício"
);
map.set(
  "0520",
  "Parcelamento - Instituições de Ensino Superior - Lei nº 10.260/01"
);
map.set(
  "0536",
  "R D Ativa - Parcelamento - Instituições de Ensino Superior - Lei nº 10.260/01"
);
map.set(
  "0542",
  "CSLL - Regime Especial de Tributação - Patrimônio de Afetação - Lançamento de Ofício"
);
map.set(
  "0559",
  "Multa por Falta ou Atraso na Entrega da Declaraçao de Rendimentos Pagos a Consultores por Organismos Internacionais - Derc"
);
map.set("0561", "IRRF - Rendimento do Trabalho Assalariado");
map.set(
  "0565",
  "Multa por Falta ou Atraso na Entrega da Declaraçao de Beneficios Fiscais - DBF"
);
map.set("0588", "IRRF - Rendimento do Trabalho sem Vínculo Empregatício");
map.set(
  "0594",
  "Multa por Falta ou Atraso na Entrega da Declaraçao Anual do Simples Nacional - DASN"
);
map.set(
  "0610",
  "IRRF - Rendimentos Prestação Serviços Transporte Rodoviário Internacional de Carga, Pagos Por PJ Domiciliada no País, Auferidos por Transportador Autônomo PF Residente no Paraguai"
);
map.set(
  "0627",
  "Multa por Falta ou Atraso na Entrega da Declaraçao sobre a Utilizaçao dos Recursos em Moeda Estrangeira Decorrentes do Recebimento de Exportaçoes - Derex"
);
map.set(
  "0656",
  "Multa por Falta ou Atraso na Entrega da Decalaraçao de Operaçoes com Cartoes de Credito - Decred"
);
map.set(
  "0662",
  "Multa por Falta ou Atraso na Entrega da Declaraçao sobre a Opçao de Tributaçao de Planos Previdenciarios - DPREV"
);
map.set("0668", "IPI - Bebidas");
map.set("0676", "IPI - Automóveis");
map.set("0679", "PIS - Tributação de Bebidas Frias - Cervejas");
map.set(
  "0685",
  "R D Ativa - Multa do Regime Disciplinar Aplicada a Rede Arrecadadora de Receitas Federais"
);
map.set("0691", "PIS - Tributação de Bebidas Frias - Demais Bebidas");
map.set("0692", "Diversas Receitas");
map.set(
  "0701",
  "Parcelamento - Art 1º da Medida Provisoria 303/2006 - Deposito Judicial"
);
map.set("0724", "PIS - Importação - Tributação de Bebidas Frias - Cervejas");
map.set(
  "0733",
  "IRPJ - Devolução de Recursos Aplicados como Redução por Reinvestimento"
);
map.set(
  "0753",
  "PIS - Importação - Tributação de Bebidas Frias - Demais Bebidas"
);
map.set("0760", "Cofins - Tributação de Bebidas Frias - Cervejas");
map.set(
  "0761",
  "Receita da Dívida Ativa - Regime Especial de Tributação do Patrimônio de Afetação"
);
map.set("0776", "Cofins - Tributação de Bebidas Frias - Demais Bebidas");
map.set("0782", "Cofins - Importação - Tributação de Bebidas Frias - Cervejas");
map.set(
  "0790",
  "Contribuição sobre a Receita de Sorteios Realizados por Entidades Filantrópicas - Lançamento de Ofício"
);
map.set(
  "0809",
  "Cofins - Importação - Tributação de Bebidas Frias - Demais Bebidas"
);
map.set("0810", "Receita Dívida Ativa - PIS");
map.set("0821", "IPI - Tributação de Bebidas Frias - Cervejas");
map.set(
  "0830",
  "Parcelamento - art. 1º da Medida Provisória nº 303, de 29 de Junho de 2006 - Pessoa Jurídica Optante pelo Simples"
);
map.set("0836", "Receita Dívida Ativa - Pasep");
map.set("0838", "IPI - Tributação de Bebidas Frias - Demais Bebidas");
map.set(
  "0842",
  "Parcelamento - art. 1º da Medida Provisória nº 303, de 29 de Junho de 2006 - Demais Pessoas Jurídicas"
);
map.set(
  "0850",
  "IPI - Vinculado Importação - Tributação de Bebidas Frias - Cervejas"
);
map.set("0855", "Cofins - Importação - Depósito Administrativo");
map.set("0860", "Pasep - Depósito Administrativo");
map.set(
  "0867",
  "IPI - Vinculado Importação - Tributação de Bebidas Frias - Demais Bebidas"
);
map.set("0868", "Pasep - Depósito Judicial");
map.set("0873", "Parcelamento para Ingresso no Simples Nacional - 2009");
map.set(
  "0879",
  "R D Ativa - Multa Isolada - CSIRF (art. 43 da Lei nº 9.430 de 1996)"
);
map.set(
  "0880",
  "Devolução de Restituição Indevida - Cofins/Finsocial - Não Tributário"
);
map.set(
  "0883",
  "R D Ativa - Juros Isolados - CSIRF (art. 43 da Lei nº 9.430 de 1996)"
);
map.set("0896", "R D Ativa - Cofins - Retenção na Fonte");
map.set("0906", "PIS - Regime Especial de Apuração e Pagamento - Álcool");
map.set(
  "0912",
  "PIS - Importação - Regime Especial de Apuração e Pagamento - Álcool"
);
map.set("0916", "IRRF - Prêmios Obtidos em Concursos e Sorteios");
map.set("0924", "IRRF - Demais Rendimentos Capital");
map.set("0929", "Cofins - Regime Epecial de Apuração e Pagamento - Álcool");
map.set(
  "0935",
  "Cofins - Importação - Regime Especial de Apuração e Pagamento - Álcool"
);
map.set(
  "0941",
  "Pagamento/Parcelamento - Artigo 1º da Medida Provisoria nº 449, de 3 de dezembro de 2008"
);
map.set("0949", "R D Ativa - PIS - Retenção na Fonte");
map.set("0958", "Pagamento/Parcelamento - Art. 3º da MP 449, de 2008");
map.set(
  "0970",
  "R D Ativa - Parcelamento para Ingresso no Simples Nacional - 2009"
);
map.set("0987", "Pagamento/Parcelamento - Art. 2º da MP 449, de 2008");
map.set("0992", "R D Ativa - CSLL - Retenção na Fonte");
map.set(
  "1009",
  "Taxa de Fiscalização Mercados Seguro, Capitalização e Previdência Privada"
);
map.set("1020", "IPI - Cigarros Contendo Tabaco");
map.set("1038", "IPI - Vinculado Importação");
map.set("1039", "R D Ativa - Recuperação de Recursos - Finor");
map.set("1045", "R D Ativa - Recuperação de Recursos - Finam");
map.set("1054", "IRPF - Devolução Restituição Indevida - Tributário");
map.set("1065", "R D Ativa - Produtos - Retido por Órgão Público");
map.set(
  "1068",
  "Pagamento Unificado - Programa Minha Casa, Minha Vida (PMCMV)/Construção ou Reforma de Creches e Pré-Escolas"
);
map.set("1070", "ITR - Exercício 1997 e Subsequentes");
map.set("1074", "Regularização Depósitos Tributários - DJE");
map.set("1089", "IE - Imposto Exportação - Cacau");
map.set("1097", "IPI - Maquinas, Aparelhos e Material de Transporte");
map.set("1107", "Multa por Falta ou Atraso na Entrega da GFIP");
map.set("1113", "IRPJ - Simples Nacional - Lançamento de Ofício");
map.set(
  "1118",
  "R D Ativa - Medicamentos Adquiridos do Distribuidor ou Varejista - Retido por Órgão Público"
);
map.set("1134", "Receita Dívida Ativa - Finsocial");
map.set(
  "1136",
  "Lei nº 11.941, de 2009 - PGFN - Débitos Previdenciários - Parcelamento de Dívidas Não Parceladas Anteriormente - art. 1º"
);
map.set("1142", "Receita Dívida Ativa - IOF");
map.set("1150", "IOF - Operações de Crédito - Pessoa Jurídica");
map.set(
  "1165",
  "Lei nº 11.941/2009 - PGFN - Débitos Previdenciários - Parcelamento de Saldo Remanescente dos Programas Refis, Paes, Paex e Parcelamentos Ordinários - art. 3º"
);
map.set(
  "1171",
  "Lei nº 11.941, de 2009 - PGFN - Débitos Previdenciários - Pagamento a Vista com Utilizaçao de Prejuízo Fiscal e Base de Cálculo Negativa da CSLL para Liquidar Multa e Juros"
);
map.set(
  "1174",
  "R D Ativa - Pneus e Câmaras-de-Ar de Borracha Adquiridos de Fabricante ou de Importador - Retido por Órgão Público"
);
map.set("1187", "Contribuição sobre Receita Concurso Prognósticos");
map.set(
  "1188",
  "Lei nº 11.941, de 2009 - PGFN - Demais Débitos - Pagamento a Vista com Utilização de Prejuízo Fiscal e Base de Cálculo Negativa da CSLL para Liquidar Multa e Juros"
);
map.set(
  "1194",
  "Lei nº 11.941, de 2009 - PGFN - Demais Débitos - Parcelamento de Dívidas Não Parceladas Anteriormente - art. 1º"
);
map.set(
  "1204",
  "Lei nº 11.941, de 2009 - PGFN - Demais Débitos - Parcelamento de Saldo Remanescente dos Programas Refis, Paes, Paex e Parcelamentos Ordinários - art. 3º"
);
map.set(
  "1210",
  "Lei nº 11.941, de 2009 - PGFN - Parcelamento de Dívida Decorrente de Aproveitamento Indevido de Créditos do IPI - art. 2º"
);
map.set(
  "1214",
  "R D Ativa - Máquinas, Tratores, Veículos para Transporte de Passageiros, de Mercadorias, de Usos Especiais e Chassis com Motores para Veículos Automóveis - Retido por Órgão Público"
);
map.set(
  "1230",
  "R D Ativa - Querosene de Aviação Adquirido de Produtor ou Importador - Retido por Órgão Público"
);
map.set(
  "1233",
  "Lei nº 11.941, de 2009 - RFB - Débitos Previdenciários - Parcelamento de Dívidas Não Parceladas Anteriormente - art. 1º"
);
map.set(
  "1240",
  "Lei nº 11.941, de 2009 - RFB - Débitos Previdenciários - Parcelamento de Saldo Remanescente dos Programas Refis, Paes, Paex e Parcelamentos Ordinarios - art. 3º"
);
map.set(
  "1256",
  "Lei nº 11.941, de 2009 - RFB - Débitos Previdenciários - Pagamento a Vista com Utilização de Prejuízo Fiscal e Base de Cálculo Negativa da CSLL para Liquidar Multa e Juros"
);
map.set(
  "1262",
  "Lei nº 11.941, de 2009 - RFB - Demais Débitos - Pagamento a Vista com Utilização de Prejuízo Fiscal e Base de Cálculo Negativa da CSLL para Liquidar Multa e Juros"
);
map.set(
  "1279",
  "Lei nº 11.941, de 2009 - RFB - Demais Débitos - Parcelamento de Dívidas Não Parceladas Anteriormente - art. 1º"
);
map.set(
  "1285",
  "Lei nº 11.941, de 2009 - RFB - Demais Débitos - Parcelamento de Saldo Remanescente dos Programas Refis, Paes, Paex e Parcelamentos Ordinários - art. 3º"
);
map.set(
  "1291",
  "Lei nº 11.941, de 2009 - RFB - Parcelamento de Dívida Decorrente de Aproveitamento Indevido de Créditos do IPI - art. 2º"
);
map.set("1318", "IPI - Simples Nacional - Lançamento de Ofício");
map.set("1324", "CSLL - Simples Nacional - Lançamento de Ofício");
map.set("1330", "Cofins - Simples Nacional - Lançamento de Ofício");
map.set("1345", "Multa Atraso Entrega DCTF");
map.set("1347", "PIS - Simples Nacional - Lançamento de Ofício");
map.set(
  "1360",
  "Contribuição Patronal Previdenciária - Simples Nacional - Lançamento de Ofício"
);
map.set(
  "1364",
  "Alienação de Bens Imóveis - Lei nº 8.011/1990 e Lei nº 8.025/1990 - SPU"
);
map.set("1376", "Multa por Falta ou Atraso na Entrega da DIF - Papel Imune");
map.set(
  "1382",
  "Lei nº 11.941, de 2009 - PGFN - Débitos Previdenciários - Parcelamento de Dívidas Não Parceladas Anteriormente - Art. 1º - Depósito Judicial"
);
map.set(
  "1399",
  "Lei nº 11.941, de 2009 - PGFN - Demais Débitos - Parcelamento de Dívidas Não Parceladas Anteriormente - Art. 1º - Dep Judicial"
);
map.set(
  "1415",
  "Lei nº 11.941, de 2009 - RFB - Débitos Previdenciários - Parcelamento de Dívidas Não Parceladas Anteriormente - Art. 1º - Depósito Judicial"
);
map.set(
  "1421",
  "Lei nº 11.941, de 2009 - RFB - Demais Débitos - Parcelamento de Dívidas Não Parceladas Anteriormente - Art. 1º - Depósito Judicial"
);
map.set("1425", "Emolumentos Consulares - País");
map.set("1433", "Emolumentos Justiça Distrito Federal");
map.set(
  "1438",
  "Multa por Falta ou Atraso na Entrega da Escrituração Contábil Digital (ECD)"
);
map.set("1441", "Convênios - Devolução de Saldo");
map.set("1444", "Pagamento/Parcelamento - MP 470/2009 - RFB");
map.set(
  "1460",
  "R D Ativa - Álcool Carb Adquir Distribuidor - Retido por Órgão Público"
);
map.set(
  "1467",
  "Regularização de depósitos judiciais não tributários - Lei nº 12.099/2009"
);
map.set(
  "1480",
  "Receita Dívida Ativa - Pagamento/Parcelamento - MP 470/2009 - PGFN"
);
map.set("1484", "Taxa Judicial da Justiça do Distrito Federal");
map.set("1492", "Custas Justiça Distrito Federal");
map.set("1496", "Devolução de Restituição Indevida - IRPJ - Não Tributário");
map.set("1505", "Custas Judiciais - outras");
map.set("1506", "Multa por Atraso na Entrega da DASN - SIMEI");
map.set(
  "1512",
  "Multa por Atraso na Entrega do Controle Fiscal Contábil de Transição - FCONT"
);
map.set("1513", "Receita Dívida Ativa - Custas Judiciais");
map.set(
  "1526",
  "Contribuição sobre a Receita de Concursos e Prognósticos - Indesp"
);
map.set("1529", "R D Ativa - Fundo de Terras e da Reforma Agrária");
map.set("1535", "R D Ativa - Funcafé - Dação em Pagamento");
map.set(
  "1539",
  "R D Ativa - Gasol/Diesel/Gás Liquefeito Petróleo e Álcool No Varejo - Retido por Órgão Público"
);
map.set(
  "1541",
  "Contribuição do Adicional sobre a Receita de Concursos e Prognósticos - Indesp"
);
map.set(
  "1548",
  "Taxa de Fiscalização de Produtos Sob Controle do Ministério do Exército"
);
map.set("1556", "Contribuição para Custeio das Pensões Militares");
map.set("1558", "Multa Aduaneira - Remessa Expressa");
map.set(
  "1570",
  "R D Ativa - Medicamentos Adquiridos do Industrial ou Importador - Retido por Órgão Público"
);
map.set("1572", "Montepio Civil");
map.set(
  "1582",
  "R D Ativa - Transporte de Passageiros - Retenção em Pagamento por Órgão Público"
);
map.set("1587", "Imposto Importação - Remessa Expressa");
map.set("1593", "Imposto Importação - Remessa Expressa - Lançamento de Ofício");
map.set(
  "1595",
  "R D Ativa - Transporte Internacional de Passageiros Empresas Nacionais - Retido por Órgão Público"
);
map.set(
  "1599",
  "IRPJ - PJ Obrigadas ao Lucro Real - Entidades Financeiras - Balanço Trimestral"
);
map.set("1607", "Multa por Aterro Irregular - SPU");
map.set(
  "1626",
  "Multa por Atraso na Entrega da Declaração de Serviços Médicos - Dmed"
);
map.set("1632", "Multa por Não Recolhimento da Estimativa - IRPJ");
map.set("1649", "Multa por Não Recolhimento da Estimativa - CSLL");
map.set("1652", "Taxa para Certidão Quitação CLT");
map.set(
  "1655",
  "Multa do Regime Disciplinar Aplicada à Rede Arrecadadora de Receitas Previdenciárias - Rarp"
);
map.set("1661", "CPSS - Servidor Civil Ativo");
map.set("1684", "CPSS - Servidor Civil Licenciado/Afastado");
map.set(
  "1689",
  "R D Ativa - Bens ou Serviços Adquiridos de Sociedades Cooperativas e Associações Profissionais ou Assemelhadas - Retido por Órgão Público"
);
map.set("1690", "CPSS - Decisão Judicial Mandado de Segurança");
map.set(
  "1691",
  "R D Ativa - Financeiras - Retenção em Pagamento por Órgão Público"
);
map.set("1700", "CPSS - Servidor Civil Inativo");
map.set(
  "1703",
  "R D Ativa - Serviços - Retenção em Pagamento por Órgão Público"
);
map.set("1708", "IRRF - Remuneração Serviços Prestados por Pessoa Jurídica");
map.set("1717", "CPSS - Pensionista Civil");
map.set(
  "1723",
  "CPSS - Servidor Civil Ativo - Precatório Judicial e Requisição de Pequeno Valor"
);
map.set(
  "1729",
  "R D Ativa - Combustível - Retenção em Pagamento por Órgão Público"
);
map.set(
  "1730",
  "CPSS - Servidor Civil Inativo - Precatório Judicial e Requisição de Pequeno Valor"
);
map.set(
  "1731",
  "R D Ativa - Retenção na Fonte de Contribuições sobre Pagamentos dos Estados, DF e Municípios pela Aquisição de Bens e Serviços - CSLL, Cofins e PIS"
);
map.set(
  "1752",
  "CPSS - Pensionista - Precatório Judicial e Requisição de Pequeno Valor"
);
map.set(
  "1769",
  "CPSS - Patronal - Servidor Civil Ativo - Operação Intra-Orçamentária"
);
map.set(
  "1772",
  "R D Ativa - Retenção na Fonte de Contribuições sobre Pagamentos de Pessoa Jurídica a outra Pessoa Jurídica de Direito Privado - CSLL, Cofins e PIS"
);
map.set(
  "1781",
  "CPSS - Patronal - Servidor Civil Licenciado/Afastado - Operação Intra-Orçamentária"
);
map.set("1800", "IRPJ - FINOR");
map.set("1804", "Receita Dívida Ativa - CSLL");
map.set(
  "1808",
  "CPSS - Patronal - Decisão Judicial Mandado de Segurança - Operação Intra-Orçamentária"
);
map.set(
  "1814",
  "CPSS - Patronal - Servidor no Exterior - Operação Intra-Orçamentária"
);
map.set("1825", "IRPJ - FINAM");
map.set(
  "1837",
  "CPSS - Patronal - Precatório Judicial e Requisição de Pequeno Valor - Operação Intra-Orçamentária"
);
map.set("1838", "IRPJ - FUNRES");
map.set("1840", "Cofins - Vendas à ZMF - Substituição Tributária");
map.set(
  "1843",
  "R D Ativa - Contribuição do Plano de Seguridade Social do Servidor Público - CPSS - Patronal"
);
map.set(
  "1850",
  "Contribuição do Plano de Seguridade Social do Servidor Público - CPSS - Patronal - Lançamento de Ofício"
);
map.set(
  "1872",
  "Complemento de Contribuição Previdenciária - Recolhimento Mensal"
);
map.set("1881", "Multas Previstas na Legislação de Trânsito");
map.set(
  "1889",
  "IRRF - Rendimentos Acumulados - Art. 12-A da Lei nº 7.713, de 22 de dezembro de 1988"
);
map.set(
  "1894",
  "Receita Dívida Ativa - Adicional ao Frete para Renovação da Marinha Mercante"
);
map.set(
  "1895",
  "IRRF - Rendimento Decorrente de Decisão da Justiça dos Estados/Distrito Federal, Exceto o Disposto no Artigo 12-A da Lei nº 7.713, de 22 de dezembro de 1988"
);
map.set("1905", "R D Ativa - Honorários Advocatícios de Sucumbência");
map.set(
  "1911",
  "R D Ativa - Contribuição do Plano de Seguridade Social do Servidor Público - CPSS - Não Patronal"
);
map.set(
  "1919",
  "Parcelamento - art. 9º da Medida Provisória nº 303, de 29 de Junho de 2006 - Pessoa Jurídica Optante pelo Simples"
);
map.set("1921", "PIS - Vendas à ZMF - Substituição Tributária");
map.set(
  "1927",
  "Parcelamento - art. 8º da Medida Provisória nº 303, de 29 de Junho de 2006 - Pessoa Jurídica Optante pelo Simples"
);
map.set(
  "1928",
  "Contribuição do Plano de Seguridade Social do Servidor Público - CPSS - Não Patronal - Lançamento de Ofício"
);
map.set("1934", "Indenização Itaipú - Parcelas Vincendas");
map.set("1940", "Devolução de Restituições não Creditadas");
map.set("1947", "PIS - Importação - Depósito Judicial");
map.set("1957", "Direitos de Natureza Comercial");
map.set("1962", "II - Imposto Importação - Petróleo e Derivados");
map.set("1963", "Direitos de Natureza Comercial - Lançamento de Oficio");
map.set("1970", "Multa Isolada - § 6º do Art 7º da Lei nº 12.270/2010");
map.set("1992", "Multa Isolada - § 7º do Art 7º da Lei nº 12.270/2010");
map.set("1994", "Cofins - Importação - Depósito Judicial");
map.set(
  "2009",
  "R D Ativa - Multa Processual Penal - Tribunal de Justiça do Distrito Federal e Territórios"
);
map.set("2015", "R D Ativa - Crédito Rural Securitização");
map.set(
  "2021",
  "R D Ativa - Crédito Rural - Programa Especial de Saneamento de Ativos - Pesa"
);
map.set("2030", "CSLL - Entidades Financeiras - Balanço Trimestral");
map.set(
  "2038",
  "R D Ativa - Crédito Rural - Programa de Desenvolvimento do Cerrado - Prodecer II"
);
map.set(
  "2044",
  "R D Ativa - Crédito Rural - Programa Nacional de Fortalecimento da Agricultura Familiar - Pronaf"
);
map.set("2048", "Renda Líquida da Loteria Federal Instantânea");
map.set("2049", "Aluguéis - outros");
map.set("2050", "ITR - Taxa de Cadastro Contribuições Área Rural");
map.set("2057", "Arrendamentos");
map.set("2063", "IRRF - Tributação Exclusiva sobre Remuneração Indireta");
map.set(
  "2067",
  "R D Ativa - Crédito Rural - Programa de Recuperação da Lavoura Cacaueira Baiana"
);
map.set("2073", "Foros");
map.set(
  "2080",
  "Depósitos Judiciais e Extrajudiciais Administrados pela PGF-AGU"
);
map.set("2081", "Laudêmios");
map.set("2089", "IRPJ - Lucro Presumido");
map.set("2090", "Taxa de Ocupação de Imóveis - outros");
map.set("2096", "CP Segurados - Contribuição Segurados - Lançamento de Ofício");
map.set("2102", "Outras Receitas Imobiliárias");
map.set("2110", "CSLL - Operações Intra Orçamentárias");
map.set("2129", "R D Ativa - Taxa Fisc Telecom");
map.set(
  "2141",
  "CP Patronal - Contribuição Empresa/Empregador - Lançamento de Ofício"
);
map.set(
  "2157",
  "Receita de Dívida Ativa - ITR e Receitas Vinculadas - Exercício de 1996 e Anteriores"
);
map.set(
  "2158",
  "Contribuição Riscos Ambientais/Aposentadoria Especial - Lançamento de Ofício"
);
map.set("2160", "Fundaf - Selos Especiais - Cigarros");
map.set(
  "2164",
  "Contribuição Devida a Outras Entidades e Fundos - Salário Educação - Lançamento de Ofício"
);
map.set("2170", "Multa por Atraso na Entrega da Dirf Anual");
map.set("2172", "Cofins - Contribuição para Financiamento Seguridade Social");
map.set("2185", "Multa Aplicada pelo Setor Aduaneiro - Sem Redução");
map.set(
  "2187",
  "Contribuição Devida a Outras Entidades e Fundos - Serviço Nacional de Aprendizagem Rural - Senar - Lançamento de Ofício"
);
map.set(
  "2193",
  "Contribuição Sujeita a Retenção Previdenciária - Lançamento de Ofício"
);
map.set(
  "2203",
  "Multa por atraso na entrega da Escrituração Fiscal Digital da Contrib para PIS/Pasep, Contrib para o Financ Segurid Social e Contrib Previd incid sobre Receita - EFD-Contribuições"
);
map.set("2217", "Juros Títulos de Renda - outros");
map.set("2226", "Contribuição Segurado - Depósito Judicial");
map.set("2241", "Dividendos - Bancos");
map.set(
  "2249",
  "Contribuição Devida a Outras Entidades e Fundos - Incra - Lançamento de Ofício"
);
map.set("2250", "Dividendos - outras Sociedades de Economia Mista");
map.set(
  "2253",
  "Contribuição ao Fundo Nacional da Cultura - sobre Prêmios e Sorteios"
);
map.set(
  "2255",
  "Contribuição Devida a Outras Entidades e Fundos - Fundo Aeroviário - Lançamento de Ofício"
);
map.set(
  "2261",
  "Contribuição Devida a Outras Entidades e Fundos - Fundo de Desenvolvimento do Ensino Profissional Marítimo - FDEPM - Lançamento de Ofício"
);
map.set("2268", "Dividendos - Empresas Publicas");
map.set("2276", "Participações");
map.set(
  "2278",
  "Contribuição Devida a Outras Entidades e Fundos - Serviço Nacional de Aprendizagem do Transporte - Senat - Lançamento de Ofício"
);
map.set(
  "2290",
  "Contribuição Devida a Outras Entidades e Fundos - Serviço Social de Transporte - Sest - Lançamento de Ofício"
);
map.set("2294", "Receita Dívida Ativa - SPU");
map.set("2300", "Contribuição Empresa/Empregador - Depósito Judicial");
map.set("2313", "Juros Bancários");
map.set(
  "2317",
  "Contribuição Devida a Outras Entidades e Fundos - Serviço Nacional de Aprendizagem Industrial -Senai - Lançamento de Ofício"
);
map.set(
  "2319",
  "IRPJ - PJ Obrigadas ao Lucro Real - Entidades Financeiras - Estimativa Mensal"
);
map.set("2321", "Outras Receitas Patrimoniais");
map.set(
  "2323",
  "Contribuição Devida a Outras Entidades e Fundos - Serviço Social da Industria - Sesi - Lançamento de Ofício"
);
map.set("2330", "Imp Importação - Operações Intra Orçamentárias");
map.set(
  "2346",
  "Contribuição Devida a Outras Entidades e Fundos - Serviço Nacional de Aprendizagem Comercial - Senac - Lançamento de Ofício"
);
map.set(
  "2352",
  "Contribuição Devida a Outras Entidades e Fundos - Serviço Social do Comercio - Sesc - Lançamento de Ofício"
);
map.set(
  "2362",
  "IRPJ- PJ Obrigadas ao Lucro Real - Entidades Não Financeiras - Estimativa Mensal"
);
map.set("2369", "Cide - Sebrae/Apex/ABDI - Lançamento de Ofício");
map.set(
  "2372",
  "CSLL - PJ que Apuram o IRPJ com Base em Lucro Presumido ou Arbitrado"
);
map.set(
  "2381",
  "Contribuição Devida a Outras Entidades e Fundos - Serviço Nacional de Aprendizagem do Cooperativismo - Sescoop - Lançamento de Ofício"
);
map.set(
  "2390",
  "IRPJ - PJ Obrigadas ao Lucro Real - Entidades Financeiras - Declaração de Ajuste"
);
map.set("2398", "Multa Isolada Compensação Previdenciária Indevida");
map.set("2401", "IPI - Bebidas - Operações Intra Orçamentárias");
map.set(
  "2408",
  "Multa Regulamentar Descumprimento de Obrigação Acessória Previdenciária - Lançamento de Ofício"
);
map.set("2410", "IPI - outros - Operações Intra Orçamentárias");
map.set("2414", "Glosa de Compensação Previdenciária - Lançamento de Ofício");
map.set("2420", "Multa Isolada Previdenciária - Depósito Judicial");
map.set("2428", "IPI - Vinculado Importação - Operações Intra Orçamentárias");
map.set(
  "2430",
  "IRPJ - PJ Obrigadas ao Lucro Real-Entidades Não Financeiras-Declaração de Ajuste"
);
map.set(
  "2450",
  "Contribuição Devida a Outras Entidades e Fundos - Salário Educação - Depósito Judicial"
);
map.set("2456", "IRPJ - PJ Não Obrigadas ao Lucro Real - Declaração de Ajuste");
map.set(
  "2466",
  "Contribuição Devida a Outras Entidades e Fundos - Serviço Nacional de Aprendizagem Rural - Senar - Depósito Judicial"
);
map.set("2469", "CSLL - Entidades Financeiras - Estimativa Mensal");
map.set(
  "2472",
  "Contribuição Devida a Outras Entidades e Fundos - Instituto Nacional de Colonização e Reforma Agrária - Incra - Depósito Judicial"
);
map.set(
  "2484",
  "CSLL - Demais PJ que Apuram o IRPJ com base em Lucro Real - Estimativa Mensal"
);
map.set(
  "2489",
  "Contribuição Devida a Outras Entidades e Fundos - Fundo Aeroviário - Depósito Judicial"
);
map.set(
  "2505",
  "Contribuição Devida a Outras Entidades e Fundos - Fundo de Desenvolvimento do Ensino Profissional Marítimo - FDEPM - Depósito Judicial"
);
map.set(
  "2528",
  "Contribuição Devida a Outras Entidades e Fundos - Serviço Nacional de Aprendizagem do Transporte - Senat - Depósito Judicial"
);
map.set(
  "2534",
  "Contribuição Devida a Outras Entidades e Fundos - Serviço Social de Transporte - Sest - Depósito Judicial"
);
map.set(
  "2557",
  "Contribuição Devida a Outras Entidades e Fundos - Serviço Nacional de Aprendizagem Industrial - Senai - Depósito Judicial"
);
map.set(
  "2563",
  "Contribuição Devida a Outras Entidades e Fundos - Serviço Social da Industria - Sesi - Depósito Judicial"
);
map.set(
  "2570",
  "Contribuição Devida a Outras Entidades e Fundos - Serviço Nacional de Aprendizagem Comercial - Senac - Depósito Judicial"
);
map.set(
  "2578",
  "Condecine - Contribuição para o Desenvolvimento da Indústria Cinematográfica Nacional"
);
map.set("2583", "Multa da Susep");
map.set(
  "2586",
  "Contribuição Devida a Outras Entidades e Fundos - Serviço Social do Comércio - Sesc - Depósito Judicial"
);
map.set("2592", "Cide - Sebrae/Apex/ABDI - Depósito Judicial");
map.set(
  "2602",
  "Contribuição Devida a Outras Entidades e Fundos - Serviço Nacional de Aprendizagem do Cooperativismo - Sescoop - Depósito Judicial"
);
map.set("2605", "IRPJ - Operações Intra Orçamentárias");
map.set("2612", "ITR - Operações Intra Orçamentárias");
map.set("2619", "Contribuição Segurado - Depósito Administrativo");
map.set("2625", "Contribuição Empresa/Empregador - Depósito Administrativo");
map.set("2654", "Multa Isolada Previdenciária - Depósito Administrativo");
map.set(
  "2655",
  "Cota Parte do Adicional ao Frete para Renovação da Marinha Mercante -AFRMM"
);
map.set("2663", "Serviços Administrativos - outros");
map.set("2671", "Outros Serviços - demais");
map.set(
  "2677",
  "Contribuição Devida a Outras Entidades e Fundos - Salário Educação - Depósito Administrativo"
);
map.set(
  "2683",
  "Contribuição Devida a Outras Entidades e Fundos - Serviço Nacional de Aprendizagem Rural - Senar - Depósito Administrativo"
);
map.set(
  "2687",
  "R D Ativa - Multas Previstas na Legislação sobre Defesa de Direitos Difusos"
);
map.set(
  "2716",
  "Contribuição Devida a Outras Entidades e Fundos - Instituto Nacional de Colonização e Reforma Agrária - Incra - Depósito Administrativo"
);
map.set(
  "2722",
  "Contribuição Devida a Outras Entidades e Fundos - Fundo Aeroviário - Depósito Administrativo"
);
map.set(
  "2739",
  "Contribuição Devida a Outras Entidades e Fundos - Fundo de Desenvolvimento do Ensino Profissional Marítimo - FDEPM - Depósito Administrativo"
);
map.set(
  "2745",
  "Contribuição Devida a Outras Entidades e Fundos - Serviço Nacional de Aprendizagem do Transporte - Senat - Depósito Administrativo"
);
map.set("2755", "Serviços Administrativos - DPRF");
map.set("2768", "IRPF - Conversão Depósito Judicial");
map.set(
  "2774",
  "Contribuição Devida a Outras Entidades e Fundos - Serviço Social de Transporte - Sest - Depósito Administrativo"
);
map.set(
  "2780",
  "Contribuição Devida a Outras Entidades e Fundos - Serviço Nacional de Aprendizagem Industrial - Senai - Depósito Administrativo"
);
map.set("2783", "IRPJ - Conversão Depósito Judicial");
map.set("2796", "IPI - Outros - Conversão Depósito Judicial");
map.set(
  "2797",
  "Contribuição Devida a Outras Entidades e Fundos - Serviço Social da Industria - Sesi - Depósito Administrativo"
);
map.set("2808", "IRRF - Conversão Depósito Judicial");
map.set("2810", "IOF - Conversão Depósito Judicial");
map.set(
  "2813",
  "Contribuição Devida a Outras Entidades e Fundos - Serviço Nacional de Aprendizagem Comercial - Senac - Depósito Administrativo"
);
map.set(
  "2820",
  "Contribuição Devida a Outras Entidades e Fundos - Serviço Social do Comercio - Sesc - Depósito Administrativo"
);
map.set("2836", "Finsocial - Conversão Depósito Judicial");
map.set("2842", "Cide - Sebrae/Apex/ABDI - Depósito Administrativo");
map.set("2849", "PIS - Conversão de Depósito Judicial");
map.set("2851", "CSLL - Conversão de Depósito Judicial");
map.set(
  "2859",
  "Contribuição Devida a Outras Entidades e Fundos - Serviço Nacional de Aprendizagem do Cooperativismo - Sescoop - Depósito Administrativo"
);
map.set("2864", "Honorários Advocatícios Sucumbência - PGFN");
map.set(
  "2865",
  "R D Ativa - Crédito Rural - Programa de Revitalização de Cooperativas de Produção Agropecuária - Recoop"
);
map.set(
  "2871",
  "R D Ativa - Multa Polícia Federal - Controle e Fiscalização de Produtos Químicos"
);
map.set("2877", "Multa destinada ao Fundo de Amparo ao Trabalhador - FAT");
map.set("2880", "Conversão Depósito Judicial - outros");
map.set(
  "2888",
  "R D Ativa - Multas Diversas - Secretaria do Patrimônio Público"
);
map.set("2892", "II - Imposto Importação - Lançamento de Ofício");
map.set(
  "2894",
  "R D Ativa - Fundo de Investimentos Setoriais - Fiset - Florestamento e Reflorestamento"
);
map.set("2904", "IRPF - Lançamento de Ofício");
map.set("2917", "IRPJ - Lançamento de Ofício");
map.set("2927", "IOF - Contrato de Derivativos");
map.set("2932", "IRRF - Lançamento de Ofício");
map.set("2933", "Regime de Tributação Unificada - RTU - Importação");
map.set("2945", "IPI - Lançamento de Ofício");
map.set(
  "2956",
  "Regime de Tributação Unificada - RTU - Importação - Lançamento de Ofício"
);
map.set("2958", "IOF - Lançamento de Ofício");
map.set("2960", "Cofins - Lançamento de Ofício");
map.set("2973", "CSLL - Lançamento de Ofício");
map.set(
  "2979",
  "Pasep - Retenção sobre as Transferências Efetuadas para Estados, DF e Municípios"
);
map.set(
  "2985",
  "Contribuição Previdenciária Sobre Receita Bruta - Art. 7º da Lei 12.546/2011"
);
map.set("2986", "PIS - Lançamento de Ofício");
map.set(
  "2991",
  "Contribuição Previdenciária Sobre Receita Bruta - Art. 8º da Lei 12.546/2011"
);
map.set("2999", "Pasep - Lançamento de Ofício");
map.set("3008", "Royalties Até 5% - Lavra na Área Pré-Sal - Em Plataforma");
map.set(
  "3014",
  "Royalties Excedente 5% - Lavra na Área Pré-Sal - Em Plataforma"
);
map.set("3037", "Participação Especial - Lavra na Área Pré-Sal");
map.set(
  "3043",
  "Lei nº 11.941, de 2009 - PGFN - Parcelamento de Dívida Decorrente de Aproveitamento Indevido de Créditos do IPI - Art. 2º - Depósito Judicial"
);
map.set(
  "3066",
  "Lei nº 11.941/2009 - PGFN - Débitos Previdenciários - Parcelamento de Saldo Remanescente dos Programas Refis, Paes, Paex e Parcelamentos Ordinários - Art. 3º - Depósito Judicial"
);
map.set("3072", "Regularização de Depósitos - Lei nº 12.099, de 2009");
map.set("3084", "Pasep - Receita Operacional");
map.set(
  "3089",
  "Lei nº 11.941, de 2009 - PGFN - Demais Débitos - Parcelamento de Saldo Remanescente dos Programas Refis, Paes, Paex e Parcelamentos Ordinários - Art. 3º - Depósito Judicial"
);
map.set("3092", "Pasep - Folha de Pagamento");
map.set(
  "3095",
  "Lei nº 11.941, de 2009 - RFB - Parcelamento de Dívida Decorrente de Aproveitamento Indevido de Créditos do IPI - Art. 2º- Depósito Judicial"
);
map.set(
  "3111",
  "Lei nº 11.941, de 2009 - RFB - Débitos Previdenciários - Parcelamento de Saldo Remanescente dos Programas Refis, Paes, Paex e Parcelamentos Ordinarios - Art. 3º- Depósito Judicial"
);
map.set("3121", "PIS - Operações Intra Orçamentárias");
map.set(
  "3128",
  "Lei nº 11.941, de 2009 - RFB - Demais Débitos - Parcelamento de Saldo Remanescente dos Programas Refis, Paes, Paex e Parcelamentos Ordinários - Art. 3º- Depósito Judicial"
);
map.set("3134", "R D Ativa - IRPJ - Lançado de Ofício - Simples Nacional");
map.set("3140", "R D Ativa - CSLL - Lançado de Ofício - Simples Nacional");
map.set(
  "3148",
  "Multa Isolada sobre Créditos em Pedidos de Ressarcimento ou Declaração de Compensação"
);
map.set("3157", "R D Ativa - Cofins - Lançado de Ofício - Simples Nacional");
map.set("3163", "R D Ativa - PIS - Lançado de Ofício - Simples Nacional");
map.set("3186", "R D Ativa - IPI - Lançado de Ofício - Simples Nacional");
map.set(
  "3192",
  "R D Ativa - Contribuição Previdenciária Patronal - Lançado de Ofício - Simples Nacional"
);
map.set("3199", "Multa por Atraso na Entrega da Declaração de IPI");
map.set("3202", "R D Ativa - Contribuição Previdenciária Sobre Receita Bruta");
map.set("3208", "IRRF - Aluguéis e Royalties Pagos a Pessoa Física");
map.set(
  "3219",
  "IRPJ - Ganho de Capital - Alienação de Ativos - Simples Nacional - Lançamento de Ofício"
);
map.set(
  "3223",
  "IRRF - Resgate Previdência Complementar/Modalidade Contribuição Definida/Variável - Não Optante Tributação Exclusiva"
);
map.set(
  "3225",
  "IRPJ - Ganhos Líquidos em Operações na Bolsa - Simples Nacional"
);
map.set("3231", "Imposto Importação - Remessa Postal");
map.set("3248", "Multa Aduaneira - Remessa Postal");
map.set("3249", "IRRF - Ouro Ativo Financeiro");
map.set(
  "3251",
  "IRRF - Juros Caderneta de Poupança e Letras Hipotecárias - Pessoa Jurídica"
);
map.set("3254", "Imposto Importação - Remessa Postal - Lançamento de Ofício");
map.set(
  "3260",
  "CSLL - Retenção na Fonte sobre Pagamentos de Pessoa Jurídica a outra Pessoa Jurídica de Direito Privado - Lançamento de Ofício"
);
map.set("3277", "IRRF - Rendimentos de Partes Beneficiárias ou de Fundador");
map.set("3280", "IRRF - Rem Serv Prest Associad Coop Trabalho");
map.set(
  "3290",
  "R D Ativa - Multa Destinada ao Fundo de Amparo ao Trabalhador - FAT"
);
map.set("3292", "Fundaf - Ressarcimento por Cópias");
map.set("3300", "CPSS - Não Patronal - Depósito Judicial");
map.set("3304", "Fundaf - Demais Receitas");
map.set(
  "3308",
  "CSLL - Retenção na Fonte sobre Pagamentos de Empresa Pública, Sociedade de Economia Mista e outras Entidades a Pessoa Jurídica de Direito Privado - Lançamento de Ofício"
);
map.set(
  "3316",
  "Cofins - Retenção na Fonte sobre Pagamentos de Pessoa Jurídica a outra Pessoa Jurídica de Direito Privado - Lançamento de Ofício"
);
map.set("3317", "IRPJ - Ganhos Líquidos em Operações na Bolsa - Lucro Real");
map.set("3320", "IRPJ - Lucro Inflacionário");
map.set("3322", "CPSS - Não Patronal - Depósito Administrativo");
map.set(
  "3332",
  "Cofins - Retenção na Fonte sobre Pagamentos de Empresa Pública, Sociedade de Economia Mista e outras Entidades a Pessoa Jurídica de Direito Privado - Lançamento de Ofício"
);
map.set(
  "3339",
  "R D Ativa - Pagamento Unificado - Incorporações - Programa Minha Casa, Minha Vida - PMCMV"
);
map.set("3345", "IPI - Vinculado Importação - Lançamento de Ofício");
map.set("3351", "Doações aos Fundos dos Direitos da Criança e do Adolescente");
map.set(
  "3359",
  "PIS - Retenção na Fonte sobre Pagamentos de Pessoa Jurídica a outra Pessoa Jurídica de Direito Privado - Lançamento de Ofício"
);
map.set(
  "3360",
  "PIS - Retenção na Fonte sobre Pagamentos de Empresa Pública, Sociedade de Economia Mista e outras Entidades a Pessoa Jurídica de Direito Privado - Lançamento de Ofício"
);
map.set(
  "3368",
  "R D Ativa - Pagamento Unificado - Construções - Programa Minha Casa, Minha Vida - PMCMV"
);
map.set("3373", "IRPJ - PJ Não Obrigadas ao Lucro Real - Balanço Trimestral");
map.set(
  "3374",
  "Multa do Regime Disciplinar Aplicada à Rede Arrecadadora do Simples Nacional - RAS"
);
map.set(
  "3375",
  "IRPJ - Retenção na Fonte sobre Pagamentos de Empresa Pública, Sociedade de Economia Mista e outras Entidades a Pessoa Jurídica de Direito Privado - Lançamento de Ofício"
);
map.set("3391", "Multa de Outras Origens");
map.set(
  "3397",
  "Multa Administrativa por Falta de Pagamento de Lote Arrematado em Leilão"
);
map.set("3399", "Devolução de Restituição Indevida - II - Não Tributário");
map.set("3407", "Devolução de Restituição Indevida - IE - Não Tributário");
map.set("3412", "Devolução de Restituição Indevida - ITR - Não Tributário");
map.set("3413", "Devolução de Restituição Indevida - IPI - Não Tributário");
map.set(
  "3426",
  "IRRF - Aplicações Financeiras de Renda Fixa - Pessoa Jurídica"
);
map.set("3436", "Devolução de Restituição Indevida - IOF - Não Tributário");
map.set("3442", "Devolução de Restituição Indevida - CSLL - Não Tributário");
map.set("3459", "Devolução de Restituição Indevida - CIDE - Não Tributário");
map.set(
  "3465",
  "Devolução de Restituição Indevida - PIS/Pasep - Não Tributário"
);
map.set("3467", "IOF - Seguros");
map.set("3471", "Multas Previstas no Código Eleitoral e Leis Conexas");
map.set(
  "3482",
  "Multa Isolada - Contribuições Sociais e Imposto de Renda Retidos na Fonte - CSIRF (art. 43 L. 9430)"
);
map.set(
  "3488",
  "Multa Por Falta de Retenção na Fonte de Imposto ou Contribuição"
);
map.set(
  "3494",
  "Doação ao Fundo Nacional de Desenvolvimento Científico e Tecnológico - FNDCT"
);
map.set(
  "3495",
  "Juros Isolados - Contribuições Sociais e Imposto de Renda Retidos na Fonte - CSIRF (art. 43 L. 9430)"
);
map.set("3498", "Indenizações - Demais");
map.set(
  "3504",
  "Devolução de Restituição Indevida - Contribuição Previdenciária - Não Tributário"
);
map.set("3527", "Receita Dívida Ativa - Imposto Importação");
map.set(
  "3533",
  "IRRF - Provento de aposentadoria, reserva ou reforma e de pensão civil ou militar pago por Previdência da União, Estados, DF ou Municípios (regime geral ou do servidor público)"
);
map.set(
  "3540",
  "IRRF - Benefício previdenciário, modalidade beneficio ou contrib definido ou de contrib variável, pagos por prev. complementar ou soc. seguradora, ou Fapi, não op tribut exclusiva"
);
map.set("3543", "Receita Dívida Ativa - IRPF");
map.set("3548", "IRPJ - Cobrança");
map.set("3550", "IRRF - Rendimento do Trabalho - Cobrança");
map.set("3551", "Receita Dívida Ativa - IRPJ");
map.set(
  "3556",
  "IRRF - Resgate de valores acumulados relativos a planos de caráter previdenciário estruturados na modalidade de beneficio definido, não optantes pela tributação exclusiva"
);
map.set("3560", "Receita Dívida Ativa - IRRF");
map.set(
  "3562",
  "IRRF - Participação dos trabalhadores nos lucros ou resultados (PLR) objeto de negociação entre a empresa e seus empregados"
);
map.set("3563", "IRRF - Rendimentos de Capital - Cobrança");
map.set("3576", "IRRF - Outros Rendimentos - Cobrança");
map.set("3578", "Receita Dívida Ativa - IPI");
map.set(
  "3579",
  "IRRF - Resgate previdência complementar/socied. seguradora (modalidade contribuição definida/variável), seguro vida cobertura por sobrevivência ou Fapi - Optantes Trib. Exclusiva"
);
map.set("3585", "R D Ativa - Contribuição para o Funttel");
map.set("3589", "IPI - Bebidas - Cobrança");
map.set("3591", "IPI - outros - Cobrança");
map.set(
  "3601",
  "Contribuição Previdenciária Sobre a Receita Bruta - Lançamento de Ofício"
);
map.set("3603", "IOF - Cobrança");
map.set("3607", "Restituições - outras");
map.set("3615", "Receita Dívida Ativa - outros Tributos - Principal");
map.set("3616", "PIS - Cobrança");
map.set("3618", "Compensação Previdenciária Indevida em GFIP");
map.set("3623", "Receita Dívida Ativa - Multa CLT");
map.set(
  "3624",
  "Multa por Atraso na Entrega da Escrituração Contábil Fiscal (ECF)"
);
map.set("3629", "Pasep - Cobrança");
map.set(
  "3630",
  "Multa por falta ou atraso na entrega da Escrituração Fiscal Digital - EFD-ICMS/IPI"
);
map.set("3640", "Receita Dívida Ativa - outras");
map.set("3644", "Cofins - Cobrança");
map.set(
  "3647",
  "Encargos por recolhimento fora do prazo - Documento de Arrecadação do Empregador Doméstico (DAE)"
);
map.set(
  "3653",
  "Encargos por repasse fora do prazo - Instituição Financeira Centralizadora - Documento de Arrecadação do Empregador Doméstico (DAE)"
);
map.set("3657", "CSLL - Cobrança");
map.set(
  "3676",
  "Multa por atraso no envio de informações de Alvarás e/ou Habite-se de Obras de Construção Civil por parte das Prefeituras e GDF via SisobraPref à RFB"
);
map.set(
  "3682",
  "Multa Isolada - Contribuições Sociais Retidas na Fonte - CSRF (art. 43 L. 9430)"
);
map.set("3690", "Receita Decorrente de Bens e Valores Apreendidos - outros");
map.set(
  "3699",
  "IRRF - Rendimentos produzidos por aplicação financeira a que se refere o art. 2º da Lei nº 12.431, de 2011. (Decreto nº 7.603/2012)."
);
map.set("3703", "Pasep - Pessoa Jurídica de Direito Publico");
map.set(
  "3709",
  "AFRMM - Empresa Estrangeira de Navegação ou Empresa Brasileira de Navegação, Operando Embarcação Afretada de Registro Estrangeiro"
);
map.set(
  "3711",
  "Juros Isolados - Contribuições Sociais Retidas na Fonte - CSRF (art. 43 L. 9430)"
);
map.set(
  "3738",
  "Multas Diversas Aplicadas pela Receita Federal, Exceto Aduaneira"
);
map.set("3746", "Cofins - Retenção na Fonte - Aquisição de Autopeças");
map.set("3762", "Outras Receitas Eventuais");
map.set(
  "3767",
  "AFRMM - Adicional ao Frete para Renovação da Marinha Mercante - Lançamento de Ofício"
);
map.set("3770", "PIS - Retenção na Fonte - Aquisição de Autopeças");
map.set(
  "3773",
  "TUM - Taxa de Utilização do Sistema de Controle de Arrecadação do Adicional ao Frete para Renovação da Marinha Mercante (MERCANTE)"
);
map.set(
  "3780",
  "Reabertura Lei nº 11.941, de 2009 - PGFN - Débitos Previdenciários - Parcelamento de Dívidas Não Parceladas Anteriormente - Art. 1º"
);
map.set(
  "3796",
  "Reabertura Lei nº 11.941, de 2009 - PGFN - Débitos Previdenciários - Parcelamento de Saldo Remanescente dos Programas Refis, Paes, Paex e Parcelamentos Ordinários - Art. 3º"
);
map.set(
  "3812",
  "Reabertura Lei nº 11.941/2009 - PGFN - Débitos Previdenciários - Pagamento à vista com utilização de Prejuízo Fiscal e Base de Cálculo Negativa da CSLL para liquidar multa e juros"
);
map.set(
  "3829",
  "Reabertura Lei nº 11.941, de 2009 - PGFN - Demais Débitos - Pagamento à vista com utilização de Prejuízo Fiscal e Base de Cálculo Negativa da CSLL para liquidar multa e juros"
);
map.set(
  "3835",
  "Reabertura Lei nº 11.941, de 2009 - PGFN - Demais Débitos - Parcelamento de Dívidas Não Parceladas Anteriormente - Art. 1º"
);
map.set(
  "3841",
  "Reabertura Lei nº 11.941, de 2009 - PGFN - Demais Débitos - Parcelamento de Saldo Remanescente dos Programas Refis, Paes, Paex e Parcelamentos Ordinários - Art. 3º"
);
map.set(
  "3842",
  "IRRF - Pagamentos a Pessoa Jurídica Fornecedora de Insumos ou Transportadora de Carga que geram Direito ao Crédito Presumido"
);
map.set(
  "3850",
  "IRRF - Pagamentos a Pessoa Física Fornecedora de Insumos ou Transportadora Autônoma de Carga que geram Direito ao Crédito Presumido"
);
map.set(
  "3858",
  "Reabertura Lei nº 11.941, de 2009 - PGFN - Parcelamento Dívida Decorrente de Aproveitamento Indevido de Créditos de IPI - Art. 2º"
);
map.set("3864", "Multa por Atraso/Erro/Omissão - Siscoserv");
map.set(
  "3870",
  "Reabertura Lei nº 11.941, de 2009 - RFB - Débitos Previdenciários - Parcelamento de Dívidas Não Parceladas Anteriormente - Art. 1º"
);
map.set(
  "3877",
  "CSLL - Pagamentos a Pessoa Jurídica Fornecedora de Insumos ou Transportadora de Carga que geram Direito ao Crédito Presumido"
);
map.set("3885", "PIS - Receita Operacional");
map.set(
  "3887",
  "Reabertura Lei nº 11.941, de 2009 - RFB - Débitos Previdenciários - Parcelamento de Saldo Remanescente dos Programas Refis, Paes, Paex e Parcelamentos Ordinários - Art. 3º"
);
map.set("3890", "II - Imposto Importação - Conversão Depósito Judicial");
map.set(
  "3903",
  "Reabertura Lei nº 11.941/2009 - RFB - Débitos Previdenciários - Pagamento à vista com utilização de Prejuízo Fiscal e Base de Cálculo Negativa da CSLL para liquidar multa e juros"
);
map.set(
  "3910",
  "Reabertura Lei nº 11.941, de 2009 - RFB - Demais Débitos - Pagamento à vista com utilização de Prejuízo Fiscal e Base de Cálculo Negativa da CSLL para liquidar multa e juros"
);
map.set("3914", "Parcelamento - Imóveis Extinta RFFSA - SPU");
map.set(
  "3926",
  "Reabertura Lei nº 11.941, de 2009 - RFB - Demais Débitos - Parcelamento de Dívidas Não Parceladas Anteriormente - Art. 1º"
);
map.set("3928", "IPI - Vinculado Importação - Conversão Depósito Judicial");
map.set(
  "3932",
  "Reabertura Lei nº 11.941, de 2009 - RFB - Demais Débitos - Parcelamento de Saldo Remanescente dos Programas Refis, Paes, Paex e Parcelamentos Ordinários - Art. 3º"
);
map.set(
  "3955",
  "Reabertura Lei nº 11.941, de 2009 - RFB - Parcelamento Dívida Decorrente de Aproveitamento Indevido de Créditos de IPI - Art. 2º"
);
map.set("3961", "Royalties Até 5% - Art. 2º da Lei nº 12.858/2013");
map.set("3978", "Royalties Excedente a 5% - Art. 2º da Lei nº 12.858/2013");
map.set("3981", "Produto Depósitos Abandonados");
map.set("3990", "Participação Especial - Art. 2º da Lei nº 12.858/2013");
map.set(
  "4007",
  "Lei nº 12.865, de 2013 - RFB - Parcelamento PIS/COFINS - Instituições Financeiras e Cia Seguradoras - Art. 39, Caput"
);
map.set(
  "4013",
  "Lei nº 12.865, de 2013 - PGFN - Parcelamento PIS/COFINS - Instituições Financeiras e Cia Seguradoras - Art. 39, Caput"
);
map.set(
  "4020",
  "Lei 12.865, de 2013 - RFB - Parcelamento PIS/COFINS - Art. 39, § 1º"
);
map.set("4028", "IOF - Ouro Ativo Financeiro");
map.set(
  "4042",
  "Lei 12.865, de 2013 - PGFN - Parcelamento PIS/COFINS - Art. 39, § 1º"
);
map.set(
  "4059",
  "Lei nº 12.865, de 2013 - RFB - Parcelamento IRPJ/CSLL - Art. 40"
);
map.set(
  "4065",
  "Lei nº 12.865, de 2013 - PGFN - Parcelamento IRPJ/CSLL - Art. 40"
);
map.set(
  "4071",
  "Lei nº 12.865, de 2013 - RFB - Pagamento à Vista - PIS/COFINS - Instituições Financeiras e Cia Seguradoras - Art. 39, Caput"
);
map.set(
  "4072",
  "Fundaf-Atividade Extraordinária de Fiscalização e Controle Aduaneiro"
);
map.set(
  "4085",
  "Retenção de Contribuições sobre Pagamentos dos Estados, DF e Municípios pela Aquisição de Bens e Serviços - CSLL, Cofins e PIS"
);
map.set(
  "4088",
  "Lei nº 12.865, de 2013 - PGFN - Pagamento à Vista - PIS/COFINS - Instituições Financeiras e Cia Seguradoras - Art. 39, Caput"
);
map.set(
  "4094",
  "Lei 12.865, de 2013 - RFB - Pagamento à Vista - PIS/COFINS - Art. 39, § 1º"
);
map.set("4095", "Regime Especial de Tributação do Patrimônio de Afetação");
map.set(
  "4104",
  "Lei 12.865, de 2013 - PGFN - Pagamento à Vista - PIS/COFINS - Art. 39, § 1º"
);
map.set(
  "4110",
  "Lei nº 12.865, de 2013 - RFB - Pagamento à Vista - IRPJ/CSLL - Art. 40"
);
map.set(
  "4112",
  "IRPJ - Regime Especial de Tributação do Patrimônio de Afetação"
);
map.set(
  "4127",
  "Lei nº 12.865, de 2013 - PGFN - Pagamento à Vista - IRPJ/CSLL - Art. 40"
);
map.set("4133", "R D Ativa - Contribuição Previdenciária Segurados");
map.set(
  "4138",
  "PIS - Regime Especial de Tributação do Patrimônio de Afetação"
);
map.set(
  "4153",
  "CSLL - Regime Especial de Tributação do Patrimônio de Afetação"
);
map.set("4156", "R D Ativa - Contribuição Empresa/Empregador");
map.set(
  "4162",
  "R D Ativa - Contribuição Risco Ambiental/Aposentadoria Especial"
);
map.set(
  "4166",
  "Cofins - Regime Especial de Tributação do Patrimônio de Afetação"
);
map.set("4181", "IRPF - Cobrança");
map.set("4185", "R D Ativa - Contribuição Sujeita a Retenção Previdenciária");
map.set(
  "4201",
  "R D Ativa - Contribuição Devida a Outras Entidades e Fundos - Salário Educação"
);
map.set(
  "4218",
  "R D Ativa - Contribuição Devida a Outras Entidades e Fundos - Serviço Nacional de Aprendizagem Rural - Senar"
);
map.set("4221", "IPMF - Conversão Depósito Judicial");
map.set(
  "4224",
  "R D Ativa - Contribuição Devida a Outras Entidades e Fundos - Instituto Nacional de Colonização e Reforma Agrária - Incra"
);
map.set(
  "4230",
  "R D Ativa - Contribuição Devida a Outras Entidades e Fundos - Fundo Aeroviário"
);
map.set("4234", "Cofins - Conversão Depósito Judicial");
map.set("4250", "Aneel - Parcelas da Reserva Global de Reversão");
map.set(
  "4253",
  "R D Ativa - Contribuição Devida a Outras Entidades e Fundos - Fundo de Desenvolvimento do Ensino Profissional Marítimo - FDEPM"
);
map.set(
  "4260",
  "R D Ativa - Contribuição Devida a Outras Entidades e Fundos - Serviço Nacional de Aprendizagem do Transporte - Senat"
);
map.set(
  "4262",
  "Multa do Regime Disciplinar Aplicada a Rede Arrecadadora de Receitas Federais"
);
map.set(
  "4263",
  "Receita Dívida Ativa - Ressarcimentos ao Fundo Nacional de Saúde - FNS"
);
map.set("4275", "CPSSS - Contribuição Patronal para o Servidor Civil");
map.set(
  "4276",
  "R D Ativa - Contribuição Devida a Outras Entidades e Fundos - Serviço Social de Transporte - Sest"
);
map.set(
  "4282",
  "R D Ativa - Contribuição Devida a Outras Entidades e Fundos - Serviço Nacional de Aprendizagem Industrial -Senai"
);
map.set("4288", "Multa por Nota Fiscal");
map.set("4290", "IOF - Operações Externas");
map.set("4298", "Alienação outros Bens Móveis - outros");
map.set(
  "4299",
  "R D Ativa - Contribuição Devida a Outras Entidades e Fundos - Serviço Social da Industria - Sesi"
);
map.set("4300", "Alienação do Domínio Útil de Bens Imóveis - SPU");
map.set(
  "4309",
  "R D Ativa - Contribuição Devida a Outras Entidades e Fundos - Serviço Nacional de Aprendizagem Comercial - Senac"
);
map.set(
  "4321",
  "R D Ativa - Contribuição Devida a Outras Entidades e Fundos - Serviço Social do Comercio - Sesc"
);
map.set("4327", "Alienação do Domínio Pleno de Bens Imóveis - SPU");
map.set("4335", "Alienação outros Bens Imóveis - Demais");
map.set("4338", "R D Ativa - Cide - Sebrae/Apex/ABDI");
map.set(
  "4344",
  "R D Ativa - Contribuição Devida a Outras Entidades e Fundos - Serviço Nacional de Aprendizagem do Cooperativismo - Sescoop"
);
map.set(
  "4350",
  "R D Ativa - Multa Isolada Compensação Previdenciária Indevida"
);
map.set(
  "4373",
  "R D Ativa - Multa Regulamentar Descumprimento de Obrigação Acessória Previdenciária"
);
map.set("4380", "R D Ativa - Glosa de Compensação Previdenciária");
map.set(
  "4396",
  "Parcelamento de Arrematação - Primeira Parcela - Depósito Judicial"
);
map.set(
  "4397",
  "CSLL - Retenção na Fonte sobre Pagamentos dos Estados, DF e Municípios pela Aquisição de Bens e Serviços"
);
map.set("4406", "Multa por Atraso na Entrega do PGDAS-D");
map.set(
  "4407",
  "Cofins - Retenção na Fonte sobre Pagamentos dos Estados, DF e Municípios pela Aquisição de Bens e Serviços"
);
map.set(
  "4409",
  "PIS - Retenção na Fonte sobre Pagamentos dos Estados, DF e Municípios pela Aquisição de Bens e Serviços"
);
map.set(
  "4412",
  "Reabertura Lei nº 11.941, de 2009 - PGFN - Demais Débitos - Parcelamento de Dívidas Não Parceladas Anteriormente - Art. 1º - Depósito Judicial"
);
map.set(
  "4429",
  "Reabertura Lei nº 11.941, de 2009 - PGFN - Débitos Previdenciários - Parcelamento de Dívidas Não Parceladas Anteriormente - Art. 1º - Depósito Judicial"
);
map.set(
  "4435",
  "Reabertura Lei 11941/09 - PGFN - Débitos Previdenciários - Parcelamento de Saldo Remanescente dos Programas Refis, Paes, Paex e Parcelamento Ordinário - Art. 3º - Depósito Judicial"
);
map.set(
  "4437",
  "Contribuição sobre Prognósticos Esportivos - Direitos Prescritos de Entidades Desportivas"
);
map.set(
  "4464",
  "Reabertura Lei nº 11941, de 2009 - PGFN - Demais Débitos - Parcelamento de Saldo Remanescente dos Programas Refis, Paes, Paex e Parcelamento Ordinário - Art. 3º - Depósito Judicial"
);
map.set(
  "4470",
  "Reabertura Lei nº 11.941, de 2009 - PGFN - Parcelamento Dívida Decorrente de Aproveitamento Indevido de Créditos de IPI - Art. 2º - Depósito Judicial"
);
map.set(
  "4480",
  "Receita Dívida Ativa - Multa por Infração ao Código Brasileiro de Aeronáutica"
);
map.set(
  "4487",
  "Reabertura Lei nº 11.941, de 2009 - RFB - Débitos Previdenciários - Parcelamento de Dívidas Não Parceladas Anteriormente - Art. 1º - Depósito Judicial"
);
map.set("4490", "Alienação Títulos Mobiliários - outros");
map.set("4493", "Receita Dívida Ativa - Cofins");
map.set(
  "4510",
  "Reabertura Lei 11941/2009 - RFB - Débitos Previdenciários - Parcelamento de Saldo Remanescente dos Programas Refis, Paes, Paex e Parcelamento Ordinário - Art 3º - Depósito Judicial"
);
map.set(
  "4526",
  "Reabertura Lei nº 11.941, de 2009 - RFB - Demais Débitos - Parcelamento de Dívidas Não Parceladas Anteriormente - Art. 1º - Depósito Judicial"
);
map.set(
  "4532",
  "Reabertura Lei nº 11.941, de 2009 - RFB - Demais Débitos - Parcelamento de Saldo Remanescente dos Programas Refis, Paes, Paex e Parcelamento Ordinário - Art. 3º - Depósito Judicial"
);
map.set("4533", "Receita Dívida Ativa - Proex");
map.set(
  "4549",
  "Reabertura Lei nº 11.941, de 2009 - RFB - Parcelamento Dívida Decorrente de Aproveitamento Indevido de Créditos de IPI - Art. 2º - Depósito Judicial"
);
map.set("4562", "PIS - Importação de Produtos - Lançamento de Ofício");
map.set("4574", "PIS - Entidades Financeiras e Equiparadas");
map.set("4578", "Encargos por Recolhimento fora do Prazo - Simples Nacional");
map.set(
  "4584",
  "Encargos por Repasse fora do Prazo - InstituiÃ§Ã£o Financeira Centralizadora - Simples Nacional"
);
map.set("4600", "IRPF - Ganhos de Capital na Alienação de Bens Duráveis");
map.set("4601", "Cota Parte - Preço de Realização de Combustível");
map.set(
  "4614",
  "Conversão Depósito Judicial - Adicional ao Frete para Renovação Marinha Mercante"
);
map.set(
  "4617",
  "Lei nº 12.865, de 2013 - RFB - Parcelamento PIS/COFINS - Instituições Financeiras e Cia Seguradoras - Art. 39, Caput - Depósito Judicial"
);
map.set(
  "4623",
  "Lei nº 12.865, de 2013 - PGFN - Parcelamento PIS/COFINS - Instituições Financeiras e Cia Seguradoras - Art. 39, Caput - Depósito Judicial"
);
map.set("4634", "Devolução Restituições não Pagas IRPF");
map.set(
  "4646",
  "Lei nº 12.865, de 2013 - RFB - Parcelamento PIS/COFINS - Art. 39, § 1º - Depósito Judicial"
);
map.set(
  "4652",
  "Lei nº 12.865, de 2013 - PGFN - Parcelamento PIS/COFINS - Art. 39, § 1º - Depósito Judicial"
);
map.set(
  "4675",
  "Lei nº 12.865, de 2013 - RFB - Parcelamento IRPJ/CSLL - Art. 40 - Depósito Judicial"
);
map.set("4677", "Restituições - IPI");
map.set(
  "4681",
  "Lei nº 12.865, de 2013 - PGFN - Parcelamento IRPJ/CSLL - Art. 40 - Depósito Judicial"
);
map.set("4685", "Cofins - Importação de Bens - Lançamento de Ofício");
map.set(
  "4698",
  "R D Ativa - Regime Especial - Pagamento Unificado - Obras em Creches e Pré-Escolas"
);
map.set(
  "4720",
  "Lei nº 12.996, de 2014 - PGFN - Débitos Previdenciários - Parcelamento"
);
map.set(
  "4737",
  "Lei nº 12.996, de 2014 - PGFN - Demais Débitos - Parcelamento"
);
map.set(
  "4743",
  "Lei nº 12.996, de 2014 - RFB - Débitos Previdenciários - Parcelamento"
);
map.set("4750", "Lei nº 12.996, de 2014 - RFB - Demais Débitos - Parcelamento");
map.set(
  "4766",
  "Lei nº 12.996, de 2014 - PGFN - Débitos Previdenciários - Pagamento à vista com utilização de Prejuízo Fiscal e Base de Cálculo Negativa da CSLL"
);
map.set(
  "4772",
  "Lei nº 12.996, de 2014 - PGFN - Demais Débitos - Pagamento à vista com utilização de Prejuízo Fiscal e Base de Cálculo Negativa da CSLL"
);
map.set(
  "4789",
  "Lei nº 12.996, de 2014 - RFB - Débitos Previdenciários - Pagamento à vista com utilização de Prejuízo Fiscal e Base de Cálculo Negativa da CSLL"
);
map.set(
  "4795",
  "Lei nº 12.996, de 2014 - RFB - Demais Débitos - Pagamento à vista com utilização de Prejuízo Fiscal e Base de Cálculo Negativa da CSLL"
);
map.set(
  "4805",
  "Taxa pela Utilização do Selo de Controle - Lei nº 12.995, de 2014 - Artigo 13 - Inciso I"
);
map.set("4810", "Ressarcimento Financeiro - Dec 97459");
map.set(
  "4811",
  "Taxa pela Utilização dos Equipamentos Contadores de Produção - Lei nº 12.995, de 2014 - Artigo 13 - Inciso II"
);
map.set("4834", "R D Ativa - Multa Isolada");
map.set(
  "4840",
  "Multa - Parcela não Restituída por Infração à Legislação - Dolo ou Má-Fé do Contribuinte - Pessoa Física"
);
map.set(
  "4857",
  "Contribuição Previdenciária sobre a Receita Bruta de Associações Desportivas que Mantêm Equipe de Futebol Profissional em Substituição à Contribuição Patronal -Lançamento de Ofício"
);
map.set(
  "4863",
  "Contribuição Previdenciária sobre a Comercialização da Produção Rural - Lançamento de Ofício"
);
map.set(
  "4892",
  "Lei nº 12.996, de 2014 - PGFN - Débitos Previdenciários - Parcelamento - Depósito Judicial"
);
map.set(
  "4902",
  "Lei nº 12.996, de 2014 - PGFN - Demais Débitos - Parcelamento - Depósito Judicial"
);
map.set(
  "4919",
  "Lei nº 12.996, de 2014 - RFB - Débitos Previdenciários - Parcelamento - Depósito Judicial"
);
map.set(
  "4931",
  "Lei nº 12.996, de 2014 - RFB - Demais Débitos - Parcelamento - Depósito Judicial"
);
map.set("4983", "Parcelamento - IRPJ/CSLL - Ganho de Capital - RFB");
map.set("4990", "Parcelamento - IRPJ/CSLL - Ganho de Capital - PGFN");
map.set(
  "5006",
  "TUM - Taxa de Utilização do Sistema de Controle de Arrecadação do Adicional ao Frete para Renovação da Marinha Mercante (MERCANTE) - Lançamento de Ofício"
);
map.set("5020", "Outros Serviços Comerciais");
map.set(
  "5029",
  "IRRF - Ganho de Capital - Integralização de Cotas com Ativos - Lei nº 13.043/2014 (Art. 1º)"
);
map.set(
  "5035",
  "IRRF - Empréstimo de Ativos - Fundos de Investimento - Lei nº 13.043/2014 (Art. 8º)"
);
map.set(
  "5041",
  "Contribuição Previdenciária sobre a Folha de Pagamento de Benefícios do Regime Geral de Previdência Social"
);
map.set("5042", "IPI - Devolução de Crédito Negativo");
map.set(
  "5058",
  "Regime de Tributação Simplificada - Mercadoria Importada não Identificada - Art. 67 da Lei nº 10.833/2003 - Lançamento de Ofício"
);
map.set(
  "5064",
  "Parcelamento Programa de Modernização da Gestão e de Responsabilidade Fiscal do Futebol Brasileiro (Profut) - Demais Débitos - RFB"
);
map.set(
  "5087",
  "Parcelamento Programa de Modernização da Gestão e de Responsabilidade Fiscal do Futebol Brasileiro (Profut) - Demais Débitos - PGFN"
);
map.set(
  "5103",
  "Devolução de Restituição Indevida - Contribuição para o Plano de Seguridade Social do Servidor - CPSS - Não Tributário"
);
map.set(
  "5110",
  "IPI - Tabaco e seus Sucedâneos Manufaturados, Exceto Cigarros Contendo Tabaco"
);
map.set("5123", "IPI - Demais Produtos");
map.set(
  "5132",
  "Multa por Falta ou Atraso na Prestação de Informação sobre Veículo/Carga Transportada/Operação Executada"
);
map.set("5135", "Tributos Extintos");
map.set("5149", "Multa Aplicada pelo Setor Aduaneiro - Com Redução");
map.set(
  "5155",
  "Depósitos Judiciais - Royalties e/ou Participação Especial - DJE"
);
map.set("5160", "Saldos de Receitas Excluídas e ou Extintas");
map.set("5161", "Programa de Regularização Tributária Rural (PRR)");
map.set(
  "5164",
  "Contribuição sobre a Receita de Concursos e Prognósticos - Fundo Penitenciário"
);
map.set("5177", "Indenizações - AGU");
map.set("5180", "Honorários Advocatícios Sucumbência - AGU");
map.set("5184", "Programa de Regularização Tributária (PRT) - Demais Débitos");
map.set(
  "5190",
  "Programa Especial de Regularização Tributária (PERT) - Demais Débitos"
);
map.set(
  "5192",
  "IRRF - Obras Audiovisuais Cinematográficas e Videofônicas ( L 8685/93) - Residentes no Exterior"
);
map.set(
  "5200",
  "IRRF - Rendimentos do Trabalho - Honorários Advocatícios de Sucumbência - Lei nº 13.327, de 2016"
);
map.set("5204", "IRRF - Juros Indenizações Lucros Cessantes");
map.set("5217", "IRRF - Pagamento a Beneficiário não Identificado");
map.set("5220", "IOF - Operações de Câmbio");
map.set(
  "5232",
  "IRRF - Aplicações Financeiras em Fundos de Investimento Imobiliário"
);
map.set("5246", "Royalties 5% (E/M) L 7990 art. 7 I A III - DJE");
map.set(
  "5252",
  "Royalties Até 5% - Lavra na Área Pré-Sal - Em Plataforma - DJE"
);
map.set("5260", "Outras Receitas - Funpem");
map.set("5269", "Royalties 5% (E-M) (L 7990 art. 7 P 4) - DJE");
map.set("5273", "IRRF - Operações de Swap (art. 74 L 8981/95)");
map.set(
  "5275",
  "Cota Parte Indenização pela Extração de Petróleo, Xisto e Gás (MM) - DJE"
);
map.set("5281", "Royalties Até 5% - Art. 2º da Lei nº 12.858/2013 - DJE");
map.set(
  "5286",
  "IRRF - Aplicações Financeiras de Residentes ou Domiciliados no Exterior"
);
map.set(
  "5298",
  "Royalties Excedentes a 5% (E/M) Lei nº 9.478/1997, art. 49, I - DJE"
);
map.set("5299", "IRRF - Juros sobre Empréstimos Externos (DL 2303/86)");
map.set("5300", "Multa Atraso Entrega Declaração ITR");
map.set("5303", "Receita Dívida Ativa - Impostos Extintos");
map.set(
  "5308",
  "Royalties Excedentes a 5% (MCT) Lei nº 9.478/1997, art. 49, I - DJE"
);
map.set(
  "5314",
  "Royalties Excedente a 5% - Lavra na Área Pré-Sal - Em Plataforma - DJE"
);
map.set("5320", "Multa Atraso Entrega DIRPF");
map.set(
  "5337",
  "Royalties Excedentes a 5% (E-M) - Lei nº 9.478/1997, art. 49, II - DJE"
);
map.set("5338", "Multa Atraso Entrega DIPJ");
map.set(
  "5343",
  "Royalties Excedentes a 5% (MM/MCT) - Lei nº 9.478/1997, art. 49, II - DJE"
);
map.set(
  "5350",
  "Royalties Excedente a 5% - Art. 2º da Lei nº 12.858/2013 - DJE"
);
map.set(
  "5366",
  "Participação Especial (E-M) - Lei nº 9.478/1997, art. 50 - DJE"
);
map.set("5370", "Receita Dívida Ativa - Crédito Rural - STN");
map.set(
  "5372",
  "Participação Especial (MME/MMA) - Lei nº 9.478/1997, art. 50 - DJE"
);
map.set("5382", "Receita Dívida Ativa - outras Multas");
map.set("5395", "Receita Dívida Ativa - Imposto Exportação");
map.set("5405", "Participação Especial - Art. 2º da Lei nº 12.858/2013 - DJE");
map.set(
  "5411",
  "Royalties - Regime de Partilha de Produção - Alíquota de 15% - Art. 42 da Lei nº 12.351/2010 - Pré-Sal e Áreas Estratégicas"
);
map.set("5422", "Receita Dívida Ativa - IPMF");
map.set(
  "5428",
  "Royalties - Regime de Partilha de Produção - Alíquota de 15% - Art. 42 da Lei nº 12.351/2010 - Pré-Sal e Áreas Estratégicas - DJE"
);
map.set("5434", "PIS - Importação de Serviços");
map.set("5440", "Multa por Atraso na Entrega (Maed) da DCTFWeb");
map.set("5442", "Cofins - Importação de Serviços");
map.set("5450", "Fundaf - Cópias Xerox - PGFN");
map.set("5457", "Alienação de Bens Apreendidos - Sistema de Leilão Eletrônico");
map.set(
  "5469",
  "Fundaf-Deslocamento de Servidor para Prestar Serviço em Recinto ou Local Fora da Sede da Repartição de Expediente"
);
map.set("5470", "Perdimento de Bens Apreendidos - Depósito Judicial");
map.set("5477", "Cofins - Não-Cumulativa - Lançamento de Ofício");
map.set("5485", "CPSSS - Contribuição Patronal Servidor Civil Inativo");
map.set("5492", "CPSS/FCDF - Servidor Civil Ativo");
map.set("5493", "CPSSS - Contribuição Patronal Pensionista Civil");
map.set("5502", "CPSS/FCDF - Servidor Civil Inativo/Pensionista");
map.set("5503", "IPI - Vinculado Importação Veículos");
map.set("5506", "Outras Contribuições Econômicas");
map.set("5516", "II - Imposto Importação - Veículos");
map.set("5519", "CPSS/FCDF - Patronal");
map.set(
  "5525",
  "Programa de Regularização de Débitos Previdenciários dos Estados e dos Municípios (PREM) - MP 778/2017"
);
map.set("5529", "Receita Direitos Antidumping");
map.set("5530", "CPSSS - Contribuição do Pensionista Civil");
map.set("5557", "IRRF - Ganhos Líquidos em Operações em Bolsas e Assemelhados");
map.set(
  "5565",
  "IRRF - Benefício previdência complementar/socied. seguradora (modalidade contribuição definida/variável), seguro vida cobertura por sobrevivência ou Fapi - Optantes Trib. Exclusiva"
);
map.set("5572", "Multa Distribuição de Prêmio (s/Redução)");
map.set("5585", "Multa Segurança Privada - DPF (Lei 9.017/95)");
map.set(
  "5590",
  "Fundaf-Vistoria Técnica e Auditoria de Sistema de Controle Informatizado-Alfandegamento-Habilitação para Despacho Aduaneiro de Local ou Recinto"
);
map.set("5602", "PIS - Importação");
map.set("5610", "Serviços Financeiros - outros");
map.set("5612", "Outros Serviços - MICT");
map.set("5625", "IRPJ - Lucro Arbitrado");
map.set("5629", "Cofins - Importação");
map.set("5640", "Transferências de Pessoas - Fundo Partidário");
map.set("5680", "Fundo Nacional Antidrogas - DJE");
map.set("5697", "Receita Dívida Ativa - Multa Não Tributária - RFB");
map.set("5706", "IRRF - Juros sobre o Capital Próprio");
map.set(
  "5713",
  "Receita Dívida Ativa - Multa Não Tributária - Departamento de Polícia Federal - Fiscalização Segurança Privada"
);
map.set(
  "5720",
  "Multa por Omissão/Incorreção/Atraso na Prestação de Informações Relativas a Operações Realizadas com Criptoativos"
);
map.set("5736", "Participação da União em Receita de Loteria Federal");
map.set("5742", "Participação da União em Receita de Loteria Esportiva");
map.set("5747", "Receita Cadastro Eleitoral - TSE");
map.set(
  "5759",
  "Participação da União em Receitas de Loteria de Prognósticos Numéricos"
);
map.set("5762", "Custas Justiça Federal - 1º Grau");
map.set(
  "5765",
  "Participação da União em Receita de Loteria Instantânea Exclusiva"
);
map.set(
  "5771",
  "Participação da União em Receitas de Loteria de Prognóstico Específico"
);
map.set("5775", "Custas Justiça Federal - 2º Grau");
map.set("5784", "Restituições - Aviso MF 087/85");
map.set("5788", "IRPJ - Suplementar");
map.set(
  "5794",
  "Multa por Omissão/Incorreção/Atraso na Entrega do Livro Caixa Digital do Produtor Rural (LCDPR)"
);
map.set("5802", "CSLL - Suplementar");
map.set(
  "5804",
  "Multa por Omissão/Incorreção/Falta/Atraso na Entrega da Escrituração Fiscal Digital de Retenções e Outras Informações Fiscais (EFD-Reinf)"
);
map.set(
  "5827",
  "Contribuição Facultativa em Período de Benefício Emergencial com Suspensão Temporária de Contrato ou Redução de Jornada de Trabalho/Salário (Lei nº 14.020/2020)"
);
map.set(
  "5833",
  "Contribuição Facultativa em Período de Afastamento/Inatividade sem Remuneração e Atividade Vinculada ao RGPS/RPPS - § 5º do Art 11 e § 35 do Art 216 do RPS (Decreto nº 3.048/1999)"
);
map.set("5856", "Cofins Não-Cumulativa");
map.set("5864", "Transferências de Instituições Privadas - outros");
map.set("5869", "CPMF - Lançamento Débito em Conta");
map.set(
  "5871",
  "CPMF-Oper. Liquidação/Pagamento Valores não Creditados em Conta do Beneficiário"
);
map.set("5872", "Transferências do Exterior - outros");
map.set(
  "5879",
  "Transação por Adesão no Contencioso Tributário de Pequeno Valor - Demais Débitos"
);
map.set("5880", "Transferências de Pessoas - outros");
map.set("5884", "CPMF - Instituição Financeira como Contribuinte");
map.set(
  "5885",
  "Transação por Adesão no Contencioso Tributário de Pequeno Valor - Débitos Previdenciários"
);
map.set(
  "5891",
  "Valores Oriundos de Depósito Judicial - Processo com Arquivamento Definitivo na Justiça do Trabalho - Projeto Garimpo (Ato Conjunto CSJT.GP.CGJT nº 1, de 2019, art. 2º, § 6º)"
);
map.set("5897", "Parcelamento Pessoa Física - Simples");
map.set("5909", "Parcelamento Pessoa Jurídica - Simples");
map.set(
  "5911",
  "Receita Dívida Ativa - Avales e Fianças - Instituto do Açúcar e do Álcool"
);
map.set(
  "5918",
  "Valores Oriundos de Depósito Judicial- Processo com Arquivamento Definitivo na Justiça Trabalho- Projeto Garimpo- Período Pandemia (Recomendação nº 9/GCGJT, de 2020, art. 1º, § 2º)"
);
map.set(
  "5928",
  "IRRF - Rendimento Decorrente de Decisão da Justiça Federal, Exceto o Disposto no Artigo 12-A da Lei nº 7.713, de 22 de dezembro de 1988"
);
map.set(
  "5930",
  "Devolução do Auxílio Emergencial pelos Beneficiários de Outros Rendimentos Tributáveis em Valor Superior ao Valor da Primeira Faixa da Tabela Progressiva Anual do IRPF"
);
map.set(
  "5936",
  "IRRF - Rendimento Decorrente de Decisão da Justiça do trabalho, Exceto o Disposto no Artigo 12-A da Lei nº 7.713, de 22 de dezembro de 1988"
);
map.set("5937", "Multa Regulamentar CSLL Suplementar");
map.set("5940", "Multa Regulamentar IRPJ Suplementar");
map.set(
  "5944",
  "IRRF - Pagamento de Pessoa Jurídica a Pessoa Jurídica por Serviços de Factoring"
);
map.set(
  "5947",
  "Parcelamento - Recuperação Judicial - Débitos não Previdenciários Recolhíveis Originalmente em Darf - Até 120 Parcelas ou até 84 Parcelas com Utilização de PF e BCN da CSLL"
);
map.set(
  "5952",
  "Retenção de Contribuições sobre Pagamentos de Pessoa Jurídica a Pessoa Jurídica de Direito Privado - CSLL, Cofins e PIS"
);
map.set(
  "5960",
  "Cofins - Retenção sobre Pagamentos de Pessoa Jurídica a Pessoa Jurídica de Direito Privado (art. 30 da Lei nº 10.833/2003)"
);
map.set(
  "5976",
  "Parcelamento - Recuperação Judicial - Tributos Retidos/Descontados Recolhíveis Originalmente em Darf (IOF, IRRF, Contribuição Previdenciária) - Até 24 parcelas"
);
map.set("5978", "Receita Dívida Ativa - CPMF");
map.set(
  "5979",
  "PIS - Retenção sobre Pagamentos de Pessoa Jurídica a Pessoa Jurídica de Direito Privado"
);
map.set("5980", "CPMF - Conversão Depósito Judicial");
map.set(
  "5982",
  "Parcelamento - Recuperação Judicial - Débitos Patronais Recolhíveis Originalmente em Darf (Previdenciário e Contribuição Devida por Lei a Terceiros) - Até 60 Parcelas"
);
map.set(
  "5987",
  "CSLL - Retenção sobre Pagamentos de Pessoa Jurídica a Pessoa Jurídica de Direito Privado (art. 30 da Lei nº 10.833/2003)"
);
map.set(
  "5993",
  "IRPJ - Optantes pela Apuraçao com Base no Lucro Real - Estimativa Mensal"
);
map.set(
  "6005",
  "Parcelamento - Recuperação Judicial - Débitos Patronais Recolhíveis Originalmente em GPS (Previdenciário e Contribuição Devida por Lei a Terceiros) - Até 60 Parcelas"
);
map.set(
  "6011",
  "Parcelamento - Recuperação Judicial - Débitos Retidos/Descontados Recolhíveis Originalmente em GPS (Contribuição Previdenciária) - Até 24 parcelas"
);
map.set(
  "6012",
  "CSLL - Demais PJ que Apuram o IRPJ com Base em Lucro Real - Balanço Trimestral"
);
map.set("6015", "IRPF - Ganhos Líquidos em Operações em Bolsa");
map.set("6023", "Receita Dívida Ativa - Cide Combustíveis");
map.set(
  "6028",
  "Transação por Adesão no Contencioso Tributário de Relevante e Disseminada Controvérsia Jurídica"
);
map.set(
  "6034",
  "Reabertura da Transação por Adesão no Contencioso Tributário de Pequeno Valor - Demais Débitos"
);
map.set(
  "6057",
  "Quota Parte do Adicional ao Frete para Renovação da Marinha Mercante - AFRMM (Fundo da Marinha Mercante - FMM)"
);
map.set(
  "6070",
  "Parcelamento Parametrizado em Contingência - Débitos Previdenciários"
);
map.set("6081", "ITR - Conversão Depósito Judicial");
map.set("6082", "Receita Dívida Ativa - Cide Remessas ao Exterior");
map.set("6090", "Serviços Processamento de Dados - Fundaf");
map.set("6094", "Multa Isolada - CSLL (art. 43 L.9430)");
map.set(
  "6106",
  "Simples - Pagamento de Micro Empresa e Empresa de Pequeno Porte"
);
map.set("6147", "Produtos - Retenção em Pagamentos por Órgão Público");
map.set("6150", "Combustível - Retenção em Pagamento por Órgão Público");
map.set(
  "6175",
  "Transporte de Passageiros - Retenção em Pagamento por Órgão Público"
);
map.set("6188", "Financeiras - Retenção em Pagamento por Órgão Público");
map.set("6190", "Serviços - Retenção em Pagamento por Órgão Público");
map.set(
  "6197",
  "Fundaf-Auditoria de Sistema Informatizado para Habilitação em Regime Aduaneiro Especial"
);
map.set(
  "6228",
  "CSLL - Retenção na Fonte sobre Pagamento a Pessoa Jurídica (art. 34 da Lei nº 10.833/2003)"
);
map.set(
  "6230",
  "PIS - Retenção na Fonte sobre Pagamento Efetuado por Órgão Público à Pessoa Jurídica"
);
map.set(
  "6243",
  "Cofins - Retenção na Fonte sobre Pagamento à Pessoa Jurídica (art. 34 da Lei nº 10.833/2003)"
);
map.set("6250", "Receita de Leilões Mercadorias Apreendidas");
map.set(
  "6256",
  "IRPJ - Retenção na Fonte sobre Pagamento à Pessoa Jurídica (art. 34 da Lei nº 10.833/2003)"
);
map.set(
  "6297",
  "IRPJ-Ganhos de Capital na Alien. de Ativos Micro Empresa/EPP Optante pelo Simples"
);
map.set(
  "6311",
  "Receita Dívida Ativa - Amortização Juros Empréstimo ao Fundo de Marinha Mercante"
);
map.set("6324", "Multa Isolada - PIS/Pasep (art. 43 L.9430)");
map.set("6337", "Multa Isolada - Cofins (art. 43 L.9430)");
map.set("6340", "Multa Isolada - Imp. Importação (art. 43 L.9430)");
map.set("6352", "Multa Isolada - IRPF (art. 43 L.9430)");
map.set("6378", "Multa Isolada - IRPJ (art. 43 L.9430)");
map.set("6380", "Multa Isolada - IRRF (art. 43 L.9430)");
map.set("6393", "Multa Isolada - IPI Vinculado (art. 43 L.9430)");
map.set("6402", "Multa do TCU");
map.set("6405", "Multa Isolada - IPI (art. 43 L.9430)");
map.set("6410", "Fundaf - Selos Especiais de Controle - Outros");
map.set("6418", "Multa Isolada - IOF (art. 43 L.9430)");
map.set("6420", "Multa Isolada - CPMF (art. 43 L.9430)");
map.set("6488", "Serviços de Laboratório de Análises - SRF");
map.set("6525", "Fundaf - Ressarcimento Receitas de Recintos Alfandegados");
map.set("6542", "Juros Imposto Importação - (art. 43 L.9430)");
map.set("6555", "Juros IRPF - (art. 43 L.9430)");
map.set("6570", "Juros IRPJ - (art. 43 L.9430)");
map.set("6583", "Juros IRRF - (art. 43 L.9430)");
map.set("6596", "Juros IPI - (art. 43 L.9430)");
map.set("6608", "Juros IPI - Vinculado (art. 43 L.9430)");
map.set("6610", "Juros IOF - (art. 43 L.9430)");
map.set("6621", "Serviços de Registro do Comércio");
map.set("6623", "Juros Cofins (art. 43 L.9430)");
map.set("6630", "Multas Previstas na Legislação do Registro de Comércio");
map.set("6636", "Juros PIS/Pasep - (art. 43 L.9430)");
map.set("6648", "Parcelamento Lei 10.684/03 (Paes) - Depósito Judicial");
map.set("6649", "Juros CSLL - (art. 43 L.9430)");
map.set("6651", "Juros CPMF (art. 43 L.9430)");
map.set("6656", "PIS - Não-Cumulativo - Lançamento de Ofício");
map.set("6677", "IRPJ - FINOR - Estimativa");
map.set(
  "6680",
  "Multa por Omissão/Erro/Atraso - Declaração de Informações sobre Atividades Imobiliárias - DIMOB"
);
map.set("6692", "IRPJ - FINAM - Estimativa");
map.set("6704", "IRPJ - FUNRES - Estimativa");
map.set("6720", "Receita Outorga Serviços de Transporte Ferroviário");
map.set(
  "6732",
  "Contribuição sobre a Receita de Concursos de Prognósticos - Crédito Educativo"
);
map.set(
  "6744",
  "Multa por Atraso na Entrega da Declaração de Operações Imobiliárias - DOI"
);
map.set("6758", "CSLL - Entidades Financeiras - Declaração de Ajuste");
map.set("6773", "CSLL - Demais Pessoas Jurídicas - Declaração de Ajuste");
map.set("6786", "Superávit Financeiro Exercícios Anteriores");
map.set(
  "6799",
  "IRRF - Resgate de Fundo de Aposentadoria Programada Individual - FAPI"
);
map.set(
  "6800",
  "IRRF - Aplicações Financeiras em Fundos de Investimento de Renda Fixa"
);
map.set("6808", "Multa por Omissão/Erro/Atraso Dacon");
map.set("6813", "IRRF - Fundos de Investimento - Ações");
map.set("6824", "PIS - Combustíveis");
map.set("6840", "Cofins - Combustíveis");
map.set(
  "6841",
  "Multa por falta de comunicação da exclusão do Simples Nacional"
);
map.set("6854", "IOF - Títulos ou Valores Mobiliários");
map.set(
  "6875",
  "Pneus e Câmaras-de-Ar de Borracha Adquiridos de Fabricante ou de Importador - Retido por Órgão Público"
);
map.set("6882", "Multa Falta Placa Simples (C/Red)");
map.set(
  "6883",
  "Máquinas, Tratores, Veículos para Transporte de Passageiros, de Mercadorias, de Usos Especiais e Chassis com Motor para Veículos Automóveis - Retido por Órgão Público"
);
map.set("6891", "IRRF - Vida Gerador de Benefício Livre - VGBL");
map.set("6895", "IOF - Factoring (art. 58 Lei 9532/97)");
map.set("6904", "IRRF - Rendimentos de Indenização por Danos Morais");
map.set("6907", "Multa Empresas Inativas (Parcelamento)");
map.set("6910", "Dividendos - Outras Empresas");
map.set("6912", "PIS - Não Cumulativo (Lei 10.637/02)");
map.set("6922", "Taxa Autorização Trabalho Estrangeiro");
map.set("6935", "Indenização Itaipú - Parcelas Vencidas Até Jul/97");
map.set("6939", "Multa de Ofício - IPI não Lançado com Cobertura de Crédito");
map.set("6948", "Receita Dívida Ativa - Créditos Contratuais da União - STN");
map.set(
  "6950",
  "Receita Dívida Ativa - Contrato Comissão Coord Criação Cavalo Nacional (L.7291/84)"
);
map.set(
  "7010",
  "Outras Receitas Atividades Associadas à Outorga de Serviços Transp. Ferroviário"
);
map.set(
  "7023",
  "Receita Dívida Ativa - Órgão Extintos, outras Obrigações Salvo IAA e Finex/Proex"
);
map.set("7034", "Encargo Legal do Decreto-Lei nº 1.025/69 - PGFN (ajuizado)");
map.set("7036", "Juros ITR - (art. 43 L.9430)");
map.set("7042", "Parcelamento Lei 10.684/03 - Pessoa Física");
map.set("7049", "Multa Isolada - ITR (art. 43 L.9430)");
map.set("7051", "ITR - Lançamento de Ofício");
map.set("7064", "Receita Dívida Ativa - Funapol");
map.set("7080", "Pagamento pela Retenção de Área para Exploração ou Produção");
map.set("7093", "Parcelamento Lei 10.684/03 - Micro Empresa");
map.set("7104", "IRPJ - Simples");
map.set("7114", "Parcelamento Lei 10.684/03 - Empresa Pequeno Porte");
map.set(
  "7118",
  "Multa Administrativa Decorrente de Auto de Infração Lavrado por Irregularidades Constatadas pelo AFT - DJE"
);
map.set("7122", "Parcelamento Lei 10.684/03 - Demais Pessoas Jurídicas");
map.set(
  "7130",
  "Multa por Atraso na Entrega da Declaração do Imposto de Renda Pessoa Física - Espólio"
);
map.set("7160", "Receita Outorga Serviço Transporte Rodoviário");
map.set("7186", "Multas Operação do Transporte Rodoviário");
map.set("7199", "Bônus de Assinatura de Contrato de Concessão");
map.set("7200", "PIS - Simples");
map.set("7202", "Cauções e Garantias Diversas");
map.set("7213", "CPMF - Lançamento de Ofício");
map.set(
  "7229",
  "Receita Dívida Ativa - Taxa Fiscalização Mercados Seguro, Capitalização e Previdência Privada"
);
map.set("7254", "Royalties 5% (E/M) L 7990 art. 7 I A III");
map.set("7267", "Royalties 5% (E-M) (L 7990 art. 7 P 4)");
map.set("7282", "Royalties Excedentes A 5% (E/M) Lei 9.478, art. 49, I");
map.set(
  "7288",
  "ITR - Parcelamento Lei 10.684/2003 - Exercício 1997 e Posteriores"
);
map.set("7295", "Royalties Excedentes a 5% (MCT) Lei 9.478, art. 49 , I");
map.set("7307", "CSLL - Simples");
map.set("7309", "Depósitos (Multas CLT)");
map.set("7310", "Royalties Excedentes a 5% (E-M) Lei 9.478, art. 49,II");
map.set("7317", "Receita Dívida Ativa - ITR - Parcelamento Lei 10.684/2003");
map.set("7322", "Royalties Excedentes a 5% (MM/MCT) Lei 9.478, art. 49, II");
map.set("7335", "Participação Especial (E-M) (L 9478 art. 50)");
map.set("7348", "Participação Especial (MME/MMA) Lei 9.478, art. 50");
map.set("7363", "II - Imposto Importação - Depósito Judicial");
map.set(
  "7368",
  "Receita da Dívida Ativa - Multa por Violação ao Código de Defesa do Consumidor"
);
map.set(
  "7376",
  "Receita de Dívida Ativa - Pasep - Regime Especial de Parcelamento - Lei 10.684/2003"
);
map.set(
  "7384",
  "Cota-Parte da Contribuição Sindical - Desvinculação de Receitas da União -DRU"
);
map.set("7389", "IPI - Outros Depósito Judicial");
map.set("7391", "IPI - Vinculado Importação Depósito Judicial");
map.set("7403", "Cofins - Simples");
map.set("7405", "Depósitos Abandonados");
map.set("7416", "IRPF - Depósito Judicial");
map.set("7429", "IRPJ - Depósito Judicial");
map.set("7431", "IRRF - Depósito Judicial");
map.set("7444", "IOF - Depósito Judicial");
map.set("7457", "ITR - Depósito Judicial");
map.set("7460", "PIS - Depósito Judicial");
map.set("7485", "CSLL - Depósito Judicial");
map.set("7498", "Cofins - Depósito Judicial");
map.set("7500", "INSS - Contribuição do Empregador - Simples");
map.set("7501", "Depósitos para quem de direito");
map.set("7512", "CPMF - Depósito Judicial");
map.set(
  "7525",
  "Receita Dívida Ativa - Depósito Garantia / Juízo - Justiça Federal"
);
map.set("7538", "II - Imposto Importação - Depósito Administrativo");
map.set("7540", "IPI - Outros Depósito Administrativo");
map.set("7553", "IPI - Vinculado Importação Depósito Administrativo");
map.set("7566", "IRPF - Depósito Administrativo");
map.set("7581", "IRPJ - Depósito Administrativo");
map.set("7594", "IRRF - Depósito Administrativo");
map.set("7606", "IPI - Simples");
map.set("7616", "Receita Dívida Ativa - Empréstimo Compulsório Principal");
map.set("7619", "IOF - Depósito Administrativo");
map.set("7621", "ITR - Depósito Administrativo");
map.set("7634", "PIS - Depósito Administrativo");
map.set("7647", "CSLL - Depósito Administrativo");
map.set("7650", "Cofins - Depósito Administrativo");
map.set("7659", "Parcelamento - Simples Federal");
map.set("7662", "CPMF - Depósito Administrativo");
map.set("7702", "ICMS - Simples");
map.set(
  "7728",
  "Receita em Consignação Pagamento de Serviços Públicos ( art. 12, Dec. 2.784/98)"
);
map.set("7730", "II - Imposto Importação - Trans Navios");
map.set("7739", "Receita da Dívida Ativa - Parcelamento de Arrematação");
map.set("7743", "IPI - Vinculado Importação Trans Navios");
map.set("7756", "IRPJ - Trans Navios");
map.set("7769", "IRRF - Demais Rendimentos Trans Navios");
map.set("7780", "Multa Isolada Condecine (art.43 da Lei 9.430)");
map.set("7784", "Cofins - Trans Navios");
map.set("7797", "PIS - Trans Navios");
map.set("7809", "ISS - Simples");
map.set("7811", "Taxa Utilização Siscomex (art. 3 L 9716/98)");
map.set("7837", "CSLL - Trans Navios");
map.set("7840", "Juros sobre Capital Próprio");
map.set(
  "7852",
  "Receita Alienação de Ações Recebidas em Pagamento de Dividendos"
);
map.set(
  "7865",
  "Tarifa e Adicional sobre Passagem Aérea Internacional (MP 1792)"
);
map.set("7878", "Condecine - Lançamento de Ofício");
map.set("7880", "Multas Isoladas Diversas - Depósito Administrativo");
map.set("7893", "IOF - Operações de Crédito - Pessoa Física");
map.set("7905", "IOF - Aquisição de Títulos ou Valores Mobiliários");
map.set(
  "7918",
  "Receita da Dívida Ativa - Multas da Agência Nacional do Petróleo"
);
map.set("7920", "IRPJ - FINOR Ajuste Anual");
map.set("7933", "IRPJ - FINAM Ajuste Anual");
map.set("7940", "Juros Isolados Condecine (art.43 da Lei 9.430)");
map.set("7946", "IRPJ - FUNRES Ajuste Anual");
map.set(
  "7961",
  "Receita Dívida Ativa - Depósito Garantia / Juízo - Justiça Estadual"
);
map.set("7987", "Cofins - Entidades Financeiras e Equiparadas");
map.set("8002", "PIS - Dedução");
map.set("8019", "Custas da Justiça do Trabalho - Lei 10.537/2002");
map.set("8021", "Porte de Remessa e Retorno dos Autos");
map.set("8034", "Multas Prev Infração Legislação Transporte Ferroviário");
map.set("8045", "IRRF - Outros Rendimentos");
map.set("8047", "Depósito Judicial - outros");
map.set("8050", "Depósito Administrativo - outros");
map.set("8053", "IRRF - Aplicações Financeiras de Renda Fixa - Pessoa Física");
map.set(
  "8062",
  "Receita Dívida Ativa - Multas Previstas no Código Eleitoral e Leis Conexas"
);
map.set("8102", "Venda de Particip Acionária União - Telebrás");
map.set("8109", "PIS - Faturamento");
map.set("8128", "Multa Isolada IRPJ - Simples (art.43 L.9430)");
map.set("8130", "Multa Isolada IPI - Simples (art.43 L.9430)");
map.set("8143", "Multa Isolada PIS - Simples (art.43 L.9430)");
map.set("8156", "Multa Isolada Cofins- Simples (art.43 L.9430)");
map.set("8168", "Emolumentos da Justiça do Trabalho - Lei 10.537/2002");
map.set("8169", "Multa Isolada CSLL - Simples (art.43 L.9430)");
map.set("8171", "Multa Isolada INSS Empregador - Simples (art.43 L.9430)");
map.set("8176", "Cide - Desistência de Ação Judicial (art. 21 da MP 66/02)");
map.set("8192", "CPMF - Desistência de Ação Judicial (art. 21 da MP 66/02)");
map.set("8197", "Multa Isolada ICMS - Simples (art.43 L.9430)");
map.set("8205", "PIS - Repique Imposto de Renda");
map.set("8209", "Multa Isolada ISS - Simples (art.43 L.9430)");
map.set("8211", "Juros Isolado IRPJ - Simples (art.43 L.9430)");
map.set("8224", "Juros Isolado IPI - Simples (art.43 L.9430)");
map.set("8237", "Juros Isolado PIS - Simples (art.43 L.9430)");
map.set("8240", "Juros Isolado Cofins- Simples (art.43 L.9430)");
map.set("8252", "Juros Isolado CSLL - Simples (art.43 L.9430)");
map.set(
  "8256",
  "Cota Parte Indenização pela Extração de Petróleo, Xisto e Gás (MM)"
);
map.set("8265", "Juros Isolado INSS Empregador - Simples (art.43 L.9430)");
map.set("8278", "Juros Isolado ICMS - Simples (art.43 L.9430)");
map.set("8293", "Juros Isolado ISS - Simples (art.43 L.9430)");
map.set("8301", "PIS - Folha de Pagamento");
map.set("8459", "PIS - Desistência de Ação Judicial (art. 21 da MP 66/02)");
map.set("8468", "IRRF - Day-Trade Operações em Bolsa");
map.set("8470", "Receita da Dívida Ativa - Multa Criminal/Justica Federal");
map.set(
  "8496",
  "PIS - Fabricação e Importação de Veículos em Substituição Tributária"
);
map.set("8504", "Multa Isolada Cide - Combustíveis - (art. 43 Lei 9430/96)");
map.set("8510", "Multa - Vale Pedágio");
map.set(
  "8523",
  "IRPF - Ganho de Capital na Alienação de Bens e Direitos e nas Liquidações e Resgates de Aplicações Financeiras, Adquiridos em Moeda estrangeira - IN SRF nº 118/2000"
);
map.set("8536", "CPMF - Acão Judicial (art.s 45 e 46 da MP 2.037-21/00)");
map.set("8539", "Emolumentos Consulares - Exterior");
map.set("8617", "Receita Diretamente Arrecadada - STN");
map.set("8619", "Juros Isolados Cide Combustíveis (art. 43 Lei 9430/96)");
map.set(
  "8645",
  "Cofins - Fabricação e Importação de Veículos em Substituição Tributária"
);
map.set("8651", "Multa Isolada Cide Remessa ao Exterior (art. 43 Lei 9430/96)");
map.set("8658", "Refis/Simples - Parcelamento Especial (MP 2061/00, artigo 2)");
map.set(
  "8660",
  "Juros Isolados Cide Remessa ao Exterior (art. 43 Lei 9430/96)"
);
map.set("8673", "IRRF - Bingos (Decreto 3659/2000 art. 14 I)");
map.set(
  "8699",
  "Contrib sobre Receita de Bingos-Parcela Destinada à União (Dec 3659/00 art. 14 IV)"
);
map.set("8700", "Taxa de Fiscalização de Vigilância Sanitária");
map.set(
  "8707",
  "Pasep - Regime Especial de Parcelamento - MP 38/2002 e Lei 10.684/03"
);
map.set(
  "8713",
  "Taxa de Fiscalização de Vigilância Sanitária nos portos, Aeroportos e Fronteiras"
);
map.set("8726", "Álcool Carb Adquir Distribuidor-Ret Órgão Pub");
map.set(
  "8739",
  "Gasol/Diesel/Gás Liquefeito Petróleo e Álcool Varejo-Ret Órgão Público"
);
map.set("8741", "Cide - Remessas ao Exterior - L. 10332/01");
map.set(
  "8754",
  "Medicamentos Adquiridos do Industrial ou Importador - Retido por Órgão Público"
);
map.set(
  "8767",
  "Medicamentos Adquiridos do Distribuidor ou Varejista - Retido por Órgão Público"
);
map.set(
  "8770",
  "Demais Combustíveis Derivados de Petróleo e Gás Natural-Retido por Órgão Público"
);
map.set(
  "8807",
  "Contrib s/ a Receita Bruta de Empresas Telecomun, Destinada Funttel-Dec 3737/01"
);
map.set(
  "8810",
  "Taxa de Fiscalização Incidente sobre Prêmio que trata Port MF N 15/2001"
);
map.set("8811", "Refis - Depósito Judicial");
map.set("8822", "Receita de Dívida Ativa - Simples");
map.set(
  "8835",
  "Transporte Internacional Cargas Efetuado Empresas Nacionais-Retido Órgão Público"
);
map.set(
  "8848",
  "Construção/Reparo Embarcações Pré Regist ou Regist no Reb - Retido Órgão Público"
);
map.set(
  "8850",
  "Transporte Internacional de Passageiros Empresas Nacionais-Retido Órgão Público"
);
map.set(
  "8863",
  "Bens ou Serviços Adquiridos de Sociedades Cooperativas e Associações Profissionais ou Assemelhadas - Retido por Órgão Público"
);
map.set("8876", "Receita de Dívida Ativa - INSS Simples");
map.set("8891", "Receita de Dívida Ativa - ICMS Simples");
map.set(
  "8900",
  "Receita de Dívida Ativa - ITR - Exercícios de 1997 e Posteriores"
);
map.set("8903", "Receita de Dívida Ativa - ISS Simples");
map.set(
  "8931",
  "Multa Previdência Complementar (Lei Complementar nº 109, de 29/05/2001, art.65, IV)"
);
map.set(
  "8944",
  "II - Imposto Importação - Garantia Aduaneira/Depósito Administrativo"
);
map.set(
  "8957",
  "IPI - Vinculado à Importação - Garantia Aduaneira Depósito Administrativo"
);
map.set(
  "8960",
  "IRPF - Ganho de Capital na Alienação de Moeda Estrangeira Mantida em Espécie - IN SRF 118/2000"
);
map.set(
  "8972",
  "IRPJ - Previdência Complementar/Sociedade Seguradora/FAPI Optante pelo Ret (art. 2º MP nº 2.222/01)"
);
map.set(
  "8993",
  "Tarifa Decorrente do Serviço de Emissão e Gerenciamento de Certificados - Portaria IPI (Instituto Nacional de Tecnologia da Informação) nº 12 de 26/03/2002"
);
map.set("8998", "IRPJ - IN SRF 89/2001 art. 7 Parágrafo 8");
map.set(
  "9004",
  "IRPJ - FINOR Balanço Trimestral - Pessoa Jurídica que Atenda às Condições do art. 9º da Lei 8.167/1991"
);
map.set(
  "9013",
  "Condecine - Pagamento, Crédito, Emprego, Entrega e Remessa para o Exterior - MP 2228/01, art. 33º, Parágrafo 2º"
);
map.set(
  "9017",
  "IRPJ - FINOR - Estimativa - Pessoa Jurídica que Atenda às Condições do art. 9º da Lei 8.167/1991"
);
map.set(
  "9020",
  "IRPJ - FINAM - Balanço Trimestral - Pessoa Jurídica que Atenda às Condições do art. 9º da Lei 8.167/1991"
);
map.set(
  "9032",
  "IRPJ - FINAM - Estimativa - Pessoa Jurídica que Atenda às Condições do art. 9º da Lei 8.167/1991"
);
map.set(
  "9045",
  "IRPJ - FUNRES - Balanço Trimestral - Pessoa Jurídica que Atenda às Condições do art. 9º da Lei 8.167/1991"
);
map.set(
  "9058",
  "IRPJ - FUNRES - Estimativa - Pessoa Jurídica que Atenda às Condições do art. 9º da Lei 8.167/1991"
);
map.set(
  "9060",
  "Querosene de Aviação Adquirido de Produtor ou Importador - Retido por Órgão Público"
);
map.set("9064", "II - Imposto Importação - Adicional");
map.set("9073", "Cofins - Desistência de Ação Judicial (art. 21 da MP 66/02)");
map.set(
  "9086",
  "IRPJ - Ganhos Líquidos Operações Bolsa Investimentos de País C/ Tributação Favorecida"
);
map.set(
  "9090",
  "Doações aos Fundos Controlados pelos Conselhos Municipais, Estaduais e Nacional do Idoso"
);
map.set("9100", "Refis - Parcelamento Vinculado Receita Bruta");
map.set("9113", "ITR - Parcelamento Alternativo Exercício 1996 e Anteriores");
map.set("9126", "ITR - Parcelamento Alternativo Exercício 1997 e Posteriores");
map.set("9136", "Multa/Juros de Transferência - SPU");
map.set("9139", "Contribuição sobre Prêmios Prescritos de Loterias Federais");
map.set("9141", "Contribuição sobre a Receita de Loteria Instantânea");
map.set("9154", "Contribuição sobre a Receita de Loterias de Números");
map.set(
  "9167",
  "Contribuição sobre a Receita de Concursos Especiais de Loterias Esportivas"
);
map.set("9170", "Contribuição sobre a Receita de Loterias Esportivas");
map.set("9182", "Contribuição sobre a Receita da Loteria Federal");
map.set(
  "9207",
  "Multa por Falta/Atraso no Recolhimento das Contribuições Instituídas pelos art. 1º e 2º da LC nº 110/2001, de que trata o Parágrafo 2º do art. 3º da Referida LC"
);
map.set(
  "9210",
  "IRPJ - Desistência de Ação Judicial de que trata o artigo 11 da MP nº 38, de 14/05/2002"
);
map.set("9222", "Refis - Parcelamento Alternativo");
map.set(
  "9235",
  "CSLL - Desistência de Ação Judicial de que trata o artigo 11 da MP nº 38, de 14/05/2002"
);
map.set(
  "9248",
  "Cofins - Desistência de Ação Judicial de que trata o artigo 11 da MP nº 38, de 14/05/2002"
);
map.set(
  "9250",
  "PIS - Desistência de Ação Judicial de que trata o artigo 11 da MP nº 38, de 14/05/2002"
);
map.set(
  "9263",
  "Pasep - Desistência de Ação Judicial de que trata o artigo 11 da MP nº 38, de 14/05/2002"
);
map.set(
  "9303",
  "Cide - Remessa Exterior - Lei 10332/01 - Lançamento de Ofício"
);
map.set("9304", "Cide - Combustíveis - Lançamento de Ofício");
map.set("9331", "Cide - Combustíveis - Mercado Interno - Lei 10336/2001");
map.set(
  "9344",
  "IRPJ - FINOR - Ajuste Anual - Pessoa Jurídica que atenda às Condições do art. 9º da Lei 8.167/1991"
);
map.set(
  "9360",
  "IRPJ - FINAM - Ajuste Anual - Pessoa Jurídica que atenda às Condições do art.9º da Lei 8.167/1991"
);
map.set(
  "9372",
  "IRPJ - FUNRES - Ajuste Anual - Pessoa Jurídica que atenda às Condições do art. 9º da Lei 8.167/1991"
);
map.set("9385", "IRRF - Multas e Vantagens");
map.set("9412", "IRRF - Fretes Internacionais - Residentes no Exterior");
map.set("9427", "IRRF - Remuneração de Direitos - Residentes no Exterior");
map.set("9438", "Cide - Combustíveis - Importação - Lei 10.336/2001");
map.set(
  "9440",
  "IRPF - Ganhos Líquidos em Operações na Bolsa de Valores - Opção MP nº 16/2001"
);
map.set(
  "9453",
  "IRRF - Juros sobre o Capital Próprio - Residentes no Exterior"
);
map.set(
  "9466",
  "IRRF - Benefícios e Resgate de Previdência Privada e FAPI - Residentes no Exterior"
);
map.set("9478", "IRRF - Aluguel e Arrendamento - Residentes no Exterior");
map.set(
  "9479",
  "Multa por Atraso na Entrega da Declaração da CPMF - IN 45/2001, art. 7º, II"
);
map.set(
  "9481",
  "IRPJ - Ganhos Líquidos em Operações na Bolsa de Valores - Pessoa Jurídica Isenta ou Sujeita ao Simples - Opção MP nº 16/2001"
);
map.set("9494", "Outras Receitas - Capital");
map.set("9558", "PIS - IN SRF 89/2001 art. 7 paragrafo 8");
map.set("9562", "Cofins - IN SRF 89/2001 art. 7 paragrafo 8");
map.set(
  "9972",
  "Receita Decorrente de Aportes Periódicos para Compensação ao Regime Geral de Previdência Social"
);

export const getDescription = (code) => {
  return map.get(code) === undefined ? "Outros" : map.get(code);
};
