import { ChipCnd } from "../../cnd/styles/CndCustomer";

export const OptionsIcons = (providedManual) => {
  if (
    providedManual === 0 ||
    providedManual === 2 ||
    providedManual === 3 ||
    providedManual === 4
  ) {
    return (
      <ChipCnd
        label="Não"
        style={{
          backgroundColor: "red",
          fontWeight: "bold",
          height: "17px",
          color: "white",
          width: "70px",
        }}
      />
    );
  }
  if (providedManual === 1) {
    return (
      <ChipCnd
        label="Sim"
        style={{
          backgroundColor: "#21ba45",
          fontWeight: "bold",
          height: "17px",
          color: "white",
          width: "70px",
        }}
      />
    );
  }
  if (providedManual === 8) {
    return (
      <ChipCnd
        label="Parcial"
        style={{
          backgroundColor: "orange",
          fontWeight: "bold",
          height: "17px",
          color: "white",
          width: "70px",
        }}
      />
    );
  }
  if (providedManual === 5 || providedManual === 6 || providedManual === 7) {
    return (
      <ChipCnd
        label="DEV"
        style={{
          backgroundColor: "#31ccec",
          fontWeight: "bold",
          height: "17px",
          color: "white",
          width: "70px",
        }}
      />
    );
  }
};

export const legendAuthentication = (row) => {
  // eslint-disable-next-line eqeqeq
  if (row == 1) {
    return "Certificado";
  }
  // eslint-disable-next-line eqeqeq
  if (row == 2) {
    return "Usuário/Senha";
  }
  // eslint-disable-next-line eqeqeq
  if (row == null || row == "") {
    return "";
  }
};

export const legendGroupCND = (row) => {
  if (row === "Estadual") {
    return (
      <ChipCnd
        label="Estadual"
        style={{
          backgroundColor: "#31ccec",
          fontWeight: "bold",
          height: "17px",
          color: "white",
          width: "100px",
        }}
      />
    );
  }
  if (row === "Municipal" || row === "Municipall") {
    return (
      <ChipCnd
        label="Municipal"
        style={{
          backgroundColor: "#21ba45",
          fontWeight: "bold",
          height: "17px",
          color: "white",
          width: "100px",
        }}
      />
    );
  }
  if (row === "Federal") {
    return (
      <ChipCnd
        label="Federal"
        style={{
          backgroundColor: "orange",
          fontWeight: "bold",
          height: "17px",
          color: "white",
          width: "100px",
        }}
      />
    );
  }
  return (
    <ChipCnd
      label={row}
      style={{
        backgroundColor: "gray",
        fontWeight: "bold",
        height: "17px",
        color: "white",
        width: "100px",
      }}
    />
  );
};
