const BASE_URL = process.env.REACT_APP_BOT_API_URL;

export function getCompanyDataInBigDataByDocument(
  document,
  companyLoggedDocument
) {
  let requestOptions = {
    method: "GET",
    redirect: "follow",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("tokenPanel"),
      govid: companyLoggedDocument,
    },
  };

  return new Promise((resolve, reject) => {
    fetch(BASE_URL + "/v1/bot/data/company/" + document, requestOptions)
      .then((response) => resolve(response.json()))
      .catch((err) => resolve(null));
  });
}

export function getAddressDataInBigDataByCEP(cep, companyLoggedDocument) {
  let requestOptions = {
    method: "GET",
    redirect: "follow",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("tokenPanel"),
      govid: companyLoggedDocument,
    },
  };

  return new Promise((resolve, reject) => {
    fetch(BASE_URL + "/v1/bot/data/address/" + cep, requestOptions)
      .then((response) => resolve(response.json()))
      .catch((err) => resolve(null));
  });
}
