import { useContext, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router";

/**
 * Icons
 */
import {
  Cached,
  EmailOutlined,
  Favorite,
  FavoriteBorder,
  PlayForWorkOutlined,
  RemoveRedEyeOutlined,
} from "@material-ui/icons";

/**
 * Component
 */

import { Grid } from "@material-ui/core";

import Loading from "../../components/basics/Loading";
import TabCustom from "../../components/tabs/TabCustom";
import { notify } from "../../utils/notify";
import ModalSendEmail from "./children/ModalSendEmail";
import { OptionsIcons } from "./children/OptionsIcons";
import { TableDescription } from "./children/TableDescription";
import TableTabCnd from "./children/TableTabCnd";
import { ContainerTab, DataGridCnd, TitleTable } from "./styles/CndCustomer";

/**
 * Styled Component
 */
import {
  ContainerIcon,
  GridStyled,
  LightTooltip,
  Margin,
  Title,
} from "../../styles/GlobalStyle";

/**
 * Context
 */

import { GlobalContext } from "../../contexts/GlobalContext";
import {
  CndCustomerContext,
  CndCustomerContextProvider,
} from "./contexts/CndCustomerContext";

/**
 * Controller
 */

import { useCndCustomerController } from "./controllers/CndCustomerController";
import { useCndGeneralController } from "./controllers/CndGeneralController";

import { GlobalVariables } from "../../GlobalVariables";
import * as rpaEntityRepository from "../../repositories/panel/v1/integrations/qyonMsRpa/entity";
import { usePermissions } from "../../utils/permissionsHook";

const View = ({ customSpacing = 2 }) => {
  const {
    tabIndex,
    setTabIndex,
    cndsFederal,
    setCndsFederal,
    cndsMunicipal,
    setCndsMunicipal,
    cndsState,
    setCndsState,
    cndsFavorites,
    setCndsFavorites,
    cndsOthers,
    setCndsOthers,
    perPageFederal,
    perPageMunicipal,
    perPageState,
    perPageOthers,
    setStatusAccessCnd,
    myCnds,
    setMyCnds,
    loading,
    setLoading,
  } = useContext(CndCustomerContext);
  const { companyUser } = useContext(GlobalContext);
  const { handleFavorite, handleStandAloneRequestCnd, removeFavorite } =
    useCndCustomerController();
  const { getFile, disableActionFile } = useCndGeneralController();
  const { push } = useHistory();
  const [dataSendEmail, setDataSendEmail] = useState({});
  const { checkIfUserHasPermission } = usePermissions();
  const hasEditionPermission = useMemo(
    () =>
      checkIfUserHasPermission(GlobalVariables.PERMISSIONS.CND_SOLICITATION),
    [checkIfUserHasPermission]
  );

  const isStandaloneClient =
    !companyUser.isAccountant && !companyUser.accountantId;

  useEffect(() => {
    (async () => {
      try {
        setLoading((prev) => ({ ...prev, customerListLoading: true }));

        if (
          !companyUser.accountantCnpj &&
          companyUser.isAccountant !== "true"
        ) {
          return notify(
            "Para visualizar as CNDs é necessário estar vinculado a um contador."
          );
        }

        if (!companyUser.isAccountant) {
          const responseCndStandAlone =
            await rpaEntityRepository.getMyCndsStandAlone({
              documentCont: companyUser.accountantCnpj,
              documentClient: companyUser.document,
            });
          if (
            responseCndStandAlone?.success &&
            Array.isArray(responseCndStandAlone?.data)
          ) {
            setMyCnds(responseCndStandAlone?.data);
          }
        }

        if (companyUser.isAccountant) {
          const responseCnd = await rpaEntityRepository.getMyCnds({
            document: companyUser.document,
          });

          if (responseCnd?.success && Array.isArray(responseCnd?.data)) {
            setMyCnds(responseCnd?.data);
          }
        }
      } catch (error) {
        console.error(error);
        notify("Não foi possível obter os dados.");
      } finally {
        setLoading((prev) => {
          let prevLoading = { ...prev };
          delete prevLoading.customerListLoading;
          return prevLoading;
        });
      }
    })();
  }, [
    companyUser.isAccountant,
    companyUser.accountantCnpj,
    companyUser.document,
    setMyCnds,
    setLoading,
    setStatusAccessCnd,
    push,
    setMyCnds,
  ]);

  useEffect(() => {
    if (!myCnds || !myCnds.length) return;

    const cndsPerCategory = {
      Federal: [],
      Estadual: [],
      Municipal: [],
      Favoritos: [],
      Outros: [],
    };

    myCnds.forEach((cnd, index) => {
      if (cnd.favorite) {
        cndsPerCategory.Favoritos.push(cnd);
      } else {
        !cnd.scope?.scope || !cndsPerCategory[cnd.scope?.scope]
          ? cndsPerCategory.Outros.push(cnd)
          : cndsPerCategory[cnd.scope?.scope].push(cnd);
      }
      //FIXME CHANGE TO cnd.agendaId
      cnd.id = index;
    });

    setCndsState(cndsPerCategory.Estadual);
    setCndsFederal(cndsPerCategory.Federal);
    setCndsMunicipal(cndsPerCategory.Municipal);
    setCndsFavorites(cndsPerCategory.Favoritos);
    setCndsOthers(cndsPerCategory.Outros);
  }, [
    myCnds,
    setCndsFavorites,
    setCndsFederal,
    setCndsMunicipal,
    setCndsOthers,
    setCndsState,
  ]);

  const columns = [
    {
      field: "action",
      headerName: "Ações",
      width: 180,
      headerAlign: "center",
      renderCell: ({ row }) => (
        <GridStyled
          container
          spacing={1}
          alignItems="center"
          margin="10px 0 0 0"
        >
          <Grid item flex={1}>
            <LightTooltip title="Abrir em outra aba">
              <ContainerIcon
                iconColor={disableActionFile(row) ? "grayPrimary" : "blue"}
              >
                <RemoveRedEyeOutlined
                  onClick={() => {
                    !disableActionFile(row)
                      ? getFile(
                          companyUser.accountantCnpj
                            ? companyUser.accountantCnpj
                            : companyUser.document,
                          row?.cndId,
                          "View",
                          setLoading,
                          companyUser.document
                        )
                      : notify("CND ainda não processada");
                  }}
                />
              </ContainerIcon>
            </LightTooltip>
          </Grid>

          <Grid item flex={1}>
            <LightTooltip
              title={row.favorite ? "Remover Favorito" : "Favoritar"}
            >
              <ContainerIcon iconColor={"blue"}>
                {row.favorite ? (
                  <Favorite
                    onClick={() => removeFavorite(row.agendaId, row.favorite)}
                  />
                ) : (
                  <FavoriteBorder
                    onClick={() => handleFavorite(row.agendaId, row.favorite)}
                  />
                )}
              </ContainerIcon>
            </LightTooltip>
          </Grid>

          <Grid item flex={1}>
            <LightTooltip title="Download">
              <ContainerIcon
                iconColor={disableActionFile(row) ? "grayPrimary" : "blue"}
              >
                <PlayForWorkOutlined
                  onClick={() => {
                    !disableActionFile(row)
                      ? getFile(
                          companyUser.document,
                          row?.cndId,
                          "Download",
                          setLoading,
                          companyUser.document
                        )
                      : notify("CND ainda não processada");
                  }}
                />
              </ContainerIcon>
            </LightTooltip>
          </Grid>

          <Grid item flex={1}>
            <LightTooltip title="Enviar">
              <ContainerIcon
                iconColor={disableActionFile(row) ? "grayPrimary" : "blue"}
              >
                <EmailOutlined
                  onClick={() => {
                    !disableActionFile(row)
                      ? setDataSendEmail({
                          id: row.cndId,
                          document: companyUser.document,
                          defaultMail: companyUser.masterUserEmail,
                        })
                      : notify("CND ainda não processada");
                  }}
                />
              </ContainerIcon>
            </LightTooltip>
          </Grid>

          {isStandaloneClient && (
            <Grid item flex={1}>
              <LightTooltip
                title={row.auto ? "Solicitar CND" : "Upload manual"}
              >
                <ContainerIcon iconColor="blue">
                  <Cached
                    onClick={() => {
                      if (!hasEditionPermission)
                        return notify(
                          "Seu usuário não tem permissão para realizar essa ação"
                        );

                      handleStandAloneRequestCnd({
                        document: companyUser.document,
                        agendaId: row?.agendaID,
                        myCnds,
                        setMyCnds,
                      });
                    }}
                  />
                </ContainerIcon>
              </LightTooltip>
            </Grid>
          )}
        </GridStyled>
      ),
    },
    {
      field: "ambito",
      headerName: "Âmbito",
      width: 121,
      renderCell: ({ row }) => (
        <div className="MuiDataGrid-columnHeaderTitle">{row.scope?.scope}</div>
      ),
    },
    {
      field: "certidao",
      headerName: "Certidão",
      flex: 1,
      minWidth: 160,
      renderCell: ({ row }) => (
        <div className="MuiDataGrid-columnHeaderTitle">
          {row.scope?.scope &&
            (row.scope?.scope === "Municipal"
              ? `${row.cndName} - ${row.scope?.state}`
              : row.cndName)}
        </div>
      ),
    },
    {
      field: "situacao",
      headerName: "Situação",
      align: "center",
      width: 132,
      renderCell: ({ row }) => (
        <GridStyled container alignItems="center" justifyContent="center">
          <Grid item flex={1}>
            {OptionsIcons[row.status]}
          </Grid>
        </GridStyled>
      ),
    },
    {
      field: "data_emissao",
      headerName: "Emissão",
      align: "center",
      width: 131,
      renderCell: ({ row }) => (
        <div className="MuiDataGrid-columnHeaderTitle">
          {row.issuanceDate
            ? row.issuanceDate.split("-").reverse().join("/")
            : ""}
        </div>
      ),
    },
    {
      field: "data_vencimento",
      headerName: "Vencimento",
      align: "center",
      width: 151,
      renderCell: ({ row }) => (
        <div className="MuiDataGrid-columnHeaderTitle">
          {row.expirationDate
            ? row.expirationDate.split("-").reverse().join("/")
            : ""}
        </div>
      ),
    },
  ];

  const children = [
    <TableTabCnd
      tab="federal"
      rows={cndsFederal}
      pageSize={perPageFederal}
      setRows={setCndsFederal}
    />,
    <TableTabCnd
      tab="state"
      rows={cndsState}
      pageSize={perPageState}
      setRows={setCndsState}
    />,
    <TableTabCnd
      tab="municipal"
      rows={cndsMunicipal}
      pageSize={perPageMunicipal}
      setRows={setCndsMunicipal}
    />,
    <TableTabCnd
      tab="others"
      rows={cndsOthers}
      pageSize={perPageOthers}
      setRows={setCndsOthers}
    />,
  ];

  return (
    <>
      {dataSendEmail?.id && (
        <ModalSendEmail
          handleClose={() => setDataSendEmail({})}
          row={dataSendEmail}
        />
      )}
      <Loading open={Object.keys(loading).length > 0} />

      {!companyUser.isAccountant && (
        <Grid item flex={1}>
          <Title color="blue">CNDs</Title>
        </Grid>
      )}
      <Margin bottom="15" />

      <Grid container spacing={customSpacing} justifyContent="space-between">
        <GridStyled container spacing={3} margin="0px 0px 20px 0">
          <GridStyled item xs bg-color="white" border-radius="4">
            <TitleTable>Favoritos</TitleTable>

            <DataGridCnd
              rows={cndsFavorites}
              columns={columns}
              disableSelectionOnClick
              disableColumnMenu
              pageSize={10}
              rowHeight={cndsFavorites.length === 0 ? 15 : 32}
              autoHeight
            />

            <Grid item xs={12}>
              <TableDescription />
            </Grid>
          </GridStyled>
        </GridStyled>

        <GridStyled container>
          <ContainerTab item xs>
            <TabCustom
              children={children}
              headers={[
                {
                  label: "Federal",
                },
                {
                  label: "Estadual",
                },
                {
                  label: "Municipal",
                },
                {
                  label: "Outros",
                },
              ]}
              tabIndex={tabIndex}
              onChange={(event, value) => {
                if (Number.isInteger(value)) {
                  setTabIndex(value);
                }
              }}
            />
          </ContainerTab>
        </GridStyled>
      </Grid>
    </>
  );
};

const CndCustomerList = ({ ...props }) => {
  return (
    <CndCustomerContextProvider>
      <View {...props} />
    </CndCustomerContextProvider>
  );
};

export { CndCustomerList };
