import { search } from "../../BaseRepositoryComponents";

const path = `${process.env.REACT_APP_PAINEL_API_URL}/api/v1/`;

export async function getWidgets(idUser, idEntity) {
  return search({
    path: `${path}user/${idUser}/entity/${idEntity}/widgets`,
    method: "GET",
  });
}

export async function putWidgets({ body, idpanel }) {
  return search({
    path: `${path}panel/${idpanel}/widget`,

    method: "POST",

    myBody: body,
  });
}

export async function getPanel(idEntity) {
  return search({ path: `${path}entity/${idEntity}/panel`, method: "GET" });
}

export async function getHomePageWidgets(idPanel) {
  return search({ path: `${path}panel/${idPanel}/widget`, method: "GET" });
}

export async function deletePanelWidget(idPanel, idVersionWidget) {
  return search({
    path: `${path}panel/${idPanel}/widget/${idVersionWidget}`,
    method: "DELETE",
  });
}
