import { useContext, useEffect } from "react";

/**
 * Components
 */
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";

/**
 * Styled Components
 */
import Btn from "../../../components/buttons/Btn";
import { GridStyled, LabelStyled, Title } from "../../../styles/GlobalStyle";
import { DialogCnd } from "../styles/CndCustomer";

/**
 * Context
 */
import { CndAccountantContext } from "../contexts/CndAccountantContext";

/**
 * Controller
 */
import { useCndAccountantController } from "../controllers/CndAccountantController";

function ModalLastDate({ handleClose, row }) {
  const { lastResponses } = useCndAccountantController();

  const { lastProcessing, setLastProcessing } =
    useContext(CndAccountantContext);

  const addingZero = (number) => {
    if (number <= 9) return "0" + number;
    else return number;
  };

  const formatedDate = (date) => {
    const newFormatedDate = new Date(date);

    const prettyDate = `
      ${addingZero(newFormatedDate.getDate())}/${addingZero(
      newFormatedDate.getMonth() + 1
    )}/${newFormatedDate.getFullYear()}`;

    return prettyDate.toString();
  };

  const formatedDateProcessing = (date) => {
    const newFormatedDate = new Date(date);

    const prettyDate = `
      ${addingZero(newFormatedDate.getDate() + 1)}/${addingZero(
      newFormatedDate.getMonth() + 1
    )}/${newFormatedDate.getFullYear()}`;

    return prettyDate.toString();
  };

  const formateCreateDate = (date) => {
    const newFormatedDate = new Date(date);

    const prettyDate = `${addingZero(newFormatedDate.getDate())}/${addingZero(
      newFormatedDate.getMonth() + 1
    )}/${newFormatedDate.getFullYear()}`;
    return prettyDate.toString();
  };

  useEffect(() => {
    lastResponses(row);
  }, [lastResponses, row]);

  return (
    <>
      <DialogCnd
        open={true}
        maxWidth="sm"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <Title subtitle>Últimos Processamentos</Title>
        </DialogTitle>

        <DialogContent>
          <GridStyled
            container
            xs={12}
            spacing={2}
            alignItems="center"
            direction="column"
            width={"100%"}
          >
            <GridStyled item xs={12} width={"100%"}>
              <TableContainer>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell align="center">Data processamento</TableCell>
                      <TableCell align="center">Status processamento</TableCell>
                      <TableCell align="center">Hora da emissão</TableCell>
                      <TableCell align="center">Data de Emissão</TableCell>
                      <TableCell align="center">Data de Vencimento</TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {lastProcessing?.map((process, index) => (
                      <TableRow key={index}>
                        <TableCell align="center">
                          {formateCreateDate(process.create_date)}
                        </TableCell>
                        <TableCell align="center">{process.status}</TableCell>
                        <TableCell align="center">
                          {process.issue_hour !== null
                            ? process.issue_hour
                            : "-"}
                        </TableCell>
                        <TableCell align="center">
                          {process?.emisson_date !== ""
                            ? formatedDate(process?.emisson_date)
                            : "-"}
                        </TableCell>
                        <TableCell align="center">
                          {process?.due_date !== ""
                            ? formatedDate(process?.due_date)
                            : "-"}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </GridStyled>

            <GridStyled item xs={12}>
              <LabelStyled fontSize="14" fontWeight="600">
                Próximo Agendamento:
              </LabelStyled>

              <LabelStyled fontSize="14" fontFamily="Open Sans">
                {row?.nextExecutionDate
                  ? " " + formatedDateProcessing(row.nextExecutionDate)
                  : " Sem agendamentos programados"}
              </LabelStyled>
            </GridStyled>
          </GridStyled>
        </DialogContent>

        <DialogActions>
          <Btn
            label="Fechar"
            onClick={() => {
              setLastProcessing([]);
              handleClose();
            }}
          />
        </DialogActions>
      </DialogCnd>
    </>
  );
}

export { ModalLastDate };
