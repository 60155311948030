import {
  Badge,
  Grid,
  IconButton,
  makeStyles,
  Tooltip,
  Zoom,
} from "@material-ui/core";
import {
  Settings as SettingsIcon,
  Visibility as VisibilityIcon,
} from "@material-ui/icons";
import { default as React, useContext } from "react";
import { GlobalContext } from "../../contexts/GlobalContext";
import { ContainerIcon, LabelStyled } from "../../styles/GlobalStyle";
import { ButtonFooter, ChatCard, ChatContainer } from "./styles/Chat";

import { useChatContext } from "./contexts/ChatContext";

import { ReactComponent as ContactsIcon } from "../../assets/icons/contato.svg";
import { ReactComponent as WaitQueueIcon } from "../../assets/icons/espera.svg";
import ChatController from "./controllers/ChatController";

import { useEffect, useState } from "react";
import { useMonitor } from "./contexts/ChatMonitorContext";
import SettingsController from "./controllers/SettingsController";
import Avaliation from "./screens/Avaliation";
import CallListContacts from "./screens/CallListContacts";
import ChatMonitor from "./screens/ChatMonitor";
import ClosingConversationScreen from "./screens/ClosingConversation";
import ContactScreen from "./screens/Contact";
import ConversationScreen from "./screens/Conversation";
import CustomerLobbyScreen from "./screens/CustomerLobby";
import CustomerLobbyWaitingScreen from "./screens/CustomerLobbyWaiting";
import { LoadingScreen } from "./screens/Loading";
import SettingsScreen from "./screens/Settings";
import { calcWaitingTime } from "./utils";

const ChatHeader = ({ queueListState }) => {
  const { companyUser } = useContext(GlobalContext);
  const context = useChatContext();
  const { setCompanyValidation } = useMonitor();
  const chatController = ChatController();
  const currentScreen = context.chat.currentScreen;
  const currentConversation = context.getCurrentConversation();

  const useStyles = makeStyles((theme) => ({
    customBadge: {
      backgroundColor: "#FA3A3A",
      color: "white",
      fontSize: 10,
      width: "15px",
      height: "15px",
      minWidth: "15px",
    },
  }));

  const classes = useStyles();

  let conversationTittleData = {
    title: "",
    subTitle: "",
  };
  if (companyUser.isAccountant === "true") {
    if (currentConversation) {
      conversationTittleData.title = currentConversation.companyName;
      if (currentConversation.name && currentConversation.name !== "")
        conversationTittleData.subTitle += currentConversation.name;
      if (
        currentConversation.departmentName &&
        currentConversation.departmentName !== ""
      )
        conversationTittleData.subTitle += ` - ${currentConversation.departmentName}`;
    }
  } else if (
    currentConversation?.departmentName &&
    currentConversation?.departmentName !== ""
  ) {
    conversationTittleData.title = currentConversation?.departmentName;
  }
  if (conversationTittleData.title === "")
    conversationTittleData.title =
      companyUser.isAccountant === "true" ? "Atendimentos" : "Atendimento";

  const map = {
    [context.SCREEN_ENUM.settings]: {
      title: "Configurações",
      subTitle: "",
    },
    [context.SCREEN_ENUM.contactList]: {
      title: "Clientes / Fila",
      subTitle: "",
    },
    [context.SCREEN_ENUM.conversation]: conversationTittleData,
    [context.SCREEN_ENUM.customerLobby]: {
      title: "Iniciar Atendimento",
      subTitle: "",
    },
    [context.SCREEN_ENUM.closingConversation]: {
      title: "Encerramento",
      subTitle: "",
    },
    [context.SCREEN_ENUM.avaliation]: {
      title: "Avaliação",
      subTitle: "",
    },
    [context.SCREEN_ENUM.loading]: {
      title: "",
      subTitle: "",
    },
    [context.SCREEN_ENUM.chatMonitor]: {
      title: "Monitor",
      subtitle: "",
    },
  };

  const { title, subTitle } = map[currentScreen] || {
    title: "",
    subTitle: "",
  };

  if (companyUser.isAccountant === "true") {
    if (currentConversation) {
      conversationTittleData.title = currentConversation.companyName;
      if (currentConversation.name && currentConversation.name !== "")
        conversationTittleData.subTitle += currentConversation.name;
      if (
        currentConversation.departmentName &&
        currentConversation.departmentName !== ""
      )
        conversationTittleData.subTitle += ` - ${currentConversation.departmentName}`;
    }
  } else if (
    currentConversation?.departmentName &&
    currentConversation?.departmentName !== ""
  ) {
    conversationTittleData.title = currentConversation?.departmentName;
  }
  if (conversationTittleData.title === "")
    conversationTittleData.title =
      companyUser.isAccountant === "true" ? "Atendimentos" : "Atendimento";

  function onLinkHeaderClick(to) {
    switch (to) {
      case currentScreen:
        context.setChat({
          currentScreen: context.SCREEN_ENUM.conversation,
        });

        return;

      case "contact-list":
        const updatedContactList = context.chat.queue.map((u) => ({
          ...u,
          waitingTime: calcWaitingTime(u.created_at),
        }));
        context.setChat({
          currentScreen: context.SCREEN_ENUM.contactList,
          queue: updatedContactList,
        });

        return;

      case "settings":
        context.setChat({
          currentScreen: context.SCREEN_ENUM.settings,
        });

        context.chat.isLoading && chatController.openSettings("settings");

        return;

      case "call-list-contacts":
        context.setChat({
          currentScreen: context.SCREEN_ENUM.callListContacts,
        });

        return;

      case "chat-monitor":
        setCompanyValidation(null);
        context.setChat({
          currentScreen: context.SCREEN_ENUM.chatMonitor,
        });

        context.chat.isLoading && chatController.openSettings("chatMonitor");

        return;

      default:
        return null;
    }
  }

  return (
    <Grid
      item
      container
      alignItems="center"
      style={{
        height: "12%",
        borderRadius: "20px 20px 0px 0px",
        background: "#006FDE",
      }}
    >
      <Grid
        item
        xs={6}
        style={{
          whiteSpace: "nowrap",
          width: "55%",
          overflow: "hidden",
          textOverflow: "ellipsis",
          textAlign: "left",
        }}
      >
        <Grid item xs={12}>
          <LabelStyled fontSize="14" color="white">
            <b>{title}</b>
          </LabelStyled>
        </Grid>
        <Grid item xs={12}>
          <LabelStyled color="white" fontSize="11" marginLeft="10px">
            {subTitle}
          </LabelStyled>
        </Grid>
      </Grid>
      {companyUser.isAccountant === "true" && (
        <Grid item xs={6} container justifyContent="flex-end">
          <Grid
            item
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Tooltip title="Monitor" placement="bottom">
              <ContainerIcon
                onClick={() => onLinkHeaderClick("chat-monitor")}
                style={{
                  backgroundColor:
                    currentScreen === "chat-monitor"
                      ? "#0068d1"
                      : "transparent",
                  borderRadius: "50%",
                }}
                iconColor="white"
                fontSize="29"
              >
                <IconButton>
                  <VisibilityIcon
                    style={{
                      fill: "#FFF",
                      width: "20px",
                    }}
                  />
                </IconButton>
              </ContainerIcon>
            </Tooltip>
          </Grid>
          <Grid
            item
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Tooltip title="Contatos" placement="bottom">
              <ContainerIcon
                onClick={() => onLinkHeaderClick("call-list-contacts")}
                style={{
                  backgroundColor:
                    currentScreen === "call-list-contacts"
                      ? "#0068d1"
                      : "transparent",
                  borderRadius: "50%",
                  width: "42px",
                  height: "42px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                iconColor="white"
                fontSize="20"
              >
                <IconButton>
                  <ContactsIcon
                    style={{
                      fill: "#FFF",
                      width: "20px",
                    }}
                  />
                </IconButton>
              </ContainerIcon>
            </Tooltip>
          </Grid>
          <Grid
            item
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Tooltip title="Fila" placement="bottom">
              <ContainerIcon
                onClick={() => onLinkHeaderClick("contact-list")}
                style={{
                  backgroundColor:
                    currentScreen === "contact-list"
                      ? "#0068d1"
                      : "transparent",
                  borderRadius: "50%",
                  width: "42px",
                  height: "42px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                iconColor="white"
                fontSize="20"
              >
                <IconButton>
                  <Badge
                    badgeContent={queueListState.count}
                    classes={{ badge: classes.customBadge }}
                    max={9}
                  >
                    <WaitQueueIcon
                      style={{
                        fill: "#FFF",
                        width: "15px",
                      }}
                    />
                  </Badge>
                </IconButton>
              </ContainerIcon>
            </Tooltip>
          </Grid>
          <Grid item style={{ display: "flex", alignItems: "center" }}>
            <Tooltip title="Configurações" placement="bottom">
              <ContainerIcon
                onClick={() => onLinkHeaderClick("settings")}
                style={{
                  backgroundColor:
                    currentScreen === "settings" ? "#0068d1" : "transparent",
                  borderRadius: "50%",
                  width: "42px",
                  height: "42px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                iconColor="white"
                fontSize="20"
              >
                <IconButton>
                  <SettingsIcon />
                </IconButton>
              </ContainerIcon>
            </Tooltip>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

const ChatContent = () => {
  const context = useChatContext();

  const map = {
    [context.SCREEN_ENUM.settings]: SettingsScreen,
    [context.SCREEN_ENUM.contactList]: ContactScreen,
    [context.SCREEN_ENUM.conversation]: ConversationScreen,
    [context.SCREEN_ENUM.customerLobby]: CustomerLobbyScreen,
    [context.SCREEN_ENUM.customerLobbyWaiting]: CustomerLobbyWaitingScreen,
    [context.SCREEN_ENUM.closingConversation]: ClosingConversationScreen,
    [context.SCREEN_ENUM.avaliation]: Avaliation,
    [context.SCREEN_ENUM.loading]: LoadingScreen,
    [context.SCREEN_ENUM.callListContacts]: CallListContacts,
    [context.SCREEN_ENUM.chatMonitor]: ChatMonitor,
  };

  const Screen = map[context.chat.currentScreen];

  return (
    <Grid
      item
      container
      xs={12}
      style={{
        height: "auto",
        flex: "1",
        width: "100%",
        overflowY: Screen === Avaliation ? "scroll" : "hidden",
        overflowX: "hidden",
      }}
    >
      <Screen />
    </Grid>
  );
};

const ChatFooter = () => {
  const { companyUser } = useContext(GlobalContext);

  return (
    companyUser?.isAccountant === "true" && (
      <Grid
        item
        container
        xs={12}
        style={{ maxHeight: "40px", textAlign: "center" }}
      >
        <ButtonFooter select="selected" typeChat="customer">
          <span style={{ margin: "auto" }}>Cliente</span>
        </ButtonFooter>
        <ButtonFooter select="" typeChat="">
          <span style={{ margin: "auto" }}>Interno</span>
        </ButtonFooter>
      </Grid>
    )
  );
};

export default function Chat() {
  const context = useChatContext();
  const [queueListState, setQueueListState] = useState({ count: 0 });

  const { saveStatus } = SettingsController();

  useEffect(() => {
    setQueueListState({ count: context.chat.queue?.length });
  }, [context.chat.queue, setQueueListState]);

  useEffect(() => {
    if (context.settings.myState === "0")
      saveStatus(context.settings.myState, "myState");
  }, [context.settings.myState, saveStatus]);

  return (
    <Zoom in={true}>
      <ChatContainer>
        <ChatCard
          container
          spacing={2}
          style={{
            border: "none",
            background: "#ECEEF1",
            outline: "2px solid #D9D9D9",
            flexWrap: "nowrap",
          }}
        >
          <ChatHeader queueListState={queueListState} />

          <Grid
            item
            container
            xs={12}
            alignItems="center"
            style={{
              height: "490px",
              flex: 1,
              padding: "0px",
              flexDirection: "column",
              overflow: "hidden",
            }}
          >
            <ChatContent />
            <ChatFooter />
          </Grid>
        </ChatCard>
      </ChatContainer>
    </Zoom>
  );
}
