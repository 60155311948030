import {
  Avatar,
  Divider,
  Grid,
  ListItem,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { GridStyled } from "../../../styles/GlobalStyle";
import { TypographyStyled } from "../styles/GeneralStyle";

import { getAvatarContent, getDateDiff } from "../utils";

const RenderListItemCustomer = ({ item }) => {
  return (
    <GridStyled width="100%">
      <ListItem alignItems="flex-start">
        <Grid container>
          <Grid xs={2}>
            <Tooltip title={item?.name} enterDelay={300}>
              <Avatar alt={item?.name}>{getAvatarContent(item?.name)}</Avatar>
            </Tooltip>
          </Grid>
          <Grid xs={8} container alignItems="center">
            <GridStyled item xs={12} margin-left="5">
              <b>{item?.name}</b>
            </GridStyled>
            <Grid item xs container>
              {item?.company?.name && (
                <GridStyled item xs={12} margin-left="5">
                  <Tooltip title={`${item?.company?.name}`} enterDelay={300}>
                    <Typography
                      component="span"
                      variant="subtilte1"
                      color="text.primary"
                    >
                      {`${item?.company?.name}`}
                    </Typography>
                  </Tooltip>
                </GridStyled>
              )}
              <GridStyled item xs={12} margin-left="5">
                <Tooltip
                  title={`Atendido por ${item?.attendant?.name}`}
                  enterDelay={300}
                >
                  <TypographyStyled
                    component="p"
                    variant="subtilte1"
                    color="text.primary"
                    font-size="11px"
                  >
                    {`Atendente: ${item?.attendant?.name}`}
                  </TypographyStyled>
                </Tooltip>
              </GridStyled>
            </Grid>
          </Grid>
          <Grid
            xs={2}
            container
            alignContent="center"
            alignItems="center"
            justifyContent="space-around"
          >
            <Tooltip
              title={`Conversa iniciada há ${getDateDiff({
                startDate: item?.startConvesationDate,
              })}`}
              placement="top"
            >
              <TypographyStyled
                component="span"
                variant="subtilte1"
                color="rgb(0, 111, 222)"
                textAlign="end"
                padding="0px 1px"
                font-size="11px"
              >
                <b>{`${getDateDiff({
                  startDate: item?.startConvesationDate,
                })}`}</b>
              </TypographyStyled>
            </Tooltip>
          </Grid>
        </Grid>
      </ListItem>
      <Divider variant="middle" />
    </GridStyled>
  );
};

export default RenderListItemCustomer;
