import { Switch } from "react-router-dom";
import { ModalCurrentUserContextProvider } from "../../pages/currentUser/contexts/ModalCurrentUserContext";
import { SettingContextProvider } from "../../pages/settings/contexts/SettingContext";
import { ConfigurePanel } from "../../pages/customerManagements/children/ConfigurePanel";
import Settings from "../../pages/settings/Setting";
import MiddlewareRoute from "../Middleware";

export default function Setting() {
  return (
    <>
      <SettingContextProvider>
        <ModalCurrentUserContextProvider>
          <Switch>
            <MiddlewareRoute
              path="/setting/users"
              component={ConfigurePanel}
              sidebar
              noPaddingSidebar
            />
            <MiddlewareRoute path="/setting" component={Settings} sidebar />
          </Switch>
        </ModalCurrentUserContextProvider>
      </SettingContextProvider>
    </>
  );
}
