import { useContext } from "react";

/**
 * Component
 */

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
} from "@material-ui/core";

import SelectErp from "../../../components/basics/SelectErp";
import TextFieldErp from "../../../components/basics/TextFieldErp";
import Btn from "../../../components/buttons/Btn";
import UploadFiles from "../../../components/uploadFiles/UploadFiles";

/**
 * Styled Component
 */

import { GridStyled, LabelStyled, Title } from "../../../styles/GlobalStyle";

/**
 * Context
 */

import { CndAccountantContext } from "../contexts/CndAccountantContext";

/**
 * Controller
 */

import { useCndAccountantController } from "../controllers/CndAccountantController";

export const ModalAccountantUploadCnd = () => {
  const {
    cndToUpload,
    clientCndToUpload,
    manualUploadedCnd,
    setManualUploadedCnd,
    manualIssueDate,
    setManualIssueDate,
    manualDueDate,
    setManualDueDate,
    manualStatus,
    setManualStatus,
    statusManualOptions,
    errors,
  } = useContext(CndAccountantContext);

  const { handleSubmitCnd, handleClose } = useCndAccountantController();

  return (
    <Dialog
      open={!!cndToUpload?.id}
      maxWidth="md"
      fullWidth
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        <Title subtitle>Upload Manual de CND</Title>
      </DialogTitle>

      <DialogContent>
        <Grid container spacing={2}>
          <Grid item md={12} xs={12}>
            <TextFieldErp
              label="Cliente"
              disabled
              value={clientCndToUpload?.companyName || ""}
            />
          </Grid>
          <Grid item md={12} xs={12}>
            <TextFieldErp
              label="CND"
              disabled
              value={cndToUpload?.type_rpa_provider?.type?.description || ""}
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <TextFieldErp
              error={!!errors.manualIssueDate}
              helperText={errors.manualIssueDate || ""}
              label="Emissão"
              type="date"
              value={manualIssueDate}
              setValue={setManualIssueDate}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <TextFieldErp
              error={!!errors.manualDueDate}
              helperText={errors.manualDueDate || ""}
              label="Vencimento"
              type="date"
              value={manualDueDate}
              setValue={setManualDueDate}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <SelectErp
              label="Status"
              error={!!errors.manualStatus}
              helperText={errors.manualStatus || ""}
              value={manualStatus}
              optionsList={statusManualOptions}
              descriptionField="description"
              setValue={setManualStatus}
            />
          </Grid>

          <GridStyled item xs={12} height="140px">
            <UploadFiles
              uploadedFiles={manualUploadedCnd}
              setUploadedFiles={setManualUploadedCnd}
              accept="application/pdf"
              label="Arraste a CND aqui"
            />
          </GridStyled>
          <LabelStyled color="red">
            {errors.manualUploadedCnd || ""}
          </LabelStyled>
        </Grid>
      </DialogContent>

      <DialogActions>
        <Btn label="Cancelar" bg-color="tertiary" onClick={handleClose} />
        <Btn label="Enviar" onClick={handleSubmitCnd} />
      </DialogActions>
    </Dialog>
  );
};
