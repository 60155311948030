import { search } from "../../../../BaseRepositoryComponents";

const path = `${process.env.REACT_APP_PAINEL_API_URL}/api/v1/integrations/rpa/group`;
const newPath = `${process.env.REACT_APP_CND_API_URL}/v1/entity`;

export async function get({ id }) {
  return search({
    path: `${path}${id ? `/${id}` : ""}`,
  });
}

export async function post({ body }) {
  return search({
    method: "POST",
    path: `${path}`,
    myBody: body,
  });
}

export async function put({ body, id }) {
  return search({
    method: "PUT",
    path: `${path}/${id}`,
    myBody: body,
  });
}

export async function destroy({ id }) {
  return search({
    method: "DELETE",
    path: `${path}/${id}`,
  });
}

export async function sync({ body }) {
  return search({
    method: "PUT",
    path: `${path}/sync`,
    myBody: body,
  });
}

export async function syncEntities({ id, body }) {
  return search({
    method: "PUT",
    path: `${path}/${id}/sync-entities`,
    myBody: body,
  });
}

export async function getEntities({ id, document }) {
  return search({
    path: `${newPath}/${document}/group/${id}/entity`,
  });
}

export async function registerCostumer({ id, document, body }) {
  return search({
    method: "POST",
    path: `${newPath}/${document}/group/${id}/entity`,
    myBody: body,
  });
}

export async function deleteCostumer({ id, document, documentClient, body }) {
  return search({
    method: "DELETE",
    path: `${newPath}/${document}/group/${id}/entity/${documentClient}`,
    myBody: body,
  });
}

export async function NewGroup({ document, body }) {
  return search({
    method: "POST",
    path: `${newPath}/${document}/groups`,
    myBody: body,
  });
}

export async function DeleteGroup(group_id) {
  return search({
    method: "DELETE",
    path: `${newPath}/${group_id}/groups`,
  });
}

export async function AddCndsInGroup(agenda_id, group_id) {
  return search({
    method: "POST",
    path: `${newPath}/${agenda_id}/group/${group_id}`,
  });
}

export async function RemoveCndsInGroup(agenda_id, group_id) {
  return search({
    method: "DELETE",
    path: `${newPath}/${agenda_id}/group/${group_id}`,
  });
}

export async function UpdateGroup(group_id, body) {
  return search({
    method: "PUT",
    path: `${newPath}/${group_id}/groups`,
    myBody: body,
  });
}

export async function UpdateDefaultGroups(group_id, body) {
  return search({
    method: "PUT",
    path: `${newPath}/${group_id}/groups/default`,
    myBody: body,
  });
}
