import { search } from "../../../BaseRepositoryComponents";

const path = `${process.env.REACT_APP_PAINEL_API_URL}/api/v1/integrations/erp`;

export async function getUsersErp({ customerDocument }) {
  return search({
    path: `${path}/users/list?empresa_cnpj=${customerDocument}`,
    method: "GET",
  });
}

export async function syncUsersErp({ customerDocument, body }) {
  return search({
    path: `${path}/users/import?empresa_cnpj=${customerDocument}`,
    method: "GET",
    myBody: body,
  });
}
