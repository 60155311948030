import { Grid } from "@material-ui/core";
import { AccordionAccountantRequestCnd } from "./AccordionAccountantRequestCnd";

export const TableTabCndRequest = () => {
  return (
    <Grid container direction="column" spacing={3}>
      <Grid item>
        <AccordionAccountantRequestCnd />
      </Grid>
    </Grid>
  );
};
