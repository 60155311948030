import { Grid, TextField } from "@material-ui/core";
import moment from "moment";
import React from "react";
import { GridStyled } from "../../../styles/GlobalStyle";
import { useTicket } from "../contexts/EditSupportServiceContext";
import AutoCompleteCRM from "./AutoCompleteCRM";

const GeneralData = () => {
  const {
    handleChange,
    handleBlur,
    handleInputChange,
    values,
    touched,
    errors,
    fields,
    loadingTicket,
    ticketData,
  } = useTicket();

  return (
    <>
      <GridStyled item xs={12} padding="15" text-align="center">
        <strong>
          ABERTO EM {moment(ticketData?.startDate).format("MM/DD/YYYY")}
          {ticketData?.solucionDate &&
            ` - FECHADO EM ${moment(ticketData?.endDate).format("MM/DD/YYYY")}`}
        </strong>
      </GridStyled>

      <Grid item xs={6}>
        <AutoCompleteCRM
          label="Cliente"
          id="customer"
          descriptionField="title"
          variantAutocomplete="outlined"
          error={Boolean(touched.customer && errors.customer)}
          helperText={
            touched.customer && errors.customer ? errors.customer : ""
          }
          value={fields?.customer || ""}
          setValue={(value) => {
            handleInputChange(value, "customer");
          }}
          loading={loadingTicket}
          onBlur={handleBlur}
          disabled
        />
      </Grid>

      <Grid item xs={6}>
        <AutoCompleteCRM
          label="Situação"
          id="situation"
          descriptionField="title"
          variantAutocomplete="outlined"
          error={Boolean(touched.situation && errors.situation)}
          helperText={
            touched.situation && errors.situation ? errors.situation : ""
          }
          value={fields?.situation || ""}
          setValue={(value) => {
            handleInputChange(value, "situation");
          }}
          loading={loadingTicket}
          onBlur={handleBlur}
          disabled
        />
      </Grid>

      <Grid item xs={6}>
        <AutoCompleteCRM
          label="Departamento"
          id="department"
          descriptionField="title"
          variantAutocomplete="outlined"
          error={Boolean(touched.department && errors.department)}
          helperText={
            touched.department && errors.department ? errors.department : ""
          }
          value={fields?.department || ""}
          setValue={(value) => {
            handleInputChange(value, "department");
          }}
          loading={loadingTicket}
          onBlur={handleBlur}
          disabled
        />
      </Grid>

      <Grid item xs={6}>
        <AutoCompleteCRM
          label="Classificaação"
          id="classification"
          descriptionField="title"
          variantAutocomplete="outlined"
          error={Boolean(touched.classification && errors.classification)}
          helperText={
            touched.classification && errors.classification
              ? errors.classification
              : ""
          }
          value={fields?.classification || ""}
          setValue={(value) => {
            handleInputChange(value, "classification");
          }}
          loading={loadingTicket}
          onBlur={handleBlur}
          disabled
        />
      </Grid>

      <Grid item xs={12}>
        <TextField
          id="subject"
          label="Assunto"
          variant="outlined"
          fullWidth
          size="small"
          error={Boolean(touched.subject && errors.subject)}
          helperText={touched.subject && errors.subject ? errors.subject : ""}
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.subject}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          id="solicitation"
          label="Solicitação"
          variant="outlined"
          fullWidth
          size="small"
          error={Boolean(touched.solicitation && errors.solicitation)}
          helperText={
            touched.solicitation && errors.solicitation
              ? errors.solicitation
              : ""
          }
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.solicitation}
          multiline
          rows={4}
          disabled
        />
      </Grid>
    </>
  );
};

export default GeneralData;
