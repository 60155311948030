class ItemGridDragDrop {
  constructor(
    id,
    titulo,
    hideHeader,
    positionX,
    positionY,
    width = 4,
    height = 4,
    minWidth,
    minHeight,
    maxWidth,
    maxHeight,
    // If true, equal to `isDraggable: false, isResizable: false`.
    itemStatic,
    // If false, will not be draggable. Overrides `static`.
    isDraggable,
    // If false, will not be resizable. Overrides `static`.
    isResizable,
    // By default, a handle is only shown on the bottom-right (southeast) corner.
    // Note that resizing from the top or left is generally not intuitive.
    resizeHandles,
    // If true and draggable, item will be moved only within grid.
    isBounded,
    stringChildren,
    stringScripts = [],
    importScripts = [],
    headerStyle,
    visualizar = true,
    onRefresh,
    refreshInterval = 0,
    refreshOnLoad = false,
    props
  ) {
    this.i = id;
    this.titulo = titulo;
    this.hideHeader = hideHeader;
    this.x = positionX;
    this.y = positionY;
    this.w = width;
    this.h = height;
    this.minW = minWidth;
    this.minH = minHeight;
    this.maxW = maxWidth;
    this.maxH = maxHeight;
    this.static = itemStatic;
    this.isDraggable = isDraggable;
    this.isResizable = isResizable;
    this.resizeHandles = resizeHandles;
    this.isBounded = isBounded;
    this.stringChildren = stringChildren;
    this.importScripts = importScripts;
    this.stringScripts = stringScripts;
    this.headerStyle = headerStyle;
    this.visualizar = visualizar;
    this.onRefresh = onRefresh;
    this.refreshInterval = refreshInterval;
    this.refreshOnLoad = refreshOnLoad;
    this.props = props;
  }

  static from(obj) {
    return new ItemGridDragDrop(
      obj.i,
      obj.titulo,
      obj.x,
      obj.y,
      obj.w,
      obj.h,
      obj.minW,
      obj.minH,
      obj.maxW,
      obj.maxH,
      obj.static,
      obj.isDraggable,
      obj.isResizable,
      obj.resizeHandles,
      obj.isBounded,
      obj.stringChildren,
      obj.importScripts,
      obj.stringScripts,
      obj.headerStyle,
      obj.visualizar,
      obj.onRefresh,
      obj.refreshInterval,
      obj.refreshOnLoad,
      obj.props
    );
  }
}

export default ItemGridDragDrop;
