export function orderBy(array, key) {
  if (!Array.isArray(array) || typeof key !== "string") return [];

  return array.sort(function (a, b) {
    if (a[key] > b[key]) {
      return 1;
    } else if (a[key] < b[key]) {
      return -1;
    }
    return 0;
  });
}
