const options = {
  play: (color) => (
    <svg
      width="20"
      height="21"
      viewBox="0 0 24 25"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M9.09 17.143L17.1 12.043L9.09 6.94303V17.143ZM12 24.043C10.36 24.043 8.81 23.728 7.35 23.098C5.89 22.468 4.615 21.608 3.525 20.518C2.435 19.428 1.575 18.153 0.945 16.693C0.315 15.233 0 13.683 0 12.043C0 10.383 0.315 8.82303 0.945 7.36303C1.575 5.90303 2.435 4.63303 3.525 3.55303C4.615 2.47303 5.89 1.61803 7.35 0.98803C8.81 0.35803 10.36 0.0430298 12 0.0430298C13.66 0.0430298 15.22 0.35803 16.68 0.98803C18.14 1.61803 19.41 2.47303 20.49 3.55303C21.57 4.63303 22.425 5.90303 23.055 7.36303C23.685 8.82303 24 10.383 24 12.043C24 13.683 23.685 15.233 23.055 16.693C22.425 18.153 21.57 19.428 20.49 20.518C19.41 21.608 18.14 22.468 16.68 23.098C15.22 23.728 13.66 24.043 12 24.043ZM12 22.243C14.84 22.243 17.25 21.248 19.23 19.258C21.21 17.268 22.2 14.863 22.2 12.043C22.2 9.20303 21.21 6.79303 19.23 4.81303C17.25 2.83303 14.84 1.84303 12 1.84303C9.18 1.84303 6.775 2.83303 4.785 4.81303C2.795 6.79303 1.8 9.20303 1.8 12.043C1.8 14.863 2.795 17.268 4.785 19.258C6.775 21.248 9.18 22.243 12 22.243Z" />
    </svg>
  ),
  chip: (color) => (
    <svg
      version="1.1"
      id="Camada_1"
      x="0px"
      y="0px"
      viewBox="0 0 24 24"
      width="20"
      height="21"
      fill={color}
    >
      <path
        class="st0"
        d="M9,8C8.4,8,8,8.5,8,9v6c0,0.6,0.4,1,1,1h6c0.6,0,1-0.4,1-1V9c0-0.6-0.4-1-1-1H9z M10,14v-4h4v4H10z"
      />
      <path
        class="st0"
        d="M7.5,0c0.6,0,1,0.4,1,1v2H11V1c0-0.6,0.4-1,1-1s1,0.4,1,1v2h2.5V1c0-0.6,0.4-1,1-1c0.6,0,1,0.4,1,1v2H19
	c1.1,0,2,0.9,2,2v1.5h2c0.6,0,1,0.4,1,1c0,0.6-0.4,1-1,1h-2V11h2c0.6,0,1,0.4,1,1c0,0.6-0.4,1-1,1h-2v2.5h2c0.6,0,1,0.4,1,1
	s-0.4,1-1,1h-2V19c0,1.1-0.9,2-2,2h-1.5v2c0,0.6-0.4,1-1,1c-0.6,0-1-0.4-1-1v-2H13v2c0,0.6-0.4,1-1,1s-1-0.4-1-1v-2H8.5v2
	c0,0.6-0.4,1-1,1c-0.6,0-1-0.4-1-1v-2H5c-1.1,0-2-0.9-2-2v-1.5H1c-0.6,0-1-0.4-1-1s0.4-1,1-1h2V13H1c-0.6,0-1-0.4-1-1
	c0-0.6,0.4-1,1-1h2V8.5H1c-0.6,0-1-0.4-1-1c0-0.6,0.4-1,1-1h2V5c0-1.1,0.9-2,2-2h1.5V1C6.5,0.4,6.9,0,7.5,0z M19,5H5v14h14V5z"
      />
    </svg>
  ),
  upload: (color) => (
    <svg
      version="1.1"
      id="Camada_1"
      x="0px"
      y="0px"
      viewBox="0 0 24 24"
      width="20"
      height="21"
      fill={color}
    >
      <path
        d="M12.1,3.8c-1.7,0-3.2,0.5-4.4,1.6c-1.2,1-2,2.3-2.4,3.9C4,9.6,2.8,10.2,2,11.2c-0.9,1-1.3,2.3-1.3,3.6
	c0,1.5,0.5,2.8,1.6,3.9c1.1,1.1,2.4,1.6,3.9,1.6H10v-2H6.2c-1,0-1.8-0.3-2.5-1c-0.7-0.7-1-1.5-1-2.5c0-1,0.3-1.8,1-2.5
	c0.7-0.7,1.5-1,2.4-1h0.7V11c0-1.4,0.5-2.6,1.5-3.7c1-1,2.2-1.5,3.6-1.5c1.4,0,2.6,0.5,3.6,1.5c1,1,1.4,2.2,1.4,3.7v2.4h1.8
	c0.7,0,1.3,0.2,1.7,0.7c0.5,0.5,0.7,1.1,0.7,1.7c0,0.7-0.2,1.3-0.7,1.7c-0.5,0.5-1.1,0.7-1.7,0.7H13v-5.6l1.8,1.8l1.4-1.4L12,8.8
	l-4.3,4.3l1.4,1.4l1.8-1.8v5.8c0,0.5,0.2,0.9,0.5,1.2c0.3,0.3,0.8,0.5,1.2,0.5h6c1.2,0,2.3-0.4,3.2-1.3c0.9-0.9,1.3-1.9,1.3-3.2
	c0-1.3-0.4-2.4-1.3-3.2c-0.8-0.8-1.8-1.2-3-1.2V11c0-2-0.7-3.7-2-5.1C15.6,4.5,14,3.8,12.1,3.8z"
      />
    </svg>
  ),
  send: (color) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill={color}
      viewBox="0 0 24 25"
    >
      <path
        fill-rule="evenodd"
        d="M22.207 1.836a1 1 0 0 1 .237 1.037l-6.65 19a1 1 0 0 1-1.858.076l-3.644-8.198-8.198-3.644a1 1 0 0 1 .076-1.858l19-6.65a1 1 0 0 1 1.037.237Zm-9.964 11.378 2.496 5.618 4.37-12.483-6.866 6.865Zm5.451-8.28L5.211 9.304l5.617 2.496 6.866-6.866Z"
        clip-rule="evenodd"
      />
    </svg>
  ),
  magicWand: (color) => (
    <svg
      version="1.1"
      id="Camada_1"
      x="0px"
      y="0px"
      viewBox="0 0 24 24"
      height={20}
      width={20}
      fill={color}
    >
      <path
        d="M16,2c0-0.6-0.4-1-1-1s-1,0.4-1,1v3c0,0.6,0.4,1,1,1s1-0.4,1-1C16,5,16,2,16,2z M10.8,3.4c-0.4-0.4-1-0.4-1.4,0
	s-0.4,1,0,1.4c0,0,0,0,0,0l2.1,2.1c0.4,0.4,1,0.4,1.4,0s0.4-1,0-1.4L10.8,3.4L10.8,3.4z M20.7,4.8c0.4-0.4,0.4-1,0-1.4
	c-0.4-0.4-1-0.4-1.4,0c0,0,0,0,0,0l-2.1,2.1c-0.4,0.4-0.4,1,0,1.4s1,0.4,1.4,0L20.7,4.8z M8,8C7.4,8,7,8.5,7,9s0.4,1,1,1h3
	c0.6,0,1-0.4,1-1s-0.4-1-1-1H8z M19,8c-0.6,0-1,0.4-1,1s0.4,1,1,1h3c0.6,0,1-0.4,1-1s-0.4-1-1-1H19z M10.8,14.7L3.5,22
	c-0.4,0.4-1,0.4-1.4,0s-0.4-1,0-1.4l12-12c0.4-0.4,1-0.4,1.4,0c0.4,0.4,0.4,1,0,1.4l-2.6,2.6l0,0L10.8,14.7
	C10.8,14.7,10.8,14.7,10.8,14.7L10.8,14.7z M18.5,11.2c-0.4-0.4-1-0.4-1.4,0c-0.4,0.4-0.4,1,0,1.4l0,0l2.1,2.1c0.4,0.4,1,0.4,1.4,0
	c0.4-0.4,0.4-1,0-1.4L18.5,11.2z M16,13c0-0.6-0.4-1-1-1s-1,0.4-1,1v3c0,0.6,0.4,1,1,1s1-0.4,1-1C16,16,16,13,16,13z"
      />
    </svg>
  ),
  time: (color) => (
    <svg
      version="1.1"
      id="Camada_1"
      x="0px"
      y="0px"
      viewBox="0 0 24 24"
      fill={color}
      height={20}
      width={20}
    >
      <path
        class="st0"
        d="M1,12C1,6,5.9,1,12,1S23,6,23,12s-4.9,11-11,11S1,18.1,1,12z M12,3c-5,0-9,4-9,9s4,9,9,9c0,0,0,0,0,0
	c5,0,9-4,9-9S17,3,12,3C12,3,12,3,12,3z M12,5c0.6,0,1,0.4,1,1v5.6l3.9,3.9c0.4,0.4,0.4,1,0,1.4c-0.4,0.4-1,0.4-1.4,0l-4.2-4.2
	C11.1,12.6,11,12.3,11,12V6C11,5.5,11.5,5,12,5z"
      />
    </svg>
  ),
  add: (color) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill={color}
      viewBox="0 0 24 24"
    >
      <path
        fill-rule="evenodd"
        d="M1 12C1 5.925 5.925 1 12 1s11 4.925 11 11-4.925 11-11 11S1 18.075 1 12Zm11-9a9 9 0 1 0 0 18 9 9 0 0 0 0-18Zm0 4a1 1 0 0 1 1 1v3h3a1 1 0 1 1 0 2h-3v3a1 1 0 1 1-2 0v-3H8a1 1 0 1 1 0-2h3V8a1 1 0 0 1 1-1Z"
        clip-rule="evenodd"
      />
    </svg>
  ),
  right: (color) => (
    <svg width="12" height="12" fill={color} viewBox="0 0 24 24">
      <path
        fill="#000"
        fill-rule="evenodd"
        d="M8.793 5.293a1 1 0 0 1 1.414 0l6 6a1 1 0 0 1 0 1.414l-6 6a1 1 0 0 1-1.414-1.414L14.086 12 8.793 6.707a1 1 0 0 1 0-1.414Z"
        clip-rule="evenodd"
      />
    </svg>
  ),
  left: (color) => (
    <svg width="12" height="12" fill={color} viewBox="0 0 24 24">
      <path
        fill="#000"
        fill-rule="evenodd"
        d="M15.207 5.293a1 1 0 0 1 0 1.414L9.914 12l5.293 5.293a1 1 0 0 1-1.414 1.414l-6-6a1 1 0 0 1 0-1.414l6-6a1 1 0 0 1 1.414 0Z"
        clip-rule="evenodd"
      />
    </svg>
  ),
  stop: (color) => (
    <svg
      version="1.1"
      id="Camada_1"
      x="0px"
      y="0px"
      viewBox="0 0 24 24"
      width="20"
      height="21"
      fill={color}
    >
      <path
        d="M12,24c-1.6,0-3.2-0.3-4.7-0.9c-1.5-0.6-2.7-1.5-3.8-2.6s-2-2.4-2.6-3.8C0.3,15.2,0,13.6,0,12c0-1.7,0.3-3.2,0.9-4.7
	s1.5-2.7,2.6-3.8s2.4-1.9,3.8-2.6S10.4,0,12,0c1.7,0,3.2,0.3,4.7,0.9c1.5,0.6,2.7,1.5,3.8,2.6s1.9,2.3,2.6,3.8
	c0.6,1.5,0.9,3,0.9,4.7c0,1.6-0.3,3.2-0.9,4.7c-0.6,1.5-1.5,2.7-2.6,3.8s-2.4,2-3.8,2.6C15.2,23.7,13.7,24,12,24z M12,22.2
	c2.8,0,5.3-1,7.2-3s3-4.4,3-7.2c0-2.8-1-5.2-3-7.2s-4.4-3-7.2-3c-2.8,0-5.2,1-7.2,3s-3,4.4-3,7.2c0,2.8,1,5.2,3,7.2
	S9.2,22.2,12,22.2z"
      />
      <path d="M8,8h8v8H8V8z" />
    </svg>
  ),
  microphone: (color) => (
    <svg
      version="1.1"
      id="Camada_1"
      x="0px"
      y="0px"
      viewBox="0 0 24 24"
      fill={color}
      width={16}
      height={16}
    >
      <path
        class="st0"
        d="M12,3c-1.7,0-3,1.3-3,3c0,0,0,0,0,0v6c0,1.7,1.3,3,3,3s3-1.3,3-3V6C15,4.4,13.7,3,12,3z M7,6c0-2.8,2.2-5,5-5
   s5,2.2,5,5v6c0,2.8-2.2,5-5,5s-5-2.2-5-5V6z"
      />
      <path
        class="st0"
        d="M5,9.5c0.6,0,1,0.4,1,1V12c0,3.3,2.7,6,6,6s6-2.7,6-6v-1.5c0-0.6,0.4-1,1-1s1,0.4,1,1V12c0,4.4-3.6,8-8,8
   s-8-3.6-8-8v-1.5C4,10,4.4,9.5,5,9.5z"
      />
      <path class="st0" d="M11,22v-3h2v3H11z" />
      <path
        class="st0"
        d="M7,22c0-0.6,0.4-1,1-1h8c0.6,0,1,0.4,1,1s-0.4,1-1,1H8C7.4,23,7,22.6,7,22z"
      />
    </svg>
  ),
};

const BotActionIcons = ({ icon, color }) => options[icon](color);
export default BotActionIcons;
