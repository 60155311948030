import { useContext } from "react";
import { GlobalSubheader } from "../../components/subHeader/GlobalSubheader";
import { DefaultPageContainer } from "../../components/layoutContainer/styles/LayoutContainer";
import BillToPayListView from "./BillToPayList";
import { BillToPayContext } from "./contexts/BillToPayContext";
import Loading from "../../components/basics/Loading";

export default function BillToPayView({ location }) {
  const { loadingPage } = useContext(BillToPayContext);

  return (
    <>
      <Loading open={loadingPage} />
      <GlobalSubheader
        title={"Gestão de Notas Fiscais"}
        titleLocation={"/dfe/management"}
      />

      <DefaultPageContainer
        style={{
          height: "calc(100% - 60px)",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <BillToPayListView />
      </DefaultPageContainer>
    </>
  );
}
