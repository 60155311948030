import styled, { css } from "styled-components";

import { Grid, Select, Tooltip } from "@material-ui/core";

const white = (props) => props.theme.palette.white;
const blue = (props) => props.theme.palette.blue;
const grayPrimary = (props) => props.theme.palette.grayPrimary;

const roboto = (props) => props.theme.fonts.roboto;

export const ContainerTabble = styled.div`
  .MuiTabs-root {
    background: ${blue};
  }

  .MuiTabs-indicator {
    background-color: ${white};
  }

  .MuiTab-wrapper {
    font-family: ${roboto}, sans-serif !important;
    color: ${white};
  }
`;

export const SelectSimple = styled(Select)`
  width: 100%;
  height: 48px;
`;

export const ItemDraggable = styled(Grid)`
  background-color: ${grayPrimary};
`;

export const PulseIcon = styled.div`
  ${(props) =>
    props.active &&
    css`
      border-radius: 50%;
      box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
      margin: 10px;

      transform: scale(1);
      background: ${blue};
      box-shadow: 0 0 0 0 rgba(52, 172, 224, 1);
      animation: pulse-blue 1.2s infinite;

      @keyframes pulse-blue {
        0% {
          transform: scale(0.95);
          box-shadow: 0 0 0 0 rgba(52, 172, 224, 0.7);
        }

        70% {
          transform: scale(1);
          box-shadow: 0 0 0 15px rgba(52, 172, 224, 0);
        }

        100% {
          transform: scale(0.95);
          box-shadow: 0 0 0 0 rgba(52, 172, 224, 0);
        }
      }
    `}
`;

export const DarkTooltip = styled(Tooltip).attrs((props) => ({ ...props }))`
  background-color: ${(props) => props.theme.palette.common.black};
  color: #fff;
  box-shadow: ${(props) => props.theme.shadows[2]};
  placement: right;
  font-size: 13px;
  margin-left: 10px;
`;
