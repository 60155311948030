import { Grid, Tooltip } from "@material-ui/core";
import { CheckCircle } from "@material-ui/icons";
import React from "react";
import WaitingIcon from "../../../components/icons/WaitingIcon";

export const SentToErpCell = ({ row, ...props }) => {
  return (
    <Tooltip
      title={row.sent_to_erp ? "Enviado" : "Não enviado"}
      arrow
      placement="top"
    >
      <Grid container justifyContent="center" alignItems="center">
        {row.sent_to_erp ? (
          <CheckCircle style={{ color: "green", fontSize: 25 }} />
        ) : (
          <Tooltip title="Não enviado">
            <WaitingIcon stroke="#fc9d03" />
          </Tooltip>
        )}
      </Grid>
    </Tooltip>
  );
};
