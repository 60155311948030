import React from "react";

import { StyledButton } from "./styles/Btn";

const Btn = ({
  label,
  textcolor = "white",
  hovercolor = "orange",
  startIcon,
  disabled = false,
  full,
  onClick,
  ...props
}) => {
  return (
    <StyledButton
      {...props}
      bg-color={props["bg-color"] || "blue"}
      variant="contained"
      startIcon={startIcon}
      textcolor={textcolor}
      hovercolor={hovercolor}
      full={full}
      disabled={disabled}
      onClick={onClick}
    >
      {label}
    </StyledButton>
  );
};

export default Btn;
