import { useContext, useMemo } from "react";
import { ManagementContext } from "../contexts/ManagementContext";
import { useManagementController } from "../controllers/ManagementController";
import { GlobalVariables } from "../../../GlobalVariables";

import { ToggleButtonGroup } from "@material-ui/lab";
import { Card, Typography, Checkbox } from "@material-ui/core";
import {
  CheckBoxOutlined,
  CheckBoxOutlineBlankOutlined,
} from "@material-ui/icons";

import {
  TabBody,
  ButtonGroupCheckAll,
  ButtonStyled,
  ToggleButtonStyled,
  CardHeaderStyled,
  PermissionIcon,
} from "../styles/management";
import { GridStyled, LightTooltip } from "../../../styles/GlobalStyle";
import { notify } from "../../../utils/notify";

export const TabMenus = ({ menus, mainTabIndex }) => {
  const { handleListItemClick } = useManagementController();
  const {
    groupCheckedPermissions,
    userCheckedPermissions,
    groupSelected,
    userSelected,
  } = useContext(ManagementContext);

  const allCheckedByGroup = useMemo(
    () =>
      groupSelected?.master ||
      groupCheckedPermissions.includes(menus?.id) ||
      groupCheckedPermissions.includes(GlobalVariables.PERMISSIONS.MASTER),
    [groupCheckedPermissions, groupSelected, menus]
  );

  const allCheckedByUser = useMemo(
    () =>
      userCheckedPermissions.includes(menus?.id) ||
      userCheckedPermissions.includes(GlobalVariables.PERMISSIONS.MASTER),
    [menus, userCheckedPermissions]
  );

  return (
    <TabBody>
      <GridStyled
        container
        direction="column"
        justifyContent="center"
        spacing={2}
      >
        <GridStyled item>
          <GridStyled
            container
            justifyContent="space-between"
            alignItems="center"
          >
            <GridStyled item>
              <Typography>
                {`Selecione os menus e funcionalidades que esse ${
                  mainTabIndex === 0 ? "grupo de usuários" : "usuário"
                }
                terá acesso`}
              </Typography>
            </GridStyled>
            <GridStyled item>
              <ButtonGroupCheckAll variant="contained">
                <LightTooltip title="Desmarcar Todos">
                  <ButtonStyled
                    onClick={() => {
                      if (!userSelected && groupSelected?.master)
                        return notify(
                          "Grupo de acesso master não pode ser editado"
                        );
                      handleListItemClick(false, menus);
                    }}
                  >
                    <CheckBoxOutlineBlankOutlined />
                  </ButtonStyled>
                </LightTooltip>
                <LightTooltip title="Marcar Todos">
                  <ButtonStyled
                    onClick={() => {
                      if (!userSelected && groupSelected?.master)
                        return notify(
                          "Grupo de acesso master não pode ser editado"
                        );

                      handleListItemClick(true, menus);
                    }}
                  >
                    <CheckBoxOutlined />
                  </ButtonStyled>
                </LightTooltip>
              </ButtonGroupCheckAll>
            </GridStyled>
          </GridStyled>
        </GridStyled>

        {(userSelected || groupSelected) &&
          (menus?.children_recursive || []).map((menu) => {
            if (!menu.id) return <></>;

            const menuChecked = {
              inGroup:
                allCheckedByGroup || groupCheckedPermissions.includes(menu.id),
              inUser:
                allCheckedByUser || userCheckedPermissions.includes(menu.id),
            };

            let someChildChecked = (menu?.children_recursive || []).some(
              (child) =>
                groupCheckedPermissions.includes(child.id) ||
                userCheckedPermissions.includes(child.id)
            );

            return (
              <GridStyled item>
                <Card>
                  <CardHeaderStyled
                    action={
                      <>
                        {menu.children_recursive && (
                          <ToggleButtonGroup aria-label="sub-permissions">
                            {menu.children_recursive.map((child) => {
                              const childChecked = {
                                inGroup: groupCheckedPermissions.includes(
                                  child.id
                                ),
                                inUser: userCheckedPermissions.includes(
                                  child.id
                                ),
                              };

                              const selectedChild =
                                menuChecked.inGroup ||
                                menuChecked.inUser ||
                                childChecked.inGroup ||
                                childChecked.inUser;

                              return (
                                <LightTooltip title={child.description}>
                                  <ToggleButtonStyled
                                    value={child.id}
                                    selected={selectedChild}
                                    $checkedByGroup={
                                      menuChecked.inGroup ||
                                      childChecked.inGroup
                                    }
                                    onChange={() => {
                                      if (
                                        !userSelected &&
                                        groupSelected?.master
                                      )
                                        return notify(
                                          "Grupo de acesso master não pode ser editado"
                                        );

                                      handleListItemClick(
                                        !selectedChild,
                                        child
                                      );
                                    }}
                                  >
                                    {child.icon_url ? (
                                      <PermissionIcon
                                        src={child.icon_url}
                                        alt={`Icone da permissão ${child.description}`}
                                      />
                                    ) : (
                                      <Typography noWrap>
                                        {child.description}
                                      </Typography>
                                    )}
                                  </ToggleButtonStyled>
                                </LightTooltip>
                              );
                            })}
                          </ToggleButtonGroup>
                        )}
                      </>
                    }
                    title={
                      <>
                        <LightTooltip title="Acesso no Menu">
                          <Checkbox
                            color="blue"
                            checked={
                              menuChecked.inGroup ||
                              menuChecked.inUser ||
                              someChildChecked
                            }
                            onChange={() => {
                              if (!userSelected && groupSelected?.master)
                                return notify(
                                  "Grupo de acesso master não pode ser editado"
                                );

                              handleListItemClick(
                                !(menuChecked.inGroup || menuChecked.inUser),
                                menu
                              );
                            }}
                            indeterminate={
                              !(menuChecked.inGroup || menuChecked.inUser) &&
                              someChildChecked
                            }
                          />
                        </LightTooltip>
                        {menu.description}
                      </>
                    }
                  />
                </Card>
              </GridStyled>
            );
          })}
      </GridStyled>
    </TabBody>
  );
};
