import { Button } from "@material-ui/core";
import styled, { css } from "styled-components";

export const StyledBotActionBar = styled.div`
  padding-top: 0px;
  max-width: 55px;
  border-left: 1px solid #dfe0e1;
  border-right: 1px solid #dfe0e1;
  display: flex;
  flex-direction: column;
`;

export const StyledBotActionButton = styled(Button)`
  min-width: 50px;
  min-height: 50px;
  max-width: 50px;
  margin: 0;
  padding: 0;
  padding-top: 20px;
  padding-bottom: 20px;
  ${(props) =>
    !props.hasAccess &&
    css`
      cursor: not-allowed;
      filter: grayscale(1);
    `}
`;
