import React, { useContext, useEffect, useState } from "react";

import { useHistory } from "react-router";

import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { GlobalVariables } from "../../GlobalVariables";
import Loading from "../../components/basics/Loading";
import TextFieldErp from "../../components/basics/TextFieldErp";
import { GridStyled, Margin } from "../../styles/GlobalStyle";
import { LoginContext } from "./contexts/LoginContext";
import useLoginController from "./controllers/LoginController";
import {
  BlueRectangle,
  DotRectangle,
  LoginIcons,
  PasswordIcon,
  QyonLogo,
} from "./LoginIcon";
import {
  BlueRectangleContainer,
  Copyright,
  EndDotRectangle,
  IconsRectangle,
  PrincipalContainer,
  QyonText,
  SideContainer,
  StartDotRectangle,
} from "./styles/Login";

import {
  RecoveryPasswordHeader,
  RecoveryPasswordHeaderSecondary,
  FormRecoveryPassword,
  RecoveryContainer,
  RecoveryLabel,
  HeaderTextField,
  RecoverySendButton,
  RecoveryCancelButton,
  LogoQyonContainer,
} from "./styles/RecoveryPassword";
import PasswordRequirements from "./PasswordRequirements";
import { IconButton, InputAdornment } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  form: {
    width: "100%",
  },
}));

export default function RecoverPassword({ location }) {
  const {
    handleSendCodeVerif,
    handleChangeEmail,
    handleChangePasswordRecover,
  } = useLoginController();
  const {
    email,
    setEmail,
    errors,
    sendCodeVerif,
    setSendCodeVerif,
    newPassword,
    setNewPassword,
    confirmNewPassword,
    setConfirmNewPassword,
    verificationCode,
    setVerificationCode,

    loading,
  } = useContext(LoginContext);
  const { push } = useHistory();
  const classes = useStyles();
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);

  useEffect(() => {
    setSendCodeVerif(false);
    setVerificationCode("");
    setNewPassword("");
    setConfirmNewPassword("");
    setVerificationCode("");
  }, []);

  useEffect(() => {
    if (verificationCode && !verificationCode.replace(/[^0-9]+/g, "")) {
      setVerificationCode("");
    }
  }, [verificationCode]);

  useEffect(() => {
    if (location.state && location.state.email) setEmail(location.state.email);
  }, [location, setEmail]);

  let iconSize = 0;
  if (window.innerHeight <= 800) {
    iconSize = 400;
  } else {
    iconSize = 500;
  }

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowPasswordConfirm = () =>
    setShowPasswordConfirm((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  return (
    <>
      <Loading open={loading} />
      <PrincipalContainer>
        <SideContainer>
          <StartDotRectangle>
            <DotRectangle />
          </StartDotRectangle>
          <IconsRectangle>
            <LoginIcons width={iconSize} height={iconSize} />
          </IconsRectangle>
          <QyonText>
            Potencializando a contabilidade do futuro com Inteligência
            Artificial
          </QyonText>

          <BlueRectangleContainer>
            <BlueRectangle />
          </BlueRectangleContainer>

          <EndDotRectangle>
            <DotRectangle />
          </EndDotRectangle>
        </SideContainer>

        <RecoveryContainer>
          <div>
            <FormRecoveryPassword>
              <GridStyled>
                <Margin top="72" />

                <LogoQyonContainer>
                  <QyonLogo />
                </LogoQyonContainer>

                <Margin top="40" />
                <div className={classes.paper}>
                  {sendCodeVerif ? (
                    <>
                      <RecoveryPasswordHeader>
                        Precisamos verificar sua identidade
                      </RecoveryPasswordHeader>
                      <Margin top="8" />
                      <RecoveryPasswordHeaderSecondary>
                        Sua senha deve conter no mínimo 8 caracteres
                      </RecoveryPasswordHeaderSecondary>
                    </>
                  ) : (
                    <>
                      <RecoveryPasswordHeader>
                        Cadastrar Nova Senha
                      </RecoveryPasswordHeader>
                      <Margin top="8" />
                      <RecoveryPasswordHeaderSecondary>
                        Digite seu e-mail para recuperar a senha
                      </RecoveryPasswordHeaderSecondary>
                    </>
                  )}

                  <Margin top="8" />

                  {sendCodeVerif ? (
                    <>
                      <GridStyled justifyContent="flex" container>
                        <RecoveryLabel>Código de Verificação</RecoveryLabel>
                        <Margin top="4" />
                        <TextFieldErp
                          autocapitalize="off"
                          autocomplete="off"
                          autocorrect="off"
                          error={errors.verificationCode ? true : false}
                          helperText={errors.verificationCode || false}
                          variant="outlined"
                          fullWidth
                          placeholder="Digite o código de Verificação"
                          value={verificationCode}
                          setValue={setVerificationCode}
                        />
                      </GridStyled>

                      <GridStyled justifyContent="flex" container>
                        <RecoveryLabel>Nova Senha</RecoveryLabel>
                        <Margin top="4" />
                        <TextFieldErp
                          error={errors.newPassword ? true : false}
                          helperText={errors.newPassword || ""}
                          variant="outlined"
                          fullWidth
                          placeholder="Digite a nova senha"
                          type={showPasswordConfirm ? "text" : "password"}
                          value={newPassword}
                          setValue={setNewPassword}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                onClick={handleClickShowPasswordConfirm}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                              >
                                <PasswordIcon />
                              </IconButton>
                            </InputAdornment>
                          }
                        />
                      </GridStyled>

                      <GridStyled justifyContent="flex" container>
                        <RecoveryLabel>Confirme a Senha</RecoveryLabel>
                        <Margin top="4" />
                        <TextFieldErp
                          error={errors.confirmNewPassword ? true : false}
                          helperText={errors.confirmNewPassword || ""}
                          variant="outlined"
                          fullWidth
                          placeholder="Repita a nova senha"
                          type={showPassword ? "text" : "password"}
                          value={confirmNewPassword}
                          setValue={setConfirmNewPassword}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                              >
                                <PasswordIcon />
                              </IconButton>
                            </InputAdornment>
                          }
                        />
                      </GridStyled>

                      <Margin top="20" />
                      <PasswordRequirements password={newPassword} />
                    </>
                  ) : (
                    <GridStyled justifyContent="space-between" container>
                      <RecoveryLabel>E-mail</RecoveryLabel>
                      <HeaderTextField
                        error={errors.email ? true : false}
                        helperText={errors.email || false}
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        placeholder="nomeusuario@exemplo.com"
                        autoComplete="email"
                        autoFocus
                        value={email}
                        onChange={handleChangeEmail}
                      />
                    </GridStyled>
                  )}

                  <Margin top="40" />
                  <Grid
                    container
                    spacing={3}
                    rowSpacing={10}
                    columnSpacing={{ xs: 1, sm: 3, md: 3 }}
                  >
                    <Grid item xs={6}>
                      <RecoveryCancelButton
                        type="submit"
                        full
                        variant="contained"
                        color="#364461"
                        label="Cancelar"
                        onClick={() => push("/login", { email, logout: true })}
                      >
                        Cancelar
                      </RecoveryCancelButton>
                    </Grid>
                    <Grid item xs={6}>
                      <RecoverySendButton
                        type="submit"
                        full
                        variant="contained"
                        color="primary"
                        label={sendCodeVerif ? "Salvar" : "Enviar"}
                        onClick={() => {
                          if (sendCodeVerif) {
                            handleChangePasswordRecover();
                          } else {
                            handleSendCodeVerif();
                          }
                        }}
                      >
                        {sendCodeVerif ? "Salvar" : "Enviar"}
                      </RecoverySendButton>
                    </Grid>
                  </Grid>
                </div>

                <Margin top="30" />
              </GridStyled>
            </FormRecoveryPassword>
            <Copyright>
              Todos os direitos reservados a QYON Sistemas Inteligentes © Versão{" "}
              {GlobalVariables.SISTEM_VERSION_DATE}
            </Copyright>
          </div>
        </RecoveryContainer>
      </PrincipalContainer>
    </>
  );
}
