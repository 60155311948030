export default function useTicketController() {
  const convertBlob = async (blobUrl) => {
    if (blobUrl && blobUrl.includes("blob:")) {
      let blobElement = await fetch(blobUrl).then((res) => res.blob());

      const result = await new Promise((resolve, _) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.readAsDataURL(blobElement);
      });

      return {
        file: {
          type: blobElement?.type,
          size: blobElement?.size,
        },
        fileBase64: result,
        uploaded: false,
        deleted: false,
      };
    }
  };

  const getBlobImage = async (elementId) => {
    var imageMessage = [
      ...document.getElementById(elementId).getElementsByTagName("img"),
    ];
    const imagesBlob = [];
    await Promise.all(
      imageMessage.map(async (value) => {
        const dataImage = await convertBlob(value.src);
        imagesBlob.push({ element: value.outerHTML, ...dataImage });
      })
    );
    return imagesBlob;
  };

  const getFileWithBase64 = async (uploadedFiles) => {
    if (!uploadedFiles) return [];
    const files = [];
    for await (let attachment of uploadedFiles) {
      if (!attachment?.uploaded) {
        files.push({
          fileName: attachment.file.name,
          ...(await convertBlob(attachment.preview)),
        });
      } else {
        files.push({
          id: attachment.id,
          fileName: attachment.name,
          size: attachment.readableSize,
          base64: attachment.preview,
          file: attachment.file,
          uploaded: attachment.uploaded,
          deleted: attachment.deleted,
        });
      }
    }
    return files;
  };

  const objMessages = async () => {
    let messages = [];
    let files = [];

    const editorContent = document
      .getElementById("publicInteraction")
      .firstChild.value.trim();

    let regex = /&(nbsp|amp|quot|lt|gt);/gim;
    let validation = editorContent.replaceAll(regex, "").replaceAll(" ", "");

    if (validation !== "<p></p>") {
      messages.push({
        interactionDescription: editorContent,
        privateFlag: 0,
        flagAcl: true,
      });

      files = await getBlobImage("publicInteraction_rte-edit-view");
    } else {
      return null;
    }

    return {
      messages,
      files,
    };
  };

  const formatErrorMessage = async (errors) => {
    if (errors?.length > 0) {
      return errors.join("<br/>");
    }

    return [];
  };

  return {
    getBlobImage,
    convertBlob,
    getFileWithBase64,
    formatErrorMessage,
    objMessages,
  };
}
