import styled, { css } from "styled-components";

import { Grid, Button } from "@material-ui/core";

export const StyledContainer = styled(Grid)`
  ${(props) =>
    props.height &&
    css`
      height: ${(props) => props.height}px;
    `};

  & > .MuiGrid-grid-xs-5 {
    height: 100%;

    & > .MuiGrid-item {
      width: 100%;
      padding: 4px 0;
    }

    & > .list-container {
      height: calc(100% - 45px);
    }
  }

  .MuiPaper-root {
    height: 100%;

    .MuiList-root {
      height: calc(100% - 75px);
    }
  }

  .MuiList-root {
    overflow: auto;
  }
`;

export const StyledButton = styled(Button)`
  width: 100%;
  height: 60px;
  background-color: ${(props) =>
    props.theme.palette[props["bg-color"]]} !important;
  color: ${(props) => props.theme.palette[props.textcolor]} !important;
  ${(props) =>
    props.disabled &&
    css`
      background-color: white !important;
      color: grey !important;
    `}
`;
