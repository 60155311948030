import fileSize from "filesize";

export const middlewareFiles = (data) => {
  if (Array.isArray(data)) {
    return data?.map((file) => {
      return {
        id: file?.id,
        preview: file?.pathS3,
        name: file?.fileNameS3,
        readableSize: fileSize(file?.fileSize),
        elementBase64: file?.dataUrl,
        file: {
          type: file?.type,
          extension: "." + file?.extension,
        },
        uploaded: true,
        deleted: file?.deleted,
        isInteractionAttendance: file?.isInteractionAttendance,
      };
    });
  } else {
    return null;
  }
};

export const formatInteraction = async ({ files, messages }) => {
  let messageDescription = messages[0];
  for await (let file of files) {
    if (file?.element) {
      await Promise.all(
        (messageDescription.interactionDescription =
          messages[0]?.interactionDescription?.replace(
            file.element,
            `<div class=divHistoricoEtapasAnexo>${
              file?.id
                ? `{{Arquivo: ${file.id}}}`
                : "Houve um problema no upload de seu arquivo!"
            }</div>`
          ))
      );
    }
  }
  return messageDescription;
};
