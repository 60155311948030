import { Grid, LinearProgress, withStyles } from "@material-ui/core";
import PasswordRequirementsField from "./PasswordRequirementsField";
import { useEffect, useState } from "react";
import { validPasswordCoginito } from "../../utils/validations";
import { LabelProgess } from "./styles/PasswordRequirementsField";

export default function PasswordRequirements({ password }) {
  const [passwordRequirements, setPasswordRequirements] = useState(
    validPasswordCoginito(password)
  );

  useEffect(() => {
    const passwordRequirementsResult = validPasswordCoginito(password);
    setPasswordRequirements(passwordRequirementsResult);
  }, [password]);

  function getRequirementsProgress() {
    const requirements = { ...passwordRequirements };

    delete requirements.error;

    const requirementsQuantity = Object.keys(requirements).length;

    const requirementsChecks = Object.values(requirements).filter(
      (value) => value === true
    );

    return (100 / requirementsQuantity) * requirementsChecks.length;
  }

  function getLabelProgress(progress) {
    if (progress === 100) {
      return "Forte";
    } else if (progress >= 50 && progress <= 75) {
      return "Média";
    } else {
      return "Fraca";
    }
  }

  function getProgressCollor(progress) {
    if (progress === 100) {
      return "#22B07D";
    } else if (progress >= 50 && progress <= 75) {
      return "orange";
    } else {
      return "#FA2D1E";
    }
  }

  const StyledLinearProgress = withStyles({
    barColorPrimary: {
      backgroundColor: getProgressCollor(getRequirementsProgress()),
    },
    colorPrimary: {
      backgroundColor: "#666",
    },
  })(LinearProgress);

  return (
    <>
      <Grid container spacing={2}>
        <Grid
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          item
          xs={11}
        >
          <StyledLinearProgress
            classes={{
              barColorPrimary: "palette.success",
            }}
            style={{ width: "100%", height: "2px" }}
            variant="determinate"
            value={getRequirementsProgress()}
          />
        </Grid>
        <Grid
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          item
          xs={1}
        >
          <LabelProgess
            style={{ color: getProgressCollor(getRequirementsProgress()) }}
          >
            {getLabelProgress(getRequirementsProgress())}
          </LabelProgess>
        </Grid>
      </Grid>

      <PasswordRequirementsField
        icon={passwordRequirements.minLength === false ? "error" : "check"}
        label={"Mínimo 8 Caracteres"}
      />
      <PasswordRequirementsField
        icon={passwordRequirements.uperCase === false ? "error" : "check"}
        label={"1 Letra Maiúscula"}
      />
      <PasswordRequirementsField
        icon={passwordRequirements.lowerCase === false ? "error" : "check"}
        label={"1 Letra Minúscula"}
      />
      <PasswordRequirementsField
        icon={passwordRequirements.number === false ? "error" : "check"}
        label={"1 Número"}
      />
    </>
  );
}
