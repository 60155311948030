import { callApi } from "./main";
const path =
  `${process.env.REACT_APP_CRM_API_URL}/v1/` || "http://localhost:4000/v1";

export async function getTicket({
  situationId,
  id,
  departmentId,
  subject,
  rowsPerPage,
  page,
  email,
  customerCPFCNPJ,
}) {
  let route = `${path}geiko-attendance?page=${page ? page : 1}&rowsPerPage=${
    rowsPerPage ? rowsPerPage : 10
  }`;
  if (situationId) route = `${route}&situationId=${situationId}`;
  if (id) route = `${route}&attendanceId=${id}`;
  if (departmentId) route = `${route}&departmentId=${departmentId}`;
  if (subject) route = `${route}&subject=${subject}`;
  if (email) route = `${route}&email=${email}`;
  if (customerCPFCNPJ) route = `${route}&customerCPFCNPJ=${customerCPFCNPJ}`;

  return callApi({
    path: route,
    method: "GET",
  });
}

export async function putTicket(body) {
  let route = `${path}geiko-attendance`;

  return callApi({
    path: route,
    method: "PUT",
    params: body,
  });
}

export async function getFilesS3({ id, attendanceId }) {
  let route = `${path}geiko-file${id ? `/${id}` : ""}`;

  if (attendanceId) route = `${route}?attendanceId=${attendanceId}`;

  return callApi({
    path: route,
    method: "GET",
  });
}

export async function postFilesS3(body) {
  return callApi({
    path: `${path}geiko-file`,
    method: "POST",
    params: body,
  });
}

export async function getMessages({ attendanceId }) {
  return callApi({
    path: `${path}geiko-message?attendanceId=${+attendanceId}`,
    method: "GET",
  });
}

export async function postMessages(body) {
  return callApi({
    path: `${path}geiko-message`,
    method: "POST",
    params: body,
  });
}

export async function getDepartmentGeiko() {
  return callApi({
    path: `${path}geiko-department/`,
    method: "GET",
  });
}

export async function getSituationGeiko() {
  return callApi({
    path: `${path}geiko-situation/`,
    method: "GET",
  });
}
