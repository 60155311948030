import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import { GridCloseIcon } from "@material-ui/data-grid";
import WarningIcon from "@material-ui/icons/Warning";

export const DialogRemoveTemplate = (props) => {
  return (
    <Dialog
      open={props.openDialog}
      onClose={() => {
        props.closeDialog();
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <GridCloseIcon
        style={{
          position: "absolute",
          right: 10,
          marginTop: "10px",
          cursor: "pointer",
        }}
        onClick={() => {
          props.closeDialog();
        }}
      />
      <DialogTitle id="alert-dialog-title">Remover template</DialogTitle>

      <DialogContent align="center">
        <WarningIcon fontSize="large" color="action" />
      </DialogContent>

      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Para remover o template selecionado, confirme com o botão abaixo.
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button autoFocus onClick={() => props.closeDialog()} color="error">
          Cancelar
        </Button>

        <Button
          autoFocus
          color="primary"
          onClick={() => props.deleteTemplate()}
        >
          Confirmar
        </Button>
      </DialogActions>
    </Dialog>
  );
};
