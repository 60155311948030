import { Grid, SvgIcon, Tooltip } from "@material-ui/core";
import React, { useContext, useState, useEffect } from "react";
import {
  CompanyLogo,
  CompanyLogoDefault,
  HeaderOption,
  IconButtonHeader,
  StyledAppBar,
  StyledBar,
  StyledHeader,
  TitleSystem,
} from "./styles/Header";
import LogoHeader from "../../assets/images/logo_header.png";
import IconsHeader from "./IconsHeader";
import { GlobalContext } from "../../contexts/GlobalContext";
import { userLogin } from "../../repositories/panel/v1/UserRepository";
import { notify } from "../../utils/notify";
import { DialogUsersLogin } from "../sidebar/children/DialogLoginUsers";
import {
  getEntities,
  getSystemsEntity,
  postGenerateAccessLog,
} from "../../repositories/panel/v1/EntityRepository";
import { getContractsByCNPJ } from "../../repositories/panel/v1/integrations/PlugRepository";
import useAuthenticationTranslate from "../../translations/authentications/Authentication";
import { usePermissions } from "../../utils/permissionsHook";
import {
  isAccountant,
  isAccountantClient,
  isCompany,
} from "../../utils/panelType";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { BotNotificationModal } from "../sidebar/children/BotNotificationModal";
import NotificationBadge, { Effect } from "react-notification-badge";
import Pusher from "pusher-js";

export const Header = ({
  type,
  customKey,
  setKey,
  setActionTotal,
  actionTotal,
  setActions,
  actions,

  refresh,
  ...props
}) => {
  const [openUser, setOpenUser] = React.useState(false);
  const [loading, setLoading] = useState(false);
  const [entities, setEntities] = useState([]);
  const [filteredRows, setFilteredRows] = useState(entities);
  const [companyChecked, setCompanyChecked] = useState("");
  const [openActionModal, setOpenActionModal] = useState(false);
  const [actionModalProduct, setActionModalProduct] = useState(null);
  const [eventGrid, setEventGrid] = useState(new Map());
  const isInClientView = sessionStorage.getItem("isInClientView");

  let clientViewed = null;
  if (isInClientView) {
    clientViewed = JSON.parse(sessionStorage.getItem("client"));
  }

  const { companyUser, userLogged, setCompanyUser, setParamsQyonBot } =
    useContext(GlobalContext);

  const [accountantAbbreviation, setAccountantAbbreviation] = useState("");

  useEffect(() => {
    var pusher = new Pusher("74f53ce76aadb042a826", {
      cluster: "mt1",
    });
    let ch = "qyon.dash." + localStorage.getItem("documentPanel");
    let ev = localStorage.getItem("documentPanel");

    var channel = pusher.subscribe(ch);
    channel.bind(ev, async (e) => {
      let json = e;

      if (typeof json === "string") {
        json = JSON.parse(e);
      }

      if (json.from === "QYON_DASH") {
        refresh();
      }

      if (json.from === "QYON_XML") {
        refresh();
      }

      if (json.event === "A1_UPLOAD") {
        setTimeout(() => {
          setParamsQyonBot((current) => ({ ...current, find: false }));
        }, [10000]);
      }

      let total = eventGrid.get(json.event);
      if (!total) {
        total = 1;
        eventGrid.set(json.event, 1);
      } else {
        eventGrid.set(json.event, ++total);
      }

      setEventGrid(eventGrid);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    refresh();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let abbreviation = companyUser.accountantName.split(" ");
    let output = "";
    if (abbreviation.length > 1) {
      output = abbreviation[0].charAt(0) + abbreviation[1].charAt(0);
    } else {
      output = companyUser.accountantName.charAt(0);
    }

    setAccountantAbbreviation(output);
  }, [companyUser.accountantName]);

  const translate = useAuthenticationTranslate();

  const exibitionTitle = {
    accountant: "Plataforma Contábil",
    standalone: "Plataforma Empresarial",
    client: "Plataforma do Cliente",
  };

  const {
    getPermissionTreeAndSaveOnLocalStorage,
    getUserPermissionsAndSaveOnLocalStorage,
  } = usePermissions();

  const { push } = useHistory();

  const OpenModalUsers = async () => {
    setOpenUser(true);
    setLoading(true);

    try {
      let response = await userLogin({
        id: localStorage.getItem("userIdPanel"),
      });
      const userEntities = response?.data?.entities
        ? [
            ...new Map(
              response.data.entities.map((entity) => [entity["id"], entity])
            ).values(),
          ]
        : [];
      setEntities(userEntities);
      setFilteredRows(userEntities);

      const companyIdPanel = localStorage.getItem("companyIdPanel");
      setCompanyChecked(companyIdPanel.toString());
    } catch (error) {
      notify("Algo deu errado", true, "warning");
    } finally {
      setLoading(false);
    }
  };

  const handleRadioChange = (event) => {
    setCompanyChecked(event.target.value);
  };

  const selectEntityLogin = async () => {
    const companyId = companyChecked;
    const userId = userLogged.userId;

    await handleSelectEntity(userLogged, companyId, userId);
  };

  const handleSelectEntity = async ({ user, companyIdParam, userIdParam }) => {
    try {
      setLoading(true);
      const companyId = companyChecked;
      const userId = userLogged.userId;

      if (!companyId) return notify(translate.notifySelectCompany);

      const companyUser = entities.find(
        (company) => company.id.toString() === companyId
      );

      localStorage.setItem("companyIdPanel", companyUser?.id);

      let getEntityCall = null;
      if (companyUser?.main_entity_id) {
        getEntityCall = getEntities({
          id: companyUser?.main_entity_id,
        });
      }

      if (companyUser?.accountant_id) {
        getEntityCall = getEntities({
          id: companyUser?.accountant_id,
        });
      }

      const [
        // eslint-disable-next-line no-unused-vars
        permissionTree,
        // eslint-disable-next-line no-unused-vars
        permissions,
        mainEntity,
        contracts,
        // eslint-disable-next-line no-unused-vars
        postLogging,
        systemEntityLogged,
      ] = await Promise.all([
        getPermissionTreeAndSaveOnLocalStorage(companyUser.document),
        getUserPermissionsAndSaveOnLocalStorage(companyUser.document, userId),
        getEntityCall,
        getContractsByCNPJ({
          document: companyUser?.document,
        }),
        postGenerateAccessLog({
          entityId: companyId,
          userId,
        }),
        getSystemsEntity({
          id: companyUser?.id,
        }),
      ]);

      const others = { systemsPermissions: [] };
      if (companyUser?.main_entity_id) {
        others.mainEntityCnpj = mainEntity?.data?.document || "";
      }

      if (companyUser?.accountant_id) {
        others.accountantCnpj = mainEntity?.data?.document || "";
        others.accountantName = mainEntity?.data?.trading_name || "";
        others.accountantLogoUrl = mainEntity?.data?.logo_url || "";
      } else {
        others.accountantCnpj = "";
        others.accountantName = "";
        others.accountantLogoUrl = "";
      }

      if (userId && companyUser?.id) {
        let systemsCompany = null;

        if (companyUser?.is_accountant) {
          systemsCompany = systemEntityLogged;
        } else {
          let systemsAccountant = null;

          if (companyUser?.accountant_id)
            systemsAccountant = await getSystemsEntity({
              id: companyUser?.accountant_id,
            });

          let systemsCompanyLogged = systemEntityLogged;

          let allowedSystems = systemsAccountant ?? systemsCompanyLogged;

          if (systemsAccountant?.data && systemsCompanyLogged?.data) {
            systemsAccountant.data.forEach((element) => {
              systemsCompanyLogged.data.forEach((element2) => {
                if (
                  element.id === element2.id &&
                  element.status_product !== "A"
                ) {
                  element.status_product = element2.status_product;
                  element.configured = element2.configured;
                }
              });
            });
          }

          systemsCompany = {
            success: true,
            data: (Array.isArray(allowedSystems?.data)
              ? allowedSystems?.data
              : []
            ).map((systAcc) => ({
              ...systAcc,
            })),
          };
        }

        if (systemsCompany?.success && Array.isArray(systemsCompany?.data)) {
          systemsCompany = systemsCompany?.data;
        } else systemsCompany = [];

        others.systemsPermissions = systemsCompany;
      }

      const compUser = {
        masterUserId: companyUser?.master_user_id,
        masterUserEmail: companyUser?.master?.email || "",
        companyId: companyUser?.id,
        document: companyUser?.document,
        logoB64: companyUser?.logo_url,
        companyName: companyUser?.company_name,
        tradingName: companyUser?.trading_name,
        taxRegimeId: companyUser?.tax_regime_id,
        mainEntityId: companyUser?.main_entity_id,
        accountantId: companyUser?.accountant_id,
        logoUrl: companyUser?.logo_url,
        entityType: companyUser?.entity_type,
        plug: companyUser?.plug,
        isAccountant: companyUser?.is_accountant,
        pivot: companyUser?.pivot,
        subdomain: companyUser?.subdomain ?? "",
        websiteExhibitionType: companyUser?.website_exhibition_type ?? "qyon",
        ownWebsiteUrl: companyUser?.own_website_url ?? "",
        contracts: contracts?.data ?? [],
        ...others,
      };
      setCompanyUser(compUser);

      localStorage.setItem("environment", process.env.REACT_APP_ENVIRONMENT);
      localStorage.setItem("subdomain", companyUser?.subdomain ?? "");

      if (localStorage.getItem("nextPageLoginPanel")) {
        const urlNextPage = localStorage.getItem("nextPageLoginPanel");
        localStorage.setItem("nextPageLoginPanel", "");
        return window.location.replace(urlNextPage);
      }

      const uri = sessionStorage.getItem("lastUriPanel") || "/dashboard/view";
      sessionStorage.setItem("lastUriPanel", "");

      window.location.href = `${
        process.env.REACT_APP_ENV_DEV ? "http" : "https"
      }://${window.location.host}${uri}`;
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const CloseModalLogin = () => {
    setOpenUser(false);
  };

  function handleFilterChange(event) {
    const value = event.target.value.toLowerCase();
    const filteredData = entities.filter(
      (row) =>
        row.trading_name.toLowerCase().includes(value) ||
        row.document.toLowerCase().includes(value)
    );
    setFilteredRows(filteredData);
  }

  const goToGenial4 = () => {
    let type = "";
    if (isAccountant()) {
      type = "ACCOUNTANT";
    }

    if (isAccountantClient()) {
      type = "ACCOUNTANT_CLIENT";
    }

    if (isCompany()) {
      type = "COMPANY";
    }

    let relationships = "";
    if (
      companyUser.systemsPermissions !== null &&
      companyUser.systemsPermissions !== undefined
    ) {
      if (companyUser.systemsPermissions.length > 0) {
        let systemsActive = companyUser.systemsPermissions.filter((system) => {
          return system.status_product === "A";
        });

        systemsActive.forEach((system, index) => {
          switch (system.id) {
            case 1:
              relationships += "erp";
              break;
            case 2:
              relationships += "folha";
              break;
            case 3:
              relationships += "xml";
              break;
            case 4:
              relationships += "crm";
              break;
            case 5:
              relationships += "contabil";
              break;
            case 6:
              relationships += "fiscal";
              break;
            default:
              break;
          }
          if (index !== systemsActive.length - 1) {
            relationships += ",";
          }
        });
      }
    }
    window.open(
      `https://qyon.genial4.com?token=${localStorage.getItem(
        "tokenCognitoPanel"
      )}&email=${localStorage.getItem("emailPanel")}&first_name=${
        userLogged.firstName
      }&last_name=${userLogged.lastName}&govid=${localStorage.getItem(
        "documentPanel"
      )}&type=${type}${
        relationships !== "" ? "&relationships_user=" + relationships : ""
      }`,
      "_blank"
    );
  };

  const onDecreaseBadge = async () => {
    await refresh();

    setKey(Math.random());
  };

  const onCloseActionModal = () => {
    setOpenActionModal(false);
  };

  useEffect(() => {
    refresh();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    // zIndex 1201 devido a classe MuiPaper de dentro do componente MuiDrawer
    <StyledAppBar position="fixed" elevation={0}>
      <StyledHeader container direction="row">
        <Grid
          item
          xs={12}
          sm={7}
          md={6}
          container
          alignItems="center"
          alignContent="center"
          direction="row"
        >
          {type === "client" && (
            <Grid
              item
              container
              direction="row"
              alignItems="center"
              spacing={3}
            >
              {(companyUser.accountantLogoUrl === undefined ||
                companyUser.accountantLogoUrl === "" ||
                companyUser.accountantLogoUrl === null) && (
                <CompanyLogoDefault
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    push("/dashboard/view");
                  }}
                >
                  {accountantAbbreviation}
                </CompanyLogoDefault>
              )}

              {companyUser.accountantLogoUrl !== undefined &&
                companyUser.accountantLogoUrl !== "" &&
                companyUser.accountantLogoUrl !== null && (
                  <CompanyLogo
                    onClick={() => {
                      push("/dashboard/view");
                    }}
                    style={{ cursor: "pointer" }}
                    src={companyUser.accountantLogoUrl}
                    alt="Logo Empresa"
                  />
                )}

              <Grid
                item
                xs={8}
                direction="column"
                onClick={() => {
                  push("/dashboard/view");
                }}
                style={{ cursor: "pointer" }}
              >
                <TitleSystem bold={true}>
                  {companyUser.accountantName}
                </TitleSystem>
                <TitleSystem>
                  {type === "client" && !isInClientView && (
                    <Grid
                      item
                      xs={12}
                      style={{
                        textTransform: "capitalize",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                      }}
                    >
                      {exibitionTitle[type] +
                        " | " +
                        localStorage.getItem("companyNamePanel").toLowerCase()}
                    </Grid>
                  )}
                  {type === "client" && isInClientView && (
                    <Grid
                      item
                      xs={12}
                      style={{
                        textTransform: "capitalize",
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                      }}
                    >
                      {exibitionTitle[type] + " | "}
                      {clientViewed.clientCompanyName.toLowerCase()}
                    </Grid>
                  )}
                </TitleSystem>
              </Grid>
            </Grid>
          )}
          {type !== "client" && (
            <>
              <StyledBar type={type} width={8} height={50} />
              <Grid
                item
                container
                xs={6}
                direction="column"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  push("/dashboard/view");
                }}
              >
                <img src={LogoHeader} alt="QYON" width={55} />
                <TitleSystem>
                  {type === "client" ? companyUser.accountantName + " | " : ""}
                  {exibitionTitle[type]}
                </TitleSystem>
              </Grid>
            </>
          )}
        </Grid>
        <Grid
          item
          xs={12}
          sm={5}
          md={6}
          container
          direction="row-reverse"
          alignItems="center"
          spacing={2}
        >
          {type === "client" && (
            <>
              <img src={LogoHeader} alt="QYON" width={55} />
              <StyledBar type={type} width={3} height={30} />
            </>
          )}

          <HeaderOption>
            <Grid item>
              <div>
                <NotificationBadge
                  count={actionTotal}
                  effect={Effect.SCALE}
                  key={customKey}
                />
                <Tooltip title="Central de mensagens">
                  <IconButtonHeader
                    onClick={() => {
                      setActionModalProduct("ALL");
                      setOpenActionModal(true);
                    }}
                  >
                    <IconsHeader icon="Notification" color="#FFF" />
                  </IconButtonHeader>
                </Tooltip>
              </div>
            </Grid>
            <Grid item>
              <Tooltip title="Configurações">
                <IconButtonHeader
                  onClick={() => {
                    push("/setting");
                  }}
                >
                  <IconsHeader icon="Config" color="#FFF" />
                </IconButtonHeader>
              </Tooltip>
            </Grid>

            <Grid item>
              <Tooltip title="Plataforma EAD (Genial4)">
                <IconButtonHeader onClick={goToGenial4}>
                  <IconsHeader icon="Genial" color="#FFF" />
                </IconButtonHeader>
              </Tooltip>
            </Grid>
            {!isInClientView && (
              <Grid item>
                <Tooltip title="Trocar de empresa">
                  <IconButtonHeader onClick={OpenModalUsers}>
                    <IconsHeader icon="SwitchUser" color="#FFF" />
                  </IconButtonHeader>
                </Tooltip>
              </Grid>
            )}
          </HeaderOption>
        </Grid>
      </StyledHeader>
      {openUser === true && (
        <DialogUsersLogin
          openModal={openUser}
          closeModal={CloseModalLogin}
          title={translate.selectCompany}
          searchItens={handleFilterChange}
          loading={loading}
          valueChecked={companyChecked}
          handleRadioChange={handleRadioChange}
          filteredRows={filteredRows}
          buttonConfirm={selectEntityLogin}
        />
      )}

      {openActionModal && (
        <BotNotificationModal
          open={openActionModal}
          product={actionModalProduct}
          onDecreaseBadge={onDecreaseBadge}
          onCloseActionModal={onCloseActionModal}
        />
      )}
    </StyledAppBar>
  );
};
