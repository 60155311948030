import styled from "styled-components";

export const GridDragDropStyledComponent = styled.div`
  box-shadow: rgba(0, 0, 0, 0.1) -4px 9px 18px -6px;
  border-radius: 10px;
  background-color: white;
  overflow: hidden;
  height: 100%;
  width: 100%;
  border: 1px solid #c2ccd2;
  padding: 0px 5px 5px 5px;
`;
