const options = {
  full: (color) => (
    <svg
      width="49"
      height="23"
      viewBox="0 0 49 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_3744_36162)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M6.48804 3C5.11393 3 4 4.11929 4 5.5V15.5C4 16.8807 5.11393 18 6.48804 18H40.0766C41.4507 18 42.5646 16.8807 42.5646 15.5V13H43.8086C44.4957 13 45.0526 12.4404 45.0526 11.75V9.25C45.0526 8.55963 44.4957 8 43.8086 8H42.5646V5.5C42.5646 4.11929 41.4507 3 40.0766 3H6.48804ZM5.69856 4.71053H40.866V16.2895H5.69856V4.71053Z"
          fill="#64DD17"
        />
      </g>
      <g filter="url(#filter1_d_3744_36162)">
        <rect
          x="7.75"
          y="6.55261"
          width="9.86842"
          height="7.89474"
          rx="0.789474"
          fill="#64DD17"
        />
      </g>
      <g filter="url(#filter2_d_3744_36162)">
        <rect
          x="18.408"
          y="6.55249"
          width="9.86842"
          height="7.89474"
          rx="0.789474"
          fill="#64DD17"
        />
      </g>
      <g filter="url(#filter3_d_3744_36162)">
        <rect
          x="29.0659"
          y="6.55249"
          width="9.86842"
          height="7.89474"
          rx="0.789474"
          fill="#64DD17"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_3744_36162"
          x="0.172249"
          y="0.129186"
          width="48.7082"
          height="22.6555"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="0.956938" />
          <feGaussianBlur stdDeviation="1.91388" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.109687 0 0 0 0 0.975 0 0 0 0 0.1443 0 0 0 0.24 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_3744_36162"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_3744_36162"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_d_3744_36162"
          x="3.92225"
          y="3.6818"
          width="17.5239"
          height="15.5503"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="0.956938" />
          <feGaussianBlur stdDeviation="1.91388" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.109687 0 0 0 0 0.975 0 0 0 0 0.1443 0 0 0 0.24 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_3744_36162"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_3744_36162"
            result="shape"
          />
        </filter>
        <filter
          id="filter2_d_3744_36162"
          x="14.5802"
          y="3.68168"
          width="17.5239"
          height="15.5503"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="0.956938" />
          <feGaussianBlur stdDeviation="1.91388" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.109687 0 0 0 0 0.975 0 0 0 0 0.1443 0 0 0 0.24 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_3744_36162"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_3744_36162"
            result="shape"
          />
        </filter>
        <filter
          id="filter3_d_3744_36162"
          x="25.2382"
          y="3.68168"
          width="17.5239"
          height="15.5503"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="0.956938" />
          <feGaussianBlur stdDeviation="1.91388" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.109687 0 0 0 0 0.975 0 0 0 0 0.1443 0 0 0 0.24 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_3744_36162"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_3744_36162"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  ),
  alert: (color) => (
    <svg
      width="50"
      height="23"
      viewBox="0 0 50 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_3744_36163)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M6.48804 3C5.11393 3 4 4.11929 4 5.5V15.5C4 16.8807 5.11393 18 6.48804 18H40.0766C41.4507 18 42.5646 16.8807 42.5646 15.5V13H43.8086C44.4957 13 45.0526 12.4404 45.0526 11.75V9.25C45.0526 8.55963 44.4957 8 43.8086 8H42.5646V5.5C42.5646 4.11929 41.4507 3 40.0766 3H6.48804ZM5.69856 4.71053H40.866V16.2895H5.69856V4.71053Z"
          fill="#FFB300"
        />
      </g>
      <g filter="url(#filter1_d_3744_36163)">
        <g filter="url(#filter2_d_3744_36163)">
          <rect
            x="7.75"
            y="6.55261"
            width="9.86842"
            height="7.89474"
            rx="0.8"
            fill="#FFB300"
          />
        </g>
        <g filter="url(#filter3_d_3744_36163)">
          <rect
            x="18.408"
            y="6.55261"
            width="9.86842"
            height="7.89474"
            rx="0.8"
            fill="#FFB300"
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_d_3744_36163"
          x="0"
          y="0"
          width="49.0527"
          height="23"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="2" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.975 0 0 0 0 0.576956 0 0 0 0 0.109687 0 0 0 0.24 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_3744_36163"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_3744_36163"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_d_3744_36163"
          x="3.75"
          y="3.55261"
          width="39.1843"
          height="15.8948"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="2" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.975 0 0 0 0 0.576956 0 0 0 0 0.109687 0 0 0 0.24 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_3744_36163"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_3744_36163"
            result="shape"
          />
        </filter>
        <filter
          id="filter2_d_3744_36163"
          x="3.75"
          y="3.55261"
          width="17.8684"
          height="15.8948"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="2" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.975 0 0 0 0 0.576956 0 0 0 0 0.109687 0 0 0 0.24 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_3744_36163"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_3744_36163"
            result="shape"
          />
        </filter>
        <filter
          id="filter3_d_3744_36163"
          x="14.408"
          y="3.55261"
          width="17.8684"
          height="15.8948"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="2" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.975 0 0 0 0 0.576956 0 0 0 0 0.109687 0 0 0 0.24 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_3744_36163"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_3744_36163"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  ),
  danger: (color) => (
    <svg
      width="50"
      height="23"
      viewBox="0 0 50 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_3744_36164)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M6.48804 3C5.11393 3 4 4.11929 4 5.5V15.5C4 16.8807 5.11393 18 6.48804 18H40.0766C41.4507 18 42.5646 16.8807 42.5646 15.5V13H43.8086C44.4957 13 45.0526 12.4404 45.0526 11.75V9.25C45.0526 8.55963 44.4957 8 43.8086 8H42.5646V5.5C42.5646 4.11929 41.4507 3 40.0766 3H6.48804ZM5.69856 4.71053H40.866V16.2895H5.69856V4.71053Z"
          fill="#FF5252"
        />
      </g>
      <g filter="url(#filter1_d_3744_36164)">
        <g filter="url(#filter2_d_3744_36164)">
          <rect
            x="7.75"
            y="6.55261"
            width="9.86842"
            height="7.89474"
            rx="0.8"
            fill="#FF5252"
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_d_3744_36164"
          x="0"
          y="0"
          width="49.0527"
          height="23"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="2" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.975 0 0 0 0 0.109687 0 0 0 0 0.109687 0 0 0 0.24 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_3744_36164"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_3744_36164"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_d_3744_36164"
          x="3.75"
          y="3.55261"
          width="39.1843"
          height="15.8948"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="2" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.975 0 0 0 0 0.576956 0 0 0 0 0.109687 0 0 0 0.24 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_3744_36164"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_3744_36164"
            result="shape"
          />
        </filter>
        <filter
          id="filter2_d_3744_36164"
          x="3.75"
          y="3.55261"
          width="17.8684"
          height="15.8948"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="2" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.975 0 0 0 0 0.109687 0 0 0 0 0.109687 0 0 0 0.24 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_3744_36164"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_3744_36164"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  ),
  interrupt: (color) => (
    <svg
      width="50"
      height="23"
      viewBox="0 0 50 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_4412_16291)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M6.48804 3C5.11393 3 4 4.11929 4 5.5V15.5C4 16.8807 5.11393 18 6.48804 18H40.0766C41.4507 18 42.5646 16.8807 42.5646 15.5V13H43.8086C44.4957 13 45.0526 12.4404 45.0526 11.75V9.25C45.0526 8.55963 44.4957 8 43.8086 8H42.5646V5.5C42.5646 4.11929 41.4507 3 40.0766 3H6.48804ZM5.69856 4.71053H40.866V16.2895H5.69856V4.71053Z"
          fill="#FF5252"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_4412_16291"
          x="0"
          y="0"
          width="49.0527"
          height="23"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="2" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.975 0 0 0 0 0.109687 0 0 0 0 0.109687 0 0 0 0.24 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_4412_16291"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_4412_16291"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  ),
};

const BatteryImages = ({ icon, color }) => options[icon](color);
export default BatteryImages;
