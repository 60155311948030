import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  makeStyles,
} from "@material-ui/core";
import Btn from "../components/buttons/Btn";
import AttendanceController from "../pages/attendance/controllers/AttendanceController";
import { Title } from "../styles/GlobalStyle";

const useStyles = makeStyles({
  dialog: {
    width: "60%",
  },
});

const ModalAttachment = ({ setOpen, urlFile, ...props }) => {
  const { handleShowFile } = AttendanceController();
  const classes = useStyles();

  const cancelButton = () => {
    setOpen(false);
  };

  return (
    <Dialog
      open={props?.open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="sm"
      classes={{
        paper: classes.dialog,
      }}
    >
      <DialogTitle id="alert-dialog-title">
        <Title subtitle style={{ wordBreak: "break-word" }}>
          <strong>
            {urlFile?.name?.length > 25
              ? urlFile?.name
                  .replace(urlFile?.name.substring(25))
                  .replace("undefined", "") +
                "[...]" +
                urlFile?.name.replace(
                  urlFile?.name.substring(0, urlFile?.name.lastIndexOf(".")),
                  ""
                )
              : urlFile?.name}
          </strong>
          {`${urlFile?.readableSize ? ` - ${urlFile?.readableSize}` : ""}`}
        </Title>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Grid
          container
          spacing={1}
          style={{
            textAlign: "center",
            maxHeight: "800px",
            maxWidth: "1200px",
            backgroundColor: "#8995a622",
            borderRadius: "10px",
          }}
        >
          <Grid
            item
            xs={12}
            style={{
              border: "1px solid #80808088",
              borderRadius: "10px",
            }}
          >
            {
              urlFile?.file?.type?.includes("image") ? (
                <img
                  alt={`${urlFile?.preview} - ${urlFile?.readableSize}`}
                  src={`${
                    urlFile?.elementBase64
                      ? handleShowFile(
                          urlFile?.elementBase64,
                          urlFile?.file?.type
                        )
                      : urlFile?.preview
                  }`}
                  style={{
                    width: "auto",
                    height: "auto",
                    maxHeight: "500px",
                    maxWidth: "500px",
                    borderRadius: "10px",
                  }}
                />
              ) : (
                <iframe
                  src={`${
                    urlFile?.elementBase64
                      ? handleShowFile(
                          urlFile?.elementBase64,
                          urlFile?.file?.type
                        )
                      : urlFile?.preview
                  }`}
                  title="PortletIFrameWidget"
                  style={{
                    width: "100%",
                    height: "600px",
                  }}
                />
              )
              // 'Aruivo sem vizualização'
            }
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Btn label="Fechar" onClick={cancelButton} />
      </DialogActions>
    </Dialog>
  );
};

export default ModalAttachment;
