import React, { createContext, useCallback, useEffect, useState } from "react";
import { getAccountBank } from "../repositories/integrations/bank/AccountsRepository";
import { getCustomerPlug } from "../repositories/panel/v1/integrations/PlugRepository";

const initLanguage =
  localStorage.languagePanel ||
  (navigator.browserLanguage !== undefined
    ? navigator.browserLanguage
    : navigator.language);

const GlobalContext = createContext();

const optionsFalse = ["false", false, "null", null];

const GlobalContextProvider = ({ children }) => {
  const [userLogged, setUserLogged] = useState({
    token: localStorage.getItem("tokenPanel") || "",
    email: localStorage.getItem("emailPanel") || "",
    showVideoIntro: localStorage.getItem("showVideoIntroPanel") || "",
    cognito: JSON.parse(localStorage.getItem("cognitoPanel") || "{}"),
    userId: localStorage.getItem("userIdPanel") || "",
    firstName: localStorage.getItem("firstNamePanel") || "",
    lastName: localStorage.getItem("lastNamePanel") || "",
    lastLogin: localStorage.getItem("lastLoginPanel") || "",
    subCognito: localStorage.getItem("subCognitoPanel") || "",
    tokenCognito: localStorage.getItem("tokenCognitoPanel") || "",
  });
  const [companyUser, setCompanyUser] = useState({
    masterUserId: localStorage.getItem("masterUserIdPanel") || "",
    masterUserEmail: localStorage.getItem("masterUserEmailPanel") || "",
    companyId: localStorage.getItem("companyIdPanel") || "",
    document: localStorage.getItem("documentPanel") || "",
    logoB64: localStorage.getItem("logoB64Panel") || "",
    companyName: localStorage.getItem("companyNamePanel") || "",
    tradingName: localStorage.getItem("tradingNamePanel") || "",
    taxRegimeId: localStorage.getItem("taxRegimeIdPanel") || "",
    mainEntityId: localStorage.getItem("mainEntityIdPanel") || "",
    mainEntityCnpj: localStorage.getItem("mainEntityCnpjPanel") || "",
    accountantId: localStorage.getItem("accountantIdPanel") || "",
    logoUrl: localStorage.getItem("logoUrlPanel") || "",
    entityType: localStorage.getItem("entityTypePanel") || "",
    plug: localStorage.getItem("plugPanel") || "",
    isAccountant: localStorage.getItem("isAccountantPanel") || "",
    accountantCnpj: localStorage.getItem("accountantCnpjPanel") || "",
    accountantName: localStorage.getItem("accountantNamePanel") || "",
    accountantLogoUrl: localStorage.getItem("accountantLogoUrlPanel") || "",
    pivot: JSON.parse(localStorage.getItem("pivotPanel") || "{}"),
    systemsPermissions: JSON.parse(
      localStorage.getItem("systemsPermissionsPanel") || "[]"
    ),
    contracts: JSON.parse(localStorage.getItem("contractsPanel") || "[]"),
  });
  const [notifications, setNotifications] = useState([]);
  const [reloadWidgets, setReloadWidgets] = useState(null);
  const [sidebar, setSidebar] = useState({ open: true, menuLanguage: null });
  const [sidebaraction, setSidebarAction] = useState({
    open: true,
    menuLanguage: null,
  });
  const [language, setLanguage] = useState(initLanguage);
  const [paramsQyonBot, setParamsQyonBot] = useState({
    find: false,
    haveBankAccount: false,
    haveCertificate: false,
  });

  const [animation, setAnimation] = useState(0);
  const [textAnimation, setTextAnimation] = useState("");
  const [overlap, setOverlap] = useState(false);

  const [websiteContent, setWebsiteContent] = useState({});
  const [botNotifications, setBotNotifications] = useState(null);

  const [breadcrumbs, setBreadcrumbs] = useState([]);

  useEffect(() => {
    const routeCurrent = window.location.href.split("/");

    if (
      ["notfound", "login", "firstlogin", "recoverpassword", ""].includes(
        routeCurrent[routeCurrent.length - 1]
      ) ||
      paramsQyonBot.find ||
      !companyUser?.document
    )
      return;

    (async () => {
      try {
        const respBank = await getAccountBank({
          document: companyUser?.document,
        });

        const respPlug = await getCustomerPlug({
          document: companyUser?.document,
        });

        setParamsQyonBot((current) => ({
          ...current,
          haveBankAccount: respBank?.bank,
          haveCertificate: respPlug?.data?.CaminhoCertificado ? true : false,
          find: true,
        }));
      } catch (error) {
        console.error(error);
      } finally {
      }
    })();
  }, [companyUser?.document, paramsQyonBot]);

  const handleChangeLanguage = (languageParam) => {
    setLanguage(languageParam);
    localStorage.setItem("languagePanel", languageParam);
  };

  const handleChangeUserLogged = (values) => {
    setUserLogged((current) => ({ ...current, ...values }));

    Object.keys(values).forEach((key) => {
      let value = values[key];
      if (typeof value === "object" && Boolean(value))
        value = JSON.stringify(values[key]);

      localStorage.setItem(
        key + "Panel",
        optionsFalse.includes(value) ? "" : value
      );
    });
  };

  const handleChangeCompanyUser = useCallback(
    (values) => {
      setCompanyUser((current) => ({ ...current, ...values }));

      Object.keys(values).forEach((key) => {
        let value = values[key];
        if (typeof value === "object" && Boolean(value))
          value = JSON.stringify(values[key]);

        localStorage.setItem(
          key + "Panel",
          optionsFalse.includes(value) ? "" : value
        );
      });
    },
    [setCompanyUser]
  );

  return (
    <GlobalContext.Provider
      value={{
        userLogged,
        setUserLogged: handleChangeUserLogged,
        clearUserLogged: useCallback(() => {
          setUserLogged({});
        }, []),
        clearCompanyUser: useCallback(() => setCompanyUser({}), []),
        companyUser,
        setCompanyUser: handleChangeCompanyUser,
        sidebar,
        setSidebar,
        sidebaraction,
        setSidebarAction,
        language,
        setLanguage: handleChangeLanguage,
        notifications,
        setNotifications,
        reloadWidgets,
        setReloadWidgets,
        paramsQyonBot,
        setParamsQyonBot,
        animation,
        setAnimation,
        textAnimation,
        setTextAnimation,
        overlap,
        setOverlap,
        websiteContent,
        setWebsiteContent,
        botNotifications,
        setBotNotifications,
        breadcrumbs,
        setBreadcrumbs,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

export { GlobalContextProvider, GlobalContext };
