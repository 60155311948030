/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useCallback, useContext, useState } from "react";
import { GlobalContext } from "../../../contexts/GlobalContext";

const ChatContext = createContext();
const initialValue = {
  company: {
    id: 1,
    alias: null,
    cnpj: null,
    sgbd: null,
  },
  user: {
    id: "",
    type: "",
    teamId: "",
    contactId: "",
    name: "",
    company: null,
  },
  chat: {
    open: false,
    currentScreen: "",
    currentConversation: "",
    currentTicketId: "",
    roomId: 1,
    distributionProduct: 0,
    conversations: [],
    queue: [],
    typeAttendace: [],
    classificationAttendance: [],
    isLoading: true,
    loadingMessage: "Conectando...",
    roomName: "",
    roomDescription: "",
    initialMessage: "",
    initialMessageIntegration: "",
    roomOffMessage: "",
    messageRoomInactivity: "",
    messagemRoomDowntime: "",
    productParam: "",
    alertTimeInactivity: "",
    downtime: "",
    alertNewCustomer: "",
    tokenWhats: "",
    integrationWppNumberId: "",
    whatsappAccountId: "",
    whatsappFlagIntegration: "",
    roomAttendanceAmount: "0",
  },
  messages: [],
  settings: {
    roomState: "0",
    myState: "0",
    reason: [],
    notificationState: "0",
    notificationIntensity: "1",
    attendanceAmount: "1",
    departments: [],
    accordion: {
      status: false,
      attendance: false,
      notification: false,
    },
  },
  socket: {
    clientId: null,
    connected: false,
    startedTopics: [],
  },
  rateQuestions: [],
  customers: [],
  callSelectedCustomer: {},
  loading: false,
  chatMonitor: {
    openFilters: false,
    nameFilter: "",
    statusFilter: null,
    departmentFilter: null,
    currentScreen: "attendants",
    accordionsState: [],
  },
};

const SCREEN_ENUM = {
  conversation: "conversation",
  settings: "settings",
  contactList: "contact-list",
  customerLobby: "customer-lobby",
  customerLobbyWaiting: "customer-lobby-waiting",
  closingConversation: "closing-conversation",
  avaliation: "avaliation",
  loading: "loading",
  callListContacts: "call-list-contacts",
  chatMonitor: "chat-monitor",
};

const useChatContext = () => {
  const [
    open,
    setOpenState,
    currentScreen,
    setCurrentScreenState,
    currentConversation,
    setCurrentConversationState,
    roomId,
    setRoomIdState,
    currentTicketId,
    setCurrentTicketId,
    distributionProduct,
    setDistributionProductState,
    conversations,
    setConversationsState,
    queue,
    setQueueState,
    messages,
    setMessagesState,
    isLoading,
    setIsLoadingState,
    loadingMessage,
    setLoadingMessageState,
    company,
    setCompanyState,
    user,
    setUserState,
    clientId,
    setClientId,
    connected,
    setConnected,
    startedTopics,
    setStartedTopics,
    settings,
    setSettingsState,
    typeAttendace,
    setTypeAttendaceState,
    classificationAttendance,
    setClassificationAttendanceState,
    rateQuestions,
    setRateQuestionsState,
    accordion,
    setAccordionState,
    roomName,
    setRoomNameState,
    roomDescription,
    setRoomDescriptionState,
    initialMessage,
    setInitialMessageState,
    initialMessageIntegration,
    setInitialMessageIntegrationState,
    roomOffMessage,
    setRoomOffMessageState,
    messageRoomInactivity,
    setmessageRoomInactivityState,
    messagemRoomDowntime,
    setMessagemRoomDowntimeState,
    productParam,
    setProductParamState,
    alertTimeInactivity,
    setAlertTimeInactivityState,
    downtime,
    setDowntimeState,
    alertNewCustomer,
    setAlertNewCustomerState,
    tokenWhats,
    setTokenWhatsState,
    integrationWppNumberId,
    setIntegrationWppNumberIdState,
    whatsappAccountId,
    setWhatsappAccountIdState,
    whatsappFlagIntegration,
    setWhatsappFlagIntegration,
    roomAttendanceAmount,
    setRoomAttendanceAmountState,
    customers,
    setCustomersState,
    callSelectedCustomer,
    setcallSelectedCustomerState,
    chatMonitorState,
    setChatMonitorState,
    loading,
    setLoadingState,
  ] = useContext(ChatContext);
  const { companyUser } = useContext(GlobalContext);

  const setChat = useCallback(
    /**
     * @param {{
     *   open: Boolean,
     *   currentScreen: string,
     *   currentConversation: string,
     *   roomId: number,
     *   distributionProduct: Boolean,
     *   conversations: Array<any>,
     *   queue: Array<any>,
     *   typeAttendace: Array<any>,
     *   classificationAttendance: Array<any>,
     *   isLoading: boolean,
     *   loadingMessage: string,
     *   currentTicketId: number
     *   roomName: string
     *   roomDescription: string
     *   initialMessage: string
     *   initialMessageIntegration: string
     *   roomOffMessage: string
     *   messageRoomInactivity: string
     *   messagemRoomDowntime: string
     *   productParam: boolean
     *   alertTimeInactivity: string
     *   downtime: string
     *   alertNewCustomer: string
     *   tokenWhats: string
     *   roomAttendanceAmount: string
     *   integrationWppNumberId: string
     *   whatsappAccountId: string
     *   whatsappFlagIntegration: string
     * }} props
     */
    (props = {}) => {
      if (props.open !== undefined) setOpenState(props.open);
      if (props.currentScreen !== undefined)
        setCurrentScreenState(props.currentScreen);
      if (props.currentConversation !== undefined)
        setCurrentConversationState(props.currentConversation);
      if (props.roomId !== undefined) setRoomIdState(props.roomId);
      if (props.distributionProduct !== undefined)
        setDistributionProductState(props.distributionProduct);
      if (props.conversations !== undefined) {
        if (props.conversations.length === 0) setConversationsState([]);
        else setConversationsState(props.conversations);
      }
      if (props.queue !== undefined) setQueueState(props.queue);
      if (props.typeAttendace !== undefined)
        setTypeAttendaceState(props.typeAttendace);
      if (props.classificationAttendance !== undefined)
        setClassificationAttendanceState(props.classificationAttendance);
      if (props.isLoading !== undefined) setIsLoadingState(props.isLoading);
      if (props.loadingMessage !== undefined)
        setLoadingMessageState(props.loadingMessage);
      if (props.currentTicketId !== undefined)
        setCurrentTicketId(props.currentTicketId);
      if (props.roomName !== undefined) setRoomNameState(props.roomName);
      if (props.roomDescription !== undefined)
        setRoomDescriptionState(props.roomDescription);
      if (props.initialMessage !== undefined)
        setInitialMessageState(props.initialMessage);
      if (props.initialMessageIntegration !== undefined)
        setInitialMessageIntegrationState(props.initialMessageIntegration);
      if (props.roomOffMessage !== undefined)
        setRoomOffMessageState(props.roomOffMessage);
      if (props.messageRoomInactivity !== undefined)
        setmessageRoomInactivityState(props.messageRoomInactivity);
      if (props.messagemRoomDowntime !== undefined)
        setMessagemRoomDowntimeState(props.messagemRoomDowntime);
      if (props.productParam !== undefined)
        setProductParamState(props.productParam);
      if (props.alertTimeInactivity !== undefined)
        setAlertTimeInactivityState(props.alertTimeInactivity);
      if (props.downtime !== undefined) setDowntimeState(props.downtime);
      if (props.alertNewCustomer !== undefined)
        setAlertNewCustomerState(props.alertNewCustomer);
      if (props.tokenWhats !== undefined) setTokenWhatsState(props.tokenWhats);
      if (props.roomAttendanceAmount !== undefined)
        setRoomAttendanceAmountState(props.roomAttendanceAmount);
      if (props.integrationWppNumberId !== undefined)
        setIntegrationWppNumberIdState(props.integrationWppNumberId);
      if (props.whatsappAccountId !== undefined)
        setWhatsappAccountIdState(props.whatsappAccountId);
      if (props.whatsappFlagIntegration !== undefined)
        setWhatsappFlagIntegration(props.whatsappFlagIntegration);
    },
    []
  );

  const setChatMonitor = useCallback((props = {}) => {
    /**
     * @param {{
     *  currentScreen: String,
     *  openFilters: Boolean,
     *  nameFilter: String,
     *  statusFilter: Integer,
     *  departmentFilter: Integer,
     *  accordionsState: Array
     * }} props
     */

    setChatMonitorState((current) => ({ ...current, ...props }));
  }, []);

  const setAccordion = useCallback((props = {}) => {
    /**
     * @param {{
     *   status: boolean,
     *   attendance: boolean,
     *   notification: boolean,
     * }} props
     */
    setAccordionState((current) => ({ ...current, ...props }));
  }, []);

  const setMessages = useCallback(
    (props = {}) => {
      if (props.length === 0) setMessagesState(initialValue.messages);
      else setMessagesState((current) => [...props, ...current]);
    },
    [setMessagesState]
  );

  const setLoading = useCallback(
    (props) => {
      if (typeof props === "boolean") setLoadingState(props);
    },
    [setLoadingState]
  );

  const setCompany = useCallback(
    /**
     * @param {{
     *   id: number,
     *   alias: string
     *   cnpj: string
     *   sgbd: string
     * }} props
     */
    (props = {}) => {
      setCompanyState((current) => ({ ...current, ...props }));
    },
    []
  );

  const setcallSelectedCustomer = useCallback(
    /**
     * @param {{
     *   id: number,
     *   fantasyName: string,
     *   socialName: string,
     *   cnpj: string,
     *   telphone: string,
     *   email: string,
     *   cep: string,
     * }} props
     */
    (props = {}) => {
      setcallSelectedCustomerState(() => ({ ...props }));
    },
    []
  );

  const setSocket = useCallback(
    /**
     * @param {{
     *   clientId: number,
     *   connected: Boolean,
     *   startedTopics: Array<any>,
     * }} props
     */
    (props = {}) => {
      if (props.clientId !== undefined) setClientId(props.clientId);
      if (props.connected !== undefined) setConnected(props.connected);
      if (props.startedTopics !== undefined) {
        if (props.startedTopics && props.startedTopics.length === 0) {
          setStartedTopics(initialValue.socket.startedTopics);
        } else {
          setStartedTopics((prev) => [{ ...prev, ...props.startedTopics }]);
        }
      }
      // setSocketState((current) => ({ ...current, ...props }));
    },
    []
  );

  const setUser = useCallback(
    /**
     * @param {{
     *   id: number,
     *   type: string,
     *   teamId: number,
     *   contactId: number,
     *   name: string,
     *   company: object,
     * }} props
     */
    (props = {}) => {
      setUserState((current) => ({ ...current, ...props }));
    },
    []
  );

  const setSettings = useCallback(
    /**
     * @param {{
     *   roomState: string,
     *   myState: string,
     *   reason: Array<any>,
     *   notificationState: string
     *   notificationIntensity: string
     *   attendanceAmount: string
     *   departments: Array<any>,
     * }} props
     */
    (props = {}) => {
      setSettingsState((current) => ({ ...current, ...props }));
    },
    []
  );

  const setRateQuestions = useCallback(
    /**
     * @param {{
     *   id: number,
     *   question: string,
     * }} props
     */
    (props = {}) => {
      setRateQuestionsState(props);
    },
    []
  );

  const setCustomers = useCallback((props = []) => {
    if (props.length === 0) setCustomersState(initialValue.customers);
    else setCustomersState(() => [...props]);
  }, []);

  const getCurrentConversation = (sessionId) => {
    if (sessionId) return conversations.find((c) => c.sessionId === sessionId);
    if (companyUser?.isAccountant !== "true" && conversations.length > 0)
      return conversations[0];
    if (!sessionId && currentConversation)
      return conversations.find((c) => c.sessionId === currentConversation);
    return null;
  };

  return {
    SCREEN_ENUM,
    company: {
      id: company.id,
      alias: company.alias,
      cnpj: company.cnpj,
      sgbd: company.sgbd,
    },
    user: {
      id: user.id,
      teamId: user.teamId,
      name: user.name,
      type: user.type,
      contactId: user.contactId,
      company: user.company,
    },
    chat: {
      open: open,
      currentScreen: currentScreen,
      currentConversation: currentConversation,
      roomId: roomId,
      distributionProduct: distributionProduct,
      conversations: conversations,
      queue: queue,
      typeAttendace: typeAttendace,
      classificationAttendance: classificationAttendance,
      isLoading,
      loadingMessage,
      currentTicketId,
      roomName: roomName,
      roomDescription: roomDescription,
      initialMessage: initialMessage,
      initialMessageIntegration: initialMessageIntegration,
      roomOffMessage: roomOffMessage,
      messageRoomInactivity: messageRoomInactivity,
      messagemRoomDowntime: messagemRoomDowntime,
      productParam: productParam,
      alertTimeInactivity: alertTimeInactivity,
      downtime: downtime,
      alertNewCustomer: alertNewCustomer,
      tokenWhats: tokenWhats,
      integrationWppNumberId: integrationWppNumberId,
      roomAttendanceAmount: roomAttendanceAmount,
      whatsappAccountId: whatsappAccountId,
      whatsappFlagIntegration: whatsappFlagIntegration,
    },
    messages,
    settings: {
      roomState: settings.roomState,
      myState: settings.myState,
      reason: settings.reason,
      notificationState: settings.notificationState,
      notificationIntensity: settings.notificationIntensity,
      attendanceAmount: settings.attendanceAmount,
      departments: settings.departments,
      accordion: {
        status: accordion.status,
        attendance: accordion.attendance,
        notification: accordion.notification,
      },
    },
    socket: {
      clientId,
      connected,
      startedTopics,
    },
    customers,
    callSelectedCustomer,
    loading,
    rateQuestions: rateQuestions,
    chatMonitor: {
      openFilters: chatMonitorState.openFilters,
      nameFilter: chatMonitorState.nameFilter,
      statusFilter: chatMonitorState.statusFilter,
      departmentFilter: chatMonitorState.departmentFilter,
      currentScreen: chatMonitorState.currentScreen,
      accordionsState: chatMonitorState.accordionsState,
    },
    setLoading,
    setRateQuestions,
    setCompany,
    setUser,
    setChat,
    setMessages,
    setSettings,
    setSocket,
    getCurrentConversation,
    setAccordion,
    setCustomers,
    setcallSelectedCustomer,
    setChatMonitor,
  };
};

const ChatContextProvider = ({ children }) => {
  const { companyUser } = useContext(GlobalContext);
  const [open, setOpen] = useState(initialValue.chat.open);
  const [currentScreen, setCurrentScreen] = useState(
    companyUser?.isAccountant === "true"
      ? SCREEN_ENUM.conversation
      : SCREEN_ENUM.loading
  );
  const [currentConversation, setCurrentConversation] = useState(
    initialValue.chat.currentConversation
  );
  const [currentTicketId, setCurrentTicketId] = useState(
    initialValue.chat.currentTicketId
  );
  const [roomId, setRoomId] = useState(initialValue.chat.roomId);
  const [distributionProduct, setDistributionProduct] = useState(
    initialValue.chat.distributionProduct
  );
  const [conversations, setConversations] = useState([
    ...initialValue.chat.conversations,
  ]);
  const [queue, setQueue] = useState([...initialValue.chat.queue]);
  const [messages, setMessages] = useState([...initialValue.messages]);
  const [isLoading, setIsLoading] = useState(initialValue.chat.isLoading);
  const [loadingMessage, setLoadingMessage] = useState(
    initialValue.chat.loadingMessage
  );
  const [company, setCompany] = useState({ ...initialValue.company });
  const [user, setUser] = useState({ ...initialValue.user });
  const [clientId, setClientId] = useState({ ...initialValue.socket.clientId });
  const [connected, setConnected] = useState({
    ...initialValue.socket.connected,
  });
  const [startedTopics, setStartedTopics] = useState([
    ...initialValue.socket.startedTopics,
  ]);
  const [settings, setSettings] = useState({ ...initialValue.settings });
  const [typeAttendace, setTypeAttendace] = useState([
    ...initialValue.chat.typeAttendace,
  ]);
  const [classificationAttendance, setClassificationAttendance] = useState([
    ...initialValue.chat.classificationAttendance,
  ]);
  const [rateQuestions, setRateQuestions] = useState([
    ...initialValue.rateQuestions,
  ]);
  const [accordion, setAccordion] = useState({
    ...initialValue.settings.accordion,
  });
  const [roomName, setRoomName] = useState(initialValue.chat.roomName);
  const [roomDescription, setRoomDescription] = useState(
    initialValue.chat.roomDescription
  );
  const [initialMessage, setInitialMessage] = useState(
    initialValue.chat.initialMessage
  );
  const [initialMessageIntegration, setInitialMessageIntegration] = useState(
    initialValue.chat.initialMessageIntegration
  );
  const [roomOffMessage, setRoomOffMessage] = useState(
    initialValue.chat.roomOffMessage
  );
  const [messageRoomInactivity, setMessageRoomInactivity] = useState(
    initialValue.chat.messageRoomInactivity
  );
  const [messagemRoomDowntime, setMessagemRoomDowntime] = useState(
    initialValue.chat.messagemRoomDowntime
  );
  const [productParam, setProductParam] = useState(
    initialValue.chat.productParam
  );
  const [alertTimeInactivity, setAlertTimeInactivity] = useState(
    initialValue.chat.alertTimeInactivity
  );
  const [downtime, setDowntime] = useState(initialValue.chat.downtime);
  const [alertNewCustomer, setAlertNewCustomer] = useState(
    initialValue.chat.alertNewCustomer
  );
  const [tokenWhats, setTokenWhats] = useState(initialValue.chat.tokenWhats);
  const [integrationWppNumberId, setIntegrationWppNumberId] = useState(
    initialValue.chat.integrationWppNumberId
  );
  const [whatsappFlagIntegration, setWhatsappFlagIntegration] = useState(
    initialValue.chat.whatsappFlagIntegration
  );
  const [whatsappAccountId, setWhatsappAccountId] = useState(
    initialValue.chat.whatsappAccountId
  );
  const [roomAttendanceAmount, setRoomAttendanceAmount] = useState(
    initialValue.chat.roomAttendanceAmount
  );
  const [customers, setCustomers] = useState(initialValue.customers);
  const [callSelectedCustomer, setCallSelectedCustomer] = useState(
    initialValue.callSelectedCustomer
  );
  const [chatMonitor, setChatMonitor] = useState(initialValue.chatMonitor);
  const [loading, setLoading] = useState(initialValue.loading);

  return (
    <ChatContext.Provider
      value={[
        open,
        setOpen,
        currentScreen,
        setCurrentScreen,
        currentConversation,
        setCurrentConversation,
        roomId,
        setRoomId,
        currentTicketId,
        setCurrentTicketId,
        distributionProduct,
        setDistributionProduct,
        conversations,
        setConversations,
        queue,
        setQueue,
        messages,
        setMessages,
        isLoading,
        setIsLoading,
        loadingMessage,
        setLoadingMessage,
        company,
        setCompany,
        user,
        setUser,
        clientId,
        setClientId,
        connected,
        setConnected,
        startedTopics,
        setStartedTopics,
        settings,
        setSettings,
        typeAttendace,
        setTypeAttendace,
        classificationAttendance,
        setClassificationAttendance,
        rateQuestions,
        setRateQuestions,
        accordion,
        setAccordion,
        roomName,
        setRoomName,
        roomDescription,
        setRoomDescription,
        initialMessage,
        setInitialMessage,
        initialMessageIntegration,
        setInitialMessageIntegration,
        roomOffMessage,
        setRoomOffMessage,
        messageRoomInactivity,
        setMessageRoomInactivity,
        messagemRoomDowntime,
        setMessagemRoomDowntime,
        productParam,
        setProductParam,
        alertTimeInactivity,
        setAlertTimeInactivity,
        downtime,
        setDowntime,
        alertNewCustomer,
        setAlertNewCustomer,
        tokenWhats,
        setTokenWhats,
        integrationWppNumberId,
        setIntegrationWppNumberId,
        whatsappAccountId,
        setWhatsappAccountId,
        whatsappFlagIntegration,
        setWhatsappFlagIntegration,
        roomAttendanceAmount,
        setRoomAttendanceAmount,
        customers,
        setCustomers,
        callSelectedCustomer,
        setCallSelectedCustomer,
        chatMonitor,
        setChatMonitor,
        loading,
        setLoading,
      ]}
    >
      {children}
    </ChatContext.Provider>
  );
};

export { ChatContextProvider, useChatContext, initialValue };
