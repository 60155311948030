import { api } from "./api";

export const getHandler = async ({ webSocketClient, companyId, ticketId }) => {
  return await api
    .get(
      `${
        webSocketClient.urlWebSocketClient || localStorage.getItem("URL_WS")
      }/rate?companyId=${companyId}&ticketId=${ticketId}`
    )
    .then((response) => {
      if (response.status === 200) return response;
      return null;
    })
    .catch((error) => {
      console.error(error);
      return null;
    });
};

export const getProductsAttendantHandler = async ({
  webSocketClient,
  ticketId,
  companyId,
  observation,
  questions,
}) => {
  return await api
    .post(
      `${
        webSocketClient.urlWebSocketClient || localStorage.getItem("URL_WS")
      }/rate`,
      {
        ticketId,
        companyId,
        observation,
        questions,
      }
    )
    .then((response) => {
      if (response.status === 200) return response;
      return null;
    })
    .catch((error) => {
      console.error(error);
      return null;
    });
};
