import { Dialog, Divider, Grid } from "@material-ui/core";
import styled, { css } from "styled-components";

const gray = (props) => props.theme.palette.gray;

export const PaperStyled = styled.div`
  padding: 0;
  text-align: center;
  color: ${gray};
  cursor: pointer;
  border-radius: 12px;
  display: flex;
  align-items: space-between;

  :hover {
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  }

  ${(props) =>
    props.border &&
    css`
      border: ${(props) => props.border};
    `};
`;

export const DividerFull = styled(Divider)`
  width: 100%;
`;

export const DailogSettings = styled(Dialog)`
  .MuiDialog-paperWidthSm {
    min-height: 10vh !important;
    max-height: 70vh !important;
  }
`;

export const BodyCard = styled(Grid)`
  border-radius: 12px 12px 0px 0px;
  border: ${(props) => `solid ${props.active ? "#0a73db" : "#e4e4e4"} 3px`};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const IconCard = styled.img`
  width: auto;
  height: 38px;
`;

export const FooterCard = styled(Grid)`
  background-color: ${(props) => (props.active ? "#0a73db" : "#e4e4e4")};
  border-radius: 0px 0px 12px 12px;
  overflow: hidden;
  height: 25px;
`;

export const PreviewWidget = styled.img`
  width: 400px;
  height: 100%;
`;
