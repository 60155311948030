import AutoCompleteCRM from "../../../pages/attendance/childrens/AutoCompleteCRM";
import { useChatContext } from "../contexts/ChatContext";
import { useMonitor } from "../contexts/ChatMonitorContext";
import { optionsList } from "../utils";

const CustomersFilters = () => {
  const { setDepartmentFilter, departmentFilter } = useMonitor();
  const { settings } = useChatContext();

  const noDepartment = {
    id: "0",
    description: "Sem departamento",
    selected: false,
  };

  return (
    <>
      <AutoCompleteCRM
        label="Departamentos"
        descriptionField="title"
        lg={2}
        value={departmentFilter}
        optionsList={optionsList([...settings.departments, noDepartment])}
        onChange={setDepartmentFilter}
        setValue={setDepartmentFilter}
        variantAutocomplete="filled"
      />
    </>
  );
};

export default CustomersFilters;
