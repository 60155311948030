import { search } from "../../BaseRepositoryComponents";

const newPath =
  localStorage.getItem("environment") === "prod"
    ? "https://api-cnd.qyon.info"
    : "https://api-cnd-hml.qyon.info";
const path = `${process.env.REACT_APP_ERP_API_URL}/api/cnd/v1`;
const token = process.env.REACT_APP_ERP_API_TOKEN;

const headers = new Headers();
headers.append("Authorization", token);
headers.append("content-type", "application/json");
headers.append("Content-Language", "pt-BR");

export async function findCNDCustomerSchedule(contador_cnpj, cliente_cnpj) {
  return search({
    path: `${path}/controle?empresa_cnpj=${contador_cnpj}&cliente_cnpj=${cliente_cnpj}`,
    myHeaders: headers,
  });
}

export default function changeFavorite(contador_cnpj, id_agenda, favorited) {
  return search({
    path: `${path}/favorite${
      id_agenda ? "/" + id_agenda : ""
    }?empresa_cnpj=${contador_cnpj}`,
    method: "POST",
    myBody: { favorited: favorited ? "t" : "f" },
    myHeaders: headers,
  });
}

export async function sendEmail(body) {
  return search({
    path: `${process.env.REACT_APP_ERP_API_URL}/api/aws/v1/ses/send`,
    method: "POST",
    myHeaders: headers,
    myBody: body,
  });
}

export async function findCNDConfig(contador_cnpj, setting) {
  return search({
    path: `${path}/config?empresa_cnpj=${contador_cnpj}&setting=${setting}`,
    myHeaders: headers,
  });
}

export async function requestNewCnd(request) {
  return search({
    path: `${newPath}/v1/cnd/request`,
    method: "POST",
    myBody: request,
  });
}