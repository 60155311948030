import { useContext } from "react";

import Swal from "sweetalert2";

import {
  deletePanel,
  postPanel,
  putPanel,
  favoritePanel,
} from "../../../repositories/panel/v1/PanelRepository";
import {
  deletePanelWidget,
  putWidgets,
} from "../../../repositories/panel/v1/WidgetRepository";
import { notify } from "../../../utils/notify";
import { DashboardSettingContext } from "../contexts/DashboardSettingContext";

export default function useDashboardSettingController() {
  const {
    setIdsWidgetsHomePage,
    setLoading,
    setErrors,
    setNewPanel,
    setModalPanel,
    panelId,
    panelName,
    versionWidget,
    setWidgetEdit,
    setRefreshPanel,
    setPanelSelected,
    panelSelected,
    setLoadingSave,
    setLoadingInputs,

    panelsList,
    setPanelsList,
  } = useContext(DashboardSettingContext);

  const handleNewPanel = () => {
    setNewPanel(true);
    setModalPanel(true);
  };

  const handlePutWidgets = async () => {
    try {
      setLoadingSave(true);
      if (!versionWidget?.id) {
        return setErrors({ versionWidget: "Campo obrigatório" });
      }

      setErrors({});

      const body = {
        panel_id: panelSelected.id,
        widget_version_id: versionWidget?.id || null,
        auto_update: true,
        position_y: 0,
        postion_x: 0,
        width: versionWidget?.width || 4,
        height: versionWidget?.height || 4,
      };

      const mainResponse = await putWidgets({
        body,
        idpanel: panelSelected.id,
      });

      if (!mainResponse?.success || !mainResponse?.data) {
        return notify("Não foi possível remover o widget.");
      }
      if (versionWidget?.id) {
        setIdsWidgetsHomePage((current) => [...current, versionWidget.id]);
      }
      setWidgetEdit(false);
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingSave(false);
    }
  };

  const handleDeleteWidgets = async () => {
    try {
      setLoading(true);

      const mainResponse = await deletePanelWidget(
        panelSelected.id,
        versionWidget.id
      );

      if (!mainResponse?.success || !mainResponse?.data) {
        return notify("Algo Deu errado, tente novamente.");
      }

      setIdsWidgetsHomePage((current) => {
        var index = current.indexOf(versionWidget.id);
        if (index !== -1) {
          current.splice(index, 1);
        }
        return current;
      });

      setWidgetEdit(false);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleSavePanel = async () => {
    try {
      setLoadingSave(true);

      if (!panelName) return setErrors({ panelName: "Campo obrigatório" });

      setErrors({});

      let respPanel;
      if (panelId) {
        respPanel = await putPanel({
          body: {
            shared_to_entity: 0,
            name: panelName,
          },
          id: panelId,
        });
      } else {
        respPanel = await postPanel({
          body: {
            shared_to_entity: 0,
            name: panelName,
          },
        });
      }

      if (respPanel.success) {
        if (panelId) {
          const copy = [...panelsList];
          const indexPanel = copy.findIndex((panel) => panel.id === panelId);
          if (indexPanel !== -1) {
            copy[indexPanel] = { ...copy[indexPanel], name: panelName };
            setPanelsList(copy);
            setPanelSelected(copy[indexPanel]);
          }
        } else {
          setRefreshPanel((current) => !current);
        }
        notify(
          `Painel ${panelId ? "editado" : "cadastrado"} com sucesso.`,
          true,
          "success"
        );
        setModalPanel(false);
        setNewPanel(false);
      }

      return;
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingSave(false);
    }
  };

  const handleDeletePanel = async (id) => {
    Swal.fire({
      icon: "question",
      text: "Tem certeza que deseja excluir?",
      cancelButtonText: "Cancelar",
      confirmButtonText: "Sim",
      showConfirmButton: true,
      showCancelButton: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          if (panelsList.length <= 1) {
            return notify("Este é seu único painel cadastrado.");
          }

          setLoadingInputs((current) => ({ ...current, blockScreen: true }));

          const respDelete = await deletePanel({ id });

          if (respDelete?.success) {
            const newList = panelsList.filter((panel) => panel?.id !== id);
            setPanelSelected(newList?.[0] || null);
            setPanelsList(newList);

            notify("Painel excluído com sucesso.", true, "success");
          }
        } catch (error) {
          console.error(error);
        } finally {
          setLoadingInputs((current) => ({ ...current, blockScreen: false }));
        }
      }
    });
  };

  const handleFavoritePanel = async (id, favorited) => {
    try {
      setLoadingInputs((current) => ({ ...current, favorited: true }));

      const respFavorite = await favoritePanel({ id, favorited });

      if (respFavorite?.success) {
        const newList = [];
        let itemFavorited = null;
        panelsList.forEach((item) => {
          if (item.id === id) {
            itemFavorited = { ...item, favorited: favorited };
            return newList.push(itemFavorited);
          }
          newList.push({ ...item, favorited: false });
        });

        setPanelsList(newList);
        setPanelSelected(itemFavorited);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingInputs((current) => ({ ...current, favorited: false }));
    }
  };

  return {
    handlePutWidgets,
    handleDeleteWidgets,
    handleNewPanel,
    handleSavePanel,
    handleDeletePanel,
    handleFavoritePanel,
  };
}
