import { useContext } from "react";
import { GlobalContext } from "../../contexts/GlobalContext";

export default function useCommonTranslate() {
  const { language } = useContext(GlobalContext);

  const ptBr = {
    notifyValidRequired:
      "Verifique se os campos foram preenchidos corretamente.",

    search: "Pesquisar",
  };

  const enUs = {
    notifyValidRequired: "Make sure the fields are filled in correctly.",

    search: "Search",
  };

  const options = { "pt-BR": ptBr, "en-US": enUs };

  if (!options[language]) return enUs;

  return {
    notifyValidRequired: options[language].notifyValidRequired,

    search: options[language].search,
  };
}
