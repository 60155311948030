import {
  Button,
  Card,
  CardActions,
  CardContent,
  FormControlLabel,
  Grid,
  Switch,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import EmailIcon from "@material-ui/icons/Email";
import SaveIcon from "@material-ui/icons/Save";
import SendIcon from "@material-ui/icons/Send";
import * as React from "react";
import { useContext, useEffect, useRef, useState } from "react";
import { GlobalContext } from "../../../contexts/GlobalContext";
import {
  ContainerIcon,
  GridStyled,
  LightTooltip,
  Title,
} from "../../../styles/GlobalStyle";
import { notify } from "../../../utils/notify";
import {
  APISendEmail,
  ActualizacionTemplate,
  CreateTemplate,
  IDTemplate,
  ListTemplate,
  PatternTemplate,
  RemoveTemplate,
} from "../controllers/Templates";
import {
  ArrowBackIconStyled,
  CardHeaderStyled,
} from "../styles/CDNTableCndEmail";
import { DataGridCnd } from "../styles/CndCustomer";
import { ModalNewTemplate } from "./DialogEmail/DialogNewTemplate";
import { DialogRemoveTemplate } from "./DialogEmail/DialogRemoveTemplate";
import { DialogEmail } from "./DialogEmail/DialogSendEmail";
import { CNDError } from "./DialogEmail/JsonTemplates/CNDError";
import { CNDInconsistent } from "./DialogEmail/JsonTemplates/CNDInconsistent";
import { CNDNegative } from "./DialogEmail/JsonTemplates/CNDNegative";
import { CNDPositive } from "./DialogEmail/JsonTemplates/CNDPositive";
import { InformativeTemplate } from "./DialogEmail/LegendEditor";

export const TabAccountantCndEmail = () => {
  const { companyUser } = useContext(GlobalContext);

  const [isLoaded, setIsLoaded] = useState(false);

  const emailEditorRef = useRef(null);

  const [value, setValue] = useState("");

  const [openNewEmail, setNewEmail] = useState(false);

  const [listTemplates, setlistTemplates] = useState([]);

  const [nameTemplate, setnameTemplate] = useState("");

  const [TypeCND, setTypeCND] = useState("");

  const [loading, setLoading] = useState(false);

  const [dialogDelete, setDialogDelete] = useState(false);

  const [dialogSendEmail, setdialogSendEmail] = useState(false);

  const [IdRemove, setIdRemove] = useState("");

  const [IdTemplate, setIdTemplate] = useState(null);

  const [HTMLTemplate, setHTMLTemplate] = useState("");

  const [emailTextField, setEmailTextField] = useState("");

  const closeDialogRemove = () => {
    setDialogDelete(false);
  };

  const closeDialogEmail = () => {
    setdialogSendEmail(false);
  };

  const handleChangeEmail = (event) => {
    setEmailTextField(event.target.value);
  };

  const handleChangeName = (event) => {
    setnameTemplate(event.target.value);
  };

  const handleChangeType = (event) => {
    setTypeCND(event.target.value);
    let templateTemp = null;

    if (event.target.value === "Positiva") {
      templateTemp = CNDPositive;
    }
    if (event.target.value === "Negativa") {
      templateTemp = CNDNegative;
    }
    if (event.target.value === "Erro") {
      templateTemp = CNDError;
    }
    if (event.target.value === "Inconsistente") {
      templateTemp = CNDInconsistent;
    }
    setValue(templateTemp);
    setTimeout(() => {
      emailEditorRef.current.editor.loadDesign(templateTemp);
    }, 0);
  };

  const columns = [
    {
      field: "padrao",
      headerName: "Template padrão",
      sortable: false,
      width: 165,
      align: "center",
      renderCell: ({ row }) => (
        <CardHeaderStyled
          title={
            <FormControlLabel
              control={
                <Switch
                  onChange={(e) => {
                    UpdateDefaultTemplate(row, e);
                  }}
                  checked={row.default}
                  color="primary"
                  size="small"
                  inputProps={{ "aria-label": "controlled" }}
                />
              }
              label={row.default === true ? "Sim" : "Não"}
            />
          }
        />
      ),
    },
    {
      field: "name",
      headerName: "Nome",
      width: 495,
      renderCell: ({ row }) => (
        <div className="MuiDataGrid-columnHeaderTitle">{row.name}</div>
      ),
    },
    {
      field: "type",
      headerName: "Tipo da CND",
      align: "center",
      headerAlign: "center",
      width: 190,
      renderCell: ({ row }) => (
        <div className="MuiDataGrid-columnHeaderTitle">{row.type}</div>
      ),
    },
    {
      field: "action",
      headerName: "Ações",
      align: "center",
      headerAlign: "center",
      width: 130,
      renderCell: ({ row }) => (
        <GridStyled
          container
          spacing={1}
          alignItems="center"
          margin="10px 0 0 0"
        >
          <Grid item flex={1}>
            <LightTooltip title="Editar">
              <ContainerIcon iconColor="blue">
                <EditIcon onClick={() => SetTemplate(row)} />
              </ContainerIcon>
            </LightTooltip>
          </Grid>

          <Grid item flex={1}>
            <LightTooltip title="Enviar e-mail">
              <ContainerIcon iconColor="blue">
                <EmailIcon onClick={() => OpenDialogSendEmail(row)} />
              </ContainerIcon>
            </LightTooltip>
          </Grid>

          <Grid item flex={1}>
            <LightTooltip title="Deletar">
              <ContainerIcon iconColor="gray">
                <DeleteIcon onClick={() => OpenDialogRemove(row)} />
              </ContainerIcon>
            </LightTooltip>
          </Grid>
        </GridStyled>
      ),
    },
  ];

  async function UpdateDefaultTemplate(row, event) {
    let data = {
      type: row.type,
      default_template: !row.default,
    };
    let response = await PatternTemplate(companyUser.document, data, row.id);
    if (response.data.success === true) {
      notify("Template padrão ativado", true, "success");
    }
    let responseList = await ListTemplate(companyUser.document);
    if (responseList.data.success === true) {
      setlistTemplates(responseList.data.data);
    }
  }

  const onLoad = () => {
    setTimeout(() => {
      if (isLoaded === false) {
        emailEditorRef.current.editor.loadDesign(value);
        setIsLoaded(true);
      }
    }, 0);
  };

  const saveDesign = async () => {
    if (IdTemplate) {
      await new Promise((resolve, reject) => {
        emailEditorRef.current.editor.saveDesign((design) => {
          resolve(design);
        });
      });

      const { design, html } = await new Promise((resolve, reject) => {
        emailEditorRef.current.editor.exportHtml((data) => {
          resolve(data);
        });
      });

      let body = {
        name: nameTemplate,
        type: TypeCND,
        base64_html: Buffer.from(html).toString("base64"),
        template: design,
      };

      await ActualizacionTemplate(companyUser.document, IdTemplate, body);
      let response = await ListTemplate(companyUser.document);
      if (response.data.success === true) {
        setlistTemplates(response.data.data);
        const { html } = await new Promise((resolve, reject) => {
          emailEditorRef.current.editor.exportHtml((data) => {
            resolve(data);
          });
        });
        setHTMLTemplate(html);
        setNewEmail(false);
        notify("Template salvo com sucesso!", true, "success");
      }
    } else {
      emailEditorRef.current.editor.saveDesign((design) => {
        jsonToBase64(design);
      });
      const { html } = await new Promise((resolve, reject) => {
        emailEditorRef.current.editor.exportHtml((data) => {
          resolve(data);
        });
      });
      setHTMLTemplate(html);
    }
  };

  async function jsonToBase64(jsonObj) {
    const { design, html } = await new Promise((resolve, reject) => {
      emailEditorRef.current.editor.exportHtml((data) => {
        resolve(data);
      });
    });

    let base64_html = Buffer.from(html).toString("base64");
    // setBase64_html(base64_html);

    if (nameTemplate !== "" && TypeCND !== "") {
      const body = {
        name: nameTemplate,
        base64_html: base64_html,
        template: design,
        type: TypeCND,
      };
      let response = await CreateTemplate(companyUser.document, body);

      if (response.data.success === true) {
        let list = await ListTemplate(companyUser.document);
        if (list.data.success === true) {
          setlistTemplates(list.data.data);
          setNewEmail(false);
          notify("Template salvo com sucesso!", true, "success");
        }
      }
    } else {
      notify("Preencha todos os campos!", true, "warning");
    }
  }

  async function SetTemplate(row) {
    setIsLoaded(false);
    if (row.id) {
      setIdTemplate(row.id);
    }
    setnameTemplate(row.name);
    setTypeCND(row.type);

    let response = await IDTemplate(companyUser.document, row.id);
    setValue(response.data.data.template);
    setNewEmail(true);
  }

  function OpenDialogRemove(row) {
    setDialogDelete(true);
    setIdRemove(row.id);
  }

  async function OpenDialogSendEmail(row) {
    setEmailTextField("");
    let response = await IDTemplate(companyUser.document, row.id);

    var decodedString = Buffer.from(
      response.data.data.base64_html,
      "base64"
    ).toString();
    setHTMLTemplate(decodedString);
    setdialogSendEmail(true);
  }

  async function DeleteTemplate() {
    let response = await RemoveTemplate(companyUser.document, IdRemove);
    if (response.data.success === true) {
      setDialogDelete(false);
      let list = await ListTemplate(companyUser.document);
      if (list.data.success === true) {
        setlistTemplates(list.data.data);
        setNewEmail(false);
        notify("Template removido com sucesso!", true, "success");
      }
    }
  }

  function NewTemplate() {
    setNewEmail(true);
    setIdTemplate(null);
    setnameTemplate("");
    setTypeCND("");
    setValue("");
  }

  async function ReturnListTemplates() {
    setNewEmail(false);
    let response = await ListTemplate(companyUser.document);
    if (response.data.success === true) {
      setlistTemplates(response.data.data);
    } else {
      setLoading(true);
    }
  }

  async function SendEmail() {
    let response = await APISendEmail(
      companyUser.document,
      emailTextField,
      Buffer.from(HTMLTemplate).toString("base64")
    );
    if (response.data.success === true) {
      setdialogSendEmail(false);
      notify("E-mail enviado com sucesso!", true, "success");
    } else {
      if (emailTextField === "") {
        notify("Digite um e-mail válido para o envio!", true, "error");
      } else {
        notify(
          "Ocorreu um erro com o envio do e-mail. Tente novamente!",
          true,
          "error"
        );
      }
    }
  }

  async function ButtonSend() {
    setdialogSendEmail(true);
    const { html } = await new Promise((resolve, reject) => {
      emailEditorRef.current.editor.exportHtml((data) => {
        resolve(data);
      });
    });
    setHTMLTemplate(html);
  }

  useEffect(() => {
    (async () => {
      setLoading(true);
      let response = await ListTemplate(companyUser.document);
      if (response.data.success === true) {
        setLoading(false);
        setlistTemplates(response.data.data);
      } else {
        setLoading(true);
      }
    })();
  }, [companyUser.document]);

  return (
    <>
      {openNewEmail === false && (
        <Grid container direction="column" spacing={3}>
          <Grid item>
            <InformativeTemplate />
          </Grid>
          <Grid item>
            <Card>
              <CardContent>
                <Grid container spacing={2}>
                  <GridStyled container spacing={3} margin="0px 0px 20px 0">
                    <GridStyled item xs bg-color="white" border-radius="4">
                      <Grid container spacing={1} alignItems="center">
                        <GridStyled
                          item
                          xs
                          height="100%"
                          container
                          alignItems="center"
                        >
                          <Title>Meus templates</Title>
                        </GridStyled>
                        <Grid item flex={1}>
                          <Button
                            variant="outlined"
                            color="primary"
                            onClick={() => NewTemplate()}
                            startIcon={<AddIcon />}
                          >
                            Novo Template
                          </Button>
                        </Grid>
                      </Grid>
                    </GridStyled>
                  </GridStyled>
                </Grid>

                <Grid container spacing={2} justifyContent="space-between">
                  <GridStyled
                    item
                    sm
                    spacing={3}
                    margin="0px 0px 30px 0"
                    border-radius="4"
                  >
                    <DataGridCnd
                      rows={listTemplates}
                      columns={columns}
                      pageSize={20}
                      disableSelectionOnClick
                      disableColumnMenu
                      autoHeight
                      loading={loading}
                      rowHeight={listTemplates.length === 0 ? 15 : 52}
                    />
                  </GridStyled>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      )}

      {openNewEmail === true && (
        <Card>
          <CardContent>
            <ContainerIcon iconColor="blue">
              <ArrowBackIconStyled onClick={() => ReturnListTemplates()} />
              <Title style={{ marginLeft: "10px" }}>
                {value === "" ? "Novo template" : "Editar template"}
              </Title>
            </ContainerIcon>

            <ModalNewTemplate
              nameTemplate={nameTemplate}
              handleChangeName={handleChangeName}
              TypeCND={TypeCND}
              handleChangeType={handleChangeType}
              emailEditorRef={emailEditorRef}
              onLoad={onLoad}
              value={value}
            />
          </CardContent>

          <CardActions>
            <GridStyled
              container
              spacing={2}
              justifyContent="flex-end"
              margin="-8px -16"
            >
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  style={{
                    marginLeft: "10px",
                  }}
                  endIcon={<SaveIcon />}
                  onClick={saveDesign}
                >
                  Salvar
                </Button>
              </Grid>

              <Grid item>
                <Button
                  variant="contained"
                  endIcon={<SendIcon />}
                  color="primary"
                  onClick={() => ButtonSend()}
                >
                  Enviar e-mail de teste
                </Button>
              </Grid>
            </GridStyled>
          </CardActions>
        </Card>
      )}

      {dialogDelete === true && (
        <DialogRemoveTemplate
          openDialog={dialogDelete}
          closeDialog={closeDialogRemove}
          deleteTemplate={DeleteTemplate}
        />
      )}

      {dialogSendEmail === true && (
        <DialogEmail
          openDialog={dialogSendEmail}
          closeDialog={closeDialogEmail}
          email={emailTextField}
          onChange={handleChangeEmail}
          HTMLTemplate={HTMLTemplate}
          sendEmail={SendEmail}
        />
      )}
    </>
  );
};
