import React from "react";

import ReactDOM from "react-dom";

import { ToastContainer } from "react-toastify";

import App from "./App";
import "./index.css";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";

ReactDOM.render(
  <React.Fragment>
    <ToastContainer />
    <App />
  </React.Fragment>,
  document.getElementById("root")
);

serviceWorkerRegistration.unregister();
reportWebVitals();
