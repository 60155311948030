import { UploadXml } from "../../../pages/dashboard/components/events/UploadXml";
import { notify } from "../../../utils/notify";

export const SendXml = async (props, xmlList) => {
  props.loadingXmlModal(true);

  let error = false;
  for (let xml of xmlList) {
    if (xml.data.xmlType !== "NotIdentified") {
      let resp = await UploadXml(xml);
      if (resp.status !== 201) {
        error = true;
        break;
      }
    }
  }

  if (error === true) {
    notify("Ocorreu um problema, tente novamente mais tarde!");
    props.loadingXmlModal(false);
    return;
  } else {
    const XMLsIdentified = xmlList.filter(
      (item) => item.data.xmlType !== "NotIdentified"
    );
    const XMLNotIdentified = xmlList.filter(
      (item) => item.data.xmlType === "NotIdentified"
    );

    if (XMLsIdentified.length !== 0) {
      notify(
        "Estamos realizando as operações nos XMLs enviados!",
        true,
        "success"
      );
      props.loadingXmlModal(false);
      props.closeXmlModal();
    }

    if (XMLNotIdentified.length !== 0) {
      notify("XML inválido não enviado!");
      props.loadingXmlModal(false);
      return;
    }
  }
};
