import { useContext, useMemo, useState } from "react";

/**
 * Icon
 */
import {
  EmailOutlined,
  ExpandLess,
  ExpandMore,
  Favorite,
  FavoriteBorder,
  PlayForWorkOutlined,
  RemoveRedEyeOutlined,
} from "@material-ui/icons";

/**
 * Component
 */

import { Grid } from "@material-ui/core";

import { DataGridCnd } from "../styles/CndCustomer";
import ModalSendEmail from "./ModalSendEmail";
import { OptionsIcons } from "./OptionsIcons";
import { TableDescription } from "./TableDescription";

/**
 * Styled Component
 */
import {
  ContainerIcon,
  GridStyled,
  LabelStyled,
  LightTooltip,
} from "../../../styles/GlobalStyle";

/**
 * Controler
 */

import { usePermissions } from "../../../utils/permissionsHook";
import { useCndCustomerController } from "../controllers/CndCustomerController";
import { useCndGeneralController } from "../controllers/CndGeneralController";

/**
 * Context
 */
import { GlobalContext } from "../../../contexts/GlobalContext";
import { CndCustomerContext } from "../contexts/CndCustomerContext";

import { Cached } from "@material-ui/icons";
import { GlobalVariables } from "../../../GlobalVariables";
import { notify } from "../../../utils/notify";

export default function TableTabCnd({ rows, pageSize, tab, setRows }) {
  const { getFile, disableActionFile } = useCndGeneralController();
  const {
    handleFavorite,
    handleChangeExpanded,
    handleStandAloneRequestCnd,
    removeFavorite,
  } = useCndCustomerController();

  const { setLoading } = useContext(CndCustomerContext);
  const { companyUser } = useContext(GlobalContext);

  const [dataSendEmail, setDataSendEmail] = useState({});
  const { checkIfUserHasPermission } = usePermissions();

  const hasEditionPermission = useMemo(
    () =>
      checkIfUserHasPermission(GlobalVariables.PERMISSIONS.CND_SOLICITATION),
    [checkIfUserHasPermission]
  );

  const isStandaloneClient =
    !companyUser.isAccountant && !companyUser.accountantId;

  const columns = [
    {
      field: "actions",
      headerName: "Ações",
      width: 180,
      headerAlign: "center",
      sortable: false,
      disableColumnSelector: true,
      renderCell: ({ row }) => (
        <GridStyled
          container
          spacing={1}
          alignItems="center"
          margin="10px 0 0 0"
        >
          <Grid item flex={1}>
            <LightTooltip title="Abrir em outra aba">
              <ContainerIcon
                iconColor={disableActionFile(row) ? "grayPrimary" : "blue"}
              >
                <RemoveRedEyeOutlined
                  onClick={() => {
                    !disableActionFile(row)
                      ? getFile(
                          companyUser.accountantCnpj
                            ? companyUser.accountantCnpj
                            : companyUser.document,
                          row.cndId,
                          "View",
                          setLoading,
                          companyUser.document
                        )
                      : notify("CND ainda não processada");
                  }}
                />
              </ContainerIcon>
            </LightTooltip>
          </Grid>

          <Grid item flex={1}>
            <LightTooltip title="Favoritar">
              <ContainerIcon iconColor={"blue"}>
                {row.favorite ? (
                  <Favorite
                    onClick={() => removeFavorite(row.agendaId, row.favorite)}
                  />
                ) : (
                  <FavoriteBorder
                    onClick={() => handleFavorite(row.agendaId, row.favorite)}
                  />
                )}
              </ContainerIcon>
            </LightTooltip>
          </Grid>

          <Grid item flex={1}>
            <LightTooltip title="Download">
              <ContainerIcon
                iconColor={disableActionFile(row) ? "grayPrimary" : "blue"}
              >
                <PlayForWorkOutlined
                  onClick={() => {
                    !disableActionFile(row)
                      ? getFile(
                          companyUser.accountantCnpj
                            ? companyUser.accountantCnpj
                            : companyUser.document,
                          row.cndId,
                          "Download",
                          setLoading,
                          companyUser.document
                        )
                      : notify("CND ainda não processada");
                  }}
                />
              </ContainerIcon>
            </LightTooltip>
          </Grid>

          <Grid item flex={1}>
            <LightTooltip title="Enviar">
              <ContainerIcon
                iconColor={disableActionFile(row) ? "grayPrimary" : "blue"}
              >
                <EmailOutlined
                  disabled={disableActionFile(row)}
                  onClick={() => {
                    !disableActionFile(row)
                      ? setDataSendEmail({
                          id: row.cndId,
                          document: companyUser.document,
                          defaultMail: companyUser.masterUserEmail,
                        })
                      : notify("CND ainda não processada");
                  }}
                />
              </ContainerIcon>
            </LightTooltip>
          </Grid>

          {isStandaloneClient && (
            <Grid item flex={1}>
              <LightTooltip
                title={row.auto ? "Solicitar CND" : "Upload manual"}
              >
                <ContainerIcon iconColor="blue">
                  <Cached
                    onClick={() => {
                      if (!hasEditionPermission)
                        return notify(
                          "Seu usuário não tem permissão para realizar essa ação"
                        );

                      handleStandAloneRequestCnd({
                        document: companyUser.document,
                        agendaId: row.agendaId,
                        setRows,
                        rows,
                      });
                    }}
                  />
                </ContainerIcon>
              </LightTooltip>
            </Grid>
          )}
        </GridStyled>
      ),
    },
    {
      field: "certidao",
      headerName: "Certidão",
      flex: 1,
      minWidth: 160,
      renderCell: ({ row }) => {
        return (
          <div className="MuiDataGrid-columnHeaderTitle">
            {row.scope?.scope &&
              (row.scope?.scope === "Municipal"
                ? `${row.cndName} - ${row.scope?.state}`
                : row.cndName)}
          </div>
        );
      },
    },
    {
      field: "situacao",
      headerName: "Situação",
      align: "center",
      headerAlign: "center",
      width: 132,
      renderCell: ({ row }) => {
        return (
          <GridStyled container alignItems="center" justifyContent="center">
            <Grid item flex={1}>
              {OptionsIcons[row.status]}
            </Grid>
          </GridStyled>
        );
      },
    },
    {
      field: "data_emissao",
      headerName: "Emissão",
      align: "center",
      headerAlign: "center",
      width: 131,
      renderCell: ({ row }) => (
        <div className="MuiDataGrid-columnHeaderTitle">
          {row.issuanceDate
            ? row.issuanceDate.split("-").reverse().join("/")
            : ""}
        </div>
      ),
    },
    {
      field: "data_vencimento",
      headerName: "Vencimento",
      align: "center",
      headerAlign: "center",
      width: 151,
      renderCell: ({ row }) => (
        <div className="MuiDataGrid-columnHeaderTitle">
          {row.expirationDate
            ? row.expirationDate.split("-").reverse().join("/")
            : ""}
        </div>
      ),
    },
  ];

  return (
    <>
      {dataSendEmail?.id && (
        <ModalSendEmail
          handleClose={() => setDataSendEmail({})}
          row={dataSendEmail}
        />
      )}
      <GridStyled container>
        <GridStyled container spacing={3} margin="0">
          <GridStyled item xs bg-color="white" border-radius="4">
            <DataGridCnd
              rows={rows}
              columns={columns}
              disableSelectionOnClick
              disableColumnMenu
              pageSize={pageSize}
              autoHeight
              rowHeight={rows.length === 0 ? 15 : 32}
            />
          </GridStyled>
          <GridStyled
            item
            container
            alignItems="center"
            justifyContent="space-between"
          >
            <Grid item flex={1} xs={12}>
              <TableDescription />
            </Grid>

            <Grid item flex={1}>
              <Grid
                container
                alignItems="center"
                bg-color="red"
                onClick={() => handleChangeExpanded(tab)}
              >
                {rows.length > 5 && (
                  <>
                    <LabelStyled color="blue" fontWeight="600" cursor="pointer">
                      {rows.length > pageSize ? "Expandir" : "Fechar"}
                    </LabelStyled>
                    <ContainerIcon fontSize="38" iconColor="blue">
                      {rows.length > pageSize ? <ExpandMore /> : <ExpandLess />}
                    </ContainerIcon>
                  </>
                )}
              </Grid>
            </Grid>
          </GridStyled>
        </GridStyled>
      </GridStyled>
    </>
  );
}
