import { CircularProgress, Grid } from "@material-ui/core";
// import Autocomplete from '@mui/material/Autocomplete';
import AddIcon from "@material-ui/icons/Add";
import { useHistory } from "react-router";
import TabsCustom from "../../pages/chatSettings/components/TabsCustom";
import { useAttendance } from "./contexts/AttendanceContext";
import { PulseIcon } from "./styles/AttendanceStyle";

import {
  IconButtonStyled,
  LightTooltip,
  Margin,
  Title,
} from "../../styles/GlobalStyle";
import BodySearch from "./childrens/BodySearch";

export default function SearchAttendance() {
  const { setCurrentAttendanceState, page, setPage, loading } = useAttendance();

  const { push } = useHistory();

  const tabsSearchAttendance = [
    {
      label: "Atendimentos",
      content: <BodySearch />,
      value: 0,
    },
    //TODO: Falta subir o backend
    // {
    //   label: "Suporte Qyon",
    //   content: <SupportServiceWrapper />,
    //   value: 1,
    // },
  ];

  return (
    <div style={{ paddingRight: 30 }}>
      <Grid container>
        <Grid
          item
          container
          justifyContent="space-between"
          alignItems="flex-end"
        >
          <Grid item flex={1}>
            <Title color="blue">{"Gestão de Atendimentos"}</Title>
          </Grid>
          {page === 0 && (
            <Grid item flex={1}>
              <PulseIcon>
                <LightTooltip title="Novo Atendimento" placement="left">
                  <IconButtonStyled
                    onClick={() => {
                      setCurrentAttendanceState(null);
                      !loading && push("insert");
                    }}
                  >
                    {!loading ? (
                      <AddIcon />
                    ) : (
                      <CircularProgress color="#fff" size={24} />
                    )}
                  </IconButtonStyled>
                </LightTooltip>
              </PulseIcon>
            </Grid>
          )}

          <Margin top="8" bottom="10" />
          <TabsCustom
            items={tabsSearchAttendance}
            setPage={setPage}
            page={page}
          />
        </Grid>
      </Grid>
    </div>
  );
}
