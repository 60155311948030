import { useCallback, useContext } from "react";
import { useHistory } from "react-router";

import {
  getEntities,
  getWebsiteBySubdomain,
  postAccountantWebsite,
  putAccountantWebsite,
  putEntity,
} from "../../../repositories/panel/v1/EntityRepository";

import { GlobalContext } from "../../../contexts/GlobalContext";
import getBase64 from "../../../utils/getBase64";
import MaskService from "../../../utils/mask/maskService";
import { notify } from "../../../utils/notify";
import { usePermissions } from "../../../utils/permissionsHook";
import { HomeContext } from "../contexts/HomeContext";

import { GlobalVariables } from "../../../GlobalVariables";
import { usePortalAsClient } from "../../../utils/viewPortalAsClientHook";

export default function useHomeController() {
  const {
    setLoading,
    setEditEnable,
    websiteContent,
    setWebsiteContent,
    landTheme,
    setLandTheme,
    setSaving,
    websiteType,
    setWebsiteType,
    ownWebsitUrl,
    setOwnWebsitUrl,
    title,
    setTitle,
    subtitle,
    setSubtitle,
    aboutUsImage,
    setAboutUsImage,
    aboutUsText,
    setAboutUsText,
    services,
    setServices,
    videoUrl,
    setVideoUrl,
    videoText,
    setVideoText,
    setStreet,
    setNumber,
    setNeighborhood,
    setCity,
    setState,
    setCep,
    setPhone,
    setEmail,
    facebook,
    setFacebook,
    instagram,
    setInstagram,
    linkedin,
    setLinkedin,
    whatsapp,
    setWhatsapp,
    googleMaps,
    setGoogleMaps,
  } = useContext(HomeContext);

  const { companyUser } = useContext(GlobalContext);
  const { checkIfUserHasPermission } = usePermissions();
  const { isInClientView, clientViewed } = usePortalAsClient();

  const { push } = useHistory();

  const hasWebsite = useCallback(
    (subdomainInfo) => {
      // não tem website -> exibe default
      // tem website qyon -> exibe configurado
      if (
        !subdomainInfo?.website_exhibition_type ||
        subdomainInfo.website_exhibition_type === "qyon"
      ) {
        return;
      }

      // está na tela de edição do website e é o master logado
      const routeCurrent = window.location.href.split("/");
      if (
        routeCurrent[routeCurrent.length - 1].toLocaleLowerCase() ===
          "edithome" &&
        checkIfUserHasPermission(GlobalVariables.PERMISSIONS.SETTINGS_COMPANY)
      ) {
        return;
      }

      //não tem website
      push("/login");
    },
    [checkIfUserHasPermission, push]
  );

  const cacheWebsiteContent = (subdomainInfo) => {
    sessionStorage.setItem(
      "website_exhibition_type",
      subdomainInfo.website_exhibition_type || "qyon"
    );

    sessionStorage.setItem(
      "own_website_url",
      subdomainInfo.own_website_url || ""
    );

    sessionStorage.setItem(
      "promo_video_url",
      subdomainInfo.promo_video_url || ""
    );

    if (subdomainInfo.logo_url) {
      sessionStorage.setItem("logoLogin", subdomainInfo.logo_url);
    }
  };

  const getWebsiteInfo = useCallback(async () => {
    let subdomain =
      sessionStorage.getItem("hostPanel") ||
      window.location.host.substring(
        0,
        window.location.host.indexOf(".qyon.")
      ) ||
      window.location.host;
    /*
    let companyData = await getEntities({
      id: companyUser?.companyId,
    });

    let subdomain;

    if (companyData?.success) {
      subdomain = companyData?.data?.subdomain || window.location.host;
    } else {
      subdomain =
        sessionStorage.getItem("hostPanel") ||
        window.location.host.substring(
          0,
          window.location.host.indexOf(".qyon.")
        ) ||
        window.location.host;
    }
    */

    let subdomainInfo = {};

    if (
      ["portal", "portal1", "portal2", "portal3", "localhost:3000"].includes(
        subdomain
      )
    ) {
      /*
      notify(
        "Não foi possível encontrar o subdomínio. Configure-o através de configurações > Dados Cadastrais > Endereço de Acesso ao Portal."
      );
      
      push("/setting");
      */
      push("/login");

      return;
    }

    let subdomainCallback = await getWebsiteBySubdomain(subdomain);
    subdomainInfo =
      subdomainCallback?.success && subdomainCallback.data
        ? subdomainCallback.data
        : {};

    hasWebsite(subdomainInfo);
    cacheWebsiteContent(subdomainInfo);

    return subdomainInfo.website;
  }, [hasWebsite, push]);

  const populateContext = useCallback(async () => {
    try {
      setLoading(true);

      let content = await getWebsiteInfo();

      setWebsiteContent(content);

      setWebsiteType(sessionStorage.getItem("website_exhibition_type"));
      setOwnWebsitUrl(sessionStorage.getItem("own_website_url"));

      if (content.website_color_theme) {
        setLandTheme(content.website_color_theme);
      }

      let fields = content.fields ? JSON.parse(content.fields) : {};

      setTitle(fields.title || companyUser.tradingName);
      fields.subtitle && setSubtitle(fields.subtitle);

      fields.aboutUs && setAboutUsText(fields.aboutUs);
      fields.aboutUsImage && setAboutUsImage(fields.aboutUsImage);

      content.service_provided &&
        content.service_provided.length > 0 &&
        setServices(content.service_provided);

      sessionStorage.getItem("promo_video_url") &&
        setVideoUrl(sessionStorage.getItem("promo_video_url"));

      fields.videoText && setVideoText(fields.videoText);

      if (content.contact_data) {
        if (content.contact_data.address) {
          setStreet(content.contact_data.address.street || "");
          setNumber(content.contact_data.address.address_number || "");
          setNeighborhood(content.contact_data.address.district || "");
          setCity(content.contact_data.address.city.name || "");
          setState(content.contact_data.address.state.uf || "");
          setCep(
            MaskService.toMask(
              "cep",
              content.contact_data.address.postal_code || ""
            )
          );
        }

        setPhone(
          content.contact_data.phone
            ? MaskService.toMask("cel-phone", content.contact_data.phone)
            : ""
        );

        setEmail(content.contact_data.email || "");
      }

      if (Array.isArray(content.social_media)) {
        setFacebook(
          content.social_media.filter((social) => social.id === 1)[0]?.pivot
            ?.reference || ""
        );

        setInstagram(
          content.social_media.filter((social) => social.id === 2)[0]?.pivot
            ?.reference || ""
        );

        setLinkedin(
          content.social_media.filter((social) => social.id === 3)[0]?.pivot
            ?.reference || ""
        );

        setWhatsapp(
          content.social_media.filter((social) => social.id === 4)[0]?.pivot
            ?.reference || ""
        );

        setGoogleMaps(
          content.social_media.filter((social) => social.id === 5)[0]?.pivot
            ?.reference || ""
        );
      }

      setEditEnable(
        window.location.href.toLocaleLowerCase().includes("/edithome")
      );
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, [
    setLoading,
    getWebsiteInfo,
    setWebsiteContent,
    setWebsiteType,
    setOwnWebsitUrl,
    setTitle,
    companyUser.tradingName,
    setSubtitle,
    setAboutUsText,
    setAboutUsImage,
    setServices,
    setVideoUrl,
    setVideoText,
    setEditEnable,
    setLandTheme,
    setPhone,
    setEmail,
    setStreet,
    setNumber,
    setNeighborhood,
    setCity,
    setState,
    setCep,
    setFacebook,
    setInstagram,
    setLinkedin,
    setWhatsapp,
    setGoogleMaps,
  ]);

  const saveWebsiteType = async (type = websiteType) => {
    const body = {
      company_name: companyUser.companyName,
      trading_name: companyUser.tradingName,
      website_exhibition_type: type || "qyon",
      own_website_url: type === "own" ? ownWebsitUrl : null,
      promo_video_url: videoUrl,
    };

    cacheWebsiteContent(body);

    try {
      setSaving(true);
      await putEntity({
        body,
        id: isInClientView ? clientViewed.company : companyUser.companyId,
      });
    } catch (error) {
      console.error(error);
    } finally {
      setSaving(false);
    }
  };

  const saveWebsite = async (theme = landTheme) => {
    let body = {
      website_theme_id: 1,
      website_color_theme_id: theme.id || landTheme.id || 5,
      fields: JSON.stringify({
        title: title,
        subtitle: subtitle,
        aboutUs: aboutUsText,
        aboutUsImage: aboutUsImage,
        videoText: videoText,
      }),
      ServiceProvided: services
        .filter((service) => service.description || service.id)
        .map((service) => {
          if (service.id && !service.description) {
            service.remove = true;
          }
          return service;
        }),
      EntitySocialMedia: [],
    };

    if (facebook) {
      body.EntitySocialMedia.push({
        social_media_id: 1,
        reference: facebook,
      });
    }
    if (instagram) {
      body.EntitySocialMedia.push({
        social_media_id: 2,
        reference: instagram,
      });
    }
    if (linkedin) {
      body.EntitySocialMedia.push({
        social_media_id: 3,
        reference: linkedin,
      });
    }
    if (whatsapp) {
      body.EntitySocialMedia.push({
        social_media_id: 4,
        reference: whatsapp.replace(/[^0-9]+/g, ""),
      });
    }
    if (googleMaps) {
      body.EntitySocialMedia.push({
        social_media_id: 5,
        reference: googleMaps,
      });
    }

    try {
      setSaving(true);
      if (!websiteContent.id) {
        let postCallback = await postAccountantWebsite(
          body,
          isInClientView ? clientViewed?.company : companyUser.companyId
        );
        if (postCallback.success) {
          setWebsiteContent((websiteContentPrev) => ({
            ...websiteContentPrev,
            id: postCallback.data.id,
          }));

          setServices(postCallback.data.ServiceProvided);
        }
      } else {
        let putCallback = await putAccountantWebsite(
          body,
          isInClientView ? clientViewed?.company : companyUser.companyId
        );
        if (putCallback.success) {
          setServices(
            putCallback.data.ServiceProvided.filter(
              (service) => typeof service === "object"
            )
          );
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setSaving(false);
    }
  };

  const uploadAboutUsImage = async (file) => {
    const body = {
      website_theme_id: 1,
      website_color_theme_id: landTheme.id || 5,
    };
    const imgB64 = await getBase64(file);
    if (imgB64.includes("base64")) {
      body.fields = JSON.stringify({ base64_aboutUsImage: imgB64 });
    }
    if (!body.fields) {
      return notify("Arquivo corrompido, insira uma imagem válida.");
    }
    const saveImageCallback = await putAccountantWebsite(
      body,
      isInClientView ? clientViewed?.company : companyUser.companyId
    );
    if (saveImageCallback?.success) {
      setAboutUsImage(JSON.parse(saveImageCallback.data.fields).aboutUsImage);
      notify("Imagem atualizada.", true, "success");
    }
  };

  const scrollTo = (id) => {
    document
      .getElementById(id)
      .scrollIntoView({ block: "start", behavior: "smooth" });
  };

  return {
    populateContext,
    scrollTo,
    saveWebsiteType,
    saveWebsite,
    uploadAboutUsImage,
  };
}
