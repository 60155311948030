import React from "react";
import Structure from "../childrens/Structure";

const ChatMonitor = () => {
  // TODO: Comentado para evolução na proxima SPRINT
  // const Customers = () => {
  //   const [arrayTeste, setArrayTeste] = useState([]);
  //   const listAttendantsCustomer = [
  //     ...new Map(
  //       arrayListCustomer.map((item) => [item["attendantId"], item])
  //     ).values(),
  //   ]
  //     .filter((attendant) => {
  //       if (!nameFilter) {
  //         return {
  //           attendantId: attendant.attendantId,
  //           byAttendant: attendant.byAttendant,
  //           accordionsState: true,
  //         };
  //       } else {
  //         if (
  //           attendant.byAttendant
  //             .toLowerCase()
  //             .includes(nameFilter.toLowerCase())
  //         )
  //           return {
  //             attendantId: attendant.attendantId,
  //             byAttendant: attendant.byAttendant,
  //             accordionsState: true,
  //           };
  //         return false;
  //       }
  //     })
  //     .map((attendant) => {
  //       // setChatMonitor({
  //       //   ...chatMonitor,
  //       //   accordionsState: listAttendantsCustomer,
  //       // });
  //       return {
  //         attendantId: attendant.attendantId,
  //         byAttendant: attendant.byAttendant,
  //         accordionsState: true,
  //       };
  //     });
  //   // setChatMonitor({ ...chatMonitor, accordionsState: listAttendantsCustomer });
  //   return (
  //     <GridStyled width="100%" padding="10px 0">
  //       <GridStyled width="100%" height="100%" container xs>
  //         {listAttendantsCustomer && listAttendantsCustomer.length ? (
  //           listAttendantsCustomer.map((attendant) => {
  //             return (
  //               <AccordionList
  //                 expanded={true}
  //                 titleCard={attendant.byAttendant}
  //                 key={attendant.attendantId}
  //               ></AccordionList>
  //             );
  //           })
  //         ) : (
  //           <GridStyled></GridStyled>
  //         )}
  //       </GridStyled>
  //     </GridStyled>
  //   );
  // };

  return (
    <>
      <Structure />
    </>
  );
};

export default ChatMonitor;
