import { Grid } from "@material-ui/core";

import moment from "moment";
import {
  BusinessIconStyled,
  CardContentStyled,
  ChipStyle,
  DivCnpjNotIdentified,
  DivStyle,
  GridContainer,
  GridEvents,
  GridSecondary,
  QuestionBotButton,
  SnackbarCFE,
  SnackbarCTE,
  SnackbarDoucmentCNPJError,
  SnackbarEvents,
  SnackbarMDFE,
  SnackbarNFE,
  SnackbarNFSE,
  SnackbarXMLNotIdentified,
  TextCardContainer,
  TextContainer,
  TextKey,
  TextValue,
  TypographyEvents,
  TypographyFontSize,
  TypographyOBS,
  TypographyQTD,
  TypographyStyled,
  WarningIconStyled,
} from "../styles/XmlModalCss";

import BotQuestionImage from "../../../assets/images/bot/question_bot.png";

function SubtypeXML(xml) {
  return xml["subtype"] === "input" ? "Entrada" : "Saída";
}

function formatCNPJorCPF(document) {
  return document.length === 14
    ? document.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5")
    : document.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
}

export const DocumentNFE = (xml, botContext, props, functionAI) => {
  return (
    <CardContentStyled>
      <TypographyStyled gutterBottom>
        <SnackbarNFE>{xml.data.xmlType}</SnackbarNFE>

        <TextContainer>
          <TextKey>Subtipo:</TextKey>
          <TextValue>{SubtypeXML(xml)}</TextValue>
        </TextContainer>

        <TextContainer>
          <TextKey>Arquivo:</TextKey>
          <TextValue>
            {xml.name}
            <br></br>
            {xml.clientFromAccountant != null && (
              <div>
                Cliente: {xml.clientFromAccountant.company.company_name}
              </div>
            )}
          </TextValue>
        </TextContainer>

        <TextContainer>
          <TextKey>Chave de Acesso:</TextKey>
          <TextValue>{xml.data.info.ch}</TextValue>
        </TextContainer>
      </TypographyStyled>

      <TextCardContainer>
        <TextKey>Emitente:</TextKey>
        <TextValue>{xml.data.emit.name}</TextValue>
        <TextContainer>
          <TextKey>CNPJ:</TextKey>
          <TextValue>{formatCNPJorCPF(xml.data.emit.document)}</TextValue>
        </TextContainer>
      </TextCardContainer>

      <TextCardContainer>
        <TextKey>Destinatário:</TextKey>
        <TextValue>{xml.data.dest.name}</TextValue>
        <TextContainer>
          <TextKey>CNPJ:</TextKey>
          <TextValue>{formatCNPJorCPF(xml.data.dest.document)}</TextValue>
        </TextContainer>
      </TextCardContainer>

      {/*
        <QuestionBotButton
          onClick={() => {
            functionAI(xml, props, botContext);
          }}
        >
          <span style={{ paddingRight: 10 }}>Perguntar para</span>
          <img
            src={BotQuestionImage}
            alt="Bot Question"
            style={{ width: 100 }}
          />
        </QuestionBotButton>
        */}
    </CardContentStyled>
  );
};

export const DocumentNFSE = (xml, botContext, props, functionAI) => {
  return (
    <CardContentStyled>
      <TypographyStyled gutterBottom>
        <SnackbarNFSE>{xml.data.xmlType}</SnackbarNFSE>
        <ChipStyle label={"Dados da 1º NFS-e"} variant="outlined" />

        <TextContainer>
          <TextKey>Subtipo:</TextKey>
          <TextValue>{SubtypeXML(xml)}</TextValue>
        </TextContainer>

        <TextContainer>
          <TextKey>Arquivo:</TextKey>
          <TextValue>
            {xml.name}
            <br></br>
            {xml.clientFromAccountant != null && (
              <div>
                Cliente: {xml.clientFromAccountant.company.company_name}
              </div>
            )}
          </TextValue>
        </TextContainer>
        {xml.data.qtd && (
          <TextContainer>
            <TextKey>Quantidade:</TextKey>
            <TextValue>{xml.data.qtd}</TextValue>
          </TextContainer>
        )}
      </TypographyStyled>

      <TextCardContainer>
        <TextKey>Emitente:</TextKey>
        <TextValue>{xml.data.prest.name}</TextValue>
        <TextContainer>
          <TextKey>CNPJ:</TextKey>
          <TextValue>{formatCNPJorCPF(xml.data.prest.document)}</TextValue>
        </TextContainer>
      </TextCardContainer>

      <TextCardContainer>
        <TextKey>Destinatário:</TextKey>
        <TextValue>{xml.data.toma.name}</TextValue>
        <TextContainer>
          <TextKey>CNPJ:</TextKey>
          <TextValue>{formatCNPJorCPF(xml.data.toma.document)}</TextValue>
        </TextContainer>
      </TextCardContainer>

      {/*
        <QuestionBotButton
          onClick={() => {
            functionAI(xml, props, botContext);
          }}
        >
          <span style={{ paddingRight: 10 }}>Perguntar para</span>
          <img
            src={BotQuestionImage}
            alt="Bot Question"
            style={{ width: 100 }}
          />
        </QuestionBotButton>
        */}
    </CardContentStyled>
  );
};

export const DocumentCTE = (xml, botContext, props, functionAI) => {
  return (
    <CardContentStyled>
      <TypographyStyled gutterBottom>
        <SnackbarCTE>{xml.data.xmlType}</SnackbarCTE>

        <TextContainer>
          <TextKey>Subtipo:</TextKey>
          <TextValue>{SubtypeXML(xml)}</TextValue>
        </TextContainer>

        <TextContainer>
          <TextKey>Arquivo:</TextKey>
          <TextValue>
            {xml.name}
            <br></br>
            {xml.clientFromAccountant != null && (
              <div>
                Cliente: {xml.clientFromAccountant.company.company_name}
              </div>
            )}
          </TextValue>
        </TextContainer>

        <TextContainer>
          <TextKey>Chave de Acesso:</TextKey>
          <TextValue>{xml.data.info.ch}</TextValue>
        </TextContainer>
      </TypographyStyled>

      <TextCardContainer>
        <TextKey>Emitente:</TextKey>
        <TextValue>{xml.data.emit.name}</TextValue>
        <TextContainer>
          <TextKey>CNPJ:</TextKey>
          <TextValue>{formatCNPJorCPF(xml.data.emit.document)}</TextValue>
        </TextContainer>
      </TextCardContainer>

      <TextCardContainer>
        <TextKey>Destinatário:</TextKey>
        <TextValue>{xml.data.dest.name}</TextValue>
        <TextContainer>
          <TextKey>CNPJ:</TextKey>
          <TextValue>{formatCNPJorCPF(xml.data.dest.document)}</TextValue>
        </TextContainer>
      </TextCardContainer>

      {/*
        <QuestionBotButton
          onClick={() => {
            functionAI(xml, props, botContext);
          }}
        >
          <span style={{ paddingRight: 10 }}>Perguntar para</span>
          <img
            src={BotQuestionImage}
            alt="Bot Question"
            style={{ width: 100 }}
          />
        </QuestionBotButton>
        */}
    </CardContentStyled>
  );
};

export const DocumentMDFE = (xml, botContext, props, functionAI) => {
  return (
    <CardContentStyled>
      <TypographyStyled gutterBottom>
        <SnackbarMDFE>{xml.data.xmlType}</SnackbarMDFE>

        <TextContainer>
          <TextKey>Subtipo:</TextKey>
          <TextValue>{SubtypeXML(xml)}</TextValue>
        </TextContainer>

        <TextContainer>
          <TextKey>Arquivo:</TextKey>
          <TextValue>
            {xml.name}
            <br></br>
            {xml.clientFromAccountant != null && (
              <div>
                Cliente: {xml.clientFromAccountant.company.company_name}
              </div>
            )}
          </TextValue>
        </TextContainer>

        <TextContainer>
          <TextKey>Chave de Acesso:</TextKey>
          <TextValue>{xml.data.info.ch}</TextValue>
        </TextContainer>
      </TypographyStyled>

      <TextCardContainer>
        <TextKey>Emitente:</TextKey>
        <TextValue>{xml.data.emit.name}</TextValue>
        <TextContainer>
          <TextKey>CNPJ:</TextKey>
          <TextValue>{formatCNPJorCPF(xml.data.emit.document)}</TextValue>
        </TextContainer>
      </TextCardContainer>

      {/*
        <QuestionBotButton
          onClick={() => {
            functionAI(xml, props, botContext);
          }}
        >
          <span style={{ paddingRight: 10 }}>Perguntar para</span>
          <img
            src={BotQuestionImage}
            alt="Bot Question"
            style={{ width: 100 }}
          />
        </QuestionBotButton>
        */}
    </CardContentStyled>
  );
};

export const DocumentCFE = (xml, botContext, props, functionAI) => {
  return (
    <CardContentStyled>
      <TypographyStyled gutterBottom>
        <SnackbarCFE>{xml.data.xmlType}</SnackbarCFE>

        <TextContainer>
          <TextKey>Subtipo:</TextKey>
          <TextValue>{SubtypeXML(xml)}</TextValue>
        </TextContainer>

        <TextContainer>
          <TextKey>Arquivo:</TextKey>
          <TextValue>
            {xml.name}
            <br></br>
            {xml.clientFromAccountant != null && (
              <div>
                Cliente: {xml.clientFromAccountant.company.company_name}
              </div>
            )}
          </TextValue>
        </TextContainer>

        <TextContainer>
          <TextKey>Chave de Acesso:</TextKey>
          <TextValue>{xml.data.info.ch}</TextValue>
        </TextContainer>
      </TypographyStyled>

      <TextCardContainer>
        <TextKey>Destinatário:</TextKey>
        <TextValue>{xml.data.dest.name}</TextValue>
        <TextContainer>
          <TextKey>CNPJ:</TextKey>
          <TextValue>{formatCNPJorCPF(xml.data.dest.document)}</TextValue>
        </TextContainer>
      </TextCardContainer>

      {/*
        <QuestionBotButton
          onClick={() => {
            functionAI(xml, props, botContext);
          }}
        >
          <span style={{ paddingRight: 10 }}>Perguntar para</span>
          <img
            src={BotQuestionImage}
            alt="Bot Question"
            style={{ width: 100 }}
          />
        </QuestionBotButton>
        */}
    </CardContentStyled>
  );
};

export const Events = (xml, botContext, props, functionAI) => {
  const date = moment(xml.data.info.date).format("DD/MM/YYYY");

  function message() {
    if (xml.data.xmlType === "EventoNFe") {
      return "Evento NFe";
    }
    if (xml.data.xmlType === "EventoCTe") {
      return "Evento CTe";
    }
    if (xml.data.xmlType === "EventoMDFe") {
      return "Evento MDFe";
    }
  }
  return (
    <CardContentStyled>
      <TypographyStyled gutterBottom>
        <SnackbarEvents>{message()}</SnackbarEvents>
        <TextContainer>
          <TextKey>Arquivo:</TextKey>
          <TextValue>
            {xml.name}
            <br></br>
            {xml.clientFromAccountant != null && (
              <div>
                Cliente: {xml.clientFromAccountant.company.company_name}
              </div>
            )}
          </TextValue>
        </TextContainer>
        <TextContainer>
          <TextKey>Chave de Acesso:</TextKey>
          <TextValue>{xml.data.info.ch}</TextValue>
        </TextContainer>
        <TextContainer>
          <TextKey>Data:</TextKey>
          <TextValue>{date}</TextValue>
        </TextContainer>
        <TextContainer>
          <TextKey>Descrição:</TextKey>
          <TextValue>{xml.data.info.eventTypeDesc}</TextValue>
        </TextContainer>
      </TypographyStyled>

      {/*
        <QuestionBotButton
          onClick={() => {
            functionAI(xml, props, botContext);
          }}
        >
          <span style={{ paddingRight: 10 }}>Perguntar para</span>
          <img
            src={BotQuestionImage}
            alt="Bot Question"
            style={{ width: 100 }}
          />
        </QuestionBotButton>
        */}
    </CardContentStyled>
  );
};

export const XMLNotIdentified = (xml, botContext, props, functionAI) => {
  return (
    <CardContentStyled>
      <TypographyStyled gutterBottom>
        <SnackbarXMLNotIdentified>XML Inválido</SnackbarXMLNotIdentified>

        <TextContainer>
          <TextKey>Arquivo:</TextKey>
          <TextValue>{xml.name}</TextValue>
        </TextContainer>
      </TypographyStyled>

      <GridContainer container>
        <Grid item xs={12}>
          <TypographyFontSize gutterBottom>
            Verifique se o XML é valido. Caso contrário, entre em contato
            conosco para mais informações!
          </TypographyFontSize>
        </Grid>
      </GridContainer>

      <TypographyOBS gutterBottom>
        <b>OBS: Esse tipo de XML não será enviado.</b>
      </TypographyOBS>
      {/*
        <QuestionBotButton
          onClick={() => {
            functionAI(xml, props, botContext);
          }}
        >
          <span style={{ paddingRight: 10 }}>Perguntar para</span>
          <img
            src={BotQuestionImage}
            alt="Bot Question"
            style={{ width: 100 }}
          />
        </QuestionBotButton>
        */}
    </CardContentStyled>
  );
};

export const DocumentErrorCNPJ = (xml, botContext, props, functionAI) => {
  return (
    <Grid container spacing={0}>
      <Grid item xs={12}>
        <CardContentStyled>
          <TypographyStyled gutterBottom>
            <SnackbarDoucmentCNPJError>
              Dados incorretos
            </SnackbarDoucmentCNPJError>
            <TextContainer>
              <TextKey>Arquivo:</TextKey>
              <TextValue>{xml.name}</TextValue>
            </TextContainer>
          </TypographyStyled>

          <GridContainer container>
            <DivCnpjNotIdentified>
              Atenção: O CNPJ do XML não condiz com o Escritório/Cliente.
            </DivCnpjNotIdentified>
          </GridContainer>
          <TypographyOBS gutterBottom>
            <b>OBS: Esse tipo de XML não será enviado.</b>
          </TypographyOBS>
        </CardContentStyled>
      </Grid>
      <DivStyle />
      {/*
        <QuestionBotButton
          onClick={() => {
            functionAI(xml, props, botContext);
          }}
        >
          <span style={{ paddingRight: 10 }}>Perguntar para</span>
          <img
            src={BotQuestionImage}
            alt="Bot Question"
            style={{ width: 100 }}
          />
        </QuestionBotButton>
        */}
    </Grid>
  );
};
