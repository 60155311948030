import { useContext, useEffect } from "react";

import { useHistory } from "react-router";
import { GlobalVariables } from "../../GlobalVariables";
import { usePermissions } from "../../utils/permissionsHook";

import { Grid } from "@material-ui/core";

import { GlobalContext } from "../../contexts/GlobalContext";
import { Margin } from "../../styles/GlobalStyle";
import { notify } from "../../utils/notify";
import CustomerManagementWithBPO from "./children/CustomerManagementWithBPO";
import { DefaultPageContainer } from "../../components/layoutContainer/styles/LayoutContainer";
import { GlobalSubheader } from "../../components/subHeader/GlobalSubheader";
// import AccordionCard from "../attendance/childrens/AccordionCard";

export default function CustomerManagement({ location }) {
  const { companyUser, setBreadcrumbs } = useContext(GlobalContext);
  const { push } = useHistory();
  const { checkIfUserHasPermission } = usePermissions();

  useEffect(() => {
    if (companyUser.isAccountant !== "true") {
      return push("/dashboard/view");
    }
  }, [companyUser.isAccountant, push]);

  const handleClickAdd = () => {
    checkIfUserHasPermission(GlobalVariables.PERMISSIONS.CLIENTES_MANAGEMENT)
      ? push("create")
      : notify(
          `Acesso negado! Permissão 'Menus - Gestão de clientes - Gerenciamento' necessária`
        );
  };

  useEffect(() => {
    setBreadcrumbs([]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {/* ADD NEW GLOBAL SUBHEADER */}
      <GlobalSubheader title={"Gestão de Clientes"}>
        {/* TODO: add Qyon Bot Copilot*/}
      </GlobalSubheader>
      <DefaultPageContainer
        style={{
          height: "calc(100% - 60px)",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <CustomerManagementWithBPO handleClickAdd={handleClickAdd} />
      </DefaultPageContainer>
    </>
  );
}
