import { AppBar, Grid, IconButton } from "@material-ui/core";
import styled, { css } from "styled-components";

export const CompanyLogo = styled.img`
  max-height: 50px;
  border-width: 2px;
  border-style: solid;
  border-radius: 5px 5px 5px 5px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.16);
  border-color: #ffffff;
`;

export const CompanyLogoDefault = styled.div`
  height: 50px;
  width: 50px;
  border-width: 2px;
  border-style: solid;
  border-color: #fff;
  border-radius: 5px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.16);
  font-size: 20px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #0a73db;
  color: #fff;
`;

export const StyledBar = styled.div`
  background: ${(props) =>
    props.type === "accountant"
      ? "#0a73db"
      : props.type === "standalone"
      ? "#ff5722"
      : "#ffffff"};
  height: ${(props) => props.height}px;
  width: ${(props) => props.width}px;
  margin: 0 10px 0 0px;
  border-radius: 5px 5px 5px 5px;
`;

export const StyledHeader = styled(Grid)`
  background: ${(props) => props.theme.palette.primary};
  min-height: 65px;
  padding: 0 20px 0 20px;
  width: 100%;
`;

//z-index: 1201
export const StyledAppBar = styled(AppBar)`
  background: ${(props) => props.theme.palette.primary};
  z-index: 1201;
`;

export const TitleSystem = styled.div`
  color: ${(props) => props.theme.palette.white};
  font-size: 20px;
  font-family: "Roboto", sans-serif !important;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  ${(props) =>
    props.bold &&
    css`
      font-weight: bold;
    `}
`;

export const IconButtonHeader = styled(IconButton)`
  height: 40px;
  width: 40px;
  color: #fff;
`;

export const StyledLogoEmpresa = styled.img`
  max-height: 50px;
  border-width: 2px;
  border-style: solid;
  border-color: #fff;
  border-radius: 5px;
`;

export const HeaderOption = styled.span`
  display: flex;
  flex-direction: row-reverse;
  @media (max-width: 600px) {
    display: none;
  }
`;

export const HamburguerLayout = styled.span`
  position: absolute;
  bottom: 10px;
  @media (min-width: 600px) {
    display: none;
  }
`;
