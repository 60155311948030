import { useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router";
import Grid from "@material-ui/core/Grid";
import { LabelStyled } from "../../styles/GlobalStyle";
import { notify } from "../../utils/notify";
import IconsMenu from "../sidebar/IconsMenu";
import {
  Beta,
  BetaBadge,
  IconContainer,
  LabelStyledSubMenu,
  ListItemCustom,
  ListItemIconCustom,
  SideLabel,
} from "../sidebar/styles/Sidebar";
import {
  ExpandLessStyled,
  ExpandMoreStyled,
  BetaChip,
} from "../sidebarDrawer/styles/SidebarDrawer";
import { TooltipContainer } from "./TooltipContainer";

export const MenuItem = ({
  sidebar,
  companyAccess,
  label,
  beta,
  icon: Icon,
  path,
  pathExternal,
  SubMenu,
  functionMenu,
  baseUrl,
  forceRedirect,
  blur,
  optionSubMenus,
  standard,
  customTooltip = false,
  value,
  hasASubMenu,
  isBeta,
  setSidebar,
}) => {
  const location = useLocation();

  const { push } = useHistory();
  const [dropDownMenu, setDropDownMenu] = useState(true);

  const handleRedirect = (path, pathExternal) => {
    if (path) {
      push(path);
    } else if (pathExternal) {
      window.open(pathExternal, "_blank");
    }
  };

  useEffect(() => {
    if (!sidebar.open) {
      setDropDownMenu(false);
    }
  }, [sidebar.open]);

  const clickDropdownMenu = (baseUrl, pathExternal, path) => {
    if (!dropDownMenu) {
      setSidebar((current) => ({ ...current, open: true }));
    }

    setDropDownMenu(!dropDownMenu);
  };

  const ableToAccess = (companyAccess || standard || forceRedirect) && !blur;

  const clickMenu = location.pathname === path;

  return (
    <>
      <div>
        <TooltipContainer
          key={label}
          title={customTooltip ? customTooltip : label}
          sidebar={sidebar}
          customTooltip={!!customTooltip}
          beta={beta}
        >
          <div style={{ marginLeft: 5, marginRight: 5 }}>
            <ListItemCustom
              style={{
                backgroundColor: /*clickMenu && ableToAccess ? "#0c2d6a" :*/ "",
                color:
                  /*clickMenu && ableToAccess
                    ? "#fff"
                    :*/ !value.hasPermission && ableToAccess
                    ? "#424c5d"
                    : "#caced1",
              }}
              button
              onClick={() => {
                if (
                  value === "CRM" ||
                  value === "ACCOUNTING" ||
                  value === "RADAR" ||
                  value === "CLIENT_MANAGEMENT"
                ) {
                  clickDropdownMenu(baseUrl, pathExternal, path);
                } else {
                  if (functionMenu) {
                    functionMenu(`${baseUrl}${pathExternal}`);
                    return;
                  }
                  if (ableToAccess) {
                    if (forceRedirect) handleRedirect(path, `${pathExternal}`);
                    else
                      handleRedirect(path, `${baseUrl || ""}${pathExternal}`);
                  } else
                    return notify(
                      !customTooltip
                        ? "Entre em contato com o nosso comercial: 0800 591 0486."
                        : "Acesso não liberado."
                    );
                }
              }}
              active={ableToAccess}
            >
              <ListItemIconCustom
                style={{
                  width: sidebar.open ? "auto" : "100%",
                }}
              >
                <IconContainer
                  item
                  container
                  justifyContent="center"
                  style={{ padding: !sidebar.open ? 10 : 0 }}
                >
                  <IconsMenu
                    color={ableToAccess ? "#063bb5" : "#c3c8cb"}
                    icon={Icon}
                  />
                  {!sidebar.open && beta && <BetaBadge children="BETA" />}
                </IconContainer>
              </ListItemIconCustom>

              {sidebar.open && (
                <SideLabel
                  primary={
                    <Grid container alignItems="center">
                      <Grid item flex={1}>
                        <Beta
                          color="secondary"
                          badgeContent={beta && "HOMOLOGAÇÃO"}
                        >
                          <LabelStyled fontSize="13" cursor="pointer">
                            {label}
                          </LabelStyled>
                        </Beta>
                      </Grid>
                    </Grid>
                  }
                />
              )}
              {isBeta && (
                <BetaChip
                  label="Beta"
                  style={{ position: "relative", left: 20 }}
                />
              )}

              {hasASubMenu &&
                (dropDownMenu ? (
                  <ExpandLessStyled clickMenu={clickMenu} />
                ) : (
                  <ExpandMoreStyled clickMenu={clickMenu} />
                ))}
            </ListItemCustom>
          </div>
        </TooltipContainer>

        {value === "CRM" &&
          optionSubMenus["CRM"].map((value) => (
            <TooltipContainer
              key={value.label}
              title={value.label}
              sidebar={sidebar}
            >
              <ListItemCustom
                button
                style={{
                  marginLeft: 0,
                  padding: "1px 0px 1px 0px",
                  marginBottom: 5,
                  display: dropDownMenu ? "block" : "none",
                }}
                disabled={value.disabled}
                onClick={() => {
                  if (functionMenu) {
                    functionMenu(`${baseUrl}${value.pathExternalSubMenu}`);
                    return;
                  }

                  if ((companyAccess && baseUrl) || value.pathSubMenu) {
                    handleRedirect(
                      value.pathSubMenu,
                      `${baseUrl}${value.pathExternalSubMenu}`
                    );
                    // if(value.subMenu) contextAttendance.setCurrentScreenState(value.subMenu)
                  } else {
                    notify(
                      !companyAccess
                        ? "Entre em contato com o nosso comercial: 0800 591 0486."
                        : "Acesso não liberado."
                    );
                  }
                }}
              >
                <SideLabel
                  style={{ marginLeft: 61 }}
                  primary={
                    <Grid
                      container
                      alignItems="center"
                      justifyContent={!sidebar.open && "center"}
                    >
                      <Grid item flex={1}>
                        <LabelStyled
                          fontSize="13"
                          style={{
                            color:
                              (companyAccess && baseUrl) ||
                              value.pathSubMenu ||
                              value.pathExternalSubMenu
                                ? "#424c5d"
                                : "#c3c8cb",
                          }}
                          cursor="pointer"
                        >
                          <Grid item container justifyContent="center">
                            {sidebar.open ? value.label : ""}
                          </Grid>
                        </LabelStyled>
                      </Grid>
                    </Grid>
                  }
                />
              </ListItemCustom>
            </TooltipContainer>
          ))}

        {value === "ACCOUNTING" &&
          optionSubMenus["ACCOUNTING"].map((value) => (
            <TooltipContainer
              key={value.label}
              title={value.customTooltip ? value.customTooltip : value.label}
              sidebar={value.sidebar}
              style={{ display: sidebar.open ? "block" : "none" }}
              customTooltip={!!value.customTooltip}
              beta={value.beta}
            >
              <ListItemCustom
                style={{
                  marginLeft: 0,
                  padding: "1px 0px 1px 0px",
                  marginBottom: 5,
                  display: value.hide ? "none" : dropDownMenu ? "flex" : "none",
                }}
                button
                onClick={() => {
                  if (value.companyAccess) {
                    if (value.forceRedirect) {
                      value.functionMenu(
                        `${value.baseUrl}${value.pathExternal}`
                      );
                    } else {
                      value.functionMenu(
                        `${value.baseUrl}${value.pathExternal}`
                      );
                    }
                  } else {
                    return notify(
                      !value.customTooltip
                        ? "Entre em contato com o nosso comercial: 0800 591 0486."
                        : "Acesso não liberado."
                    );
                  }
                }}
                active={ableToAccess}
              >
                <SideLabel
                  style={{
                    display: sidebar.open ? "flex" : "none",
                    marginLeft: 61,
                  }}
                  primary={
                    <Grid container alignItems="center">
                      <Grid item flex={1}>
                        <Beta
                          color="secondary"
                          badgeContent={value.beta && "HOMOLOGAÇÃO"}
                        >
                          <LabelStyledSubMenu
                            fontSize="13"
                            cursor="pointer"
                            color={value.companyAccess ? "#424c5d" : "#c3c8cb"}
                          >
                            {value.label}
                          </LabelStyledSubMenu>
                        </Beta>
                      </Grid>
                    </Grid>
                  }
                />

                {value.isBeta && <BetaChip label="Beta" />}
              </ListItemCustom>
            </TooltipContainer>
          ))}

        {value === "RADAR" &&
          optionSubMenus["RADAR"].map((value) => (
            <TooltipContainer
              key={value.label}
              title={value.customTooltip ? value.customTooltip : value.label}
              sidebar={value.sidebar}
              style={{ display: sidebar.open ? "block" : "none" }}
              customTooltip={!!value.customTooltip}
              beta={value.beta}
            >
              <ListItemCustom
                style={{
                  marginLeft: 0,
                  padding: "1px 0px 1px 0px",
                  marginBottom: 5,
                  display: value.hide ? "none" : dropDownMenu ? "flex" : "none",
                }}
                button
                onClick={() => {
                  if (value.hasPermission) {
                    value.functionMenu();
                  } else {
                    return notify(
                      !value.customTooltip
                        ? "Entre em contato com o nosso comercial: 0800 591 0486."
                        : "Acesso não liberado."
                    );
                  }
                }}
              >
                <SideLabel
                  style={{
                    display: sidebar.open ? "flex" : "none",
                    marginLeft: 61,
                  }}
                  primary={
                    <Grid container alignItems="center">
                      <Grid item flex={1}>
                        <Beta
                          color="secondary"
                          badgeContent={value.beta && "HOMOLOGAÇÃO"}
                        >
                          <LabelStyledSubMenu
                            fontSize="13"
                            cursor="pointer"
                            color={!value.blur ? "#424c5d" : "#c3c8cb"}
                          >
                            {value.label}
                          </LabelStyledSubMenu>
                        </Beta>
                      </Grid>
                    </Grid>
                  }
                />

                {value.isBeta && <BetaChip label="Beta" />}
              </ListItemCustom>
            </TooltipContainer>
          ))}

        {value === "CLIENT_MANAGEMENT" &&
          optionSubMenus["CLIENT_MANAGEMENT"].map((value) => {
            return (
              <TooltipContainer
                key={value.label}
                title={value.customTooltip ? value.customTooltip : value.label}
                sidebar={value.sidebar}
                style={{ display: sidebar.open ? "block" : "none" }}
                customTooltip={!!value.customTooltip}
                beta={value.beta}
              >
                <ListItemCustom
                  style={{
                    marginLeft: 0,
                    padding: "1px 0px 1px 0px",
                    marginBottom: 5,
                    display: value.hide
                      ? "none"
                      : dropDownMenu
                      ? "flex"
                      : "none",
                  }}
                  button
                  onClick={() => {
                    if (ableToAccess) {
                      push(value.path);
                    } else
                      return notify(
                        !customTooltip
                          ? "Entre em contato com o nosso comercial: 0800 591 0486."
                          : "Acesso não liberado."
                      );
                  }}
                >
                  <SideLabel
                    style={{
                      display: sidebar.open ? "flex" : "none",
                      marginLeft: 61,
                    }}
                    primary={
                      <Grid container alignItems="center">
                        <Grid item flex={1}>
                          <Beta
                            color="secondary"
                            badgeContent={value.beta && "HOMOLOGAÇÃO"}
                          >
                            <LabelStyledSubMenu
                              fontSize="13"
                              cursor="pointer"
                              color={!value.blur ? "#424c5d" : "#c3c8cb"}
                            >
                              {value.label}
                            </LabelStyledSubMenu>
                          </Beta>
                        </Grid>
                      </Grid>
                    }
                  />

                  {value.isBeta && <BetaChip label="Beta" />}
                </ListItemCustom>
              </TooltipContainer>
            );
          })}
      </div>
    </>
  );
};
