import * as React from "react";

function ConfigureTableIcon(props) {
  return (
    <svg
      width={20}
      height={20}
      viewBox="0 0 18 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M2.854.057c.524 0 .949.424.949.948v6.553a.948.948 0 01-1.897 0V1.005c0-.524.425-.948.948-.948zM15.912 1.005a.948.948 0 10-1.897 0v6.553a.948.948 0 001.897 0V1.005zM7.765 19.131a.948.948 0 001.896 0v-5.553a.948.948 0 00-1.896 0v5.553zM8.712.057c.524 0 .949.424.949.948v7.243h.016a1.264 1.264 0 010 2.529H7.781a1.264 1.264 0 01-.017-2.529V1.005c0-.524.425-.948.948-.948zM14.016 19.132v-7.09h-.073a1.264 1.264 0 010-2.53h1.897a1.264 1.264 0 01.073 2.527v7.093a.948.948 0 01-1.897 0zM1.906 12.04a1.264 1.264 0 01.037-2.527H3.84a1.264 1.264 0 110 2.528h-.037v7.09a.948.948 0 01-1.897 0v-7.09z"
        fill="#616161"
      />
    </svg>
  );
}

export default ConfigureTableIcon;
