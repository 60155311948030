import { useContext } from "react";
import { ManagementContext } from "../contexts/ManagementContext";
import { useManagementController } from "../controllers/ManagementController";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
} from "@material-ui/core";
import { Title } from "../../../styles/GlobalStyle";
import Btn from "../../../components/buttons/Btn";
import TextFieldErp from "../../../components/basics/TextFieldErp";

export const ModalCreateGroup = () => {
  const { openModalCreateGroup, groupSelected, setGroupSelected, errors } =
    useContext(ManagementContext);

  const { handleSaveGroup, handleCloseCreateGroup } = useManagementController();

  return (
    <Dialog
      open={openModalCreateGroup}
      maxWidth="sm"
      fullWidth
      aria-labelledby="dialog-create-permission-group"
      aria-describedby="dialog-create-permission-group"
    >
      <DialogTitle id="create-permission-group-title">
        <Title subtitle>
          {groupSelected.id
            ? "Edição de Grupo de Permissões"
            : "Criação de Grupo de Permissões"}
        </Title>
      </DialogTitle>

      <DialogContent>
        <Grid container spacing={2}>
          <Grid item md={12} xs={12}>
            <TextFieldErp
              label="Grupo"
              error={!!errors.name}
              helperText={errors.name || ""}
              value={groupSelected?.name || ""}
              onChange={(e) =>
                setGroupSelected((prev) => {
                  return { ...prev, name: e.target.value };
                })
              }
            />
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <Btn
          label="Cancelar"
          bg-color="tertiary"
          onClick={handleCloseCreateGroup}
        />
        <Btn label="Salvar" onClick={handleSaveGroup} />
      </DialogActions>
    </Dialog>
  );
};
