import { Route as RouteDom, Switch } from "react-router-dom";
import { HomeContextProvider } from "../../pages/default/contexts/HomeContext";
import { GlobalVariables } from "../../GlobalVariables";
import MiddlewareRoute from "../Middleware";

import Logo from "../../pages/default/Logo";
import Video from "../../pages/default/Video";
import Home from "../../pages/default/Home";

export default function Default() {
  return (
    <HomeContextProvider>
      <Switch>
        <RouteDom path="/logo" component={Logo} />
        <RouteDom path="/video" component={Video} />
        <RouteDom path="/home" component={Home} />
        <MiddlewareRoute
          routePermissionId={GlobalVariables.PERMISSIONS.SETTINGS_COMPANY}
          path="/edithome"
          component={Home}
        />
        <RouteDom exact path="/" component={Home} />
      </Switch>
    </HomeContextProvider>
  );
}
