import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  SvgIcon,
  TextField,
  Tooltip,
} from "@material-ui/core";
import { Add } from "@material-ui/icons";
import { ReactComponent as DeleteIcon } from "../../assets/icons/delete.svg";
import { ReactComponent as EditIcon } from "../../assets/icons/edit.svg";
import Dropzone from "react-dropzone";

import React, { useCallback, useContext, useEffect, useState } from "react";
import { ChipAddress } from "./styles/CustomerManagementStyle";
import PictureIcon from "../../assets/icons/picture.svg";
import { DataGrid } from "@material-ui/data-grid";
import { DropMessage } from "../dashboard/children/styles/UploadLogo";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import ImageOutlinedIcon from "@material-ui/icons/ImageOutlined";
import { GlobalContext } from "../../contexts/GlobalContext";
import { notify } from "../../utils/notify";
import getBase64 from "../../utils/getBase64";
// import { putEntity } from "../../repositories/panel/v1/EntityRepository";
// import { usePortalAsClient } from "../../utils/viewPortalAsClientHook";
import Loading from "../../components/basics/Loading";
import { CompanyLogo } from "../../pages/dashboard/components/styles/Subheader";
import { DefaultPageContainer } from "../../components/layoutContainer/styles/LayoutContainer";
import { GlobalSubheader } from "../../components/subHeader/GlobalSubheader";
import { ReactComponent as SaveCloudIcon } from "../../assets/icons/saving-cloud.svg";
import SelectErp from "../../components/basics/SelectErp";

import listCities from "../../utils/cities.json";
import listStates from "../../utils/states.json";
import listTaxRegime from "../../utils/tax-regimes.json";

import { DialogAddress } from "./children/DialogAddress";
import MaskService from "../../utils/mask/maskService";
import { DialogAddUser } from "./children/DialogAddUser";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

import {
  deleteAccountantClient,
  getAccountantClientDataByDocument,
  getAccountantClientDataById,
  registerAccountantClient,
  updateAccountantClientData,
} from "../../repositories/portal_api/AccountantClient";
import {
  getAddressDataInBigDataByCEP,
  getCompanyDataInBigDataByDocument,
} from "../../repositories/bot-microsservice/BotRepository";
import { DialogConfirm } from "./children/DialogConfirm";
import HelpIcon from "./children/HelpIcon";
import { validateCPF } from "../../utils/validations";
import { usePortalAsClient } from "../../utils/viewPortalAsClientHook";
import InfoIcon from "../../components/icons/InfoIcon";
import { DialogTXTPattern } from "./children/DialogTXTPattern";

export const CustomerManagementEdit = ({ location }) => {
  const { push } = useHistory();

  const { companyUser, setBreadcrumbs } = useContext(GlobalContext);
  const [loading, setLoading] = useState(false);

  const [companyName, setCompanyName] = useState("");
  const [companyFantasyName, setCompanyFantasyName] = useState("");
  const [companyDocument, setCompanyDocument] = useState("");
  const [companyEmail, setCompanyEmail] = useState("");
  const [companyPhone, setCompanyPhone] = useState("");
  const [companyTaxRegime, setCompanyTaxRegime] = useState("");
  const [companyStateRegistration, setCompanyStateRegistration] = useState("");
  const [companyMunicipalRegistration, setCompanyMunicipalRegistration] =
    useState("");
  const [companyMunicipalRegistrationCCM, setCompanyMunicipalRegistrationCCM] =
    useState("");

  const [logoClient, setLogoClient] = useState(null);

  const [addressList, setAddressList] = useState([]);

  const [openAddresDialog, setOpenAddresDialog] = useState(false);
  const [currentAddress, setCurrentAddress] = useState({});

  const [openUserDialog, setOpenUserDialog] = useState(false);

  const [errorDocument, setErrorDocument] = useState(false);
  const [errorName, setErrorName] = useState(false);
  const [errorFantasyName, setErrorFantasyName] = useState(false);

  const [hasIncompleteAddress, setHasIncompleteAddress] = useState(false);

  const [disabledCompanyDocument, setDisabledCompanyDocument] = useState(false);
  const [searchedDocument, setSearchedDocument] = useState("");
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  // const { isInClientView, clientViewed } = usePortalAsClient();
  const [openConfirmDelete, setOpenConfirmDelete] = useState(false);
  const [selectedAddresses, setSelectedAddresses] = useState([]);
  const [openDeleteAddressDialog, setOpenDeleteAddressDialog] = useState(false);
  const [openDeleteSelectedAddressDialog, setOpenDeleteSelectedAddressDialog] =
    useState(false);
  const [openConfirmCancel, setOpenConfirmCancel] = useState(false);
  const [isBlocking, setIsBlocking] = useState(true);

  const [blockedNavigation, setBlockedNavigation] = useState(null);
  const [requisitionType, setRequisitionType] = useState("POST");
  const [entityId, setEntityId] = useState(null);

  //USED ONLY IN CONFIGURATION
  const [documentResponsible, setDocumentResponsible] = useState("");
  const [errorDocumentResponsible, setErrorDocumentResponsible] =
    useState(false);
  const [subdomain, setSubdomain] = useState("");
  const [bpoActive, setBpoActive] = useState(false);

  const [gfPayer, setGfPayer] = useState("");

  const [openDialogTXTPattern, setOpenDialogTXTPattern] = useState(false);

  const { isInClientView } = usePortalAsClient();

  const history = useHistory();

  let columns = [
    {
      field: "street",
      headerName: "Endereço",
      flex: 1,
      renderCell: (params) => {
        return (
          <div style={{ padding: 10 }}>
            {params?.row?.street}, {params?.row?.address_number}
          </div>
        );
      },
    },
    {
      field: "city",
      headerName: "Município",
      flex: 1,
      renderCell: (params) => {
        const city = listCities.find(
          (value) => value?.id === params?.row?.city_id
        );
        const state = listStates.find(
          (value) => value?.id === params?.row?.state_id
        );

        return (
          <div style={{ padding: 10 }}>
            {city?.name}, {state?.uf}
          </div>
        );
      },
    },
    {
      field: "main_address",
      headerName: "Principal",
      flex: 0.5,
      renderCell: (params) => (
        <div style={{ padding: 10 }}>
          {params?.row?.main_address ? (
            <ChipAddress main label="Principal"></ChipAddress>
          ) : (
            <ChipAddress label="Secundário"></ChipAddress>
          )}
        </div>
      ),
    },
    {
      field: "postal_code",
      headerName: "CEP",
      flex: 0.5,
      renderCell: (params) => (
        <div style={{ padding: 10 }}>
          {params?.row?.postal_code.substring(0, 5)}-
          {params?.row?.postal_code.substring(5)}
        </div>
      ),
    },
    {
      field: "actions",
      headerName: "Ações",
      description: "This column has a value getter and is not sortable.",
      sortable: false,
      flex: 0.5,
      renderCell: (params) => (
        <>
          <IconButton
            size="small"
            onClick={() => {
              setCurrentAddress(params.row);
              setOpenAddresDialog(true);
            }}
          >
            <EditIcon></EditIcon>
          </IconButton>
          <IconButton
            size="small"
            onClick={(e) => {
              e.stopPropagation();
              setCurrentAddress(params.row);
              setOpenDeleteAddressDialog(true);
            }}
          >
            <DeleteIcon></DeleteIcon>
          </IconButton>
        </>
      ),
    },
  ];

  useEffect(() => {
    const unblock = history.block((location, action) => {
      if (isBlocking) {
        setIsBlocking(false);
        setBlockedNavigation({ location, action });
        setOpenConfirmCancel(true);
        return false;
      }
    });
    return unblock;
  }, [isBlocking, history]);

  useEffect(() => {
    if (location?.state?.customerId) {
      setLoading(true);
      setIsBlocking(false);

      setDisabledCompanyDocument(true);

      getAccountantClientDataById(location?.state?.customerId)
        .then((resp) => {
          if (resp?.success) {
            if (location.pathname === "/customermanagement/create") {
              setBreadcrumbs([
                {
                  name: "Configuração da Conta",
                  href: "",
                },
              ]);
            } else {
              setBreadcrumbs([
                {
                  name: resp?.data?.company_name,
                  href: "",
                },
                {
                  name: "Edição de Cliente",
                  href: "",
                },
              ]);
            }

            if (resp?.data?.document) {
              resp.data.document.length > 11
                ? setCompanyDocument(
                    MaskService.toMask("cnpj", resp?.data?.document, "")
                  )
                : setCompanyDocument(
                    MaskService.toMask("cpf", resp?.data?.document, "")
                  );
            }

            setCompanyName(resp?.data?.company_name);
            setCompanyFantasyName(resp?.data?.trading_name);
            setCompanyEmail(resp?.data?.email);
            if (resp?.data?.phone) {
              setCompanyPhone(
                MaskService.toMask("cel-phone", resp?.data?.phone, "")
              );
            }

            let actualList = listTaxRegime.filter(
              (taxRegime) => taxRegime.id === resp?.data?.tax_regime_id
            );

            if (
              actualList[0] !== null &&
              actualList[0] !== undefined &&
              actualList[0] !== ""
            ) {
              setCompanyTaxRegime(actualList[0]);
            }

            setCompanyStateRegistration(resp?.data?.ie);
            setCompanyMunicipalRegistration(resp?.data?.im);
            setCompanyMunicipalRegistrationCCM(resp?.data?.ccm);
            setLogoClient(resp?.data?.logo_url);
            setAddressList(resp?.data?.addresses);
            setSubdomain(resp?.data.subdomain);
            setBpoActive(resp?.data.bpo_active);
            setGfPayer(resp?.data.gf_payer);
            if (resp?.data.responsable_person_document) {
              setDocumentResponsible(
                MaskService.toMask(
                  "cpf",
                  resp?.data.responsable_person_document,
                  ""
                )
              );
            }
          } else {
            if (resp?.code === 403) {
              notify("Você não possui permissão para acessar este cliente.");
            } else {
              notify("Houve um erro ao carregar os dados do cliente.");
            }
            push("/customermanagement/list");
          }
        })
        .catch((err) => {
          console.log(err);
          push("/customermanagement/list");
          notify("Houve um erro ao carregar os dados do cliente.");
        })
        .finally(() => {
          setIsBlocking(true);
          setLoading(false);
        });
    } else {
      //TODO CREATE NEW CUSTOMER
      setIsBlocking(true);
      setBreadcrumbs([
        {
          name: "Cadastro de Cliente",
          href: "",
        },
      ]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    if (addressList.length > 0) {
      const incompleteAddress = addressList.find(
        (address) => address.incomplete === true
      );

      if (incompleteAddress) {
        setHasIncompleteAddress(true);
      } else {
        setHasIncompleteAddress(false);
      }
    }
  }, [addressList]);

  const validateFields = () => {
    if (!companyDocument) {
      return { valid: false, message: "O campo CPF/CNPJ é obrigatório." };
    }
    if (companyDocument.length === 0) {
      return { valid: false, message: "O campo CPF/CNPJ é obrigatório." };
    }
    if (!companyName) {
      return { valid: false, message: "O campo Razão Social é obrigatório." };
    }
    if (companyName.length === 0) {
      return { valid: false, message: "O campo Razão Social é obrigatório." };
    }
    if (!companyFantasyName) {
      return { valid: false, message: "O campo Nome Fantasia é obrigatório." };
    }
    if (companyFantasyName.length === 0) {
      return { valid: false, message: "O campo Nome Fantasia é obrigatório." };
    }
    if (errorDocument) {
      return { valid: false, message: "O campo CPF/CNPJ é obrigatório." };
    }
    if (errorFantasyName) {
      return { valid: false, message: "O campo Nome Fantasia é obrigatório." };
    }
    if (errorName) {
      return { valid: false, message: "O campo Razão Social é obrigatório." };
    }
    if (!addressList) {
      return {
        valid: false,
        message: "O cliente deve ter no mínimo um endereço cadastrado",
      };
    }
    if (addressList.length === 0) {
      return {
        valid: false,
        message: "O cliente deve ter no mínimo um endereço cadastrado",
      };
    }

    for (let i = 0; i < addressList.length; i++) {
      if (addressList[i]?.incomplete) {
        return {
          valid: false,
          message: "Preencha todos os campos do endereço",
        };
      }
    }

    const listSuportedDDDs = [
      "11",
      "12",
      "13",
      "14",
      "15",
      "16",
      "17",
      "18",
      "19",
      "21",
      "22",
      "24",
      "27",
      "28",
      "31",
      "32",
      "33",
      "34",
      "35",
      "37",
      "38",
      "41",
      "42",
      "43",
      "44",
      "45",
      "46",
      "47",
      "48",
      "49",
      "51",
      "53",
      "54",
      "55",
      "61",
      "62",
      "63",
      "64",
      "65",
      "66",
      "67",
      "68",
      "69",
      "71",
      "73",
      "74",
      "75",
      "77",
      "79",
      "81",
      "82",
      "83",
      "84",
      "85",
      "86",
      "87",
      "88",
      "89",
      "91",
      "92",
      "93",
      "94",
      "95",
      "96",
      "97",
      "98",
      "99",
    ];

    if (
      documentResponsible &&
      location.pathname === "/customermanagement/create"
    ) {
      if (!validateCPF(documentResponsible)) {
        return {
          valid: false,
          message: "CPF do responsável inválido",
        };
      }
    }

    if (companyPhone) {
      if (
        companyPhone.replace(/[^0-9]+/g, "").length < 10 ||
        companyPhone.replace(/[^0-9]+/g, "").length > 11 ||
        !listSuportedDDDs.includes(
          companyPhone.replace(/[^0-9]+/g, "").substring(0, 2)
        )
      ) {
        return {
          valid: false,
          message: "Telefone inválido",
        };
      }
    }

    return { valid: true, message: "Campos validados com sucesso!" };
  };

  const handleUpdateEntity = () => {
    const formattedAddresses = [];

    addressList.forEach((address) => {
      let formatted = {
        postal_code: address.postal_code.replace(/[^0-9]+/g, ""),
        address_complement: address.address_complement,
        address_number: address.address_number,
        city_id: address.city_id,
        country_id: address.country_id,
        district: address.district,
        main_address: address.main_address,
        state_id: address.state_id,
        street: address.street,
      };

      if (typeof address.id === "number") {
        formatted.id = address.id;
      }

      formattedAddresses.push(formatted);
    });

    let body = {
      company_name: companyName,
      trading_name: companyFantasyName,
      document: companyDocument.replace(/[^0-9]+/g, ""),
      email: companyEmail,
      phone: companyPhone.replace(/[^0-9]+/g, ""),
      entity_type:
        companyDocument.replace(/[^0-9]+/g, "").length <= 11
          ? "natural"
          : "legal",
      tax_regime_id: companyTaxRegime?.id,
      addresses: formattedAddresses,
      im: companyMunicipalRegistration,
      ie: companyStateRegistration,
      ccm: companyMunicipalRegistrationCCM,
      accountant_id: companyUser?.id,
      bpo_active: bpoActive,
      subdomain: subdomain,
      responsable_person_document: documentResponsible.replace(/[^0-9]+/g, ""),
    };

    if (logoClient) {
      if (!logoClient.includes("http")) {
        body.logo_base64 = logoClient;
      }
    }

    setLoading(true);
    setIsBlocking(false);

    let customerId = location?.state?.customerId || entityId;

    updateAccountantClientData(customerId, body)
      .then((resp) => {
        if (resp?.success) {
          notify("Cliente atualizado com sucesso!", true, "success");
          if (location.pathname === "/customermanagement/create") {
            push("/setting");
          } else {
            push("/customermanagement/list");
          }
        } else {
          if (resp?.code === 403) {
            notify("Você não possui permissão para editar este cliente.");
          } else {
            notify("Houve um erro ao editar o cliente.");
          }
        }
      })
      .catch((err) => {
        console.error(err);
        setIsBlocking(true);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleDeleteRowAddress = useCallback(
    (address) => {
      try {
        const newAddressList = addressList.filter(
          (addressItem) => addressItem.id !== address.id
        );

        if (
          newAddressList.filter((address) => address.main_address === true)
            .length === 0 &&
          newAddressList.length > 0
        ) {
          newAddressList[0].main_address = true;
        }

        setCurrentAddress({});

        setAddressList(newAddressList);
      } catch (err) {
        console.error(err);
      }
    },
    [addressList]
  );

  const handleDeleteSelectedAddress = useCallback(() => {
    try {
      const newAddressList = addressList.filter(
        (addressItem) => !selectedAddresses.includes(addressItem.id)
      );

      setCurrentAddress({});

      setAddressList(newAddressList);
    } catch (err) {
      console.error(err);
    }
  }, [addressList, selectedAddresses]);

  const handleRegisterEntity = (user) => {
    const formattedAddresses = [];

    addressList.forEach((address) => {
      let formatted = {
        postal_code: address.postal_code.replace(/[^0-9]+/g, ""),
        address_complement: address.address_complement,
        address_number: address.address_number,
        city_id: address.city_id,
        country_id: address.country_id,
        district: address.district,
        main_address: address.main_address,
        state_id: address.state_id,
        street: address.street,
      };

      if (typeof address.id === "number") {
        formatted.id = address.id;
      }

      formattedAddresses.push(formatted);
    });

    let body = {
      master_email: user.email,
      company_name: companyName,
      trading_name: companyFantasyName,
      document: companyDocument.replace(/[^0-9]+/g, ""),
      email: companyEmail,
      phone: companyPhone.replace(/[^0-9]+/g, ""),
      entity_type:
        companyDocument.replace(/[^0-9]+/g, "").length <= 11
          ? "natural"
          : "legal",
      tax_regime_id: companyTaxRegime?.id,
      addresses: formattedAddresses,
      subdomain: "",
      im: companyMunicipalRegistration,
      ie: companyStateRegistration,
      ccm: companyMunicipalRegistrationCCM,
      responsable_person_document: "",
      users: [
        {
          first_name: user.first_name,
          last_name: user.last_name,
          email: user.email,
          edit: true,
          permission_group_id: null,
        },
      ],
    };

    if (logoClient) {
      if (!logoClient.includes("http")) {
        body.logo_base64 = logoClient;
      }
    }

    setLoading(true);
    setIsBlocking(false);
    registerAccountantClient(body)
      .then((resp) => {
        if (resp?.success) {
          notify("Cliente cadastrado com sucesso!", true, "success");
          push("/customermanagement/list");
        } else {
          if (resp?.code === 403) {
            notify("Você não possui permissão para cadastrar este cliente");
          } else {
            notify("Houve um erro ao cadastrar o cliente.");
          }
        }
      })
      .catch((err) => {
        setIsBlocking(true);
        console.error(err);
        notify("Houve um erro ao cadastrar o cliente.");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleDeleteEntity = () => {
    if (location?.state?.customerId) {
      setLoading(true);
      setIsBlocking(false);

      deleteAccountantClient(location?.state?.customerId)
        .then((resp) => {
          if (resp?.success) {
            notify("Cliente excluído com sucesso!", true, "success");
            push("/customermanagement/list");
          } else {
            setIsBlocking(true);
          }
        })
        .catch((err) => {
          setIsBlocking(true);
          notify("Não foi possível excluir o cliente.");
          console.error(err);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      notify("Não foi possível excluir o cliente.");
    }
  };

  const handleUpload = async (files) => {
    try {
      const file = files[0];

      const imgB64 = await getBase64(file);

      if (imgB64.includes("base64")) {
        setLogoClient(imgB64);
      }

      if (!imgB64)
        return notify("Arquivo corrompido, insira uma imagem válida.");

      return;
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <DialogTXTPattern
        open={openDialogTXTPattern}
        handleClose={() => {
          setOpenDialogTXTPattern(false);
        }}
      />

      <DialogAddress
        open={openAddresDialog}
        address={currentAddress}
        changeAddress={setCurrentAddress}
        handleClose={() => {
          setCurrentAddress({});
          setOpenAddresDialog(false);
        }}
        handleSave={(newAddress) => {
          let updated = false;

          newAddress.incomplete = false;

          const newAddressLits = addressList.map((address) => {
            if (address.id === newAddress.id) {
              updated = true;
              return newAddress;
            }
            return address;
          });

          if (!updated) {
            newAddressLits.push(newAddress);
          }

          let mainAddressList = addressList.filter(
            (address) => address.main_address === true
          );

          if (mainAddressList.length > 0 && newAddress.main_address === true) {
            newAddressLits.map((address) => {
              if (address.id !== newAddress.id) {
                address.main_address = false;
              }
              return address;
            });
          }

          if (
            newAddressLits.filter((address) => address.main_address === true)
              .length === 0
          ) {
            return notify("É necessário incluir um endereço principal.");
          }

          setAddressList(newAddressLits);

          setCurrentAddress({});

          setOpenAddresDialog(false);
        }}
      />

      <DialogConfirm
        open={openConfirmDialog}
        title="Atualização de Dados"
        description={
          location?.state?.from === "settings" ? (
            <>Deseja atualizar os dados da conta?</>
          ) : (
            <>
              Deseja atualizar os dados do cliente <b>{companyName}</b>?
            </>
          )
        }
        handleClose={() => {
          setOpenConfirmDialog(false);
        }}
        handleConfirm={() => {
          handleUpdateEntity();
          setOpenConfirmDialog(false);
        }}
      />

      <DialogConfirm
        open={openConfirmDelete}
        title="Exclusão de Cliente"
        description={
          <>
            Deseja realmente <b style={{ color: "red" }}>excluir</b> o cliente{" "}
            <b>{companyName}</b>?
          </>
        }
        handleClose={() => {
          setOpenConfirmDelete(false);
        }}
        handleConfirm={() => {
          handleDeleteEntity();
          setOpenConfirmDelete(false);
        }}
      />

      <DialogConfirm
        open={openDeleteAddressDialog}
        title="Exclusão de Endereço"
        description={
          <>
            Deseja <b style={{ color: "red" }}>excluir</b> o endereço{" "}
            <b>
              {currentAddress.street}, {currentAddress.address_number}
            </b>
            ?
            <div style={{ fontSize: 12 }}>
              * A exclusão do endereço só será refletida ao salvar o cadastro de
              seu cliente
            </div>
          </>
        }
        handleClose={() => {
          setOpenDeleteAddressDialog(false);
        }}
        handleConfirm={() => {
          handleDeleteRowAddress(currentAddress);
          setOpenDeleteAddressDialog(false);
        }}
      />

      <DialogConfirm
        open={openDeleteSelectedAddressDialog}
        title="Exclusão de Endereços"
        description={
          <>
            Deseja <b style={{ color: "red" }}>excluir</b> todos os endereços
            selecionados?
            <div style={{ fontSize: 12 }}>
              * A exclusão do endereço só será refletida ao salvar o cadastro de
              seu cliente
            </div>
          </>
        }
        handleClose={() => {
          setOpenDeleteSelectedAddressDialog(false);
        }}
        handleConfirm={() => {
          handleDeleteSelectedAddress();
          setOpenDeleteSelectedAddressDialog(false);
        }}
      />

      <DialogConfirm
        open={openConfirmCancel}
        title="Cancelar"
        description={
          <>
            Deseja cancelar a operação?
            <div style={{ fontSize: 12 }}>
              * Os dados alterados e inseridos que não foram salvos serão
              perdidos
            </div>
          </>
        }
        handleClose={() => {
          setIsBlocking(true);
          setOpenConfirmCancel(false);
        }}
        handleConfirm={() => {
          setIsBlocking(false);
          setOpenConfirmCancel(false);
          if (blockedNavigation !== null) {
            if (blockedNavigation.action === "PUSH") {
              history.push(blockedNavigation.location);
            } else {
              history.replace(blockedNavigation.location);
            }
          }
        }}
      />
      <DialogAddUser
        open={openUserDialog}
        handleClose={() => {
          setOpenUserDialog(false);
        }}
        firstUser={true}
        handleSave={(user) => {
          if (requisitionType === "POST") {
            handleRegisterEntity(user);
          } else {
            handleUpdateEntity();
          }
        }}
        companyDocument={companyDocument}
      />
      <Loading open={loading} />
      <GlobalSubheader
        title={
          location.pathname === "/customermanagement/create"
            ? "Configurações"
            : "Gestão de Clientes"
        }
        titleLocation={
          location.pathname === "/customermanagement/create"
            ? "/setting"
            : "/customermanagement/list"
        }
      >
        <div style={{ gap: 5, display: "flex", justifyContent: "flex-end" }}>
          {location.pathname !== "/customermanagement/create" && (
            <Button
              style={{ textTransform: "none" }}
              disabled={location?.state?.customerId ? false : true}
              startIcon={
                <SvgIcon>
                  <DeleteIcon />
                </SvgIcon>
              }
              onClick={() => {
                setOpenConfirmDelete(true);
              }}
            >
              Excluir
            </Button>
          )}

          <Button
            style={{
              textTransform: "none",
            }}
            onClick={() => {
              if (location?.state?.customerId === companyUser?.companyId) {
                push("/setting");
              } else {
                push("/customermanagement/list");
              }
            }}
          >
            Cancelar
          </Button>
          <Button
            style={{
              textTransform: "none",
              background: "#0036b3",
              color: "#FFF",
            }}
            onClick={() => {
              const validationResult = validateFields();

              if (validationResult.valid) {
                if (location?.state?.customerId) {
                  setOpenConfirmDialog(true);
                } else {
                  setOpenUserDialog(true);
                }
              } else {
                notify(validationResult.message);
              }
            }}
            startIcon={
              <SvgIcon>
                <SaveCloudIcon />
              </SvgIcon>
            }
          >
            Salvar
          </Button>
        </div>
      </GlobalSubheader>
      <DefaultPageContainer>
        <div
          style={{
            background: "#fff",
            border: "1px solid #E0E0E0",
            borderRadius: 15,
            padding: 15,
          }}
        >
          {!location.state?.customerId && (
            <div
              style={{
                background: "#E3F2FD",
                color: "#03226A",
                borderRadius: 8,

                marginBottom: 8,
                padding: "10px 16px",
              }}
            >
              <div style={{ display: "flex" }}>
                <InfoIcon />
                <h3
                  style={{
                    color: "#0036B3",
                    marginTop: 0,
                    marginBottom: 0,
                    marginRight: 0,
                    marginLeft: 8,
                    padding: 0,
                  }}
                >
                  Info:
                </h3>
              </div>

              <div
                style={{
                  marginLeft: 8 + 24,
                  fontWeight: 400,
                  fontSize: 14,
                  color: "#0036B3",
                }}
              >
                Você pode realizar o cadastro simplificado ao arrastar o{" "}
                <b>Certificado Digital (.pfx)</b> de seu cliente no Qyon Bot ou
                arrastando um <b>arquivo de texto (.txt)</b> que siga este{" "}
                <b
                  style={{ textDecoration: "underline", cursor: "pointer" }}
                  onClick={() => {
                    setOpenDialogTXTPattern(true);
                  }}
                >
                  padrão
                </b>
              </div>
            </div>
          )}

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "15px",
              color: "#364461",
              fontWeight: "500",
            }}
          >
            <Grid container direction="row" spacing={1} alignItems="flex-end">
              <Grid item xs={4}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "flex-end",
                    justifyContent: "space-between",
                  }}
                >
                  CPF/CNPJ*{" "}
                  <div style={{ color: "red", fontSize: 10 }}>
                    {errorDocument ? "CPF/CNPJ Inválido" : ""}
                  </div>
                </div>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  placeholder="CPF/CNPJ"
                  value={companyDocument}
                  disabled={disabledCompanyDocument}
                  error={errorDocument}
                  onChange={(e) => {
                    if (e.target.value.replace(/[^\d]+/g, "").length <= 11) {
                      setCompanyDocument(
                        MaskService.toMask("cpf", e.target.value, "")
                      );
                    } else {
                      setCompanyDocument(
                        MaskService.toMask("cnpj", e.target.value, "")
                      );
                    }
                  }}
                  onBlur={async (e) => {
                    if (
                      searchedDocument !== e.target.value.replace(/[^\d]+/g, "")
                    ) {
                      setSearchedDocument(
                        e.target.value.replace(/[^\d]+/g, "")
                      );

                      if (
                        e.target.value.replace(/[^\d]+/g, "").length === 11 ||
                        e.target.value.replace(/[^\d]+/g, "").length === 14
                      ) {
                        setLoading(true);
                        setErrorDocument(false);
                        try {
                          let response =
                            await getAccountantClientDataByDocument(
                              e.target.value.replace(/[^\d]+/g, "")
                            );

                          if (response?.success) {
                            try {
                              setEntityId(response?.data?.id);
                              setCompanyName(response?.data?.company_name);
                              setCompanyFantasyName(
                                response?.data?.trading_name
                              );
                              setCompanyEmail(response?.data?.email);

                              if (response?.data?.phone) {
                                setCompanyPhone(
                                  MaskService.toMask(
                                    "cel-phone",
                                    response?.data?.phone,
                                    ""
                                  )
                                );
                              } else {
                                setCompanyPhone("");
                              }

                              setCompanyTaxRegime(
                                response?.data?.tax_regime_id
                              );
                              setCompanyStateRegistration(response?.data?.ie);
                              setCompanyMunicipalRegistration(
                                response?.data?.im
                              );
                              setCompanyMunicipalRegistrationCCM(
                                response?.data?.ccm
                              );
                              setLogoClient(response?.data?.logo_url);
                              setAddressList(response?.data?.addresses);
                              setRequisitionType("PUT");
                            } catch (err) {
                              console.log(err);
                            }
                          } else {
                            switch (response?.code) {
                              case 403:
                                notify(
                                  "Você não possui permissão para acessar este cliente. Muito provavelmente o cliente está vinculado a outro contador."
                                );
                                break;
                              case 404:
                                if (
                                  e.target.value.replace(/[^\d]+/g, "")
                                    .length === 14
                                ) {
                                  let responseBigData =
                                    await getCompanyDataInBigDataByDocument(
                                      e.target.value.replace(/[^\d]+/g, ""),
                                      companyUser.document
                                    );

                                  if (
                                    responseBigData !== null &&
                                    responseBigData?.success
                                  ) {
                                    setCompanyName(
                                      responseBigData?.result?.companyName
                                    );
                                    setCompanyFantasyName(
                                      responseBigData?.result?.tradingName
                                    );
                                    setCompanyEmail(
                                      responseBigData?.result?.email
                                    );

                                    if (responseBigData?.result?.phone) {
                                      setCompanyPhone(
                                        MaskService.toMask(
                                          "cel-phone",
                                          responseBigData?.result?.phone,
                                          ""
                                        )
                                      );
                                    }

                                    let responseAddress =
                                      await getAddressDataInBigDataByCEP(
                                        responseBigData?.result?.addresses[0]?.cep.replace(
                                          /[^\d]+/g,
                                          ""
                                        ),
                                        companyUser.document
                                      );

                                    if (
                                      responseAddress !== null &&
                                      responseAddress?.success
                                    ) {
                                      const addressObj = {
                                        id: "NEW_" + Math.random(),
                                        postal_code:
                                          responseAddress?.result?.cep.replace(
                                            /[^\d]+/g,
                                            ""
                                          ),
                                        street: responseAddress?.result?.street,
                                        address_number:
                                          responseAddress?.result?.number || 0,
                                        address_complement:
                                          responseAddress?.result?.complement,
                                        district:
                                          responseAddress?.result?.neighborhood,
                                        city_id: parseInt(
                                          responseAddress?.result?.ibgeCode
                                        ),
                                        state_id: listStates.find(
                                          (state) =>
                                            state.uf ===
                                            responseAddress?.result?.state
                                        )?.id,

                                        country_id: 1058,
                                        main_address: true,
                                        incomplete:
                                          responseAddress?.result?.number ===
                                            null ||
                                          responseAddress?.result?.number ===
                                            undefined ||
                                          responseAddress?.result?.number ===
                                            "",
                                      };

                                      setAddressList([addressObj]);
                                    } else {
                                      notify(
                                        "Não foi possível encontrar o endereço do cliente."
                                      );
                                      setAddressList([]);
                                    }
                                  }
                                }
                                break;
                              default:
                                console.log(response);
                                break;
                            }
                          }
                        } catch (err) {
                          console.log(err);
                        } finally {
                          setLoading(false);
                        }
                      } else {
                        setErrorDocument(true);
                      }
                    }
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "flex-end",
                    justifyContent: "space-between",
                  }}
                >
                  Razão Social*{" "}
                  <div style={{ color: "red", fontSize: 10 }}>
                    {errorName ? "Razão Social Inválida" : ""}
                  </div>
                </div>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  placeholder="Razão Social"
                  value={companyName}
                  error={errorName}
                  onChange={(e) => {
                    setCompanyName(e.target.value);
                  }}
                  onBlur={(e) => {
                    if (e.target.value.trim().length === 0) {
                      setErrorName(true);
                    } else {
                      setErrorName(false);
                    }
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "flex-end",
                    justifyContent: "space-between",
                  }}
                >
                  Nome Fantasia*{" "}
                  <div style={{ color: "red", fontSize: 10 }}>
                    {errorFantasyName ? "Nome Fantasia Inválido" : ""}
                  </div>
                </div>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  placeholder="Nome Fantasia"
                  error={errorFantasyName}
                  value={companyFantasyName}
                  onChange={(e) => {
                    setCompanyFantasyName(e.target.value);
                  }}
                  onBlur={(e) => {
                    if (e.target.value.trim().length === 0) {
                      setErrorFantasyName(true);
                    } else {
                      setErrorFantasyName(false);
                    }
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <div>Regime Tributário</div>

                <FormControl
                  size="small"
                  fullWidth
                  placeholder="Selecione o regime tributário"
                >
                  <SelectErp
                    variant="outlined"
                    placeholder="Regime"
                    value={companyTaxRegime}
                    setValue={setCompanyTaxRegime}
                    optionsList={listTaxRegime}
                    descriptionField="description"
                  />
                </FormControl>
              </Grid>

              <Grid item xs={4}>
                <div>Email</div>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  placeholder="contato@empresa.com"
                  value={companyEmail}
                  onChange={(e) => {
                    setCompanyEmail(e.target.value.toLowerCase());
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <div>Telefone</div>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  placeholder="Telefone"
                  value={companyPhone}
                  onChange={(e) => {
                    setCompanyPhone(
                      MaskService.toMask("cel-phone", e.target.value, "")
                    );
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <div>Inscrição Estadual</div>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  placeholder="Digite a Inscrição Estadual"
                  value={companyStateRegistration}
                  onChange={(e) => setCompanyStateRegistration(e.target.value)}
                />
              </Grid>
              <Grid item xs={4}>
                <div>Inscrição Municipal (Mobiliária)</div>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  placeholder="Digite a Inscrição Municipal (Mobiliária)"
                  value={companyMunicipalRegistration}
                  onChange={(e) =>
                    setCompanyMunicipalRegistration(e.target.value)
                  }
                />
              </Grid>
              <Grid item xs={4}>
                <div>Inscrição Municipal (Mobiliária - CCM)</div>
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  placeholder="Digite a Inscrição Municipal (Mobiliária - CCM)"
                  value={companyMunicipalRegistrationCCM}
                  onChange={(e) =>
                    setCompanyMunicipalRegistrationCCM(e.target.value)
                  }
                />
              </Grid>

              {location.pathname === "/customermanagement/create" &&
                companyUser.isAccountant && (
                  <Grid item xs={4}>
                    <Tooltip
                      title="Este campo é utilizado na consulta de CNDs"
                      placement="right"
                      arrow
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "flex-end",
                          justifyContent: "space-between",
                        }}
                      >
                        CPF do Responsável da Empresa
                        <HelpIcon
                          fill="#616161"
                          height="18"
                          width="18"
                        ></HelpIcon>
                      </div>
                    </Tooltip>

                    <TextField
                      variant="outlined"
                      size="small"
                      fullWidth
                      placeholder="CPF/CNPJ"
                      value={documentResponsible}
                      error={errorDocumentResponsible}
                      onBlur={(e) => {
                        if (!validateCPF(e.target.value)) {
                          setErrorDocumentResponsible(true);
                        } else {
                          setErrorDocumentResponsible(false);
                        }
                      }}
                      onChange={(e) => {
                        setDocumentResponsible(
                          MaskService.toMask("cpf", e.target.value, "")
                        );
                      }}
                    />
                  </Grid>
                )}
            </Grid>

            <Grid
              item
              xs={2}
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                cursor: "pointer",
              }}
            >
              <Dropzone accept="image/png" onDropAccepted={handleUpload}>
                {({
                  getRootProps,
                  getInputProps,
                  isDragActive,
                  isDragReject,
                }) => (
                  <div
                    {...getRootProps()}
                    isDragActive={isDragActive}
                    isDragReject={isDragReject}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                    }}
                  >
                    <input {...getInputProps()} multiple={false} />

                    {logoClient ? (
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <div>
                          <CompanyLogo alt="logo" src={logoClient} />
                        </div>
                      </div>
                    ) : (
                      <img
                        src={PictureIcon}
                        alt="Logo placeholder"
                        height="50px"
                      />
                    )}

                    <div style={{ textAlign: "center" }}>
                      {!isDragActive && (
                        <div>Clique ou Arraste seu logotipo aqui </div>
                      )}
                      {isDragActive && (
                        <>
                          {isDragReject ? (
                            <DropMessage isDragReject>
                              <ErrorOutlineIcon /> Não suportado
                            </DropMessage>
                          ) : (
                            <DropMessage isDragReject={isDragReject}>
                              <ImageOutlinedIcon /> Solte Aqui
                            </DropMessage>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                )}
              </Dropzone>
            </Grid>
          </div>

          {!companyUser.isAccountant &&
            companyUser.accountantCnpj &&
            !isInClientView && (
              <>
                <div
                  style={{
                    display: "flex",

                    borderBottom: "1px solid #CECFD0",
                    fontWeight: "600",
                    fontSize: "18px",
                    color: "#424242",
                    paddingTop: "20px",
                    paddingBottom: "10px",
                    flexDirection: "column",
                  }}
                >
                  Acesso BPO do Contador
                </div>
                <div style={{ color: "gray", fontSize: "16px", padding: 5 }}>
                  O acesso BPO permite que o contador a que você está vinculado
                  possa acessar o seu Gestão Fácil para realizar lançamentos
                  contábeis e fiscais.
                </div>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        disabled={gfPayer === "accountant"}
                        color="primary"
                        checked={bpoActive}
                        onChange={(e) => {
                          setBpoActive(e.target.checked);
                        }}
                      />
                    }
                    label="Permito que o Contador a que estou vinculado realize acesso BPO em meu Gestão Fácil"
                  />
                </Grid>
              </>
            )}

          {location.pathname === "/customermanagement/create" &&
            companyUser.isAccountant && (
              <>
                <div
                  style={{
                    display: "flex",

                    borderBottom: "1px solid #CECFD0",
                    fontWeight: "600",
                    fontSize: "18px",
                    color: "#424242",
                    paddingTop: "20px",
                    paddingBottom: "10px",
                    flexDirection: "column",
                  }}
                >
                  Endereço de Acesso à sua Plataforma
                </div>

                <Grid contanier style={{ marginTop: 20 }}>
                  Personalize aqui o endereço de acesso à sua Plataforma
                  <Grid
                    item
                    xs={6}
                    container
                    alignItems="center"
                    style={{ marginTop: 10 }}
                  >
                    <b>https://</b>
                    <TextField
                      variant="outlined"
                      size="small"
                      placeholder={
                        "Exemplo: " +
                          companyName
                            .normalize("NFD")
                            .toLowerCase()
                            .replace(/[^a-z0-9_]/g, "")
                            .replace(/\s/g, "")
                            .substring(0, 18) || "sorveteriabrasil"
                      }
                      value={subdomain}
                      onChange={(e) => {
                        setSubdomain(e.target.value.replace(" ", ""));
                      }}
                    />
                    <b>.qyon.net</b>
                  </Grid>
                </Grid>
              </>
            )}

          <div
            style={{
              display: "flex",

              borderBottom: "1px solid #CECFD0",
              fontWeight: "600",
              fontSize: "18px",
              color: "#424242",
              paddingTop: "20px",
              paddingBottom: "10px",
              flexDirection: "column",
            }}
          >
            Endereço
            {hasIncompleteAddress && (
              <div style={{ fontSize: "12px" }}>
                <div style={{ color: "#F2C037 " }}>
                  * Alguns endereços se encontram incompletos, por favor
                  verifique
                </div>
              </div>
            )}
          </div>

          <div
            style={{
              display: "flex",
              marginTop: 20,
              background: "#E0E0E0",
              justifyContent: "flex-end",
              gap: "10px",
              padding: "10px",
            }}
          >
            <div>
              <Tooltip title="Excluir Endereço(s)">
                <IconButton
                  size="small"
                  onClick={() => {
                    if (selectedAddresses.length > 0) {
                      setOpenDeleteSelectedAddressDialog(true);
                    } else {
                      notify("Selecione ao menos um endereço para excluir.");
                    }
                  }}
                >
                  <SvgIcon>
                    <DeleteIcon></DeleteIcon>
                  </SvgIcon>
                </IconButton>
              </Tooltip>
            </div>

            <div>
              <Tooltip title="Adicionar Endereço">
                <Button
                  variant="contained"
                  color="primary"
                  endIcon={<Add />}
                  style={{ textTransform: "none" }}
                  onClick={() => {
                    setOpenAddresDialog(true);
                  }}
                >
                  Adicionar
                </Button>
              </Tooltip>
            </div>
          </div>

          <DataGrid
            autoHeight
            rows={addressList}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 5 },
              },
            }}
            disableSelectionOnClick
            checkboxSelection
            onSelectionModelChange={(newSelection) => {
              setSelectedAddresses(newSelection);
            }}
            selectionModel={selectedAddresses}
          />
        </div>
      </DefaultPageContainer>
    </>
  );
};
