import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";

import styled from "styled-components";

export const LogoQyonContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const RecoveryPasswordHeader = styled.div`
  color: var(--Quasar-Grey-09, #364461);
  font-family: Roboto;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 66.667% */
  letter-spacing: 0.15px;
`;

export const RecoveryPasswordHeaderSecondary = styled.div`
  color: var(--Quasar-Grey-09, #364461);
  /* subtitle2. */
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 21.98px; /* 157% */
  letter-spacing: 0.1px;
`;

export const FormRecoveryPassword = styled.div`
  width: 560px;
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 12px;
  padding-bottom: 30px;
  background: white;
  border-radius: 40px;
  box-shadow: 0px 4px 24px 0px #1355ff66;
`;

export const PrincipalRecoveryContainer = styled.div`
  display: flex;
  height: 100vh;
  flex-shrink: 0;
  background: linear-gradient(
      110deg,
      #cedafc 16.83%,
      #eef2ff 49.04%,
      #c6ddfb 82.62%
    ),
    #d9d9d9;
`;

export const RecoveryContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 600px) {
    padding: 16px;
  }
`;

export const RecoveryLabel = styled.div`
  color: var(--Quasar-Grey-09, #364461);

  /* Subtitle/01 Small */
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 157.143% */
  margin-top: 10px;
`;

export const HeaderTextField = styled(TextField)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  align-self: stretch;
  margin-top: 4px;
`;

export const RecoverySendButton = styled(Button)`
  display: flex;
  height: 48px;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
  width: 100%;
`;

export const RecoveryCancelButton = styled(Button)`
  display: flex;
  height: 48px;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;
  width: 100%;
  background-color: #f0f1f3;
  font-color: #364461;
`;
