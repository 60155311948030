import { Switch } from "react-router-dom";
import { GlobalVariables } from "../../GlobalVariables";

import { UseGuideReportProvider } from "../../pages/GuideReports/contexts/GuideReportContext";
import GuideReportList from "../../pages/GuideReports/GuideReportList";
import MiddlewareRoute from "../Middleware";

export default function Report() {
  return (
    <UseGuideReportProvider>
      <Switch>
        <MiddlewareRoute
          routePermissionId={GlobalVariables.PERMISSIONS.CRM}
          path="/report/:id?"
          sidebar
          type="report"
          component={GuideReportList}
        />
      </Switch>
    </UseGuideReportProvider>
  );
}
