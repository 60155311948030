export const getTitle = (props) => {
  return props.xmlList.length === 1
    ? "Identifiquei um XML - O que você deseja fazer com ele?"
    : `Identifiquei uma lista de ${props.xmlList.length} XMLs neste arquivo`;
};

export const getSubtitle = (xmlList) => {
  let msg = "Total de";
  let listOutput = [];
  let listInput = [];
  let eventsAndNotIdentified = [];

  for (let xml of xmlList) {
    if (xmlList.length === 1) {
      if (xml.subtype === "XMLNotIdentified" || xml.subtype === "event") {
        msg = "";
      } else {
        msg =
          xml.subtype === "output"
            ? `Total de ${xmlList.length} nota de saída`
            : `Total de ${xmlList.length} nota de entrada`;
      }
    }

    if (xmlList.length > 1) {
      for (xml of xmlList) {
        if (xml.subtype === "output") {
          listOutput.push(xml);
        }
        if (xml.subtype === "input") {
          listInput.push(xml);
        }

        if (xml.subtype === "XMLNotIdentified" || xml.subtype === "event") {
          eventsAndNotIdentified.push(xml);
        }

        let entradaS = listInput.length > 1 ? "s" : "";
        let saidaS = listOutput.length > 1 ? "s" : "";

        if (
          eventsAndNotIdentified.length > 1 &&
          listInput.length === 0 &&
          listOutput.length === 0
        ) {
          msg = "";
        } else {
          msg =
            listInput.length >= 1 && listOutput.length >= 1
              ? `Total de ${listInput.length} nota${entradaS} de entrada e ${listOutput.length} nota${saidaS} de saída`
              : listInput.length >= 1
              ? `Total de ${listInput.length} nota${entradaS} de entrada`
              : `Total de ${listOutput.length} nota${saidaS} de saída`;
        }
      }
    }

    return msg;
  }
};
