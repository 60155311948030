import { useCallback, useContext, useMemo } from "react";
import { useHistory } from "react-router";
import { GlobalContext } from "../contexts/GlobalContext";
import { getUserPermission } from "../repositories/panel/v1/EntityRepository";
import { notify } from "./notify";

const usePortalAsClient = () => {
  const { push } = useHistory();
  const { companyUser, setCompanyUser } = useContext(GlobalContext);

  const isInClientView = useMemo(
    () => !!sessionStorage.getItem("isInClientView"),
    []
  );

  const permissionsInClientView = useMemo(
    () => JSON.parse(sessionStorage.getItem("clientViewPermission")),
    []
  );

  const clientViewed = useMemo(
    () => JSON.parse(sessionStorage.getItem("client")),
    []
  );

  const setPortalAsClientView = useCallback(
    async (
      clientDocument,
      clientMasterUserId,
      clientCompanyId,
      clientName,
      clientCompanyName,
      logoUrl,
      clientEmail,
      setLoading
    ) => {
      setLoading(true);
      sessionStorage.setItem("isInClientView", true);

      try {
        const res = await getUserPermission({
          document: clientDocument,
          userId: clientMasterUserId,
        });
        if (!res.success || !Array.isArray(res.data))
          return notify("Não foi possível acessar como cliente");

        sessionStorage.setItem(
          "clientViewPermission",
          JSON.stringify(res.data)
        );
        sessionStorage.setItem(
          "client",
          JSON.stringify({
            id: clientMasterUserId,
            company: clientCompanyId,
            document: clientDocument,
            name: clientName,
            clientCompanyName,
            email: clientEmail,
          })
        );
        sessionStorage.setItem(
          "accountant",
          JSON.stringify({
            id: companyUser.companyId,
            document: companyUser.document,
          })
        );
        localStorage.setItem(
          "panelByClientView",
          JSON.stringify({
            isAccountant: companyUser.isAccountant,
            accountantId: companyUser.accountantId,
            accountantName: companyUser.accountantName,
            accountantCnpj: companyUser.accountantCnpj,
            accountantLogoUrl: companyUser.accountantLogoUrl,
            document: companyUser.document,
            logoUrl: companyUser.logoUrl,
            logoB64: companyUser.logoB64,
          })
        );
        setCompanyUser({
          ...companyUser,
          isAccountant: null,
          accountantId: companyUser.companyId,
          accountantName: companyUser.companyName,
          accountantCnpj: companyUser.document,
          accountantLogoUrl: companyUser.logoUrl,
          document: clientDocument,
          // companyId: clientUserId,
          logoUrl,
          logoB64: logoUrl,
        });

        push("/dashboard/view");
      } catch (error) {
        notify(`Algo deu errado! ${error}`);
      } finally {
        setLoading(false);
      }
    },
    [companyUser, setCompanyUser, push]
  );

  const backToAccountantView = useCallback(() => {
    const panelByClientView = JSON.parse(
      localStorage.getItem("panelByClientView")
    );
    sessionStorage.removeItem("isInClientView");
    sessionStorage.removeItem("clientViewPermission");
    sessionStorage.removeItem("client");
    sessionStorage.removeItem("accountant");
    if (panelByClientView) setCompanyUser(panelByClientView);
    localStorage.removeItem("panelByClientView");
  }, [setCompanyUser]);

  return {
    isInClientView,
    permissionsInClientView,
    clientViewed,
    setPortalAsClientView,
    backToAccountantView,
  };
};

export { usePortalAsClient };
