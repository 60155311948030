import React, { createContext, useState } from "react";

const UserContext = createContext();

const UserContextProvider = ({ children }) => {
  //Usuários
  const [usersCostumer, setUsersCostumer] = useState([]);
  const [userModalOpen, setUserModalOpen] = useState(false);
  const [userModalIndex, setUserModalIndex] = useState(-1);
  //Modal Usuário
  const [id, setId] = useState(null);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");

  //Filtros da Listagem
  const [profilesFilter, setProfilesFilter] = useState([]);

  const [expanded, setExpanded] = useState({ general: true });
  const [loading, setLoading] = useState(false);
  const [listProfiles, setListProfiles] = useState([]);
  const [loadingInputs, setLoadingInputs] = useState({});
  const [errors, setErrors] = useState({});

  return (
    <UserContext.Provider
      value={{
        //Usuários
        usersCostumer,
        setUsersCostumer,
        userModalOpen,
        setUserModalOpen,
        userModalIndex,
        setUserModalIndex,
        id,
        setId,
        firstName,
        setFirstName,
        lastName,
        setLastName,
        email,
        setEmail,
        profilesFilter,
        setProfilesFilter,

        listProfiles,
        setListProfiles,
        loading,
        setLoading,
        loadingInputs,
        setLoadingInputs,
        errors,
        setErrors,
        expanded,
        setExpanded,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export { UserContextProvider, UserContext };
