import { callApi } from "./main";

const path =
  `${process.env.REACT_APP_CRM_API_URL}/v1` || "http://localhost:4000/v1";

export async function getFilesS3({ id, attendanceId }) {
  let route = `${path}/file${id ? `/${id}` : ""}`;

  if (attendanceId) route = `${route}?attendanceId=${attendanceId}`;

  return callApi({
    path: route,
    method: "GET",
  });
}
