import { Row, Label } from "./styles/PasswordRequirementsField";
import { Close, Check } from "@material-ui/icons";

export default function PasswordRequirementsField({ label, icon }) {
  return (
    <Row>
      {icon === "check" ? (
        <Check
          style={{ width: "16px", height: "16px", color: "#22B07D" }}
        ></Check>
      ) : (
        <Close
          style={{ width: "16px", height: "16px", color: "#FA2D1E" }}
        ></Close>
      )}

      <Label>{label}</Label>
    </Row>
  );
}
