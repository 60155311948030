import { Switch } from "react-router-dom";
import MiddlewareRoute from "../Middleware";
import { System } from "../../pages/settings/System";

export default function Systems() {
  return (
    <Switch>
      <MiddlewareRoute
        noPaddingSidebar
        path="/system"
        sidebar
        component={System}
      />
    </Switch>
  );
}
