import { useCallback, useContext } from "react";
import { GlobalContext } from "../../../contexts/GlobalContext";
import { notify } from "../../../utils/notify";
import { CndCustomerContext } from "../contexts/CndCustomerContext";

import * as rpaEntityRepository from "../../../repositories/panel/v1/integrations/qyonMsRpa/entity";

export function useCndCustomerController() {
  const { companyUser } = useContext(GlobalContext);
  const {
    myCnds,
    setMyCnds,
    cndsFederal,
    cndsMunicipal,
    cndsState,
    cndsOthers,
    setPerPageFederal,
    setPerPageMunicipal,
    setPerPageState,
    setPerPageOthers,
    expandedTable,
    setExpandedTable,
    setLoading,
  } = useContext(CndCustomerContext);
  const handleFavorite = async (id, current) => {
    try {
      setLoading((prev) => ({ ...prev, handleFavorite: true }));

      if (!current && myCnds.filter((item) => item.favorite).length >= 10) {
        return notify("Só é possível favoritar até 10 CNDs.");
      }

      const responseFavorite = await rpaEntityRepository.agendasFavorites({
        document: companyUser.document,
        schedulleID: id,
      });

      if (!responseFavorite.success)
        return notify("Não foi possível favoritar.");

      const index = myCnds.findIndex((cnd) => cnd.agendaId === id);

      if (index === -1) return;

      const copy = [...myCnds];
      copy[index] = { ...copy[index], favorite: !current };

      setMyCnds(copy);
    } catch (error) {
      console.error(error);
      return notify("Não foi possível favoritar.");
    } finally {
      setLoading((prev) => {
        let prevLoading = { ...prev };
        delete prevLoading.handleFavorite;
        return prevLoading;
      });
    }
  };

  const removeFavorite = async (id, current) => {
    try {
      setLoading((prev) => ({ ...prev, removeFavorite: true }));

      const response = await rpaEntityRepository.removeCNDFavorite({
        document: companyUser.document,
        schedulleID: id,
      });

      if (!response.success)
        return notify("Não foi possível remover de favoritos.");

      const index = myCnds.findIndex((cnd) => cnd.agendaId === id);

      if (index === -1) return;

      const copy = [...myCnds];
      copy[index] = { ...copy[index], favorite: !current };

      setMyCnds(copy);
    } catch (error) {
      console.error(error);
      return notify("Não foi possível remover.");
    } finally {
      setLoading((prev) => {
        let prevLoading = { ...prev };
        delete prevLoading.removeFavorite;
        return prevLoading;
      });
    }
  };

  const handleChangeExpanded = (tab) => {
    const optionsStates = {
      federal: { cnds: cndsFederal, setter: setPerPageFederal },
      state: { cnds: cndsState, setter: setPerPageState },
      municipal: { cnds: cndsMunicipal, setter: setPerPageMunicipal },
      others: { cnds: cndsOthers, setter: setPerPageOthers },
    };

    const optionSelected = optionsStates[tab];

    optionSelected.setter(!expandedTable[tab] ? optionSelected.cnds.length : 5);
    setExpandedTable((current) => ({ ...current, [tab]: !current[tab] }));
  };

  const handleStandAloneRequestCnd = useCallback(
    async ({ document, agendaId = null, setListCnds, listCnds }) => {
      try {
        setLoading((prev) => ({ ...prev, handleRequestCnd: true }));

        const body = agendaId ? { agendas: [agendaId] } : { all: true };

        const response = await rpaEntityRepository.refreshAgenda({
          parentDocument: document,
          clientDocument: document,
          body,
        });

        if (!response.success)
          return notify("Não foi possível obter os dados.");

        const copy = [...listCnds];

        copy.forEach((cnd, cndIndex) => {
          if (cnd.agendaID === agendaId || agendaId === null) {
            copy[cndIndex].expirationDate = null;
            copy[cndIndex].issuanceDate = null;
            copy[cndIndex].status = "Processando";
          }
        });

        setListCnds(copy);
        notify("CNDs solicitadas com sucesso!", true, "success");
      } catch (error) {
        console.error(error);
      } finally {
        setLoading((prev) => {
          let prevLoading = { ...prev };
          delete prevLoading.handleRequestCnd;
          return prevLoading;
        });
      }
    },
    [setLoading]
  );

  return {
    handleFavorite,
    removeFavorite,
    handleChangeExpanded,
    handleStandAloneRequestCnd,
  };
}
