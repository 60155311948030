import { Avatar, Divider, Grid, ListItem } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { GridStyled } from "../../../styles/GlobalStyle";
import { PaperStyled } from "../styles/ChatMonitor";

const RenderListAttendantSkeleton = () => {
  return (
    <GridStyled width="100%" padding="10px 0">
      <PaperStyled padding-bottom="10px">
        <GridStyled width="100%">
          <ListItem alignItems="flex-start">
            <Grid container>
              <Grid xs={2}>
                <Avatar>
                  <Skeleton
                    animation="wave"
                    variant="circular"
                    width={40}
                    height={40}
                  />
                </Avatar>
              </Grid>
              <Grid xs={9} container alignItems="center">
                <Skeleton animation="wave" height={10} width="88%" />
                <Skeleton animation="wave" height={10} width="40%" />
              </Grid>
              <Grid
                xs={1}
                container
                alignContent="center"
                alignItems="center"
              ></Grid>
            </Grid>
          </ListItem>
          <Divider variant="middle" />
          <ListItem alignItems="flex-start">
            <Grid container>
              <Grid xs={2}>
                <Avatar>
                  <Skeleton
                    animation="wave"
                    variant="circular"
                    width={40}
                    height={40}
                  />
                </Avatar>
              </Grid>
              <Grid xs={9} container alignItems="center">
                <Skeleton animation="wave" height={10} width="88%" />
                <Skeleton animation="wave" height={10} width="40%" />
              </Grid>
              <Grid
                xs={1}
                container
                alignContent="center"
                alignItems="center"
              ></Grid>
            </Grid>
          </ListItem>
          <Divider variant="middle" />
        </GridStyled>
      </PaperStyled>
    </GridStyled>
  );
};

export default RenderListAttendantSkeleton;
