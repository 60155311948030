import React, { createContext, useState } from "react";

const ModalUserContext = createContext();

const ModalUserContextProvider = ({ children }) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [mainUser, setMainUser] = useState(false);
  const [systemsSelecteds, setSystemsSelecteds] = useState([]);
  const [listSystems, setListSystems] = useState([]);
  const [accessGroup, setAccessGroup] = useState(null);
  const [listAccessGroup, setListAccessGroup] = useState([]);

  return (
    <ModalUserContext.Provider
      value={{
        firstName,
        setFirstName,
        lastName,
        setLastName,
        email,
        setEmail,
        mainUser,
        setMainUser,
        systemsSelecteds,
        setSystemsSelecteds,
        listSystems,
        setListSystems,
        accessGroup,
        setAccessGroup,
        listAccessGroup,
        setListAccessGroup,
      }}
    >
      {children}
    </ModalUserContext.Provider>
  );
};

export { ModalUserContextProvider, ModalUserContext };
