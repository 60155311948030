import {
  ButtonGroup,
  IconButton,
  Tooltip,
  Typography,
} from "@material-ui/core";
import {
  Close,
  Forum as ForumIcon,
  HeadsetMic as HeadsetMicIcon,
} from "@material-ui/icons";
import React, { useEffect } from "react";
import { ContainerIcon, GridStyled } from "../../../styles/GlobalStyle";
import Attendants from "../childrens/Attendants";
import Customers from "../childrens/Customers";
import MonitorFilters from "../childrens/MonitorFilters";
import RenderListAttendantSkeleton from "../childrens/RenderListAttendantSkeleton";
import { useMonitor } from "../contexts/ChatMonitorContext";
import { ButtonIconMonitor } from "../styles/ChatMonitor";

import { useAuth } from "../../../contexts/crm/Auth";
import { useChatContext } from "../contexts/ChatContext";
import useChatController from "../controllers/ChatController";

const Structure = () => {
  const { renderInfosMonitor } = useChatController();
  const { loading, setChat, SCREEN_ENUM } = useChatContext();
  const { company } = useAuth();

  const {
    companyValidation,
    currentScreen,
    setCurrentScreen,
    setBadgeFilters,
    openFilters,
    setNameFilter,
    statusFilter,
    setStatusFilter,
    departmentFilter,
    setDepartmentFilter,
    validationBadgeFilters,
  } = useMonitor();

  useEffect(() => {
    if (company?.id !== companyValidation) {
      renderInfosMonitor();
    }
  }, [company?.id, companyValidation, renderInfosMonitor]);

  useEffect(() => {
    setBadgeFilters(validationBadgeFilters());
  }, [
    statusFilter,
    departmentFilter,
    openFilters,
    currentScreen,
    setBadgeFilters,
    validationBadgeFilters,
  ]);

  const map = {
    attendants: {
      title: "Operadores conectados",
      component: loading ? <RenderListAttendantSkeleton /> : <Attendants />,
    },
    customers: {
      title: "Atendimentos no Chat",
      component: loading ? <RenderListAttendantSkeleton /> : <Customers />,
    },
  };

  const Screen = map[currentScreen].component;

  return (
    <>
      <GridStyled container xs>
        <GridStyled
          className="header"
          container
          alignItems="center"
          height="48px"
          padding="0 16"
          spacing={1}
        >
          <GridStyled item xs>
            <Typography>{map[currentScreen].title}</Typography>
          </GridStyled>
          <GridStyled item height="80%">
            <ButtonGroup>
              <Tooltip title="Operadores conectado ao chat" enterDelay={700}>
                <ButtonIconMonitor
                  value={"attendants"}
                  selected={currentScreen}
                  onClick={() => {
                    setNameFilter("");
                    setStatusFilter("");
                    setDepartmentFilter("");
                    setCurrentScreen("attendants");
                  }}
                >
                  <ContainerIcon
                    fontSize="20"
                    center={true}
                    iconColor={
                      currentScreen !== "attendants" ? "#000" : "white"
                    }
                  >
                    <HeadsetMicIcon />
                  </ContainerIcon>
                </ButtonIconMonitor>
              </Tooltip>
              <Tooltip title="Conversas em andamento" enterDelay={700}>
                <ButtonIconMonitor
                  value={"customers"}
                  selected={currentScreen}
                  onClick={() => {
                    setNameFilter("");
                    setStatusFilter("");
                    setDepartmentFilter("");
                    setCurrentScreen("customers");
                  }}
                >
                  <ContainerIcon
                    fontSize="20"
                    center={true}
                    iconColor={
                      currentScreen === "attendants" ? "#000" : "white"
                    }
                  >
                    <ForumIcon />
                  </ContainerIcon>
                </ButtonIconMonitor>
              </Tooltip>
            </ButtonGroup>
          </GridStyled>
          <GridStyled>
            <Tooltip title="Fechar">
              <ContainerIcon
                iconColor="#CCCFD6"
                fontSize="20"
                onClick={() =>
                  setChat({
                    currentScreen: SCREEN_ENUM.conversation,
                  })
                }
              >
                <IconButton>
                  <Close />
                </IconButton>
              </ContainerIcon>
            </Tooltip>
          </GridStyled>
        </GridStyled>
        <GridStyled
          container
          xs
          className="bodyMonitor"
          width="100%"
          height="100%"
          padding="15px 16"
          overflow-y="scroll"
        >
          <MonitorFilters />
          <GridStyled width="100%" height="100%">
            {Screen}
          </GridStyled>
        </GridStyled>
      </GridStyled>
    </>
  );
};

export default Structure;
