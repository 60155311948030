import { useCallback, useContext } from "react";

import { useHistory } from "react-router";

import jwt_decode from "jwt-decode";

import Swal from "sweetalert2";

import { GlobalContext } from "../../../contexts/GlobalContext";
import { GlobalVariables } from "../../../GlobalVariables";
import { getUserCongnito } from "../../../repositories/integrations/bank/AccountsRepository";
import {
  getEntities,
  getSystemsEntity,
  postGenerateAccessLog,
} from "../../../repositories/panel/v1/EntityRepository";
import {
  confirmUserCognito,
  postChangePassRecover,
  postCodeRecover,
  postUserCognito,
} from "../../../repositories/panel/v1/integrations/CognitoRepository";
import { getContractsByCNPJ } from "../../../repositories/panel/v1/integrations/PlugRepository";
import {
  auth,
  changePasswordFirstTime,
  changePasswordAccessFirst,
} from "../../../repositories/panel/v1/UserRepository";
import useAuthenticationTranslate from "../../../translations/authentications/Authentication";
import useCommonTranslate from "../../../translations/common/Common";
import { notify } from "../../../utils/notify";
import { usePermissions } from "../../../utils/permissionsHook";
import {
  validNotNull,
  validPasswordCoginito,
} from "../../../utils/validations";
import { LoginContext } from "../contexts/LoginContext";

export default function useLoginController() {
  const {
    email,
    setEmail,
    password,
    setPassword,
    sessionCognito,
    setSessionCognito,
    newPassword,
    confirmNewPassword,
    verificationCode,
    setErrors,
    setLoading,
    companyChecked,
    setCompanyChecked,
    setSendCodeVerif,

    listCompanies,
    setListCompanies,
  } = useContext(LoginContext);

  const {
    setUserLogged,
    setCompanyUser,
    userLogged,
    clearUserLogged,
    clearCompanyUser,
  } = useContext(GlobalContext);

  const translate = useAuthenticationTranslate();
  const translateUtils = useCommonTranslate();

  const { push } = useHistory();

  const {
    getPermissionTreeAndSaveOnLocalStorage,
    getUserPermissionsAndSaveOnLocalStorage,
  } = usePermissions();

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();

      const resultValid = validNotNull([
        { key: "email", value: email },
        { key: "password", value: password },
      ]);

      setErrors(resultValid.errorsValidation);
      if (resultValid.error) {
        return notify(translateUtils.notifyValidRequired);
      }

      setLoading(true);

      let response = await auth({ email, password });

      if (
        response.internalCode === GlobalVariables.INTERNAL_CODES.NotAuthorized
      ) {
        return notify("Email ou senha inválidos.");
      }

      const userObjectJSON = JSON.stringify(response?.data?.user);
      localStorage.setItem("userLogged", userObjectJSON);

      if (
        response.internalCode ===
        GlobalVariables.INTERNAL_CODES.UserTemporaryPasswordException
      ) {
        setSessionCognito(response.data?.session || "");

        return push("/changepassword");
      }

      if (
        response?.internalCode ===
        GlobalVariables.INTERNAL_CODES.UserNotConfirmedException
      ) {
        return push("/firstlogin", { sendCodeVerif: true });
      }

      if (
        response?.internalCode ===
        GlobalVariables.INTERNAL_CODES.UserNotFoundOnCognito
      ) {
        return push("/firstlogin");
      }

      if (
        response?.internalCode ===
        GlobalVariables.INTERNAL_CODES.UserExistsInCognitoButNotInDatabase
      ) {
        return Swal.fire({
          icon: "info",
          html: "<p>Seu usuário está cadastrado em nossa base de acesso, porem não está vinculada à nenhuma plataforma/empresa. </p> <p>Verifique se o email utilizado é o mesmo que foi liberado o produto, ou solicite ao usuário master da empresa a liberação. </p> <p> Caso contrário entre em contato com nosso suporte técnico.</p>",
          showConfirmButton: true,
          timer: 5000,
        });
      }

      if (!response?.data?.auth?.token) {
        return notify("Email ou senha inválidos.");
      }

      response = response.data;

      const tokenDecode = jwt_decode(response?.auth?.cognito?.IdToken || "");

      const user = {
        token: response?.auth?.token || "",
        email: response?.auth?.user_email || "",
        cognito: { ...(response?.auth?.cognito || {}), ...(tokenDecode || {}) },
        userId: response?.user?.id || "",
        firstName: response?.user?.first_name || "",
        lastName: response?.user?.last_name || "",
        lastLogin: response?.user?.last_login || "",
        tokenCognito: response?.auth?.cognito?.AccessToken || "",
        subCognito: tokenDecode?.sub || "",
        showVideoIntro: (!!response?.user?.show_video_intro).toString(),
      };

      setUserLogged(user);

      // filtra empresas duplicadas
      const userEntities = response.entities
        ? [
            ...new Map(
              response.entities.map((entity) => [entity["id"], entity])
            ).values(),
          ]
        : [];

      if (userEntities.length === 1) {
        await handleSelectEntity({
          user: user,
          list: userEntities,
          companyIdParam: userEntities[0].id.toString(),
          userIdParam: response?.user?.id,
        });

        return;
      }

      // if(userEntities.length > 1){
      //   const companyInitial = userEntities.find(
      //     (company) => company?.accountant_id || company?.is_accountant
      //   );
      //   await handleSelectEntity({
      //     list: userEntities,
      //     companyIdParam: companyInitial.id.toString(),
      //     userIdParam: response?.user?.id,
      //   });

      //   return;
      // }
      setListCompanies(userEntities);
      return push("/companyselection");
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleSendCodeVerif = async (e) => {
    try {
      setLoading(true);

      const respRecover = await postCodeRecover({ body: { email: email } });

      if (respRecover?.success) {
        setSendCodeVerif(true);
        return notify(
          "Código de verificação enviado no e-mail informado.",
          true,
          "success"
        );
      } else {
        setSendCodeVerif(false);
        return notify(
          respRecover?.message ||
            "Ocorreu um erro ao enviar o código de verificação, por favor, entre em contato com o suporte, ou tente novamente mais tarde.",
          false,
          "error"
        );
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleChangePasswordRecover = async (e) => {
    try {
      setLoading(true);

      const valid = validNotNull([
        { value: verificationCode, key: "verificationCode" },
        { value: newPassword, key: "newPassword" },
        { value: confirmNewPassword, key: "confirmNewPassword" },
      ]);

      if (newPassword !== confirmNewPassword) {
        valid.error = true;
        valid.errorsValidation.confirmNewPassword = "A senha não coincide";
      }

      if (validPasswordCoginito(newPassword).error) {
        valid.error = true;
        valid.errorsValidation.newPassword =
          "A senha deve conter letras maiúsculas, minúsculas e números";
      }

      setErrors(valid.errorsValidation);

      if (valid.error) return;

      const respChangPass = await postChangePassRecover({
        body: {
          code: verificationCode.trim(),
          email: email.trim(),
          password: newPassword,
        },
      });

      if (respChangPass?.success) {
        setPassword(newPassword);
        notify("Senha atualizada com sucesso.", true, "success");

        setTimeout(() => {
          push("/login", { logout: true });
        }, 5000);
      } else {
        notify(
          respChangPass?.message || "Ocorreu um erro ao recuperar a senha",
          true,
          "warning"
        );
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleChangeEmail = (e) => {
    setEmail(e.target.value.toLowerCase());
  };

  const handleRadioChange = (event) => {
    setCompanyChecked(event.target.value);
  };

  const handleSelectEntity = async ({
    user,
    list,
    companyIdParam,
    userIdParam,
  }) => {
    try {
      setLoading(true);
      const companyId = companyIdParam || companyChecked;
      const userId = userIdParam || userLogged.userId;

      if (!companyId) return notify(translate.notifySelectCompany);

      const companyUser = (list || listCompanies).find(
        (company) => company.id.toString() === companyId
      );

      localStorage.setItem("companyIdPanel", companyUser?.id);
      let getEntityCall = null;
      if (companyUser?.main_entity_id) {
        getEntityCall = getEntities({
          id: companyUser?.main_entity_id,
        });
      }

      if (companyUser?.accountant_id) {
        getEntityCall = getEntities({
          id: companyUser?.accountant_id,
        });
      }

      const [
        // eslint-disable-next-line no-unused-vars
        permissionTree,
        // eslint-disable-next-line no-unused-vars
        permissions,
        mainEntity,
        contracts,
        // eslint-disable-next-line no-unused-vars
        postLogging,
        systemEntityLogged,
      ] = await Promise.all([
        getPermissionTreeAndSaveOnLocalStorage(companyUser.document),
        getUserPermissionsAndSaveOnLocalStorage(companyUser.document, userId),
        getEntityCall,
        getContractsByCNPJ({
          document: companyUser?.document,
        }),
        postGenerateAccessLog({
          entityId: companyId,
          userId,
        }),
        getSystemsEntity({
          id: companyUser?.id,
        }),
      ]);

      const others = { systemsPermissions: [] };
      if (companyUser?.main_entity_id) {
        others.mainEntityCnpj = mainEntity?.data?.document || "";
      }

      if (companyUser?.accountant_id) {
        others.accountantCnpj = mainEntity?.data?.document || "";
        others.accountantName = mainEntity?.data?.trading_name || "";
        others.accountantLogoUrl = mainEntity?.data?.logo_url || "";
      }

      if (userId && companyUser?.id) {
        let systemsCompany = null;

        if (companyUser?.is_accountant) {
          systemsCompany = systemEntityLogged;
        } else {
          let systemsAccountant = null;

          if (companyUser?.accountant_id)
            systemsAccountant = await getSystemsEntity({
              id: companyUser?.accountant_id,
            });

          let systemsCompanyLogged = systemEntityLogged;

          let allowedSystems = systemsAccountant ?? systemsCompanyLogged;

          if (systemsAccountant?.data && systemsCompanyLogged?.data) {
            systemsAccountant.data.forEach((element) => {
              systemsCompanyLogged.data.forEach((element2) => {
                if (
                  element.id === element2.id &&
                  element.status_product !== "A"
                ) {
                  element.status_product = element2.status_product;
                  element.configured = element2.configured;
                }
              });
            });
          }

          systemsCompany = {
            success: true,
            data: (Array.isArray(allowedSystems?.data)
              ? allowedSystems?.data
              : []
            ).map((systAcc) => ({
              ...systAcc,
            })),
          };
        }

        if (systemsCompany?.success && Array.isArray(systemsCompany?.data)) {
          systemsCompany = systemsCompany?.data;
        } else systemsCompany = [];

        others.systemsPermissions = systemsCompany;
      }

      const compUser = {
        masterUserId: companyUser?.master_user_id,
        masterUserEmail: companyUser?.master?.email || "",
        companyId: companyUser?.id,
        document: companyUser?.document,
        logoB64: companyUser?.logo_url,
        companyName: companyUser?.company_name,
        tradingName: companyUser?.trading_name,
        taxRegimeId: companyUser?.tax_regime_id,
        mainEntityId: companyUser?.main_entity_id,
        accountantId: companyUser?.accountant_id,
        logoUrl: companyUser?.logo_url,
        entityType: companyUser?.entity_type,
        plug: companyUser?.plug,
        isAccountant: companyUser?.is_accountant,
        pivot: companyUser?.pivot,
        subdomain: companyUser?.subdomain ?? "",
        websiteExhibitionType: companyUser?.website_exhibition_type ?? "qyon",
        ownWebsiteUrl: companyUser?.own_website_url ?? "",
        contracts: contracts?.data ?? [],
        ...others,
      };
      setCompanyUser(compUser);

      localStorage.setItem("environment", process.env.REACT_APP_ENVIRONMENT);
      localStorage.setItem("subdomain", companyUser?.subdomain ?? "");

      if (localStorage.getItem("nextPageLoginPanel")) {
        const urlNextPage = localStorage.getItem("nextPageLoginPanel");
        localStorage.setItem("nextPageLoginPanel", "");
        return window.location.replace(urlNextPage);
      }

      const uri = sessionStorage.getItem("lastUriPanel") || "/dashboard/view";
      sessionStorage.setItem("lastUriPanel", "");

      window.location.href = `${
        process.env.REACT_APP_ENV_DEV ? "http" : "https"
      }://${window.location.host}${uri}`;

      // push("/dashboard/view");
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleClearContextAuth = useCallback(() => {
    setEmail("");
    setPassword("");
    setCompanyChecked("");
    setListCompanies([]);
  }, [setCompanyChecked, setEmail, setListCompanies, setPassword]);

  const handleSetCustomColumns = useCallback(
    (orderColumnsFromLocalStorage, checkedColumnsFromLocalStorage) => {
      const defaultValues = {
        orderColumns: [
          { name: "Ações", id: 1 },
          { name: "Acesso Plataforma", id: 2 },
          { name: "Gestão Fácil", id: 3 },
          { name: "Acesso GF", id: 4 },
          { name: "Cliente", id: 5 },
          { name: "Mensalidade", id: 6 },
          { name: "Usuários", id: 7 },
          { name: "Certificado Digital", id: 8 },
          { name: "Cont. Pagar", id: 9 },
          { name: "Cont. Receber", id: 10 },
          { name: "NF. Entrada", id: 11 },
        ],
        columnsCheck: {
          1: true,
          2: true,
          3: true,
          4: true,
          5: true,
          6: true,
          7: true,
          8: true,
          9: true,
          10: true,
          11: true,
        },
      };

      let columnsCheck = defaultValues.columnsCheck;
      let orderedColumns = orderColumnsFromLocalStorage;

      if (
        Object.keys(checkedColumnsFromLocalStorage).length ===
        Object.keys(defaultValues.columnsCheck).length
      ) {
        columnsCheck = checkedColumnsFromLocalStorage;
      }

      const checkedColumns = defaultValues.orderColumns.filter(
        (column) => columnsCheck[`${column.id}`]
      );

      if (checkedColumns.length !== orderColumnsFromLocalStorage.length) {
        orderedColumns = checkedColumns;
      }
      localStorage.setItem(
        "orderColumns",
        JSON.stringify({
          orderColumns: orderedColumns,
        })
      );

      localStorage.setItem(
        "checkColumns",
        JSON.stringify({
          checkColumns: columnsCheck,
        })
      );
    },
    []
  );

  const handleLogout = useCallback(() => {
    const nextPageLoginPanel = localStorage.getItem("nextPageLoginPanel");
    const orderColumnsFromLocalStorage =
      JSON.parse(localStorage.getItem("orderColumns"))?.orderColumns ?? [];
    const checkedColumnsFromLocalStorage =
      JSON.parse(localStorage.getItem("checkColumns"))?.checkColumns ?? {};

    clearUserLogged();
    clearCompanyUser();
    localStorage.clear();
    handleSetCustomColumns(
      orderColumnsFromLocalStorage,
      checkedColumnsFromLocalStorage
    );

    localStorage.setItem("nextPageLoginPanel", nextPageLoginPanel || "");
  }, [clearCompanyUser, clearUserLogged, handleSetCustomColumns]);

  const handleConfirmUser = async (e) => {
    try {
      e.preventDefault();

      setLoading(true);

      const valid = validNotNull([
        { value: verificationCode, key: "verificationCode" },
      ]);

      setErrors(valid.errorsValidation);

      if (valid.error) return;

      const repsConfirm = await confirmUserCognito({
        body: {
          email,
          confirmationCode: verificationCode,
        },
      });

      if (repsConfirm?.success) {
        notify("Usuário configurado.", true, "success");
        return push("/login", { logout: true });
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handlePostUser = async (e) => {
    try {
      e.preventDefault();

      setLoading(true);

      if (email) {
        const emailCognito = await getUserCongnito({ email });

        if (emailCognito?.cognito) {
          notify(
            'Este usuário já possui uma senha configurada nos produtos Qyon. Caso não se lembre acesse a opção "Esqueceu a senha?".',
            true,
            "warning",
            null,
            10000
          );
          return push("/login", { logout: true });
        }
      }

      const valid = validNotNull([
        { value: email, key: "email" },
        { value: newPassword, key: "newPassword" },
        { value: confirmNewPassword, key: "confirmNewPassword" },
      ]);

      if (newPassword !== confirmNewPassword) {
        valid.error = true;
        valid.errorsValidation.confirmNewPassword = "A senha não coincide";
      }

      if (validPasswordCoginito(newPassword).error) {
        valid.error = true;
        valid.errorsValidation.newPassword =
          "A senha deve conter letras maiúsculas, minúsculas e números";
      }

      setErrors(valid.errorsValidation);

      if (valid.error) return;

      const repsUser = await postUserCognito({
        body: {
          email,
          password: newPassword,
        },
      });

      if (
        repsUser?.internalCode ===
        GlobalVariables.INTERNAL_CODES.UserExistsInCognitoButNotInDatabase
      ) {
        Swal.fire({
          icon: "info",
          html: "<p>Seu usuário está cadastrado em nossa base de acesso, porem não está vinculada à nenhuma plataforma/empresa. </p> <p>Verifique se o email utilizado é o mesmo que foi liberado o produto, ou solicite ao usuário master da empresa a liberação. </p> <p> Caso contrário entre em contato com nosso suporte técnico.</p>",
          showConfirmButton: true,
          timer: 5000,
        });

        return push("/login", { logout: true });
      }

      if (repsUser?.success) {
        setSendCodeVerif(true);
        return notify(
          "Código de verificação enviado no e-mail informado.",
          true,
          "success"
        );
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleChangePassword = async (event) => {
    let successChange = false;
    try {
      event.preventDefault();

      setLoading(true);

      const valid = validNotNull([
        { value: password, key: "password" },
        { value: confirmNewPassword, key: "confirmNewPassword" },
      ]);

      if (password !== confirmNewPassword) {
        valid.error = true;
        valid.errorsValidation.confirmNewPassword = "A senha não coincide";
      }

      if (validPasswordCoginito(password).error) {
        valid.error = true;
        valid.errorsValidation.password = "Formato de senha inválido";
      }

      setErrors(valid.errorsValidation);
      if (valid.error) return;

      const respChangPass = await changePasswordFirstTime({
        body: {
          session: sessionCognito,
          email: email.trim(),
          password: password,
        },
      });

      if (respChangPass?.success) {
        successChange = true;
        notify("Senha atualizada com sucesso.", true, "success");
      }
    } catch (error) {
      console.error(error);
    } finally {
      successChange ? handleSubmit(event) : setLoading(false);
    }
  };

  return {
    //Login
    handleSubmit,
    handleChangeEmail,
    handleClearContextAuth,

    //Logout
    handleLogout,

    //FirstLogin
    handlePostUser,
    handleConfirmUser,

    //CompanySelection
    handleSelectEntity,
    handleRadioChange,

    //RecoverPassword
    handleSendCodeVerif,
    handleChangePasswordRecover,

    //ChangePassword,
    handleChangePassword,
  };
}
