import React, { useContext } from "react";

import { useHistory } from "react-router";
import { GlobalVariables } from "../../GlobalVariables";
import { usePermissions } from "../../utils/permissionsHook";

import { Grid } from "@material-ui/core";
import ChatIcon from "@material-ui/icons/Chat";
import DescriptionIcon from "@material-ui/icons/Description";
import BuildIcon from "@material-ui/icons/Build";
import Edit from "@material-ui/icons/Edit";
import PersonIcon from "@material-ui/icons/Person";
import ThumbsUpIcon from "@material-ui/icons/ThumbUp";
import Loading from "../../components/basics/Loading";
import CardButton from "../../components/cards/CardButton";
import { GlobalContext } from "../../contexts/GlobalContext";
import { Margin, Title } from "../../styles/GlobalStyle";
import useSettingTranslate from "../../translations/settings/Setting";
import { notify } from "../../utils/notify";
import { usePortalAsClient } from "../../utils/viewPortalAsClientHook";
import ModalCurrentUser from "../currentUser/chindren/ModalCurrentUser";
import { ModalCurrentUserContext } from "../currentUser/contexts/ModalCurrentUserContext";
import { SettingContext } from "./contexts/SettingContext";
import useSettingController from "./controllers/SettingController";
import { Group } from "@material-ui/icons";

export default function Settings() {
  const translate = useSettingTranslate();
  const { push } = useHistory();
  const { handleOpenDataCompany, handleCRMSettings } = useSettingController();
  const { loading } = useContext(SettingContext);
  const { userLogged, companyUser } = useContext(GlobalContext);
  const { currentUserModalOpen, setCurrentUserModalOpen } = useContext(
    ModalCurrentUserContext
  );
  const { isInClientView } = usePortalAsClient();
  const { checkIfUserHasPermission } = usePermissions();
  return (
    <>
      {currentUserModalOpen && <ModalCurrentUser />}

      <Loading open={loading} />

      <Grid container justifyContent="space-between">
        <Grid item xs={12} container alignItems="center">
          <Grid item flex={1}>
            <Title color="blue">Configurações</Title>
          </Grid>

          <Margin top="20" />

          <Grid item container>
            <Grid xs={6}>
              <CardButton
                onClick={() => {
                  checkIfUserHasPermission(
                    GlobalVariables.PERMISSIONS.SETTINGS_COMPANY
                  )
                    ? handleOpenDataCompany()
                    : notify(
                        `Acesso negado! Permissão 'Menus - Configuração - Edição da empresa/website' necessária`
                      );
                }}
                label={translate.registrationData}
                icon={<Edit />}
              />
            </Grid>

            <Grid xs={6}>
              <CardButton
                onClick={() => {
                  checkIfUserHasPermission(
                    GlobalVariables.PERMISSIONS.SETTINGS_COMPANY
                  )
                    ? push("/setting/users", {
                        customerId: companyUser.companyId,
                      })
                    : notify(
                        `Acesso negado! Permissão 'Menus - Configuração - Edição da empresa/website' necessária`
                      );
                }}
                label="Usuários"
                icon={<Group />}
              />
            </Grid>

            <Grid xs={6}>
              <CardButton
                onClick={() =>
                  isInClientView
                    ? notify(
                        "Você está na Plataforma do Cliente, volte a sua visão para alterar seus dados"
                      )
                    : setCurrentUserModalOpen(true)
                }
                label="Perfil"
                icon={<PersonIcon />}
              />
            </Grid>

            {companyUser?.isAccountant && (
              <Grid xs={6}>
                <CardButton
                  onClick={() =>
                    checkIfUserHasPermission(
                      GlobalVariables.PERMISSIONS.SETTINGS_COMPANY
                    )
                      ? push("/edithome")
                      : notify(
                          `Acesso negado! Permissão 'Menus - Configuração - Edição da empresa/website' necessária`
                        )
                  }
                  label="Website"
                  icon={<Edit />}
                />
              </Grid>
            )}
            {companyUser?.isAccountant && (
              <Grid xs={6}>
                <CardButton
                  onClick={() =>
                    checkIfUserHasPermission(GlobalVariables.PERMISSIONS.CRM)
                      ? handleCRMSettings(userLogged)
                      : notify(
                          `Acesso negado! Permissão 'Menus - CRM' necessária`
                        )
                  }
                  label="Relacionamento (CRM)"
                  icon={<ThumbsUpIcon />}
                />
              </Grid>
            )}
            {companyUser?.isAccountant && (
              <Grid xs={6}>
                <CardButton
                  onClick={() =>
                    checkIfUserHasPermission(GlobalVariables.PERMISSIONS.CRM)
                      ? push("/chat/settings")
                      : notify(
                          `Acesso negado! Permissão 'Menus - CRM' necessária`
                        )
                  }
                  label="Chat"
                  icon={<ChatIcon />}
                />
              </Grid>
            )}
            <Grid xs={6}>
              <CardButton
                onClick={() =>
                  checkIfUserHasPermission(
                    GlobalVariables.PERMISSIONS.SETTINGS_USER
                  )
                    ? push("/accessprofile/management")
                    : notify(
                        `Acesso negado! Permissão 'Menus - Configuração - Controle de usuários' necessária`
                      )
                }
                label="Grupos de Acesso"
                icon={<Edit />}
              />
            </Grid>

            <Grid xs={6}>
              <CardButton
                onClick={() => push("/system")}
                label="Notas de versão"
                icon={<DescriptionIcon />}
              />
            </Grid>

            <Grid xs={6}>
              <CardButton
                onClick={() => push("/system")}
                label="Sistemas"
                icon={<BuildIcon />}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
