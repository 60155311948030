import {
  FormControlLabel,
  Grid,
  IconButton,
  ListItem,
  ListItemText,
  Popover,
  Switch,
  Typography,
} from "@material-ui/core";
import { useState } from "react";
import { LabelStyled } from "../../../../styles/GlobalStyle";
import HelpIcon from "@material-ui/icons/Help";
import InfoIcon from "@material-ui/icons/Info";

export const TableDescriptionEmail = () => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div>
      <FormControlLabel
        sx={{
          display: "block",
        }}
        control={
          <IconButton
            aria-label="help"
            onClick={handleClick}
            style={{ marginLeft: "20px" }}
          >
            <HelpIcon fontSize="inherit" label="Legenda" />
          </IconButton>
        }
        label="Legenda dos campos"
      />

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Grid
          container
          style={{
            marginBottom: "10px",
            marginTop: "10px",
            marginRight: "20px",
            marginLeft: "20px",
          }}
        >
          <Grid item xs={12}>
            <Grid item flex={2}>
              <LabelStyled color="gray">
                ● 'Arraste sua logomarca aqui': Adicionar a logo do escritório
              </LabelStyled>
            </Grid>

            <Grid item flex={2}>
              <LabelStyled color="black">
                ● [NOME_CLIENTE]: Campo destinado ao nome do cliente
              </LabelStyled>
            </Grid>

            <Grid item flex={2}>
              <LabelStyled color="blue">
                ● [NOME_CND]: Campo destinado ao nome da CND
              </LabelStyled>
            </Grid>

            <Grid item flex={2}>
              <LabelStyled color="orange">
                ● [STATUS]: Campo destinado ao status da CND
              </LabelStyled>
            </Grid>
          </Grid>
        </Grid>
      </Popover>
    </div>
  );
};

export const InformativeTemplate = () => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  return (
    <div>
      <FormControlLabel
        control={
          <IconButton
            color="primary"
            onClick={handleClick}
            style={{ marginLeft: "10px" }}
          >
            <InfoIcon />
          </IconButton>
        }
        label="Saiba mais"
      />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Grid
          container
          style={{
            marginBottom: "10px",
            marginTop: "10px",
            width: "550px",
          }}
        >
          <Grid item xs={12}>
            <ListItem>
              <Typography variant="subtitle2" color="primary">
                Crie e-mails personalizados para informar sobre novas CNDs
                disponíveis.
              </Typography>
            </ListItem>
            <LegendGridItem
              Icon={<Switch color="primary" id={id} />}
              label="Para cada tipo de CND é possível definir apenas UM template padrão. Quando ativo, será usado como principal para envios de e-mails futuros."
            />
          </Grid>
        </Grid>
      </Popover>
    </div>
  );
};

const LegendGridItem = ({ Icon, label }) => {
  return (
    <Grid item xs={12}>
      <ListItem>
        {Icon}
        <ListItemText>
          <Typography variant="subtitle2">{label}</Typography>
        </ListItemText>
      </ListItem>
    </Grid>
  );
};
