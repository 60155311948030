import { search } from "../../BaseRepositoryComponents";

const path = `${process.env.REACT_APP_PAINEL_API_URL}/api/v1/cities`;
const newPath = "https://servicodados.ibge.gov.br/api/v1/localidades/estados";

export async function getCities({ id }) {
  return search({ path: `${path}${id ? `/${id}` : ""}`, method: "GET" });
}

export async function citiesRequestCNDs(uf) {
  return search({
    path: `${newPath}/${uf}/municipios?orderBy=nome`,
    method: "GET",
  });
}
