const options = {
  Genial: (fill) => (
    <svg
      width="16"
      height="16"
      viewBox="0 0 20 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.2028 0.524881C10.0701 0.491706 9.93124 0.491706 9.79854 0.524881L1.4652 2.60821C1.09423 2.70096 0.833984 3.03428 0.833984 3.41667C0.833984 3.79906 1.09423 4.13238 1.4652 4.22512L2.08398 4.37981V11.3333H1.66732C1.20708 11.3333 0.833984 11.7064 0.833984 12.1667V14.6667C0.833984 15.1269 1.20708 15.5 1.66732 15.5H4.16732C4.62755 15.5 5.00065 15.1269 5.00065 14.6667V12.1667C5.00065 11.7064 4.62755 11.3333 4.16732 11.3333H3.75065V4.79648L4.58398 5.00481V8.82108C4.58398 9.11252 4.73624 9.38278 4.98549 9.5338C5.70638 8.34399 4.98633 9.53431 4.98633 9.53431L4.98736 9.53493L4.99 9.53652L4.99759 9.54105L5.02184 9.55531C5.04199 9.56705 5.07005 9.58316 5.10563 9.60301C5.17675 9.6427 5.27808 9.69746 5.40631 9.7622C5.66239 9.8915 6.02795 10.0617 6.47637 10.2315C7.36711 10.5688 8.61674 10.9167 10.0007 10.9167C11.3846 10.9167 12.6342 10.5688 13.5249 10.2315C13.9733 10.0617 14.3389 9.8915 14.595 9.7622C14.7232 9.69746 14.8245 9.6427 14.8957 9.60301C14.9312 9.58316 14.9593 9.56705 14.9795 9.55531L15.0037 9.54105L15.0113 9.53652L15.015 9.53431L15.0158 9.5338C15.2651 9.38278 15.4173 9.11252 15.4173 8.82108V5.00481L18.5361 4.22512C18.9071 4.13238 19.1673 3.79906 19.1673 3.41667C19.1673 3.03428 18.9071 2.70096 18.5361 2.60821L10.2028 0.524881ZM15.0158 9.5338C14.5574 8.77727 14.5797 8.81405 15.0158 9.5338V9.5338ZM2.50065 13V13.8333H3.33398V13H2.50065ZM13.7506 5.42148L10.2028 6.30845C10.0701 6.34163 9.93124 6.34163 9.79854 6.30845L6.25065 5.42148V8.32071C6.45712 8.42172 6.73455 8.54714 7.0666 8.67288C7.84252 8.9667 8.88456 9.25 10.0007 9.25C11.1167 9.25 12.1588 8.9667 12.9347 8.67288C13.2668 8.54714 13.5442 8.42172 13.7506 8.32071V5.42148ZM10.0007 4.64102L5.10324 3.41667L10.0007 2.19231L14.8981 3.41667L10.0007 4.64102Z"
        fill="white"
      />
    </svg>
  ),
  Notification: (fill) => (
    <svg
      width="16"
      height="16"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.0007 2.50065C7.23922 2.50065 5.00065 4.73922 5.00065 7.50065V15.0006H15.0006V7.50065C15.0006 4.73922 12.7621 2.50065 10.0007 2.50065ZM16.6673 15.0006V7.50065C16.6673 3.81875 13.6826 0.833984 10.0007 0.833984C6.31875 0.833984 3.33398 3.81875 3.33398 7.50065V15.0006H1.66732C1.20708 15.0006 0.833984 15.3737 0.833984 15.834C0.833984 16.2942 1.20708 16.6673 1.66732 16.6673H7.11352C7.3157 18.0808 8.5313 19.1673 10.0007 19.1673C11.47 19.1673 12.6856 18.0808 12.8878 16.6673H18.334C18.7942 16.6673 19.1673 16.2942 19.1673 15.834C19.1673 15.3737 18.7942 15.0006 18.334 15.0006H16.6673ZM11.1795 16.6673H8.82178C8.99338 17.1528 9.4564 17.5006 10.0007 17.5006C10.5449 17.5006 11.0079 17.1528 11.1795 16.6673Z"
        fill="white"
      />
    </svg>
  ),
  Config: (fill) => (
    <svg
      width="16"
      height="16"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.4114 1.07806C9.73683 0.752625 10.2645 0.752625 10.5899 1.07806L12.8458 3.33398H15.834C16.2942 3.33398 16.6673 3.70708 16.6673 4.16732V7.15547L18.9232 9.4114C19.2487 9.73683 19.2487 10.2645 18.9232 10.5899L16.6673 12.8458V15.834C16.6673 16.2942 16.2942 16.6673 15.834 16.6673H12.8458L10.5899 18.9232C10.2645 19.2487 9.73683 19.2487 9.4114 18.9232L7.15547 16.6673H4.16732C3.70708 16.6673 3.33398 16.2942 3.33398 15.834V12.8458L1.07806 10.5899C0.752625 10.2645 0.752625 9.73683 1.07806 9.4114L3.33398 7.15547V4.16732C3.33398 3.70708 3.70708 3.33398 4.16732 3.33398H7.15547L9.4114 1.07806ZM10.0007 2.84583L8.08991 4.75657C7.93363 4.91285 7.72166 5.00065 7.50065 5.00065H5.00065V7.50065C5.00065 7.72166 4.91285 7.93363 4.75657 8.08991L2.84583 10.0007L4.75657 11.9114C4.91285 12.0677 5.00065 12.2796 5.00065 12.5007V15.0006H7.50065C7.72166 15.0006 7.93363 15.0884 8.08991 15.2447L10.0007 17.1555L11.9114 15.2447C12.0677 15.0884 12.2796 15.0006 12.5007 15.0006H15.0006V12.5007C15.0006 12.2796 15.0884 12.0677 15.2447 11.9114L17.1555 10.0007L15.2447 8.08991C15.0884 7.93363 15.0006 7.72166 15.0006 7.50065V5.00065H12.5007C12.2796 5.00065 12.0677 4.91285 11.9114 4.75657L10.0007 2.84583ZM10.0007 8.33398C9.08018 8.33398 8.33398 9.08018 8.33398 10.0007C8.33398 10.9211 9.08018 11.6673 10.0007 11.6673C10.9211 11.6673 11.6673 10.9211 11.6673 10.0007C11.6673 9.08018 10.9211 8.33398 10.0007 8.33398ZM6.66732 10.0007C6.66732 8.1597 8.1597 6.66732 10.0007 6.66732C11.8416 6.66732 13.334 8.1597 13.334 10.0007C13.334 11.8416 11.8416 13.334 10.0007 13.334C8.1597 13.334 6.66732 11.8416 6.66732 10.0007Z"
        fill="white"
      />
    </svg>
  ),
  Support: (fill, size) => (
    <svg
      width="16"
      height="16"
      viewBox="0 0 24 25"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_2935_9661"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="24"
        height="25"
      >
        <rect y="0.0430298" width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_2935_9661)">
        <path
          d="M12 22.043L11.75 19.043H11.5C9.13333 19.043 7.125 18.218 5.475 16.568C3.825 14.918 3 12.9097 3 10.543C3 8.17636 3.825 6.16803 5.475 4.51803C7.125 2.86803 9.13333 2.04303 11.5 2.04303C12.6833 2.04303 13.7875 2.26386 14.8125 2.70553C15.8375 3.1472 16.7375 3.75553 17.5125 4.53053C18.2875 5.30553 18.8958 6.20553 19.3375 7.23053C19.7792 8.25553 20 9.3597 20 10.543C20 11.793 19.7958 12.993 19.3875 14.143C18.9792 15.293 18.4208 16.3597 17.7125 17.343C17.0042 18.3264 16.1625 19.218 15.1875 20.018C14.2125 20.818 13.15 21.493 12 22.043ZM14 18.393C15.1833 17.393 16.1458 16.2222 16.8875 14.8805C17.6292 13.5389 18 12.093 18 10.543C18 8.72636 17.3708 7.18886 16.1125 5.93053C14.8542 4.6722 13.3167 4.04303 11.5 4.04303C9.68333 4.04303 8.14583 4.6722 6.8875 5.93053C5.62917 7.18886 5 8.72636 5 10.543C5 12.3597 5.62917 13.8972 6.8875 15.1555C8.14583 16.4139 9.68333 17.043 11.5 17.043H14V18.393ZM11.475 16.018C11.7583 16.018 12 15.918 12.2 15.718C12.4 15.518 12.5 15.2764 12.5 14.993C12.5 14.7097 12.4 14.468 12.2 14.268C12 14.068 11.7583 13.968 11.475 13.968C11.1917 13.968 10.95 14.068 10.75 14.268C10.55 14.468 10.45 14.7097 10.45 14.993C10.45 15.2764 10.55 15.518 10.75 15.718C10.95 15.918 11.1917 16.018 11.475 16.018ZM10.75 12.843H12.25C12.25 12.343 12.3 11.993 12.4 11.793C12.5 11.593 12.8167 11.2264 13.35 10.693C13.65 10.393 13.9 10.068 14.1 9.71803C14.3 9.36803 14.4 8.99303 14.4 8.59303C14.4 7.74303 14.1125 7.10553 13.5375 6.68053C12.9625 6.25553 12.2833 6.04303 11.5 6.04303C10.7667 6.04303 10.15 6.2472 9.65 6.65553C9.15 7.06386 8.8 7.5597 8.6 8.14303L10 8.69303C10.0833 8.4097 10.2417 8.13053 10.475 7.85553C10.7083 7.58053 11.05 7.44303 11.5 7.44303C11.95 7.44303 12.2875 7.56803 12.5125 7.81803C12.7375 8.06803 12.85 8.34303 12.85 8.64303C12.85 8.92636 12.7667 9.18053 12.6 9.40553C12.4333 9.63053 12.2333 9.8597 12 10.093C11.4167 10.593 11.0625 10.9889 10.9375 11.2805C10.8125 11.5722 10.75 12.093 10.75 12.843Z"
          fill={fill}
        />
      </g>
    </svg>
  ),
  SwitchUser: (fill) => (
    <svg
      width="16"
      height="16"
      viewBox="0 0 20 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.75657 0.744078C5.08201 1.06951 5.08201 1.59715 4.75657 1.92259L3.67916 3H18.334C18.7942 3 19.1673 3.3731 19.1673 3.83333C19.1673 4.29357 18.7942 4.66667 18.334 4.66667H3.67916L4.75657 5.74408C5.08201 6.06951 5.08201 6.59715 4.75657 6.92259C4.43114 7.24803 3.9035 7.24803 3.57806 6.92259L1.07806 4.42259C0.752625 4.09715 0.752625 3.56951 1.07806 3.24408L3.57806 0.744078C3.9035 0.418641 4.43114 0.418641 4.75657 0.744078ZM15.2447 9.07741C15.5702 8.75197 16.0978 8.75197 16.4232 9.07741L18.9232 11.5774C19.2487 11.9028 19.2487 12.4305 18.9232 12.7559L16.4232 15.2559C16.0978 15.5814 15.5702 15.5814 15.2447 15.2559C14.9193 14.9305 14.9193 14.4028 15.2447 14.0774L16.3221 13H1.66732C1.20708 13 0.833984 12.6269 0.833984 12.1667C0.833984 11.7064 1.20708 11.3333 1.66732 11.3333H16.3221L15.2447 10.2559C14.9193 9.93049 14.9193 9.40285 15.2447 9.07741Z"
        fill="white"
      />
    </svg>
  ),
  HamburguerIcon: (fill) => (
    <svg width="24" height="25" viewBox="0 0 24 25" fill={fill}>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.97485 6.01794C2.97485 5.46566 3.42257 5.01794 3.97485 5.01794H19.9749C20.5272 5.01794 20.9749 5.46566 20.9749 6.01794C20.9749 6.57023 20.5272 7.01794 19.9749 7.01794H3.97485C3.42257 7.01794 2.97485 6.57023 2.97485 6.01794ZM2.97485 12.0179C2.97485 11.4657 3.42257 11.0179 3.97485 11.0179H19.9749C20.5272 11.0179 20.9749 11.4657 20.9749 12.0179C20.9749 12.5702 20.5272 13.0179 19.9749 13.0179H3.97485C3.42257 13.0179 2.97485 12.5702 2.97485 12.0179ZM2.97485 18.0179C2.97485 17.4657 3.42257 17.0179 3.97485 17.0179H19.9749C20.5272 17.0179 20.9749 17.4657 20.9749 18.0179C20.9749 18.5702 20.5272 19.0179 19.9749 19.0179H3.97485C3.42257 19.0179 2.97485 18.5702 2.97485 18.0179Z"
        fill={fill}
      />
    </svg>
  ),
};

const IconsHeader = ({ icon, color, size }) => options[icon](color);
export default IconsHeader;
