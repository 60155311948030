import React, { useContext, useEffect, useState } from "react";
import Loading from "../../../components/basics/Loading";
import { GlobalSubheader } from "../../../components/subHeader/GlobalSubheader";
import { Button, Grid, IconButton, SvgIcon, Tooltip } from "@material-ui/core";
import { ReactComponent as SaveCloudIcon } from "../../../assets/icons/saving-cloud.svg";
import { ReactComponent as DeleteIcon } from "../../../assets/icons/delete.svg";
import { ReactComponent as EditIcon } from "../../../assets/icons/edit.svg";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { GlobalContext } from "../../../contexts/GlobalContext";
import { DefaultPageContainer } from "../../../components/layoutContainer/styles/LayoutContainer";
import { DataGrid } from "@material-ui/data-grid";
import { Add } from "@material-ui/icons";
import { notify } from "../../../utils/notify";
import { getUsersByEntityId } from "../../../repositories/portal_api/Users";
import { DialogAddUser } from "./DialogAddUser";
import { DialogConfirm } from "./DialogConfirm";
import { postAccountantClientUsers } from "../../../repositories/portal_api/AccountantClient";
import { postInvite } from "../../../repositories/panel/v1/integrations/EmailRepository";
import { LightTooltip } from "../../../styles/GlobalStyle.js";
import SendIcon from "../../../components/icons/SendIcon.js";

// [ ] - Implementar rota de consulta de usuários da empresa
// [ ] - DataGrid com os usuários da empresa
//      [ ] - Tags para grupos de usuário (Master primary, outros secundary)
//      [ ] - Deleção de usuário
// [ ] - Implementar dialog de edição/cadastro de usuário

export const ConfigurePanel = ({ location }) => {
  const { push } = useHistory();
  const { setBreadcrumbs, companyUser } = useContext(GlobalContext);
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [currentUser, setCurrentUser] = useState({});
  const [openUserDialog, setOpenUserDialog] = useState(false);
  const [openConfirmDeleteUser, setOpenConfirmDeleteUser] = useState(false);
  const [openConfirmDeleteSelectedUsers, setOpenConfirmDeleteSelectedUsers] =
    useState(false);

  const [openConfirmSaveUsers, setOpenConfirmSaveUsers] = useState(false);
  const [openConfirmCancel, setOpenConfirmCancel] = useState(false);

  const [isBlocking, setIsBlocking] = useState(true);
  const [blockedNavigation, setBlockedNavigation] = useState(null);

  const history = useHistory();

  useEffect(() => {
    const unblock = history.block((location, action) => {
      if (isBlocking) {
        setIsBlocking(false);
        setBlockedNavigation({ location, action });
        setOpenConfirmCancel(true);
        return false;
      }
    });
    return unblock;
  }, [isBlocking, history]);

  let columns = [
    {
      field: "first_name",
      headerName: "Primeiro Nome",
      flex: 0.5,
    },
    {
      field: "last_name",
      headerName: "Último Nome",
      flex: 0.5,
    },
    {
      field: "email",
      headerName: "Email",
      flex: 0.7,
    },
    {
      field: "permission_group_name",
      headerName: "Grupo de Permissões",
      flex: 0.7,
      minWidth: 200,
      renderHeader: () => (
        <Grid container justifyContent="center">
          <div
            className="MuiDataGrid-columnHeaderTitle"
            style={{ textAlign: "center" }}
          >
            Grupo de Permissões
          </div>
        </Grid>
      ),
      renderCell: (params) => (
        //TODO: Implementar tags de grupos de permissões
        <Grid container justifyContent="flex-start" style={{}}>
          <div
            style={{
              textAlign: "center",
              background:
                params?.row?.permission_group_name === "Master"
                  ? "#E7EEFE"
                  : "#F5F5F5",
              color:
                params?.row?.permission_group_name === "Master"
                  ? "#0036B3"
                  : "#424242",
              margin: 10,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: 5,
              padding: 10,
              maxHeight: 30,
            }}
          >
            {params?.row?.permission_group_name}
          </div>
          {params?.row?.is_master && (
            <div
              style={{
                textAlign: "center",
                background: "#CCF4CC",
                color: "#1A6B1A",
                margin: 10,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: 5,
                padding: 10,
                maxHeight: 30,
              }}
            >
              Principal
            </div>
          )}
        </Grid>
      ),
    },
    {
      field: "actions",
      headerName: "Ações",
      sortable: false,
      flex: 0.4,
      renderCell: (params) => (
        <>
          <LightTooltip title="Enviar Convite " placement="top">
            <IconButton
              size="small"
              onClick={() => {
                setLoading(true);
                postInvite({
                  client_id: location?.state?.customerId,
                  user_id: params?.row?.id,
                  accountant_id: localStorage.getItem("companyIdPanel"),
                })
                  .then((response) => {
                    if (response?.success) {
                      params.row.portal_invite_sended = true;
                      notify("Convite enviado com sucesso!", true, "success");
                    } else {
                      notify(response?.message);
                    }
                  })
                  .catch((error) => {
                    console.error(error);
                  })
                  .finally(() => {
                    setLoading(false);
                  });
              }}
            >
              <SendIcon
                fill={params?.row?.portal_invite_sended ? "#ff9800" : "#616161"}
              />
            </IconButton>
          </LightTooltip>

          <LightTooltip title="Editar" placement="top">
            <IconButton
              size="small"
              onClick={() => {
                if (params?.row?.is_master) {
                  return notify(
                    "Não é possível editar o usuário master principal."
                  );
                } else {
                  setCurrentUser(params?.row);
                  setOpenUserDialog(true);
                }
              }}
            >
              <EditIcon></EditIcon>
            </IconButton>
          </LightTooltip>

          <LightTooltip title="Excluir" placement="top">
            <IconButton
              size="small"
              onClick={(e) => {
                if (params?.row?.is_master) {
                  return notify(
                    "Não é possível deletar o usuário master principal."
                  );
                } else {
                  setCurrentUser(params?.row);
                  setOpenConfirmDeleteUser(true);
                }
              }}
            >
              <DeleteIcon></DeleteIcon>
            </IconButton>
          </LightTooltip>
        </>
      ),
    },
  ];

  useEffect(() => {
    if (location?.state?.companyName) {
      setBreadcrumbs([
        {
          name: location?.state?.companyName,
          href: "",
        },
        {
          name: "Configurar Plataforma",
          href: "",
        },
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location?.state?.companyName]);

  useEffect(() => {
    if (location?.state?.customerId) {
      if (location.pathname === "/setting/users") {
        setBreadcrumbs([{ name: "Usuários", href: "" }]);
      }

      setLoading(true);
      setIsBlocking(false);
      //TODO: GET USERS OF COMPANY
      getUsersByEntityId(location?.state?.customerId)
        .then((response) => {
          if (response?.success) {
            setIsBlocking(true);
            setUsers(response?.data);
          } else {
            notify(response?.message);
          }
        })
        .catch((error) => {
          console.error(error);
          push("customermanagement/list");
        })
        .finally(() => {
          setLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location?.state?.customerId]);

  const handleDeleteUser = (user) => {
    if (user.is_master) {
      return notify("Não é possível deletar o usuário master.");
    }

    let newUserList = users.filter((u) => u.id !== user.id);
    setSelectedUsers([]);
    setUsers(newUserList);
  };

  const handleDeleteSelectedUsers = () => {
    let selectedHasMaster = false;
    users.forEach((u) => {
      if (u.is_master && selectedUsers.includes(u.id)) {
        selectedHasMaster = true;
      }
    });

    if (selectedHasMaster) {
      return notify("Não é possível deletar o usuário master.");
    }

    let newUserList = users.filter((u) => !selectedUsers.includes(u.id));

    setSelectedUsers([]);
    setUsers(newUserList);
  };

  const handleSaveUsers = () => {
    users.map((u) => {
      if (typeof u?.id !== "number" && u?.id !== null && u?.id !== undefined) {
        if (u.id.includes("NEW")) delete u.id;
      }
      return u;
    });

    setLoading(true);
    setIsBlocking(false);

    postAccountantClientUsers(location?.state?.customerId, users)
      .then((response) => {
        if (response?.success) {
          notify("Usuários atualizados com sucesso!", true, "success");
          if (location.pathname === "/setting/users") {
            push("/setting");
          } else {
            push("/customermanagement/list");
          }
        } else {
          notify(response?.message);
        }
      })
      .catch((error) => {
        console.error(error);
        setIsBlocking(true);
        notify("Erro ao atualizar usuários.");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <Loading open={loading} />

      <DialogConfirm
        open={openConfirmDeleteUser}
        description={
          <>
            Deseja realmente <b style={{ color: "red" }}>excluir</b> o usuário{" "}
            <b>{currentUser.email}</b>?
          </>
        }
        handleClose={() => {
          setOpenConfirmDeleteUser(false);
        }}
        handleConfirm={() => {
          handleDeleteUser(currentUser);
          setOpenConfirmDeleteUser(false);
        }}
        title="Excluir Usuário"
      />
      <DialogConfirm
        open={openConfirmDeleteSelectedUsers}
        description={
          <>
            Deseja realmente <b style={{ color: "red" }}>excluir</b> todos os
            usuários selecionados?
          </>
        }
        handleClose={() => {
          setOpenConfirmDeleteSelectedUsers(false);
        }}
        handleConfirm={() => {
          selectedUsers.forEach((u) => {
            if (u.is_master) {
              return notify("Não é possível deletar o usuário master.");
            }
          });
          handleDeleteSelectedUsers();
          setOpenConfirmDeleteSelectedUsers(false);
        }}
        title="Excluir Usuário"
      />

      <DialogConfirm
        open={openConfirmSaveUsers}
        description={<>Deseja salvar as alterações realizadas nos usuários?</>}
        handleClose={() => {
          setOpenConfirmSaveUsers(false);
        }}
        handleConfirm={() => {
          handleSaveUsers();
          setOpenConfirmSaveUsers(false);
        }}
        title="Salvar Alterações"
      />

      <DialogConfirm
        open={openConfirmCancel}
        title="Cancelar"
        description={
          <>
            Deseja cancelar a operação?
            <div style={{ fontSize: 12 }}>
              * Os dados alterados e inseridos que não foram salvos serão
              perdidos
            </div>
          </>
        }
        handleClose={() => {
          setIsBlocking(true);
          setOpenConfirmCancel(false);
        }}
        handleConfirm={() => {
          setIsBlocking(false);
          setOpenConfirmCancel(false);
          if (blockedNavigation !== null) {
            if (blockedNavigation.action === "PUSH") {
              history.push(blockedNavigation.location);
            } else {
              history.replace(blockedNavigation.location);
            }
          }
        }}
      />

      <DialogAddUser
        open={openUserDialog}
        user={currentUser}
        entityId={location?.state?.customerId}
        handleClose={() => {
          setCurrentUser({});
          setOpenUserDialog(false);
        }}
        handleSave={(user) => {
          if (user.id) {
            if (user.is_master) {
              return notify("Não é possível editar o usuário master.");
            }

            let newUserList = users.map((u) => {
              if (u.id === user.id) {
                return user;
              }
              return u;
            });

            setUsers(newUserList);
          } else {
            user.id = "NEW_" + Math.random();
            setUsers([...users, user]);
          }

          setCurrentUser({});
          setOpenUserDialog(false);
        }}
        firstUser={false}
      ></DialogAddUser>
      <GlobalSubheader
        title={
          location.pathname === "/setting/users"
            ? "Configurações"
            : "Gestão de Clientes"
        }
        titleLocation={
          location.pathname === "/setting/users"
            ? "/setting"
            : "/customermanagement/list"
        }
      >
        <div style={{ gap: 5, display: "flex", justifyContent: "flex-end" }}>
          <Button
            style={{
              textTransform: "none",
            }}
            onClick={() => {
              if (location?.state?.customerId === companyUser?.companyId) {
                push("/setting");
              } else {
                push("/customermanagement/list");
              }
            }}
          >
            Cancelar
          </Button>
          <Button
            style={{
              textTransform: "none",
              background: "#0036b3",
              color: "#FFF",
            }}
            onClick={() => {
              setOpenConfirmSaveUsers(true);
            }}
            startIcon={
              <SvgIcon>
                <SaveCloudIcon />
              </SvgIcon>
            }
          >
            Salvar
          </Button>
        </div>
      </GlobalSubheader>
      <DefaultPageContainer>
        <div
          style={{
            background: "#fff",
            border: "1px solid #E0E0E0",
            borderRadius: 15,
            padding: 15,
          }}
        >
          <div
            style={{
              display: "flex",
              borderBottom: "1px solid #CECFD0",
              fontWeight: "600",
              fontSize: "18px",
              color: "#424242",
              paddingTop: "20px",
              paddingBottom: "10px",
              flexDirection: "column",
            }}
          >
            Usuários da Plataforma
          </div>
          <div
            style={{
              display: "flex",
              marginTop: 20,
              background: "#E0E0E0",
              justifyContent: "flex-end",
              gap: "10px",
              padding: "10px",
            }}
          >
            <div>
              <Tooltip title="Excluir Usuário(s)">
                <IconButton
                  size="small"
                  onClick={() => {
                    if (selectedUsers.length > 0) {
                      setOpenConfirmDeleteSelectedUsers(true);
                    } else {
                      notify("Selecione ao menos um usuário para excluir.");
                    }
                  }}
                >
                  <SvgIcon>
                    <DeleteIcon></DeleteIcon>
                  </SvgIcon>
                </IconButton>
              </Tooltip>
            </div>

            <div>
              <Tooltip title="Adicionar Usuário">
                <Button
                  variant="contained"
                  color="primary"
                  endIcon={<Add />}
                  style={{ textTransform: "none" }}
                  onClick={() => {
                    setCurrentUser({});
                    setOpenUserDialog(true);
                  }}
                >
                  Adicionar
                </Button>
              </Tooltip>

              {/* <IconButton
                size="small"
                style={{ color: "#fff", background: "#0036b3" }}
                onClick={() => {
                  setCurrentUser({});
                  setOpenUserDialog(true);
                }}
              >
                <Add></Add>
              </IconButton> */}
            </div>
          </div>

          <DataGrid
            autoHeight
            rows={users}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 5 },
              },
            }}
            isRowSelectable={(params) => {
              return !params.row.is_master;
            }}
            disableSelectionOnClick
            checkboxSelection
            onSelectionModelChange={(newSelection) => {
              setSelectedUsers(newSelection);
            }}
            selectionModel={selectedUsers}
          />
        </div>
      </DefaultPageContainer>
    </>
  );
};
