import * as React from "react";

function HelpIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={25}
      viewBox="0 0 24 25"
      {...props}
    >
      <path d="M8 9.355a4 4 0 115 3.874v1.127a1 1 0 11-2 0v-2a1 1 0 011-1 2 2 0 10-2-2 1 1 0 11-2 0zm5.25 8.25a1.25 1.25 0 11-2.5 0 1.25 1.25 0 012.5 0z" />
      <path
        fillRule="evenodd"
        d="M4.222 4.265A10.969 10.969 0 0112 1.043c3.037 0 5.789 1.232 7.778 3.222A10.969 10.969 0 0123 12.043c0 3.037-1.232 5.789-3.222 7.778A10.969 10.969 0 0112 23.043a10.969 10.969 0 01-7.778-3.222A10.969 10.969 0 011 12.043c0-3.037 1.232-5.789 3.222-7.778zM12 3.043a8.969 8.969 0 00-6.364 2.636A8.969 8.969 0 003 12.043a8.969 8.969 0 002.636 6.364A8.969 8.969 0 0012 21.043a8.968 8.968 0 006.364-2.636A8.969 8.969 0 0021 12.043a8.969 8.969 0 00-2.636-6.364A8.969 8.969 0 0012 3.043z"
        clipRule="evenodd"
      />
    </svg>
  );
}

export default HelpIcon;
