export const RpaTrackingIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="22"
      viewBox="0 0 21 22"
      fill="none"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.80619 0.327148H15.4598V5.85294H6.80619V0.327148ZM8.16157 1.68253V4.49756H14.1044V1.68253H8.16157Z"
        fill="#616161"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.8062 8.0423H20.6728V13.5681H6.8062V8.0423ZM8.16158 9.39769V12.2127H19.3174V9.39769H8.16158Z"
        fill="#616161"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.8062 15.7575H15.4598V21.2832H6.8062V15.7575ZM8.16158 17.1128V19.9279H14.1044V17.1128H8.16158Z"
        fill="#616161"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0.404297 19.3199C0.404297 18.0148 1.46238 16.9568 2.76752 16.9568C4.07266 16.9568 5.13075 18.0148 5.13075 19.3199C5.13075 20.6251 4.07266 21.6831 2.76752 21.6831C1.46238 21.6831 0.404297 20.6251 0.404297 19.3199ZM2.76752 18.1384C2.11493 18.1384 1.5859 18.6674 1.5859 19.3199C1.5859 19.9725 2.11493 20.5015 2.76752 20.5015C3.42011 20.5015 3.94914 19.9725 3.94914 19.3199C3.94914 18.6674 3.42011 18.1384 2.76752 18.1384Z"
        fill="#616161"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0.404297 2.77722C0.404297 1.47205 1.46238 0.414032 2.76752 0.414032C4.07266 0.414032 5.13075 1.47205 5.13075 2.77722C5.13075 4.08239 4.07266 5.14041 2.76752 5.14041C1.46238 5.14041 0.404297 4.08239 0.404297 2.77722ZM2.76752 1.59564C2.11493 1.59564 1.5859 2.12467 1.5859 2.77722C1.5859 3.42978 2.11493 3.95881 2.76752 3.95881C3.42011 3.95881 3.94914 3.42978 3.94914 2.77722C3.94914 2.12467 3.42011 1.59564 2.76752 1.59564Z"
        fill="#616161"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0.404297 11.0482C0.404297 9.74305 1.46238 8.68503 2.76752 8.68503C4.07266 8.68503 5.13075 9.74305 5.13075 11.0482C5.13075 12.3534 4.07266 13.4114 2.76752 13.4114C1.46238 13.4114 0.404297 12.3534 0.404297 11.0482ZM2.76752 9.86663C2.11493 9.86663 1.5859 10.3957 1.5859 11.0482C1.5859 11.7008 2.11493 12.2298 2.76752 12.2298C3.42011 12.2298 3.94914 11.7008 3.94914 11.0482C3.94914 10.3957 3.42011 9.86663 2.76752 9.86663Z"
        fill="#616161"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.17694 17.547V4.54953H3.35854V17.547H2.17694Z"
        fill="#616161"
      />
      <path
        d="M0.995117 11.0482C0.995117 10.0694 1.78868 9.27585 2.76754 9.27585C3.7464 9.27585 4.53996 10.0694 4.53996 11.0482C4.53996 12.0271 3.7464 12.8206 2.76754 12.8206C1.78868 12.8206 0.995117 12.0271 0.995117 11.0482Z"
        fill="#616161"
      />
    </svg>
  );
};

export const ArrowRightIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.2879 5.29777C14.6785 4.90725 15.3116 4.90725 15.7021 5.29778L21.7021 11.2978C22.0926 11.6883 22.0926 12.3215 21.7021 12.712L15.7021 18.712C15.3116 19.1025 14.6785 19.1025 14.2879 18.712C13.8974 18.3215 13.8974 17.6883 14.2879 17.2978L18.5808 13.0049H2.995C2.44271 13.0049 1.995 12.5572 1.995 12.0049C1.995 11.4526 2.44271 11.0049 2.995 11.0049H18.5808L14.2879 6.71199C13.8974 6.32146 13.8974 5.6883 14.2879 5.29777Z"
        fill="#616161"
      />
    </svg>
  );
};

export const VerifiedIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <g clip-path="url(#clip0_10672_3159)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M11.9951 0.00488281C10.9601 0.00488281 9.23063 0.402383 7.60313 0.844883C5.93813 1.29488 4.25963 1.82738 3.27263 2.14988C2.85996 2.28616 2.49411 2.53608 2.2171 2.87093C1.94009 3.20579 1.76316 3.61199 1.70663 4.04288C0.812625 10.7584 2.88713 15.7354 5.40413 19.0279C6.47189 20.436 7.7445 21.6762 9.17963 22.7074C9.75863 23.1169 10.2956 23.4304 10.7516 23.6449C11.1716 23.8429 11.6231 24.0049 11.9951 24.0049C12.3671 24.0049 12.8171 23.8429 13.2386 23.6449C13.7889 23.3787 14.3149 23.065 14.8106 22.7074C16.2458 21.6762 17.5184 20.436 18.5861 19.0279C21.1031 15.7354 23.1776 10.7584 22.2836 4.04288C22.2274 3.61172 22.0505 3.2052 21.7735 2.87006C21.4965 2.53492 21.1305 2.28477 20.7176 2.14838C19.2847 1.6789 17.8408 1.24377 16.3871 0.843383C14.7596 0.403883 13.0301 0.00488281 11.9951 0.00488281ZM15.2141 7.72388C15.355 7.58305 15.546 7.50393 15.7451 7.50393C15.9443 7.50394 16.1353 7.58305 16.2761 7.72388C16.417 7.86471 16.4961 8.05572 16.4961 8.25488C16.4961 8.45405 16.417 8.64505 16.2761 8.78588L11.7761 13.2859C11.7065 13.3557 11.6237 13.4111 11.5326 13.449C11.4415 13.4868 11.3438 13.5062 11.2451 13.5062C11.1465 13.5062 11.0488 13.4868 10.9577 13.449C10.8666 13.4111 10.7838 13.3557 10.7141 13.2859L8.46413 11.0359C8.39439 10.9662 8.33908 10.8834 8.30134 10.7923C8.2636 10.7011 8.24418 10.6035 8.24418 10.5049C8.24418 10.4063 8.2636 10.3086 8.30134 10.2175C8.33908 10.1264 8.39439 10.0436 8.46413 9.97388C8.53386 9.90415 8.61664 9.84884 8.70775 9.8111C8.79886 9.77336 8.89651 9.75393 8.99513 9.75393C9.09374 9.75393 9.19139 9.77336 9.2825 9.8111C9.37361 9.84884 9.45639 9.90415 9.52613 9.97388L11.2451 11.6944L15.2141 7.72388Z"
          fill="#0036B3"
        />
      </g>
      <defs>
        <clipPath id="clip0_10672_3159">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(-0.00488281 0.00488281)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export const VerificationInProgressIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <g clip-path="url(#clip0_10672_3000)">
        <path
          d="M8.00213 2.38988C6.57344 2.78336 5.15452 3.21149 3.74663 3.67388C3.61797 3.71537 3.50359 3.79225 3.41658 3.8957C3.32957 3.99916 3.27345 4.12502 3.25463 4.25888C2.42363 10.4944 4.34363 15.0439 6.63413 18.0409C7.60347 19.3228 8.75987 20.4519 10.0646 21.3904C10.5836 21.7564 11.0426 22.0204 11.4041 22.1899C11.5841 22.2749 11.7306 22.3339 11.8436 22.3669C11.8934 22.382 11.944 22.3945 11.9951 22.4044C12.0457 22.3945 12.0958 22.382 12.1451 22.3669C12.2591 22.3329 12.4061 22.2739 12.5861 22.1899C12.9461 22.0204 13.4066 21.7549 13.9256 21.3904C15.2304 20.4519 16.3868 19.3228 17.3561 18.0409C19.6466 15.0454 21.5666 10.4944 20.7356 4.25888C20.7168 4.12502 20.6607 3.99916 20.5737 3.8957C20.4867 3.79225 20.3723 3.71537 20.2436 3.67388C19.2671 3.35438 17.6186 2.83388 15.9881 2.39138C14.3231 1.93988 12.7916 1.60538 11.9951 1.60538C11.2001 1.60538 9.66713 1.93838 8.00213 2.38988ZM7.60313 0.844883C9.23063 0.402383 10.9601 0.00488281 11.9951 0.00488281C13.0301 0.00488281 14.7596 0.402383 16.3871 0.844883C18.0521 1.29488 19.7306 1.82738 20.7176 2.14988C21.1303 2.28616 21.4961 2.53608 21.7732 2.87093C22.0502 3.20579 22.2271 3.61199 22.2836 4.04288C23.1776 10.7584 21.1031 15.7354 18.5861 19.0279C17.5184 20.436 16.2458 21.6762 14.8106 22.7074C14.3149 23.065 13.7889 23.3787 13.2386 23.6449C12.8186 23.8429 12.3671 24.0049 11.9951 24.0049C11.6231 24.0049 11.1731 23.8429 10.7516 23.6449C10.2013 23.3787 9.67534 23.065 9.17963 22.7074C7.7445 21.6762 6.47189 20.436 5.40413 19.0279C2.88713 15.7354 0.812625 10.7584 1.70663 4.04288C1.76316 3.61199 1.94009 3.20579 2.2171 2.87093C2.49411 2.53608 2.85996 2.28616 3.27263 2.14988C4.70551 1.6804 6.14942 1.24527 7.60313 0.844883Z"
          fill="#63708A"
        />
        <path
          d="M12.9951 5.96189C12.4428 5.96189 11.9951 6.40961 11.9951 6.96189V10.9619L7.99513 10.9619C7.44285 10.9619 6.99513 11.4096 6.99513 11.9619C6.99513 12.5142 7.44285 12.9619 7.99513 12.9619H12.9951C13.5474 12.9619 13.9951 12.5142 13.9951 11.9619V6.96189C13.9951 6.40961 13.5474 5.96189 12.9951 5.96189Z"
          fill="#63708A"
        />
      </g>
      <defs>
        <clipPath id="clip0_10672_3000">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(-0.00488281 0.00488281)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
