import { Chip, Dialog } from "@material-ui/core";
import { DataGrid } from "@material-ui/data-grid";

import styled, { css } from "styled-components";

const blue = (props) => props.theme.palette.blue;
const background = (props) => props.theme.palette.background;
const white = (props) => props.theme.palette.white;

export const DataGridCnd = styled(DataGrid)`
  border: none !important;
  ${(props) =>
    props.maxHeight &&
    css`
      max-height: ${props.maxHeight}!important;
    `}
  border: none !important;

  .MuiDataGrid-footerContainer {
    display: none;
  }
`;

export const TitleTable = styled.label`
  font-size: 20px;
  color: ${blue};
  font-weight: 600;
`;

export const ChipCnd = styled(Chip)`
  ${(props) =>
    props["bg-color"] &&
    css`
      background-color: ${(props) => props.theme.palette[props["bg-color"]]};
    `};

  ${(props) =>
    props.$marginRight &&
    css`
      margin-right: ${(props) => props.$marginRight}px;
    `};

  ${(props) =>
    props.$marginLeft &&
    css`
      margin-left: ${(props) => props.$marginLeft}px;
    `};

  width: ${(props) => props.$width || "50"}px;
  height: 10px;
`;

export const DialogCnd = styled(Dialog)`
  .MuiPaper-root {
    min-width: 600px !important;
  }
`;

export const ContainerTab = styled.div`
  width: 100%;

  .MuiPaper-elevation4 {
    box-shadow: none !important;
  }

  .MuiAppBar-root {
    background-color: ${background};
  }

  .MuiTabs-indicator {
    height: 5px;
    border-radius: 5px;
    background-color: ${white} !important;
  }
`;

export const PulseIcon = styled.div`
  border-radius: 6px;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);

  height: 10px;
  align-items: center;
  display: flex;

  transform: scale(1);
  /* background: red; */
  box-shadow: 0 0 0 0 rgba(52, 172, 224, 1);
  animation: pulse-gray 1.2s infinite;

  @keyframes pulse-gray {
    0% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(52, 172, 224, 0.7);
    }

    70% {
      transform: scale(1);
      box-shadow: 0 0 0 10px rgba(52, 172, 224, 0);
    }

    100% {
      transform: scale(0.95);
      box-shadow: 0 0 0 0 rgba(52, 172, 224, 0);
    }
  }
`;
