import { search } from "../../BaseRepositoryComponents";

const path = `${process.env.REACT_APP_PAINEL_API_URL}/api/v1/panel`;

export async function getPanels({ id }) {
  return search({ path: `${path}${id ? `/${id}` : ""}`, method: "GET" });
}

export async function getPanelPositions({ id }) {
  return search({
    path: `${path}/${id}/widget`,
    method: "GET",
  });
}

export async function putPanelPositions({ id, body }) {
  return search({
    path: `${path}/${id}/widget`,
    method: "PUT",
    myBody: body,
  });
}

export async function putPanel({ body, id }) {
  return search({
    path: `${path}/${id}`,
    method: "PUT",
    myBody: body,
  });
}

export async function postPanel({ body }) {
  return search({
    path: `${path}`,
    method: "POST",
    myBody: body,
  });
}

export async function deletePanel({ id }) {
  return search({
    path: `${path}/${id}`,
    method: "DELETE",
  });
}

export async function favoritePanel({ id, favorited }) {
  return search({
    path: `${path}/${id}/favorited`,
    method: "PUT",
    myBody: { favorited },
  });
}
