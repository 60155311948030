import { useState, useContext, useEffect } from "react";

// Icons
import { Refresh } from "@material-ui/icons";

// Components
import {
  Grid,
  Table,
  TableBody,
  TableHead,
  TablePagination,
} from "@material-ui/core";

import TextFieldErp from "../../../components/basics/TextFieldErp";
import Btn from "../../../components/buttons/Btn";

import { TableTabCndAccountantRow } from "./TableTabCndAccountantRow";
import { TableDescription } from "./TableDescription";
import { ModalAccountantUploadCnd } from "./ModalAccountantUploadCnd";

// Styled Components
import { GridStyled, Margin } from "../../../styles/GlobalStyle";

import { StyledTableRow, StyledTableCell } from "../styles/CndAccountant";

// controllers
import { useCndAccountantController } from "../controllers/CndAccountantController";

// Contexts
import { CndAccountantContext } from "../contexts/CndAccountantContext";

export const TableTabCndAccountant = () => {
  const { getRequestedSchedulledExecutions } = useCndAccountantController();
  const { cndToUpload, requestedCnd } = useContext(CndAccountantContext);

  const [filterText, setFilterText] = useState("");
  const [filteredCnds, setFilteredCnds] = useState([]);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);

  // Busca de cnds
  useEffect(() => {
    getRequestedSchedulledExecutions();
  }, [getRequestedSchedulledExecutions]);

  // Filtro de Clientes
  useEffect(() => {
    setFilteredCnds(
      !requestedCnd.length
        ? []
        : requestedCnd.filter((entity) => {
            return (
              entity.document?.includes(filterText) ||
              entity.name
                ?.toLowerCase()
                .includes(filterText.toLocaleLowerCase()) ||
                filterText.replace(/[^\d]+/g, '').includes(entity.document)
            );
          })
    );
    setPage(0);
  }, [filterText, requestedCnd]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  return (
    <>
      {!!cndToUpload?.id && <ModalAccountantUploadCnd />}

      <Grid container spacing={1} key={1} alignItems="center">
        <Grid item lg={8} xs={12}>
          <TextFieldErp
            variant="filled"
            label="Pesquisa por CNPJ ou Razão Social"
            value={filterText}
            setValue={setFilterText}
          />
        </Grid>
        <GridStyled item lg={4} xs={12} height="56px">
          <Btn
            label="ATUALIZAR STATUS DAS CONSULTAS"
            startIcon={<Refresh />}
            bg-color="blue"
            hovercolor="gray"
            full={true}
            onClick={() => getRequestedSchedulledExecutions()}
          />
        </GridStyled>
      </Grid>

      <Margin bottom="20" />

      <Table aria-label="collapsible table" size="small">
        <TableHead>
          <StyledTableRow>
            <StyledTableCell />
            <StyledTableCell align="left">CNPJ</StyledTableCell>
            <StyledTableCell align="left">Razão Social</StyledTableCell>
            <StyledTableCell align="center">Email</StyledTableCell>

            <StyledTableCell align="center" width="260px">
              Status
            </StyledTableCell>
            <StyledTableCell align="center" width="100px">
              Situação
            </StyledTableCell>
            <StyledTableCell align="center">Total</StyledTableCell>
          </StyledTableRow>
        </TableHead>
        <TableBody>
          {filteredCnds
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((row, index) => (
              <TableTabCndAccountantRow key={index} row={row} />
            ))}
        </TableBody>
      </Table>
      <TablePagination
        component="div"
        count={filteredCnds.length}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[10, 25, 50, 100]}
        page={page}
        onRowsPerPageChange={(event) => setRowsPerPage(event.target.value)}
        onPageChange={handleChangePage}
        labelRowsPerPage={null}
      />

      <Margin bottom="20" />

      <TableDescription />
    </>
  );
};
