import {
  Avatar,
  Divider,
  Grid,
  ListItem,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { GridStyled } from "../../../styles/GlobalStyle";
import { useChatContext } from "../contexts/ChatContext";
import { BadgeAvatar } from "../styles/ChatMonitor";
import { getAvatarContent, getDateDiff } from "../utils";

const RenderListItemAttendat = ({ item }) => {
  const { settings } = useChatContext();

  const getDescriptionStatus = () => {
    return (
      settings?.reason?.filter((i) => i.state === item?.statusAttendance)[0]
        ?.description || "Carregando..."
    );
  };

  return (
    <GridStyled width="100%">
      <ListItem alignItems="flex-start">
        <Grid container>
          <GridStyled xs={2} position="relative">
            <Tooltip title={item?.attendant?.name} enterDelay={300}>
              <Avatar alt={item?.attendant?.name}>
                {getAvatarContent(item?.attendant?.name)}
              </Avatar>
            </Tooltip>

            <Tooltip
              title={
                item?.statusAttendance !== 0
                  ? settings?.reason?.filter(
                      (i) => i.state === item?.statusAttendance
                    )[0]?.description
                  : "Disponível"
              }
              enterDelay={300}
            >
              <BadgeAvatar
                background-color={
                  item?.statusAttendance !== 0
                    ? "rgb(212, 19, 13, 1)"
                    : "rgb(146, 195, 83)"
                }
              />
            </Tooltip>
          </GridStyled>
          <Grid xs={9} container alignItems="center">
            <GridStyled item margin-left="5">
              {item?.attendant?.name}
            </GridStyled>
            {item?.statusAttendance !== 0 && (
              <Grid>
                <GridStyled item margin-left="5">
                  <Tooltip title={getDescriptionStatus()} enterDelay={300}>
                    <Typography
                      component="span"
                      variant="body2"
                      color="text.primary"
                    >
                      {`${getDescriptionStatus()} | hà ${getDateDiff({
                        startDate: item?.timeOff,
                      })}`}
                    </Typography>
                  </Tooltip>
                </GridStyled>
              </Grid>
            )}
          </Grid>
          <Grid xs={1} container alignContent="center" alignItems="center">
            <Tooltip
              title={`${item?.attendant?.name} está com ${item?.qtdAttendances} atendimentos`}
              placement="top"
            >
              <GridStyled>
                <b>{item?.qtdAttendances}</b>
              </GridStyled>
            </Tooltip>
          </Grid>
        </Grid>
      </ListItem>
      <Divider variant="middle" />
    </GridStyled>
  );
};

export default RenderListItemAttendat;
