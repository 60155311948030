import { Container } from "@material-ui/core";
import styled from "styled-components";

export const StyledContainer = styled(Container)`
  padding: 0;
`;

export const StyledLayoutContainerBase = styled.div`
  display: flex;
  max-height: 100vh;
`;

// TODO: Desenvolver wrapper padrão para as páginas, hoje padding das páginas esta no coponente pai no route modules, o que chumba o padding para todas as páginas tornando dificil a implementação do subheader
export const DefaultPageContainer = styled.div`
  padding: 20px;
`;
