import styled, { css } from "styled-components";

import Button from "@material-ui/core/Button";

export const StyledButton = styled(Button)`
  background-color: ${(props) =>
    props.theme.palette[props["bg-color"]]} !important;
  color: ${(props) => props.theme.palette[props.textcolor]} !important;
  span {
    font-size: 0.925em;
  }
  &:hover {
    background-color: ${(props) =>
      props.theme.palette[props.hovercolor]} !important;
  }
  ${(props) =>
    props.full &&
    css`
      width: 100% !important;
      height: 100% !important;
    `}
  ${(props) =>
    props.disabled &&
    css`
      background-color: ${(props) =>
        props.theme.palette.light.subgrey} !important;
    `}
`;
