import { useContext, useState } from "react";

import { CopyToClipboard } from "react-copy-to-clipboard";

import { Grid } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import WebIcon from "@material-ui/icons/Web";
import Tooltip from "@material-ui/core/Tooltip";
import Link from "@material-ui/core/Link";
import LanguageIcon from "@material-ui/icons/Language";
import NotInterestedIcon from "@material-ui/icons/NotInterested";

import { notify } from "../../../utils/notify";

import useHomeController from "../controllers/HomeController";
import { HomeContext } from "../contexts/HomeContext";
import {
  WebsiteTypeDial,
  WebsiteTypeDialAction,
  WebsiteTypeContainer,
  WebsiteTypeCard,
  UrlWebsiteField,
} from "../styles/Home";

const dialIcons = {
  none: <NotInterestedIcon />,
  qyon: <WebIcon />,
  own: <LanguageIcon />,
};

const WebsiteTypeSelector = ({ ...props }) => {
  const { websiteType, setWebsiteType, ownWebsitUrl, setOwnWebsitUrl } =
    useContext(HomeContext);
  const { saveWebsiteType } = useHomeController();

  const [open, setOpen] = useState(false);

  const [urlOffice] = useState(
    `https://${localStorage.getItem("subdomain")}.qyon.net`
  );

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSelectWesiteType = (type) => {
    setWebsiteType(type);
    handleClose();
    saveWebsiteType(type);
  };

  const handleBlurOwnWebsite = () => {
    saveWebsiteType();
  };

  const cardContent = {
    none: "Website desativado",
    qyon: `Meu website: ${urlOffice}`,
    own: (
      <Grid container spacing={2} alignItems="center">
        <Grid item>
          <UrlWebsiteField
            variant="outlined"
            label="URL Website próprio"
            value={ownWebsitUrl}
            onChange={(e) => {
              setOwnWebsitUrl(e.target.value);
            }}
            onBlur={handleBlurOwnWebsite}
          />
        </Grid>
        <Grid item>
          <Grid container direction="column" spacing={1}>
            <Grid item>
              Importante: coloque no seu website o link para login do seu
              cliente
            </Grid>
            <Grid item>
              <Grid container spacing={2} justifyContent="space-between">
                <Grid item>
                  <CopyToClipboard
                    text={`${urlOffice}/login`}
                    onCopy={() =>
                      notify(
                        "Url de login copiada!",
                        true,
                        "success",
                        null,
                        2000
                      )
                    }
                  >
                    <Link>{`${urlOffice}/login  `}</Link>
                  </CopyToClipboard>
                </Grid>
                <Grid item>
                  <Tooltip title="Copiar URL de login">
                    <CopyToClipboard
                      text={`${urlOffice}/login`}
                      onCopy={() =>
                        notify(
                          "Url de login copiada!",
                          true,
                          "success",
                          null,
                          2000
                        )
                      }
                    >
                      <Button variant="outlined" size="small">
                        Copiar
                      </Button>
                    </CopyToClipboard>
                  </Tooltip>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    ),
  };

  return (
    <WebsiteTypeContainer>
      <Grid item>
        <WebsiteTypeDial
          ariaLabel="website type"
          icon={dialIcons[websiteType]}
          onClose={handleClose}
          onOpen={handleOpen}
          open={open}
          direction="down"
        >
          <WebsiteTypeDialAction
            icon={<WebIcon />}
            tooltipOpen
            tooltipPlacement="right"
            tooltipTitle={`Usar website Qyon - ${urlOffice}`}
            onClick={() => handleSelectWesiteType("qyon")}
          />
          <WebsiteTypeDialAction
            icon={<LanguageIcon />}
            tooltipOpen
            tooltipPlacement="right"
            tooltipTitle="Usar meu próprio web site"
            onClick={() => handleSelectWesiteType("own")}
          />
          <WebsiteTypeDialAction
            icon={<NotInterestedIcon />}
            tooltipOpen
            tooltipPlacement="right"
            tooltipTitle="Desativar website"
            onClick={() => handleSelectWesiteType("none")}
          />
        </WebsiteTypeDial>
      </Grid>
      <Grid item>
        <WebsiteTypeCard>{cardContent[websiteType]}</WebsiteTypeCard>
      </Grid>
    </WebsiteTypeContainer>
  );
};

export default WebsiteTypeSelector;
