import {
  Box,
  Button,
  Divider,
  Grid,
  List,
  ListItem,
  ListSubheader,
  TextareaAutosize,
  Typography,
} from "@material-ui/core";
import styled, { css } from "styled-components";

export const BoxStyled = styled(Box).attrs((props) => ({
  ...props,
}))`
  ${(props) =>
    props["width"] &&
    css`
      width: ${props["width"]};
    `}
  ${(props) =>
    props["height"] &&
    css`
      height: ${props["height"]};
    `}
    ${(props) =>
    props["fontSize"] &&
    css`
      font-size: ${props["fontSize"]};
    `}
    ${(props) =>
    props["fontSize"] &&
    css`
      font-size: ${props["fontSize"]};
    `}
    ${(props) =>
    props.justifyContent &&
    css`
      justify-content: ${props["justifyContent"]};
    `}
    ${(props) =>
    props.textAlign &&
    css`
      text-align: ${props["textAlign"]};
    `}
    ${(props) =>
    props.paddingTop &&
    css`
      padding-top: ${props["paddingTop"]};
    `}
    ${(props) =>
    props.paddingBottom &&
    css`
      padding-bottom: ${props["paddingBottom"]};
    `}
    ${(props) =>
    props["display"] &&
    css`
      display: ${props["display"]};
    `}
    ${(props) =>
    props["flexDirection"] &&
    css`
      flex-direction: ${props["flexDirection"]};
    `}
    ${(props) =>
    props["overflowX"] &&
    css`
      overflow-x: ${props["overflowX"]};
    `}
    ${(props) =>
    props["overflowY"] &&
    css`
      overflow-y: ${props["overflowY"]};
    `}
`;

export const DividerStyled = styled(Divider).attrs((props) => ({
  ...props,
}))`
  ${(props) =>
    props.marginTop &&
    css`
      margin-top: ${props.marginTop};
    `}
  ${(props) =>
    props.marginBottom &&
    css`
      margin-bottom: ${props.marginBottom};
    `}
`;

export const TextareaAutosizeStyled = styled(TextareaAutosize).attrs(
  (props) => ({
    ...props,
  })
)`
  ${(props) =>
    props.width &&
    css`
      width: ${props.width};
    `}
  ${(props) =>
    props.marginTop &&
    css`
      margin-top: ${props.marginTop};
    `}
${(props) =>
    props.borderRadius &&
    css`
      border-radius: ${props.borderRadius};
    `}
${(props) =>
    props.padding &&
    css`
      padding: ${props.padding};
    `}
${(props) =>
    props.borderColor &&
    css`
      border-color: ${props.borderColor};
    `}
${(props) =>
    props.resize &&
    css`
      resize: ${props.resize};
    `}
${(props) =>
    props.outline &&
    css`
      outline: ${props.outline};
    `}
`;

export const ListSubheaderStyled = styled(ListSubheader).attrs((props) => ({
  ...props,
}))`
    border-bottom 1px solid #CCCFD6;
    width 95%;
    margin auto;
    padding 0;
    position relative;
`;

export const ListItemStyled = styled(ListItem).attrs((props) => ({
  ...props,
}))`
  cursor: pointer;
  ${(props) =>
    props.alignItems &&
    css`
      align-items: ${props.alignItems};
    `}
`;

export const ListStyled = styled(List).attrs((props) => ({
  ...props,
}))`
  width: 100%;
`;

export const TypographyStyled = styled(Typography).attrs((props) => ({
  ...props,
}))`
  ${(props) =>
    props["font-size"] &&
    css`
      font-size: ${props["font-size"]};
    `}
  ${(props) =>
    props["padding"] &&
    css`
      padding: ${props["padding"]};
    `}
${(props) =>
    props["fontWeight"] &&
    css`
      font-weight: ${props["fontWeight"]};
    `}
${(props) =>
    props["display"] &&
    css`
      display: ${props["display"]};
    `}
  ${(props) =>
    props["textAlign"] &&
    css`
      text-align: ${props["textAlign"]};
    `}
${(props) =>
    props.color &&
    css`
      color: ${props.color.includes("#") || props.color.includes("rgb")
        ? props.color
        : props.theme.palette[props.color]};
    `}
`;

export const GridStyled = styled(Grid).attrs((props) => ({
  ...props,
}))`
  ${(props) =>
    props["alignContent"] &&
    css`
      align-content: ${props["alignContent"]};
    `}
  ${(props) =>
    props["alignItems"] &&
    css`
      align-items: ${props["alignItems"]};
    `}
${(props) =>
    props["padding"] &&
    css`
      padding: ${props["padding"]};
    `}
${(props) =>
    props["display"] &&
    css`
      display: ${props["display"]};
    `}
${(props) =>
    props["width"] &&
    css`
      width: ${props["width"]};
    `}
${(props) =>
    props.color &&
    css`
      color: ${props.color.includes("#")
        ? props.color
        : props.theme.palette[props.color]};
    `}
${(props) =>
    props["justifyContent"] &&
    css`
      justify-content: ${props["justifyContent"]};
    `}
${(props) =>
    props["height"] &&
    css`
      height: ${props["height"]};
    `}
${(props) =>
    props["margin"] &&
    css`
      margin: ${props["margin"]};
    `}
${(props) =>
    props["fontSize"] &&
    css`
      font-size: ${props["fontSize"]};
    `}
${(props) =>
    props["fontWeight"] &&
    css`
      font-weight: ${props["fontWeight"]};
    `}
${(props) =>
    props["whiteSpace"] &&
    css`
      white-space: ${props["whiteSpace"]};
    `}
${(props) =>
    props["overflow"] &&
    css`
      overflow: ${props["overflow"]};
    `}
${(props) =>
    props["textOverflow"] &&
    css`
      text-overflow: ${props["textOverflow"]};
    `}
${(props) =>
    props["marginRight"] &&
    css`
      margin-right: ${props["marginRight"]};
    `}
${(props) =>
    props["textAlign"] &&
    css`
      text-align: ${props["textAlign"]};
    `}
${(props) =>
    props["marginTop"] &&
    css`
      margin-top: ${props["marginTop"]};
    `}
${(props) =>
    props["marginBottom"] &&
    css`
      margin-bottom: ${props["marginBottom"]};
    `}
${(props) =>
    props["flexDirection"] &&
    css`
      flex-direction: ${props["flexDirection"]};
    `}
`;

export const ButtonStyled = styled(Button).attrs((props) => ({ ...props }))`
  ${(props) =>
    props["flex"] &&
    css`
      flex: ${props["flex"]};
    `}
  ${(props) =>
    props["backgroundColor"] &&
    css`
      background-color: ${props["backgroundColor"]} !important;
    `}
  ${(props) =>
    props["color"] &&
    css`
      color: ${props.color.includes("#")
        ? props.color
        : props.theme.palette[props.color]};
    `}
  ${(props) =>
    props["marginRight"] &&
    css`
      margin-right: ${props["marginRight"]};
    `}
`;
