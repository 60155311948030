import styled, { css } from "styled-components";

import { Chip, Grid, IconButton, Select } from "@material-ui/core";
import { DataGrid, GridToolbarExport } from "@material-ui/data-grid";

const white = (props) => props.theme.palette.white;
const blue = (props) => props.theme.palette.blue;
const grayPrimary = (props) => props.theme.palette.grayPrimary;

const roboto = (props) => props.theme.fonts.roboto;

export const ContainerTab = styled.div`
  .MuiTabs-root {
    background: ${blue};
  }

  .MuiTabs-indicator {
    background-color: ${white};
  }

  .MuiTab-wrapper {
    font-family: ${roboto}, sans-serif !important;
    color: ${white};
  }
`;

export const CertificateStatus = styled.div`
  ${(props) => {
    if (props.status === "active") {
      return css`
        background: #e8f5e9;
        color: #1b5e20;
      `;
    }
    if (props.status === "expired") {
      return css`
        background: #ffebee;
        color: #b71c1c;
      `;
    }
    if (props.status === "expiring") {
      return css`
        background: #fff3e0;
        color: #e65100;
      `;
    }
  }}
  font-weight: 500;
  border-radius: 4px;
  padding: 5px;
  line-height: 30px;
`;

export const CustomToolgridButtonExport = styled(GridToolbarExport)`
  min-width: 0px;
  .MuiButtonBase-root {
    width: 0px;
    height: 0px;
    overflow: hidden;
    padding: 0px;
    min-width: 0px;
  }

  .MuiButton-root {
    padding: 0px;
    min-width: 0px;
    max-width: 0px;
    width: 0px;
  }

  .MuiButton-textSizeSmall {
    padding: 0px 0px;
    min-width: 0px;
    max-width: 0px;
    width: 0px;
  }

  .MuiButton-text {
    padding: 0px;
    min-width: 0px;
    max-width: 0px;
    width: 0px;
  }

  .MuiButton-label {
    display: none;
  }
  .MuiTouchRipple-root {
    display: none;
  }
`;

export const SelectSimple = styled(Select)`
  width: 100%;
  height: 48px;
`;

export const ItemDraggable = styled(Grid)`
  background-color: ${grayPrimary};
`;

export const PulseIcon = styled.div`
  ${(props) =>
    props.active &&
    css`
      border-radius: 50%;
      box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
      margin: 10px;

      transform: scale(1);
      background: ${blue};
      box-shadow: 0 0 0 0 rgba(52, 172, 224, 1);
      animation: pulse-blue 1.2s infinite;

      @keyframes pulse-blue {
        0% {
          transform: scale(0.95);
          box-shadow: 0 0 0 0 rgba(52, 172, 224, 0.7);
        }

        70% {
          transform: scale(1);
          box-shadow: 0 0 0 15px rgba(52, 172, 224, 0);
        }

        100% {
          transform: scale(0.95);
          box-shadow: 0 0 0 0 rgba(52, 172, 224, 0);
        }
      }
    `}
`;

export const SelectFilterStyled = styled(Select)`
  & .MuiOutlinedInput-input {
    padding: 10.5px 32px 10.5px 14px;
  }
`;

export const LabelFilterStyled = styled.div`
  color: #364461;
  font-size: 14px;
`;

export const IconButtonAddStyled = styled(IconButton)`
  background-color: #0036b3;
  transition: 0.2s ease-in-out;
  color: #fff;
  &:hover {
    background-color: #002b8f;
  }
`;

export const ChipAddress = styled(Chip)`
  border-radius: 10px;
  background:  ${(props) => (props.main ? "#E8F5E9" : "#F5F5F5")};

  & .MuiChip-label {
    color: ${(props) => (props.main ? "#1B5E20" : "#424242")}
    font-weight: 500;
  }
`;

export const SearchLabel = styled.div`
  color: #364461;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
`;

export const ClientDataGrid = styled(DataGrid)`
  border: 0px !important;

  .MuiDataGrid-window {
    ::-webkit-scrollbar {
      height: 13px !important;
    }

    ::-webkit-scrollbar-track {
      background-color: #efefef;
    }

    ::-webkit-scrollbar-thumb {
      background-color: lightgrey;
    }
  }

  .MuiDataGrid-columnHeaderWrapper {
    font-family: ${roboto}, sans-serif !important;
    font-weight: 500;
    white-space: normal;
  }

  .MuiDataGrid-columnsContainer {
    min-height: 40px !important;
    line-height: 20px !important;
  }

  .MuiDataGrid-columnHeaderTitle {
    white-space: normal !important;
    text-overflow: unset !important;
  }

  .MuiDataGrid-root {
    border: 5px solid #000 !important;
  }

  div .MuiTablePagination-toolbar {
    .MuiTablePagination-caption:first-of-type {
      display: none;
    }
  }

  .MuiDataGrid-columnSeparator {
    display: none !important;
  }
`;
