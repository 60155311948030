import { useContext, useEffect, useState } from "react";

import {
  Grid,
  List,
  Card,
  CardHeader,
  ListItem,
  ListItemText,
  ListItemIcon,
  Checkbox,
  Button,
  Divider,
} from "@material-ui/core";

import TextFieldErp from "../basics/TextFieldErp";
import filterTable from "../../utils/filterTable";

import { StyledContainer, StyledButton } from "./styles/TransferList";
import {
  deleteCostumer,
  registerCostumer,
} from "../../repositories/panel/v1/integrations/qyonMsRpa/group";
import { GlobalContext } from "../../contexts/GlobalContext";
import { CndAccountantContext } from "../../pages/cnd/contexts/CndAccountantContext";

import {
  linkedCND,
  unlinkedCND,
} from "../../repositories/panel/v1/integrations/qyonMsRpa/agenda";
import { notify } from "../../utils/notify";

const not = (a, b) => {
  return a.filter((value) => b.indexOf(value) === -1);
};

const intersection = (a, b) => {
  return a.filter((value) => b.indexOf(value) !== -1);
};

const union = (a, b) => {
  return [...a, ...not(b, a)];
};

export const TransferList = ({
  leftOptions,
  setLeftOptions,
  leftListLabel,

  rightOptions,
  setRightOptions,
  rightListLabel,

  descriptionField,
  filterFields = [],

  toRightLabel = ">",
  toLeftLabel = "<",

  height = 365,
}) => {
  const { companyUser } = useContext(GlobalContext);

  const { group, cnd } = useContext(CndAccountantContext);

  const [checked, setChecked] = useState([]);

  const [filterLeft, setFilterLeft] = useState("");
  const [filteredLeft, setFilteredLeft] = useState(leftOptions);

  const [filterRight, setFilterRight] = useState("");
  const [filteredRight, setFilteredRight] = useState(rightOptions);

  const leftChecked = intersection(checked, leftOptions);
  const rightChecked = intersection(checked, rightOptions);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
  };

  const numberOfChecked = (items) => intersection(checked, items).length;

  const handleToggleAll = (items) => () => {
    if (numberOfChecked(items) === items.length) {
      setChecked(not(checked, items));
    } else {
      setChecked(union(checked, items));
    }
  };

  const handleCheckedRight = () => {
    try {
      saveEntitiesInGroup(checked);

      setRightOptions(rightOptions.concat(leftChecked));
      setLeftOptions(not(leftOptions, leftChecked));
      setChecked(not(checked, leftChecked));
    } catch (err) {
      notify(
        "Ops! Algo deu errado na hora de cadastrar o cliente!",
        true,
        "error"
      );
      console.error(err);
    } finally {
      setTimeout(() => {
        notify("Cadastro de cliente efetuado com sucesso!", true, "success");
      }, 100);
    }
  };

  async function saveEntitiesInGroup(checked) {
    await checked.forEach((item) => {
      const body = {
        document: item.document,
        name: item.company_name,
        mail: item.master_email,
        ie: item.ie,
        im: item.im,
        ccm: item.ccm,
      };

      if (group) {
        registerCostumer({
          id: group.id,
          document: companyUser.document,
          body: body,
        });
      } else {
        linkedCND({
          docEscritorio: companyUser.document,
          docClient: item.document,
          IdAgenda: cnd.id,
          body: body,
        });
      }
    });
  }

  async function deleteEntitiesInGroup(checked) {
    await checked.forEach((item) => {
      if (group) {
        deleteCostumer({
          id: group.id,
          document: companyUser.document,
          documentClient: item.document,
          body: item,
        });
      } else {
        unlinkedCND({ IDClient: item.document, IDAgenda: cnd.id });
      }
    });
  }

  const handleCheckedLeft = () => {
    try {
      deleteEntitiesInGroup(checked);

      setLeftOptions(leftOptions.concat(rightChecked));
      setRightOptions(not(rightOptions, rightChecked));
      setChecked(not(checked, rightChecked));
    } catch (err) {
      notify(
        "Ops! Algo deu errado na hora de remover o cliente!",
        true,
        "error"
      );
    } finally {
      setTimeout(() => {
        notify("Cliente removido com sucesso!", true, "success");
      }, 100);
    }
  };

  const CustomList = ({ title, items }) => (
    <Card>
      <CardHeader
        avatar={
          <Checkbox
            onClick={handleToggleAll(items)}
            checked={
              numberOfChecked(items) === items.length && items.length !== 0
            }
            indeterminate={
              numberOfChecked(items) !== items.length &&
              numberOfChecked(items) !== 0
            }
            disabled={items.length === 0}
            inputProps={{ "aria-label": "all items selected" }}
          />
        }
        title={title}
        subheader={`${numberOfChecked(items)}/${items.length} selecionados`}
      />
      <Divider />
      <List dense component="div" role="list">
        {items.map((value) => {
          const labelId = `transfer-list-all-item-${value[descriptionField]}-label`;

          return (
            <ListItem
              key={value}
              role="listitem"
              button
              onClick={handleToggle(value)}
            >
              <ListItemIcon>
                <Checkbox
                  checked={checked.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ "aria-labelledby": labelId }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={value[descriptionField]} />
            </ListItem>
          );
        })}
        <ListItem />
      </List>
    </Card>
  );

  useEffect(() => {
    setFilteredLeft(
      filterLeft
        ? filterTable({
            data: leftOptions,
            text: filterLeft,
            columns: filterFields,
          })
        : leftOptions
    );
  }, [leftOptions, filterLeft, filterFields]);

  useEffect(() => {
    setFilteredRight(
      filterRight
        ? filterTable({
            data: rightOptions,
            text: filterRight,
            columns: filterFields,
          })
        : rightOptions
    );
  }, [filterRight, rightOptions, filterFields]);

  return (
    <StyledContainer
      container
      spacing={2}
      flex={1}
      justifyContent="center"
      alignItems="center"
      height={height}
    >
      <Grid item xs={5} flex={1} container spacing={1}>
        <Grid item>
          <TextFieldErp
            label={`Filtro ${leftListLabel}`}
            min={1}
            value={filterLeft}
            setValue={setFilterLeft}
          />
        </Grid>
        <Grid item flex={1} className="list-container">
          <CustomList title={leftListLabel} items={filteredLeft} />
        </Grid>
      </Grid>

      <Grid item xs={2} flex={1}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <StyledButton
              variant="outlined"
              size="small"
              bg-color="blue"
              textcolor="white"
              // style={{background: "#0a73db", color: "white"}}
              onClick={handleCheckedRight}
              disabled={leftChecked.length === 0}
              aria-label="move selected right"
            >
              {toRightLabel}
            </StyledButton>
          </Grid>
          <Grid item xs={12}>
            <StyledButton
              variant="outlined"
              size="small"
              bg-color="red"
              textcolor="white"
              onClick={handleCheckedLeft}
              disabled={rightChecked.length === 0}
              aria-label="move selected left"
            >
              {toLeftLabel}
            </StyledButton>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={5} flex={1} container spacing={1}>
        <Grid item flex={1}>
          <TextFieldErp
            label={`Filtro ${rightListLabel}`}
            min={1}
            value={filterRight}
            setValue={setFilterRight}
          />
        </Grid>
        <Grid item flex={1} className="list-container">
          <CustomList title={rightListLabel} items={filteredRight} />
        </Grid>
      </Grid>
    </StyledContainer>
  );
};
