import { notify } from "../../../utils/notify";
import { useChatContext } from "./../../../components/chat/contexts/ChatContext";

import { useHistory } from "react-router";
import { useAuth } from "../../../contexts/crm/Auth";

export default function ChatSettingsController() {
  const { chat, setChat, settings, setSettings } = useChatContext();

  const { company } = useAuth();
  const tokenPanel = localStorage.getItem("tokenCognitoPanel");
  const { push } = useHistory();
  const urlApiCrm = process.env.REACT_APP_CRM_API_URL;
  const validFields = (obj, typeScreen) => {
    let errorsValidation = {};
    let error = false;
    let fields = [];

    fields = [
      {
        roomName: "O nome da sala precisa ser preenchido",
      },
    ];

    let notification = [];
    obj.forEach((element) => {
      if (!element.value) {
        errorsValidation[element.key] = true;
        error = true;
        notification.push(fields[0][element.key]);
      } else {
        errorsValidation[element.key] = false;
        notification.splice(fields[0][element.key], 1);
      }
    });
    return { error, errorsValidation, notification };
  };

  const handleSave = async ({ value }) => {
    let valid = [];
    try {
      valid = validFields([{ value: value.roomName, key: "roomName" }]);
      valid.notification.forEach((notification) => {
        valid.error && notify(notification, true, "error");
      });

      const dataChatSettings = {
        roomId: chat.roomId,
        initialMessage: value?.initialMessage || null,
        initialMessageIntegration: value?.initialMessageIntegration || null,
        roomOffMessage: value?.roomOffMessage || null,
        messageRoomInactivity: value?.messageRoomInactivity || null,
        messagemRoomDowntime: value?.messagemRoomDowntime || null,
        roomName: value?.roomName,
        roomDescription: value?.roomDescription || null,
        statusRoom: value?.statusRoom === "0" ? "1" : "0",
        roomAttendanceAmount: value?.roomAttendanceAmount || null,
        productParam: value?.productParam,
        alertTimeInactivity: value?.alertTimeInactivity || null,
        alertNewCustomer: value?.alertNewCustomer || null,
        downtime: value?.downtime || null,
        tokenWhats: value?.tokenWhats || null,
        integrationWppNumberId: value?.integrationWppNumberId || null,
        whatsappAccountId: value?.whatsappAccountId || null,
      };
      if (valid.error !== true) {
        const result = await fetch(`${urlApiCrm}/v1/room`, {
          method: "put",
          headers: {
            Authorization: tokenPanel,
            "qyon-customer-id": company.id,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(dataChatSettings),
        }).then((response) => response.json());
        if (result?.success) {
          setChat({
            ...chat,
            initialMessage: dataChatSettings.initialMessage,
            initialMessageIntegration:
              dataChatSettings.initialMessageIntegration,
            roomOffMessage: dataChatSettings.roomOffMessage,
            messageRoomInactivity: dataChatSettings.messageRoomInactivity,
            messagemRoomDowntime: dataChatSettings.messagemRoomDowntime,
            roomName: dataChatSettings.roomName,
            roomDescription: dataChatSettings.roomDescription,
            statusRoom: dataChatSettings.statusRoom === "0" ? "1" : "0",
            roomAttendanceAmount: dataChatSettings.roomAttendanceAmount,
            productParam: dataChatSettings.productParam,
            alertTimeInactivity: dataChatSettings.alertTimeInactivity,
            alertNewCustomer: dataChatSettings.alertNewCustomer,
            downtime: dataChatSettings.downtime,
            tokenWhats: dataChatSettings.tokenWhats,
            integrationWppNumberId: dataChatSettings.integrationWppNumberId,
            whatsappFlagIntegration: dataChatSettings.whatsappFlagIntegration,
            whatsappAccountId: dataChatSettings.whatsappAccountId,
          });
          setSettings({
            ...settings,
            roomState: dataChatSettings.statusRoom === "0" ? "1" : "0",
          });
          notify(
            "Configurações do chat alteradas com sucesso",
            true,
            "success"
          );
          push("/chat/settings");
        } else {
          notify(
            "Algo deu errado, por favor tente novamente mais tarde!",
            true,
            "error"
          );
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  return {
    handleSave,
  };
}
