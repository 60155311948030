import {
  DocumentNFE,
  DocumentCTE,
  DocumentNFSE,
  DocumentMDFE,
  DocumentCFE,
  XMLNotIdentified,
  Events,
} from "../children/Documents";

export const ClassifyDocuments = (xml, botContext, props, functionAI) => {
  switch (xml.data.xmlType) {
    case "NFe":
      return DocumentNFE(xml, botContext, props, functionAI);

    case "EventoNFe":
      return Events(xml, botContext, props, functionAI);

    case "NFSe":
      return DocumentNFSE(xml, botContext, props, functionAI);

    case "CTe":
      return DocumentCTE(xml, botContext, props, functionAI);

    case "EventoCTe":
      return Events(xml, botContext, props, functionAI);

    case "CFe":
      return DocumentCFE(xml, botContext, props, functionAI);

    case "MDFe":
      return DocumentMDFE(xml, botContext, props, functionAI);

    case "EventoMDFe":
      return Events(xml, botContext, props, functionAI);

    default:
      return XMLNotIdentified(xml, botContext, props, functionAI);
  }
};
