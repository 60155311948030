import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputAdornment,
  TextField,
} from "@material-ui/core";
import { GridCloseIcon } from "@material-ui/data-grid";
import { AccountCircle } from "@material-ui/icons";
import SendIcon from "@material-ui/icons/Send";

export const DialogEmail = (props) => {
  return (
    <Dialog
      open={props.openDialog}
      onClose={() => {
        props.closeDialog();
      }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
    >
      <GridCloseIcon
        style={{
          position: "absolute",
          right: 10,
          marginTop: "10px",
          cursor: "pointer",
        }}
        onClick={() => {
          props.closeDialog();
        }}
      />
      <DialogTitle id="alert-dialog-title">Novo E-mail</DialogTitle>

      <DialogContent>
        <TextField
          id="input-with-icon-textfield"
          label="Para:"
          value={props.email}
          onChange={props.onChange}
          placeholder="Digite o e-mail destino para enviar o template"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <AccountCircle />
              </InputAdornment>
            ),
          }}
          variant="outlined"
          fullWidth
        />
      </DialogContent>

      <DialogContent>
        <div
          style={{
            marginTop: "25px",
            border: props.HTMLTemplate ? "1px dashed grey" : "",
            marginBottom: "25px",
          }}
          dangerouslySetInnerHTML={{ __html: props.HTMLTemplate }}
        />
      </DialogContent>

      <DialogActions>
        <Button
          variant="contained"
          endIcon={<SendIcon />}
          color="primary"
          onClick={() => props.sendEmail()}
        >
          Enviar
        </Button>
      </DialogActions>
    </Dialog>
  );
};
