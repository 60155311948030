import { Avatar, Divider, Grid, Tooltip } from "@material-ui/core";
import {
  ArrowForward as ArrowForwardIcon,
  Forum as ForumIcon,
} from "@material-ui/icons";
import React from "react";
import { ContainerIcon, LightTooltip } from "../../../styles/GlobalStyle";
import {
  GridStyled,
  ListItemStyled,
  TypographyStyled,
} from "../styles/GeneralStyle";

export default function GroupItem({
  title,
  email,
  subTitle,
  sessionId,
  tooltipSubTitle,
  flag,
  setCallCustomer,
  openConversationInRoom,
}) {
  const getAvatarContent = (text) => {
    if (text) {
      let result = text.charAt(0);
      const splited = text.split(" ");
      if (splited.length > 1) result += splited[splited.length - 1].charAt(0);
      else result += text.charAt(1);
      return result.toUpperCase();
    } else {
      return "";
    }
  };

  return (
    <>
      <ListItemStyled
        alignItems="flex-start"
        onClick={() => {
          flag === "clientes"
            ? setCallCustomer(sessionId)
            : openConversationInRoom({
                roomId: 1,
                nameCustomerContact: title,
                email,
              });
        }}
      >
        <Grid container>
          <Grid xs={2}>
            <Avatar alt={title}>{getAvatarContent(title)}</Avatar>
          </Grid>
          <Grid xs={7}>
            <GridStyled item fontSize="16px" fontWeight="bold">
              {title}
            </GridStyled>
            <Grid>
              <GridStyled
                item
                whiteSpace="nowrap"
                overflow="hidden"
                textOverflow="ellipsis"
                marginRight="8px"
              >
                <Tooltip
                  title={tooltipSubTitle}
                  enterDelay={300}
                  leaveDelay={200}
                >
                  <TypographyStyled
                    display="inline"
                    font-size="12px"
                    component="span"
                    variant="body2"
                    color="gray"
                  >
                    {subTitle}
                  </TypographyStyled>
                </Tooltip>
              </GridStyled>
            </Grid>
          </Grid>
          <GridStyled
            xs={3}
            display="flex"
            alignContent="center"
            alignItems="center"
            justifyContent="center"
          >
            <LightTooltip
              title={`${
                flag === "clientes"
                  ? "Contatos do cliente"
                  : "Iniciar uma conversa"
              }`}
              placement="top"
            >
              <ContainerIcon bg-color="transparent" padding="2px">
                {flag === "clientes" ? (
                  <ArrowForwardIcon
                    style={{ width: "100%", color: "#006fde" }}
                    size={10}
                  />
                ) : (
                  <ForumIcon
                    style={{ width: "100%", color: "#006fde" }}
                    size={10}
                  />
                )}
              </ContainerIcon>
            </LightTooltip>
          </GridStyled>
        </Grid>
      </ListItemStyled>
      <Divider variant="middle" />
    </>
  );
}
