import axios from "axios";
import { changeAccessTokenStorage } from "../../../components/chat/utils";

axios.interceptors.response.use(
  function (response) {
    if (response?.data?.extraData?.newAccessToken) {
      changeAccessTokenStorage(response?.data?.extraData?.newAccessToken);
    }
    return response;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export const doRequest = ({
  path,
  method,
  authorization,
  refreshToken,
  params,
  contentType,
}) =>
  axios(path, {
    method,
    headers: {
      Accept: "/",
      "Content-Type": contentType,
      "Accept-Language": "pt-BR",
      ...(authorization && { Authorization: `${authorization}` }),
      ...(refreshToken && { refreshToken: `${refreshToken}` }),
      "qyon-customer-cnpj":
        localStorage.getItem("isAccountantPanel") === "true"
          ? localStorage.getItem("documentPanel")
          : localStorage.getItem("accountantCnpjPanel"),
      "customer-cnpj": localStorage.getItem("documentPanel") || "",
      // isInClientView: sessionStorage.getItem("isInClientView") === "true",
      // email: sessionStorage.getItem("client")
      //   ? JSON.parse(sessionStorage.getItem("client")).email
      //   : null,
    },
    ...(method !== "GET"
      ? {
          data: params,
        }
      : {}),
  });

export const callApi = async ({
  path,
  method,
  authorization = localStorage.getItem("tokenCognitoPanel"),
  refreshToken = JSON.parse(localStorage.getItem("cognitoPanel"))?.RefreshToken,
  params,
  contentType = "application/json; charset=UTF-8",
}) => {
  const res = await doRequest({
    path,
    method,
    authorization,
    refreshToken,
    params,
    contentType,
  });

  if (res.data) return res.data;

  return null;
};
