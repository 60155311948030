import { Grid, Zoom } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import ClearAllOutlinedIcon from "@material-ui/icons/ClearAllOutlined";

import {
  CardInterrogation,
  ContainerInterrogation,
} from "./styles/InterrogationBot";
import {
  ContainerIcon,
  GridStyled,
  LabelStyled,
} from "../../../styles/GlobalStyle";

export default function InterrogationBot({ setOpen }) {
  return (
    <Zoom in={true}>
      <ContainerInterrogation>
        <CardInterrogation container spacing={2}>
          <Grid item container spacing={2} alignItems="center">
            <Grid item>
              <ContainerIcon iconColor="blue">
                <ClearAllOutlinedIcon />
              </ContainerIcon>
            </Grid>

            <Grid item>
              <LabelStyled fontSize="18" fontWeight="600" color="blue">
                BOT
              </LabelStyled>
            </Grid>
            <Grid item container xs justifyContent="flex-end">
              <ContainerIcon
                iconColor="red"
                fontSize="26"
                onClick={() => setOpen("")}
              >
                <CloseIcon />
              </ContainerIcon>
            </Grid>
          </Grid>

          <Grid item container>
            <GridStyled
              item
              xs={12}
              border-radius="5"
              padding="5"
              container
              alignItems="center"
              bg-color="blue"
            >
              <LabelStyled color="white">
                Saiba o que eu posso fazer por você!
              </LabelStyled>
            </GridStyled>
          </Grid>

          <Grid item container>
            <GridStyled
              item
              xs={12}
              border-radius="5"
              padding="5"
              container
              alignItems="center"
              bg-color="blue"
            >
              <LabelStyled color="white">Como me treinar</LabelStyled>
            </GridStyled>
          </Grid>

          <Grid item container>
            <GridStyled
              item
              xs={12}
              border-radius="5"
              padding="5"
              container
              alignItems="center"
              bg-color="blue"
            >
              <LabelStyled color="white">Comandos já ensinados</LabelStyled>
            </GridStyled>
          </Grid>

          <Grid item container>
            <GridStyled
              item
              xs={12}
              border-radius="5"
              padding="5"
              container
              alignItems="center"
              bg-color="blue"
            >
              <LabelStyled color="white">Certificados enviados</LabelStyled>
            </GridStyled>
          </Grid>
        </CardInterrogation>
      </ContainerInterrogation>
    </Zoom>
  );
}
