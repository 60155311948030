import styled from "styled-components";

export const CompanyCard = styled.div`
  padding: 24px 24px 16px 24px;
  background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : "#fff"};
  border: 1px solid #cfd8dc;
  max-height: 500px;
  border-radius: 8px;
`;
