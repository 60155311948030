import styled, { css } from "styled-components";

export const IconOriginAttendance = styled.div`
  background-color: ${(props) =>
    css`
      ${props.color}
    `};
  border-radius: 13px;
  border: 2px solid #fff;
  position: absolute;
  cursor: pointer;
  padding: 1px;
  left: 12px;
  top: 37px;
  width: 22px;
  height: 22px;
  text-align: -webkit-center;
`;

export const BoxItemConversation = styled.div`
  background-color: ${(props) =>
    css`
      ${props.bghovercolor}
    `};
  position: absolute;
  width: 21px;
  height: 21px;
  border-radius: 13px;
  border: 2px solid #fff;
  padding: 1px;
  left: 15px;
  bottom: 12px;
  z-index: 2;
`;
