import styled, { css } from "styled-components";

export const ContainerMessages = styled.div`
  text-align: center;
  width: 100%;

  #containerMessages {
    height: 100%;
    padding: 2%;
    overflow-y: scroll;
    max-height: 500px;
  }

  .viewMore {
    text-align: center;
    color: #006fde;
    cursor: pointer;
  }
`;

const defaultProps = (props) => {
  const { privateFlag, typeMessage } = props;

  const bgColor = (typeMessage) => {
    return typeMessage === "attendant" ? "#808080" : "#006FDE";
  };

  return css`
    width: 100%;
    max-width: 100%;
    margin-top: 10px;
    display: ${privateFlag ? "none" : "flex"};
    justify-content: ${typeMessage === "attendant" ? "left" : "right"};

    .messageContent {
      padding: 3%;
      border-radius: ${typeMessage === "attendant"
        ? "0 8px 8px 8px"
        : "8px 0 8px 8px"};
      background: ${privateFlag ? "#f5f5f9" : bgColor(typeMessage)};
      border: ${privateFlag ? "1px dashed #8995a6" : ""};
      color: ${privateFlag ? " #8995a6" : "#fff"};
      word-break: break-word;
      display: block;
      text-align: left;
    }

    p {
      max-width: 500px;
    }

    .date {
      text-align: right;
      font-size: 10px;
      color: black;
      margin-right: 40px;
    }
  `;
};

export const CardMessage = styled.div`
  ${({ privateFlag, typeMessage }) =>
    defaultProps({
      privateFlag,
      typeMessage,
    })}
`;
