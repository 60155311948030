import { useContext } from "react";

import { GlobalContext } from "../../../contexts/GlobalContext";
import { sendEmail } from "../../../repositories/integrations/erp/email";
import { changeFavorite } from "../../../repositories/panel/v1/GuideReportRepository";
import { notify } from "../../../utils/notify";
import { getApi } from "../../attendance/utils";
import { useGuideReport } from "../contexts/GuideReportContext";

export default function useGuideReportController() {
  const {
    dataModalSend,
    setDataModalSend,

    allFiles,
    setAllFiles,
    filesPerDepartament,

    setLoading,
    expandedTable,
    setExpandedTable,
    setCompany,
    company,
  } = useGuideReport();
  const urlApiCrm =
    process.env.REACT_APP_CRM_API_URL || "http://localhost:4000/";

  const { companyUser, userLogged } = useContext(GlobalContext);

  const accountantCnpj =
    companyUser?.isAccountant === "true"
      ? companyUser.document
      : companyUser.accountantCnpj;

  const handleShowFile = (file, contentType) => {
    if (!file || typeof file !== "string" || !contentType) {
      return;
    }

    const byteCharacters = atob(file);

    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += 1024) {
      const slice = byteCharacters.slice(offset, offset + 1024);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }
    const blob = new Blob(byteArrays, { type: contentType });
    const blobUrl = URL.createObjectURL(blob);

    window.open(blobUrl, "_blank");
  };

  const authenticate = async () => {
    const responseAuth = await getApi({
      path: `/v1/auth`,
      token: userLogged.tokenCognito,
      cnpj: accountantCnpj,
    });

    if (responseAuth.company) {
      let company = {
        id: responseAuth.company.id,
        alias: responseAuth.company.alias,
        cnpj: accountantCnpj,
      };
      setCompany(company);
    }
  };

  const filterTable = ({ data = [], text = "", columns = [] }) => {
    text = text
      .normalize("NFD")
      .toLowerCase()
      .replace(/[^a-z0-9_]/g, "");
    const newData = data.filter((item) => {
      let valueFound = false;
      columns.forEach((column) => {
        if (!valueFound) {
          valueFound = item[column]
            ? item[column]
                .toString()
                .normalize("NFD")
                .toLowerCase()
                .replace(/[^a-z0-9_]/g, "")
                .includes(text)
            : false;
        }
      });
      return valueFound;
    });
    setAllFiles(newData);
  };

  const handleDownloadFile = async (fileBase64, filename) => {
    if (!fileBase64 || typeof fileBase64 !== "string") return;

    try {
      var element = document.createElement("a");
      element.setAttribute(
        "href",
        "data:application/octet-stream;base64," + encodeURIComponent(fileBase64)
      );
      element.setAttribute("download", `${filename}`);
      element.style.display = "none";
      document.body.appendChild(element);

      element.click();

      document.body.removeChild(element);
      return true;
    } catch (error) {
      console.error("ERROR ButtonsAccountant executeDownload:", error);
      return false;
    }
  };

  const handleFavorite = async (id, current) => {
    try {
      setLoading(true);
      const responseFavorite = await changeFavorite(id, !current);

      if (!responseFavorite.success)
        return notify("Não foi possível favoritar.");

      const index = allFiles.findIndex(({ file }) => file.id === id);

      if (index === -1) return;

      const copy = [...allFiles];
      copy[index].file.starred = !current;
      setAllFiles(copy);
    } catch (error) {
      console.error(error);
      return notify("Não foi possível favoritar.");
    } finally {
      setLoading(false);
    }
  };

  const handleSendEmail = async (emails) => {
    try {
      setLoading(true);

      const file = {
        data: dataModalSend.file.url.substring(
          dataModalSend.file.url.split("").findIndex((e) => e === ",") + 1
        ),
        filename: `CND.${dataModalSend.file.url.substring(
          dataModalSend.file.url.split("").findIndex((e) => e === "/") + 1,
          dataModalSend.file.url.split("").findIndex((e) => e === ";")
        )}`,
      };

      const result = await sendEmail({
        from: { name: null },
        e_mails: emails,
        e_mailsCC: [],
        attachment: [file],
        subject: "QYON - ERP  Controle de CNDs",
        replyTo: "",
        bodyHtml: `<h3>Prezado cliente,</h3><br/>Segue anexa certidão emitida para sua empresa.<br/>Nos colocamos à disposição para maiores esclarecimentos.`,
      });

      if (result && result.success) {
        setDataModalSend(null);
        return notify("E-mail enviado com sucesso.", true, "success");
      }

      return notify("Falha ao enviar e-mail.");
    } catch (error) {
      console.error(error);

      return notify("Falha ao enviar e-mail.");
    } finally {
      setLoading(false);
    }
  };

  const handleOpenSend = (row) => {
    const statusAccess = [];
    // if (cndNegativaCheck) statusAccess.push("Negativa");
    // if (cndPositivaCheck) statusAccess.push("Positiva");

    // if (row || !row.file.url || statusAccess.includes(row.status)) return;

    // if (!filesToSend || filesToSend.length < 1) {
    //   return notify(
    //     "As CNDs selecionadas não respeitam as configurações de envio."
    //   );
    // }

    setDataModalSend(row);
  };

  const handleChangeExpanded = (tab) => {
    const optionContabils = {
      Fiscal: { files: filesPerDepartament["Fiscal"], setter: 5 },
      Folha: { files: filesPerDepartament["Folha"], setter: 5 },
      Contabil: { files: filesPerDepartament["Contabil"], setter: 5 },
      others: { files: filesPerDepartament["others"], setter: 5 },
    };

    const optionSelected = optionContabils[tab];

    optionSelected.setter(
      !expandedTable[tab] ? optionSelected.files.length : 5
    );
    setExpandedTable((current) => ({ ...current, [tab]: !current[tab] }));
  };

  const findDepartaments = async ({ typeFile }) => {
    try {
      const result = await fetch(
        `${urlApiCrm}/v1/type-tabs?typeFileKey=${typeFile}`,
        {
          method: "get",
          headers: {
            Authorization: localStorage.getItem("tokenCognitoPanel").toString(),
            "qyon-customer-cnpj":
              localStorage.getItem("isAccountantPanel") === "true"
                ? localStorage.getItem("documentPanel")
                : localStorage.getItem("accountantCnpjPanel"),
            "customer-cnpj": localStorage.getItem("documentPanel") || "",
            isInClientView: sessionStorage.getItem("isInClientView") === "true",
            email: sessionStorage.getItem("client")
              ? JSON.parse(sessionStorage.getItem("client")).email
              : null,
          },
        }
      ).then((response) => response.json());
      if (result?.hasError) return {};

      return result?.items;
    } catch (error) {
      console.error(error);
    }
  };

  const findGuideReports = async ({ typeFile }) => {
    try {
      const result = await fetch(
        `${urlApiCrm}/v1/file?typeFileKey=${typeFile}`,
        {
          method: "get",
          headers: {
            Authorization: localStorage.getItem("tokenCognitoPanel").toString(),
            "qyon-customer-cnpj":
              localStorage.getItem("isAccountantPanel") === "true"
                ? localStorage.getItem("documentPanel")
                : localStorage.getItem("accountantCnpjPanel"),
            "customer-cnpj": localStorage.getItem("documentPanel") || "",
            isInClientView: sessionStorage.getItem("isInClientView") === "true",
            email: sessionStorage.getItem("client")
              ? JSON.parse(sessionStorage.getItem("client")).email
              : null,
          },
        }
      ).then((response) => response.json());
      if (result?.hasError) return {};

      return result?.items;
    } catch (error) {
      console.error(error);
    }
  };

  const findReportAttendance = async ({
    attendaceId,
    startDate,
    endDate,
    statusAttendace,
    customerId,
    situationId,
    classificationId,
    userRegisterId,
    departamentId,
    teamId,
    page,
    rowsPerPage,
    columnToFilterDate,
  }) => {
    let route = `${urlApiCrm}/v1/report/attendance`;

    if (startDate) route = `${route}&startDate=${startDate}`;
    if (endDate) route = `${route}&endDate=${endDate}`;
    if (customerId) route = `${route}&customerId=${customerId}`;
    if (situationId) route = `${route}&situationId=${situationId}`;
    if (classificationId)
      route = `${route}&classificationId=${classificationId}`;
    if (userRegisterId) route = `${route}&userRegisterId=${userRegisterId}`;
    if (teamId) route = `${route}&teamId=${teamId}`;
    if (departamentId) route = `${route}&departamentId=${departamentId}`;
    if (statusAttendace) route = `${route}&statusAttendace=${statusAttendace}`;
    if (columnToFilterDate)
      route = `${route}&columnToFilterDate=${columnToFilterDate}`;
    if (attendaceId) route = `${route}&attendaceId=${attendaceId}`;

    const response = await fetch(route, {
      method: "get",
      headers: {
        Authorization: localStorage.getItem("tokenCognitoPanel").toString(),
        "qyon-customer-cnpj": localStorage.getItem("accountantCnpjPanel"),
        "qyon-customer-id": company.id,
        refreshToken: userLogged.cognito.RefreshToken,
      },
    }).then((response) => response.json());

    return response;
  };

  const fetchCustomer = async ({ id }) => {
    try {
      const result = await fetch(`${urlApiCrm}/v1/customer/${id ? id : ""}`, {
        method: "get",
        headers: {
          Authorization: localStorage.getItem("tokenCognitoPanel"),
          "qyon-customer-id": company?.id,
          refreshToken: userLogged.cognito.RefreshToken,
        },
      }).then((response) => response.json());

      if (result?.hasError) {
        return {};
      }
      return result?.items;
    } catch (error) {
      console.error(error);
    }
  };

  const fetchSituation = async () => {
    try {
      const result = await fetch(`${urlApiCrm}/v1/situation`, {
        method: "get",
        headers: {
          Authorization: localStorage.getItem("tokenCognitoPanel"),
          "qyon-customer-id": company.id,
          refreshToken: userLogged.cognito.RefreshToken,
        },
      }).then((response) => response.json());

      if (result?.hasError) {
        return {};
      }

      return result?.items;
    } catch (error) {
      console.error(error);
    }
  };

  const fetchClassification = async () => {
    try {
      const result = await fetch(`${urlApiCrm}/v1/classification`, {
        method: "get",
        headers: {
          Authorization: localStorage.getItem("tokenCognitoPanel"),
          "qyon-customer-id": company.id,
          refreshToken: userLogged.cognito.RefreshToken,
        },
      }).then((response) => response.json());

      if (result?.hasError) {
        return {};
      }
      return result?.items;
    } catch (error) {
      console.error(error);
    }
  };

  const fetchDepartment = async () => {
    try {
      const result = await fetch(`${urlApiCrm}/v1/department`, {
        method: "get",
        headers: {
          Authorization: localStorage.getItem("tokenCognitoPanel"),
          "qyon-customer-id": company.id,
          refreshToken: userLogged.cognito.RefreshToken,
        },
      }).then((response) => response.json());

      if (result?.hasError) {
        return {};
      }
      return result?.items;
    } catch (error) {
      console.error(error);
    }
  };

  const fetchTeams = async () => {
    try {
      const result = await fetch(`${urlApiCrm}/v1/teams`, {
        method: "get",
        headers: {
          Authorization: localStorage.getItem("tokenCognitoPanel"),
          "qyon-customer-id": company.id,
          refreshToken: userLogged.cognito.RefreshToken,
        },
      }).then((response) => response.json());

      if (result?.hasError) {
        return {};
      }
      return result?.items;
    } catch (error) {
      console.error(error);
    }
  };

  return {
    handleShowFile,
    handleDownloadFile,
    handleFavorite,
    handleOpenSend,
    handleChangeExpanded,
    handleSendEmail,
    filterTable,
    findDepartaments,
    findGuideReports,
    findReportAttendance,
    fetchCustomer,
    authenticate,
    fetchSituation,
    fetchClassification,
    fetchDepartment,
    fetchTeams,
  };
}
