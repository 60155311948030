import axios from "axios";

export const getDocumentData = async (base64) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "c799c146-6264-4bc6-94d7-56b4cac8deb0",
  };

  if (localStorage.getItem("environment") === "prod") {
    let response = await axios.post(
      "https://qyon-bot.qyon.info/v1/bot/scan/document",
      {
        base64,
      },
      {
        headers: headers,
      }
    );
        return response.status == 200 ? response.data : null;
  } else {
    let response = await axios.post(
      "https://qyon-bot-stg.qyon.info/v1/bot/scan/document",
      {
        base64,
      },
      {
        headers: headers,
      }
    );
    return response.status == 200 ? response.data : null;
  }
};
