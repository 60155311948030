import { useContext } from "react";

import { useHistory } from "react-router";

import { GlobalContext } from "../../../contexts/GlobalContext";
import { usePortalAsClient } from "../../../utils/viewPortalAsClientHook";

export default function useSettingController() {
  const { push } = useHistory();
  const { companyUser } = useContext(GlobalContext);
  const { isInClientView } = usePortalAsClient();

  const companyId = JSON.parse(sessionStorage.getItem("client"))?.company;

  const handleOpenDataCompany = async () => {
    push("/customermanagement/create", {
      customerId: isInClientView ? companyId.toString() : companyUser.companyId,
      title: "Empresa",
      from: "settings",
    });
  };

  const handleCRMSettings = (userLogged) => {
    const crmPermission = companyUser?.systemsPermissions?.filter(
      (item) => item.name === "CRM"
    )[0];
    if (crmPermission)
      window.open(
        crmPermission.url +
          `/crm/PartnerLogin.asp?token=${userLogged.tokenCognito}&cnpj=${companyUser.document}&action=settings&redirect=sim`,
        "_blank"
      );
  };

  return {
    handleOpenDataCompany,
    handleCRMSettings,
  };
}
