import React, { useContext, useEffect, useState } from "react";
import Loading from "../../../components/basics/Loading";
import { GlobalSubheader } from "../../../components/subHeader/GlobalSubheader";
import { Button, Grid, RadioGroup } from "@material-ui/core";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { DefaultPageContainer } from "../../../components/layoutContainer/styles/LayoutContainer";
import { ConfigureERPRadioButton } from "./ConfigureERPRadioButton";
import { ComercialContactTab } from "./ComercialContactTab";
import { InactiveERPTab } from "./InactiveERPTab";
import { ConfigureERPContext } from "../contexts/ConfigureERPContext";
import {
  getAccountantClientDataById,
  isComercialContactAlreadyRequested,
} from "../../../repositories/portal_api/AccountantClient";
import MaskService from "../../../utils/mask/maskService";
import { ERPPlansTab } from "./ERPPlansTab";
import { GlobalContext } from "../../../contexts/GlobalContext";
import { ReactComponent as HandbagIcon } from "../../../assets/icons/handbag.svg";
import { ReactComponent as GFIcon } from "../../../assets/icons/GF.svg";
import { notify } from "../../../utils/notify";
import { getUsersByEntityId } from "../../../repositories/portal_api/Users";
import { getSystemsAccountantCanEnable } from "../../../repositories/portal_api/Systems";

export const ConfigureERP = ({ location }) => {
  const [selectedValue, setSelectedValue] = useState("get_erp");
  const [loading, setLoading] = useState(true);
  const [lockedGetErp, setLockedGetErp] = useState(false);
  const [lockedGetErpMessage, setLockedGetErpMessage] = useState("");
  const { push } = useHistory();
  const [configured, setConfigured] = useState(false);
  const [gfPlan, setGfPlan] = useState(null);
  const [gfPayer, setGfPayer] = useState(null);

  const {
    setComercialContactDocument,
    setComercialContactPhone,
    setComercialContactEmail,
    setComercialContactName,
    setErpPlansList,
    setComercialContactAlreadyRequested,
  } = useContext(ConfigureERPContext);

  const { setBreadcrumbs } = useContext(GlobalContext);

  useEffect(() => {
    if (location?.state?.customerId) {
      setLoading(true);

      setComercialContactName("");

      Promise.all([
        getAccountantClientDataById(location.state.customerId),
        getSystemsAccountantCanEnable(),
        getUsersByEntityId(location.state.customerId),
        isComercialContactAlreadyRequested(location.state.customerId),
      ])
        .then((responseArray) => {
          const responseClientData = responseArray[0];
          const responseErpPlans = responseArray[1];
          const responseUsers = responseArray[2];
          const responseComercialContact = responseArray[3];

          if (responseClientData?.success) {
            setBreadcrumbs([
              {
                name: responseClientData?.data?.company_name,
                href: "",
              },
              {
                name: "Configurar Gestão Fácil",
                href: "",
              },
            ]);

            if (responseClientData.data.document.length <= 11) {
              setComercialContactDocument(
                MaskService.toMask("cpf", responseClientData.data.document, "")
              );
            } else {
              setComercialContactDocument(
                MaskService.toMask("cnpj", responseClientData.data.document, "")
              );
            }

            if (responseClientData?.data?.phone) {
              setComercialContactPhone(
                MaskService.toMask(
                  "cel-phone",
                  responseClientData.data.phone,
                  ""
                )
              );
            }

            setConfigured(responseClientData?.data?.configured);
            setGfPlan(responseClientData?.data?.gf_plan);
            setGfPayer(responseClientData?.data?.gf_payer);
          } else {
            notify("Erro ao carregar dados do cliente");
            push("/customermanagement/list");
          }

          if (responseErpPlans?.success) {
            setErpPlansList(responseErpPlans?.data);
          } else {
            setLockedGetErp(true);
            setLockedGetErpMessage(
              "Você não possui acesso para realizar a liberação de sistemas para seus clientes"
            );
          }

          if (responseUsers?.success) {
            if (responseUsers?.data?.length > 0) {
              responseUsers?.data.forEach((user) => {
                if (user?.is_master) {
                  setComercialContactEmail(user?.email);
                }
              });
            }
          }

          if (responseComercialContact.success) {
            setComercialContactAlreadyRequested(
              responseComercialContact.data.comercial_contact_requested
            );
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setLoading(false);
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Loading open={loading} />
      <GlobalSubheader
        title="Gestão de Clientes"
        titleLocation="/customermanagement/list"
      >
        <div style={{ gap: 5, display: "flex", justifyContent: "flex-end" }}>
          <Button
            style={{
              textTransform: "none",
            }}
            onClick={() => {
              push("/customermanagement/list");
            }}
          >
            Voltar
          </Button>
        </div>
      </GlobalSubheader>
      <DefaultPageContainer>
        <div
          style={{
            background: "#fff",
            border: "1px solid #E0E0E0",
            borderRadius: 15,
            padding: 24,
          }}
        >
          <RadioGroup defaultValue="get_erp" name="radio-buttons-group">
            <Grid container justifyContent="space-between">
              <ConfigureERPRadioButton
                title="Solicitar contato comercial da Qyon para o Cliente"
                subtitle="A Qyon entra em contato com o seu cliente para oferecer o Gestão Fácil ERP"
                name="comercial_contact"
                selectValue={setSelectedValue}
                selected={selectedValue}
                icon={<HandbagIcon />}
                xs={configured ? 6 : 6} //FIXME: CHANGE TO 4 : 6 WHEN ACTIVATE BUTTON INACTIVE ERP
              />
              <ConfigureERPRadioButton
                title="Adquira a Plataforma para uso do Cliente"
                subtitle="Comprar o Gestão Fácil para seu cliente utilizar."
                name="get_erp"
                selectValue={(name) => {
                  setSelectedValue(name);
                }}
                selected={selectedValue}
                icon={<GFIcon />}
                xs={configured ? 6 : 6} //FIXME: CHANGE TO 4 : 6 WHEN ACTIVATE BUTTON INACTIVE ERP
              />
              {/* {configured && (
                <ConfigureERPRadioButton
                  title="Gestão Fácil Inativo"
                  subtitle="Para um controle Contábil mais eficiente, considere a utilização do Gestão Fácil ERP"
                  name="inactive_erp"
                  selected={selectedValue}
                  selectValue={setSelectedValue}
                  icon={<GFInactiveIcon />}
                  xs={4}
                />
              )} */}
            </Grid>
          </RadioGroup>
          {selectedValue === "comercial_contact" && (
            <ComercialContactTab
              setLoading={setLoading}
              accountantClientId={location?.state?.customerId}
            />
          )}
          {selectedValue === "get_erp" && (
            <ERPPlansTab
              setLoading={setLoading}
              accountantClientId={location?.state?.customerId}
              configured={configured}
              gfPlan={gfPlan}
              gfPayer={gfPayer}
              lockedGetErp={lockedGetErp}
            />
          )}

          {selectedValue === "inactive_erp" && (
            <InactiveERPTab setLoading={setLoading} />
          )}
        </div>
      </DefaultPageContainer>
    </>
  );
};
