import { CardHeader } from "@material-ui/core";
import styled from "styled-components";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";

export const ArrowBackIconStyled = styled(ArrowBackIcon)`
  margin-top: "5px";
`;

export const CardHeaderStyled = styled(CardHeader)`
  font-size: 12px;
`;

export const LabelStyled = styled.label`
  font-size: 15px;
  color: #0a73db;
  font-weight: 600;
`;
