import { Switch, useLocation } from "react-router-dom";

import MiddlewareRoute from "../Middleware";
import { CustumerManagementContextProvider } from "../../pages/customerManagements/contexts/CustomerManagementContext";
import CustomerManagementCreate from "../../pages/customerManagements/CustomerManagementCreate";
import CustomerManagementList from "../../pages/customerManagements/CustomerManagementList";
import { ModalConfigAccessContextProvider } from "../../pages/customerManagements/contexts/ModalConfigAccessContext";
import { ModalAddressContextProvider } from "../../pages/customerManagements/contexts/ModalAddressContext";
import { ModalUserContextProvider } from "../../pages/customerManagements/contexts/ModalUserContext";
import { ModalRequestVisitContextProvider } from "../../pages/customerManagements/contexts/ModalRequestVisitContext";
import { GlobalVariables } from "../../GlobalVariables";
import { CustomerManagementEdit } from "../../pages/customerManagements/CustomerManagementEdit";
import { DashboardViewContextProvider } from "../../pages/dashboard/contexts/DashboardViewContext";
import { ConfigurePanel } from "../../pages/customerManagements/children/ConfigurePanel";
import { ConfigureERP } from "../../pages/customerManagements/children/ConfigureERP";
import { ConfigureERPContextProvider } from "../../pages/customerManagements/contexts/ConfigureERPContext";

export default function CustomerManagement() {
  let location = useLocation();

  return (
    <CustumerManagementContextProvider>
      <ModalConfigAccessContextProvider>
        <ModalRequestVisitContextProvider>
          <Switch>
            <MiddlewareRoute
              routePermissionId={GlobalVariables.PERMISSIONS.CLIENTES}
              path="/customermanagement/list"
              component={CustomerManagementList}
              sidebar
              noPaddingSidebar
            />
          </Switch>
        </ModalRequestVisitContextProvider>
      </ModalConfigAccessContextProvider>

      <ModalAddressContextProvider>
        <ModalUserContextProvider>
          <Switch>
            <MiddlewareRoute
              routePermissionId={
                location?.state?.customerId &&
                Number(location.state.customerId) !==
                  Number(localStorage.getItem("companyIdPanel"))
                  ? GlobalVariables.PERMISSIONS.CLIENTES_MANAGEMENT
                  : GlobalVariables.PERMISSIONS.SETTINGS_COMPANY
              }
              path="/customermanagement/create"
              component={CustomerManagementEdit}
              sidebar
              noPaddingSidebar
            />
          </Switch>
        </ModalUserContextProvider>
      </ModalAddressContextProvider>

      <DashboardViewContextProvider>
        <Switch>
          <MiddlewareRoute
            routePermissionId={
              location?.state?.customerId &&
              Number(location.state.customerId) !==
                Number(localStorage.getItem("companyIdPanel"))
                ? GlobalVariables.PERMISSIONS.CLIENTES_MANAGEMENT
                : GlobalVariables.PERMISSIONS.SETTINGS_COMPANY
            }
            path="/customermanagement/edit"
            component={CustomerManagementEdit}
            sidebar
            noPaddingSidebar
          />

          <MiddlewareRoute
            routePermissionId={
              location?.state?.customerId &&
              Number(location.state.customerId) !==
                Number(localStorage.getItem("companyIdPanel"))
                ? GlobalVariables.PERMISSIONS.CLIENTES_MANAGEMENT
                : GlobalVariables.PERMISSIONS.SETTINGS_COMPANY
            }
            path="/customermanagement/configure-panel"
            component={ConfigurePanel}
            sidebar
            noPaddingSidebar
          />
        </Switch>
      </DashboardViewContextProvider>

      <ConfigureERPContextProvider>
        <Switch>
          <MiddlewareRoute
            routePermissionId={
              location?.state?.customerId &&
              Number(location.state.customerId) !==
                Number(localStorage.getItem("companyIdPanel"))
                ? GlobalVariables.PERMISSIONS.CLIENTES_MANAGEMENT
                : GlobalVariables.PERMISSIONS.SETTINGS_COMPANY
            }
            path="/customermanagement/configure-erp"
            component={ConfigureERP}
            sidebar
            noPaddingSidebar
          />
        </Switch>
      </ConfigureERPContextProvider>
    </CustumerManagementContextProvider>
  );
}
