import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";

import { removeNotify } from "../../utils/notify";

import { useHistory } from "react-router-dom";

import AddToHomeScreen from "@ideasio/add-to-homescreen-react";
import Loading from "../../components/basics/Loading";
import { GlobalVariables } from "../../GlobalVariables";
import { getLogoBySubdomain } from "../../repositories/panel/v1/EntityRepository";
import { GridStyled, Margin } from "../../styles/GlobalStyle";

import { usePortalAsClient } from "../../utils/viewPortalAsClientHook";
import { LoginContext } from "./contexts/LoginContext";
import useLoginController from "./controllers/LoginController";
import {
  BlueRectangle,
  DotRectangle,
  LoginIcons,
  PasswordIcon,
  QyonLogo,
} from "./LoginIcon";
import {
  BlueRectangleContainer,
  Copyright,
  EndDotRectangle,
  FormLogin,
  IconsRectangle,
  LoginButton,
  LoginContainer,
  LoginHeader,
  LoginHeaderSecondary,
  LoginLabel,
  LoginTextField,
  PrincipalContainer,
  QyonText,
  SideContainer,
  StartDotRectangle,
} from "./styles/Login";

const allowedHosts = [
  "portal",
  "portal1",
  "portal2",
  "portal3",
  "localhost:3000",
];

export default function Login({ location }) {
  const {
    handleSubmit,
    handleChangeEmail,
    handleClearContextAuth,
    handleLogout,
  } = useLoginController();

  const {
    email,
    setEmail,
    password,
    setPassword,
    errors,
    loading,
    setLoading,
  } = useContext(LoginContext);
  const { push } = useHistory();
  const { search } = useLocation();
  const { backToAccountantView } = usePortalAsClient();
  const [logoUrl, setLogoUrl] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    if (location.state && location.state.email) setEmail(location.state.email);
  }, [location, setEmail]);

  useEffect(() => {
    let data = localStorage.getItem("LoginWebsite");

    handleClearContextAuth();

    const logout = new URLSearchParams(search).get("logout") || false;
    if (
      localStorage.getItem("tokenPanel") &&
      !location?.state?.logout &&
      !logout
    ) {
      push("/dashboard/view");
    } else {
      removeNotify();
      backToAccountantView();
      handleLogout();
    }

    if (data) {
      setLoading(true);
      data = JSON.parse(data);
      setEmail(data.email);
      setPassword(data.password);
      setTimeout(() => {
        try {
          document.getElementById("loginForm").requestSubmit();
        } catch (err) {
        } finally {
          localStorage.removeItem("LoginWebsite");
        }
      }, 1);
    }
  }, [
    handleClearContextAuth,
    handleLogout,
    location?.state,
    push,
    search,
    backToAccountantView,
  ]);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);

        const subdomain =
          sessionStorage.getItem("hostPanel") ||
          window.location.host.substring(
            0,
            window.location.host.indexOf(".qyon.")
          ) ||
          window.location.host;

        if (
          sessionStorage.getItem("logoLogin") ||
          allowedHosts.includes(subdomain)
        ) {
          setLogoUrl(sessionStorage.getItem("logoLogin") || "");
          return;
        }

        const logoCallback = await getLogoBySubdomain(subdomain);
        if (logoCallback?.success) {
          let logoUrlCallback = logoCallback?.data?.logo_url || "";
          setLogoUrl(logoUrlCallback);
          sessionStorage.setItem("logoLogin", logoUrlCallback);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    })();
  }, [setLoading]);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  let iconSize = 0;
  if (window.innerHeight <= 800) {
    iconSize = 400;
  } else {
    iconSize = 500;
  }

  return (
    <>
      <Loading open={loading} />
      <PrincipalContainer>
        <SideContainer>
          <StartDotRectangle>
            <DotRectangle />
          </StartDotRectangle>
          <IconsRectangle>
            <LoginIcons width={iconSize} height={iconSize} />
          </IconsRectangle>
          <QyonText>
            Potencializando a contabilidade do futuro com Inteligência
            Artificial
          </QyonText>

          <BlueRectangleContainer>
            <BlueRectangle />
          </BlueRectangleContainer>

          <EndDotRectangle>
            <DotRectangle />
          </EndDotRectangle>
        </SideContainer>

        <LoginContainer>
          <AddToHomeScreen
            appId="Add-to-Homescreen"
            lifespan={-1}
            startDelay={0}
            startAutomatically={true}
            customPromptContent={{
              title:
                "Deseja instalar a Plataforma Qyon em sua área de trabalho?",
              cancelMsg: "Não",
              installMsg: "Sim",
              guidanceCancelMsg: "",
              src: "favicon.ico",
            }}
            customPromptElements={{
              container: "athContainer",
              containerAddOns: "",
              banner: "athBanner",
              logoCell: "athLogoCell",
              logoCellAddOns: "athContentCell",
              logo: "athLogo",
              titleCell: "athTitleCell",
              titleCellAddOns: "athContentCell",
              title: "athTitle",
              cancelButtonCell: "athCancelButtonCell",
              cancelButtonCellAddOns: "athButtonCell",
              cancelButton: "athCancelButton",
              installButtonCell: "athInstallButtonCell",
              installButtonCellAddOns: "athButtonCell",
              installButton: "athInstallButton",
              installButtonAddOns: "button",
              guidance: "athGuidance",
              guidanceImageCell: "athGuidanceImageCell",
              guidanceImageCellAddOns: "",
              guidanceCancelButton: "athGuidanceCancelButton",
            }}
          />
          <div>
            <FormLogin onSubmit={handleSubmit} id="loginForm">
              <GridStyled
                item
                container
                style={{
                  justifyContent: "center",
                  marginBottom: 20,
                  marginTop: 40,
                }}
              >
                {logoUrl && (
                  <img
                    style={{ maxWidth: "100%", maxHeight: 90 }}
                    src={`${logoUrl}`}
                    alt="Logotipo do escritório"
                  />
                )}

                {!logoUrl && <QyonLogo />}
              </GridStyled>

              <LoginHeader>Seja bem-vindo!</LoginHeader>
              <LoginHeaderSecondary>Faça seu Login</LoginHeaderSecondary>

              <GridStyled justifyContent="space-between" container>
                <LoginLabel>E-mail</LoginLabel>
                <LoginTextField
                  disabled={loading}
                  placeholder="nomeusuario@exemplo.com"
                  fullWidth
                  size="medium"
                  variant="outlined"
                  autoComplete="email"
                  autoFocus
                  value={email}
                  onChange={handleChangeEmail}
                />
              </GridStyled>
              <GridStyled
                justifyContent="space-between"
                container
                style={{ marginTop: 10 }}
              >
                <LoginLabel>Senha</LoginLabel>
                <LoginTextField
                  variant="outlined"
                  fullWidth
                  disabled={loading}
                  placeholder="Senha"
                  autoComplete="current-password"
                  type={showPassword ? "text" : "password"}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          <PasswordIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </GridStyled>

              <Margin top="20" />
              <Grid item>
                <LoginButton
                  type="submit"
                  full
                  variant="contained"
                  color="primary"
                >
                  Login
                </LoginButton>
              </Grid>

              <Margin top="10" />

              <Grid container justifyContent="flex-end">
                <Grid item flex={1}>
                  <Link
                    variant="body2"
                    onClick={() => push("/recoverpassword", { email })}
                    style={{
                      cursor: "pointer",
                      fontSize: "12px",
                      fontWeight: 500,
                      color: "#7b8b9c",
                    }}
                  >
                    Esqueci minha senha
                  </Link>
                </Grid>
              </Grid>
              <Margin bottom="20" />
            </FormLogin>

            <Copyright>
              Todos os direitos reservados a QYON Sistemas Inteligentes © Versão{" "}
              {GlobalVariables.SISTEM_VERSION_DATE}
            </Copyright>
          </div>
        </LoginContainer>
      </PrincipalContainer>
    </>
  );
}
