import axios from 'axios'

export const scanZIPData = async (base64, nodeName) => {
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': 'c799c146-6264-4bc6-94d7-56b4cac8deb0',
    }

    let response = await axios.post('https://qyon-bot.qyon.info/v1/bot/scan/zip?nodeName=' + nodeName, { base64 }, { headers: headers });
    return response.status == 200 ? response.data : null;
}

export const saveZIPData = async (base64) => {
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': 'c799c146-6264-4bc6-94d7-56b4cac8deb0',
    }

    let response = await axios.post('https://qyon-bot.qyon.info/v1/bot/save/zip', { base64 }, { headers: headers });
    return response.status == 200 ? response.data : null;
}
