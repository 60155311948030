import { Switch } from "react-router-dom";
import { management } from "../../pages/accessProfile/management";
import MiddlewareRoute from "../Middleware";
import { GlobalVariables } from "../../GlobalVariables";

export default function AccessProfile() {
  return (
    <Switch>
      <MiddlewareRoute
        routePermissionId={GlobalVariables.PERMISSIONS.SETTINGS_USER}
        path="/accessprofile/management"
        sidebar
        component={management}
      />
    </Switch>
  );
}
