import { Switch } from "react-router-dom";
import { CertificateContextProvider } from "../../pages/certificate/contexts/CertificateContext";
import CertificateView from "../../pages/certificate/Certificate";
import MiddlewareRoute from "../Middleware";

export default function Certificate() {
  return (
    <CertificateContextProvider>
      <Switch>
        <MiddlewareRoute
          path="/certificate"
          component={CertificateView}
          sidebar
          noPaddingSidebar={true}
        />
      </Switch>
    </CertificateContextProvider>
  );
}
