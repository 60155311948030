import React from "react";

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import { ItemListStyled, ListStyled } from "./styles/DragAndDropList";

function DragDropList({
  list = [],
  setList = () => {},
  component: Component = () => <></>,
}) {
  function handleOnDragEnd(result) {
    if (!result.destination) return;

    const items = Array.from(list);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setList(items);
  }

  return (
    <div style={{ width: "100%" }}>
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <Droppable droppableId="characters">
          {(provided) => (
            <ListStyled
              className="characters"
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {list.map((item, index) => {
                return (
                  <Draggable
                    key={item.id}
                    draggableId={item.id ? item.id.toString() : ""}
                    index={index}
                  >
                    {(provided) => (
                      <ItemListStyled
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <Component item={item} index={index} />
                      </ItemListStyled>
                    )}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </ListStyled>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
}

export default DragDropList;
