import { Switch } from "react-router-dom";
import BillToPayView from "../../pages/billToPay/BillToPay";
import MiddlewareRoute from "../Middleware";
import { BillToPayContextProvider } from "../../pages/billToPay/contexts/BillToPayContext";

export default function BillToPay() {
  return (
    <BillToPayContextProvider>
      <Switch>
        <MiddlewareRoute
          path="/dfe/management"
          component={BillToPayView}
          sidebar
          noPaddingSidebar={true}
        />
      </Switch>
    </BillToPayContextProvider>
  );
}
