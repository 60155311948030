import React, { useContext, useEffect } from "react";

import { useHistory } from "react-router-dom";

import Box from "@material-ui/core/Box";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";

import Loading from "../../components/basics/Loading";
import TextFieldErp from "../../components/basics/TextFieldErp";
import Btn from "../../components/buttons/Btn";
import { GridStyled, Margin } from "../../styles/GlobalStyle";
import { LoginContext } from "./contexts/LoginContext";
import useLoginController from "./controllers/LoginController";
import { AvatarStyled } from "./styles/Login";

const useStyles = makeStyles((theme) => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  form: {
    width: "100%",
  },
}));

export default function FirstLogin({ location }) {
  const { handlePostUser, handleChangeEmail, handleConfirmUser } =
    useLoginController();
  const {
    newPassword,
    setNewPassword,
    confirmNewPassword,
    setConfirmNewPassword,
    verificationCode,
    setVerificationCode,
    sendCodeVerif,
    setSendCodeVerif,
    email,
    setEmail,

    errors,
    loading,
  } = useContext(LoginContext);
  const { push } = useHistory();

  const classes = useStyles();
  useEffect(() => {
    setVerificationCode("");
    setNewPassword("");
    setConfirmNewPassword("");
  }, []);

  useEffect(() => {
    setSendCodeVerif(location?.state?.sendCodeVerif || false);
  }, [location.state]);

  useEffect(() => {
    if (location.state && location.state.email) setEmail(location.state.email);
  }, [location.state]);

  return (
    <div style={{ backgroundColor: "#c0c0c0" }}>
      <Loading open={loading} />
      <GridStyled
        height="100vh"
        container
        alignItems="center"
        justifyContent="center"
      >
        <Container maxWidth="xs">
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              background: "#fff",
            }}
          >
            <GridStyled
              item
              container
              style={{
                backgroundColor: "#0a73db",
              }}
            >
              <h1></h1>
            </GridStyled>

            <Margin top="80" />
            <AvatarStyled onClick={() => push("/login", { logout: true })}>
              <KeyboardBackspaceIcon />
            </AvatarStyled>
            <Typography component="h1" variant="h5" color="primary">
              Primeiro Acesso
            </Typography>

            <Margin top="10" />
            <form
              className={classes.form}
              onSubmit={sendCodeVerif ? handleConfirmUser : handlePostUser}
            >
              <Grid container justifyContent="center">
                {sendCodeVerif ? (
                  <Grid item flex={1}>
                    <TextFieldErp
                      autoFocus
                      error={errors.verificationCode ? true : false}
                      helperText={errors.verificationCode || false}
                      variant="outlined"
                      label="Código de Verificação"
                      value={verificationCode}
                      setValue={setVerificationCode}
                    />
                  </Grid>
                ) : (
                  <GridStyled justifyContent="center" container>
                    <GridStyled item>
                      <TextFieldErp
                        error={errors.email ? true : false}
                        helperText={errors.email || false}
                        variant="outlined"
                        margin="normal"
                        fullWidth
                        label="E-mail"
                        autoComplete="email"
                        autoFocus
                        value={email}
                        onChange={handleChangeEmail}
                      />
                    </GridStyled>

                    <GridStyled item>
                      <TextFieldErp
                        error={errors.newPassword ? true : false}
                        helperText={errors.newPassword || ""}
                        variant="outlined"
                        margin="normal"
                        label="Escolha sua Senha"
                        type="password"
                        value={newPassword}
                        setValue={setNewPassword}
                      />
                    </GridStyled>

                    <GridStyled item>
                      <TextFieldErp
                        error={errors.confirmNewPassword ? true : false}
                        helperText={errors.confirmNewPassword || ""}
                        variant="outlined"
                        margin="normal"
                        label="Confirme sua Senha"
                        type="password"
                        value={confirmNewPassword}
                        setValue={setConfirmNewPassword}
                      />
                    </GridStyled>
                  </GridStyled>
                )}

                {!sendCodeVerif && (
                  <GridStyled xs={6} item container>
                    <GridStyled justifyContent="center" container>
                      <Link
                        variant="body2"
                        onClick={() => setSendCodeVerif(true)}
                        style={{ cursor: "pointer", fontSize: "10px" }}
                      >
                        Ja tenho um código
                      </Link>
                    </GridStyled>
                  </GridStyled>
                )}
                <Margin top="10" />

                <GridStyled xs={6} item justifyContent="center">
                  <Btn
                    type="submit"
                    full
                    variant="contained"
                    color="primary"
                    label="Enviar"
                  />
                </GridStyled>
              </Grid>

              <Box mt={8}>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  align="center"
                  style={{ fontSize: "10px" }}
                >
                  {"powered by "}
                  <Link color="inherit" href="https://qyon.com/">
                    QYON
                  </Link>{" "}
                  {"."}
                </Typography>
              </Box>
            </form>
          </div>
        </Container>
      </GridStyled>
    </div>
  );
}
