import { v4 as uuidv4 } from "uuid";
import { getIbgeCityCode } from "../../../../repositories/IbgeProvider";

import { isCompany } from "../../../../utils/panelType";
import { getEnvironment } from "../../../../utils/environment";

export const createCompany = async (body, token, userDocument) => {
  const forbiddenCnpjs = ["38469354000152"];

  let hasForbiddenCnpj = false;

  forbiddenCnpjs.forEach((cnpj) => {
    if (cnpj === body.clientCnpj) {
      hasForbiddenCnpj = true;
    }
  });

  if (hasForbiddenCnpj) {
    return null;
  }

  if (body.addresses[0]) {
    body.addresses[0].cep = body.addresses[0].cep
      ? body.addresses[0].cep.replace(/\D/g, "")
      : "";
    body.addresses[0].ibgeCode = body.addresses[0].cep
      ? await getIbgeCityCode(body.addresses[0].cep, token, userDocument)
      : "";
  }

  if (body.companyName === "") {
    body.companyName = body.clientCnpj;
    body.tradingName = body.clientCnpj;
  }

  body.phone = body.phone ? body.phone.replace(/\D/g, "") : "";

  let accountantCnpj =
    localStorage.getItem("isAccountantPanel") === "true"
      ? localStorage.getItem("documentPanel")
      : localStorage.getItem("accountantCnpjPanel");

  if (isCompany() === true) {
    accountantCnpj = localStorage.getItem("documentPanel");
  }

  if (body.clientCnpj === accountantCnpj) {
    body.isAccountant = true;
  } else {
    body.isAccountant = false;
  }

  let environment = getEnvironment();

  let createCompanyJson = {
    id: uuidv4(),
    version: "v1.0.0",
    from: "QYON_DASH",
    to: "ALL",
    timestamp: new Date().getTime(),
    payload: {
      ...body,
    },
    extraData: {
      uiMessage:
        "Solicitado criação de conta da empresa CNPJ: " + body.clientCnpj,
      mainCompanyCnpj: localStorage.getItem("mainEntityCnpjPanel") || "",
      accountantCnpj: accountantCnpj,
      sub: localStorage.getItem("subCognitoPanel"),
      createAccess: isCompany() === true ? false : true,
      env: environment,
    },
    event: "CREATE_ACCOUNTANT_CLIENT",
    priority: "CRITICAL",
  };

  let myHeaders = new Headers();
  myHeaders.append("Authorization", "18309cd6-30ca-4647-b289-fb2ae3e1d2f6");
  myHeaders.append("Content-Type", "application/json");

  let requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: JSON.stringify(createCompanyJson),
    redirect: "follow",
  };

  let response = await new Promise((resolve, reject) => {
    fetch("https://hub.qyon.info/v1/hub/message", requestOptions)
      .then((response) => resolve(response))
      .catch((error) => resolve(null));
  });

  return response;
};
