import { useContext } from "react";
import { useAuth } from "../../../contexts/crm/Auth";
import { GlobalContext } from "../../../contexts/GlobalContext";
import { useChatContext } from "../contexts/ChatContext";
import useWebSocketClient from "./WebSocketClient";

export default function CustomerLobbyController() {
  const webSocketClient = useWebSocketClient();
  const { userLogged } = useContext(GlobalContext);
  const { chat, setChat, SCREEN_ENUM } = useChatContext();
  const { user, company } = useAuth();

  const sendStartChatCustomer = ({
    departmentId,
    departmentName,
    customerInfo,
  }) => {
    webSocketClient.onConnect((data) => {
      const topic = webSocketClient.TOPICS.emit.start;
      setChat({
        conversations: [
          {
            departmentId,
            departmentName,
          },
        ],
        currentScreen: SCREEN_ENUM.loading,
        isLoading: true,
        loadingMessage: "Carregando...",
      });
      webSocketClient.send({
        topic,
        message: {
          identifiers: {
            sessionId: "",
            customerSocketClientId: data.socket.id,
          },
          company_code: company?.id?.toString(),
          company_alias: company.alias,
          email: customerInfo?.email ? customerInfo?.email : userLogged.email,
          username: customerInfo?.fantasyName
            ? customerInfo?.fantasyName
            : user.name,
          contactId: user.contactId,
          room_code: chat.roomId?.toString(),
          product_code: parseInt(departmentId),
          hash: data.socket.id,
        },
        unique: true,
      });
    });
  };

  return {
    sendStartChatCustomer,
  };
}
