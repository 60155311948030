import { useCallback, useMemo } from "react";
import { GlobalVariables } from "../GlobalVariables";

import * as EntityRepository from "../repositories/panel/v1/EntityRepository";

import { notify } from "./notify";

const usePermissions = () => {
  const userPermissions = useMemo(() => {
    const isInClientView = sessionStorage.getItem("isInClientView");
    const permissions = isInClientView
      ? JSON.parse(sessionStorage.getItem("clientViewPermission"))
      : JSON.parse(localStorage.getItem("permissions"));
    return permissions || [];
  }, []);

  const permissionTree = useMemo(
    () => JSON.parse(localStorage.getItem("permissionTree") || "{}"),
    []
  );

  async function getPermissionTreeAndSaveOnLocalStorage(document) {
    const res = await EntityRepository.getPermissionTree({ document });
    if (!res.success || !res.data) return notify("Falha ao buscar permissões");

    localStorage.setItem("permissionTree", JSON.stringify(res.data));
    return res.data;
  }

  async function getUserPermissionsAndSaveOnLocalStorage(document, userId) {
    const res = await EntityRepository.getUserPermission({
      document,
      userId,
    });

    if (!res.success || !res.data)
      return notify("Nenhuma permissão para essa empresa.");

    localStorage.setItem("permissions", JSON.stringify(res.data));
    return res.data;
  }

  const handleLoggedUserPermissionsAndSaveOnLocalStorage = useCallback(
    async (document, userId) => {
      const res = await EntityRepository.getUserPermission({
        document,
        userId,
      });

      if (!res.success || !res.data)
        return notify("Nenhuma permissão para essa empresa.");

      localStorage.setItem("permissions", JSON.stringify(res.data));
      return true;
    },
    []
  );

  /**
   * verifica se o usuario possúi a permissão passada, ou permissoes acima
   * @param {string} permissionId
   * @returns boolean
   */
  const checkIfUserHasPermission = useCallback(
    (permissionId) => {
      const isRoot =
        localStorage.getItem("userIdPanel") ===
        localStorage.getItem("masterUserIdPanel");
      if (isRoot || userPermissions.includes(permissionId)) return true;

      const stack = [permissionTree];
      while (stack.length) {
        const permission = stack.pop();
        if (permission.id === permissionId && permission.parent_id) {
          return checkIfUserHasPermission(permission.parent_id);
        }

        permission.children_recursive &&
          stack.push(...permission.children_recursive);
      }

      return false;
    },
    [permissionTree, userPermissions]
  );

  /**
   * Verifica se tem a permissão ou acima,
   * mas tb verifica se tem algum filho marcado (dando permissão parcial)
   * @param {string} permissionId
   * @returns boolean
   */
  const checkIfUserHasPartialPermission = useCallback(
    (permissionId) => {
      if (checkIfUserHasPermission(permissionId)) return true;

      const stack = [permissionTree];
      let hasPartialPermission = false;
      while (stack.length) {
        const permission = stack.pop();
        if (permission.id === permissionId) {
          hasPartialPermission = permission.children_recursive.some((child) =>
            userPermissions.includes(child.id)
          );
          break;
        }
        permission.children_recursive &&
          stack.push(...permission.children_recursive);
      }

      return hasPartialPermission;
    },
    [checkIfUserHasPermission, permissionTree, userPermissions]
  );

  /**
   * Verifica o status do produto, e retorna true caso esteja ativo
   * @param {"ERP" | "Folha" | "XML" | "CRM" | "Contabil" | "Fiscal" | "BANK" } product
   * @returns boolean
   */
  const validateProductStatus = useCallback((product) => {
    const systemsPermissions = JSON.parse(
      localStorage.getItem("systemsPermissionsPanel")
    );

    const enumMessage = {
      A: null,
      E: `Sua licença do produto ${product} está expirada`,
      C: `Produto ${product} cancelado, caso queira acessar, adquira o produto novamente`,
      B: `Seu usuário esta Bloqueado ou Desativado`,
    };

    const status = systemsPermissions?.find(
      (s) => s.id === GlobalVariables.SYSTEMS[product]
    )?.status_product;

    return {
      isActive: status === "A",
      message: enumMessage[status] || null,
    };
  }, []);

  /**
   * Verifica se irá esconder o menu,
   * TODO adicionar CND quando o mesmo for adicionado ao PLUG
   * @param {"ERP" | "Folha" | "XML" | "CRM" | "Contabil" | "Fiscal" | "BANK" } product
   * @returns boolean
   */
  const handleProductMenuHide = useCallback(
    ({ product, mustBeActive = false }) => {
      const systemsPermissions = JSON.parse(
        localStorage.getItem("systemsPermissionsPanel")
      );

      const hasNotPermission = !systemsPermissions?.find(
        (s) => s.id === GlobalVariables.SYSTEMS[product]
      )?.configured;

      const isNotActive = hasNotPermission
        ? true
        : !validateProductStatus(product).isActive;
      const hide = hasNotPermission || (mustBeActive && isNotActive);

      return hide;
    },
    [validateProductStatus]
  );

  return {
    userPermissions,
    getPermissionTreeAndSaveOnLocalStorage,
    getUserPermissionsAndSaveOnLocalStorage,
    handleLoggedUserPermissionsAndSaveOnLocalStorage,
    checkIfUserHasPermission,
    checkIfUserHasPartialPermission,
    handleProductMenuHide,
    validateProductStatus,
  };
};

export { usePermissions };
