import fetch from "node-fetch";
import { changeAccessTokenStorage } from "../utils";
import { api } from "./api";

const productsOfAttendance = async ({
  userId,
  companyId,
  tokenCognito,
  refreshToken,
}) => {
  if (!userId) return null;
  const responseRequest = await fetch(
    `${process.env.REACT_APP_CRM_API_URL}/v1/product?userId=${userId}`,
    {
      method: "GET",
      headers: {
        "qyon-customer-id": companyId,
        Authorization: tokenCognito,
        refreshToken: refreshToken,
      },
    }
  )
    .then(async (response) => {
      const requestData = await response.json();

      if (requestData.extraData) {
        const token = requestData.extraData.newAccessToken
          ? requestData.extraData.newAccessToken
          : "";
        changeAccessTokenStorage(token);
      }

      return requestData.items.map((department) => {
        return {
          id: department.productId,
          description: department.description,
          selected:
            requestData.productsSelected.indexOf(
              requestData.productsSelected.find((each) => {
                return each === department.productId;
              })
            ) >= 0,
        };
      });
    })
    .catch((exception) => {
      console.log(exception);
    });

  return responseRequest;
};

const statusRoom = async ({
  roomId,
  companyId,
  tokenCognito,
  refreshToken,
}) => {
  if (!roomId) return null;
  if (!companyId) return null;

  return await api
    .get(`/v1/room/${roomId}`, {
      headers: {
        "qyon-customer-id": companyId,
        Authorization: tokenCognito,
        refreshToken: refreshToken,
      },
    })
    .then((response) => {
      if (response.data.extraData) {
        const token = response.data.extraData.newAccessToken
          ? response.data.extraData.newAccessToken
          : "";
        changeAccessTokenStorage(token);
      }
      const arrRooms = response.data?.items || [response.data];
      const rooms = arrRooms?.map((room) => {
        return {
          id: room.roomId,
          online: room.online,
          maxChatCli: room.maxChatCli,
        };
      });
      return {
        success: true,
        rooms,
      };
    })
    .catch((error) => {
      console.error(error);
      return null;
    });
};

const infoAttendantInChat = async ({
  roomId,
  companyId,
  tokenCognito,
  userId,
  refreshToken,
}) => {
  if (!roomId) return null;

  return await api
    .get(`/v1/chat/attendant/${userId}`, {
      headers: {
        "qyon-customer-id": companyId,
        Authorization: tokenCognito,
        refreshToken: refreshToken,
      },
    })
    .then((response) => {
      const _return = response.data;
      if (!_return) return null;

      let responseData = {
        attendanceAmount: response.data.attendanceAmount,
        online: true,
        reason: {
          id: null,
          description: null,
        },
      };
      if (_return.extraData) {
        const token = _return.extraData.newAccessToken
          ? _return.extraData.newAccessToken
          : "";
        changeAccessTokenStorage(token);
      }
      if (_return.status.length) {
        const { online, reason, currentStatus } = _return.status.find(
          (c) => c.roomId === roomId
        );

        responseData = {
          ...responseData,
          online,
          currentStatus,
          reason: {
            id: reason?.id || null,
            description: reason?.description || null,
          },
        };
      }

      return responseData;
    })
    .catch((error) => {
      //console.error("Error on get attendant chat info - error: ", error);
      return null;
    });
};

const reasonChat = async ({ companyId, tokenCognito, refreshToken }) => {
  return await api
    .get(`/v1/reason`, {
      headers: {
        "qyon-customer-id": companyId,
        Authorization: tokenCognito,
        refreshToken: refreshToken,
      },
    })
    .then((response) => {
      const _return = response.data.items;
      if (response.data.extraData) {
        const token = response.data.extraData.newAccessToken
          ? response.data.extraData.newAccessToken
          : "";
        changeAccessTokenStorage(token);
      }
      if (_return.length > 0) {
        return _return.map((reason) => {
          return {
            description: reason.description,
            id: reason.id,
            active: reason.active,
          };
        });
      }
    })
    .catch((error) => {
      console.error(error);
      return null;
    });
};

export { infoAttendantInChat, productsOfAttendance, reasonChat, statusRoom };
