import {
  Avatar,
  Badge,
  Box,
  Grid,
  IconButton,
  Tooltip,
  Zoom,
} from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { InfoOutlined as InfoIcon, Send as SendIcon } from "@material-ui/icons";
import React, { useContext, useEffect, useRef, useState } from "react";

import CircularProgress from "@material-ui/core/CircularProgress";
import { GlobalContext } from "../../../contexts/GlobalContext";
import { ContainerIcon, GridStyled } from "../../../styles/GlobalStyle";
import { GridListAttendance } from "../styles/ListAttendance";
// import Input from "../../input/Input";
import { useChatContext } from "../contexts/ChatContext";
import ChatController from "../controllers/ChatController";
import ConversationController from "../controllers/ConversationController";
import {
  ChatBubbleOutlined,
  WhatsApp as WhastAppIcon,
} from "@material-ui/icons";

import Btn from "../../../components/buttons/Btn";
import parse from "html-react-parser";
import { BoxItemConversation } from "../styles/Contact";
import { useAuth } from "../../../contexts/crm/Auth";

const DarkTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.black,
    color: "#fff",
    boxshadow: theme.shadows[2],
    placement: "right",
    fontSize: 13,
  },
}))(Tooltip);

const TooltipContainer = ({ title, children }) => (
  <DarkTooltip
    title={title}
    placement="right"
    TransitionComponent={Zoom}
    TransitionProps={{ timeout: 100 }}
  >
    {children}
  </DarkTooltip>
);

const useStyles = makeStyles((theme) => ({
  customBadge: {
    backgroundColor: "#FA3A3A",
    color: "white",
    fontSize: 10,
  },
}));

const commomOriginChat = {
  icon: <ChatBubbleOutlined styled={{ cursor: "default" }} />,
  tooltip: "Atendimento padrão Plataforma Qyon",
  badgeColor: "rgb(0, 111, 222)",
  iconSize: 11,
};

const enumOriginChat = Object.freeze({
  WEB: commomOriginChat,
  chat: commomOriginChat,
  whatsappMeta: {
    icon: <WhastAppIcon styled={{ cursor: "default" }} />,
    tooltip: "Atendimento integrado ao WhastApp",
    badgeColor: "rgb(37,211,102)",
    iconSize: 15,
  },
});

const ConversationList = () => {
  const { openConversation } = ConversationController();
  const { chat } = useChatContext();
  const classes = useStyles();

  const chatRef = useRef(chat);
  useEffect(() => {
    chatRef.current = chat;
  }, [chat]);

  const ItemAvatar = ({ name }) => {
    const initialsArray = name ? name.match(/\b(\w)/g) : ["N", "I"];
    let initials = initialsArray[0];
    if (initialsArray.length > 1)
      initials += initialsArray[initialsArray.length - 1];
    else initials += name.charAt(1);
    return (
      <Avatar
        sx={{ width: 40, height: 40, bgcolor: "#ADADAD", color: "#ffffff" }}
      >
        {initials.toUpperCase()}
      </Avatar>
    );
  };

  return (
    <>
      {chatRef.current.conversations.length !== 0 && (
        <Grid
          item
          xs={3}
          style={{ textAlign: "center", overflowY: "scroll", height: "100%" }}
        >
          <Grid container>
            {chatRef.current.conversations?.map((conversation) => {
              const selected =
                conversation.sessionId === chatRef.current.currentConversation
                  ? "selected"
                  : "";
              const integration = conversation?.integrationType;
              return (
                <div style={{ position: "relative", width: "100%" }}>
                  {integration && (
                    <DarkTooltip
                      title={enumOriginChat[integration]?.tooltip}
                      enterDelay={300}
                    >
                      <BoxItemConversation
                        bghovercolor={enumOriginChat[integration]?.badgeColor}
                      >
                        <ContainerIcon
                          iconColor={"white"}
                          fontSize={enumOriginChat[integration]?.iconSize}
                        >
                          {enumOriginChat[integration]?.icon}
                        </ContainerIcon>
                      </BoxItemConversation>
                    </DarkTooltip>
                  )}

                  <TooltipContainer title={conversation.name}>
                    <GridListAttendance
                      select={selected}
                      onClick={() => {
                        if (
                          chatRef.current.currentConversation ===
                          conversation.sessionId
                        )
                          return;
                        openConversation(conversation.sessionId);
                      }}
                    >
                      {conversation.countMessages > 0 ? (
                        <Badge
                          badgeContent={conversation.countMessages}
                          classes={{ badge: classes.customBadge }}
                          max={9}
                        >
                          <ItemAvatar name={conversation.name} />
                        </Badge>
                      ) : (
                        <ItemAvatar name={conversation.name} />
                      )}
                    </GridListAttendance>
                  </TooltipContainer>
                </div>
              );
            })}
          </Grid>
        </Grid>
      )}
    </>
  );
};

const FinalizeAttendance = () => {
  const { openScreenOptions } = ConversationController();
  return (
    <>
      <Grid
        style={{
          width: "100%",
          padding: "10px 0",

          textAlign: "-webkit-center",
        }}
        onClick={() => openScreenOptions(null, 0)}
      >
        <ContainerIcon
          style={{
            flex: 1,
            backgroundColor: "#006FDE",
            color: "#fff",
            marginRight: 0,
            height: "19px",
            borderRadius: "5px",
            width: "91px",
            textAlign: "center",
            cursor: "pointer",
            fontSize: "13px",
          }}
        >
          ENCERRAR
        </ContainerIcon>
      </Grid>
    </>
  );
};

const ENUMUserMessage = {
  myMessage: {
    direction: "right",
    bgColorMessage: "#006FDE",
    border: "8px 0 8px 8px",
    justifyContent: "flex-end",
  },
  anotherMessages: {
    direction: "left",
    bgColorMessage: "#808080",
    border: "0 8px 8px 8px",
    justifyContent: "flex-start",
  },
};

const Message = ({ typeUser, message, time, displayUserName, username }) => {
  const { user } = useAuth();
  const messageAttachment = message.replace(
    /[(<div class=divHistoricoEtapasAnexo>)+{{Arquivo:[^0-9A-Za-z]+}}+<\/div>/gm, // eslint-disable-line no-useless-escape
    ""
  );
  const { direction, bgColorMessage, border, justifyContent } =
    user.type === typeUser
      ? ENUMUserMessage.myMessage
      : ENUMUserMessage.anotherMessages;
  return (
    <Grid
      style={{
        display: "flex",
        justifyContent: direction,
        marginTop: 8,
      }}
    >
      <Grid
        item
        xs={8}
        style={{
          textAlign: "right",
          display: "flex",
          flexDirection: "column",
          alignItems: justifyContent,
        }}
      >
        <Grid
          item
          style={{
            padding: "3% 5%",
            borderRadius: border,
            background: bgColorMessage,
            color: "#fff",
            wordBreak: "break-word",
            display: "block",
            textAlign: "left",
            width: "fit-content",
          }}
        >
          {displayUserName && (
            <b>
              {username}
              <br />
            </b>
          )}
          <span
            style={{
              color: messageAttachment === "" ? "#f5f5f9e7" : "#ffffff",
              fontStyle: messageAttachment === "" ? "italic" : "",
            }}
          >
            {parse(
              message
                .replace(
                  /[(<div class=divHistoricoEtapasAnexo>)+{{Arquivo:[^0-9A-Za-z]+}}+<\/div>/gm, // eslint-disable-line no-useless-escape
                  "Mensagem não suportada!"
                )
                .replaceAll("\n", "<br>")
            )}
            {messageAttachment === "" ? (
              <>
                <br />
                (Envio de arquivos e aúdio não estão disponíveis nessa versão)
              </>
            ) : (
              ""
            )}
          </span>
        </Grid>
        <Grid style={{ textAlign: direction, fontSize: "10px" }}>{time}</Grid>
      </Grid>
      {messageAttachment === "" && (
        <Grid
          item
          xs={4}
          style={{
            display: "flex",
            alignContent: "center",
            alignItems: "center",
            paddingBottom: "7%",
          }}
        >
          <InfoIcon style={{ color: "#DC3545" }} />
        </Grid>
      )}
    </Grid>
  );
};

const MessagesContainer = () => {
  const { messages, chat, setChat, SCREEN_ENUM } = useChatContext();
  const { user, company } = useAuth();
  const { fetchType, fetchClassification } = ConversationController();

  const renderMessages = () => {
    if (!messages || messages.length === 0) return <></>;
    const result = messages.map((messageBody, index) => {
      const messagesCopy = [...messages];
      messagesCopy.reverse();
      const msgIndex = messagesCopy.findIndex((i) => i.id === messageBody.id);
      const displayUserName =
        messageBody.typeUser !== user.type &&
        (msgIndex === 0 ||
          messagesCopy[msgIndex - 1].typeUser !== messageBody.typeUser);

      return (
        messageBody !== "atendimento_encerrado" && (
          <Message
            typeUser={messageBody.typeUser}
            message={messageBody.text}
            username={messageBody.username}
            displayUserName={displayUserName}
            time={messageBody.time}
          />
        )
      );
    });
    return result;
  };

  const handleClose = async () => {
    const types = await fetchType({ company_code: company.id });
    const classification = await fetchClassification({
      company_code: company.id,
    });

    setChat({
      typeAttendace: types,
      classificationAttendance: classification,
      currentScreen: SCREEN_ENUM.closingConversation,
    });
  };

  return (
    <Grid
      id="conversation"
      item
      xs={12}
      // rowSpacing={50}
      style={{
        padding: "2%",
        overflowY: "scroll",
        display: "flex",
        flex: 1,
        flexDirection: "column-reverse",
        scrollBehavior: "smooth",
      }}
    >
      {chat.currentConversation && messages.length === 0 && (
        <Box
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <CircularProgress />
        </Box>
      )}

      {messages.includes("atendimento_encerrado") && (
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box textAlign="center" mt={4} mb={2}>
            O cliente encerrou o atendimento
          </Box>
          <Btn label="Finalizar" onClick={handleClose} />
        </Box>
      )}

      {renderMessages()}
    </Grid>
  );
};

const SendContainer = () => {
  const { companyUser } = useContext(GlobalContext);
  const { chat, messages } = useChatContext();
  const { company } = useAuth();
  const { sendMessageSocket } = ConversationController();
  const { getConversation } = ChatController();
  const [newMessage, setNewMessage] = useState("");
  const [typing, setTyping] = useState(false);

  useEffect(() => {
    if (
      !messages.includes("atendimento_encerrado") ||
      chat.currentConversation
    ) {
      const editText = document.getElementById("newMessageEditText");
      const sessionMessage = sessionStorage.getItem("handleCurrentMessage");

      editText.value = sessionMessage || "";
      setNewMessage(sessionMessage || "");
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onChangeTextArea = (e) => {
    sessionStorage.setItem(
      "handleCurrentMessage",
      e.target.value.replace("\n", "")
    );
    setNewMessage(e.target.value.replace("\n", ""));
  };

  const messageFormat = () => {
    const convertTextToLink = ({ message }) => {
      var urlRegex = /(https?:\/\/[^\s]+)/g;
      return message.replace(urlRegex, function ({ url }) {
        return (
          <a href={url} target="_blank" rel="noreferrer">
            {url}{" "}
          </a>
        );
      });
    };
    return newMessage.split("\n").map((line) => {
      return (
        <span>
          {line}
          <br />
        </span>
      );
    });
  };

  const sendMessage = () => {
    const getDateTimeNow = (fullDate) => {
      return (
        (fullDate.getHours() < 10 ? "0" : "") +
        fullDate.getHours() +
        ":" +
        (fullDate.getMinutes() < 10 ? "0" : "") +
        fullDate.getMinutes()
      );
    };
    let message = newMessage;
    if (message.trim() !== "" && message.trim()) {
      const currentConversation = getConversation();

      const messageId = `${company.id}${
        currentConversation?.ticketId
          ? currentConversation?.ticketId
          : chat.currentTicketId
          ? chat.currentTicketId
          : "0000"
      }${new Date().getTime().toString()}`;
      sendMessageSocket({
        isAttendant: companyUser.isAccountant === "true",
        message,
        messageId,
        sessionId: chat.currentConversation,
      });
      setNewMessage("");
      sessionStorage.removeItem("handleCurrentMessage");
    }
  };

  const sendAudio = () => {};

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      if (!event.shiftKey) {
        sendMessage();
      }
    }
  };

  function getInputPlaceholder() {
    if (messages.includes("atendimento_encerrado")) return "Finalizado";
    if (!chat.currentConversation) return "Sem conversa";
  }

  return (
    <Grid
      item
      container
      xs={12}
      style={{ flexWrap: "nowrap", flex: 0, padding: "10px 0 10px 10px" }}
    >
      <Grid item xs={9}>
        <Grid
          container
          style={{
            borderRadius: "100px",
            background: "#F2F2F2",
            height: "40px",
            overflow: "hidden",
          }}
        >
          <textarea
            id="newMessageEditText"
            disabled={
              messages.includes("atendimento_encerrado") ||
              !chat.currentConversation
            }
            placeholder={getInputPlaceholder()}
            rows="1"
            autoFocus
            onKeyPress={handleKeyPress}
            onChange={onChangeTextArea}
            value={newMessage}
            style={{
              border: "0",
              outline: "none",
              background: "none",
              fontSize: "15px",
              padding: "5% 10px 0 10px",
              minHeight: "99%",
              width: "100%",
              maxHeight: "100%",
              resize: "none",
            }}
          />
          {/* <ContainerIcon
            style={{ width: "20%" }}
            fontSize="15"
          >
            <IconButton>
              <AttachFileIcon style={{ color: "#B3B3B3" }} />
            </IconButton>
          </ContainerIcon> */}
        </Grid>
      </Grid>
      <Grid item container xs={3} style={{ textAlign: "center" }}>
        <Grid item xs={12}>
          <ContainerIcon
            // onClick={newMessage.trim() ? sendMessage : sendAudio}
            onClick={sendMessage}
            fontSize="15"
          >
            <IconButton style={{ background: "#006FDE" }}>
              <SendIcon style={{ color: "#FFFFFF" }} />
              {/* {newMessage.trim() ? (
                <SendIcon style={{ color: "#FFFFFF" }} />
              ) : (
                <KeyboardVoiceIcon style={{ color: "#FFFFFF" }} />
              )} */}
            </IconButton>
          </ContainerIcon>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default function ConversationScreen() {
  const { chat, messages } = useChatContext();
  const { companyUser } = useContext(GlobalContext);

  return (
    <>
      {companyUser?.isAccountant === "true" && <ConversationList />}
      {!chat.currentConversation && (
        <Grid
          item
          container
          xs={
            companyUser?.isAccountant === "true" && chat.conversations.length
              ? 9
              : 12
          }
          direction="row"
          style={{ height: "100%" }}
        >
          <Grid
            style={{
              display: "flex",
              width: "100%",
              alignContent: "center",
              alignItems: "center",
              justifyContent: "center",
              marginTop: 8,
            }}
          >
            {chat.conversations.length === 0
              ? "Nenhum atendimento em andamento!"
              : "Nenhum atendimento selecionado!"}
          </Grid>
        </Grid>
      )}
      {chat.currentConversation && (
        <Grid
          item
          container
          xs={companyUser?.isAccountant === "true" ? 9 : 12}
          direction="column"
          style={{ height: "100%", alignContent: "center", flexWrap: "nowrap" }}
        >
          {chat.currentConversation &&
            messages.length !== 0 &&
            !messages.includes("atendimento_encerrado") && (
              <FinalizeAttendance />
            )}
          {/* {
            chat.currentConversation &&
            messages.length !== 0 &&
            !messages.includes('atendimento_encerrado') && 
            <ConversationOptions/> 
          } */}
          <Grid
            item
            style={{
              width: "100%",
              padding: "1% 1% 1% 1%",
              display: "flex",
              flexDirection: "column",
              flex: "1",
              flexWrap: "nowrap",
              maxHeight: "92%",
              borderRadius:
                companyUser.isAccountant === "true"
                  ? "10px 10px 10px 10px"
                  : "20px 20px 20px 20px",
              background: "#FFFFFF",
            }}
          >
            <MessagesContainer />
            <SendContainer />
          </Grid>
        </Grid>
      )}
    </>
  );
}
