import {
  CircularProgress,
  Container,
  Grid,
  MenuItem,
  Select,
} from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import Btn from "../../../components/buttons/Btn";
import { notify } from "../../../utils/notify";
import { useChatContext } from "../contexts/ChatContext";
import ChatController from "../controllers/ChatController";
import CustomerLobbyController from "../controllers/CustomerLobbyController";
import { TypographyStyled } from "../styles/GeneralStyle";

export default function CustomerLobby() {
  const theme = useTheme();

  const { settings } = useChatContext();
  const controller = CustomerLobbyController();
  const chatController = ChatController();
  const [departmentId, setDepartmentId] = useState(1);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    settings.departments &&
      settings.departments.forEach((department, index) => {
        if (index === 0) setDepartmentId(department.id);
      });
  }, [settings.departments, setDepartmentId]);

  function handleSubmit() {
    if (!departmentId) return notify("Selecione um departamento!");
    setIsLoading(true);
    chatController.sendConnectCustomer();
    chatController.subscribeOnConnection();
    chatController.subscribeOnStart();

    controller.sendStartChatCustomer({
      departmentId: departmentId,
      departmentName: settings.departments.find(
        (department) => department.id === departmentId
      ).description,
    });
  }

  return (
    <Container style={{ padding: theme.spacing(2) }}>
      <form onSubmit={() => {}}>
        <Grid
          item
          container
          alignItems="center"
          alignContent="center"
          style={{ width: "100" }}
        >
          <Grid item sm={12}>
            {settings?.departments?.length > 0 ? (
              <Select
                fullWidth
                style={{
                  alignContent: "flex-start",
                  textAlign: "left",
                }}
                value={departmentId}
                onChange={(e) => setDepartmentId(e.target.value)}
              >
                <MenuItem value="" disabled>
                  Selecione o departamento
                </MenuItem>
                {settings.departments &&
                  settings.departments.map((department) => (
                    <MenuItem value={department.id || department}>
                      {department.description}
                    </MenuItem>
                  ))}
              </Select>
            ) : (
              <Grid
                container
                style={{
                  display: "flex",
                  justifyContent: "space-evenly",
                  alignItems: "center",
                }}
              >
                {settings?.departments.length === 0 ? (
                  <>
                    <Grid item>
                      <TypographyStyled>
                        Não há atendentes disponíveis no momento!
                      </TypographyStyled>
                    </Grid>
                  </>
                ) : (
                  <>
                    <Grid item>
                      <TypographyStyled>
                        Carregando departamentos...
                      </TypographyStyled>
                    </Grid>
                    <Grid item>
                      <CircularProgress />
                    </Grid>
                  </>
                )}
              </Grid>
            )}
          </Grid>
          <Grid
            item
            sm={12}
            style={{ padding: theme.spacing(4), display: "inline-grid" }}
          >
            <Btn label="Entrar" disabled={isLoading} onClick={handleSubmit} />
          </Grid>
        </Grid>
      </form>
    </Container>
  );
}
