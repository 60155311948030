import { LightTooltip } from "../../../styles/GlobalStyle";
import { RpaStatus } from "../../../utils/RpaStatus";
import { ChipCnd, PulseIcon } from "../styles/CndCustomer";
//TODO update references in customer tab cnd

export const OptionsIcons = {
  [RpaStatus.NEGATIVA_POSITIVA.description]: (
    <LightTooltip title="Positiva com efeito Negativa">
      <ChipCnd size="small" bg-color="blue" />
    </LightTooltip>
  ),
  [RpaStatus.NEGATIVA_POSITIVA.description + "aVencer"]: (
    <LightTooltip title="Positiva com efeitos de Negativa a vencer">
      <span>
        <ChipCnd size="small" bg-color="blue" $width="24" $marginRight="1" />
        <ChipCnd size="small" bg-color="purple" $width="24" $marginLeft="1" />
      </span>
    </LightTooltip>
  ),
  [RpaStatus.NEGATIVA_POSITIVA.description + "Vencida"]: (
    <LightTooltip title="Positiva com efeitos de Negativa vencida">
      <span>
        <ChipCnd size="small" bg-color="blue" $width="24" $marginRight="1" />
        <ChipCnd size="small" bg-color="red" $width="24" $marginLeft="1" />
      </span>
    </LightTooltip>
  ),

  [RpaStatus.NEGATIVA.description]: (
    <LightTooltip title="Negativa">
      <ChipCnd size="small" bg-color="green" />
    </LightTooltip>
  ),
  [RpaStatus.NEGATIVA.description + "aVencer"]: (
    <LightTooltip title="Negativa a vencer">
      <span>
        <ChipCnd size="small" bg-color="green" $width="24" $marginRight="1" />
        <ChipCnd size="small" bg-color="purple" $width="24" $marginLeft="1" />
      </span>
    </LightTooltip>
  ),
  [RpaStatus.NEGATIVA.description + "Vencida"]: (
    <LightTooltip title="Negativa vencida">
      <span>
        <ChipCnd size="small" bg-color="green" $width="24" $marginRight="1" />
        <ChipCnd size="small" bg-color="red" $width="24" $marginLeft="1" />
      </span>
    </LightTooltip>
  ),

  [RpaStatus.POSITIVA.description]: (
    <LightTooltip title="Positiva">
      <ChipCnd size="small" bg-color="orange" />
    </LightTooltip>
  ),
  [RpaStatus.POSITIVA.description + "aVencer"]: (
    <LightTooltip title="Positiva a vencer">
      <span>
        <ChipCnd size="small" bg-color="orange" $width="24" $marginRight="1" />
        <ChipCnd size="small" bg-color="purple" $width="24" $marginLeft="1" />
      </span>
    </LightTooltip>
  ),
  [RpaStatus.POSITIVA.description + "Vencida"]: (
    <LightTooltip title="Positiva vencida">
      <span>
        <ChipCnd size="small" bg-color="orange" $width="24" $marginRight="1" />
        <ChipCnd size="small" bg-color="red" $width="24" $marginLeft="1" />
      </span>
    </LightTooltip>
  ),

  [RpaStatus.ERRO.description]: (
    <LightTooltip title="Falha">
      <ChipCnd size="small" bg-color="yellow" />
    </LightTooltip>
  ),
  [RpaStatus.FALHA.description]: (
    <LightTooltip title="Falha">
      <ChipCnd size="small" bg-color="yellow" />
    </LightTooltip>
  ),

  [RpaStatus.PROCESSANDO.description]: (
    <PulseIcon active={true}>
      <LightTooltip title="Processando">
        <ChipCnd size="small" bg-color="gray" />
      </LightTooltip>
    </PulseIcon>
  ),

  [RpaStatus.INCONSISTENTE.description]: (
    <LightTooltip title="Inconsistente">
      <ChipCnd size="small" bg-color="grayBlue" />
    </LightTooltip>
  ),
};
