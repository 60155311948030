import React, { useEffect, useState } from "react";

import { Button, Grid } from "@material-ui/core";
import Modal from "@material-ui/core/Modal";
import Typography from "@material-ui/core/Typography";

import CloseIcon from "@material-ui/icons/Close";

import Carousel, { Dots } from "@brainhubeu/react-carousel";
import "@brainhubeu/react-carousel/lib/style.css";
import "./styles/BotActionModal.css";
//import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

import { getMP3Audio } from "../../../repositories/AudioHandler";
import {
  deleteAction,
  getActions,
  getAllActions,
  markAsSeen,
} from "../../../repositories/Bot";
import { getActionDescription } from "./ActionUtil";
import { getEnvironment } from "../../../utils/environment";

import {
  DialogStyle,
  DialogHeader,
  BorderlessBodyStyle,
} from "../../modal/Modal";
import { ButtonCarrousel } from "./styles/BotActionModal";
import { ArrowBackIos, ArrowForwardIos } from "@material-ui/icons";

export const BotNotificationModal = (props) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [actionTotal, setActionTotal] = useState(0);
  const [loading, setLoading] = useState(true);
  const [actions, setActions] = useState([]);
  const [idRefresh, setIdRefresh] = useState("");
  const [key, setKey] = useState(0);
  let parentCnpj =
    localStorage.getItem("isAccountantPanel") === "true"
      ? localStorage.getItem("documentPanel")
      : localStorage.getItem("accountantCnpjPanel");
  let accountantCnpj =
    localStorage.getItem("isAccountantPanel") === "true"
      ? localStorage.getItem("documentPanel")
      : localStorage.getItem("accountantCnpjPanel");

  const getOriginDescription = (origin) => {
    const descriptions = {
      DARF_UPLOAD: "Enviar DARF",
      DAS_UPLOAD: "Enviar DAS",
      BOLETO_UPLOAD: "Enviar Boleto",
      ACCOUNTANT_CLIENT_LINK_REQUEST: "Cadastrar",
      ACCOUNTANT_CLIENT_LINK_RESPONSE: "Entendi",
      A1_UPLOAD: "iniciar",
      XML_UPLOAD: "enviar",
      ESOCIAL_UPLOAD_SET_COMPLETE: "ok",
      ATTENDANCE_UPDATE_NOTIFICATION: "ok",
      DCTF_RETURN_UPLOAD: "Enviar DCTF",
      FGTS_UPLOAD: "Enviar FGTS",
    };

    return descriptions[origin] || "ok";
  };

  const refresh = (idToRemove = null, botMessage = false) => {
    async function fetchAPI() {
      let response =
        props.product === "ALL"
          ? await getAllActions()
          : await getActions(props.product);

      if (response === null || response.status !== 200) {
        return;
      }

      if (idToRemove) {
        let remove = response.data.filter(
          (e) => e.bot_action_id === idToRemove
        )[0];
        await deleteAction(remove.action, remove.bot_action_id);
        await props.onDecreaseBadge();
      }

      response =
        props.product === "ALL"
          ? await getAllActions()
          : await getActions(props.product);

      let data = response.data;
      data = data.filter((e) => e.seen === 0);

      for (let dt of data) {
        dt.buttonDisabled = false;
      }

      response.headers.count = parseInt(response.headers.count);

      setActions(data);
      setActionTotal(response.headers.count);
      if (botMessage === true) {
        let message = "";
        if (response.headers.count === 0) {
          message =
            "Você não possui nenhuma pendência com que precisa se preocupar no momento";
        } else {
          message =
            "Notei que você tem um total de " +
            response.headers.count +
            (response.headers.count > 1 ? " pendências" : " pendência");
        }

        getMP3Audio(message).then((result) => {
          var snd = new Audio("data:audio/wav;base64," + result);
          snd.play();
        });
      }
      setKey(Math.random());
      setTimeout(() => {
        setLoading(false);
      }, 100);
    }

    fetchAPI();
  };

  useEffect(() => {
    window.addEventListener("message", async function (e) {
      try {
        if (e.data.includes("SUCCESS-")) {
          let id = e.data.replace("SUCCESS-", "");
          refresh(id);
        }

        if (e.data.includes("END-LOADING-")) {
          let id = e.data.replace("END-LOADING-", "");
          for (let action of actions) {
            if (action.id === id) {
              action.buttonDisabled = false;
            }
          }

          let response =
            props.product === "ALL"
              ? await getAllActions()
              : await getActions(props.product);

          let data = response.data;
          data = data.filter((e) => e.seen === 0);

          for (let dt of data) {
            dt.buttonDisabled = false;
          }

          setActions(data);
          setActionTotal(response.headers.count);

          setIdRefresh(Math.random());
        }
      } catch (err) {}
    });

    refresh(null, true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const markAsReaded = async (action) => {
    await markAsSeen(action.bot_action_id);

    setCurrentIndex(0);

    refresh(null, false);

    await props.onDecreaseBadge();
  };

  let environment = getEnvironment();
  return (
    <Modal
      open={props.open}
      onClose={() => {
        props.onCloseActionModal();
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <DialogStyle style={{ width: 800, minHeight: 400 }}>
        <DialogHeader>
          <div className="row">
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginRight: 20,
                marginBottom: 10,
              }}
            >
              {actions.length === 0 && <>Não há pendências no momento</>}
              {actions.length !== 0 && (
                <>QYON BOT - {getActionDescription(actions[currentIndex])}</>
              )}
            </div>

            <CloseIcon
              style={{
                position: "absolute",
                right: 0,
                cursor: "pointer",
                color: "rgba(126, 137, 158, 1)",
              }}
              onClick={() => {
                props.onCloseActionModal();
              }}
            />
          </div>
        </DialogHeader>

        <BorderlessBodyStyle style={{ margin: 10 }}>
          {actionTotal !== 0 && (
            <div style={{ fontSize: 14 }}>
              Pendência {currentIndex + 1}{" "}
              {actionTotal > 5
                ? " das primeiras 5 pêndencias"
                : " de " + actionTotal}
            </div>
          )}
          <div key={key}>
            {actions.length === 0 && (
              <Typography style={{ textAlign: "center", marginTop: 125 }}>
                Não há pendências no momento
              </Typography>
            )}

            <Carousel
              show
              value={currentIndex}
              onChange={(index) => {
                setCurrentIndex(index);
              }}
            >
              {actions.map((action, index) => {
                return (
                  <Grid
                    container
                    spacing={2}
                    style={{ height: "100%" }}
                    id={"ITEM_" + index}
                  >
                    <Grid
                      item
                      xs={12}
                      style={{
                        paddingTop: 0,
                        paddingBottom: 20,
                        paddingLeft: 10,
                        paddingRight: 10,
                      }}
                    >
                      <iframe
                        title="208"
                        style={{
                          height: 450,
                          visibility: loading === true ? "hidden" : "visible",
                        }}
                        id={action.bot_action_id}
                        src={`https://s3.sa-east-1.amazonaws.com/qyon.panel/${
                          action.widget_id
                        }.html?cnpj=${localStorage.getItem(
                          "documentPanel"
                        )}&mainCompanyCnpj=${localStorage.getItem(
                          "mainEntityCnpjPanel"
                        )}&parentCnpj=${parentCnpj}&accountantCnpj=${accountantCnpj}&sub=${localStorage.getItem(
                          "subCognitoPanel"
                        )}&id=${
                          action.bot_action_id
                        }&token=${localStorage.getItem("tokenCognitoPanel")}
                        &tokenPanel=${localStorage.getItem("tokenPanel")}
                        &environment=${environment}`}
                        frameborder="0"
                        width="100%"
                        height="100%"
                        allowtransparency="true"
                        name="208"
                      ></iframe>

                      <div style={{ marginTop: 10 }}>
                        <Grid container spacing={1}>
                          <Grid item xs={6}>
                            <Button
                              fullWidth
                              style={{
                                zIndex: 1000,
                                background: "#F0F1F3",
                                color: "#364461",
                                fontSize: 16,
                                fontWeight: "bold",
                                height: 40,

                                marginRight: 10,
                              }}
                              onClick={() => {
                                document
                                  .getElementById(action.bot_action_id)
                                  .contentWindow.postMessage("DISCART", "*");
                                markAsReaded(actions[currentIndex]);
                              }}
                            >
                              Descartar
                            </Button>
                          </Grid>

                          <Grid item xs={6}>
                            <Button
                              disabled={action.buttonDisabled}
                              key={idRefresh}
                              fullWidth
                              style={{
                                zIndex: 1000,
                                background: action.buttonDisabled
                                  ? "#FBFBFD"
                                  : "#0036B3",
                                color: action.buttonDisabled
                                  ? "#364461"
                                  : "white",
                                fontSize: 16,
                                fontWeight: "bold",
                                height: 40,

                                marginRight: 10,
                              }}
                              onClick={() => {
                                if (action.action === "ESOCIAL_START") {
                                  action.buttonDisabled = true;
                                  setIdRefresh(Math.random());
                                }

                                document
                                  .getElementById(action.bot_action_id)
                                  .contentWindow.postMessage("DONE", "*");

                                if (
                                  action.origin ===
                                    "ATTENDANCE_UPDATE_NOTIFICATION" ||
                                  !action.origin
                                ) {
                                  props.onCloseActionModal();
                                }
                              }}
                            >
                              {getOriginDescription(action.origin)}
                            </Button>
                          </Grid>
                        </Grid>
                      </div>
                    </Grid>
                  </Grid>
                );
              })}
            </Carousel>
          </div>
        </BorderlessBodyStyle>

        {actionTotal !== 0 && (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              paddingLeft: 34,
              paddingRight: 34,
              paddingTop: 10,
              paddingBottom: 10,
              marginTop: 30,
            }}
          >
            <ButtonCarrousel
              disabled={currentIndex === 0}
              size="small"
              color="variant"
              onClick={() => {
                if (currentIndex === 0) return;
                setCurrentIndex(currentIndex - 1);
              }}
              startIcon={<ArrowBackIos />}
            >
              Voltar
            </ButtonCarrousel>

            <Dots
              value={currentIndex}
              number={actionTotal > 5 ? 5 : actionTotal}
              onChange={(index) => {
                setCurrentIndex(index);
              }}
            />

            <ButtonCarrousel
              disabled={currentIndex === actions.length - 1}
              size="small"
              color="variant"
              onClick={() => {
                if (currentIndex === actions.length - 1) return;
                setCurrentIndex(currentIndex + 1);
              }}
              endIcon={<ArrowForwardIos />}
            >
              Próximo
            </ButtonCarrousel>
          </div>
        )}
      </DialogStyle>
    </Modal>
  );
};
