import React, { createContext, useState } from "react";

const ModalConfigAccessContext = createContext();

const ModalConfigAccessContextProvider = ({ children }) => {
  const [versionConfig, setVersionConfig] = useState(null);
  const [accessNameConfig, setAccessNameConfig] = useState("");
  const [emailConfig, setEmailConfig] = useState("");
  const [typeLimit, setTypeLimit] = useState("trial");

  const [listVersions, setListVersions] = useState([]);
  const [errors, setErrors] = useState({});

  const payerOptions = [
    {
      value: "client",
      description: "Em nome do seu cliente",
      hint: "Será enviado uma solicitação se o cliente aceita pagar este novo valor",
    },
    {
      value: "cont",
      description: "Em meu nome",
      hint: "Valor será somado a sua mensalidade",
    },
  ];

  const [payer, setPayer] = useState(payerOptions[1]);

  return (
    <ModalConfigAccessContext.Provider
      value={{
        versionConfig,
        setVersionConfig,
        accessNameConfig,
        setAccessNameConfig,
        emailConfig,
        setEmailConfig,
        typeLimit,
        setTypeLimit,

        payer,
        setPayer,
        payerOptions,

        listVersions,
        setListVersions,
        errors,
        setErrors,
      }}
    >
      {children}
    </ModalConfigAccessContext.Provider>
  );
};

export { ModalConfigAccessContextProvider, ModalConfigAccessContext };
