import { useContext } from "react";

import { GlobalContext } from "../../../contexts/GlobalContext";
import { putEntity } from "../../../repositories/panel/v1/EntityRepository";
import { usePortalAsClient } from "../../../utils/viewPortalAsClientHook";

export default function useBannerBankController() {
  const { userLogged, companyUser } = useContext(GlobalContext);
  const { isInClientView, clientViewed } = usePortalAsClient();

  const handleSubmitBanner = async ({ noDisplay, setBanner }) => {
    try {
      if (noDisplay) {
        putEntity({
          body: {
            show_banner_bank: false,
          },
          id: isInClientView ? clientViewed?.company : companyUser.companyId,
        });

        let dataBanner = localStorage.getItem("dataBannerQyonBank");

        dataBanner = JSON.parse(dataBanner);
        if (dataBanner) dataBanner.show_banner_bank = false;

        localStorage.setItem(
          "dataBannerQyonBank",
          JSON.stringify(dataBanner || {})
        );
      }

      window.open(
        `https://gestaofacil.qyon.com/zf/login/loginpanel?tokenCognito=${userLogged.token}&document=${companyUser.document}`,
        "_blank"
      );

      setBanner({});
    } catch (error) {
      console.error(error);
    } finally {
    }
  };

  return {
    handleSubmitBanner,
  };
}
