import styled from "styled-components";
import { DataGrid } from "@material-ui/data-grid";
import { List, Grid, IconButton } from "@material-ui/core";
const roboto = (props) => props.theme.fonts.roboto;

export const SearchLabel = styled.div`
  color: #364461;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
`;

export const BillToPayDataGrid = styled(DataGrid)`
  border: 0px !important;

  .MuiDataGrid-window {
    ::-webkit-scrollbar {
      height: 13px !important;
    }

    ::-webkit-scrollbar-track {
      background-color: #efefef;
    }

    ::-webkit-scrollbar-thumb {
      background-color: lightgrey;
    }
  }

  .MuiDataGrid-columnHeaderWrapper {
    font-family: ${roboto}, sans-serif !important;
    font-weight: 500;
    white-space: normal;
  }

  .MuiDataGrid-columnsContainer {
    min-height: 40px !important;
    line-height: 20px !important;
  }

  .MuiDataGrid-columnHeaderTitle {
    white-space: normal !important;
    text-overflow: unset !important;
  }

  .MuiDataGrid-root {
    border: 5px solid #000 !important;
  }

  div .MuiTablePagination-toolbar {
    .MuiTablePagination-caption:first-of-type {
      display: none;
    }
  }

  .MuiDataGrid-columnSeparator {
    display: none !important;
  }

  .MuiTablePagination-displayedRows {
    display: none !important;
  }
`;

export const RowColumn = styled.div`
  display: flex;
  height: 32px;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  background: var(--Quasar-Secundary-Navy-Blue-01, #e7e7fe);
  color: var(--Quasar-Secundary-Navy-Blue-08, #020275);
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20.02px; /* 143% */
  letter-spacing: 0.15px;
`;

export const FileColumn = styled.div`
  display: flex;
  height: 32px;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 32px;
  background: var(--Quasar-Primary-Blue-05, #1e60fb);
  color: var(--White-Color, #fff);
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20.02px; /* 143% */
  letter-spacing: 0.15px;
`;

export const InputColumn = styled.div`
  display: flex;
  height: 32px;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  color: var(--Grey-800, #424242);
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20.02px; /* 143% */
  letter-spacing: 0.15px;
  border-radius: 4px;
  background: var(--Grey-100, #f5f5f5);
`;

export const DocumentNumberColumn = styled.div`
  display: flex;
  height: 32px;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  background: var(--Quasar-Secundary-Navy-Blue-01, #e7e7fe);
  color: var(--Quasar-Secundary-Navy-Blue-08, #020275);
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20.02px; /* 143% */
  letter-spacing: 0.15px;
`;

export const CompanyColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CompanyNameColumn = styled.div`
  overflow: hidden;
  color: rgba(0, 0, 0, 0.87);
  text-overflow: ellipsis;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 114.286% */
  letter-spacing: 0.15px;
`;

export const CompanyDocumentColumn = styled.div`
  color: var(--Grey-700, #616161);
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px; /* 116.667% */
  letter-spacing: 0.4px;
`;

export const DateColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

export const DatePrincipalColumn = styled.div`
  overflow: hidden;
  color: rgba(0, 0, 0, 0.87);
  text-overflow: ellipsis;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; /* 114.286% */
  letter-spacing: 0.15px;
`;

export const DateHourColumn = styled.div`
  color: var(--Grey-700, #616161);
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px; /* 116.667% */
  letter-spacing: 0.4px;
`;

export const ListDuplicatas = styled(List)`
  padding: 0px;
  max-height: 270px;
  min-height: 270px;
  overflow-y: auto;
  overflow-x: hidden;

  ::-webkit-scrollbar {
    height: 13px !important;
  }
  ::-webkit-scrollbar-track {
    background-color: #efefef;
  }

  ::-webkit-scrollbar-thumb {
    background-color: lightgrey;
  }
`;

export const GridDuplicataContainer = styled(Grid)`
  background: #f5f5f5;
  border-radius: 8px;
  border: 1px solid #cfd8dc;
  padding: 16px 8px 16px 0px;
  margin-bottom: 8px;
`;

export const IconButtonDuplicata = styled(IconButton)`
  .MuiIconButton-root {
    padding: 0px;
  }
`;

export const LabelBillToPay = styled.div`
  color: #364461;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 8px;
`;
