import { api } from "./api";

export const getAllCustomersInQueue = ({ webSocketClient }) => {
  return {
    fromAttendantId: async ({
      userId,
      roomId,
      companyId,
      sessionId,
      socketId,
    }) => {
      return await api
        .get(
          `${
            webSocketClient.urlWebSocketClient || localStorage.getItem("URL_WS")
          }/queues/${userId}?room_code=${roomId}&company_code=${companyId}`,
          {
            headers: {
              sessionId,
              customerSocketClientId: socketId,
            },
          }
        )
        .then((response) => {
          if (response.status !== 200) return null;
          return response.data.queue;
        })
        .catch((error) => {
          console.error(error);
          return null;
        });
    },
    fromQueueId: async ({ queueId }) => {
      const res = await api.get(
        `${
          webSocketClient.urlWebSocketClient || localStorage.getItem("URL_WS")
        }/queues/?queue_id=${queueId}`,
        { headers: { "Content-Type": "application/json" } }
      );
      if (res?.status !== 200) {
        console.error(res.status);
        return null;
      }
      return res.data;
    },
  };
};

export const setLastUpdateClientInQueue = async ({
  webSocketClient,
  sessionId,
  queueId,
}) => {
  api
    .post(
      `${
        webSocketClient.urlWebSocketClient || localStorage.getItem("URL_WS")
      }/queues/update/${sessionId}`,
      {
        sessionId,
        type: "lastUpdateClient",
        queueId,
      }
    )
    .then((response) => {})
    .catch((error) => {
      console.error("setLastUpdateClient error: ", { error });
    });
};

export const pullCustomerFromQueue = async ({
  webSocketClient,
  userId,
  roomId,
  companyId,
  sessionId,
  socketId,
  attendantName,
}) => {
  try {
    return await api.post(
      `${
        webSocketClient.urlWebSocketClient || localStorage.getItem("URL_WS")
      }/queues/${sessionId}`,
      {
        room_code: roomId,
        companyId,
        company_code: companyId,
        attendant_code: userId,
        attendantName,
      },
      {
        headers: {
          sessionId,
          customerSocketClientId: socketId,
        },
      }
    );
  } catch (error) {
    console.error("error in pull customer from queue: ", { error });
    return {
      hasError: true,
      error,
    };
  }
};
