import React, { useEffect, useState } from "react";

import Grid from "@material-ui/core/Grid";
import Loading from "../../components/basics/Loading";

import { getLogoBySubdomain } from "../../repositories/panel/v1/EntityRepository";

const Logo = () => {
  const [loading, seLoading] = useState(false);
  const [logo, setLogo] = useState("");
  const [allowedHosts] = useState([
    "portal",
    "portal1",
    "portal2",
    "portal3",
    "localhost:3000",
  ]);

  const [subdomain] = useState(
    localStorage.getItem("subdomain") ||
      window.location.host.substring(
        0,
        window.location.host.indexOf(".qyon.")
      ) ||
      window.location.host
  );

  useEffect(() => {
    (async () => {
      if (sessionStorage.getItem("logoLogin")) {
        setLogo(sessionStorage.getItem("logoLogin"));
      } else {
        try {
          seLoading(true);
          if (allowedHosts.includes(subdomain)) {
            return;
          }

          const logoCallback = await getLogoBySubdomain(subdomain);
          setLogo(logoCallback?.data?.logo_url || "");

          sessionStorage.setItem(
            "logoLogin",
            logoCallback?.data?.logo_url || ""
          );
        } catch (e) {
          console.error(e);
        } finally {
          seLoading(false);
        }
      }
    })();
  }, [allowedHosts, subdomain]);

  return (
    <>
      {loading ? (
        <Loading open={loading} />
      ) : (
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          style={{ height: "100vh" }}
        >
          <img src={logo} alt="Logotipo da empresa" />
        </Grid>
      )}
    </>
  );
};

export default Logo;
