import { search } from "../../BaseRepositoryComponents";

const path = `${process.env.REACT_APP_BANK_API}`;
const myHeaders = new Headers({
  "Content-Type": "application/json",
  "Content-Language": "pt-BR",
});

export async function getAccountBank({ document }) {
  return search({
    path: `${path}/v2/public/bank/accounts/verify?account_govid=${document}`,
    method: "GET",
    myHeaders,
  });
}

export async function getUserCongnito({ email }) {
  return search({
    path: `${path}/v1/public/bank/accounts/verify?email=${email}`,
    method: "GET",
    myHeaders,
  });
}
