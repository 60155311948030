import { Button, Chip, Modal } from "@material-ui/core";
import {
  CloseIconStyle,
  DialogHeader,
  DialogStyle,
} from "../../components/modal/Modal";
import {
  CircularProgressStyled,
  DivNew,
} from "../../components/xmlModal/styles/XmlModalCss";
import { useEffect, useState } from "react";
import { getProcurationPermission } from "../../repositories/panel/v1/ProcurationRepository";
import moment from "moment";
import { formatCNPJorCPF } from "./util/Formatter";
import { DateColumn, DateColumnExpired } from "./styles/Certificate";

export default function ProcurationPermissionModal({
  openModal,
  closeModal,
  selectedProcuration,
}) {
  const [loading, setLoading] = useState(true);
  const [procurationPermissions, setProcurationPermissions] = useState([]);

  useEffect(() => {
    if (!selectedProcuration) {
      return;
    }
    setLoading(true);
    getProcurationPermission({
      grantorDocument: selectedProcuration.grantor_document,
      grantedDocument: selectedProcuration.granted_document,
    }).then((procurations) => {
      setProcurationPermissions(procurations);
      setLoading(false);
    });
  }, [selectedProcuration]);

  return (
    <Modal
      open={openModal}
      onClose={() => {
        closeModal();
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <DialogStyle>
        <DialogHeader>
          <div className="row">
            <div style={{ display: "flex", flexDirection: "column" }}>
              Permissões da procuração
              {selectedProcuration && selectedProcuration.grantor_name && (
                <div style={{ fontSize: 14, lineHeight: "normal" }}>
                  Permissões que {selectedProcuration.grantor_name} -{" "}
                  {formatCNPJorCPF(selectedProcuration.grantor_document)}{" "}
                  forneceu para a empresa de CPF/CNPJ{" "}
                  {formatCNPJorCPF(selectedProcuration.granted_document)}.
                </div>
              )}
              {selectedProcuration && !selectedProcuration.grantor_name && (
                <div style={{ fontSize: 14, lineHeight: "normal" }}>
                  Permissões que a empresa CADASTRADA MANUALMENTE -{" "}
                  {formatCNPJorCPF(selectedProcuration.grantor_document)}{" "}
                  forneceu para a empresa de CPF/CNPJ{" "}
                  {formatCNPJorCPF(selectedProcuration.granted_document)}.
                </div>
              )}
            </div>

            <CloseIconStyle
              onClick={() => {
                closeModal();
              }}
            />
          </div>
        </DialogHeader>

        {loading && <CircularProgressStyled />}
        {!loading && (
          <div
            style={{
              maxHeight: "450px",
              overflowY: "auto",
              marginBottom: 50,
            }}
          >
            <div style={{ paddingLeft: 20, paddingRight: 20 }}>
              {selectedProcuration?.start_date && (
                <span style={{ display: "flex" }}>
                  <span style={{ marginRight: 5 }}>
                    Note que as permissões são válidas de
                  </span>
                  {selectedProcuration.status === "EXPIRED" ? (
                    <div style={{ display: "flex" }}>
                      <DateColumnExpired>
                        {moment(selectedProcuration.start_date).format(
                          "DD/MM/YYYY"
                        )}
                      </DateColumnExpired>
                      <span style={{ marginLeft: 5, marginRight: 5 }}>até</span>
                      <DateColumnExpired>
                        {moment(selectedProcuration.end_date).format(
                          "DD/MM/YYYY"
                        )}
                      </DateColumnExpired>
                    </div>
                  ) : (
                    <div style={{ display: "flex" }}>
                      <DateColumn>
                        {moment(selectedProcuration.start_date).format(
                          "DD/MM/YYYY"
                        )}
                      </DateColumn>
                      <span style={{ marginLeft: 5, marginRight: 5 }}>até</span>
                      <DateColumn>
                        {moment(selectedProcuration.end_date).format(
                          "DD/MM/YYYY"
                        )}
                      </DateColumn>
                    </div>
                  )}
                </span>
              )}
            </div>
            <ul>
              {procurationPermissions.map((row, index) => (
                <li>
                  {row.permission_text}{" "}
                  <Chip label={row.permission_name} variant="outlined" />
                </li>
              ))}

              {procurationPermissions.length === 0 && (
                <li>Nenhuma permissão foi encontrada para essa procuração</li>
              )}
            </ul>
          </div>
        )}

        {!loading && (
          <DivNew>
            <Button
              className="Button"
              onClick={() => {
                closeModal();
              }}
            >
              Cancelar
            </Button>
          </DivNew>
        )}
      </DialogStyle>
    </Modal>
  );
}
