import { useContext, useEffect, useState } from "react";

/**
 * Icons
 */
import { ExpandMore } from "@material-ui/icons";

/**
 * Components
 */
import { AccordionSummary, AccordionDetails } from "@material-ui/core";

import { TableAccountantCndSettings } from "./TableAccountantCndSettings";

/**
 * Styled Components
 */
import {
  AccordionStyled,
  Title,
  GridStyled,
} from "../../../styles/GlobalStyle";
import { ContainerTab } from "../styles/CndAccountant";
import TabCustom from "../../../components/tabs/TabCustom";

/**
 * Context
 */
import { CndAccountantContext } from "../contexts/CndAccountantContext";

/**
 * Controller
 */
import { useCndAccountantController } from "../controllers/CndAccountantController";

export const AccordionAccountantCndSettings = () => {
  const { getEntityAgendasSettings, getCndTypes } =
    useCndAccountantController();
  const {
    agendas,
    cndTypes,

    setCndsFederal,

    setCndsMunicipal,

    setCndsEstadual,

    setCndsOthers,
  } = useContext(CndAccountantContext);

  const [tabIndex, setTabIndex] = useState(0);

  useEffect(() => {
    getEntityAgendasSettings();
    getCndTypes();
  }, [getEntityAgendasSettings, getCndTypes]);

  useEffect(() => {
    if (!cndTypes || !cndTypes.length) return;
    if (!agendas || !agendas.length) return;

    const copy = cndTypes.map((type) => {
      let agendaRelated = agendas.find((agenda) => agenda.type_id === type.id);
      const body = {
        id: type.id,
        agendaId: agendaRelated?.id || null,
        auto: agendaRelated?.auto || false,
        antecedenceDays: agendaRelated?.antecedence_days || "",
        requestOwnSchedule: agendaRelated?.request_own_schedule || false,
        hasAuto: type.type_rpa_providers_count > 0,
        scope: type.configs.scope,
        state: type.configs.state || "",
        description: type.description,
      };

      return body;
    });

    const cndsPerCategory = {
      Federal: [],
      Estadual: [],
      Municipal: [],
      Favoritos: [],
    };

    const others = [];

    copy.forEach((cnd) => {
      if (!cnd.scope || !cndsPerCategory[cnd.scope]) {
        others.push(cnd);
        return;
      }

      cndsPerCategory[cnd.scope].push(cnd);
    });

    setCndsEstadual(cndsPerCategory.Estadual);
    setCndsFederal(cndsPerCategory.Federal);
    setCndsMunicipal(cndsPerCategory.Municipal);

    setCndsOthers(others);
  }, [
    cndTypes,
    agendas,
    setCndsFederal,
    setCndsMunicipal,
    setCndsOthers,
    setCndsEstadual,
  ]);

  const headers = [
    {
      label: "Federal",
    },
    {
      label: "Estadual",
    },
    {
      label: "Municipal",
    },
    {
      label: "Outros",
    },
  ];

  const children = [
    <TableAccountantCndSettings tab="federal" />,
    <TableAccountantCndSettings tab="state" />,
    <TableAccountantCndSettings tab="municipal" />,
    <TableAccountantCndSettings tab="others" />,
  ];

  return (
    <AccordionStyled defaultExpanded={true}>
      <AccordionSummary expandIcon={<ExpandMore />}>
        <Title subtitle cursor="pointer">
          CNDs
        </Title>
      </AccordionSummary>
      <AccordionDetails>
        <GridStyled container>
          <ContainerTab item>
            <TabCustom
              children={children}
              headers={headers}
              tabIndex={tabIndex}
              onChange={(event, value) => {
                if (Number.isInteger(value)) {
                  setTabIndex(value);
                }
              }}
            />
          </ContainerTab>
        </GridStyled>
      </AccordionDetails>
    </AccordionStyled>
  );
};
