import { search } from "../../BaseRepositoryComponents";
import axios from "axios";

const path = `${process.env.REACT_APP_PAINEL_API_URL}/api/v1/user`;
const newPath = `${process.env.REACT_APP_NEW_PAINEL_API_URL}`;

const API_PANEL_URL = process.env.REACT_APP_NEW_PAINEL_API_URL;

export async function auth({ email, password }) {
  return search({
    path: `${path}/login`,
    method: "POST",
    myBody: { email, password },
  });
}

export async function userLogin({ id }) {
  return search({
    path: `${API_PANEL_URL}/v1/entity_user/${id}`,
    method: "GET",
  });
}

export async function getEntitiesUser({ id }) {
  return search({ path: `${path}/${id}/entities`, method: "GET" });
}

export async function getPanelsUser({ id, ownerentity }) {
  return search({
    path: `${path}/${id}/panel${
      ownerentity ? `?ownerentity=${ownerentity}` : ""
    }`,
    method: "GET",
  });
}

export async function putUser({ body, id }) {
  return search({
    path: `${path}/${id}`,
    method: "PUT",
    myBody: body,
  });
}

export async function postUser({ body }) {
  return search({
    path: `${path}`,
    method: "POST",
    myBody: body,
  });
}

export async function deleteUser({ id }) {
  return search({
    path: `${path}/${id}`,
    method: "DELETE",
  });
}

export async function verifyEmailUser({ email }) {
  return search({
    path: `${path}/verify/email?email=${email}`,
    method: "GET",
  });
}

export async function changePasswordAccessFirst({ body }) {
  return search({
    path: `${path}/change-password-access-first`,
    method: "POST",
    myBody: body,
  });
}

export async function changePasswordFirstTime({ body }) {
  try {
    const response = await axios.post(
      `${newPath}/v1/auth/change-password-first-time`,
      body
    );
    return response?.data || null;
  } catch (error) {
    console.log("ERROR => postCodeRecover", error.response);
    return error?.response?.data || null;
  }
}
