import React, { useState } from "react";

import { Box } from "@material-ui/core";

import { notify } from "../../../utils/notify";
import Btn from "../../buttons/Btn";
import { RatingRow } from "../components/RatingRow";
import { useChatContext } from "../contexts/ChatContext";
import ChatController from "../controllers/ChatController";
import ConversationController from "../controllers/ConversationController";

import {
  BoxStyled,
  DividerStyled,
  TextareaAutosizeStyled,
} from "../styles/GeneralStyle";

export default function Avaliation() {
  const { rateQuestions, chat, SCREEN_ENUM, setChat } = useChatContext();
  const { handleSendRate } = ConversationController();
  const { changeOpen } = ChatController();

  const arrayValues =
    rateQuestions?.map((item) => {
      return {
        id: item.id,
        title: item.question,
        value: 0,
        setValue: (newValue) => (item.value = newValue),
      };
    }) || [];

  const [values, setValues] = useState(arrayValues || []);
  const [message, setMessage] = useState("");

  async function handleSendAvaliation() {
    const isNullValue = values.indexOf((item) => item.value === 0);

    if (!isNullValue) return notify("Preencha as notas", true, "warning");

    const questions = values.map((item) => {
      return { id: item.id, rate: item.value };
    });

    setChat({
      currentScreen: SCREEN_ENUM.loading,
      isLoading: true,
      loadingMessage: "Enviando avaliação",
    });

    const response = await handleSendRate({
      questions,
      observation: message,
    });

    if (response.success) {
      setTimeout(() => {
        setChat({
          isLoading: false,
          loadingMessage: response.message,
          currentConversation: "",
          currentTicketId: "",
          conversations: [],
          queue: [],
        });

        changeOpen({ isChatOpen: false });
      }, 1500);
    } else {
      notify(response.message, true, "warning");
      setChat({ currentScreen: SCREEN_ENUM.avaliation });
    }
  }

  function handleSetValue(value, id) {
    const newValues = values.map((item) => {
      if (item.id === id) {
        item.value = value;
      }

      return item;
    });

    setValues(newValues);
  }

  return (
    <BoxStyled
      display="flex"
      flexDirection="column"
      alignItems="center"
      padding="20px"
      paddingTop="20px"
      textAlign="center"
    >
      <Box>
        <Box textAlign="center" marginBottom={4} color="#006FDE">
          Olá! Avalie o atendimento recebido no ticket
          <strong>
            {" "}
            {chat.conversations &&
              (chat.conversations[0].ticketId || chat.currentTicketId)}
          </strong>
        </Box>

        {values.map(({ id, title, value, setValue }, index) => {
          return (
            <>
              <RatingRow
                key={id}
                value={value}
                setValue={(newValue) => handleSetValue(newValue, id)}
                title={title}
              />

              {index + 1 !== values.length && (
                <DividerStyled
                  component="hr"
                  marginTop="16px"
                  marginBottom="24px"
                />
              )}
            </>
          );
        })}

        <DividerStyled component="hr" marginTop="16px" marginBottom="24px" />

        <Box textAlign="start">Conte-nos como podemos melhorar!</Box>

        <TextareaAutosizeStyled
          minRows={3}
          width="100%"
          marginTop="4px"
          borderRadius="5px"
          padding="8px"
          borderColor="#999"
          resize="vertical"
          outline="none"
          placeholder="Digite aqui"
          value={message}
          onChange={(event) => setMessage(event.target.value)}
        />

        <Btn
          label="Enviar"
          style={{ width: "100%" }}
          onClick={handleSendAvaliation}
        />
      </Box>
    </BoxStyled>
  );
}
