export const UFOptions = [
  {
    id: 12,
    name: "Acre",
    country_id: 1058,
    created_at: "2021-10-20T15:21:30.661879Z",
    updated_at: "2021-10-20T15:21:30.661879Z",
    deleted_at: null,
    uf: "AC",
  },
  {
    id: 27,
    name: "Alagoas",
    country_id: 1058,
    created_at: "2021-10-20T15:21:30.661879Z",
    updated_at: "2021-10-20T15:21:30.661879Z",
    deleted_at: null,
    uf: "AL",
  },
  {
    id: 16,
    name: "Amap\u00e1",
    country_id: 1058,
    created_at: "2021-10-20T15:21:30.661879Z",
    updated_at: "2021-10-20T15:21:30.661879Z",
    deleted_at: null,
    uf: "AP",
  },
  {
    id: 13,
    name: "Amazonas",
    country_id: 1058,
    created_at: "2021-10-20T15:21:30.661879Z",
    updated_at: "2021-10-20T15:21:30.661879Z",
    deleted_at: null,
    uf: "AM",
  },
  {
    id: 29,
    name: "Bahia",
    country_id: 1058,
    created_at: "2021-10-20T15:21:30.661879Z",
    updated_at: "2021-10-20T15:21:30.661879Z",
    deleted_at: null,
    uf: "BA",
  },
  {
    id: 23,
    name: "Cear\u00e1",
    country_id: 1058,
    created_at: "2021-10-20T15:21:30.661879Z",
    updated_at: "2021-10-20T15:21:30.661879Z",
    deleted_at: null,
    uf: "CE",
  },
  {
    id: 53,
    name: "Distrito Federal",
    country_id: 1058,
    created_at: "2021-10-20T15:21:30.661879Z",
    updated_at: "2021-10-20T15:21:30.661879Z",
    deleted_at: null,
    uf: "DF",
  },
  {
    id: 32,
    name: "Esp\u00edrito Santo",
    country_id: 1058,
    created_at: "2021-10-20T15:21:30.661879Z",
    updated_at: "2021-10-20T15:21:30.661879Z",
    deleted_at: null,
    uf: "ES",
  },
  {
    id: 52,
    name: "Goi\u00e1s",
    country_id: 1058,
    created_at: "2021-10-20T15:21:30.661879Z",
    updated_at: "2021-10-20T15:21:30.661879Z",
    deleted_at: null,
    uf: "GO",
  },
  {
    id: 21,
    name: "Maranh\u00e3o",
    country_id: 1058,
    created_at: "2021-10-20T15:21:30.661879Z",
    updated_at: "2021-10-20T15:21:30.661879Z",
    deleted_at: null,
    uf: "MA",
  },
  {
    id: 51,
    name: "Mato Grosso",
    country_id: 1058,
    created_at: "2021-10-20T15:21:30.661879Z",
    updated_at: "2021-10-20T15:21:30.661879Z",
    deleted_at: null,
    uf: "MT",
  },
  {
    id: 50,
    name: "Mato Grosso do Sul",
    country_id: 1058,
    created_at: "2021-10-20T15:21:30.661879Z",
    updated_at: "2021-10-20T15:21:30.661879Z",
    deleted_at: null,
    uf: "MS",
  },
  {
    id: 31,
    name: "Minas Gerais",
    country_id: 1058,
    created_at: "2021-10-20T15:21:30.661879Z",
    updated_at: "2021-10-20T15:21:30.661879Z",
    deleted_at: null,
    uf: "MG",
  },
  {
    id: 15,
    name: "Par\u00e1",
    country_id: 1058,
    created_at: "2021-10-20T15:21:30.661879Z",
    updated_at: "2021-10-20T15:21:30.661879Z",
    deleted_at: null,
    uf: "PA",
  },
  {
    id: 25,
    name: "Para\u00edba",
    country_id: 1058,
    created_at: "2021-10-20T15:21:30.661879Z",
    updated_at: "2021-10-20T15:21:30.661879Z",
    deleted_at: null,
    uf: "PB",
  },
  {
    id: 41,
    name: "Paran\u00e1",
    country_id: 1058,
    created_at: "2021-10-20T15:21:30.661879Z",
    updated_at: "2021-10-20T15:21:30.661879Z",
    deleted_at: null,
    uf: "PR",
  },
  {
    id: 26,
    name: "Pernambuco",
    country_id: 1058,
    created_at: "2021-10-20T15:21:30.661879Z",
    updated_at: "2021-10-20T15:21:30.661879Z",
    deleted_at: null,
    uf: "PE",
  },
  {
    id: 22,
    name: "Piau\u00ed",
    country_id: 1058,
    created_at: "2021-10-20T15:21:30.661879Z",
    updated_at: "2021-10-20T15:21:30.661879Z",
    deleted_at: null,
    uf: "PI",
  },
  {
    id: 33,
    name: "Rio de Janeiro",
    country_id: 1058,
    created_at: "2021-10-20T15:21:30.661879Z",
    updated_at: "2021-10-20T15:21:30.661879Z",
    deleted_at: null,
    uf: "RJ",
  },
  {
    id: 24,
    name: "Rio Grande do Norte",
    country_id: 1058,
    created_at: "2021-10-20T15:21:30.661879Z",
    updated_at: "2021-10-20T15:21:30.661879Z",
    deleted_at: null,
    uf: "RN",
  },
  {
    id: 43,
    name: "Rio Grande do Sul",
    country_id: 1058,
    created_at: "2021-10-20T15:21:30.661879Z",
    updated_at: "2021-10-20T15:21:30.661879Z",
    deleted_at: null,
    uf: "RS",
  },
  {
    id: 11,
    name: "Rond\u00f4nia",
    country_id: 1058,
    created_at: "2021-10-20T15:21:30.661879Z",
    updated_at: "2021-10-20T15:21:30.661879Z",
    deleted_at: null,
    uf: "RO",
  },
  {
    id: 14,
    name: "Roraima",
    country_id: 1058,
    created_at: "2021-10-20T15:21:30.661879Z",
    updated_at: "2021-10-20T15:21:30.661879Z",
    deleted_at: null,
    uf: "RR",
  },
  {
    id: 42,
    name: "Santa Catarina",
    country_id: 1058,
    created_at: "2021-10-20T15:21:30.661879Z",
    updated_at: "2021-10-20T15:21:30.661879Z",
    deleted_at: null,
    uf: "SC",
  },
  {
    id: 35,
    name: "S\u00e3o Paulo",
    country_id: 1058,
    created_at: "2021-10-20T15:21:30.661879Z",
    updated_at: "2021-10-20T15:21:30.661879Z",
    deleted_at: null,
    uf: "SP",
  },
  {
    id: 28,
    name: "Sergipe",
    country_id: 1058,
    created_at: "2021-10-20T15:21:30.661879Z",
    updated_at: "2021-10-20T15:21:30.661879Z",
    deleted_at: null,
    uf: "SE",
  },
  {
    id: 17,
    name: "Tocantins",
    country_id: 1058,
    created_at: "2021-10-20T15:21:30.661879Z",
    updated_at: "2021-10-20T15:21:30.661879Z",
    deleted_at: null,
    uf: "TO",
  },
];
