import { useCallback } from "react";
import Swal from "sweetalert2";
const useAlerts = () => {
  const dialogConfirmation = useCallback(
    async ({ message, onYesCallback, onNoCallback }) => {
      return Swal.fire({
        icon: "question",
        text: message,
        cancelButtonText: "Cancelar",
        confirmButtonText: "Sim",
        showConfirmButton: true,
        showCancelButton: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          if (onYesCallback) {
            onYesCallback();
          }
        } else if (onNoCallback) {
          onNoCallback();
        }
      });
    },
    []
  );

  const dialogMessage = useCallback(async ({ message, status }) => {
    return Swal.fire({
      icon: status,
      html: `<div>${message}</div>`,
      confirmButtonText: "Ok",
      showConfirmButton: true,
      timer: 10000,
      timerProgressBar: true,
    });
  }, []);

  const toastMessage = useCallback(async ({ message, status }) => {
    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      timer: 10000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });

    return Toast.fire({
      icon: status,
      title: message,
    });
  }, []);

  return { dialogConfirmation, toastMessage, dialogMessage };
};

export default useAlerts;
