import React, { createContext, useState } from "react";

const LoginContext = createContext();

const LoginContextProvider = ({ children }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [sessionCognito, setSessionCognito] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [verificationCode, setVerificationCode] = useState("");
  const [companyChecked, setCompanyChecked] = useState("");
  const [sendCodeVerif, setSendCodeVerif] = useState(false);
  const [codeRecover, setCodeRecover] = useState("");

  const [listCompanies, setListCompanies] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});

  return (
    <LoginContext.Provider
      value={{
        email,
        setEmail,
        password,
        setPassword,
        sessionCognito,
        setSessionCognito,
        newPassword,
        setNewPassword,
        confirmNewPassword,
        setConfirmNewPassword,
        verificationCode,
        setVerificationCode,
        sendCodeVerif,
        setSendCodeVerif,
        codeRecover,
        setCodeRecover,

        errors,
        setErrors,
        loading,
        setLoading,
        companyChecked,
        setCompanyChecked,
        listCompanies,
        setListCompanies,

        loading,
        setLoading,
        errors,
        setErrors,
      }}
    >
      {children}
    </LoginContext.Provider>
  );
};

export { LoginContextProvider, LoginContext };
