import BaseMask from "./_base.mask";

const CEP_MASK = "99.999-999";

const validateCEP = cep => {
  if (cep === "") {
    return true;
  }

  const rawCep = cep.replace(/\./gi, "").replace(/-/gi, "");

  if (
    rawCep.length !== 8 ||
    rawCep === "00000000" ||
    rawCep === "11111111" ||
    rawCep === "22222222" ||
    rawCep === "33333333" ||
    rawCep === "44444444" ||
    rawCep === "55555555" ||
    rawCep === "66666666" ||
    rawCep === "77777777" ||
    rawCep === "88888888" ||
    rawCep === "99999999"
  ) {
    return false;
  }

  const regEx = /^[0-9]{2}.[0-9]{3}-[0-9]{3}$/;
  return regEx.test(cep);
};

export class CepMask extends BaseMask {
  static getType() {
    return "cep";
  }

  getValue(value, settings) {
    return this.getVMasker().toPattern(value, CEP_MASK);
  }

  getRawValue(maskedValue, settings) {
    return super.removeNotNumbers(maskedValue);
  }

  validate(value, settings) {
    return validateCEP(value);
  }
}
