import React from "react";
import { ListItem } from "@material-ui/core";
import { TypographyStyled, BoxStyled } from "../styles/GeneralStyle";
import Group from "./Group";
import GroupItem from "./GroupItem";

import { useTheme } from "@material-ui/core/styles";

export default function RenderCustomersList({
  listCustomers,
  setCallCustomer,
  fieldValue,
  setFieldValue,
  listUpdateCustomers,
  setFlag = () => {},
}) {
  const theme = useTheme();
  return (
    <Group
      fieldValue={fieldValue}
      setFieldValue={setFieldValue}
      setFlag={setFlag}
      flag={"clientes"}
    >
      {listUpdateCustomers &&
        listUpdateCustomers.map(
          (i, index) =>
            i.name && (
              <GroupItem
                key={index}
                title={i.name}
                sessionId={i.id}
                setCallCustomer={setCallCustomer}
                flag={"clientes"}
              />
            )
        )}
      {listCustomers && listCustomers?.length === 0 && (
        <ListItem alignItems="flex-start">
          <BoxStyled
            width="100%"
            textAlign="center"
            paddingTop={theme.spacing(2)}
            paddingBottom={theme.spacing(2)}
          >
            <TypographyStyled
              display="inline"
              component="span"
              variant="body2"
              color="gray"
            >
              Você não possui nenhum cliente!
            </TypographyStyled>
          </BoxStyled>
        </ListItem>
      )}
    </Group>
  );
}
