import { useContext } from "react";
import { useAuth } from "../../../contexts/crm/Auth";
import { GlobalContext } from "../../../contexts/GlobalContext";
import { postUpdateDepartment } from "../../../repositories/integrations/crm/ChatRepository";
import { useChatContext } from "../contexts/ChatContext";
import useWebSocketClient from "./WebSocketClient";

export default function SettingsController() {
  const webSocketClient = useWebSocketClient();
  const { settings, chat } = useChatContext();
  const { user, company } = useAuth();
  const { userLogged } = useContext(GlobalContext);

  const ENUMSaves = ({ state, action }) => {
    return {
      roomState: {
        url: `/roomStatus?company_id=${company.id}`,
        jsonBody: {
          room_id: chat.roomId,
          status: state === "0" ? 1 : 0,
          attendant_code: user.id,
        },
        method: "PUT",
      },
      myState: {
        url: `/attendant/status/${user.id}`,
        jsonBody: {
          room_id: chat.roomId,
          attendant_status: state === "0" ? 1 : 0,
          reason: parseInt(state),
          company_code: company.id,
        },
        method: "POST",
      },
      attendanceAmount: {
        url: process.env.REACT_APP_CRM_API_URL + `/v1/user/attendanceAmount`,
        params: `?userId=${user.id}&attendanceAmount=${state}`,
        jsonHeaders: {
          Authorization: localStorage.getItem("tokenCognitoPanel"),
          refreshToken: userLogged.cognito.RefreshToken,
          "qyon-customer-id": company.id,
          "Content-Type": "application/json",
        },
        method: "put",
      },
      department: {
        url: process.env.REACT_APP_CRM_API_URL + `/v1/product/chat`,
        params: `?userId=${user.id}&products=${JSON.stringify(
          state
        )}&action=${action}`,
        jsonHeaders: {
          Authorization: localStorage.getItem("tokenCognitoPanel"),
          refreshToken: userLogged.cognito.RefreshToken,
          "qyon-customer-id": company.id,
          "Content-Type": "application/json",
        },
        method: "post",
      },
    };
  };

  const subsribeOnAttendantStatus = async (callback) => {
    webSocketClient.subscribe(
      webSocketClient.TOPICS.subscriptions.att(chat.roomId, (data) => {
        if (callback) callback(data);
      })
    );
  };

  const saveStatus = async ({ state, prop, reason }) => {
    if (settings?.[prop]) {
      const saveFunction = ENUMSaves({ state })?.[prop];
      const saveStatus = await fetch(company.urlSocket + saveFunction.url, {
        method: saveFunction.method,
        body: JSON.stringify(saveFunction.jsonBody),
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("tokenCognitoPanel"),
          refreshToken: localStorage.getItem("tokenCognitoPanel"),
          "qyon-customer-cnpj":
            localStorage.getItem("isAccountantPanel") === "true"
              ? localStorage.getItem("documentPanel")
              : localStorage.getItem("accountantCnpjPanel"),
          "customer-cnpj": localStorage.getItem("documentPanel") || "",
        },
      });
      return saveStatus;
    }
  };

  const saveAttendanceParameters = async ({ state, prop, action }) => {
    try {
      const saveFunction = ENUMSaves({ state, action })?.[prop];
      const saveStatus = await fetch(saveFunction.url + saveFunction.params, {
        method: saveFunction.method,
        body: JSON.stringify(saveFunction.jsonBody),
        headers: saveFunction.jsonHeaders,
      });

      if (prop === "attendanceAmount" && settings.myState === "0") {
        webSocketClient.onConnect(() => {
          webSocketClient.send({
            topic: webSocketClient.TOPICS.emit.onOffAtendants,
            message: {
              company_code: company.id,
              company_alias: company.alias,
              attendantCode: user.id,
              attendantStatus: 1,
              room_id: chat.roomId,
            },
          });
        });
      }
      if (prop === "department") {
        const filtersDepartments = state.filter(
          (departments) => departments.selected === true
        );

        await postUpdateDepartment({
          body: { departamentsSelected: filtersDepartments },
          companyId: company.id,
          roomId: chat.roomId,
          userId: user.id,
          path: company.urlSocket,
        });
      }

      return saveStatus;
    } catch (error) {
      console.error(error);
    }
  };

  const saveNotificationsParameters = async ({ state, intensity }) => {
    const notificationState = state;
    const notificationIntensity = intensity;
    sessionStorage.setItem("AllowNotifyChat", notificationState == "0" ? 1 : 0);
    sessionStorage.setItem("IntensityNotifyChat", notificationIntensity);
  };

  return {
    subsribeOnAttendantStatus,
    saveStatus,
    saveAttendanceParameters,
    saveNotificationsParameters,
  };
}
