import React, { createContext, useState } from "react";

const DashboardViewContext = createContext();

const DashboardViewContextProvider = ({ children }) => {
  const [uploadedLogo, setUploadedLogo] = useState([]);
  const [panelSelected, setPanelSelected] = useState(null);

  const [panelsList, setPanelsList] = useState([]);
  const [listVersionsWidget, setListVersionsWidget] = useState([]);
  const [loading, setLoading] = useState({
    panelsList: false,
    widgets: false,
    savePosition: false,
  });
  const [loadingInputs, setLoadingInputs] = useState({});
  const [errors, setErrors] = useState({});

  const [layoutDashboard, setLayoutDashboard] = useState([]);

  return (
    <DashboardViewContext.Provider
      value={{
        uploadedLogo,
        setUploadedLogo,
        panelSelected,
        setPanelSelected,

        layoutDashboard,
        setLayoutDashboard,

        panelsList,
        setPanelsList,
        listVersionsWidget,
        setListVersionsWidget,
        loading,
        setLoading,
        loadingInputs,
        setLoadingInputs,
        errors,
        setErrors,
      }}
    >
      {children}
    </DashboardViewContext.Provider>
  );
};

export { DashboardViewContextProvider, DashboardViewContext };
