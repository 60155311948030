import { Grid, Radio } from "@material-ui/core";
import React from "react";

export const ConfigureERPRadioButton = ({
  selected,
  name,
  title,
  subtitle,
  icon,
  selectValue,
  disabled,
  xs = 4,
  ...props
}) => {
  return (
    <Grid
      style={{
        paddingLeft: 8,
        paddingRight: 8,
        paddingTop: 16,
        paddingBottom: 16,
        borderRadius: 10,
        border: "1px solid #CECFD0",
        background: "#FBFBFD",
        cursor: disabled ? "not-allowed" : "pointer",
      }}
      xs={xs}
      item
      container
      spacing={3}
      onClick={(e) => {
        selectValue(name);
      }}
      {...props}
    >
      <Grid container direction="row" alignItems="center">
        <Grid item xs={3} container justifyContent="center">
          <Radio
            color="primary"
            onClick={(e) => {
              selectValue(name);
            }}
            value={name}
            checked={selected === name}
            disabled={disabled ?? false}
          ></Radio>
        </Grid>

        <Grid item xs={6} container>
          <b>{title}</b>
          <div>{subtitle}</div>
        </Grid>

        <Grid item xs={3} container justifyContent="center">
          <div
            style={{
              borderRadius: "50%",
              background: "#E7EEFE",
              padding: 10,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {icon}
          </div>
        </Grid>
      </Grid>
    </Grid>
  );
};
