import PropTypes from "prop-types";
import React, { useEffect } from "react";

import { Box } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import { makeStyles, styled } from "@material-ui/core/styles";
import { TabsNew, TabsStyled } from "../../pages/GuideReports/styles/GuideReport";

const useStyles = makeStyles((theme) => {
  return {
    root: {
      flexGrow: 1,
      width: "100%",
    },
    selected: {
      color: theme.palette.primary.main,
      fontWeight: "bold",
      opacity: "1",
    },
    enable: {
      opacity: "0.8",
    },
    disabled: {
      opacity: "0.5",
    },
  };
});

export default function TabCustom({
  headers,
  children,
  position = "static",
  backgroundColor = "inherit",
  changeTab,
  tabIndex,
  setTabIndex,
  paddingInside = 3,
  classNameRoot,
  classNameTabEnable,
  classNameTabDisabled,
  value,
  ...props
}) {
  const classes = useStyles();

  useEffect(() => {
    if(value === 2){
      setTabIndex(0);
    }   
  }, [value]);

  return (
    <div className={classNameRoot ? classNameRoot : classes.root}>
      {headers && (
        <AppBar position={position} color={backgroundColor}>
          <TabsNew
            style={{backgroundColor: "white"}}
            value={tabIndex}
            onChange={changeTab}
            {...props}
            scrollButtons="on"
          >
            {headers.map((headerValue, index) => {            
              return (
                <TabsStyled
                  id={`tabErp-${index}`}
                  key={`tabErp-${index}`}
                  {...headerValue}
                  aria-controls={`tabErpPanel-${index}`}
                  className={classes}
                />
              );
            })}
          </TabsNew>
          
        </AppBar>
      )}
      {children.map((child, index) => {
        return (
          <div
            key={`tabErpPanel-item-${index}`}
            id={`tabErpPanel-item-${index}`}
            role="tabpanel"
            hidden={tabIndex !== index}
            aria-labelledby={`tabErp-item-${index}`}
            style={{ borderWidth: "5px" }}
            {...props}
          >
            {tabIndex === index && (
              <Box style={headers ? { marginTop: 20 } : {}}>{child}</Box>
            )}
          </div>
        );
      })}
    </div>
  );
}

TabCustom.propTypes = {
  children: PropTypes.array.isRequired,
};
