import { v4 as uuidv4 } from "uuid";
import { api } from "../services/api";

const RefreshToken =
  JSON.parse(localStorage.getItem("cognitoPanel"))?.RefreshToken || "";

export default function EmailController() {
  const sendToHub = async ({ tokenCognito }) => {
    const bodyJson = {
      id: uuidv4(),
      version: "v1.0.0",
      from: "QYON_DASH",
      to: "QYON_DASH",
      timestamp: new Date().getTime(),
      payload: {
        cnpj: localStorage.getItem("documentPanel"),
        tokenCognito,
      },
      extraData: {
        uiMessage: `Não foi possivel encontrar nenhum e-mail configurado!`,
        sub: localStorage.getItem("subCognitoPanel"),
        cnpj: localStorage.getItem("documentPanel"),
      },
      event: "SMTP_CONFIGURATION",
      priority: "NOT_CRITICAL",
    };

    fetch("https://hub.qyon.info/v1/hub/message", {
      method: "POST",
      headers: {
        Authorization: "18309cd6-30ca-4647-b289-fb2ae3e1d2f6",
        "Content-Type": "application/json",
        refreshToken: RefreshToken,
      },
      body: JSON.stringify(bodyJson),
      redirect: "follow",
    });
  };

  const fetchEmailIsConfigured = async ({ companyId, tokenCognito }) => {
    return await api
      .get(`/v1/settings/email`, {
        headers: {
          "qyon-customer-id": companyId,
          Authorization: tokenCognito,
          refreshToken: RefreshToken,
        },
      })
      .then((response) => {
        const _return = response.data.items;
        if (
          _return?.length === 0 &&
          localStorage.getItem("sendAlertToSmtpConfiguration").trim() !==
            "envoy"
        ) {
          sendToHub({ tokenCognito });
          localStorage.setItem("sendAlertToSmtpConfiguration", "envoy");
        }
        return _return;
      })
      .catch((error) => {
        console.error(error);
        return null;
      });
  };

  return {
    fetchEmailIsConfigured,
  };
}
