import {
  Grid,
  LinearProgress,
  TextField,
  Tooltip,
  FormControl,
  InputLabel,
  FilledInput,
  InputAdornment,
} from "@material-ui/core";
import PlayForWorkOutlinedIcon from "@material-ui/icons/PlayForWorkOutlined";
import RemoveRedEyeOutlinedIcon from "@material-ui/icons/RemoveRedEyeOutlined";
import EmailOutlinedIcon from "@material-ui/icons/EmailOutlined";
import moment from "moment";

import {
  ContainerIcon,
  GridStyled,
  LightTooltip,
} from "../../../styles/GlobalStyle";
import useGuideReportController from "../controllers/GuideReportController";
import { DataGridGuideReport } from "../styles/GuideReport";
import { GridOverlay } from "@material-ui/data-grid";
import { useEffect, useState } from "react";
import { StarBorder, StarRate } from "@material-ui/icons";

export default function TableTabGuideReport({
  typeFile,
  favorite = false,
  rows,
  pageSize,
  tab,
  messageNoRows,
  loadingTabs,
  setUrlFileState,
  setOpenModalFile,
  refresh,
}) {
  const {
    // handleShowFile,
    handleDownloadFile,
    handleFavorite,
    handleOpenSend,
    handleChangeExpanded,
  } = useGuideReportController();

  const [filteredRows, setFilteredRows] = useState([]);

  useEffect(() => {
    setFilteredRows(rows);
  }, [rows]);

  const handleShowFile = (file, contentType, name) => {
    if (!file || typeof file !== "string" || !contentType) {
      return;
    }

    const byteCharacters = atob(file);

    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += 1024) {
      const slice = byteCharacters.slice(offset, offset + 1024);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }
    const blob = new Blob(byteArrays, { type: contentType });
    const blobUrl = URL.createObjectURL(blob);

    setUrlFileState({
      file: {
        type: contentType,
      },
      name: name,
      elementBase64: file,
      preview: blobUrl,
    });
  };

  let isGuide = typeFile === "GUIDE";

  const isInClientView = sessionStorage.getItem("isInClientView");

  const columns = [
    {
      field: "name",
      headerName: "Nome",
      flex: 1,
      minWidth: 330,
      renderCell: ({ row }) => (
        <Tooltip title={row.name ? row.name : ""}>
          <div className="MuiDataGrid-columnHeaderTitle">
            {row.name ? row.name : ""}
          </div>
        </Tooltip>
      ),
    },
    !isGuide && {
      field: "vencimento",
      headerName: "Vencimento",
      flex: 1,
      minWidth: 151,
      renderCell: ({ row }) => (
        <div className="MuiDataGrid-columnHeaderTitle">
          {row.dueDate
            ? moment(row.dueDate.split("T")[0]).format("DD/MM/YYYY")
            : ""}
        </div>
      ),
    },
    {
      field: "Lançamento",
      headerName: "Lançamento",
      flex: 1,
      minWidth: 151,
      type: "date",
      valueGetter: (params) =>
        moment(params.row.insertDate.split("T")[0]).format("DD/MM/YYYY"),
      sortComparator: (a, b) =>
        moment(a, "DD/MM/YYYY") - moment(b, "DD/MM/YYYY"),
      renderCell: ({ row }) => (
        <div className="MuiDataGrid-columnHeaderTitle">
          {row.insertDate
            ? moment(row.insertDate.split("T")[0]).format("DD/MM/YYYY")
            : ""}
        </div>
      ),
    },
    !tab && {
      field: "Tipo",
      headerName: "Tipo",
      flex: 1,
      minWidth: 151,
      renderCell: ({ row }) => (
        <div className="MuiDataGrid-columnHeaderTitle">
          {row.departmentName ? row.departmentName : ""}
        </div>
      ),
    },
    !isInClientView && {
      field: "favorite",
      headerName: "Favoritos",
      maxWidth: 120,
      minWidth: 120,
      renderCell: ({ row }) => (
        <Grid
          container
          justifyContent="center"
          spacing={1}
          display="flex"
          alignItems="center"
          style={{ paddingTop: "10px" }}
        >
          <Grid item flex={1}>
            <LightTooltip title={row?.starred ? "Desfavoritar" : "Favoritar"}>
              <ContainerIcon
                iconColor={row?.starred ? "#0c2d6a" : "grayPrimary"}
                style={{
                  cursor: "pointer",
                  color: row?.starred ? "#0c2d6a" : "grayPrimary",
                }}
              >
                {row?.starred ? (
                  <StarRate
                    onClick={() => handleFavorite(row.id, row.starred)}
                  />
                ) : (
                  <StarBorder
                    style={{ color: "#5b5b5b" }}
                    onClick={() => handleFavorite(row.id, row.starred)}
                  />
                )}
              </ContainerIcon>
            </LightTooltip>
          </Grid>
        </Grid>
      ),
    },
    {
      field: "actions",
      headerName: "Ações",
      maxWidth: 90,
      minWidth: 90,
      flex: 1,
      renderCell: ({ row }) => (
        <Grid
          container
          justifyContent="center"
          spacing={1}
          display="flex"
          alignItems="center"
          style={{ paddingTop: "10px" }}
        >
          <Grid item flex={1}>
            <LightTooltip title="Visualizar arquivo">
              <ContainerIcon>
                <RemoveRedEyeOutlinedIcon
                  style={{ color: "#5b5b5b" }}
                  disabled={row.base64 ? false : true}
                  onClick={() => {
                    handleShowFile(row.base64, row.mimeType, row.name);
                    setOpenModalFile(true);
                  }}
                />
              </ContainerIcon>
            </LightTooltip>
          </Grid>

          <Grid item flex={1}>
            <LightTooltip title="Download">
              <ContainerIcon style={{ cursor: "pointer" }}>
                <PlayForWorkOutlinedIcon
                  style={{ color: "#5b5b5b" }}
                  disabled={row.base64 ? false : true}
                  onClick={() => {
                    handleDownloadFile(
                      row.base64,
                      `${row.name}.${row.extension}`
                    );
                  }}
                />
              </ContainerIcon>
            </LightTooltip>
          </Grid>

          <Grid item flex={1}>
            <LightTooltip title="Em breve...">
              <ContainerIcon cursor="default">
                <EmailOutlinedIcon style={{ color: "#5b5b5b" }} />
              </ContainerIcon>
            </LightTooltip>
          </Grid>
        </Grid>
      ),
    },
  ];

  const enumMessageNoRows = {
    ["Favoritos"]: "Nenhum item foi favoritado.",
    ["Contrato Social"]: "Nenhum contrato social encontrado.",
    ["Contrato de Trabalho"]: "Nenhum contrato de trabalho encontrado.",
    ["Fiscal"]: messageNoRows.replace("[%type%]", "fiscal"),
    ["Contábil"]: messageNoRows.replace("[%type%]", "contábil"),
    ["Folha"]: messageNoRows.replace("[%type%]", "folha"),
    ["Outros"]: "Nenhum registro encontrado.",
    [undefined]: "Nenhum item foi favoritado.",
  };

  const filterName = (value) => {
    const newData = rows.filter((item) => {
      let valueFound = false;
      if (!valueFound) {
        valueFound = item["name"]
          ? item["name"]
              .toString()
              .normalize("NFD")
              .toLowerCase()
              .replace(/[^a-z0-9_]/g, "")
              .includes(
                value
                  .toString()
                  .normalize("NFD")
                  .toLowerCase()
                  .replace(/[^a-z0-9_]/g, "")
              )
          : false;
      }
      return valueFound;
    });
    setFilteredRows(newData);
  };

  return (
    <GridStyled container padding={tab ? 10 : 0}>
      <GridStyled container spacing={1} margin="0px 0px 0px 0">
        <GridStyled item xs bg-color="white" border-radius="4">
          <Grid
            container
            justify="flex"
            style={{ marginBottom: 20 }}
            spacing={2}
          >
            <Grid item xs={12}>
              <span style={{ fontWeight: "bold", color: "#43506b" }}>
                Pesquisar
              </span>

              <TextField
                variant="outlined"
                placeholder={"Pesquisar por nome"}
                size="small"
                fullWidth
                style={{ marginTop: 5 }}
                onChange={(e) => filterName(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="20"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <path
                            fill="#63708a"
                            fill-rule="evenodd"
                            d="M1 10.5a9.5 9.5 0 1 1 16.887 5.973l3.674 3.673a1 1 0 0 1-1.415 1.415l-3.673-3.674A9.46 9.46 0 0 1 10.5 20 9.5 9.5 0 0 1 1 10.5ZM10.5 3a7.5 7.5 0 1 0 0 15 7.5 7.5 0 0 0 0-15ZM6.964 6.464A4.988 4.988 0 0 1 10.5 5c1.38 0 2.632.56 3.536 1.464A1 1 0 0 1 12.62 7.88 2.987 2.987 0 0 0 10.5 7c-.829 0-1.577.335-2.121.879a1 1 0 1 1-1.415-1.415Z"
                            clip-rule="evenodd"
                          />
                        </svg>
                      </div>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>

            {/* <Grid item xs={5}>
              <span style={{ fontWeight: "bold", color: "#43506b" }}>
                Período de Vencimento
              </span>
      
              <TextField
                id="outlined-basic"
                placeholder="Selecione um Período"
                variant="outlined"
                size="small"
                fullWidth
                onChange={(e) => filterName(e.target.value)}
              />
            </Grid> */}
          </Grid>
          <DataGridGuideReport
            slots={{
              loadingOverlay: LinearProgress,
            }}
            disableColumnMenu={true}
            rows={filteredRows}
            columns={columns}
            rowHeight={32}
            autoHeight
            disableSelectionOnClick
            maxHeight={"300px"}
            pageSize={pageSize}
            hideFooter={filteredRows.length === 0}
            loading={loadingTabs}
            components={{
              LoadingOverlay: () => <LinearProgress style={{ top: 52 }} />,
              NoRowsOverlay: () => (
                <GridOverlay style={{ paddingTop: 20 }}>
                  <br />
                  <h4>{enumMessageNoRows[tab]}</h4>
                  <br />
                </GridOverlay>
              ),
            }}
          />
        </GridStyled>
      </GridStyled>
    </GridStyled>
  );
}
