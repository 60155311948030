import moment from "moment/moment";

export const isExpired = (row) => {
  const yourDate = moment(row.valid_to);
  const isBeforeToday = yourDate.isBefore(moment(), "day");
  return isBeforeToday;
};

export const isWarningInXDays = (row, day) => {
  const yourDate = moment(row.valid_to);
  const daysDifference = yourDate.diff(moment(), "days");

  if (daysDifference <= day) {
    return true;
  }

  return false;
};

export const getExpirationInDays = (date) => {
  const yourDate = moment(date);
  const daysDifference = yourDate.diff(moment(), "days");
  return daysDifference;
};
