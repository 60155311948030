import { Grid } from "@material-ui/core";
import React, { useState } from "react";
import {
  TabTextField,
  valueFormStyle,
  WarningBoletoExists,
} from "../styles/DocumentModal";
import InputMask from "react-input-mask";
import IntlCurrencyInput from "react-intl-currency-input";
import useDocumentModalController from "../controllers/DocumentModalController";

export const DocumentBoletoTab = (props) => {
  const [typeableLine, setTypeableLine] = useState(
    props.document.data.typeable_line
  );
  const [draweeName, setDraweeName] = useState(
    props.document.data.data.drawee.name
  );
  const [draweeTaxId, setDraweeTaxId] = useState(
    props.document.data.data.drawee.taxIdentifier.taxId
  );

  const [draweeTaxIdMask, setDraweeTaxIdMask] = useState(
    props.document.data.data.drawee.taxIdentifier.taxId.replace(/[^\d]/g, "")
      .length <= 11 &&
      props.document.data.data.drawee.taxIdentifier.taxId.replace(/[^\d]/g, "")
        .length > 0
      ? "999.999.999-999"
      : "99.999.999/9999-99"
  );

  const [beneficiaryName, setBeneficiaryName] = useState(
    props.document.data.data.beneficiary.name
  );
  const [beneficiaryTaxId, setBeneficiaryTaxId] = useState(
    props.document.data.data.beneficiary.taxIdentifier.taxId
  );

  const [beneficiaryTaxIdMask, setBeneficiaryTaxIdMask] = useState(
    props.document.data.data.beneficiary.taxIdentifier.taxId.replace(
      /[^\d]/g,
      ""
    ).length <= 11 &&
      props.document.data.data.beneficiary.taxIdentifier.taxId.replace(
        /[^\d]/g,
        ""
      ).length > 0
      ? "999.999.999-999"
      : "99.999.999/9999-99"
  );

  const [totalAmount, setTotalAmount] = useState(
    props.document.data.data.paymentDetails.totalAmount
  );
  const [documentName, setDocumentName] = useState(
    props.document.use_suggestion
      ? props.document.name_suggestion
      : props.document.name
  );

  const { verifyAndChangeGovidMask } = useDocumentModalController();

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        {props.document.data.error && (
          <WarningBoletoExists>
            AVISO: Boleto não encontrado na base de compensação. Pode ser que
            este boleto já tenha sido pago.
          </WarningBoletoExists>
        )}
      </Grid>
      <Grid item xs={12}>
        {props.document.boletoExists && (
          <WarningBoletoExists>
            AVISO: Você já realizou o envio deste boleto.
          </WarningBoletoExists>
        )}
      </Grid>
      <Grid item xs={12}></Grid>
      <Grid item xs={12}>
        <TabTextField
          label="Linha digitável"
          variant="outlined"
          key={"TYPEABLE_LINE" + props.index}
          value={typeableLine}
          onChange={(ev) => {
            setTypeableLine(ev.target.value);
            props.document.data.typeable_line = ev.target.value;
          }}
        />
      </Grid>

      <Grid item xs={9}>
        <TabTextField
          label="Pagador"
          variant="outlined"
          key={"PAYER_CODE" + props.index}
          value={draweeName}
          onChange={(ev) => {
            setDraweeName(ev.target.value);
            props.document.data.data.drawee.name = ev.target.value;
          }}
        />
      </Grid>

      <Grid item xs={3}>
        <InputMask
          onBlur={(ev) => {
            props.setCpfCnpjFocus(false);
          }}
          mask={draweeTaxIdMask}
          disabled={false}
          key={"CPF_CNPJ" + props.index + props.govIdKey}
          maskChar=""
          value={draweeTaxId}
          onChange={(ev) => {
            setDraweeTaxId(ev.target.value.replace(/[^\d]/g, ""));
            props.document.data.data.drawee.taxIdentifier.taxId =
              ev.target.value.replace(/[^\d]/g, "");
            verifyAndChangeGovidMask(
              ev.target.value.replace(/[^\d]/g, ""),
              setDraweeTaxIdMask
            );
            props.setCpfCnpjFocus(true);
          }}
        >
          {() => (
            <TabTextField
              autoFocus={props.cpfCnpjFocus}
              label="CPF/CNPJ Pagador"
              variant="outlined"
              key={"PAYER" + props.index}
            />
          )}
        </InputMask>
      </Grid>

      <Grid item xs={9}>
        <TabTextField
          label="Beneficiario"
          variant="outlined"
          key={"RECEIVER_CODE" + props.index}
          value={beneficiaryName}
          onChange={(ev) => {
            setBeneficiaryName(ev.target.value);
            props.document.data.data.beneficiary.name = ev.target.value;
          }}
        />
      </Grid>

      <Grid item xs={3}>
        <InputMask
          onBlur={(ev) => {
            props.setCpfCnpjFocus1(false);
          }}
          mask={beneficiaryTaxIdMask}
          disabled={false}
          key={"CPF_CNPJ" + props.index + props.govIdKey}
          maskChar=""
          value={beneficiaryTaxId}
          onChange={(ev) => {
            setBeneficiaryTaxId(ev.target.value.replace(/[^\d]/g, ""));
            props.document.data.data.beneficiary.taxIdentifier.taxId =
              ev.target.value.replace(/[^\d]/g, "");
            verifyAndChangeGovidMask(
              ev.target.value.replace(/[^\d]/g, ""),
              setBeneficiaryTaxIdMask
            );
            props.setCpfCnpjFocus1(true);
          }}
        >
          {() => (
            <TabTextField
              autoFocus={props.cpfCnpjFocus1}
              label="CPF/CNPJ Recebedor"
              variant="outlined"
              key={"RECEIVER" + props.index}
            />
          )}
        </InputMask>
      </Grid>

      <Grid item xs={3}>
        <div
          class="MuiFormControl-root MuiTextField-root"
          style={valueFormStyle}
        >
          <label
            style={{ background: "#f2f2f2" }}
            class="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiInputLabel-marginDense MuiInputLabel-outlined MuiFormLabel-filled"
            data-shrink="true"
          >
            Valor total
          </label>

          <div class="MuiInputBase-root MuiOutlinedInput-root MuiInputBase-formControl MuiInputBase-marginDense MuiOutlinedInput-marginDense">
            <IntlCurrencyInput
              currency="BRL"
              defaultValue={totalAmount}
              class="MuiInputBase-input MuiOutlinedInput-input MuiInputBase-inputMarginDense MuiOutlinedInput-inputMarginDense"
              config={{
                locale: "pt-BR",
                formats: {
                  number: {
                    BRL: {
                      style: "currency",
                      currency: "BRL",
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    },
                  },
                },
              }}
              onChange={(event, value, maskedValue) => {
                event.preventDefault();
                setTotalAmount(maskedValue);
                props.document.data.data.paymentDetails.totalAmount =
                  maskedValue;
              }}
            />
          </div>
        </div>
      </Grid>

      {props.documentModalLoading === false && (
        <>
          <Grid item xs={9}>
            <TabTextField
              final
              label={
                props.document.use_suggestion
                  ? "Nome do documento (sugestão)"
                  : "Nome do documento (original)"
              }
              variant="outlined"
              key={"DOCUMENT_NAME" + props.index + " - " + props.documentKey}
              value={documentName}
              onChange={(ev) => {
                setDocumentName(ev.target.value);
                props.document.name_suggestion = ev.target.value;
              }}
            />
          </Grid>
        </>
      )}
    </Grid>
  );
};
