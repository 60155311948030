// https://material-ui.com/pt/customization/default-theme/

import { ThemeProvider } from "styled-components";

const theme = {
  palette: {
    background: "#e8ecf4",
    primary: "#0c2d6a",
    secondary: "#d6e5ff",
    tertiary: "#AFB9C8",
    black: "#4b4c4e",
    white: "white",
    whiteGradient: "#ffffff33",
    whiteGradientSecondary: "#ffffff59",
    gray: "#8995a6",
    grayLight: "#DDDDDD",
    grayPrimary: "#e4e4e4",
    graySecondary: "#f1f1f1",
    grayBlue: "#bbbcdb",
    grayTertiary: "#cacdd5",
    grayMedium: "#505868",
    grayDark: "#3c3d41",
    orange: "#fc9d03",
    green: "#7FC8A9",
    diminutegreen: "#bcdccf",
    red: "#FA8072",
    redSecondary: "#F55C47",
    blue: "#0a73db",
    bluePrimary: "#d6e5ff",
    blueSecondary: "#0a73db33",
    blueLight: "#2CCEFD",
    blueDark: "#03294d",
    yellow: "#d8bb18",
    light: {
      gray: "#DDDDDD",
      subgray: "#EEEEEE",
    },
    transparent: "transparent",
    purple: "#704095",
  },
  fonts: {
    roboto: `"Roboto", sans-serif`,
  },
};

const Theme = ({ children }) => (
  <ThemeProvider theme={theme}>{children}</ThemeProvider>
);

export default Theme;
