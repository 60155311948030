import { useState, useEffect, useContext } from "react";

// Components
import { Switch, Checkbox, Tooltip, IconButton, Grid } from "@material-ui/core";
import { DataGridCnd } from "../styles/CndCustomer";
import TextFieldErp from "../../../components/basics/TextFieldErp";

// Styled Components
import { GridStyled } from "../../../styles/GlobalStyle";

import { CndAccountantContext } from "../contexts/CndAccountantContext";

/**
 * Controller
 */
import { useCndAccountantController } from "../controllers/CndAccountantController";
import { InfoOutlined } from "@material-ui/icons";

let saveTimer = null;

export const TableAccountantCndSettings = ({ tab }) => {
  const { storeAgenda, updateAgenda, destroyAgenda } =
    useCndAccountantController();
  const {
    cndsFederal,
    cndsMunicipal,
    cndsEstadual,
    others,
    agendas,
    setAgendas,
    editedAgendasIds,
    setEditedAgendasIds,
  } = useContext(CndAccountantContext);

  const [filterText, setFilterText] = useState("");
  const [filteredRows, setFilteredRows] = useState([]);
  const [tabRows, setTabRows] = useState([]);

  useEffect(() => {
    switch (tab) {
      case "state":
        setTabRows(cndsEstadual);
        break;
      case "federal":
        setTabRows(cndsFederal);
        break;
      case "municipal":
        setTabRows(cndsMunicipal);
        break;
      default:
        setTabRows(others);
    }
  }, [cndsEstadual, cndsFederal, cndsMunicipal, others, tab]);

  useEffect(() => {
    clearTimeout(saveTimer);
    saveTimer = setTimeout(() => {
      if (editedAgendasIds.length > 0) {
        updateAgenda();
      }
    }, 3000);
  }, [editedAgendasIds, updateAgenda]);

  useEffect(() => {
    setFilteredRows(
      !tabRows.length
        ? []
        : tabRows.filter((entity) => {
            return entity.description
              ?.toLowerCase()
              .includes(filterText.toLocaleLowerCase());
          })
    );
  }, [filterText, tabRows]);

  const handleChange = (key, value, agendaId) => {
    const copyEdited = [...editedAgendasIds];

    if (copyEdited.indexOf(agendaId) === -1) {
      copyEdited.push(agendaId);
    }

    setEditedAgendasIds(copyEdited);

    const copyAgendas = [...agendas];

    const indexAgenda = copyAgendas.findIndex(
      (agenda) => agenda.id === agendaId
    );

    copyAgendas[indexAgenda][key] = value;

    setAgendas(copyAgendas);
  };

  const columns = [
    { field: "id", headerName: "ID", width: 90, hide: true },
    {
      field: "active",
      headerName: "Ativo",
      sortable: false,
      flex: 0.5,

      headerAlign: "center",
      align: "center",

      renderCell: ({ row, field }) => {
        return (
          <Checkbox
            checked={!!row.agendaId}
            color="primary"
            onChange={(e) => {
              e.target.checked ? storeAgenda(row) : destroyAgenda(row.agendaId);
            }}
          />
        );
      },
    },
    {
      field: "description",
      headerName: "CND",
      sortable: false,
      headerAlign: "left",
      align: "left",
      flex: 2.05,

      renderCell: ({ row }) => {
        return (
          <GridStyled container>
            <GridStyled item>{`${row.description}  ${
              row.scope === "Municipal" ? " - " + row.state : ""
            }`}</GridStyled>
          </GridStyled>
        );
      },
    },
    {
      field: "auto",
      headerName: "Automática",
      sortable: false,
      headerAlign: "center",
      align: "center",
      flex: 0.75,

      renderCell: ({ row }) => {
        return (
          <Switch
            disabled={!row.agendaId}
            defaultChecked={row.hasAuto}
            checked={row.auto || false}
            color="primary"
            onChange={(e) =>
              handleChange("auto", e.target.checked, row.agendaId)
            }
          />
        );
      },
    },
    {
      field: "antecedenceDays",
      headerName: "Antecedência",
      type: "number",
      sortable: false,
      headerAlign: "center",
      align: "center",
      flex: 0.95,

      renderCell: ({ row }) => {
        return (
          <GridStyled container max-width={"80px"}>
            <TextFieldErp
              disabled={!row.agendaId}
              size="small"
              variant="outlined"
              type="number"
              value={row.antecedenceDays}
              onInput={(e) => {
                e.target.value = Math.max(0, parseInt(e.target.value))
                  .toString()
                  .slice(0, 12);
              }}
              inputProps={{
                step: "1",
                min: "0",
              }}
              onChange={(e) => {
                handleChange("antecedence_days", e.target.value, row.agendaId);
              }}
            ></TextFieldErp>
          </GridStyled>
        );
      },
    },
    {
      field: "requestOwnSchedule",
      headerName: "Minha CND",
      sortable: false,
      headerAlign: "center",
      align: "center",
      flex: 0.75,

      renderHeader: () => {
        return (
          <Grid container justifyContent="center">
            <div className="MuiDataGrid-columnHeaderTitle">Minha CND</div>
            <Tooltip title="CNDs exibidas na aba 'Minhas CNDs'">
              <InfoOutlined
                style={{ marginTop: "16px", marginLeft: "5px", color: "grey" }}
              />
            </Tooltip>
          </Grid>
        );
      },

      renderCell: ({ row }) => {
        return (
          <Switch
            disabled={!row.agendaId}
            checked={row.requestOwnSchedule}
            color="primary"
            onChange={(e) => {
              handleChange(
                "request_own_schedule",
                e.target.checked,
                row.agendaId
              );
            }}
          />
        );
      },
    },
  ];

  return (
    <GridStyled container justifyContent="center">
      <GridStyled margin-top={20} margin-bottom={15} item lg={12} xs={12}>
        <TextFieldErp
          variant="filled"
          label="Pesquisa por CND"
          value={filterText}
          setValue={setFilterText}
        />
      </GridStyled>

      <GridStyled item lg={12} xs={12} height={"400px"}>
        <DataGridCnd
          rows={filteredRows}
          columns={columns}
          disableSelectionOnClick
          disableColumnMenu
          hideFooter
          pageSize={tabRows.length}
          rowHeight={tabRows.length === 0 ? 15 : 52}
        />
      </GridStyled>
    </GridStyled>
  );
};
