import { useContext } from "react";
import { GlobalContext } from "../../contexts/GlobalContext";

export default function useAuthenticationTranslate() {
  const { language } = useContext(GlobalContext);

  const ptBr = {
    titleLogin: "Informações de Acesso",

    password: "Senha",

    forgotPassword: "Esqueceu a senha?",

    newPassword: "Nova senha",

    confirmNewPassword: "Confirmar nova senha",

    rememberData: "Lembrar meus dados",

    enter: "Entrar",

    verify: "Verificar",

    titleRecover: "Recuperar Senha",

    recover: "Recuperar",

    selectCompany: "Selecione a Empresa",

    confirm: "Confirmar",

    notifySelectCompany: "Selecione uma empresa.",

    notifyEmailPassInvalid: "E-mail ou senha inválido.",
  };

  const enUs = {
    titleLogin: "Access Information",

    password: "Password",

    forgotPassword: "Forgot password?",

    newPassword: "New password",

    confirmNewPassword: "Confirm new password",

    rememberData: "Remember my details",

    enter: "Enter",

    verify: "Verify",

    titleRecover: "Recover Password",

    recover: "Recover",

    selectCompany: "Select the Company",

    confirm: "Confirm",

    notifySelectCompany: "Select a company.",

    notifyEmailPassInvalid: "Invalid e-mail or password.",
  };

  const options = { "pt-BR": ptBr, "en-US": enUs };

  if (!options[language]) return enUs;

  return {
    titleLogin: options[language].titleLogin,

    password: options[language].password,

    forgotPassword: options[language].forgotPassword,

    rememberData: options[language].rememberData,

    enter: options[language].enter,

    titleRecover: options[language].titleRecover,

    recover: options[language].recover,

    selectCompany: options[language].selectCompany,

    confirm: options[language].confirm,

    notifySelectCompany: options[language].notifySelectCompany,

    notifyEmailPassInvalid: options[language].notifyEmailPassInvalid,
  };
}
